import React, { useState } from 'react';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import Grid from '@material-ui/core/Grid';
import { IconButton } from '@material-ui/core';
import { connect } from 'react-redux';
import {
  updateFormOpen,
  setModalForm,
  setModalFormType,
  setModalResetTextFieldFunction,
  setModalServiceName,
} from 'reducers/AddEntityFormReducer';

function RMSComboBoxListItem(props) {
  const {
    option,
    label,
    title,
    updateFormOpen,
    setModalForm,
    setModalFormType,
    resetField,
    setModalResetTextFieldFunction,
    serviceName,
    setModalServiceName,
  } = props;

  const [optionsVisibility, setOptionsVisibility] = useState(false);

  return (
    <React.Fragment>
      <Grid
        container
        onMouseOver={() => {
          setOptionsVisibility(true);
        }}
        onMouseLeave={() => {
          setOptionsVisibility(false);
        }}>
        <Grid justify="flex-start" container sm={9}>
          {option[title]}
        </Grid>
        <Grid justify="flex-end" container sm={3}>
          <IconButton
            hidden={!optionsVisibility}
            style={{ margin: 0, padding: 0 }}
            aria-label="delete"
            onClick={() => {
              if (serviceName) setModalServiceName(serviceName);
              setModalForm(option);
              setModalFormType('edit');
              updateFormOpen(label);
              setModalResetTextFieldFunction(resetField);
            }}>
            <EditIcon style={{ fontSize: '17px' }} />
          </IconButton>
          <span style={{ marginLeft: '5px' }}></span>
          <IconButton
            style={{ margin: 0, padding: 0 }}
            aria-label="delete"
            hidden={!optionsVisibility}
            onClick={() => {
              if (serviceName) setModalServiceName(serviceName);
              setModalForm(option);
              setModalFormType('delete');
              updateFormOpen(label);
              setModalResetTextFieldFunction(resetField);
            }}>
            <DeleteIcon style={{ fontSize: '17px' }} />
          </IconButton>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps, {
  updateFormOpen,
  setModalForm,
  setModalFormType,
  setModalResetTextFieldFunction,
  setModalServiceName,
})(RMSComboBoxListItem);
