import React, { Fragment } from 'react';

import clsx from 'clsx';
import TableCell from '@material-ui/core/TableCell';
import Avatar from '@material-ui/core/Avatar';
import Typography from '@material-ui/core/Typography';
import { lighten, makeStyles, withStyles } from '@material-ui/core/styles';

import { colors } from '../../../../config/colorVariables';
import { Badge } from '@material-ui/core';
import { countIncPropertiesErrors } from '../../../../simpl-schema-validation/CountErrors/incProperty';

const useStyles = makeStyles((theme) => ({
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: colors.cyan,
    marginRight: theme.spacing(2),
  },
  incidentLabel: {
    padding: '3px 0px',
  },
  detailsText:
    theme.palette.type === 'light'
      ? {
          color: lighten(theme.palette.text.primary, 0.4),
        }
      : {
          color: lighten(theme.palette.text.secondary, 2.5),
        },
}));

const StyledBadge = withStyles((theme) => ({
  badge: {
    right: 18,
    top: 10,
    border: `2px solid ${theme.palette.background.paper}`,
    padding: '0 4px',
  },
}))(Badge);

const RMSPropertiesCell = (props) => {
  const classes = useStyles();
  const { row } = props;

  function dragStart(event) {
    event.dataTransfer.setData('Text', event.target.id);
  }

  return (
    <Fragment>
      <TableCell
        onDragStart={dragStart}
        draggable={props.dnd}
        id={`${row.type || ''} ${row.description || ''} ${row.year || ''} ${row.make || ''} ${
          row.model || ''
        } ${row.plateNumber || ''} ${row.plateState || ''} ${row.vin || ''} ${
          row.serialNumber || ''
        }`}
        key={row.parentType}>
        <span style={{ display: 'none' }}>{row.parentType}</span>
        <td>
          <div className="d-flex align-items-center">
            <StyledBadge
              badgeContent={
                row.simpleListType === 'incProperties'
                  ? countIncPropertiesErrors(row.incIncidentPropertyId)
                    ? '!'
                    : 0
                  : 0
              }
              color="error">
              <Avatar aria-label="avatar" className={classes.avatar}>
                {row.parentType !== undefined && row.parentType.substring(0, 3)}
              </Avatar>
            </StyledBadge>

            <div>
              <Typography variant="h5" className={classes.incidentLabel}>
                {row.propertyId} {row.propertyId ? ` | ` : ``} {row.parentType}
              </Typography>

              {row.serialNumber && row.serialNumber.length > 0 && (
                <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
                  <div>
                    <Typography variant="h6" className={clsx(classes.detailsText, ' d-block')}>
                      Serial Number: {row.serialNumber}
                    </Typography>
                  </div>
                </div>
              )}

              {row.parentType.toLowerCase() === 'vehicle' && (
                <>
                  <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
                    <div style={{ paddingRight: '10px' }}>
                      <Typography variant="h6" className={clsx(classes.detailsText, ' d-block')}>
                        VIN: {row.vin}
                      </Typography>
                    </div>
                  </div>
                  <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
                    <div style={{ paddingRight: '10px' }}>
                      <Typography variant="h6" className={clsx(classes.detailsText, ' d-block')}>
                        Plate Number: {row.plateNumber}
                      </Typography>
                    </div>
                    <div style={{ paddingLeft: '10px' }}>
                      <Typography variant="h6" className={clsx(classes.detailsText, ' d-block')}>
                        Plate State: {row.plateState}
                      </Typography>
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
        </td>
      </TableCell>
      <TableCell key={row.description}>{row.description}</TableCell>
      <TableCell key={row.updated}>{row.updated}</TableCell>
      <TableCell key={row.updatedBy}>{row.updatedBy}</TableCell>
    </Fragment>
  );
};

export default RMSPropertiesCell;
