import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';

const useStyles = makeStyles((theme) => ({
  card: {
    padding: '5px 15px 10px',
    marginBottom: 15,
    '& th': {
      paddingLeft: 10,
    },
  },
  creator: {
    '& strong': {
      fontWeight: 500,
    },
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: theme.spacing(1, 0),
  },
}));

export default function EventCard(props) {
  const classes = useStyles();
  const { event } = props;
  const [eventData, setEventData] = useState({});

  useEffect(() => {
    if (event.Event) {
      setEventData(event.Event);
    }
  }, [event]);

  return (
    <Card className={classes.card} variant="outlined">
      <div className={classes.header}>
        <h4>Event</h4>
      </div>
      <table>
        <tbody>
          <tr>
            <td>Event Type:</td>
            <th>{eventData.CallType || ''}</th>
          </tr>
          <tr>
            <td>Call Method:</td>
            <th>{eventData.CallMethod || ''}</th>
          </tr>
          <tr>
            <td>Requested Action:</td>
            <th>{eventData.RequestedAction || ''}</th>
          </tr>
          <tr>
            <td>Location Description:</td>
            <th>{eventData.Description || ''}</th>
          </tr>
        </tbody>
      </table>
    </Card>
  );
}
