import React from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Badge from '@material-ui/core/Badge';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles((props) => ({
  root: {
    backgroundColor: (props) => props.color,
    borderRadius: '3px',
    paddingTop: '3px',
    paddingBottom: '3px',
    paddingLeft: (props) => (props.size === 'sm' ? '0.8em' : props.size === 'md' ? '1em' : '1.5em'),
    paddingRight: (props) =>
      props.size === 'sm' ? '0.8em' : props.size === 'md' ? '1em' : '1.5em',
    margin: '5px',
  },
  label: {
    color: 'white',
    fontWeight: 'bold',
    fontSize: (props) => (props.size === 'sm' ? '0.7em' : props.size === 'md' ? '0.8em' : '0.9em'),
  },
}));

const StyledBadge = (props) => {
  const classes = useStyles(props);
  const { label } = props;

  return (
    <Badge classes={{ root: classes.root }}>
      <Typography className={classes.label}>{label}</Typography>
    </Badge>
  );
};

export default StyledBadge;
