/* eslint-disable prettier/prettier */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { Fragment } from 'react';
import { useHistory } from 'react-router';
import { connect, useDispatch } from 'react-redux';

import clsx from 'clsx';
import { Tooltip, Badge, Button } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Grid, TableCell } from '@material-ui/core';
import Avatar from '@material-ui/core/Avatar';
import Typography from '@material-ui/core/Typography';
import { lighten, makeStyles } from '@material-ui/core/styles';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import IconButton from '@material-ui/core/IconButton';
import AttachFileIcon from '@material-ui/icons/AttachFile';

import { colors } from '../../../../config/colorVariables';
import RMSEditButton from 'components/RMSButtons/RMSEditButton';
import { updateIncPartiesPersonForm } from '../../../../reducers/IncReducer';
import RMSAttachments from 'components/RMSAttachments';
import { handleFormChangeNew } from 'reducers/helpers/formHelpers';
import { setCircularLoading } from 'reducers/ui/UiMenuReducer';
import { upsertRecord } from 'reducers/RecordsReducer';
import { withStyles } from '@material-ui/core';
import { countPartiesErrors, countArresteesErrors } from 'simpl-schema-validation/CountErrors';

const useStyles = makeStyles((theme) => ({
  paper: {
    width: '300px',
    padding: '20px',
  },
  avatar: {
    backgroundColor: colors.cyan,
    height: '55px',
    width: '55px',
  },
  incidentLabel: {
    padding: '3px 0px',
    marginRight: '25px',
    marginTop: '3px !important',
    color:
      theme.palette.type === 'light'
        ? lighten(theme.palette.text.primary, 0.1)
        : theme.palette.text.primary,
  },
  female: {
    backgroundColor: colors.pink,
    height: '55px',
    width: '55px',
  },
  other: {
    backgroundColor: colors.teal,
    height: '55px',
    width: '55px',
  },
  cellRoot: {
    border: 'none',
    verticalAlign: 'bottom',
  },
  detailsText:
    theme.palette.type === 'light'
      ? {
          color: lighten(theme.palette.text.primary, 0.4),
        }
      : {
          color: lighten(theme.palette.text.secondary, 2.5),
        },
  loadingText: {
    width: '100%',
    height: '100%',
    padding: '10px',
  },
  dialogBoxForAttachment: {
    minWidth: '700px',
    maxHeight: '850px',
    minHeight: '350px',
  },
  dialogBoxTitle: {
    background: '#1976d2',
  },
  chipStyle: {
    marginTop: '5px',
    marginLeft: '10px',
    backgroundColor: '#B6C4D2',
    color: '#0b55a1',
    fontSize: '15px',
    padding: '10px',
  },
  badgeStyle: {
    backgroundColor: '#B6C4D2',
    color: '#0b55a1',
    top: '10px',
    right: '14px',
  },
}));

const StyledBadge = withStyles((theme) => ({
  badge: {
    right: 10,
    top: 5,
    border: `2px solid ${theme.palette.background.paper}`,
    padding: '0 4px',
  },
}))(Badge);

const RMSPersonCell = (props) => {
  /** region Component Variables and Props */
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const {
    row,
    updateIncPartiesPersonForm,
    isMP,
    wsClient,
    duplicateCheck,
    partiesPersonForm,
    upsertRecord,
  } = props;
  const [entityType, setEntityType] = React.useState('');
  const [personImage, setPersonImage] = React.useState(null);
  const [totalAttachments, setTotalAttachments] = React.useState(0);
  const [open, setOpen] = React.useState(false);
  const [partiesPersonFormState, setPartiesPersonFormState] = React.useState({
    formType: 'edit',
    isValid: false,
    values: {},
    touched: {},
    errors: {},
  });
  /** endregion */
  /** region React Hooks */
  React.useEffect(() => {
    const parts = history.location.pathname.split('/');
    setEntityType(parts[1]);

    if (row.ptsPersonID) {
      getAttachments();
      getTotalAttachments();
    }
  }, [row.ptsPersonID]);

  React.useEffect(() => {
    setTotalAttachments(partiesPersonFormState.values.attachments?.length);
  }, [partiesPersonFormState.values.attachments?.length]);

  React.useEffect(() => {
    if (!wsClient?.websocket) {
      return;
    }
    getPersonPhotos();
  }, [wsClient]);

  React.useEffect(() => {
    setPartiesPersonFormState((partiesPersonFormState) => ({
      ...partiesPersonFormState,
      ...partiesPersonForm,
      values: {
        ...partiesPersonFormState.values,
        ...partiesPersonForm.values,
      },
    }));
  }, [
    partiesPersonForm.values?.incidentId,
    partiesPersonForm.values?.personId,
    partiesPersonForm.values?.credentialId,
    partiesPersonForm.values?.partyType,
    partiesPersonForm.values?.firstName,
    partiesPersonForm.values?.middleName,
    partiesPersonForm.values?.lastName,
    partiesPersonForm.values?.suffix,
    partiesPersonForm.values?.race,
    partiesPersonForm.values?.sex,
    partiesPersonForm.values?.ethnicity,
    partiesPersonForm.values?.age,
    partiesPersonForm.values?.ageRangeIncrement,
    partiesPersonForm.values?.height,
    partiesPersonForm.values?.weight,
    partiesPersonForm.values?.eyeColor,
    partiesPersonForm.values?.hairColor,
    partiesPersonForm.values?.hairStyle,
    partiesPersonForm.values?.hairLength,
    partiesPersonForm.values?.bloodType,
    partiesPersonForm.values?.handed,
    partiesPersonForm.values?.ssn,
    partiesPersonForm.values?.oln,
    partiesPersonForm.values?.state,
    partiesPersonForm.values?.ptsImageId,
    partiesPersonForm.values?.isUnknownParty,
  ]);

  React.useEffect(() => {
    updateIncPartiesPersonForm(partiesPersonFormState);
  }, [
    partiesPersonFormState.values?.incidentId,
    partiesPersonFormState.values?.personId,
    partiesPersonFormState.values?.credentialId,
    partiesPersonFormState.values?.partyType,
    partiesPersonFormState.values?.firstName,
    partiesPersonFormState.values?.middleName,
    partiesPersonFormState.values?.lastName,
    partiesPersonFormState.values?.suffix,
    partiesPersonFormState.values?.race,
    partiesPersonFormState.values?.sex,
    partiesPersonFormState.values?.ethnicity,
    partiesPersonFormState.values?.age,
    partiesPersonFormState.values?.ageRangeIncrement,
    partiesPersonFormState.values?.height,
    partiesPersonFormState.values?.weight,
    partiesPersonFormState.values?.eyeColor,
    partiesPersonFormState.values?.hairColor,
    partiesPersonFormState.values?.hairStyle,
    partiesPersonFormState.values?.hairLength,
    partiesPersonFormState.values?.bloodType,
    partiesPersonFormState.values?.handed,
    partiesPersonFormState.values?.ssn,
    partiesPersonFormState.values?.oln,
    partiesPersonFormState.values?.state,
    partiesPersonFormState.values?.birthdate,
    partiesPersonFormState.values?.ptsImageId,
    partiesPersonFormState.values?.isUnknownParty,
    partiesPersonFormState.values.attachments,
  ]);
  /** endregion */
  /** region Helper Functions */
  function dragStart(event) {
    event.dataTransfer.setData('Text', event.target.id);
  }

  function arrayBufferToBase64(buffer) {
    var binary = '';
    var bytes = new Uint8Array(buffer);
    var len = bytes.byteLength;
    for (var i = 0; i < len; i++) {
      binary += String.fromCharCode(bytes[i]);
    }
    return window.btoa(binary);
  }

  const getPersonPhotos = async () => {
    const service = wsClient?.websocket.service('rms-party-lock-photo');
    service.timeout = 200000;
    if (!row.ptsImageId) return;

    const result = await service.get(row.ptsImageId);

    if (result) {
      setPersonImage(result[0].FileObject);

      var base64Data = arrayBufferToBase64(result[0].FileObject);
      setPersonImage(base64Data);
    }
  };

  const handleEditPersonRecord = () => {
    history.push(`/records/edit/persons/${row.ptsPersonID}`);
  };

  const calculateAge = (birthday) => {
    const b = new Date(birthday);
    var ageDifMs = Date.now() - b.getTime();
    var ageDate = new Date(ageDifMs);
    return Math.abs(ageDate.getUTCFullYear() - 1970);
  };

  const closeDialogBox = () => {
    setOpen(false);
  };

  const handleSave = () => {
    closeDialogBox();
    dispatch(setCircularLoading(true));

    upsertRecord('person', {}, 'add').then(() => {
      dispatch(setCircularLoading(false));
    });
  };

  const openDialogBox = () => {
    getAttachments();
    setOpen(true);
  };

  const getTotalAttachments = async () => {
    const service = wsClient.websocket.service('ptsattachments');
    service.timeout = 200000;
    const result = await service.find({
      query: {
        ptsParentID: row.ptsPersonID,
        ParentType: 'PERSON',
        IsDeleted: 0,
      },
    });

    setTotalAttachments(result.total);
  };

  const getAttachments = async () => {
    const service = wsClient.websocket.service('rms-attachments');
    service.timeout = 200000;

    const result = await service.find({
      query: {
        id: row.ptsPersonID,
        type: 'PERSON',
      },
    });
    let atts = [];
    result.map((r) => {
      atts.push({
        ptsAttachmentId: r.ptsAttachmentID,
        name: r.FileName,
        type: r.FileType,
        dataURL: r.FileObject,
      });
    });

    setPartiesPersonFormState({
      ptsPersonID: row?.ptsPersonID,
      ptsPersonId: row?.ptsPersonID,
      values: {
        ...row,
        attachments: atts,
      },
    });
  };

  /** endregion */
  if (!row.PersonId && entityType === 'records')
    return (
      <Grid container spacing={2}>
        <Grid item xs={12} className="mt-3">
          <Typography color="textSecondary" variant="row" className={classes.loadingText}>
            Loading...
          </Typography>
        </Grid>
      </Grid>
    );
  if (row.PersonId || entityType !== 'records')
    return (
      <Fragment>
        <Dialog
          classes={{
            paperWidthSm: classes.dialogBoxForAttachment,
          }}
          open={open}
          onClose={closeDialogBox}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description">
          <DialogTitle
            className={clsx(
              classes.dialogBoxTitle,
              ' d-flex justify-content-between align-items-center'
            )}
            id="alert-dialog-title">
            <span style={{ fontSize: '16px', color: 'white' }}>Attachments</span>
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              <RMSAttachments
                isModal={true}
                attachments={partiesPersonFormState.values.attachments}
                stateHolder={partiesPersonFormState}
                setStateHolder={setPartiesPersonFormState}
                setField={handleFormChangeNew}
              />
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Grid
                  container
                  direction="row"
                  justify="flex-end"
                  alignItems="center"
                  style={{ padding: 5, margin: '-15px 0px 10px' }}>
                  <Button onClick={closeDialogBox} style={{ marginRight: 10 }}>
                    Close
                  </Button>
                  <Button
                    onClick={handleSave}
                    style={{ background: '#1976d2', color: '#fff' }}
                    variant="contained">
                    Save
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </DialogActions>
        </Dialog>
        <TableCell
          onDragStart={dragStart}
          draggable="true"
          id={`${row.lastName || ''}, ${row.firstName || ''} ${row.middleName || ''} ${
            row.suffix || ''
          } (${row.race || ''} ${row.sex || ''} ${row.dob || ''} ${row.age || ''})`}
          className={entityType === 'records' || duplicateCheck || isMP ? classes.cellRoot : ''}
          key={row.fullName}>
          <span style={{ display: 'none' }}>{row.fullName}</span>
          <td>
            <div className="d-flex align-items-center">
              {entityType !== 'records' && !duplicateCheck && (
                <div style={{ marginRight: '28px' }}>
                  <Badge badgeContent={'MP'} color="secondary" invisible={!row.isMP}>
                    <StyledBadge
                      badgeContent={
                        row.simpleListType === 'arrestees'
                          ? countArresteesErrors(row.ptsIncPersonId)? '!' : 0
                          : countPartiesErrors(row.ptsIncPersonId) ? '!' : 0
                      }
                      color="error">
                      {personImage === null && (
                        <Avatar
                          aria-label="avatar"
                          className={clsx(
                            row.sex === 'M' && classes.avatar,
                            row.sex === 'F' && classes.female,
                            row.sex === 'U' && classes.other
                          )}>
                          <FontAwesomeIcon icon="user" />
                        </Avatar>
                      )}

                      {personImage !== null && (
                        <Avatar
                          alt={row.firstName}
                          className={classes.avatar}
                          src={'data:image/png;base64, ' + personImage}
                        />
                      )}
                    </StyledBadge>
                  </Badge>
                </div>
              )}

              <div>
                <div style={{ display: 'flex' }}>
                  <Typography
                    variant={entityType === 'records' ? 'h4' : 'h5'}
                    className={classes.incidentLabel}>
                    {row.PersonId} {row.PersonId ? ` | ` : ``} {row.lastName || ''},{' '}
                    {`${row.firstName || ''} ${row.middleName || ''}`}
                  </Typography>
                  {entityType === 'records' && props.duplicateCheck !== true && (
                    <>
                      <RMSEditButton onClick={handleEditPersonRecord} />

                      <Tooltip title="Attachments">
                        <Badge
                          badgeContent={totalAttachments}
                          classes={{ badge: classes.badgeStyle }}>
                          <IconButton
                            style={{ marginLeft: '10px' }}
                            onClick={() => openDialogBox()}
                            color="secondary"
                            aria-label="Add Attachments">
                            <AttachFileIcon style={{ color: 'grey' }} fontSize="small" />
                          </IconButton>
                        </Badge>
                      </Tooltip>
                    </>
                  )}
                </div>
                <div>
                  <Typography variant="h6" className={clsx(classes.detailsText, ' d-block')}>
                    DOB: {row.dob}
                  </Typography>
                </div>
                <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
                  <div style={{ paddingRight: '10px' }}>
                    <Typography variant="h6" className={clsx(classes.detailsText, ' d-block')}>
                      Age: {row.age ? row.age : row.dob ? calculateAge(row.dob) : null}
                    </Typography>
                    <Typography variant="h6" className={clsx(classes.detailsText, ' d-block')}>
                      Sex: {row.sex}
                    </Typography>
                  </div>
                  <div style={{ paddingLeft: '10px' }}>
                    <Typography variant="h6" className={clsx(classes.detailsText, ' d-block')}>
                      Race: {row.race}
                    </Typography>
                    <Typography variant="h6" className={clsx(classes.detailsText, ' d-block')}>
                      Ethnicity: {row.ethnicity}
                    </Typography>
                  </div>
                </div>
              </div>
            </div>
          </td>
        </TableCell>
        <TableCell
          className={entityType === 'records' || duplicateCheck === true ? classes.cellRoot : ''}
          key={row.partyType}>
          {row.partyType}
        </TableCell>
        <TableCell
          className={entityType === 'records' || duplicateCheck === true ? classes.cellRoot : ''}
          key={row.ssn}>
          <Typography variant="body" className={clsx(classes.detailsText, ' d-block')}>
            SSN: {row.ssn}
          </Typography>
          <Typography variant="body" className={clsx(classes.detailsText, ' d-block')}>
            OLN: {row.oln}
          </Typography>
        </TableCell>
        <TableCell
          className={entityType === 'records' || duplicateCheck === true ? classes.cellRoot : ''}
          key="info">
          <td st>
            <div className="d-flex align-items-center justify-content-center">
              <div>
                <Typography variant="body" className={clsx(classes.detailsText, ' d-block')}>
                  <span className={classes.detailsText}> Email: </span> {row.email}
                </Typography>
                <Typography variant="body" className={clsx(classes.detailsText, ' d-block')}>
                  <span className={classes.detailsText}> Phone: </span> {row.phone1 || row.phone2}
                </Typography>
                <Typography variant="body" className={clsx(classes.detailsText, ' d-block')}>
                  <span className={classes.detailsText}> Address: </span> {row.fullAddressText}
                </Typography>
              </div>
            </div>
          </td>
        </TableCell>
      </Fragment>
    );
};

const mapStateToProps = (state) => ({
  wsClient: state.websocket,
  partiesPersonForm: state.incident.partiesPersonForm,
});

export default connect(mapStateToProps, {
  upsertRecord,
  updateIncPartiesPersonForm,
})(RMSPersonCell);
