/* eslint-disable no-restricted-globals */
/* eslint-disable no-undef */
import React from 'react';
import { connect } from 'react-redux';

import { faFlask } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PerfectScrollbar from 'react-perfect-scrollbar';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Divider from '@material-ui/core/Divider';
import { makeStyles } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

import RMSFabButtons from '../../components/RMSForms/RMSIncidentForm/RMSFabButtons';
import TabPanel from '../../components/RMSForms/RMSIncidentForm/TabPanel';
import TabSubstance from '../PageRMSProperties/PageRMSSubstance/TabSubstance';
import { setHandleClose, setMenuType } from '../../reducers/ui/UiMenuReducer';
import { updateIncPropertySubstanceForm } from '../../reducers/IncReducer';
import { setOffensesTab } from '../../reducers/ui/UiFormReducer';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    height: window.innerHeight - 90 + 'px',
  },
  scrollBar: {
    width: '100%',
    overflowX: 'hidden',
    [theme.breakpoints.up('lg')]: {
      height: '70vh',
    },
    [theme.breakpoints.down('lg')]: {
      height: '50vh',
    },
    [theme.breakpoints.only('lg')]: {
      height: '65vh',
    },
  },
  paper: {
    minHeight: window.innerHeight - 95 + 'px',
  },
  dialogBox: {
    minWidth: '412px',
    minHeight: '150px',
  },
  icons: {
    textDecoration: 'capitalize',
    padding: '16px',
    color: 'white',
  },
}));

const AddEditSubstance = (props) => {
  const classes = useStyles();
  const {
    history,
    setHandleClose,
    setMenuType,
    properties,
    selectedIncident,
    selectedEntity,
    updateIncPropertySubstanceForm,
  } = props;
  const [value, setValue] = React.useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  React.useEffect(() => {
    setMenuType('forms');
    setHandleClose('incForms', history);

    if (!selectedIncident || !selectedEntity || properties.length === 0) {
      return;
    }

    const { incIncidentPropertyId } = selectedEntity;

    const property = properties.filter(
      (e) => e.propertyDetails.incIncidentPropertyId === incIncidentPropertyId
    );
    if (property.length === 0) return;
    const { propertyDetails } = property[0];
    updateIncPropertySubstanceForm(propertyDetails);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedIncident]);

  return (
    <div className={classes.root}>
      <RMSFabButtons />
      <Grid container>
        <Grid xs={12} lg={12}>
          <Paper className={classes.paper}>
            <Grid container justify="center">
              <Grid item xs={12} lg={12}>
                <div
                  className="card-header-actions"
                  style={{
                    backgroundColor: '#1976d2',
                    color: 'white',
                    borderRadius: '0.5rem 0.5rem 0 0',
                  }}>
                  <Tabs
                    value={value}
                    indicatorColor="secondary"
                    variant="fullWidth"
                    classes={{
                      indicator: classes.indicator,
                    }}
                    onChange={handleChange}>
                    <Tab
                      className={classes.icons}
                      icon={<FontAwesomeIcon icon={faFlask} />}
                      label="Substance"
                    />
                  </Tabs>
                  <Divider />
                </div>
              </Grid>

              <PerfectScrollbar className={classes.scrollBar}>
                <Grid item xs={12} lg={12} style={{ padding: '15px' }}>
                  <TabPanel value={value} index={0}>
                    <TabSubstance history={history} />
                  </TabPanel>
                </Grid>
              </PerfectScrollbar>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    </div>
  );
};

const mapStateToProps = (state) => ({
  state: state,
  properties: state.incident.properties,
  selectedEntity: state.incident.selectedEntity,
  selectedIncident: state.incident.ptsIncidentId,
});

export default connect(mapStateToProps, {
  setHandleClose,
  setMenuType,
  updateIncPropertySubstanceForm,
  setOffensesTab,
})(AddEditSubstance);
