/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { connect } from 'react-redux';

import clsx from 'clsx';
import PerfectScrollbar from 'react-perfect-scrollbar';
import Grid from '@material-ui/core/Grid';
import Avatar from '@material-ui/core/Avatar';
import makeStyles from '@material-ui/core/styles/makeStyles';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';

import { colors } from '../../config/colorVariables';
import RMSAddButton from '../../components/RMSButtons/RMSAddButton';
import CustodyForm from '../../components/RMSForms/RMSEvidenceForm/CustodyForm';
import CustodyFormAdd from '../../components/RMSForms/RMSEvidenceForm/CustodyForm/Add';
import RMSSimpleList from '../../components/RMSList/RMSSimpleList';
import {
  removeIncEvidenceCustodyDetails,
  updateIncEvidenceCustodyForm,
  setSelectedEvidenceCustody,
  updateIncEvidenceCustodyFormNew,
} from 'reducers/IncReducer';

const useStyles = makeStyles((theme) => ({
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: colors.cyan,
    marginRight: theme.spacing(2),
  },
  scrollBar: {
    width: '100%',
    overflowX: 'hidden',
    paddingBottom: '10px',
    [theme.breakpoints.up('lg')]: {
      height: '60vh',
    },
    [theme.breakpoints.down('lg')]: {
      height: '40vh',
    },
    [theme.breakpoints.only('lg')]: {
      height: '55vh',
    },
  },
}));

export const CustodyAvatar = (props) => {
  const { label } = props;
  const classes = useStyles();

  return <Avatar className={clsx(classes.avatar)}>{label}</Avatar>;
};

const TabEvidenceCustody = (props) => {
  /*Region: Props and State*/
  const {
    incEvidenceCustody,
    removeIncEvidenceCustodyDetails,
    selectedEvidenceCustody,
    updateIncEvidenceCustodyFormNew,
    setSelectedEvidenceCustody,
  } = props;
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [isAdd, setIsAdd] = React.useState(true);
  const [listItems, setListItems] = React.useState([]);
  /** End Region */
  /*Region: Helper Functions*/
  const openModal = () => {
    setIsAdd(true);
    updateIncEvidenceCustodyFormNew({
      isValid: false,
      values: {},
      touched: {},
      errors: {},
    });
    setOpen(true);
  };

  const closeDialogBox = () => {
    setOpen(false);
  };

  const handleDelete = () => {
    let updatedList = listItems.filter((s) => s.id !== selectedEvidenceCustody);
    setListItems(updatedList);
    removeIncEvidenceCustodyDetails();
    setSelectedEvidenceCustody(updatedList[0] ? updatedList[0].id : '');
  };

  const handleEdit = () => {
    setIsAdd(false);
    updateIncEvidenceCustodyForm({
      isValid: false,
      values: {},
      touched: {},
      errors: {},
    });
    setOpen(true);
  };

  const createListItems = () => {
    const items = [];
    incEvidenceCustody.forEach((s, key) => {
      let item = {
        id:
          s.incEvidenceCustodyDetails.ptsEvidenceCustodyId ||
          s.incEvidenceCustodyDetails.tempEvidenceCustodyId,
        type: 'custody',
        icon: <CustodyAvatar label="M" />,
        Status: s.incEvidenceCustodyDetails.values.Status,
        from: s.incEvidenceCustodyDetails.values.custodyFrom,
        reason: s.incEvidenceCustodyDetails.values.reason,
        requiredAction: s.incEvidenceCustodyDetails.values.requiredAction,
        to: s.incEvidenceCustodyDetails.values.custodyTo,
      };
      items.push(item);
    });
    setListItems(items);
    if (items.length > 0) setSelectedEvidenceCustody(items[items.length - 1].id);
  };
  /** End Region */
  /*Region: useEffect*/
  React.useEffect(() => {
    createListItems();
  }, [incEvidenceCustody, incEvidenceCustody.length]);
  /** End Region */
  return (
    <Grid container justify="center">
      <Dialog
        classes={{
          paperWidthSm: classes.dialogBox,
        }}
        open={open}
        onClose={closeDialogBox}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <DialogTitle
          className={'d-flex justify-content-between align-items-center'}
          id="alert-dialog-title"
          style={{ background: '#1976d2' }}>
          <span style={{ fontSize: '18px', color: 'white' }}>
            {isAdd === true ? 'Add Evidence Custody' : 'Edit Evidence Custody'}
          </span>
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {isAdd === true && (
              <CustodyFormAdd dialogToggle={setOpen} reloadItems={createListItems} />
            )}
            {isAdd === false && (
              <CustodyForm dialogToggle={setOpen} reloadItems={createListItems} />
            )}
          </DialogContentText>
        </DialogContent>
      </Dialog>
      <Grid container justify="center">
        <Grid container spacing={2} className="mb-3 mt-3" justify="left">
          <Grid item className="mr-2">
            <RMSAddButton onClick={openModal} />
          </Grid>
        </Grid>
        <Grid container justify="left">
          <Grid item xs={12} lg={12}>
            <PerfectScrollbar className={classes.scrollBar}>
              <RMSSimpleList
                listItems={listItems}
                listItemDelete={handleDelete}
                listItemEdit={handleEdit}
                entity="custody"
              />
            </PerfectScrollbar>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

const mapStateToProps = (state) => ({
  incEvidenceCustody: state.incident.incEvidenceCustody,
  evidenceCustodyForm: state.incident.evidenceCustodyForm,
  evidenceCustodyFormNew: state.incident.evidenceCustodyFormNew,
  selectedEvidenceCustody: state.incident.selectedEvidenceCustody,
});

export default connect(mapStateToProps, {
  removeIncEvidenceCustodyDetails,
  updateIncEvidenceCustodyForm,
  setSelectedEvidenceCustody,
  updateIncEvidenceCustodyFormNew,
})(TabEvidenceCustody);
