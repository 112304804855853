import React from 'react';
import Grid from '@material-ui/core/Grid';
import RMSSelectButton from '../../components/RMSButtons/RMSSelectButton';
import PerfectScrollbar from 'react-perfect-scrollbar';
import RMSSimpleList from '../../components/RMSList/RMSSimpleList';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { colors } from '../../config/colorVariables';
import Avatar from '@material-ui/core/Avatar';
import clsx from 'clsx';
import Tooltip from '@material-ui/core/Tooltip';
import RMSAddButton from '../../components/RMSButtons/RMSAddButton';
import { useHistory } from 'react-router';
import { connect } from 'react-redux';
import {
  setListVictims,
  setSelectedVictim,
  removeIncVictimDetails,
  updateIncPartiesPersonForm,
  updateIncOffensesDVVictimForm,
  updateIncOffensesDVSuspectForm,
  updateIncOffensesDVAdditionalForm,
  setSelectedSuspect,
  setIncVictimsDetails,
} from '../../reducers/IncReducer';
import {
  setHandleAddIncVictim,
  setHandleAddIncDVVictim,
  setHandleAddIncDVSuspect,
  setHandleAddIncDVAdditional,
  setHandleOffensesRedirectFormClose,
} from '../../reducers/ui/UiMenuReducer';
import { setVictimDVTab } from '../../reducers/ui/UiFormReducer';
import { v4 as uuidv4 } from 'uuid';
import DialogList from '../../components/RMSList/RMSDialogList';

const useStyles = makeStyles((theme) => ({
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: colors.cyan,
    marginRight: theme.spacing(2),
  },
  female: {
    margin: theme.spacing(1),
    backgroundColor: colors.pink,
    marginRight: theme.spacing(2),
  },
  property: {
    margin: theme.spacing(1),
    backgroundColor: colors.indigo,
    marginRight: theme.spacing(2),
  },
  victim: {
    margin: theme.spacing(1),
    backgroundColor: colors.orange,
    marginRight: theme.spacing(2),
  },
  detailsPanel: {
    padding: theme.spacing(4),
  },
}));

export const VictimAvatar = (props) => {
  const { label } = props;
  const classes = useStyles();

  return (
    <Avatar
      className={clsx(
        label === 'M' && classes.avatar,
        label === 'F' && classes.female,
        label === 'P' && classes.property,
        label === 'V' && classes.victim
      )}>
      {label}
    </Avatar>
  );
};

const TabVictims = (props) => {
  /** region Component Variables and Props */
  const history = useHistory();
  const {
    selectedVictim,
    incVictims,
    listVictims,
    listDialogVictims,
    parties,
    setHandleOffensesRedirectFormClose,
    setHandleAddIncVictim,
    setListVictims,
    setSelectedVictim,
    removeIncVictimDetails,
    updateIncPartiesPersonForm,
    offensesOffenseForm,
  } = props;
  const [offensesVictimFormState, setOffensesVictimFormState] = React.useState({
    tempIncVictimId: uuidv4(),
    isValid: false,
    values: {},
    touched: {},
    errors: {},
  });
  const [dialogBox, setDialogBox] = React.useState(false);
  const [changeKey, setChangeKey] = React.useState(0);
  /** endregion */
  /** region React Hooks */
  React.useEffect(() => {
    if (offensesOffenseForm.ptsOffenseId) {
      setOffensesVictimFormState({
        ...offensesVictimFormState,
        ptsOffenseId: offensesOffenseForm.ptsOffenseId,
      });
    } else {
      setOffensesVictimFormState({
        ...offensesVictimFormState,
        tempOffenseId: offensesOffenseForm.tempOffenseId,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    setHandleOffensesRedirectFormClose(false);
    createIncVictimList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [incVictims, parties, incVictims.length]);
  /** endregion */
  /** region Helper Functions */
  const createIncVictimList = () => {
    if (incVictims !== undefined && incVictims.length > 0) {
      let newList = [];
      let victim = {};

      incVictims.forEach((s, i) => {
        let party;
        if (!s.incVictimDetails.ptsIncPersonId) {
          party = parties.find((x) => x.tempPartyId === s.incVictimDetails.tempPartyId);
        } else {
          party = parties.find((x) => x.ptsIncPersonId === s.incVictimDetails.ptsIncPersonId);
        }

        if (party) {
          victim = {
            id: party?.ptsIncPersonId || party?.personDetail.tempPartyId,
            name: `${party.personDetail.values.firstName}${
              party.personDetail.values.middleName
                ? ` ${party.personDetail.values.middleName} `
                : ' '
            }${party.personDetail.values.lastName}`,
            icon: (
              <VictimAvatar
                label={
                  party.personDetail.values.sex === 'MALE'
                    ? 'M'
                    : party.personDetail.values.sex === 'FEMALE'
                    ? 'F'
                    : 'U'
                }
              />
            ),
            age: party.personDetail.values.age,
            ethnicity: party.personDetail.values.ethnicity,
            type: 'person',
          };
          newList.push(victim);
        }
        if (newList.length === 1) {
          setSelectedVictim(victim.id);
        }
      });
      setListVictims(newList);
      setChangeKey(newList.length);
    }
  };
  const handleIncVictimSelect = () => {
    if (listDialogVictims.length > 0) {
      setDialogBox(true);
    }
  };

  const closeDialogBox = () => {
    createIncVictimList();
    setDialogBox(false);
  };

  const handleIncVictimAdd = () => {
    setHandleOffensesRedirectFormClose(true);
    setHandleAddIncVictim(true);
    updateIncPartiesPersonForm({
      isValid: false,
      values: {},
      touched: {},
      errors: {},
      ptsIncPersonId: undefined,
    });
    history.push('/incidents/add/victims/person');
  };

  const handleIncVictimDelete = () => {
    let updatedList = listVictims.filter((s) => s.id !== selectedVictim);
    setListVictims(updatedList);
    removeIncVictimDetails();
    setSelectedVictim(updatedList[0] ? updatedList[0].id : '');
  };
  /** endregion */
  return (
    <Grid container>
      <Grid container spacing={2} className="mb-3">
        <Grid item className="mr-2 ml-2">
          <RMSAddButton onClick={handleIncVictimAdd} />
        </Grid>
        <Grid item className="mr-1">
          <DialogList
            dialogBox={dialogBox}
            title="Incident Victim List"
            type="victims"
            list={[]}
            addToSimpleList={createIncVictimList}
            closeDialogBox={closeDialogBox}
          />

          <Tooltip title="Select Victims from Parties">
            <RMSSelectButton lists={listDialogVictims} onClick={handleIncVictimSelect} />
          </Tooltip>
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={12} lg={12}>
          <PerfectScrollbar style={{ height: '99%' }} className="scroll-area-lg">
            <RMSSimpleList
              key={changeKey}
              listItems={listVictims}
              entity="victims"
              deleteFromList={handleIncVictimDelete}
            />
          </PerfectScrollbar>
        </Grid>
      </Grid>
    </Grid>
  );
};

const mapStateToProps = (state) => ({
  offensesVictimForm: state.incident.offensesVictimForm,
  incVictims: state.incident.incVictims,
  listVictims: state.incident.listVictims,
  listDialogVictims: state.incident.listDialogVictims,
  parties: state.incident.parties,
  selectedVictim: state.incident.selectedVictim,
  offensesOffenseForm: state.incident.offensesOffenseForm,
  wsClient: state.websocket.websocket,
  selectedEntity: state.incident.selectedEntity,
  selectedIncident: state.incident.ptsIncidentId,
});

export default connect(mapStateToProps, {
  setHandleOffensesRedirectFormClose,
  setHandleAddIncVictim,
  setHandleAddIncDVSuspect,
  setListVictims,
  setSelectedVictim,
  setHandleAddIncDVVictim,
  setHandleAddIncDVAdditional,
  removeIncVictimDetails,
  updateIncOffensesDVVictimForm,
  updateIncOffensesDVSuspectForm,
  updateIncOffensesDVAdditionalForm,
  updateIncPartiesPersonForm,
  setVictimDVTab,
  setSelectedSuspect,
  setIncVictimsDetails,
})(TabVictims);
