import React, { useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router';
import { connect, useDispatch, useSelector } from 'react-redux';

import {
  FormControl,
  Grid,
  InputLabel,
  makeStyles,
  MenuItem,
  Select,
  TextField,
  Paper,
} from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';

import { PhoneMask } from '../../../../components/RMSTextMask';
import { handleFormChangeNew } from 'reducers/helpers/formHelpers';
import RMSFabButtons from 'components/RMSForms/RMSIncidentForm/RMSFabButtons';
import { upsertRecord } from 'reducers/RecordsReducer';
import { setCircularLoading } from 'reducers/ui/UiMenuReducer';
import { checkExistingContact } from 'reducers/helpers/recordHelper';

const useStyles = makeStyles(() => ({
  root: {
    height: window.innerHeight - 90 + 'px',
    borderRadius: '0.5rem',
    padding: '15px',
  },
  form: {
    width: '100%',
    textAlign: 'left',
  },
  input: {
    width: '100%',
    padding: '10px',
  },
  btnStyle: {
    display: 'block',
    width: '150px',
    padding: '10px',
    marginTop: '15px',
    marginLeft: 'auto',
    position: 'relative',
    right: 10,
  },
}));

const codeMethodType = ['BUS', 'EML', 'HME', 'WEB', 'WRK'];

const PersonContactMethodForm = (props) => {
  /** region Component Variables and Props */
  const classes = useStyles();
  let data = useSelector((state) => state.records.formData);
  const location = useLocation();
  const { upsertRecord } = props;
  const history = useHistory();
  const dispatch = useDispatch();
  const actionType = location.pathname.split('/')[5];
  const { persondId } = useParams();
  if (actionType === 'add') {
    data = {};
  }
  const [formData, setFormData] = useState({
    values: {
      ptsInfoID: data?.ptsInfoID || undefined,
      PartyType: data?.PartyType || '',
      ptsParentID: persondId,
      ContactMethod: data?.ContactMethod || '',
      MethodType: data?.MethodType || '',
      Info: data?.Info || '',
    },
  });
  /** endregion */
  /** region Helper Functions */

  const handleSave = async () => {
    if (!formData.values.ptsInfoID) {
      const contactExit = await checkExistingContact(formData.values);

      if (contactExit.length > 0) {
        formData.values.ptsInfoID = contactExit[0].ptsInfoID;
        upsertRecord('contact', formData.values, 'edit').finally(() => handleFinally());
      } else {
        upsertRecord('contact', formData.values, 'add').finally(() => handleFinally());
      }
    } else {
      upsertRecord('contact', formData.values, 'edit').finally(() => handleFinally());
    }
  };

  const handleFinally = () => {
    dispatch(setCircularLoading(false));
    history.goBack();
  };
  /** endregion */
  return (
    <Paper className={classes.root}>
      <form autoComplete="off" className={classes.form}>
        <Grid container item xs={12} align="center" spacing={2} justify="space-between">
          <Grid item xs={4}>
            <FormControl variant="outlined" className={classes.form} size="small">
              <InputLabel htmlFor="outlined-age-native-simple">Contact Method</InputLabel>
              <Select
                small
                value={formData.values?.ContactMethod}
                onChange={(event) =>
                  handleFormChangeNew(
                    event,
                    event.target.value,
                    'none',
                    'ContactMethod',
                    setFormData
                  )
                }
                label="Contact Method"
                inputProps={{
                  name: 'ContactMethod',
                  id: 'outlined-type-native-simple',
                }}>
                <MenuItem value={'Phone'}>Phone</MenuItem>
                <MenuItem value={'Email'}>Email</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={4}>
            <FormControl variant="outlined" className={classes.form} size="small">
              <InputLabel htmlFor="outlined-partytype-native-simple">Party Type</InputLabel>
              <Select
                small
                value={formData.values?.PartyType}
                onChange={(event) =>
                  handleFormChangeNew(event, event.target.value, 'none', 'PartyType', setFormData)
                }
                label="Party Type"
                inputProps={{
                  name: 'PartyType',
                  id: 'outlined-type-native-simple',
                }}>
                <MenuItem value={'Person'}>Person</MenuItem>
                <MenuItem value={'Place'}>Place</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={4}>
            <Autocomplete
              size="small"
              autoComplete
              autoSelect
              autoHighlight
              id="combo-MethodType"
              getOptionLabel={(option) => option || ''}
              options={codeMethodType}
              key={formData.values?.MethodType || ''}
              value={formData.values?.MethodType || ''}
              onChange={(event, newValue) =>
                handleFormChangeNew(event, newValue, 'none', 'MethodType', setFormData)
              }
              renderInput={(params) => (
                <Grid container spacing={1} alignItems="flex-end">
                  <Grid item xs={12} lg={12}>
                    <TextField
                      autoComplete="hidden"
                      size="small"
                      {...params}
                      label="Method Type"
                      variant="outlined"
                      fullWidth
                    />
                  </Grid>
                </Grid>
              )}
            />
          </Grid>
          <Grid item xs={4}>
            {formData.values.ContactMethod === 'Phone' ? (
              <TextField
                autoComplete="hidden"
                size="small"
                fullWidth
                variant="outlined"
                placeholder="Primary Phone"
                label="Primary Phone"
                name="Info"
                value={formData.values?.Info || ''}
                onChange={(event) =>
                  handleFormChangeNew(event, event.target.value, 'none', 'Info', setFormData)
                }
                id="formatted-numberformat-input"
                InputProps={{ inputComponent: PhoneMask }}
                multiline
                InputLabelProps={{ shrink: true }}
              />
            ) : (
              <TextField
                id="standard-textarea-contact-emailMask"
                autoComplete="hidden"
                size="small"
                variant="outlined"
                fullWidth
                placeholder="pts@pts.com"
                label="Info"
                name="Info"
                value={formData.values?.Info}
                onChange={(event) =>
                  handleFormChangeNew(event, event.target.value, 'none', 'Info', setFormData)
                }
                multiline
                InputLabelProps={{ shrink: true }}
              />
            )}
          </Grid>
        </Grid>
      </form>
      <RMSFabButtons recordForm={true} handleSaveRecord={handleSave} />
    </Paper>
  );
};

const mapStateToProps = (state) => ({
  wsClient: state.websocket,
});

export default connect(mapStateToProps, { upsertRecord })(PersonContactMethodForm);
