import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { setHandleClose, setMenuType } from '../../reducers/ui/UiMenuReducer';
import { setVictimDVTab } from '../../reducers/ui/UiFormReducer';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserShield, faArchive, faUsers } from '@fortawesome/free-solid-svg-icons';
import Divider from '@material-ui/core/Divider';
import TabPanel from '../../components/RMSForms/RMSIncidentForm/TabPanel';
import { makeStyles } from '@material-ui/core/styles';
import TabDomesticViolenceVictim from './TabDomesticViolenceVictim';
import TabDomesticViolenceSuspects from './TabDomesticViolenceSuspects';
import TabDomesticViolenceAdditional from './TabDomesticViolenceAdditional';
import clsx from 'clsx';
import IncSummaryDetails from '../../components/RMSForms/RMSIncidentForm/IncSummaryDetails';
import PerfectScrollbar from 'react-perfect-scrollbar';
import RMSFabButtons from 'components/RMSForms/RMSIncidentForm/RMSFabButtons';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    height: window.innerHeight - 94 + 'px',
  },
  paper: {
    minHeight: window.innerHeight - 90 + 'px',
    paddingLeft: '8px',
    paddingRight: '8px',
    marginTop: '8px',
  },

  tabBgStyles: {
    backgroundColor: '#1976d2',
    color: 'white',
    borderRadius: '0.5rem 0.5rem 0 0',
  },
  indicator: {
    backgroundColor: 'rgba(0,0,254,0.3)',
  },
  scrollBar: {
    width: '100%',
    overflowX: 'hidden',

    paddingRight: '5px',
    [theme.breakpoints.up('lg')]: {
      height: '70vh',
    },
    [theme.breakpoints.down('lg')]: {
      height: '55vh',
    },
    [theme.breakpoints.only('lg')]: {
      height: '65vh',
    },
  },
  icons: {
    textDecoration: 'capitalize',
    padding: '16px',
    fontSize: '13px',
  },
}));

const AddEditDomesticViolence = (props) => {
  const { history, setHandleClose, setMenuType, setVictimDVTab, selectedTab } = props;
  const classes = useStyles();

  useEffect(() => {
    setMenuType('forms');
    setHandleClose('incSubForms', history);
  }, []);

  const handleChange = (event, newValue) => {
    setVictimDVTab(newValue);
  };

  return (
    <div className={classes.root}>
      <RMSFabButtons />
      <Grid container>
        <Grid xs={3} lg={3}>
          <IncSummaryDetails history={history} />
        </Grid>
        <Grid xs={9} lg={9}>
          <Paper className={clsx(classes.paper, selectedTab === 2 && classes.additional)}>
            <Grid container justify="center" spacing={2}>
              <Grid item xs={12} lg={12} style={{ padding: '0px' }}>
                <div className={`card-header-actions ${classes.tabBgStyles}`}>
                  <Tabs
                    value={selectedTab}
                    indicatorColor="secondary"
                    variant="fullWidth"
                    classes={{
                      indicator: classes.indicator,
                    }}
                    onChange={handleChange}>
                    <Tab
                      className={classes.icons}
                      icon={<FontAwesomeIcon icon={faUserShield} />}
                      label="Victim"
                    />
                    <Tab
                      className={classes.icons}
                      icon={<FontAwesomeIcon icon={faUsers} />}
                      label="Suspects"
                    />
                    <Tab
                      className={classes.icons}
                      icon={<FontAwesomeIcon icon={faArchive} />}
                      label="Additional"
                    />
                  </Tabs>
                  <Divider />
                </div>
              </Grid>

              <PerfectScrollbar className={classes.scrollBar}>
                <Grid item xs={12}>
                  <Grid item>
                    <TabPanel value={selectedTab} index={0}>
                      <TabDomesticViolenceVictim />
                    </TabPanel>
                  </Grid>
                  <Grid item>
                    <TabPanel value={selectedTab} index={1}>
                      <TabDomesticViolenceSuspects />
                    </TabPanel>
                  </Grid>
                  <Grid item>
                    <TabPanel value={selectedTab} index={2}>
                      <TabDomesticViolenceAdditional />
                    </TabPanel>
                  </Grid>
                </Grid>
              </PerfectScrollbar>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    </div>
  );
};

const mapStateToProps = (state) => ({
  selectedTab: state.uiForm.victimDVTabState,
});

export default connect(mapStateToProps, { setMenuType, setHandleClose, setVictimDVTab })(
  AddEditDomesticViolence
);
