export const GET_CODE_PARTY_INVOLVEMENT_TYPES = 'GET_CODE_PARTY_INVOLVEMENT_TYPES';

export const getCodePartyInvolvementTypes = (state) => {
  return async (dispatch) => {
    return dispatch({ type: GET_CODE_PARTY_INVOLVEMENT_TYPES, payload: state });
  };
};

export default function reducer(state = [], action) {
  switch (action.type) {
    case GET_CODE_PARTY_INVOLVEMENT_TYPES:
      state = action.payload;
      return state;
    default:
      return state;
  }
}
