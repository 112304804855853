export const GET_CODE_STRUCTURE_COLOR = 'GET_CODE_STRUCTURE_COLOR';
export const ADD_CODE_STRUCTURE_COLOR = 'ADD_CODE_STRUCTURE_COLOR';

export const addCodeStructureColor = (newVal) => {
  return { type: ADD_CODE_STRUCTURE_COLOR, payload: newVal };
};

export const getCodeStructureColor = (state) => {
  return async (dispatch) => {
    return dispatch({ type: GET_CODE_STRUCTURE_COLOR, payload: state });
  };
};

export default function reducer(state = [], action) {
  switch (action.type) {
    case GET_CODE_STRUCTURE_COLOR:
      state = action.payload;
      return state;
    case ADD_CODE_STRUCTURE_COLOR:
      return [...state, action.payload];
    default:
      return state;
  }
}
