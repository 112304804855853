/* eslint-disable react-hooks/exhaustive-deps */
import React, { Fragment } from 'react';
import TableCell from '@material-ui/core/TableCell';
import Avatar from '@material-ui/core/Avatar';
import clsx from 'clsx';
import Typography from '@material-ui/core/Typography';
import { colors } from '../../../../config/colorVariables';
import { lighten, makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: colors.cyan,
    marginRight: theme.spacing(2),
  },
  female: {
    margin: theme.spacing(1),
    backgroundColor: colors.pink,
    marginRight: theme.spacing(2),
  },
  incidentLabel: {
    padding: '3px 0px',
  },
  detailsText:
    theme.palette.type === 'light'
      ? {
          color: lighten(theme.palette.text.primary, 0.4),
        }
      : {
          color: lighten(theme.palette.text.secondary, 2.5),
        },
}));

const RMSEvidenceCell = (props) => {
  const classes = useStyles();
  const { row } = props;
  const [iconText, setIconText] = React.useState('');

  const setAvatarIcon = () => {
    if (row.evidenceType !== undefined && row.evidenceType !== null) {
      setIconText(row.evidenceType.substr(0, 2));
    } else if (row.description !== undefined && row.description !== null) {
      setIconText(row.description.substr(0, 2));
    }
  };

  function dragStart(event) {
    event.dataTransfer.setData('Text', event.target.id);
  }

  React.useEffect(() => {
    setAvatarIcon();
  }, []);

  return (
    <Fragment>
      <TableCell
        key={row.ptsEvidenceId}
        onDragStart={dragStart}
        draggable={props.dnd}
        id={`${row.evidenceType || ''} ${row.description || ''} ${row.year || ''} ${
          row.make || ''
        } ${row.model || ''} ${row.plateNumber || ''} ${row.plateState || ''} ${row.vin || ''} ${
          row.serialNumber || ''
        }`}>
        <span style={{ display: 'none' }}>{row.evidenceType}</span>
        <td>
          <div className="d-flex align-items-center">
            <Avatar aria-label="avatar" className={clsx(classes.avatar)}>
              {iconText}
            </Avatar>
            <div>
              <Typography variant="h5" className={classes.incidentLabel}>
                {row.evidenceType}
              </Typography>
              <div>
                <Typography variant="h6" className={clsx(classes.detailsText, ' d-block')}>
                  VIN: {row.vin}
                </Typography>
              </div>
              <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
                <div style={{ paddingRight: '10px' }}>
                  <Typography variant="h6" className={clsx(classes.detailsText, ' d-block')}>
                    Plate Number: {row.plateNumber}
                  </Typography>
                </div>
                <div style={{ paddingLeft: '10px' }}>
                  <Typography variant="h6" className={clsx(classes.detailsText, ' d-block')}>
                    Serial Number: {row.serialNumber}
                  </Typography>
                </div>
              </div>
              <div>
                <Typography variant="h6" className={clsx(classes.detailsText, ' d-block')}>
                  Description: {row.description}
                </Typography>
              </div>
            </div>
          </div>
        </td>
      </TableCell>
      <TableCell key={row.collected_info}>
        <div className="d-flex align-items-center">
          <div>
            <div>
              <Typography variant="h6" className={clsx(classes.detailsText, ' d-block')}>
                Collected By: {row.collectedBy}
              </Typography>
            </div>
            <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
              <div style={{ paddingRight: '10px' }}>
                <Typography variant="h6" className={clsx(classes.detailsText, ' d-block')}>
                  Location: {row.locationCollected}
                </Typography>
              </div>
              <div style={{ paddingLeft: '10px' }}>
                <Typography variant="h6" className={clsx(classes.detailsText, ' d-block')}>
                  Date: {row.collectedDate}
                </Typography>
              </div>
            </div>
            <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
              <div style={{ paddingRight: '10px' }}>
                <Typography variant="h6" className={clsx(classes.detailsText, ' d-block')}>
                  Make: {row.make}
                </Typography>
              </div>
              <div style={{ paddingLeft: '10px' }}>
                <Typography variant="h6" className={clsx(classes.detailsText, ' d-block')}>
                  Model: {row.model}
                </Typography>
              </div>
            </div>
            <div>
              <Typography variant="h6" className={clsx(classes.detailsText, ' d-block')}>
                Condition: {row.condition}
              </Typography>
            </div>
          </div>
        </div>
      </TableCell>
      <TableCell key={row.updated}>{row.updated}</TableCell>
      <TableCell key={row.updated}>{row.updatedBy}</TableCell>
    </Fragment>
  );
};

export default RMSEvidenceCell;
