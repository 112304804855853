import React, { useContext } from 'react';
import { connect } from 'react-redux';
import { useParams } from 'react-router';

import { XGrid } from '@material-ui/x-grid';
import CheckCircleSharpIcon from '@material-ui/icons/CheckCircleSharp';
import CancelSharpIcon from '@material-ui/icons/CancelSharp';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';

import { RefreshContext } from './AddEditPersonRecord';
import { setFormData } from '../../../reducers/RecordsReducer';

const useStyles = makeStyles(() => ({
  root: {
    height: window.innerHeight - 307 + 'px',
  },
}));

function RecordPersonName(props) {
  const [loading, setLoading] = React.useState(false);
  const [columns, setColumns] = React.useState([]);
  const [rows, setRows] = React.useState([]);
  const [rowCount, setRowCount] = React.useState(0);
  const classes = useStyles();
  const refresh = useContext(RefreshContext);

  const { wsClient, setFormData } = props;
  const fields = [
    'ptsPersonID',
    'ptsImageID',
    'Photo',
    'ImageTypeCode',
    'IsDefault',
    'IsMark',
    'Location',
    'Gang',
    'Description',
    'MarkType',
  ];
  let { persondId } = useParams();

  const setXGridData = (result) => {
    if (result.length > 0) {
      if (result[0].Mugshots) {
        let parsedLocalMugshots =
          result[0].Mugshots !== null ? JSON.parse(result[0].Mugshots) : null;
        const data = parsedLocalMugshots.map((item, i) => {
          const obj = {
            ...item,
            ptsPersonID: result[0].ptsPersonID,
            id: i,
          };
          return obj;
        });
        setRows(data);
      }
    } else {
      setRows([]);
    }
  };

  const pullData = async (skip = 0) => {
    if (!wsClient?.websocket) {
      return;
    }

    const service = wsClient?.websocket.service('record-person-photo');
    service.timeout = 200000;

    let q = {
      skip: skip,
      limit: 50,
      ptsPersonID: persondId,
    };

    setRows([]);

    const result = await service.find({
      query: q,
    });

    setXGridData(result);
    setRowCount(result.length);
    setLoading(false);
  };

  React.useEffect(() => {
    const col = fields.map((e) => {
      if (e === 'Photo') {
        return {
          field: 'FileObject',
          headerName: e,
          width: 200,
          renderCell: (params) => (
            // eslint-disable-next-line jsx-a11y/img-redundant-alt
            <img
              src={'data:image/png;base64, ' + params.value}
              alt="Photo"
              width="30%"
              height="100%"
            />
          ),
        };
      } else if (e === 'IsDefault' || e === 'IsMark')
        return {
          field: e,
          headerName: e,
          width: 150,
          renderCell: (params) =>
            // eslint-disable-next-line jsx-a11y/img-redundant-alt
            params.value ? (
              <CheckCircleSharpIcon fontSize="20px" color="primary" />
            ) : (
              <CancelSharpIcon fontSize="20px" color="action" />
            ),
        };
      else {
        return {
          field: e,
          headerName: e,
          width: 150,
        };
      }
    });
    setColumns(col);
    setLoading(true);

    const delayDebounceFn = setTimeout(() => {
      pullData();
    }, 2000);

    return () => clearTimeout(delayDebounceFn);
  }, [wsClient, refresh]);

  React.useEffect(() => {
    setFormData({});
  }, []);
  return (
    <Grid container className={classes.root}>
      <React.Fragment
        style={{ minHeight: window.innerHeight - 400 + 'px' }}
        className={classes.paper}>
        <XGrid
          onPageChange={(params) => {
            pullData(params.page * 50);
          }}
          pageSize={50}
          rowCount={rowCount}
          pagination
          paginationMode="server"
          loading={loading}
          onRowClick={(row) => {
            if (row) {
              setFormData(row.row);
            } else {
              setFormData(null);
            }
          }}
          rows={rows}
          columns={columns}
        />
      </React.Fragment>
    </Grid>
  );
}

const mapStateToProps = (state) => ({
  wsClient: state.websocket,
  records: state.records,
});

const mapDispatchToProps = {
  setFormData,
};

export default connect(mapStateToProps, mapDispatchToProps)(RecordPersonName);
