/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */
import React from 'react';
import { connect, useDispatch } from 'react-redux';

import { v4 as uuidv4 } from 'uuid';

import PerfectScrollbar from 'react-perfect-scrollbar';
import DateFnsUtils from '@date-io/date-fns';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import RMSEditor from '../../components/RMSEditor';
import makeStyles from '@material-ui/core/styles/makeStyles';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Paper from '@material-ui/core/Paper';
import { MuiPickersUtilsProvider, KeyboardDateTimePicker } from '@material-ui/pickers';

import DNDList from './DNDList';
import TabPanel from '../../components/RMSForms/RMSIncidentForm/TabPanel';
import RMSFabButtons from 'components/RMSForms/RMSIncidentForm/RMSFabButtons';
import { handleFormChangeNew } from '../../reducers/helpers/formHelpers';
import { setCircularLoading, setHandleClose, setMenuType } from '../../reducers/ui/UiMenuReducer';
import { updateIncNarrativeForm } from '../../reducers/IncReducer';
import { getCodeNarrativeTypes } from 'reducers/dictionaries/CodeNarrativeTypesReducer';
import { RMSPersonSearch } from 'components/RMSPersonSearch';
import RMSAttachments from 'components/RMSAttachments';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    height: window.innerHeight - 90 + 'px',
  },
  scrollBar: {
    width: '100%',
    overflowX: 'hidden',

    paddingBottom: '5px',
    [theme.breakpoints.up('lg')]: {
      height: '70vh',
    },
    [theme.breakpoints.down('lg')]: {
      height: '45vh',
    },
    [theme.breakpoints.only('lg')]: {
      height: '65vh',
    },
  },
  paper: {
    minHeight: window.innerHeight - 95 + 'px',
  },
  head: {
    backgroundColor: '#1976d2',
    color: 'white',
    borderRadius: '0.5rem 0.5rem 0px 0px ',
  },
  input: {
    borderRadius: '0.5rem ',
    color: 'black',
  },
  floatingLabelFocusStyle: {
    color: 'black',
    backgroundColor: 'white',
    padding: '2px 5px !important',
    fontSize: '14px !important',
  },
  indicator: {
    backgroundColor: 'rgba(0,0,254,0.3)',
    color: 'white',
  },
}));

const options = ['1', '2', '3', '4', '5', '6', '7', '8', '9'];

const AddEditNarratives = (props) => {
  /** region Component Variables and Props */
  const classes = useStyles();
  const {
    history,
    setMenuType,
    setHandleClose,
    narrativeForm,
    updateIncNarrativeForm,
    wsClient,
    selectedEntity,
    selectedIncident,
    narratives,
    codeNarrativeTypes,
  } = props;
  const dispatch = useDispatch();
  const [pageType, setPageType] = React.useState('add');
  const [narrativeBodyState, setNarrativeBodyState] = React.useState();
  const [narrativeFormState, setNarrativeFormState] = React.useState({
    tempNarrativeId: uuidv4(),
    isValid: false,
    changes: false,
    values: {},
    touched: {},
    errors: {},
  });
  /** endregion */
  /** region Hooks */
  React.useEffect(() => {
    setMenuType('forms');
    setHandleClose('incForms', history);
  }, []);

  React.useEffect(() => {
    const parts = history.location.pathname.split('/');
    if (parts[2] === 'add') narrativeForm.values.sequence = narratives.length + 1;
    setPageType(parts[2]);
  }, [narratives.length]);

  React.useEffect(() => {
    if (!wsClient) {
      return;
    }

    const getCode = async () => {
      const result = await wsClient.service('codenarrativetypes').find({
        query: {
          $limit: 0,
          $select: ['Code', 'Description'],
          $sort: {
            Description: 1,
          },
          IsDeleted: false,
        },
      });

      dispatch(getCodeNarrativeTypes(result.data.length > 0 ? result.data : []));
    };

    getCode();
  }, [wsClient]);

  React.useEffect(() => {
    setNarrativeBodyState(narrativeForm.values.narrativeBody);
    setNarrativeFormState((narrativeFormState) => ({
      ...narrativeFormState,
      ...(narrativeFormState.values.incidentId = narrativeForm.values.incidentId),
      ...(narrativeFormState.values.narrativeId = narrativeForm.values.narrativeId),
      ...(narrativeFormState.values.narrativeTitle = narrativeForm.values.narrativeTitle),
      ...(narrativeFormState.values.sequence = narrativeForm.values.sequence),
      ...(narrativeFormState.values.narrativeBody = narrativeForm.values.narrativeBody),
      ...(narrativeFormState.values.narrativeType = narrativeForm.values.narrativeType),
      ...(narrativeFormState.values.approvedDT = narrativeForm.values.approvedDT),
      ...(narrativeFormState.values.approvedBy = narrativeForm.values.approvedBy),
      ...(narrativeFormState.values.narrativeDT = narrativeForm.values.narrativeDT),
      ...(narrativeFormState.values.entryClerk = narrativeForm.values.entryClerk),
      ...(narrativeFormState.values.author = narrativeForm.values.author),
      ...(narrativeFormState.values.authorId = narrativeForm.values.authorId),
      ...(narrativeFormState.values.attachments = narrativeForm.values.attachments),
    }));
  }, [narrativeForm]);

  React.useEffect(() => {
    setNarrativeFormState({ ...narrativeFormState, changes: true });
    updateNarrativeFormState();
    updateIncNarrativeForm(narrativeFormState);
  }, [
    narrativeFormState.values.narrativeTitle,
    narrativeFormState.values.narrativeBody,
    narrativeFormState.values.narrativeType,
    narrativeFormState.values.approvedDT,
    narrativeFormState.values.narrativeDT,
    narrativeFormState.values.approvedBy,
    narrativeFormState.values.entryClerk,
    narrativeFormState.values.author,
    narrativeFormState.values.authorId,
    narrativeFormState.values?.attachments,
    narrativeBodyState,
  ]);

  React.useEffect(() => {
    if (!selectedIncident || !selectedEntity) {
      return;
    }
    const { ptsNarrativeId } = selectedEntity;
    dispatch(setCircularLoading(true));

    if (!narrativeForm.values.narrativeId && narratives && narratives.length > 0) {
      narratives.map((n) => {
        if (n.narrativeDetail.ptsNarrativeId === ptsNarrativeId) {
          let narrativeDetails = {
            values: {
              narrativeId: ptsNarrativeId,
              narrativeTitle: n.narrativeDetail?.values?.narrativeTitle,
              narrativeType: n.narrativeDetail?.values?.narrativeType,
              sequence: n.narrativeDetail?.values?.sequence,
              narrativeBody: n.narrativeDetail?.values?.narrativeBody,
              approvedDT: n.narrativeDetail?.values?.approvedDT,
              narrativeDT: n.narrativeDetail?.values?.narrativeDT,
              approvedBy: n.narrativeDetail?.values?.approvedBy,
              entryClerk: n.narrativeDetail?.values?.entryClerk,
              authorId: n.narrativeDetail?.values?.authorId,
              author: n.narrativeDetail?.values?.author,
            },
          };
          updateIncNarrativeForm(narrativeDetails);
        }
      });
    } else if (!narrativeForm.values.narrativeId && pageType === 'edit') {
      const service = wsClient.service('rms-incident');
      service.timeout = 20000;
      service
        .find({
          query: {
            ptsIncidentID: selectedIncident,
            $select: ['ptsIncidentID'],
            narrative: true,
          },
        })
        .then(({ data }) => {
          if (!data.length) return;
          const narrative = data[0].Narrative.filter((e) => e.ptsNarrativeId === ptsNarrativeId)[0];

          let narrativeDetails = {
            ptsNarrativeId: ptsNarrativeId,
            values: {
              incidentId: narrative?.ptsParentID,
              narrativeId: ptsNarrativeId,
              narrativeTitle: narrative?.NarrativeTitle,
              narrativeType: narrative?.NarrativeType,
              sequence: narrative?.NarrativeSequence,
              narrativeBody: narrative?.NarrativeBody,
              approvedDT: narrative?.ApprovedDT,
              narrativeDT: narrative?.NarrativeDT,
              approvedBy: narrative?.ApprovedBy,
              authorId: narrative.Author?.ptsPersonID,
              author: narrative.Author?.FullName,
            },
          };

          updateIncNarrativeForm(narrativeDetails);
          dispatch(setCircularLoading(false));
        });
    }

    dispatch(setCircularLoading(false));
  }, [wsClient, selectedEntity, selectedIncident]);

  React.useEffect(() => {
    if (!wsClient) {
      return;
    }

    const getAttachments = async () => {
      const service = wsClient.service('rms-attachments');
      service.timeout = 200000;

      const result = await service.find({
        query: {
          id: narrativeFormState.values.narrativeId,
          type: 'NARRATIVE',
        },
      });
      let atts = [];
      result.map((r) => {
        atts.push({
          ptsAttachmentId: r.ptsAttachmentID,
          name: r.FileName,
          type: r.FileType,
          dataURL: r.FileObject,
        });
      });
      setNarrativeFormState((stateValue) => ({
        ...stateValue,
        values: {
          ...stateValue.values,
          attachments: atts,
        },
      }));
    };

    if (narrativeFormState.values.narrativeId) {
      getAttachments();
    }
  }, [wsClient, narrativeFormState.values.narrativeId]);
  /** endregion */
  /** region Helper Functions */
  const updateNarrativeFormState = () => {
    setNarrativeFormState((narrativeFormState) => ({
      ...narrativeFormState,
      ...(narrativeFormState.values.narrativeBody = narrativeBodyState),
    }));
  };

  const changeApprovedBy = (id, name) => {
    setNarrativeFormState((narrativeFormState) => ({
      ...narrativeFormState,
      ...(narrativeFormState.values.approvedBy = name),
    }));
  };

  const changeEntryClerk = (id, name) => {
    setNarrativeFormState((narrativeFormState) => ({
      ...narrativeFormState,
      ...(narrativeFormState.values.entryClerk = name),
    }));
  };

  const changeAuthor = (id, name) => {
    setNarrativeFormState((narrativeFormState) => ({
      ...narrativeFormState,
      ...(narrativeFormState.values.authorId = id),
      ...(narrativeFormState.values.author = name),
    }));
  };
  /** endregion */
  return (
    <div className={classes.root}>
      <RMSFabButtons />
      <Grid container>
        <Grid xs={4} lg={4}>
          <DNDList history={history} />
        </Grid>
        <Grid xs={8} lg={8}>
          <Paper className={classes.paper}>
            <div
              style={{
                backgroundColor: '#1976d2',
                borderRadius: '0.5rem 0.5rem 0 0',
              }}>
              <Tabs
                indicatorColor="secondary"
                value={0}
                variant="fullWidth"
                classes={{
                  indicator: classes.indicator,
                }}>
                <Tab
                  className={classes.icons}
                  label="Narrative"
                  style={{ color: 'white', padding: '26px' }}
                />
              </Tabs>
              <Divider />
            </div>
            <PerfectScrollbar className={classes.scrollBar}>
              <Grid item xs={12} lg={12} className="pt-3">
                <TabPanel value={0} index={0}>
                  <Grid container spacing={2} justify="flex-start" style={{ padding: '5px 20px' }}>
                    <Grid item xs={12} className="">
                      <span style={{ color: '#fff', background: '#1976d2' }} className="mb-1 badge">
                        Narrative Details
                      </span>
                      <Divider />
                    </Grid>
                    <Grid item xs={3} lg={3}>
                      <TextField
                        autoComplete="hidden"
                        autoSelect
                        size="small"
                        id="narrative-title-textfield"
                        label="Narrative Title"
                        fullWidth
                        variant="outlined"
                        name="narrativeTitle"
                        autoHighlight
                        className={classes.input}
                        value={narrativeFormState.values.narrativeTitle || ''}
                        onChange={(event) =>
                          handleFormChangeNew(
                            event,
                            event.target.value,
                            'none',
                            'narrativeTitle',
                            setNarrativeFormState
                          )
                        }
                      />
                    </Grid>
                    <Grid item xs={3} lg={3}>
                      <Autocomplete
                        size="small"
                        autoComplete
                        autoSelect
                        autoHighlight
                        id="combo-search-offenses"
                        className={classes.input}
                        getOptionLabel={(option) => option || ''}
                        options={codeNarrativeTypes.map((s) => s.Code)}
                        key={narrativeFormState.values.narrativeType || ''}
                        value={narrativeFormState.values.narrativeType || ''}
                        inputValue={narrativeFormState.values.narrativeType || ''}
                        onChange={(event, newValue) =>
                          handleFormChangeNew(
                            event,
                            newValue,
                            'none',
                            'narrativeType',
                            setNarrativeFormState,
                            'name'
                          )
                        }
                        onInputChange={(event, newValue, reason) =>
                          handleFormChangeNew(
                            event,
                            newValue,
                            reason,
                            'narrativeType',
                            setNarrativeFormState,
                            'name'
                          )
                        }
                        renderInput={(params) => (
                          <TextField
                            autoComplete="hidden"
                            size="small"
                            {...params}
                            label="Narrative Type"
                            variant="outlined"
                            fullWidth
                          />
                        )}
                      />
                    </Grid>

                    <Grid item xs={3} lg={3}>
                      <Autocomplete
                        size="small"
                        autoComplete
                        autoSelect
                        autoHighlight
                        id="combo-search-offenses"
                        className={classes.input}
                        options={options}
                        key={narrativeFormState.values.sequence || ''}
                        value={narrativeFormState.values.sequence || ''}
                        inputValue={narrativeFormState.values.sequence || ''}
                        onChange={(event, newValue) =>
                          handleFormChangeNew(
                            event,
                            newValue,
                            'none',
                            'sequence',
                            setNarrativeFormState,
                            'name'
                          )
                        }
                        onInputChange={(event, newValue, reason) =>
                          handleFormChangeNew(
                            event,
                            newValue,
                            reason,
                            'sequence',
                            setNarrativeFormState,
                            'name'
                          )
                        }
                        renderInput={(params) => (
                          <TextField
                            autoComplete="hidden"
                            size="small"
                            {...params}
                            label="Sequence"
                            variant="outlined"
                            fullWidth
                          />
                        )}
                      />
                    </Grid>

                    <Grid item xs={3} lg={3}>
                      <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <KeyboardDateTimePicker
                          size="small"
                          inputVariant="outlined"
                          fullWidth
                          showTodayButton={true}
                          variant="dialog"
                          format="MM/dd/yyyy hh:mm a"
                          id="date-picker-inline-from"
                          label="Narrative DT"
                          value={
                            narrativeFormState.values.narrativeDT
                              ? narrativeFormState.values.narrativeDT
                              : null
                          }
                          onChange={(date) => {
                            setNarrativeFormState((state) => {
                              return {
                                ...state,
                                values: {
                                  ...state.values,
                                  narrativeDT: date,
                                },
                              };
                            });
                          }}
                          KeyboardButtonProps={{
                            'aria-label': 'change date',
                          }}
                        />
                      </MuiPickersUtilsProvider>
                    </Grid>

                    <Grid item>
                      {narrativeForm.values.narrativeId ? (
                        narrativeBodyState !== null ? (
                          narrativeBodyState && (
                            <RMSEditor
                              narrativeBody={narrativeBodyState}
                              setNarrativeBodyState={setNarrativeBodyState}
                            />
                          )
                        ) : (
                          <RMSEditor
                            narrativeBody={narrativeBodyState}
                            setNarrativeBodyState={setNarrativeBodyState}
                          />
                        )
                      ) : (
                        <RMSEditor
                          narrativeBody={narrativeBodyState}
                          setNarrativeBodyState={setNarrativeBodyState}
                        />
                      )}
                    </Grid>

                    <Grid item xs={12} className="">
                      <span style={{ color: '#fff', background: '#1976d2' }} className="mb-1 badge">
                        Author
                      </span>
                      <Divider />
                    </Grid>

                    <Grid item xs={6} lg={6}>
                      <RMSPersonSearch
                        setName={changeAuthor}
                        placeholder="Search Person for Author"
                      />
                    </Grid>

                    <Grid item xs={6} lg={6}>
                      <TextField
                        autoComplete="hidden"
                        autoSelect
                        size="small"
                        id="narrative-approved-by-textfield"
                        label="Author"
                        fullWidth
                        variant="outlined"
                        name="author"
                        autoHighlight
                        className={classes.input}
                        value={narrativeFormState.values.author || ''}
                        onChange={(event) =>
                          handleFormChangeNew(
                            event,
                            event.target.value,
                            'none',
                            'author',
                            setNarrativeFormState
                          )
                        }
                      />
                    </Grid>

                    <Grid item xs={12} className="">
                      <span style={{ color: '#fff', background: '#1976d2' }} className="mb-1 badge">
                        Entry Clerk
                      </span>
                      <Divider />
                    </Grid>

                    <Grid item xs={6} lg={6}>
                      <RMSPersonSearch
                        setName={changeEntryClerk}
                        placeholder="Search Person for Entry Clerk"
                      />
                    </Grid>

                    <Grid item xs={6} lg={6}>
                      <TextField
                        autoComplete="hidden"
                        autoSelect
                        size="small"
                        id="narrative-approved-by-textfield"
                        label="Entry Clerk"
                        fullWidth
                        variant="outlined"
                        name="entryClerk"
                        autoHighlight
                        className={classes.input}
                        value={narrativeFormState.values.entryClerk || ''}
                        onChange={(event) =>
                          handleFormChangeNew(
                            event,
                            event.target.value,
                            'none',
                            'entryClerk',
                            setNarrativeFormState
                          )
                        }
                      />
                    </Grid>

                    <Grid item xs={12} className="">
                      <span style={{ color: '#fff', background: '#1976d2' }} className="mb-1 badge">
                        Approved Details
                      </span>
                      <Divider />
                    </Grid>

                    <Grid item xs={6} lg={6}>
                      <RMSPersonSearch
                        setName={changeApprovedBy}
                        placeholder="Search Person for Approved By"
                      />
                    </Grid>

                    <Grid item xs={3} lg={3}>
                      <TextField
                        autoComplete="hidden"
                        autoSelect
                        size="small"
                        id="narrative-approved-by-textfield"
                        label="Approved By"
                        fullWidth
                        variant="outlined"
                        name="approvedBy"
                        autoHighlight
                        className={classes.input}
                        value={narrativeFormState.values.approvedBy || ''}
                        onChange={(event) =>
                          handleFormChangeNew(
                            event,
                            event.target.value,
                            'none',
                            'approvedBy',
                            setNarrativeFormState
                          )
                        }
                      />
                    </Grid>

                    <Grid item xs={3} lg={3}>
                      <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <KeyboardDateTimePicker
                          size="small"
                          inputVariant="outlined"
                          fullWidth
                          showTodayButton={true}
                          variant="dialog"
                          format="MM/dd/yyyy hh:mm a"
                          id="date-picker-inline-from"
                          label="Approved DT"
                          value={
                            narrativeFormState.values.approvedDT
                              ? narrativeFormState.values.approvedDT
                              : null
                          }
                          onChange={(date) => {
                            setNarrativeFormState((state) => {
                              return {
                                ...state,
                                values: {
                                  ...state.values,
                                  approvedDT: date,
                                },
                              };
                            });
                          }}
                          KeyboardButtonProps={{
                            'aria-label': 'change date',
                          }}
                        />
                      </MuiPickersUtilsProvider>
                    </Grid>
                    <Grid item xs={12}>
                      <RMSAttachments
                        attachments={narrativeFormState.values.attachments}
                        stateHolder={narrativeFormState}
                        setStateHolder={setNarrativeFormState}
                        setField={handleFormChangeNew}
                      />
                    </Grid>
                  </Grid>
                </TabPanel>
              </Grid>
            </PerfectScrollbar>
          </Paper>
        </Grid>
      </Grid>
    </div>
  );
};

const mapStateToProps = (state) => ({
  narrativeForm: state.incident.narrativeForm,
  wsClient: state.websocket.websocket,
  narratives: state.incident.narratives,
  selectedEntity: state.incident.selectedEntity,
  selectedIncident: state.incident.ptsIncidentId,
  codeNarrativeTypes: state.codeNarrativeTypes,
});

export default connect(mapStateToProps, { setHandleClose, setMenuType, updateIncNarrativeForm })(
  AddEditNarratives
);
