import React from 'react';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import TextField from '@material-ui/core/TextField';
import { connect } from 'react-redux';
import { updateIncPartiesOthersForm } from '../../reducers/IncReducer';
import { handleFormChangeNew } from '../../reducers/helpers/formHelpers';

const TabPartiesOthers = (props) => {
  /** region Component Variables and Props */
  const { partiesOthersForm, updateIncPartiesOthersForm } = props;
  /** endregion */
  /** region React Hooks */
  const [changeKey, setChangeKey] = React.useState(0);
  const [partiesOthersFormState, setPartiesOthersFormState] = React.useState({
    isValid: false,
    values: {},
    touched: {},
    errors: {},
  });
  React.useEffect(() => {
    setPartiesOthersFormState((partiesOthersFormState) => ({
      ...partiesOthersFormState,
      ...(partiesOthersFormState.values.incidentId = partiesOthersForm.values.incidentId),
      ...(partiesOthersFormState.values.personId = partiesOthersForm.values.personId),
      ...(partiesOthersFormState.values.credentialId = partiesOthersForm.values.credentialId),
      ...(partiesOthersFormState.values.generalAppearance =
        partiesOthersForm.values.generalAppearance),
      ...(partiesOthersFormState.values.personNotes = partiesOthersForm.values.personNotes),
    }));
  }, [partiesOthersForm]);
  React.useEffect(() => {
    updateIncPartiesOthersForm(partiesOthersFormState);
  }, [
    partiesOthersFormState.values.incidentId,
    partiesOthersFormState.values.personId,
    partiesOthersFormState.values.credentialId,
    partiesOthersFormState.values.generalAppearance,
    partiesOthersFormState.values.personNotes,
  ]);
  /** endregion */
  return (
    <Grid container xs={12} className="p-3">
      <Grid item xs={12} className="mb-2">
        <span style={{ color: '#fff', background: '#1976d2' }} className="mb-1 badge badge-primary">General Appearance Details</span>
        <Divider />
      </Grid>

      <Grid item xs={12}>
        <TextField
          autoComplete="hidden"
          size="small"
          fullWidth
          id="outlined-multiline-static"
          label="General Appearance"
          placeholder="General Appearance"
          rows="15"
          name="generalAppearance"
          key={changeKey}
          defaultValue={partiesOthersFormState.values.generalAppearance}
          onBlur={(event) =>
            handleFormChangeNew(
              event,
              event.target.value,
              'none',
              'generalAppearance',
              setPartiesOthersFormState
            )
          }
          variant="outlined"
          multiline
          InputLabelProps={{ shrink: true }}
        />
      </Grid>

      <Grid item xs={12} className="mb-2 mt-2">
        <span style={{ color: '#fff', background: '#1976d2' }} className="mb-1 badge badge-primary">Person Notes</span>
        <Divider />
      </Grid>

      <Grid item xs={12}>
        <TextField
          id="standard-textarea-partiesOthers-personNotes"
          autoComplete="hidden"
          size="small"
          fullWidth
          placeholder="Notes"
          label="Notes"
          rows="15"
          name="personNotes"
          key={changeKey}
          defaultValue={partiesOthersFormState.values.personNotes}
          onBlur={(event) =>
            handleFormChangeNew(
              event,
              event.target.value,
              'none',
              'personNotes',
              setPartiesOthersFormState
            )
          }
          variant="outlined"
          multiline
          InputLabelProps={{ shrink: true }}
        />
      </Grid>
    </Grid>
  );
};

const mapStateToProps = (state) => ({
  partiesOthersForm: state.incident.partiesOthersForm,
});

export default connect(mapStateToProps, { updateIncPartiesOthersForm })(TabPartiesOthers);
