/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { connect } from 'react-redux';

import Grid from '@material-ui/core/Grid';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';

import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { handleFormChangeNew } from '../../../../reducers/helpers/formHelpers';
import { updateIncOffensesOffenseForm, updateIncOffenses } from '../../../../reducers/IncReducer';
import { codeLocationCategories } from '../../../../reducers/_dev/codeLocationCategories';

import { validateOffenseForm } from 'simpl-schema-validation/ValidateForms';
import { FormHelperText } from '@material-ui/core';
import makeStyles from '@material-ui/core/styles/makeStyles';

const methodsEntry = [
  { code: 'F', description: 'Force' },
  { code: 'N', description: 'No Force' },
];

const useStyles = makeStyles((theme) => ({
  label: {
    color: 'red',
  },
}));

const OffenseDetailsForm = (props) => {
  /** region Component Variables and Props */
  const { offensesOffenseForm, updateIncOffensesOffenseForm, updateIncOffenses } = props;
  /** endregion */
  /** region React Hooks*/
  const [errors, setErrors] = React.useState({});
  const [changeKey, setChangeKey] = React.useState(0);
  const [offensesOffenseFormState, setOffensesOffenseFormState] = React.useState({
    isValid: false,
    changes: false,
    values: {},
    touched: {},
    errors: {},
  });

  const classes = useStyles();

  React.useEffect(() => {
    setOffensesOffenseFormState({
      ...offensesOffenseFormState,
      ...offensesOffenseForm,
      ptsOffenseId: offensesOffenseForm.ptsOffenseId,
      values: { ...offensesOffenseFormState.values, ...offensesOffenseForm.values },
    });
  }, [offensesOffenseForm]);

  React.useEffect(() => {
    let timeoutId;
    offensesOffenseFormState.changes = true;
    updateIncOffenses(offensesOffenseFormState);
    updateIncOffensesOffenseForm(offensesOffenseFormState);

    if (offensesOffenseFormState.values.statuteDetails) {
      timeoutId = setTimeout(() => {
        let errorStatus = errors;
        let nibrErrorStructuredEntered = validateOffenseForm('structuresEntered');
        if (nibrErrorStructuredEntered && nibrErrorStructuredEntered.length) {
          errorStatus = {
            ...errorStatus,
            structuresEntered: `${nibrErrorStructuredEntered[0].message}`,
          };
        } else {
          errorStatus = {
            ...errorStatus,
            structuresEntered: null,
          };
        }
        let nibrErrorLocationCategory = validateOffenseForm('locationCategory');
        if (nibrErrorLocationCategory && nibrErrorLocationCategory.length) {
          errorStatus = {
            ...errorStatus,
            locationCategory: `${nibrErrorLocationCategory[0].message}`,
          };
        } else {
          errorStatus = {
            ...errorStatus,
            locationCategory: null,
          };
        }
        let nibrErrorMethodOfEntry = validateOffenseForm('methodOfEntry');
        if (nibrErrorMethodOfEntry && nibrErrorMethodOfEntry.length) {
          errorStatus = {
            ...errorStatus,
            methodOfEntry: `${nibrErrorMethodOfEntry[0].message}`,
          };
        } else {
          errorStatus = {
            ...errorStatus,
            methodOfEntry: null,
          };
        }
        let nibrErrorOffenseAttempted = validateOffenseForm('offenseAttempted');
        if (nibrErrorOffenseAttempted && nibrErrorOffenseAttempted.length) {
          errorStatus = {
            ...errorStatus,
            offenseAttempted: `${nibrErrorOffenseAttempted[0].message}`,
          };
        } else {
          errorStatus = {
            ...errorStatus,
            offenseAttempted: null,
          };
        }
        setErrors(errorStatus);
      }, 2000);
    }
    return () => clearTimeout(timeoutId);
  }, [
    offensesOffenseFormState.values.statuteDetails,
    offensesOffenseFormState.values.offenseAttempted,
    offensesOffenseFormState.values.structuresEntered,
    offensesOffenseFormState.values.structuresOccupied,
    offensesOffenseFormState.values.methodOfEntry,
    offensesOffenseFormState.values.locationCategory,
  ]);
  /** endregion */
  return (
    <Grid xs={12} container className="mt-2">
      <Grid item xs={12} spacing={2} className="d-flex">
        <Grid xs={4} lg={2}>
          <FormControlLabel
            control={
              <Checkbox
                color={errors['offenseAttempted'] ? 'error' : 'primary'}
                key={offensesOffenseFormState.values.offenseAttempted}
                checked={offensesOffenseFormState.values.offenseAttempted}
                onChange={(event) =>
                  handleFormChangeNew(
                    event,
                    event.target.checked,
                    'none',
                    'offenseAttempted',
                    setOffensesOffenseFormState
                  )
                }
                name="offenseAttempted"
              />
            }
            className={errors['offenseAttempted'] ? classes.label : ''}
            label="Offense Attempted"
          />
          {errors['offenseAttempted'] && (
            <FormHelperText error>{errors['offenseAttempted']}</FormHelperText>
          )}
        </Grid>
        <Grid xs={8} lg={10}>
          <Autocomplete
            size="small"
            autoComplete
            autoSelect
            autoHighlight
            id="combo-location-category"
            getOptionLabel={(option) => option || ''}
            options={codeLocationCategories.map((s) => s.description)}
            key={offensesOffenseFormState.values.locationCategory || ''}
            value={offensesOffenseFormState.values.locationCategory || ''}
            onChange={(event, newValue) =>
              handleFormChangeNew(
                event,
                newValue,
                'none',
                'locationCategory',
                setOffensesOffenseFormState
              )
            }
            renderInput={(params) => (
              <TextField
                autoComplete="hidden"
                size="small"
                {...params}
                label="Location Category"
                variant="outlined"
                fullWidth
                error={errors['locationCategory'] ? true : false}
                helperText={errors['locationCategory']}
              />
            )}
          />
        </Grid>
      </Grid>
      <Grid
        container
        spacing={1}
        xs={12}
        lg={12}
        style={{ justifyContent: 'space-between' }}
        className="mt-2">
        <Grid item xs={4} sm={3} lg={2}>
          <FormControlLabel
            control={
              <Checkbox
                key={offensesOffenseFormState.values.structuresOccupied}
                checked={offensesOffenseFormState.values.structuresOccupied}
                onChange={(event) =>
                  handleFormChangeNew(
                    event,
                    event.target.checked,
                    'none',
                    'structuresOccupied',
                    setOffensesOffenseFormState
                  )
                }
                name="structuresOccupied"
              />
            }
            label="Structures Occupied"
          />
        </Grid>
        <Grid item xs={4} sm={4} lg={4}>
          <TextField
            autoComplete="hidden"
            size="small"
            label="Structures Entered"
            variant="outlined"
            fullWidth
            name="structuresEntered"
            key={changeKey}
            value={offensesOffenseFormState.values.structuresEntered || ''}
            onChange={(event) =>
              handleFormChangeNew(
                event,
                event.target.value,
                'none',
                'structuresEntered',
                setOffensesOffenseFormState
              )
            }
            error={errors['structuresEntered'] ? true : false}
            helperText={errors['structuresEntered']}
          />
        </Grid>
        <Grid item xs={4} sm={5} lg={6}>
          <Autocomplete
            size="small"
            autoComplete
            autoSelect
            autoHighlight
            id="combo-methods-entry"
            getOptionLabel={(option) => option || ''}
            options={methodsEntry.map((s) => s.description)}
            key={offensesOffenseFormState.values.methodOfEntry || ''}
            value={offensesOffenseFormState.values.methodOfEntry || ''}
            onChange={(event, newValue) =>
              handleFormChangeNew(
                event,
                newValue,
                'none',
                'methodOfEntry',
                setOffensesOffenseFormState
              )
            }
            renderInput={(params) => (
              <TextField
                style={{ marginLeft: '8px' }}
                autoComplete="hidden"
                size="small"
                {...params}
                label="Method of Entry"
                variant="outlined"
                fullWidth
                error={errors['methodOfEntry'] ? true : false}
                helperText={errors['methodOfEntry']}
              />
            )}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

const mapStateToProps = (state) => ({
  offensesOffenseForm: state.incident.offensesOffenseForm,
  currentOffense: state.incident.currentOffense,
  offenses: state.incident.offenses,
  selectedEntity: state.incident.selectedEntity,
});

export default connect(mapStateToProps, { updateIncOffensesOffenseForm, updateIncOffenses })(
  OffenseDetailsForm
);
