export const GET_CODE_STATE = 'GET_CODE_STATE';
export const ADD_CODE_STATE = 'ADD_CODE_STATE';

export const addCodeStates = (newVal) => {
  return { type: ADD_CODE_STATE, payload: newVal };
};

export const getCodeStates = (state) => {
  return async (dispatch) => {
    return dispatch({ type: GET_CODE_STATE, payload: state });
  };
};

export default function reducer(state = [], action) {
  switch (action.type) {
    case GET_CODE_STATE:
      state = action.payload;
      return state;
    case ADD_CODE_STATE:
      return [...state, action.payload];
    default:
      return state;
  }
}
