import { Fab } from '@material-ui/core';
import React from 'react';

export default function RMSDomesticViolenceButton() {
  return (
    <Fab size="small" color="secondary" aria-label="edit">
      DV
    </Fab>
  );
}
