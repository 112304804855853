import React from 'react';
import Grid from '@material-ui/core/Grid';
import RMSSelectButton from '../../components/RMSButtons/RMSSelectButton';
import RMSAddButton from '../../components/RMSButtons/RMSAddButton';
import PerfectScrollbar from 'react-perfect-scrollbar';
import RMSSimpleList from '../../components/RMSList/RMSSimpleList';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { colors } from '../../config/colorVariables';
import Avatar from '@material-ui/core/Avatar';
import clsx from 'clsx';
import { OffenderAvatar } from './TabOffenders';
import { connect } from 'react-redux';
import {
  setListArrestees,
  setSelectedArrestee,
  removeIncArresteeDetails,
  updateIncArresteeDetails,
  updateIncPartiesPersonForm,
  setIncArresteeDetails,
} from '../../reducers/IncReducer';
import { v4 as uuidv4 } from 'uuid';
import {
  setHandleAddIncArrestee,
  setHandleOffensesRedirectFormClose,
} from '../../reducers/ui/UiMenuReducer';
import DialogList from '../../components/RMSList/RMSDialogList';

const useStyles = makeStyles((theme) => ({
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: colors.cyan,
    marginRight: theme.spacing(2),
  },
  female: {
    margin: theme.spacing(1),
    backgroundColor: colors.pink,
    marginRight: theme.spacing(2),
  },
  property: {
    margin: theme.spacing(1),
    backgroundColor: colors.indigo,
    marginRight: theme.spacing(2),
  },
  victim: {
    margin: theme.spacing(1),
    backgroundColor: colors.orange,
    marginRight: theme.spacing(2),
  },
  detailsPanel: {
    padding: theme.spacing(4),
  },
}));

export const ArresteeAvatar = (props) => {
  const { label } = props;
  const classes = useStyles();

  return (
    <Avatar
      className={clsx(
        label === 'M' && classes.avatar,
        label === 'F' && classes.female,
        label === 'P' && classes.property,
        label === 'V' && classes.victim
      )}>
      {label}
    </Avatar>
  );
};

const TabArrestees = (props) => {
  /** region Component Variables and Props */
  const classes = useStyles();
  const {
    history,
    incArrestees,
    listArrestees,
    listDialogArrestees,
    parties,
    selectedArrestee,
    setHandleOffensesRedirectFormClose,
    setHandleAddIncArrestee,
    setListArrestees,
    setSelectedArrestee,
    removeIncArresteeDetails,
    offensesOffenseForm,
    updateIncPartiesPersonForm,
  } = props;
  const [changeKey, setChangeKey] = React.useState(0);
  const [dialogBox, setDialogBox] = React.useState(false);

  const [offensesArresteesFormState, setOffensesArresteesFormState] = React.useState({
    tempIncArresteeId: uuidv4(),
    isValid: false,
    values: {},
    touched: {},
    errors: {},
  });
  /** endregion */
  /** region React Hooks */
  React.useEffect(() => {
    setHandleOffensesRedirectFormClose(false);
    createIncArresteeList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [incArrestees, parties, incArrestees.length]);

  React.useEffect(() => {
    createIncArresteeList();
    if (offensesOffenseForm.ptsOffenseId) {
      setOffensesArresteesFormState({
        ...offensesArresteesFormState,
        ptsOffenseId: offensesOffenseForm.ptsOffenseId,
      });
    } else {
      setOffensesArresteesFormState({
        ...offensesArresteesFormState,
        tempOffenseId: offensesOffenseForm.tempOffenseId,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  /** endregion */
  /** region Helper Functions */
  const createIncArresteeList = () => {
    if (incArrestees !== undefined && incArrestees.length > 0) {
      let newList = [];
      let arrestee = {};

      incArrestees.map((s) => {
        let party;
        if (!s.incArresteeDetails.ptsIncPersonId) {
          party = parties.find((x) => x.tempPartyId === s.incArresteeDetails.tempPartyId);
        } else {
          party = parties.find((x) => x.ptsIncPersonId === s.incArresteeDetails.ptsIncPersonId);
        }

        if (party) {
          arrestee = {
            id: party?.ptsIncPersonId || party?.personDetail.tempPartyId,
            name: `${party?.personDetail.values.firstName}${
              party?.personDetail.values.middleName
                ? ` ${party?.personDetail.values.middleName} `
                : ' '
            }${party?.personDetail.values.lastName}`,
            icon: (
              <OffenderAvatar
                label={
                  party?.personDetail.values.sex === 'MALE'
                    ? 'M'
                    : party?.personDetail.values.sex === 'FEMALE'
                    ? 'F'
                    : 'U'
                }
              />
            ),
            age: party?.personDetail.values.age,
            ethnicity: party?.personDetail.values.ethnicity,
            type: 'person',
          };
          newList.push(arrestee);
        }
        if (newList.length === 1) {
          setSelectedArrestee(arrestee.id);
        }
      });
      setListArrestees(newList);
      setChangeKey(newList.length);
    }
  };

  const handleIncArresteeAdd = () => {
    setHandleOffensesRedirectFormClose(true);
    updateIncPartiesPersonForm({ isValid: false, values: {}, touched: {}, errors: {} });
    setHandleAddIncArrestee(true);
    history.push('/incidents/add/arrestees');
  };

  const handleIncArresteeSelect = () => {
    if (listDialogArrestees.length > 0) {
      setDialogBox(true);
    }
  };

  const handleIncArresteeDelete = () => {
    let updatedList = listArrestees.filter((s) => s.id !== selectedArrestee);
    setListArrestees(updatedList);
    removeIncArresteeDetails();
    setSelectedArrestee(updatedList[0] ? updatedList[0].id : '');
  };

  const closeDialogBox = () => {
    createIncArresteeList();
    setDialogBox(false);
  };
  /** endregion */
  return (
    <Grid container>
      <Grid container spacing={2} className="mb-3">
        <Grid item className="mr-2 ml-2">
          <RMSAddButton onClick={handleIncArresteeAdd} />
        </Grid>
        <Grid item className="mr-1">
          <DialogList
            dialogBox={dialogBox}
            title="Incident Arrestee List"
            type="arrestees"
            list={[]}
            addToSimpleList={createIncArresteeList}
            closeDialogBox={closeDialogBox}
          />

          <RMSSelectButton lists={listDialogArrestees} onClick={handleIncArresteeSelect} />
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={12} lg={12}>
          <PerfectScrollbar style={{ height: '99%' }} className="scroll-area-lg">
            <RMSSimpleList
              key={changeKey}
              listItems={listArrestees}
              entity="arrestees"
              deleteFromList={handleIncArresteeDelete}
            />
          </PerfectScrollbar>
        </Grid>
      </Grid>
    </Grid>
  );
};

const mapStateToProps = (state) => ({
  offensesOffenseForm: state.incident.offensesOffenseForm,
  offensesArresteesForm: state.incident.offensesArresteesForm,
  incArrestees: state.incident.incArrestees,
  listArrestees: state.incident.listArrestees,
  listDialogArrestees: state.incident.listDialogArrestees,
  parties: state.incident.parties,
  selectedArrestee: state.incident.selectedArrestee,
  wsClient: state.websocket.websocket,
  selectedEntity: state.incident.selectedEntity,
  selectedIncident: state.incident.ptsIncidentId,
});

export default connect(mapStateToProps, {
  setHandleOffensesRedirectFormClose,
  setHandleAddIncArrestee,
  setListArrestees,
  setSelectedArrestee,
  removeIncArresteeDetails,
  updateIncArresteeDetails,
  updateIncPartiesPersonForm,
  setIncArresteeDetails,
})(TabArrestees);
