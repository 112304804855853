import { fields } from './_dev/recordFilterFields';
// import { notify } from '../reducers/NotifierReducer';
import {
  upsertPerson,
  addPersonCredential,
  updatePersonCredential,
  addPersonContactMethod,
  updatePersonContactMethod,
  addPersonDNASample,
  updatePersonDNASample,
  upsertPersonEmployment,
  addPersonFeature,
  updatePersonFeature,
  addPersonVehicleRegistration,
  updatePersonVehicleRegistration,
  addPersonName,
  updatePersonName,
  addPersonPhoto,
  upsertPersonAssociate,
  addPersonAddress,
  updatePersonAddress,
  upsertPersonResource,
  removeRecordEntity,
  updatePersonPhoto,
  addPlace,
  updatePlace,
} from './helpers/recordHelper';

export const FORM_DATA = 'RECORD/FORM_DATA';
export const SET_RECORD_ACTIVE = 'RECORD/SET_RECORD_ACTIVE';
export const SET_RECORD_ADVANCE_SEARCH_KEYWORD = 'RECORD/SET_RECORD_ADVANCE_SEARCH_KEYWORD';
export const SET_RECORD_TYPE = 'RECORD/SET_RECORD_TYPE';

export const setFormData = (payload) => {
  return async (dispatch) => {
    dispatch({ type: FORM_DATA, payload });
  };
};

export const setRecordActive = (payload) => {
  return async (dispatch) => {
    dispatch({ type: SET_RECORD_ACTIVE, payload });
  };
};

export const setRecordAdvanceSearchKeyword = (payload) => {
  return async (dispatch) => {
    dispatch({ type: SET_RECORD_ADVANCE_SEARCH_KEYWORD, payload });
  };
};

export const removeRecord = (entity, id, id2) => {
  return async (dispatch) => {
    await removeRecordEntity(entity, id, id2);
    // dispatch(notify('Record is deleted.', 'success'));
  };
};

export const upsertRecord = (entity, data, opearation) => {
  return async (dispatch) => {
    if (entity === 'address') {
      if (opearation === 'add') await addPersonAddress(data);
      if (opearation === 'edit') await updatePersonAddress(data);
    } else if (entity === 'person') {
      await upsertPerson(data);
    } else if (entity === 'associate') {
      await upsertPersonAssociate(data);
    } else if (entity === 'contact') {
      if (opearation === 'add') await addPersonContactMethod(data);
      if (opearation === 'edit') await updatePersonContactMethod(data);
    } else if (entity === 'credential') {
      if (opearation === 'add') await addPersonCredential(data);
      if (opearation === 'edit') await updatePersonCredential(data);
    } else if (entity === 'dnasample') {
      if (opearation === 'add') await addPersonDNASample(data);
      if (opearation === 'edit') await updatePersonDNASample(data);
    } else if (entity === 'employment') {
      await upsertPersonEmployment(data);
    } else if (entity === 'feature') {
      if (opearation === 'add') await addPersonFeature(data);
      if (opearation === 'edit') await updatePersonFeature(data);
    } else if (entity === 'name') {
      if (opearation === 'add') await addPersonName(data);
      if (opearation === 'edit') await updatePersonName(data);
    } else if (entity === 'resource') {
      await upsertPersonResource(data);
    } else if (entity === 'vehicle') {
      if (opearation === 'add') await addPersonVehicleRegistration(data);
      if (opearation === 'edit') await updatePersonVehicleRegistration(data);
    } else if (entity === 'photo') {
      if (opearation === 'add') await addPersonPhoto(data);
      if (opearation === 'edit') await updatePersonPhoto(data);
    } else if (entity === 'place') {
      if (opearation === 'add') await addPlace(data);
      if (opearation === 'edit') await updatePlace(data);
    }

    // dispatch(notify('Record is Saved.', 'success'));
  };
};

export const resetRecordAdvanceSearchKeyword = () => {
  fields.person = fields.person.map((p) => ({ ...p, value: '' }));
  fields.address = fields.address.map((a) => ({ ...a, value: '' }));
  fields.item = fields.item.map((i) => ({ ...i, value: '' }));
  fields.firearm = fields.firearm.map((f) => ({ ...f, value: '' }));
  fields.jewelry = fields.jewelry.map((j) => ({ ...j, value: '' }));
  fields.structure = fields.structure.map((str) => ({ ...str, value: '' }));
  fields.substance = fields.substance.map((sub) => ({ ...sub, value: '' }));
  fields.vehicle = fields.vehicle.map((v) => ({ ...v, value: '' }));
  fields.place = fields.place.map((v) => ({ ...v, value: '' }));

  return async (dispatch) => {
    dispatch({ type: SET_RECORD_ADVANCE_SEARCH_KEYWORD, payload: fields });
  };
};

export const setSelectedRecordType = (payload) => {
  return async (dispatch) => {
    dispatch({ type: SET_RECORD_TYPE, payload });
  };
};

export default function reducer(
  state = {
    recordAdvanceSearchKeyword: fields,
    recordActive: false,
    formData: null,
    selectedRecordType: '',
  },
  action
) {
  switch (action.type) {
    case FORM_DATA:
      return {
        ...state,
        formData: action.payload,
      };
    case SET_RECORD_ACTIVE:
      return {
        ...state,
        recordActive: action.payload,
      };
    case SET_RECORD_ADVANCE_SEARCH_KEYWORD:
      return {
        ...state,
        recordAdvanceSearchKeyword: action.payload,
      };
    case SET_RECORD_TYPE:
      return {
        ...state,
        selectedRecordType: action.payload,
      };
    default:
      break;
  }
  return state;
}
