/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */
import React, { useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { useHistory, Link } from 'react-router-dom';

import 'date-fns';
import clsx from 'clsx';
import moment from 'moment';
import PerfectScrollbar from 'react-perfect-scrollbar';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Checkbox, TablePagination } from '@material-ui/core';
import { lighten, makeStyles } from '@material-ui/core/styles';
import Fade from '@material-ui/core/Fade';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import Typography from '@material-ui/core/Typography';
import Avatar from '@material-ui/core/Avatar';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';

import EnhancedTableToolbar from '../RMSEventList/EnhancedTableToolbar';
import EnhancedTableHead from '../RMSEventList/EnhancedTableHead';
import StyledBadge from '../../RMSStyledBadge';
import { colors } from '../../../config/colorVariables';
import { getEvents } from '../../../reducers/IncReducer';
import { showSpinner, hideSpinner } from '../../../reducers/ui/UiDrawerReducer';
import { notify } from '../../../reducers/NotifierReducer';

const useStyles = makeStyles((theme) => ({
  root: {
    boxSizing: 'border-box',
    width: '100%',
  },
  paper: {
    width: '100%',
    paddintop: '3px',
  },
  table: {
    padding: '0px',
    overflowX: 'hidden',
    width: '100%',
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  margin: {
    margin: theme.spacing(1),
  },
  button: {
    margin: theme.spacing(1),
  },
  eventLabel: { padding: '3px 0px' },
  eventSelector: {
    '&:hover': {
      cursor: 'pointer',
    },
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: colors.cyan,
    marginRight: theme.spacing(2),
  },
  submitted: {
    margin: theme.spacing(1),
    backgroundColor: colors.yellow,
    marginRight: theme.spacing(2),
    width: '3rem',
    height: '3rem',
  },
  pending: {
    margin: theme.spacing(1),
    backgroundColor: colors.cyan,
    marginRight: theme.spacing(2),
    width: '3rem',
    height: '3rem',
  },
  dispatched: {
    margin: theme.spacing(1),
    backgroundColor: colors.tulipTree,
    marginRight: theme.spacing(2),
    width: '3rem',
    height: '3rem',
  },
  enroute: {
    margin: theme.spacing(1),
    backgroundColor: colors.curiousBlue,
    marginRight: theme.spacing(2),
    width: '3rem',
    height: '3rem',
  },
  arrived: {
    margin: theme.spacing(1),
    backgroundColor: colors.gold,
    marginRight: theme.spacing(2),
    width: '3rem',
    height: '3rem',
  },
  controlled: {
    margin: theme.spacing(1),
    backgroundColor: colors.cadetBlue,
    marginRight: theme.spacing(2),
    width: '3rem',
    height: '3rem',
  },
  completed: {
    margin: theme.spacing(1),
    backgroundColor: colors.cadetBlue2,
    marginRight: theme.spacing(2),
    width: '3rem',
    height: '3rem',
  },
  onhold: {
    margin: theme.spacing(1),
    backgroundColor: colors.albescentWhite,
    marginRight: theme.spacing(2),
    width: '3rem',
    height: '3rem',
  },
  closed: {
    margin: theme.spacing(1),
    backgroundColor: colors.red,
    marginRight: theme.spacing(2),
    width: '3rem',
    height: '3rem',
  },
  closeButton: {
    position: 'absolute',
    bottom: '64px',
    right: '32px',
    zIndex: 999,
    width: '100px',
    textAlign: 'right',
    backgroundColor: theme.palette.info.dark,
    color: theme.palette.common.white,
    '&:hover': {
      backgroundColor: theme.palette.info.dark,
      boxShadow: 'none',
    },
    '&:active': {
      boxShadow: 'none',
      backgroundColor: theme.palette.info.dark,
    },
  },
  createIncidentButton: {
    position: 'absolute',
    bottom: '64px',
    right: '90px',
    zIndex: 999,
    width: '130px',
    textAlign: 'right',
    backgroundColor: theme.palette.info.dark,
    color: theme.palette.common.white,
    '&:hover': {
      backgroundColor: theme.palette.info.dark,
      boxShadow: 'none',
    },
    '&:active': {
      boxShadow: 'none',
      backgroundColor: theme.palette.info.dark,
    },
    marginRight: '50px',
  },
  detailsText:
    theme.palette.type === 'light'
      ? {
          color: lighten(theme.palette.text.primary, 0.5),
        }
      : {
          color: lighten(theme.palette.text.secondary, 1.0),
        },
}));

export const setStatusBadge = (status) => {
  // eslint-disable-next-line default-case
  // switch (status) {
  //   case 'Pending':
  //     return (
  //       <td className="text-center">
  //         <StyledBadge color={colors.cyan} label="PENDING" size="sm" />
  //       </td>
  //     );
  //   case 'Dispatched':
  //     return (
  //       <td className="text-center">
  //         <StyledBadge color={colors.tulipTree} label="DISPATCHED" size="sm" />
  //       </td>
  //     );
  //   case 'Enroute':
  //     return (
  //       <td className="text-center">
  //         <StyledBadge color={colors.curiousBlue} label="ENROUTE" size="sm" />
  //       </td>
  //     );
  //   case 'Arrived':
  //     return (
  //       <td className="text-center">
  //         <StyledBadge color={colors.gold} label="ARRIVED" size="sm" />
  //       </td>
  //     );
  //   case 'Controlled':
  //     return (
  //       <td className="text-center">
  //         <StyledBadge color={colors.cadetBlue} label="CONTROLLED" size="sm" />
  //       </td>
  //     );
  //   case 'Completed':
  //     return (
  //       <td className="text-center">
  //         <StyledBadge color={colors.cadetBlue2} label="COMPLETED" size="sm" />
  //       </td>
  //     );
  //   case 'OnHold':
  //     return (
  //       <td className="text-center">
  //         <StyledBadge color={colors.albescentWhite} label="ONHOLD" size="sm" />
  //       </td>
  //     );
  //   case 'Closed':
  //     return (
  //       <td className="text-center">
  //         <StyledBadge color={colors.red} label="CLOSED" size="sm" />
  //       </td>
  //     );
  // }
  switch (status) {
    case 'Pending':
      return (
        <td className="text-center">
          <StyledBadge color={colors.cyan} label="PENDING" size="sm" />
        </td>
      );
    case 'Dispatched':
      return (
        <td className="text-center">
          <StyledBadge color={colors.cyan} label="DISPATCHED" size="sm" />
        </td>
      );
    case 'Enroute':
      return (
        <td className="text-center">
          <StyledBadge color={colors.cyan} label="ENROUTE" size="sm" />
        </td>
      );
    case 'Arrived':
      return (
        <td className="text-center">
          <StyledBadge color={colors.cyan} label="ARRIVED" size="sm" />
        </td>
      );
    case 'Controlled':
      return (
        <td className="text-center">
          <StyledBadge color={colors.cyan} label="CONTROLLED" size="sm" />
        </td>
      );
    case 'Completed':
      return (
        <td className="text-center">
          <StyledBadge color={colors.cyan} label="COMPLETED" size="sm" />
        </td>
      );
    case 'OnHold':
      return (
        <td className="text-center">
          <StyledBadge color={colors.cyan} label="ONHOLD" size="sm" />
        </td>
      );
    case 'Closed':
      return (
        <td className="text-center">
          <StyledBadge color={colors.cyan} label="CLOSED" size="sm" />
        </td>
      );
  }
};

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  if (stabilizedThis === 'undefined') return;
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

function RMSEventList(props) {
  /*Region: Props and State*/
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState();
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [rows, setRow] = React.useState([]);
  const [eventsList, setEventsList] = React.useState([]);
  const [dateRange, setDateRange] = React.useState({});
  const [spinnerLoading, setSpinnerLoading] = React.useState(true);
  const [firstLoad, setFirstLoad] = React.useState(true);
  const [total, setTotal] = useState(0);
  const { events, getEvents, wsClient, showSpinner, hideSpinner, notify, searchKeyword } = props;
  const { createdFromDate, createdToDate } = dateRange;
  /** End Region */
  /*Region: Helper Functions*/
  const formatDate = (type, date) => {
    let time;
    if (type === 'from') {
      time = new Date(date).setUTCHours(0, 0, 0, 0);
    } else {
      time = new Date(date).setUTCHours(23, 59, 59, 999);
    }
    const newDate = new Date(time).toISOString();
    const dateTime = newDate.replace('T', ' ').replace('Z', '');
    return dateTime;
  };

  const dateFilter = {
    from: createdFromDate ? formatDate('from', createdFromDate) : '',
    to: createdToDate ? formatDate('to', createdToDate) : '',
  };

  const getAllEvents = () => {
    if (!wsClient) return;
    try {
      const service = wsClient.service('rms-events');
      service.timeout = 200000;
      service
        .find({
          query: {
            pageNo: page,
            rowsPerPage: rowsPerPage,
            eventTypeKeyword: searchKeyword.incidentKeyword?.eventType,
            eventIdKeyword: searchKeyword.incidentKeyword?.eventId,
            addressKeyword: searchKeyword.incidentKeyword?.address,
            agencyIdKeyword: searchKeyword.incidentKeyword?.agencyId,
            dispositionKeyword: searchKeyword.incidentKeyword?.disposition,
            officerKeyword: searchKeyword.incidentKeyword?.officer,
            createdFrom: dateFilter?.from,
            createdTo: dateFilter?.to,
          },
        })
        .then((result) => {
          let eventsList = [];
          const events = result.data;
          console.log('Events: ', events);

          setTotal(result.total);

          events.map((r) => {
            eventsList.push({
              ptsEventId: r.ptsEventID,
              status: r.Status,
              name: r.EventID,
              created: r.Created,
              callType: r.CallType,
              eventType: r.CallTypeDescription,
              agencyId: r.AgencyID,
              address: r.FullAddressText,
              disposition: r.Disposition,
              officer: r.FullName,
            });
          });

          setEventsList(eventsList);
          setRow(eventsList ? eventsList : []);
          getEvents(eventsList);
          setSpinnerLoading(false);
        });
    } catch (error) {
      setSpinnerLoading(false);
      console.log(error);
    }
  };

  const createIncidentFromEvent = async () => {
    if (!wsClient) return;
    showSpinner();

    try {
      console.log('Creating Incident....');

      const uniqueIDs = [...new Set(selected)];
      console.log("Unique ID's: ", uniqueIDs);

      for (let i = 0; i < uniqueIDs.length; i++) {
        const eventId = uniqueIDs[i];
        const agencyId = 'POLICEDEP';

        const data = {
          ptsEventID: eventId,
          AgencyID: agencyId,
        };

        console.log('Data for incident: ', data);

        const service = wsClient.service('rms-event-to-incident');
        service.timeout = 200000;

        const result = await service.update(eventId, data);

        console.log(result);

        if (result[1] === 1) {
          notify('Incident not created because of AgencyID not found in caseIDs!', 'error');
        } else if (i < uniqueIDs.length - 1) {
          continue;
        } else {
          notify('Incident created!', 'success');
          history.push('/incidents');
        }
      }
    } catch (error) {
      console.log(error);
    }

    hideSpinner();
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = rows.map((n) => n.ptsEventId);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, eventId) => {
    let selectedIndex = selected.indexOf(eventId);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, eventId);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };

  const handleCreateIncidentClick = () => {
    createIncidentFromEvent();
  };

  const isSelected = (eventId) => selected.indexOf(eventId) !== -1;

  const handleChangePage = (event, newPage) => setPage(newPage);

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  /** End Region */
  /*Region: useEffect*/
  React.useEffect(() => {
    if (events === undefined || events?.length === 0) {
      getAllEvents();
      setFirstLoad(false);
    } else if (events.length > 0 && firstLoad === false) {
      setRow(events);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [events?.length, wsClient]);

  React.useEffect(() => {
    if (
      searchKeyword?.incidentKeyword?.eventType?.length >= 0 ||
      searchKeyword?.incidentKeyword?.eventId?.length >= 0 ||
      searchKeyword?.incidentKeyword?.address?.length >= 0 ||
      searchKeyword?.incidentKeyword?.agencyId?.length >= 0 ||
      searchKeyword?.incidentKeyword?.disposition?.length >= 0 ||
      searchKeyword?.incidentKeyword?.officer?.length >= 0
    ) {
      const timeoutId = setTimeout(() => {
        setSpinnerLoading(true);
        setPage(0);
        setRowsPerPage(10);
        getAllEvents();
      }, 2000);

      return () => clearTimeout(timeoutId);
    }
  }, [searchKeyword.incidentKeyword]);

  React.useEffect(() => {
    setSpinnerLoading(true);
    getAllEvents();
  }, [page, rowsPerPage, dateFilter.from, dateFilter.to]);

  /** End Region */
  return (
    <Grid container className={classes.root}>
      <Grid item xs={12}>
        <div className={clsx(classes.root)}>
          <Paper style={{ minHeight: window.innerHeight - 96 + 'px' }} className={classes.paper}>
            <EnhancedTableToolbar setDateRange={setDateRange} dateRange={dateRange} />
            <TableContainer
              style={{
                overflowX: 'hidden',
                maxHeight: window.innerHeight - 270 + 'px',
                marginBottom: '20px',
              }}
              className="mt-4">
              <PerfectScrollbar
                style={{
                  height: window.innerHeight - 270 + 'px',
                  overflowX: 'hidden',
                }}>
                <Table
                  stickyHeader
                  className={classes.table}
                  aria-label="sticky table"
                  size="small">
                  <EnhancedTableHead
                    classes={classes}
                    numSelected={selected.length}
                    order={order}
                    orderBy={orderBy}
                    onRequestSort={handleRequestSort}
                    onSelectAllClick={handleSelectAllClick}
                    rowCount={rows.length}
                  />
                  <TableBody>
                    {spinnerLoading && (
                      <div className={classes.spinnerLoading} style={{ margin: '350% 1200%' }}>
                        <CircularProgress disableShrink /> Loading..
                      </div>
                    )}

                    {spinnerLoading === false &&
                      stableSort(rows, getComparator(order, orderBy)).map((row, index) => {
                        const isItemSelected = isSelected(row.ptsEventId);
                        const labelId = `enhanced-table-checkbox-${index}`;

                        return (
                          <TableRow
                            hover
                            onClick={(event) => handleClick(event, row.ptsEventId)}
                            role="checkbox"
                            aria-checked={isItemSelected}
                            tabIndex={-1}
                            label={labelId}
                            key={row.ptsEventId}
                            className={classes.eventSelector}
                            selected={isItemSelected}>
                            <TableCell padding="checkbox">
                              <Checkbox
                                color="primary"
                                checked={isItemSelected}
                                inputProps={{
                                  'aria-labelledby': labelId,
                                }}
                              />
                            </TableCell>
                            <TableCell>
                              <td>
                                <div className="d-flex align-items-center">
                                  <Avatar
                                    aria-label="avatar"
                                    className={clsx(
                                      // row.status === 'Pending' && classes.pending,
                                      // row.status === 'Dispatched' && classes.dispatched,
                                      // row.status === 'Enroute' && classes.enroute,
                                      // row.status === 'Arrived' && classes.arrived,
                                      // row.status === 'Controlled' && classes.controlled,
                                      // row.status === 'Completed' && classes.completed,
                                      // row.status === 'OnHold' && classes.onhold,
                                      // row.status === 'Closed' && classes.closed
                                      row.status === 'Pending' && classes.pending,
                                      row.status === 'Dispatched' && classes.pending,
                                      row.status === 'Enroute' && classes.pending,
                                      row.status === 'Arrived' && classes.pending,
                                      row.status === 'Controlled' && classes.pending,
                                      row.status === 'Completed' && classes.pending,
                                      row.status === 'OnHold' && classes.pending,
                                      row.status === 'Closed' && classes.pending
                                    )}>
                                    {'EV'}
                                  </Avatar>
                                  <div>
                                    <Typography variant="h5" className={classes.eventLabel}>
                                      {row.name ? row.name : ''}
                                    </Typography>
                                    <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
                                      <div style={{ paddingRight: '10px' }}>
                                        <Typography
                                          variant="h6"
                                          className={clsx(classes.detailsText, ' d-block')}>
                                          Call Type: {row.callType}
                                        </Typography>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </td>
                            </TableCell>
                            <TableCell> {setStatusBadge(row.status)} </TableCell>
                            <TableCell> {row.agencyId ? row.agencyId : ''} </TableCell>
                            <TableCell> {row.eventType ? row.eventType : ''} </TableCell>
                            <TableCell> {row.address ? row.address : ''} </TableCell>
                            <TableCell> {row.disposition ? row.disposition : ''} </TableCell>
                            <TableCell>
                              {row.created
                                ? moment(row.created).format('MM/DD/YYYY hh:mm A')
                                : null}
                            </TableCell>
                            <TableCell> {row.officer ? row.officer : ''} </TableCell>
                            <TableCell></TableCell>
                          </TableRow>
                        );
                      })}

                    {!spinnerLoading && rows.length < 1 && (
                      <div style={{ margin: '20px 0px 0px 20px' }}>
                        <h6>No Data Found</h6>
                      </div>
                    )}
                  </TableBody>
                </Table>
              </PerfectScrollbar>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[2, 5, 10, 25]}
              component="div"
              count={total}
              rowsPerPage={rowsPerPage}
              page={page}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
            />
          </Paper>
        </div>
        <Fade in={selected.length > 0}>
          <Button className={classes.createIncidentButton} onClick={handleCreateIncidentClick}>
            Create Incident
          </Button>
        </Fade>
        <Link to="/incidents">
          <Button className={classes.closeButton}>Close</Button>
        </Link>
      </Grid>
    </Grid>
  );
}

const mapStateToProps = (state) => ({
  wsClient: state.websocket.websocket,
  events: state.incident.events,
  searchKeyword: state.incident.searchKeyword,
});

export default connect(mapStateToProps, {
  getEvents,
  showSpinner,
  hideSpinner,
  notify,
})(RMSEventList);
