import { codeVictimCategory } from 'reducers/_dev/codeVictimCategory';
import SimpleSchema from 'simpl-schema';

SimpleSchema.setDefaultMessages({
  messages: {
    en: {
      'NIBRS-2019.2-124':
        'When a Person age is provided it must contain a valid value of 00-99 or (NN,NB,BB).',
      'NIBRS-2019.2-176': 'The Age of the Arrestee must be provided.',
      'NIBRS-2019.2-180': 'The Race of the Arrestee must be provided.',
      'NIBRS-2019.2-178': 'The Sex of the Arrestee must be provided.',
      'NIBRS-2019.2-179': 'For Sex of the Arrestee, U = Unknown is not a valid value.',
      'NIBRS-2019.2-128':
        'When a Victim type of (L) is provided then the Victim Age must be 17-98 or zero for Unknown.',
      'NIBRS-2019.2-130':
        'The Victim Age must be less than 18 when the Victim Connected to Offense code is 36B.',
      'NIBRS-2019.2-131':
        'The Victim Sex must be M or F when the Victim Connected to Offense code is 11A or 36B.',
      'NIBRS-2019.2-129':
        'A Victim Age, Sex, Race, Resident, and Ethnicity can only be provided when the Victim Type is (I,L).',
      'NIBRS-2019.2-123':
        'When a Victim is (I,L) is provided then Age, Sex, and Race are required.',
      'NIBRS-2019.2-157':
        'When the Offense Code = 09C, then Offender Age, Offender Sex, and/or Offender Race must be known.',
    },
  },
});

const getVictimCategory = (codeVictimCategory, cat) =>
  codeVictimCategory.find((category) => category.description === cat);

export const partiesSchema = new SimpleSchema({
  party: {
    type: Object,
    optional: true,
    blackbox: true,
    custom() {
      // NIBRS-2019.2-123: Person Age
      let party = this.value.values;
      if (this.obj.victim) {
        if (
          ['I', 'L'].includes(
            getVictimCategory(codeVictimCategory, this.obj.victim.values.category).code
          )
        ) {
          if (!party.race || !party.sex || !party.age) {
            return 'NIBRS-2019.2-123';
          }
        }
      }
    },
  },

  age: {
    type: SimpleSchema.Integer,
    label: 'Person Age',
    optional: true,
    custom() {
      if (this.obj.victim) {
        let codeVictimCategoryType = getVictimCategory(
          codeVictimCategory,
          this.obj.victim.values.category
        );

        if (this.value && (this.value < 0 || this.value > 99)) {
          return 'NIBRS-2019.2-124';
        }

        if (codeVictimCategoryType?.code === 'L') {
          if (this.value < 17 || this.value > 98) {
            return 'NIBRS-2019.2-128';
          }
        }

        if (this.obj.victimOffense) {
          if (this.obj.victimOffense.values.statuteDetails?.FBICode === '36B' && this.value > 18) {
            return 'NIBRS-2019.2-130';
          }
        }

        if (
          !['I', 'L'].includes(
            getVictimCategory(codeVictimCategory, this.obj.victim.values.category)?.code
          ) &&
          this.value
        ) {
          return 'NIBRS-2019.2-129';
        }
      } else if (this.obj.offender) {
        if (this.obj.offenderOffense) {
          if (this.obj.offenderOffense.values.statuteDetails?.FBICode === '09C' && !this.value) {
            return 'NIBRS-2019.2-157';
          }
        }
      } else {
        if (this.value && (this.value < 0 || this.value > 99)) {
          return 'NIBRS-2019.2-124';
        }
      }
    },
  },

  sex: {
    type: String,
    label: 'Person Sex',
    optional: true,
    custom() {
      if (this.obj.victim) {
        if (this.obj.victimOffense) {
          if (
            this.obj.victimOffense.values.statuteDetails?.FBICode === '11A' ||
            this.obj.victimOffense.values.statuteDetails?.FBICode === '36B'
          ) {
            if (
              !(
                this.value === 'M' ||
                this.value === 'Male' ||
                this.value === 'MALE' ||
                this.value === 'F' ||
                this.value === 'Female' ||
                this.value === 'FEMALE'
              )
            ) {
              return 'NIBRS-2019.2-131';
            }
          }
        }

        if (
          !['I', 'L'].includes(
            getVictimCategory(codeVictimCategory, this.obj.victim.values.category)?.code
          ) &&
          this.value &&
          this.value.length !== 0
        ) {
          return 'NIBRS-2019.2-129';
        }
      }
      if (this.obj.offender) {
        if (this.obj.offenderOffense) {
          if (this.obj.offenderOffense.values.statuteDetails?.FBICode === '09C' && !this.value) {
            return 'NIBRS-2019.2-157';
          }
        }
      }
    },
  },

  race: {
    type: String,
    label: 'Person Age',
    optional: true,
    custom() {
      if (this.obj.victim) {
        if (
          !['I', 'L'].includes(
            getVictimCategory(codeVictimCategory, this.obj.victim.values.category)?.code
          ) &&
          this.value &&
          this.value.length !== 0
        ) {
          return 'NIBRS-2019.2-129';
        }
      } else if (this.obj.offender) {
        if (this.obj.offenderOffense) {
          if (this.obj.offenderOffense.values.statuteDetails?.FBICode === '09C' && !this.value) {
            return 'NIBRS-2019.2-157';
          }
        }
      }
    },
  },

  ethnicity: {
    type: String,
    label: 'Person Age',
    optional: true,
    custom() {
      if (this.obj.victim) {
        if (
          !['I', 'L'].includes(
            getVictimCategory(codeVictimCategory, this.obj.victim.values.category)?.code
          ) &&
          this.value &&
          this.value.length !== 0
        ) {
          return 'NIBRS-2019.2-129';
        }
      }
    },
  },

  arresteeAge: {
    type: SimpleSchema.Integer,
    label: 'NIBRS-2019.2-176: Arrestee Age',
    optional: true,
    custom() {
      if (!this.value) {
        return 'NIBRS-2019.2-176';
      }
    },
  },

  arresteeRace: {
    type: String,
    label: 'NIBRS-2019.2-180: Arrestee Race',
    optional: true,
    custom() {
      if (!this.value) {
        return 'NIBRS-2019.2-180';
      }
    },
  },

  arresteeSex: {
    type: String,
    label: 'NIBRS-2019.2-178: Arrestee Sex | NIBRS-2019.2-179: Arrestee Sex',
    optional: true,
    custom() {
      if (!this.value) {
        return 'NIBRS-2019.2-178';
      } else {
        if (this.value === 'U' || this.value === 'Unknown' || this.value === 'UNKNOWN') {
          return 'NIBRS-2019.2-179';
        }
      }
    },
  },
});
