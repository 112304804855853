import SimpleSchema from 'simpl-schema';
import moment from 'moment';

SimpleSchema.setDefaultMessages({
  messages: {
    en: {
      'NIBRS-2019.2-164': 'Arrest Date cannot be earlier than the Incident Date/Hour.',
      'NIBRS-2019.2-170': 'The Arrestee Armed With code is required for each Arrestee.',
      'NIBRS-2019.2-166':
        'The Arrestee Segment Indicator is required when an Arrestee is provided.',
      'NIBRS-2019.2-177':
        'When the Age of the Arrestee is under 18, the Disposition of Arrestee Under 18 must be provided.',
      'NIBRS-2019.2-181':
        'When the Age of the Arrestee is 18 or older, then then Disposition of Arrestee Under 18 must be omitted.',
    },
  },
});

export const arresteesSchema = new SimpleSchema({
  arrestDate: {
    type: Date,
    label: 'NIBRS-2019.2-164: Arrest Date',
    optional: true,
    custom() {
      let incidentOccuredDate = moment(this.obj.incident.values.occurred).format('YYYY-MM-DD');
      let arrestDate = moment(this.value).format('YYYY-MM-DD');

      if (new Date(arrestDate) < new Date(incidentOccuredDate)) {
        return 'NIBRS-2019.2-164';
      }
    },
  },

  arresteeArmedWith: {
    type: String,
    label: 'NIBRS-2019.2-170: Arrestee Armed With',
    optional: true,
    custom() {
      if (!this.value) {
        return 'NIBRS-2019.2-170';
      }
    },
  },

  arresteeSegmentsIndicator: {
    type: String,
    label: 'NIBRS-2019.2-166: Arrestee Segments',
    optional: true,
    custom() {
      if (!this.value) {
        return 'NIBRS-2019.2-166';
      }
    },
  },

  juvenileDisposition: {
    type: String,
    optional: true,
    custom() {
      // NIBRS-2019.2-177: Arrestee Age
      if (this.obj.arresteeParty.personDetail.values.age < 18) {
        if (!this.value) {
          return 'NIBRS-2019.2-177';
        }
      }
      // NIBRS-2019.2-181: Disposition of Arrestee Under 18
      if (this.obj.arresteeParty.personDetail.values.age >= 18) {
        if (this.value) {
          return 'NIBRS-2019.2-181';
        }
      }
    },
  },
});
