import store from '../../config/configureStore';

export const SET_CODE_ADDRESS_BLDG_TYPES = 'CODE/SET_CODE_ADDRESS_BLDG_TYPES';
export const ADD_CODE_ADDRESS_BLDG_TYPES = 'CODE/ADD_CODE_ADDRESS_BLDG_TYPES';
export const SET_CODE_ADDRESS_UNIT_TYPES = 'CODE/SET_CODE_ADDRESS_UNIT_TYPES';
export const ADD_CODE_ADDRESS_UNIT_TYPES = 'CODE/ADD_CODE_ADDRESS_UNIT_TYPES';
export const SET_CODE_ADDRESS_FLOOR_TYPES = 'CODE/SET_CODE_ADDRESS_FLOOR_TYPES';
export const ADD_CODE_ADDRESS_FLOOR_TYPES = 'CODE/ADD_CODE_ADDRESS_FLOOR_TYPES';

export const addCodeAddressBldgTypes = (newValue) => {
  return { type: ADD_CODE_ADDRESS_BLDG_TYPES, payload: newValue };
};

export const getCodeAddressBldgTypes = () => {
  const client = store.store.getState().websocket.websocket;
  return async (dispatch) => {
    try {
      const service = client.service('codeaddressbldgtypes');
      service.timeout = 20000;
      const codes = await service.find({
        query: {
          $select: ['Code', 'Description'],
          $sort: {
            Description: 1,
          },
          IsDeleted: false,
        },
      });
      dispatch({ type: SET_CODE_ADDRESS_BLDG_TYPES, payload: codes.data });
    } catch (error) {
      if (error.code === 401) {
        console.log('Unauthenticated');
      } else {
        console.log(error);
      }
    }
  };
};

export const addCodeAddressUnitTypes = (newValue) => {
  return { type: ADD_CODE_ADDRESS_UNIT_TYPES, payload: newValue };
};

export const getCodeAddressUnitTypes = () => {
  const client = store.store.getState().websocket.websocket;
  return async (dispatch) => {
    try {
      const service = client.service('codeaddressunittypes');
      service.timeout = 20000;
      const codes = await service.find({
        query: {
          $select: ['Code', 'Description'],
          $sort: {
            Code: 1,
          },
          IsDeleted: false,
          isDeleted: false, // doen't need this line once the db is fixed.
        },
      });
      dispatch({ type: SET_CODE_ADDRESS_UNIT_TYPES, payload: codes.data });
    } catch (error) {
      if (error.code === 401) {
        console.log('Unauthenticated');
      } else {
        console.log(error);
      }
    }
  };
};

export const addCodeAddressFloorTypes = (newValue) => {
  return { type: ADD_CODE_ADDRESS_FLOOR_TYPES, payload: newValue };
};

export const getCodeAddressFloorTypes = () => {
  const client = store.store.getState().websocket.websocket;
  return async (dispatch) => {
    try {
      const service = client.service('codeaddressfloortypes');
      service.timeout = 20000;
      const codes = await service.find({
        query: {
          $select: ['Code', 'Description'],
          $sort: {
            Code: 1,
          },
          IsDeleted: false,
        },
      });
      dispatch({ type: SET_CODE_ADDRESS_FLOOR_TYPES, payload: codes.data });
    } catch (error) {
      if (error.code === 401) {
        console.log('Unauthenticated');
      } else {
        console.log(error);
      }
    }
  };
};

export default function reducer(
  state = {
    codeAddressBldgTypes: [],
    codeAddressUnitTypes: [],
    codeAddressFloorTypes: [],
  },
  action
) {
  // eslint-disable-next-line default-case
  switch (action.type) {
    case SET_CODE_ADDRESS_BLDG_TYPES:
      return { ...state, codeAddressBldgTypes: action.payload };
    case ADD_CODE_ADDRESS_BLDG_TYPES:
      return { ...state, codeAddressBldgTypes: [...state.codeAddressBldgTypes, action.payload] };
    case SET_CODE_ADDRESS_UNIT_TYPES:
      return { ...state, codeAddressUnitTypes: action.payload };
    case ADD_CODE_ADDRESS_UNIT_TYPES:
      return { ...state, codeAddressUnitTypes: [...state.codeAddressUnitTypes, action.payload] };
    case SET_CODE_ADDRESS_FLOOR_TYPES:
      return { ...state, codeAddressFloorTypes: action.payload };
    case ADD_CODE_ADDRESS_FLOOR_TYPES:
      return { ...state, codeAddressFloorTypes: [...state.codeAddressFloorTypes, action.payload] };
  }
  return state;
}
