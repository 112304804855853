/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */
import React, { useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';

import 'date-fns';
import clsx from 'clsx';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PerfectScrollbar from 'react-perfect-scrollbar';
import CircularProgress from '@material-ui/core/CircularProgress';
import { TablePagination } from '@material-ui/core';
import { lighten, makeStyles } from '@material-ui/core/styles';
import Fade from '@material-ui/core/Fade';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import Typography from '@material-ui/core/Typography';
import Avatar from '@material-ui/core/Avatar';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';

import RMSEditButton from '../../RMSButtons/RMSEditButton';
import RMSDeleteButton from '../../RMSButtons/RMSDeleteButton';
import EnhancedTableToolbar from './EnhancedTableToolbar';
import EnhancedTableHead from './EnhancedTableHead';
import StyledBadge from '../../RMSStyledBadge';
import { colors } from '../../../config/colorVariables';
import {
  resetAllForms,
  setActiveIncidents,
  setSelectedIncident,
} from '../../../reducers/IncReducer';
import { resetAllEntitiesList } from '../../../reducers/ui/UiListReducer';
import { removeSelectedIncident } from '../../../reducers/IncReducer';
import { setShowIncidentStepper } from 'reducers/ui/UiMenuReducer';

const useStyles = makeStyles((theme) => ({
  root: {
    boxSizing: 'border-box',
    width: '100%',
  },
  paper: {
    width: '100%',
    paddintop: '3px',
  },
  table: {
    padding: '0px',
    overflowX: 'hidden',
    width: '100%',
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  margin: {
    margin: theme.spacing(1),
  },
  button: {
    margin: theme.spacing(1),
  },
  incidentLabel: { padding: '3px 0px' },
  incidentSelector: {
    '&:hover': {
      cursor: 'pointer',
    },
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: colors.cyan,
    marginRight: theme.spacing(2),
  },
  submitted: {
    margin: theme.spacing(1),
    backgroundColor: colors.yellow,
    marginRight: theme.spacing(2),
    width: '3rem',
    height: '3rem',
  },
  pending: {
    margin: theme.spacing(1),
    backgroundColor: colors.cyan,
    marginRight: theme.spacing(2),
    width: '3rem',
    height: '3rem',
  },
  approved: {
    margin: theme.spacing(1),
    backgroundColor: colors.green,
    marginRight: theme.spacing(2),
    width: '3rem',
    height: '3rem',
  },
  disposed: {
    margin: theme.spacing(1),
    backgroundColor: colors.red,
    marginRight: theme.spacing(2),
    width: '3rem',
    height: '3rem',
  },
  detailsText:
    theme.palette.type === 'light'
      ? {
          color: lighten(theme.palette.text.primary, 0.5),
        }
      : {
          color: lighten(theme.palette.text.secondary, 1.0),
        },
}));

export const setEventIcon = (isCadEvent) => {
  // eslint-disable-next-line default-case
  switch (isCadEvent) {
    case true:
      return <FontAwesomeIcon icon="headset" className="font-size-xxl mr-2" />;
    case false:
      return <FontAwesomeIcon icon="laptop" className="font-size-xxl mr-2" />;
  }
};

export const setStatusBadge = (status) => {
  // eslint-disable-next-line default-case
  switch (status) {
    case 'pending':
      return (
        <td className="text-center">
          <StyledBadge color={colors.cyan} label="PENDING" size="sm" />
        </td>
      );
    case 'approved':
      return (
        <td className="text-center">
          <StyledBadge color={colors.green} label="APPROVED" size="sm" />
        </td>
      );
    case 'disposed':
      return (
        <td className="text-center">
          <StyledBadge color={colors.red} label="DISPOSED" size="sm" />
        </td>
      );
    case 'submitted':
      return (
        <td className="text-center">
          <StyledBadge color={colors.yellow} label="SUBMITTED" size="sm" />
        </td>
      );
  }
};

export const setFlags = (flags) => {
  return <td className="text-center">{createBadges(flags)}</td>;
};

const createBadges = (flags) => {
  if (flags !== undefined)
    return flags.map((f) => {
      // eslint-disable-next-line default-case
      switch (f) {
        case 'isJuvenile':
          return <StyledBadge color={colors.indigo} label="JUVENILE" size="sm" />;

        case 'isDomesticViolence':
          return <StyledBadge color={colors.teal} label="DOMESTIC VIOLENCE" size="sm" />;
      }
    });
};

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  if (stabilizedThis === 'undefined') return;
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

function RMSIncidentList(props) {
  /*Region: Props and State*/
  const classes = useStyles();
  const dispatch = useDispatch();
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState();
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [rows, setRow] = React.useState([]);
  const [activeList, setActiveList] = React.useState([]);
  const [dateRange, setDateRange] = React.useState({});
  const [spinnerLoading, setSpinnerLoading] = React.useState(true);
  const [firstLoad, setFirstLoad] = React.useState(true);
  const [total, setTotal] = useState(0);
  const {
    searchKeyword,
    activeIncidents,
    setActiveIncidents,
    selectedIncident,
    setSelectedIncident,
    wsClient,
    resetAllEntitiesList,
    removeSelectedIncident,
  } = props;
  const { selectedFromDate, selectedToDate } = dateRange;
  /** End Region */
  /*Region: Helper Functions*/
  const formatDate = (type, date) => {
    let time;
    if (type === 'from') {
      time = new Date(date).setUTCHours(0, 0, 0, 0);
    } else {
      time = new Date(date).setUTCHours(23, 59, 59, 999);
    }
    const newDate = new Date(time).toISOString();
    const dateTime = newDate.replace('T', ' ').replace('Z', '');
    return dateTime;
  };
  const dateFilter = {
    from: selectedFromDate ? formatDate('from', selectedFromDate) : '',
    to: selectedToDate ? formatDate('to', selectedToDate) : '',
  };

  const getAllActiveIncidents = () => {
    if (!wsClient) return;
    try {
      const service = wsClient.service('rms-incident');
      service.timeout = 20000;
      service
        .find({
          query: {
            IsDeleted: false,
            location: true,
            keyword: searchKeyword.incidentKeyword?.eventType || '',
            addressKeyword: searchKeyword.incidentKeyword?.address || '',
            offenseKeyword: searchKeyword.incidentKeyword?.offense || '',
            offenses: true,
            forIncidentList: true,
            dateFilter: dateFilter,
            address: true,
            $skip: page * rowsPerPage,
            $limit: rowsPerPage,
          },
        })
        .then((result) => {
          let activeList = [];
          const activeIncidents = result.data;

          setTotal(result.total);

          activeIncidents.map((r) => {
            if (!activeList.find((x) => x.ptsIncidentId === r.ptsIncidentID))
              activeList.push({
                updated: r.Updated,
                updatedBy: r.UpdatedBy,
                createdBy: r.CreatedBy,
                // status: r.Status,
                status: 'pending',
                occurred: r.OccurredTo,
                name: r.Offense[0]?.statute,
                incidentId: r.ptsIncidentID,
                CustomId: r.IncidentID,
                ptsIncidentId: r.ptsIncidentID,
                weather: r.Weather,
                eventType: r.IncidentCategory,
                lighting: r.Lighting,
                category: r.Location?.LocationCategory,
                location: r.Location?.Name,
                crossStreet: r.Location?.CrossStreet,
                highway: r.Location?.Highway,
                landmark: r.Location?.LandmarkText,
                subDivision: r.Location?.Subdivision,
                primaryAddress: r.Location?.LocationAddress[0]?.FullAddressText,
              });
          });

          setActiveList(activeList);
          setRow(activeList ? activeList : []);
          setActiveIncidents(activeList);
          setSpinnerLoading(false);
        });
    } catch (error) {
      setSpinnerLoading(false);
      console.log(error);
    }
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleClick = (event, incidentId) => {
    let newSelected = [];
    if (selected[0] !== incidentId) {
      newSelected[0] = incidentId;
    }
    setSelected(newSelected);
    setSelectedIncident(incidentId);
  };

  const handleEditClick = () => {
    dispatch(setShowIncidentStepper(true));
    resetAllForms();
    resetAllEntitiesList();
  };

  const handleDeleteClick = () => {
    removeSelectedIncident();
    setTotal(total-1);
  };

  const isSelected = (incidentId) => {
    const isTrue = selected.length && selected[0] === incidentId;
    return isTrue ? isTrue : false;
  };

  const handleChangePage = (event, newPage) => setPage(newPage);

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  /** End Region */
  /*Region: useEffect*/
  React.useEffect(() => {
    if (activeIncidents === undefined || activeIncidents?.length === 0) {
      getAllActiveIncidents();
      setFirstLoad(false);
    } else if (activeIncidents.length > 0 && firstLoad === false) {
      setRow(activeIncidents);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeIncidents?.length, wsClient]);

  React.useEffect(() => {
    if (
      searchKeyword?.incidentKeyword?.eventType?.length >= 0 ||
      searchKeyword?.incidentKeyword?.address?.length >= 0 ||
      searchKeyword?.incidentKeyword?.offense?.length >= 0
    ) {
      const timeoutId = setTimeout(() => {
        setSpinnerLoading(true);
        setPage(0);
        setRowsPerPage(10);
        getAllActiveIncidents();
      }, 2000);

      return () => clearTimeout(timeoutId);
    }
  }, [searchKeyword.incidentKeyword]);

  React.useEffect(() => {
    setSpinnerLoading(true);
    getAllActiveIncidents();
  }, [page, rowsPerPage, dateFilter.from, dateFilter.to]);
  /** End Region */
  return (
    <Grid container className={classes.root}>
      <Grid item xs={12}>
        <div className={clsx(classes.root)}>
          <Paper style={{ minHeight: window.innerHeight - 96 + 'px' }} className={classes.paper}>
            <EnhancedTableToolbar setDateRange={setDateRange} dateRange={dateRange} />
            <TableContainer
              style={{
                overflowX: 'hidden',
                maxHeight: window.innerHeight - 270 + 'px',
                marginBottom: '20px',
              }}
              className="mt-4">
              <PerfectScrollbar
                style={{
                  height: window.innerHeight - 270 + 'px',
                  overflowX: 'hidden',
                }}>
                <Table
                  stickyHeader
                  className={classes.table}
                  aria-label="sticky table"
                  size="small">
                  <EnhancedTableHead
                    classes={classes}
                    numSelected={selected.length}
                    order={order}
                    orderBy={orderBy}
                    onRequestSort={handleRequestSort}
                    rowCount={rows.length}
                  />
                  <TableBody>
                    {spinnerLoading && (
                      <div className={classes.spinnerLoading} style={{ margin: '130% 350%' }}>
                        <CircularProgress disableShrink /> Loading..
                      </div>
                    )}

                    {spinnerLoading === false &&
                      stableSort(rows, getComparator(order, orderBy)).map((row, index) => {
                        const isItemSelected = isSelected(row.incidentId);
                        const labelId = `enhanced-table-checkbox-${index}`;

                        return (
                          <TableRow
                            hover
                            onMouseEnter={(event) => handleClick(event, row.incidentId)}
                            onMouseLeave={() => setSelected([])}
                            aria-checked={isItemSelected}
                            tabIndex={-1}
                            label={labelId}
                            key={row.incidentId}
                            className={classes.incidentSelector}
                            selected={isItemSelected}>
                            <TableCell>
                              <span style={{ display: 'none' }}>{row.name || ''}</span>
                              <td>
                                <div className="d-flex align-items-center">
                                  <Avatar
                                    aria-label="avatar"
                                    className={clsx(
                                      row.status === 'pending' && classes.pending,
                                      row.status === 'approved' && classes.approved,
                                      row.status === 'submitted' && classes.submitted,
                                      row.status === 'disposed' && classes.disposed
                                    )}>
                                    {row.name?.substring(0, 3) !== undefined
                                      ? row.name?.substring(0, 3)
                                      : 'INC'}
                                  </Avatar>
                                  <div>
                                    <Typography variant="h5" className={classes.incidentLabel}>
                                      {row.CustomId} {row.CustomId && row.name ? '|' : ''}{' '}
                                      {row.name ? row.name : ''}
                                    </Typography>
                                    <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
                                      <div style={{ paddingRight: '10px' }}>
                                        <Typography
                                          variant="h6"
                                          className={clsx(classes.detailsText, ' d-block')}>
                                          Event Type: {row.eventType}
                                        </Typography>
                                        <Typography
                                          variant="h6"
                                          className={clsx(classes.detailsText, ' d-block')}>
                                          Category: {row.category}
                                        </Typography>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </td>
                            </TableCell>
                            <TableCell>
                              <td>
                                <div>
                                  <Typography
                                    variant="h6"
                                    className={clsx(classes.detailsText, ' d-block')}>
                                    Address: {row.primaryAddress}
                                  </Typography>
                                </div>
                                <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
                                  <Typography
                                    variant="h6"
                                    className={clsx(classes.detailsText, ' d-block')}>
                                    Location: {row.location}
                                  </Typography>
                                </div>
                                <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
                                  <div style={{ paddingRight: '10px' }}>
                                    <Typography
                                      variant="h6"
                                      className={clsx(classes.detailsText, ' d-block')}>
                                      Highway: {row.highway}
                                    </Typography>
                                    <Typography
                                      variant="h6"
                                      className={clsx(classes.detailsText, ' d-block')}>
                                      Sub Division: {row.subDivision}
                                    </Typography>
                                  </div>
                                  <div style={{ paddingLeft: '10px' }}>
                                    <Typography
                                      variant="h6"
                                      className={clsx(classes.detailsText, ' d-block')}>
                                      Cross Street: {row.crossStreet}
                                    </Typography>
                                    <Typography
                                      variant="h6"
                                      className={clsx(classes.detailsText, ' d-block')}>
                                      Landmark: {row.landmark}
                                    </Typography>
                                  </div>
                                </div>
                              </td>
                            </TableCell>
                            <TableCell> {setStatusBadge(row.status)} </TableCell>
                            <TableCell>
                              {row.occurred
                                ? moment(row.occurred).format('MM/DD/YYYY hh:mm A')
                                : null}
                            </TableCell>
                            <TableCell>
                              {row.updated
                                ? moment(row.updated).format('MM/DD/YYYY hh:mm A')
                                : null}
                            </TableCell>
                            <TableCell>{row.createdBy}</TableCell>
                            <TableCell>
                              <Fade in={selected.length > 0 && isItemSelected === true}>
                                <Grid container wrap="nowrap" spacing={2} justify="flex-end">
                                  <Grid item className="mt-2">
                                    <Link to={`incidents/edit/${selectedIncident}`}>
                                      <RMSEditButton onClick={handleEditClick} />
                                    </Link>
                                  </Grid>
                                  <Grid item className="mt-2">
                                    <RMSDeleteButton onClick={handleDeleteClick} />
                                  </Grid>
                                </Grid>
                              </Fade>
                            </TableCell>
                          </TableRow>
                        );
                      })}

                    {!spinnerLoading && rows.length < 1 && (
                      <div style={{ margin: '20px 0px 0px 20px' }}>
                        <h6>No Data Found</h6>
                      </div>
                    )}
                  </TableBody>
                </Table>
              </PerfectScrollbar>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[2, 5, 10, 25]}
              component="div"
              count={total}
              rowsPerPage={rowsPerPage}
              page={page}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
            />
          </Paper>
        </div>
      </Grid>
    </Grid>
  );
}

const mapStateToProps = (state) => ({
  wsClient: state.websocket.websocket,
  activeIncidents: state.incident.activeIncidents,
  selectedIncident: state.incident.ptsIncidentId,
  searchKeyword: state.incident.searchKeyword,
});

export default connect(mapStateToProps, {
  setActiveIncidents,
  setSelectedIncident,
  resetAllEntitiesList,
  removeSelectedIncident,
})(RMSIncidentList);
