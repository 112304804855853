import store from '../../config/configureStore';

import { arresteesSchema } from 'simpl-schema-validation/schema';
import { generateHandleEditOffense } from 'simpl-schema-validation/helpers/generateHandleEdit';

export const validateArrestees = () => {
  const currentState = store.store.getState().incident;
  const incArrestees = currentState.incArrestees;
  const parties = currentState.parties;

  let validationErrors = [];

  for (let incArrestee of incArrestees) {
    let arresteeParty = parties.find(
      (p) => p.ptsIncPersonId === incArrestee.incArresteeDetails.ptsIncPersonId
    );
    let fullName = `${arresteeParty.PersonId} | ${
      arresteeParty.personDetail.values.lastName || ''
    }, ${arresteeParty.personDetail.values.firstName || ''} ${
      arresteeParty.personDetail.values.middleName || ''
    }`;

    let objToValidate = {
      arrestDate: new Date(incArrestee.incArresteeDetails.values.arrestDate),
      arresteeArmedWith: incArrestee.incArresteeDetails.values.arresteeArmedWith,
      arresteeSegmentsIndicator: incArrestee.incArresteeDetails.values.arresteeSegmentsIndicator,
      incident: currentState.incident,
      arresteeParty,
      juvenileDisposition: incArrestee.incArresteeDetails.values.juvenileDisposition,
    };
    let arresteesValidationContext = arresteesSchema.newContext();
    let result = arresteesValidationContext.validate(objToValidate, {
      keys: ['arrestDate', 'arresteeArmedWith', 'arresteeSegmentsIndicator', 'juvenileDisposition'],
    });

    if (!result) {
      arresteesValidationContext.validationErrors().forEach((error) => {
        validationErrors.push({
          id: incArrestee.incArresteeDetails.ptsArresteeId,
          ptsIncPersonId: incArrestee.incArresteeDetails.ptsIncPersonId,
          ptsOffenseId: incArrestee.incArresteeDetails.ptsOffenseId,
          idType: 'ptsArresteeId',
          errType: error.type,
          field: error.name,
          name: fullName,
          message: arresteesValidationContext.keyErrorMessage(error.name),
          ptsIncidentId: currentState.ptsIncidentId,
          handleEdit: generateHandleEditOffense(
            currentState.ptsIncidentId,
            incArrestee.incArresteeDetails.ptsOffenseId,
            5
          ),
        });
      });
    }
  }

  // console.log('883 parties errors', validationErrors);
  return validationErrors;
};
