import React from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Grid from '@material-ui/core/Grid';
import lime from '@material-ui/core/colors/lime';
import deepPurple from '@material-ui/core/colors/deepPurple';
import amber from '@material-ui/core/colors/amber';
import deepOrange from '@material-ui/core/colors/deepOrange';
import teal from '@material-ui/core/colors/teal';
import pink from '@material-ui/core/colors/pink';
import RMSGridMenu from '../../components/RMSGridMenu';
import { PropertiesMenu } from '../../components/RMSVerticalMenu';

const useStyles = makeStyles({
  root: {
    height: '1000px',
  },
  columns: {
    height: '1000px',
  },
  columnHeading: {
    margin: ' 5px',
    textAlign: 'center',
    fontWeight: 'bold',
    color: 'white',
  },
  badge1: {
    backgroundColor: lime['A700'],
    margin: '3px',
    borderRadius: '5px',
    paddingLeft: '5px',
    paddingRight: '5px',
    color: 'white',
  },
  badge2: {
    backgroundColor: deepPurple['A400'],
    margin: '3px',
    borderRadius: '5px',
    paddingLeft: '5px',
    paddingRight: '5px',
    color: 'white',
  },
  badge3: {
    backgroundColor: amber['A400'],
    margin: '3px',
    borderRadius: '5px',
    paddingLeft: '5px',
    paddingRight: '5px',
    color: 'white',
  },
  badge4: {
    backgroundColor: deepOrange['A400'],
    margin: '3px',
    borderRadius: '5px',
    paddingLeft: '5px',
    paddingRight: '5px',
    color: 'white',
  },
  badge5: {
    backgroundColor: teal['A400'],
    margin: '3px',
    borderRadius: '5px',
    paddingLeft: '5px',
    paddingRight: '5px',
    color: 'white',
  },
  badge6: {
    backgroundColor: pink['A400'],
    margin: '3px',
    borderRadius: '5px',
    paddingLeft: '5px',
    paddingRight: '5px',
    color: 'white',
  },
  badgeFont: {
    fontSize: '0.8em',
    fontWeight: 'bold',
    margin: '3px',
  },
});

const PageRMSDev = () => {
  const classes = useStyles();

  return (
    <Grid className={classes.root} container spacing={3}>
      <RMSGridMenu />
      <PropertiesMenu />
    </Grid>
  );
};

export default PageRMSDev;
