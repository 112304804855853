import React from 'react';
import { connect } from 'react-redux';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import { GrCar } from 'react-icons/gr';

const useStyles = makeStyles((theme) => ({
  icon: {
    marginRight: theme.spacing(2),
  },
}));

function VehicleSearch(props) {
  const { wsClient, onReset } = props;
  const classes = useStyles();
  const [value, setValue] = React.useState(null);
  const [inputValue, setInputValue] = React.useState('');
  const [options, setOptions] = React.useState([]);

  React.useEffect(() => {
    if (inputValue === '' || inputValue.length < 3) {
      setOptions(value ? [value] : []);
      return undefined;
    }

    (async () => {
      try {
        const service = wsClient.websocket.service('rms-property-vehicle-search');
        service.timeout = 20000;
        const vehicles = await service.find({ query: { searchText: inputValue } });
        
        console.log(vehicles);
        if (vehicles.data.length > 0) {
          setOptions(vehicles.data);
        }
      } catch (error) {
        console.log(error);
      }
    })();
  }, [value, inputValue, wsClient.websocket]);

  const getOptionLabel = (option) => {
    let label = '';
    label += option.PlateNumber ? `Plate Number ${option.PlateNumber} ` : '';
    label += option.PlateState ? `Plate State ${option.PlateState} ` : '';
    label += option.VIN ? `Brand ${option.VIN} ` : '';
    label += option.Brand ? `Brand ${option.Brand} ` : '';
    label += option.Model ? `Model ${option.Model} ` : '';
    label += option.Make ? `Make ${option.Make} ` : '';
    label += option.PrimaryColor ? `Primary Color ${option.PrimaryColor} ` : '';
    label += option.SecondaryColor ? `Secondary Color ${option.SecondaryColor} ` : '';
    label += option.Year ? `Year ${option.Year} ` : '';
    return label;
  };

  const onChange = (ev, newValue) => {
    setOptions(newValue ? [newValue, ...options] : options);
    setValue(newValue);
    if (newValue) {
      if (newValue.ptsVehicleID !== null) {
        props.onVehicleValueSet &&
          props.onVehicleValueSet(
            newValue,
            props.setFormState,
            props.setChangeKey,
            props.changeKey
          );
      }
    } else {
      onReset && onReset(props.setFormState);
    }
  };

  const onInputChange = (ev, newInputValue) => {
    setInputValue(newInputValue);
  };

  const renderInput = (params) => (
    <TextField
      autoComplete="hidden"
      size="small"
      {...params}
      label="Search Vehicles by VIN or Plate Number"
      variant="outlined"
      fullWidth
    />
  );

  const renderOption = (option) => {
    let label = '';
    label += option.PlateNumber ? `Plate Number ${option.PlateNumber} ` : '';
    label += option.PlateState ? `Plate State ${option.PlateState} ` : '';
    label += option.VIN ? `VIN ${option.VIN} ` : '';
    label += option.Brand ? `Brand ${option.Brand} ` : '';
    label += option.Model ? `Model ${option.Model} ` : '';
    label += option.Make ? `Make ${option.Make} ` : '';
    label += option.PrimaryColor ? `Primary Color ${option.PrimaryColor} ` : '';
    label += option.SecondaryColor ? `Secondary Color ${option.SecondaryColor} ` : '';
    label += option.Year ? `Year ${option.Year} ` : '';

    return (
      <Grid container alignItems="center">
        <Grid item>
          <GrCar className={classes.icon} style={{ color: 'red', fontSize: '24px' }} />
        </Grid>
        <Grid item xs>
          <span
            style={{
              fontWeight: 400,
            }}>
            {label}
          </span>
        </Grid>
      </Grid>
    );
  };

  const getOptionSelected = (option, value) => {
    return option.ptsVehicleID === value.ptsVehicleID;
  };

  return (
    <Autocomplete
      size="small"
      autoComplete
      autoSelect
      autoHighlight
      id="vehicle-search"
      getOptionLabel={getOptionLabel}
      filterOptions={(x) => x}
      options={options}
      includeInputInList
      filterSelectedOptions
      className={classes.autocomplete}
      value={value || ''}
      onChange={onChange}
      onInputChange={onInputChange}
      renderInput={renderInput}
      renderOption={renderOption}
      getOptionSelected={getOptionSelected}
    />
  );
}

const mapStateToProps = (state) => ({
  wsClient: state.websocket,
});

export default connect(mapStateToProps)(VehicleSearch);
