import React, { Fragment } from 'react';
import TableCell from '@material-ui/core/TableCell';
import Avatar from '@material-ui/core/Avatar';
import Typography from '@material-ui/core/Typography';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { colors } from '../../../../config/colorVariables';

const useStyles = makeStyles((theme) => ({
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: colors.cyan,
    marginRight: theme.spacing(2),
  },
}));

const RMSStorageCell = (props) => {
  const classes = useStyles();
  const { row } = props;
  return (
    <Fragment>
      <TableCell key={row.location}>
        <td>
          <div className="d-flex align-items-center">
            <Avatar aria-label="avatar" className={classes.avatar}>
              {(row.location ? row.location : 'N').substring(0, 1)}
            </Avatar>
            <div>
              <Typography variant="h5" className={classes.incidentLabel}>
                <b>{row.location}</b>
              </Typography>
            </div>
          </div>
        </td>
      </TableCell>
      <TableCell key={row.sub}>{row.sub}</TableCell>
      <TableCell key={row.date}>{row.date}</TableCell>
    </Fragment>
  );
};

export default RMSStorageCell;
