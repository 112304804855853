import React from 'react';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import { connect } from 'react-redux';
import { updateIncOffensesDVVictimForm } from '../../../../reducers/IncReducer';
import { handleFormChangeNew } from '../../../../reducers/helpers/formHelpers';
import FormControl from '@material-ui/core/FormControl';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';

const selections = [
  { key: 'IsMedicalNone', value: 'None' },
  { key: 'IsWillSeekOwnDoctor', value: 'Will Seek Own Doctor' },
  { key: 'IsParamedics', value: 'Paramedics' },
  { key: 'IsFirstAid', value: 'First Aid' },
  { key: 'IsParamedicAtScene', value: 'Paramedic at Scene' },
  { key: 'IsHospital', value: 'Hospital' },
  { key: 'IsRefusedMedicalAid', value: 'Refused Medical Aid' },
  { key: 'IsMedicalOther', value: 'Other' },
];

const useStyles = makeStyles((theme) => ({
  formControl: (props) => ({
    margin: theme.spacing(1),
    minWidth: '100%',
    maxWidth: '100%',
  }),
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chip: {
    margin: 2,
  },
  noLabel: {
    marginTop: theme.spacing(3),
  },
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const MedicalForm = (props) => {
  const classes = useStyles(props);
  const { offensesDVVictimForm, updateIncOffensesDVVictimForm, dvVictims, selectedVictim } = props;

  const [offensesDVVictimFormState, setOffensesDVVictimFormState] = React.useState({
    isValid: false,
    changes: false,
    values: {},
    touched: {},
    errors: {},
  });

  React.useEffect(() => {
    async function fetch() {
      let dvVictimDetails = dvVictims.find(
        (dvVictim) => dvVictim.ptsIncPersonId === selectedVictim
      );
      if (dvVictimDetails) {
        await updateIncOffensesDVVictimForm(dvVictimDetails);
        await setOffensesDVVictimFormState(dvVictimDetails);
      }
    }
    // Fetching current selected victim's dv details
    fetch();
  }, []);

  React.useEffect(() => {
    setOffensesDVVictimFormState({
      ...offensesDVVictimFormState,
      values: { ...offensesDVVictimFormState.values, ...offensesDVVictimForm.values },
    });
  }, [offensesDVVictimForm]);

  React.useEffect(() => {
    offensesDVVictimFormState.changes = true;
    updateIncOffensesDVVictimForm(offensesDVVictimFormState);
  }, [
    ...selections.map((s) => offensesDVVictimFormState.values[s.key]),
    offensesDVVictimFormState.values?.ParamedicPhysician,
    offensesDVVictimFormState.values?.ParamedicUnit,
    offensesDVVictimFormState.values?.ParamedicNames,
    offensesDVVictimFormState.values?.ParamedicHospital,
  ]);

  return (
    <Grid container justify="center">
      <Grid item xs={10} lg-={10}>
        <FormControl size="small" className={classes.formControl}>
          <Grid>
            {selections.map((selection) => (
              <FormControlLabel
                control={
                  <Checkbox
                    checked={offensesDVVictimFormState.values[selection.key] || false}
                    name={selection.key}
                    onChange={(event) =>
                      handleFormChangeNew(
                        event,
                        event.target.checked,
                        'none',
                        selection.key,
                        setOffensesDVVictimFormState
                      )
                    }
                  />
                }
                label={selection.value}
              />
            ))}
          </Grid>
        </FormControl>
      </Grid>
      <Grid item xs={10} lg={10}>
        <TextField
          autoComplete="hidden"
          size="small"
          className="m-3"
          id="standard-textarea-paramedic-unit"
          label="Paramedic Unit"
          placeholder="Unit"
          variant="outlined"
          fullWidth
          multiline
          name="ParamedicUnit"
          defaultValue={offensesDVVictimFormState.values.ParamedicUnit}
          onBlur={(event) =>
            handleFormChangeNew(
              event,
              event.target.value,
              'none',
              'ParamedicUnit',
              setOffensesDVVictimFormState
            )
          }
          InputLabelProps={{ shrink: true }}
        />
      </Grid>
      <Grid item xs={10} lg={10}>
        <TextField
          autoComplete="hidden"
          size="small"
          className="m-3"
          id="standard-textarea-paramedic-names"
          label="Paramedic Names"
          placeholder="Names"
          variant="outlined"
          name="ParamedicNames"
          defaultValue={offensesDVVictimFormState.values.ParamedicNames}
          onBlur={(event) =>
            handleFormChangeNew(
              event,
              event.target.value,
              'none',
              'ParamedicNames',
              setOffensesDVVictimFormState
            )
          }
          fullWidth
          multiline
          InputLabelProps={{ shrink: true }}
        />
      </Grid>
      <Grid item xs={10} lg={10}>
        <TextField
          autoComplete="hidden"
          size="small"
          className="m-3"
          id="standard-textarea-hospital"
          label="Hospital"
          placeholder="Hospital"
          variant="outlined"
          fullWidth
          name="ParamedicHospital"
          defaultValue={offensesDVVictimFormState.values?.ParamedicHospital}
          onBlur={(event) =>
            handleFormChangeNew(
              event,
              event.target.value,
              'none',
              'ParamedicHospital',
              setOffensesDVVictimFormState
            )
          }
          multiline
          InputLabelProps={{ shrink: true }}
        />
      </Grid>
      <Grid item xs={10} lg={10}>
        <TextField
          autoComplete="hidden"
          size="small"
          className="m-3"
          id="standard-textarea-physician"
          label="Physician"
          placeholder="Physician"
          variant="outlined"
          name="ParamedicPhysician"
          defaultValue={offensesDVVictimFormState.values?.ParamedicPhysician}
          onBlur={(event) =>
            handleFormChangeNew(
              event,
              event.target.value,
              'none',
              'ParamedicPhysician',
              setOffensesDVVictimFormState
            )
          }
          fullWidth
          multiline
          InputLabelProps={{ shrink: true }}
        />
      </Grid>
    </Grid>
  );
};

const mapStateToProps = (state) => ({
  offensesDVVictimForm: state.incident.offensesDVVictimForm,
  selectedVictim: state.incident.selectedVictim,
  dvVictims: state.incident.dvVictims,
});

export default connect(mapStateToProps, { updateIncOffensesDVVictimForm })(MedicalForm);
