import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import React, { Fragment } from 'react';
import Typography from '@material-ui/core/Typography';
import makeStyles from '@material-ui/core/styles/makeStyles';
import DetailsIcon from '@material-ui/icons/Details';

const useStyles = makeStyles((theme) => ({
  title: {
    color: 'grey',
  },
  icon: {
    marginRight: theme.spacing(2),
  },
}));

const RMSCollapsibleList = (props) => {
  const [expanded, setExpanded] = React.useState(false);
  const { panelItems } = props;
  const classes = useStyles();

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <Fragment>
      {panelItems.map((item) => {
        return (
          <ExpansionPanel expanded={expanded === item.name} onChange={handleChange(item.name)}>
            <ExpansionPanelSummary
              expandIcon={<DetailsIcon />}
              aria-controls="panel1bh-content"
              id="panel1bh-header">
              <Typography variant="h5" className={classes.title}>
                <span className={classes.icon}>{item.icon}</span>
                {item.title}
              </Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>{item.details}</ExpansionPanelDetails>
          </ExpansionPanel>
        );
      })}
    </Fragment>
  );
};

export default RMSCollapsibleList;
