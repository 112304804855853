import React from 'react';
import { connect } from 'react-redux';

import { v4 as uuidv4 } from 'uuid';
import DateFnsUtils from '@date-io/date-fns';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Divider from '@material-ui/core/Divider';
import { MuiPickersUtilsProvider, KeyboardDateTimePicker } from '@material-ui/pickers';

import { updateIncPropertyCredentialsAnalysisForm } from '../../../reducers/IncReducer';
import { getCodeVehicleCommercial } from '../../../reducers/dictionaries/CodeVehicleCommercialReducer';
import { getCodeTransmission } from '../../../reducers/dictionaries/CodeTransmissionReducer';
import { getCodeVehicleProperty } from '../../../reducers/dictionaries/CodeVehiclePropertyReducer';
import { getCodeGarageFacilities } from '../../../reducers/dictionaries/CodeGarageReducer';
import { handleFormChangeNew } from '../../../reducers/helpers/formHelpers';
import { getCodeStates } from '../../../reducers/dictionaries/CodeStatesReducer';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import RMSAutoComplete from 'components/RMSAutoComplete/RMSAutoComplete';
import {
  setDuplicateModal,
  setDuplicateModalMessage,
  setDuplicateVehicleList,
} from 'reducers/ui/UiModalReducer';
import { duplicateCheck } from 'reducers/helpers/duplicateCheckHelpers';
import {
  getCodePlateColors,
  getCodePlateStatuses,
  getCodePlateTypes,
  getCodePlateDetails,
} from 'reducers/dictionaries/CodeVehiclePlateReducer';

const TabCredentialsAnalysis = (props) => {
  /** region Component Variables and Props */
  const {
    updateIncPropertyCredentialsAnalysisForm,
    codeVehicleProperty,
    codeVehicleCommercial,
    codeTransmissions,
    getCodeVehicleProperty,
    getCodeVehicleCommercial,
    getCodeTransmission,
    getCodeGarageFacilities,
    codeGarageFacilities,
    wsClient,
    codePlateTypes,
    codePlateColors,
    codePlateStatuses,
    codeStates,
    codePlateDetails,
    getCodePlateDetails,
    getCodeStates,
    getCodePlateTypes,
    getCodePlateColors,
    getCodePlateStatuses,
    setDuplicateModal,
    setDuplicateModalMessage,
    setDuplicateVehicleList,
    propertyVehicleDetailsForm,
    propertyCredentialsAnalysisForm,
  } = props;
  const [changeKey, setChangeKey] = React.useState(0);
  const [
    propertyCredentialsAnalysisFormState,
    setPropertyCredentialsAnalysisFormState,
  ] = React.useState({
    tempPropId: uuidv4(),
    isValid: false,
    changes: false,
    values: {},
    touched: {},
    errors: {},
  });
  /** endregion */
  /** region React Helper Functions */
  const duplicateFinder = async () => {
    let duplicateFound = await duplicateCheck(propertyCredentialsAnalysisFormState, 'vehicle');
    if (duplicateFound) {
      setDuplicateModal(true);
      setDuplicateModalMessage('Duplciate Found');
    } else {
      setDuplicateVehicleList([]);
      setDuplicateModal(false);
    }
  };
  /** endregion */
  /** region React Hooks */
  React.useEffect(() => {
    if (wsClient.websocket) {
      getCodeGarageFacilities();
    }
  }, [getCodeGarageFacilities, wsClient]);

  React.useEffect(() => {
    const services = [
      'codeStates',
      'codeVehicleProperty',
      'codeVehicleCommercial',
      'codeTransmissions',
    ];

    if (!wsClient.websocket) return;

    getCodePlateTypes();
    getCodePlateColors();
    getCodePlateStatuses();
    getCodePlateDetails();

    services.forEach(async (svc) => {
      const result = await wsClient.websocket.service(svc.toLowerCase()).find({
        query: {
          $limit: 0,
          $select: ['Code', 'Description'],
          $sort: {
            Code: 1,
          },
          IsDeleted: false,
        },
      });
      if (result.total > 0) {
        if (svc === 'codeVehicleProperty') {
          getCodeVehicleProperty(result.data);
        } else if (svc === 'codeVehicleCommercial') {
          getCodeVehicleCommercial(result.data);
        } else if (svc === 'codeTransmissions') {
          getCodeTransmission(result.data);
        } else if (svc === 'codeStates') {
          getCodeStates(result.data);
        }
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [wsClient]);

  React.useEffect(() => {
    setPropertyCredentialsAnalysisFormState((propertyCredentialsAnalysisFormState) => ({
      ...propertyCredentialsAnalysisFormState,
      ...propertyCredentialsAnalysisForm,
      values: {
        ...propertyCredentialsAnalysisFormState.values,
        ...propertyCredentialsAnalysisForm.values,
      },
    }));
  }, [propertyCredentialsAnalysisForm]);

  React.useEffect(() => {
    let timeoutId = setTimeout(() => {
      if (
        Object.keys(propertyVehicleDetailsForm.values).length !== 0 &&
        Object.keys(propertyCredentialsAnalysisForm.values).length !== 0
      )
        duplicateFinder();
    }, 2000);
    propertyCredentialsAnalysisFormState.changes = true;
    updateIncPropertyCredentialsAnalysisForm(propertyCredentialsAnalysisFormState);
    return () => clearTimeout(timeoutId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    propertyCredentialsAnalysisFormState.values.incidentId,
    propertyCredentialsAnalysisFormState.values.vehicleId,
    propertyCredentialsAnalysisFormState.values.vin,
    propertyCredentialsAnalysisFormState.values.vinAnalysisTest,
    propertyCredentialsAnalysisFormState.values.propertyId,
    propertyCredentialsAnalysisFormState.values.propertyCode,
    propertyCredentialsAnalysisFormState.values.commercialId,
    propertyCredentialsAnalysisFormState.values.commercialCode,
    propertyCredentialsAnalysisFormState.values.cmv,
    propertyCredentialsAnalysisFormState.values.emissionInspection,
    propertyCredentialsAnalysisFormState.values.transmissionCode,
    propertyCredentialsAnalysisFormState.values.rented,
    propertyCredentialsAnalysisFormState.values.wanted,
    propertyCredentialsAnalysisFormState.values.brander,
    propertyCredentialsAnalysisFormState.values.garageIndicator,
    propertyCredentialsAnalysisFormState.values.garageFacility,
    propertyCredentialsAnalysisFormState.values.plateState,
    propertyCredentialsAnalysisFormState.values.plateStatus,
    propertyCredentialsAnalysisFormState.values.plateColor,
    propertyCredentialsAnalysisFormState.values.plateType,
    propertyCredentialsAnalysisFormState.values.plateNumber,
    propertyCredentialsAnalysisFormState.values.plateExpiration,
    propertyCredentialsAnalysisFormState.values.plateDetail,
    propertyCredentialsAnalysisFormState.values.plateBackgroundText,
  ]);
  /** endregion */
  return (
    <Grid container>
      <Grid xs={12} className="mb-3">
        <span style={{ color: '#fff', background: '#1976d2' }} className="mb-1 badge badge-primary">
          Credentials
        </span>
        <Divider />
      </Grid>
      <Grid container spacing={2} className="mb-2">
        <Grid item xs={6} lg={6}>
          <TextField
            id="standard-textarea-credentialsAnalysis-vin"
            autoComplete="hidden"
            size="small"
            label="VIN"
            placeholder="VIN"
            variant="outlined"
            name="vin"
            key={changeKey}
            defaultValue={propertyCredentialsAnalysisFormState.values.vin}
            onBlur={(event) =>
              handleFormChangeNew(
                event,
                event.target.value,
                'none',
                'vin',
                setPropertyCredentialsAnalysisFormState
              )
            }
            fullWidth
            multiline
            InputLabelProps={{ shrink: true }}
          />
        </Grid>
        <Grid item xs={6} lg={6}>
          <TextField
            id="standard-textarea-credentialsAnalysis-vinAnalysisTest"
            autoComplete="hidden"
            size="small"
            label="VIN Analysis Test"
            placeholder="VIN Analysis Test"
            variant="outlined"
            name="vinAnalysisTest"
            key={changeKey}
            defaultValue={propertyCredentialsAnalysisFormState.values.vinAnalysisTest}
            onBlur={(event) =>
              handleFormChangeNew(
                event,
                event.target.value,
                'none',
                'vinAnalysisTest',
                setPropertyCredentialsAnalysisFormState
              )
            }
            fullWidth
            multiline
            InputLabelProps={{ shrink: true }}
          />
        </Grid>
      </Grid>
      <Grid container spacing={2} justify="center" className="mb-2">
        <Grid item xs={4} lg={4}>
          <TextField
            id="standard-textarea-credentialsAnalysis-propertyId"
            autoComplete="hidden"
            size="small"
            label="Property ID"
            placeholder="Property ID"
            variant="outlined"
            name="propertyId"
            key={changeKey}
            defaultValue={propertyCredentialsAnalysisFormState.values.propertyId}
            onBlur={(event) =>
              handleFormChangeNew(
                event,
                event.target.value,
                'none',
                'propertyId',
                setPropertyCredentialsAnalysisFormState
              )
            }
            fullWidth
            multiline
            InputLabelProps={{ shrink: true }}
          />
        </Grid>
        <Grid item xs={4} lg={4}>
          <RMSAutoComplete
            size="small"
            autoComplete
            autoSelect
            autoHighlight
            id="combo-vehicle-property-code"
            options={codeVehicleProperty}
            label="Property Code"
            serviceName="codevehicleproperty"
            title="Code"
            stateHolderAttribute="propertyCode"
            variant="outlined"
            stateHolder={propertyCredentialsAnalysisFormState}
            setStateHolder={setPropertyCredentialsAnalysisFormState}
            setField={handleFormChangeNew}
          />
        </Grid>
        <Grid item xs={4} lg={4}>
          <TextField
            id="standard-textarea-credentialsAnalysis-commercialId"
            autoComplete="hidden"
            size="small"
            label="Commercial ID"
            placeholder="Commercial ID"
            variant="outlined"
            name="commercialId"
            key={changeKey}
            defaultValue={propertyCredentialsAnalysisFormState.values.commercialId}
            onBlur={(event) =>
              handleFormChangeNew(
                event,
                event.target.value,
                'none',
                'commercialId',
                setPropertyCredentialsAnalysisFormState
              )
            }
            fullWidth
            multiline
            InputLabelProps={{ shrink: true }}
          />
        </Grid>
      </Grid>
      <Grid container spacing={2} justify="center" className="mb-0">
        <Grid item xs={4} lg={4}>
          <RMSAutoComplete
            size="small"
            autoComplete
            autoSelect
            autoHighlight
            id="combo-vehicle-commercial-code"
            options={codeVehicleCommercial}
            label="Commercial Code"
            serviceName="codevehiclecommercial"
            title="Code"
            stateHolderAttribute="commercialCode"
            variant="outlined"
            stateHolder={propertyCredentialsAnalysisFormState}
            setStateHolder={setPropertyCredentialsAnalysisFormState}
            setField={handleFormChangeNew}
          />
        </Grid>
        <Grid item xs={1} lg={1}>
          <FormControlLabel
            control={
              <Checkbox
                key={propertyCredentialsAnalysisFormState.values.cmv}
                checked={propertyCredentialsAnalysisFormState.values.cmv}
                onChange={(event) =>
                  handleFormChangeNew(
                    event,
                    event.target.checked,
                    'none',
                    'cmv',
                    setPropertyCredentialsAnalysisFormState
                  )
                }
                name="cmv"
              />
            }
            label="CMV"
          />
        </Grid>
        <Grid item xs={3} lg={3}>
          <TextField
            id="standard-textarea-credentialsAnalysis-emissionInspection"
            autoComplete="hidden"
            size="small"
            label="Emission Inspection"
            placeholder="Emission Inspection"
            variant="outlined"
            name="emissionInspection"
            key={changeKey}
            defaultValue={propertyCredentialsAnalysisFormState.values.emissionInspection}
            onBlur={(event) =>
              handleFormChangeNew(
                event,
                event.target.value,
                'none',
                'emissionInspection',
                setPropertyCredentialsAnalysisFormState
              )
            }
            fullWidth
            multiline
            InputLabelProps={{ shrink: true }}
          />
        </Grid>
        <Grid item xs={4} lg={4}>
          <RMSAutoComplete
            size="small"
            autoComplete
            autoSelect
            autoHighlight
            id="combo-vehicle-transmission"
            options={codeTransmissions}
            label="Transmission Code"
            serviceName="codetransmissions"
            title="Code"
            stateHolderAttribute="transmissionCode"
            variant="outlined"
            stateHolder={propertyCredentialsAnalysisFormState}
            setStateHolder={setPropertyCredentialsAnalysisFormState}
            setField={handleFormChangeNew}
          />
        </Grid>
      </Grid>
      <Grid item xs={12} lg={12} className="mb-2">
        <span style={{ color: '#fff', background: '#1976d2' }} className="m-1 badge badge-primary">
          Registration Details
        </span>
        <Divider />
      </Grid>

      <Grid container spacing={3}>
        <Grid item xs={4} lg={4} className="mt-1">
          <TextField
            id="standard-textarea-credentialsAnalysis-plate-number"
            autoComplete="hidden"
            size="small"
            label="Plate Number"
            placeholder="Plate Number"
            variant="outlined"
            name="plateNumber"
            key={changeKey}
            defaultValue={propertyCredentialsAnalysisFormState.values.plateNumber}
            onBlur={(event) =>
              handleFormChangeNew(
                event,
                event.target.value,
                'none',
                'plateNumber',
                setPropertyCredentialsAnalysisFormState
              )
            }
            fullWidth
            multiline
            InputLabelProps={{ shrink: true }}
          />
        </Grid>

        <Grid item xs={4} lg={4} className="mt-1">
          <RMSAutoComplete
            size="small"
            autoComplete
            autoSelect
            autoHighlight
            id="combo-plate-type"
            options={codePlateTypes || []}
            label="Plate Type"
            serviceName="codeplatetypes"
            title="Code"
            stateHolderAttribute="plateType"
            variant="outlined"
            stateHolder={propertyCredentialsAnalysisFormState}
            setStateHolder={setPropertyCredentialsAnalysisFormState}
            setField={handleFormChangeNew}
          />
        </Grid>

        <Grid item xs={4} lg={4} className="mt-1">
          <RMSAutoComplete
            size="small"
            autoComplete
            autoSelect
            autoHighlight
            id="combo-plate-color"
            options={codePlateColors || []}
            label="Plate Color"
            serviceName="codeplatecolors"
            title="Code"
            stateHolderAttribute="plateColor"
            variant="outlined"
            stateHolder={propertyCredentialsAnalysisFormState}
            setStateHolder={setPropertyCredentialsAnalysisFormState}
            setField={handleFormChangeNew}
          />
        </Grid>
      </Grid>

      <Grid container spacing={3} className="mt-1">
        <Grid item xs={4} lg={4}>
          <RMSAutoComplete
            size="small"
            autoComplete
            autoSelect
            autoHighlight
            id="combo-plate-status"
            options={codePlateStatuses || []}
            label="Plate Status"
            serviceName="codeplatestatuses"
            title="Code"
            stateHolderAttribute="plateStatus"
            variant="outlined"
            stateHolder={propertyCredentialsAnalysisFormState}
            setStateHolder={setPropertyCredentialsAnalysisFormState}
            setField={handleFormChangeNew}
          />
        </Grid>

        <Grid item xs={4} lg={4}>
          <RMSAutoComplete
            size="small"
            autoComplete
            autoSelect
            autoHighlight
            id="combo-plate-state"
            options={codeStates || []}
            label="Plate State"
            serviceName="codestates"
            title="Code"
            stateHolderAttribute="plateState"
            variant="outlined"
            stateHolder={propertyCredentialsAnalysisFormState}
            setStateHolder={setPropertyCredentialsAnalysisFormState}
            setField={handleFormChangeNew}
          />
        </Grid>

        <Grid item xs={4} lg={4}>
          <RMSAutoComplete
            size="small"
            autoComplete
            autoSelect
            autoHighlight
            id="combo-plate-detail"
            options={codePlateDetails || []}
            label="Plate Detail"
            serviceName="codeplatedetails"
            title="Code"
            stateHolderAttribute="plateDetail"
            variant="outlined"
            stateHolder={propertyCredentialsAnalysisFormState}
            setStateHolder={setPropertyCredentialsAnalysisFormState}
            setField={handleFormChangeNew}
          />
        </Grid>
      </Grid>

      <Grid container spacing={3} className="mt-1">
        <Grid item xs={4} lg={4}>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <KeyboardDateTimePicker
              size="small"
              inputVariant="outlined"
              fullWidth
              showTodayButton={true}
              variant="dialog"
              format="MM/dd/yyyy hh:mm a"
              id="date-picker-inline-from"
              label="Plate Expiration"
              value={propertyCredentialsAnalysisFormState.values.plateExpiration || null}
              onChange={(date) => {
                setPropertyCredentialsAnalysisFormState((state) => {
                  return {
                    ...state,
                    values: {
                      ...state.values,
                      plateExpiration: date,
                    },
                  };
                });
              }}
              KeyboardButtonProps={{
                'aria-label': 'change date',
              }}
            />
          </MuiPickersUtilsProvider>
        </Grid>

        <Grid item xs={8} lg={8}>
          <TextField
            id="standard-textarea-credentialsAnalysis-plate-background-text"
            autoComplete="hidden"
            size="small"
            label="Plate Background Text"
            placeholder="Plate Background Text"
            variant="outlined"
            name="plateBackgroundText"
            key={changeKey}
            defaultValue={propertyCredentialsAnalysisFormState.values.plateBackgroundText}
            onBlur={(event) =>
              handleFormChangeNew(
                event,
                event.target.value,
                'none',
                'plateBackgroundText',
                setPropertyCredentialsAnalysisFormState
              )
            }
            fullWidth
            multiline
            InputLabelProps={{ shrink: true }}
          />
        </Grid>
      </Grid>

      <Grid item xs={12} lg={12} className="mt-3 mb-2">
        <span style={{ color: '#fff', background: '#1976d2' }} className="m-1 badge badge-primary">
          Flags
        </span>
        <Divider />
      </Grid>
      <Grid container justify="center" spacing={2}>
        <Grid item xs={3} sm={3} md={2} lg={2} xl={1}>
          <FormControlLabel
            control={
              <Checkbox
                key={propertyCredentialsAnalysisFormState.values.rented}
                checked={propertyCredentialsAnalysisFormState.values.rented}
                onChange={(event) =>
                  handleFormChangeNew(
                    event,
                    event.target.checked,
                    'none',
                    'rented',
                    setPropertyCredentialsAnalysisFormState
                  )
                }
                name="rented"
              />
            }
            label="Rented"
          />
        </Grid>
        <Grid item xs={3} sm={3} md={2} lg={2} xl={1}>
          <FormControlLabel
            control={
              <Checkbox
                key={propertyCredentialsAnalysisFormState.values.wanted}
                checked={propertyCredentialsAnalysisFormState.values.wanted}
                onChange={(event) =>
                  handleFormChangeNew(
                    event,
                    event.target.checked,
                    'none',
                    'wanted',
                    setPropertyCredentialsAnalysisFormState
                  )
                }
                name="wanted"
              />
            }
            label="Wanted"
          />
        </Grid>
        <Grid item xs={3} sm={3} md={3} lg={3} xl={3}>
          <FormControlLabel
            control={
              <Checkbox
                key={propertyCredentialsAnalysisFormState.values.garageIndicator}
                checked={propertyCredentialsAnalysisFormState.values.garageIndicator}
                onChange={(event) =>
                  handleFormChangeNew(
                    event,
                    event.target.checked,
                    'none',
                    'garageIndicator',
                    setPropertyCredentialsAnalysisFormState
                  )
                }
                name="garageIndicator"
              />
            }
            label="Garage Indicator"
          />
        </Grid>
        <Grid item xs={3} sm={3} md={5} lg={5} xl={7} className="mt-1">
          <RMSAutoComplete
            size="small"
            autoComplete
            autoSelect
            autoHighlight
            id="combo-garage-facility"
            options={codeGarageFacilities}
            label="Garage Facility"
            serviceName="codegaragefacilities"
            title="Code"
            stateHolderAttribute="garageFacility"
            variant="outlined"
            stateHolder={propertyCredentialsAnalysisFormState}
            setStateHolder={setPropertyCredentialsAnalysisFormState}
            setField={handleFormChangeNew}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

const mapStateToProps = (state) => ({
  propertyCredentialsAnalysisForm: state.incident.propertyCredentialsAnalysisForm,
  propertyVehicleDetailsForm: state.incident.propertyVehicleDetailsForm,
  wsClient: state.websocket,
  codeVehicleProperty: state.codeVehicleProperty,
  codeVehicleCommercial: state.codeVehicleCommercial,
  codeTransmissions: state.codeTransmissions,
  codeGarageFacilities: state.codesGarage.codeGarageFacilities,
  codePlateTypes: state.vehiclePlate.codePlateTypes,
  codePlateStatuses: state.vehiclePlate.codePlateStatuses,
  codePlateColors: state.vehiclePlate.codePlateColors,
  codePlateDetails: state.vehiclePlate.codePlateDetails,
  codeStates: state.codeStates,
});

export default connect(mapStateToProps, {
  updateIncPropertyCredentialsAnalysisForm,
  getCodeVehicleCommercial,
  getCodeTransmission,
  getCodeGarageFacilities,
  getCodeVehicleProperty,
  setDuplicateModal,
  setDuplicateModalMessage,
  setDuplicateVehicleList,
  getCodeStates,
  getCodePlateDetails,
  getCodePlateTypes,
  getCodePlateColors,
  getCodePlateStatuses,
})(TabCredentialsAnalysis);
