import store from '../../config/configureStore';
import { incPropertiesSchema } from 'simpl-schema-validation/schema';

export const validateIncPropertiesForm = (field, form) => {
  const currentState = store.store.getState().incident;
  const {
    offenses,
    offensesOffenseForm,
    currentOffense,
    incProperties,
    selectedProperty,
  } = currentState;

  let objToValidate = {};

  if (form) {
    let property = form;

    let propertyOffense = offenses.find(
      (o) => o.offenseDetails.ptsOffenseId === property.ptsOffenseId
    );

    let incProperty = incProperties.find(
      (i) => i.incPropertyDetails.incIncidentPropertyId === selectedProperty
    );

    objToValidate = {
      [field]: property.values[field] || '',
      offense: propertyOffense?.offenseDetails || offensesOffenseForm,
      property: form,
      incProperty: incProperty,
    };

    if (field === 'value') {
      let propertyValue = parseInt(property.values.value);
      objToValidate.value = isNaN(propertyValue) ? null : propertyValue;
    }
  } else {
    let propertyOffense = offenses.find((o) => o.offenseDetails.ptsOffenseId === currentOffense);

    objToValidate = {
      [field]: field,
      offense: propertyOffense?.offenseDetails || offensesOffenseForm,
      incProperties: incProperties,
    };
  }

  let propertiesValidationContext = incPropertiesSchema.newContext();
  let result = propertiesValidationContext.validate(objToValidate, {
    keys: [field],
  });

  let propertiesFormErrors = [];
  if (!result) {
    propertiesValidationContext.validationErrors().forEach((error) => {
      if (field === error.name) {
        error.message = propertiesValidationContext.keyErrorMessage(error.name);
        propertiesFormErrors.push(error);
      }
    });
  }

  return propertiesFormErrors || [];
};
