export const ptsFBICode = [
  {
    FBICode: '0',
    FBIGroupCode: '',
    CrimeAgainst: '',
    Description: 'NONE',
    State: '',
    IsActive: true,
    ptsFBICodeID: 1,
    FBICodeID: '',
  },
  {
    FBICode: '09A',
    FBIGroupCode: 'A',
    CrimeAgainst: 'PERSON',
    Description: 'MURDER/NONNEGLIGENT MANSLAUGHTER',
    State: '',
    IsActive: true,
    ptsFBICodeID: 2,
    FBICodeID: '',
  },
  {
    FBICode: '09B',
    FBIGroupCode: 'A',
    CrimeAgainst: 'PERSON',
    Description: 'NEGLIGENT MANSLAUGHTER',
    State: '',
    IsActive: true,
    ptsFBICodeID: 3,
    FBICodeID: '',
  },
  {
    FBICode: '09C',
    FBIGroupCode: 'A',
    CrimeAgainst: 'PERSON',
    Description: 'JUSTIFIABLE HOMICIDE',
    State: '',
    IsActive: true,
    ptsFBICodeID: 4,
    FBICodeID: '',
  },
  {
    FBICode: '100',
    FBIGroupCode: 'A',
    CrimeAgainst: 'PERSON',
    Description: 'KIDNAPPING/ABDUCTION',
    State: '',
    IsActive: true,
    ptsFBICodeID: 5,
    FBICodeID: '',
  },
  {
    FBICode: '11A',
    FBIGroupCode: 'A',
    CrimeAgainst: 'PERSON',
    Description: 'FORCIBLE RAPE',
    State: '',
    IsActive: true,
    ptsFBICodeID: 6,
    FBICodeID: '',
  },
  {
    FBICode: '11B',
    FBIGroupCode: 'A',
    CrimeAgainst: 'PERSON',
    Description: 'FORCIBLE SODOMY',
    State: '',
    IsActive: true,
    ptsFBICodeID: 7,
    FBICodeID: '',
  },
  {
    FBICode: '11C',
    FBIGroupCode: 'A',
    CrimeAgainst: 'PERSON',
    Description: 'SEXUAL ASSAULT WITH AN OBJECT',
    State: '',
    IsActive: true,
    ptsFBICodeID: 8,
    FBICodeID: '',
  },
  {
    FBICode: '11D',
    FBIGroupCode: 'A',
    CrimeAgainst: 'PERSON',
    Description: 'FORCIBLE FONDLING (INDICENT LIBERTIES/CHILD MOLEST',
    State: '',
    IsActive: true,
    ptsFBICodeID: 9,
    FBICodeID: '',
  },
  {
    FBICode: '120',
    FBIGroupCode: 'A',
    CrimeAgainst: 'PROPERTY',
    Description: 'ROBBERY',
    State: '',
    IsActive: true,
    ptsFBICodeID: 10,
    FBICodeID: '',
  },
  {
    FBICode: '13A',
    FBIGroupCode: 'A',
    CrimeAgainst: 'PERSON',
    Description: 'AGGRAVATED ASSAULT',
    State: '',
    IsActive: true,
    ptsFBICodeID: 11,
    FBICodeID: '',
  },
  {
    FBICode: '13B',
    FBIGroupCode: 'A',
    CrimeAgainst: 'PERSON',
    Description: 'SIMPLE ASSAULT',
    State: '',
    IsActive: true,
    ptsFBICodeID: 12,
    FBICodeID: '',
  },
  {
    FBICode: '13C',
    FBIGroupCode: 'A',
    CrimeAgainst: 'PERSON',
    Description: 'INTIMIDATION',
    State: '',
    IsActive: true,
    ptsFBICodeID: 13,
    FBICodeID: '',
  },
  {
    FBICode: '200',
    FBIGroupCode: 'A',
    CrimeAgainst: 'PROPERTY',
    Description: 'ARSON',
    State: '',
    IsActive: true,
    ptsFBICodeID: 14,
    FBICodeID: '',
  },
  {
    FBICode: '210',
    FBIGroupCode: 'A',
    CrimeAgainst: 'PROPERTY',
    Description: 'EXTORTION BLACKMAIL',
    State: '',
    IsActive: true,
    ptsFBICodeID: 15,
    FBICodeID: '',
  },
  {
    FBICode: '220',
    FBIGroupCode: 'A',
    CrimeAgainst: 'PROPERTY',
    Description: 'BUGLARY/BREAKING AND ENTERING',
    State: '',
    IsActive: true,
    ptsFBICodeID: 16,
    FBICodeID: '',
  },
  {
    FBICode: '23A',
    FBIGroupCode: 'A',
    CrimeAgainst: 'PROPERTY',
    Description: 'POCKET PICKING',
    State: '',
    IsActive: true,
    ptsFBICodeID: 17,
    FBICodeID: '',
  },
  {
    FBICode: '23B',
    FBIGroupCode: 'A',
    CrimeAgainst: 'PROPERTY',
    Description: 'PURSE SNATCHING',
    State: '',
    IsActive: true,
    ptsFBICodeID: 18,
    FBICodeID: '',
  },
  {
    FBICode: '23C',
    FBIGroupCode: 'A',
    CrimeAgainst: 'PROPERTY',
    Description: 'SHOPLIFTING',
    State: '',
    IsActive: true,
    ptsFBICodeID: 19,
    FBICodeID: '',
  },
  {
    FBICode: '23D',
    FBIGroupCode: 'A',
    CrimeAgainst: 'PROPERTY',
    Description: 'THEFT FROM BUILDING',
    State: '',
    IsActive: true,
    ptsFBICodeID: 20,
    FBICodeID: '',
  },
  {
    FBICode: '23E',
    FBIGroupCode: 'A',
    CrimeAgainst: 'PROPERTY',
    Description: 'THEFT FROM COIN OPERATED MACHINE OR DEVICE',
    State: '',
    IsActive: true,
    ptsFBICodeID: 21,
    FBICodeID: '',
  },
  {
    FBICode: '23F',
    FBIGroupCode: 'A',
    CrimeAgainst: 'PROPERTY',
    Description: 'THEFT FROM MOTOR VEHICLE',
    State: '',
    IsActive: true,
    ptsFBICodeID: 22,
    FBICodeID: '',
  },
  {
    FBICode: '23G',
    FBIGroupCode: 'A',
    CrimeAgainst: 'PROPERTY',
    Description: 'THEFT OF MOTOR VEHICLE PARTS/ACCESSORIES',
    State: '',
    IsActive: true,
    ptsFBICodeID: 23,
    FBICodeID: '',
  },
  {
    FBICode: '23H',
    FBIGroupCode: 'A',
    CrimeAgainst: 'PROPERTY',
    Description: 'ALL OTHER LARCENY',
    State: '',
    IsActive: true,
    ptsFBICodeID: 24,
    FBICodeID: '',
  },
  {
    FBICode: '240',
    FBIGroupCode: 'A',
    CrimeAgainst: 'PROPERTY',
    Description: 'MOTOR VEHICLE THEFT',
    State: '',
    IsActive: true,
    ptsFBICodeID: 25,
    FBICodeID: '',
  },
  {
    FBICode: '250',
    FBIGroupCode: 'A',
    CrimeAgainst: 'PROPERTY',
    Description: 'COUNTERFEITING/FORGERY',
    State: '',
    IsActive: true,
    ptsFBICodeID: 26,
    FBICodeID: '',
  },
  {
    FBICode: '26A',
    FBIGroupCode: 'A',
    CrimeAgainst: 'PROPERTY',
    Description: 'FALSE PRETENSE/SWINDLE/CONFIDENCE GAME',
    State: '',
    IsActive: true,
    ptsFBICodeID: 27,
    FBICodeID: '',
  },
  {
    FBICode: '26B',
    FBIGroupCode: 'A',
    CrimeAgainst: 'PROPERTY',
    Description: 'CREDIT CARD/AUTOMATIC TELLER MACHINE FRAUD',
    State: '',
    IsActive: true,
    ptsFBICodeID: 28,
    FBICodeID: '',
  },
  {
    FBICode: '26C',
    FBIGroupCode: 'A',
    CrimeAgainst: 'PROPERTY',
    Description: 'IMPERSONATION',
    State: '',
    IsActive: true,
    ptsFBICodeID: 29,
    FBICodeID: '',
  },
  {
    FBICode: '26D',
    FBIGroupCode: 'A',
    CrimeAgainst: 'PROPERTY',
    Description: 'WELFARE FRAUD',
    State: '',
    IsActive: true,
    ptsFBICodeID: 30,
    FBICodeID: '',
  },
  {
    FBICode: '26E',
    FBIGroupCode: 'A',
    CrimeAgainst: 'PROPERTY',
    Description: 'WIRE FRAUD',
    State: '',
    IsActive: true,
    ptsFBICodeID: 31,
    FBICodeID: '',
  },
  {
    FBICode: '270',
    FBIGroupCode: 'A',
    CrimeAgainst: 'PROPERTY',
    Description: 'EMBEZZLEMENT',
    State: '',
    IsActive: true,
    ptsFBICodeID: 32,
    FBICodeID: '',
  },
  {
    FBICode: '280',
    FBIGroupCode: 'A',
    CrimeAgainst: 'PROPERTY',
    Description: 'STOLEN PROPERTY OFFENSES (RECEIVING, SELLING, ETC.',
    State: '',
    IsActive: true,
    ptsFBICodeID: 33,
    FBICodeID: '',
  },
  {
    FBICode: '290',
    FBIGroupCode: 'A',
    CrimeAgainst: 'PROPERTY',
    Description: 'DESTRUCTION/DAMAGE/VANDALISM OF PROPERTY',
    State: '',
    IsActive: true,
    ptsFBICodeID: 34,
    FBICodeID: '',
  },
  {
    FBICode: '35A',
    FBIGroupCode: 'A',
    CrimeAgainst: 'SOCIETY',
    Description: 'DRUG/NARCOTIC VIOLATION',
    State: '',
    IsActive: true,
    ptsFBICodeID: 35,
    FBICodeID: '',
  },
  {
    FBICode: '35B',
    FBIGroupCode: 'A',
    CrimeAgainst: 'SOCIETY',
    Description: 'DRUG EQUIPMENT VIOLATION',
    State: '',
    IsActive: true,
    ptsFBICodeID: 36,
    FBICodeID: '',
  },
  {
    FBICode: '36A',
    FBIGroupCode: 'A',
    CrimeAgainst: 'PERSON',
    Description: 'INCEST',
    State: '',
    IsActive: true,
    ptsFBICodeID: 37,
    FBICodeID: '',
  },
  {
    FBICode: '36B',
    FBIGroupCode: 'A',
    CrimeAgainst: 'PERSON',
    Description: 'STATUTORY RAPE',
    State: '',
    IsActive: true,
    ptsFBICodeID: 38,
    FBICodeID: '',
  },
  {
    FBICode: '370',
    FBIGroupCode: 'A',
    CrimeAgainst: 'SOCIETY',
    Description: 'PORNOGRAPHY/OBSCENE MATERIAL',
    State: '',
    IsActive: true,
    ptsFBICodeID: 39,
    FBICodeID: '',
  },
  {
    FBICode: '39A',
    FBIGroupCode: 'A',
    CrimeAgainst: 'SOCIETY',
    Description: 'BETTING/WAGERING',
    State: '',
    IsActive: true,
    ptsFBICodeID: 40,
    FBICodeID: '',
  },
  {
    FBICode: '39B',
    FBIGroupCode: 'A',
    CrimeAgainst: 'SOCIETY',
    Description: 'OPERATING/PROMOTING/ASSISTING GAMBLING',
    State: '',
    IsActive: true,
    ptsFBICodeID: 41,
    FBICodeID: '',
  },
  {
    FBICode: '39C',
    FBIGroupCode: 'A',
    CrimeAgainst: 'SOCIETY',
    Description: 'GAMBLING EQUIPMENT VIOLATION',
    State: '',
    IsActive: true,
    ptsFBICodeID: 42,
    FBICodeID: '',
  },
  {
    FBICode: '39D',
    FBIGroupCode: 'A',
    CrimeAgainst: 'SOCIETY',
    Description: 'SPORTS TAMPERING',
    State: '',
    IsActive: true,
    ptsFBICodeID: 43,
    FBICodeID: '',
  },
  {
    FBICode: '40A',
    FBIGroupCode: 'A',
    CrimeAgainst: 'SOCIETY',
    Description: 'PROSTITUTION',
    State: '',
    IsActive: true,
    ptsFBICodeID: 44,
    FBICodeID: '',
  },
  {
    FBICode: '40B',
    FBIGroupCode: 'A',
    CrimeAgainst: 'SOCIETY',
    Description: 'ASSISTING OR PROMOTING PROSTITUTION',
    State: '',
    IsActive: true,
    ptsFBICodeID: 45,
    FBICodeID: '',
  },
  {
    FBICode: '510',
    FBIGroupCode: 'A',
    CrimeAgainst: 'PROPERTY',
    Description: 'BRIBERY',
    State: '',
    IsActive: true,
    ptsFBICodeID: 46,
    FBICodeID: '',
  },
  {
    FBICode: '520',
    FBIGroupCode: 'A',
    CrimeAgainst: 'SOCIETY',
    Description: 'WEAPON LAW VIOLATION',
    State: '',
    IsActive: true,
    ptsFBICodeID: 47,
    FBICodeID: '',
  },
  {
    FBICode: '90A',
    FBIGroupCode: 'B',
    CrimeAgainst: 'PROPERTY',
    Description: 'BAD CHECKS',
    State: '',
    IsActive: true,
    ptsFBICodeID: 48,
    FBICodeID: '',
  },
  {
    FBICode: '90B',
    FBIGroupCode: 'B',
    CrimeAgainst: 'SOCIETY',
    Description: 'CURFEW/LOITERING/VAGRANCY VIOLATIONS',
    State: '',
    IsActive: true,
    ptsFBICodeID: 49,
    FBICodeID: '',
  },
  {
    FBICode: '90C',
    FBIGroupCode: 'B',
    CrimeAgainst: 'SOCIETY',
    Description: 'DISORDERLY CONDUCT',
    State: '',
    IsActive: true,
    ptsFBICodeID: 50,
    FBICodeID: '',
  },
  {
    FBICode: '90D',
    FBIGroupCode: 'B',
    CrimeAgainst: 'SOCIETY',
    Description: 'DRIVING UNDER THE INFLUENCE',
    State: '',
    IsActive: true,
    ptsFBICodeID: 51,
    FBICodeID: '',
  },
  {
    FBICode: '90E',
    FBIGroupCode: 'B',
    CrimeAgainst: 'SOCIETY',
    Description: 'DRUNKENESS',
    State: '',
    IsActive: true,
    ptsFBICodeID: 52,
    FBICodeID: '',
  },
  {
    FBICode: '90F',
    FBIGroupCode: 'B',
    CrimeAgainst: 'SOCIETY',
    Description: 'FAMILY OFFENSES, NONVIOLENT',
    State: '',
    IsActive: true,
    ptsFBICodeID: 53,
    FBICodeID: '',
  },
  {
    FBICode: '90G',
    FBIGroupCode: 'B',
    CrimeAgainst: 'SOCIETY',
    Description: 'LIQUOR LAW VIOLATIONS',
    State: '',
    IsActive: true,
    ptsFBICodeID: 54,
    FBICodeID: '',
  },
  {
    FBICode: '90H',
    FBIGroupCode: 'B',
    CrimeAgainst: 'SOCIETY',
    Description: 'PEEPING TOM',
    State: '',
    IsActive: true,
    ptsFBICodeID: 55,
    FBICodeID: '',
  },
  {
    FBICode: '90I',
    FBIGroupCode: 'B',
    CrimeAgainst: 'SOCIETY',
    Description: 'RUNAWAY',
    State: '',
    IsActive: true,
    ptsFBICodeID: 56,
    FBICodeID: '',
  },
  {
    FBICode: '90J',
    FBIGroupCode: 'B',
    CrimeAgainst: 'SOCIETY',
    Description: 'TRESPASS OF REAL PROPERTY',
    State: '',
    IsActive: true,
    ptsFBICodeID: 57,
    FBICodeID: '',
  },
  {
    FBICode: '90Z',
    FBIGroupCode: 'B',
    CrimeAgainst: '',
    Description: 'ALL OTHER OFFENSES',
    State: '',
    IsActive: true,
    ptsFBICodeID: 58,
    FBICodeID: '',
  },
  {
    FBICode: '40C',
    FBIGroupCode: 'A',
    CrimeAgainst: 'SOCIETY',
    Description: 'PURCHASING PROSTITUTION',
    State: '',
    IsActive: true,
    ptsFBICodeID: 59,
    FBICodeID: '',
  },
  {
    FBICode: '64A',
    FBIGroupCode: 'A',
    CrimeAgainst: 'PERSON',
    Description: 'HUMAN TRAFFICKING, COMMERCIAL SEX ACTS',
    State: '',
    IsActive: true,
    ptsFBICodeID: 60,
    FBICodeID: '',
  },
  {
    FBICode: '64B',
    FBIGroupCode: 'A',
    CrimeAgainst: 'PERSON',
    Description: 'HUMAN TRAFFICKING, INVOLUNTARY SERVITUDE',
    State: '',
    IsActive: true,
    ptsFBICodeID: 61,
    FBICodeID: '',
  },
  {
    FBICode: '13D',
    FBIGroupCode: 'A',
    CrimeAgainst: 'PERSON',
    Description: 'STALKING',
    State: '',
    IsActive: true,
    ptsFBICodeID: 62,
    FBICodeID: '',
  },
  {
    FBICode: 'test',
    FBIGroupCode: '',
    CrimeAgainst: 'test',
    Description: 'test',
    State: '',
    IsActive: true,
    ptsFBICodeID: 63,
    FBICodeID: '',
  },
  {
    FBICode: '09D',
    FBIGroupCode: 'A',
    CrimeAgainst: 'PERSON',
    Description: 'NEGLIGENT VEHICULAR MANSLAUGHTER',
    State: '',
    IsActive: true,
    ptsFBICodeID: 64,
    FBICodeID: '',
  },
  {
    FBICode: '26F',
    FBIGroupCode: 'A',
    CrimeAgainst: 'PROPERTY',
    Description: 'IDENTITY THEFT',
    State: '',
    IsActive: true,
    ptsFBICodeID: 65,
    FBICodeID: '',
  },
  {
    FBICode: '26G',
    FBIGroupCode: 'A',
    CrimeAgainst: 'PROPERTY',
    Description: 'Computer Hacking/Invasion',
    State: '',
    IsActive: true,
    ptsFBICodeID: 66,
    FBICodeID: '',
  },
  {
    FBICode: '720',
    FBIGroupCode: 'A',
    CrimeAgainst: 'SOCIETY',
    Description: 'Animal Cruelty',
    State: '',
    IsActive: true,
    ptsFBICodeID: 67,
    FBICodeID: '',
  },
];
