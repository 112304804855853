import React from 'react';
import { Fab, Tooltip } from '@material-ui/core';
import PersonIcon from '@material-ui/icons/PersonOutlined';

export default function RMSPersonButton(props) {
  const { onClick, disabled } = props;
  return (
    <Tooltip title={props.tooltipText}>
      <Fab size="small" disabled={disabled} color="secondary" aria-label="person">
        <PersonIcon onClick={onClick} />
      </Fab>
    </Tooltip>
  );
}
