import React from 'react';
import Grid from '@material-ui/core/Grid';
import DvAdditionalPanels from '../../components/RMSList/RMSCollapsibleList/DvAdditionalPanels';

const TabDomesticViolenceAdditional = () => {
  return (
    <Grid container spacing={3} justify="center">
      <Grid item xs={7} lg={7} className="mt-4 mb-4">
        <DvAdditionalPanels />
      </Grid>
    </Grid>
  );
};
export default TabDomesticViolenceAdditional;
