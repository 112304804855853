import SimpleSchema from 'simpl-schema';
import { substanceTypeCodes } from 'simpl-schema-validation/CategoryCodes/substanceType';

SimpleSchema.setDefaultMessages({
  messages: {
    en: {
      'NIBRS-2019.2-102': 'When Substance Quantity must be 1 when the Drug Measure is XX.',
      'NIBRS-2019.2-101':
        'When Substance Quantity is provided, then Drug Type and Drug Measure must be provided.',
      'NIBRS-2019.2-104':
        'When Substance Measure is provided, then Drug Type and Drug Quantity must be provided.',
      'NIBRS-2019.2-97':
        'No more than three Substance records can be provided in an Incident Report. ',
      'NIBRS-2019.2-94': 'Item Quantity must be 01-99 or 00 = Unknown.',
      'NIBRS-2019.2-103':
        'When Substance Measure code NP-Number of Plants is only valid for Substance type of (E, G, or K).',
      'NIBRS-2019.2-92':
        'When the Offense code = 240 and Property Loss type = Stolen, and the Offense Attempted is false to indicate the offense had been completed, then the number of Property Quantity is required.',
      'NIBRS-2019.2-95':
        'When the Offense code = 240 and Property Loss type = Recovered, and the Offense Attempted is false to indicate the offense had been completed, then the number of Property Quantity is required.',
    },
  },
});

export const propertiesSchema = new SimpleSchema({
  substanceQuantity: {
    type: SimpleSchema.Integer,
    optional: true,
    custom() {
      if (this.obj.property.values?.parentType?.toLowerCase() === 'substance') {
        // NIBRS-2019.2-102: Property Drug Quantity
        if (this.obj.property.values.quantityMeasure === 'XX') {
          if (this.value !== 1) {
            return 'NIBRS-2019.2-102';
          }
        }
      }
    },
  },
  quantityMeasure: {
    type: String,
    optional: true,
    custom() {
      if (this.obj.property.values?.parentType?.toLowerCase() === 'substance') {
        // NIBRS-2019.2-104: Property Drug Measure
        if (this.value) {
          if (!this.obj.property.values.substanceType || !this.obj.property.values.quantity) {
            return 'NIBRS-2019.2-104';
          }
        }

        // NIBRS-2019.2-101: Property Drug Quantity
        if (this.obj.property.values.quantity) {
          if (!this.value) {
            return 'NIBRS-2019.2-101';
          }
        }
      }
    },
  },
  quantity: {
    type: SimpleSchema.Integer,
    optional: true,
    custom() {
      if (this.obj.property.values?.parentType?.toLowerCase() === 'item') {
        if (this.value && !(this.value > 0 && this.value < 100)) {
          return 'NIBRS-2019.2-94';
        }

        if (this.obj.incPropertyOffense) {
          if (
            this.obj.incPropertyOffense.values.statuteDetails?.FBICode === '240' &&
            this.obj.incProperty.values.status === 'STOLEN' &&
            (!this.value || this.value === 0)
          ) {
            return 'NIBRS-2019.2-92';
          }

          if (
            this.obj.incPropertyOffense.values.statuteDetails?.FBICode === '240' &&
            this.obj.incProperty.values.status === 'RECOVERED' &&
            (!this.value || this.value === 0)
          ) {
            return 'NIBRS-2019.2-95';
          }
        }
      }
      if (this.obj.property.values?.parentType?.toLowerCase() === 'substance') {
        // NIBRS-2019.2-102: Property Drug Quantity
        if (this.obj.property.values.quantityMeasure === 'XX') {
          if (this.value !== 1) {
            return 'NIBRS-2019.2-102';
          }
        }
      }
    },
  },
  substanceType: {
    type: String,
    optional: true,
    custom() {
      // NIBRS-2019.2-103: Property Drug Measure
      if (this.obj.property.values.quantityMeasure === 'NP') {
        if (
          ![substanceTypeCodes.E, substanceTypeCodes.G, substanceTypeCodes.K].includes(this.value)
        ) {
          return 'NIBRS-2019.2-103';
        }
      }

      // NIBRS-2019.2-101: Property Drug Quantity
      if (this.obj.property.values.quantity) {
        if (!this.value) {
          return 'NIBRS-2019.2-101';
        }
      }
    },
  },
  propertySubstance: {
    type: String,
    optional: true,
    custom() {
      //NIBRS-2019.2-97: Property Drug Type
      if (this.obj.properties.length !== 0) {
        let substanceCount = 0;
        for (let p of this.obj.properties) {
          if (p.propertyDetails.values.parentType === 'SUBSTANCE')
            substanceCount = substanceCount + 1;
        }
        if (substanceCount > 2) {
          return 'NIBRS-2019.2-97';
        }
      }
    },
  },
});
