import React, { useState } from 'react';
import { useHistory, useParams } from 'react-router';
import { connect, useDispatch } from 'react-redux';

import { Grid, makeStyles, Paper } from '@material-ui/core';
import FormControl from '@material-ui/core/FormControl';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';

import RMSEditButton from 'components/RMSButtons/RMSEditButton';
import RMSFabButtons from 'components/RMSForms/RMSIncidentForm/RMSFabButtons';
import AddressSearch from 'components/RMSForms/RMSAddressForm/AddressSearch';
import RMSAddButton from '../../../RMSButtons/RMSAddButton';
import { setCircularLoading } from 'reducers/ui/UiMenuReducer';
import { upsertRecord } from 'reducers/RecordsReducer';
import { handleFormChangeNew } from 'reducers/helpers/formHelpers';

const useStyles = makeStyles(() => ({
  root: {
    height: window.innerHeight - 90 + 'px',
    borderRadius: '0.5rem',
    padding: '15px',
  },
  btnStyle: {
    display: 'block',
    textAlign: 'right',
    marginBottom: '10px',
  },
}));

const PersonAddressForm = (props) => {
  /** region Component Variables and Props */
  const history = useHistory();
  const dispatch = useDispatch();
  const classes = useStyles();
  const { persondId } = useParams();

  const { upsertRecord, records } = props;
  const data = records.formData;

  const [changeKey, setChangeKey] = React.useState(0);
  const [pageType, setPageType] = React.useState('add');
  const [formData, setFormData] = useState({
    values: {
      ptsAddressID: data?.ptsAddressID || undefined,
      ptsPersonID: Number(persondId),
      IsPrimary: data?.IsPrimary || false,
    },
  });
  /** endregion */
  /** region React Hooks */
  React.useEffect(() => {
    const parts = history.location.pathname.split('/');
    let type = '';
    if (parts.length === 6) type = parts[parts.length - 1];
    if (parts.length === 7) type = parts[parts.length - 2];

    setPageType(type);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  /** endregion */
  /** region Helper Functions */
  const handleSave = () => {
    upsertRecord('address', formData.values, pageType).finally(() => handleFinally());
  };

  const handleFinally = () => {
    dispatch(setCircularLoading(false));
    history.goBack();
  };

  const onPlaceValueChange = (newValue) => {
    setFormData({
      values: {
        ...formData.values,
        ptsAddressID: newValue.ptsAddressID,
      },
    });
  };

  const clearPlace = () => {
    setFormData({
      values: {
        ...formData.values,
        ptsAddressID: '',
      },
    });
  };

  const goToEditAddressPage = () => {
    history.push(`/records/edit/address/${formData.values.ptsAddressID}`);
  };

  const goToAddAddressPage = () => {
    history.push(`/records/add/address`);
  };
  /** endregion */
  return (
    <Paper className={classes.root}>
      <Grid container spacing={2} className="p-3">
        {pageType === 'add' && (
          <>
            <Grid item xs={12} className={classes.btnStyle}>
              <RMSAddButton tooltipText="Add Address" onClick={goToAddAddressPage} />
            </Grid>
            <Grid item xs={12}>
              <AddressSearch
                ptsPlaces={true}
                ptsAddresses={true}
                googleAddresses={false}
                onPlaceValueSet={onPlaceValueChange}
                onAddressValueSet={onPlaceValueChange}
                attachAddress={true}
                label={'Search Addresses'}
                setChangeKey={setChangeKey}
                changeKey={changeKey}
                defaultLocation={null}
                onReset={clearPlace}
              />
            </Grid>
          </>
        )}
        {pageType === 'edit' && (
          <Grid item xs={12} className={classes.btnStyle}>
            <RMSEditButton tooltipText="Edit Address" onClick={goToEditAddressPage} />
          </Grid>
        )}
        <Grid item xs={6}>
          <FormControl size="small">
            <Grid>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={formData.values.IsPrimary || false}
                    name="IsPrimary"
                    onChange={(event) =>
                      handleFormChangeNew(
                        event,
                        event.target.checked,
                        'none',
                        'IsPrimary',
                        setFormData
                      )
                    }
                  />
                }
                label={'Is Primary?'}
              />
            </Grid>
          </FormControl>
        </Grid>
        <RMSFabButtons recordForm={true} handleSaveRecord={handleSave} />
      </Grid>
    </Paper>
  );
};

const mapStateToProps = (state) => ({
  wsClient: state.websocket,
  records: state.records,
});

export default connect(mapStateToProps, { upsertRecord })(PersonAddressForm);
