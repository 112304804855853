export const GET_CODE_PLACE_TYPES = 'GET_CODE_PLACE_TYPES';

export const getCodePlaceTypes = (state) => {
  return async (dispatch) => {
    return dispatch({ type: GET_CODE_PLACE_TYPES, payload: state });
  };
};

export default function reducer(state = [], action) {
  switch (action.type) {
    case GET_CODE_PLACE_TYPES:
      state = action.payload;
      return state;
    default:
      return state;
  }
}
