import Button from '@material-ui/core/Button';
import React from 'react';
import { useHistory } from 'react-router';
import makeStyles from '@material-ui/core/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  button: {
    backgroundColor: theme.palette.info.dark,
    color: theme.palette.common.white,
    '&:hover': {
      backgroundColor: theme.palette.info.dark,
      boxShadow: 'none',
    },
    '&:active': {
      boxShadow: 'none',
      backgroundColor: theme.palette.info.dark,
    },
    cursor: 'pointer',
    marginRight: '20px',
  },
}));

export default function RMSMissingPersonButton() {
  const classes = useStyles();
  const history = useHistory();

  const handleMissingPersonClick = () => {
    history.push('/incidents/add/missing-person');
  };

  return (
    <Button onClick={handleMissingPersonClick} className={classes.button}>
      MP
    </Button>
  );
}
