import React, { Fragment } from 'react';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import IosFlame from 'react-ionicons/lib/IosFlame';
import IosImages from 'react-ionicons/lib/IosImages';
import IosPeople from 'react-ionicons/lib/IosPeople';
import IosApps from 'react-ionicons/lib/IosApps';

import { withStyles } from '@material-ui/core/styles';
import Badge from '@material-ui/core/Badge';

const chart30Options = {
  chart: {
    toolbar: {
      show: false,
    },
    sparkline: {
      enabled: true,
    },
  },
  dataLabels: {
    enabled: false,
  },
  colors: ['#3c44b1'],
  stroke: {
    color: '#4191ff',
    curve: 'smooth',
    width: 4,
  },
  xaxis: {
    crosshairs: {
      width: 1,
    },
  },
  yaxis: {
    min: 0,
  },
  legend: {
    show: false,
  },
};

const chart30Data = [
  {
    name: 'Customers',
    data: [47, 38, 56, 24, 45, 54, 38, 47, 38, 56, 24, 56, 24, 65],
  },
];

const StyledBadge = withStyles({
  badge: {
    backgroundColor: 'var(--success)',
    color: 'var(--success)',
    boxShadow: '0 0 0 2px #fff',
    '&::after': {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      borderRadius: '50%',
      animation: '$ripple 1.2s infinite ease-in-out',
      border: '1px solid currentColor',
      content: '""',
    },
  },
  '@keyframes ripple': {
    '0%': {
      transform: 'scale(.8)',
      opacity: 1,
    },
    '100%': {
      transform: 'scale(2.4)',
      opacity: 0,
    },
  },
})(Badge);

const RMSGridMenu = () => {
  const [anchorElMenu1, setAnchorElMenu1] = React.useState(null);
  const handleClickMenu1 = (event) => {
    setAnchorElMenu1(event.currentTarget);
  };
  const handleCloseMenu1 = () => {
    setAnchorElMenu1(null);
  };

  return (
    <Fragment>
      <Grid item xs={12} sm={6} md={4}>
        <div className="heading-3 text-center">Grid menus</div>
        <div className="text-center">
          <Button variant="outlined" color="primary" onClick={handleClickMenu1}>
            Open menu
          </Button>
        </div>
        <Menu
          anchorEl={anchorElMenu1}
          keepMounted
          open={Boolean(anchorElMenu1)}
          onClose={handleCloseMenu1}
          classes={{ list: 'p-0' }}
          getContentAnchorEl={null}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}>
          <div className="overflow-hidden dropdown-menu-lg p-0">
            <div className="grid-menu grid-menu-2col">
              <Grid container spacing={0}>
                <Grid item sm={6} className="p-2">
                  <Button variant="outlined" color="primary" className="border-0 w-100 d-block">
                    <span className="font-size-xxl d-block mb-1">
                      <IosFlame fontSize="28px" color="var(--primary)" />
                    </span>
                    <span className="pb-1">Primary</span>
                  </Button>
                </Grid>
                <Grid item sm={6} className="p-2">
                  <Button variant="outlined" color="primary" className="border-0 w-100 d-block">
                    <span className="font-size-xxl d-block mb-1">
                      <IosImages fontSize="28px" color="var(--info)" />
                    </span>
                    <span className="pb-1">Info</span>
                  </Button>
                </Grid>
                <Grid item sm={6} className="p-2">
                  <Button variant="outlined" color="primary" className="border-0 w-100 d-block">
                    <span className="font-size-xxl d-block mb-1">
                      <IosPeople fontSize="28px" color="var(--success)" />
                    </span>
                    <span className="pb-1">Success</span>
                  </Button>
                </Grid>
                <Grid item sm={6} className="p-2">
                  <Button variant="outlined" color="primary" className="border-0 w-100 d-block">
                    <span className="font-size-xxl d-block mb-1">
                      <IosApps fontSize="28px" color="var(--danger)" />
                    </span>
                    <span className="pb-1">Danger</span>
                  </Button>
                </Grid>
              </Grid>
            </div>
          </div>
        </Menu>
      </Grid>
    </Fragment>
  );
};

export default RMSGridMenu;
