import React from 'react';
import { connect } from 'react-redux';

import SimpleSchema from 'simpl-schema';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Divider from '@material-ui/core/Divider';
import { PhoneMask } from '../../components/RMSTextMask';
import RMSAutoComplete from 'components/RMSAutoComplete/RMSAutoComplete';
import {
  setDuplicateContactAddressList,
  setDuplicateModal,
  setDuplicateModalMessage,
} from 'reducers/ui/UiModalReducer';
import { duplicateCheck } from 'reducers/helpers/duplicateCheckHelpers';
import { getCityIdFromCityName, updateIncPartiesContactForm } from '../../reducers/IncReducer';
import { getCodeStreetDirections } from '../../reducers/dictionaries/CodeStreetDirectionsReducer';
import { getCodeStreetType } from '../../reducers/dictionaries/CodeStreetTypesReducer';
import AddressSearch from '../../components/RMSForms/RMSAddressForm/AddressSearch';
import { getCodeStates } from '../../reducers/dictionaries/CodeStatesReducer';
import { getCodeCities } from '../../reducers/dictionaries/CodeOthersReducer';
import {
  clearPlace,
  handleFormChangeNew,
  onPlaceValueChange,
  setFullAddressText,
} from '../../reducers/helpers/formHelpers';
import { checkFormType } from '../helpers/checkFormState';

const validationContext = new SimpleSchema({
  addressNumber: SimpleSchema.Integer,
}).newContext();

const TabContact = (props) => {
  /** region Component Variables and Props */
  const {
    partiesContactForm,
    updateIncPartiesContactForm,
    codeStreetTypes,
    codeStreetDirections,
    codeStates,
    getCodeStreetType,
    getCodeStreetDirections,
    getCodeStates,
    getCodeCities,
    codeCities,
    wsClient,
    history,
    changeOptions,
    addressOptions,
    selectedAddress,
    changeSelectedOption,
    setDuplicateModal,
    uiDuplicateModalData,
    setDuplicateModalMessage,
    setDuplicateContactAddressList,
  } = props;
  const { duplicateListOfAddressForEmployment, duplicateListOfPerson } = uiDuplicateModalData;
  const [changeKey, setChangeKey] = React.useState(0);
  const [partiesContactFormState, setPartiesContactFormState] = React.useState({
    isValid: false,
    values: {},
    touched: {},
    errors: {},
    notes: '',
  });
  const [firstLoad, setFirstLoad] = React.useState(true);
  const [errors, setErrors] = React.useState({});
  /** endregion */
  /** region React Helper Functions */
  const duplicateFinder = async () => {
    let duplicateFound = !partiesContactFormState.ptsAddressId
      ? await duplicateCheck(partiesContactFormState, 'address', 'contact')
      : false;
    if (duplicateFound) {
      setDuplicateModal(true);
      setDuplicateModalMessage('Duplciate Found');
    } else {
      setDuplicateContactAddressList([]);
      if (duplicateListOfPerson.length > 0 || duplicateListOfAddressForEmployment.length > 0) {
        setDuplicateModal(true);
      } else {
        setDuplicateModal(false);
      }
    }
  };

  const onReset = () => {
    clearPlace(setPartiesContactFormState);

    setPartiesContactFormState((partiesContactFormState) => ({
      ...partiesContactFormState,
      ptsAddressId: null,
      values: {
        ...partiesContactFormState.values,
        addressNumberPrefix: '',
        addressNumber: '',
        addressNumberSuffix: '',
        streetName: '',
        preDirection: '',
        postDirection: '',
        city: '',
        state: '',
        county: '',
        country: '',
        postalCode: '',
        postalCodeExtension: '',
        fullAddressText: '',
        streetType: '',
        notes: '',
      },
    }));

    changeSelectedOption(null);
  };

  const onChange = (place, setFormState, setChangeKey, changeKey) => {
    onPlaceValueChange(place, setFormState, setChangeKey, changeKey, true);
  };
  /** endregion */
  /** region React Hooks */
  React.useEffect(() => {
    checkFormType(
      partiesContactFormState.ptsContactId !== undefined &&
        partiesContactFormState.ptsContactId !== null,
      setPartiesContactFormState,
      history
    );
  }, []);

  React.useEffect(() => {
    const services = ['codeStreetTypes', 'codeStreetDirections', 'codeStates'];

    if (!wsClient.websocket) return;

    getCodeCities();

    services.forEach(async (svc) => {
      if (props[svc].length < 1) {
        const result = await wsClient.websocket.service(svc.toLowerCase()).find({
          query: {
            $limit: 0,
            $select: ['Code', 'Description'],
            IsDeleted: false,
            $sort: {
              Description: 1,
            },
          },
        });

        if (result.total > 0) {
          if (svc === 'codeStreetTypes') {
            getCodeStreetType(result.data);
          } else if (svc === 'codeStreetDirections') {
            getCodeStreetDirections(result.data);
          } else if (svc === 'codestates') {
            getCodeStates(result.data);
          }
        }
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [wsClient]);

  React.useEffect(() => {
    // eslint-disable-next-line prettier/prettier
    setPartiesContactFormState(partiesContactFormState => ({
      ...partiesContactFormState,
      ...partiesContactForm,
      values: {
        ...partiesContactFormState.values,
        ...partiesContactForm.values,
      },
    }));
  }, [partiesContactForm]);

  React.useEffect(() => {
    if (typeof partiesContactFormState.values?.city === 'string') {
      partiesContactFormState.values.cityId = getCityIdFromCityName(
        partiesContactFormState.values?.city
      );
    }
    const fullAddressText = setFullAddressText(partiesContactFormState);
    setPartiesContactFormState((partiesContactFormState) => ({
      ...partiesContactFormState,
      values: {
        ...partiesContactFormState.values,
        fullAddressText: fullAddressText,
      },
    }));

    let timeoutId;
    if (
      !firstLoad &&
      partiesContactFormState.values?.addressNumber &&
      partiesContactFormState.values?.addressNumber !== ''
    ) {
      validationContext.validate({
        addressNumber: Number(partiesContactFormState.values?.addressNumber),
      });

      if (validationContext.isValid() === false) {
        const errs = validationContext.validationErrors();
        let e = {};
        errs.map((er) => {
          e[er.name] = `Value must be ${er.dataType}`;
          setErrors(e);
        });
      } else {
        setErrors({});
      }

      timeoutId = setTimeout(() => {
        if (Object.keys(partiesContactFormState.values).length !== 0) duplicateFinder();
      }, 2000);
    }
    setFirstLoad(false);

    updateIncPartiesContactForm(partiesContactFormState);
    return () => clearTimeout(timeoutId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    partiesContactFormState.values?.incidentId,
    partiesContactFormState.values?.personId,
    partiesContactFormState.values?.addressId,
    partiesContactFormState.values?.cityId,
    partiesContactFormState.values?.addressNumberPrefix,
    partiesContactFormState.values?.addressNumber,
    partiesContactFormState.values?.addressNumberSuffix,
    partiesContactFormState.values?.preDirection,
    partiesContactFormState.values?.streetName,
    partiesContactFormState.values?.streetType,
    partiesContactFormState.values?.postDirection,
    partiesContactFormState.values?.city,
    partiesContactFormState.values?.state,
    partiesContactFormState.values?.postalCode,
    partiesContactFormState.values?.postalCodeExtension,
    partiesContactFormState.values?.county,
    partiesContactFormState.values?.country,
    partiesContactFormState.values?.fullAddressText,
    partiesContactFormState.values?.textmask1,
    partiesContactFormState.values?.textmask2,
    partiesContactFormState.values?.emailmask,
    partiesContactFormState.values?.notes,
  ]);
  /** endregion */
  return (
    <Grid container justify="center" xs={12} className="p-3">
      <Grid item xs={12}>
        <AddressSearch
          ptsPlaces={true}
          ptsAddresses={true}
          googleAddresses={false}
          onPlaceValueSet={onChange}
          onAddressValueSet={onChange}
          setFormState={setPartiesContactFormState}
          label={'Search Addresses'}
          setChangeKey={setChangeKey}
          changeKey={changeKey}
          defaultLocation={null}
          onReset={onReset}
          addressOptions={addressOptions}
          changeOptions={changeOptions}
          changeSelectedOption={changeSelectedOption}
          selectedAddress={selectedAddress}
        />
      </Grid>
      <Grid item xs={12} className="mt-4 mb-2">
        <span style={{ color: '#fff', background: '#1976d2' }} className="mb-1 badge">
          Address Details
        </span>
        <Divider />
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={6} lg={4}>
          <TextField
            id="standard-textarea-contact-addressNumberPrefix"
            autoComplete="hidden"
            size="small"
            placeholder="Prefix"
            label="Prefix"
            variant="outlined"
            name="prefix"
            key={changeKey}
            value={partiesContactFormState.values?.addressNumberPrefix}
            onChange={(event) =>
              handleFormChangeNew(
                event,
                event.target.value,
                'none',
                'addressNumberPrefix',
                setPartiesContactFormState
              )
            }
            fullWidth
            multiline
            InputLabelProps={{ shrink: true }}
          />
        </Grid>
        <Grid item xs={6} lg={4}>
          <TextField
            id="standard-textarea-contact-addressNumber"
            autoComplete="hidden"
            size="small"
            placeholder="Number"
            label="Number"
            variant="outlined"
            name="number"
            error={errors['addressNumber'] ? true : false}
            helperText={errors['addressNumber']}
            key={changeKey}
            value={partiesContactFormState.values?.addressNumber}
            onChange={(event) =>
              handleFormChangeNew(
                event,
                event.target.value,
                'none',
                'addressNumber',
                setPartiesContactFormState
              )
            }
            fullWidth
            multiline
            InputLabelProps={{ shrink: true }}
          />
        </Grid>
        <Grid item xs={6} lg={4}>
          <TextField
            id="standard-textarea-contact-addressNumberSuffix"
            autoComplete="hidden"
            size="small"
            placeholder="Suffix"
            label="Suffix"
            variant="outlined"
            name="suffix"
            key={changeKey}
            value={partiesContactFormState.values?.addressNumberSuffix}
            onChange={(event) =>
              handleFormChangeNew(
                event,
                event.target.value,
                'none',
                'addressNumberSuffix',
                setPartiesContactFormState
              )
            }
            fullWidth
            multiline
            InputLabelProps={{ shrink: true }}
          />
        </Grid>
        <Grid item xs={6} lg={4}>
          <RMSAutoComplete
            size="small"
            autoComplete
            autoSelect
            autoHighlight
            id="combo-pre-directional"
            options={codeStreetDirections}
            label="Predirectional"
            serviceName="codestreetdirections"
            title="Code"
            stateHolderAttribute="preDirection"
            variant="outlined"
            stateHolder={partiesContactFormState}
            setStateHolder={setPartiesContactFormState}
            setField={handleFormChangeNew}
          />
        </Grid>
        <Grid item xs={6} lg={4}>
          <TextField
            id="standard-textarea-contact-streetName"
            autoComplete="hidden"
            size="small"
            placeholder="Street Name"
            label="Street Name"
            variant="outlined"
            name="streetName"
            key={changeKey}
            value={partiesContactFormState.values?.streetName}
            onChange={(event) =>
              handleFormChangeNew(
                event,
                event.target.value,
                'none',
                'streetName',
                setPartiesContactFormState
              )
            }
            fullWidth
            multiline
            InputLabelProps={{ shrink: true }}
          />
        </Grid>
        <Grid item xs={6} lg={4}>
          <RMSAutoComplete
            size="small"
            autoComplete
            autoSelect
            autoHighlight
            id="combo-street-type"
            options={codeStreetTypes}
            label="Type"
            serviceName="codestreettypes"
            title="Code"
            stateHolderAttribute="streetType"
            variant="outlined"
            stateHolder={partiesContactFormState}
            setStateHolder={setPartiesContactFormState}
            setField={handleFormChangeNew}
          />
        </Grid>
        <Grid item xs={6} lg={4}>
          <RMSAutoComplete
            size="small"
            autoComplete
            autoSelect
            autoHighlight
            id="combo-post-directional"
            options={codeStreetDirections}
            label="Postdirectional"
            serviceName="codestreetdirections"
            title="Code"
            stateHolderAttribute="postDirection"
            variant="outlined"
            stateHolder={partiesContactFormState}
            setStateHolder={setPartiesContactFormState}
            setField={handleFormChangeNew}
          />
        </Grid>
        <Grid item xs={6} lg={4}>
          <RMSAutoComplete
            size="small"
            autoComplete
            autoSelect
            autoHighlight
            id="combo-cities"
            options={codeCities}
            label="City"
            serviceName="codecities"
            title="CityDescription"
            stateHolderAttribute="city"
            variant="outlined"
            stateHolder={partiesContactFormState}
            setStateHolder={setPartiesContactFormState}
            setField={handleFormChangeNew}
          />
        </Grid>
        <Grid item xs={12} lg={4}>
          <RMSAutoComplete
            size="small"
            autoComplete
            autoSelect
            autoHighlight
            id="combo-state"
            options={codeStates}
            label="State"
            serviceName="codeStates"
            title="Code"
            stateHolderAttribute="state"
            variant="outlined"
            stateHolder={partiesContactFormState}
            setStateHolder={setPartiesContactFormState}
            setField={handleFormChangeNew}
          />
        </Grid>
      </Grid>
      <Grid container spacing={2} className="mt-2">
        <Grid item xs={6} lg={3}>
          <TextField
            id="standard-textarea-contact-postalCode"
            autoComplete="hidden"
            size="small"
            placeholder="Postal Code"
            label="Postal Code"
            variant="outlined"
            name="postalCode"
            key={changeKey}
            value={partiesContactFormState.values?.postalCode}
            onChange={(event) =>
              handleFormChangeNew(
                event,
                event.target.value,
                'none',
                'postalCode',
                setPartiesContactFormState
              )
            }
            fullWidth
            multiline
            InputLabelProps={{ shrink: true }}
          />
        </Grid>
        <Grid item xs={6} lg={3}>
          <TextField
            id="standard-textarea-contact-postalCodeExtension"
            autoComplete="hidden"
            size="small"
            placeholder="Extension"
            label="Extension"
            variant="outlined"
            name="extension"
            key={changeKey}
            value={partiesContactFormState.values?.postalCodeExtension}
            onChange={(event) =>
              handleFormChangeNew(
                event,
                event.target.value,
                'none',
                'postalCodeExtension',
                setPartiesContactFormState
              )
            }
            fullWidth
            multiline
            InputLabelProps={{ shrink: true }}
          />
        </Grid>
        <Grid item xs={6} lg={3}>
          <TextField
            id="standard-textarea-contact-county"
            autoComplete="hidden"
            size="small"
            placeholder="County"
            label="County"
            variant="outlined"
            name="county"
            key={changeKey}
            value={partiesContactFormState.values?.county}
            onChange={(event) =>
              handleFormChangeNew(
                event,
                event.target.value,
                'none',
                'county',
                setPartiesContactFormState
              )
            }
            fullWidth
            multiline
            InputLabelProps={{ shrink: true }}
          />
        </Grid>
        <Grid item xs={6} lg={3}>
          <TextField
            id="standard-textarea-contact-country"
            autoComplete="hidden"
            size="small"
            placeholder="Country"
            label="Country"
            variant="outlined"
            name="country"
            key={changeKey}
            value={partiesContactFormState.values?.country}
            onChange={(event) =>
              handleFormChangeNew(
                event,
                event.target.value,
                'none',
                'country',
                setPartiesContactFormState
              )
            }
            fullWidth
            multiline
            InputLabelProps={{ shrink: true }}
          />
        </Grid>
      </Grid>
      <Grid item xs={12} className="mt-4 mb-2">
        <span style={{ color: '#fff', background: '#1976d2' }} className="mb-1 badge">
          Contacts
        </span>
        <Divider />
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={12} lg={4}>
          <TextField
            autoComplete="hidden"
            size="small"
            fullWidth
            variant="outlined"
            placeholder="Primary Phone"
            label="Primary Phone"
            name="textmask1"
            key={changeKey}
            value={partiesContactFormState.values?.textmask1 || ''}
            onChange={(event) =>
              handleFormChangeNew(
                event,
                event.target.value,
                'none',
                'textmask1',
                setPartiesContactFormState
              )
            }
            id="formatted-numberformat-input"
            InputProps={{ inputComponent: PhoneMask }}
            multiline
            InputLabelProps={{ shrink: true }}
          />
        </Grid>
        <Grid item xs={12} lg={4}>
          <TextField
            autoComplete="hidden"
            size="small"
            fullWidth
            variant="outlined"
            placeholder="Secondary Phone"
            label="Secondary Phone"
            name="textmask2"
            key={changeKey}
            value={partiesContactFormState.values?.textmask2 || ''}
            onChange={(event) =>
              handleFormChangeNew(
                event,
                event.target.value,
                'none',
                'textmask2',
                setPartiesContactFormState
              )
            }
            id="formatted-numberformat-input-secondary"
            InputProps={{ inputComponent: PhoneMask }}
            multiline
            InputLabelProps={{ shrink: true }}
          />
        </Grid>
        <Grid item xs={12} lg={4}>
          <TextField
            id="standard-textarea-contact-emailMask"
            autoComplete="hidden"
            size="small"
            variant="outlined"
            fullWidth
            placeholder="pts@pts.com"
            label="pts@pts.com"
            {...props}
            name="emailmask"
            key={changeKey}
            value={partiesContactFormState.values?.emailmask}
            onChange={(event) =>
              handleFormChangeNew(
                event,
                event.target.value,
                'none',
                'emailmask',
                setPartiesContactFormState
              )
            }
            multiline
            InputLabelProps={{ shrink: true }}
          />
        </Grid>
      </Grid>
      <Grid xs={12} className="mt-4 mb-2">
        <span style={{ color: '#fff', background: '#1976d2' }} className="mb-1 badge">
          Notes
        </span>
        <Divider />
      </Grid>
      <Grid item xs={12}>
        <TextField
          autoComplete="hidden"
          size="small"
          className="mt-3"
          id="outlined-multiline-static-address-notes"
          placeholder="Notes"
          label="Notes"
          rows="7"
          name="notes"
          key={changeKey}
          value={partiesContactFormState.values?.notes}
          onChange={(event) =>
            handleFormChangeNew(
              event,
              event.target.value,
              'none',
              'notes',
              setPartiesContactFormState
            )
          }
          fullWidth
          variant="outlined"
          multiline
          InputLabelProps={{ shrink: true }}
        />
      </Grid>
    </Grid>
  );
};

const mapStateToProps = (state) => ({
  wsClient: state.websocket,
  partiesContactForm: state.incident.partiesContactForm,
  codeStreetTypes: state.codeStreetTypes,
  codeStreetDirections: state.codeStreetDirections,
  codeStates: state.codeStates,
  codeCities: state.codesOthers.codeCities,
  uiDuplicateModalData: state.uiModal,
});

export default connect(mapStateToProps, {
  updateIncPartiesContactForm,
  getCodeStreetType,
  getCodeStreetDirections,
  getCodeStates,
  getCodeCities,
  setDuplicateModal,
  setDuplicateModalMessage,
  setDuplicateContactAddressList,
})(TabContact);
