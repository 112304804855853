import React, { Fragment } from 'react';

import { TableCell, Typography, Chip } from '@material-ui/core';
import { lighten, makeStyles } from '@material-ui/core/styles';
import { colors } from '../../../../config/colorVariables';

const useStyles = makeStyles((theme) => ({
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: colors.cyan,
    marginRight: theme.spacing(2),
  },
  incidentLabel: {
    padding: '3px 0px',
  },
  chipStyle:
    theme.palette.type === 'light'
      ? {
          color: theme.palette.secondary.main,
          backgroundColor: lighten(theme.palette.secondary.light, 0.85),
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark,
        },
}));

const RMSAddressCell = (props) => {
  const classes = useStyles();
  const { row } = props;

  function dragStart(event) {
    event.dataTransfer.setData('Text', event.target.id);
  }
  return (
    <Fragment>
      <TableCell
        style={{ cursor: 'grabbin' }}
        onDragStart={dragStart}
        draggable={props.dnd}
        id={`${row.Number || ''} ${row.PreDirection || ''} ${row.StreetName || ''} ${
          row.StreetType || ''
        } ${row.UnitType || ''} ${row.UnitTypeIdentifier || ''} ${row.City || ''} ${
          row.State || ''
        }`}>
        <td>
          <div className="d-flex align-items-center">
            <div>
              <Typography variant="h5" className={classes.incidentLabel}>
                {row.FullAddressText}
              </Typography>

              {row.isPrimary && (
                <Chip label="Primary" className={classes.chipStyle} variant="outlined" />
              )}
            </div>
          </div>
        </td>
      </TableCell>
    </Fragment>
  );
};

export default RMSAddressCell;
