import React from 'react';
import BlurOnIcon from '@material-ui/icons/BlurOn';
import RMSCollapsibleList from '../index';
import {
  AdditionalInfoForm,
  EvidenceCollectedForm,
  RestrainingOrderForm,
  VictimGivenForm,
  WitnessForm,
} from '../../../RMSForms/RMSDomesticViolenceForm/AdditionalForm';

const panelItems = [
  {
    name: 'panel1',
    title: 'Evidence Collected',
    details: <EvidenceCollectedForm />,
    icon: <BlurOnIcon />,
  },
  {
    name: 'panel2',
    title: 'Restraining Orders',
    details: <RestrainingOrderForm />,
    icon: <BlurOnIcon />,
  },
  {
    name: 'panel3',
    title: 'Witness',
    details: <WitnessForm />,
    icon: <BlurOnIcon />,
  },
  {
    name: 'panel4',
    title: 'Additional Victim Info',
    details: <AdditionalInfoForm />,
    icon: <BlurOnIcon />,
  },
  {
    name: 'panel5',
    title: 'Victim Given',
    details: <VictimGivenForm />,
    icon: <BlurOnIcon />,
  },
];

const DvAdditionalPanels = () => {
  return <RMSCollapsibleList panelItems={panelItems} />;
};

export default DvAdditionalPanels;
