import React from 'react';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { connect } from 'react-redux';
import { updateIncEvidenceDispositionForm } from '../../reducers/IncReducer';
import { handleFormChangeNew } from '../../reducers/helpers/formHelpers';
import { v4 as uuidv4 } from 'uuid';
import RMSAutoComplete from 'components/RMSAutoComplete/RMSAutoComplete';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { Divider } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  root: {
    width: '80vw',
  },
}));

const TabEvidenceDisposition = (props) => {
  const classes = useStyles();
  const {
    evidenceDispositionForm,
    updateIncEvidenceDispositionForm,
    codeEvidenceFinalDisposition,
  } = props;

  const [collectedDate, setCollectedDate] = React.useState(null);
  const [dispositionDate, setDispositionDate] = React.useState(null);

  const [evidenceFormDispositionState, setEvidenceDispositionFormState] = React.useState({
    tempEvidenceId: uuidv4(),
    isValid: false,
    changes: false,
    values: {},
    touched: {},
    errors: {},
  });

  React.useEffect(() => {
    setEvidenceDispositionFormState((evidenceFormDispositionState) => ({
      ...evidenceFormDispositionState,
      ...evidenceDispositionForm,
      values: { ...evidenceFormDispositionState.values, ...evidenceDispositionForm.values },
    }));
    if (evidenceDispositionForm.values.collectedDate)
      setCollectedDate(evidenceDispositionForm.values.collectedDate);
    if (evidenceDispositionForm.values.dispositionDate)
      setDispositionDate(evidenceDispositionForm.values.dispositionDate);
  }, [evidenceDispositionForm]);

  React.useEffect(() => {
    updateIncEvidenceDispositionForm(evidenceFormDispositionState);
    evidenceFormDispositionState.changes = true;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    evidenceFormDispositionState.values?.incidentId,
    evidenceFormDispositionState.values?.evidenceId,
    evidenceFormDispositionState.values?.evidenceDispositionId,
    evidenceFormDispositionState.values?.bypassDisposalAssumption,
    evidenceFormDispositionState.values?.readyForDisposal,
    evidenceFormDispositionState.values?.isDisposed,
    evidenceFormDispositionState.values?.disposedOfBy,
    evidenceFormDispositionState.values?.finalDisposition,
    evidenceFormDispositionState.values?.dispositionNotes,
    evidenceFormDispositionState.values?.dispositionDate,
    evidenceFormDispositionState.values?.collectedDate,
  ]);

  return (
    <Grid container className="p-3">
      <Grid container justify="center" spacing={2} className="mb-3">
        <Grid item>
          <FormControlLabel
            control={
              <Checkbox
                key={evidenceFormDispositionState.values.bypassDisposalAssumption}
                checked={evidenceFormDispositionState.values.bypassDisposalAssumption}
                onChange={(event) =>
                  handleFormChangeNew(
                    event,
                    event.target.checked,
                    'none',
                    'bypassDisposalAssumption',
                    setEvidenceDispositionFormState
                  )
                }
                name="bypassDisposalAssumption"
              />
            }
            label="Bypass Disposal Assumption"
          />
        </Grid>
        <Grid item>
          <FormControlLabel
            control={
              <Checkbox
                key={evidenceFormDispositionState.values.readyForDisposal}
                checked={evidenceFormDispositionState.values.readyForDisposal}
                onChange={(event) =>
                  handleFormChangeNew(
                    event,
                    event.target.checked,
                    'none',
                    'readyForDisposal',
                    setEvidenceDispositionFormState
                  )
                }
                name="readyForDisposal"
              />
            }
            label="Ready for Disposal"
          />
        </Grid>
        <Grid item>
          <FormControlLabel
            control={
              <Checkbox
                key={evidenceFormDispositionState.values.isDisposed}
                checked={evidenceFormDispositionState.values.isDisposed}
                onChange={(event) =>
                  handleFormChangeNew(
                    event,
                    event.target.checked,
                    'none',
                    'isDisposed',
                    setEvidenceDispositionFormState
                  )
                }
                name="isDisposed"
              />
            }
            label="Is Disposed"
          />
        </Grid>
      </Grid>
      <Grid xs={12} className="mb-3">
        <span style={{ color: '#fff', background: '#1976d2' }} className="mb-1 badge badge-primary">
          Disposal Details
        </span>
        <Divider />
      </Grid>
      <Grid container spacing={2} className="mb-3">
        <Grid item xs={3}>
          <RMSAutoComplete
            size="small"
            autoComplete
            autoSelect
            autoHighlight
            id="combo-evidence-final-disposition"
            options={codeEvidenceFinalDisposition}
            // new ones
            serviceName="codeevidencefinaldisposition"
            label="Final Disposition"
            variant="outlined"
            title="Code"
            stateHolder={evidenceFormDispositionState}
            setStateHolder={setEvidenceDispositionFormState}
            setField={handleFormChangeNew}
            stateHolderAttribute="finalDisposition"
          />
        </Grid>

        <Grid item xs={3}>
          <TextField
            autoComplete="hidden"
            size="small"
            label="Disposed of By"
            placeholder="Disposed of By"
            variant="outlined"
            defaultValue={evidenceFormDispositionState.values.disposedOfBy}
            onBlur={(event) =>
              handleFormChangeNew(
                event,
                event.target.value,
                'none',
                'disposedOfBy',
                setEvidenceDispositionFormState
              )
            }
            fullWidth
            multiline
            InputLabelProps={{ shrink: true }}
          />
        </Grid>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <Grid item xs={3}>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <KeyboardDatePicker
                size="small"
                inputVariant="outlined"
                fullWidth
                showTodayButton={true}
                variant="dialog"
                format="MM/dd/yyyy"
                id="date-picker-inline-from"
                label="Disposition Date"
                value={dispositionDate ? dispositionDate : null}
                onChange={(date) => {
                  setEvidenceDispositionFormState((state) => {
                    return {
                      ...state,
                      values: {
                        ...state.values,
                        dispositionDate: date,
                      },
                    };
                  });
                }}
                KeyboardButtonProps={{
                  'aria-label': 'change date',
                }}
              />
            </MuiPickersUtilsProvider>
          </Grid>

          <Grid item xs={3}>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <KeyboardDatePicker
                size="small"
                inputVariant="outlined"
                fullWidth
                showTodayButton={true}
                variant="dialog"
                format="MM/dd/yyyy"
                id="date-picker-inline-from"
                label="Collected Date"
                value={collectedDate ? collectedDate : null}
                onChange={(date) => {
                  setEvidenceDispositionFormState((state) => {
                    return {
                      ...state,
                      values: {
                        ...state.values,
                        collectedDate: date,
                      },
                    };
                  });
                }}
                KeyboardButtonProps={{
                  'aria-label': 'change date',
                }}
              />
            </MuiPickersUtilsProvider>
          </Grid>
        </MuiPickersUtilsProvider>
      </Grid>

      <Grid item xs={12} className="mt-2">
        <TextField
          autoComplete="hidden"
          size="small"
          fullWidth
          id="outlined-multiline-static"
          label="Disposition Notes"
          placeholder="Disposition Notes"
          rows="3"
          defaultValue={evidenceFormDispositionState.values.dispositionNotes}
          onBlur={(event) =>
            handleFormChangeNew(
              event,
              event.target.value,
              'none',
              'dispositionNotes',
              setEvidenceDispositionFormState
            )
          }
          variant="outlined"
          multiline
          InputLabelProps={{ shrink: true }}
        />
      </Grid>
    </Grid>
  );
};

const mapStateToProps = (state) => ({
  evidenceDispositionForm: state.incident.evidenceDispositionForm,
  codeEvidenceFinalDisposition: state.codesEvidence.codeEvidenceFinalDisposition,
});

export default connect(mapStateToProps, { updateIncEvidenceDispositionForm })(
  TabEvidenceDisposition
);
