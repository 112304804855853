import React from 'react';
import Grid from '@material-ui/core/Grid';
import { MuiPickersUtilsProvider, KeyboardDateTimePicker } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import { connect } from 'react-redux';
import { handleFormChangeNew } from '../../../../reducers/helpers/formHelpers';
import { v4 as uuidv4 } from 'uuid';
import RMSAutoComplete from 'components/RMSAutoComplete/RMSAutoComplete';
import { addIncEvidenceCustody } from '../../../../reducers/IncReducer';
import { Button } from '@material-ui/core';

const options = [{ name: 'Option One' }, { name: 'Option Two' }];

const CustodyFormAdd = (props) => {
  const {
    codeEvidenceDisposalCode,
    codeEvidenceCustodyReason,
    codeEvidenceRequiredAction,
    codeEvidenceCustodyStatus,
    dialogToggle,
  } = props;
  const [evidenceCustodyFormState, setEvidenceCustodyFormState] = React.useState({
    tempEvidenceCustodyId: uuidv4(),
    isValid: false,
    changes: false,
    values: {
      transactionDate: new Date(),
    },
    touched: {},
    errors: {},
  });

  const addCustody = () => {
    addIncEvidenceCustody(evidenceCustodyFormState);
    dialogToggle(false);
  };

  const handleClose = () => {
    dialogToggle(false);
  };

  return (
    <Grid container justify="center" className="mt-3">
      <Grid container spacing={2}>
        <Grid item xs={6} lg={6}>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <KeyboardDateTimePicker
              size="small"
              inputVariant="outlined"
              fullWidth
              showTodayButton={true}
              variant="dialog"
              format="MM/dd/yyyy hh:mm a"
              id="date-picker-inline-from"
              label="Transaction Date"
              value={evidenceCustodyFormState.values.transactionDate}
              onChange={(date) => {
                setEvidenceCustodyFormState((state) => {
                  return {
                    ...state,
                    values: {
                      ...state.values,
                      transactionDate: date,
                    },
                  };
                });
              }}
              KeyboardButtonProps={{
                'aria-label': 'change date',
              }}
            />
          </MuiPickersUtilsProvider>
        </Grid>
        <Grid item xs={6} lg={6}>
          <TextField
            autoComplete="hidden"
            size="small"
            id="outlined-multiline-static-address-notes"
            label="Transaction Type"
            placeholder="Transaction Type"
            fullWidth
            variant="outlined"
            name="transactionType"
            defaultValue={evidenceCustodyFormState.values.transactionType}
            onBlur={(event) =>
              handleFormChangeNew(
                event,
                event.target.value,
                'none',
                'transactionType',
                setEvidenceCustodyFormState
              )
            }
            multiline
            InputLabelProps={{ shrink: true }}
          />
        </Grid>
      </Grid>
      <Grid item xs={12} lg={12} className="mt-3">
        <Autocomplete
          size="small"
          autoComplete
          autoSelect
          autoHighlight
          id="combo-search-from"
          getOptionLabel={(option) => option || ''}
          options={codeEvidenceCustodyStatus.map((s) => s.Code)}
          key={evidenceCustodyFormState.values.Status || ''}
          value={evidenceCustodyFormState.values.Status || ''}
          onChange={(event, newValue) =>
            handleFormChangeNew(event, newValue, 'none', 'Status', setEvidenceCustodyFormState)
          }
          renderInput={(params) => (
            <Grid container spacing={1} alignItems="flex-end">
              <Grid item xs={12} lg={12}>
                <TextField
                  autoComplete="hidden"
                  size="small"
                  {...params}
                  label="Status"
                  variant="outlined"
                  fullWidth
                />
              </Grid>
            </Grid>
          )}
        />
      </Grid>
      <Grid container spacing={2} className="mt-2">
        <Grid item xs={6} lg={6}>
          <Autocomplete
            size="small"
            autoComplete
            autoSelect
            autoHighlight
            id="combo-search-from"
            getOptionLabel={(option) => option || ''}
            options={options.map((s) => s.name)}
            key={evidenceCustodyFormState.values.custodyFrom || ''}
            value={evidenceCustodyFormState.values.custodyFrom || ''}
            onChange={(event, newValue) =>
              handleFormChangeNew(
                event,
                newValue,
                'none',
                'custodyFrom',
                setEvidenceCustodyFormState
              )
            }
            renderInput={(params) => (
              <Grid container spacing={1} alignItems="flex-end">
                <Grid item xs={12} lg={12}>
                  <TextField
                    autoComplete="hidden"
                    size="small"
                    {...params}
                    label="From"
                    variant="outlined"
                    fullWidth
                  />
                </Grid>
              </Grid>
            )}
          />
        </Grid>
        <Grid item xs={6} lg={6}>
          <Autocomplete
            size="small"
            autoComplete
            autoSelect
            autoHighlight
            id="combo-search-to"
            getOptionLabel={(option) => option || ''}
            options={options.map((s) => s.name)}
            key={evidenceCustodyFormState.values.custodyTo || ''}
            value={evidenceCustodyFormState.values.custodyTo || ''}
            onChange={(event, newValue) =>
              handleFormChangeNew(event, newValue, 'none', 'custodyTo', setEvidenceCustodyFormState)
            }
            renderInput={(params) => (
              <Grid container spacing={1} alignItems="flex-end">
                <Grid item xs={12} lg={12}>
                  <TextField
                    autoComplete="hidden"
                    size="small"
                    {...params}
                    label="To"
                    variant="outlined"
                    fullWidth
                  />
                </Grid>
              </Grid>
            )}
          />
        </Grid>
      </Grid>
      <Grid item xs={12} lg={12} className="mt-3">
        <RMSAutoComplete
          size="small"
          autoComplete
          autoSelect
          autoHighlight
          id="combo-evidence-custody-reason"
          options={codeEvidenceCustodyReason}
          // new ones
          serviceName="codeevidencecustodyreason"
          label="Reason"
          variant="outlined"
          title="Code"
          stateHolder={evidenceCustodyFormState}
          setStateHolder={setEvidenceCustodyFormState}
          setField={handleFormChangeNew}
          stateHolderAttribute="reason"
        />
      </Grid>
      <Grid container justify="center">
        <Grid item xs={12} lg={12}>
          <TextField
            autoComplete="hidden"
            size="small"
            className="mt-3"
            id="outlined-multiline-static-address-notes"
            label="Notes"
            placeholder="Notes"
            rows="4"
            fullWidth
            defaultValue={evidenceCustodyFormState.values.notes}
            variant="outlined"
            name="notes"
            onBlur={(event) =>
              handleFormChangeNew(
                event,
                event.target.value,
                'none',
                'notes',
                setEvidenceCustodyFormState
              )
            }
            multiline
            InputLabelProps={{ shrink: true }}
          />
        </Grid>
      </Grid>
      <Grid container spacing={2} className="mt-2">
        <Grid item xs={6} lg={6}>
          <RMSAutoComplete
            size="small"
            autoComplete
            autoSelect
            autoHighlight
            id="combo-evidence-required-action"
            options={codeEvidenceRequiredAction}
            // new ones
            serviceName="codeevidencerequiredaction"
            label="Required Action"
            variant="outlined"
            title="Code"
            stateHolder={evidenceCustodyFormState}
            setStateHolder={setEvidenceCustodyFormState}
            setField={handleFormChangeNew}
            stateHolderAttribute="requiredAction"
          />
        </Grid>
        <Grid item xs={6} lg={6}>
          <RMSAutoComplete
            size="small"
            autoComplete
            autoSelect
            autoHighlight
            id="combo-evidence-disposal-code"
            options={codeEvidenceDisposalCode}
            // new ones
            serviceName="codeevidencedisposalcode"
            label="Disposal Code"
            variant="outlined"
            title="Code"
            stateHolder={evidenceCustodyFormState}
            setStateHolder={setEvidenceCustodyFormState}
            setField={handleFormChangeNew}
            stateHolderAttribute="disposalCode"
          />
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Grid
            container
            direction="row"
            justify="flex-end"
            alignItems="center"
            style={{ marginTop: 30, marginBottom: 5 }}>
            <Button onClick={handleClose} style={{ marginRight: 10 }}>
              Close
            </Button>
            <Button
              onClick={addCustody}
              style={{ background: '#1976d2', color: '#fff' }}
              variant="contained">
              Add
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

const mapStateToProps = (state) => ({
  evidenceCustodyFormNew: state.incident.evidenceCustodyFormNew,
  codeEvidenceDisposalCode: state.codesEvidence.codeEvidenceDisposalCode,
  codeEvidenceCustodyReason: state.codesEvidence.codeEvidenceCustodyReason,
  codeEvidenceRequiredAction: state.codesEvidence.codeEvidenceRequiredAction,
  codeEvidenceCustodyStatus: state.codesEvidence.codeEvidenceCustodyStatus,
});

export default connect(mapStateToProps, {})(CustodyFormAdd);
