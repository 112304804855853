import React, { useState } from 'react';
import { useHistory } from 'react-router';

import 'date-fns';
import clsx from 'clsx';

import { makeStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import { Fab } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import Fade from '@material-ui/core/Fade';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

import ScrollBar from 'react-perfect-scrollbar';
import PerfectScrollbar from 'react-perfect-scrollbar';

import EnhancedTableToolbar from './EnhancedTableToolbar';
import EnhancedTableHead from './EnhancedTableHead';
import { connect } from 'react-redux';
import { setListBodyCells } from '../../reducers/ui/UiListReducer';
import RMSPersonCell from './RMSEnhancedCells/RMSPersonCell';
import RMSPropertiesCell from './RMSEnhancedCells/RMSPropertyCell';
import RMSAddressCell from './RMSEnhancedCells/RMSAddressCell';
import RMSOffenseCell from './RMSEnhancedCells/RMSOffenseCell';
import RMSEvidenceCell from './RMSEnhancedCells/RMSEvidenceCell';
import RMSNarrativeCell from './RMSEnhancedCells/RMSNarrativeCell';
import RMSDispositionCell from './RMSEnhancedCells/RMSDispositionCell';

import RMSEditButton from '../RMSButtons/RMSEditButton';
import RMSDeleteButton from '../RMSButtons/RMSDeleteButton';
import {
  setSelectedEntity,
  getEntitiesByIncidentId,
  removeSelectedEntity,
  resetOffenseLists,
  setCurrentOffense,
  resetSubForms,
  updateIncPartiesPersonForm,
  updateIncPartiesContactForm,
  updateIncPartiesEmploymentForm,
  updateIncOffensesMPBasicForm,
  updateIncMPRelatedRecords,
  updateIncPartiesOthersForm,
} from '../../reducers/IncReducer';
import 'react-perfect-scrollbar/dist/css/styles.css';
import RMSZoneCell from './RMSEnhancedCells/RMSZoneCell';
import RMSAlertCell from './RMSEnhancedCells/RMSAlertCell';
import RMSNotificationCell from './RMSEnhancedCells/RMSNotificationCell';

const useStyles = makeStyles((theme) => ({
  root: {
    boxSizing: 'border-box',
  },
  paper: {
    width: '100%',
    backgroundColor: '#fff',
    '@media (max-width: 1920px)': {
      width: '540px',
    },
    '@media (max-width: 1500px)': {
      width: '300px',
    },
    '@media (max-width: 1024px)': {
      width: '240px',
    },
    '@media (max-width: 992px)': {
      width: '230px',
    },
    '@media (max-width: 768px)': {
      width: '160px',
    },
  },
  table: {
    padding: '0px',
    width: '100%',
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  margin: {
    margin: theme.spacing(1),
  },
  button: {
    margin: theme.spacing(1),
  },
  incidentSelector: {
    '&:hover': {
      cursor: 'pointer',
    },
  },
  spinnerLoadingContainer: {
    position: 'fixed',
    top: '55%',
    left: '60%',
    '& span': {
      marginLeft: '-5px',
    },
  },
  ScrollBar: {
    '&::-webkit-scrollbar': {
      width: '6px',
    },
    '&::-webkit-scrollbar-track': {
      borderRadius: '6px',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: 'rgba(0, 0, 0, 0.1)',
      borderRadius: '6px',
      right: '2px',
      position: 'absolute',
      transition: 'background-color 0.2s linear, width 0.2s ease-in-out',
    },
  },
}));

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const RMSList = (props) => {
  /** region Component Variables and Props */
  const {
    bodyState,
    entityFlag,
    selectedIncident,
    wsClient,
    setSelectedEntity,
    removeSelectedEntity,
    setListBodyCells,
    getEntitiesByIncidentId,
    updateIncPartiesPersonForm,
    updateIncPartiesContactForm,
    updateIncPartiesEmploymentForm,
    updateIncMPRelatedRecords,
    updateIncOffensesMPBasicForm,
    updateIncPartiesOthersForm,
    parties,
    selectedEntity,
    searchKeyword,
  } = props;
  const classes = useStyles();
  const [order, setOrder] = React.useState('asc');
  const [spinnerLoading, setSpinnerLoading] = React.useState({
    parties: true,
    addresses: true,
    properties: true,
    offenses: true,
    evidence: true,
    narratives: true,
    dispositions: true,
    zones: true,
  });
  const [orderBy, setOrderBy] = React.useState('calories');
  const [selected, setSelected] = React.useState([]);
  const [rows, setRows] = useState([]);
  const [rowKeyProperty, setRowKeyProperty] = React.useState();
  const [parentType, setParentType] = React.useState(null);
  const history = useHistory();
  /** endregion */
  /** region React Hooks */
  React.useEffect(() => {
    setRowKeyProperty(determineRowKey());
    if (entityFlag !== 'parties') return;

    setSpinnerLoading({
      parties: true,
      properties: false,
      offenses: false,
      evidence: false,
      narratives: false,
      addresses: false,
      dispositions: false,
      zones: false,
      alerts: false,
      notifications: false,
    });

    const runEffect = async () => {
      if (selectedIncident && wsClient) {
        await getEntitiesByIncidentId(entityFlag, searchKeyword).then(() => true);
      }

      await setListBodyCells(entityFlag);
    };

    const timeoutId = setTimeout(async () => {
      await runEffect().then((res) => {
        setSpinnerLoading({
          parties: false,
          addresses: false,
          properties: false,
          offenses: false,
          evidence: false,
          narratives: false,
          dispositions: false,
          zones: false,
          alerts: false,
          notifications: false,
        });
      });
    }, 2000);

    return () => clearTimeout(timeoutId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [entityFlag, searchKeyword?.partiesKeyword]);

  React.useEffect(() => {
    setRowKeyProperty(determineRowKey());
    if (entityFlag !== 'properties') return;

    setSpinnerLoading({
      parties: false,
      properties: true,
      offenses: false,
      evidence: false,
      narratives: false,
      addresses: false,
      dispositions: false,
      zones: false,
      alerts: false,
      notifications: false,
    });

    const runEffect = async () => {
      if (selectedIncident && wsClient) {
        await getEntitiesByIncidentId(entityFlag, searchKeyword).then(() => true);
      }

      await setListBodyCells(entityFlag);
    };

    const timeoutId = setTimeout(async () => {
      await runEffect().then((res) => {
        setSpinnerLoading({
          parties: false,
          addresses: false,
          properties: false,
          offenses: false,
          evidence: false,
          narratives: false,
          dispositions: false,
          zones: false,
          alerts: false,
          notifications: false,
        });
      });
    }, 2000);

    return () => clearTimeout(timeoutId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchKeyword?.propertiesKeyword]);

  React.useEffect(() => {
    setRowKeyProperty(determineRowKey());
    if (entityFlag !== 'offenses') return;

    setSpinnerLoading({
      parties: false,
      properties: false,
      offenses: true,
      evidence: false,
      narratives: false,
      addresses: false,
      dispositions: false,
      zones: false,
      alerts: false,
      notifications: false,
    });

    const runEffect = async () => {
      if (selectedIncident && wsClient) {
        await getEntitiesByIncidentId(entityFlag, searchKeyword).then(() => true);
      }

      await setListBodyCells(entityFlag);
    };

    const timeoutId = setTimeout(async () => {
      await runEffect().then((res) => {
        setSpinnerLoading({
          parties: false,
          addresses: false,
          properties: false,
          offenses: false,
          evidence: false,
          narratives: false,
          dispositions: false,
          zones: false,
          alerts: false,
          notifications: false,
        });
      });
    }, 2000);

    return () => clearTimeout(timeoutId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchKeyword?.offensesKeyword]);

  React.useEffect(() => {
    setRowKeyProperty(determineRowKey());
    if (entityFlag !== 'evidence') return;

    setSpinnerLoading({
      parties: false,
      properties: false,
      offenses: false,
      evidence: true,
      narratives: false,
      addresses: false,
      dispositions: false,
      zones: false,
      alerts: false,
      notifications: false,
    });

    const runEffect = async () => {
      if (selectedIncident && wsClient) {
        await getEntitiesByIncidentId('properties');

        await getEntitiesByIncidentId(entityFlag, searchKeyword).then(() => true);
      }

      await setListBodyCells(entityFlag);
    };

    const timeoutId = setTimeout(async () => {
      await runEffect().then((res) => {
        setSpinnerLoading({
          parties: false,
          addresses: false,
          properties: false,
          offenses: false,
          evidence: false,
          narratives: false,
          dispositions: false,
          zones: false,
          alerts: false,
          notifications: false,
        });
      });
    }, 2000);

    return () => clearTimeout(timeoutId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchKeyword?.evidencesKeyword]);

  React.useEffect(() => {
    setRowKeyProperty(determineRowKey());
    if (entityFlag !== 'narratives') return;

    setSpinnerLoading({
      parties: false,
      properties: false,
      offenses: false,
      evidence: false,
      narratives: true,
      addresses: false,
      dispositions: false,
      zones: false,
      alerts: false,
      notifications: false,
    });

    const runEffect = async () => {
      if (selectedIncident && wsClient) {
        await getEntitiesByIncidentId(entityFlag, searchKeyword).then(() => true);
      }

      await setListBodyCells(entityFlag);
    };

    const timeoutId = setTimeout(async () => {
      await runEffect().then((res) => {
        setSpinnerLoading({
          parties: false,
          addresses: false,
          properties: false,
          offenses: false,
          evidence: false,
          narratives: false,
          dispositions: false,
          zones: false,
          alerts: false,
          notifications: false,
        });
      });
    }, 2000);

    return () => clearTimeout(timeoutId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchKeyword?.narrativesKeyword]);

  React.useEffect(() => {
    setRowKeyProperty(determineRowKey());
    if (entityFlag !== 'addresses') return;

    setSpinnerLoading({
      parties: false,
      properties: false,
      offenses: false,
      evidence: false,
      narratives: false,
      addresses: true,
      dispositions: false,
      zones: false,
      alerts: false,
      notifications: false,
    });

    const runEffect = async () => {
      if (selectedIncident && wsClient) {
        await getEntitiesByIncidentId(entityFlag, searchKeyword).then(() => true);
      }

      await setListBodyCells(entityFlag);
    };

    const timeoutId = setTimeout(async () => {
      await runEffect().then((res) => {
        setSpinnerLoading({
          parties: false,
          addresses: false,
          properties: false,
          offenses: false,
          evidence: false,
          narratives: false,
          dispositions: false,
          zones: false,
          alerts: false,
          notifications: false,
        });
      });
    }, 2000);

    return () => clearTimeout(timeoutId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchKeyword?.addressesKeyword]);

  React.useEffect(() => {
    setRowKeyProperty(determineRowKey());
    if (entityFlag !== 'dispositions') return;

    setSpinnerLoading({
      parties: false,
      properties: false,
      offenses: false,
      evidence: false,
      narratives: false,
      addresses: false,
      dispositions: true,
      zones: false,
      alerts: false,
      notifications: false,
    });

    const runEffect = async () => {
      if (selectedIncident && wsClient) {
        await getEntitiesByIncidentId(entityFlag, searchKeyword).then(() => true);
      }

      await setListBodyCells(entityFlag);
    };

    const timeoutId = setTimeout(async () => {
      await runEffect().then((res) => {
        setSpinnerLoading({
          parties: false,
          addresses: false,
          properties: false,
          offenses: false,
          evidence: false,
          narratives: false,
          dispositions: false,
          zones: false,
          alerts: false,
          notifications: false,
        });
      });
    }, 2000);

    return () => clearTimeout(timeoutId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchKeyword?.dispositionsKeyword]);

  React.useEffect(() => {
    setRowKeyProperty(determineRowKey());
    if (entityFlag !== 'zones') return;

    setSpinnerLoading({
      parties: false,
      properties: false,
      offenses: false,
      evidence: false,
      narratives: false,
      addresses: false,
      dispositions: false,
      zones: true,
      alerts: false,
      notifications: false,
    });

    const runEffect = async () => {
      if (selectedIncident && wsClient) {
        await getEntitiesByIncidentId(entityFlag, searchKeyword).then(() => true);
      }

      await setListBodyCells(entityFlag);
    };

    const timeoutId = setTimeout(async () => {
      await runEffect().then((res) => {
        setSpinnerLoading({
          parties: false,
          addresses: false,
          properties: false,
          offenses: false,
          evidence: false,
          narratives: false,
          dispositions: false,
          zones: false,
          alerts: false,
          notifications: false,
        });
      });
    }, 2000);

    return () => clearTimeout(timeoutId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchKeyword?.zonesKeyword]);

  React.useEffect(() => {
    setRowKeyProperty(determineRowKey());
    if (entityFlag !== 'notifications') return;

    setSpinnerLoading({
      parties: false,
      properties: false,
      offenses: false,
      evidence: false,
      narratives: false,
      addresses: false,
      dispositions: false,
      zones: false,
      alerts: false,
      notifications: true,
    });

    const runEffect = async () => {
      if (selectedIncident && wsClient) {
        await getEntitiesByIncidentId(entityFlag, searchKeyword).then(() => true);
      }

      await setListBodyCells(entityFlag);
    };

    const timeoutId = setTimeout(async () => {
      await runEffect().then((res) => {
        setSpinnerLoading({
          parties: false,
          addresses: false,
          properties: false,
          offenses: false,
          evidence: false,
          narratives: false,
          dispositions: false,
          zones: false,
          alerts: false,
          notifications: false,
        });
      });
    }, 2000);

    return () => clearTimeout(timeoutId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchKeyword?.zonesKeyword]);

  React.useEffect(() => {
    setRowKeyProperty(determineRowKey());
    if (entityFlag !== 'alerts') return;

    setSpinnerLoading({
      parties: false,
      properties: false,
      offenses: false,
      evidence: false,
      narratives: false,
      addresses: false,
      dispositions: false,
      zones: false,
      alerts: true,
      notifications: false,
    });

    const runEffect = async () => {
      if (selectedIncident && wsClient) {
        await getEntitiesByIncidentId(entityFlag, searchKeyword).then(() => true);
      }

      await setListBodyCells(entityFlag);
    };

    const timeoutId = setTimeout(async () => {
      await runEffect().then((res) => {
        setSpinnerLoading({
          parties: false,
          addresses: false,
          properties: false,
          offenses: false,
          evidence: false,
          narratives: false,
          dispositions: false,
          zones: false,
          alerts: false,
          notifications: false,
        });
      });
    }, 2000);

    return () => clearTimeout(timeoutId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchKeyword?.alertsKeyword]);

  React.useEffect(() => {
    if (bodyState && bodyState[entityFlag]) setRows(bodyState[entityFlag]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bodyState[entityFlag]]);
  /** End Region */
  /*Region: Helper Functions*/
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleMissingPersonClick = () => {
    const { ptsPersonId, ptsIncPersonId } = selectedEntity;

    const selectedPartyDetail = parties.filter(
      (a) =>
        a.personDetail.ptsPersonId === ptsPersonId ||
        a.personDetail.ptsIncPersonId === ptsIncPersonId
    );

    if (selectedPartyDetail.length === 0) {
      return;
    }

    const {
      personDetail,
      contactDetail,
      employmentDetail,
      otherDetail,
      mpBasicDetail,
      mpRelatedRecords,
    } = selectedPartyDetail[0];

    updateIncPartiesPersonForm(personDetail);
    updateIncPartiesContactForm(contactDetail);
    updateIncPartiesEmploymentForm(employmentDetail);
    updateIncPartiesOthersForm(otherDetail);
    updateIncOffensesMPBasicForm(mpBasicDetail);
    updateIncMPRelatedRecords(mpRelatedRecords);

    history.push('/incidents/add/missing-person');
  };

  const determineRowKey = () => {
    const keys = {
      addresses: 'ptsAddressId',
      parties: 'ptsIncPersonId',
      offenses: 'ptsOffenseId',
      properties: 'incIncidentPropertyId',
      evidence: 'ptsEvidenceId',
      narratives: 'ptsNarrativeId',
      zones: 'zone',
      dispositions: 'disposition',
      notifications: 'ptsNotificationId',
      alerts: 'ptsAlertId',
    };
    return keys[entityFlag];
  };

  const handleClick = (event, row) => {
    event.persist();

    const isProperty = entityFlag === 'properties';
    if (isProperty) {
      setParentType(row.parentType);
    }

    let newSelected = [];
    if (!selected.length || selected[0] !== row[rowKeyProperty]) {
      newSelected.push(row[rowKeyProperty]);
    }
    setSelected(newSelected);

    setSelectedEntity({ [rowKeyProperty]: row[rowKeyProperty] });
  };

  const isSelected = (row) => {
    return selected.length && selected[0] === row[rowKeyProperty];
  };

  const handleDeleteClick = async () => {
    await removeSelectedEntity(entityFlag);
    await setListBodyCells(entityFlag);
  };

  let editUrl = `/incidents/edit/${selectedIncident}/`;

  if (parentType) {
    editUrl += parentType.toLowerCase();
  } else {
    if (entityFlag !== 'parties') {
      editUrl += entityFlag;
    }
  }

  const handleEdit = (row) => {
    resetOffenseLists(entityFlag);
    resetSubForms('edit');

    if (row.partyType && row.partyType === 'Offender') {
      editUrl += 'offenders/' + row.ptsIncPersonId;
    } else if (row.partyType && row.partyType === 'Victim') {
      editUrl += 'victims/' + row.ptsIncPersonId;
    } else if (row.partyType && row.partyType === 'Suspect') {
      editUrl += 'suspects/' + row.ptsIncPersonId;
    } else if (row.partyType) {
      editUrl += 'others/' + row.ptsIncPersonId;
    } else if (row.ptsIncPersonId) {
      editUrl += 'others/' + row.ptsIncPersonId;
    } else {
      editUrl += `/${row.id}`;
    }

    history.push(editUrl);
  };
  /** endregion */
  return (
    <div className={clsx(classes.root)}>
      <Paper
        className={props.dnd ? classes.paper : ''}
        style={{
          minHeight: props.dnd
            ? rows.length >= 4
              ? '600px'
              : rows.length === 3
              ? '350px'
              : '250px'
            : window.innerHeight - 191 + 'px',
          marginBottom: entityFlag !== 'addresses' ? '10px' : '10px',
        }}>
        {!props.dnd && <EnhancedTableToolbar entityFlag={entityFlag} />}
        <TableContainer
          style={{
            maxHeight: '100%',
            marginBottom: entityFlag !== 'addresses' ? '10px' : '20px',
            alignItems: 'center',
            display: 'block',
            justifyContent: 'center',
          }}
          className={classes.ScrollBar}>
          {props.dnd && (
            <ScrollBar
              component="Table"
              style={{
                width: props.dnd ? '540px' : '100%',
                whiteSpace: 'nowrap',
                minHeight: props.dnd
                  ? rows.length >= 4
                    ? '600px'
                    : rows.length === 3
                    ? '350px'
                    : '250px'
                  : window.innerHeight - 400 + 'px',
                marginBottom: entityFlag !== 'addresses' ? '30px' : '20px',
                overflow: 'scroll',
              }}>
              <Table
                style={{
                  width: '100%',
                }}
                aria-label="sticky table"
                aria-labelledby="tableTitle"
                size="small">
                <EnhancedTableHead
                  classes={classes}
                  numSelected={selected.length}
                  order={order}
                  orderBy={orderBy}
                  onRequestSort={handleRequestSort}
                  rowCount={rows.length}
                  entityFlag={entityFlag}
                  dnd={props.dnd}
                />

                <TableBody>
                  {spinnerLoading[entityFlag] && !props.dnd && (
                    <div
                      className={entityFlag !== 'addresses' ? classes.spinnerLoadingContainer : ''}>
                      <div>
                        {' '}
                        <CircularProgress disableShrink />
                      </div>
                      <span>Loading...</span>
                    </div>
                  )}

                  {!spinnerLoading[entityFlag] &&
                    stableSort(rows, getComparator(order, orderBy)).map((row, index) => {
                      const isItemSelected = isSelected(row);

                      const key = rowKeyProperty
                        ? row[rowKeyProperty]
                        : row.name ||
                          row.fullName ||
                          row.FullAddressText ||
                          row.ptsPropertyId ||
                          row.ptsOffenseId ||
                          row.ptsEvidenceId ||
                          row.ptsNarrativeId ||
                          row.disposition ||
                          row.zone ||
                          row.ptsAlertId ||
                          row.ptsNotificationId;

                      return (
                        <TableRow
                          hover
                          onMouseEnter={(event) => handleClick(event, row)}
                          onMouseLeave={(event) => setSelected([])}
                          role="checkbox"
                          aria-checked={isItemSelected}
                          tabIndex={-1}
                          key={key}
                          selected={isItemSelected}
                          className={classes.incidentSelector}>
                          {entityFlag === 'addresses' && <RMSAddressCell row={row} />}
                          {entityFlag === 'parties' && <RMSPersonCell row={row} />}
                          {entityFlag === 'properties' && <RMSPropertiesCell row={row} />}
                          {entityFlag === 'offenses' && <RMSOffenseCell row={row} />}
                          {entityFlag === 'evidence' && <RMSEvidenceCell row={row} />}
                          {entityFlag === 'narratives' && <RMSNarrativeCell row={row} />}

                          {entityFlag === 'dispositions' && <RMSDispositionCell row={row} />}
                          {entityFlag === 'zones' && <RMSZoneCell row={row} />}
                          {entityFlag === 'alerts' && <RMSAlertCell row={row} />}
                          {entityFlag === 'notifications' && <RMSNotificationCell row={row} />}
                          {!props.dnd && (
                            <TableCell>
                              <Fade in={selected.length > 0 && isItemSelected === true}>
                                <Grid container wrap="nowrap" spacing={2} justify="flex-end">
                                  {row.isMP && (
                                    <Grid item className="mt-2" justify="flex-end">
                                      <Tooltip title="Missing Person">
                                        <Fab
                                          onClick={handleMissingPersonClick}
                                          size="small"
                                          color="secondary"
                                          aria-label="edit"
                                          style={{ width: '40px', height: '40px' }}>
                                          MP
                                        </Fab>
                                      </Tooltip>
                                    </Grid>
                                  )}
                                  {!['dispositions', 'alerts', 'notifications', 'zones'].includes(
                                    entityFlag
                                  ) && (
                                    <Grid item className="mt-2" justify="flex-end">
                                      <RMSEditButton onClick={() => handleEdit(row)} />
                                    </Grid>
                                  )}
                                  <Grid item className="mt-2" justify="flex-end">
                                    <RMSDeleteButton onClick={handleDeleteClick} />
                                  </Grid>
                                </Grid>
                              </Fade>
                            </TableCell>
                          )}
                        </TableRow>
                      );
                    })}
                  {!spinnerLoading[entityFlag] && rows.length < 1 && (
                    <div style={{ margin: '20px 0px 0px 20px' }}>
                      <h6>No Data Found</h6>
                    </div>
                  )}
                </TableBody>
              </Table>
            </ScrollBar>
          )}
          {!props.dnd && (
            <PerfectScrollbar
              style={{
                maxHeight: window.innerHeight - 400 + 'px',
                marginBottom: entityFlag !== 'addresses' ? '30px' : '20px',
                overflow: 'scroll',
                width: '100%',
              }}>
              <Table
                style={{
                  width: '100%',
                }}
                aria-label="sticky table"
                aria-labelledby="tableTitle"
                size="small">
                <EnhancedTableHead
                  classes={classes}
                  numSelected={selected.length}
                  order={order}
                  orderBy={orderBy}
                  onRequestSort={handleRequestSort}
                  rowCount={rows.length}
                  entityFlag={entityFlag}
                  dnd={props.dnd}
                />

                <TableBody>
                  {spinnerLoading[entityFlag] && !props.dnd && (
                    <div
                      className={entityFlag !== 'addresses' ? classes.spinnerLoadingContainer : ''}>
                      <div>
                        {' '}
                        <CircularProgress disableShrink />
                      </div>
                      <span>Loading...</span>
                    </div>
                  )}

                  {!spinnerLoading[entityFlag] &&
                    stableSort(rows, getComparator(order, orderBy)).map((row, index) => {
                      const isItemSelected = isSelected(row);

                      const key = rowKeyProperty
                        ? row[rowKeyProperty]
                        : row.name ||
                          row.fullName ||
                          row.FullAddressText ||
                          row.ptsPropertyId ||
                          row.ptsOffenseId ||
                          row.ptsEvidenceId ||
                          row.ptsNarrativeId ||
                          row.disposition ||
                          row.zone ||
                          row.ptsAlertId ||
                          row.ptsNotificationId;

                      return (
                        <TableRow
                          hover
                          onMouseEnter={(event) => handleClick(event, row)}
                          onMouseLeave={(event) => setSelected([])}
                          role="checkbox"
                          aria-checked={isItemSelected}
                          tabIndex={-1}
                          key={key}
                          selected={isItemSelected}
                          className={classes.incidentSelector}>
                          {entityFlag === 'addresses' && <RMSAddressCell row={row} />}
                          {entityFlag === 'parties' && <RMSPersonCell row={row} />}
                          {entityFlag === 'properties' && <RMSPropertiesCell row={row} />}
                          {entityFlag === 'offenses' && <RMSOffenseCell row={row} />}
                          {entityFlag === 'evidence' && <RMSEvidenceCell row={row} />}
                          {entityFlag === 'narratives' && <RMSNarrativeCell row={row} />}

                          {entityFlag === 'dispositions' && <RMSDispositionCell row={row} />}
                          {entityFlag === 'zones' && <RMSZoneCell row={row} />}
                          {entityFlag === 'alerts' && <RMSAlertCell row={row} />}
                          {entityFlag === 'notifications' && <RMSNotificationCell row={row} />}
                          {!props.dnd && (
                            <TableCell>
                              <Fade in={selected.length > 0 && isItemSelected === true}>
                                <Grid container wrap="nowrap" spacing={2} justify="flex-end">
                                  {row.isMP && (
                                    <Grid item className="mt-2" justify="flex-end">
                                      <Tooltip title="Missing Person">
                                        <Fab
                                          onClick={handleMissingPersonClick}
                                          size="small"
                                          color="secondary"
                                          aria-label="edit"
                                          style={{ width: '40px', height: '40px' }}>
                                          MP
                                        </Fab>
                                      </Tooltip>
                                    </Grid>
                                  )}
                                  {!['dispositions', 'alerts', 'notifications', 'zones'].includes(
                                    entityFlag
                                  ) && (
                                    <Grid item className="mt-2" justify="flex-end">
                                      <RMSEditButton onClick={() => handleEdit(row)} />
                                    </Grid>
                                  )}
                                  <Grid item className="mt-2" justify="flex-end">
                                    <RMSDeleteButton onClick={handleDeleteClick} />
                                  </Grid>
                                </Grid>
                              </Fade>
                            </TableCell>
                          )}
                        </TableRow>
                      );
                    })}
                  {!spinnerLoading[entityFlag] && rows.length < 1 && (
                    <div style={{ margin: '20px 0px 0px 20px' }}>
                      <h6>No Data Found</h6>
                    </div>
                  )}
                </TableBody>
              </Table>
            </PerfectScrollbar>
          )}
        </TableContainer>
      </Paper>
    </div>
  );
};

const mapStateToProps = (state) => ({
  bodyState: state.uiList.incListBodyState,
  selectedIncident: state.incident.ptsIncidentId,
  wsClient: state.websocket.websocket,
  selectedEntity: state.incident.selectedEntity,
  parties: state.incident.parties,
  searchKeyword: state.incident.searchKeyword,
});

export default connect(mapStateToProps, {
  setListBodyCells,
  setSelectedEntity,
  getEntitiesByIncidentId,
  setCurrentOffense,
  removeSelectedEntity,
  updateIncPartiesPersonForm,
  updateIncPartiesContactForm,
  updateIncPartiesEmploymentForm,
  updateIncOffensesMPBasicForm,
  updateIncMPRelatedRecords,
  updateIncPartiesOthersForm,
})(RMSList);
