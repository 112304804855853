/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { connect } from 'react-redux';

import { v4 as uuidv4 } from 'uuid';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';

import { updateIncPropertyItemForm, updateIncEvidenceItemForm } from '../../reducers/IncReducer';
import {
  getCodeItemModels,
  getCodeItemConditions,
  getCodeItemMakes,
} from '../../reducers/dictionaries/CodeItemReducer';
import { handleFormChangeNew } from '../../reducers/helpers/formHelpers';
import { checkFormType } from '../helpers/checkFormState';

const TabAlerts = (props) => {
  /** region Component Variables and Props */
  const {
    propertyItemForm,
    updateIncPropertyItemForm,
    updateIncEvidenceItemForm,
    getCodeItemModels,
    getCodeItemConditions,
    getCodeItemMakes,
    codeItemConditions,
    codeItemMakes,
    codeItemModels,
    wsClient,
    history,
  } = props;
  const [changeKey, setChangeKey] = React.useState(0);
  const [pageType, setPageType] = React.useState('add');
  const [entityType, setEntityType] = React.useState('');
  const [propertyItemFormState, setPropertyItemFormState] = React.useState({
    tempPropId: uuidv4(),
    isValid: false,
    changes: false,
    values: {},
    touched: {},
    errors: {},
    formType: 'edit',
  });

  /** endregion */
  /** region React Hooks */
  React.useEffect(() => {
    checkFormType(
      propertyItemFormState.ptsItemId !== undefined && propertyItemFormState.ptsItemId !== null,
      setPropertyItemFormState,
      history
    );
    const parts = history.location.pathname.split('/');
    let type = '';
    if (parts.length === 4) type = parts[parts.length - 2];
    if (parts.length === 5) type = parts[parts.length - 3];

    setEntityType(parts[1]);
    setPageType(type);
  }, []);

  React.useEffect(() => {
    if (wsClient.websocket) {
      getCodeItemModels();
      getCodeItemConditions();
      getCodeItemMakes();
    }
  }, [getCodeItemMakes, getCodeItemModels, getCodeItemConditions, wsClient]);

  React.useEffect(() => {
    setPropertyItemFormState((propertyItemFormState) => ({
      ...propertyItemFormState,
      ...propertyItemForm,
      values: {
        ...propertyItemFormState.values,
        ...propertyItemForm.values,
      },
    }));
  }, [propertyItemForm]);

  React.useEffect(() => {
    propertyItemFormState.changes = true;
    updateIncPropertyItemForm(propertyItemFormState);

    if (propertyItemFormState.values.isEvidence) {
      updateIncEvidenceItemForm({
        values: {
          make: propertyItemFormState.values.make,
          model: propertyItemFormState.values.model,
          condition: propertyItemFormState.values.condition,
          serialNumber: propertyItemFormState.values.serialNumber,
          category: 'Property',
          description: `${propertyItemFormState.values?.name || ''} ${
            propertyItemFormState.values?.description || ''
          } ${propertyItemFormState.values?.make || ''}`,
          incIncidentPropertyId: propertyItemFormState.incIncidentPropertyId,
          offenses: [],
        },
      });
    }
  }, [
    propertyItemFormState.values?.incidentId,
    propertyItemFormState.values?.itemId,
    propertyItemFormState.values?.name,
    propertyItemFormState.values?.description,
    propertyItemFormState.values?.condition,
    propertyItemFormState.values?.make,
    propertyItemFormState.values?.model,
    propertyItemFormState.values?.serialNumber,
    propertyItemFormState.values?.isEvidence,
    propertyItemFormState.values?.attachments,
  ]);

  React.useEffect(() => {
    if (!wsClient?.websocket) {
      return;
    }

    const getAttachments = async () => {
      const service = wsClient?.websocket.service('rms-attachments');
      service.timeout = 200000;

      const result = await service.find({
        query: {
          id:
            entityType === 'records'
              ? propertyItemFormState.ptsItemId
              : propertyItemFormState.incIncidentPropertyId,
          type: entityType === 'records' ? 'ITEM' : 'INCIDENTPROPERTY',
        },
      });
      let atts = [];
      result.map((r) => {
        atts.push({
          ptsAttachmentId: r.ptsAttachmentID,
          name: r.FileName,
          type: r.FileType,
          dataURL: r.FileObject,
        });
      });
      setPropertyItemFormState((stateValue) => ({
        ...stateValue,
        values: {
          ...stateValue.values,
          attachments: atts,
        },
      }));
    };

    if (propertyItemFormState.ptsItemId) {
      getAttachments();
    }
  }, [wsClient, propertyItemFormState.ptsItemId]);
  /** endregion */
  return (
    <Grid item xs={12} lg={12}>
      <Grid item xs={12} lg={12} className="mb-3 mt-3">
        <TextField
          id="standard-textarea-item-alerts"
          autoComplete="hidden"
          size="small"
          placeholder="Alerts"
          label="Alerts"
          variant="outlined"
          name="alerts"
          key={changeKey}
          defaultValue={propertyItemFormState.values?.alerts || ''}
          onBlur={(event) =>
            handleFormChangeNew(
              event,
              event.target.value,
              'none',
              'alerts',
              setPropertyItemFormState
            )
          }
          fullWidth
          multiline
          InputLabelProps={{ shrink: true }}
        />
      </Grid>
    </Grid>
  );
};

const mapStateToProps = (state) => ({
  propertyItemForm: state.incident.propertyItemForm,
  wsClient: state.websocket,
  codeItemConditions: state.codeItem.codeItemConditions,
  codeItemMakes: state.codeItem.codeItemMakes,
  codeItemModels: state.codeItem.codeItemModels,
});

export default connect(mapStateToProps, {
  updateIncPropertyItemForm,
  getCodeItemModels,
  getCodeItemMakes,
  getCodeItemConditions,
  updateIncEvidenceItemForm,
})(TabAlerts);
