/* eslint-disable array-callback-return */
import store from '../config/configureStore';
import { duplicateCheck } from './helpers/duplicateCheckHelpers';
import {
  getEntitiesByIncId,
  removeIncident,
  removeEntity,
  addNewEvidenceItem,
} from './helpers/incHelpers';
import { notify } from './NotifierReducer';
import { ptsStatute } from './_dev/ptsStatute';

/************************************/
/****** Region Action Types ********/
/**********************************/
export const SET_INCIDENT = 'SET_INCIDENT';
export const SET_EVENT = 'SET_EVENT';
export const GET_EVENTS = 'GET_EVENTS';
export const SET_INC_ADDRESS = 'SET_INC_ADDRESS';
export const SET_INC_ADDRESS_ID = 'SET_INC_ADDRESS_ID';
export const SET_INC_COORDINATES = 'SET_INC_COORDINATES';
export const SET_INC_PARTIES_PERSON = 'SET_INC_PARTIES_PERSON';
export const SET_INC_PARTIES_CONTACT = 'SET_INC_PARTIES_CONTACT';
export const SET_INC_PARTIES_EMPLOYMENT = 'SET_INC_PARTIES_EMPLOYMENT';
export const SET_INC_PARTIES_OTHERS = 'SET_INC_PARTIES_OTHERS';
export const SET_INC_PARTIES_PLACE = 'SET_INC_PARTIES_PLACE';
export const SET_INC_PROPERTY_ITEM = 'SET_INC_PROPERTY_ITEM';
export const SET_INC_PROPERTY_FIREARM = 'SET_INC_PROPERTY_FIREARM';
export const SET_INC_PROPERTY_JEWELRY = 'SET_INC_PROPERTY_JEWELRY';
export const SET_INC_PROPERTY_STRUCTURE = 'SET_INC_PROPERTY_STRUCTURE';
export const SET_INC_PROPERTY_SUBSTANCE = 'SET_INC_PROPERTY_SUBSTANCE';
export const SET_INC_PROPERTY_VEHICLE_DETAILS = 'SET_INC_PROPERTY_VEHICLE_DETAILS';
export const SET_INC_PROPERTY_CREDENTIALS_ANALYSIS = 'SET_INC_PROPERTY_CREDENTIALS_ANALYSIS';
export const SET_INC_OFFENSES_OFFENSE = 'SET_INC_OFFENSES_OFFENSE';
export const SET_INC_OFFENSES_PROPERTY_OWNER = 'SET_INC_OFFENSES_PROPERTY_OWNER';
export const SET_INC_OFFENSES_VICTIM = 'SET_INC_OFFENSES_VICTIM';
export const SET_INC_OFFENSES_VICTIM_SUBFORMS = 'SET_INC_OFFENSES_VICTIM_SUBFORMS';
export const SET_INC_OFFENSES_DV_VICTIM = 'SET_INC_OFFENSES_DV_VICTIM';
export const SET_INC_SUBJECTS = 'SET_INC_SUBJECTS';
export const SET_INC_VICTIMS = 'SET_INC_VICTIMS';
export const SET_INC_PROPERTIES = 'SET_INC_PROPERTIES';
export const SET_INC_ARRESTEES = 'SET_INC_ARRESTEES';
export const SET_INC_SUSPECTS = 'SET_INC_SUSPECTS';
export const SET_INC_NARRATIVE = 'SET_INC_NARRATIVE';
export const SET_INC_RELATIONSHIPS = 'SET_INC_RELATIONSHIPS';
export const SET_INC_EVIDENCE_ITEM = 'SET_INC_EVIDENCE_ITEM';
export const SET_INC_EVIDENCE_CUSTODY = 'SET_INC_EVIDENCE_CUSTODY';
export const SET_INC_EVIDENCE_STORAGE = 'SET_INC_EVIDENCE_STORAGE';
export const SET_INC_OFFENSES_MP_BASIC = 'SET_INC_OFFENSES_MP_BASIC';
export const SET_INC_MP_RELATED_RECORDS = 'SET_INC_MP_RELATED_RECORDS';
export const SET_INC_OFFENSES_DV_SUSPECT = 'SET_INC_OFFENSES_DV_SUSPECT';
export const SET_INC_EVIDENCE_CUSTODY_NEW = 'SET_INC_EVIDENCE_CUSTODY_NEW';
export const SET_INC_EVIDENCE_STORAGE_NEW = 'SET_INC_EVIDENCE_STORAGE_NEW';
export const SET_INC_OFFENSES_RELATIONSHIP = 'SET_INC_OFFENSES_RELATIONSHIP';
export const SET_INC_EVIDENCE_DISPOSITION = 'SET_INC_EVIDENCE_DISPOSITION';
export const SET_INC_OFFENSES_DV_ADDITIONAL = 'SET_INC_OFFENSES_DV_ADDITIONAL';
export const SET_INC_OFFENSES_DV_VICTIM_SUBFORMS = 'SET_INC_OFFENSES_DV_VICTIM_SUBFORMS';
export const SET_INC_OFFENSES_DV_SUSPECT_SUBFORMS = 'SET_INC_OFFENSES_DV_SUSPECT_SUBFORMS';
export const SET_INC_ZONE_FORM = 'SET_INC_ZONE_FORM';
export const SET_INC_DISPOSITION_FORM = 'SET_INC_DISPOSITION_FORM';

export const SET_LIST_SUBJECTS = 'SET_LIST_SUBJECTS';
export const SET_LIST_VICTIMS = 'SET_LIST_VICTIMS';
export const SET_LIST_PROPERTIES = 'SET_LIST_PROPERTIES';
export const SET_LIST_PROP_OWNERS = 'SET_LIST_PROP_OWNERS';
export const SET_LIST_ARRESTEES = 'SET_LIST_ARRESTEES';
export const SET_LIST_SUSPECTS = 'SET_LIST_SUSPECTS';
export const SET_LIST_EVIDENCE_CUSTODY = 'SET_LIST_EVIDENCE_CUSTODY';
export const SET_LIST_EVIDENCE_STORAGE = 'SET_LIST_EVIDENCE_STORAGE';
export const SET_DIALOG_LIST_SUBJECTS = 'SET_DIALOG_LIST_SUBJECTS';
export const SET_DIALOG_LIST_VICTIMS = 'SET_DIALOG_LIST_VICTIMS';
export const SET_DIALOG_LIST_PROPERTIES = 'SET_DIALOG_LIST_PROPERTIES';
export const SET_DIALOG_LIST_ARRESTEES = 'SET_DIALOG_LIST_ARRESTEES';
export const SET_DIALOG_LIST_SUSPECTS = 'SET_DIALOG_LIST_SUSPECTS';
export const SET_DIALOG_LIST_EVIDENCES = 'SET_DIALOG_LIST_EVIDENCES';
export const SET_INC_DV_VICTIM_LIST = 'SET_INC_DV_VICTIM_LIST';
export const SET_INC_DV_SUSPECT_LIST = 'SET_INC_DV_SUSPECT_LIST';
export const SET_INC_DV_ADDITIONAL_LIST = 'SET_INC_DV_ADDITIONAL_LIST';

export const SET_SELECTED_EVIDENCE_CUSTODY = 'SET_SELECTED_EVIDENCE_CUSTODY';
export const SET_SELECTED_EVIDENCE_STORAGE = 'SET_SELECTED_EVIDENCE_STORAGE';
export const SET_SELECTED_SUBJECT = 'SET_SELECTED_SUBJECT';
export const SET_SELECTED_VICTIM = 'SET_SELECTED_VICTIM';
export const SET_SELECTED_PROPERTY = 'SET_SELECTED_PROPERTY';
export const SET_SELECTED_PROP_OWNER = 'SET_SELECTED_PROP_OWNER';
export const SET_SELECTED_ARRESTEE = 'SET_SELECTED_ARRESTEE';
export const SET_SELECTED_EVIDENCE = 'SET_SELECTED_EVIDENCE';
export const SET_SELECTED_NARRATIVE = 'SET_SELECTED_NARRATIVE';
export const SET_SELECTED_SUSPECT = 'SET_SELECTED_SUSPECT';
export const SET_SELECTED_INCIDENT = 'SET_SELECTED_INCIDENT';
export const SET_SELECTED_ENTITY = 'SET_SELECTED_ENTITY';

export const SET_CURRENT_OFFENSE = 'SET_CURRENT_OFFENSE'; // To Persist current offense id
export const SET_SEARCH_KEYWORDS = 'SET_SEARCH_KEYWORDS';

export const SET_OFFENSE_VALIDATION_ERRORS = 'SET_OFFENSE_VALIDATION_ERRORS'; // for simpl-schema validation
export const SET_VALIDATION_ERRORS = 'SET_VALIDATION_ERRORS';

export const ADD_PARTIES = 'ADD_PARTIES';
export const ADD_ADDRESSES = 'ADD_ADDRESSES';
export const ADD_PROPERTIES = 'ADD_PROPERTIES';
export const ADD_OFFENSES = 'ADD_OFFENSES';

export const GET_ACTIVE_INCIDENTS = 'GET_ACTIVE_INCIDENTS';
export const GET_PARTIES = 'GET_PARTIES';
export const GET_ADDRESSESS = 'GET_ADDRESSESS';
export const GET_OFFENSES = 'GET_OFFENSES';
export const GET_PROPERTIES = 'GET_PROPERTIES';
export const GET_EVIDENCES = 'GET_EVIDENCES';
export const GET_NARRATIVES = 'GET_NARRATIVES';
export const GET_ENTITIES = 'GET_ENTITIES';
export const GET_DISPOSITIONS = 'GET_DISPOSITIONS';
export const GET_ZONES = 'GET_ZONES';
export const GET_ALERTS = 'GET_ALERTS';
export const GET_NOTIFICATIONS = 'GET_NOTIFICATIONS';

export const REMOVE_INC_SUBJECTS = 'REMOVE_INC_SUBJECTS';
export const REMOVE_INC_VICTIMS = 'REMOVE_INC_VICTIMS';
export const REMOVE_INC_PROPERTIES = 'REMOVE_INC_PROPERTIES';
export const REMOVE_INC_ARRESTEES = 'REMOVE_INC_ARRESTEES';
export const REMOVE_INC_SUSPECTS = 'REMOVE_INC_SUSPECTS';
/************************************/
/*** END Region: Action Types ******/
/**********************************/

/************************************/
/* Region Action Creator Functions */
/**********************************/
// start region: validation action creator functions
export const setOffenseValidationErrors = (errors) => {
  return (dispatch) => {
    return dispatch({ type: SET_OFFENSE_VALIDATION_ERRORS, payload: errors });
  };
};

export const setValidationErrors = (errors) => {
  return (dispatch) => {
    return dispatch({ type: SET_VALIDATION_ERRORS, payload: errors });
  };
};
// end region: validation action creator functions

export const setSelectedIncident = (ptsIncidentId) => {
  return async (dispatch) => {
    const incState = store.store.getState().incident;
    incState.ptsIncidentId = ptsIncidentId;
    return dispatch({ type: SET_SELECTED_INCIDENT, payload: ptsIncidentId });
  };
};

export const setEvent = (data) => {
  return async (dispatch) => {
    return dispatch({ type: SET_EVENT, payload: data });
  };
};

export const getEvents = (data) => {
  return async (dispatch) => {
    return dispatch({ type: GET_EVENTS, payload: data });
  };
};

export const setActiveIncidents = (state) => {
  return async (dispatch) => {
    return dispatch({ type: GET_ACTIVE_INCIDENTS, payload: state });
  };
};

export const setCurrentOffense = (state) => {
  return async (dispatch) => {
    return dispatch({ type: SET_CURRENT_OFFENSE, payload: state });
  };
};

export const updateCurrentIncident = (state) => {
  return async (dispatch) => {
    return dispatch({ type: SET_INCIDENT, payload: state });
  };
};

export const updateIncAddressForm = (state) => {
  return async (dispatch) => {
    return dispatch({ type: SET_INC_ADDRESS, payload: state });
  };
};

export const updateIncAddressIdentifiersForm = (state) => {
  return async (dispatch) => {
    return dispatch({ type: SET_INC_ADDRESS_ID, payload: state });
  };
};

export const updateIncCoordinatesForm = (state) => {
  return async (dispatch) => {
    return dispatch({ type: SET_INC_COORDINATES, payload: state });
  };
};

export const updateIncPartiesPersonForm = (state) => {
  return async (dispatch) => {
    return dispatch({ type: SET_INC_PARTIES_PERSON, payload: state });
  };
};

export const updateIncPartiesContactForm = (state) => {
  return async (dispatch) => {
    return dispatch({ type: SET_INC_PARTIES_CONTACT, payload: state });
  };
};

export const updateIncPartiesEmploymentForm = (state) => {
  return async (dispatch) => {
    return dispatch({ type: SET_INC_PARTIES_EMPLOYMENT, payload: state });
  };
};

export const updateIncPartiesOthersForm = (state) => {
  return async (dispatch) => {
    return dispatch({ type: SET_INC_PARTIES_OTHERS, payload: state });
  };
};

export const updateIncPartiesPlaceForm = (state) => {
  return async (dispatch) => {
    return dispatch({ type: SET_INC_PARTIES_PLACE, payload: state });
  };
};

export const updateIncPropertyItemForm = (state) => {
  return async (dispatch) => {
    return dispatch({ type: SET_INC_PROPERTY_ITEM, payload: state });
  };
};

export const updateIncPropertyFirearmForm = (state) => {
  return async (dispatch) => {
    return dispatch({ type: SET_INC_PROPERTY_FIREARM, payload: state });
  };
};

export const updateIncPropertyJewelryForm = (state) => {
  return async (dispatch) => {
    return dispatch({ type: SET_INC_PROPERTY_JEWELRY, payload: state });
  };
};

export const updateIncPropertyStructureForm = (state) => {
  return async (dispatch) => {
    return dispatch({ type: SET_INC_PROPERTY_STRUCTURE, payload: state });
  };
};

export const updateIncPropertySubstanceForm = (state) => {
  return async (dispatch) => {
    return dispatch({ type: SET_INC_PROPERTY_SUBSTANCE, payload: state });
  };
};

export const updateIncPropertyVehicleDetailsForm = (state) => {
  return async (dispatch) => {
    return dispatch({ type: SET_INC_PROPERTY_VEHICLE_DETAILS, payload: state });
  };
};

export const updateIncPropertyCredentialsAnalysisForm = (state) => {
  return async (dispatch) => {
    return dispatch({ type: SET_INC_PROPERTY_CREDENTIALS_ANALYSIS, payload: state });
  };
};

export const updateIncOffensesOffenseForm = (state) => {
  return async (dispatch) => {
    return dispatch({ type: SET_INC_OFFENSES_OFFENSE, payload: state });
  };
};

export const updateIncOffensesDVVictimForm = (state) => {
  return async (dispatch) => {
    return dispatch({ type: SET_INC_OFFENSES_DV_VICTIM, payload: state });
  };
};

export const updateIncOffensesDVVictimSubForm = (state) => {
  return async (dispatch) => {
    return dispatch({ type: SET_INC_OFFENSES_DV_VICTIM_SUBFORMS, payload: state });
  };
};

export const updateIncOffensesDVSuspectForm = (state) => {
  return async (dispatch) => {
    return dispatch({ type: SET_INC_OFFENSES_DV_SUSPECT, payload: state });
  };
};

export const updateIncOffensesDVSuspectSubForm = (state) => {
  return async (dispatch) => {
    return dispatch({ type: SET_INC_OFFENSES_DV_SUSPECT_SUBFORMS, payload: state });
  };
};

export const updateIncOffensesDVAdditionalForm = (state) => {
  return async (dispatch) => {
    return dispatch({ type: SET_INC_OFFENSES_DV_ADDITIONAL, payload: state });
  };
};

export const updateIncOffensesMPBasicForm = (state) => {
  return async (dispatch) => {
    return dispatch({ type: SET_INC_OFFENSES_MP_BASIC, payload: state });
  };
};

export const updateIncMPRelatedRecords = (state) => {
  return async (dispatch) => {
    return dispatch({ type: SET_INC_MP_RELATED_RECORDS, payload: state });
  };
};

export const updateIncOffensesRelationshipForm = (state) => {
  return async (dispatch) => {
    return dispatch({ type: SET_INC_OFFENSES_RELATIONSHIP, payload: state });
  };
};

export const updateIncOffensesPropertyOwnerForm = (state) => {
  return async (dispatch) => {
    return dispatch({ type: SET_INC_OFFENSES_PROPERTY_OWNER, payload: state });
  };
};

export const updateIncEvidenceItemForm = (state) => {
  return async (dispatch) => {
    return dispatch({ type: SET_INC_EVIDENCE_ITEM, payload: state });
  };
};

export const updateIncEvidenceCustodyForm = (state) => {
  return async (dispatch) => {
    return dispatch({ type: SET_INC_EVIDENCE_CUSTODY, payload: state });
  };
};

export const updateIncEvidenceCustodyFormNew = (state) => {
  return async (dispatch) => {
    return dispatch({ type: SET_INC_EVIDENCE_CUSTODY_NEW, payload: state });
  };
};

export const updateIncEvidenceStorageForm = (state) => {
  return async (dispatch) => {
    return dispatch({ type: SET_INC_EVIDENCE_STORAGE, payload: state });
  };
};

export const updateIncEvidenceStorageFormNew = (state) => {
  return async (dispatch) => {
    return dispatch({ type: SET_INC_EVIDENCE_STORAGE_NEW, payload: state });
  };
};

export const updateIncEvidenceDispositionForm = (state) => {
  return async (dispatch) => {
    return dispatch({ type: SET_INC_EVIDENCE_DISPOSITION, payload: state });
  };
};

export const updateIncNarrativeForm = (state) => {
  return async (dispatch) => {
    return dispatch({ type: SET_INC_NARRATIVE, payload: state });
  };
};

export const updateIncZoneForm = (state) => {
  return async (dispatch) => {
    return dispatch({ type: SET_INC_ZONE_FORM, payload: state });
  };
};

export const updateIncDispositionForm = (state) => {
  return async (dispatch) => {
    return dispatch({ type: SET_INC_DISPOSITION_FORM, payload: state });
  };
};

// Set Lists Region
export const setListSubjects = (list) => {
  return async (dispatch) => {
    return dispatch({ type: SET_LIST_SUBJECTS, payload: list });
  };
};

export const setDialogListSubjects = (list) => {
  return async (dispatch) => {
    return dispatch({ type: SET_DIALOG_LIST_SUBJECTS, payload: list });
  };
};

export const setListSuspects = (list) => {
  return async (dispatch) => {
    return dispatch({ type: SET_LIST_SUSPECTS, payload: list });
  };
};

export const setDialogListSuspects = (list) => {
  return async (dispatch) => {
    return dispatch({ type: SET_DIALOG_LIST_SUSPECTS, payload: list });
  };
};

export const setListVictims = (list) => {
  return async (dispatch) => {
    return dispatch({ type: SET_LIST_VICTIMS, payload: list });
  };
};

export const setDialogListVictims = (list) => {
  return async (dispatch) => {
    return dispatch({ type: SET_DIALOG_LIST_VICTIMS, payload: list });
  };
};

export const setListProperties = (list) => {
  return async (dispatch) => {
    return dispatch({ type: SET_LIST_PROPERTIES, payload: list });
  };
};

export const setDialogListProperties = (list) => {
  return async (dispatch) => {
    return dispatch({ type: SET_DIALOG_LIST_PROPERTIES, payload: list });
  };
};

export const setDialogListEvidences = (list) => {
  return async (dispatch) => {
    return dispatch({ type: SET_DIALOG_LIST_EVIDENCES, payload: list });
  };
};

export const setListPropertyOwners = (list) => {
  return async (dispatch) => {
    return dispatch({ type: SET_LIST_PROP_OWNERS, payload: list });
  };
};

export const setListArrestees = (list) => {
  return async (dispatch) => {
    return dispatch({ type: SET_LIST_ARRESTEES, payload: list });
  };
};

export const setDialogListArrestees = (list) => {
  return async (dispatch) => {
    return dispatch({ type: SET_DIALOG_LIST_ARRESTEES, payload: list });
  };
};
// End Region

// Set Entity Region
export const setSelectedSubject = (state) => {
  return async (dispatch) => {
    return dispatch({ type: SET_SELECTED_SUBJECT, payload: state });
  };
};

export const setSelectedVictim = (state) => {
  return async (dispatch) => {
    return dispatch({ type: SET_SELECTED_VICTIM, payload: state });
  };
};

export const setSelectedSuspect = (state) => {
  return async (dispatch) => {
    return dispatch({ type: SET_SELECTED_SUSPECT, payload: state });
  };
};

export const setSelectedProperty = (state) => {
  return async (dispatch) => {
    return dispatch({ type: SET_SELECTED_PROPERTY, payload: state });
  };
};

export const setSelectedPropertyOwner = (state) => {
  return async (dispatch) => {
    return dispatch({ type: SET_SELECTED_PROP_OWNER, payload: state });
  };
};

export const setSelectedArrestee = (state) => {
  return async (dispatch) => {
    return dispatch({ type: SET_SELECTED_ARRESTEE, payload: state });
  };
};

export const setSelectedEvidenceCustody = (state) => {
  return async (dispatch) => {
    return dispatch({ type: SET_SELECTED_EVIDENCE_CUSTODY, payload: state });
  };
};

export const setSelectedEvidenceStorage = (state) => {
  return async (dispatch) => {
    return dispatch({ type: SET_SELECTED_EVIDENCE_STORAGE, payload: state });
  };
};

export const setSelectedEvidence = (state) => {
  return async (dispatch) => {
    return dispatch({ type: SET_SELECTED_EVIDENCE, payload: state });
  };
};

export const setSelectedNarrative = (state) => {
  return async (dispatch) => {
    return dispatch({ type: SET_SELECTED_NARRATIVE, payload: state });
  };
};

export const setSelectedEntity = (state) => {
  return async (dispatch) => {
    return dispatch({ type: SET_SELECTED_ENTITY, payload: state });
  };
};

export const setSearchKeyword = (data) => {
  return (dispatch) => {
    dispatch({ type: SET_SEARCH_KEYWORDS, payload: data });
  };
};
// End Region

// Set Lists Details Region
export const updateIncOffenses = (state) => {
  const incState = store.store.getState().incident;

  incState.offenses.map((s) => {
    if (typeof incState.currentOffense === 'string') {
      if (s.offenseDetails.tempOffenseId === incState.currentOffense) {
        s.offenseDetails = { ...s.offenseDetails, ...state };
      }
    } else {
      if (s.offenseDetails.ptsOffenseId === incState.currentOffense) {
        s.offenseDetails = { ...s.offenseDetails, ...state };
      }
    }
  });

  return async (dispatch) => {
    return dispatch({
      type: ADD_OFFENSES,
      payload: incState.offenses,
    });
  };
};

export const setIncSubjectsDetails = (state) => {
  return async (dispatch) => {
    dispatch({ type: SET_INC_SUBJECTS, payload: state });
  };
};

export const setIncDVVictimList = (state) => {
  return async (dispatch) => {
    dispatch({ type: SET_INC_DV_VICTIM_LIST, payload: state });
  };
};

export const setIncDVSuspectList = (state) => {
  return async (dispatch) => {
    dispatch({ type: SET_INC_DV_SUSPECT_LIST, payload: state });
  };
};

export const setIncDVAdditionalList = (state) => {
  return async (dispatch) => {
    dispatch({ type: SET_INC_DV_ADDITIONAL_LIST, payload: state });
  };
};

export const updateIncSubjectsDetails = (state, newData) => {
  const incState = store.store.getState().incident;
  if (newData) {
    incState.incSubjects.push({
      incSubjectDetails: { changes: true, ...state },
    });
  } else {
    let foundSubject = false;
    incState.incSubjects.map((s) => {
      if (
        typeof incState.selectedSubject === 'string' &&
        s.incSubjectDetails.tempPartyId === incState.selectedSubject
      ) {
        foundSubject = true;
      } else if (s.incSubjectDetails.ptsIncPersonId === incState.selectedSubject) {
        foundSubject = true;
      }
      if (foundSubject) {
        s.incSubjectDetails = {
          ...s.incSubjectDetails,
          changes: true,
          values: {
            ...s.incSubjectDetails.values,
            ...state.values,
          },
        };
        foundSubject = false;
      }
    });
  }
  return async (dispatch) => {
    return dispatch({
      type: SET_INC_SUBJECTS,
      payload: incState.incSubjects,
    });
  };
};

export const removeIncSubjectDetails = async () => {
  const incState = store.store.getState().incident;
  const deletedSubject = incState.incSubjects.find(
    (s) => s.incSubjectDetails.ptsIncPersonId === incState.selectedSubject
  );

  incState.incSubjects = incState.incSubjects.filter((s) =>
    typeof incState.selectedSubject === 'string'
      ? s.incSubjectDetails.tempPartyId !== incState.selectedSubject
      : s.incSubjectDetails.ptsIncPersonId !== incState.selectedSubject
  );

  if (deletedSubject && deletedSubject.incSubjectDetails.ptsSubjectId) {
    await removeEntity('incsubject', {
      ptsSubjectId: deletedSubject.incSubjectDetails.ptsSubjectId,
      ptsIncidentId: incState.incident.ptsIncidentId,
    });
  }

  return async (dispatch) => {
    return dispatch({
      type: SET_INC_SUBJECTS,
      payload: incState.incSubjects,
    });
  };
};

export const setIncVictimsDetails = (state) => {
  return async (dispatch) => {
    dispatch({ type: SET_INC_VICTIMS, payload: state });
  };
};

export const updateIncVictimsDetails = (state, newData) => {
  const incState = store.store.getState().incident;

  if (newData) {
    incState.incVictims.push({
      incVictimDetails: { changes: true, ...state },
    });
  } else {
    let foundVictim = false;
    incState.incVictims.map((s) => {
      if (
        typeof incState.selectedVictim === 'string' &&
        s.incVictimDetails.tempPartyId === incState.selectedVictim
      ) {
        foundVictim = true;
      } else if (s.incVictimDetails.ptsIncPersonId === incState.selectedVictim) {
        foundVictim = true;
      }
      if (foundVictim) {
        s.incVictimDetails = {
          ...s.incVictimDetails,
          changes: true,
          values: {
            ...s.incVictimDetails.values,
            ...state.values,
          },
        };
        foundVictim = false;
      }
    });
  }
  return async (dispatch) => {
    return dispatch({
      type: SET_INC_VICTIMS,
      payload: incState.incVictims,
    });
  };
};

export const removeIncVictimDetails = () => {
  return async (dispatch) => {
    const incState = store.store.getState().incident;
    const deletedVictim = incState.incVictims.find(
      (v) => v.incVictimDetails.ptsIncPersonId === incState.selectedVictim
    );

    incState.incVictims = incState.incVictims.filter((v) =>
      typeof incState.selectedVictim === 'string'
        ? v.incVictimDetails.tempPartyId !== incState.selectedVictim
        : v.incVictimDetails.ptsIncPersonId !== incState.selectedVictim
    );

    if (deletedVictim && deletedVictim.incVictimDetails.ptsVictimId) {
      await removeEntity('incvictim', {
        ptsVictimId: deletedVictim.incVictimDetails.ptsVictimId,
        ptsIncidentId: incState.incident.ptsIncidentId,
      });
    }

    return dispatch({
      type: SET_INC_VICTIMS,
      payload: incState.incVictims,
    });
  };
};

export const setIncPropertyDetails = (ptsOffenseId) => {
  return async (dispatch) => {
    const { incident } = store.store.getState();
    const selectedIncId = incident.ptsIncidentId;

    const data = await getEntitiesByIncId(selectedIncId, 'offenses', { property: true });

    const { Offense } = data;
    const selectedOffense = Offense.filter((e) => e.ptsOffenseID === ptsOffenseId)[0];

    if (!selectedOffense) return [];

    const { Property } = selectedOffense;

    if (!Array.isArray(Property) || Property.length === 0) return [];

    const currentOffenseProperties = Property.filter(
      (p) => p.ptsOffenseID === selectedOffense.ptsOffenseID && p.IsDeleted === false
    );

    const properties = currentOffenseProperties.map((property) => {
      return {
        incPropertyDetails: {
          incIncidentPropertyId: property.incIncidentPropertyID,
          ptsOffenseId: selectedOffense.ptsOffenseID,
          ptsPropertyId: property.ptsPropertyID,
          values: {
            incIncidentPropertyId: property.incIncidentPropertyID,
            ownerFullName: '', // Need to join with incPropert eyOwners
            propertyDescription: property.PropertyDescription,
            type: property.PropertyLossType,
            parentType: property.ParentType,
            status: property.PropertyStatus,
            notes: property.Notes,
            updated: property.Updated,
            updatedBy: property.UpdatedBy,
            value: property.Value,
            recoveredDate: property.RecoveryDate,
            statusDate: property.StatusDate,
            stolenFromOtherJurisdiction: property.IsStolenFromOtherJurisdiction,
            recoveredFromOtherJurisdiction: property.IsRecoveredFromOtherJurisdiction,
            stolenVehicles: property.StolenVehicles,
            recoveredVehicles: property.RecoveredVehicles,
          },
        },
      };
    });

    dispatch({ type: SET_INC_PROPERTIES, payload: properties });
  };
};

export const updateIncPropertyDetails = (state, newData) => {
  const incState = store.store.getState().incident;

  if (newData) {
    incState.incProperties.push({
      incPropertyDetails: { changes: true, ...state },
    });
  } else {
    let foundProperty = false;
    incState.incProperties.map((s) => {
      if (
        typeof incState.selectedProperty === 'string' &&
        s.incPropertyDetails.tempPropId === incState.selectedProperty
      ) {
        foundProperty = true;
      } else if (s.incPropertyDetails.incIncidentPropertyId === incState.selectedProperty) {
        foundProperty = true;
      }

      if (foundProperty) {
        s.incPropertyDetails = {
          ...s.incPropertyDetails,
          changes: true,
          values: {
            ...s.incPropertyDetails.values,
            ...state.values,
          },
        };
        foundProperty = false;
      }
    });
  }
  return async (dispatch) => {
    return dispatch({
      type: SET_INC_PROPERTIES,
      payload: incState.incProperties,
    });
  };
};

export const removeIncPropertyDetails = () => {
  return async (dispatch) => {
    const incState = store.store.getState().incident;
    const deletedProperty = incState.incProperties.find(
      (v) => v.incPropertyDetails.incIncidentPropertyId === incState.selectedProperty
    );

    incState.incProperties = incState.incProperties.filter((s) =>
      typeof incState.selectedProperty === 'string'
        ? s.incPropertyDetails.tempPropId !== incState.selectedProperty
        : s.incPropertyDetails.incIncidentPropertyId !== incState.selectedProperty
    );

    if (deletedProperty && deletedProperty.incPropertyDetails.ptsPropertyId) {
      await removeEntity('incproperty', {
        ptsPropertyId: deletedProperty.incPropertyDetails.ptsPropertyId,
        ptsIncidentId: incState.incident.ptsIncidentId,
      });
    }

    return dispatch({
      type: SET_INC_PROPERTIES,
      payload: incState.incProperties,
    });
  };
};

export const setIncArresteeDetails = (data) => {
  return (dispatch) => {
    dispatch({ type: SET_INC_ARRESTEES, payload: data });
  };
};

export const updateIncArresteeDetails = (state, newData) => {
  const incState = store.store.getState().incident;
  if (newData) {
    incState.incArrestees.push({
      incArresteeDetails: { changes: true, ...state },
    });
  } else {
    let foundArrestee = false;
    incState.incArrestees.map((s) => {
      if (s.incArresteeDetails.ptsIncPersonId === incState.selectedArrestee) {
        foundArrestee = true;
      }

      if (foundArrestee) {
        s.incArresteeDetails = {
          ...s.incArresteeDetails,
          changes: true,
          values: {
            ...s.incArresteeDetails.values,
            ...state.values,
          },
        };
        foundArrestee = false;
      }
    });
  }

  return async (dispatch) => {
    return dispatch({
      type: SET_INC_ARRESTEES,
      payload: incState.incArrestees,
    });
  };
};

export const removeIncArresteeDetails = () => {
  return async (dispatch) => {
    const incState = store.store.getState().incident;
    const deletedArrestee = incState.incArrestees.find(
      (v) => v.incArresteeDetails.ptsIncPersonId === incState.selectedArrestee
    );

    incState.incArrestees = incState.incArrestees.filter((s) =>
      typeof incState.selectedArrestee === 'string'
        ? s.incArresteeDetails.tempPartyId !== incState.selectedArrestee
        : s.incArresteeDetails.ptsIncPersonId !== incState.selectedArrestee
    );

    if (deletedArrestee && deletedArrestee.incArresteeDetails.ptsArresteeId) {
      await removeEntity('incarrestee', {
        ptsArresteeId: deletedArrestee.incArresteeDetails.ptsArresteeId,
        ptsIncidentId: incState.incident.ptsIncidentId,
      });
    }
    return dispatch({
      type: REMOVE_INC_ARRESTEES,
      payload: incState.incArrestees,
    });
  };
};

export const updateIncSuspectDetails = (state, newData) => {
  const incState = store.store.getState().incident;

  if (newData) {
    incState.dvSuspects.push({
      incSuspectDetails: { changes: true, ...state },
    });
  } else {
    let foundSuspect = false;
    incState.dvSuspects.map((s) => {
      if (
        typeof incState.selectedSuspect === 'string' &&
        s.incSuspectDetails.tempPartyId === incState.selectedSuspect
      ) {
        foundSuspect = true;
      } else if (s.incSuspectDetails.ptsIncPersonId === incState.selectedSuspect) {
        foundSuspect = true;
      }

      if (foundSuspect) {
        s.incSuspectDetails = {
          ...s.incSuspectDetails,
          changes: true,
          values: {
            ...s.incSuspectDetails.values,
            ...state.values,
          },
        };
        foundSuspect = false;
      }
    });
  }

  return async (dispatch) => {
    return dispatch({
      type: SET_INC_SUSPECTS,
      payload: incState.dvSuspects,
    });
  };
};

export const removeIncSuspectDetails = () => {
  return async (dispatch) => {
    const incState = store.store.getState().incident;
    const deletedSuspect = incState.dvSuspects.find(
      (v) => v.incSuspectDetails.ptsIncPersonId === incState.selectedSuspect
    );

    incState.dvSuspects = incState.dvSuspects.filter((s) =>
      typeof incState.selectedSuspect === 'string'
        ? s.incSuspectDetails.tempPartyId !== incState.selectedSuspect
        : s.incSuspectDetails.ptsIncPersonId !== incState.selectedSuspect
    );

    if (deletedSuspect && deletedSuspect.incSuspectDetails.ptsDVSuspectId) {
      await removeEntity('incdvsuspect', {
        ptsDVSuspectId: deletedSuspect.incSuspectDetails.ptsDVSuspectId,
        ptsIncidentId: incState.incident.ptsIncidentId,
      });
    }

    return dispatch({
      type: REMOVE_INC_SUSPECTS,
      payload: incState.dvSuspects,
    });
  };
};

export const setIncRelationshipDetails = (state) => {
  return async (dispatch) => {
    dispatch({ type: SET_INC_RELATIONSHIPS, payload: state });
  };
};

export const setIncEvidenceStorageDetails = (state) => {
  return async (dispatch) => {
    dispatch({ type: SET_LIST_EVIDENCE_STORAGE, payload: state });
  };
};

export const updateIncEvidenceStorageDetails = (state) => {
  const incState = store.store.getState().incident;

  let found = false;
  incState.incEvidenceStorage.map((s) => {
    if (
      typeof incState.selectedEvidenceStorage === 'string' &&
      s.incEvidenceStorageDetails.tempEvidenceStorageId === incState.selectedEvidenceStorage
    ) {
      found = true;
    } else if (
      s.incEvidenceStorageDetails.ptsEvidenceStorageId === incState.selectedEvidenceStorage
    ) {
      found = true;
    }
    if (found) {
      s.incEvidenceStorageDetails = {
        ...s.incEvidenceStorageDetails,
        changes: true,
        values: {
          ...s.incEvidenceStorageDetails.values,
          ...state.values,
        },
      };
      found = false;
    }
  });
  return async (dispatch) => {
    return dispatch({
      type: SET_LIST_EVIDENCE_STORAGE,
      payload: incState.incEvidenceStorage,
    });
  };
};

export const removeIncEvidenceStorageDetails = () => {
  return async (dispatch) => {
    const incState = store.store.getState().incident;
    const deletedStorage = incState.incEvidenceStorage.find(
      (v) => v.incEvidenceStorageDetails.ptsEvidenceStorageId === incState.selectedEvidenceStorage
    );

    incState.incEvidenceStorage = incState.incEvidenceStorage.filter((v) =>
      typeof incState.selectedEvidenceStorage === 'string'
        ? v.incEvidenceStorageDetails.tempEvidenceStorageId !== incState.selectedEvidenceStorage
        : v.incEvidenceStorageDetails.ptsEvidenceStorageId !== incState.selectedEvidenceStorage
    );

    if (deletedStorage && deletedStorage.incEvidenceStorageDetails.ptsEvidenceStorageId) {
      await removeEntity('incevidencestorage', {
        ptsEvidenceStorageId: deletedStorage.incEvidenceStorageDetails.ptsEvidenceStorageId,
        ptsIncidentId: incState.incident.ptsIncidentId,
      });
    }

    return dispatch({
      type: SET_LIST_EVIDENCE_STORAGE,
      payload: incState.incEvidenceStorage,
    });
  };
};

export const setIncEvidenceCustodyDetails = (state) => {
  return async (dispatch) => {
    dispatch({ type: SET_LIST_EVIDENCE_CUSTODY, payload: state });
  };
};

export const updateIncEvidenceCustodyDetails = (state) => {
  const incState = store.store.getState().incident;

  let found = false;
  incState.incEvidenceCustody.map((s) => {
    if (
      typeof incState.selectedEvidenceCustody === 'string' &&
      s.incEvidenceCustodyDetails.tempEvidenceCustodyId === incState.selectedEvidenceCustody
    ) {
      found = true;
    } else if (
      s.incEvidenceCustodyDetails.ptsEvidenceCustodyId === incState.selectedEvidenceCustody
    ) {
      found = true;
    }
    if (found) {
      s.incEvidenceCustodyDetails = {
        ...s.incEvidenceCustodyDetails,
        changes: true,
        values: {
          ...s.incEvidenceCustodyDetails.values,
          ...state.values,
        },
      };
      found = false;
    }
  });
  return async (dispatch) => {
    return dispatch({
      type: SET_LIST_EVIDENCE_CUSTODY,
      payload: incState.incEvidenceCustody,
    });
  };
};

export const removeIncEvidenceCustodyDetails = () => {
  return async (dispatch) => {
    const incState = store.store.getState().incident;
    const deletedCustody = incState.incEvidenceCustody.find(
      (v) => v.incEvidenceCustodyDetails.ptsEvidenceCustodyId === incState.selectedEvidenceCustody
    );

    incState.incEvidenceCustody = incState.incEvidenceCustody.filter((v) =>
      typeof incState.selectedEvidenceCustody === 'string'
        ? v.incEvidenceCustodyDetails.tempEvidenceCustodyId !== incState.selectedEvidenceCustody
        : v.incEvidenceCustodyDetails.ptsEvidenceCustodyId !== incState.selectedEvidenceCustody
    );

    if (deletedCustody && deletedCustody.incEvidenceCustodyDetails.ptsEvidenceCustodyId) {
      await removeEntity('incevidencecustody', {
        ptsEvidenceCustodyId: deletedCustody.incEvidenceCustodyDetails.ptsEvidenceCustodyId,
        ptsIncidentId: incState.incident.ptsIncidentId,
      });
    }

    return dispatch({
      type: SET_LIST_EVIDENCE_CUSTODY,
      payload: incState.incEvidenceCustody,
    });
  };
};
/****************************************/
/* END Region Action Creator Functions */
/**************************************/

/*region Helper Functions*/
// TODO: Refactor and move all of the helper functions to IncHelpers.js
export const removeSelectedIncident = () => {
  return async (dispatch) => {
    const { incident } = store.store.getState();

    const remainingItems = incident.activeIncidents.filter((item) => {
      return item.incidentId !== incident.ptsIncidentId;
    });

    await removeIncident();

    // dispatch(notify('Deleted successfully.', 'success'));
    return dispatch({ type: GET_ACTIVE_INCIDENTS, payload: remainingItems });
  };
};

export const removeSelectedEntity = (entity) => {
  const definition = {
    addresses: {
      reduxKey: 'addresses',
      subKey: 'addressDetails',
      filterKey: 'ptsAddressId', // key is used for filtering.
      reduxAction: ADD_ADDRESSES,
    },
    parties: {
      reduxKey: 'parties',
      subKey: 'personDetail',
      filterKey: 'ptsIncPersonId', // key is used for filtering.
      reduxAction: ADD_PARTIES,
    },
    offenses: {
      reduxKey: 'offenses',
      subKey: 'offenseDetails',
      filterKey: 'ptsOffenseId', // key is used for filtering.
      reduxAction: ADD_OFFENSES,
    },
    properties: {
      reduxKey: 'properties',
      subKey: 'propertyDetails',
      filterKey: 'incIncidentPropertyId', // key is used for filtering.
      reduxAction: GET_PROPERTIES,
    },
    narratives: {
      reduxKey: 'narratives',
      subKey: 'narrativeDetail',
      filterKey: 'ptsNarrativeId', // key is used for filtering.
      reduxAction: GET_NARRATIVES,
    },
    evidence: {
      reduxKey: 'evidences',
      subKey: 'itemRecordDetail',
      filterKey: 'ptsEvidenceId', // key is used for filtering.
      reduxAction: GET_EVIDENCES,
    },
    zones: {
      reduxKey: 'zones',
      subKey: 'values',
      filterKey: 'zone', // key is used for filtering.
      reduxAction: GET_ZONES,
    },
    alerts: {
      reduxKey: 'alerts',
      subKey: 'values',
      filterKey: 'ptsAlertId', // key is used for filtering.
      reduxAction: GET_ALERTS,
    },
    dispositions: {
      reduxKey: 'dispositions',
      subKey: 'values',
      filterKey: 'disposition', // key is used for filtering.
      reduxAction: GET_DISPOSITIONS,
    },
    notifications: {
      reduxKey: 'notifications',
      subKey: 'values',
      filterKey: 'ptsNotificationId', // key is used for filtering.
      reduxAction: GET_NOTIFICATIONS,
    },
  };

  return async (dispatch) => {
    const { incident } = store.store.getState();
    const selectedEntity = incident.selectedEntity;

    // Attach incident id with the payload
    selectedEntity['ptsIncidentId'] = incident.ptsIncidentId;

    const allListItems = incident[definition[entity].reduxKey];
    const filterKey = definition[entity].filterKey;
    const reduxAction = definition[entity].reduxAction;

    if (!Array.isArray(allListItems)) return;

    await removeEntity(entity, selectedEntity);

    const remainingItems = allListItems.filter((item) => {
      if (definition[entity].subKey) {
        item = item[definition[entity].subKey];
      }

      return item[filterKey] !== selectedEntity[filterKey];
    });

    // dispatch(notify('Deleted successfully.', 'success'));
    return dispatch({ type: reduxAction, payload: remainingItems });
  };
};

function preparePartyPersonData(data) {
  if (!data || !data.IncidentPartyPerson) return [];
  const { IncidentPartyPerson } = data;
  if (!IncidentPartyPerson || !IncidentPartyPerson.length) return [];
  return IncidentPartyPerson.map((item) => {
    const ContactInfo = item?.ContactInfo;

    let contactId1 = null;
    let contactId2 = null;
    let contactId3 = null;

    let employeeId1 = null;
    let employeeId2 = null;
    let employeeId3 = null;

    if (Array.isArray(ContactInfo) && ContactInfo.length > 0) {
      ContactInfo.forEach((item) => {
        if (item) {
          if (
            item.PartyType === 'Person' &&
            item.ContactMethod === 'Phone' &&
            item.MethodType === 'HME'
          ) {
            contactId1 = item.ptsInfoID;
          }
          if (
            item.PartyType === 'Person' &&
            item.ContactMethod === 'Phone' &&
            item.MethodType === 'WRK'
          ) {
            contactId2 = item.ptsInfoID;
          }

          if (item.PartyType === 'Person' && item.ContactMethod === 'Email') {
            contactId3 = item.ptsInfoID;
          }

          if (
            item.PartyType === 'Place' &&
            item.ContactMethod === 'Phone' &&
            item.MethodType === 'BUS1'
          ) {
            employeeId1 = item.ptsInfoID;
          }
          if (
            item.PartyType === 'Place' &&
            item.ContactMethod === 'Phone' &&
            item.MethodType === 'BUS2'
          ) {
            employeeId2 = item.ptsInfoID;
          }

          if (
            item.PartyType === 'Place' &&
            item.MethodType === 'BUS' &&
            item.ContactMethod === 'Email'
          ) {
            employeeId3 = item.ptsInfoID;
          }
        }
      });
    }

    // TODO: Need to set credentialId
    const person = {
      // Adding these two properties here as well on Ron's request
      ptsIncPersonId: item.ptsIncPersonID,
      ptsPersonId: item.ptsPersonID,
      PersonId: item.PersonID,
      personDetail: {
        ptsIncPersonId: item.ptsIncPersonID,
        ptsPersonId: item.ptsPersonID,
        PersonId: item.PersonID,
        values: {
          firstName: item.FirstName,
          lastName: item.LastName,
          middleName: item.MiddleName,
          ptsPersonId: item.ptsPersonID,
          age: item.Age,
          ageRangeIncrement: item.AgeRangeIncrement,
          ptsImageId: item.ptsImageID,
          birthdate: item.Born,
          bloodType: item.BloodType,
          // credentialId: item.,
          ethnicity: item.Ethnicity,
          eyeColor: item.EyeColor,
          hairColor: item.HairColor,
          hairLength: item.HairLength,
          hairStyle: item.HairStyle,
          handed: item.Handed,
          height: item.Height,
          incidentId: item.ptsIncidentID,
          partyType: item.Relationship,
          personId: item.PersonID,
          race: item.Race,
          sex: item.Sex,
          weight: item.Weight,
          ssn: item.SSNNumber,
          oln: item.OLNNumber,
          state: item.OLNState,
          suffix: item.Suffix,
          isUnknownParty: item.IsUnknownParty,
          isArrestee: item?.Arrestee? true : false,
        },
      },
      contactDetail: {
        ptsContactId1: contactId1,
        ptsContactId2: contactId2,
        ptsContactId3: contactId3,
        ptsAddressId: item?.PartiesAddresses?.ptsAddressID,
        ptsIncAddressId: item?.PartiesAddresses?.ptsIncAddressID,
        values: {
          incidentId: item?.PartiesAddresses?.ptsIncidentID,
          personId: item?.PartiesAddresses?.ptsPersonID,
          cityId: item?.PartiesAddresses?.ptsCityID,
          addressNumberPrefix: item?.PartiesAddresses?.AddressNumberPrefix,
          addressNumber: item?.PartiesAddresses?.AddressNumber,
          addressNumberSuffix: item?.PartiesAddresses?.AddressNumberSuffix,
          preDirection: item?.PartiesAddresses?.PreDirection,
          streetName: item?.PartiesAddresses?.StreetName,
          streetType: item?.PartiesAddresses?.StreetType,
          postDirection: item?.PartiesAddresses?.PostDirection,
          city: item?.PartiesAddresses?.City?.CityDescription,
          state: item?.PartiesAddresses?.State,
          postalCode: item?.PartiesAddresses?.PostalCode,
          postalCodeExtension: item?.PartiesAddresses?.PostalCodeExtension,
          county: item?.PartiesAddresses?.County,
          country: item?.PartiesAddresses?.Country,
          fullAddressText: item?.PartiesAddresses?.FullAddressText,
          textmask1: item.ContactInfo1,
          textmask2: item.ContactInfo2,
          emailmask: item.ContactInfo3,
          notes: item?.PartiesAddresses?.Notes,
        },
      },
      employmentDetail: {
        ptsContactId1: employeeId1,
        ptsContactId2: employeeId2,
        ptsContactId3: employeeId3,
        ptsIncPartyEmploymentId: item?.PartiesEmployment?.ptsIncPartyEmploymentID,
        ptsPlaceId: item?.PartiesEmployment?.ptsPlaceID,
        ptsAddressId: item?.PartiesEmployment?.ptsAddressID,
        values: {
          incidentId: item?.PartiesEmployment?.ptsIncidentID,
          personId: item?.PartiesEmployment?.ptsPersonID,
          addressId: item?.PartiesEmployment?.ptsAddressID,
          placeName: item?.PartiesEmployment?.PlaceName,
          cityId: item?.PartiesEmployment?.ptsCityID,
          addressNumberPrefix: item?.PartiesEmployment?.AddressNumberPrefix,
          addressNumber: item?.PartiesEmployment?.AddressNumber,
          addressNumberSuffix: item?.PartiesEmployment?.AddressNumberSuffix,
          preDirection: item?.PartiesEmployment?.PreDirection,
          postDirection: item?.PartiesEmployment?.PostDirection,
          streetName: item?.PartiesEmployment?.StreetName,
          streetType: item?.PartiesEmployment?.StreetType,
          city: item?.PartiesEmployment?.City?.CityDescription,
          state: item?.PartiesEmployment?.State,
          postalCode: item?.PartiesEmployment?.PostalCode,
          postalCodeExtension: item?.PartiesEmployment?.PostalCodeExtension,
          county: item?.PartiesEmployment?.County,
          country: item?.PartiesEmployment?.Country,
          fullAddressText: item?.PartiesEmployment?.FullAddressText,
          textmask1: item?.PartiesEmployment?.ContactInfo1,
          textmask2: item?.PartiesEmployment?.ContactInfo2,
          emailmask: item?.PartiesEmployment?.ContactInfo3,
          notes: item?.PartiesEmployment?.Notes,
        },
      },
      otherDetail: {
        values: {
          incidentId: item.ptsIncidentID,
          personId: item?.ptsPersonID,
          addressId: item?.ptsAddressID,
          generalAppearance: item?.GeneralAppearance,
          personNotes: item?.Notes,
        },
      },
      mpBasicDetail: {
        ptsMPVictimId: item?.MPVictim?.ptsMPVictimID,
        ptsIncPersonId: item?.ptsIncPersonID,
        values: {
          typeVictim: item?.MPVictim?.VictimType,
          category: item?.MPVictim?.VictimCategory,
          reportingAgency: item?.MPVictim?.ReportingAgency,
          ori: item?.MPVictim?.ORI,
          dojNumber: item?.MPVictim?.DOJNumber,
          ncicNumber: item?.MPVictim?.NCICNumber,
          recordType: item?.MPVictim?.RecordType ? JSON.parse(item?.MPVictim?.RecordType) : [],
          dateMissing: item?.MPVictim?.DateTimeMissing,
          dateFound: item?.MPVictim?.FoundDateTime,
          mentalCondition: item?.MPVictim?.MentalCondition,
          scarsMarksTattoos: item?.MPVictim?.ScarsMarksTattoos,
          locationLastSeen: item?.MPVictim?.LocationLastSeen,
          portableDestination: item?.MPVictim?.ProbableDestination,
          age: item?.MPVictim?.AgeInPhoto,
          clothingDesc: item?.MPVictim?.ClothingDescription,
          brokenOrMissingBones: item?.MPVictim?.BrokenBonesMissingOrgans,
          visibleDentalWork: item?.MPVictim?.VisibleDentalWork,
          glasses: item?.MPVictim?.HasGlasses,
          contactLenses: item?.MPVictim?.HasContactLenses,
          xrays: item?.MPVictim?.HasSkeletonXrays,
          dental: item?.MPVictim?.HasDentalXrays,
          dentures: item?.MPVictim?.Dentures,
          textmask2: item?.MPVictim?.DentistPhone,
          textmask1: item?.MPVictim?.DoctorPhone,
          photo: item?.MPVictim?.PhotoAvailable,
          missingStatus: item?.MPVictim?.MissingStatus,
        },
      },
      mpRelatedRecords: {},
    };

    // Formatting MP Related Records Information
    let mppropertyrecords = [];
    let mpaddressrecords = [];
    let mppartyrecords = [];

    if (item?.MPVictim?.MPProperty) {
      if (item?.MPVictim?.MPAddress && item?.MPVictim?.MPProperty.length > 0) {
        for (let arrayIndex = 0; arrayIndex < item?.MPVictim?.MPProperty.length; arrayIndex++) {
          if (item?.MPVictim?.MPProperty[arrayIndex].IsDeleted === false)
            mppropertyrecords[arrayIndex] =
              item?.MPVictim?.MPProperty[arrayIndex].incIncidentPropertyID;
        }
      }

      if (item?.MPVictim?.MPAddress && item?.MPVictim?.MPAddress.length > 0) {
        for (let arrayIndex = 0; arrayIndex < item?.MPVictim?.MPAddress.length; arrayIndex++) {
          if (item?.MPVictim?.MPAddress[arrayIndex].IsDeleted === false)
            mpaddressrecords[arrayIndex] = item?.MPVictim?.MPAddress[arrayIndex].ptsAddressID;
        }
      }

      if (item?.MPVictim?.MPPartyPerson && item?.MPVictim?.MPPartyPerson.length > 0) {
        for (let arrayIndex = 0; arrayIndex < item?.MPVictim?.MPPartyPerson.length; arrayIndex++) {
          if (item?.MPVictim?.MPPartyPerson[arrayIndex].IsDeleted === false)
            mppartyrecords[arrayIndex] = item?.MPVictim?.MPPartyPerson[arrayIndex].ptsIncPersonID;
        }
      }
    } else if (item?.MPVictim?.RelatedRecords) {
      mppropertyrecords = item?.MPVictim?.RelatedRecords.property;
      mpaddressrecords = item?.MPVictim?.RelatedRecords.address;
      mppartyrecords = item?.MPVictim?.RelatedRecords.party;
    }

    person.mpRelatedRecords = {
      property: mppropertyrecords,
      address: mpaddressrecords,
      party: mppartyrecords,
    };

    return person;
  });
}

function prepareAddressData(data) {
  if (!data || !data.Location) return [];
  const { Location } = data;

  const { LocationAddress } = Location; // array
  if (!LocationAddress.length) return [];

  const incidentId = Location.ptsIncidentID;
  const addresses = LocationAddress.map((adrs) => {
    // adrs is having the data of incLocationAddress
    return {
      ptsAddressId: adrs.ptsAddressID,
      ptsLocationId: adrs.ptsLocationID,
      ptsCoordinateId: adrs.ptsCoordinateID,
      addressDetails: {
        ptsAddressId: adrs.ptsAddressID,
        values: {
          ptsLocationId: adrs.ptsLocationID,
          fullAddressText: adrs.FullAddressText,
          created: adrs.Created,
          createdBy: adrs.CreatedBy,
          updated: adrs.Updated,
          updatedBy: adrs.UpdatedBy,
          updatedIp: adrs.UpdatedIP,
          addressNumberPrefix: adrs.AddressNumberPrefix,
          addressNumberSuffix: adrs.AddressNumberSuffix,
          addressNumber: adrs.AddressNumber,
          streetName: adrs.StreetName,
          preDirection: adrs.PreDirection,
          postDirection: adrs.PostDirection,
          city: adrs?.City?.CityDescription,
          state: adrs.State,
          county: adrs.County,
          country: adrs.Country,
          postalCode: adrs.PostalCode,
          postalCodeExtension: adrs?.PostalCodeExtension,
          streetType: adrs.StreetType,
          notes: adrs.Notes,
          isPrimary: adrs.IsPrimary,
        },
      },
      addressIdDetails: {
        values: {
          isEventLookup: adrs.ShowInEventLookup,
          isSexOffender: adrs.SexOffender,
          isParolee: adrs.Parolee,
          isProbation: adrs.Probation,
          isNonValidated: adrs.NonValidatedAddress,
          isCampusResidential: adrs.IsCampusResidential,
          isCampusNonResidential: adrs.IsCampusNonResidential,
          isNonCampus: adrs.IsNonCampus,
          isPublic: adrs.IsPublic,
          unitType: adrs.UnitType,
          unitTypeIdentifier: adrs.UnitIdentifier,
          floorType: adrs.FloorType,
          floorIdentifier: adrs.FloorIdentifier,
          buildingType: adrs.BldgType,
          buildingIdentifier: adrs.BldgIdentifier,
        },
      },
      addressCoordinatesDetails: {
        ptsCoordinateId: adrs.ptsCoordinateID,
        values: {
          incidentId: incidentId,
          locationId: adrs.ptsLocationID,
          addressId: adrs.ptsAddressID,
          coordinateId: adrs?.ptsCoordinateID,
          latitudeSign: adrs?.LatitudeSign,
          latitudeDegree: adrs?.LatitudeDegree,
          longitudeSign: adrs?.LongitudeSign,
          longitudeDegree: adrs?.LongitudeDegree,
          uncertaintyDistance: adrs?.UnCertaintyDistance,
          datum: adrs?.Datum,
          coordinateDatetime: adrs?.CoordinateDT,
          altitude: adrs?.Altitude,
          altitudePor: adrs?.AltitudeRefPoint,
          altitudeUom: adrs?.AltitudeUnitMeasure,
          cellTower: adrs?.CellIDText,
          sectorId: adrs?.CellSectorIDText,
          speed: adrs?.Speed,
          speedUom: adrs?.SpeedUnitMeasure,
        },
      },
    };
  });

  return addresses;
}

function prepareOffenseData(data) {
  if (!data || !data.Offense) return [];

  const { Offense } = data;
  if (!Offense || !Offense.length) return [];

  const offenses = Offense.map((offense) => {
    let statuteDetails = ptsStatute.find((x) => x.Description === offense.statute);
    return {
      offenseDetails: {
        ptsOffenseId: offense.ptsOffenseID,
        ptsStatuteId: statuteDetails.ptsStatuteID,
        values: {
          ptsOffenseId: offense.ptsOffenseID,
          statute: offense.statute || '',
          statuteDetails: statuteDetails || {},
          fbiCode: offense.ptsFBICode,
          offenseAttempted: offense.IsAttempted,
          structuresEntered: offense.StructuresEntered,
          structuresOccupied: offense.StructuresOccupied,
          methodOfEntry: offense.MethodOfEntry,
          locationCategory: offense.IncidentLocationType,
          criminalActivity: offense.CriminalActivity,
          offenderSuspectedOfUsing: offense.OffenderSuspectedOfUsing,
          biasMotivation: offense.BiasMotivations,
          forceCategory: offense.WeaponForceType,
          date: offense.Created,
          updated: offense.Updated,
          updatedBy: offense.UpdatedBy,
        },
      },
    };
  });

  return offenses;
}

function capitalize(string) {
  return string && string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
}

function lowercaseObjectKeys(obj) {
  var key,
    keys = Object.keys(obj);
  var n = keys.length;
  var newobj = {};
  while (n--) {
    key = keys[n];
    const key1 = key.charAt(0).toLowerCase() + key.slice(1);
    newobj[key1] = obj[key];
  }
  return newobj;
}

function preparePropertyData(data) {
  if (!data || !data.IncidentProperty) return [];
  const { IncidentProperty } = data;
  if (!IncidentProperty || !IncidentProperty.length) return [];

  const properties = [];

  IncidentProperty.forEach((property) => {
    const capParentType = capitalize(property.ParentType);
    const currentProperty = property[capParentType];
    if (!currentProperty) return;

    let newObj = lowercaseObjectKeys(currentProperty);
    // mapping the structure attribtues.
    if (newObj.isResidence) {
      newObj = { ...newObj, residence: newObj.isResidence };
    }
    if (newObj.bathroomQty) {
      newObj = { ...newObj, bathroomQuantity: newObj.bathroomQty };
    }
    if (newObj.quantityMeasure) {
      newObj = { ...newObj, substanceQuantityMeasurement: newObj.quantityMeasure };
    }
    if (newObj.drugDaysSupply) {
      newObj = { ...newObj, substanceDaysSupply: newObj.drugDaysSupply };
    }
    if (newObj.drugStrength) {
      newObj = { ...newObj, substanceStrength: newObj.drugStrength };
    }
    if (newObj.dEAClass) {
      newObj = { ...newObj, deaClass: newObj.dEAClass };
    }
    if (newObj.isRented) {
      newObj = { ...newObj, rented: newObj.isRented };
    }
    if (newObj.isWanted) {
      newObj = { ...newObj, wanted: newObj.isWanted };
    }
    if (newObj.isAutomatic) {
      newObj = { ...newObj, automatic: newObj.isAutomatic };
    }
    if (newObj.bedroomQty) {
      newObj = { ...newObj, bedroomQuantity: newObj.bedroomQty };
    }
    if (newObj.bathroomQty) {
      newObj = { ...newObj, bathroomQuantity: newObj.bathroomQty };
    }
    if (newObj.commercialID) {
      newObj = { ...newObj, commercialId: newObj.commercialID };
    }
    if (newObj.commercial) {
      newObj = { ...newObj, commercialCode: newObj.commercial };
    }
    if (newObj.propertyID) {
      newObj = { ...newObj, propertyId: newObj.propertyID };
    }
    if (newObj.transmission) {
      newObj = { ...newObj, transmissionCode: newObj.transmission };
    }
    if (newObj.emissionInspectionQty) {
      newObj = { ...newObj, emissionInspection: newObj.emissionInspectionQty };
    }
    if (newObj.property) {
      newObj = { ...newObj, propertyCode: newObj.property };
    }

    if (currentProperty) {
      if (currentProperty.ptsVehicleID) {
        properties.push({
          propertyType: property.ParentType.toLowerCase(),
          propertyDetails: {
            incIncidentPropertyId: property.incIncidentPropertyID,
            [`pts${capParentType}Id`]: property[capParentType]?.[`pts${capParentType}ID`], // Ex: ptsItemID, ptsVehicleID
            ptsVehicleId: currentProperty.ptsVehicleID,
            propertyId: property[capParentType]?.[`${capParentType}ID`], // Ex: ptsItemID, ptsVehicleID
            ptsRegistrationId: currentProperty.VehicleReg?.ptsRegistrationID,
            values: {
              incidentId: property.ptsIncidentID,
              incIncidentPropertyId: property.incIncidentPropertyID,
              ownerFullName: '', // Need to join with incPropert eyOwners
              description: currentProperty.Description,
              propertyLossType: '', // Where to get it?
              make: currentProperty.Make,
              model: currentProperty.Model,
              parentType: property.ParentType,
              updated: property.Updated,
              updatedBy: property.UpdatedBy,
              name: currentProperty.Name,
              ...newObj,
              vin: currentProperty.VIN,
              quantity: currentProperty.Quantity,
              vinAnalysisTest: currentProperty.VINAnalysisText,
              passengerQuantity: currentProperty.PassengerSafeQty,
              seatQuantity: currentProperty.SeatQty,
              plateType: currentProperty.VehicleReg?.PlateType,
              plateColor: currentProperty.VehicleReg?.PlateColor,
              plateNumber: currentProperty.VehicleReg?.PlateNumber,
              plateState: currentProperty.VehicleReg?.PlateState,
              plateStatus: currentProperty.VehicleReg?.PlateStatus,
              plateDetail: currentProperty.VehicleReg?.PlateDetail,
              plateExpiration: currentProperty.VehicleReg?.PlateExpiration,
              plateBackgroundText: currentProperty.VehicleReg?.PlateBackgroundText,
            },
          },
        });
      } else {
        properties.push({
          propertyType: property.ParentType.toLowerCase(),
          propertyDetails: {
            incIncidentPropertyId: property.incIncidentPropertyID,
            [`pts${capParentType}Id`]: property[capParentType]?.[`pts${capParentType}ID`], // Ex: ptsItemID, ptsVehicleID
            propertyId: property[capParentType]?.[`${capParentType}ID`], // Ex: ptsItemID, ptsVehicleID

            values: {
              incidentId: property.ptsIncidentID,
              incIncidentPropertyId: property.incIncidentPropertyID,
              ownerFullName: '', // Need to join with incPropertyOwners
              description: currentProperty.Description,
              propertyLossType: '', // Where to get it?
              make: currentProperty.Make,
              model: currentProperty.Model,
              parentType: property.ParentType,
              updated: property.Updated,
              updatedBy: property.UpdatedBy,
              name: currentProperty.Name,
              ...newObj,
            },
          },
        });
      }
    }
  });

  // console.log('properties in the inc reducer is', properties);
  return properties;
}

function prepareEvidenceData(data) {
  if (!data || !data.Evidence) return [];
  const { Evidence } = data;
  const incidentState = store.store.getState().incident;
  if (!Evidence) return [];

  const evidences = Evidence.map((item) => {
    const { EvidenceCustody, EvidenceDisposition, EvidenceStorage, EvidenceOffense } = item;

    let evidenceOffenses = EvidenceOffense.map((eo) => {
      for (let i = 0; i < incidentState.offenses.length; i++) {
        if (incidentState.offenses[i].offenseDetails.ptsOffenseId === eo.ptsOffenseID) {
          return incidentState.offenses[i].offenseDetails.values.statute;
        }
      }
    });

    let evidenceStorageDetails = [];
    // eslint-disable-next-line array-callback-return
    EvidenceStorage.map((s) => {
      let storageDetail = {
        incEvidenceStorageDetails: {
          ptsEvidenceId: item.ptsEvidenceID,
          ptsIncidentId: item.ptsIncidentID,
          ptsEvidenceStorageId: s?.ptsEvidenceStorageID,
          values: {
            incidentId: item.ptsIncidentID,
            evidenceId: item.ptsEvidenceID,
            evidenceStorageId: s?.ptsEvidenceStorageID,
            dateIn: s?.DateTimeIn,
            storageLocation: s?.StorageLocation,
            container: s?.Container,
            subLocation: s?.SubLocation,
            enteredBy: s?.UpdatedBy,
          },
        },
      };
      setSelectedEvidenceStorage(storageDetail.incEvidenceStorageDetails.ptsEvidenceStorageId);
      evidenceStorageDetails.push(storageDetail);
    });
    // incidentState.incEvidenceStorage = evidenceStorageDetails;

    let evidenceCustodyDetails = [];
    // eslint-disable-next-line array-callback-return
    EvidenceCustody.map((s) => {
      let custodyDetail = {
        incEvidenceCustodyDetails: {
          ptsEvidenceId: item.ptsEvidenceID,
          ptsIncidentId: item.ptsIncidentID,
          ptsEvidenceCustodyId: s?.ptsEvidenceCustodyID,
          values: {
            incidentId: item.ptsIncidentID,
            evidenceId: item.ptsEvidenceID,
            transactionDate: s?.TransactionDate ? s?.TransactionDate : null,
            transactionType: s?.TransactionType ? s?.TransactionType : '',
            evidenceCustodyId: s?.ptsEvidenceCustodyID,
            Status: s?.Status,
            custodyFrom: s?.CustodyFrom,
            custodyTo: s?.CustodyTo,
            reason: s?.Reason,
            notes: s?.Notes,
            requiredAction: s?.RequiredAction,
            disposalCode: s?.DisposalCode,
          },
        },
      };
      setSelectedEvidenceCustody(custodyDetail.incEvidenceCustodyDetails.ptsEvidenceCustodyId);
      evidenceCustodyDetails.push(custodyDetail);
    });
    // incidentState.incEvidenceCustody = evidenceCustodyDetails;
    return {
      itemRecordDetail: {
        ptsEvidenceId: item.ptsEvidenceID,
        ptsIncidentId: item.ptsIncidentID,
        incIncidentPropertyId: item.incIncidentPropertyID,
        values: {
          ptsEvidenceId: item.ptsEvidenceID,
          evidenceType: item.EvidenceType,
          description: item.Description,
          requiredAction: item.RequiredAction,
          actionDate: item.ActionDate,
          collectedDate: item.DateTimeCollected,
          collectedBy: item.CollectedBy,
          submittedBy: item.SubmittedBy,
          incidentId: item.ptsIncidentID,
          evidenceId: item.ptsEvidenceID,
          category: item.Category,
          locationCollected: item.LocationCollected,
          temporaryLocation: item.TemporaryLocation,
          notes: item.Notes,
          disposalCode: item.DisposalCode,
          offenses: evidenceOffenses,
          quantity: item.Quantity,
          tagNumber: item.TagNumber,
          make: item.Make,
          model: item.Model,
          serialNumber: item.SerialNumber,
          vin: item.VIN,
          licenseNumber: item.LicenseNumber,
          condition: item.Condition,
          substanceType: item.SubstanceType,
          substanceClass: item.SubstanceClass,
          measurement: item.Measurement,
          updated: item.Updated,
          updatedBy: item.UpdatedBy,
        },
      },
      custodyDetail: evidenceCustodyDetails,
      storageDetail: evidenceStorageDetails,
      dispositionDetail: {
        ptsEvidenceId: item.ptsEvidenceID,
        ptsIncidentId: item.ptsIncidentID,
        ptsEvidenceDispositionId: EvidenceDisposition?.ptsEvidenceDispositionID,
        values: {
          incidentId: item.ptsIncidentID,
          evidenceId: item.ptsEvidenceID,
          evidenceDispositionId: EvidenceDisposition?.ptsEvidenceDispositionID,
          bypassDisposalAssumption: EvidenceDisposition?.BypassDispAssumption,
          readyForDisposal: EvidenceDisposition?.ReadyForDisposal,
          isDisposed: EvidenceDisposition?.IsDisposed,
          disposedOfBy: EvidenceDisposition?.DisposedOfBy,
          dispositionDate: EvidenceDisposition?.DispositionDate,
          collectedDate: item.DateTimeCollected,
          finalDisposition: EvidenceDisposition?.FinalDisposition,
          dispositionNotes: EvidenceDisposition?.DispositionNotes,
        },
      },
    };
  });
  return evidences;
}

function prepareGeneralData(data) {
  let Incident;
  if (Array.isArray(data)) Incident = data[0];
  else Incident = data;

  if (!Incident) return [];

  return {
    ptsIncidentId: Incident.ptsIncidentID,
    ptsEventId: Incident.ptsEventID,
    ptsLocationId: Incident.Location?.ptsLocationID,
    CustomId: Incident.IncidentID,
    values: {
      callType: Incident?.CADMethod,
      status: Incident?.Status,
      incidentCategory: Incident?.IncidentCategory,
      weather: Incident?.Weather,
      lighting: Incident?.Lighting,
      locationCategory: Incident.Location?.LocationCategory,
      locationName: Incident.Location?.Name,
      locationCrossStreet: Incident.Location?.CrossStreet,
      locationHighway: Incident.Location?.Highway,
      locationLandmarkText: Incident.Location?.LandmarkText,
      locationSubdivision: Incident.Location?.Subdivision,
      occurred: Incident?.Occurred,
      occurredTo: Incident?.OccurredTo,
      CreatedBy: Incident.CreatedBy,
      updated: Incident?.Updated,
      updatedBy: Incident?.UpdatedBy,
      dispatched: Incident?.Dispatched,
      received: Incident?.Received,
      enroute: Incident?.Enroute,
      arrived: Incident?.Arrived,
      underCont: Incident?.UnderCont,
      completed: Incident?.Completed,
    },
  };
}

function prepareNarrativeData(data) {
  const { Narrative } = data;

  if (!Narrative) return [];

  const narratives = Narrative.map((item) => {
    return {
      narrativeDetail: {
        ptsNarrativeId: item.ptsNarrativeID,
        values: {
          ptsNarrativeId: item.ptsNarrativeID,
          narrativeTitle: item.NarrativeTitle,
          narrativeBody: item.NarrativeBody,
          narrativeType: item.NarrativeType,
          approvedDT: item.ApprovedDT,
          narrativeDT: item.NarrativeDT,
          sequence: item.NarrativeSequence,
          approvedBy: item.ApprovedBy,
          updated: item.Updated,
          updatedBy: item.UpdatedBy,
          entryClerk: item.EntryClerk,
          authorId: item.Author?.ptsPersonID,
          author: item.Author?.FullName,
        },
      },
    };
  });

  return narratives;
}

function prepareDispositionData(data) {
  const { Dispositions } = data;

  if (!Dispositions) return [];

  const dispositons = Dispositions.map((item) => {
    return {
      ptsDispositionId: item.ptsDispositionID,
      values: {
        ptsDispositionId: item.ptsDispositionID,
        disposition: item.Disposition,
        updated: item.Updated,
        updatedBy: item.UpdatedBy,
      },
    };
  });

  return dispositons;
}

function prepareZoneData(data) {
  const { Location } = data;

  if (!Location.LocationZones) return [];

  const zones = data.Location?.LocationZones.map((item) => {
    return {
      ptsLocationId: item.ptsLocationID,
      values: {
        ptsLocationId: item.ptsLocationID,
        zone: item.Zone,
        updated: item.Updated,
        updatedBy: item.UpdatedBy,
      },
    };
  });

  return zones;
}

function prepareAlertData(data) {
  const { Alerts } = data;

  if (!Alerts) return [];

  const zones = data.Alerts.map((item) => {
    return {
      ptsAlertId: item.ptsAlertID,
      values: {
        ptsAlertId: item.ptsAlertID,
        alertType: item.AlertType,
        notification: item.Notification,
        updated: item.Updated,
        updatedBy: item.UpdatedBy,
      },
    };
  });

  return zones;
}

function prepareNotificationData(data) {
  const { Notifications } = data;

  if (!Notifications) return [];

  const notifications = data.Notifications.map((item) => {
    return {
      ptsNotificationId: item.ptsNotificationID,
      values: {
        ptsNotificationId: item.ptsNotificationID,
        notification: item.Notification,
        updated: item.Updated,
        updatedBy: item.UpdatedBy,
      },
    };
  });

  return notifications;
}

export const getEntitiesByIncidentId = (entity, q = {}) => {
  return async (dispatch) => {
    const { incident } = store.store.getState();
    const selectedIncId = incident.ptsIncidentId;
    let query = {};

    // Attach Filtering Keywords to the query
    if (q.partiesKeyword && q.partiesKeyword.length > 0 && entity === 'parties') {
      query.partiesKeyword = q.partiesKeyword;
    }
    if (
      (q.propertiesKeyword || q.propertiesKeyword2 || q.propertiesKeyword3) &&
      entity === 'properties'
    ) {
      query.propertiesKeyword = q.propertiesKeyword || ''; // SN
      query.propertiesKeyword2 = q.propertiesKeyword2 || ''; // LN or Plate Number
      query.propertiesKeyword3 = q.propertiesKeyword3 || ''; // VIN
    }
    if (
      (q.propertiesKeyword || q.propertiesKeyword2 || q.propertiesKeyword3) &&
      entity === 'evidence'
    ) {
      query.propertiesKeyword = q.evidencesKeyword || ''; // SN
      query.evidencesKeyword2 = q.evidencesKeyword2 || ''; // LN
      query.evidencesKeyword3 = q.evidencesKeyword3 || ''; // VIN
    }
    if (
      (q.evidencesKeyword || q.evidencesKeyword2 || q.evidencesKeyword3) &&
      entity === 'evidence'
    ) {
      query.evidencesKeyword = q.evidencesKeyword || ''; // SN
      query.evidencesKeyword2 = q.evidencesKeyword2 || ''; // LN
      query.evidencesKeyword3 = q.evidencesKeyword3 || ''; // VIN
    }
    if (q.offensesKeyword && q.offensesKeyword.length > 0 && entity === 'offenses') {
      query.offensesKeyword = q.offensesKeyword;
    }
    if (q.addressesKeyword && q.addressesKeyword.length > 0 && entity === 'addresses') {
      query.addressesKeyword = q.addressesKeyword;
    }
    if (q.narrativesKeyword && q.narrativesKeyword.length > 0 && entity === 'narratives') {
      query.narrativesKeyword = q.narrativesKeyword;
    }

    if (['parties', 'suspects'].includes(entity)) {
      entity = 'parties';
      query['contact'] = true;
    }

    await getEntitiesByIncId(selectedIncId, entity, query)
      .then((data) => {
        let actionType;
        let payload;
        if (entity === 'parties') {
          payload = preparePartyPersonData(data);
          actionType = GET_PARTIES;
        } else if (entity === 'addresses') {
          payload = prepareAddressData(data);
          actionType = GET_ADDRESSESS;
        } else if (entity === 'offenses') {
          payload = prepareOffenseData(data);
          actionType = GET_OFFENSES;
        } else if (entity === 'properties') {
          payload = preparePropertyData(data);
          actionType = GET_PROPERTIES;
        } else if (entity === 'evidence') {
          payload = prepareEvidenceData(data);
          actionType = GET_EVIDENCES;
        } else if (entity === 'narratives') {
          payload = prepareNarrativeData(data);
          actionType = GET_NARRATIVES;
        } else if (entity === 'general') {
          payload = prepareGeneralData(data);
          actionType = SET_INCIDENT;
        } else if (entity === 'dispositions') {
          payload = prepareDispositionData(data);
          actionType = GET_DISPOSITIONS;
        } else if (entity === 'zones') {
          payload = prepareZoneData(data);
          actionType = GET_ZONES;
        } else if (entity === 'alerts') {
          payload = prepareAlertData(data);
          actionType = GET_ALERTS;
        } else if (entity === 'notifications') {
          payload = prepareNotificationData(data);
          actionType = GET_NOTIFICATIONS;
        }
        dispatch({ type: actionType, payload: payload });
      })
      .catch((err) => console.log(err));

    return true;
  };
};

export const getCityIdFromCityName = (cityName) => {
  const { codesOthers } = store.store.getState();
  const { codeCities } = codesOthers;

  if (!Array.isArray(codeCities)) {
    return;
  }

  for (const city of codeCities) {
    if (city.CityDescription === cityName) {
      return city.ptsCityID;
    }
  }
};

export const upsertAddress = async (data, records = false, dispatch) => {
  let { addressForm, addressIdForm, coordinatesForm, incident, addresses } = data;
  const user = store.store.getState().user.userData.user.Username;
  const client = store.store.getState().websocket.websocket;

  if (!addressForm) return;

  if (!client) {
    return;
  }

  if (typeof addressForm.values?.city === 'string') {
    addressForm.values.cityId = getCityIdFromCityName(addressForm.values?.city);
  }

  if (Object.keys(addressForm.values).length <= 1) return;

  // If it is first address of this incident, then it is primary address
  if (addresses.length === 0) {
    addressForm = {
      ...addressForm,
      values: {
        ...addressForm.values,
        isPrimary: true,
      },
    };
  }

  const addressRequestBody = {
    ptsAddressId: addressForm?.ptsAddressId,
    ptsLocationId: incident.ptsLocationId,
    tempAddressId: addressForm.tempAddressId,
    addressDetails: addressForm,
    addressIdDetails: addressIdForm,
    addressCoordinatesDetails: coordinatesForm,
    user: user,
  };

  let service;
  if (records) {
    service = client.service('record-address');

    // Address Duplicate Checking for Records
    let duplicateFound = await duplicateCheck(addressRequestBody.addressDetails, 'address');
    if (duplicateFound) {
      dispatch(notify('Duplicate Found.', 'warning'));
      return;
    }

    service.timeout = 20000;

    let response;
    if (addressForm?.ptsAddressId) {
      response = await service.patch(addressForm?.ptsAddressId, addressRequestBody);
    } else {
      response = await service.create(addressRequestBody);
    }

    // Change the flag
    data.changed = false;

    return {
      ptsAddressId: response.ptsAddressID,
      ptsCoordinateId:
        response?.locationAddress?.ptsCoordinateID || response?.ptsCoordinates.ptsCoordinateID,
    };
  } else {
    service = client.service('rms-address');

    // Address Duplicate Checking for Incident Address
    let duplicateFound = !addressForm?.ptsAddressId
      ? await duplicateCheck(addressRequestBody.addressDetails, 'address')
      : false;
    if (duplicateFound) {
      dispatch(notify('Duplicate Found.', 'warning'));
      return;
    }

    service.timeout = 20000;

    let response;
    if (addressForm?.ptsAddressId) {
      response = await service.patch(addressForm?.ptsAddressId, addressRequestBody);
    } else {
      response = await service.create(addressRequestBody);
    }

    // Change the flag
    data.changed = false;

    return {
      ptsAddressId: response.ptsAddressID,
      ptsCoordinateId: response?.locationAddress?.ptsCoordinateID,
      addressDetails: {
        ptsAddressId: response.ptsAddressID,
        values: {
          ptsLocationId: response.ptsLocationID,
          fullAddressText: response.FullAddressText,
          created: response.Created,
          updated: response.Updated,
          updatedIp: response.UpdatedIP,
          addressNumberPrefix: response.AddressNumberPrefix,
          addressNumber: response.AddressNumber,
          streetName: response.StreetName,
          preDirection: response.PreDirection,
          postDirection: response.PostDirection,
          city: response?.locationAddress?.City?.CityDescription,
          state: response.State,
          county: response.County,
          country: response.Country,
          postalCode: response.PostalCode,
          postalCodeExtension: response?.locationAddress?.PostalCodeExtension,
          streetType: response?.locationAddress?.StreetType,
          notes: response?.locationAddress?.Notes,
        },
      },
      addressIdDetails: {
        ptsAddressId: response.ptsAddressID,
        ptsLocationId: incident.ptsLocationId,
        values: {
          isEventLookup: response?.locationAddress?.ShowInEventLookup,
          isSexOffender: response?.locationAddress?.SexOffender,
          isParolee: response?.locationAddress?.Parolee,
          isProbation: response?.locationAddress?.Probation,
          isNonValidated: response?.locationAddress?.NonValidatedAddress,
          isCampusResidential: response?.locationAddress?.IsCampusResidential,
          isCampusNonResidential: response?.locationAddress?.IsCampusNonResidential,
          isNonCampus: response?.locationAddress?.IsNonCampus,
          isPublic: response?.locationAddress?.IsPublic,
          unitType: response?.locationAddress?.UnitType,
          unitTypeIdentifier: response?.locationAddress?.UnitIdentifier,
          floorType: response?.locationAddress?.FloorType,
          floorIdentifier: response?.locationAddress?.FloorIdentifier,
          buildingType: response?.locationAddress?.BldgType,
          buildingIdentifier: response?.locationAddress?.BldgIdentifier,
        },
      },
      addressCoordinatesDetails: {
        ptsAddressId: response.ptsAddressID,
        ptsLocationId: incident.ptsLocationId,
        ptsCoordinateId: response?.locationAddress?.ptsCoordinateID,
        values: {
          incidentId: data?.ptsIncidentId,
          coordinateId: response?.locationAddress?.ptsCoordinateID,
          latitudeSign: response?.locationAddress.LatitudeSign,
          latitudeDegree: response?.locationAddress.LatitudeDegree,
          longitudeSign: response?.locationAddress.LongitudeSign,
          longitudeDegree: response?.locationAddress.LongitudeDegree,
          uncertaintyDistance: response?.locationAddress.UnCertaintyDistance,
          datum: response?.locationAddress.Datum,
          coordinateDatetime: response?.locationAddress.CoordinateDT,
          altitude: response?.locationAddress.Altitude,
          altitudePor: response?.locationAddress.AltitudeRefPoint,
          altitudeUom: response?.locationAddress.AltitudeUnitMeasure,
          cellTower: response?.locationAddress.CellIDText,
          sectorId: response?.locationAddress.CellSectorIDText,
          speed: response?.locationAddress.Speed,
          speedUom: response?.locationAddress.SpeedUnitMeasure,
        },
      },
    };
  }
};

export const upsertIncident = async (data) => {
  const client = store.store.getState().websocket.websocket;
  const user = store.store.getState().user.userData.user.Username;
  if (!client) {
    return;
  }

  if (data?.changed === false) {
    return;
  }
  const service = client.service('rms-incident');
  service.timeout = 100000;

  let requestBody = {
    lighting: data.values?.lighting,
    weather: data.values?.weather,
    callType: data.values?.callType,
    incidentCategory: data.values?.incidentCategory,
    tempIncidentId: data.values?.tempIncidentId,
    status: data.values?.status,
    occurred: data.values?.occurred || new Date(),
    occurredTo: data.values?.occurredTo || new Date(),
    locationCategory: data.values.locationCategory,
    locationSubdivision: data.values.locationSubdivision,
    locationName: data.values.locationName,
    locationHighway: data.values.locationHighway,
    locationLandmarkText: data.values.locationLandmarkText,
    locationCrossStreet: data.values.locationCrossStreet,
    received: data.values?.received,
    dispatched: data.values?.dispatched,
    underCont: data.values?.underCont,
    completed: data.values?.completed,
    arrived: data.values?.arrived,
    enroute: data.values?.enroute,
    attachments: data.values?.attachments,
  };

  let response;
  if (data?.ptsIncidentId > 0) {
    requestBody = { ...requestBody, createdBy: data.values?.CreatedBy, updatedBy: user };
    response = await service.patch(data?.ptsIncidentId, requestBody);
  } else {
    requestBody = { ...requestBody, createdBy: user, updatedBy: user };
    response = await service.create(requestBody);
  }

  // Change the flag
  data.changed = false;

  return {
    ptsIncidentId: response.ptsIncidentID,
    ptsLocationId: response.location?.ptsLocationID,
    values: {
      callType: response?.CADMethod,
      status: response?.Status,
      incidentCategory: response?.IncidentCategory,
      weather: response?.Weather,
      lighting: response?.Lighting,
      locationCategory: response.location?.LocationCategory,
      locationName: response.location?.Name,
      locationCrossStreet: response.location?.CrossStreet,
      locationHighway: response.location?.Highway,
      locationLandmarkText: response.location?.LandmarkText,
      locationSubdivision: response.location?.Subdivision,
      occurred: response?.Occurred,
      occurredTo: response?.OccurredTo,
      updated: response?.Updated,
      createdBy: user,
      updatedBy: user,
    },
  };
};

export const upsertProperties = async (data, records = false, dispatch) => {
  const {
    ptsIncidentId,
    propertyItemForm,
    propertyFirearmForm,
    propertyStructureForm,
    propertyVehicleDetailsForm,
    propertySubstanceForm,
    propertyJewelryForm,
    propertyCredentialsAnalysisForm,
    evidenceItemForm,
  } = data;

  // Vehicle Duplicate Checking
  if (
    (propertyVehicleDetailsForm.changes || propertyCredentialsAnalysisForm.changes) &&
    Object.keys(propertyVehicleDetailsForm.values).length !== 0 &&
    Object.keys(propertyCredentialsAnalysisForm.values).length !== 0 &&
    propertyCredentialsAnalysisForm.values.vin !== undefined
  ) {
    let duplicateFound = await duplicateCheck(propertyCredentialsAnalysisForm, 'vehicle');

    if (duplicateFound) {
      dispatch(notify('Duplicate Found.', 'warning'));
      return;
    }
  }

  const user = store.store.getState().user.userData.user.Username;
  const client = store.store.getState().websocket.websocket;
  if (!client) {
    return;
  }

  let requestBody = {};
  let response;
  let incIncidentPropertyId = 0;
  let propertyId = 0;
  let serviceName = 'rms-property-item';
  let parentType = 'Item';

  if (propertyItemForm.changes && Object.keys(propertyItemForm.values).length > 0) {
    incIncidentPropertyId = propertyItemForm.incIncidentPropertyId;
    propertyId = propertyItemForm.ptsItemId;
    requestBody = {
      Condition: propertyItemForm.values.condition,
      SerialNumber: propertyItemForm.values.serialNumber,
      Model: propertyItemForm.values.model,
      Make: propertyItemForm.values.make,
      Description: propertyItemForm.values.description,
      Name: propertyItemForm.values.name,
      Quantity: propertyItemForm.values.quantity,
      attachments: propertyItemForm.values.attachments,
      CreatedBy: user,
      UpdatedBy: user,
      IsDeleted: false,
      IsLocked: false,
      IsSealed: false,
    };
  } else if (propertyFirearmForm.changes && Object.keys(propertyFirearmForm.values).length > 0) {
    parentType = 'Firearm';
    serviceName = 'rms-property-firearm';
    incIncidentPropertyId = propertyFirearmForm.incIncidentPropertyId;
    propertyId = propertyFirearmForm.ptsFirearmId;
    requestBody = {
      FirearmCategory: propertyFirearmForm.values.firearmCategory,
      IsAutomatic: propertyFirearmForm.values.automatic || false,
      BarrelLength: Number.isInteger(Number(propertyFirearmForm.values.barrelLength))
        ? Number(propertyFirearmForm.values.barrelLength)
        : 0,
      BarrelLengthMeasure: propertyFirearmForm.values.barrelLengthMeasure,
      Caliber: propertyFirearmForm.values.caliber,
      Make: propertyFirearmForm.values.make,
      Model: propertyFirearmForm.values.model,
      Description: propertyFirearmForm.values.description,
      SerialNumber: propertyFirearmForm.values.serialNumber,
      Condition: propertyFirearmForm.values.condition,
      attachments: propertyFirearmForm.values.attachments,
      CreatedBy: user,
      UpdatedBy: user,
      IsDeleted: false,
      IsLocked: false,
      IsSealed: false,
    };
  } else if (
    propertyStructureForm.changes &&
    Object.keys(propertyStructureForm.values).length > 0
  ) {
    parentType = 'Structure';
    serviceName = 'rms-property-structure';
    incIncidentPropertyId = propertyStructureForm.incIncidentPropertyId;
    propertyId = propertyStructureForm.ptsStructureId;
    requestBody = {
      Description: propertyStructureForm.values.description,
      PrimaryColor: propertyStructureForm.values.primaryColor,
      SecondaryColor: propertyStructureForm.values.secondaryColor,
      GarageType: propertyStructureForm.values.garageType,
      Notes: propertyStructureForm.values.notes,
      IsResidence: propertyStructureForm.values.isResidence,
      BedroomQty: Number.isInteger(Number(propertyStructureForm.values.bedroomQuantity))
        ? Number(propertyStructureForm.values.bedroomQuantity)
        : 0,
      BathroomQty: Number.isInteger(Number(propertyStructureForm.values.bathroomQuantity))
        ? Number(propertyStructureForm.values.bedroomQuantity)
        : 0,
      attachments: propertyStructureForm.values.attachments,
      CreatedBy: user,
      UpdatedBy: user,
      IsDeleted: false,
      IsLocked: false,
      IsSealed: false,
    };
  } else if (
    propertySubstanceForm.changes &&
    Object.keys(propertySubstanceForm.values).length > 0
  ) {
    parentType = 'Substance';
    serviceName = 'rms-property-substance';
    incIncidentPropertyId = propertySubstanceForm.incIncidentPropertyId;
    propertyId = propertySubstanceForm.ptsSubstanceId;
    requestBody = {
      SubstanceType: propertySubstanceForm.values.substanceType,
      Designator: propertySubstanceForm.values.designator,
      Container: propertySubstanceForm.values.container,
      Quantity: Number.isInteger(Number(propertySubstanceForm.values.quantity))
        ? Number(propertySubstanceForm.values.quantity)
        : 0,
      Composition: propertySubstanceForm.values.composition,
      Form: propertySubstanceForm.values.form,
      Description: propertySubstanceForm.values.description,
      DrugDaysSupply: Number.isInteger(Number(propertySubstanceForm.values.substanceDaysSupply))
        ? Number(propertySubstanceForm.values.substanceDaysSupply)
        : 0,
      DrugStrength: propertySubstanceForm.values.substanceStrength,
      DEAClass: propertySubstanceForm.values.deaClass,
      ptsPrescribingPlaceID: propertySubstanceForm.values.placeId,
      ptsPrescribingPersonID: propertySubstanceForm.values.personId,
      QuantityMeasure: propertySubstanceForm.values.quantityMeasure,
      attachments: propertySubstanceForm.values.attachments,
      CreatedBy: user,
      UpdatedBy: user,
      IsDeleted: false,
      IsLocked: false,
      IsSealed: false,
    };
  } else if (propertyJewelryForm.changes && Object.keys(propertyJewelryForm.values).length > 0) {
    parentType = 'Jewelry';
    serviceName = 'rms-property-jewelry';
    incIncidentPropertyId = propertyJewelryForm.incIncidentPropertyId;
    propertyId = propertyJewelryForm.ptsJewelryId;
    requestBody = {
      Make: propertyJewelryForm.values.make,
      Model: propertyJewelryForm.values.model,
      Description: propertyJewelryForm.values.description,
      SerialNumber: propertyJewelryForm.values.serialNumber,
      Condition: propertyJewelryForm.values.condition,
      JewelryCategory: propertyJewelryForm.values.jewelryCategory,
      Sex: propertyJewelryForm.values.sex,
      Shape: propertyJewelryForm.values.shape,
      Arrangement: propertyJewelryForm.values.arrangement,
      Carat: Number.isInteger(Number(propertyJewelryForm.values.carat))
        ? Number(propertyJewelryForm.values.carat)
        : 0,
      Band: propertyJewelryForm.values.band,
      MainPendant: propertyJewelryForm.values.mainPendant,
      PendantQuantity: Number.isInteger(Number(propertyJewelryForm.values.pendantQuantity))
        ? Number(propertyJewelryForm.values.pendantQuantity)
        : 0,
      Material: propertyJewelryForm.values.material,
      attachments: propertyJewelryForm.values.attachments,
      CreatedBy: user,
      UpdatedBy: user,
      IsDeleted: false,
      IsLocked: false,
      IsSealed: false,
    };
  } else if (
    (propertyVehicleDetailsForm.changes || propertyCredentialsAnalysisForm.changes) &&
    Object.keys(propertyVehicleDetailsForm.values).length > 0
  ) {
    parentType = 'Vehicle';
    serviceName = 'rms-property-vehicle';
    incIncidentPropertyId = propertyVehicleDetailsForm.incIncidentPropertyId;
    propertyId = propertyVehicleDetailsForm.ptsVehicleId;
    requestBody = {
      registrationDetails: {
        ptsRegistrationId: propertyVehicleDetailsForm.ptsRegistrationId,
        values: {
          ptsVehicleID: propertyId,
          PlateType: propertyCredentialsAnalysisForm.values.plateType,
          PlateColor: propertyCredentialsAnalysisForm.values.plateColor,
          PlateNumber: propertyCredentialsAnalysisForm.values.plateNumber,
          PlateState: propertyCredentialsAnalysisForm.values.plateState,
          PlateStatus: propertyCredentialsAnalysisForm.values.plateStatus,
          PlateDetail: propertyCredentialsAnalysisForm.values?.plateDetail,
          PlateExpiration: propertyCredentialsAnalysisForm.values?.plateExpiration,
          PlateBackgroundText: propertyCredentialsAnalysisForm.values?.plateBackgroundText,
          ptsParentID: ptsIncidentId,
          PartyType: 'INCIDENT',
        },
      },
      vehicleDetails: {
        values: {
          Brand: propertyVehicleDetailsForm.values.brand,
          Brander: propertyVehicleDetailsForm.values.brander,
          Commercial: propertyCredentialsAnalysisForm.values.commercialCode,
          IsCMVIndicator: propertyCredentialsAnalysisForm.values.cmv,
          GarageFacility: propertyCredentialsAnalysisForm.values.garageFacility,
          GarageIndicator: propertyCredentialsAnalysisForm.values.garageIndicator,
          VIN: propertyCredentialsAnalysisForm.values.vin,
          VINAnalysisText: propertyCredentialsAnalysisForm.values.vinAnalysisTest,
          IsRented: propertyCredentialsAnalysisForm.values.rented,
          Classification: propertyVehicleDetailsForm.values.classification,
          PrimaryColor: propertyVehicleDetailsForm.values.primaryColor,
          SecondaryColor: propertyVehicleDetailsForm.values.secondaryColor,
          InteriorColor: propertyVehicleDetailsForm.values.interior,
          Make: propertyVehicleDetailsForm.values.make,
          Model: propertyVehicleDetailsForm.values.model,
          Style: propertyVehicleDetailsForm.values.style,
          Year: Number.isInteger(Number(propertyVehicleDetailsForm.values.year))
            ? Number(propertyVehicleDetailsForm.values.year)
            : 0,
          PassengerSafeQty: Number.isInteger(
            Number(propertyVehicleDetailsForm.values.passengerQuantity)
          )
            ? Number(propertyVehicleDetailsForm.values.passengerQuantity)
            : 0,
          Transmission: propertyCredentialsAnalysisForm.values.transmissionCode,
          IsWanted: propertyCredentialsAnalysisForm.values.wanted,
          Description: propertyVehicleDetailsForm.values.description,
          CreatedBy: user,
          UpdatedBy: user,
          IsDeleted: false,
          SeatQty: Number.isInteger(Number(propertyVehicleDetailsForm.values.seatQuantity))
            ? Number(propertyVehicleDetailsForm.values.seatQuantity)
            : 0,
          EmissionInspectionQty: Number.isInteger(
            Number(propertyCredentialsAnalysisForm.values.emissionInspection)
          )
            ? Number(propertyCredentialsAnalysisForm.values.emissionInspection)
            : 0,
          // assuming commercial code in then backend corresponds to commercialId in the front end
          CommercialID: propertyCredentialsAnalysisForm.values.commercialId,
          Property: propertyCredentialsAnalysisForm.values.propertyCode,
          PropertyID: propertyCredentialsAnalysisForm.values.propertyId,
          attachments: propertyVehicleDetailsForm.values.attachments,
        },
      },
    };
  }

  const service = client.service(serviceName);
  service.timeout = 20000;
  if (Object.keys(requestBody).length < 1) {
    return;
  }

  requestBody['ptsIncidentID'] = records ? 0 : ptsIncidentId;
  requestBody['incIncidentPropertyId'] = records ? 0 : incIncidentPropertyId;

  if (incIncidentPropertyId || propertyId) {
    response = await service.patch(propertyId, requestBody);
    response = {
      ParentType: parentType,
      incIncidentPropertyID: incIncidentPropertyId,
      ptsIncidentID: ptsIncidentId,
      [parentType]: response,
    };
  } else {
    response = await service.create(requestBody);
  }

  // Saving Property as Evidence
  if (
    propertyVehicleDetailsForm.values.isEvidence ||
    propertyJewelryForm.values.isEvidence ||
    propertySubstanceForm.values.isEvidence ||
    propertyStructureForm.values.isEvidence ||
    propertyFirearmForm.values.isEvidence ||
    propertyItemForm.values.isEvidence
  ) {
    let evidenceForm = {
      ...evidenceItemForm,
      values: {
        ...evidenceItemForm.values,
        incIncidentPropertyId: response.incIncidentPropertyID,
      },
    };

    const res = await addNewEvidenceItem(evidenceForm, ptsIncidentId, []);
    console.log('Evidence from property is created => ', res);
  }

  const reduxPayload = preparePropertyData({
    IncidentProperty: [response],
  });

  return reduxPayload[0];
};

export const upsertParty = async (data, dispatch) => {
  const {
    ptsIncidentId,
    partiesPersonForm,
    partiesContactForm,
    partiesEmploymentForm,
    partiesOthersForm,
    offensesMPBasicForm,
    mpRelatedRecords,
  } = data;
  const recordsState = store.store.getState().records;
  const incidentState = store.store.getState().incident;
  const user = store.store.getState().user.userData.user.Username;
  const modalState = store.store.getState().uiModal;

  if (Object.keys(partiesPersonForm.values).length === 0) return;

  if (typeof partiesContactForm.values?.city === 'string') {
    partiesContactForm.values.cityId = getCityIdFromCityName(partiesContactForm.values?.city);
  }

  if (typeof partiesEmploymentForm.values?.city === 'string') {
    partiesEmploymentForm.values.cityId = getCityIdFromCityName(partiesEmploymentForm.values?.city);
  }

  // If the form is empty then return right away
  if (!partiesPersonForm.values.firstName) {
    incidentState.requiredError = true;
    dispatch(notify('First Name is Required.', 'warning'));
    return;
  } else {
    incidentState.requiredError = false;
  }

  /** Duplicate Checking */
  modalState.duplicateModalMessage = '';
  let duplicateFoundPerson,
    duplicateFoundContact,
    duplicateFoundEmployment = false;
  // Person
  duplicateFoundPerson = !partiesPersonForm.ptsPersonId
    ? await duplicateCheck(partiesPersonForm, 'person')
    : false;

  // Employment Address
  if (
    !partiesEmploymentForm.ptsAddressId &&
    Object.keys(partiesEmploymentForm.values).length !== 0
  ) {
    duplicateFoundEmployment = await duplicateCheck(partiesEmploymentForm, 'address', 'employment');
  }

  // Contact Address
  if (!partiesContactForm.ptsAddressId && Object.keys(partiesContactForm.values).length !== 0) {
    duplicateFoundContact = await duplicateCheck(partiesContactForm, 'address', 'contact');
  }

  modalState.showDuplicateModal =
    duplicateFoundPerson || duplicateFoundContact || duplicateFoundEmployment;
  if (duplicateFoundPerson || duplicateFoundContact || duplicateFoundEmployment) {
    dispatch(notify('Duplicate Found.', 'warning'));
    return;
  }
  /** End Duplicate Checking */

  const client = store.store.getState().websocket.websocket;
  if (!client) {
    return;
  }

  const service = client.service('rms-party');
  service.timeout = 100000;

  const requestBody = {
    ptsIncidentId,
    ptsPersonId: partiesPersonForm.ptsPersonId,
    ptsIncPersonId: partiesPersonForm.ptsIncPersonId,
    partyType: partiesPersonForm.values.partyType,
    personDetail: partiesPersonForm,
    contactDetail: partiesContactForm,
    employmentDetail: partiesEmploymentForm,
    otherDetail: partiesOthersForm,
    user: user,
  };

  if (
    partiesPersonForm.values?.partyType?.toLowerCase() === 'victim' &&
    Object.keys(offensesMPBasicForm.values).length !== 0 &&
    offensesMPBasicForm.changes === true
  ) {
    requestBody.mpBasicFormDetails = offensesMPBasicForm;
    requestBody.mpRelatedRecords = mpRelatedRecords;
  }

  let response;
  if (partiesPersonForm.ptsPersonId && recordsState.recordActive) {
    response = await service.patch(null, requestBody);
  } else {
    if (partiesPersonForm.ptsIncPersonId) {
      response = await service.patch(null, requestBody);
    } else {
      response = await service.create(requestBody);
    }

    let formatResponse = {
      ...response.partyPerson,
      PartiesAddresses: response?.partyContactInfo?.partyAddress,
      ContactInfo: [
        response?.partyContactInfo.contact1,
        response?.partyContactInfo.contact2,
        response?.partyContactInfo.contact3,
        response?.partyEmploymentInfo.contact1,
        response?.partyEmploymentInfo.contact2,
        response?.partyEmploymentInfo.contact3,
      ],
      PartiesEmployment: {
        ...response?.partyEmploymentInfo?.partyEmployment,
        Place: response?.partyEmploymentInfo?.place,
      },
    };

    if (response.mpBasic) {
      formatResponse.MPVictim = {
        ...response.mpBasic,
        RelatedRecords: response.mpRelatedRecords,
      };
    }

    const reduxPayload = preparePartyPersonData({
      IncidentPartyPerson: [formatResponse],
    });

    partiesPersonForm.ptsIncPersonId = reduxPayload[0].personDetail.ptsIncPersonId;

    return reduxPayload[0];
  }
};

export const addOffenses = () => {
  let { offenses, offensesOffenseForm } = store.store.getState().incident;

  let isNewOffense = true;

  // If the form is empty then return right away
  if (!offensesOffenseForm.ptsOffenseId && !offensesOffenseForm.tempOffenseId) {
    return;
  }

  if (Object.keys(offensesOffenseForm.values).length === 0) {
    return;
  }

  let newOffense = {};

  if (offensesOffenseForm.values.statuteDetails !== undefined) {
    // TODO: Temporary implementation of set ptsStatuteId. Later we need to populate ptsStatute table move this over to backend implementation
    offensesOffenseForm.values.ptsStatuteId = ptsStatute.find(
      (x) => x.Description === offensesOffenseForm.values.statuteDetails
    )?.ptsStatuteID;

    newOffense = {
      offenseDetails: offensesOffenseForm,
    };
  }

  for (let i = 0; i < offenses.length; i++) {
    if (
      offenses[i].offenseDetails.ptsOffenseId === offensesOffenseForm.ptsOffenseId ||
      offenses[i].offenseDetails.tempOffenseId === offensesOffenseForm.tempOffenseId
    ) {
      isNewOffense = false;
      break;
    }
  }

  if (isNewOffense) {
    offenses.push(newOffense);
  }
};

export const addEvidences = () => {
  const incState = store.store.getState().incident;
  if (
    !incState.evidences.some(
      (x) => x.itemRecordDetail.tempEvidenceId === incState.evidenceItemForm.tempEvidenceId
    )
  ) {
    if (Object.keys(incState.evidenceItemForm.values).length !== 0) {
      let evidence = {
        itemRecordDetail: incState.evidenceItemForm,
        custodyDetail: incState.evidenceCustodyForm,
        storageDetail: incState.evidenceStorageForm,
        dispositionDetail: incState.evidenceDispositionForm,
      };
      incState.evidences.push(evidence);
    }
  }
};

export const addNarratives = () => {
  const incState = store.store.getState().incident;
  if (
    !incState.narratives.some((x) => x.tempNarrativeId === incState.narrativeForm.tempNarrativeId)
  ) {
    if (Object.keys(incState.narrativeForm.values).length !== 0) {
      incState.narratives.push(incState.narrativeForm);
    }
  }
};

export const addIncSubjects = (party) => {
  console.log(party);
  const incState = store.store.getState().incident;
  setLinkedFormOffenseId();
  let offenseID = incState.offensesOffenseForm.ptsOffenseId || incState.currentOffense;

  if (incState.partiesPersonForm.values.partyType === 'Offender') {
    let subject = {
      incSubjectDetails: {
        ...initFormModel,
        changes: true,
        ptsOffenseId: offenseID,
        ptsPersonId: party.ptsPersonId ? party.ptsPersonId : 0,
        ptsIncPersonId: party.ptsIncPersonId,
      },
    };
    incState.incSubjects.push(subject);
  }

  const menuState = store.store.getState().uiMenu;
  menuState.handleAddIncSubject = false;
};

export const addIncVictims = (party) => {
  const incState = store.store.getState().incident;
  setLinkedFormOffenseId();
  let offenseID = incState.offensesOffenseForm.ptsOffenseId || incState.currentOffense;

  if (incState.partiesPersonForm.values.partyType === 'Victim') {
    let victim = {
      incVictimDetails: {
        ...initFormModel,
        changes: true,
        ptsPersonId: party.ptsPersonId ? party.ptsPersonId : 0,
        ptsIncPersonId: party.ptsIncPersonId,
        ptsOffenseId: offenseID,
      },
    };
    incState.incVictims.push(victim);
  }

  const menuState = store.store.getState().uiMenu;
  menuState.handleAddIncVictim = false;
};

export const addVictimRelationships = () => {
  const incState = store.store.getState().incident;
  setLinkedFormOffenseId();

  if (
    !incState.incRelationships.find(
      (x) =>
        x.incRelationshipDetails.ptsOffenseId === incState.offensesRelationshipForm.ptsOffenseId &&
        x.incRelationshipDetails.values.relationship.offenderId ===
          incState.offensesRelationshipForm.values.relationship?.offenderId &&
        x.incRelationshipDetails.values.relationship.victimId ===
          incState.offensesRelationshipForm.values.relationship?.victimId
    )
  ) {
    if (incState.offensesRelationshipForm.values.relationship)
      incState.incRelationships.push({
        incRelationshipDetails: incState.offensesRelationshipForm,
      });
  } else {
    let updateRelationship = incState.incRelationships.find(
      (x) =>
        x.incRelationshipDetails.ptsOffenseId === incState.offensesRelationshipForm.ptsOffenseId &&
        x.incRelationshipDetails.values.relationship.offenderId ===
          incState.offensesRelationshipForm.values.relationship?.offenderId &&
        x.incRelationshipDetails.values.relationship.victimId ===
          incState.offensesRelationshipForm.values.relationship?.victimId
    );
    if (updateRelationship !== undefined)
      updateRelationship.incRelationshipDetails = incState.offensesRelationshipForm;
  }

  const menuState = store.store.getState().uiMenu;
  menuState.handleAddIncRelationship = false;
};

export const addIncProperties = (prop) => {
  const incState = store.store.getState().incident;
  setLinkedFormOffenseId();
  let offenseID = incState.offensesOffenseForm.ptsOffenseId || incState.currentOffense;

  let property = {
    incPropertyDetails: {
      ...initFormModel,
      changes: true,
      incIncidentPropertyId: prop.propertyDetails.incIncidentPropertyId,
      ptsOffenseId: offenseID,
    },
  };
  incState.incProperties.push(property);

  const menuState = store.store.getState().uiMenu;
  menuState.handleAddIncProperty = false;
};

export const addIncArrestees = (party) => {
  const incState = store.store.getState().incident;
  setLinkedFormOffenseId();
  let offenseID = incState.offensesOffenseForm.ptsOffenseId || incState.currentOffense;

  let arrestee = {
    incArresteeDetails: {
      ...initFormModel,
      changes: true,
      ptsOffenseId: offenseID,
      ptsPersonId: party.ptsPersonId ? party.ptsPersonId : 0,
      ptsIncPersonId: party.ptsIncPersonId,
    },
  };
  incState.incArrestees.push(arrestee);

  const menuState = store.store.getState().uiMenu;
  menuState.handleAddIncArrestee = false;
};

export const addIncDVVictim = () => {
  const incState = store.store.getState().incident;
  let { offensesDVVictimForm, selectedVictim, incVictims, dvVictims } = incState;
  let isNew = true;
  setLinkedFormOffenseId();

  if (Object.keys(offensesDVVictimForm.values).length === 0) {
    return;
  }

  for (let i = 0; i < dvVictims.length; i++) {
    if (dvVictims[i].ptsIncPersonId === selectedVictim) {
      isNew = false;
      dvVictims[i].values = offensesDVVictimForm.values;
      break;
    }
  }

  if (isNew) {
    offensesDVVictimForm.ptsVictimId = incVictims.find(
      (x) => x.incVictimDetails.ptsIncPersonId === selectedVictim
    )?.incVictimDetails?.ptsVictimId;
    offensesDVVictimForm.ptsIncPersonId = selectedVictim;
    dvVictims.push(offensesDVVictimForm);
  }

  const menuState = store.store.getState().uiMenu;
  menuState.handleAddIncDVVictim = false;
};

export const addIncSuspects = (party) => {
  const incState = store.store.getState().incident;
  let { selectedVictim, incVictims } = incState;
  setLinkedFormOffenseId();

  if (incState.partiesPersonForm.values.partyType === 'Suspect') {
    let suspect = {
      incSuspectDetails: {
        ...initFormModel,
        changes: true,
        ptsPersonId: party.ptsPersonId ? party.ptsPersonId : 0,
        ptsIncPersonId: party.ptsIncPersonId,
        ptsVictimId: incVictims.find((x) => x.incVictimDetails.ptsIncPersonId === selectedVictim)
          ?.incVictimDetails?.ptsVictimId,
        ptsVictimPersonId: selectedVictim,
      },
    };

    incState.dvSuspects.push(suspect);
  }

  const menuState = store.store.getState().uiMenu;
  menuState.handleAddIncSuspect = false;
};

export const addIncDVAdditional = () => {
  const incState = store.store.getState().incident;
  let isNew = true;
  let { offensesDVAdditionalForm, selectedVictim, incVictims, dvAdditionals } = incState;
  setLinkedFormOffenseId();

  if (Object.keys(offensesDVAdditionalForm.values).length === 0) {
    return;
  }

  for (let i = 0; i < dvAdditionals.length; i++) {
    if (dvAdditionals[i].ptsIncPersonId === selectedVictim) {
      isNew = false;
      dvAdditionals[i].values = offensesDVAdditionalForm.values;
      break;
    }
  }

  if (isNew) {
    offensesDVAdditionalForm.ptsVictimId = incVictims.find(
      (x) => x.incVictimDetails.ptsIncPersonId === selectedVictim
    )?.incVictimDetails?.ptsVictimId;
    offensesDVAdditionalForm.ptsIncPersonId = selectedVictim;
    incState.dvAdditionals.push(offensesDVAdditionalForm);
  }

  const menuState = store.store.getState().uiMenu;
  menuState.handleAddIncDVAdditional = false;
};

export const addIncEvidenceCustody = (data) => {
  const incState = store.store.getState().incident;

  let custody = {
    incEvidenceCustodyDetails: {
      ...initFormModel,
      values: {
        ...data.values,
      },
      changes: true,
      ptsEvidenceId: incState.evidenceItemForm.ptsEvidenceId,
      tempEvidenceCustodyId: data.tempEvidenceCustodyId,
    },
  };
  incState.incEvidenceCustody.push(custody);

  const menuState = store.store.getState().uiMenu;
  menuState.handleAddIncEvidenceCustody = false;
};

export const addIncEvidenceStorage = (data) => {
  const incState = store.store.getState().incident;

  let storage = {
    incEvidenceStorageDetails: {
      ...initFormModel,
      values: {
        ...data.values,
      },
      changes: true,
      ptsEvidenceId: incState.evidenceItemForm.ptsEvidenceId,
      tempEvidenceStorageId: data.tempEvidenceStorageId,
    },
  };
  incState.incEvidenceStorage.push(storage);

  const menuState = store.store.getState().uiMenu;
  menuState.handleAddIncEvidenceStorage = false;
};

export const setActiveOffensePropertyForm = () => {
  const incState = store.store.getState().incident;
  incState.offensesPropertyForm = incState.incProperties.find(
    (x) => x.incPropertyDetails.tempPropId === incState.selectedProperty
  ).incPropertyDetails;
};

export const setLinkedFormOffenseId = () => {
  const incState = store.store.getState().incident;
  incState.offensesOffendersForm.tempOffenseId = incState.offensesOffenseForm.tempOffenseId;
  incState.offensesVictimForm.tempOffenseId = incState.offensesOffenseForm.tempOffenseId;
  incState.offensesPropertyForm.tempOffenseId = incState.offensesOffenseForm.tempOffenseId;
  incState.offensesRelationshipForm.tempOffenseId = incState.offensesOffenseForm.tempOffenseId;
  incState.offensesArresteesForm.tempOffenseId = incState.offensesOffenseForm.tempOffenseId;
  incState.offensesDVVictimForm.tempOffenseId = incState.offensesOffenseForm.tempOffenseId;
  incState.offensesDVSuspectForm.tempOffenseId = incState.offensesOffenseForm.tempOffenseId;
  incState.offensesDVAdditionalForm.tempOffenseId = incState.offensesOffenseForm.tempOffenseId;

  incState.offensesOffendersForm.ptsOffenseId = incState.offensesOffenseForm.ptsOffenseId;
  incState.offensesVictimForm.ptsOffenseId = incState.offensesOffenseForm.ptsOffenseId;
  incState.offensesPropertyForm.ptsOffenseId = incState.offensesOffenseForm.ptsOffenseId;
  incState.offensesRelationshipForm.ptsOffenseId = incState.offensesOffenseForm.ptsOffenseId;
  incState.offensesArresteesForm.ptsOffenseId = incState.offensesOffenseForm.ptsOffenseId;
  incState.offensesDVVictimForm.ptsOffenseId = incState.offensesOffenseForm.ptsOffenseId;
  incState.offensesDVSuspectForm.ptsOffenseId = incState.offensesOffenseForm.ptsOffenseId;
  incState.offensesDVAdditionalForm.ptsOffenseId = incState.offensesOffenseForm.ptsOffenseId;
};

export const initFormModel = {
  isValid: false,
  values: {},
  touched: {},
  errors: {},
};

export const resetAllForms = () => {
  const incState = store.store.getState().incident;
  incState.ptsIncidentId = 0;
  incState.addresses = [];
  incState.parties = [];
  incState.properties = [];
  incState.offenses = [];
  incState.evidences = [];
  incState.dispositions = [];
  incState.zones = [];
  incState.incSubjects = [];
  incState.incVictims = [];
  incState.incArrestees = [];
  incState.incRelationships = [];
  incState.incProperties = [];
  incState.incPropertyOwners = [];
  incState.incEvidenceCustody = [];
  incState.incEvidenceStorage = [];
  incState.incArrestees = [];
  incState.listSubjects = [];
  incState.listVictims = [];
  incState.listProperties = [];
  incState.listDialogSubjects = [];
  incState.listDialogVictims = [];
  incState.listDialogProperties = [];
  incState.listPropertyOwners = [];
  incState.listArrestees = [];
  incState.listDialogArrestees = [];
  incState.mpRelatedRecords = [];
  incState.selectedSubject = '';
  incState.selectedVictim = '';
  incState.selectedProperty = '';
  incState.selectedPropertyOwner = '';
  incState.selectedArrestee = '';
  incState.selectedEvidence = '';
  incState.selectedNarrative = '';
  incState.currentOffense = '';
  incState.incident = initFormModel;
  incState.addressForm = initFormModel;
  incState.addressIdForm = initFormModel;
  incState.coordinatesForm = initFormModel;
  incState.partiesPersonForm = initFormModel;
  incState.partiesContactForm = initFormModel;
  incState.partiesEmploymentForm = initFormModel;
  incState.partiesOthersForm = initFormModel;
  incState.partiesPlaceForm = initFormModel;
  incState.propertyItemForm = initFormModel;
  incState.propertyFirearmForm = initFormModel;
  incState.propertyJewelryForm = initFormModel;
  incState.propertyStructureForm = initFormModel;
  incState.propertySubstanceForm = initFormModel;
  incState.propertyVehicleDetailsForm = initFormModel;
  incState.propertyCredentialsAnalysisForm = initFormModel;
  incState.offensesOffenseForm = initFormModel;
  incState.offensesOffendersForm = initFormModel;
  incState.offensesVictimForm = initFormModel;
  incState.offensesDVVictimForm = initFormModel;
  incState.offensesDVSuspectForm = initFormModel;
  incState.offensesDVAdditionalForm = initFormModel;
  incState.offensesMPBasicForm = initFormModel;
  incState.offensesMPLocationForm = initFormModel;
  incState.offensesMPAdd1Form = initFormModel;
  incState.offensesMPAdd2Form = initFormModel;
  incState.offensesMPAdd3Form = initFormModel;
  incState.offensesRelationshipForm = initFormModel;
  incState.offensesPropertyForm = initFormModel;
  incState.offensesArresteesForm = initFormModel;
  incState.evidenceItemForm = initFormModel;
  incState.evidenceCustodyForm = initFormModel;
  incState.evidenceStorageForm = initFormModel;
  incState.evidenceDispositionForm = initFormModel;
  incState.narrativeForm = initFormModel;
  incState.zoneForm = initFormModel;
  incState.dispositionForm = initFormModel;
  incState.update = false;
  incState.validationErrors = [];
  incState.searchKeyword = {
    partiesKeyword: '',
    propertiesKeyword: '',
    offensesKeyword: '',
    evidencesKeyword: '',
    evidencesKeyword2: '',
    evidencesKeyword3: '',
  };
};

export const resetSubForms = (flag) => {
  const incState = store.store.getState().incident;
  if (flag === 'add' && incState.selectedEntity) {
    incState.selectedEntity.ptsOffenseId = null;
    incState.selectedEntity.ptsAddressId = null;
    incState.selectedEntity.ptsEvidenceId = null;
    incState.selectedEntity.incIncidentPropertyId = null;
    incState.selectedEntity.ptsNarrativeId = null;
  }
  incState.addressForm = initFormModel;
  incState.addressIdForm = initFormModel;
  incState.coordinatesForm = initFormModel;
  incState.partiesPersonForm = initFormModel;
  incState.partiesContactForm = initFormModel;
  incState.partiesEmploymentForm = initFormModel;
  incState.partiesOthersForm = initFormModel;
  incState.partiesPlaceForm = initFormModel;
  incState.propertyItemForm = initFormModel;
  incState.propertyFirearmForm = initFormModel;
  incState.propertyJewelryForm = initFormModel;
  incState.propertyStructureForm = initFormModel;
  incState.propertySubstanceForm = initFormModel;
  incState.propertyVehicleDetailsForm = initFormModel;
  incState.propertyCredentialsAnalysisForm = initFormModel;
  incState.offensesOffendersForm = initFormModel;
  incState.offensesVictimForm = initFormModel;
  incState.offensesDVVictimForm = initFormModel;
  incState.offensesDVSuspectForm = initFormModel;
  incState.offensesDVAdditionalForm = initFormModel;
  incState.offensesMPBasicForm = initFormModel;
  incState.offensesMPLocationForm = initFormModel;
  incState.offensesMPAdd1Form = initFormModel;
  incState.offensesMPAdd2Form = initFormModel;
  incState.offensesMPAdd3Form = initFormModel;
  incState.offensesRelationshipForm = initFormModel;
  incState.offensesPropertyForm = initFormModel;
  incState.offensesArresteesForm = initFormModel;
  incState.evidenceItemForm = initFormModel;
  incState.evidenceCustodyForm = initFormModel;
  incState.evidenceStorageForm = initFormModel;
  incState.evidenceDispositionForm = initFormModel;
  incState.narrativeForm = initFormModel;
  incState.zoneForm = initFormModel;
  incState.dispositionForm = initFormModel;
  incState.mpRelatedRecords = [];
};

export const resetOffenseLists = (entity) => {
  const incState = store.store.getState().incident;

  if (entity !== 'parties') {
    incState.incVictims = [];
    incState.incSubjects = [];
  }

  if (entity !== 'properties') {
    incState.incProperties = [];
  }

  incState.currentOffense = '';
  incState.incArrestees = [];
  incState.incRelationships = [];
  incState.incArrestees = [];
  incState.listSubjects = [];
  incState.listVictims = [];
  incState.listProperties = [];
  incState.listArrestees = [];
  incState.listDialogSubjects = [];
  incState.listDialogVictims = [];
  incState.listDialogProperties = [];
  incState.listDialogArrestees = [];
  incState.dvSuspects = [];
  incState.dvVictims = [];
  incState.dvAdditionals = [];
  incState.listSuspects = [];
  incState.listDialogSuspects = [];
  incState.selectedSubject = '';
  incState.selectedVictim = '';
  incState.selectedProperty = '';
  incState.selectedArrestee = '';
  incState.selectedSuspect = '';
};
/*endregion*/
export default function reducer(
  state = {
    activeIncidents: [],
    events: [],
    currentOffense: '',
    requiredError: false,
    ptsIncidentId: 0,
    addresses: [],
    parties: [],
    properties: [],
    offenses: [],
    evidences: [],
    narratives: [],
    dispositions: [],
    alerts: [],
    notifications: [],
    zones: [],
    incSubjects: [],
    incVictims: [],
    incRelationships: [],
    incProperties: [],
    incPropertyOwners: [],
    incArrestees: [],
    incEvidenceCustody: [],
    incEvidenceStorage: [],
    dvVictims: [],
    dvSuspects: [],
    listSuspects: [],
    dvAdditionals: [],
    listDialogSuspects: [],
    listSubjects: [],
    listDialogSubjects: [],
    listVictims: [],
    listDialogArrestees: [],
    listDialogVictims: [],
    listProperties: [],
    listDialogProperties: [],
    listPropertyOwners: [],
    listDialogEvidences: [],
    listArrestees: [],
    mpRelatedRecords: {},
    selectedSubject: '',
    selectedVictim: '',
    selectedProperty: '',
    selectedPropertyOwner: '',
    selectedArrestee: '',
    selectedSuspect: '',
    selectedEvidenceCustody: '',
    selectedEvidenceStorage: '',
    selectedEvidence: '',
    selectedNarrative: '',
    incident: initFormModel,
    addressForm: initFormModel,
    addressIdForm: initFormModel,
    coordinatesForm: initFormModel,
    partiesPersonForm: initFormModel,
    partiesContactForm: initFormModel,
    partiesEmploymentForm: initFormModel,
    partiesOthersForm: initFormModel,
    partiesPlaceForm: initFormModel,
    propertyItemForm: initFormModel,
    propertyFirearmForm: initFormModel,
    propertyJewelryForm: initFormModel,
    propertyStructureForm: initFormModel,
    propertySubstanceForm: initFormModel,
    propertyVehicleDetailsForm: initFormModel,
    propertyCredentialsAnalysisForm: initFormModel,
    offensesOffenseForm: initFormModel,
    offensesOffendersForm: initFormModel,
    offensesVictimForm: initFormModel,
    offensesDVVictimForm: initFormModel,
    offensesDVSuspectForm: initFormModel,
    offensesDVAdditionalForm: initFormModel,
    offensesMPBasicForm: initFormModel,
    offensesMPLocationForm: initFormModel,
    offensesMPAdd1Form: initFormModel,
    offensesMPAdd2Form: initFormModel,
    offensesMPAdd3Form: initFormModel,
    offensesRelationshipForm: initFormModel,
    offensesPropertyForm: initFormModel,
    offensesArresteesForm: initFormModel,
    evidenceItemForm: initFormModel,
    evidenceCustodyForm: initFormModel,
    evidenceCustodyFormNew: initFormModel,
    evidenceStorageForm: initFormModel,
    evidenceStorageFormNew: initFormModel,
    evidenceDispositionForm: initFormModel,
    narrativeForm: initFormModel,
    zoneForm: initFormModel,
    dispositionForm: initFormModel,
    update: false,
    selectedEntity: null,
    searchKeyword: {
      partiesKeyword: '',
      propertiesKeyword: '',
      offensesKeyword: '',
      evidencesKeyword: '',
      evidencesKeyword2: '',
      evidencesKeyword3: '',
    },
    offenseValidationErrors: [],
    validationErrors: [],
  },
  action
) {
  // eslint-disable-next-line default-case
  switch (action.type) {
    case SET_VALIDATION_ERRORS:
      return { ...state, validationErrors: action.payload };
    case SET_OFFENSE_VALIDATION_ERRORS:
      return { ...state, offenseValidationErrors: action.payload };
    case SET_INCIDENT:
      return { ...state, incident: action.payload };
    case SET_INC_ADDRESS:
      return { ...state, addressForm: action.payload };
    case SET_INC_ADDRESS_ID:
      return { ...state, addressIdForm: action.payload };
    case SET_INC_COORDINATES:
      return { ...state, coordinatesForm: action.payload };
    case SET_INC_PARTIES_PERSON:
      return { ...state, partiesPersonForm: action.payload };
    case SET_INC_PARTIES_CONTACT:
      return { ...state, partiesContactForm: action.payload };
    case SET_INC_PARTIES_EMPLOYMENT:
      return { ...state, partiesEmploymentForm: action.payload };
    case SET_INC_PARTIES_OTHERS:
      return { ...state, partiesOthersForm: action.payload };
    case SET_INC_PARTIES_PLACE:
      return { ...state, partiesPlaceForm: action.payload };
    case SET_INC_PROPERTY_ITEM:
      return { ...state, propertyItemForm: action.payload };
    case SET_INC_PROPERTY_FIREARM:
      return { ...state, propertyFirearmForm: action.payload };
    case SET_INC_PROPERTY_JEWELRY:
      return { ...state, propertyJewelryForm: action.payload };
    case SET_INC_PROPERTY_STRUCTURE:
      return { ...state, propertyStructureForm: action.payload };
    case SET_INC_PROPERTY_SUBSTANCE:
      return { ...state, propertySubstanceForm: action.payload };
    case SET_INC_PROPERTY_VEHICLE_DETAILS:
      return { ...state, propertyVehicleDetailsForm: action.payload };
    case SET_INC_PROPERTY_CREDENTIALS_ANALYSIS:
      return { ...state, propertyCredentialsAnalysisForm: action.payload };
    case SET_INC_OFFENSES_OFFENSE:
      return { ...state, offensesOffenseForm: action.payload };
    case SET_INC_OFFENSES_DV_VICTIM:
      return {
        ...state,
        offensesDVVictimForm: { ...state.offensesDVVictimForm, ...action.payload },
      };
    case SET_INC_DV_VICTIM_LIST:
      return { ...state, dvVictims: action.payload };
    case SET_INC_DV_SUSPECT_LIST:
      return { ...state, dvSuspects: action.payload };
    case SET_INC_DV_ADDITIONAL_LIST:
      return { ...state, dvAdditionals: action.payload };
    case SET_INC_OFFENSES_DV_VICTIM_SUBFORMS:
      return {
        ...state,
        offensesDVVictimForm: { ...state.offensesDVVictimForm, ...action.payload },
      };
    case SET_INC_OFFENSES_DV_SUSPECT:
      return {
        ...state,
        offensesDVSuspectForm: { ...state.offensesDVSuspectForm, ...action.payload },
      };
    case SET_INC_OFFENSES_DV_SUSPECT_SUBFORMS:
      return {
        ...state,
        offensesDVSuspectForm: { ...state.offensesDVSuspectForm, ...action.payload },
      };
    case SET_INC_OFFENSES_DV_ADDITIONAL:
      return {
        ...state,
        offensesDVAdditionalForm: { ...state.offensesDVAdditionalForm, ...action.payload },
      };
    case SET_INC_OFFENSES_MP_BASIC:
      return { ...state, offensesMPBasicForm: action.payload };
    case SET_INC_MP_RELATED_RECORDS:
      return { ...state, mpRelatedRecords: action.payload };
    case SET_INC_OFFENSES_RELATIONSHIP:
      return { ...state, offensesRelationshipForm: action.payload };
    case SET_INC_OFFENSES_PROPERTY_OWNER:
      return {
        ...state,
        offensesPropertyForm: { ...state.offensesPropertyForm, ...action.payload },
      };
    case SET_INC_EVIDENCE_ITEM:
      return { ...state, evidenceItemForm: action.payload };
    case SET_INC_EVIDENCE_CUSTODY:
      return { ...state, evidenceCustodyForm: action.payload };
    case SET_INC_EVIDENCE_CUSTODY_NEW:
      return { ...state, evidenceCustodyFormNew: action.payload };
    case SET_INC_EVIDENCE_STORAGE:
      return { ...state, evidenceStorageForm: action.payload };
    case SET_INC_EVIDENCE_STORAGE_NEW:
      return { ...state, evidenceStorageFormNew: action.payload };
    case SET_INC_EVIDENCE_DISPOSITION:
      return { ...state, evidenceDispositionForm: action.payload };
    case SET_INC_NARRATIVE:
      return { ...state, narrativeForm: action.payload };
    case ADD_ADDRESSES:
      return { ...state, addresses: action.payload };
    case ADD_PARTIES:
      return { ...state, parties: action.payload };
    case ADD_OFFENSES:
      return { ...state, offenses: action.payload };
    case ADD_PROPERTIES:
      return { ...state, properties: action.payload };
    case SET_LIST_SUBJECTS:
      return { ...state, listSubjects: action.payload };
    case SET_DIALOG_LIST_SUBJECTS:
      return { ...state, listDialogSubjects: action.payload };
    case SET_LIST_VICTIMS:
      return { ...state, listVictims: action.payload };
    case SET_DIALOG_LIST_VICTIMS:
      return { ...state, listDialogVictims: action.payload };
    case SET_LIST_SUSPECTS:
      return { ...state, listSuspects: action.payload };
    case SET_DIALOG_LIST_SUSPECTS:
      return { ...state, listDialogSuspects: action.payload };
    case SET_LIST_PROPERTIES:
      return { ...state, listProperties: action.payload };
    case SET_DIALOG_LIST_PROPERTIES:
      return { ...state, listDialogProperties: action.payload };
    case SET_DIALOG_LIST_ARRESTEES:
      return { ...state, listDialogArrestees: action.payload };
    case SET_LIST_PROP_OWNERS:
      return { ...state, listPropertyOwners: action.payload };
    case SET_LIST_ARRESTEES:
      return { ...state, listArrestees: action.payload };
    case SET_DIALOG_LIST_EVIDENCES:
      return { ...state, listDialogEvidences: action.payload };
    case SET_LIST_EVIDENCE_STORAGE:
      return { ...state, incEvidenceStorage: action.payload };
    case SET_LIST_EVIDENCE_CUSTODY:
      return { ...state, incEvidenceCustody: action.payload };
    case SET_SELECTED_SUBJECT:
      return { ...state, selectedSubject: action.payload };
    case SET_SELECTED_VICTIM:
      return { ...state, selectedVictim: action.payload };
    case SET_SELECTED_SUSPECT:
      return { ...state, selectedSuspect: action.payload };
    case SET_SELECTED_PROPERTY:
      return { ...state, selectedProperty: action.payload };
    case SET_SELECTED_EVIDENCE_CUSTODY:
      return { ...state, selectedEvidenceCustody: action.payload };
    case SET_SELECTED_EVIDENCE_STORAGE:
      return { ...state, selectedEvidenceStorage: action.payload };
    case SET_SELECTED_PROP_OWNER:
      return { ...state, selectedPropertyOwner: action.payload };
    case SET_SELECTED_ARRESTEE:
      return { ...state, selectedArrestee: action.payload };
    case SET_SELECTED_EVIDENCE:
      return { ...state, selectedEvidence: action.payload };
    case SET_SELECTED_NARRATIVE:
      return { ...state, selectedNarrative: action.payload };
    case GET_ACTIVE_INCIDENTS:
      return { ...state, activeIncidents: action.payload };
    case GET_PARTIES:
      return { ...state, parties: action.payload };
    case GET_ADDRESSESS:
      return { ...state, addresses: action.payload };
    case GET_OFFENSES:
      return { ...state, offenses: action.payload };
    case GET_PROPERTIES:
      return { ...state, properties: action.payload };
    case GET_EVIDENCES:
      return { ...state, evidences: action.payload };
    case GET_NARRATIVES:
      return { ...state, narratives: action.payload };
    case SET_SELECTED_INCIDENT:
      return { ...state, ptsIncidentId: action.payload };
    case SET_SELECTED_ENTITY:
      return { ...state, selectedEntity: action.payload };
    case SET_INC_SUBJECTS:
      return { ...state, incSubjects: action.payload };
    case SET_INC_VICTIMS:
      return { ...state, incVictims: action.payload };
    case SET_INC_SUSPECTS:
      return { ...state, dvSuspects: action.payload };
    case SET_INC_PROPERTIES:
      return { ...state, incProperties: action.payload };
    case SET_INC_ARRESTEES:
      return { ...state, incArrestees: action.payload };
    case SET_INC_RELATIONSHIPS:
      return { ...state, incRelationships: action.payload };
    case SET_CURRENT_OFFENSE:
      return { ...state, currentOffense: action.payload };
    case SET_SEARCH_KEYWORDS:
      return { ...state, searchKeyword: action.payload };
    case GET_DISPOSITIONS:
      return { ...state, dispositions: action.payload };
    case GET_ZONES:
      return { ...state, zones: action.payload };
    case GET_NOTIFICATIONS:
      return { ...state, notifications: action.payload };
    case GET_ALERTS:
      return { ...state, alerts: action.payload };
    case SET_INC_ZONE_FORM:
      return { ...state, zoneForm: action.payload };
    case SET_INC_DISPOSITION_FORM:
      return { ...state, dispositionForm: action.payload };
    case GET_EVENTS:
      return { ...state, events: action.payload };
    case SET_EVENT:
      return { ...state, event: action.payload };
  }
  return state;
}
