import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import IncOffensesList from '../../RMSList/RMSIncidentList/IncOffensesList';
import { connect } from 'react-redux';
import { setIncOffensesTab } from '../../../reducers/ui/UiFormReducer';
import { setOffensesTab } from '../../../reducers/ui/UiFormReducer';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  formFields: {
    width: '100%',
    height: '100%',
  },
  lists: {
    marginTop: '20px',
  },
}));

const IncOffensesStep = (props) => {
  const classes = useStyles();
  const { selectedTab, setIncOffensesTab, setOffensesTab } = props;

  const handleChange = (event, newValue) => {
    setIncOffensesTab(newValue);
  };

  useEffect(() => {
    setOffensesTab(0);
  }, []);

  return (
    <Grid container className={classes.root}>
      <Grid item xs={12} md={12} lg={12}>
        <IncOffensesList />
      </Grid>
    </Grid>
  );
};

const mapStateToProps = (state) => ({
  selectedTab: state.uiForm.incOffensesTabState,
});

export default connect(mapStateToProps, { setIncOffensesTab, setOffensesTab })(IncOffensesStep);
