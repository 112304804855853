import React from 'react';
import RMSAddressForm from '../../components/RMSForms/RMSAddressForm';

const TabAddress = (props) => {
  const { changeOptions, addressOptions, selectedAddress, changeSelectedOption } = props;

  return (
    <RMSAddressForm
      addressOptions={addressOptions}
      changeOptions={changeOptions}
      changeSelectedOption={changeSelectedOption}
      selectedAddress={selectedAddress}
    />
  );
};

export default TabAddress;
