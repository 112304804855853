/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { connect } from 'react-redux';

import { v4 as uuidv4 } from 'uuid';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { Divider } from '@material-ui/core';

import { updateIncPropertyItemForm, updateIncEvidenceItemForm } from '../../../reducers/IncReducer';
import {
  getCodeItemModels,
  getCodeItemConditions,
  getCodeItemMakes,
} from '../../../reducers/dictionaries/CodeItemReducer';
import { handleFormChangeNew } from '../../../reducers/helpers/formHelpers';
import { checkFormType } from '../../helpers/checkFormState';
import RMSComboBox from 'components/RMSComboBox';
import RMSComboBoxListItem from 'components/RMSComboBoxListItem';
import RMSAttachments from 'components/RMSAttachments';
import { validatePropertiesForm } from 'simpl-schema-validation/ValidateForms';

const TabItem = (props) => {
  /** region Component Variables and Props */
  const {
    propertyItemForm,
    updateIncPropertyItemForm,
    updateIncEvidenceItemForm,
    getCodeItemModels,
    getCodeItemConditions,
    getCodeItemMakes,
    codeItemConditions,
    codeItemMakes,
    codeItemModels,
    wsClient,
    history,
  } = props;
  const [errors, setErrors] = React.useState({});
  const [changeKey, setChangeKey] = React.useState(0);
  const [pageType, setPageType] = React.useState('add');
  const [entityType, setEntityType] = React.useState('');
  const [propertyItemFormState, setPropertyItemFormState] = React.useState({
    tempPropId: uuidv4(),
    isValid: false,
    changes: false,
    values: {},
    touched: {},
    errors: {},
    formType: 'edit',
  });

  /** endregion */
  /** region React Hooks */
  React.useEffect(() => {
    checkFormType(
      propertyItemFormState.ptsItemId !== undefined && propertyItemFormState.ptsItemId !== null,
      setPropertyItemFormState,
      history
    );
    const parts = history.location.pathname.split('/');
    let type = '';
    if (parts.length === 4) type = parts[parts.length - 2];
    if (parts.length === 5) type = parts[parts.length - 3];

    setEntityType(parts[1]);
    setPageType(type);
  }, []);

  React.useEffect(() => {
    if (wsClient.websocket) {
      getCodeItemModels();
      getCodeItemConditions();
      getCodeItemMakes();
    }
  }, [getCodeItemMakes, getCodeItemModels, getCodeItemConditions, wsClient]);

  React.useEffect(() => {
    setPropertyItemFormState((propertyItemFormState) => ({
      ...propertyItemFormState,
      ...propertyItemForm,
      values: {
        ...propertyItemFormState.values,
        ...propertyItemForm.values,
      },
    }));
  }, [propertyItemForm]);

  React.useEffect(() => {
    propertyItemFormState.changes = true;
    updateIncPropertyItemForm(propertyItemFormState);
    let errorStatus = {};
    let nibrErrorQuantity = validatePropertiesForm('quantity', propertyItemFormState);

    if (nibrErrorQuantity && nibrErrorQuantity.length) {
      errorStatus = {
        ...errorStatus,
        quantity: `${nibrErrorQuantity[0].message}`,
      };
    } else {
      errorStatus = {
        ...errorStatus,
        quantity: null,
      };
    }

    setErrors(errorStatus);

    if (propertyItemFormState.values.isEvidence) {
      updateIncEvidenceItemForm({
        values: {
          make: propertyItemFormState.values.make,
          model: propertyItemFormState.values.model,
          condition: propertyItemFormState.values.condition,
          serialNumber: propertyItemFormState.values.serialNumber,
          category: 'Property',
          description: `${propertyItemFormState.values?.name || ''} ${
            propertyItemFormState.values?.description || ''
          } ${propertyItemFormState.values?.make || ''}`,
          incIncidentPropertyId: propertyItemFormState.incIncidentPropertyId,
          offenses: [],
        },
      });
    }
  }, [
    propertyItemFormState.values?.incidentId,
    propertyItemFormState.values?.itemId,
    propertyItemFormState.values?.name,
    propertyItemFormState.values?.description,
    propertyItemFormState.values?.condition,
    propertyItemFormState.values?.make,
    propertyItemFormState.values?.model,
    propertyItemFormState.values?.serialNumber,
    propertyItemFormState.values?.isEvidence,
    propertyItemFormState.values?.attachments,
    propertyItemFormState.values?.quantity,
  ]);

  React.useEffect(() => {
    if (!wsClient?.websocket) {
      return;
    }

    const getAttachments = async () => {
      const service = wsClient?.websocket.service('rms-attachments');
      service.timeout = 200000;

      const result = await service.find({
        query: {
          id:
            entityType === 'records'
              ? propertyItemFormState.ptsItemId
              : propertyItemFormState.incIncidentPropertyId,
          type: entityType === 'records' ? 'ITEM' : 'INCIDENTPROPERTY',
        },
      });
      let atts = [];
      result.map((r) => {
        atts.push({
          ptsAttachmentId: r.ptsAttachmentID,
          name: r.FileName,
          type: r.FileType,
          dataURL: r.FileObject,
        });
      });
      setPropertyItemFormState((stateValue) => ({
        ...stateValue,
        values: {
          ...stateValue.values,
          attachments: atts,
        },
      }));
    };

    if (propertyItemFormState.ptsItemId) {
      getAttachments();
    }
  }, [wsClient, propertyItemFormState.ptsItemId]);
  /** endregion */
  return (
    <Grid item xs={12} lg={12}>
      {pageType === 'add' && entityType !== 'records' && (
        <Grid item xs={12} lg={12} className="">
          <FormControlLabel
            control={
              <Checkbox
                key={propertyItemFormState.values.isEvidence}
                checked={propertyItemFormState.values.isEvidence}
                onChange={(event) =>
                  handleFormChangeNew(
                    event,
                    event.target.checked,
                    'none',
                    'isEvidence',
                    setPropertyItemFormState
                  )
                }
                name="isEvidence"
              />
            }
            label="Is Evidence?"
          />
        </Grid>
      )}

      <Grid item xs={12} lg={12} className="mb-3 mt-3">
        <TextField
          id="standard-textarea-item-name"
          autoComplete="hidden"
          size="small"
          placeholder="Name"
          label="Name"
          variant="outlined"
          name="name"
          key={changeKey}
          defaultValue={propertyItemFormState.values?.name || ''}
          onBlur={(event) =>
            handleFormChangeNew(event, event.target.value, 'none', 'name', setPropertyItemFormState)
          }
          fullWidth
          multiline
          InputLabelProps={{ shrink: true }}
        />
      </Grid>
      <Grid item xs={12} lg={12} className="mb-3">
        <TextField
          id="standard-textarea-item-description"
          autoComplete="hidden"
          size="small"
          label="Description"
          placeholder="Description"
          variant="outlined"
          name="description"
          defaultValue={propertyItemFormState.values?.description || ''}
          onBlur={(event) =>
            handleFormChangeNew(
              event,
              event.target.value,
              'none',
              'description',
              setPropertyItemFormState
            )
          }
          fullWidth
          multiline
          InputLabelProps={{ shrink: true }}
        />
      </Grid>
      <Grid xs={12} className="mt-2 mb-3">
        <span style={{ color: '#fff', background: '#1976d2' }} className="mb-1 badge badge-primary">
          item Details
        </span>
        <Divider />
      </Grid>
      <Grid container spacing={2} className="mb-1">
        <Grid item xs={3} lg={3}>
          <TextField
            id="standard-textarea-item-serialNumber"
            autoComplete="hidden"
            size="small"
            label="Serial Number"
            placeholder="Serial Number"
            variant="outlined"
            name="serialNumber"
            defaultValue={propertyItemFormState.values?.serialNumber || ''}
            onBlur={(event) =>
              handleFormChangeNew(
                event,
                event.target.value,
                'none',
                'serialNumber',
                setPropertyItemFormState
              )
            }
            fullWidth
            multiline
            InputLabelProps={{ shrink: true }}
          />
        </Grid>
        <Grid item xs={3} lg={3}>
          <Autocomplete
            size="small"
            autoComplete
            autoSelect
            autoHighlight
            id="combo-item-condition"
            getOptionLabel={(option) => option.Code || ''}
            options={codeItemConditions}
            key={propertyItemFormState.values?.condition || ''}
            value={{ Code: propertyItemFormState.values?.condition } || ''}
            onChange={(event, newValue) =>
              handleFormChangeNew(
                event,
                newValue?.Code,
                'none',
                'condition',
                setPropertyItemFormState
              )
            }
            renderOption={(option) => {
              return (
                <RMSComboBoxListItem
                  option={option}
                  title="Code"
                  serviceName="codeitemconditions"
                  label="Condition"
                  resetField={() =>
                    handleFormChangeNew(null, null, 'none', 'condition', setPropertyItemFormState)
                  }
                />
              );
            }}
            renderInput={(params) => (
              <RMSComboBox
                {...params}
                setField={(newVal) => {
                  handleFormChangeNew(
                    null,
                    newVal['Code'],
                    'none',
                    'condition',
                    setPropertyItemFormState
                  );
                }}
                serviceName="codeitemconditions"
                label="Condition"
                variant="outlined"
                fullWidth
              />
            )}
          />
        </Grid>
        <Grid item xs={3} lg={3}>
          <Autocomplete
            size="small"
            autoComplete
            autoSelect
            autoHighlight
            id="combo-item-make"
            getOptionLabel={(option) => option.Code || ''}
            options={codeItemMakes}
            key={propertyItemFormState.values?.make || ''}
            value={{ Code: propertyItemFormState.values?.make } || ''}
            onChange={(event, newValue) =>
              handleFormChangeNew(event, newValue?.Code, 'none', 'make', setPropertyItemFormState)
            }
            renderOption={(option) => {
              return (
                <RMSComboBoxListItem
                  option={option}
                  title="Code"
                  serviceName="codeitemmakes"
                  label="Make"
                  resetField={() =>
                    handleFormChangeNew(null, null, 'none', 'make', setPropertyItemFormState)
                  }
                />
              );
            }}
            renderInput={(params) => (
              <RMSComboBox
                {...params}
                setField={(newVal) => {
                  handleFormChangeNew(
                    null,
                    newVal['Code'],
                    'none',
                    'make',
                    setPropertyItemFormState
                  );
                }}
                serviceName="codeitemmakes"
                label="Make"
                variant="outlined"
                fullWidth
              />
            )}
          />
        </Grid>
        <Grid item xs={3} lg={3}>
          <Autocomplete
            size="small"
            autoComplete
            autoSelect
            autoHighlight
            id="combo-item-model"
            getOptionLabel={(option) => option.Code || ''}
            options={codeItemModels}
            key={propertyItemFormState.values?.model || ''}
            value={{ Code: propertyItemFormState.values?.model } || ''}
            onChange={(event, newValue) =>
              handleFormChangeNew(event, newValue?.Code, 'none', 'model', setPropertyItemFormState)
            }
            renderOption={(option) => {
              return (
                <RMSComboBoxListItem
                  option={option}
                  title="Code"
                  serviceName="codeitemmodels"
                  label="Model"
                  resetField={() =>
                    handleFormChangeNew(null, null, 'none', 'model', setPropertyItemFormState)
                  }
                />
              );
            }}
            renderInput={(params) => (
              <RMSComboBox
                {...params}
                setField={(newVal) => {
                  handleFormChangeNew(
                    null,
                    newVal['Code'],
                    'none',
                    'model',
                    setPropertyItemFormState
                  );
                }}
                serviceName="codeitemmodels"
                label="Model"
                variant="outlined"
                fullWidth
              />
            )}
          />
        </Grid>

        <Grid item xs={3} lg={3}>
          <TextField
            id="standard-textarea-item-quantity"
            autoComplete="hidden"
            size="small"
            label="Quantity"
            placeholder="Quantity"
            variant="outlined"
            name="quantity"
            defaultValue={propertyItemFormState.values?.quantity || ''}
            onBlur={(event) =>
              handleFormChangeNew(
                event,
                event.target.value,
                'none',
                'quantity',
                setPropertyItemFormState
              )
            }
            fullWidth
            multiline
            error={errors['quantity'] ? true : false}
            helperText={errors['quantity']}
            InputLabelProps={{ shrink: true }}
          />
        </Grid>
      </Grid>
      <RMSAttachments
        attachments={propertyItemFormState.values.attachments}
        stateHolder={propertyItemFormState}
        setStateHolder={setPropertyItemFormState}
        setField={handleFormChangeNew}
      />
    </Grid>
  );
};

const mapStateToProps = (state) => ({
  propertyItemForm: state.incident.propertyItemForm,
  wsClient: state.websocket,
  codeItemConditions: state.codeItem.codeItemConditions,
  codeItemMakes: state.codeItem.codeItemMakes,
  codeItemModels: state.codeItem.codeItemModels,
});

export default connect(mapStateToProps, {
  updateIncPropertyItemForm,
  getCodeItemModels,
  getCodeItemMakes,
  getCodeItemConditions,
  updateIncEvidenceItemForm,
})(TabItem);
