import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import React from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import { makeStyles } from '@material-ui/core/styles';
import TableCell from '@material-ui/core/TableCell';
import { Checkbox } from '@material-ui/core';

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.info.dark,
    color: theme.palette.common.white,
    '&:last-child': { paddingLeft: '160px' },
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const useStyles = makeStyles((theme) => ({
  root: {
    '&:hover': {
      color: '#FFEDC2 !important',
    },
  },
  active: {
    color: '#FFEDC2 !important',
  },
  icon: {
    color: '#FFEDC2 !important',
    '&:hover': {
      color: '#FFEDC2 !important',
    },
  },
}));

const headCells = [
  { id: 'name', numeric: false, disablePadding: false, label: 'Event', sortable: true },
  { id: 'status', numeric: false, disablePadding: false, label: 'Status' },
  { id: 'agencyId', numeric: false, disablePadding: false, label: 'Agency ID', sortable: true },
  { id: 'eventType', numeric: false, disablePadding: false, label: 'Event Type', sortable: true },
  { id: 'address', numeric: false, disablePadding: false, label: 'Full Address', sortable: true },
  {
    id: 'disposition',
    numeric: false,
    disablePadding: false,
    label: 'Disposition',
    sortable: true,
  },
  { id: 'created', numeric: false, disablePadding: false, label: 'Created', sortable: true },
  {
    id: 'officer',
    numeric: false,
    disablePadding: false,
    label: 'Assigned Officer',
    sortable: true,
  },
  { id: 'action', numeric: false, disablePadding: false, label: '' },
];

function EnhancedTableHead(props) {
  const { order, orderBy, onRequestSort, numSelected, onSelectAllClick, rowCount } = props;
  const classes = useStyles();
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        <StyledTableCell padding="checkbox">
          <Checkbox
            style={{ color: 'white' }}
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{
              'aria-label': 'select all events',
            }}
          />
        </StyledTableCell>
        {headCells.map((headCell) => (
          <StyledTableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'default'}
            sortDirection={orderBy === headCell.id ? order : false}>
            {headCell.sortable && (
              <TableSortLabel
                classes={{
                  root: classes.root,
                  active: classes.active,
                  icon: classes.icon,
                }}
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : 'asc'}
                onClick={createSortHandler(headCell.id)}>
                {headCell.label}
              </TableSortLabel>
            )}

            {!headCell.sortable && headCell.label}
          </StyledTableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

export default EnhancedTableHead;
