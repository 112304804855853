import store from '../../config/configureStore';
import { incSubjectVictimRelationshipSchema } from 'simpl-schema-validation/schema';

export const validateIncSubjectVictimForm = (victim, subject, relationship, allRelations) => {
  const currentState = store.store.getState().incident;
  const incVictims = currentState.incVictims;
  const { parties, offensesOffenseForm } = currentState;
  let victimOffenders = [];
  let victimSpouses = [];

  allRelations.forEach((relation) => {
    if (relation.relationship?.victimId === victim.id) {
      victimOffenders.push(relation.relationship?.offenderId);
      if (relation.relationship?.relationship === 'NIBRS CODE: SE | Victim Was Spouse') {
        victimSpouses.push(relation.relationship?.offenderId);
      }
    }
  });

  let offenderDetails = parties.find((p) => p.ptsIncPersonId === subject.id);

  let incVictimPerson = incVictims.find(
    (incVictim) => incVictim.incVictimDetails.ptsIncPersonId === victim.id
  );
  victim = {
    ...victim,
    category: incVictimPerson.incVictimDetails.values.category,
  };
  let objToValidate = {
    victim,
    subject,
    relationship,
    incident: currentState.incident,
    offenderDetails: offenderDetails.personDetail.values,
    offense: offensesOffenseForm,
    victimOffenders,
    victimSpouses,
  };

  let incSubjectVictimRelationshipValidationContext = incSubjectVictimRelationshipSchema.newContext();
  let result = incSubjectVictimRelationshipValidationContext.validate(objToValidate, {
    keys: ['relationship', 'victimOffenders', 'victimSpouses'],
  });

  let errors = [];
  if (!result) {
    incSubjectVictimRelationshipValidationContext.validationErrors().forEach((error) => {
      error.message = incSubjectVictimRelationshipValidationContext.keyErrorMessage(error.name);
      errors.push(error);
    });
  }

  return errors || [];
};
