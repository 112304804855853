import React, { Fragment } from 'react';
import TableCell from '@material-ui/core/TableCell';
import Avatar from '@material-ui/core/Avatar';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { colors } from '../../../../config/colorVariables';

const useStyles = makeStyles((theme) => ({
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: colors.cyan,
    marginRight: theme.spacing(2),
  },
  incidentLabel: {
    padding: '3px 0px',
  },
}));

const RMSCustodyCell = (props) => {
  const classes = useStyles();
  const { row } = props;

  return (
    <Fragment key={row.id}>
      <TableCell key={row.Status}>
        <div className="d-flex align-items-center">
          <Avatar aria-label="avatar" className={classes.avatar}>
            {(row.Status ? row.Status : 'N').substring(0, 1)}
          </Avatar>
          <b>{row.Status}</b>
        </div>
      </TableCell>
      <TableCell key={row.reason}>{row.reason}</TableCell>
      <TableCell key={row.from}>{row.from}</TableCell>
      <TableCell key={row.to}>{row.to}</TableCell>
      <TableCell key={row.requiredAction}>{row.requiredAction}</TableCell>
    </Fragment>
  );
};

export default RMSCustodyCell;
