/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { useParams } from 'react-router';

import { v4 as uuidv4 } from 'uuid';
import SimpleSchema from 'simpl-schema';
import Grid from '@material-ui/core/Grid';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import Divider from '@material-ui/core/Divider';
import FormControl from '@material-ui/core/FormControl';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';

import AddressSearch from './AddressSearch';
import RMSAutoComplete from 'components/RMSAutoComplete/RMSAutoComplete';
import {
  getCityIdFromCityName,
  initFormModel,
  updateIncAddressForm,
  updateIncAddressIdentifiersForm,
  updateIncCoordinatesForm,
} from '../../../reducers/IncReducer';
import { getCodeStreetType } from '../../../reducers/dictionaries/CodeStreetTypesReducer';
import { getCodeStreetDirections } from '../../../reducers/dictionaries/CodeStreetDirectionsReducer';
import { getCodeStates } from '../../../reducers/dictionaries/CodeStatesReducer';
import { getCodeCities } from '../../../reducers/dictionaries/CodeOthersReducer';
import { getCodeAddressPartyRelationship } from '../../../reducers/dictionaries/CodeAddressPartyRelationshipReducer';
import {
  clearPlace,
  handleFormChangeNew,
  onPlaceValueChange,
  setFullAddressText,
} from '../../../reducers/helpers/formHelpers';
import { setCircularLoading } from 'reducers/ui/UiMenuReducer';
import {
  setDuplicateModal,
  setDuplicateModalMessage,
  setDuplicatAddressList,
} from 'reducers/ui/UiModalReducer';
import { duplicateCheck } from 'reducers/helpers/duplicateCheckHelpers';
import RMSAttachments from 'components/RMSAttachments';

const validationContext = new SimpleSchema({
  addressNumber: SimpleSchema.Integer,
}).newContext();

const RMSAddressForm = (props) => {
  /** region Component Variables and Props */
  const {
    hasNotes = true,
    addressForm,
    updateIncAddressForm,
    updateIncCoordinatesForm,
    updateIncAddressIdentifiersForm,
    codeStreetTypes,
    codeStreetDirections,
    codeStates,
    codeCities,
    getCodeStreetType,
    getCodeStreetDirections,
    getCodeStates,
    getCodeCities,
    getCodeAddressPartyRelationship,
    recordActive,
    wsClient,
    changeOptions,
    addressOptions,
    selectedAddress,
    changeSelectedOption,
    setDuplicateModal,
    setDuplicatAddressList,
  } = props;
  const [addressFormState, setAddressFormState] = React.useState({
    missingPersonForm: !hasNotes,
    tempAddressId: uuidv4(),
    isValid: false,
    values: {},
    touched: {},
    errors: {},
    changed: false,
  });
  const history = useHistory();
  const dispatch = useDispatch();
  let { addressId } = useParams();
  const [changeKey, setChangeKey] = React.useState(0);
  const [firstLoad, setFirstLoad] = React.useState(true);
  const [errors, setErrors] = React.useState({});
  const [pageType, setPageType] = React.useState('add');
  const [entityType, setEntityType] = React.useState('');
  const [doesFetchCoordinate, setDoesFetchCoordinate] = useState(false);
  /** endregion */
  /** region React Hooks */
  React.useEffect(() => {
    const parts = history.location.pathname.split('/');
    setEntityType(parts[1]);
    setPageType(parts[2]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    if (!wsClient.websocket) return;
    const parts = history.location.pathname.split('/');
    setEntityType(parts[1]);
    setPageType(parts[2]);

    if (
      !addressForm.ptsAddressID &&
      !addressForm.ptsAddressId &&
      parts[1] === 'records' &&
      parts[2] === 'edit'
    ) {
      fetchAddressData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addressId, addressForm, wsClient]);

  React.useEffect(() => {
    if (!wsClient.websocket) return;

    const services = [
      'codeStreetTypes',
      'codeStreetDirections',
      'codeStates',
      'codeAddressPartyRelationship',
    ];
    getCodeCities();

    services.forEach(async (svc) => {
      let $select = ['Code'];

      if (props[svc].length < 1) {
        const result = await wsClient.websocket.service(svc.toLowerCase()).find({
          query: {
            $limit: 0,
            $select,
            $sort: {
              Description: 1,
            },
            IsDeleted: false,
          },
        });

        if (result.total > 0) {
          if (svc === 'codeStreetTypes') {
            getCodeStreetType(result.data);
          } else if (svc === 'codeStreetDirections') {
            getCodeStreetDirections(result.data);
          } else if (svc === 'codeStates') {
            getCodeStates(result.data);
          } else if (svc === 'codeAddressPartyRelationship') {
            getCodeAddressPartyRelationship(result.data);
          }
        }
      }
    });
  }, [wsClient]);

  React.useEffect(() => {
    if (!wsClient?.websocket) {
      return;
    }

    const getAttachments = async () => {
      const service = wsClient?.websocket.service('rms-attachments');
      service.timeout = 200000;

      const result = await service.find({
        query: {
          id: addressFormState.ptsAddressId,
          type: 'ADDRESS',
        },
      });
      let atts = [];
      result.map((r) => {
        atts.push({
          ptsAttachmentId: r.ptsAttachmentID,
          name: r.FileName,
          type: r.FileType,
          dataURL: r.FileObject,
        });
      });
      setAddressFormState((stateValue) => ({
        ...stateValue,
        values: {
          ...stateValue.values,
          attachments: atts,
        },
      }));
    };

    if (addressFormState.ptsAddressId && entityType === 'records') {
      getAttachments();
    }
  }, [wsClient, addressFormState.ptsAddressId]);

  React.useEffect(() => {
    const newState = {
      ...addressForm,
      changed: true,
      missingPersonForm: !hasNotes, // For Missing Person, there is a props hasNotes = false. So this props indicates that this address is for MP. It wont create address for incident.
      values: {
        ...addressForm.values,
      },
    };

    setAddressFormState(newState);
  }, [addressForm]);

  const duplicateFinder = async () => {
    let duplicateFound = !addressFormState?.ptsAddressId
      ? await duplicateCheck(addressFormState, 'address')
      : false;
    if (duplicateFound) {
      setDuplicateModal(true);
      setDuplicateModalMessage('Duplciate Found');
    } else {
      setDuplicatAddressList([]);
      setDuplicateModal(false);
    }
  };

  React.useEffect(() => {
    if (typeof addressFormState.values?.city === 'string') {
      addressFormState.values.cityId = getCityIdFromCityName(addressFormState.values?.city);
    }
    if (Object.keys(addressFormState.values).length === 0) {
      return;
    }

    let timeoutId;
    if (!firstLoad) {
      if (
        addressFormState.values?.addressNumber !== '' &&
        addressFormState.values?.addressNumber !== undefined
      ) {
        validationContext.validate({
          addressNumber: Number(addressFormState.values?.addressNumber),
        });

        if (validationContext.isValid() === false) {
          const errs = validationContext.validationErrors();
          let e = {};
          errs.map((er) => {
            e[er.name] = `Value must be ${er.dataType}`;
            setErrors(e);
          });
        } else {
          setErrors({});
        }
      }

      timeoutId = setTimeout(() => {
        duplicateFinder();
      }, 2000);
    }
    setFirstLoad(false);

    const fullAddressText = setFullAddressText(addressFormState);
    setAddressFormState((addressFormState) => ({
      ...addressFormState,
      changed: true,
      missingPersonForm: !hasNotes,
      values: {
        ...addressFormState.values,
        fullAddressText: fullAddressText,
      },
    }));
    updateIncAddressForm(addressFormState);
    return () => clearTimeout(timeoutId);
  }, [
    addressFormState.values.addressNumberPrefix,
    addressFormState.values.addressNumber,
    addressFormState.values.addressNumberSuffix,
    addressFormState.values.streetName,
    addressFormState.values.preDirection,
    addressFormState.values.postDirection,
    addressFormState.values.city,
    addressFormState.values.state,
    addressFormState.values.county,
    addressFormState.values.country,
    addressFormState.values.postalCode,
    addressFormState.values.postalCodeExtension,
    addressFormState.values.fullAddressText,
    addressFormState.values.streetType,
    addressFormState.values.notes,
    addressFormState.values.isPrimary,
    addressFormState.values.attachments,
  ]);

  React.useEffect(() => {
    if (doesFetchCoordinate === true) {
      fetchAddressData();
      setDoesFetchCoordinate(false);
    }
  }, [doesFetchCoordinate, wsClient, addressFormState.ptsCoordinateId]);
  /** endregion */
  /** region Helper Functions */
  const fetchAddressData = async () => {
    dispatch(setCircularLoading(true));
    const addressService = wsClient.websocket.service('rms-address');
    addressService.timeout = 20000;
    const resAddress = await addressService.get(
      addressFormState.ptsAddressId ? addressFormState.ptsAddressId : addressId
    );

    onPlaceValueChange(resAddress, setAddressFormState, setChangeKey, changeKey);

    updateIncAddressIdentifiersForm({
      ptsAddressId: resAddress.ptsAddressID,
      values: {
        isEventLookup: resAddress.ShowInEventLookup,
        isSexOffender: resAddress?.SexOffender,
        isParolee: resAddress?.Parolee,
        isProbation: resAddress?.Probation,
        isNonValidated: resAddress?.NonValidatedAddress,
        isCampusResidential: resAddress?.IsCampusResidential,
        isCampusNonResidential: resAddress?.IsCampusNonResidential,
        isNonCampus: resAddress?.IsNonCampus,
        isPublic: resAddress?.IsPublic,
        unitType: resAddress?.UnitType,
        unitTypeIdentifier: resAddress?.UnitIdentifier,
        floorType: resAddress?.FloorType,
        floorIdentifier: resAddress?.FloorIdentifier,
        buildingType: resAddress?.BldgType,
        buildingIdentifier: resAddress?.BldgIdentifier,
      },
    });

    if (resAddress.ptsCoordinateID) {
      const service = wsClient.websocket.service('ptscoordinates');
      service.timeout = 20000;
      const res = await service.get(resAddress.ptsCoordinateID);
      updateIncCoordinatesForm({
        ptsAddressId: addressFormState.ptsAddressId,
        ptsCoordinateId: res?.ptsCoordinateID,
        values: {
          latitudeSign: res?.LatitudeSign,
          latitudeDegree: res?.LatitudeDegree,
          longitudeSign: res?.LongitudeSign,
          longitudeDegree: res?.LongitudeDegree,
          uncertaintyDistance: res?.UnCertaintyDistance,
          datum: res?.Datum,
          coordinateDatetime: res?.CoordinateDT,
          altitude: res?.Altitude,
          altitudePor: res?.AltitudeRefPoint,
          altitudeUom: res?.AltitudeUnitMeasure,
          cellTower: res?.CellIDText,
          sectorId: res?.CellSectorIDText,
          speed: res?.Speed,
          speedUom: res?.SpeedUnitMeasure,
        },
      });
    }

    dispatch(setCircularLoading(false));
  };

  const onSelectAddress = (place, setFormState, setChangeKey, changeKey) => {
    onPlaceValueChange(place, setFormState, setChangeKey, changeKey);

    updateIncAddressIdentifiersForm({
      ptsAddressId: place.ptsAddressID,
      values: {
        isEventLookup: place.ShowInEventLookup,
        isSexOffender: place?.SexOffender,
        isParolee: place?.Parolee,
        isProbation: place?.Probation,
        isNonValidated: place?.NonValidatedAddress,
        isCampusResidential: place?.IsCampusResidential,
        isCampusNonResidential: place?.IsCampusNonResidential,
        isNonCampus: place?.IsNonCampus,
        isPublic: place?.IsPublic,
        unitType: place?.UnitType,
        unitTypeIdentifier: place?.UnitIdentifier,
        floorType: place?.FloorType,
        floorIdentifier: place?.FloorIdentifier,
        buildingType: place?.BldgType,
        buildingIdentifier: place?.BldgIdentifier,
      },
    });
    setDoesFetchCoordinate(true);
  };

  const onReset = () => {
    clearPlace(setAddressFormState);

    updateIncAddressIdentifiersForm(initFormModel);
    updateIncCoordinatesForm(initFormModel);
    setAddressFormState(initFormModel);
    updateIncAddressForm(initFormModel);

    changeSelectedOption(null);
  };
  /** endregion */
  return (
    <Grid container className="p-3">
      {entityType !== 'records' && pageType === 'add' && (
        <Grid item xs={12} spacing={2} className="mt-2 mb-3">
          <AddressSearch
            ptsPlaces={true}
            ptsAddresses={true}
            googleAddresses={false}
            onPlaceValueSet={onSelectAddress}
            onAddressValueSet={onSelectAddress}
            setFormState={setAddressFormState}
            label={'Search Addresses'}
            setChangeKey={setChangeKey}
            changeKey={changeKey}
            defaultLocation={null}
            onReset={onReset}
            addressOptions={addressOptions}
            changeOptions={changeOptions}
            changeSelectedOption={changeSelectedOption}
            selectedAddress={selectedAddress}
          />
        </Grid>
      )}
      <Grid item xs={12} className="mb-2">
        <span style={{ color: '#fff', background: '#1976d2' }} className="mb-2 badge badge-primary">
          Address Details
        </span>
        <Divider />
      </Grid>
      <Grid container justify="center" xs={12} spacing={2} className="mb-2">
        <Grid item xs={4}>
          <TextField
            id="standard-textarea-address-number-prefix"
            autoComplete="hidden"
            size="small"
            label="Prefix"
            placeholder="Prefix"
            variant="outlined"
            name="addressNumberPrefix"
            key={changeKey}
            value={addressFormState.values.addressNumberPrefix || ''}
            onChange={(event) =>
              handleFormChangeNew(
                event,
                event.target.value,
                'none',
                'addressNumberPrefix',
                setAddressFormState
              )
            }
            fullWidth
            multiline
            InputLabelProps={{ shrink: true }}
          />
        </Grid>
        <Grid item xs={4}>
          <TextField
            id="standard-textarea-address-number"
            autoComplete="hidden"
            size="small"
            error={errors['addressNumber'] ? true : false}
            helperText={errors['addressNumber']}
            label="Number"
            placeholder="Number"
            variant="outlined"
            name="addressNumber"
            key={changeKey}
            value={addressFormState?.values?.addressNumber || ''}
            onChange={(event) =>
              handleFormChangeNew(
                event,
                event.target.value,
                'none',
                'addressNumber',
                setAddressFormState
              )
            }
            fullWidth
            multiline
            InputLabelProps={{ shrink: true }}
          />
        </Grid>
        <Grid item xs={4}>
          <TextField
            id="standard-textarea-address-number-suffix"
            autoComplete="hidden"
            size="small"
            placeholder="Suffix"
            label="Suffix"
            variant="outlined"
            name="addressNumberSuffix"
            key={changeKey}
            value={addressFormState.values.addressNumberSuffix || ''}
            onChange={(event) =>
              handleFormChangeNew(
                event,
                event.target.value,
                'none',
                'addressNumberSuffix',
                setAddressFormState
              )
            }
            fullWidth
            multiline
            InputLabelProps={{ shrink: true }}
          />
        </Grid>
      </Grid>
      <Grid container justify="center" xs={12} spacing={2} className="mb-2">
        <Grid item xs={4}>
          <RMSAutoComplete
            size="small"
            autoComplete
            autoSelect
            autoHighlight
            id="combo-pre-directional"
            options={codeStreetDirections}
            label="Predirectional"
            serviceName="codestreetdirections"
            title="Code"
            stateHolderAttribute="preDirection"
            variant="outlined"
            stateHolder={addressFormState}
            setStateHolder={setAddressFormState}
            setField={handleFormChangeNew}
          />
        </Grid>
        <Grid item xs={4}>
          <TextField
            id="standard-textarea-address-street-name"
            autoComplete="hidden"
            size="small"
            placeholder="Street Name"
            label="Street Name"
            variant="outlined"
            name="streetName"
            key={changeKey}
            value={addressFormState.values.streetName || ''}
            onChange={(event) =>
              handleFormChangeNew(
                event,
                event.target.value,
                'none',
                'streetName',
                setAddressFormState
              )
            }
            fullWidth
            multiline
            InputLabelProps={{ shrink: true }}
          />
        </Grid>
        <Grid item xs={4}>
          <Autocomplete
            size="small"
            autoComplete
            autoSelect
            autoHighlight
            id="combo-street-type"
            getOptionLabel={(option) => option || ''}
            options={codeStreetTypes.map((s) => s.Code)}
            key={addressFormState.values.streetType || ''}
            defaultValue={addressFormState.values.streetType || ''}
            onChange={(event, newValue) =>
              handleFormChangeNew(event, newValue, 'none', 'streetType', setAddressFormState)
            }
            renderInput={(params) => (
              <TextField
                autoComplete="hidden"
                size="small"
                {...params}
                label="Type"
                variant="outlined"
                fullWidth
              />
            )}
          />
        </Grid>
      </Grid>
      <Grid container justify="center" xs={12} spacing={2} className="mb-2">
        <Grid item xs={4}>
          <RMSAutoComplete
            size="small"
            autoComplete
            autoSelect
            autoHighlight
            id="combo-post-direction"
            options={codeStreetDirections}
            label="Postdirectional"
            serviceName="codestreetdirections"
            title="Code"
            stateHolderAttribute="postDirection"
            variant="outlined"
            stateHolder={addressFormState}
            setStateHolder={setAddressFormState}
            setField={handleFormChangeNew}
          />
        </Grid>
        <Grid item xs={4}>
          <RMSAutoComplete
            size="small"
            autoComplete
            autoSelect
            autoHighlight
            id="combo-city"
            options={codeCities}
            label="City"
            serviceName="codecities"
            title="CityDescription"
            stateHolderAttribute="city"
            variant="outlined"
            stateHolder={addressFormState}
            setStateHolder={setAddressFormState}
            setField={handleFormChangeNew}
          />
        </Grid>
        <Grid item xs={4}>
          <RMSAutoComplete
            size="small"
            autoComplete
            autoSelect
            autoHighlight
            id="combo-state"
            getOptionLabel={(option) => option || ''}
            options={codeStates}
            label="State"
            serviceName="codestates"
            title="Code"
            stateHolderAttribute="state"
            variant="outlined"
            stateHolder={addressFormState}
            setStateHolder={setAddressFormState}
            setField={handleFormChangeNew}
          />
        </Grid>
      </Grid>
      <Grid container xs={12} spacing={2} className="mb-1">
        <Grid item xs={3}>
          <TextField
            id="outlined-multiline-static-address-postal-code"
            autoComplete="hidden"
            size="small"
            placeholder="Postal Code"
            label="Postal Code"
            variant="outlined"
            name="postalCode"
            key={changeKey}
            defaultValue={addressFormState.values.postalCode || ''}
            onBlur={(event) =>
              handleFormChangeNew(
                event,
                event.target.value,
                'none',
                'postalCode',
                setAddressFormState
              )
            }
            fullWidth
            multiline
            InputLabelProps={{ shrink: true }}
          />
        </Grid>
        <Grid item xs={3}>
          <TextField
            id="outlined-multiline-static-address-postal-code-ext"
            inputProps={{
              autocomplete: 'new-password',
              form: {
                autocomplete: 'off',
              },
            }}
            size="small"
            placeholder="Extension"
            label="Extension"
            variant="outlined"
            name="postalCodeExtension"
            key={changeKey}
            value={addressFormState.values.postalCodeExtension || ''}
            onChange={(event) =>
              handleFormChangeNew(
                event,
                event.target.value,
                'none',
                'postalCodeExtension',
                setAddressFormState
              )
            }
            fullWidth
            multiline
            InputLabelProps={{ shrink: true }}
          />
        </Grid>
        <Grid item xs={3}>
          <TextField
            id="outlined-multiline-static-address-county"
            autoComplete="hidden"
            size="small"
            placeholder="County"
            label="County"
            variant="outlined"
            name="county"
            key={changeKey}
            value={addressFormState.values.county || ''}
            onChange={(event) =>
              handleFormChangeNew(event, event.target.value, 'none', 'county', setAddressFormState)
            }
            fullWidth
            multiline
            InputLabelProps={{ shrink: true }}
          />
        </Grid>
        <Grid item xs={3}>
          <TextField
            id="outlined-multiline-static-address-country"
            autoComplete="hidden"
            size="small"
            placeholder="Country"
            label="Country"
            variant="outlined"
            name="country"
            key={changeKey}
            value={addressFormState.values.country || ''}
            onChange={(event) =>
              handleFormChangeNew(event, event.target.value, 'none', 'country', setAddressFormState)
            }
            fullWidth
            multiline
            InputLabelProps={{ shrink: true }}
          />
        </Grid>
      </Grid>
      {hasNotes && (
        <>
          <Grid item xs={12}>
            <span
              style={{ color: '#fff', background: '#1976d2' }}
              className="m-1 badge badge-primary">
              Notes
            </span>
            <Divider />
          </Grid>

          <Grid container justify="space-between">
            <Grid item xs={12}>
              <TextField
                autoComplete="hidden"
                size="small"
                className="mt-3"
                id="outlined-multiline-static-address-notes"
                placeholder="Notes"
                label="Notes"
                rows="4"
                fullWidth
                variant="outlined"
                name="notes"
                key={changeKey}
                value={addressFormState.values.notes || ''}
                onChange={(event) =>
                  handleFormChangeNew(
                    event,
                    event.target.value,
                    'none',
                    'notes',
                    setAddressFormState
                  )
                }
                multiline
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
          </Grid>

          {!recordActive && entityType !== 'records' && (
            <Grid container justify="space-between" spacing={2}>
              <Grid item xs={6}>
                <FormControl size="small">
                  <Grid>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={addressFormState.values.isPrimary || false}
                          name={'isPrimary'}
                          onChange={(event) =>
                            handleFormChangeNew(
                              event,
                              event.target.checked,
                              'none',
                              'isPrimary',
                              setAddressFormState
                            )
                          }
                        />
                      }
                      label={'Is Primary?'}
                    />
                  </Grid>
                </FormControl>
              </Grid>
            </Grid>
          )}

          {recordActive && (
            <Grid container justify="space-between" className="mt-3">
              <Grid item xs={12}>
                <RMSAttachments
                  attachments={addressFormState.values.attachments}
                  stateHolder={addressFormState}
                  setStateHolder={setAddressFormState}
                  setField={handleFormChangeNew}
                />
              </Grid>
            </Grid>
          )}
        </>
      )}
    </Grid>
  );
};

const mapStateToProps = (state) => ({
  wsClient: state.websocket,
  addressForm: state.incident.addressForm,
  codeStreetTypes: state.codeStreetTypes,
  codeStreetDirections: state.codeStreetDirections,
  codeStates: state.codeStates,
  codeCities: state.codesOthers.codeCities,
  codeAddressPartyRelationship: state.codeAddressPartyRelationship,
  recordActive: state.records.recordActive,
});

export default connect(mapStateToProps, {
  updateIncAddressForm,
  updateIncCoordinatesForm,
  updateIncAddressIdentifiersForm,
  getCodeStreetType,
  getCodeStreetDirections,
  getCodeStates,
  getCodeCities,
  getCodeAddressPartyRelationship,
  setDuplicateModal,
  setDuplicateModalMessage,
  setDuplicatAddressList,
})(RMSAddressForm);
