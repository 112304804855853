import React from 'react';
import { Draggable } from 'react-beautiful-dnd';
import { withStyles } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import StyledBadge from '../RMSStyledBadge';
import StyledCard from '../RMSStyledCard';
import { colors } from '../../config/colorVariables';

const useStyles = () => ({
  root: {
    padding: '10px',
    textAlign: 'center',
    margin: '5px',
  },
});

class DraggableTasks extends React.Component {
  setFlags = (flags) => {
    return <td className="text-center">{this.createBadges(flags)}</td>;
  };

  createBadges = (flags) => {
    if (flags !== undefined)
      return flags.map((f) => {
        switch (f) {
          case 'isJuvenile':
            return <StyledBadge color={colors.indigo} label="JUVENILE" size="sm" />;
          case 'isDomesticViolence':
            return <StyledBadge color={colors.teal} label="DOMESTIC VIOLENCE" size="sm" />;
        }
      });
  };

  render() {
    const { task, index, classes, color } = this.props;
    return (
      <Draggable draggableId={task.id} index={index}>
        {(provided, snapshot) => {
          return (
            <Grid
              item
              xs={12}
              lg={12}
              className={classes.root}
              {...provided.draggableProps}
              {...provided.dragHandleProps}
              innerRef={provided.innerRef}
              isDragging={snapshot.isDragging}>
              <StyledCard
                color={color}
                avatar={
                  task.name.substring(1, 2) === task.name.substring(2, 3)
                    ? task.name.substring(0, 2)
                    : task.name.substring(0, 3)
                }
                title={task.name}
                date={task.updated}
                address={task.address}
                updated={task.updated}
                updatedBy={task.updatedBy}
                badge1={this.setFlags(task.flags)}
                id={`Incident Id: ${task.incidentId}`}
              />
            </Grid>
          );
        }}
      </Draggable>
    );
  }
}

export default withStyles(useStyles)(DraggableTasks);
