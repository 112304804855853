import React from 'react';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Divider from '@material-ui/core/Divider';
import { makeStyles } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import TabPanel from '../../../components/RMSForms/RMSIncidentForm/TabPanel';
import { connect } from 'react-redux';
import { setHandleClose, setMenuType } from '../../../reducers/ui/UiMenuReducer';
import { faGem } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import TabJewelry from './TabJewelry';
import IncSummaryDetails from '../../../components/RMSForms/RMSIncidentForm/IncSummaryDetails';
import { 
  updateIncPropertyJewelryForm,
  getEntitiesByIncidentId,
  setSelectedIncident,
  setSelectedEntity,
} from '../../../reducers/IncReducer';
import { setOffensesTab } from '../../../reducers/ui/UiFormReducer';
import RMSFabButtons from 'components/RMSForms/RMSIncidentForm/RMSFabButtons';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { useParams } from 'react-router';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    height: window.innerHeight - 90 + 'px',
  },
  scrollBar: {
    width: '100%',
    overflowX: 'hidden',

    [theme.breakpoints.up('lg')]: {
      height: '70vh',
    },
    [theme.breakpoints.down('lg')]: {
      height: '50vh',
    },
    [theme.breakpoints.only('lg')]: {
      height: '65vh',
    },
  },
  paper: {
    minHeight: window.innerHeight - 95 + 'px',
  },
  dialogBox: {
    minWidth: '412px',
    minHeight: '150px',
  },
  icons: {
    textDecoration: 'capitalize',
    padding: '16px',
    color: 'white',
    fontSize: '13px',
  },
}));

const AddEditJewelry = (props) => {
  const classes = useStyles();
  const {
    history,
    setHandleClose,
    setMenuType,
    properties,
    selectedIncident,
    selectedEntity,
    updateIncPropertyJewelryForm,
    getEntitiesByIncidentId,
    setSelectedIncident,
    setSelectedEntity,
    wsClient,
  } = props;

  const [value, setValue] = React.useState(0);
  let { incId, propId } = useParams();

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  React.useEffect(() => {
    setMenuType('forms');
    setHandleClose('incForms', history);

    if (!selectedIncident || !selectedEntity || properties.length === 0) {
      return;
    }

    const { incIncidentPropertyId } = selectedEntity;
    const property = properties.filter(
      (e) => e.propertyDetails.incIncidentPropertyId === incIncidentPropertyId
    );

    if (property.length === 0) return;
    const { propertyDetails } = property[0];

    updateIncPropertyJewelryForm(propertyDetails);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedIncident !== 0, selectedEntity !== null, properties.length]);

  React.useEffect(() => {
    if (!wsClient.websocket) return;
    getEntitiesByIncidentId('properties', {});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedIncident !== 0, wsClient]);

  React.useEffect(() => {
    if (propId) setSelectedEntity({ incIncidentPropertyId: parseInt(propId) });
    if (incId) setSelectedIncident(parseInt(incId));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedIncident === 0, selectedEntity === null]);

  return (
    <div className={classes.root}>
      <RMSFabButtons />
      <Grid container>
        <Grid xs={3} lg={3}>
          <IncSummaryDetails history={history} />
        </Grid>
        <Grid xs={9} lg={9}>
          <Paper className={classes.paper}>
            <Grid container justify="center">
              <Grid item xs={12} lg={12}>
                <div
                  className="card-header-actions"
                  style={{
                    backgroundColor: '#1976d2',
                    color: 'white',
                    borderRadius: '0.5rem 0.5rem 0 0',
                  }}>
                  <Tabs
                    value={value}
                    indicatorColor="secondary"
                    variant="fullWidth"
                    classes={{
                      indicator: classes.indicator,
                    }}
                    onChange={handleChange}>
                    <Tab
                      className={classes.icons}
                      icon={<FontAwesomeIcon icon={faGem} />}
                      label="Jewelry"
                    />
                  </Tabs>
                  <Divider />
                </div>
              </Grid>
              <PerfectScrollbar className={classes.scrollBar}>
                <Grid item xs={12} lg={12} style={{ padding: '15px' }}>
                  <TabPanel value={value} index={0}>
                    <TabJewelry history={history} />
                  </TabPanel>
                </Grid>
              </PerfectScrollbar>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    </div>
  );
};

const mapStateToProps = state => ({
  wsClient: state.websocket,
  properties: state.incident.properties,
  selectedEntity: state.incident.selectedEntity,
  selectedIncident: state.incident.ptsIncidentId,
  showUCRAlertModal: state.uiModal.showUCRAlertModal,
});

export default connect(mapStateToProps, {
  setHandleClose,
  setMenuType,
  updateIncPropertyJewelryForm,
  setOffensesTab,
  getEntitiesByIncidentId,
  setSelectedIncident,
  setSelectedEntity,
})(AddEditJewelry);
