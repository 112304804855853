import React from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import 'date-fns';
import DateFnsUtils from '@date-io/date-fns';
import clsx from 'clsx';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import CloseIcon from '@material-ui/icons/Close';
import SaveIcon from '@material-ui/icons/Save';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import { getCountryCodes, addHVRecord } from './api';
import { showSpinner, hideSpinner } from '../../reducers/ui/UiDrawerReducer';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { notify } from '../../reducers/NotifierReducer';
import Dialog from './CustomDialog';

function getFormStyle(minWidth, maxWidth) {
  return {
    maxWidth: maxWidth,
    flexBasis: minWidth,
    minWidth: minWidth,
    flexGrow: 1,
    margin: `0 4px 8px`,
  };
}

const useStyles = makeStyles((theme) => ({
  wrap: {
    // padding: theme.spacing(4),
    // maxWidth: 1200,
    // margin: '0 auto',
    // boxSizing: 'border-box',
    // height: 'calc(100vh - 120px)',
    // overflowY: 'auto',
    // background: '#fff',
    '& > h5': {
      marginBottom: 20,
    },
  },
  form: {
    margin: '0 -4px 4px',
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    flexGrow: 1,
  },
  w100x200: {
    ...getFormStyle(100, 200),
  },
  w120x180: {
    margin: '0 4px',
  },
  w100pr: {
    width: '100%',
  },
  formControl: {
    ...getFormStyle(170, 170),
  },
  buttons: {
    '& svg': {
      marginRight: theme.spacing(1),
    },
  },
}));

const emptyFormData = {
  date: new Date(),
  host: '',
  contractorEmployee: false,
  usCitizen: false,
  scd: false,
  foreignNational: null,
  citizenship: null,
  unannouncedVisit: false,
  favor: '',
  location: '',
  hostReqActivities: ['', '', '', '', ''],
  observations: '',
  observationsCheck: [false, false, false, false],
  foreignNatInterview: '',
  foreignNatInterviewCheck: [false, false, false],
  comments: '',
  evaluator: '',
  approval: '',
  approvalDate: new Date(),
};

function HostValidationForm(props) {
  const classes = useStyles();
  const { websocket, close } = props;
  const [data, setData] = React.useState({ ...emptyFormData });
  const [countries, setCountries] = React.useState([]);

  React.useEffect(() => {
    if (!websocket.websocket) return;
    updateCountryCodes();
    // eslint-disable-next-line
  }, [websocket]);

  const updateCountryCodes = async () => {
    props.showSpinner();
    try {
      const countries = await getCountryCodes();
      setCountries(countries.data);
    } catch (err) {
      props.notify('Error, Problem with API connection', 'error');
    }
    props.hideSpinner();
  };

  const setAttr = (name, val) => {
    const newData = { ...data };
    newData[name] = val;
    setData(newData);
  };

  const setHostReq = (no, ev) => {
    const val = ev.target.value;
    const newData = { ...data };
    newData.hostReqActivities[no] = val;
    setData(newData);
  };

  const renderHostReqAct = (no, text) => {
    return (
      <li>
        <span>{text}: </span>
        <TextField
          autoComplete="hidden"
          size="small"
          className={classes.w120x180}
          value={data.hostReqActivities[no]}
          onChange={(ev) => setHostReq(no, ev)}
        />
      </li>
    );
  };

  const setObservations = (no, ev) => {
    const val = ev.target.checked;
    const newData = { ...data };
    newData.observationsCheck[no] = val;
    setData(newData);
  };

  const renderObservations = (no, text) => {
    return (
      <FormControlLabel
        className={clsx(classes.checkbox, classes.w120x180)}
        control={
          <Checkbox
            checked={data.observationsCheck[no]}
            onChange={(ev) => setObservations(no, ev)}
            className={classes.checkbox}
          />
        }
        labelPlacement="start"
        label={text}
      />
    );
  };

  const setForeignNatCheck = (no, ev) => {
    const val = ev.target.checked;
    const newData = { ...data };
    newData.foreignNatInterviewCheck[no] = val;
    setData(newData);
  };

  const renderRoreignNat = (no, text) => {
    return (
      <FormControlLabel
        className={clsx(classes.checkbox, classes.w120x180)}
        labelPlacement="start"
        label={text}
        control={
          <Checkbox
            checked={data.foreignNatInterviewCheck[no]}
            onChange={(ev) => setForeignNatCheck(no, ev)}
            className={classes.checkbox}
          />
        }
      />
    );
  };

  const renderActions = () => {
    return (
      <>
        <Button color="primary" variant="contained" autoFocus onClick={save}>
          <SaveIcon /> Save
        </Button>
        <Button color="primary" onClick={close}>
          <CloseIcon /> Cancel
        </Button>
      </>
    );
  };

  const save = async () => {
    try {
      await addHVRecord(data);
    } catch (err) {
      console.log(err);
    }
  };

  const renderForm = () => {
    return (
      <div className={classes.wrap}>
        <h5>HOSTING VALIDATION</h5>
        <p>Report Type: HOST VALIDATION-TEST</p>
        <div className={classes.form}>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <KeyboardDatePicker
              size="small"
              inputVariant="outlined"
              variant="inline"
              disableToolbar
              format="MM/dd/yyyy"
              margin="normal"
              label="Date"
              value={data.date}
              onChange={(date) => setAttr('date', date)}
              autoOk={true}
            />
          </MuiPickersUtilsProvider>
        </div>
        <hr />
        <div className={classes.form}>
          <TextField
            autoComplete="hidden"
            size="small"
            className={clsx(classes.textField, classes.w180)}
            label="Host"
            variant="outlined"
            value={data.host}
            onChange={(ev) => setAttr('host', ev.target.value)}
          />
          <FormControlLabel
            className={clsx(classes.checkbox, classes.w120x180)}
            control={
              <Checkbox
                checked={data.contractorEmployee}
                onChange={(ev) => setAttr('contractorEmployee', ev.target.checked)}
                className={classes.checkbox}
              />
            }
            labelPlacement="start"
            label="DOE / Contractor Employee:"
          />
          <FormControlLabel
            className={clsx(classes.checkbox, classes.w120x180)}
            control={
              <Checkbox
                checked={data.usCitizen}
                onChange={(ev) => setAttr('usCitizen', ev.target.checked)}
                className={classes.checkbox}
              />
            }
            labelPlacement="start"
            label="U.S. Citizen:"
          />
          <FormControlLabel
            className={clsx(classes.checkbox, classes.w120x180)}
            control={
              <Checkbox
                checked={data.scd}
                onChange={(ev) => setAttr('scd', ev.target.checked)}
                className={classes.checkbox}
              />
            }
            labelPlacement="start"
            label="Current w/ SCD 100:"
          />
          <Autocomplete
            size="small"
            options={countries}
            getOptionLabel={(option) => option.Description}
            value={data.foreignNational}
            className={classes.w100x200}
            renderInput={(params) => (
              <TextField
                autoComplete="hidden"
                size="small"
                {...params}
                label="Foreign National"
                variant="outlined"
              />
            )}
            onChange={(event, newValue) => setAttr('foreignNational', newValue)}
          />
          <Autocomplete
            size="small"
            options={countries}
            getOptionLabel={(option) => option.Description}
            value={data.citizenship}
            className={classes.w100x200}
            renderInput={(params) => (
              <TextField
                autoComplete="hidden"
                size="small"
                {...params}
                label="Citizenship"
                variant="outlined"
              />
            )}
            onChange={(event, newValue) => setAttr('citizenship', newValue)}
          />
          <FormControlLabel
            className={clsx(classes.checkbox, classes.w120x180)}
            control={
              <Checkbox
                checked={data.unannouncedVisit}
                onChange={(ev) => setAttr('unannouncedVisit', ev.target.checked)}
                className={classes.checkbox}
              />
            }
            labelPlacement="start"
            label="Unannounced Visit:"
          />
          <TextField
            autoComplete="hidden"
            size="small"
            className={classes.w120x180}
            label="Favor #:"
            variant="outlined"
            value={data.favor}
            onChange={(ev) => setAttr('favor', ev.target.value)}
          />
          <TextField
            autoComplete="hidden"
            size="small"
            className={classes.w120x180}
            label="Location:"
            variant="outlined"
            value={data.location}
            onChange={(ev) => setAttr('location', ev.target.value)}
          />
        </div>
        <h6>HOST REQUIRED ACTIVITIES:</h6>
        <div className={classes.form}>
          <ol>
            {renderHostReqAct(0, 'Host Identified to the Visitor')}
            {renderHostReqAct(
              1,
              'Knowledge to report suspicious activities / any suspicious activities observed'
            )}
            {renderHostReqAct(
              2,
              'Provided the terms and conditions of access approval, including restrictions and requirements to notify the host of changes in name, immigrant/nonimmigrant status, and other information as required'
            )}
            {renderHostReqAct(
              3,
              'Provided the requirement to notify the host of any civil or criminal problems that could affect their status and association with DOE'
            )}
            <li>
              Provided that the failure to provide appropriate documentation when required or
              providing fradulent documentation will result in suspension of access approval,
              removal from the site, and possible cancellation of future access.
            </li>
          </ol>
        </div>
        <h6>Observations:</h6>
        <div className={classes.form}>
          <TextField
            autoComplete="hidden"
            size="small"
            className={classes.w100pr}
            variant="outlined"
            multiline
            rows={6}
            value={data.observations}
            onChange={(ev) => setAttr('observations', ev.target.value)}
          />
        </div>
        <div>
          {renderObservations(0, "Host Knowledgeable of Foreign National's whereabouts:")}
          <br />
          {renderObservations(1, 'Foreign National on-site / presence observed:')}
          <br />
          {renderObservations(2, 'Host Knowledgeable of Security Plan / Security Plan adhered:')}
          <br />
          {renderObservations(3, "Host Conducted walk-down of Foreign National's work area:")}
          <br />
        </div>
        <h6>FOREIGN NATIONAL INTERVIEW:</h6>
        <p>
          (Foreign National knowledgable about requirements, host communicated the required
          information, adherence to the Security Plan, etc.)
        </p>
        <div className={classes.form}>
          <TextField
            autoComplete="hidden"
            size="small"
            className={classes.w100pr}
            variant="outlined"
            multiline
            rows={6}
            value={data.foreignNatInterview}
            onChange={(ev) => setAttr('foreignNatInterview', ev.target.value)}
          />
        </div>
        <div>
          {renderRoreignNat(0, 'Remedial Host Training Required:')}
          <br />
          {renderRoreignNat(1, 'Division Follow-up Required:')}
          <br />
          {renderRoreignNat(2, 'Host Compliant with DOE O 142.3A:')}
          <br />
        </div>
        <h6>COMMENTS:</h6>
        <div className={classes.form}>
          <TextField
            autoComplete="hidden"
            size="small"
            className={classes.w100pr}
            variant="outlined"
            multiline
            rows={6}
            value={data.comments}
            onChange={(ev) => setAttr('comments', ev.target.value)}
          />
        </div>

        <div className={classes.form} style={{ marginTop: 20 }}>
          <TextField
            autoComplete="hidden"
            size="small"
            className={classes.w120x180}
            variant="outlined"
            value={data.evaluator}
            label="Evaluator"
            onChange={(ev) => setAttr('evaluator', ev.target.value)}
          />
        </div>
        <div className={classes.form}>
          <TextField
            autoComplete="hidden"
            size="small"
            className={classes.w120x180}
            variant="outlined"
            value={data.approval}
            label="Approval"
            onChange={(ev) => setAttr('approval', ev.target.value)}
          />
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <KeyboardDatePicker
              size="small"
              inputVariant="outlined"
              style={{ marginTop: 0, marginBottom: 0 }}
              variant="inline"
              disableToolbar
              format="MM/dd/yyyy"
              margin="normal"
              label="Approval Date:"
              value={data.approvalDate}
              onChange={(date) => setAttr('approvalDate', date)}
              autoOk={true}
            />
          </MuiPickersUtilsProvider>
        </div>
      </div>
    );
  };

  return (
    <Dialog
      onClose={close}
      title={'Add Note'}
      actions={renderActions()}
      className={classes.dialog}
      // toolbar={false}
      // draggable={false}
      // fullScreen
    >
      {renderForm()}
    </Dialog>
  );
}

const mapStateToProps = (state) => {
  return { websocket: state.websocket };
};

export default connect(mapStateToProps, {
  showSpinner,
  hideSpinner,
  notify,
})(HostValidationForm);
