/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { connect } from 'react-redux';

import { v4 as uuidv4 } from 'uuid';
import SimpleSchema from 'simpl-schema';
import { Divider } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

import RMSComboBox from 'components/RMSComboBox';
import RMSComboBoxListItem from 'components/RMSComboBoxListItem';
import RMSAutoComplete from 'components/RMSAutoComplete/RMSAutoComplete';
import {
  updateIncPropertyJewelryForm,
  updateIncEvidenceItemForm,
} from '../../../reducers/IncReducer';
import { getCodeSex } from '../../../reducers/dictionaries/CodeSexReducer';
import { getCodeMaterial } from '../../../reducers/dictionaries/CodeMaterialReducer';
import {
  getCodeItemModels,
  getCodeItemConditions,
  getCodeItemMakes,
} from '../../../reducers/dictionaries/CodeItemReducer';
import { getCodeJewelryCateogory } from '../../../reducers/dictionaries/CodeOthersReducer';
import { handleFormChangeNew } from '../../../reducers/helpers/formHelpers';
import { checkFormType } from '../../helpers/checkFormState';
import RMSAttachments from 'components/RMSAttachments';

// Validation Schema
const validationContext = new SimpleSchema({
  pendantQuantity: SimpleSchema.Integer,
  carat: SimpleSchema.Integer,
}).newContext();

const TabJewelry = (props) => {
  /** region Component Variables and Props */
  const {
    updateIncPropertyJewelryForm,
    codeSex,
    codeMaterial,
    getCodeSex,
    getCodeMaterial,
    getCodeJewelryCateogory,
    getCodeItemConditions,
    getCodeItemModels,
    getCodeItemMakes,
    codeItemModels,
    codeItemMakes,
    codeItemConditions,
    codeJewelryCategory,
    propertyJewelryForm,
    updateIncEvidenceItemForm,
    wsClient,
    history,
  } = props;
  const [entityType, setEntityType] = React.useState('');
  const [changeKey, setChangeKey] = React.useState(0);
  const [pageType, setPageType] = React.useState('add');
  const [firstLoad, setFirstLoad] = React.useState(true);
  const [errors, setErrors] = React.useState({});
  const [propertyJewelryFormState, setPropertyJewelryFormState] = React.useState({
    tempPropId: uuidv4(),
    isValid: false,
    values: {},
    touched: {},
    errors: {},
    changes: false,
    formType: 'edit',
  });
  /** endregion */
  /** region React Hooks */
  React.useEffect(() => {
    checkFormType(
      propertyJewelryFormState.ptsJewelryId !== undefined &&
        propertyJewelryFormState.ptsJewelryId !== null,
      setPropertyJewelryFormState,
      history
    );
  }, []);

  React.useEffect(() => {
    if (wsClient.websocket) {
      getCodeJewelryCateogory();
      getCodeItemModels();
      getCodeItemConditions();
      getCodeItemMakes();
    }
  }, [
    getCodeItemMakes,
    getCodeItemModels,
    getCodeItemConditions,
    getCodeJewelryCateogory,
    wsClient,
  ]);

  React.useEffect(() => {
    const services = ['codeSex', 'codeMaterial'];

    if (!wsClient.websocket) return;

    services.forEach(async (svc) => {
      if (props[svc].length < 1) {
        const result = await wsClient.websocket.service(svc.toLowerCase()).find({
          query: {
            $limit: 0,
            $select: ['Code', 'Description'],
            IsDeleted: false,
          },
        });

        if (result.total > 0) {
          if (svc === 'codeSex') {
            getCodeSex(result.data);
          } else if (svc === 'codeMaterial') {
            getCodeMaterial(result.data);
          }
        }
      }
    });
  }, [wsClient]);

  React.useEffect(() => {
    const parts = history.location.pathname.split('/');
    let type = '';
    if (parts.length === 4) type = parts[parts.length - 2];
    if (parts.length === 5) type = parts[parts.length - 3];

    setEntityType(parts[1]);
    setPageType(type);
  }, []);

  React.useEffect(() => {
    checkFormType(
      propertyJewelryFormState.ptsJewelryId !== undefined &&
        propertyJewelryFormState.ptsJewelryId !== null,
      setPropertyJewelryFormState,
      history
    );
    setPropertyJewelryFormState((propertyJewelryFormState) => ({
      ...propertyJewelryFormState,
      ...propertyJewelryForm,
      values: {
        ...propertyJewelryFormState.values,
        ...propertyJewelryForm.values,
      },
    }));
  }, [propertyJewelryForm]);

  React.useEffect(() => {
    if (!firstLoad) {
      validationContext.validate({
        pendantQuantity:
          propertyJewelryFormState.values?.pendantQuantity?.length > 0
            ? Number(propertyJewelryFormState.values.pendantQuantity)
            : 0,
        carat:
          propertyJewelryFormState.values?.carat?.length > 0
            ? Number(propertyJewelryFormState.values.carat)
            : 0,
      });

      if (validationContext.isValid() === false) {
        const errs = validationContext.validationErrors();

        let e = {};
        errs.map((er) => {
          e[er.name] = `Value must be ${er.dataType}`;
          setErrors(e);
        });
      } else {
        setErrors({});
      }
    }
    setFirstLoad(false);
    propertyJewelryFormState.changes = true;
    updateIncPropertyJewelryForm(propertyJewelryFormState);

    if (propertyJewelryFormState.values.isEvidence) {
      updateIncEvidenceItemForm({
        values: {
          description: `${propertyJewelryFormState.values?.description || ''} ${
            propertyJewelryFormState.values?.make || ''
          } ${propertyJewelryFormState.values?.model || ''}`,
          offenses: [],
          make: propertyJewelryFormState.values?.make,
          model: propertyJewelryFormState.values?.model,
          category: 'Property',
          condition: propertyJewelryFormState.values?.condition,
          serialNumber: propertyJewelryFormState.values?.serialNumber,
        },
      });
    }
  }, [
    propertyJewelryFormState.values?.incidentId,
    propertyJewelryFormState.values?.jewelaryId,
    propertyJewelryFormState.values?.description,
    propertyJewelryFormState.values?.shape,
    propertyJewelryFormState.values?.arrangement,
    propertyJewelryFormState.values?.band,
    propertyJewelryFormState.values?.mainPendant,
    propertyJewelryFormState.values?.serialNumber,
    propertyJewelryFormState.values?.condition,
    propertyJewelryFormState.values?.make,
    propertyJewelryFormState.values?.model,
    propertyJewelryFormState.values?.jewelryCategory,
    propertyJewelryFormState.values?.sex,
    propertyJewelryFormState.values?.material,
    propertyJewelryFormState.values?.carat,
    propertyJewelryFormState.values?.pendantQuantity,
    propertyJewelryFormState.values?.isEvidence,
    propertyJewelryFormState.values?.attachments,
  ]);

  React.useEffect(() => {
    if (!wsClient?.websocket) {
      return;
    }

    const getAttachments = async () => {
      const service = wsClient?.websocket.service('rms-attachments');
      service.timeout = 200000;

      const result = await service.find({
        query: {
          id:
            entityType === 'records'
              ? propertyJewelryFormState.ptsJewelryId
              : propertyJewelryFormState.incIncidentPropertyId,
          type: entityType === 'records' ? 'JEWELRY' : 'INCIDENTPROPERTY',
        },
      });
      let atts = [];
      result.map((r) => {
        atts.push({
          ptsAttachmentId: r.ptsAttachmentID,
          name: r.FileName,
          type: r.FileType,
          dataURL: r.FileObject,
        });
      });
      setPropertyJewelryFormState((fireArmState) => ({
        ...fireArmState,
        values: {
          ...fireArmState.values,
          attachments: atts,
        },
      }));
    };

    if (propertyJewelryFormState.ptsJewelryId) {
      getAttachments();
    }
  }, [wsClient, propertyJewelryFormState.ptsJewelryId]);
  /** endregion */
  return (
    <Grid item xs={12} lg={12}>
      <Grid item xs={12} lg={12} className="mb-3">
        {pageType === 'add' && entityType !== 'records' && (
          <Grid item xs={12} lg={12} className="mb-3">
            <FormControlLabel
              control={
                <Checkbox
                  key={propertyJewelryFormState.values.isEvidence}
                  checked={propertyJewelryFormState.values.isEvidence}
                  onChange={(event) =>
                    handleFormChangeNew(
                      event,
                      event.target.checked,
                      'none',
                      'isEvidence',
                      setPropertyJewelryFormState
                    )
                  }
                  name="isEvidence"
                />
              }
              label="Is Evidence?"
            />
          </Grid>
        )}
        <TextField
          id="standard-textarea-jewelry-description"
          autoComplete="hidden"
          size="small"
          label="Description"
          placeholder="Description"
          variant="outlined"
          name="description"
          key={changeKey}
          defaultValue={propertyJewelryFormState.values?.description || ''}
          onBlur={(event) =>
            handleFormChangeNew(
              event,
              event.target.value,
              'none',
              'description',
              setPropertyJewelryFormState
            )
          }
          fullWidth
          multiline
          InputLabelProps={{ shrink: true }}
        />
      </Grid>
      <Grid xs={12} className="mt-2 mb-3">
        <span style={{ color: '#fff', background: '#1976d2' }} className="mb-1 badge badge-primary">
          Basic Info
        </span>
        <Divider />
      </Grid>
      <Grid container spacing={2} justify="center" className="mb-2">
        <Grid item xs={4}>
          <TextField
            id="standard-textarea-jewelry-shape"
            autoComplete="hidden"
            size="small"
            label="Shape"
            placeholder="Shape"
            variant="outlined"
            name="shape"
            key={changeKey}
            defaultValue={propertyJewelryFormState.values?.shape || ''}
            onBlur={(event) =>
              handleFormChangeNew(
                event,
                event.target.value,
                'none',
                'shape',
                setPropertyJewelryFormState
              )
            }
            fullWidth
            multiline
            InputLabelProps={{ shrink: true }}
          />
        </Grid>
        <Grid item xs={4}>
          <TextField
            id="standard-textarea-jewelry-arrangement"
            autoComplete="hidden"
            size="small"
            label="Arrangement"
            placeholder="Arrangement"
            variant="outlined"
            name="arrangement"
            key={changeKey}
            defaultValue={propertyJewelryFormState.values?.arrangement || ''}
            onBlur={(event) =>
              handleFormChangeNew(
                event,
                event.target.value,
                'none',
                'arrangement',
                setPropertyJewelryFormState
              )
            }
            fullWidth
            multiline
            InputLabelProps={{ shrink: true }}
          />
        </Grid>

        <Grid item xs={4}>
          <TextField
            id="standard-textarea-jewelry-mainPendant"
            autoComplete="hidden"
            size="small"
            label="Main Pendant"
            placeholder="Main Pendant"
            variant="outlined"
            name="mainPendant"
            key={changeKey}
            defaultValue={propertyJewelryFormState.values?.mainPendant || ''}
            onBlur={(event) =>
              handleFormChangeNew(
                event,
                event.target.value,
                'none',
                'mainPendant',
                setPropertyJewelryFormState
              )
            }
            fullWidth
            multiline
            InputLabelProps={{ shrink: true }}
          />
        </Grid>
        <Grid item xs={4}>
          <Autocomplete
            size="small"
            autoComplete
            autoSelect
            autoHighlight
            id="combo-jewelry-condition"
            getOptionLabel={(option) => option.Code || ''}
            options={codeItemConditions}
            key={propertyJewelryFormState.values?.condition || ''}
            value={{ Code: propertyJewelryFormState.values?.condition } || ''}
            onChange={(event, newValue) =>
              handleFormChangeNew(
                event,
                newValue?.Code,
                'none',
                'condition',
                setPropertyJewelryFormState
              )
            }
            renderOption={(option) => {
              return (
                <RMSComboBoxListItem
                  option={option}
                  title="Code"
                  serviceName="codeitemconditions"
                  label="Condition"
                  resetField={() =>
                    handleFormChangeNew(
                      null,
                      null,
                      'none',
                      'condition',
                      setPropertyJewelryFormState
                    )
                  }
                />
              );
            }}
            renderInput={(params) => (
              <RMSComboBox
                {...params}
                setField={(newVal) => {
                  handleFormChangeNew(
                    null,
                    newVal['Code'],
                    'none',
                    'condition',
                    setPropertyJewelryFormState
                  );
                }}
                serviceName="codeitemconditions"
                label="Condition"
                variant="outlined"
                fullWidth
              />
            )}
          />
        </Grid>
        <Grid item xs={4}>
          <RMSAutoComplete
            size="small"
            autoComplete
            autoSelect
            autoHighlight
            id="combo-jewelry-category"
            options={codeJewelryCategory}
            label="Jewelry Category"
            serviceName="codeJewelryCategory"
            title="Code"
            stateHolderAttribute="jewelryCategory"
            variant="outlined"
            stateHolder={propertyJewelryFormState}
            setStateHolder={setPropertyJewelryFormState}
            setField={handleFormChangeNew}
          />
        </Grid>
        <Grid item xs={4}>
          <Autocomplete
            size="small"
            autoComplete
            autoSelect
            autoHighlight
            id="combo-jewelry-sex"
            getOptionLabel={(option) => option || ''}
            options={codeSex.map((s) => s.Description)}
            key={propertyJewelryFormState.values?.sex || ''}
            value={propertyJewelryFormState.values?.sex || ''}
            onChange={(event, newValue) =>
              handleFormChangeNew(event, newValue, 'none', 'sex', setPropertyJewelryFormState)
            }
            renderInput={(params) => (
              <RMSComboBox {...params} label="Sex" variant="outlined" fullWidth />
            )}
          />
        </Grid>
      </Grid>
      <Grid xs={12} className="mt-2 mb-3">
        <span style={{ color: '#fff', background: '#1976d2' }} className="mb-1 badge badge-primary">
          Build Info
        </span>
        <Divider />
      </Grid>
      <Grid container spacing={2} justify="center" className="mb-2">
        <Grid item xs={3}>
          <TextField
            id="standard-textarea-jewelry-band"
            autoComplete="hidden"
            size="small"
            label="Band"
            placeholder="Band"
            variant="outlined"
            name="band"
            key={changeKey}
            defaultValue={propertyJewelryFormState.values?.band || ''}
            onBlur={(event) =>
              handleFormChangeNew(
                event,
                event.target.value,
                'none',
                'band',
                setPropertyJewelryFormState
              )
            }
            fullWidth
            multiline
            InputLabelProps={{ shrink: true }}
          />
        </Grid>

        <Grid item xs={3}>
          <TextField
            id="standard-textarea-jewelry-serialNumber"
            autoComplete="hidden"
            size="small"
            label="Serial Number"
            placeholder="Serial Number"
            variant="outlined"
            name="serialNumber"
            key={changeKey}
            defaultValue={propertyJewelryFormState.values?.serialNumber || ''}
            onBlur={(event) =>
              handleFormChangeNew(
                event,
                event.target.value,
                'none',
                'serialNumber',
                setPropertyJewelryFormState
              )
            }
            fullWidth
            multiline
            InputLabelProps={{ shrink: true }}
          />
        </Grid>

        <Grid item xs={3}>
          <Autocomplete
            size="small"
            autoComplete
            autoSelect
            autoHighlight
            id="combo-jewelry-make"
            getOptionLabel={(option) => option.Code || ''}
            options={codeItemMakes}
            key={propertyJewelryFormState.values?.make || ''}
            value={{ Code: propertyJewelryFormState.values?.make } || ''}
            inputValue={propertyJewelryFormState.values?.make || ''}
            onChange={(event, newValue) =>
              handleFormChangeNew(
                event,
                newValue?.Code,
                'none',
                'make',
                setPropertyJewelryFormState
              )
            }
            renderOption={(option) => {
              return (
                <RMSComboBoxListItem
                  option={option}
                  title="Code"
                  serviceName="codeitemmakes"
                  label="Make"
                  resetField={() =>
                    handleFormChangeNew(null, null, 'none', 'make', setPropertyJewelryFormState)
                  }
                />
              );
            }}
            renderInput={(params) => (
              <RMSComboBox
                {...params}
                setField={(newVal) => {
                  handleFormChangeNew(
                    null,
                    newVal['Code'],
                    'none',
                    'make',
                    setPropertyJewelryFormState
                  );
                }}
                serviceName="codeitemmakes"
                label="Make"
                variant="outlined"
                fullWidth
              />
            )}
          />
        </Grid>
        <Grid item xs={3}>
          <Autocomplete
            size="small"
            autoComplete
            autoSelect
            autoHighlight
            id="combo-jewelry-model"
            getOptionLabel={(option) => option.Code || ''}
            options={codeItemModels}
            key={propertyJewelryFormState.values?.model || ''}
            value={{ Code: propertyJewelryFormState.values?.model } || ''}
            inputValue={propertyJewelryFormState.values?.model || ''}
            onChange={(event, newValue) =>
              handleFormChangeNew(
                event,
                newValue?.Code,
                'none',
                'model',
                setPropertyJewelryFormState
              )
            }
            renderOption={(option) => {
              return (
                <RMSComboBoxListItem
                  option={option}
                  title="Code"
                  serviceName="codeitemmodels"
                  label="Model"
                  resetField={() =>
                    handleFormChangeNew(null, null, 'none', 'model', setPropertyJewelryFormState)
                  }
                />
              );
            }}
            renderInput={(params) => (
              <RMSComboBox
                {...params}
                setField={(newVal) => {
                  handleFormChangeNew(
                    null,
                    newVal['Code'],
                    'none',
                    'model',
                    setPropertyJewelryFormState
                  );
                }}
                serviceName="codeitemmodels"
                label="Model"
                variant="outlined"
                fullWidth
              />
            )}
          />
        </Grid>
      </Grid>

      <Grid xs={12} className="mt-2 mb-3">
        <span style={{ color: '#fff', background: '#1976d2' }} className="mb-1 badge badge-primary">
          Quality
        </span>
        <Divider />
      </Grid>
      <Grid container spacing={2} justify="center" className="mb-2">
        <Grid item xs={4} lg={4}>
          <RMSAutoComplete
            size="small"
            autoComplete
            autoSelect
            autoHighlight
            id="combo-jewelry-material"
            options={codeMaterial}
            label="Material"
            serviceName="codematerial"
            title="Code"
            stateHolderAttribute="material"
            variant="outlined"
            stateHolder={propertyJewelryFormState}
            setStateHolder={setPropertyJewelryFormState}
            setField={handleFormChangeNew}
          />
        </Grid>
        <Grid item xs={4}>
          <TextField
            id="standard-textarea-jewelry-carat"
            autoComplete="hidden"
            size="small"
            label="Carat"
            placeholder="Carat"
            error={errors['carat'] ? true : false}
            helperText={errors['carat']}
            variant="outlined"
            name="carat"
            key={changeKey}
            defaultValue={propertyJewelryFormState.values?.carat || ''}
            onChange={(event) =>
              handleFormChangeNew(
                event,
                event.target.value,
                'none',
                'carat',
                setPropertyJewelryFormState
              )
            }
            fullWidth
            multiline
            InputLabelProps={{ shrink: true }}
          />
        </Grid>
        <Grid item xs={4}>
          <TextField
            id="standard-textarea-jewelry-pendantQuantity"
            autoComplete="hidden"
            size="small"
            error={errors['pendantQuantity'] ? true : false}
            helperText={errors['pendantQuantity']}
            label="Pendant Quantity"
            placeholder="Pendant Quantity"
            variant="outlined"
            name="pendantQuantity"
            key={changeKey}
            value={propertyJewelryFormState.values?.pendantQuantity || ''}
            onChange={(event) =>
              handleFormChangeNew(
                event,
                event.target.value,
                'none',
                'pendantQuantity',
                setPropertyJewelryFormState
              )
            }
            fullWidth
            multiline
            InputLabelProps={{ shrink: true }}
          />
        </Grid>
      </Grid>
      <RMSAttachments
        attachments={propertyJewelryFormState.values.attachments}
        stateHolder={propertyJewelryFormState}
        setStateHolder={setPropertyJewelryFormState}
        setField={handleFormChangeNew}
      />
    </Grid>
  );
};

const mapStateToProps = (state) => ({
  wsClient: state.websocket,
  propertyJewelryForm: state.incident.propertyJewelryForm,
  codeSex: state.codeSex,
  codeMaterial: state.codeMaterial,
  codeJewelryCategory: state.codesOthers.codeJewelryCategory,
  codeItemConditions: state.codesItem.codeItemConditions,
  codeItemMakes: state.codesItem.codeItemMakes,
  codeItemModels: state.codesItem.codeItemModels,
});

export default connect(mapStateToProps, {
  updateIncPropertyJewelryForm,
  getCodeSex,
  getCodeMaterial,
  getCodeItemModels,
  getCodeItemMakes,
  getCodeItemConditions,
  getCodeJewelryCateogory,
  updateIncEvidenceItemForm,
})(TabJewelry);
