import store from '../../config/configureStore';

export const SET_CODE_INCIDENT_CATEGORIES = 'CODE/SET_CODE_INCIDENT_CATEGORIES';
export const ADD_CODE_INCIDENT_CATEGORIES = 'CODE/ADD_CODE_INCIDENT_CATEGORIES';
export const SET_CODE_INCIDENT_TYPES = 'CODE/SET_CODE_INCIDENT_TYPES';
export const ADD_CODE_INCIDENT_TYPES = 'CODE/ADD_CODE_INCIDENT_TYPES';

export const getCodeIncidentTypes = () => {
  const client = store.store.getState().websocket.websocket;
  return async (dispatch) => {
    try {
      if (!client) {
        return;
      }

      const service = client.service('codeincidenttypes');
      service.timeout = 20000;
      const codes = await service.find({
        query: {
          $select: ['Code', 'Description'],
          IsDeleted: false,
        },
      });
      dispatch({ type: SET_CODE_INCIDENT_TYPES, payload: codes.data });
    } catch (error) {
      if (error.code === 401) {
        console.log('Unauthenticated');
      } else {
        console.log(error);
      }
    }
  };
};

export const addCodeIncidentTypes = (state) => {
  return { type: ADD_CODE_INCIDENT_TYPES, payload: state };
};

export const addCodeIncidentCategories = (state) => {
  return { type: ADD_CODE_INCIDENT_CATEGORIES, payload: state };
};

export const getCodeIncidentCategories = () => {
  const client = store.store.getState().websocket.websocket;
  return async (dispatch) => {
    try {
      if (!client) {
        return;
      }

      const service = client.service('codeincidentcategories');
      service.timeout = 20000;
      const codes = await service.find({
        query: {
          $select: ['Code', 'Description'],
          IsDeleted: false,
        },
      });
      dispatch({ type: SET_CODE_INCIDENT_CATEGORIES, payload: codes.data });
    } catch (error) {
      if (error.code === 401) {
        console.log('Unauthenticated');
      } else {
        console.log(error);
      }
    }
  };
};

export default function reducer(
  state = {
    codeIncidentCategories: [],
    codeIncidentTypes: [],
  },
  action
) {
  // eslint-disable-next-line default-case
  switch (action.type) {
    case SET_CODE_INCIDENT_TYPES:
      return { ...state, codeIncidentCategories: action.payload };
    case SET_CODE_INCIDENT_CATEGORIES:
      return { ...state, codeIncidentTypes: action.payload };
    case ADD_CODE_INCIDENT_CATEGORIES:
      return {
        ...state,
        codeIncidentCategories: [...state.codeIncidentCategories, action.payload],
      };
    case ADD_CODE_INCIDENT_TYPES:
      return {
        ...state,
        codeIncidentTypes: [...state.codeIncidentTypes, action.payload],
      };
    default:
      return state;
  }
}
