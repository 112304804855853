export const GET_CODE_PROPERTY_LOSS_TYPE = 'GET_CODE_PROPERTY_LOSS_TYPE';
export const ADD_CODE_PROPERTY_LOSS_TYPE = 'ADD_CODE_PROPERTY_LOSS_TYPE';

export const addCodePropertyLossType = (newValue) => {
  return { type: ADD_CODE_PROPERTY_LOSS_TYPE, payload: newValue };
};

export const getCodePropertyLossType = (state) => {
  return async (dispatch) => {
    return dispatch({ type: GET_CODE_PROPERTY_LOSS_TYPE, payload: state });
  };
};

export default function reducer(state = [], action) {
  switch (action.type) {
    case GET_CODE_PROPERTY_LOSS_TYPE:
      state = action.payload;
      return state;
    case ADD_CODE_PROPERTY_LOSS_TYPE:
      return [...state, action.payload];
    default:
      return state;
  }
}
