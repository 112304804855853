/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { connect } from 'react-redux';

import clsx from 'clsx';
import PerfectScrollbar from 'react-perfect-scrollbar';
import moment from 'moment-timezone';
import Grid from '@material-ui/core/Grid';
import Avatar from '@material-ui/core/Avatar';
import makeStyles from '@material-ui/core/styles/makeStyles';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import Dialog from '@material-ui/core/Dialog';

import { colors } from '../../config/colorVariables';
import RMSAddButton from '../../components/RMSButtons/RMSAddButton';
import RMSSimpleList from '../../components/RMSList/RMSSimpleList';
import StorageForm from '../../components/RMSForms/RMSEvidenceForm/StorageForm';
import StorageFormAdd from '../../components/RMSForms/RMSEvidenceForm/StorageForm/Add';
import {
  removeIncEvidenceStorageDetails,
  updateIncEvidenceStorageForm,
  setSelectedEvidenceStorage,
  updateIncEvidenceStorageFormNew,
} from 'reducers/IncReducer';

const useStyles = makeStyles((theme) => ({
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: colors.cyan,
    marginRight: theme.spacing(2),
  },
  dialogBox: {
    minWidth: '412px',
    minHeight: '150px',
  },
  scrollBar: {
    width: '100%',
    overflowX: 'hidden',
    paddingBottom: '10px',
    [theme.breakpoints.up('lg')]: {
      height: '60vh',
    },
    [theme.breakpoints.down('lg')]: {
      height: '40vh',
    },
    [theme.breakpoints.only('lg')]: {
      height: '55vh',
    },
  },
}));

export const StorageAvatar = (props) => {
  const { label } = props;
  const classes = useStyles();

  return <Avatar className={clsx(classes.avatar)}>{label}</Avatar>;
};

const TabEvidenceStorage = (props) => {
  const {
    incEvidenceStorage,
    updateIncEvidenceStorageFormNew,
    updateIncEvidenceStorageForm,
    selectedEvidenceStorage,
    removeIncEvidenceStorageDetails,
    setSelectedEvidenceStorage,
  } = props;
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [isAdd, setIsAdd] = React.useState(true);
  const [listItems, setListItems] = React.useState([]);

  const openModal = () => {
    setIsAdd(true);
    updateIncEvidenceStorageFormNew({
      isValid: false,
      values: {},
      touched: {},
      errors: {},
    });
    setOpen(true);
  };

  const closeDialogBox = () => {
    setOpen(false);
  };

  const handleDelete = () => {
    let updatedList = listItems.filter((s) => s.id !== selectedEvidenceStorage);
    setListItems(updatedList);
    removeIncEvidenceStorageDetails();
    setSelectedEvidenceStorage(updatedList[0] ? updatedList[0].id : '');
  };

  const handleEdit = () => {
    setIsAdd(false);
    updateIncEvidenceStorageForm({
      isValid: false,
      values: {},
      touched: {},
      errors: {},
    });
    setOpen(true);
  };

  const createListItems = () => {
    const items = [];
    incEvidenceStorage.forEach((s) => {
      let item = {
        id:
          s.incEvidenceStorageDetails.ptsEvidenceStorageId ||
          s.incEvidenceStorageDetails.tempEvidenceStorageId,
        type: 'storage',
        icon: <StorageAvatar label="M" />,
        location: s.incEvidenceStorageDetails.values.storageLocation,
        sub: s.incEvidenceStorageDetails.values.subLocation,
        container: s.incEvidenceStorageDetails.values.container,
        date: s.incEvidenceStorageDetails.values.dateIn
          ? moment(s.incEvidenceStorageDetails.values.dateIn).format('MM/DD/YYYY hh:mm A')
          : null,
      };
      items.push(item);
    });
    setListItems(items);
  };

  React.useEffect(() => {
    createListItems();
  }, [incEvidenceStorage, incEvidenceStorage.length]);

  return (
    <Grid container justify="center">
      <Dialog
        classes={{
          paperWidthSm: classes.dialogBox,
        }}
        open={open}
        onClose={closeDialogBox}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <DialogTitle
          className={'d-flex justify-content-between align-items-center'}
          id="alert-dialog-title"
          style={{ background: '#1976d2' }}>
          <span style={{ fontSize: '18px', color: 'white' }}>
            {isAdd === true ? 'Add Evidence Storage' : 'Edit Evidence Storage'}
          </span>
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {isAdd === true && (
              <StorageFormAdd dialogToggle={setOpen} reloadItems={createListItems} />
            )}
            {isAdd === false && (
              <StorageForm dialogToggle={setOpen} reloadItems={createListItems} />
            )}
          </DialogContentText>
        </DialogContent>
      </Dialog>
      <Grid container justify="center">
        <Grid container spacing={2} className="mb-3 mt-3">
          <Grid item className="mr-2">
            <RMSAddButton onClick={openModal} />
          </Grid>
        </Grid>
        <Grid container justify="center">
          <Grid item xs={12} lg={12}>
            <PerfectScrollbar className={classes.scrollBar}>
              <RMSSimpleList
                listItems={listItems}
                listItemDelete={handleDelete}
                listItemEdit={handleEdit}
                entity="storage"
              />
            </PerfectScrollbar>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

const mapStateToProps = (state) => ({
  incEvidenceStorage: state.incident.incEvidenceStorage,
  evidenceStorageForm: state.incident.evidenceStorageForm,
  evidenceStorageFormNew: state.incident.evidenceStorageFormNew,
  selectedEvidenceStorage: state.incident.selectedEvidenceStorage,
});

export default connect(mapStateToProps, {
  updateIncEvidenceStorageForm,
  updateIncEvidenceStorageFormNew,
  setSelectedEvidenceStorage,
  removeIncEvidenceStorageDetails,
})(TabEvidenceStorage);
