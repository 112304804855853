import React from 'react';
import Grid from '@material-ui/core/Grid';
import { connect } from 'react-redux';
import { updateIncOffensesDVVictimSubForm } from '../../../../reducers/IncReducer';
import { handleFormChangeNew } from '../../../../reducers/helpers/formHelpers';
import FormControl from '@material-ui/core/FormControl';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';

const selections = [
  { key: 'IsMigrantFarm', value: 'Migrant Farm' },
  { key: 'IsImmigrant', value: 'Immigrant' },
  { key: 'IsLesbian', value: 'Lesbian' },
  { key: 'IsWomenAtRisk', value: 'Women At Risk' },
];

const useStyles = makeStyles((theme) => ({
  formControl: (props) => ({
    margin: theme.spacing(1),
    minWidth: '100%',
    maxWidth: '100%',
  }),
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chip: {
    margin: 2,
  },
  noLabel: {
    marginTop: theme.spacing(3),
  },
}));

const SpecialNeedsForm = (props) => {
  const classes = useStyles(props);
  const {
    offensesDVVictimForm,
    updateIncOffensesDVVictimSubForm,
    dvVictims,
    selectedVictim,
  } = props;

  const [offensesDVVictimFormState, setOffensesDVVictimFormState] = React.useState({
    isValid: false,
    changes: false,
    values: {},
    touched: {},
    errors: {},
  });

  React.useEffect(() => {
    async function fetch() {
      let dvVictimDetails = dvVictims.find(
        (dvVictim) => dvVictim.ptsIncPersonId === selectedVictim
      );
      if (dvVictimDetails) {
        await updateIncOffensesDVVictimSubForm(dvVictimDetails);
        await setOffensesDVVictimFormState(dvVictimDetails);
      }
    }
    // Fetching current selected victim's dv details
    fetch();
  }, []);

  React.useEffect(() => {
    setOffensesDVVictimFormState({
      ...offensesDVVictimFormState,
      values: { ...offensesDVVictimFormState.values, ...offensesDVVictimForm.values },
    });
  }, [offensesDVVictimForm]);

  React.useEffect(
    () => {
      offensesDVVictimFormState.changes = true;
      updateIncOffensesDVVictimSubForm(offensesDVVictimFormState);
    },
    selections.map((s) => offensesDVVictimFormState.values[s.key])
  );

  return (
    <Grid container justify="center">
      <Grid item xs={10} lg-={10}>
        <FormControl size="small" className={classes.formControl}>
          <Grid>
            {selections.map((selection) => (
              <FormControlLabel
                control={
                  <Checkbox
                    checked={offensesDVVictimFormState.values[selection.key] || false}
                    name={selection.key}
                    onChange={(event) =>
                      handleFormChangeNew(
                        event,
                        event.target.checked,
                        'none',
                        selection.key,
                        setOffensesDVVictimFormState
                      )
                    }
                  />
                }
                label={selection.value}
              />
            ))}
          </Grid>
        </FormControl>
      </Grid>
    </Grid>
  );
};

const mapStateToProps = (state) => ({
  offensesDVVictimForm: state.incident.offensesDVVictimForm,
  selectedVictim: state.incident.selectedVictim,
  dvVictims: state.incident.dvVictims,
});

export default connect(mapStateToProps, { updateIncOffensesDVVictimSubForm })(SpecialNeedsForm);
