/* eslint-disable default-case */
/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { connect } from 'react-redux';

import clsx from 'clsx';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  Avatar,
  Grid,
  Dialog,
  Button,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { colors } from '../../../config/colorVariables';
import RMSSimpleList from '../RMSSimpleList';
import {
  updateIncSubjectsDetails,
  updateIncPropertyDetails,
  setDialogListSubjects,
  setDialogListVictims,
  setDialogListProperties,
  setDialogListArrestees,
  setDialogListEvidences,
  updateIncVictimsDetails,
  updateIncArresteeDetails,
  setDialogListSuspects,
  updateIncSuspectDetails,
  updateIncEvidenceItemForm,
  setSearchKeyword,
} from '../../../reducers/IncReducer';
import { setHandleIncEvidenceFormSave } from 'reducers/ui/UiMenuReducer';

const useStyles = makeStyles((theme) => ({
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: colors.cyan,
    marginRight: theme.spacing(2),
  },
  female: {
    margin: theme.spacing(1),
    backgroundColor: colors.pink,
    marginRight: theme.spacing(2),
  },
  offender: {
    margin: theme.spacing(1),
    backgroundColor: colors.red,
    marginRight: theme.spacing(2),
  },
  dialogBox: {
    minWidth: '1100px',
    minHeight: '150px',
  },
  scrollBar: {
    width: '100%',
    overflowX: 'hidden',

    paddingRight: '5px',
    [theme.breakpoints.up('lg')]: {
      height: '50vh',
    },
    [theme.breakpoints.down('lg')]: {
      height: '45vh',
    },
    [theme.breakpoints.only('lg')]: {
      height: '50vh',
    },
  },
}));

export const OffenderAvatar = (props) => {
  const { label } = props;
  const classes = useStyles();

  return (
    <Avatar
      className={clsx(
        label === 'M' && classes.avatar,
        label === 'F' && classes.female,
        label === 'O' && classes.offender
      )}>
      {label}
    </Avatar>
  );
};

export const PropertyAvatar = (props) => {
  const { label } = props;
  const classes = useStyles();
  return <Avatar className={clsx(classes.avatar)}>{label}</Avatar>;
};

const DialogList = (props) => {
  const [open, setOpen] = React.useState(props.dialogBox);
  const [changeKey, setChangeKey] = React.useState(0);
  const classes = useStyles();
  const {
    parties,
    properties,
    offensesOffenseForm,
    incSubjects,
    dvSuspects,
    incVictims,
    incProperties,
    incArrestees,
    evidences,
    listDialogSubjects,
    listDialogVictims,
    listDialogSuspects,
    listDialogProperties,
    listDialogArrestees,
    listDialogEvidences,
    setDialogListSubjects,
    setDialogListEvidences,
    setDialogListVictims,
    setDialogListProperties,
    setDialogListArrestees,
    setDialogListSuspects,
    updateIncVictimsDetails,
    updateIncSubjectsDetails,
    updateIncPropertyDetails,
    updateIncArresteeDetails,
    updateIncSuspectDetails,
    updateIncEvidenceItemForm,
    currentOffense,
    selectedVictim,
    dialogBox,
    closeDialogBox,
    title,
    type,
    addToSimpleList,
    setHandleIncEvidenceFormSave,
    setSearchKeyword,
    searchKeyword,
  } = props;

  React.useEffect(() => {
    setOpen(dialogBox);
  }, [dialogBox]);

  React.useEffect(() => {
    createList();
  }, [
    incSubjects,
    incVictims,
    incProperties,
    incArrestees,
    parties,
    evidences,
    properties,
    dvSuspects,
  ]);

  const createList = () => {
    if (props.type === 'offenders') {
      if (incSubjects !== undefined && parties !== undefined && parties.length > 0) {
        let newList = [];

        parties.forEach((party) => {
          let s = '';

          if (!party.ptsIncPersonId) {
            s = incSubjects.find((x) => x.incSubjectDetails.tempPartyId === party.tempPartyId);
          } else {
            s = incSubjects.find(
              (x) => x.incSubjectDetails.ptsIncPersonId === party.ptsIncPersonId
            );
          }

          if (!s && party.personDetail.values.partyType.toLowerCase() === 'offender') {
            let subject = {
              id: party?.ptsIncPersonId || party?.personDetail?.tempPartyId,
              name: `${party?.personDetail?.values.firstName}${
                party?.personDetail?.values.middleName
                  ? ` ${party?.personDetail?.values.middleName} `
                  : ' '
              }${party?.personDetail?.values.lastName}`,
              icon: (
                <OffenderAvatar
                  label={
                    party?.personDetail?.values.sex === 'MALE'
                      ? 'M'
                      : party?.personDetail?.values.sex === 'FEMALE'
                      ? 'F'
                      : 'U'
                  }
                />
              ),
              age: party?.personDetail?.values.age,
              ethnicity: party?.personDetail?.values.ethnicity,
              type: 'person',
            };

            newList.push(subject);
          }
        });
        setDialogListSubjects(newList);
        setChangeKey(newList.length);
      }
    } else if (props.type === 'victims') {
      if (incVictims !== undefined && parties !== undefined && parties.length > 0) {
        let newList = [];

        parties.forEach((party) => {
          let v = '';

          if (!party.ptsIncPersonId) {
            v = incVictims.find((x) => x.incVictimDetails.tempPartyId === party.tempPartyId);
          } else {
            v = incVictims.find((x) => x.incVictimDetails.ptsIncPersonId === party.ptsIncPersonId);
          }

          if (!v && party.personDetail.values.partyType.toLowerCase() === 'victim') {
            let victim = {
              id: party?.ptsIncPersonId || party?.personDetail?.tempPartyId,
              name: `${party?.personDetail?.values.firstName}${
                party?.personDetail?.values.middleName
                  ? ` ${party?.personDetail?.values.middleName} `
                  : ' '
              }${party?.personDetail?.values.lastName}`,
              icon: (
                <OffenderAvatar
                  label={
                    party?.personDetail?.values.sex === 'MALE'
                      ? 'M'
                      : party?.personDetail?.values.sex === 'FEMALE'
                      ? 'F'
                      : 'U'
                  }
                />
              ),
              age: party?.personDetail?.values.age,
              ethnicity: party?.personDetail?.values.ethnicity,
              type: 'person',
            };

            newList.push(victim);
          }
        });
        setDialogListVictims(newList);
        setChangeKey(newList.length);
      }
    } else if (props.type === 'properties') {
      if (incProperties !== undefined && properties !== undefined && properties.length > 0) {
        let newList = [];
        properties.forEach((property) => {
          let p = '';
          if (!property.propertyDetails.incIncidentPropertyId) {
            p = incProperties.find(
              (x) => x.incPropertyDetails.tempPropId === property.propertyDetails.tempPropId
            );
          } else {
            p = incProperties.find(
              (x) =>
                x.incPropertyDetails.incIncidentPropertyId ===
                property.propertyDetails.incIncidentPropertyId
            );
          }

          if (!p) {
            let saveProperty = {
              id:
                property?.propertyDetails?.values.incIncidentPropertyId ||
                property?.propertyDetails?.tempPropId,
              name: `${
                property?.propertyDetails.values.description ||
                property?.propertyDetails.values.name
              }`,
              icon: <PropertyAvatar />,
              description: `${property?.propertyDetails.values.propertyDescription}`,
              propertyDescription: '',
              propertyLossType: '',
              type: 'property',
            };

            newList.push(saveProperty);
          }
        });
        setDialogListProperties(newList);
        setChangeKey(newList.length);
      }
    } else if (props.type === 'arrestees') {
      if (
        incSubjects !== undefined &&
        incArrestees !== undefined &&
        incSubjects.length > 0 &&
        parties !== undefined &&
        parties.length > 0
      ) {
        let newList = [];
        incSubjects.forEach((is) => {
          let s = '';
          let party = '';

          if (!is.incSubjectDetails.ptsIncPersonId) {
            s = incArrestees.find(
              (x) => x.incArresteeDetails.tempPartyId === is.incSubjectDetails.tempPartyId
            );

            party = parties.find(
              (p) => p.personDetail.tempPartyId === is.incSubjectDetails.tempPartyId
            );
          } else {
            s = incArrestees.find(
              (x) => x.incArresteeDetails.ptsIncPersonId === is.incSubjectDetails.ptsIncPersonId
            );

            party = parties.find((p) => p.ptsIncPersonId === is.incSubjectDetails.ptsIncPersonId);
          }

          if (!s && party) {
            let arrestee = {
              id: party?.ptsIncPersonId || party?.personDetail?.tempPartyId,
              ptsOffenseId: is.incSubjectDetails.ptsOffenseId,
              name: `${party?.personDetail?.values.firstName}${
                party?.personDetail?.values.middleName
                  ? ` ${party?.personDetail?.values.middleName} `
                  : ' '
              }${party?.personDetail?.values.lastName}`,
              icon: (
                <OffenderAvatar
                  label={
                    party?.personDetail?.values.sex === 'MALE'
                      ? 'M'
                      : party?.personDetail?.values.sex === 'FEMALE'
                      ? 'F'
                      : 'U'
                  }
                />
              ),
              age: party?.personDetail?.values.age,
              ethnicity: party?.personDetail?.values.ethnicity,
              type: 'person',
            };

            newList.push(arrestee);
          }
        });
        setDialogListArrestees(newList);
        setChangeKey(newList.length);
      }
    } else if (type === 'suspects') {
      if (dvSuspects !== undefined && parties !== undefined && parties.length > 0) {
        let newList = [];

        parties.forEach((party) => {
          let v = '';
          if (!party.ptsIncPersonId) {
            v = dvSuspects.find((x) => x.incSuspectDetails.tempPartyId === party.tempPartyId);
          } else {
            v = dvSuspects.find((x) => x.incSuspectDetails.ptsIncPersonId === party.ptsIncPersonId);
          }

          // Checking if this party is in incSubject or not
          if (!v) {
            v = incSubjects.find(
              (x) => x.incSubjectDetails.ptsIncPersonId === party.ptsIncPersonId
            );
          }

          if (
            !v &&
            (party.personDetail.values.partyType.toLowerCase() === 'offender' ||
              party.personDetail.values.partyType.toLowerCase() === 'suspect')
          ) {
            let suspect = {
              id: party?.ptsIncPersonId || party?.personDetail?.tempPartyId,
              name: `${party?.personDetail?.values.firstName}${
                party?.personDetail?.values.middleName
                  ? ` ${party?.personDetail?.values.middleName} `
                  : ' '
              }${party?.personDetail?.values.lastName}`,
              icon: (
                <OffenderAvatar
                  label={
                    party?.personDetail?.values.sex === 'MALE'
                      ? 'M'
                      : party?.personDetail?.values.sex === 'FEMALE'
                      ? 'F'
                      : 'U'
                  }
                />
              ),
              age: party?.personDetail?.values.age,
              ethnicity: party?.personDetail?.values.ethnicity,
              type: 'person',
            };

            newList.push(suspect);
          }
        });
        setDialogListSuspects(newList);
        setChangeKey(newList.length);
      }
    } else if (type === 'evidence') {
      if (properties !== undefined && properties.length > 0) {
        let newList = [];
        properties.forEach((property) => {
          let v = evidences.find(
            (x) =>
              x.itemRecordDetail.incIncidentPropertyId ===
              property?.propertyDetails?.values.incIncidentPropertyId
          );

          if (!v) {
            let saveProperty = {
              id:
                property?.propertyDetails?.values.incIncidentPropertyId ||
                property?.propertyDetails?.tempPropId,
              name: `${
                property?.propertyDetails.values.description ||
                property?.propertyDetails.values.name
              }`,
              icon: <PropertyAvatar />,
              description: `${property?.propertyDetails.values.propertyDescription}`,
              propertyDescription: '',
              propertyLossType: '',
              type: 'property',
            };

            newList.push(saveProperty);
          }
        });
        setDialogListEvidences(newList);
        setChangeKey(newList.length);
      } else {
        setDialogListEvidences([]);
      }
    }
  };

  const handleListItemClick = (event, index, row) => {
    let itemId = row.id;
    console.log(row);
    let newList = [];
    switch (type) {
      case 'offenders':
        newList = listDialogSubjects.filter((s) => s.id !== itemId);
        if (newList.length === 0) closeDialogBox();
        setDialogListSubjects(newList);
        updateIncSubjectsDetails(
          {
            ptsIncPersonId: itemId,
            ptsOffenseId: offensesOffenseForm.ptsOffenseId || currentOffense,
            values: {},
          },
          true
        );
        break;
      case 'victims':
        newList = listDialogVictims.filter((s) => s.id !== itemId);
        if (newList.length === 0) closeDialogBox();
        setDialogListVictims(newList);
        updateIncVictimsDetails(
          {
            ptsIncPersonId: itemId,
            ptsOffenseId: offensesOffenseForm.ptsOffenseId || currentOffense,
            values: {},
          },
          true
        );
        break;
      case 'properties':
        newList = listDialogProperties.filter((s) => s.id !== itemId);
        if (newList.length === 0) closeDialogBox();
        setDialogListProperties(newList);
        updateIncPropertyDetails(
          {
            incIncidentPropertyId: itemId,
            ptsOffenseId: offensesOffenseForm.ptsOffenseId || currentOffense,
            values: {
              parentType: row.parentType,
            },
          },
          true
        );
        break;
      case 'arrestees':
        newList = listDialogArrestees.filter((s) => s.id !== itemId);
        if (newList.length === 0) closeDialogBox();
        setDialogListArrestees(newList);
        updateIncArresteeDetails(
          {
            ptsIncPersonId: itemId,
            ptsOffenseId: offensesOffenseForm.ptsOffenseId || currentOffense,
            values: {},
          },
          true
        );
        break;
      case 'suspects':
        newList = listDialogSuspects.filter((s) => s.id !== itemId);
        if (newList.length === 0) closeDialogBox();
        setDialogListSuspects(newList);
        updateIncSuspectDetails(
          {
            ptsIncPersonId: itemId,
            ptsVictimPersonId: selectedVictim,
            ptsVictimId: incVictims.find(
              (x) => x.incVictimDetails.ptsIncPersonId === selectedVictim
            )?.incVictimDetails?.ptsVictimId,
            ptsOffenseId: offensesOffenseForm.ptsOffenseId || currentOffense,
            values: {},
          },
          true
        );
        break;
      case 'evidence':
        newList = listDialogEvidences.filter((s) => s.id !== itemId);
        if (newList.length === 0) closeDialogBox();
        setDialogListEvidences(newList);
        properties.map(async (p) => {
          if (p.propertyDetails.values.incIncidentPropertyId === itemId) {
            console.log(p.propertyDetails);
            updateIncEvidenceItemForm({
              changes: true,
              values: {
                description: `${p.propertyDetails.values?.name || ''} ${
                  p.propertyDetails.values?.description || ''
                } ${p.propertyDetails.values?.make || ''}`,
                make: p.propertyDetails.values?.make,
                category: 'Property',
                model: p.propertyDetails.values?.model,
                condition: p.propertyDetails.values?.condition,
                serialNumber: p.propertyDetails.values?.serialNumber,
                quantity: p.propertyDetails.values?.quantity,
                licenseNumber: p.propertyDetails.values?.plateNumber,
                substanceType: p.propertyDetails.values?.substanceType,
                vin: p.propertyDetails.values.vin,
                incIncidentPropertyId: p.propertyDetails.values.incIncidentPropertyId,
              },
            });

            await setHandleIncEvidenceFormSave();
            setSearchKeyword({
              partiesKeyword: '',
              propertiesKeyword: '',
              offensesKeyword: '',
              evidencesKeyword: '',
            });
          }
        });

        break;
    }
    if (type !== 'evidence') addToSimpleList();
  };

  return (
    <Dialog
      classes={{
        paperWidthSm: classes.dialogBox,
      }}
      open={open}
      onClose={closeDialogBox}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description">
      <DialogTitle
        className={'d-flex justify-content-between align-items-center'}
        style={{ background: '#1976d2' }}
        id="alert-dialog-title">
        <span style={{ fontSize: '16px', color: 'white' }}>{title}</span>
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {type === 'offenders' && (
            <PerfectScrollbar className={classes.scrollBar}>
              <RMSSimpleList
                key={changeKey}
                listItems={listDialogSubjects}
                entity="offenders"
                origin="fromModal"
                handleListItemClick={handleListItemClick}
              />
            </PerfectScrollbar>
          )}

          {type === 'victims' && (
            <PerfectScrollbar className={classes.scrollBar}>
              <RMSSimpleList
                key={changeKey}
                listItems={listDialogVictims}
                entity="victims"
                origin="fromModal"
                handleListItemClick={handleListItemClick}
              />
            </PerfectScrollbar>
          )}

          {type === 'properties' && (
            <PerfectScrollbar className={classes.scrollBar}>
              <RMSSimpleList
                key={changeKey}
                listItems={listDialogProperties}
                entity="properties"
                origin="fromModal"
                handleListItemClick={handleListItemClick}
              />
            </PerfectScrollbar>
          )}

          {type === 'arrestees' && (
            <PerfectScrollbar className={classes.scrollBar}>
              <RMSSimpleList
                key={changeKey}
                listItems={listDialogArrestees}
                entity="arrestees"
                origin="fromModal"
                handleListItemClick={handleListItemClick}
              />
            </PerfectScrollbar>
          )}

          {type === 'suspects' && (
            <PerfectScrollbar className={classes.scrollBar}>
              <RMSSimpleList
                key={changeKey}
                listItems={listDialogSuspects}
                entity="suspects"
                origin="fromModal"
                handleListItemClick={handleListItemClick}
              />
            </PerfectScrollbar>
          )}

          {type === 'evidence' && (
            <PerfectScrollbar className={classes.scrollBar}>
              <RMSSimpleList
                key={changeKey}
                listItems={listDialogEvidences}
                entity="properties"
                origin="fromModal"
                handleListItemClick={handleListItemClick}
              />
            </PerfectScrollbar>
          )}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Grid
              container
              direction="row"
              justify="flex-end"
              alignItems="center"
              style={{ padding: 5, margin: '-15px 0px 10px' }}>
              <Button onClick={closeDialogBox}>Close</Button>
            </Grid>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );
};

const mapStateToProps = (state) => ({
  incSubjects: state.incident.incSubjects,
  parties: state.incident.parties,
  properties: state.incident.properties,
  incVictims: state.incident.incVictims,
  incArrestees: state.incident.incArrestees,
  incProperties: state.incident.incProperties,
  dvSuspects: state.incident.dvSuspects,
  evidences: state.incident.evidences,
  offensesOffenseForm: state.incident.offensesOffenseForm,
  listDialogSubjects: state.incident.listDialogSubjects,
  listDialogVictims: state.incident.listDialogVictims,
  listDialogProperties: state.incident.listDialogProperties,
  listDialogArrestees: state.incident.listDialogArrestees,
  listDialogSuspects: state.incident.listDialogSuspects,
  listDialogEvidences: state.incident.listDialogEvidences,
  currentOffense: state.incident.currentOffense,
  selectedVictim: state.incident.selectedVictim,
  searchKeyword: state.incident.searchKeyword,
});

export default connect(mapStateToProps, {
  setDialogListSubjects,
  setDialogListVictims,
  setDialogListProperties,
  setDialogListArrestees,
  setDialogListSuspects,
  updateIncVictimsDetails,
  updateIncSubjectsDetails,
  updateIncPropertyDetails,
  updateIncArresteeDetails,
  updateIncEvidenceItemForm,
  updateIncSuspectDetails,
  setDialogListEvidences,
  setSearchKeyword,
  setHandleIncEvidenceFormSave,
})(DialogList);
