import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';

import AddEntityFormReducer from './AddEntityFormReducer';
import ThemeReducer from './ThemeReducer';
import WebsocketReducer from './WebsocketReducer';
import NotifierReducer from './NotifierReducer';
import UiReducer from './ui/UiDrawerReducer';
import UiListReducer from './ui/UiListReducer';
import UiMenuReducer from './ui/UiMenuReducer';
import UiLayoutReducer from './ui/UiLayoutReducer';
import UiFormReducer from './ui/UiFormReducer';
import UiImageReducer from './ui/UiImageReducer';
import UiModalReducer from './ui/UiModalReducer';
import UserReducer from './UserReducer';
import AppInfoReducer from './AppInfoReducer';
import IncReducer from './IncReducer';
import RecordsReducer from './RecordsReducer';
import DuplicateReducer from './DuplicateReducer';
import CodeWeatherReducer from './dictionaries/CodeWeatherReducer';
import CodeVictimTreatmentReducer from './dictionaries/CodeVictimTreatmentReducer';
import CodeVictimSubjectRelationshipReducer from './dictionaries/CodeVictimSubjectRelationshipReducer';
import CodeVictimDispositionReducer from './dictionaries/CodeVictimDispositionReducer';
import CodeVictimCategoryReducer from './dictionaries/CodeVictimCategoryReducer';
import CodeVehicleStylesReducer from './dictionaries/CodeVehicleStylesReducer';
import CodeVehicleMakesModelsReducer from './dictionaries/CodeVehicleMakesModelsReducer';
import CodeVehicleMakesReducer from './dictionaries/CodeVehicleMakesReducer';
import CodeVehicleCommercialReducer from './dictionaries/CodeVehicleCommercialReducer';
import CodeVehicleColorsReducer from './dictionaries/CodeVehicleColorsReducer';
import CodeVehicleClassificationsReducer from './dictionaries/CodeVehicleClassificationsReducer';
import CodeVehicleBrandsReducer from './dictionaries/CodeVehicleBrandsReducer';
import CodeVehicleBrandersReducer from './dictionaries/CodeVehicleBrandersReducer';
import CodeTransmissionReducer from './dictionaries/CodeTransmissionReducer';
import CodeSubstanceCategoryReducer from './dictionaries/CodeSubstanceCategoryReducer';
import CodeSubjectWeaponReducer from './dictionaries/CodeSubjectWeaponReducer';
import CodeSubjectStatusReducer from './dictionaries/CodeSubjectStatusReducer';
import CodeSubjectMOReducer from './dictionaries/CodeSubjectMOReducer';
import CodeSubjectInvolvementReducer from './dictionaries/CodeSubjectInvolvementReducer';
import CodeStructureColorReducer from './dictionaries/CodeStructureColorReducer';
import CodeStreetTypesReducer from './dictionaries/CodeStreetTypesReducer';
import CodeStreetDirectionsReducer from './dictionaries/CodeStreetDirectionsReducer';
import CodeStatesReducer from './dictionaries/CodeStatesReducer';
import CodeSexReducer from './dictionaries/CodeSexReducer';
import CodeResidentReducer from './dictionaries/CodeResidentReducer';
import CodeRaceReducer from './dictionaries/CodeRaceReducer';
import CodePropertyTypeReducer from './dictionaries/CodePropertyTypeReducer';
import CodePropertyLossTypeReducer from './dictionaries/CodePropertyLossTypeReducer';
import CodePropertyCategoryReducer from './dictionaries/CodePropertyCategoryReducer';
import CodePropertyDescriptionReducer from './dictionaries/CodePropertyDescriptionReducer';
import CodePlaceTypesReducer from './dictionaries/CodePlaceTypesReducer';
import CodePartyInvolvementTypesReducer from './dictionaries/CodePartyInvolvementTypesReducer';
import CodeOfficerAssignmentReducer from './dictionaries/CodeOfficerAssignmentReducer';
import CodeOfficerActivityReducer from './dictionaries/CodeOfficerActivityReducer';
import CodeOffenseFactorReducer from './dictionaries/CodeOffenseFactorReducer';
import CodeNarrativeTypesReducer from './dictionaries/CodeNarrativeTypesReducer';
import CodeNameSuffixesReducer from './dictionaries/CodeNameSuffixesReducer';
import CodeNamePrefixesReducer from './dictionaries/CodeNamePrefixesReducer';
import CodeMeasuresReducer from './dictionaries/CodeMeasuresReducer';
import CodeMaterialReducer from './dictionaries/CodeMaterialReducer';
import CodeLocationCategoryReducer from './dictionaries/CodeLocationCategoriesReducer';
import CodeItemReducer from './dictionaries/CodeItemReducer';
import CodeAddressReducer from './dictionaries/CodeAddressReducer';
import CodeCitiesReducer from './dictionaries/CodeCitiesReducer';
import CodeEvidenceReducer from './dictionaries/CodeEvidenceReducer';
import CodeArrestReducer from './dictionaries/CodeArrestReducer';
import CodeFirearmReducer from './dictionaries/CodeFirearmReducer';
import CodeGarageReducer from './dictionaries/CodeGarageReducer';
import CodeHairReducer from './dictionaries/CodeHairReducer';
import CodeIncidentReducer from './dictionaries/CodeIncidentReducer';
import CodeOthersReducer from './dictionaries/CodeOthersReducer';
import CodeLightingReducer from './dictionaries/CodeLightingReducer';
import CodeVehiclePropertyReducer from './dictionaries/CodeVehiclePropertyReducer';
import CodeCapabilityTypeReducer from './dictionaries/CodeCapabilityTypeReducer';
import CodeAddressPartyRelationship from './dictionaries/CodeAddressPartyRelationshipReducer';
import CodeMugshotTypesReducer from './dictionaries/CodeMugshotTypesReducer';
import CodeVehiclePlateReducer from './dictionaries/CodeVehiclePlateReducer';
import CadReducer from './dictionaries/CadReducer';

const createRootReducer = (history) =>
  combineReducers({
    router: connectRouter(history),
    theme: ThemeReducer,
    websocket: WebsocketReducer,
    notifier: NotifierReducer,
    ui: UiReducer,
    uiList: UiListReducer,
    uiForm: UiFormReducer,
    uiMenu: UiMenuReducer,
    uiImage: UiImageReducer,
    uiLayout: UiLayoutReducer,
    uiModal: UiModalReducer,
    user: UserReducer,
    appInfo: AppInfoReducer,
    incident: IncReducer,
    codeWeather: CodeWeatherReducer,
    codeAddressPartyRelationship: CodeAddressPartyRelationship,
    codeItem: CodeItemReducer,
    codeVictimTreatment: CodeVictimTreatmentReducer,
    codeVictimSubjectRelationship: CodeVictimSubjectRelationshipReducer,
    codeVictimDisposition: CodeVictimDispositionReducer,
    codeVictimCategory: CodeVictimCategoryReducer,
    codeVehicleStyles: CodeVehicleStylesReducer,
    codeVehicleMakesModels: CodeVehicleMakesModelsReducer,
    codeVehicleMakes: CodeVehicleMakesReducer,
    codeVehicleCommercial: CodeVehicleCommercialReducer,
    codeVehicleColors: CodeVehicleColorsReducer,
    codeVehicleClassifications: CodeVehicleClassificationsReducer,
    codeVehicleBrands: CodeVehicleBrandsReducer,
    codeVehicleBranders: CodeVehicleBrandersReducer,
    codeTransmissions: CodeTransmissionReducer,
    codeSubstanceCategory: CodeSubstanceCategoryReducer,
    codeSubjectWeapon: CodeSubjectWeaponReducer,
    codeSubjectStatus: CodeSubjectStatusReducer,
    codeSubjectMO: CodeSubjectMOReducer,
    codeSubjectInvolvement: CodeSubjectInvolvementReducer,
    codeStructureColor: CodeStructureColorReducer,
    codeStreetTypes: CodeStreetTypesReducer,
    codeStreetDirections: CodeStreetDirectionsReducer,
    codeStates: CodeStatesReducer,
    codeSex: CodeSexReducer,
    codeResident: CodeResidentReducer,
    codeRace: CodeRaceReducer,
    codePropertyType: CodePropertyTypeReducer,
    codePropertyLossType: CodePropertyLossTypeReducer,
    codePropertyCategory: CodePropertyCategoryReducer,
    codePropertyDescription: CodePropertyDescriptionReducer,
    codePlaceTypes: CodePlaceTypesReducer,
    codePartyInvolvementTypes: CodePartyInvolvementTypesReducer,
    codeOfficerAssignment: CodeOfficerAssignmentReducer,
    codeOfficerActivity: CodeOfficerActivityReducer,
    codeOffenseFactor: CodeOffenseFactorReducer,
    codeNarrativeTypes: CodeNarrativeTypesReducer,
    codeNameSuffixes: CodeNameSuffixesReducer,
    codeNamePrefixes: CodeNamePrefixesReducer,
    codeMeasures: CodeMeasuresReducer,
    codeMaterial: CodeMaterialReducer,
    codeLocationCategories: CodeLocationCategoryReducer,
    codeCities: CodeCitiesReducer,
    codesArrest: CodeArrestReducer,
    codesOthers: CodeOthersReducer,
    codesAddress: CodeAddressReducer,
    codesEvidence: CodeEvidenceReducer,
    codesFirearm: CodeFirearmReducer,
    codesHair: CodeHairReducer,
    codesGarage: CodeGarageReducer,
    codesIncident: CodeIncidentReducer,
    codesItem: CodeItemReducer,
    codeLighting: CodeLightingReducer,
    codeCapabilityType: CodeCapabilityTypeReducer,
    codeVehicleProperty: CodeVehiclePropertyReducer,
    codeMugshotTypes: CodeMugshotTypesReducer,
    addEntityForm: AddEntityFormReducer,
    records: RecordsReducer,
    duplicateForm: DuplicateReducer,
    vehiclePlate: CodeVehiclePlateReducer,
    dictionary: CadReducer,
  });

export default createRootReducer;
