import SimpleSchema from 'simpl-schema';
import {
  crimeAgainstPropertyCodes,
  gamblingOffense,
  kidnappingOffenses,
} from 'simpl-schema-validation/helpers/Codes/UCR';
import { codeSubstanceCategory } from 'reducers/_dev/codeSubstanceCategory';

const getSubstanceCategory = (codeSubstanceCategory, substanceType) =>
  codeSubstanceCategory.find((category) => category.description === substanceType);

SimpleSchema.setDefaultMessages({
  messages: {
    en: {
      'NIBRS-2019.2-187':
        'When the Property Loss type = Burned, Counterfeited/Forged, Destroyed/Damaged/Vandalized, Recovered, Seized, or Stolen then Property Description, Value, Date Recovered, Stolen Car Quantity, Recovered Car Quantity must be provided if applicable.',
      'NIBRS-2019.2-188':
        'When the Property Loss type = Stolen then Property Description, Value, Date Recovered, Stolen Car Quantity, Recovered Car Quantity must be provided if applicable.',
      'NIBRS-2019.2-63':
        'When the Offense UCR Code = 200 and Offense Attempted = "false" then the Property Loss type must be BURNED.',
      'NIBRS-2019.2-64':
        'When the Offense UCR Code = 250 and Offense Attempted = "false" then the Property Loss type must be COUNTERFEITED, RECOVERED, or SEIZED.',
      'NIBRS-2019.2-65':
        'When the Offense UCR Code = 290 and Offense Attempted = "false" then the Property Loss type must be DESTROYED_DAMAGED_VANDALIZED.',
      'NIBRS-2019.2-62':
        'When the Offense UCR Code = 35A or 35B and Offense Attempted = "false" then the Property Loss type must be NONE or SEIZED.',
      'NIBRS-2019.2-67':
        'When the Offense UCR Code = 39A, 39B, 39C, or 39D and Offense Attempted = "false" then the Property Loss type must be SEIZED.',
      'NIBRS-2019.2-61':
        'When the Offense UCR Code = 100, 220, or 510 and Offense Attempted = "false" then the Property Loss type must be NONE, RECOVERED, STOLEN, or UNKNOWN.',
      'NIBRS-2019.2-60':
        'When the Offense UCR Code = 280 and Offense Attempted = "false" then the Property Loss type must be NONE or RECOVERED.',
      'NIBRS-2019.2-66':
        'When the Offense UCR Code = 120, 210, 23A-23H, 240, 26A-G, 270 and Offense Attempted = "false" then the Property Loss type must be RECOVERED or STOLEN.',
      'NIBRS-2019.2-71': 'When the Offense = "35B" then you must provide the Property item.',
      'NIBRS-2019.2-73': 'When the Offense = "35A" then you must provide the Substance.',
      'NIBRS-2019.2-90':
        'When the Property Date Recovered is provided, then Property Description and/or Property Value must be provided.',
      'NIBRS-2019.2-68':
        'When the Property Loss type = Unknown then Property Description, Value, Date Recovered, Stolen Car Quantity, Recovered Car Quantity, Drug Type, Drug quantity, and Drug Measure must be omitted.',
      'NIBRS-2019.2-86':
        'When the Property is 09, 22, 48, 65, 66, or 99, then the property value must be zero.',
      'NIBRS-2019.2-87': 'When the Property is 88, then the property value must be $1.',
      'NIBRS-2019.2-83':
        'When the Offense code indicates 23H=All Other Larceny, then Property Description cannot indicate items that would not fit in pocket or purse (03,05,24,28,37).',
      'NIBRS-2019.2-182':
        'A Property must first be submitted with the Property Loss type = Stolen before it can be submitted as Recovered. Only exception is when the UCR Offense Code contains 250 or 280 or when the recovered property = 38 (Vehicle Parts/ Accessories).',
      'NIBRS-2019.2-58':
        'When the Offense UCR Code is Kidnapping/Abduction, Crimes against Property, Drug/Narcotic Offenses, or Gambling Offenses then the Property Loss type is required.',
      'NIBRS-2019.2-59':
        'When the Offense UCR Code is Kidnapping/Abduction, Crimes against Property, Drug/Narcotic Offenses, or Gambling Offenses and Offense Attempted = "true" then the Property Loss type must be NONE or UNKNOWN.',
      'NIBRS-2019.2-81':
        'When the Offense code indicates 23C=Shoplifting, then Property Description cannot indicate items that would not fit in pocket or purse (01,03,05,12,15,18,24,28-35,37,39).',
      'NIBRS-2019.2-80':
        'When the Offense code indicates 23A=Pocket-picking or 23B=Purse-snatching, then Property Description cannot indicate items that would not fit in pocket or purse (01,03-05,12,15,18,24,28-35,37,39,66,78).',
      'NIBRS-2019.2-79':
        'When the Offense code indicates 220=Burglary/Breaking and Entering, 240=Motor Vehicle Theft, then Property Description cannot indicate Structures types 29 - 35.',
      'NIBRS-2019.2-82':
        'When the Offense code indicates 23D=Theft from Building, 23E=Theft from Coin-Operated Machine/Device, 23F=Theft from Motor Vehicle, and 23G=Theft of Motor Vehicle Parts/Accessories, then Property Description cannot indicate items that would not fit in pocket or purse (03,05,24,28-35,37).',
      'NIBRS-2019.2-77':
        'When the Offense code indicates Drug/Narcotic Violations (35A) and the Attempted/Completed is Completed, then you must have a Property Description of 10 = Drugs/Narcotics in the Substance entity with a Property Loss of "SEIZED".',
      'NIBRS-2019.2-69':
        'When the Substance Loss type = Unknown then Substance Description, Substance Value, Date Recovered, Drug Type, Drug quantity, and Drug Measure must be omitted.',
      'NIBRS-2019.2-195':
        'When the Substance Loss type = Seized, then Drug Type, Substance Value, Date Recovered, Drug quantity, and Drug Measure must be provided unless Substance Type code = X-Over 3 Drug Types, then Drug quantity, and Drug Measure must be omitted.',
    },
  },
});

export const incPropertiesSchema = new SimpleSchema({
  propertyItem: {
    type: String,
    label: 'NIBRS-2019.2-71: Property Lose Type',
    optional: true,
    custom() {
      if (this.obj.offense.values.statuteDetails?.FBICode === '35B') {
        let foundItem = this.obj.incProperties.find(
          (ip) => ip.incPropertyDetails.values.parentType === 'ITEM'
        );
        if (!foundItem) {
          return 'NIBRS-2019.2-71';
        }
      }
    },
  },
  propertySubstance: {
    type: String,
    label: 'NIBRS-2019.2-73: Property Lose Type',
    optional: true,
    custom() {
      if (this.obj.offense.values.statuteDetails?.FBICode === '35A') {
        let foundItem = this.obj.incProperties.find(
          (ip) => ip.incPropertyDetails.values.parentType === 'SUBSTANCE'
        );
        if (!foundItem) {
          return 'NIBRS-2019.2-73';
        }
      }
    },
  },
  propertyDescription: {
    type: String,
    label: 'Property Description',
    optional: true,
    custom() {
      if (this.obj.property.values.recoveredDate) {
        if (this.value.length === 0) return 'NIBRS-2019.2-90';
      }

      if (
        this.obj.offense.values.statuteDetails?.FBICode === '23A' ||
        this.obj.offense.values.statuteDetails?.FBICode === '23B'
      ) {
        if (
          [
            '01',
            '03',
            '04',
            '05',
            '12',
            '15',
            '18',
            '24',
            '28',
            '29',
            '30',
            '31',
            '32',
            '33',
            '34',
            '35',
            '37',
            '39',
            '66',
            '78',
          ].includes(this.value)
        ) {
          return 'NIBRS-2019.2-80';
        }
      }

      if (this.obj.offense.values.statuteDetails?.FBICode === '23C') {
        if (
          [
            '01',
            '03',
            '05',
            '12',
            '15',
            '18',
            '24',
            '28',
            '29',
            '30',
            '31',
            '32',
            '33',
            '34',
            '35',
            '37',
            '39',
          ].includes(this.value)
        ) {
          return 'NIBRS-2019.2-81';
        }
      }

      if (this.obj.offense.values.statuteDetails?.FBICode === '23H') {
        if (['03', '05', '25', '28', '37'].includes(this.value)) {
          return 'NIBRS-2019.2-83';
        }
      }

      if (this.obj.offense.values.statuteDetails?.FBICode === '23C') {
        if (
          [
            '01',
            '03',
            '05',
            '12',
            '15',
            '18',
            '24',
            '28',
            '29',
            '30',
            '31',
            '32',
            '33',
            '34',
            '35',
            '37',
            '39',
          ].includes(this.value)
        ) {
          return 'NIBRS-2019.2-81';
        }
      }
      // NIBRS-2019.2-79: Property Description
      if (['220', '240'].includes(this.obj.offense.values.statuteDetails?.FBICode)) {
        if (['29', '30', '31', '32', '33', '34', '35'].includes(this.value)) {
          return 'NIBRS-2019.2-79';
        }
      }

      // NIBRS-2019.2-82: Property Description
      if (['23D', '23E', '23F', '23G'].includes(this.obj.offense.values.statuteDetails?.FBICode)) {
        if (
          ['03', '05', '24', '28', '29', '30', '31', '32', '33', '34', '35', '37'].includes(
            this.value
          )
        ) {
          return 'NIBRS-2019.2-82';
        }
      }
      // NIBRS-2019.2-77: Property Description
      if (
        this.obj.offense.values.statuteDetails?.FBICode === '35A' &&
        this.obj.offense.values.offenseAttempted === true
      ) {
        if (this.value !== '10' || this.obj.status !== 'SEIZED') {
          return 'NIBRS-2019.2-77';
        }
      }
    },
  },
  value: {
    type: SimpleSchema.Integer,
    label: 'NIBRS-2019.2-90: Property Date Recovered',
    optional: true,
    custom() {
      if (this.obj.property.values.recoveredDate) {
        if (this.value?.length === 0 || !this.value) return 'NIBRS-2019.2-90';
      }

      if (
        (['09', '22', '48', '65', '66', '99'].includes(
          this.obj.property.values.propertyDescription
        ) ||
          [9, 22, 48, 65, 66, 99].includes(this.obj.property.values.propertyDescription)) &&
        this.value !== 0
      ) {
        return 'NIBRS-2019.2-86';
      }

      if (
        (this.obj.property.values.propertyDescription === '88' ||
          this.obj.property.values.propertyDescription === 88) &&
        this.value !== 1
      ) {
        return 'NIBRS-2019.2-87';
      }
    },
  },
  status: {
    type: String,
    label: 'Property Lose Type',
    optional: true,
    custom() {
      // NIBRS-2019.2-69: Property Lose Type
      if (this.value === 'UNKNOWN') {
        if (this.obj.originProperty?.values.parentType === 'SUBSTANCE') {
          if (
            this.obj.originProperty.values.quantity ||
            this.obj.originProperty.values.quantityMeasure ||
            this.obj.originProperty.values.substanceType
          ) {
            return 'NIBRS-2019.2-69';
          }
        }
      }

      // NIBRS-2019.2-195: Property Lose Type
      if (this.value === 'SEIZED') {
        if (this.obj.originProperty?.values.parentType === 'SUBSTANCE') {
          if (
            getSubstanceCategory(
              codeSubstanceCategory,
              this.obj.originProperty.values.substanceType
            ).code === 'X'
          ) {
            if (
              this.obj.originProperty.values.quantity ||
              this.obj.originProperty.values.quantityMeasure
            ) {
              return 'NIBRS-2019.2-195';
            }
          } else if (
            !this.obj.originProperty.values.quantity ||
            !this.obj.originProperty.values.quantityMeasure
          ) {
            return 'NIBRS-2019.2-195';
          }
        }
      }

      if (this.value === 'UNKNOWN') {
        if (
          this.obj.property.values.value ||
          this.obj.property.values.stolenVehicles ||
          this.obj.property.values.recoveredVehicles ||
          this.obj.property.values.propertyDescription ||
          this.obj.property.values.recoveredDate
        )
          return 'NIBRS-2019.2-68';
      }

      if (
        (kidnappingOffenses.includes(this.obj.offense.values.statuteDetails?.FBICode) ||
          gamblingOffense.includes(this.obj.offense.values.statuteDetails?.FBICode) ||
          crimeAgainstPropertyCodes.includes(this.obj.offense.values.statuteDetails?.FBICode)) &&
        this.obj.offense.values.offenseAttempted === true
      ) {
        if (this.value !== 'NONE' || this.value !== 'UNKNOWN') {
          return 'NIBRS-2019.2-59';
        }
      }

      if (
        kidnappingOffenses.includes(this.obj.offense.values.statuteDetails?.FBICode) ||
        gamblingOffense.includes(this.obj.offense.values.statuteDetails?.FBICode) ||
        crimeAgainstPropertyCodes.includes(this.obj.offense.values.statuteDetails?.FBICode)
      ) {
        if (!this.value) {
          return 'NIBRS-2019.2-58';
        }
      }

      if (
        this.obj.offense.values.statuteDetails?.FBICode === '200' &&
        this.obj.offense.values.offenseAttempted === false
      ) {
        if (this.value !== 'BURNED') {
          return 'NIBRS-2019.2-63';
        }
      }

      if (
        this.obj.offense.values.statuteDetails?.FBICode === '250' &&
        this.obj.offense.values.offenseAttempted === false
      ) {
        if (
          this.value !== 'COUNTERFEITED' &&
          this.value !== 'RECOVERED' &&
          this.value !== 'SEIZED'
        ) {
          return 'NIBRS-2019.2-64';
        }
      }

      if (
        this.obj.offense.values.statuteDetails?.FBICode === '290' &&
        this.obj.offense.values.offenseAttempted === false
      ) {
        if (this.value !== 'DESTROYED_DAMAGED_VANDALIZED') {
          return 'NIBRS-2019.2-65';
        }
      }

      if (
        (this.obj.offense.values.statuteDetails?.FBICode === '35A' ||
          this.obj.offense.values.statuteDetails?.FBICode === '35B') &&
        this.obj.offense.values.offenseAttempted === false
      ) {
        if (this.value !== 'SEIZED' && this.value !== 'NONE') {
          return 'NIBRS-2019.2-62';
        }
      }

      if (
        (this.obj.offense.values.statuteDetails?.FBICode === '39A' ||
          this.obj.offense.values.statuteDetails?.FBICode === '39B' ||
          this.obj.offense.values.statuteDetails?.FBICode === '39C' ||
          this.obj.offense.values.statuteDetails?.FBICode === '39D') &&
        this.obj.offense.values.offenseAttempted === false
      ) {
        if (this.value !== 'SEIZED') {
          return 'NIBRS-2019.2-67';
        }
      }

      if (
        (this.obj.offense.values.statuteDetails?.FBICode === '100' ||
          this.obj.offense.values.statuteDetails?.FBICode === '220' ||
          this.obj.offense.values.statuteDetails?.FBICode === '510') &&
        this.obj.offense.values.offenseAttempted === false
      ) {
        if (
          this.value !== 'NONE' &&
          this.value !== 'RECOVERED' &&
          this.value !== 'STOLEN' &&
          this.value !== 'UNKNOWN'
        ) {
          return 'NIBRS-2019.2-61';
        }
      }

      if (
        this.obj.offense.values.statuteDetails?.FBICode === '280' &&
        this.obj.offense.values.offenseAttempted === false
      ) {
        if (this.value !== 'NONE' && this.value !== 'RECOVERED') {
          return 'NIBRS-2019.2-60';
        }
      }

      if (
        (this.obj.offense.values.statuteDetails?.FBICode === '120' ||
          this.obj.offense.values.statuteDetails?.FBICode === '210' ||
          this.obj.offense.values.statuteDetails?.FBICode === '240' ||
          this.obj.offense.values.statuteDetails?.FBICode === '23A' ||
          this.obj.offense.values.statuteDetails?.FBICode === '23B' ||
          this.obj.offense.values.statuteDetails?.FBICode === '23C' ||
          this.obj.offense.values.statuteDetails?.FBICode === '23D' ||
          this.obj.offense.values.statuteDetails?.FBICode === '23E' ||
          this.obj.offense.values.statuteDetails?.FBICode === '23F' ||
          this.obj.offense.values.statuteDetails?.FBICode === '23G' ||
          this.obj.offense.values.statuteDetails?.FBICode === '23H' ||
          this.obj.offense.values.statuteDetails?.FBICode === '26A' ||
          this.obj.offense.values.statuteDetails?.FBICode === '26B' ||
          this.obj.offense.values.statuteDetails?.FBICode === '26C' ||
          this.obj.offense.values.statuteDetails?.FBICode === '26D' ||
          this.obj.offense.values.statuteDetails?.FBICode === '26E' ||
          this.obj.offense.values.statuteDetails?.FBICode === '26F' ||
          this.obj.offense.values.statuteDetails?.FBICode === '26G' ||
          this.obj.offense.values.statuteDetails?.FBICode === '270') &&
        this.obj.offense.values.offenseAttempted === false
      ) {
        if (this.value !== 'STOLEN' && this.value !== 'RECOVERED') {
          return 'NIBRS-2019.2-66';
        }
      }

      //NIBRS-2019.2-182: Property Recovered/Stolen Check.
      if (
        this.value === 'RECOVERED' &&
        this.obj.incProperty.incPropertyDetails.values.status !== 'STOLEN'
      ) {
        if (
          this.obj.offense.values.statuteDetails?.FBICode !== '250' &&
          this.obj.offense.values.statuteDetails?.FBICode !== '280' &&
          this.obj.property.values.propertyDescription !== '38'
        ) {
          return 'NIBRS-2019.2-182';
        }
      }

      if (
        this.value === 'BURNED' ||
        this.value === 'COUNTERFEITED' ||
        this.value === 'RECOVERED' ||
        this.value === 'SEIZED' ||
        this.value === 'DESTROYED_DAMAGED_VANDALIZED'
      ) {
        if (
          !this.obj.property.value ||
          !this.obj.property.stolenVehicles ||
          !this.obj.property.recoveredVehicles ||
          !this.obj.property.propertyDescription ||
          !this.obj.property.recoveredDate
        )
          return 'NIBRS-2019.2-187';
      } else if (this.value === 'STOLEN') {
        if (
          !this.obj.property.value ||
          !this.obj.property.stolenVehicles ||
          !this.obj.property.recoveredVehicles ||
          !this.obj.property.propertyDescription ||
          !this.obj.property.recoveredDate
        )
          return 'NIBRS-2019.2-188';
      }
    },
  },
});
