import React from 'react';
import { connect } from 'react-redux';

import PropTypes from 'prop-types';
import clsx from 'clsx';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faVials } from '@fortawesome/free-solid-svg-icons';
import { makeStyles, styled } from '@material-ui/core/styles';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Grid from '@material-ui/core/Grid';
import EmojiTransportationIcon from '@material-ui/icons/EmojiTransportation';
import VerticalSplit from '@material-ui/icons/VerticalSplit';
import AssignmentIcon from '@material-ui/icons/Assignment';
import LibraryBooksIcon from '@material-ui/icons/LibraryBooks';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';

import IncPartiesStep from './IncPartiesStep';
import IncPropertiesStep from './IncPropertiesStep';
import RMSFabButtons from './RMSFabButtons';
import IncOffensesStep from './IncOffensesStep';
import IncEvidencesStep from './IncEvidencesStep';
import IncNarrativesStep from './IncNarrativesStep';
import IncIBRSStep from './IncIBRSStep';
import IncAttachmentStep from './IncAttachmentStep';

import { setHandleClose, setMenuType } from '../../../reducers/ui/UiMenuReducer';
import { setIncFormActiveStep, setIncFormActiveStepCad } from '../../../reducers/ui/UiFormReducer';
import IncSummaryDetails from './IncSummaryDetails';
import IncDispositionStep from './IncDispositionStep';
import IncZonesStep from './IncZonesStep';
import IncAlertsStep from './IncAlertsStep';
import IncNotificationsStep from './IncNotificationsStep';
import IncCadUnitStatusesStep from './IncCadUnitStatusesStep';
import IncCoordinatesStep from './IncCoordinatesStep';
import AttachFileIcon from '@material-ui/icons/AttachFile';
// import AvTimerIcon from '@material-ui/icons/AvTimer';
import {
  AddAlarm,
  NotificationImportant,
  MyLocationOutlined,
  PausePresentationOutlined,
} from '@material-ui/icons';
import { withStyles, Badge } from '@material-ui/core';

import { useSelector } from 'react-redux';

import { errorTree } from '../../../simpl-schema-validation/helpers/errorTree';

const StyledStepLabel = styled(StepLabel)({
  cursor: 'pointer',

  '& .MuiStepLabel-label': {
    color: '#ccc',
    padding: '0px',
    marginTop: '0px',
  },
  '& .MuiStepLabel-active': {
    color: 'white',
  },
});

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  button: {
    marginRight: theme.spacing(1),
  },
  backButton: {
    marginRight: theme.spacing(1),
  },
  completed: {
    display: 'inline-block',
  },
  instructions: {
    marginTop: theme.spacing(1),
  },
  stepper: {},
}));

const useColorlibStepIconStyles = makeStyles({
  root: {
    zIndex: 1,
    color: '#ccc',
    width: 30,
    height: 30,
    display: 'flex',
    borderRadius: '50%',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: '10px',
  },
  active: {
    color: 'black',
    backgroundColor: 'whiteSmoke',
  },
});

const ErrorBadge = withStyles({
  badge: {
    boxShadow: '0 0 0 2px #fff',
    '&::after': {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      borderRadius: '50%',
      animation: '$ripple 1.2s infinite ease-in-out',
      border: '1px solid currentColor',
      content: '""',
    },
  },
  '@keyframes ripple': {
    '0%': {
      transform: 'scale(.8)',
      opacity: 1,
    },
    '100%': {
      transform: 'scale(2.4)',
      opacity: 0,
    },
  },
})(Badge);

const StyledErrorBadge = withStyles({
  badge: {
    backgroundColor: 'var(--danger)',
    color: 'var(--danger)',
  },
})(ErrorBadge);

function ColorlibStepIcon(props) {
  const classes = useColorlibStepIconStyles();
  const { active } = props;

  const icons = {
    1: <FontAwesomeIcon icon="user" style={{ fontSize: '15px' }} />,
    2: <EmojiTransportationIcon style={{ fontSize: '15px' }} />,
    3: <AssignmentIcon style={{ fontSize: '15px' }} />,
    4: <FontAwesomeIcon icon={faVials} style={{ fontSize: '15px' }} />,
    5: <LibraryBooksIcon style={{ fontSize: '15px' }} />,
    6: <VerticalSplit style={{ fontSize: '15px' }} />,
  };

  return (
    <div
      className={clsx(classes.root, {
        [classes.active]: active,
      })}>
      {icons[String(props.icon)]}
    </div>
  );
}

ColorlibStepIcon.propTypes = {
  active: PropTypes.bool,
  completed: PropTypes.bool,
  icon: PropTypes.node,
};

function ColorlibStepIconError(props) {
  const classes = useColorlibStepIconStyles();
  const { active } = props;

  const icons = {
    1: <FontAwesomeIcon icon="user" style={{ fontSize: '15px' }} />,
    2: <EmojiTransportationIcon style={{ fontSize: '15px' }} />,
    3: <AssignmentIcon style={{ fontSize: '15px' }} />,
    4: <FontAwesomeIcon icon={faVials} style={{ fontSize: '15px' }} />,
    5: <LibraryBooksIcon style={{ fontSize: '15px' }} />,
    6: <VerticalSplit style={{ fontSize: '15px' }} />,
  };

  return (
    <StyledErrorBadge
      overlap="circle"
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      variant="dot">
      <div
        className={clsx(classes.root, {
          [classes.active]: active,
        })}>
        {icons[String(props.icon)]}
      </div>
    </StyledErrorBadge>
  );
}

ColorlibStepIconError.propTypes = {
  active: PropTypes.bool,
  completed: PropTypes.bool,
  icon: PropTypes.node,
};

function ColorlibStepCadIcon(props) {
  const classes = useColorlibStepIconStyles();
  const { active } = props;

  const icons = {
    1: <AttachFileIcon style={{ fontSize: '17px' }} />,
    2: <AssignmentIcon style={{ fontSize: '15px' }} />,
    3: <FontAwesomeIcon icon={faVials} style={{ fontSize: '15px' }} />,
    4: <AddAlarm style={{ fontSize: '17px' }} />,
    5: <NotificationImportant style={{ fontSize: '17px' }} />,
    6: <PausePresentationOutlined style={{ fontSize: '17px' }} />,
    7: <MyLocationOutlined style={{ fontSize: '17px' }} />,
  };

  return (
    <div
      className={clsx(classes.root, {
        [classes.active]: active,
      })}>
      {icons[String(props.icon)]}
    </div>
  );
}

ColorlibStepCadIcon.propTypes = {
  active: PropTypes.bool,
  completed: PropTypes.bool,
  icon: PropTypes.node,
};

const RMSIncidentForm = (props) => {
  const {
    history,
    setMenuType,
    setHandleClose,
    activeStep,
    setIncFormActiveStep,
    activeStepCad,
    setIncFormActiveStepCad,
  } = props;
  const classes = useStyles();
  const showIncidentStepper = useSelector((state) => state.uiMenu.showIncidentStepper);
  const steppersWithErrors = useSelector((state) => {
    const valdiationErrors = state.incident.validationErrors;
    const currentIncidentId = state.incident.ptsIncidentId;

    const errorObj = valdiationErrors?.find((error) => error.ptsIncidentId === currentIncidentId);
    if (!errorObj) {
      return [];
    }
    const errorsAssociatedWithSteppers = errorTree;
    let steppersWithErrors = [];

    Object.keys(errorsAssociatedWithSteppers).forEach((stepperError, index) => {
      for (let subStepperError of errorTree[stepperError]) {
        if (errorObj.errors?.[subStepperError]?.length) {
          steppersWithErrors.push(index);
          break;
        }
      }
    });
    if (steppersWithErrors.length) {
      steppersWithErrors.push(5);
    }
    return steppersWithErrors;
  });

  const steps = getSteps();
  const cadImportSteps = getCadImportSteps();

  function getCadImportSteps() {
    return [
      { label: 'Attachments' },
      { label: 'Dispositions' },
      { label: 'Zones' },
      { label: 'Bulletins' },
      { label: 'Notifications' },
      { label: 'Cad Unit Statuses' },
      { label: 'Coordinates' },
    ];
  }

  function getSteps() {
    return [
      { label: 'Parties' },
      { label: 'Properties' },
      { label: 'Offenses' },
      { label: 'Evidence' },
      { label: 'Narratives' },
      { label: 'IBRS' },
    ];
  }

  React.useEffect(() => {
    setMenuType('incident');
    setHandleClose('incidents', history);
  }, []);

  const isStepOptional = (step) => {
    return false;
  };

  const handleStep = (step) => () => {
    setIncFormActiveStep(step);
  };

  const handleStepCad = (step) => () => {
    setIncFormActiveStepCad(step);
  };

  return (
    <div className={classes.root}>
      <RMSFabButtons />
      <Grid container>
        <Grid xs={3} lg={3}>
          <IncSummaryDetails history={history} />
        </Grid>
        <Grid xs={9} lg={9}>
          {!showIncidentStepper && (
            <>
              <Box className={classes.stepper}>
                <Stepper
                  alternativeLabel
                  nonLinear
                  activeStep={activeStepCad}
                  style={{
                    backgroundColor: '#1976d2',
                    borderRadius: '0.5rem',
                    padding: '15px 5px',
                  }}>
                  {cadImportSteps.map((obj, index) => {
                    const stepProps = {};
                    const buttonProps = {};
                    if (isStepOptional(index)) {
                      buttonProps.optional = <Typography variant="caption">Optional</Typography>;
                    }
                    return (
                      <Step key={obj.label} {...stepProps}>
                        <StyledStepLabel
                          StepIconComponent={ColorlibStepCadIcon}
                          onClick={handleStepCad(index)}>
                          {obj.label}
                        </StyledStepLabel>
                      </Step>
                    );
                  })}
                </Stepper>
              </Box>
              <Grid container className="mt-3">
                {activeStepCad === 0 && <IncAttachmentStep />}
                {activeStepCad === 1 && <IncDispositionStep />}
                {activeStepCad === 2 && <IncZonesStep />}
                {activeStepCad === 3 && <IncAlertsStep />}
                {activeStepCad === 4 && <IncNotificationsStep />}
                {activeStepCad === 5 && <IncCadUnitStatusesStep />}
                {activeStepCad === 6 && <IncCoordinatesStep />}
              </Grid>
            </>
          )}
          {showIncidentStepper && (
            <>
              <Box className={classes.stepper}>
                <Stepper
                  alternativeLabel
                  nonLinear
                  activeStep={activeStep}
                  style={{
                    backgroundColor: '#1976d2',
                    borderRadius: '0.5rem',
                    padding: '15px 5px',
                  }}>
                  {steps.map((obj, index) => {
                    const stepProps = {};
                    const buttonProps = {};
                    if (isStepOptional(index)) {
                      buttonProps.optional = <Typography variant="caption">Optional</Typography>;
                    }
                    return (
                      <Step key={obj.label} {...stepProps}>
                        {steppersWithErrors.includes(index) ? (
                          <StyledStepLabel
                            StepIconComponent={ColorlibStepIconError}
                            onClick={handleStep(index)}>
                            {obj.label}
                          </StyledStepLabel>
                        ) : (
                          <StyledStepLabel
                            StepIconComponent={ColorlibStepIcon}
                            onClick={handleStep(index)}>
                            {obj.label}
                          </StyledStepLabel>
                        )}
                      </Step>
                    );
                  })}
                </Stepper>
              </Box>
              <Grid container className="mt-3">
                {activeStep === 0 && <IncPartiesStep />}
                {activeStep === 1 && <IncPropertiesStep />}
                {activeStep === 2 && <IncOffensesStep />}
                {activeStep === 3 && <IncEvidencesStep />}
                {activeStep === 4 && <IncNarrativesStep />}
                {activeStep === 5 && <IncIBRSStep />}
              </Grid>
            </>
          )}
        </Grid>
      </Grid>
    </div>
  );
};

const mapStateToProps = (state) => ({
  activeStep: state.uiForm.incFormActiveStep,
  activeStepCad: state.uiForm.incFormActiveStepCad,
});

export default connect(mapStateToProps, {
  setMenuType,
  setHandleClose,
  setIncFormActiveStep,
  setIncFormActiveStepCad,
})(RMSIncidentForm);
