import store from '../../config/configureStore';

import { offenseDetailsSchema } from 'simpl-schema-validation/schema';

export const validateOffenseForm = (field) => {
  const currentState = store.store.getState().incident;
  const { offensesOffenseForm } = currentState;

  let structuresEntered = parseInt(offensesOffenseForm.values.structuresEntered);

  if (Number.isNaN(structuresEntered)) {
    structuresEntered = 0;
  }

  let objToValidate = {
    offense: offensesOffenseForm,
    locationCategory: offensesOffenseForm.values.locationCategory,
    biasMotivation: offensesOffenseForm.values.biasMotivation,
    criminalActivity: offensesOffenseForm.values.criminalActivity,
    structuresEntered,
    forceCategory: offensesOffenseForm.values.forceCategory,
    methodOfEntry: offensesOffenseForm.values.methodOfEntry,
    offenseAttempted: offensesOffenseForm.values.offenseAttempted
      ? offensesOffenseForm.values.offenseAttempted
      : false,
    offenderSuspectedOfUsing: offensesOffenseForm.values.offenderSuspectedOfUsing,
    ucrCode: offensesOffenseForm.values.statuteDetails
      ? offensesOffenseForm.values.statuteDetails.FBICode
      : null,
    incVictims: currentState.incVictims,
    incSubjects: currentState.incSubjects,
  };

  let offenseValidationContext = offenseDetailsSchema.newContext();
  let result = offenseValidationContext.validate(objToValidate, {
    keys: [field],
  });

  let offensesFormErrors = [];

  if (!result) {
    offenseValidationContext.validationErrors().forEach((error) => {
      if (field === error.name) {
        error.message = offenseValidationContext.keyErrorMessage(error.name);
        offensesFormErrors.push(error);
      }
    });
  }

  return offensesFormErrors || [];
};
