import React, { useState } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation, useParams } from 'react-router';

import {
  FormControl,
  Grid,
  InputLabel,
  makeStyles,
  Select,
  TextField,
  MenuItem,
  Paper,
} from '@material-ui/core';

import RMSFabButtons from 'components/RMSForms/RMSIncidentForm/RMSFabButtons';
import { upsertRecord } from 'reducers/RecordsReducer';
import { setCircularLoading } from 'reducers/ui/UiMenuReducer';
import { handleFormChangeNew } from '../../../../reducers/helpers/formHelpers';

// eslint-disable-next-line unused-imports/no-unused-vars
const useStyles = makeStyles((theme) => ({
  root: {
    height: window.innerHeight - 90 + 'px',
    borderRadius: '0.5rem',
    padding: '15px',
  },
  form: {
    width: '100%',
    textAlign: 'left',
    marginTop: '1px',
  },
  input: {
    width: '100%',
  },
  btnStyle: {
    display: 'block',
    width: '150px',
    padding: '10px',
    marginTop: '15px',

    position: 'relative',
    right: 10,
  },
}));

const PersonCredentialForm = (props) => {
  /** region Component Variables and Props */
  const classes = useStyles();
  let data = useSelector((state) => state.records.formData);
  const location = useLocation();
  const actionType = location.pathname.split('/')[5];
  const { persondId } = useParams();
  const dispatch = useDispatch();
  const history = useHistory();
  const { upsertRecord } = props;
  if (actionType === 'add') {
    data = {};
  }
  const [formData, setFormData] = useState({
    values: {
      ptsCredentialID: data?.ptsCredentialID || undefined,
      ptsPersonID: persondId,
      Type: data?.Type || '',
      Number: data?.Number || '',
    },
  });
  /** endregion */
  /** region Helper Functions */
  const handleSave = () => {
    if (!formData.values.ptsCredentialID) {
      upsertRecord('credential', formData.values, 'add').finally(() => handleFinally());
    } else {
      upsertRecord('credential', formData.values, 'edit').finally(() => handleFinally());
    }
  };

  const handleFinally = () => {
    dispatch(setCircularLoading(false));
    history.goBack();
  };
  /** endregion */
  return (
    <Paper className={classes.root}>
      <form autoComplete="off" className={classes.form}>
        <Grid container xs={12} align="center" spacing={2} justify="left">
          <Grid item xs={4}>
            <FormControl variant="outlined" className={classes.form} size="small">
              <InputLabel htmlFor="outlined-type-native-simple">Type</InputLabel>
              <Select
                small
                value={formData.values?.Type}
                onChange={(event) =>
                  handleFormChangeNew(event, event.target.value, 'none', 'Type', setFormData)
                }
                label="Type"
                inputProps={{
                  name: 'Type',
                  id: 'outlined-type-native-simple',
                }}>
                <MenuItem value={'OLN'}>OLN</MenuItem>
                <MenuItem value={'SSN'}>SSN</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={4}>
            <TextField
              autoComplete="hidden"
              size="small"
              name="Number"
              className={classes.input}
              variant="outlined"
              placeholder="Number"
              label="Number"
              value={formData.values?.Number || ''}
              onChange={(event) =>
                handleFormChangeNew(event, event.target.value, 'none', 'Number', setFormData)
              }
              id="formatted-number-input"
              multiline
              InputLabelProps={{ shrink: true }}
            />
          </Grid>
        </Grid>
      </form>
      <RMSFabButtons recordForm={true} handleSaveRecord={handleSave} />
    </Paper>
  );
};

const mapStateToProps = (state) => ({
  wsClient: state.websocket,
});
export default connect(mapStateToProps, { upsertRecord })(PersonCredentialForm);
