import store from '../../config/configureStore';

export const SET_CODE_BLOOD_TYPE = 'CODE/SET_CODE_BLOOD_TYPE';
export const ADD_CODE_BLOOD_TYPE = 'CODE/ADD_CODE_BLOOD_TYPE';
export const SET_CODE_CAD_STATUSES = 'CODE/SET_CODE_CAD_STATUSES';
export const ADD_CODE_CAD_STATUSES = 'CODE/ADD_CODE_CAD_STATUSES';
export const SET_CODE_CITIES = 'CODE/SET_CODE_CITIES';
export const ADD_CODE_CITIES = 'CODE/ADD_CODE_CITIES';
export const SET_CODE_COORDINATE_DATUM_TYPES = 'CODE/SET_CODE_COORDINATE_DATUM_TYPES';
export const ADD_CODE_COORDINATE_DATUM_TYPES = 'CODE/ADD_CODE_COORDINATE_DATUM_TYPES';
export const SET_CODE_DEA_DRUG_CLASSES = 'CODE/SET_CODE_DEA_DRUG_CLASSES';
export const ADD_CODE_DEA_DRUG_CLASSES = 'CODE/ADD_CODE_DEA_DRUG_CLASSES';
export const SET_CODE_DNA_COLLECTION = 'CODE/SET_CODE_DNA_COLLECTION';
export const ADD_CODE_DNA_COLLECTION = 'CODE/ADD_CODE_DNA_COLLECTION';
export const SET_CODE_DRUG_MEASUREMENTS = 'CODE/SET_CODE_DRUG_MEASUREMENTS';
export const ADD_CODE_DRUG_MEASUREMENTS = 'CODE/ADD_CODE_DRUG_MEASUREMENTS';
export const SET_CODE_HANDED = 'CODE/SET_CODE_HANDED';
export const ADD_CODE_HANDED = 'CODE/ADD_CODE_HANDED';
export const SET_CODE_EYE_COLOR = 'CODE/SET_CODE_EYE_COLOR';
export const ADD_CODE_EYE_COLOR = 'CODE/ADD_CODE_EYE_COLOR';
export const SET_CODE_ETHNICITY = 'CODE/SET_CODE_ETHNICITY';
export const ADD_CODE_ETHNICITY = 'CODE/ADD_CODE_ETHNICITY';
export const SET_CODE_JEWELRRY_CATEGORY = 'CODE/SET_CODE_JEWELRRY_CATEGORY';
export const ADD_CODE_JEWELRRY_CATEGORY = 'CODE/ADD_CODE_JEWELRRY_CATEGORY';
export const SET_CODE_JUVENILE_DISPOSITION = 'CODE/SET_CODE_JUVENILE_DISPOSITION';
export const ADD_CODE_JUVENILE_DISPOSITION = 'CODE/ADD_CODE_JUVENILE_DISPOSITION';

export const addCodeBloodType = (newVal) => {
  return { type: ADD_CODE_BLOOD_TYPE, payload: newVal };
};

export const getCodeBloodType = () => {
  const client = store.store.getState().websocket.websocket;
  return async (dispatch) => {
    try {
      const service = client.service('codebloodtype');
      service.timeout = 20000;
      const codes = await service.find({
        query: {
          $select: ['Code', 'Description'],
          IsDeleted: false,
        },
      });
      dispatch({ type: SET_CODE_BLOOD_TYPE, payload: codes.data });
    } catch (error) {
      if (error.code === 401) {
        console.log('Unauthenticated');
      } else {
        console.log(error);
      }
    }
  };
};

export const addCodeCADStatuses = (newVal) => {
  return { type: ADD_CODE_CAD_STATUSES, payload: newVal };
};

export const getCodeCADStatuses = () => {
  const client = store.store.getState().websocket.websocket;
  return async (dispatch) => {
    try {
      if (!client) {
        return;
      }

      const service = client.service('codecadstatuses');
      service.timeout = 20000;
      const codes = await service.find({
        query: {
          $select: ['Code', 'Description'],
          IsDeleted: false,
        },
      });
      dispatch({ type: SET_CODE_CAD_STATUSES, payload: codes.data });
    } catch (error) {
      if (error.code === 401) {
        console.log('Unauthenticated');
      } else {
        console.log(error);
      }
    }
  };
};

export const addCodeCities = (newVal) => {
  return { type: ADD_CODE_CITIES, payload: newVal };
};

export const getCodeCities = () => {
  const client = store.store.getState().websocket.websocket;
  return async (dispatch) => {
    try {
      const service = client.service('codecities');
      service.timeout = 20000;
      const codes = await service.find({
        query: {
          $select: ['CityDescription', 'ptsCityID'],
          $sort: {
            CityDescription: 1,
          },
          IsDeleted: false,
        },
      });
      dispatch({ type: SET_CODE_CITIES, payload: codes.data });
    } catch (error) {
      if (error.code === 401) {
        console.log('Unauthenticated');
      } else {
        console.log(error);
      }
    }
  };
};

export const addCodeCoordinateDatumTypes = (newValue) => {
  return { type: ADD_CODE_COORDINATE_DATUM_TYPES, payload: newValue };
};

export const getCodeCoordinateDatumTypes = () => {
  const client = store.store.getState().websocket.websocket;
  return async (dispatch) => {
    try {
      const service = client.service('codecoordinatedatumtypes');
      service.timeout = 20000;
      const codes = await service.find({
        query: {
          $select: ['Code', 'Description'],
          $sort: {
            Code: 1,
          },
          IsDeleted: false,
        },
      });
      dispatch({ type: SET_CODE_COORDINATE_DATUM_TYPES, payload: codes.data });
    } catch (error) {
      if (error.code === 401) {
        console.log('Unauthenticated');
      } else {
        console.log(error);
      }
    }
  };
};

export const addCodeDEADrugClasses = (newVal) => {
  return { type: ADD_CODE_DEA_DRUG_CLASSES, payload: newVal };
};

export const getCodeDEADrugClasses = () => {
  const client = store.store.getState().websocket.websocket;
  return async (dispatch) => {
    try {
      const service = client.service('codedeadrugclasses');
      service.timeout = 20000;
      const codes = await service.find({
        query: {
          $select: ['Code', 'Description'],
          IsDeleted: false,
        },
      });
      dispatch({ type: SET_CODE_DEA_DRUG_CLASSES, payload: codes.data });
    } catch (error) {
      if (error.code === 401) {
        console.log('Unauthenticated');
      } else {
        console.log(error);
      }
    }
  };
};

export const addCodeDNACollection = (newVal) => {
  return { type: ADD_CODE_DNA_COLLECTION, payload: newVal };
};

export const getCodeDNACollection = () => {
  const client = store.store.getState().websocket.websocket;
  return async (dispatch) => {
    try {
      const service = client.service('codednacollection');
      service.timeout = 20000;
      const codes = await service.find({
        query: {
          $select: ['Code', 'Description'],
          IsDeleted: false,
        },
      });
      dispatch({ type: SET_CODE_DNA_COLLECTION, payload: codes.data });
    } catch (error) {
      if (error.code === 401) {
        console.log('Unauthenticated');
      } else {
        console.log(error);
      }
    }
  };
};

export const addCodeDrugMeasurements = (newVal) => {
  return { type: ADD_CODE_DRUG_MEASUREMENTS, payload: newVal };
};

export const getCodeDrugMeasurements = () => {
  const client = store.store.getState().websocket.websocket;
  return async (dispatch) => {
    try {
      const service = client.service('codedrugmeasurements');
      service.timeout = 20000;
      const codes = await service.find({
        query: {
          $select: ['Code', 'Description'],
          IsDeleted: false,
        },
      });
      dispatch({ type: SET_CODE_DRUG_MEASUREMENTS, payload: codes.data });
    } catch (error) {
      if (error.code === 401) {
        console.log('Unauthenticated');
      } else {
        console.log(error);
      }
    }
  };
};

export const addCodeEthnicity = (newVal) => {
  return { type: ADD_CODE_ETHNICITY, payload: newVal };
};

export const getCodeEthnicity = () => {
  const client = store.store.getState().websocket.websocket;
  return async (dispatch) => {
    try {
      const service = client.service('codeethnicity');
      service.timeout = 20000;
      const codes = await service.find({
        query: {
          $select: ['Code', 'Description'],
          IsDeleted: false,
        },
      });
      dispatch({ type: SET_CODE_ETHNICITY, payload: codes.data });
    } catch (error) {
      if (error.code === 401) {
        console.log('Unauthenticated');
      } else {
        console.log(error);
      }
    }
  };
};

export const addCodeEyeColor = (newVal) => {
  return { type: ADD_CODE_EYE_COLOR, payload: newVal };
};

export const getCodeEyeColor = () => {
  const client = store.store.getState().websocket.websocket;
  return async (dispatch) => {
    try {
      const service = client.service('codeeyecolor');
      service.timeout = 20000;
      const codes = await service.find({
        query: {
          $select: ['Code', 'Description'],
          IsDeleted: false,
        },
      });
      dispatch({ type: SET_CODE_EYE_COLOR, payload: codes.data });
    } catch (error) {
      if (error.code === 401) {
        console.log('Unauthenticated');
      } else {
        console.log(error);
      }
    }
  };
};
export const addCodeHanded = (newVal) => {
  return { type: ADD_CODE_HANDED, payload: newVal };
};

export const getCodeHanded = () => {
  const client = store.store.getState().websocket.websocket;
  return async (dispatch) => {
    try {
      const service = client.service('codehanded');
      service.timeout = 20000;
      const codes = await service.find({
        query: {
          $select: ['Code', 'Description'],
          IsDeleted: false,
        },
      });
      dispatch({ type: SET_CODE_HANDED, payload: codes.data });
    } catch (error) {
      if (error.code === 401) {
        console.log('Unauthenticated');
      } else {
        console.log(error);
      }
    }
  };
};

export const addCodeJewelryCateogory = (newVal) => {
  return { type: ADD_CODE_JEWELRRY_CATEGORY, payload: newVal };
};

export const getCodeJewelryCateogory = () => {
  const client = store.store.getState().websocket.websocket;
  return async (dispatch) => {
    try {
      const service = client.service('codejewelrycategory');
      service.timeout = 20000;
      const codes = await service.find({
        query: {
          $select: ['Code', 'Description'],
          IsDeleted: false,
        },
      });
      dispatch({ type: SET_CODE_JEWELRRY_CATEGORY, payload: codes.data });
    } catch (error) {
      if (error.code === 401) {
        console.log('Unauthenticated');
      } else {
        console.log(error);
      }
    }
  };
};

export const addCodeJuvenileDisposition = (newValue) => {
  return { type: ADD_CODE_JUVENILE_DISPOSITION, payload: newValue };
};

export const getCodeJuvenileDisposition = () => {
  const client = store.store.getState().websocket.websocket;
  return async (dispatch) => {
    try {
      const service = client.service('codejuveniledisposition');
      service.timeout = 20000;
      const codes = await service.find({
        query: {
          $select: ['Code', 'Description'],
          $sort: {
            Description: 1,
          },
          IsDeleted: false,
        },
      });
      dispatch({ type: SET_CODE_JUVENILE_DISPOSITION, payload: codes.data });
    } catch (error) {
      if (error.code === 401) {
        console.log('Unauthenticated');
      } else {
        console.log(error);
      }
    }
  };
};

export default function reducer(
  state = {
    codeBloodType: [],
    codeCADStatuses: [],
    codeCities: [],
    codeCoordinateDatumTypes: [],
    codeDEADrugClasses: [],
    codeDNACollection: [],
    codeDrugMeasurements: [],
    codeEthnicity: [],
    codeEyeColor: [],
    codeHanded: [],
    codeJewelryCategory: [],
    codeJuvenileDisposition: [],
  },
  action
) {
  // eslint-disable-next-line default-case
  switch (action.type) {
    case SET_CODE_BLOOD_TYPE:
      return { ...state, codeBloodType: action.payload };
    case ADD_CODE_BLOOD_TYPE:
      return { ...state, codeBloodType: [...state.codeBloodType, action.payload] };
    case SET_CODE_CAD_STATUSES:
      return { ...state, codeCADStatuses: action.payload };
    case ADD_CODE_CAD_STATUSES:
      return { ...state, codeCADStatuses: [...state.codeCADStatuses, action.payload] };
    case SET_CODE_CITIES:
      return { ...state, codeCities: action.payload };
    case ADD_CODE_CITIES:
      return { ...state, codeCities: [...state.codeCities, action.payload] };
    case SET_CODE_COORDINATE_DATUM_TYPES:
      return { ...state, codeCoordinateDatumTypes: action.payload };
    case ADD_CODE_COORDINATE_DATUM_TYPES:
      return {
        ...state,
        codeCoordinateDatumTypes: [...state.codeCoordinateDatumTypes, action.payload],
      };
    case SET_CODE_DEA_DRUG_CLASSES:
      return { ...state, codeDEADrugClasses: action.payload };
    case ADD_CODE_DEA_DRUG_CLASSES:
      return { ...state, codeDEADrugClasses: [...state.codeDEADrugClasses, action.payload] };
    case SET_CODE_DNA_COLLECTION:
      return { ...state, codeDNACollection: action.payload };
    case ADD_CODE_DNA_COLLECTION:
      return { ...state, codeDNACollection: [...state.codeDNACollection, action.payload] };
    case SET_CODE_DRUG_MEASUREMENTS:
      return { ...state, codeDrugMeasurements: action.payload };
    case ADD_CODE_DRUG_MEASUREMENTS:
      return { ...state, codeDrugMeasurements: [...state.codeDrugMeasurements, action.payload] };
    case SET_CODE_ETHNICITY:
      return { ...state, codeEthnicity: action.payload };
    case ADD_CODE_ETHNICITY:
      return { ...state, codeEthnicity: [...state.codeEthnicity, action.payload] };
    case SET_CODE_EYE_COLOR:
      return { ...state, codeEyeColor: action.payload };
    case ADD_CODE_EYE_COLOR:
      return { ...state, codeEyeColor: [...state.codeEyeColor, action.payload] };
    case SET_CODE_HANDED:
      return { ...state, codeHanded: action.payload };
    case ADD_CODE_HANDED:
      return { ...state, codeHanded: [...state.codeHanded, action.payload] };
    case SET_CODE_JEWELRRY_CATEGORY:
      return { ...state, codeJewelryCategory: action.payload };
    case ADD_CODE_JEWELRRY_CATEGORY:
      return { ...state, codeJewelryCategory: [...state.codeJewelryCategory, action.payload] };
    case SET_CODE_JUVENILE_DISPOSITION:
      return { ...state, codeJuvenileDisposition: action.payload };
    case ADD_CODE_JUVENILE_DISPOSITION:
      return {
        ...state,
        codeJuvenileDisposition: [...state.codeJuvenileDisposition, action.payload],
      };
  }
  return state;
}
