import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { connect } from 'react-redux';
import { setIncPartiesTab } from '../../../reducers/ui/UiFormReducer';
import IncPartiesList from '../../RMSList/RMSIncidentList/IncPartiesList';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  formFields: {
    width: '100%',
    height: '100%',
  },
  formCard: {
    width: '100%',
    height: '100%',
  },
  lists: {
    marginTop: '20px',
  },
}));

const IncPartiesStep = (props) => {
  const classes = useStyles();
  return (
    <Grid container className={classes.root}>
      <Grid item xs={12} md={12} lg={12}>
        <IncPartiesList />
      </Grid>
    </Grid>
  );
};

const mapStateToProps = (state) => ({
  state: state.uiList.incListHeaderState,
  selectedTab: state.uiForm.incPartiesTabState,
});

export default connect(mapStateToProps, {
  setIncPartiesTab,
})(IncPartiesStep);
