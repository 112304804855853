/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { connect } from 'react-redux';

import clsx from 'clsx';
import moment from 'moment';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { Box, Typography, Paper, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import { setListBodyCells } from '../../reducers/ui/UiListReducer';
import { getEntitiesByIncidentId } from 'reducers/IncReducer';
import RMSList from 'components/RMSList';

const useStyles = makeStyles((theme) => ({
  summaryDetails: {
    height: window.innerHeight - 95 + 'px',
    position: '-webkit-sticky',
    // eslint-disable-next-line no-dupe-keys
    position: 'sticky',
    overflow: 'hidden',
  },
  scrollBar: {
    width: '100%',
    padding: '20px',
    height: '72vh',
  },
  summaryHeading: {
    color: 'white',
    display: 'flex',
    justifyContent: 'center',
  },
  summaryHeadingID: {
    color: 'white',
    justifyContent: 'center',
    textAlign: 'center',
    margin: 'auto',
    paddingTop: '3px',
  },
  demo: {
    marginBottom: '20px',
  },
}));

const DNDList = (props) => {
  const classes = useStyles();
  const { incident } = props;

  const [expanded, setExpanded] = React.useState({
    parties: false,
    addresses: false,
    properties: false,
    offenses: false,
    evidence: false,
  });

  const handleChange = (event, entity) => {
    let changedExpanded = {
      parties: false,
      addresses: false,
      properties: false,
      offenses: false,
      evidence: false,
    };
    changedExpanded[entity] = !expanded[entity];
    setExpanded(changedExpanded);
  };

  function dragStart(event) {
    event.dataTransfer.setData('Text', event.target.id);
  }

  return (
    <Paper className={clsx(classes.summaryDetails, 'mr-3')}>
      <Grid
        item
        style={{ minHeight: '50px', background: '#1976d2', width: '100%', padding: '15px' }}>
        <Box order={2} flexGrow={1}>
          <Typography variant="h4" className={classes.summaryHeading}>
            Incident Details
          </Typography>
          <Typography variant="h6" className={classes.summaryHeadingID}>
            {incident.CustomId}
          </Typography>
        </Box>
      </Grid>
      <PerfectScrollbar className={classes.scrollBar}>
        <div className="droptarget">
          <Grid item xs={12} md={12}>
            <div className={classes.demo}>
              <Typography variant="h6">
                <div
                  onDragStart={dragStart}
                  draggable="true"
                  id={`${incident.values.incidentCategory || ''}`}
                  style={{
                    fontSize: '20px',
                    marginBottom: '10px',
                    color: 'rgb(25, 118, 210)',
                    cursor: 'grabbin',
                  }}>{`Type: ${incident.values.incidentCategory || ''}`}</div>
                <span
                  onDragStart={dragStart}
                  draggable="true"
                  id={`${moment(incident.values.occurred).format('MM/DD/YYYY hh:mm A')}`}
                  style={{
                    marginBottom: '3px',
                    fontSize: '15px',
                    cursor: 'grabbin',
                  }}>
                  {`Occurred from ${
                    incident.values.occurred
                      ? `${moment(incident.values.occurred).format('MM/DD/YYYY hh:mm A')}`
                      : ''
                  }`}
                </span>
                <span
                  onDragStart={dragStart}
                  draggable="true"
                  id={`${moment(incident.values.occurredTo).format('MM/DD/YYYY hh:mm A')}`}
                  style={{
                    fontSize: '15px',
                    cursor: 'grabbin',
                  }}>
                  {` to ${
                    incident.values.occurredTo
                      ? `${moment(incident.values.occurredTo).format('MM/DD/YYYY hh:mm A')}`
                      : ''
                  }`}
                </span>
              </Typography>
            </div>
          </Grid>

          {['addresses', 'parties', 'properties', 'evidence', 'offenses'].map((l) => (
            <Grid item xs={12} md={12}>
              <div className={classes.demo}>
                <ExpansionPanel
                  expanded={expanded[l] === true}
                  onChange={(e) => handleChange(e, l)}>
                  <ExpansionPanelSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1bh-content"
                    id="panel1bh-header">
                    <Typography style={{ color: 'rgb(25, 118, 210)' }} variant="h6">
                      {l.charAt(0).toUpperCase() + l.slice(1)}
                    </Typography>
                  </ExpansionPanelSummary>
                  <ExpansionPanelDetails>
                    <RMSList entityFlag={l} dnd={true} />
                  </ExpansionPanelDetails>
                </ExpansionPanel>
              </div>
            </Grid>
          ))}
        </div>
      </PerfectScrollbar>
    </Paper>
  );
};

const mapStateToProps = (state) => ({
  bodyState: state.uiList.incListBodyState,
  selectedIncident: state.incident.ptsIncidentId,
  wsClient: state.websocket.websocket,
  incident: state.incident.incident,
});

export default connect(mapStateToProps, { getEntitiesByIncidentId, setListBodyCells })(DNDList);
