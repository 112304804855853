import React, { useEffect, useState } from 'react';
import VehiclesTab from './VehiclesTab';
import LocationsTab from './LocationsTab';
import NotesTab from './NotesTab';
import DispositionsTab from './DispositionsTab';
import AttachmentsTab from './AttachmentsTab';
import HistoryTab from './HistoryTab';
import RoutingTab from './RoutingTab';
import CaseIDTab from './CaseIDTab';
import PartiesTab from './PartiesTab';
import EventUnitsTab from './EventUnitsTab';
import ZonesTab from './ZonesTab';
import NotificationsTab from './NotificationsTab';
import { connect } from 'react-redux';
// import PartiesTab from './PartiesTab';

function EventTabDetails(props) {
  const { ptsEventID, menuOptions, event, tab, colorPalette, dictionary } = props;
  const [zones, setZones] = useState(null);
  const [dispositions, setDispositions] = useState(null);
  const [attachments, setAttachments] = useState(null);
  const [assignedUnits, setAssignedUnits] = useState(null);
  const [eventRouting, setEventRouting] = useState(null);
  const [unitStatuses, setUnitStatus] = useState(null);
  const [notes, setNotes] = useState(null);
  const [caseIDs, setCaseIDs] = useState(null);
  const [parties, setParties] = useState(null);
  const [locationID, setLocationID] = useState();
  // const [vehicleCount, setVehicleCount] = useState(null);

  console.log(tab);

  useEffect(() => {
    if (event) {
      const z = event.zones;
      const d = event.dispositions;
      const asU = event.assignedUnits;
      const locationId = event.ptsEventLocationID;
      const eR = event.EventRouting;
      const n = event.notes;
      const uS = event.UnitStatuses;
      const at = event.Attachments;
      const cIds = event.CaseIds;
      const p = event.parties;

      if (at) {
        setAttachments(JSON.parse(at));
      }
      if (uS) {
        setUnitStatus(JSON.parse(uS));
      }
      if (cIds) {
        setCaseIDs(JSON.parse(cIds));
      }
      if (eR) {
        setEventRouting(JSON.parse(eR));
      }
      setLocationID(locationId);
      setZones(z);
      setParties(p);
      setDispositions(d);
      setAssignedUnits(asU);
      setNotes(n);
      // setVehicleCount(v);
    }
  }, [event]);

  const displayLoading =
    (!event.zones &&
      !event.dispositions &&
      !event.assignedUnits &&
      !event.ptsEventLocationID &&
      !event.EventRouting &&
      !event.notes &&
      !event.UnitStatuses &&
      !event.attachments &&
      !event.CaseIds &&
      !event.parties) ||
    !colorPalette;

  return (
    <div>
      {displayLoading ? (
        'Loading...'
      ) : (
        <>
          {tab === 0 && assignedUnits && (
            <EventUnitsTab
              units={assignedUnits}
              colorPalette={colorPalette}
              UnitStatuses={unitStatuses}
              ptsEventID={ptsEventID}
              sortOption={menuOptions.Units.sort}
            />
          )}
          {tab === 1 && dispositions && (
            <DispositionsTab
              dispositions={dispositions}
              units={assignedUnits}
              EventRouting={eventRouting}
              ptsEventID={ptsEventID}
              sortOption={menuOptions.Dispositions.sort}
            />
          )}
          {tab === 2 && notes && (
            <NotesTab notes={notes} ptsEventID={ptsEventID} sortOption={menuOptions.Notes.sort} />
          )}
          {tab === 3 && attachments && (
            <AttachmentsTab
              attachments={attachments}
              ptsEventID={ptsEventID}
              sortOption={menuOptions.Attachments.sort}
            />
          )}
          {tab === 4 && (
            <HistoryTab
              colorPalette={colorPalette}
              history={unitStatuses}
              sortOption={menuOptions.History.sort}
            />
          )}
          {tab === 5 && (
            <CaseIDTab
              cases={caseIDs}
              ptsEventID={ptsEventID}
              sortOption={menuOptions['Case IDs'].sort}
            />
          )}
          {tab === 6 && parties && (
            <PartiesTab
              data={parties}
              ptsEventID={ptsEventID}
              sortOption={menuOptions.Parties.sort}
            />
          )}
          {tab === 7 && zones && (
            <ZonesTab
              zones={zones}
              ptsEventLocationID={locationID}
              sortOption={menuOptions.Zones.sort}
            />
          )}
          {tab === 8 && eventRouting && (
            <RoutingTab
              data={eventRouting}
              ptsEventID={ptsEventID}
              CallType={event.CallType}
              sortOption={menuOptions.Routing.sort}
            />
          )}
          {tab === 9 && (
            <NotificationsTab
              cases={caseIDs}
              ptsEventID={ptsEventID}
              event={event}
              sortOption={menuOptions.Locations.sort}
            />
          )}
          {tab === 10 && (
            <LocationsTab
              cases={caseIDs}
              ptsEventID={ptsEventID}
              sortOption={menuOptions.Locations.sort}
            />
          )}
          {tab === 11 && (
            <VehiclesTab
              ptsEventID={ptsEventID}
              event={event}
              sortOption={menuOptions.Vehicles.sort}
            />
          )}
        </>
      )}
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    dictionary: state.dictionary.dictionary,
    colorPalette: state.dictionary.colorPalette,
  };
};

export default connect(mapStateToProps)(EventTabDetails);
