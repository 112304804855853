export const codeSubstanceCategory = [
  {
    code: 'STIMULANTS',
    description: 'STIMULANTS',
  },
  {
    code: 'INHALANTS',
    description: 'INHALANTS',
  },
  {
    code: 'CANNABINOIDS',
    description: 'CANNABINOIDS',
  },
  {
    code: 'DEPRESSANTS',
    description: 'DEPRESSANTS',
  },
  {
    code: 'OPIOIDS/MORPHINE DERIVATIVES',
    description: 'OPIOIDS/MORPHINE DERIVATIVES',
  },
  {
    code: 'ANABOLIC STEROIDS',
    description: 'ANABOLIC STEROIDS',
  },
  {
    code: 'HALLUCINOGENS',
    description: 'HALLUCINOGENS',
  },
  {
    code: 'PRESCRIPTION DRUGS',
    description: 'PRESCRIPTION DRUGS',
  },
];
