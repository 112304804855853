import store from '../../config/configureStore';

export const SET_CODE_PLATE_TYPES = 'CODE/SET_CODE_PLATE_TYPES';
export const SET_CODE_PLATE_COLORS = 'CODE/SET_CODE_PLATE_COLORS';
export const SET_CODE_PLATE_STATUSES = 'CODE/SET_CODE_PLATE_STATUSES';
export const SET_CODE_PLATE_DETAILS = 'SET_CODE_PLATE_DETAILS';

export const getCodePlateTypes = () => {
  const client = store.store.getState().websocket.websocket;
  return async (dispatch) => {
    try {
      const service = client.service('codeplatetypes');
      service.timeout = 20000;
      const codes = await service.find({
        query: {
          $select: ['Code', 'Description'],
          $sort: {
            Code: 1,
          },
          IsDeleted: false,
        },
      });
      dispatch({ type: SET_CODE_PLATE_TYPES, payload: codes.data });
    } catch (error) {
      if (error.code === 401) {
        console.log('Unauthenticated');
      } else {
        console.log(error);
      }
    }
  };
};

export const getCodePlateColors = () => {
  const client = store.store.getState().websocket.websocket;
  return async (dispatch) => {
    try {
      const service = client.service('codeplatecolors');
      service.timeout = 20000;
      const codes = await service.find({
        query: {
          $select: ['Code', 'Description'],
          $sort: {
            Code: 1,
          },
          IsDeleted: false,
        },
      });
      dispatch({ type: SET_CODE_PLATE_COLORS, payload: codes.data });
    } catch (error) {
      if (error.code === 401) {
        console.log('Unauthenticated');
      } else {
        console.log(error);
      }
    }
  };
};

export const getCodePlateStatuses = () => {
  const client = store.store.getState().websocket.websocket;
  return async (dispatch) => {
    try {
      const service = client.service('codeplatestatuses');
      service.timeout = 20000;
      const codes = await service.find({
        query: {
          $select: ['Code', 'Description'],
          $sort: {
            Code: 1,
          },
          IsDeleted: false,
        },
      });
      dispatch({ type: SET_CODE_PLATE_STATUSES, payload: codes.data });
    } catch (error) {
      if (error.code === 401) {
        console.log('Unauthenticated');
      } else {
        console.log(error);
      }
    }
  };
};

export const getCodePlateDetails = () => {
  const client = store.store.getState().websocket.websocket;
  return async (dispatch) => {
    try {
      const service = client.service('codeplatedetails');
      service.timeout = 20000;
      const codes = await service.find({
        query: {
          $select: ['Code', 'Description'],
          $sort: {
            Code: 1,
          },
          IsDeleted: false,
        },
      });
      dispatch({ type: SET_CODE_PLATE_DETAILS, payload: codes.data });
    } catch (error) {
      if (error.code === 401) {
        console.log('Unauthenticated');
      } else {
        console.log(error);
      }
    }
  };
};

// export const addCodeEvidenceCustodyReason = (newVal) => {
//   return { type: ADD_CODE_EVIDENCE_CUSTODY_REASON, payload: newVal };
// };

export default function reducer(
  state = {
    codePlateTypes: [],
    codePlateColors: [],
    codePlateStatuses: [],
    codePlateDetails: [],
  },
  action
) {
  // eslint-disable-next-line default-case
  switch (action.type) {
    case SET_CODE_PLATE_TYPES:
      return { ...state, codePlateTypes: action.payload };
    case SET_CODE_PLATE_COLORS:
      return { ...state, codePlateColors: action.payload };
    case SET_CODE_PLATE_STATUSES:
      return { ...state, codePlateStatuses: action.payload };
    case SET_CODE_PLATE_DETAILS:
      return { ...state, codePlateDetails: action.payload };
    // case ADD_CODE_EVIDENCE_CUSTODY_REASON:
    //   return {
    //     ...state,
    //     codeEvidenceCustodyReason: [...state.codeEvidenceCustodyReason, action.payload],
    //   };
  }
  return state;
}
