/* eslint-disable react-hooks/exhaustive-deps */
import React, { createContext } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';

import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { Badge, Grid } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';

import RecordPersonAddress from './TabRecordPersonAddress';
import RecordPersonCredentials from './TabRecordPersonCredentials';
import RecordPersonContact from './TabRecordPersonContact';
import RecordPersonEmployment from './TabRecordPersonEmployment';
import RecordPersonDNASample from './TabRecordPersonDNASample';
import RMSAddButton from 'components/RMSButtons/RMSAddButton';
import RMSEditButton from 'components/RMSButtons/RMSEditButton';
import RMSDeleteButton from 'components/RMSButtons/RMSDeleteButton';
import { useHistory, useParams } from 'react-router';
import RecordPersonFeature from './TabRecordPersonFeature';
import RecordPersonName from './TabRecordPersonName';
import RecordPersonPhoto from './TabRecordPersonPhoto';
import RecordPersonResources from './TabRecordPersonResources';
import RecordPersonSOPS from './TabRecordPersonSOPS';
import RecordPersonVehicleRegistration from './TabRecordPersonVehicleRegistration';
import RecordPersonAssociate from './TabRecordPersonAssociate';
import RMSFabButtons from 'components/RMSForms/RMSIncidentForm/RMSFabButtons';
import RMSPersonInfoCard from 'components/RMSPersonInfoCard';
import { removeRecord } from 'reducers/RecordsReducer';
import { setFormData } from '../../../reducers/RecordsReducer';
import {
  updateIncAddressForm,
  updateIncCoordinatesForm,
  updateIncAddressIdentifiersForm,
} from 'reducers/IncReducer';
import { setRecordPersonDetailsStep } from 'reducers/ui/UiFormReducer';
import { setCircularLoading } from 'reducers/ui/UiMenuReducer';

export const RefreshContext = createContext();
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}>
      {value === index && (
        <Box borderRadius={16}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    height: window.innerHeight - 90 + 'px',
    marginBottom: '10px',
  },
  paper: {
    borderRadius: '0.5rem',
    backgroundColor: theme.palette.background.paper,
    height: window.innerHeight - 260 + 'px',
  },
  gridBg: {
    width: '100%',
    margin: '0px 0px 15px 0px',
    display: 'block',
    height: '150px',
  },
  BadgeStyle: {
    backgroundColor: '#B6C4D2',
    color: '#0b55a1',
  },
  button: {
    position: 'absolute',
    bottom: '85px',
    right: '20px',
    width: '120px',
    backgroundColor: theme.palette.info.dark,
    color: theme.palette.common.white,
    '&:hover': {
      backgroundColor: theme.palette.info.dark,
      boxShadow: 'none',
    },
    '&:active': {
      boxShadow: 'none',
      backgroundColor: theme.palette.info.dark,
    },
  },
}));

const tabsnumber = [
  {
    key: 'address',
    id: 'ptsAddressID',
  },
  {
    key: 'associate',
    id: 'ptsAssociateID',
  },
  {
    key: 'contact-method',
    id: 'ptsInfoID',
  },
  {
    key: 'credential',
    id: 'ptsCredentialID',
  },
  {
    key: 'dna-sample',
    id: 'ptsDNASampleID',
  },
  {
    key: 'employment',
    id: 'ptsPlaceID',
  },
  {
    key: 'feature',
    id: 'ptsFeatureID',
  },
  {
    key: 'name',
    id: 'ptsNameID',
  },
  {
    key: 'photo',
    id: 'ptsImageID',
  },
  {
    key: 'resource',
    id: 'ptsResourceID',
  },
  {
    key: 'sop',
    id: '',
  },
  {
    key: 'vehicle-registration',
    id: 'ptsRegistrationID',
  },
];
const AddEditPersonRecord = (props) => {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const [personInfo, setPersonInfo] = React.useState({});
  const records = useSelector((state) => state.records);
  const wsClient = useSelector((state) => state.websocket);
  const { persondId } = useParams();
  const [resultCount, setResultCount] = React.useState({});
  const [countLoading, setCountLoading] = React.useState(true);
  const {
    selectedTab,
    removeRecord,
    updateIncAddressForm,
    updateIncCoordinatesForm,
    setRecordPersonDetailsStep,
    updateIncAddressIdentifiersForm,
  } = props;
  const [value, setValue] = React.useState(selectedTab);
  const [refresh, setRefresh] = React.useState(false);
  const [cardRefresh, setCardRefresh] = React.useState(false);
  const [photoRefresh, setPhotoRefresh] = React.useState(false);
  const handleChange = (event, newValue) => {
    setValue(newValue);
    setRecordPersonDetailsStep(newValue);
  };

  const handleDelete = async () => {
    setRefresh(!refresh);
    await removeRecord(
      tabsnumber[value].key,
      records.formData[tabsnumber[value].id],
      persondId
    ).finally(() => {
      if (tabsnumber[value].key === 'address') {
        setResultCount({ ...resultCount, addressCount: resultCount.addressCount - 1 });
      } else if (tabsnumber[value].key === 'associate') {
        setResultCount({ ...resultCount, associateCount: resultCount.associateCount - 1 });
      } else if (tabsnumber[value].key === 'contact-method') {
        setResultCount({ ...resultCount, contactCount: resultCount.contactCount - 1 });
      } else if (tabsnumber[value].key === 'credential') {
        setCardRefresh(!cardRefresh);
        setResultCount({ ...resultCount, credentialCount: resultCount.credentialCount - 1 });
      } else if (tabsnumber[value].key === 'dna-sample') {
        setResultCount({ ...resultCount, dnaSampleCount: resultCount.dnaSampleCount - 1 });
      } else if (tabsnumber[value].key === 'employment') {
        setResultCount({ ...resultCount, employmentCount: resultCount.employmentCount - 1 });
      } else if (tabsnumber[value].key === 'feature') {
        setResultCount({ ...resultCount, featureCount: resultCount.featureCount - 1 });
      } else if (tabsnumber[value].key === 'name') {
        setResultCount({ ...resultCount, nameCount: resultCount.nameCount - 1 });
      } else if (tabsnumber[value].key === 'photo') {
        setPhotoRefresh(!photoRefresh);
        setResultCount({ ...resultCount, photoCount: resultCount.photoCount - 1 });
      } else if (tabsnumber[value].key === 'resource') {
        setResultCount({ ...resultCount, resourceCount: resultCount.resourceCount - 1 });
      } else if (tabsnumber[value].key === 'sop') {
        setResultCount({ ...resultCount, sopCount: resultCount.sopCount - 1 });
      } else if (tabsnumber[value].key === 'vehicle-registration') {
        setResultCount({ ...resultCount, vehicleCount: resultCount.vehicleCount - 1 });
      }
    });
    dispatch(setFormData({}));
  };

  const setAddressFormData = async () => {
    let formData = records.formData;
    let addressDetail = {
      ptsAddressId: formData.ptsAddressID,
      values: {
        ptsLocationId: formData.ptsLocationID,
        fullAddressText: formData.FullAddressText,
        created: formData.Created,
        createdBy: formData.CreatedBy,
        updated: formData.Updated,
        updatedBy: formData.UpdatedBy,
        updatedIp: formData.UpdatedIP,
        addressNumberPrefix: formData.AddressNumberPrefix,
        addressNumberSuffix: formData.AddressNumberSuffix,
        addressNumber: formData.AddressNumber,
        streetName: formData.StreetName,
        preDirection: formData.PreDirection,
        postDirection: formData.PostDirection,
        city: formData?.City?.CityDescription,
        state: formData.State,
        county: formData.County,
        country: formData.Country,
        postalCode: formData.PostalCode,
        postalCodeExtension: formData.PostalCodeExtension,
        streetType: formData.StreetType,
        notes: formData.Notes,
        isPrimary: formData.IsPrimary,
      },
    };

    let addressIdDetails = {
      ptsAddressId: formData.ptsAddressID,
      values: {
        isEventLookup: formData.ShowInEventLookup,
        isSexOffender: formData.SexOffender,
        isParolee: formData.Parolee,
        isProbation: formData.Probation,
        isNonValidated: formData.NonValidatedAddress,
        unitType: formData.UnitType,
        unitTypeIdentifier: formData.UnitIdentifier,
        floorType: formData.FloorType,
        floorIdentifier: formData.FloorIdentifier,
        buildingType: formData.BldgType,
        buildingIdentifier: formData.BldgIdentifier,
      },
    };

    let addressCoordinatesDetails = {
      ptsAddressId: formData.ptsAddressID,
      ptsCoordinateId: formData?.Coordinate?.ptsCoordinateID,
      values: {
        latitudeSign: formData?.Coordinate?.LatitudeSign,
        latitudeDegree: formData?.Coordinate?.LatitudeDegree,
        longitudeSign: formData?.Coordinate?.LongitudeSign,
        longitudeDegree: formData?.Coordinate?.LongitudeDegree,
        uncertaintyDistance: formData?.Coordinate?.UnCertaintyDistance,
        datum: formData?.Coordinate?.Datum,
        coordinateDatetime: formData?.Coordinate?.CoordinateDT,
        altitude: formData?.Coordinate?.Altitude,
        altitudePor: formData?.Coordinate?.AltitudeRefPoint,
        altitudeUom: formData?.Coordinate?.AltitudeUnitMeasure,
        cellTower: formData?.Coordinate?.CellIDText,
        sectorId: formData?.Coordinate?.CellSectorIDText,
        speed: formData?.Coordinate?.Speed,
        speedUom: formData?.Coordinate?.SpeedUnitMeasure,
      },
    };

    await Promise.all([
      updateIncAddressForm(addressDetail),
      updateIncAddressIdentifiersForm(addressIdDetails),
      updateIncCoordinatesForm(addressCoordinatesDetails),
    ]);
  };

  const handleSave = () => {
    history.goBack();
    dispatch(setCircularLoading(false));
  };

  const handleEdit = () => {
    if (tabsnumber[value].key === 'address') {
      setAddressFormData();
    }

    history.push(
      `/record/person/${persondId}/${tabsnumber[value].key}/edit/${
        records.formData[tabsnumber[value].id]
      }`
    );
  };

  const handleAdd = () => {
    if (tabsnumber[value].key === 'address') {
      let initForm = {
        isValid: false,
        values: {},
        touched: {},
        errors: {},
      };
      updateIncAddressForm(initForm);
      updateIncAddressIdentifiersForm(initForm);
      updateIncCoordinatesForm(initForm);
    }
    history.push(`/record/person/${persondId}/${tabsnumber[value].key}/add`);
  };

  const getLabel = (name, data) => {
    return (
      <Badge
        classes={{ badge: classes.BadgeStyle }}
        badgeContent={
          countLoading ? <CircularProgress size={15} color={'inherit'} thickness={5} /> : data
        }>
        {name}
      </Badge>
    );
  };

  const getPerson = async () => {
    if (!wsClient?.websocket) {
      return;
    }

    const service = wsClient?.websocket.service('record-person-details');
    service.timeout = 200000;

    const result = await service.get(persondId);
    setCountLoading(false);
    setResultCount(result);
    setPersonInfo(result);
  };

  React.useEffect(() => {
    setPersonInfo({});
    getPerson();
  }, [wsClient, cardRefresh]);

  React.useEffect(() => {
    setValue(selectedTab);
  }, [selectedTab]);
  return (
    <Grid container className={classes.root}>
      <Paper className={classes.gridBg} container spacing={1} wrap="nowrap">
        <Grid spacing={1} item xs={12}>
          <RMSPersonInfoCard data={personInfo} record={true} photoRefresh={photoRefresh} />
        </Grid>
        {!(value === 10) && (
          <Grid style={{ float: 'right', position: 'absolute', bottom: 15, right: 20 }} item xs={2}>
            <Grid container alignItems="center" justify="flex-end" wrap="nowrap">
              <Grid item style={{ paddingRight: '12px' }}>
                <RMSAddButton
                  tooltipText={`Add ${
                    tabsnumber[value].key
                      ? tabsnumber[value].key.charAt(0).toUpperCase() + tabsnumber[value].key.slice(1)
                      : ''
                  }`}
                  onClick={handleAdd}
                />
              </Grid>

              <Grid item style={{ paddingRight: '12px' }}>
                <RMSEditButton
                  disabled={
                    !(
                      records?.formData &&
                      (records.formData.ptsPersonID || records.formData.ptsParentID)
                    )
                  }
                  onClick={handleEdit}
                />
              </Grid>

              <Grid item>
                <RMSDeleteButton
                  disabled={
                    !(
                      records?.formData &&
                      (records.formData.ptsPersonID || records.formData.ptsParentID)
                    )
                  }
                  onClick={handleDelete}
                />
              </Grid>
            </Grid>
          </Grid>
        )}
      </Paper>

      <Grid item xs={12}>
        <Paper className={classes.paper}>
          <RefreshContext.Provider value={refresh}>
            <AppBar position="static" style={{ borderRadius: '0.5rem 0.5rem 0 0' }}>
              <Tabs
                scrollButtons="auto"
                variant="scrollable"
                value={value}
                onChange={handleChange}
                aria-label="person-details">
                <Tab label={getLabel('Address', resultCount.addressCount)} {...a11yProps(0)}></Tab>

                <Tab
                  label={getLabel('Associate', resultCount?.associateCount || 0)}
                  {...a11yProps(1)}
                />
                <Tab
                  label={getLabel('Contact', resultCount?.contactCount || 0)}
                  {...a11yProps(2)}
                />

                <Tab
                  label={getLabel('Credential', resultCount?.credentialCount || 0)}
                  {...a11yProps(3)}
                />
                <Tab
                  label={getLabel('DNA Samples', resultCount?.dnaSampleCount || 0)}
                  {...a11yProps(4)}
                />
                <Tab
                  label={getLabel('Employment', resultCount?.employmentCount || 0)}
                  {...a11yProps(5)}
                />
                <Tab
                  label={getLabel('Feature', resultCount?.featureCount || 0)}
                  {...a11yProps(6)}
                />
                <Tab label={getLabel('Name', resultCount?.nameCount || 0)} {...a11yProps(7)} />
                <Tab label={getLabel('Photos', resultCount?.photoCount || 0)} {...a11yProps(8)} />
                <Tab
                  label={getLabel('Resources', resultCount?.resourceCount || 0)}
                  {...a11yProps(9)}
                />
                <Tab label={getLabel('SOP', resultCount?.sopsCount || 0)} {...a11yProps(10)} />
                <Tab
                  label={getLabel('Vehicle Registrations', resultCount?.vehicleCount || 0)}
                  {...a11yProps(11)}
                />
              </Tabs>
            </AppBar>
            <TabPanel value={value} index={0}>
              <RecordPersonAddress />
            </TabPanel>
            <TabPanel value={value} index={1}>
              <RecordPersonAssociate />
            </TabPanel>
            <TabPanel value={value} index={2}>
              <RecordPersonContact />
            </TabPanel>
            <TabPanel value={value} index={3}>
              <RecordPersonCredentials />
            </TabPanel>
            <TabPanel value={value} index={4}>
              <RecordPersonDNASample />
            </TabPanel>
            <TabPanel value={value} index={5}>
              <RecordPersonEmployment />
            </TabPanel>
            <TabPanel value={value} index={6}>
              <RecordPersonFeature />
            </TabPanel>
            <TabPanel value={value} index={7}>
              <RecordPersonName />
            </TabPanel>
            <TabPanel value={value} index={8}>
              <RecordPersonPhoto />
            </TabPanel>
            <TabPanel value={value} index={9}>
              <RecordPersonResources />
            </TabPanel>
            <TabPanel value={value} index={10}>
              <RecordPersonSOPS />
            </TabPanel>
            <TabPanel value={value} index={11}>
              <RecordPersonVehicleRegistration />
            </TabPanel>
          </RefreshContext.Provider>
        </Paper>
        <RMSFabButtons recordForm={true} handleSaveRecord={handleSave} />
      </Grid>
    </Grid>
  );
};

const mapStateToProps = (state) => ({
  selectedTab: state.uiForm.recordPersonDetailsTabState,
});

const mapDispatchToProps = {
  updateIncCoordinatesForm,
  updateIncAddressIdentifiersForm,
  updateIncAddressForm,
  setRecordPersonDetailsStep,
  removeRecord,
};

export default connect(mapStateToProps, mapDispatchToProps)(AddEditPersonRecord);
