export const GET_CODE_TRANSMISSION = 'GET_CODE_TRANSMISSION';
export const ADD_CODE_TRANSMISSION = 'ADD_CODE_TRANSMISSION';

export const addCodeTransmission = (newVal) => {
  return { type: ADD_CODE_TRANSMISSION, payload: newVal };
};

export const getCodeTransmission = (state) => {
  return async (dispatch) => {
    return dispatch({ type: GET_CODE_TRANSMISSION, payload: state });
  };
};

export default function reducer(state = [], action) {
  switch (action.type) {
    case GET_CODE_TRANSMISSION:
      state = action.payload;
      return state;
    case ADD_CODE_TRANSMISSION:
      return [...state, action.payload];
    default:
      return state;
  }
}
