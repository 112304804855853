import React from 'react';
import { connect } from 'react-redux';
import { useHistory, useLocation } from 'react-router';

import PerfectScrollbar from 'react-perfect-scrollbar';
import Tooltip from '@material-ui/core/Tooltip';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import {
  Grid,
  Button,
  ListItem,
  ListItemText,
  DialogTitle,
  DialogActions,
  DialogContent,
  // DialogContentText,
  Card,
  Fab,
} from '@material-ui/core';
import makeStyles from '@material-ui/core/styles/makeStyles';

import {
  resetDuplicateList,
  setDuplicateModal,
  setDuplicateFoundBtn,
} from 'reducers/ui/UiModalReducer';
import RMSEditButton from 'components/RMSButtons/RMSEditButton';
import {
  initFormModel,
  updateIncAddressForm,
  updateIncAddressIdentifiersForm,
  updateIncCoordinatesForm,
  updateIncPartiesPersonForm,
} from 'reducers/IncReducer';
import RMSPersonInfoCard from 'components/RMSPersonInfoCard';

const useStyles = makeStyles((theme) => ({
  listItemRoot: {
    minWidth: '500px',
  },
  listStyl: {
    marginBottom: '20px',
  },
  secondaryListItem: {
    width: '400px',
  },
  accordinTypo: {
    width: '100%',
  },
  accordinRoot: {
    backgroundColor: 'rgba(0, 0, 0, .03)',
    borderBottom: '1px solid rgba(0, 0, 0, .125)',
    marginBottom: -1,
    minHeight: 56,
    '&$expanded': {
      minHeight: 56,
    },
  },
  scrollBar: {
    width: '100%',
    overflowX: 'hidden',
    overflowY: 'scroll',
    paddingRight: '5px',
    maxHeight: '400px',
  },
}));

const RMSDuplicateDialogModal = (props) => {
  /** region Component Variables and Props */
  const classes = useStyles();
  const history = useHistory();
  const [open, setOpen] = React.useState(false);
  const [personList, setPersonList] = React.useState([]);
  const [vehicleList, setVehicleList] = React.useState([]);
  const [placeList, setPlaceList] = React.useState([]);
  const [addressList, setAddressList] = React.useState([]);
  const [contactAddressList, setContactAddressList] = React.useState([]);
  const [employmentAddressList, setEmploymentAddressList] = React.useState([]);
  const {
    uiDuplicateModalData,
    setDuplicateModal,
    updateIncAddressForm,
    updateIncAddressIdentifiersForm,
    updateIncCoordinatesForm,
    setDuplicateFoundBtn,
    updateIncPartiesPersonForm,
  } = props;
  const {
    duplicateModalMessage,
    duplicateListOfAddress,
    duplicateListOfAddressForContact,
    duplicateListOfAddressForEmployment,
    duplicateListOfPlace,
    duplicateListOfVehicle,
    duplicateListOfPerson,
  } = uiDuplicateModalData;
  const location = useLocation();
  const urlParams = location.pathname.split('/');
  const rootType = urlParams[1];
  /** endregion */
  /** region React Hooks */
  React.useEffect(() => {
    setPersonList([...duplicateListOfPerson]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [duplicateListOfPerson]);

  React.useEffect(() => {
    setVehicleList(duplicateListOfVehicle);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [duplicateListOfVehicle]);

  React.useEffect(() => {
    setPlaceList(duplicateListOfPlace);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [duplicateListOfPlace]);

  React.useEffect(() => {
    setAddressList(duplicateListOfAddress);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [duplicateListOfAddress]);

  React.useEffect(() => {
    let arr = [...duplicateListOfAddressForContact];
    console.log(arr);
    setContactAddressList([...duplicateListOfAddressForContact]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    uiDuplicateModalData,
    uiDuplicateModalData.duplicateListOfAddressForContact,
    duplicateListOfAddressForContact,
    duplicateListOfAddressForContact.length,
  ]);

  React.useEffect(() => {
    setEmploymentAddressList(duplicateListOfAddressForEmployment);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [duplicateListOfAddressForEmployment]);
  /** endregion */
  /** region Helper Functions */
  const close = () => {
    setOpen(false);
    setDuplicateModal(false);
    setDuplicateFoundBtn(false);
    resetDuplicateList();
  };

  const handleEdit = (id, entity) => {
    close();
    if (entity === 'person') {
      history.push(`/records/edit/persons/${id}`);
    } else if (entity === 'address') {
      updateIncAddressForm({
        ...initFormModel,
      });
      updateIncAddressIdentifiersForm(initFormModel);
      updateIncCoordinatesForm(initFormModel);
      history.push(`/records/edit/address/${id}`);
    } else if (entity === 'place') {
      history.push(`/records/edit/place/${id}`);
    } else if (entity === 'vehicle') {
      history.push(`/records/edit/vehicle/${id}`);
    }
  };

  const selectThisPerson = (i) => {
    let selectedPerson = duplicateListOfPerson[i];

    let personDetail = {
      ptsPersonId: rootType !== 'records' ? selectedPerson.ptsPersonID : undefined,
      values: {
        ptsPersonId: selectedPerson.ptsPersonID,
        firstName: selectedPerson.FirstName,
        lastName: selectedPerson.LastName,
        middleName: selectedPerson.MiddleName,
        suffix: selectedPerson.Suffix,
        race: selectedPerson.Race,
        sex: selectedPerson.Sex,
        ethnicity: selectedPerson.Ethnicity,
        age: selectedPerson.Age,
        ageRangeIncrement: selectedPerson.AgeRangeIncrement,
        eyeColor: selectedPerson.EyeColor,
        hairColor: selectedPerson.HairColor,
        hairLength: selectedPerson.HairLength,
        hairStyle: selectedPerson.HairStyle,
        bloodType: selectedPerson.BloodType,
        handed: selectedPerson.Handed,
        height: selectedPerson.Height,
        weight: selectedPerson.Weight,
        birthdate: selectedPerson.Born,
        ssn: selectedPerson.SSN,
        oln: selectedPerson.OLN,
      },
    };

    updateIncPartiesPersonForm(personDetail);
  };
  /** endregion */
  return (
    <Card>
      <DialogTitle
        className="mb-2 d-flex justify-content-between align-items-center"
        style={{ color: '#fff', background: '#1976d2' }}
        id="alert-dialog-title">
        {duplicateModalMessage}{' '}
      </DialogTitle>
      <DialogContent>
        <PerfectScrollbar className={classes.scrollBar}>
          {personList.length > 0 && (
            <div className={classes.listStyl}>
              <h6>PERSON</h6>
              {personList.map((p, index) => (
                <ListItem key={p.FirstName} button className={classes.listItemRoot}>
                  <div style={{ width: '750px' }}>
                    <RMSPersonInfoCard data={p} duplicateCheck={true} />
                  </div>
                  <Grid container wrap="nowrap" spacing={2} justify="flex-end">
                    <Grid item className="mt-2">
                      <Tooltip title="Select">
                        <Fab
                          size="small"
                          color="secondary"
                          aria-label="edit"
                          onClick={() => selectThisPerson(index)}>
                          <ArrowForwardIcon />
                        </Fab>
                      </Tooltip>
                    </Grid>
                    <Grid item className="mt-2">
                      <RMSEditButton onClick={() => handleEdit(p.ptsPersonID, 'person')} />
                    </Grid>
                  </Grid>
                </ListItem>
              ))}
            </div>
          )}

          {vehicleList.length > 0 && (
            <div className={classes.listStyl}>
              <h6>VEHICLE</h6>
              {vehicleList.map((v) => (
                <ListItem key={v.ptsVehicleID} button className={classes.listItemRoot}>
                  <div style={{ width: '350px' }}>
                    <ListItemText
                      classes={{
                        secondary: classes.secondaryListItem,
                      }}
                      primary={`VechicleID: ${v.VechicleID || ''}`}
                      secondary={`VIN: ${v.VIN} | Plate Number: ${
                        v.PlateNumber || ''
                      } | Plate State: ${v.PlateState || ''}`}
                    />
                  </div>
                  <Grid container wrap="nowrap" spacing={2} justify="flex-end">
                    <Grid item className="mt-2">
                      <RMSEditButton onClick={() => handleEdit(v.ptsVehicleID, 'vehicle')} />
                    </Grid>
                  </Grid>
                </ListItem>
              ))}
            </div>
          )}

          {placeList.length > 0 && (
            <div className={classes.listStyl}>
              <h6>PLACE</h6>
              {placeList.map((p) => (
                <ListItem key={p.PlaceID} button className={classes.listItemRoot}>
                  <ListItemText
                    classes={{
                      secondary: classes.secondaryListItem,
                    }}
                    primary={`PlaceID: ${p.PlaceID || ''}`}
                    secondary={`Place Name: ${p.PlaceName}`}
                  />
                  <Grid container wrap="nowrap" spacing={2} justify="flex-end">
                    <Grid item className="mt-2">
                      <RMSEditButton onClick={() => handleEdit(p.ptsPlaceID, 'place')} />
                    </Grid>
                  </Grid>
                </ListItem>
              ))}
            </div>
          )}

          {addressList.length > 0 && (
            <div className={classes.listStyl}>
              <h6>ADDRESS</h6>
              {addressList.map((a) => (
                <ListItem key={a.FullAddressText} button className={classes.listItemRoot}>
                  <ListItemText
                    classes={{
                      secondary: classes.secondaryListItem,
                    }}
                    primary={`AddressID: ${a.AddressID || ''}`}
                    secondary={`Full Address: ${a.FullAddressText || ''}`}
                  />
                  <Grid container wrap="nowrap" spacing={2} justify="flex-end">
                    <Grid item className="mt-2">
                      <RMSEditButton onClick={() => handleEdit(a.ptsAddressID, 'address')} />
                    </Grid>
                  </Grid>
                </ListItem>
              ))}
            </div>
          )}

          {contactAddressList.length > 0 && (
            <div className={classes.listStyl}>
              <h6>ADDRESS (Contact)</h6>
              {contactAddressList.map((a) => (
                <ListItem key={a.FullAddressText} button className={classes.listItemRoot}>
                  <ListItemText
                    classes={{
                      secondary: classes.secondaryListItem,
                    }}
                    primary={`AddressID: ${a.AddressID || ''}`}
                    secondary={`Full Address: ${a.FullAddressText || ''}`}
                  />
                  <Grid container wrap="nowrap" spacing={2} justify="flex-end">
                    <Grid item className="mt-2">
                      <RMSEditButton onClick={() => handleEdit(a.ptsAddressID, 'address')} />
                    </Grid>
                  </Grid>
                </ListItem>
              ))}
            </div>
          )}

          {employmentAddressList.length > 0 && (
            <div className={classes.listStyl}>
              <h6>ADDRESS (Employment)</h6>
              {employmentAddressList.map((a) => (
                <ListItem key={a.FullAddressText} button className={classes.listItemRoot}>
                  <ListItemText
                    classes={{
                      secondary: classes.secondaryListItem,
                    }}
                    primary={`AddressID: ${a.AddressID || ''}`}
                    secondary={`Full Address: ${a.FullAddressText || ''}`}
                  />
                  <Grid container wrap="nowrap" spacing={2} justify="flex-end">
                    <Grid item className="mt-2">
                      <RMSEditButton onClick={() => handleEdit(a.ptsAddressID, 'address')} />
                    </Grid>
                  </Grid>
                </ListItem>
              ))}
            </div>
          )}
        </PerfectScrollbar>
      </DialogContent>
      <DialogActions>
        <Button onClick={close}>Close</Button>
      </DialogActions>
    </Card>
  );
};

const mapStateToProps = (state) => ({
  uiDuplicateModalData: state.uiModal,
});

const mapDispatchToProps = {
  setDuplicateModal,
  updateIncAddressForm,
  updateIncCoordinatesForm,
  setDuplicateFoundBtn,
  updateIncPartiesPersonForm,
  updateIncAddressIdentifiersForm,
};

export default connect(mapStateToProps, mapDispatchToProps)(RMSDuplicateDialogModal);
