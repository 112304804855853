/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { connect } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';

import { faSearch } from '@fortawesome/free-solid-svg-icons/faSearch';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import Grid from '@material-ui/core/Grid';
import { InputAdornment } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import IncOffensesPanels from '../../components/RMSList/RMSCollapsibleList/IncOffensesPanels';
import { validateOffenseForm } from 'simpl-schema-validation/ValidateForms';

import {
  updateIncOffensesOffenseForm,
  setCurrentOffense,
  updateIncOffenses,
} from '../../reducers/IncReducer';
import { ptsStatute } from '../../reducers/_dev/ptsStatute';
import { ptsFBICode } from '../../reducers/_dev/ptsFBICode';
import { handleFormChangeNew } from '../../reducers/helpers/formHelpers';

const TabOffense = (props) => {
  /** region Component Variables and Props */
  const {
    updateIncOffenses,
    offensesOffenseForm,
    updateIncOffensesOffenseForm,
    setCurrentOffense,
    incProperties,
  } = props;
  const [offensesOffenseFormState, setOffensesOffenseFormState] = React.useState({
    tempOffenseId: uuidv4(),
    changes: false,
    isValid: false,
    values: {},
    touched: {},
    errors: {},
  });
  const [ptsStatueArray, setPtsStatueArray] = React.useState([]);
  const [errors, setErrors] = React.useState({});
  /** endregion */
  /** region React Hooks */
  React.useEffect(() => {
    let sortedArray = ptsStatute
      .filter((s) => s.Description !== '')
      .sort((a, b) => (a.Description > b.Description ? 1 : -1));
    let arr = [];

    sortedArray.forEach((s) => {
      let fbCode = ptsFBICode.find((fb) => fb.ptsFBICodeID === s.ptsFBICodeID)?.FBICode;
      s.FBICode = fbCode;
      arr.push(s);
    });

    setPtsStatueArray(arr);
  }, []);

  React.useEffect(() => {
    if (offensesOffenseForm && offensesOffenseForm.ptsOffenseId) {
      setCurrentOffense(offensesOffenseForm.ptsOffenseId);
    } else {
      setCurrentOffense('');
    }

    setOffensesOffenseFormState({
      ...offensesOffenseFormState,
      ...offensesOffenseForm,
      ptsOffenseId: offensesOffenseForm.ptsOffenseId,
      values: {
        ...offensesOffenseFormState.values,
        ...offensesOffenseForm.values,
      },
    });
  }, [offensesOffenseForm]);

  React.useEffect(() => {
    let timeoutId;
    offensesOffenseFormState.changes = true;

    updateIncOffenses(offensesOffenseFormState);
    updateIncOffensesOffenseForm(offensesOffenseFormState);

    if (offensesOffenseFormState.values.statuteDetails) {
      timeoutId = setTimeout(() => {
        let errorStatus = errors;

        let nibrErrorUCRCode = validateOffenseForm('ucrCode');
        if (nibrErrorUCRCode && nibrErrorUCRCode.length) {
          errorStatus = {
            ...errorStatus,
            ucrCode: `${nibrErrorUCRCode[0].message}`,
          };
        } else {
          errorStatus = {
            ...errorStatus,
            ucrCode: null,
          };
        }
        setErrors(errorStatus);
      }, 2000);
    }
    return () => clearTimeout(timeoutId);
  }, [offensesOffenseFormState?.values?.statuteDetails, incProperties]);
  /** endregion */
  return (
    <Grid container justify="center">
      <Grid item xs={12} style={{ padding: '0px 15px' }}>
        <Autocomplete
          size="small"
          autoComplete
          autoSelect
          autoHighlight
          id="combo-search-offenses"
          getOptionLabel={(option) => {
            return `${option.Description} | ${option.Code} | ${option.FBICode}`;
          }}
          options={ptsStatueArray}
          key={offensesOffenseFormState?.values?.statuteDetails || ''}
          value={offensesOffenseFormState?.values?.statuteDetails || null}
          onChange={(event, newValue) => {
            handleFormChangeNew(
              event,
              newValue,
              'none',
              'statuteDetails',
              setOffensesOffenseFormState
            );
          }}
          renderInput={(params) => (
            <Grid container xs={12} a className="mb-3 pt-1 d-flex">
              <TextField
                autoComplete="hidden"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <FontAwesomeIcon icon={faSearch} />
                    </InputAdornment>
                  ),
                }}
                size="small"
                {...params}
                label="Search Statutes and FBI Codes"
                variant="outlined"
                fullWidth
                error={errors['ucrCode'] ? true : false}
                helperText={errors['ucrCode']}
              />
            </Grid>
          )}
        />
      </Grid>
      <Grid xs={12} item>
        <IncOffensesPanels />
      </Grid>
    </Grid>
  );
};

const mapStateToProps = (state) => ({
  offensesOffenseForm: state.incident.offensesOffenseForm,
  currentOffense: state.incident.currentOffense,
  incProperties: state.incident.incProperties,
  offenses: state.incident.offenses,
  selectedEntity: state.incident.selectedEntity,
});

export default connect(mapStateToProps, {
  updateIncOffenses,
  updateIncOffensesOffenseForm,
  setCurrentOffense,
})(TabOffense);
