import store from '../../config/configureStore';
import { incVictimSchema } from 'simpl-schema-validation/schema';
import { generateHandleEditOffense } from 'simpl-schema-validation/helpers/generateHandleEdit';

export const validateIncVictims = () => {
  const currentState = store.store.getState().incident;
  const incVictims = currentState.incVictims;
  const parties = currentState.parties;
  const offensesOffenseForm = currentState.offensesOffenseForm;
  const offenses = currentState.offenses;

  let validationErrors = [];

  for (let incVictim of incVictims) {
    let victimeOffense = offenses.find(
      (o) => o.offenseDetails.ptsOffenseId === incVictim.incVictimDetails.ptsOffenseId
    );
    let victimParty = parties.find(
      (p) => p.ptsIncPersonId === incVictim.incVictimDetails.ptsIncPersonId
    );
    let fullName = `${victimParty.PersonId} | ${victimParty.personDetail.values.lastName || ''}, ${
      victimParty.personDetail.values.firstName || ''
    } ${victimParty.personDetail.values.middleName || ''}`;

    let objToValidate = {
      injuryType: incVictim.incVictimDetails.values.injuryType,
      incVictim: incVictim.incVictimDetails,
      category: incVictim.incVictimDetails.values.category,
      offense: victimeOffense?.offenseDetails || offensesOffenseForm,
      incident: currentState.incident,
      aggravatedAssaultHomicide: incVictim.incVictimDetails.values.aggravatedAssaultHomicide,
      victimParty: victimParty,
    };
    let incVictimsValidationContext = incVictimSchema.newContext();
    let result = incVictimsValidationContext.validate(objToValidate, {
      keys: ['injuryType', 'category', 'aggravatedAssaultHomicide'],
    });

    if (!result) {
      incVictimsValidationContext.validationErrors().forEach((error) => {
        validationErrors.push({
          id: incVictim.incVictimDetails.ptsArresteeId,
          ptsIncPersonId: incVictim.incVictimDetails.ptsIncPersonId,
          ptsOffenseId: incVictim.incVictimDetails.ptsOffenseId,
          idType: 'ptsVictimId',
          errType: error.type,
          field: error.name,
          name: fullName,
          message: incVictimsValidationContext.keyErrorMessage(error.name),
          ptsIncidentId: currentState.ptsIncidentId,
          handleEdit: generateHandleEditOffense(
            currentState.ptsIncidentId,
            incVictim.incVictimDetails.ptsOffenseId,
            2
          ),
        });
      });
    }
  }

  return validationErrors;
};
