import React from 'react';
import RMSRadio from '../../../RMSRadio';
import Grid from '@material-ui/core/Grid';
import { connect } from 'react-redux';
import { updateIncOffensesDVVictimSubForm } from '../../../../reducers/IncReducer';

const radioGroup = [
  {
    groupLabel: '',
    ariaLabel: 'typeVictim',
    groupName: 'typeVictim',
    controls: [
      {
        value: 'primary',
        label: 'Primary',
      },
      {
        value: 'secondary',
        label: 'Secondary',
      },
      {
        value: 'unknown',
        label: 'Unknown',
      },
    ],
  },
];

const TypeVictimForm = (props) => {
  const { offensesDVVictimForm, updateIncOffensesDVVictimSubForm } = props;

  const [formState, setFormState] = React.useState({
    isValid: false,
    values: {},
    touched: {},
    errors: {},
  });

  const [offensesDVVictimFormState, setOffensesDVVictimFormState] = React.useState({
    typeVictim: '',
  });

  /**
   * For edit view only.
   */
  React.useEffect(() => {
    setFormState((formState) => ({
      // isValid: errors ? false : true,
      // errors: errors || {},
      ...formState,
      ...(formState.values.typeVictim = offensesDVVictimForm.typeVictim),
    }));
  }, [offensesDVVictimForm]);

  React.useEffect(() => {
    // const errors = validate(formState.values, schema);
    setFormState((formState) => ({
      ...formState,
      // isValid: errors ? false : true,
      // errors: errors || {},
    }));
    setOffensesDVVictimFormState((offensesDVVictimFormState) => ({
      ...offensesDVVictimFormState,
      ...(offensesDVVictimFormState.typeVictim = formState.values.typeVictim),
    }));
    updateIncOffensesDVVictimSubForm(offensesDVVictimFormState);
  }, [formState.values]);

  const handleFormChange = (event) => {
    event.persist();
    setFormState((formState) => ({
      ...formState,
      values: {
        [event.target.name]: event.target.value,
      },
      touched: {
        ...formState.touched,
        [event.target.name]: true,
      },
    }));
  };

  return (
    <Grid container justify="center">
      <Grid item>
        <RMSRadio
          size="small"
          radioGroup={radioGroup}
          selectedValue={formState.values.typeVictim}
          handleFormChange={handleFormChange}
        />
      </Grid>
    </Grid>
  );
};

const mapStateToProps = (state) => ({
  offensesDVVictimForm: state.incident.offensesDVVictimForm,
});

export default connect(mapStateToProps, { updateIncOffensesDVVictimSubForm })(TypeVictimForm);
