export const GET_CODE_VICTIM_SUBJECT_RELATIONSHIP = 'GET_CODE_VICTIM_SUBJECT_RELATIONSHIP';

export const getCodeVictimSubjectRelationship = (state) => {
  return async (dispatch) => {
    return dispatch({ type: GET_CODE_VICTIM_SUBJECT_RELATIONSHIP, payload: state });
  };
};

export default function reducer(state = [], action) {
  switch (action.type) {
    case GET_CODE_VICTIM_SUBJECT_RELATIONSHIP:
      state = action.payload;
      return state;
    default:
      return state;
  }
}
