import SimpleSchema from 'simpl-schema';
import { criminalActivityCategoryCodes } from '../CategoryCodes/criminalActivity';
import { locationCategoryCodes } from '../CategoryCodes/location';
import { forceCategoryCodes } from '../CategoryCodes/force';
import { groupAOffenseCodes } from '../CategoryCodes/groupAOffenses';

SimpleSchema.setDefaultMessages({
  messages: {
    en: {
      'NIBRS-2019.2-38': 'The Location type is required for each offense.',
      'NIBRS-2019.2-41': 'The Number of Premises Entered must be 01–99 when provided.',
      'NIBRS-2019.2-37':
        "The Bias Motivation Code must equal 'NONE' (88) when the Offense Code is 09C (Justifiable Homicide).",
      'NIBRS-2019.2-49':
        'Criminal Activity/Gang Information type is required when the Offense code is 250, 280, 35A, 35B, 39C, 370, 520, 720.',
      'NIBRS-2019.2-48':
        'When the Criminal Activity/Gang Information code is A, F, I, S the Offense code must be 720.',
      'NIBRS-2019.2-47':
        'When the Criminal Activity/Gang Information code is J, G, N the Offense code must be 09A, 09B, 100, 11A, 11B, 11C, 11D, 120, 13A, 13B, 13C.',
      'NIBRS-2019.2-46':
        'When the Criminal Activity/Gang Information code is B, C, D, E, O, P, T, U the Offense code must be 250, 280, 35A, 35B, 39C, 370, or 520.',
      'NIBRS-2019.2-40':
        'The Number of Premises Entered is required when the Offense Code = 220 and the Location type = 14, or 19.',
      'NIBRS-2019.2-55':
        'When the Offense is a homicide offense (09A, 09B, 09C) then the Weapon/Force Involved cannot be 99.',
      'NIBRS-2019.2-53':
        'Weapon/Force Involved type must be omitted when the Offense code is not 09A, 09B, 09C, 100, 11A, 11B, 11C, 11D, 120, 13A, 13B, 210, 520, 64A, 64B.',
      'NIBRS-2019.2-54':
        'When the Offense code = 13B then the Weapon/Force Involved must be 40, 90, 95, or 99.',
      'NIBRS-2019.2-52':
        'Weapon/Force Involved type is required when the Offense code is 09A, 09B, 09C, 100, 11A, 11B, 11C, 11D, 120, 13A, 13B, 210, 520, 64A, 64B.',
      'NIBRS-2019.2-42': 'The Method of Entry must be provided when Offense Code = 220.',
      'NIBRS-2019.2-43': 'The Method of Entry must be omitted when Offense Code is not equal 220.',
      'NIBRS-2019.2-56':
        'When the Offense code = 13B and the Weapon/Force Involved code = 11, 12, 13, 14, or 15 then the Offense code should = 13A.',
      'NIBRS-2019.2-34':
        'When submitting a Group A Incident Report, the Bias Motivation Code is required.',
      'NIBRS-2019.2-30':
        'When submitting a Group A Incident Report and the Offense Code indicates Assault or Homicide (09A, 09B, 09C, 13A, 13B, or 13C), the Offense Attempted/Completed indicator must be "false".',
      'NIBRS-2019.2-31':
        'When submitting a Group A Incident Report, the Offender suspected of Using Code is required.',
      'NIBRS-2019.2-23':
        'When submitting a Group A Incident Report to be ingested into the NIBRS system, Incident, Offense, Victim, and Subject must be provided.',
    },
  },
});

export const offenseDetailsSchema = new SimpleSchema({
  ucrCode: {
    type: String,
    optional: true,
    custom() {
      // NIBRS-2019.2-23: Report Entity Validation
      if (groupAOffenseCodes.includes(this.value)) {
        if (!this.obj.incVictims.length || !this.obj.incSubjects.length) {
          return 'NIBRS-2019.2-23';
        }
      }
    },
  },
  locationCategory: {
    type: String,
    label: 'NIBRS-2019.2-38: Location',
    optional: true,
    custom() {
      if (!this.value) {
        return 'NIBRS-2019.2-38';
      }
    },
  },

  structuresEntered: {
    type: SimpleSchema.Integer,
    optional: true,
    custom() {
      if (this.value && (this.value < 1 || this.value > 99)) return 'NIBRS-2019.2-41';
      if (this.obj.offense.values.statuteDetails?.FBICode === '220') {
        if (
          [locationCategoryCodes[14], locationCategoryCodes[19]].includes(
            this.obj.offense.values.locationCategory
          )
        ) {
          if (!this.value) {
            return 'NIBRS-2019.2-40';
          }
        }
      }
    },
  },

  biasMotivation: {
    type: String,
    optional: true,
    custom() {
      if (this.obj.offense.values.statuteDetails?.FBICode === '09C') {
        if (this.value !== 'NIBRS CODE: 88 | None (no bias)') {
          return 'NIBRS-2019.2-37';
        }
      }
      // NIBRS-2019.2-34: Bias Motivation
      if (groupAOffenseCodes.includes(this.obj.offense.values.statuteDetails?.FBICode)) {
        if (!this.value) {
          return 'NIBRS-2019.2-34';
        }
      }
    },
  },

  offenseAttempted: {
    type: Boolean,
    optional: true,
    custom() {
      // NIBRS-2019.2-30: Offense Attempted/Completed
      if (groupAOffenseCodes.includes(this.obj.offense.values.statuteDetails?.FBICode)) {
        if (
          ['09A', '09B', '09C', '13A', '13B', '13B'].includes(
            this.obj.offense.values.statuteDetails?.FBICode
          )
        ) {
          if (this.value !== false) {
            return 'NIBRS-2019.2-30';
          }
        }
      }
    },
  },

  offenderSuspectedOfUsing: {
    type: String,
    optional: true,
    custom() {
      // NIBRS-2019.2-31: Offender Suspected of Using
      if (groupAOffenseCodes.includes(this.obj.offense.values.statuteDetails?.FBICode)) {
        if (!this.value) {
          return 'NIBRS-2019.2-31';
        }
      }
    },
  },

  forceCategory: {
    type: String,
    optional: true,
    custom() {
      // NIBRS-2019.2-55: Weapon/Force Involved
      if (['09A', '09B', '09C'].includes(this.obj.offense.values.statuteDetails?.FBICode)) {
        if (this.value === forceCategoryCodes[99]) {
          return 'NIBRS-2019.2-55';
        }
      }
      // NIBRS-2019.2-53: Weapon/Force Involved
      // NIBRS-2019.2-52: Weapon/Force Involved
      if (
        ![
          '09A',
          '09B',
          '09C',
          '100',
          '11A',
          '11B',
          '11C',
          '11D',
          '120',
          '13A',
          '13B',
          '210',
          '520',
          '64A',
          '64B',
        ].includes(this.obj.offense.values.statuteDetails?.FBICode)
      ) {
        if (this.value) {
          return 'NIBRS-2019.2-53';
        }
      } else {
        if (!this.value) {
          return 'NIBRS-2019.2-52';
        }
      }

      // NIBRS-2019.2-56: Weapon/Force Involved
      if (this.obj.offense.values.statuteDetails?.FBICode === '13B') {
        if (
          [
            forceCategoryCodes[11],
            forceCategoryCodes[12],
            forceCategoryCodes[13],
            forceCategoryCodes[14],
            forceCategoryCodes[15],
          ].includes(this.value)
        ) {
          return 'NIBRS-2019.2-56';
        }
      }

      // NIBRS-2019.2-54: Weapon/Force Involved
      if (this.obj.offense.values.statuteDetails?.FBICode === '13B') {
        if (
          ![
            forceCategoryCodes[40],
            forceCategoryCodes[90],
            forceCategoryCodes[95],
            forceCategoryCodes[99],
          ].includes(this.value)
        ) {
          return 'NIBRS-2019.2-54';
        }
      }
    },
  },

  criminalActivity: {
    type: String,
    optional: true,
    custom() {
      if (
        ['250', '280', '35A', '35B', '39C', '370', '520', '720'].find(
          (element) => element === this.obj.offense.values.statuteDetails?.FBICode
        )
      ) {
        if (this.value === null || this.value === undefined || this.value === '') {
          return 'NIBRS-2019.2-49';
        }
      }
      if (
        [
          criminalActivityCategoryCodes.A,
          criminalActivityCategoryCodes.F,
          criminalActivityCategoryCodes.I,
          criminalActivityCategoryCodes.S,
        ].includes(this.value)
      ) {
        if (this.obj.offense.values.statuteDetails?.FBICode !== '720') {
          return 'NIBRS-2019.2-48';
        }
      }
      if (
        [
          criminalActivityCategoryCodes.J,
          criminalActivityCategoryCodes.G,
          criminalActivityCategoryCodes.N,
        ].includes(this.value)
      ) {
        if (
          !['09A', '09B', '100', '11A', '11B', '11C', '11D', '120', '13A', '13B', '13C'].includes(
            this.obj.offense.values.statuteDetails?.FBICode
          )
        ) {
          return 'NIBRS-2019.2-47';
        }
      }
      if (
        [
          criminalActivityCategoryCodes.B,
          criminalActivityCategoryCodes.C,
          criminalActivityCategoryCodes.D,
          criminalActivityCategoryCodes.E,
          criminalActivityCategoryCodes.O,
          criminalActivityCategoryCodes.P,
          criminalActivityCategoryCodes.T,
          criminalActivityCategoryCodes.U,
        ].includes(this.value)
      ) {
        if (
          !['250', '280', '35A', '35B', '39C', '370', '520'].includes(
            this.obj.offense.values.statuteDetails?.FBICode
          )
        ) {
          return 'NIBRS-2019.2-46';
        }
      }
    },
  },
  methodOfEntry: {
    type: String,
    optional: true,
    custom() {
      // NIBRS-2019.2-42: Method of Entry
      if (this.obj.offense.values.statuteDetails?.FBICode === '220') {
        if (!this.value) {
          return 'NIBRS-2019.2-42';
        }
      }

      // NIBRS-2019.2-43: Method of Entry
      if (this.obj.offense.values.statuteDetails?.FBICode !== '220') {
        if (this.value) {
          return 'NIBRS-2019.2-43';
        }
      }
    },
  },
});
