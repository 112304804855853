/* eslint-disable default-case */
import React from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';

import PerfectScrollbar from 'react-perfect-scrollbar';

import { resetOffenseLists, resetSubForms } from '../../../reducers/IncReducer';

import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  List,
  ListItem,
  ListItemText,
  Card,
  Button,
  Checkbox,
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    height: 'auto',
  },
  formFields: {
    width: '100%',
    height: '100%',
  },
  child: {
    // border: '2px solid red',
    flex: '1 1 auto',
    // height:"calc(100% - 32px)" //height of toolbar if you know it beforehand
  },
  parent: {
    display: 'flex',
    flexFlow: 'column',
    height: '73.5vh',
  },
  paper: {
    minHeight: window.innerHeight - 90 + 'px',
  },
}));

const handleEdit = (row, entityFlag, history) => {
  if (row.handleEdit) {
    return row.handleEdit(history);
  }
  let editUrl = `/incidents/edit/${row.ptsIncidentId}/`;
  let parentType = null;
  resetOffenseLists('edit');
  resetSubForms('edit');

  if (entityFlag === 'properties' && row.parentType) {
    parentType = row.parentType;
    console.log('Parent: ', parentType);
    editUrl += parentType.toLowerCase();
  } else if (entityFlag !== 'parties') {
    editUrl += entityFlag;
  }

  if (row.partyType && row.partyType === 'Offender') {
    editUrl += 'offenders/' + row.id;
  } else if (row.partyType && row.partyType === 'Victim') {
    editUrl += 'victims/' + row.id;
  } else if (row.partyType && row.partyType === 'Suspect') {
    editUrl += 'suspects/' + row.id;
  } else if (row.partyType) {
    editUrl += 'others/' + row.id;
  } else if (row.ptsIncPersonId) {
    editUrl += 'others/' + row.id;
  } else {
    editUrl += `/${row.id}`;
  }
  history.push(editUrl);
};

const renderPartiesErrors = (partiesErrors, history) => {
  return (
    <>
      {partiesErrors.map((errorObj, index) => (
        <TableRow key={index} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
          <TableCell align="left">
            <ListItem disablePadding>
              <ListItemText align="left" primary={'Parties View'} secondary={`${errorObj.name}`} />
            </ListItem>
          </TableCell>
          <TableCell align="right">
            <List>
              <ListItem disablePadding>
                <ListItemText
                  align="right"
                  primary={errorObj.errType}
                  secondary={errorObj.message}
                />
              </ListItem>
            </List>
          </TableCell>
          <TableCell>
            <Button
              variant="outlined"
              color="primary"
              onClick={() => handleEdit(errorObj, 'parties', history)}>
              Go to the Screen
            </Button>
          </TableCell>
        </TableRow>
      ))}
    </>
  );
};

const renderPropertiesErrors = (propertiesErrors, history) => {
  return (
    <>
      {propertiesErrors.map((errorObj, index) => (
        <TableRow key={index} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
          <TableCell align="left">
            <ListItem disablePadding>
              <ListItemText
                align="left"
                primary={'Properties View'}
                secondary={`${errorObj.name}`}
              />
            </ListItem>
          </TableCell>
          <TableCell align="right">
            <List>
              <ListItem disablePadding>
                <ListItemText
                  align="right"
                  primary={errorObj.errType}
                  secondary={errorObj.message}
                />
              </ListItem>
            </List>
          </TableCell>
          <TableCell>
            <Button
              variant="outlined"
              color="primary"
              onClick={() => handleEdit(errorObj, errorObj.name.toLowerCase(), history)}>
              Go to the Screen
            </Button>
          </TableCell>
        </TableRow>
      ))}
    </>
  );
};

const renderOffenseErrors = (offenseErrors, history) => {
  return (
    <>
      {offenseErrors.map((errorObj, index) => (
        <TableRow key={index} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
          <TableCell align="left">
            <ListItem disablePadding>
              <ListItemText align="left" primary={'Offense View'} secondary={`${errorObj.name}`} />
            </ListItem>
          </TableCell>
          <TableCell align="right">
            <List>
              <ListItem disablePadding>
                <ListItemText
                  align="right"
                  primary={errorObj.errType}
                  secondary={errorObj.message}
                />
              </ListItem>
            </List>
          </TableCell>
          <TableCell>
            <Button
              variant="outlined"
              color="primary"
              onClick={() => handleEdit(errorObj, 'offenses', history)}>
              Go to the Screen
            </Button>
          </TableCell>
        </TableRow>
      ))}
    </>
  );
};

const renderArresteesErrors = (errors, history) => {
  return (
    <>
      {errors.map((errorObj, index) => (
        <TableRow key={index} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
          <TableCell align="left">
            <ListItem disablePadding>
              <ListItemText
                align="left"
                primary={'Offense Arrestee View'}
                secondary={`${errorObj.name}`}
              />
            </ListItem>
          </TableCell>
          <TableCell align="right">
            <List>
              <ListItem disablePadding>
                <ListItemText
                  align="right"
                  primary={errorObj.errType}
                  secondary={errorObj.message}
                />
              </ListItem>
            </List>
          </TableCell>
          <TableCell>
            <Button
              variant="outlined"
              color="primary"
              onClick={() => handleEdit(errorObj, 'offenses', history)}>
              Go to the Screen
            </Button>
          </TableCell>
        </TableRow>
      ))}
    </>
  );
};

const renderIncPropertiesErrors = (errors, history) => {
  return (
    <>
      {errors.map((errorObj, index) => (
        <TableRow key={index} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
          <TableCell align="left">
            <ListItem disablePadding>
              <ListItemText
                align="left"
                primary={'Offense Property View'}
                secondary={`${errorObj.name}`}
              />
            </ListItem>
          </TableCell>
          <TableCell align="right">
            <List>
              <ListItem disablePadding>
                <ListItemText
                  align="right"
                  primary={errorObj.errType}
                  secondary={errorObj.message}
                />
              </ListItem>
            </List>
          </TableCell>
          <TableCell>
            <Button
              variant="outlined"
              color="primary"
              onClick={() => handleEdit(errorObj, 'offenses', history)}>
              Go to the Screen
            </Button>
          </TableCell>
        </TableRow>
      ))}
    </>
  );
};

const renderIncRelationshipErrors = (errors, history) => {
  return (
    <>
      {errors.map((errorObj, index) => (
        <TableRow key={index} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
          <TableCell align="left">
            <ListItem disablePadding>
              <ListItemText
                align="left"
                primary={'Offense Subject-Victim Relationship View'}
                secondary={`${errorObj.name}`}
              />
            </ListItem>
          </TableCell>
          <TableCell align="right">
            <List>
              <ListItem disablePadding>
                <ListItemText
                  align="right"
                  primary={errorObj.errType}
                  secondary={errorObj.message}
                />
              </ListItem>
            </List>
          </TableCell>
          <TableCell>
            <Button
              variant="outlined"
              color="primary"
              onClick={() => handleEdit(errorObj, 'offenses', history)}>
              Go to the Screen
            </Button>
          </TableCell>
        </TableRow>
      ))}
    </>
  );
};

const renderIncVictimErrors = (errors, history) => {
  return (
    <>
      {errors.map((errorObj, index) => (
        <TableRow key={index} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
          <TableCell align="left">
            <ListItem disablePadding>
              <ListItemText
                align="left"
                primary={'Offense Victims View'}
                secondary={`${errorObj.name}`}
              />
            </ListItem>
          </TableCell>
          <TableCell align="right">
            <List>
              <ListItem disablePadding>
                <ListItemText
                  align="right"
                  primary={errorObj.errType}
                  secondary={errorObj.message}
                />
              </ListItem>
            </List>
          </TableCell>
          <TableCell>
            <Button
              variant="outlined"
              color="primary"
              onClick={() => handleEdit(errorObj, 'offenses', history)}>
              Go to the Screen
            </Button>
          </TableCell>
        </TableRow>
      ))}
    </>
  );
};

const renderErrors = (errors, history, checkedViews) => {
  const {
    allChecked,
    partiesChecked,
    propertiesChecked,
    offensesChecked,
    relationshipsChecked,
    offensePropertiesChecked,
    offenseArresteesChecked,
    offenseVictimsChecked,
  } = checkedViews;

  return (
    <>
      {(allChecked === true || partiesChecked === true) &&
        renderPartiesErrors(errors?.errors?.partiesErrors || [], history)}
      {(allChecked === true || propertiesChecked === true) &&
        renderPropertiesErrors(errors?.errors?.propertiesErrors || [], history)}
      {(allChecked === true || offensesChecked === true) &&
        renderOffenseErrors(errors?.errors?.offenseErrors || [], history)}
      {(allChecked === true || relationshipsChecked === true) &&
        renderIncRelationshipErrors(errors?.errors?.incRelationshipErrors || [], history)}
      {(allChecked === true || offensePropertiesChecked === true) &&
        renderIncPropertiesErrors(errors?.errors?.incPropertiesErrors || [], history)}
      {(allChecked === true || offenseArresteesChecked === true) &&
        renderArresteesErrors(errors?.errors?.arresteesErrors || [], history)}
      {(allChecked === true || offenseVictimsChecked === true) &&
        renderIncVictimErrors(errors?.errors?.incVictimErrors || [], history)}
    </>
  );
};

export default function IncIBRSStep() {
  const classes = useStyles();
  const incident = useSelector((state) => state.incident.incident);
  const _validationErrors = useSelector((state) => state.incident.validationErrors);
  const errors = _validationErrors.find((vE) => vE.ptsIncidentId === incident.ptsIncidentId);
  const history = useHistory();

  const [allChecked, setAllChecked] = React.useState(true);
  const [partiesChecked, setPartiesChecked] = React.useState(false);
  const [propertiesChecked, setPropertiesChecked] = React.useState(false);
  const [offensesChecked, setOffensesChecked] = React.useState(false);
  const [relationshipsChecked, setRelationshipsChecked] = React.useState(false);
  const [offensePropertiesChecked, setOffensesPropertiesChecked] = React.useState(false);
  const [offenseArresteesChecked, setOffenseArresteesChecked] = React.useState(false);
  const [offenseVictimsChecked, setOffenseVictimsChecked] = React.useState(false);

  const checkedViews = {
    allChecked,
    partiesChecked,
    propertiesChecked,
    offensesChecked,
    relationshipsChecked,
    offensePropertiesChecked,
    offenseArresteesChecked,
    offenseVictimsChecked,
  };

  const checkBoxList = [
    { checker: allChecked, view: 'all', name: 'All' },
    { checker: partiesChecked, view: 'parties', name: 'Parties' },
    { checker: propertiesChecked, view: 'properties', name: 'Properties' },
    { checker: offensesChecked, view: 'offenses', name: 'Offenses' },
    {
      checker: relationshipsChecked,
      view: 'relationships',
      name: 'Offense(Subject Victim Relationships)',
    },
    { checker: offensePropertiesChecked, view: 'offenseProperties', name: 'Offense(Properties)' },
    { checker: offenseArresteesChecked, view: 'offenseArrestees', name: 'Offense(Arrestees)' },
    { checker: offenseVictimsChecked, view: 'offenseVictims', name: 'Offense(Victims)' },
  ];

  const handleChange = (event, view) => {
    return (
      <>
        {view === 'all'
          ? setAllChecked(event.target.checked)
          : view === 'parties'
          ? setPartiesChecked(event.target.checked)
          : view === 'properties'
          ? setPropertiesChecked(event.target.checked)
          : view === 'offenses'
          ? setOffensesChecked(event.target.checked)
          : view === 'relationships'
          ? setRelationshipsChecked(event.target.checked)
          : view === 'offenseProperties'
          ? setOffensesPropertiesChecked(event.target.checked)
          : view === 'offenseArrestees'
          ? setOffenseArresteesChecked(event.target.checked)
          : view === 'offenseVictims'
          ? setOffenseVictimsChecked(event.target.checked)
          : ''}
      </>
    );
  };

  return (
    <Grid container className={classes.root}>
      <Paper
        style={{
          minHeight: window.innerHeight - 190 + 'px',
          marginBottom: '10px',
          width: '100%',
        }}>
        <Grid container spacing={1} className="p-4">
          <Grid item xs={12} sm={6} md={1}>
            <Card className="card-box-alt card-border-top border-first mb-2 pb-1">
              <h5 className="font-size-md font-weight-bold mt-3 mb-2">Parties</h5>
              <p className="card-text px-4 mb-4">{errors?.errors?.partiesErrors?.length || 0}</p>
            </Card>
          </Grid>
          <Grid item xs={12} sm={6} md={1}>
            <Card className="card-box-alt card-border-top border-first mb-2 pb-1">
              <h5 className="font-size-md font-weight-bold mt-3 mb-2">Properties</h5>
              <p className="card-text px-4 mb-4">{errors?.errors?.propertiesErrors?.length || 0}</p>
            </Card>
          </Grid>
          <Grid item xs={12} sm={6} md={1}>
            <Card className="card-box-alt card-border-top border-first mb-2 pb-1">
              <h5 className="font-size-md font-weight-bold mt-3 mb-2">Offenses</h5>
              <p className="card-text px-4 mb-4">{errors?.errors?.offenseErrors?.length || 0}</p>
            </Card>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <Card className="card-box-alt card-border-top border-first mb-2 pb-1">
              <h5 className="font-size-md font-weight-bold mt-3 mb-2">Offense</h5>
              <p className="font-size-sm">Subject-Victim Relationships</p>
              <p className="card-text px-4 mb-4">
                {errors?.errors?.incRelationshipErrors?.length || 0}
              </p>
            </Card>
          </Grid>
          <Grid item xs={12} sm={6} md={2}>
            <Card className="card-box-alt card-border-top border-first mb-2 pb-1">
              <h5 className="font-size-md font-weight-bold mt-3 mb-2">Offense</h5>
              <p className="font-size-sm">Properties</p>
              <p className="card-text px-4 mb-4">
                {errors?.errors?.incPropertiesErrors?.length || 0}
              </p>
            </Card>
          </Grid>
          <Grid item xs={12} sm={6} md={2}>
            <Card className="card-box-alt card-border-top border-first mb-2 pb-1">
              <h5 className="font-size-md font-weight-bold mt-3 mb-2">Offense</h5>
              <p className="font-size-sm">Arrestees</p>
              <p className="card-text px-4 mb-4">{errors?.errors?.arresteesErrors?.length || 0}</p>
            </Card>
          </Grid>
          <Grid item xs={12} sm={6} md={2}>
            <Card className="card-box-alt card-border-top border-first mb-2 pb-1">
              <h5 className="font-size-md font-weight-bold mt-3 mb-2">Offense</h5>
              <p className="font-size-sm">Victims</p>
              <p className="card-text px-4 mb-4">{errors?.errors?.incVictimErrors?.length || 0}</p>
            </Card>
          </Grid>
        </Grid>

        <Grid item xs={12} container justify="center" style={{ marginTop: '-30px' }}>
          {checkBoxList.map((item, index) => {
            return (
              <>
                <Checkbox
                  checked={item.checker}
                  onChange={(event) => handleChange(event, item.view)}
                  inputProps={{ 'aria-label': 'controlled' }}
                />
                <p style={{ paddingTop: '15px' }}>{item.name}</p>
              </>
            );
          })}
        </Grid>

        <Grid item xs={12} className="p-4">
          <TableContainer
            style={{
              overflowX: 'hidden',
              maxHeight: window.innerHeight - 270 + 'px',
              marginBottom: '20px',
            }}>
            <PerfectScrollbar
              style={{
                height: window.innerHeight - 470 + 'px',
                overflowX: 'hidden',
              }}>
              <Table sx={{ minWidth: 650 }} size="small" aria-label="simple table" stickyHeader>
                <TableHead>
                  <TableRow>
                    <TableCell align="left">Views</TableCell>
                    <TableCell align="right">Details</TableCell>
                    <TableCell align="right"></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>{renderErrors(errors, history, checkedViews)}</TableBody>
              </Table>
            </PerfectScrollbar>
          </TableContainer>
        </Grid>
      </Paper>
    </Grid>
  );
}
