import React from 'react';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import makeStyles from '@material-ui/core/styles/makeStyles';

const useStyles = makeStyles(() => ({
  checkbox: {
    color: 'grey',
  },
  checkboxLabel: {
    color: 'grey',
  },
}));

const RMSCheckbox = (props) => {
  const classes = useStyles();
  const { label, name, selectedCheckbox, handleFormChange, key } = props;

  return (
    <FormControl>
      <FormControlLabel
        className={classes.checkboxLabel}
        control={
          <Checkbox
            id="rms-checkbox"
            key={key}
            checked={selectedCheckbox}
            className={classes.checkbox}
            onChange={handleFormChange}
            name={name}
            variant="outlined"
          />
        }
        label={label}
      />
    </FormControl>
  );
};

export default RMSCheckbox;
