import store from '../../config/configureStore';

import { partiesSchema } from 'simpl-schema-validation/schema';

export const validatePartiesForm = (field) => {
  const currentState = store.store.getState().incident;
  const { partiesPersonForm, incVictims, offenses, incSubjects } = currentState;

  let party = partiesPersonForm;

  let victim = incVictims.find(
    (incVictim) => incVictim.incVictimDetails.ptsIncPersonId === party.ptsIncPersonId
  );

  let victimOffense = offenses.find(
    (offense) => offense.offenseDetails.ptsOffenseId === victim?.incVictimDetails.ptsOffenseId
  );

  let offender = incSubjects.find(
    (incSubject) => incSubject.incSubjectDetails.ptsIncPersonId === party.ptsIncPersonId
  );

  let subjectOffense = offenses.find(
    (offense) => offense.offenseDetails.ptsOffenseId === offender?.incSubjectDetails.ptsOffenseId
  );

  let objToValidate = {
    age: parseInt(party.values.age),
    sex: party.values.sex,
    race: party.values.race,
    ethnicity: party.values.ethnicity,
    arresteeAge: parseInt(party.values.age),
    arresteeRace: party.values.race,
    arresteeSex: party.values.sex,
    victim: victim ? victim.incVictimDetails : null,
    victimOffense: victimOffense ? victimOffense.offenseDetails : null,
    offender: offender ? offender.incSubjectDetails : null,
    subjectOffense: subjectOffense ? subjectOffense.offenseDetails : null,
  };

  let partiesValidationContext = partiesSchema.newContext();
  let result = partiesValidationContext.validate(objToValidate, {
    keys: [field],
  });

  let partiesFormErrors = [];
  if (!result) {
    partiesValidationContext.validationErrors().forEach((error) => {
      if (field === error.name) {
        error.message = partiesValidationContext.keyErrorMessage(error.name);
        partiesFormErrors.push(error);
      }
    });
  }

  return partiesFormErrors || [];
};
