import React from 'react';
import { connect } from 'react-redux';
import { useParams } from 'react-router';

import PropTypes from 'prop-types';
import clsx from 'clsx';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserPlus, faWarehouse, faBan } from '@fortawesome/free-solid-svg-icons';
import Card from '@material-ui/core/Card';
import Grid from '@material-ui/core/Grid';

import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import AssignmentIcon from '@material-ui/icons/Assignment';

import { makeStyles, styled } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';

import TabEvidenceItemRecord from './TabEvidenceItemRecord';
import TabEvidenceCustody from './TabEvidenceCustody';
import TabEvidenceStorage from './TabEvidenceStorage';
import TabEvidenceDisposition from './TabEvidenceDisposition';
import IncSummaryDetails from '../../components/RMSForms/RMSIncidentForm/IncSummaryDetails';
import RMSFabButtons from '../../components/RMSForms/RMSIncidentForm/RMSFabButtons';

import { setHandleClose, setMenuType } from '../../reducers/ui/UiMenuReducer';
import {
  updateIncEvidenceItemForm,
  getEntitiesByIncidentId,
  updateIncEvidenceDispositionForm,
  setIncEvidenceStorageDetails,
  setIncEvidenceCustodyDetails,
  setSelectedIncident,
  setSelectedEntity,
} from '../../reducers/IncReducer';

const StyledStepLabel = styled(StepLabel)({
  cursor: 'pointer',

  '& .MuiStepLabel-label': {
    color: '#ccc',
    padding: '0px',
    marginTop: '0px',
  },
  '& .MuiStepLabel-active': {
    color: 'white',
  },
});

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    height: window.innerHeight - 94 + 'px',
  },
  scrollBar: {
    width: '100%',
    overflowX: 'hidden',

    [theme.breakpoints.up('lg')]: {
      height: '69vh',
    },
    [theme.breakpoints.down('lg')]: {
      height: '50vh',
    },
    [theme.breakpoints.only('lg')]: {
      height: '65vh',
    },
  },
  indicator: {
    backgroundColor: 'rgba(0,0,254,0.4)',
  },
  icons: {
    textDecoration: 'capitalize',
    padding: '11px',
    color: 'white',
    fontSize: '13px',
  },
}));

const useColorlibStepIconStyles = makeStyles({
  root: {
    zIndex: 1,
    color: '#ccc',
    width: 30,
    height: 30,
    display: 'flex',
    borderRadius: '50%',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: '10px',
  },
  active: {
    color: 'black',
    backgroundColor: 'whiteSmoke',
  },
});

function ColorlibStepIcon(props) {
  const classes = useColorlibStepIconStyles();
  const { active } = props;

  const icons = {
    1: <AssignmentIcon style={{ fontSize: '17px' }} />,
    2: <FontAwesomeIcon icon={faUserPlus} style={{ fontSize: '15px' }} />,
    3: <FontAwesomeIcon icon={faWarehouse} style={{ fontSize: '15px' }} />,
    4: <FontAwesomeIcon icon={faBan} style={{ fontSize: '15px' }} />,
  };

  return (
    <div
      className={clsx(classes.root, {
        [classes.active]: active,
      })}>
      {icons[String(props.icon)]}
    </div>
  );
}

ColorlibStepIcon.propTypes = {
  active: PropTypes.bool,
  completed: PropTypes.bool,
  icon: PropTypes.node,
};

const AddEditEvidence = (props) => {
  /** region Component Variables and Props */
  const classes = useStyles();
  const {
    history,
    setHandleClose,
    setMenuType,
    wsClient,
    selectedEntity,
    selectedIncident,
    evidences,
    getEntitiesByIncidentId,
    updateIncEvidenceItemForm,
    setIncEvidenceStorageDetails,
    setIncEvidenceCustodyDetails,
    updateIncEvidenceDispositionForm,
    setSelectedIncident,
    setSelectedEntity,
  } = props;
  const [value, setValue] = React.useState(0);
  let { incId, evidenceId } = useParams();
  const steps = [
    { label: 'Evidence Item Record' },
    { label: 'Custody' },
    { label: 'Storage' },
    { label: 'Evidence Disposition' },
  ];
  /** endregion */
  /** region React Hooks */
  React.useEffect(() => {
    (async () => {
      await Promise.all([
        getEntitiesByIncidentId('offenses'),
        getEntitiesByIncidentId('properties'),
      ]);
    })();

    setMenuType('forms');
    setHandleClose('incForms', history);

    if (!selectedIncident || !selectedEntity || evidences.length === 0) {
      return;
    }

    const { ptsEvidenceId } = selectedEntity;

    const selectedEvidenceDetail = evidences.filter(
      (a) => a.itemRecordDetail.ptsEvidenceId === ptsEvidenceId
    );

    if (selectedEvidenceDetail.length === 0) {
      return;
    }

    const {
      itemRecordDetail,
      dispositionDetail,
      custodyDetail,
      storageDetail,
    } = selectedEvidenceDetail[0];

    updateIncEvidenceItemForm(itemRecordDetail);
    updateIncEvidenceDispositionForm(dispositionDetail);
    setIncEvidenceStorageDetails(storageDetail);
    setIncEvidenceCustodyDetails(custodyDetail);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedIncident !== 0, selectedEntity !== null, evidences.length]);

  React.useEffect(() => {
    if (!wsClient.websocket) return;
    getEntitiesByIncidentId('evidence', {});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [wsClient, selectedIncident !== 0]);

  React.useEffect(() => {
    if (evidenceId) setSelectedEntity({ ptsEvidenceId: parseInt(evidenceId) });
    if (incId) setSelectedIncident(parseInt(incId));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedIncident === 0, selectedEntity === null]);
  /** endregion */
  /** region Helper Functions */
  const handleChange = (newValue) => {
    setValue(newValue);
  };

  const isStepOptional = () => {
    return false;
  };
  /** endregion */
  return (
    <div className={classes.root}>
      <RMSFabButtons />
      <Grid container>
        <Grid xs={3} lg={3}>
          <IncSummaryDetails history={history} />
        </Grid>
        <Grid xs={9} lg={9}>
          <Card className={classes.root}>
            <Grid container justify="center">
              <Grid item xs={12} lg={12}>
                <Box className={classes.stepper}>
                  <Stepper
                    alternativeLabel
                    nonLinear
                    activeStep={value}
                    style={{
                      backgroundColor: '#1976d2',
                      borderRadius: '0.5rem',
                      padding: '15px 5px',
                    }}>
                    {steps.map((obj, index) => {
                      const stepProps = {};
                      const buttonProps = {};
                      if (isStepOptional(index)) {
                        buttonProps.optional = <Typography variant="caption">Optional</Typography>;
                      }
                      return (
                        <Step key={obj.label} {...stepProps}>
                          <StyledStepLabel
                            StepIconComponent={ColorlibStepIcon}
                            onClick={() => handleChange(index)}>
                            {obj.label}
                          </StyledStepLabel>
                        </Step>
                      );
                    })}
                  </Stepper>
                </Box>
              </Grid>

              <Grid item xs={12} lg={12}>
                {value === 0 && (
                  <Grid item>
                    <TabEvidenceItemRecord />
                  </Grid>
                )}

                {value === 1 && (
                  <Grid item>
                    <TabEvidenceCustody />
                  </Grid>
                )}
                {value === 2 && (
                  <Grid item>
                    <TabEvidenceStorage />
                  </Grid>
                )}
                {value === 3 && (
                  <Grid item>
                    <TabEvidenceDisposition />
                  </Grid>
                )}
              </Grid>
            </Grid>
          </Card>
        </Grid>
      </Grid>
    </div>
  );
};
const mapStateToProps = (state) => ({
  wsClient: state.websocket,
  selectedEntity: state.incident.selectedEntity,
  selectedIncident: state.incident.ptsIncidentId,
  evidences: state.incident.evidences,
});

export default connect(mapStateToProps, {
  setMenuType,
  setHandleClose,
  updateIncEvidenceItemForm,
  setIncEvidenceStorageDetails,
  setIncEvidenceCustodyDetails,
  getEntitiesByIncidentId,
  updateIncEvidenceDispositionForm,
  setSelectedEntity,
  setSelectedIncident,
})(AddEditEvidence);
