export const GET_CODE_PROPERTY_CATEGORY = 'GET_CODE_PROPERTY_CATEGORY';
export const ADD_CODE_PROPERTY_CATEGORY = 'ADD_CODE_PROPERTY_CATEGORY';

export const addCodePropertyCategory = (newVal) => {
  return { type: ADD_CODE_PROPERTY_CATEGORY, payload: newVal };
};

export const getCodePropertyCategory = (state) => {
  return async (dispatch) => {
    return dispatch({ type: GET_CODE_PROPERTY_CATEGORY, payload: state });
  };
};

export default function reducer(state = [], action) {
  switch (action.type) {
    case GET_CODE_PROPERTY_CATEGORY:
      state = action.payload;
      return state;
    case ADD_CODE_PROPERTY_CATEGORY:
      return [...state, action.payload];
    default:
      return state;
  }
}
