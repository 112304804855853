import React from 'react';
import { DragDropContext } from 'react-beautiful-dnd';
import DroppableColumn from './DroppableColumn';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core';
import { colors } from '../../config/colorVariables';
import testData from '../../pages/PageRMSDev/DragDropDev/testData';

const useStyles = () => ({
  root: {
    height: '100%',
  },
  column: {
    textAlign: 'center',
  },
});

class InnerList extends React.Component {
  render() {
    const { column, taskMap, index } = this.props;
    const tasks = column.taskIds.map((taskId) => taskMap[taskId]);
    let color = '';
    switch (column.title) {
      case 'Pending':
        color = colors.cyan;
        break;
      case 'Approved':
        color = colors.green;
        break;
      case 'Submitted':
        color = colors.yellow;
        break;
      case 'Disposed':
        color = colors.red;
        break;
    }
    return <DroppableColumn color={color} column={column} tasks={tasks} index={index} />;
  }
}

class DragAndDropContext extends React.Component {
  state = testData;
  onDragEnd = (result, provided) => {
    const { destination, source, draggableId, type } = result;
    if (!destination) {
      return;
    }
    if (destination.droppableId === source.droppableId && destination.index === source.index) {
      return;
    }

    const home = this.state.columns[source.droppableId];
    const foreign = this.state.columns[destination.droppableId];

    if (home === foreign) {
      const newTaskIds = Array.from(home.taskIds);
      const { index } = source;
      newTaskIds.splice(index, 1);
      newTaskIds.splice(destination.index, 0, draggableId);

      const newHome = {
        ...home,
        taskIds: newTaskIds,
      };

      const newState = {
        ...this.state,
        columns: {
          ...this.state.columns,
          [newHome.id]: newHome,
        },
      };

      this.setState(newState);
      return;
    }

    const homeTaskIds = Array.from(home.taskIds);
    const { index: index1 } = source;
    homeTaskIds.splice(index1, 1);
    const newHome = {
      ...home,
      taskIds: homeTaskIds,
    };

    const foreignTaskIds = Array.from(foreign.taskIds);
    foreignTaskIds.splice(destination.index, 0, draggableId);
    const newForeign = {
      ...foreign,
      taskIds: foreignTaskIds,
    };

    const newState = {
      ...this.state,
      columns: {
        ...this.state.columns,
        [newHome.id]: newHome,
        [newForeign.id]: newForeign,
      },
    };
    this.setState(newState);
  };

  render() {
    const { classes } = this.props;
    return (
      <DragDropContext onDragEnd={this.onDragEnd}>
        <Grid container className={classes.root} justify="center">
          {this.state.columnOrder.map((columnId, index) => {
            const column = this.state.columns[columnId];
            return (
              <Grid item key={column.id} className={classes.column}>
                <InnerList
                  key={column.id}
                  column={column}
                  taskMap={this.state.tasks}
                  index={index}
                />
              </Grid>
            );
          })}
        </Grid>
      </DragDropContext>
    );
  }
}

export default withStyles(useStyles)(DragAndDropContext);
