/**
 * Menu item configurations for desktop and mobile
 */

// eslint-disable-next-line import/no-anonymous-default-export
export default [
  {
    label: 'Incidents',
    tooltip: 'Incidents',
    link: '/incidents',
    icon: 'laptop',
  },

  {
    label: 'Host Val',
    tooltip: 'Hosting Validation',
    link: '/hosting-validation',
    icon: 'cog',
  },
  {
    label: 'Person',
    tooltip: 'Person',
    link: '/records/person',
    icon: 'cog',
  },
  {
    label: 'Address',
    tooltip: 'Address',
    link: '/records/address',
    icon: 'cog',
  },
  {
    label: 'Property',
    tooltip: 'Property',
    icon: 'folder-plus',
  },
];
