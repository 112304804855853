import React from 'react';
import { Fab, Tooltip } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';

export default function RMSDeleteButton(props) {
  const { onClick, disabled } = props;
  return (
    <Tooltip title="Delete">
      <Fab size="small" disabled={disabled} color="secondary" aria-label="edit" onClick={onClick}>
        <DeleteIcon />
      </Fab>
    </Tooltip>
  );
}
