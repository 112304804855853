import React, { useEffect } from 'react';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';

const RMSRadio = (props) => {
  const { radioGroup, placement } = props;
  const { selectedValue, handleFormChange } = props;
  const [value, setValue] = React.useState(selectedValue || '');

  useEffect(() => {
    if (selectedValue) {
      selectedValue === 'true' || selectedValue === true || selectedValue === '1'
        ? setValue(true)
        : selectedValue === 'false' || selectedValue === false || selectedValue === '0'
        ? setValue(false)
        : setValue(selectedValue);
    }
  }, [selectedValue]);

  const handleChange = (event) => {
    setValue(event.target.value);
    handleFormChange(event);
  };

  return (
    <FormControl size="small" component="fieldset">
      {radioGroup.map((g) => {
        return (
          <>
            <FormLabel component="legend">{g.groupLabel}</FormLabel>
            <RadioGroup
              aria-label={g.ariaLabel}
              name={g.groupName}
              value={value}
              row
              onChange={handleChange}>
              {g.controls.map((c) => {
                return (
                  <FormControlLabel
                    labelPlacement={placement}
                    value={c.value}
                    control={<Radio size="small" />}
                    label={c.label}
                  />
                );
              })}
            </RadioGroup>
          </>
        );
      })}
    </FormControl>
  );
};

export default RMSRadio;
