import store from '../../config/configureStore';

import { offenseDetailsSchema } from 'simpl-schema-validation/schema';
import { validateAllOffenses } from '.';
import { generateHandleEditOffense } from 'simpl-schema-validation/helpers/generateHandleEdit';

export const validateOffenses = async () => {
  const currentState = store.store.getState().incident;
  const offenses = currentState.offenses;

  let validationErrors = [];

  offenses.forEach((offense) => {
    let structuresEntered = parseInt(offense.offenseDetails.values.structuresEntered);

    if (Number.isNaN(structuresEntered)) {
      structuresEntered = 0;
    }

    let objToValidate = {
      offense: offense.offenseDetails,
      locationCategory: offense.offenseDetails.values.locationCategory,
      biasMotivation: offense.offenseDetails.values.biasMotivation,
      criminalActivity: offense.offenseDetails.values.criminalActivity,
      structuresEntered,
      forceCategory: offense.offenseDetails.values.forceCategory,
      methodOfEntry: offense.offenseDetails.values.methodOfEntry,
      offenseAttempted: offense.offenseDetails.values.offenseAttempted,
      offenderSuspectedOfUsing: offense.offenseDetails.values.offenderSuspectedOfUsing,
      ucrCode: offense.offenseDetails.values.statuteDetails
        ? offense.offenseDetails.values.statuteDetails.FBICode
        : null,
      incVictims: currentState.incVictims,
      incSubjects: currentState.incSubjects,
    };

    let offenseValidationContext = offenseDetailsSchema.newContext();
    let result = offenseValidationContext.validate(objToValidate, {
      keys: [
        'locationCategory',
        'structuresEntered',
        'biasMotivation',
        'criminalActivity',
        'forceCategory',
        'methodOfEntry',
        'offenseAttempted',
        'offenderSuspectedOfUsing',
        'ucrCode',
      ],
    });

    if (!result) {
      offenseValidationContext.validationErrors().forEach((error) => {
        validationErrors.push({
          id: offense.offenseDetails.ptsOffenseId,
          idType: 'ptsOffenseId',
          errType: error.type,
          field: error.name,
          name:
            offense.offenseDetails.values.statuteDetails?.Description +
            ' | ' +
            offense.offenseDetails.values.statuteDetails?.Code,
          message: offenseValidationContext.keyErrorMessage(error.name),
          ptsIncidentId: currentState.ptsIncidentId,
          ptsOffenseId: offense.offenseDetails.ptsOffenseId,
          handleEdit: generateHandleEditOffense(
            currentState.ptsIncidentId,
            offense.offenseDetails.ptsOffenseId,
            0
          ),
        });
      });
    }
  });
  const allOffensesValidationErrors = await validateAllOffenses();

  return validationErrors.concat(allOffensesValidationErrors);
};
