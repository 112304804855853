import React from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';

const PageDashboard = () => {
  return (
    <PerfectScrollbar style={{ height: '90vh', overflowX: 'hidden' }}>
      <div></div>
    </PerfectScrollbar>
  );
};

export default PageDashboard;
