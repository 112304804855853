import { Button } from '@material-ui/core';
import React from 'react';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';

export default function RMSClearButton(props) {
  const { onClick, text } = props;
  return (
    <Button
      style={{ width: '130px', marginLeft: '13px' }}
      size="large"
      onClick={onClick}
      startIcon={<DeleteOutlineIcon />}>
      <span style={{ flex: 'none' }}>{text}</span>
    </Button>
  );
}
