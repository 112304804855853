/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Paper, Grid } from '@material-ui/core';

import { connect } from 'react-redux';
import RMSAttachments from 'components/RMSAttachments';
import { handleFormChangeNew } from 'reducers/helpers/formHelpers';
import { updateCurrentIncident } from 'reducers/IncReducer';

const useStyles = makeStyles((theme) => ({
  root: {
    boxSizing: 'border-box',
  },
  formFields: {
    width: '100%',
    height: '100%',
  },
  formCard: {
    width: '100%',
    height: '100%',
  },
  lists: {
    marginTop: '20px',
  },
}));

const IncAttachmentStep = (props) => {
  const classes = useStyles();
  const { incident, updateCurrentIncident, wsClient } = props;
  const [incidentState, setIncidentState] = React.useState({
    isValid: false,
    values: {},
    touched: {},
    errors: {},
    formType: 'edit',
    changed: false,
  });

  React.useEffect(() => {
    setIncidentState((incidentState) => ({
      ...incidentState,
      ...incident,
      changed: true,
      values: {
        ...incidentState.values,
        ...incident.values,
      },
    }));
  }, [
    incident?.values?.callType,
    incident?.values?.status,
    incident?.values?.incidentCategory,
    incident?.values?.weather,
    incident?.values?.lighting,
    incident?.values?.locationCategory,
    incident?.values?.locationName,
    incident?.values?.locationCrossStreet,
    incident?.values?.locationHighway,
    incident?.values?.locationLandmarkText,
    incident?.values?.locationSubdivision,
    incident?.ptsIncidentId,
    incident.values?.received,
    incident.values?.dispatched,
    incident.values?.enroute,
    incident.values?.arrived,
    incident.values?.underCont,
    incident.values?.completed,
    incident.values?.attachments,
  ]);

  React.useEffect(() => {
    updateCurrentIncident(incidentState);
  }, [
    incidentState?.values?.callType,
    incidentState?.values?.status,
    incidentState?.values?.incidentCategory,
    incidentState?.values?.weather,
    incidentState?.values?.lighting,
    incidentState?.values?.locationCategory,
    incidentState?.values?.locationName,
    incidentState?.values?.locationCrossStreet,
    incidentState?.values?.locationHighway,
    incidentState?.values?.locationLandmarkText,
    incidentState?.values?.locationSubdivision,
    incidentState.values?.attachments,
    incidentState?.ptsIncidentId,
  ]);

  React.useEffect(() => {
    updateCurrentIncident(incidentState);
  }, [incidentState]);

  React.useEffect(() => {
    if (incidentState.ptsIncidentId) {
      getAttachments();
    }
  }, [incidentState.ptsIncidentId]);

  const getAttachments = async () => {
    const service = wsClient.websocket.service('rms-attachments');
    service.timeout = 200000;

    const result = await service.find({
      query: {
        id: incidentState.ptsIncidentId,
        type: 'INCIDENT',
      },
    });
    let atts = [];
    result.map((r) => {
      atts.push({
        ptsAttachmentId: r.ptsAttachmentID,
        name: r.FileName,
        type: r.FileType,
        dataURL: r.FileObject,
      });
    });
    setIncidentState((stateValue) => ({
      ...stateValue,
      values: {
        ...stateValue.values,
        attachments: atts,
      },
    }));
  };

  return (
    <Grid container>
      <Grid item xs={12} md={12} lg={12}>
        <div className={classes.root}>
          <Paper
            style={{
              marginBottom: '10px',
              padding: '10px',
              height: window.innerHeight - 191 + 'px',
            }}>
            <RMSAttachments
              isModal={true}
              attachments={incidentState.values.attachments}
              stateHolder={incidentState}
              setStateHolder={setIncidentState}
              setField={handleFormChangeNew}
            />
          </Paper>
        </div>
      </Grid>
    </Grid>
  );
};

const mapStateToProps = (store) => ({
  incident: store.incident.incident,
  wsClient: store.websocket,
});

export default connect(mapStateToProps, { updateCurrentIncident })(IncAttachmentStep);
