import React from 'react';
import { connect } from 'react-redux';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import PerfectScrollbar from 'react-perfect-scrollbar';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';

import { makeStyles, styled } from '@material-ui/core/styles';
import HomeWorkIcon from '@material-ui/icons/HomeWork';
import FingerprintIcon from '@material-ui/icons/Fingerprint';
import GpsFixedIcon from '@material-ui/icons/GpsFixed';

import TabAddress from '../PageRMSAddress/TabAddress';
import TabIdentifiers from '../PageRMSAddress/TabIdentifiers';
import TabCoordinates from '../PageRMSAddress/TabCoordinates';
import RMSFabButtons from '../../components/RMSForms/RMSIncidentForm/RMSFabButtons';
import {
  updateIncAddressForm,
  updateIncAddressIdentifiersForm,
  updateIncCoordinatesForm,
} from '../../reducers/IncReducer';
import { setHandleClose, setMenuType } from '../../reducers/ui/UiMenuReducer';
import { Box, Step, StepLabel, Stepper, Typography } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    height: window.innerHeight - 90 + 'px',
  },
  scrollBar: {
    width: '100%',
    overflowX: 'hidden',

    paddingRight: '5px',
    [theme.breakpoints.up('lg')]: {
      height: '70vh',
    },
    [theme.breakpoints.down('lg')]: {
      height: '55vh',
    },
    [theme.breakpoints.only('lg')]: {
      height: '65vh',
    },
  },
  indicator: {
    backgroundColor: 'rgba(0,0,254,0.3)',
  },
  paper: {
    minHeight: window.innerHeight - 95 + 'px',
  },
  dialogBox: {
    minWidth: '412px',
    minHeight: '150px',
  },
  icons: {
    textDecoration: 'capitalize',
    padding: '14px',
    fontSize: '13px',
  },
}));

const useColorlibStepIconStyles = makeStyles({
  root: {
    zIndex: 1,
    color: '#ccc',
    width: 30,
    height: 30,
    display: 'flex',
    borderRadius: '50%',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: '10px',
  },
  active: {
    color: 'black',
    backgroundColor: 'whiteSmoke',
  },
});

const StyledStepLabel = styled(StepLabel)({
  cursor: 'pointer',

  '& .MuiStepLabel-label': {
    color: '#ccc',
    padding: '0px',
    marginTop: '0px',
  },
  '& .MuiStepLabel-active': {
    color: 'white',
  },
});

function ColorlibStepIcon(props) {
  const classes = useColorlibStepIconStyles();
  const { active } = props;

  const icons = {
    1: <HomeWorkIcon style={{ fontSize: '17px' }} />,
    2: <FingerprintIcon icon="user" style={{ fontSize: '15px' }} />,
    3: <GpsFixedIcon icon="user-shield" style={{ fontSize: '15px' }} />,
  };

  return (
    <div
      className={clsx(classes.root, {
        [classes.active]: active,
      })}>
      {icons[String(props.icon)]}
    </div>
  );
}

ColorlibStepIcon.propTypes = {
  active: PropTypes.bool,
  completed: PropTypes.bool,
  icon: PropTypes.node,
};

const AddEditAddress = () => {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);
  const steps = [{ label: 'Address' }, { label: 'Identifiers' }, { label: 'Coordinates' }];

  const handleChange = (newValue) => {
    setValue(newValue);
  };
  const isStepOptional = () => {
    return false;
  };

  return (
    <div className={classes.root}>
      <RMSFabButtons />
      <Grid container>
        <Grid xs={12} lg={12}>
          <Paper className={classes.paper}>
            <Grid container justify="center">
              <Grid item xs={12} lg={12}>
                <Box className={classes.stepper}>
                  <Stepper
                    alternativeLabel
                    nonLinear
                    activeStep={value}
                    style={{
                      backgroundColor: '#1976d2',
                      borderRadius: '0.5rem',
                      padding: '15px 5px',
                    }}>
                    {steps.map((obj, index) => {
                      const stepProps = {};
                      const buttonProps = {};
                      if (isStepOptional(index)) {
                        buttonProps.optional = <Typography variant="caption">Optional</Typography>;
                      }
                      return (
                        <Step key={obj.label} {...stepProps}>
                          <StyledStepLabel
                            StepIconComponent={ColorlibStepIcon}
                            onClick={() => handleChange(index)}>
                            {obj.label}
                          </StyledStepLabel>
                        </Step>
                      );
                    })}
                  </Stepper>
                </Box>
              </Grid>
              <PerfectScrollbar className={classes.scrollBar}>
                {value === 0 && (
                  <Grid item>
                    <TabAddress />
                  </Grid>
                )}
                {value === 1 && (
                  <Grid item>
                    <TabIdentifiers />
                  </Grid>
                )}
                {value === 2 && (
                  <Grid item>
                    <TabCoordinates />
                  </Grid>
                )}
              </PerfectScrollbar>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    </div>
  );
};

const mapStateToProps = (state) => ({
  state: state,
  selectedEntity: state.incident.selectedEntity,
  selectedIncident: state.incident.ptsIncidentId,
  addresses: state.incident.addresses,
  recordActive: state.records.recordActive,
});

export default connect(mapStateToProps, {
  setHandleClose,
  setMenuType,
  updateIncAddressForm,
  updateIncAddressIdentifiersForm,
  updateIncCoordinatesForm,
})(AddEditAddress);
