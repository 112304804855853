import React, { useRef } from 'react';
import { connect } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import { PersistGate } from 'redux-persist/integration/react';
import * as serviceWorker from './serviceWorker';
import Routes from './Routes';
import store from './config/configureStore';
import { history } from './config/configureStore';
import ScrollToTop from './utils/ScrollToTop';
import CssBaseline from '@material-ui/core/CssBaseline';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import { DayTheme, NightTheme } from './theme';
import { ThemeProvider } from '@material-ui/styles';
import { SnackbarProvider } from 'notistack';
import Notifier from 'global-components/Notifier';
import { AnimatePresence } from 'framer-motion';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import Spinner from 'components/Spinner';
import './assets/base.scss';
import './icons';
import RMSAddModal from 'components/RMSAddModal';
import { LicenseInfo } from '@material-ui/x-grid';
LicenseInfo.setLicenseKey(process.env.REACT_APP_XGRID_KEY);

const App = (props) => {
  const snackbarRef = useRef();
  const { showSpinner } = props.ui;
  const themeMode = () => {
    let theme = DayTheme;
    if (props.mode === 'night') {
      theme = NightTheme;
    }
    return theme;
  };

  const updateApp = (registration) => {
    window.localStorage.clear();
    const waitingServiceWorker = registration.waiting;

    if (waitingServiceWorker) {
      waitingServiceWorker.addEventListener('statechange', (event) => {
        if (event.target.state === 'activated') {
          window.location.reload();
        }
      });
      waitingServiceWorker.postMessage({ type: 'SKIP_WAITING' });
    }
  };

  React.useEffect(() => {
    serviceWorker.register({
      onSuccess: () => console.log('service worker registered!'),
      onUpdate: (reg) => updateApp(reg),
    });
  }, []);

  const closeSnackbar = (key) => () => {
    snackbarRef.current.closeSnackbar(key);
  };

  return (
    <ConnectedRouter history={history}>
      <PersistGate loading={null} persistor={store.persistor}>
        <CssBaseline />
        <ScrollToTop>
          <MuiPickersUtilsProvider utils={MomentUtils}>
            <ThemeProvider theme={themeMode()}>
              <SnackbarProvider
                ref={snackbarRef}
                action={(key) => (
                  <IconButton onClick={closeSnackbar(key)}>
                    <CloseIcon fontSize="small" />
                  </IconButton>
                )}>
                <Notifier />
                <RMSAddModal />
                <AnimatePresence>
                  <Routes />
                </AnimatePresence>
              </SnackbarProvider>
            </ThemeProvider>
          </MuiPickersUtilsProvider>
        </ScrollToTop>
      </PersistGate>
      {showSpinner && <Spinner />}
    </ConnectedRouter>
  );
};

const mapStateToProps = (state) => ({
  mode: state.theme.mode,
  ui: state.ui,
});

export default connect(mapStateToProps)(App);
