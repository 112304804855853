/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { useHistory } from 'react-router';
import { connect } from 'react-redux';

import PropTypes from 'prop-types';
import clsx from 'clsx';
import Toolbar from '@material-ui/core/Toolbar';
import Grid from '@material-ui/core/Grid';
import SearchIcon from '@material-ui/icons/Search';
import TextField from '@material-ui/core/TextField';
import DialogList from 'components/RMSList/RMSDialogList';
import { lighten, makeStyles } from '@material-ui/core/styles';

import RMSAddButton from '../RMSButtons/RMSAddButton';
import { PropertiesMenu, VictimsMenu, PartiesMenu } from '../RMSVerticalMenu';
import {
  resetOffenseLists,
  resetSubForms,
  updateIncOffensesOffenseForm,
  setSearchKeyword,
} from '../../reducers/IncReducer';
import RMSSelectFabButton from 'components/RMSButtons/RMSSelectFabButton';

const useToolbarStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
    marginBottom: theme.spacing(3),
  },
  highlight:
    theme.palette.type === 'light'
      ? {
          color: theme.palette.secondary.main,
          backgroundColor: lighten(theme.palette.secondary.light, 0.85),
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark,
        },
  title: {
    flex: '1 1 100%',
  },
}));

const EnhancedTableToolbar = (props) => {
  /*Region: Props and State*/
  const classes = useToolbarStyles();
  const [filterValue, setFilterValue] = React.useState('');
  const [dialogBox, setDialogBox] = React.useState(false);
  const [evidenceCountAsProperty, setEvidenceCountAsProperty] = React.useState(0);
  const {
    listDialogEvidences,
    entityFlag,
    numSelected,
    searchKeyword,
    setSearchKeyword,
    updateIncOffensesOffenseForm,
  } = props;
  const history = useHistory();
  /** End Region */
  /*Region: useEffect*/
  React.useEffect(() => {
    if (entityFlag === 'parties') {
      setFilterValue(searchKeyword?.partiesKeyword);
    } else if (entityFlag === 'properties') {
      setFilterValue(searchKeyword?.propertiesKeyword);
    } else if (entityFlag === 'evidence') {
      setFilterValue(searchKeyword?.evidencesKeyword);
    } else if (entityFlag === 'offenses') {
      setFilterValue(searchKeyword?.offensesKeyword);
    } else if (entityFlag === 'addresses') {
      setFilterValue(searchKeyword?.addressesKeyword);
    }
  }, [
    searchKeyword?.partiesKeyword,
    searchKeyword?.offensesKeyword,
    searchKeyword?.evidencesKeyword,
    searchKeyword?.propertiesKeyword,
    searchKeyword?.addressesKeyword,
  ]);

  React.useEffect(() => {
    setEvidenceCountAsProperty(listDialogEvidences.length || 0);
  }, [listDialogEvidences, listDialogEvidences.length]);
  /** End Region */
  /*Region: Helper Functions*/
  const handleAddNew = () => {
    updateIncOffensesOffenseForm({
      isValid: false,
      ptsOffenseId: undefined,
      values: {},
      touched: {},
      errors: {},
    });
    resetOffenseLists();
    resetSubForms('add');
    history.push(`/incidents/add/${entityFlag}`);
  };

  const handleFilterChange = (e, type) => {
    let currentSearchKeywords = searchKeyword;
    setFilterValue(e.target.value);

    if (entityFlag === 'parties') {
      setSearchKeyword({
        ...currentSearchKeywords,
        partiesKeyword: e.target.value,
      });
    } else if (entityFlag === 'properties') {
      if (type === 'SN')
        setSearchKeyword({
          ...currentSearchKeywords,
          propertiesKeyword: e.target.value,
        });
      else if (type === 'LN')
        setSearchKeyword({
          ...currentSearchKeywords,
          propertiesKeyword2: e.target.value,
        });
      else if (type === 'VIN')
        setSearchKeyword({
          ...currentSearchKeywords,
          propertiesKeyword3: e.target.value,
        });
    } else if (entityFlag === 'evidence') {
      if (type === 'SN')
        setSearchKeyword({
          ...currentSearchKeywords,
          evidencesKeyword: e.target.value,
        });
      else if (type === 'LN')
        setSearchKeyword({
          ...currentSearchKeywords,
          evidencesKeyword2: e.target.value,
        });
      else if (type === 'VIN')
        setSearchKeyword({
          ...currentSearchKeywords,
          evidencesKeyword3: e.target.value,
        });
    } else if (entityFlag === 'offenses') {
      setSearchKeyword({
        ...currentSearchKeywords,
        offensesKeyword: e.target.value,
      });
    } else if (entityFlag === 'addresses') {
      setSearchKeyword({
        ...currentSearchKeywords,
        addressesKeyword: e.target.value,
      });
    } else if (entityFlag === 'narratives') {
      setSearchKeyword({
        ...currentSearchKeywords,
        narrativesKeyword: e.target.value,
      });
    }
  };

  const addEvidenceFromProperty = () => {
    setDialogBox(true);
  };

  const closeDialogBox = () => {
    setDialogBox(false);
  };
  /** End Region */
  return (
    <Toolbar
      className={clsx(classes.root, {
        [classes.highlight]: numSelected > 0,
      })}>
      <Grid container spacing={2} justify="flex-start">
        <Grid item xs={entityFlag === 'addresses' ? 3 : 6} className="mt-4">
          {entityFlag !== 'properties' && entityFlag !== 'victims' && entityFlag !== 'parties' && (
            <RMSAddButton
              tooltipText={`Add ${
                entityFlag === 'offenses'
                  ? 'Offense'
                  : entityFlag === 'evidence'
                  ? 'Evidence'
                  : entityFlag === 'addresses'
                  ? 'Address'
                  : entityFlag === 'zones'
                  ? 'Zone'
                  : entityFlag === 'narratives'
                  ? 'Narratives'
                  : entityFlag === 'alerts'
                  ? 'Alerts'
                  : entityFlag === 'notifications'
                  ? 'Notifications'
                  : entityFlag === 'cadUnitStatuses'
                  ? 'Cad Unit Status'
                  : entityFlag === 'coordinates'
                  ? 'Coordinates'
                  : 'Disposition'
              } `}
              style={{ margin: '12px' }}
              onClick={handleAddNew}
            />
          )}
          {entityFlag === 'properties' && <PropertiesMenu />}
          {entityFlag === 'victims' && <VictimsMenu />}
          {entityFlag === 'parties' && <PartiesMenu style={{ margin: '0px', padding: '0px' }} />}

          {entityFlag === 'evidence' && (
            <RMSSelectFabButton
              onClick={addEvidenceFromProperty}
              listCount={evidenceCountAsProperty}
            />
          )}
        </Grid>

        <Grid item xs={entityFlag === 'addresses' ? 9 : 6} className="mt-2">
          <DialogList
            dialogBox={dialogBox}
            title="Incident Properties"
            type="evidence"
            list={[]}
            closeDialogBox={closeDialogBox}
          />

          <Grid container justify="flex-start">
            <Grid item xs={1} className="mt-4" style={{ textAlign: 'right', paddingRight: '0px' }}>
              <SearchIcon />
            </Grid>
            {entityFlag !== 'evidence' && entityFlag !== 'properties' && (
              <Grid
                item
                xs={10}
                className="ml-4"
                style={{ marginRight: entityFlag === 'victims' ? '20px' : '0px' }}>
                <TextField
                  autoComplete="hidden"
                  id="input-with-icon-grid"
                  fullWidth
                  label={
                    entityFlag === 'narratives'
                      ? 'Filter by Title'
                      : entityFlag === 'parties'
                      ? 'Filter by Full Name'
                      : entityFlag === 'offenses'
                      ? 'Filter by Statute'
                      : entityFlag === 'properties' || entityFlag === 'evidence'
                      ? 'Filter by Description'
                      : entityFlag === 'zones'
                      ? 'Filter by Zone'
                      : entityFlag === 'addresses'
                      ? 'Filter by Full Address'
                      : 'Filter by Disposition'
                  }
                  size="small"
                  onChange={handleFilterChange}
                  value={filterValue || ''}
                />
              </Grid>
            )}
            {entityFlag === 'evidence' && (
              <>
                <Grid
                  item
                  xs={4}
                  className="ml-2"
                  style={{ marginRight: entityFlag === 'victims' ? '20px' : '0px' }}>
                  <TextField
                    autoComplete="hidden"
                    id="input-with-icon-grid"
                    fullWidth
                    label="Filter By Serial Number"
                    size="small"
                    onChange={(e) => handleFilterChange(e, 'SN')}
                    value={searchKeyword?.evidencesKeyword || ''}
                  />
                </Grid>
                <Grid
                  item
                  xs={3}
                  className="ml-2"
                  style={{ marginRight: entityFlag === 'victims' ? '20px' : '0px' }}>
                  <TextField
                    autoComplete="hidden"
                    id="input-with-icon-grid"
                    fullWidth
                    label="Plate Number"
                    size="small"
                    onChange={(e) => handleFilterChange(e, 'LN')}
                    value={searchKeyword?.evidencesKeyword2 || ''}
                  />
                </Grid>
                <Grid
                  item
                  xs={3}
                  className="ml-2"
                  style={{ marginRight: entityFlag === 'victims' ? '20px' : '0px' }}>
                  <TextField
                    autoComplete="hidden"
                    id="input-with-icon-grid"
                    fullWidth
                    label="VIN"
                    size="small"
                    onChange={(e) => handleFilterChange(e, 'VIN')}
                    value={searchKeyword?.evidencesKeyword3 || ''}
                  />
                </Grid>
              </>
            )}
            {entityFlag === 'properties' && (
              <>
                <Grid
                  item
                  xs={4}
                  className="ml-2"
                  style={{ marginRight: entityFlag === 'victims' ? '20px' : '0px' }}>
                  <TextField
                    autoComplete="hidden"
                    id="input-with-icon-grid"
                    fullWidth
                    label="Filter By Serial Number"
                    size="small"
                    onChange={(e) => handleFilterChange(e, 'SN')}
                    value={searchKeyword?.propertiesKeyword || ''}
                  />
                </Grid>
                <Grid
                  item
                  xs={3}
                  className="ml-2"
                  style={{ marginRight: entityFlag === 'victims' ? '20px' : '0px' }}>
                  <TextField
                    autoComplete="hidden"
                    id="input-with-icon-grid"
                    fullWidth
                    label="Plate Number"
                    size="small"
                    onChange={(e) => handleFilterChange(e, 'LN')}
                    value={searchKeyword?.propertiesKeyword2 || ''}
                  />
                </Grid>
                <Grid
                  item
                  xs={3}
                  className="ml-2"
                  style={{ marginRight: entityFlag === 'victims' ? '20px' : '0px' }}>
                  <TextField
                    autoComplete="hidden"
                    id="input-with-icon-grid"
                    fullWidth
                    label="Description"
                    size="small"
                    onChange={(e) => handleFilterChange(e, 'VIN')}
                    value={searchKeyword?.propertiesKeyword3 || ''}
                  />
                </Grid>
              </>
            )}
          </Grid>
        </Grid>
      </Grid>
    </Toolbar>
  );
};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

const mapStateToProps = (state) => ({
  selectedIncident: state.incident.ptsIncidentId,
  searchKeyword: state.incident.searchKeyword,
  listDialogEvidences: state.incident.listDialogEvidences,
});

export default connect(mapStateToProps, {
  updateIncOffensesOffenseForm,
  setSearchKeyword,
})(EnhancedTableToolbar);
