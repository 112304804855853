import store from '../../config/configureStore';

import { incidentAllOffensesSchema } from 'simpl-schema-validation/schema';
import { generateHandleEditIncident } from 'simpl-schema-validation/helpers/generateHandleEdit';

export const validateAllOffenses = async () => {
  const currentState = store.store.getState().incident;
  const offenses = currentState.offenses;

  const ucrCodes = [];
  const biasMotivationCodes = [];
  const criminalActivityCodes = [];
  const offenderSuspectedOfUsingCodes = [];
  const forceCategoryCodes = [];

  offenses.forEach((offense) => {
    if (offense.offenseDetails.values.statuteDetails?.FBICode) {
      ucrCodes.push(offense.offenseDetails.values.statuteDetails?.FBICode);
      biasMotivationCodes.push(offense.offenseDetails.values.biasMotivation);
      criminalActivityCodes.push(offense.offenseDetails.values.criminalActivity);
      offenderSuspectedOfUsingCodes.push(offense.offenseDetails.values.offenderSuspectedOfUsing);
      forceCategoryCodes.push(offense.offenseDetails.values.forceCategory);
    }
  });

  let validationErrors = [];
  let allOffensesValidationContext = incidentAllOffensesSchema.newContext();
  let result = allOffensesValidationContext.validate(
    {
      ucrCodes,
      biasMotivationCodes,
      criminalActivityCodes,
      offenderSuspectedOfUsingCodes,
      forceCategoryCodes,
    },
    {
      keys: [
        'ucrCodes',
        'biasMotivationCodes',
        'criminalActivityCodes',
        'offenderSuspectedOfUsingCodes',
        'forceCategoryCodes',
      ],
    }
  );

  if (!result) {
    allOffensesValidationContext.validationErrors().forEach((error) => {
      validationErrors.push({
        id: currentState.ptsIncidentId,
        idType: 'ptsIncidentId',
        errType: error.type,
        field: error.name,
        name: 'Offenses',
        message: allOffensesValidationContext.keyErrorMessage(error.name),
        ptsIncidentId: currentState.ptsIncidentId,
        ptsOffenseId: currentState.currentOffense,
        handleEdit: generateHandleEditIncident(currentState.ptsIncidentId, 2),
      });
    });
  }

  return validationErrors;
};
