export const criminalActivityCategoryCodes = {
  A:
    'Simple/Gross Neglect (unintentionally, intentionally, or knowingly failing to provide food, water, shelter, veterinary care, hoarding, etc.)',
  B: 'Buying/Receiving',
  C: 'Cultivating/Manufacturing/Publishing (i.e., production of any type)',
  D: 'Distributing/Selling',
  E: 'Exploiting Children',
  F: 'Organized Abuse (Dog Fighting and Cock Fighting)',
  G: 'Other Gang',
  I: 'Intentional Abuse and Torture (tormenting, mutilating, maiming, poisoning, or abandonment)',
  J: 'Juvenile Gang',
  N: 'None/Unknown',
  O: 'Operating/Promoting/Assisting',
  P: 'Possessing/Concealing',
  S: 'Animal Sexual Abuse (Bestiality)',
  T: 'Transporting/Transmitting/Importing',
  U: 'Using/Consuming',
};
