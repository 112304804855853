/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router';

import { makeStyles } from '@material-ui/core/styles';
import Fade from '@material-ui/core/Fade';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Grid from '@material-ui/core/Grid';
import { Button, Fab } from '@material-ui/core';
import Tooltip from '@material-ui/core/Tooltip';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';

import RMSDeleteButton from 'components/RMSButtons/RMSDeleteButton';
import RMSEditButton from 'components/RMSButtons/RMSEditButton';
import RMSPersonButton from 'components/RMSButtons/RMSPersonButton';
import OffendersForm from 'components/RMSForms/RMSOffensesForm/OffendersForm';
import VictimsForm from 'components/RMSForms/RMSOffensesForm/VictimsForm';
import PropertyForm from 'components/RMSForms/RMSOffensesForm/PropertyForm';
import ArresteesForm from 'components/RMSForms/RMSOffensesForm/ArresteesForm';
import RMSDomesticViolenceButton from 'components/RMSButtons/RMSDomesticViolenceButton';
import RMSPersonCell from '../RMSEnhancedCells/RMSPersonCell';
import RMSPropertiesCell from '../RMSEnhancedCells/RMSPropertyCell';
import EnhancedTableHead from '../EnhancedTableHead';
import SuspectsForm from 'components/RMSForms/RMSDomesticViolenceForm/SuspectsForm';
import RMSStorageCell from '../RMSEnhancedCells/RMSStorageCell/RMSStorageCell';
import RMSCustodyCell from '../RMSEnhancedCells/RMSCustodyCell/RMSCustodyCell';
import {
  setSelectedEvidenceCustody,
  setSelectedEvidenceStorage,
  setSelectedArrestee,
  setSelectedProperty,
  setSelectedSubject,
  setSelectedVictim,
  setSelectedSuspect,
  updateIncOffensesDVVictimForm,
  updateIncOffensesDVSuspectForm,
  updateIncOffensesDVAdditionalForm,
  updateIncPartiesPersonForm,
  updateIncPartiesContactForm,
  updateIncPartiesEmploymentForm,
  updateIncPartiesOthersForm,
} from '../../../reducers/IncReducer';
import { setListBodyCells } from '../../../reducers/ui/UiListReducer';
import {
  setHandleAddIncDVVictim,
  setHandleAddIncDVAdditional,
} from '../../../reducers/ui/UiMenuReducer';
import { setVictimDVTab } from '../../../reducers/ui/UiFormReducer';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    backgroundColor: theme.palette.background.paper,
  },
  table: {
    margin: '5px 0px',
    padding: '0px',
    overflowX: 'hidden',
    width: '100%',
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  incidentSelector: {
    '&:hover': {
      cursor: 'pointer',
    },
  },
  dialogBox: {
    minHeight: '80vh',
    maxHeight: '80vh',
  },
  spinnerLoadingContainer: {
    position: 'fixed',
    top: '50%',
    left: '50%',
    '& span': {
      marginLeft: '-5px',
    },
  },
}));

const RMSSimpleList = (props) => {
  const classes = useStyles();
  const history = useHistory();
  const [selectedItem, setSelectedItem] = React.useState();
  const [orderBy, setOrderBy] = React.useState('calories');
  const [order, setOrder] = React.useState('asc');
  const [rows, setRows] = useState([]);
  const [open, setOpen] = useState(false);
  const [spinnerLoading, setSpinnerLoading] = useState(false);
  const [updated, setUpdated] = useState(null);
  const [simpleList, setSimpleList] = React.useState([]);
  const {
    listItems,
    bodyState,
    entity,
    parties,
    setListBodyCells,
    setSelectedSubject,
    setSelectedSuspect,
    setSelectedVictim,
    setSelectedProperty,
    setVictimDVTab,
    setSelectedArrestee,
    setSelectedEvidenceCustody,
    setSelectedEvidenceStorage,
    updateIncOffensesDVVictimForm,
    updateIncOffensesDVSuspectForm,
    updateIncOffensesDVAdditionalForm,
    setHandleAddIncDVVictim,
    setHandleAddIncDVAdditional,
    listItemDelete,
    listItemEdit,
    selectedSubject,
    selectedVictim,
    selectedArrestee,
    updateIncPartiesPersonForm,
    updateIncPartiesContactForm,
    updateIncPartiesEmploymentForm,
    updateIncPartiesOthersForm,
  } = props;
  /** endregion */
  /** region React Hooks */
  React.useEffect(() => {
    const timer = setInterval(() => {
      setSimpleList(listItems);
    }, 500);
    return () => clearInterval(timer);
  }, [listItems, listItems.length]);

  React.useEffect(() => {
    setListBodyCells(
      entity === 'offenders' ||
        entity === 'victims' ||
        entity === 'arrestees' ||
        entity === 'suspects'
        ? 'parties'
        : 'properties'
    );
  }, [parties, parties.length]);

  React.useEffect(() => {
    let entityFlag =
      entity === 'offenders' ||
      entity === 'victims' ||
      entity === 'arrestees' ||
      entity === 'suspects'
        ? 'parties'
        : 'properties';
    if (bodyState && bodyState[entityFlag]) setRows(bodyState[entityFlag]);
  }, [bodyState]);
  /** endregion */
  /** region Helper Functions */

  const handleEditParty = () => {
    console.log('Clicked me!');
    console.log('Entity: ', entity);

    const entityPath =
      entity === 'offenders'
        ? 'offenders'
        : entity === 'victims'
        ? 'victims'
        : entity === 'arrestees'
        ? 'offenders'
        : '';

    const personID =
      entity === 'offenders'
        ? selectedSubject
        : entity === 'victims'
        ? selectedVictim
        : entity === 'arrestees'
        ? selectedArrestee
        : '';

    const selectedPartyDetail = parties.filter((x) => x.ptsIncPersonId === personID);

    if (selectedPartyDetail.length === 0) {
      console.log('Nothing found!!');
    }

    const { personDetail, contactDetail, employmentDetail, otherDetail } = selectedPartyDetail[0];
    const incidentId = personDetail.values.incidentId;

    console.log('Incident: ', incidentId);

    updateIncPartiesPersonForm(personDetail);
    updateIncPartiesContactForm(contactDetail);
    updateIncPartiesEmploymentForm(employmentDetail);
    updateIncPartiesOthersForm(otherDetail);

    history.push(`/incidents/edit/${incidentId}/${entityPath}/${personID}`);
  };

  const openModal = () => {
    setOpen(true);
  };

  const closeDialogBox = () => {
    setUpdated(null);
    setOpen(false);
  };

  const handleDeleteClick = () => {
    props.deleteFromList();
  };

  const handleListItemClick = (id) => {
    setSelectedItem(id);
    // eslint-disable-next-line default-case
    switch (entity) {
      case 'offenders':
        setSelectedSubject(id);
        break;
      case 'victims':
        setSelectedVictim(id);
        break;
      case 'arrestees':
        setSelectedArrestee(id);
        break;
      case 'properties':
        setSelectedProperty(id);
        break;
      case 'suspects':
        setSelectedSuspect(id);
        break;
      case 'custody':
        setSelectedEvidenceCustody(id);
        break;
      case 'storage':
        setSelectedEvidenceStorage(id);
        break;
    }
  };

  const handleClickUpdate = () => {
    setUpdated(!updated);
    setOpen(false);
  };

  const handleDomesticViolenceClick = () => {
    setHandleAddIncDVVictim(true);
    setHandleAddIncDVAdditional(true);
    // Clear DV forms Redux State
    updateIncOffensesDVVictimForm({
      ptsDVVictimId: null,
      isValid: false,
      values: {},
      touched: {},
      errors: {},
    });
    updateIncOffensesDVAdditionalForm({
      ptsDVAdditionalId: null,
      isValid: false,
      values: {},
      touched: {},
      errors: {},
    });
    updateIncOffensesDVSuspectForm({
      ptsDVSuspectId: null,
      isValid: false,
      values: {},
      touched: {},
      errors: {},
    });
    setSelectedSuspect('');
    setVictimDVTab(0);
    history.push('/incidents/add/domestic-violence');
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  }

  function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }

  function getComparator(order, orderBy) {
    return order === 'desc'
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }
  /** endregion */
  return (
    <div className={classes.root}>
      <Dialog
        classes={{
          paper: classes.dialogBox,
        }}
        open={open}
        onClose={closeDialogBox}
        scroll={'paper'}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <DialogTitle
          className={'d-flex justify-content-between align-items-center'}
          id="alert-dialog-title"
          style={{ background: '#1976d2' }}>
          <span style={{ fontSize: '18px', color: 'white' }}>
            {`Edit ${entity.charAt(0).toUpperCase() + entity.slice(1)}`}
          </span>
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {entity === 'offenders' && <OffendersForm updated={updated} />}

            {entity === 'victims' && <VictimsForm updated={updated} />}

            {entity === 'properties' && <PropertyForm updated={updated} />}

            {entity === 'arrestees' && <ArresteesForm updated={updated} />}

            {entity === 'suspects' && <SuspectsForm updated={updated} />}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Grid
            container
            spacing={2}
            style={{ marginTop: '-35px' }}
            className={entity === 'suspects' ? 'mr-1' : ''}>
            <Grid item xs={12}>
              <Grid
                container
                direction="row"
                justify="flex-end"
                alignItems="center"
                style={{ padding: 15 }}>
                <Button onClick={closeDialogBox} style={{ marginRight: 5 }}>
                  Close
                </Button>

                <Button
                  onClick={handleClickUpdate}
                  style={{ background: '#1976d2', color: '#fff' }}
                  variant="contained">
                  Update
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>
      {(entity === 'storage' || entity === 'custody') && (
        <Table
          stickyHeader
          className={classes.table}
          aria-label="sticky table"
          aria-labelledby="tableTitle"
          size="small">
          <EnhancedTableHead
            numSelected={1}
            classes={classes}
            order={order}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
            rowCount={rows.length}
            entityFlag={entity === 'storage' ? 'storages' : 'custody'}
          />

          <TableBody>
            {stableSort(simpleList, getComparator(order, orderBy)).map((row, index) => {
              return (
                <TableRow
                  hover
                  onMouseEnter={() => handleListItemClick(row.id)}
                  onMouseLeave={() => setSelectedItem('')}
                  role="checkbox"
                  tabIndex={-1}
                  key={row.id}
                  className={classes.incidentSelector}>
                  {entity === 'storage' ? (
                    <RMSStorageCell row={row} />
                  ) : (
                    <RMSCustodyCell row={row} />
                  )}

                  <TableCell>
                    <Fade in={selectedItem === row.id}>
                      <Grid container justify="flex-end">
                        <Grid item className="mt-2 mr-3">
                          <Tooltip title="Edit">
                            <RMSEditButton onClick={listItemEdit} />
                          </Tooltip>
                        </Grid>
                        <Grid item className="mt-2 mr-3">
                          <Tooltip title="Delete">
                            <RMSDeleteButton onClick={listItemDelete} />
                          </Tooltip>
                        </Grid>
                      </Grid>
                    </Fade>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      )}
      {['offenders', 'victims', 'properties', 'arrestees', 'suspects'].includes(entity) && (
        <Table
          stickyHeader
          className={classes.table}
          aria-label="sticky table"
          aria-labelledby="tableTitle"
          size="small">
          <EnhancedTableHead
            numSelected={1}
            classes={classes}
            order={order}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
            rowCount={rows.length}
            entityFlag={
              entity === 'offenders' ||
              entity === 'victims' ||
              entity === 'arrestees' ||
              entity === 'suspects'
                ? 'parties'
                : 'properties'
            }
          />

          <TableBody>
            {stableSort(rows, getComparator(order, orderBy)).map((row, index) => {
              let id =
                entity === 'offenders' ||
                entity === 'victims' ||
                entity === 'arrestees' ||
                entity === 'suspects'
                  ? row.ptsIncPersonId
                  : row.incIncidentPropertyId;
              row.id = id;
              let found = false;
              row.simpleListType = entity === 'properties' ? 'incProperties' : entity;
              simpleList.forEach((sl) => {
                if (sl.id === id) found = true;
              });

              if (found && spinnerLoading === false)
                return (
                  <TableRow
                    hover
                    onMouseEnter={() => handleListItemClick(id)}
                    onMouseLeave={() => setSelectedItem('')}
                    role="checkbox"
                    tabIndex={-1}
                    key={id}
                    className={classes.incidentSelector}>
                    {entity === 'offenders' && <RMSPersonCell row={row} />}
                    {entity === 'victims' && <RMSPersonCell row={row} />}
                    {entity === 'arrestees' && <RMSPersonCell row={row} />}
                    {entity === 'suspects' && <RMSPersonCell row={row} />}
                    {entity === 'properties' && <RMSPropertiesCell row={row} />}
                    <TableCell>
                      <Fade in={selectedItem === id}>
                        <Grid container justify="flex-end">
                          {entity === 'victims' && !props.origin && (
                            <Grid item className="mt-2 mr-3" onClick={handleDomesticViolenceClick}>
                              <Tooltip title="Domestic Violence">
                                <RMSDomesticViolenceButton />
                              </Tooltip>
                            </Grid>
                          )}
                          {!props.origin && entity !== 'properties' && (
                            <Grid item className="mt-2 mr-3">
                              <Tooltip tooltipText="Edit Person">
                                <RMSPersonButton onClick={handleEditParty} />
                              </Tooltip>
                            </Grid>
                          )}
                          {!props.origin && (
                            <Grid item className="mt-2 mr-3">
                              <Tooltip title="Edit">
                                <RMSEditButton onClick={openModal} />
                              </Tooltip>
                            </Grid>
                          )}
                          {props.origin && props.origin === 'fromModal' ? (
                            <Grid item className="mt-2 mr-3">
                              <Tooltip title="Move">
                                <Fab
                                  size="small"
                                  color="secondary"
                                  aria-label="edit"
                                  onClick={(event) => props.handleListItemClick(event, index, row)}>
                                  <ArrowForwardIcon />
                                </Fab>
                              </Tooltip>
                            </Grid>
                          ) : (
                            <Grid item className="mt-2 mr-3">
                              <Tooltip title="Delete">
                                <RMSDeleteButton onClick={handleDeleteClick} />
                              </Tooltip>
                            </Grid>
                          )}
                        </Grid>
                      </Fade>
                    </TableCell>
                  </TableRow>
                );
            })}
          </TableBody>
        </Table>
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  selectedSubject: state.incident.selectedSubject,
  selectedVictim: state.incident.selectedVictim,
  parties: state.incident.parties,
  selectedProperty: state.incident.selectedProperty,
  selectedArrestee: state.incident.selectedArrestee,
  selectedSuspect: state.incident.selectedSuspect,
  selectedEvidenceCustody: state.incident.selectedEvidenceCustody,
  selectedEvidenceStorage: state.incident.selectedEvidenceStorage,
  bodyState: state.uiList.incListBodyState,
});

export default connect(mapStateToProps, {
  setVictimDVTab,
  setSelectedSubject,
  setSelectedVictim,
  setSelectedProperty,
  setSelectedArrestee,
  setSelectedSuspect,
  setSelectedEvidenceCustody,
  setSelectedEvidenceStorage,
  updateIncOffensesDVVictimForm,
  updateIncOffensesDVSuspectForm,
  updateIncOffensesDVAdditionalForm,
  setHandleAddIncDVVictim,
  setHandleAddIncDVAdditional,
  setListBodyCells,
  updateIncPartiesPersonForm,
  updateIncPartiesContactForm,
  updateIncPartiesEmploymentForm,
  updateIncPartiesOthersForm,
})(RMSSimpleList);
