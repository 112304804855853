import React from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router';

import clsx from 'clsx';
import { v4 as uuidv4 } from 'uuid';
import Avatar from '@material-ui/core/Avatar';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Grid from '@material-ui/core/Grid';

import { colors } from '../../config/colorVariables';
import RMSSelectButton from '../../components/RMSButtons/RMSSelectButton';
import RMSAddButton from '../../components/RMSButtons/RMSAddButton';
import PerfectScrollbar from 'react-perfect-scrollbar';
import RMSSimpleList from '../../components/RMSList/RMSSimpleList';
import DialogList from '../../components/RMSList/RMSDialogList';
import {
  updateIncOffensesDVSuspectForm,
  updateIncPartiesPersonForm,
  setSelectedSuspect,
  setListSuspects,
  removeIncSuspectDetails,
  updateIncSuspectDetails,
} from '../../reducers/IncReducer';
import {
  setDvMaleSuspectCondition,
  setDvFemaleSuspectCondition,
} from '../../reducers/ui/UiImageReducer';
import {
  setHandleAddIncDVSuspect,
  setHandleOffensesRedirectFormClose,
} from 'reducers/ui/UiMenuReducer';

const useStyles = makeStyles((theme) => ({
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: colors.cyan,
    marginRight: theme.spacing(2),
  },
  female: {
    margin: theme.spacing(1),
    backgroundColor: colors.pink,
    marginRight: theme.spacing(2),
  },
  property: {
    margin: theme.spacing(1),
    backgroundColor: colors.indigo,
    marginRight: theme.spacing(2),
  },
  victim: {
    margin: theme.spacing(1),
    backgroundColor: colors.orange,
    marginRight: theme.spacing(2),
  },
  detailsPanel: {
    padding: theme.spacing(4),
  },
  bodyLine: {
    overflow: 'hidden',
    width: '100%',
  },
}));

export const SuspectAvatar = (props) => {
  const { label } = props;
  const classes = useStyles();

  return (
    <Avatar
      className={clsx(
        label === 'M' && classes.avatar,
        label === 'F' && classes.female,
        label === 'S' && classes.suspect
      )}>
      {label}
    </Avatar>
  );
};

const TabDomesticViolenceSuspects = (props) => {
  const classes = useStyles();
  const history = useHistory();
  const {
    dvSuspects,
    selectedSuspect,
    selectedVictim,
    parties,
    listSuspects,
    listDialogSuspects,
    setListSuspects,
    removeIncSuspectDetails,
    setSelectedSuspect,
    maleSuspectCondition,
    femaleSuspectCondition,
    setHandleAddIncDVSuspect,
    updateIncPartiesPersonForm,
    setHandleOffensesRedirectFormClose,
  } = props;
  const [dialogBox, setDialogBox] = React.useState(false);
  const [changeKey, setChangeKey] = React.useState(0);
  const [victimGender, setVictimGender] = React.useState('male');

  const [offensesDVSuspectFormState, setOffensesDVSuspectFormState] = React.useState({
    tempIncDVSuspectId: uuidv4(),
    isValid: false,
    values: {},
    touched: {},
    errors: {},
  });

  React.useEffect(() => {
    createIncSuspectList();
  }, []);

  React.useEffect(() => {
    async function fetch() {
      let dvSuspectDetails = dvSuspects.find(
        (dvSuspect) => dvSuspect.incSuspectDetails.ptsIncPersonId === selectedSuspect
      );
      if (dvSuspectDetails && dvSuspectDetails.incSuspectDetails) {
        await setOffensesDVSuspectFormState(dvSuspectDetails.incSuspectDetails);
      }
    }
    // Fetching current selected victim's dv details
    fetch();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedVictim, selectedSuspect]);

  React.useEffect(() => {
    let gender = parties.find((party) => party.ptsIncPersonId === selectedSuspect)?.personDetail
      .values.sex;
    if (gender) {
      let g = gender.toLowerCase();
      if (g === 'm' || g === 'male') {
        gender = gender.toLowerCase() === 'm' ? 'male' : 'male';
      } else {
        gender = 'female';
      }
    } else {
      gender = 'male';
    }
    setVictimGender(gender);

    gender === 'male'
      ? setOffensesDVSuspectFormState({
          ...offensesDVSuspectFormState,
          values: {
            ...offensesDVSuspectFormState.values,
            File: offensesDVSuspectFormState.values.File || maleSuspectCondition,
          },
        })
      : setOffensesDVSuspectFormState({
          ...offensesDVSuspectFormState,
          values: {
            ...offensesDVSuspectFormState.values,
            File: offensesDVSuspectFormState.values.File || femaleSuspectCondition,
          },
        });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedSuspect]);

  React.useEffect(() => {
    setHandleOffensesRedirectFormClose(false);
    createIncSuspectList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dvSuspects, parties]);

  const createIncSuspectList = () => {
    if (dvSuspects !== undefined && dvSuspects.length > 0) {
      let newList = [];
      let suspect = {};

      dvSuspects.forEach((s) => {
        if (s.incSuspectDetails.ptsVictimPersonId === selectedVictim) {
          let party;
          if (!s.incSuspectDetails.ptsIncPersonId) {
            party = parties.find((x) => x.tempPartyId === s.incSuspectDetails.tempPartyId);
          } else {
            party = parties.find((x) => x.ptsIncPersonId === s.incSuspectDetails.ptsIncPersonId);
          }

          if (party) {
            suspect = {
              id: party?.ptsIncPersonId || party?.personDetail.tempPartyId,
              name: `${party.personDetail.values.firstName}${
                party.personDetail.values.middleName
                  ? ` ${party.personDetail.values.middleName} `
                  : ' '
              }${party.personDetail.values.lastName}`,
              icon: (
                <SuspectAvatar
                  label={
                    party.personDetail.values.sex === 'MALE'
                      ? 'M'
                      : party.personDetail.values.sex === 'FEMALE'
                      ? 'F'
                      : 'U'
                  }
                />
              ),
              age: party.personDetail.values.age,
              ethnicity: party.personDetail.values.ethnicity,
              type: 'person',
            };
            newList.push(suspect);
          }
        }
        if (newList.length === 1) {
          setSelectedSuspect(suspect.id);
        }
      });

      setListSuspects(newList);
      setChangeKey(newList.length);
    }
  };

  const handleIncSuspectSelect = () => {
    if (listDialogSuspects.length > 0) {
      setDialogBox(true);
    }
  };

  const closeDialogBox = () => {
    createIncSuspectList();
    setDialogBox(false);
  };

  const handleIncSuspectAdd = async () => {
    setHandleOffensesRedirectFormClose(true);
    setHandleAddIncDVSuspect(true);
    updateIncPartiesPersonForm({ isValid: false, values: {}, touched: {}, errors: {} });
    history.push('/incidents/add/suspects/person');
  };

  const handleIncSuspectDelete = () => {
    let updatedList = listSuspects.filter((s) => s.id !== selectedSuspect);
    setListSuspects(updatedList);
    removeIncSuspectDetails();
    setSelectedSuspect(updatedList[0] ? updatedList[0].id : '');
  };

  return (
    <Grid container>
      <Grid container spacing={2} className="mb-3 mt-3">
        <Grid item className="mr-2 ml-2">
          <RMSAddButton onClick={handleIncSuspectAdd} />
        </Grid>
        <Grid item className="mr-1">
          <DialogList
            dialogBox={dialogBox}
            title="Person List"
            type="suspects"
            list={[]}
            addToSimpleList={createIncSuspectList}
            closeDialogBox={closeDialogBox}
          />

          <RMSSelectButton lists={listDialogSuspects} onClick={handleIncSuspectSelect} />
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={12} lg={12}>
          <PerfectScrollbar style={{ height: '99%' }} className="scroll-area-lg">
            <RMSSimpleList
              key={changeKey}
              listItems={listSuspects}
              entity="suspects"
              deleteFromList={handleIncSuspectDelete}
            />
          </PerfectScrollbar>
        </Grid>
      </Grid>
    </Grid>
  );
};

const mapStateToProps = (state) => ({
  maleSuspectCondition: state.uiImage.dvMaleSuspectConditionState,
  femaleSuspectCondition: state.uiImage.dvFemaleSuspectConditionState,
  offensesDVSuspectForm: state.incident.offensesDVSuspectForm,
  dvSuspects: state.incident.dvSuspects,
  selectedSuspect: state.incident.selectedSuspect,
  selectedVictim: state.incident.selectedVictim,
  parties: state.incident.parties,
  listSuspects: state.incident.listSuspects,
  listDialogSuspects: state.incident.listDialogSuspects,
});

export default connect(mapStateToProps, {
  setDvMaleSuspectCondition,
  setDvFemaleSuspectCondition,
  updateIncOffensesDVSuspectForm,
  setHandleAddIncDVSuspect,
  updateIncPartiesPersonForm,
  setHandleOffensesRedirectFormClose,
  removeIncSuspectDetails,
  setSelectedSuspect,
  setListSuspects,
  updateIncSuspectDetails,
})(TabDomesticViolenceSuspects);
