import store from '../../config/configureStore';

export const countOffenseErrors = (ptsOffenseId) => {
  let currentIncidentId = store.store.getState().incident.ptsIncidentId;
  let validationErrors = store.store.getState().incident.validationErrors;

  const errorObj = validationErrors.find((error) => error.ptsIncidentId === currentIncidentId);

  if (!errorObj?.errors?.offenseErrors) {
    return 0;
  }
  // console.log('883 count', errorObj.errors.offenseErrors);
  let errorCount = 0;

  errorObj.errors.offenseErrors?.forEach((error) => {
    if (error.id === ptsOffenseId) {
      errorCount++;
    }
  });
  return errorCount;
};
