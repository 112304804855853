import React from 'react';
import { connect } from 'react-redux';

import { v4 as uuidv4 } from 'uuid';
import * as markerjs2 from 'markerjs2';
import Grid from '@material-ui/core/Grid';
import { Typography } from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import Tooltip from '@material-ui/core/Tooltip';
import DvVictimsPanels from '../../components/RMSList/RMSCollapsibleList/DvVictimPanels';
import TextField from '@material-ui/core/TextField';
import makeStyles from '@material-ui/core/styles/makeStyles';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

import {
  updateIncOffensesDVVictimForm,
  updateIncOffensesDVAdditionalForm,
} from '../../reducers/IncReducer';
import {
  setDvMaleVictimCondition,
  setDvFemaleVictimCondition,
} from '../../reducers/ui/UiImageReducer';
import { handleFormChangeNew } from '../../reducers/helpers/formHelpers';

const useStyles = makeStyles((theme) => ({
  detailsPanel: {
    padding: theme.spacing(4),
  },
  bodyLine: {
    overflow: 'hidden',
    width: '100%',
  },
}));

const TabDomesticViolenceVictim = (props) => {
  const {
    maleVictimCondition,
    femaleVictimCondition,
    parties,
    selectedVictim,
    offensesDVVictimForm,
    updateIncOffensesDVVictimForm,
    updateIncOffensesDVAdditionalForm,
    dvVictims,
    dvAdditionals,
    maleSuspectCondition,
    femaleSuspectCondition,
  } = props;
  const classes = useStyles();
  const [victimGender, setVictimGender] = React.useState('male');

  const [offensesDVVictimFormState, setOffensesDVVictimFormState] = React.useState({
    tempIncDVVictimId: uuidv4(),
    isValid: false,
    changes: false,
    formType: 'edit',
    values: {},
    touched: {},
    errors: {},
  });

  React.useEffect(() => {
    async function fetch() {
      let dvVictimDetails = dvVictims.find(
        (dvVictim) => dvVictim.ptsIncPersonId === selectedVictim
      );
      if (dvVictimDetails) {
        await updateIncOffensesDVVictimForm(dvVictimDetails);
        await setOffensesDVVictimFormState(dvVictimDetails);
      }

      let dvAdditionalDetails = dvAdditionals.find(
        (dvVictim) => dvVictim.ptsIncPersonId === selectedVictim
      );
      if (dvAdditionalDetails) {
        await updateIncOffensesDVAdditionalForm(dvAdditionalDetails);
      }
    }
    // Fetching current selected victim's dv details
    fetch();

    let gender = parties.find((party) => party.ptsIncPersonId === selectedVictim)?.personDetail
      .values.sex;
    if (gender) {
      let g = gender.toLowerCase();
      if (g === 'm' || g === 'male') {
        gender = gender.toLowerCase() === 'm' ? 'male' : 'male';
      } else {
        gender = 'female';
      }
    } else {
      gender = 'male';
    }
    setVictimGender(gender);

    gender === 'male'
      ? setOffensesDVVictimFormState({
          ...offensesDVVictimFormState,
          ptsIncPersonId: selectedVictim,
          values: {
            ...offensesDVVictimFormState.values,
            File: offensesDVVictimFormState.values.File
              ? offensesDVVictimFormState.values.File
              : maleSuspectCondition,
          },
        })
      : setOffensesDVVictimFormState({
          ...offensesDVVictimFormState,
          ptsIncPersonId: selectedVictim,
          values: {
            ...offensesDVVictimFormState.values,
            File: offensesDVVictimFormState.values.File
              ? offensesDVVictimFormState.values.File
              : femaleSuspectCondition,
          },
        });

    return () => {
      updateIncOffensesDVVictimForm({
        isValid: false,
        values: {},
        touched: {},
        errors: {},
        ptsIncPersonId: null,
      });
    };
  }, []);

  React.useEffect(() => {
    setOffensesDVVictimFormState({
      ...offensesDVVictimFormState,
      ptsIncPersonId: selectedVictim,
      values: { ...offensesDVVictimFormState.values, ...offensesDVVictimForm.values },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [offensesDVVictimForm]);

  React.useEffect(() => {
    offensesDVVictimFormState.changes = true;
    updateIncOffensesDVVictimForm(offensesDVVictimFormState);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    offensesDVVictimFormState.values.Comments,
    offensesDVVictimFormState.values.IsMentallyChallenged,
    offensesDVVictimFormState.values.IsPhysicallyChallenged,
    offensesDVVictimFormState.values.File,
  ]);

  const handleClickImg = () => {
    let target = document.getElementById('body-outline');
    const markerArea = new markerjs2.MarkerArea(target);

    markerArea.targetRoot = document.getElementById('image-root');
    markerArea.renderAtNaturalSize = true;
    markerArea.renderImageType = 'image/jpeg';
    markerArea.renderImageQuality = 0.5;
    markerArea.addRenderEventListener((imgURL) => {
      document.getElementById('body-outline').src = imgURL;
      updateIncOffensesDVVictimForm({
        ...offensesDVVictimFormState,
        values: {
          ...offensesDVVictimFormState.values,
          File: imgURL,
        },
      });

      setOffensesDVVictimFormState({
        ...offensesDVVictimFormState,
        values: {
          ...offensesDVVictimFormState.values,
          File: imgURL,
        },
      });
    });

    markerArea.show();
  };

  return (
    <Grid container spacing={3} justify="center" className="mb-4">
      <Grid item xs={5} lg={5}>
        <Grid item className="mt-4">
          <Paper id="image-root" className="p-5">
            <Typography variant="h4" className="mb-4">
              Victim Conditions Memo
            </Typography>
            {victimGender === 'female' && (
              <Tooltip title="Click the image to make annotations" placement="right">
                <img
                  id="body-outline"
                  className={classes.bodyLine}
                  onClick={handleClickImg}
                  src={offensesDVVictimFormState.values?.File || femaleVictimCondition}
                  alt="female-body-outline"
                />
              </Tooltip>
            )}
            {victimGender === 'male' && (
              <Tooltip title="Click the image to make annotations" placement="right">
                <img
                  id="body-outline"
                  className={classes.bodyLine}
                  onClick={handleClickImg}
                  src={offensesDVVictimFormState.values?.File || maleVictimCondition}
                  alt="male-body-outline"
                />
              </Tooltip>
            )}
          </Paper>
        </Grid>
      </Grid>
      <Grid item xs={5} lg={5} className="mt-4">
        <Paper className={classes.detailsPanel}>
          <DvVictimsPanels />
          <Grid container justify="center">
            <Grid item className="mt-4">
              <FormControlLabel
                control={
                  <Checkbox
                    key={offensesDVVictimFormState.values?.IsMentallyChallenged}
                    checked={offensesDVVictimFormState.values?.IsMentallyChallenged}
                    onChange={(event) =>
                      handleFormChangeNew(
                        event,
                        event.target.checked,
                        'none',
                        'IsMentallyChallenged',
                        setOffensesDVVictimFormState
                      )
                    }
                    name="IsMentallyChallenged"
                  />
                }
                label="Mentally/Emotionally Challenged"
              />

              <FormControlLabel
                control={
                  <Checkbox
                    key={offensesDVVictimFormState.values?.IsPhysicallyChallenged}
                    checked={offensesDVVictimFormState.values?.IsPhysicallyChallenged}
                    onChange={(event) =>
                      handleFormChangeNew(
                        event,
                        event.target.checked,
                        'none',
                        'IsPhysicallyChallenged',
                        setOffensesDVVictimFormState
                      )
                    }
                    name="IsPhysicallyChallenged"
                  />
                }
                label="Physically/Medically Challenged"
              />
            </Grid>
            <Grid item xs={12} lg={12}>
              <TextField
                autoComplete="hidden"
                size="small"
                className="mt-3"
                id="outlined-multiline-static-address-notes"
                label="Comments"
                multiline
                rows="4"
                fullWidth
                name="Comments"
                value={offensesDVVictimFormState.values?.Comments}
                onChange={(event) =>
                  handleFormChangeNew(
                    event,
                    event.target.value,
                    'none',
                    'Comments',
                    setOffensesDVVictimFormState
                  )
                }
                variant="outlined"
              />
            </Grid>
          </Grid>
        </Paper>
      </Grid>
    </Grid>
  );
};

const mapStateToProps = (state) => ({
  maleVictimCondition: state.uiImage.dvMaleVictimConditionState,
  femaleVictimCondition: state.uiImage.dvFemaleVictimConditionState,
  offensesDVVictimForm: state.incident.offensesDVVictimForm,
  parties: state.incident.parties,
  selectedVictim: state.incident.selectedVictim,
  dvVictims: state.incident.dvVictims,
  dvAdditionals: state.incident.dvAdditionals,
});

export default connect(mapStateToProps, {
  setDvMaleVictimCondition,
  setDvFemaleVictimCondition,
  updateIncOffensesDVVictimForm,
  updateIncOffensesDVAdditionalForm,
})(TabDomesticViolenceVictim);
