import React from 'react';
import { connect } from 'react-redux';

import PerfectScrollbar from 'react-perfect-scrollbar';
import { faBuilding } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Grid, Paper, Divider, Tabs, Tab } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import TabStructure from '../PageRMSProperties/PageRMSStructure/TabStructure';
import RMSFabButtons from '../../components/RMSForms/RMSIncidentForm/RMSFabButtons';
import TabPanel from '../../components/RMSForms/RMSIncidentForm/TabPanel';
import { setHandleClose, setMenuType } from '../../reducers/ui/UiMenuReducer';

import { setOffensesTab } from '../../reducers/ui/UiFormReducer';
import { updateIncPropertyStructureForm } from '../../reducers/IncReducer';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    height: window.innerHeight - 90 + 'px',
  },
  scrollBar: {
    width: '100%',
    overflowX: 'hidden',

    [theme.breakpoints.up('lg')]: {
      height: '70vh',
    },
    [theme.breakpoints.down('lg')]: {
      height: '50vh',
    },
    [theme.breakpoints.only('lg')]: {
      height: '65vh',
    },
  },
  paper: {
    minHeight: window.innerHeight - 95 + 'px',
  },
  dialogBox: {
    minWidth: '412px',
    minHeight: '150px',
  },
  icons: {
    textDecoration: 'capitalize',
    padding: '16px',
    color: 'white',
    fontSize: '13px',
  },
}));

const AddEditStructure = (props) => {
  const classes = useStyles();
  const {
    history,
    setHandleClose,
    setMenuType,
    properties,
    selectedIncident,
    selectedEntity,
    updateIncPropertyStructureForm,
    showUCRAlertModal,
    setOffensesTab,
  } = props;
  const [value, setValue] = React.useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  React.useEffect(() => {
    setMenuType('forms');
    setHandleClose('incForms', history);

    if (!selectedIncident || !selectedEntity || properties.length === 0) {
      return;
    }

    const { incIncidentPropertyId } = selectedEntity;
    const property = properties.filter(
      (e) => e.propertyDetails.incIncidentPropertyId === incIncidentPropertyId
    );
    if (property.length === 0) return;
    const { propertyDetails } = property[0];
    updateIncPropertyStructureForm(propertyDetails);
  }, [selectedIncident]);

  return (
    <div className={classes.root}>
      <RMSFabButtons />
      <Grid container>
        <Grid xs={12} lg={12}>
          <Paper className={classes.paper}>
            <Grid container justify="center">
              <Grid item xs={12} lg={12}>
                <div
                  className="card-header-actions"
                  style={{
                    backgroundColor: '#1976d2',
                    color: 'white',
                    borderRadius: '0.5rem 0.5rem 0 0',
                  }}>
                  <Tabs
                    value={value}
                    indicatorColor="secondary"
                    variant="fullWidth"
                    classes={{
                      indicator: classes.indicator,
                    }}
                    onChange={handleChange}>
                    <Tab
                      className={classes.icons}
                      icon={<FontAwesomeIcon icon={faBuilding} />}
                      label="Structure"
                    />
                  </Tabs>
                  <Divider />
                </div>
              </Grid>

              <PerfectScrollbar className={classes.scrollBar}>
                <Grid item xs={12} lg={12} style={{ padding: '15px' }}>
                  <TabPanel value={value} index={0}>
                    <TabStructure history={history} />
                  </TabPanel>
                </Grid>
              </PerfectScrollbar>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    </div>
  );
};

const mapStateToProps = (state) => ({
  state: state,
  properties: state.incident.properties,
  selectedEntity: state.incident.selectedEntity,
  selectedIncident: state.incident.ptsIncidentId,
  showUCRAlertModal: state.uiModal.showUCRAlertModal,
  recordActive: state.records.recordActive,
});

export default connect(mapStateToProps, {
  setHandleClose,
  setMenuType,
  updateIncPropertyStructureForm,
  setOffensesTab,
})(AddEditStructure);
