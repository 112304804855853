// import store from '../config/configureStore';

// User
export const DUPLiCATE_PERSON_FORM = 'DUPLICATE/DUPLiCATE_PERSON_FORM';

export default function reducer(
  state = {
    dulicatePersonForm: {},
  },
  action
) {
  switch (action.type) {
    case DUPLiCATE_PERSON_FORM:
      return {
        ...state,
        dulicatePersonForm: action.payload,
      };
    default:
      break;
  }
  return state;
}
