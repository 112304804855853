import React from 'react';
import { connect } from 'react-redux';

import 'date-fns';
import { XGrid } from '@material-ui/x-grid';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import { Paper } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';

import { getHVRecords } from './api';
import { formatDate, getFormStyle, filterResults } from './functions';
import ViewData from './ViewData';
import AddData from './AddHostingValidation';
import { showSpinner, hideSpinner } from '../../reducers/ui/UiDrawerReducer';
import { notify } from '../../reducers/NotifierReducer';

const useStyles = makeStyles((theme) => ({
  wrap: {
    padding: theme.spacing(4),
    boxSizing: 'border-box',
    height: 'calc(100vh - 96px)',
    overflowY: 'auto',
    '& > h5': {
      marginBottom: 20,
    },
  },
  form: {
    margin: '0 -4px 4px',
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    flexGrow: 1,
  },
  w100x200: {
    ...getFormStyle(100, 200),
  },
  formControl: {
    ...getFormStyle(170, 170),
  },
  buttons: {
    '& svg': {
      marginRight: theme.spacing(1),
    },
  },
  list: {
    width: '100%',

    // height: 'calc(100vh - 280px)',
    height: 'calc(100% - 100px)',
  },
  actions: {
    width: '100%',
    marginBottom: 12,
    display: 'flex',
    justifyContent: 'space-between',
  },
}));

// const renderCapAll = (params) => { // renderCell: renderCapAll
//   const reqCapabilities = params.row.capabilities;
//   const factor = <span style={{display: 'inline-block', minWidth: 40}}>{Math.round(params.row.capFactor * 100)}%</span>
//   return (
//     <Fragment>
//       {factor}
//       {reqCapabilities.map((c, idx) => {
//         return (
//           <Fragment key={c.type}>
//             {idx > 0 && <>,&nbsp;</>}
//             <span>{c.type}</span> ({c.quantity})
//           </Fragment>
//         );
//       })}
//     </Fragment>
//   );
// }

const formatBool = (params) => (params.value ? 'Yes' : 'No');

const columns = [
  {
    field: 'date',
    headerName: 'Date',
    width: 100,
    valueFormatter: (params) => formatDate(params.value),
  },
  { field: 'host', headerName: 'Host', width: 130 },
  {
    field: 'contractorEmployee',
    headerName: 'DOE / Contractor Employee',
    width: 80,
    valueFormatter: formatBool,
  },
  { field: 'usCitizen', headerName: 'U.S. Citizen', width: 80, valueFormatter: formatBool },
  { field: 'scd', headerName: 'Current w/ SCD 100', width: 80, valueFormatter: formatBool },
  { field: 'foreignNational', headerName: 'Foreign National', width: 100 },
  { field: 'citizenship', headerName: 'Citizenship', width: 100 },
  { field: 'location', headerName: 'Location', width: 150 },
  { field: 'observations', headerName: 'Observations', width: 150 },
  {
    field: 'unannoundedVisit',
    headerName: 'Unannounced Visit',
    width: 80,
    valueFormatter: formatBool,
  },
  { field: 'evaluator', headerName: 'Evaluator', width: 130 },
  { field: 'approval', headerName: 'Approval', width: 130 },
  {
    field: 'approvalDate',
    headerName: 'Approval Date',
    width: 100,
    valueFormatter: (params) => formatDate(params.value),
  },
];

const searchColumns = [
  { field: 'date', headerName: 'Date' },
  { field: 'host', headerName: 'Host' },
  { field: 'contractorEmployee', headerName: 'DOE / Contractor Employee' },
  { field: 'usCitizen', headerName: 'U.S. Citizen' },
  { field: 'scd', headerName: 'Current w/ SCD 100' },
  { field: 'foreignNational', headerName: 'Foreign National' },
  { field: 'citizenship', headerName: 'Citizenship' },
  { field: 'unannoundedVisit', headerName: 'Unannounced Visit' },
  { field: 'evaluator', headerName: 'Evaluator' },
  { field: 'approval', headerName: 'Approval' },
  { field: 'approvalDate', headerName: 'Approval Date' },
  { field: 'location', headerName: 'Location' },
];

// citizenship approval approvalDate comments favor foreignNatInterview foreign NatInterviewCheck[0, 1, 2] foreignNat
// {code Description} hostReqActivities['', '', '', '', '', ] id, location observations observationsCheck scd unannoundedVisit usCitizen
function HostingValidation(props) {
  const classes = useStyles();
  const { websocket } = props;
  const [data, setData] = React.useState([]);
  const [filteredData, setFilteredData] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const [view, setView] = React.useState('list');
  const [viewData, setViewData] = React.useState(false);
  const [filter, setFilter] = React.useState('');

  React.useEffect(() => {
    if (!websocket.websocket) return;
    getData();
    // eslint-disable-next-line
  }, [websocket]);

  React.useEffect(() => {
    if (!filter) {
      setFilteredData(data);
    } else {
      setFilteredData(filterResults(filter, data, columns));
    }
    // eslint-disable-next-line
  }, [filter, data]);

  const handleSelectChange = ({ selectionModel }) => {
    if (!selectionModel || !selectionModel.length) return;
    const id = parseInt(selectionModel[0]);
    if (!id) return;
    const rowData = data.find((row) => row.id === id);
    if (rowData) setViewData(rowData);
  };

  const handleSelectChange2 = (selection) => {
    if (!selection.rowIds || !selection.rowIds.length) return;
    const id = parseInt(selection.rowIds[0]);
    if (!id) return;
    const rowData = data.find((row) => row.id === id);
    if (rowData) setViewData(rowData);
  };

  const closeView = () => {
    setViewData(false);
  };

  const quickFilter = (ev) => {
    const val = ev.target.value;
    setFilter(val);
  };

  const renderList = () => {
    return (
      <div className={classes.list}>
        <XGrid
          columns={columns}
          rows={filteredData}
          loading={loading}
          rowHeight={38}
          onSelectionModelChange={handleSelectChange}
          onSelectionChange={handleSelectChange2}
          // disableSelectionOnClick={view === 'view'}
          disableMultipleSelection
          hideFooter
        />
      </div>
    );
  };

  const closeAdd = () => {
    setView('list');
  };

  const renderActions = () => {
    return (
      <div className={classes.actions}>
        <div>
          <TextField
            autoComplete="hidden"
            size="small"
            label="Quick filter"
            type="search"
            className={classes.filter}
            variant="outlined"
            onChange={quickFilter}
            value={filter}
            style={{ width: 300, marginBottom: 20 }}
          />
        </div>
        <div>
          <Fab size="small" color="secondary" onClick={() => setView('add')}>
            <AddIcon />
          </Fab>
        </div>
      </div>
    );
  };

  const getData = async () => {
    props.showSpinner();
    try {
      const results = await getHVRecords();
      setData(processData(results));
    } catch (err) {
      console.log(err);
      props.notify('Error loading results', 'error');
    }
    props.hideSpinner();
  };

  const processData = (data) => {
    return data.map((row) => {
      row.foreignNational = row.foreignNational ? row.foreignNational.Description : '';
      row.citizenship = row.citizenship ? row.citizenship.Description : '';
      return row;
    });
  };

  return (
    <Paper className={classes.wrap}>
      <h5>Hosting Validation</h5>
      {renderActions()}
      {renderList()}
      {!!viewData && <ViewData data={viewData} close={closeView} />}
      {view === 'add' && <AddData close={closeAdd} />}
    </Paper>
  );
}

const mapStateToProps = (state) => {
  return { websocket: state.websocket };
};

export default connect(mapStateToProps, {
  showSpinner,
  hideSpinner,
  notify,
})(HostingValidation);
