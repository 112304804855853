export const codeCities = [
  {
    code: 'New Orleans',
    description: 'New Orleans',
  },
  {
    code: 'Baton Rouge',
    description: 'Baton Rouge',
  },
  {
    code: 'Shreveport',
    description: 'Shreveport',
  },
  {
    code: 'Metairie',
    description: 'Metairie',
  },
  {
    code: 'Lafayette',
    description: 'Lafayette',
  },
  {
    code: 'Lake Charles',
    description: 'Lake Charles',
  },
  {
    code: 'Bossier City',
    description: 'Bossier City',
  },
  {
    code: 'Kenner',
    description: 'Kenner',
  },
];
