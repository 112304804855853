import store from '../../config/configureStore';

export const countIncPropertiesErrors = (incIncidentPropertyId) => {
  let currentIncidentId = parseInt(store.store.getState().incident.ptsIncidentId);
  let validationErrors = store.store.getState().incident.validationErrors;

  const errorObj = validationErrors.find((error) => error.ptsIncidentId === currentIncidentId);

  if (!errorObj?.errors?.incPropertiesErrors) {
    return 0;
  }
  let errorCount = 0;

  for (let error of errorObj.errors.incPropertiesErrors) {
    if (error.incIncidentPropertyId === incIncidentPropertyId) {
      errorCount++;
    }
  }
  return errorCount;
};
