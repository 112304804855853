import React from 'react';
import { connect } from 'react-redux';

import clsx from 'clsx';
import DateFnsUtils from '@date-io/date-fns';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { KeyboardDateTimePicker } from '@material-ui/pickers';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import Input from '@material-ui/core/Input';
import MenuItem from '@material-ui/core/MenuItem';
import Chip from '@material-ui/core/Chip';
import Card from '@material-ui/core/Card';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Autocomplete from '@material-ui/lab/Autocomplete';

import RMSRadio from '../../components/RMSRadio';
import { PhoneMask } from '../../components/RMSTextMask';
import RMSPersonInfoCard from 'components/RMSPersonInfoCard';
import { handleFormChangeNew } from '../../reducers/helpers/formHelpers';
import { updateIncOffensesMPBasicForm } from '../../reducers/IncReducer';

const useStyles = makeStyles((theme) => ({
  cardHeader: {
    backgroundColor: theme.palette.info.dark,
    color: theme.palette.common.white,
  },
  detailsPanel: {
    padding: theme.spacing(4),
  },
  root2: {
    padding: '2px 15px 0px 15px',
  },
  dateFields: {
    marginLeft: '2em',
  },

  formControl: {
    width: '100%',
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chip: {
    margin: 2,
  },
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const victimTypeRadioGroup = [
  {
    groupLabel: 'Victim Type',
    ariaLabel: 'typeVictim',
    groupName: 'typeVictim',
    controls: [
      {
        value: 'adult',
        label: 'Adult',
      },
      {
        value: 'juvenile',
        label: 'Juvenile',
      },
    ],
  },
];

const categoryRadioGroup = [
  {
    groupLabel: 'Victim Category',
    ariaLabel: 'category',
    groupName: 'category',
    controls: [
      {
        value: 'at-risk',
        label: 'At Risk',
      },
      {
        value: 'prior-missing',
        label: 'Prior Missing',
      },
      {
        value: 'sexual-exploitation-suspected',
        label: 'Sexual Exploitation Suspected',
      },
    ],
  },
];

const photoRadioGroup = [
  {
    groupLabel: 'Photo Available',
    ariaLabel: 'photo',
    groupName: 'photo',
    controls: [
      {
        value: 'yes',
        label: 'Yes',
      },
      {
        value: 'no',
        label: 'No',
      },
    ],
  },
];

const recordTypeSelections = [
  'Runaway Juvenile',
  'Voluntary Missing Adult',
  'Parent/Family Abduction',
  'Non-Family Abduction',
  'Stranger Abduction',
  'Dependant Adult',
  'Lost',
  'Catastrophe',
  'Unknown Circumstance',
];

const missingStatus = ['Missing', 'Found'];

const xRayRadioGroup = [
  {
    groupLabel: 'Skeletal X-Rays Available',
    ariaLabel: 'xrays',
    groupName: 'xrays',
    controls: [
      {
        value: true,
        label: 'Yes',
      },
      {
        value: false,
        label: 'No',
      },
    ],
  },
];

const dentalRadioGroup = [
  {
    groupLabel: 'Dental X-Rays Available',
    ariaLabel: 'dental',
    groupName: 'dental',
    controls: [
      {
        value: true,
        label: 'Yes',
      },
      {
        value: false,
        label: 'No',
      },
    ],
  },
];

const denturesRadioGroup = [
  {
    groupLabel: 'Dentures',
    ariaLabel: 'dentures',
    groupName: 'dentures',
    controls: [
      {
        value: 'upper',
        label: 'Upper',
      },
      {
        value: 'lower',
        label: 'Lower',
      },
      {
        value: 'full',
        label: 'Full',
      },
      {
        value: 'partial',
        label: 'Partial',
      },
    ],
  },
];

const TabBasicInformation = (props) => {
  const classes = useStyles();
  const [selectedFromDate, setSelectedFromDate] = React.useState(new Date('2020-01-01T00:00:00'));
  const [selectedToDate, setSelectedToDate] = React.useState(new Date('2020-02-28T00:00:00'));
  const { offensesMPBasicForm, updateIncOffensesMPBasicForm, partiesPersonForm } = props;
  const [offensesMPBasicFormState, setOffensesMPBasicFormState] = React.useState({
    formType: 'edit',
    changes: false,
    isValid: false,
    values: {},
    touched: {},
    errors: {},
  });
  /** endregion */
  /** region React Hooks */
  React.useEffect(() => {
    setOffensesMPBasicFormState((offensesMPBasicFormState) => ({
      ...offensesMPBasicFormState,
      ...offensesMPBasicForm,
      values: {
        ...offensesMPBasicFormState.values,
        ...offensesMPBasicForm.values,
      },
    }));
    setSelectedFromDate(offensesMPBasicForm.values?.dateMissing);
    setSelectedToDate(offensesMPBasicForm.values?.dateFound);
  }, [offensesMPBasicForm]);

  React.useEffect(() => {
    offensesMPBasicFormState.changes = true;

    updateIncOffensesMPBasicForm({
      ...offensesMPBasicForm,
      ...offensesMPBasicFormState,
      values: {
        ...offensesMPBasicForm.values,
        ...offensesMPBasicFormState.values,
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    offensesMPBasicFormState.values.category,
    offensesMPBasicFormState.values.typeVictim,
    offensesMPBasicFormState.values.reportingAgency,
    offensesMPBasicFormState.values.ori,
    offensesMPBasicFormState.values.dojNumber,
    offensesMPBasicFormState.values.ncicNumber,
    offensesMPBasicFormState.values.recordType,
    offensesMPBasicFormState.values.dateMissing,
    offensesMPBasicFormState.values.dateFound,
    offensesMPBasicFormState.values.mentalCondition,
    offensesMPBasicFormState.values.scarsMarksTattoos,
    offensesMPBasicFormState.values.locationLastSeen,
    offensesMPBasicFormState.values.portableDestination,
    offensesMPBasicFormState.values.brokenOrMissingBones,
    offensesMPBasicFormState.values.clothingDesc,
    offensesMPBasicFormState.values.photo,
    offensesMPBasicFormState.values.age,
    offensesMPBasicFormState.values.glasses,
    offensesMPBasicFormState.values.contactLenses,
    offensesMPBasicFormState.values.visibleDentalWork,
    offensesMPBasicFormState.values.textmask1,
    offensesMPBasicFormState.values.textmask2,
    offensesMPBasicFormState.values.xrays,
    offensesMPBasicFormState.values.dental,
    offensesMPBasicFormState.values.dentures,
    offensesMPBasicFormState.values.missingStatus,
  ]);
  /** endregion */
  /** region Helper Functions */
  const handleMultiSelectValues = (event, handle) => {
    handleFormChangeNew(event, event.target.value, 'none', handle, setOffensesMPBasicFormState);
  };
  /** endregion */
  return (
    <Grid container className={classes.root2} xs={12} justify="left">
      <Grid container spacing={2} justify="left" className="mt-2 mb-3">
        <Grid item xs={12} lg={12} xl={12}>
          <Card className="card-box mb-3">
            <div
              className={clsx(
                classes.cardHeader,
                'card-header d-flex justify-content-between align-items-center'
              )}>
              <div className="card-header--title text-white">
                <b>Victim Info</b>
              </div>
            </div>
            <RMSPersonInfoCard
              data={{ ...partiesPersonForm.values, ...partiesPersonForm }}
              isMP={true}
            />
          </Card>
        </Grid>
        <Grid item xs={4} lg={4} xl={3}>
          <RMSRadio
            size="small"
            radioGroup={victimTypeRadioGroup}
            selectedValue={offensesMPBasicFormState.values.typeVictim}
            handleFormChange={(event) =>
              handleFormChangeNew(
                event,
                event.target.value,
                'none',
                'typeVictim',
                setOffensesMPBasicFormState
              )
            }
          />
        </Grid>
        <Grid item xs={8} lg={8} xl={9}>
          <RMSRadio
            size="small"
            radioGroup={categoryRadioGroup}
            selectedValue={offensesMPBasicFormState.values.category}
            handleFormChange={(event) =>
              handleFormChangeNew(
                event,
                event.target.value,
                'none',
                'category',
                setOffensesMPBasicFormState
              )
            }
          />
        </Grid>
      </Grid>
      <Grid container justify="left" spacing={2} className="mb-3">
        <Grid item xs={3} lg={3}>
          <TextField
            autoComplete="hidden"
            size="small"
            id="standard-textarea-reporting-agency"
            label="Reporting Agency"
            name="reportingAgency"
            value={offensesMPBasicFormState.values?.reportingAgency || ''}
            onChange={(event) =>
              handleFormChangeNew(
                event,
                event.target.value,
                'none',
                'reportingAgency',
                setOffensesMPBasicFormState
              )
            }
            placeholder="Reporting Agency"
            variant="outlined"
            fullWidth
            multiline
          />
        </Grid>
        <Grid item xs={3} lg={3}>
          <TextField
            autoComplete="hidden"
            size="small"
            id="standard-textarea-ori"
            label="ORI"
            placeholder="ORI"
            name="ori"
            value={offensesMPBasicFormState.values?.ori || ''}
            onChange={(event) =>
              handleFormChangeNew(
                event,
                event.target.value,
                'none',
                'ori',
                setOffensesMPBasicFormState
              )
            }
            variant="outlined"
            fullWidth
            multiline
          />
        </Grid>
        <Grid item xs={3} lg={3}>
          <TextField
            autoComplete="hidden"
            size="small"
            id="standard-textarea-doj-number"
            label="DOJ Number"
            placeholder="DOJ Number"
            name="dojNumber"
            value={offensesMPBasicFormState.values?.dojNumber || ''}
            onChange={(event) =>
              handleFormChangeNew(
                event,
                event.target.value,
                'none',
                'dojNumber',
                setOffensesMPBasicFormState
              )
            }
            variant="outlined"
            fullWidth
            multiline
          />
        </Grid>
        <Grid item xs={3} lg={3}>
          <TextField
            autoComplete="hidden"
            size="small"
            id="standard-textarea-ncic-number"
            label="NCIC Number"
            placeholder="NCIC Number"
            name="ncicNumber"
            value={offensesMPBasicFormState.values?.ncicNumber || ''}
            onChange={(event) =>
              handleFormChangeNew(
                event,
                event.target.value,
                'none',
                'ncicNumber',
                setOffensesMPBasicFormState
              )
            }
            variant="outlined"
            fullWidth
            multiline
          />
        </Grid>
      </Grid>

      <Grid container spacing={2} justify="left" className="mb-3" id="test">
        <Grid item xs={6} lg={6} style={{ marginTop: '-10px' }}>
          <FormControl size="small" variant="outlined" className={classes.formControl}>
            <InputLabel id="demo-mutiple-chip-label">Record Type</InputLabel>
            <Select
              labelId="demo-mutiple-chip-label"
              id="demo-mutiple-chip"
              multiple
              name="recordType"
              className={classes.recordType}
              value={offensesMPBasicFormState.values.recordType || []}
              onChange={(e) => handleMultiSelectValues(e, 'recordType')}
              input={<Input id="select-multiple-chip" />}
              renderValue={(selected) => (
                <div className={classes.chips}>
                  {selected.map((value) => (
                    <Chip key={value} label={value} className={classes.chip} />
                  ))}
                </div>
              )}
              MenuProps={MenuProps}>
              {recordTypeSelections.map((selection) => (
                <MenuItem key={selection} value={selection}>
                  {selection}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>

        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <Grid item xs={3} lg={3}>
            <KeyboardDateTimePicker
              size="small"
              inputVariant="outlined"
              fullWidth
              showTodayButton={true}
              variant="dialog"
              format="MM/dd/yyyy hh:mm a"
              id="date-picker-inline-from"
              label="Date Missing"
              value={selectedFromDate ? selectedFromDate : null}
              onChange={(date) => {
                setOffensesMPBasicFormState((state) => {
                  return {
                    ...state,
                    values: {
                      ...state.values,
                      dateMissing: date,
                    },
                  };
                });
              }}
              KeyboardButtonProps={{
                'aria-label': 'change date',
              }}
            />
          </Grid>
          <Grid item xs={3} lg={3}>
            <KeyboardDateTimePicker
              size="small"
              inputVariant="outlined"
              fullWidth
              showTodayButton={true}
              variant="dialog"
              format="MM/dd/yyyy hh:mm a"
              id="date-picker-inline-from"
              label="Found Date"
              value={selectedToDate ? selectedToDate : null}
              onChange={(date) => {
                setOffensesMPBasicFormState((state) => {
                  return {
                    ...state,
                    values: {
                      ...state.values,
                      dateFound: date,
                    },
                  };
                });
              }}
              KeyboardButtonProps={{
                'aria-label': 'change date',
              }}
            />
          </Grid>
        </MuiPickersUtilsProvider>
      </Grid>

      <Grid container justify="left" spacing={2} className="mb-3">
        <Grid item xs={5} lg={4} xl={4}>
          <TextField
            autoComplete="hidden"
            size="small"
            id="standard-textarea-mental-condition"
            label="Mental Condition"
            placeholder="Mental Condition"
            variant="outlined"
            fullWidth
            multiline
            name="mentalCondition"
            value={offensesMPBasicFormState.values.mentalCondition}
            onChange={(event) =>
              handleFormChangeNew(
                event,
                event.target.value,
                'none',
                'mentalCondition',
                setOffensesMPBasicFormState
              )
            }
          />
        </Grid>
        <Grid item xs={6} lg={5} xl={4}>
          <TextField
            autoComplete="hidden"
            size="small"
            id="standard-textarea-scars-tattoos"
            label="Scars/Marks/Tattoos(locate/describe)"
            placeholder="Scars/Marks/Tattoos"
            variant="outlined"
            fullWidth
            name="scarsMarksTattoos"
            value={offensesMPBasicFormState.values.scarsMarksTattoos}
            onChange={(event) =>
              handleFormChangeNew(
                event,
                event.target.value,
                'none',
                'scarsMarksTattoos',
                setOffensesMPBasicFormState
              )
            }
            multiline
          />
        </Grid>
      </Grid>

      <Grid container justify="left" spacing={2} className="mb-3">
        <Grid item xs={4} lg={4}>
          <TextField
            autoComplete="hidden"
            size="small"
            id="standard-textarea-last-seen"
            label="Location Last Seen"
            placeholder="Location Last Seen"
            variant="outlined"
            fullWidth
            name="locationLastSeen"
            value={offensesMPBasicFormState.values.locationLastSeen}
            onChange={(event) =>
              handleFormChangeNew(
                event,
                event.target.value,
                'none',
                'locationLastSeen',
                setOffensesMPBasicFormState
              )
            }
            multiline
          />
        </Grid>
        <Grid item xs={4} lg={4}>
          <TextField
            autoComplete="hidden"
            size="small"
            id="standard-textarea-probable-destination"
            label="Probable Destination"
            placeholder="Probable Destination"
            variant="outlined"
            fullWidth
            name="portableDestination"
            value={offensesMPBasicFormState.values.portableDestination}
            onChange={(event) =>
              handleFormChangeNew(
                event,
                event.target.value,
                'none',
                'portableDestination',
                setOffensesMPBasicFormState
              )
            }
            multiline
          />
        </Grid>

        <Grid item xs={4} lg={4}>
          <TextField
            autoComplete="hidden"
            size="small"
            id="standard-textarea-broken-missing"
            label="Broken Bones/Missing Organs"
            placeholder="Bones/Organs"
            variant="outlined"
            fullWidth
            multiline
            name="brokenOrMissingBones"
            value={offensesMPBasicFormState.values.brokenOrMissingBones}
            onChange={(event) =>
              handleFormChangeNew(
                event,
                event.target.value,
                'none',
                'brokenOrMissingBones',
                setOffensesMPBasicFormState
              )
            }
          />
        </Grid>
      </Grid>

      <Grid container justify="left" spacing={3} className="mb-3">
        <Grid item xs={4} lg={4}>
          <TextField
            autoComplete="hidden"
            size="small"
            id="standard-textarea-clothing-description"
            label="Clothing Details / Size"
            placeholder="Clothing Details / Size"
            variant="outlined"
            name="clothingDesc"
            value={offensesMPBasicFormState.values.clothingDesc}
            onChange={(event) =>
              handleFormChangeNew(
                event,
                event.target.value,
                'none',
                'clothingDesc',
                setOffensesMPBasicFormState
              )
            }
            fullWidth
            multiline
          />
        </Grid>
        <Grid item xs={2} lg={2} className="pl-2">
          <RMSRadio
            size="small"
            radioGroup={photoRadioGroup}
            selectedValue={offensesMPBasicFormState.values.photo}
            handleFormChange={(event) =>
              handleFormChangeNew(
                event,
                event.target.value,
                'none',
                'photo',
                setOffensesMPBasicFormState
              )
            }
          />
        </Grid>

        <Grid item xs={3} lg={3}>
          <TextField
            autoComplete="hidden"
            size="small"
            id="standard-textarea-age-in-photo"
            label="Age In Photo"
            placeholder="Age"
            variant="outlined"
            fullWidth
            multiline
            name="age"
            value={offensesMPBasicFormState.values.age}
            onChange={(event) =>
              handleFormChangeNew(
                event,
                event.target.value,
                'none',
                'age',
                setOffensesMPBasicFormState
              )
            }
          />
        </Grid>

        <Grid item xs={2} lg={3}>
          <FormControlLabel
            control={
              <Checkbox
                key={offensesMPBasicFormState.values.glasses}
                checked={offensesMPBasicFormState.values.glasses}
                size="small"
                onChange={(event) =>
                  handleFormChangeNew(
                    event,
                    event.target.checked,
                    'none',
                    'glasses',
                    setOffensesMPBasicFormState
                  )
                }
              />
            }
            label="Glasses"
          />
          <FormControlLabel
            control={
              <Checkbox
                key={offensesMPBasicFormState.values.contactLenses}
                size="small"
                checked={offensesMPBasicFormState.values.contactLenses}
                onChange={(event) =>
                  handleFormChangeNew(
                    event,
                    event.target.checked,
                    'none',
                    'contactLenses',
                    setOffensesMPBasicFormState
                  )
                }
              />
            }
            label="Contact Lenses"
          />
        </Grid>
      </Grid>

      <Grid container justify="left" spacing={2} className="mb-3">
        <Grid item xs={4} lg={2}>
          <TextField
            autoComplete="hidden"
            size="small"
            id="standard-textarea-visible-dental-work"
            label="Visible Dental Work"
            placeholder="Dental Work"
            variant="outlined"
            name="visibleDentalWork"
            value={offensesMPBasicFormState.values.visibleDentalWork}
            onChange={(event) =>
              handleFormChangeNew(
                event,
                event.target.value,
                'none',
                'visibleDentalWork',
                setOffensesMPBasicFormState
              )
            }
            fullWidth
            multiline
          />
        </Grid>

        <Grid item xs={2} lg={3} xl={4}>
          <TextField
            autoComplete="hidden"
            size="small"
            fullWidth
            variant="outlined"
            label="Doctor Phone"
            name="textmask1"
            value={offensesMPBasicFormState.values.textmask1}
            onChange={(event) =>
              handleFormChangeNew(
                event,
                event.target.value,
                'none',
                'textmask1',
                setOffensesMPBasicFormState
              )
            }
            id="formatted-numberformat-input"
            InputProps={{
              inputComponent: PhoneMask,
            }}
          />
        </Grid>

        <Grid item xs={2} lg={3} xl={4}>
          <TextField
            autoComplete="hidden"
            size="small"
            fullWidth
            variant="outlined"
            label="Dentist Phone"
            name="textmask2"
            value={offensesMPBasicFormState.values.textmask2}
            onChange={(event) =>
              handleFormChangeNew(
                event,
                event.target.value,
                'none',
                'textmask2',
                setOffensesMPBasicFormState
              )
            }
            id="formatted-numberformat-input"
            InputProps={{
              inputComponent: PhoneMask,
            }}
          />
        </Grid>

        <Grid item xs={3} xl={2}>
          <RMSRadio
            size="small"
            radioGroup={xRayRadioGroup}
            selectedValue={offensesMPBasicFormState.values.xrays}
            handleFormChange={(event) =>
              handleFormChangeNew(
                event,
                event.target.value,
                'none',
                'xrays',
                setOffensesMPBasicFormState
              )
            }
          />
        </Grid>
      </Grid>

      <Grid container spacing={2} justify="left" className="mb-3">
        <Grid item xs={3} lg={3} xl={3}>
          <RMSRadio
            size="small"
            radioGroup={dentalRadioGroup}
            selectedValue={offensesMPBasicFormState.values.dental}
            handleFormChange={(event) =>
              handleFormChangeNew(
                event,
                event.target.value,
                'none',
                'dental',
                setOffensesMPBasicFormState
              )
            }
          />
        </Grid>
        <Grid item xs={6} lg={6} xl={4}>
          <RMSRadio
            size="small"
            radioGroup={denturesRadioGroup}
            selectedValue={offensesMPBasicFormState.values.dentures}
            handleFormChange={(event) =>
              handleFormChangeNew(
                event,
                event.target.value,
                'none',
                'dentures',
                setOffensesMPBasicFormState
              )
            }
          />
        </Grid>
        <Grid item xs={3} lg={3} xl={3}>
          <Autocomplete
            size="small"
            autoComplete
            autoSelect
            autoHighlight
            id="combo-missing-status"
            getOptionLabel={(option) => option || ''}
            options={missingStatus}
            key={offensesMPBasicFormState.values.missingStatus || ''}
            value={offensesMPBasicFormState.values.missingStatus || ''}
            onChange={(event, newValue) =>
              handleFormChangeNew(
                event,
                newValue,
                'none',
                'missingStatus',
                setOffensesMPBasicFormState
              )
            }
            renderInput={(params) => (
              <TextField
                autoComplete="hidden"
                size="small"
                {...params}
                label="Missing Status"
                variant="outlined"
                fullWidth
              />
            )}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

const mapStateToProps = (state) => ({
  maleVictimCondition: state.uiImage.dvMaleVictimConditionState,
  partiesPersonForm: state.incident.partiesPersonForm,
  offensesMPBasicForm: state.incident.offensesMPBasicForm,
});

export default connect(mapStateToProps, { updateIncOffensesMPBasicForm })(TabBasicInformation);
