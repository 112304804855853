/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */
import React from 'react';
import { connect } from 'react-redux';
import { useParams } from 'react-router';
import { useSelector } from 'react-redux';

import clsx from 'clsx';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Badge, Paper, Stepper, Step, StepLabel, Box, Grid } from '@material-ui/core';
import { makeStyles, withStyles, styled } from '@material-ui/core/styles';
import AssignmentIcon from '@material-ui/icons/Assignment';
import EmojiTransportationIcon from '@material-ui/icons/EmojiTransportation';
import LockIcon from '@material-ui/icons/Lock';

import TabOffense from './TabOffense';
import TabOffenders from './TabOffenders';
import TabVictims from './TabVictims';
import TabRelationships from './TabRelationships';
import TabProperty from './TabProperty';
import TabArrestees from './TabArrestees';

import IncSummaryDetails from '../../components/RMSForms/RMSIncidentForm/IncSummaryDetails';
import RMSFabButtons from '../../components/RMSForms/RMSIncidentForm/RMSFabButtons';
import {
  updateIncOffensesOffenseForm,
  setIncSubjectsDetails,
  setIncVictimsDetails,
  setIncPropertyDetails,
  getEntitiesByIncidentId,
  setIncArresteeDetails,
  setIncRelationshipDetails,
  setCurrentOffense,
  setIncDVVictimList,
  setIncDVSuspectList,
  setIncDVAdditionalList,
  setSelectedIncident,
  setSelectedEntity,
} from '../../reducers/IncReducer';
import { setOffensesTab } from '../../reducers/ui/UiFormReducer';
import { setHandleClose, setMenuType } from '../../reducers/ui/UiMenuReducer';
// import { ptsStatute } from '../../reducers/_dev/ptsStatute';
import { errorTree } from 'simpl-schema-validation/helpers/errorTree';

const StyledStepLabel = styled(StepLabel)({
  cursor: 'pointer',

  '& .MuiStepLabel-label': {
    color: '#ccc',
    padding: '0px',
    marginTop: '0px',
  },
  '& .MuiStepLabel-active': {
    color: 'white',
  },
});

const ActiveIconBadge = withStyles({
  badge: {
    boxShadow: '0 0 0 2px #fff',
    '&::after': {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      borderRadius: '50%',
      animation: '$ripple 1.2s infinite ease-in-out',
      border: '1px solid currentColor',
      content: '""',
    },
  },
  '@keyframes ripple': {
    '0%': {
      transform: 'scale(.8)',
      opacity: 1,
    },
    '100%': {
      transform: 'scale(2.4)',
      opacity: 0,
    },
  },
})(Badge);

const useColorlibStepIconStyles = makeStyles({
  root: {
    zIndex: 1,
    color: '#ccc',
    width: 30,
    height: 30,
    display: 'flex',
    borderRadius: '50%',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: '10px',
  },
  active: {
    color: 'black',
    backgroundColor: 'whiteSmoke',
  },
});
function ColorlibStepIcon(props) {
  const classes = useColorlibStepIconStyles();
  const { active } = props;

  const icons = {
    1: <AssignmentIcon style={{ fontSize: '17px' }} />,
    2: <FontAwesomeIcon icon="user" style={{ fontSize: '15px' }} />,
    3: <FontAwesomeIcon icon="user-shield" style={{ fontSize: '15px' }} />,
    4: <FontAwesomeIcon icon="users" style={{ fontSize: '15px' }} />,
    5: <EmojiTransportationIcon style={{ fontSize: '15px' }} />,
    6: <LockIcon style={{ fontSize: '15px' }} />,
  };

  return (
    <div
      className={clsx(classes.root, {
        [classes.active]: active,
      })}>
      {icons[String(props.icon)]}
    </div>
  );
}

ColorlibStepIcon.propTypes = {
  active: PropTypes.bool,
  completed: PropTypes.bool,
  icon: PropTypes.node,
};

const StyledBadge = withStyles({
  badge: {
    backgroundColor: 'var(--danger)',
    color: 'var(--danger)',
  },
})(ActiveIconBadge);

const ErrorBadge = withStyles({
  badge: {
    boxShadow: '0 0 0 2px #fff',
    '&::after': {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      borderRadius: '50%',
      animation: '$ripple 1.2s infinite ease-in-out',
      border: '1px solid currentColor',
      content: '""',
    },
  },
  '@keyframes ripple': {
    '0%': {
      transform: 'scale(.8)',
      opacity: 1,
    },
    '100%': {
      transform: 'scale(2.4)',
      opacity: 0,
    },
  },
})(Badge);

const StyledErrorBadge = withStyles({
  badge: {
    backgroundColor: 'var(--danger)',
    color: 'var(--danger)',
  },
})(ErrorBadge);

function ColorlibStepIconError(props) {
  const classes = useColorlibStepIconStyles();
  const { active } = props;

  const icons = {
    1: <AssignmentIcon style={{ fontSize: '17px' }} />,
    2: <FontAwesomeIcon icon="user" style={{ fontSize: '15px' }} />,
    3: <FontAwesomeIcon icon="user-shield" style={{ fontSize: '15px' }} />,
    4: <FontAwesomeIcon icon="users" style={{ fontSize: '15px' }} />,
    5: <EmojiTransportationIcon style={{ fontSize: '15px' }} />,
    6: <LockIcon style={{ fontSize: '15px' }} />,
  };

  return (
    <StyledErrorBadge
      overlap="circle"
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      variant="dot">
      <div
        className={clsx(classes.root, {
          [classes.active]: active,
        })}>
        {icons[String(props.icon)]}
      </div>
    </StyledErrorBadge>
  );
}

ColorlibStepIconError.propTypes = {
  active: PropTypes.bool,
  completed: PropTypes.bool,
  icon: PropTypes.node,
};

const useStyles = makeStyles((theme) => ({
  paper: {
    minHeight: window.innerHeight - 176 + 'px',
  },
  root: {
    width: '100%',
    height: window.innerHeight - 90 + 'px',
  },
  dialogBox: {
    minWidth: '412px',
    minHeight: '150px',
  },
  icons: {
    textDecoration: 'capitalize',
    padding: '11px',
    color: 'white',
    fontSize: '13px',
  },
  indicator: {
    backgroundColor: 'rgba(0,0,254,0.4)',
  },
}));

const AddEditOffense = (props) => {
  /** region Component Variables and Props */
  const classes = useStyles();
  const {
    history,
    setHandleClose,
    setMenuType,
    setOffensesTab,
    selectedTab,
    wsClient,
    selectedEntity,
    selectedIncident,
    updateIncOffensesOffenseForm,
    setIncSubjectsDetails,
    setIncVictimsDetails,
    setIncPropertyDetails,
    setIncArresteeDetails,
    setIncRelationshipDetails,
    getEntitiesByIncidentId,
    tabPropertyActive,
    setIncDVVictimList,
    setIncDVSuspectList,
    setIncDVAdditionalList,
    offenses,
    setSelectedIncident,
    setSelectedEntity,
  } = props;
  let { incId, offenseId } = useParams();
  const steps = [
    { label: 'Offense' },
    { label: 'Offenders' },
    { label: 'Victims' },
    { label: 'Relationships' },
    { label: 'Property' },
    { label: 'Arrestees' },
  ];
  /** endregion */
  /** redux store */
  const steppersWithErrors = useSelector((state) => {
    const valdiationErrors = state.incident.validationErrors;
    const currentIncidentId = state.incident.ptsIncidentId;
    const currentOffenseId = state.incident.currentOffense;

    const errorObj = valdiationErrors?.find((error) => error.ptsIncidentId === currentIncidentId);
    if (!errorObj) {
      return [];
    }
    const errorsAssociatedWithSteppers = errorTree.offensesErrors;
    let steppersWithErrors = [];

    errorsAssociatedWithSteppers.forEach((stepperError, index) => {
      if (errorObj.errors?.[stepperError]?.length) {
        let errorsOfCurrentOffense = errorObj.errors?.[stepperError].filter(
          (error) => error.ptsOffenseId === currentOffenseId
        );
        if (errorsOfCurrentOffense?.length) steppersWithErrors.push(index);
      }
    });
    return steppersWithErrors;
  });
  /** endregion redux store */

  /** region React Hooks */
  React.useEffect(() => {
    setMenuType('forms');
    setHandleClose('incForms', history);

    if (!selectedIncident || !selectedEntity || !wsClient) {
      return;
    }

    (async () => {
      await Promise.all([
        getEntitiesByIncidentId('parties'),
        getEntitiesByIncidentId('properties'),
      ]);

      const { ptsOffenseId } = selectedEntity;
      const service = wsClient.service('rms-incident');
      service.timeout = 200000;

      const promise1 = service.find({
        query: {
          ptsIncidentID: selectedIncident,
          $select: ['ptsIncidentID'],
          offenses: true,
          offender: true,
        },
      });

      const promise2 = service.find({
        query: {
          ptsIncidentID: selectedIncident,
          $select: ['ptsIncidentID'],
          offenses: true,
          victim: true,
        },
      });

      const promise3 = service.find({
        query: {
          ptsIncidentID: selectedIncident,
          $select: ['ptsIncidentID'],
          offenses: true,
          arrestee: true,
        },
      });

      const promise4 = service.find({
        query: {
          ptsIncidentID: selectedIncident,
          $select: ['ptsIncidentID'],
          offenses: true,
          relation: true,
        },
      });

      const [result, result2, result3, result4] = await Promise.all([
        promise1,
        promise2,
        promise3,
        promise4,
      ]);

      if (result.total === 0) return;

      let { Offense } = result.data[0];
      if (!Offense || !Array.isArray(Offense)) return;

      Offense = Offense.filter((e) => e.ptsOffenseID === ptsOffenseId);

      if (Offense.length === 0) return;

      Offense = Offense[0];

      // TODO: locationCategory forceCategory
      let { Subject } = Offense;
      if (!Subject) return;

      const currentOffenseSubjects = Subject.filter(
        (s) => s.ptsOffenseID === Offense.ptsOffenseID && s.IsDeleted === false
      );

      const subjects = currentOffenseSubjects.map((sub) => {
        return {
          incSubjectDetails: {
            ptsPersonId: sub.ptsPersonID,
            ptsIncPersonId: sub.ptsIncPersonID,
            ptsSubjectId: sub.ptsSubjectID,
            ptsOffenseId: Offense.ptsOffenseID,
            values: {
              incidentId: selectedIncident,
              offenseId: Offense.ptsOffenseID,
              subjectId: sub.ptsSubjectID,
              armed: sub.IsArmed,
              extradition: sub.IsExtradition,
              unknown: sub.IsUnknown,
              dnaCollection: sub.DNACollection,
              involvement: sub.SubjectInvolvement,
              mo: sub.SubjectMO,
              status: sub.SubjectStatus,
              weapon: sub.SubjectWeapon,
              notes: sub.Notes,
            },
          },
        };
      });

      if (result2.total === 0) return;

      Offense = result2.data[0].Offense;
      Offense = Offense.filter((e) => e.ptsOffenseID === ptsOffenseId)[0];

      let { Victim } = Offense;

      const currentOffenseVictims = Victim.filter(
        (v) => v.ptsOffenseID === Offense.ptsOffenseID && v.IsDeleted === false
      );

      let dvVictims = [];
      let dvSuspects = [];
      let dvAdditionals = [];

      currentOffenseVictims.forEach((vic) => {
        let dvV = vic.DVVictim;
        if (dvV) {
          dvVictims.push({
            ptsVictimId: vic.ptsVictimID,
            ptsDVVictimId: dvV.ptsDVVictimID,
            ptsOffenseId: Offense.ptsOffenseID,
            ptsPersonId: vic.ptsParentID,
            ptsIncPersonId: vic.ptsIncPersonID,
            values: {
              ...dvV,
            },
          });
        }
      });

      currentOffenseVictims.forEach((vic) => {
        let dvS = vic.DVSuspects;
        dvS.forEach((s) => {
          if (s.IsDeleted === false) {
            dvSuspects.push({
              incSuspectDetails: {
                ptsVictimId: vic.ptsVictimID,
                ptsDVSuspectId: s.ptsDVSuspectID,
                ptsOffenseId: Offense.ptsOffenseID,
                ptsVictimPersonId: vic.ptsIncPersonID,
                ptsPersonId: s.ptsPersonID,
                ptsIncPersonId: s.ptsIncPersonID,
                values: {
                  ...s,
                },
              },
            });
          }
        });
      });

      currentOffenseVictims.forEach((vic) => {
        let dvA = vic.DVAdditional;
        if (dvA) {
          dvAdditionals.push({
            ptsVictimId: vic.ptsVictimID,
            ptsDVAdditionalId: dvA.ptsDVAdditionalID,
            ptsOffenseId: Offense.ptsOffenseID,
            ptsPersonId: vic.ptsParentID,
            ptsIncPersonId: vic.ptsIncPersonID,
            values: {
              ...dvA,
            },
          });
        }
      });

      // TODO: aggravatedAssaultHomicide seekingProsecution
      const victims = currentOffenseVictims.map((vic) => {
        return {
          incVictimDetails: {
            ptsPersonId: vic.ptsParentID,
            ptsIncPersonId: vic.ptsIncPersonID,
            ptsVictimId: vic.ptsVictimID,
            ptsOffenseId: Offense.ptsOffenseID,
            values: {
              incidentId: selectedIncident,
              offenseId: Offense.ptsOffenseID,
              injuryType: vic.InjuryType,
              negligentManslaughter: vic.NegligentManslaughter,
              justifiableHomicide: vic.JustifiableHomicide,
              additionalJustifiableHomicide: vic.AddlJustifiableHomicide,
              officerORI: vic.OfficerORI,
              officerActivity: vic.OfficerActivity,
              officerAssignment: vic.OfficerAssignment,
              seekingProsecution: vic.IsSeekingPersecution,
              society: vic.IsSociety,
              caseNumber: vic.VICAPCaseNumber,
              category: vic.VictimCategory,
              disposition: vic.VictimDisposition,
              treatment: vic.VictimTreatment,
              residentStatus: vic.ResidentStatus,
              aggravatedAssaultHomicide: vic.AggAssaultType,
              notes: vic.Notes,
            },
          },
        };
      });

      if (result3.total === 0) return;
      Offense = result3.data[0].Offense;
      Offense = Offense.filter((e) => e.ptsOffenseID === ptsOffenseId)[0];
      let { Arrestee } = Offense;

      const currentOffenseArrestees = Arrestee.filter(
        (a) => a.ptsOffenseID === Offense.ptsOffenseID && a.IsDeleted === false
      );

      const arrestees = currentOffenseArrestees.map((a) => {
        return {
          incArresteeDetails: {
            ptsPersonId: a?.ptsPersonID,
            ptsIncPersonId: a?.ptsIncPersonID,
            ptsArresteeId: a?.ptsArresteeID,
            ptsOffenseId: Offense.ptsOffenseID,
            values: {
              arrestDate: a?.ArrestDate,
              cleared: a?.IsCleared,
              arrestType: a?.ArrestType,
              arresteeSegmentsIndicator: a?.ArrestSegmentIndicator,
              juvenileDisposition: a?.JuvenileDisposition,
              arresteeResidentStatus: a?.ResidentStatus,
              arresteeArmedWith: a?.ArresteeArmed,
              offenseDescription: a?.OffenseDescription,
              notes: a?.Notes,
            },
          },
        };
      });

      if (result4.total === 0) return;
      Offense = result4.data[0].Offense;
      Offense = Offense.filter((e) => e.ptsOffenseID === ptsOffenseId)[0];
      let { VictimOffenseSubject } = Offense;

      const currentOffenseRelations = VictimOffenseSubject.filter(
        (a) => a.ptsOffenseID === Offense.ptsOffenseID && a.IsDeleted === false
      );

      const relations = currentOffenseRelations.map((r) => {
        return {
          incRelationshipDetails: {
            ptsOffenseId: Offense.ptsOffenseID,
            values: {
              relationship: {
                offenderId: r.Subject?.ptsIncPersonID,
                victimId: r.Victim?.ptsIncPersonID,
                relationship: r.Relationship,
              },
            },
          },
        };
      });

      await Promise.all([
        setIncSubjectsDetails(subjects),
        setIncVictimsDetails(victims),
        setIncDVVictimList(dvVictims),
        setIncDVSuspectList(dvSuspects),
        setIncDVAdditionalList(dvAdditionals),
        setIncPropertyDetails(ptsOffenseId),
        setIncArresteeDetails(arrestees),
        setIncRelationshipDetails(relations),
      ]);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedIncident, wsClient]);

  React.useEffect(() => {
    if (selectedEntity) {
      const { ptsOffenseId } = selectedEntity;
      offenses.map((o) => {
        if (o.offenseDetails.ptsOffenseId === ptsOffenseId) {
          updateIncOffensesOffenseForm(o.offenseDetails);
        }
      });
    }
  }, [offenses, selectedEntity]);

  React.useEffect(() => {
    if (offenseId) setSelectedEntity({ ptsOffenseId: parseInt(offenseId) });
    if (incId) setSelectedIncident(parseInt(incId));
  }, [selectedIncident === 0, selectedEntity === null]);
  /** endregion */
  /** region Helper Functions */
  const handleChange = (newValue) => {
    setOffensesTab(newValue);
  };

  const isStepOptional = () => {
    return false;
  };
  /** endregion */
  return (
    <div className={classes.root}>
      <RMSFabButtons />
      <Grid container>
        <Grid xs={3} lg={3}>
          <IncSummaryDetails history={history} />
        </Grid>
        <Grid xs={9} lg={9}>
          <Paper className={classes.paper}>
            <Grid container justify="center">
              <Grid item xs={12} style={{ paddingTop: '0px' }}>
                <Box className={classes.stepper}>
                  <Stepper
                    alternativeLabel
                    nonLinear
                    activeStep={selectedTab}
                    style={{
                      backgroundColor: '#1976d2',
                      borderRadius: '0.5rem',
                      padding: '15px 5px',
                    }}>
                    {steps.map((obj, index) => {
                      const stepProps = {};
                      const buttonProps = {};
                      if (isStepOptional(index)) {
                        buttonProps.optional = <Typography variant="caption">Optional</Typography>;
                      }
                      return (
                        <Step key={obj.label} {...stepProps}>
                          {steppersWithErrors.includes(index) ? (
                            <StyledStepLabel
                              StepIconComponent={ColorlibStepIconError}
                              onClick={() => handleChange(index)}>
                              {obj.label}
                            </StyledStepLabel>
                          ) : (
                            <StyledStepLabel
                              StepIconComponent={ColorlibStepIcon}
                              onClick={() => handleChange(index)}>
                              {obj.label}
                            </StyledStepLabel>
                          )}
                        </Step>
                      );
                    })}
                  </Stepper>
                </Box>
              </Grid>
              <PerfectScrollbar
                style={{
                  overflow: 'scroll',
                  width: '100%',
                  maxHeight: window.innerHeight - 250 + 'px',
                  overflowX: 'hidden',
                  paddingTop: '20px',
                  paddingBottom: '30px',
                }}
                className={classes.paper}>
                {selectedTab === 0 && (
                  <Grid item>
                    <TabOffense history={history} />
                  </Grid>
                )}

                {selectedTab === 1 && (
                  <Grid item>
                    <TabOffenders history={history} />
                  </Grid>
                )}

                {selectedTab === 2 && (
                  <Grid item>
                    <TabVictims history={history} />
                  </Grid>
                )}

                {selectedTab === 3 && (
                  <Grid item>
                    <TabRelationships history={history} />
                  </Grid>
                )}
                {selectedTab === 4 && (
                  <Grid item>
                    <TabProperty history={history} />
                  </Grid>
                )}
                {selectedTab === 5 && (
                  <Grid item>
                    <TabArrestees history={history} />
                  </Grid>
                )}
              </PerfectScrollbar>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    </div>
  );
};

const mapStateToProps = (state) => ({
  selectedTab: state.uiForm.offensesTabState,
  wsClient: state.websocket.websocket,
  selectedEntity: state.incident.selectedEntity,
  parties: state.incident.parties,
  offenses: state.incident.offenses,
  selectedIncident: state.incident.ptsIncidentId,
  currentOffense: state.incident.currentOffense,
  showUCRAlertModal: state.uiModal.showUCRAlertModal,
});

export default connect(mapStateToProps, {
  setMenuType,
  setHandleClose,
  setOffensesTab,
  updateIncOffensesOffenseForm,
  setIncSubjectsDetails,
  setIncVictimsDetails,
  setIncPropertyDetails,
  setIncArresteeDetails,
  setIncRelationshipDetails,
  getEntitiesByIncidentId,
  setCurrentOffense,
  setIncDVVictimList,
  setIncDVSuspectList,
  setIncDVAdditionalList,
  setSelectedIncident,
  setSelectedEntity,
})(AddEditOffense);
