import React from 'react';
import { connect } from 'react-redux';

import DateFnsUtils from '@date-io/date-fns';
import PerfectScrollbar from 'react-perfect-scrollbar';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Paper from '@material-ui/core/Paper';
import { Divider, Typography } from '@material-ui/core';
import Tooltip from '@material-ui/core/Tooltip';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';

import {
  updateIncOffensesDVSuspectForm,
  updateIncSuspectDetails,
} from '../../../../reducers/IncReducer';
import { handleFormChangeNew } from '../../../../reducers/helpers/formHelpers';

import RMSRadio from '../../../RMSRadio';
import * as markerjs2 from 'markerjs2';

const selections = [
  { key: 'IsAngry', value: 'Angry' },
  { key: 'IsApologetic', value: 'Apologetic' },
  { key: 'IsCrying', value: 'Crying' },
  { key: 'IsFearful', value: 'Fearful' },
  { key: 'IsHysterical', value: 'Hysterical' },
  { key: 'IsCalm', value: 'Calm' },
  { key: 'IsIrrational', value: 'Irrational' },
  { key: 'IsNervous', value: 'Nervous' },
  { key: 'IsThreatening', value: 'Threatening' },
];

const physicalSelections = [
  { key: 'IsCompOfPain', value: 'Comp of Pain' },
  { key: 'IsBruise', value: 'Bruise(s)' },
  { key: 'IsMinorCut', value: 'Minor Cut(s)' },
  { key: 'IsLaceration', value: 'Laceration(s)' },
  { key: 'IsFracture', value: 'Fracture(s)' },
  { key: 'IsConcussion', value: 'Concussion' },
  { key: 'IsPhysicalOther', value: 'Other' },
];

const relationSelections = [
  { key: 'IsSpouse', value: 'Spouse' },
  { key: 'IsFormerSpouse', value: 'Former Spouse' },
  { key: 'IsCohabitants', value: 'Cohabitants' },
  { key: 'IsFormerCohabitants', value: 'Former Cohabitants' },
  { key: 'IsDatingOrEngaged', value: 'Dating/Engaged' },
  { key: 'IsFormerDating', value: 'Former Dating' },
  { key: 'IsSameSex', value: 'Same Sex' },
  { key: 'IsParentOfChild', value: 'Parent Of Child' },
  { key: 'IsOtherRelationship', value: 'Other Relationship' },
];

const priorSelections = [
  { key: 'IsDomesticViolence', value: 'Domestic Violence' },
  { key: 'IsAssaults', value: 'Assaults' },
  { key: 'IsSeriousIncidents', value: 'Serious Incidents' },
];

const radioGroupLanguage = [
  {
    groupLabel: '',
    ariaLabel: 'language',
    groupName: 'language',
    controls: [
      {
        value: 'spanish',
        label: 'Spanish',
      },
      {
        value: 'asian',
        label: 'Asian',
      },
      {
        value: 'other-non-english',
        label: 'Other Non-English',
      },
    ],
  },
];

const useStyles = makeStyles((theme) => ({
  formControl: (props) => ({
    margin: theme.spacing(1),
    minWidth: '100%',
    maxWidth: '100%',
  }),
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chip: {
    margin: 2,
  },
  noLabel: {
    marginTop: theme.spacing(3),
  },
  scrollBar: {
    width: '100%',
    overflowX: 'hidden',
    paddingRight: '5px',
  },
}));

const SuspectsForm = (props) => {
  const classes = useStyles();
  const {
    parties,
    dvSuspects,
    selectedSuspect,
    maleSuspectCondition,
    femaleSuspectCondition,
    updateIncOffensesDVSuspectForm,
    updateIncSuspectDetails,
  } = props;

  const [offensesDVSuspectFormState, setOffensesDVSuspectFormState] = React.useState({
    isValid: false,
    values: {},
    touched: {},
    errors: {},
  });
  const [victimGender, setVictimGender] = React.useState('male');

  const updateIncDetailsList = () => {
    updateIncSuspectDetails(offensesDVSuspectFormState);
  };

  const handleClickImg = () => {
    let target = document.getElementById('body-outline');
    const markerArea = new markerjs2.MarkerArea(target);
    markerArea.targetRoot = document.getElementById('image-root');
    markerArea.renderAtNaturalSize = true;
    markerArea.renderImageType = 'image/jpeg';
    markerArea.renderImageQuality = 0.5;
    markerArea.addRenderEventListener((imgURL) => {
      document.getElementById('body-outline').src = imgURL;
      updateIncOffensesDVSuspectForm({
        ...offensesDVSuspectFormState,
        values: {
          ...offensesDVSuspectFormState.values,
          File: imgURL,
        },
      });

      setOffensesDVSuspectFormState({
        ...offensesDVSuspectFormState,
        values: {
          ...offensesDVSuspectFormState.values,
          File: imgURL,
        },
      });
    });

    markerArea.show();
  };

  const getIncFormData = () => {
    let selectedSuspectDetails = {};

    selectedSuspectDetails = dvSuspects.find(
      (x) => x.incSuspectDetails.ptsIncPersonId === selectedSuspect
    );

    if (selectedSuspectDetails && selectedSuspectDetails.incSuspectDetails) {
      setOffensesDVSuspectFormState(selectedSuspectDetails.incSuspectDetails);
    }
  };

  React.useEffect(() => {
    let gender = parties.find((party) => party.ptsIncPersonId === selectedSuspect)?.personDetail
      .values.sex;
    if (gender) {
      let g = gender.toLowerCase();
      if (g === 'm' || g === 'male') {
        gender = gender.toLowerCase() === 'm' ? 'male' : 'male';
      } else {
        gender = 'female';
      }
    } else {
      gender = 'male';
    }
    setVictimGender(gender);

    gender === 'male'
      ? setOffensesDVSuspectFormState({
          ...offensesDVSuspectFormState,
          values: {
            ...offensesDVSuspectFormState.values,
            File: offensesDVSuspectFormState.values.File || maleSuspectCondition,
          },
        })
      : setOffensesDVSuspectFormState({
          ...offensesDVSuspectFormState,
          values: {
            ...offensesDVSuspectFormState.values,
            File: offensesDVSuspectFormState.values.File || femaleSuspectCondition,
          },
        });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedSuspect]);

  React.useEffect(() => {
    if (props.updated !== null) updateIncDetailsList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.updated]);

  React.useEffect(() => {
    getIncFormData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedSuspect, dvSuspects]);

  return (
    <PerfectScrollbar className={classes.scrollBar}>
      <Grid container justify="center" className="pr-2 pb-2">
        <Grid xs={12}>
          <span
            style={{ color: '#fff', background: '#1976d2' }}
            className="mb-1 badge badge-primary">
            Mental
          </span>
          <Divider />
        </Grid>
        <Grid item xs={12} lg-={12}>
          <FormControl size="small" className={classes.formControl}>
            <Grid>
              {selections.map((selection) => (
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={offensesDVSuspectFormState.values[selection.key] || false}
                      name={selection.key}
                      onChange={(event) =>
                        handleFormChangeNew(
                          event,
                          event.target.checked,
                          'none',
                          selection.key,
                          setOffensesDVSuspectFormState
                        )
                      }
                    />
                  }
                  label={selection.value}
                />
              ))}
            </Grid>
          </FormControl>
        </Grid>

        <Grid xs={12}>
          <span
            style={{ color: '#fff', background: '#1976d2' }}
            className="mb-1 badge badge-primary">
            Physical
          </span>
          <Divider />
        </Grid>
        <Grid item xs={12} lg-={12}>
          <FormControl size="small" className={classes.formControl}>
            <Grid>
              {physicalSelections.map((selection) => (
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={offensesDVSuspectFormState.values[selection.key] || false}
                      name={selection.key}
                      onChange={(event) =>
                        handleFormChangeNew(
                          event,
                          event.target.checked,
                          'none',
                          selection.key,
                          setOffensesDVSuspectFormState
                        )
                      }
                    />
                  }
                  label={selection.value}
                />
              ))}
            </Grid>
          </FormControl>
        </Grid>

        <Grid xs={12}>
          <span
            style={{ color: '#fff', background: '#1976d2' }}
            className="mb-1 badge badge-primary">
            Relationship
          </span>
          <Divider />
        </Grid>
        <Grid item xs={12} lg-={12}>
          <FormControl size="small" className={classes.formControl}>
            <Grid>
              {relationSelections.map((selection) => (
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={offensesDVSuspectFormState.values[selection.key] || false}
                      name={selection.key}
                      onChange={(event) =>
                        handleFormChangeNew(
                          event,
                          event.target.checked,
                          'none',
                          selection.key,
                          setOffensesDVSuspectFormState
                        )
                      }
                    />
                  }
                  label={selection.value}
                />
              ))}
            </Grid>
          </FormControl>
        </Grid>

        <Grid container justify="center" spacing={2} className="mb-2">
          <Grid item xs={6} lg={6}>
            <TextField
              autoComplete="hidden"
              size="small"
              id="standard-textarea-relationship-years"
              label="Length of Relationship - Years"
              placeholder="Years"
              variant="outlined"
              name="LengthOfRelationshipYears"
              defaultValue={offensesDVSuspectFormState.values?.LengthOfRelationshipYears || ''}
              onBlur={(event) =>
                handleFormChangeNew(
                  event,
                  event.target.value,
                  'none',
                  'LengthOfRelationshipYears',
                  setOffensesDVSuspectFormState
                )
              }
              fullWidth
              multiline
              InputLabelProps={{ shrink: true }}
            />
          </Grid>

          <Grid item xs={6} lg={6}>
            <TextField
              id="standard-textarea-suspects-LengthOfRelationshipMonths"
              autoComplete="hidden"
              size="small"
              label="Length of Relationship - Months"
              placeholder="Months"
              variant="outlined"
              name="LengthOfRelationshipMonths"
              defaultValue={offensesDVSuspectFormState.values?.LengthOfRelationshipMonths || ''}
              onBlur={(event) =>
                handleFormChangeNew(
                  event,
                  event.target.value,
                  'none',
                  'LengthOfRelationshipMonths',
                  setOffensesDVSuspectFormState
                )
              }
              fullWidth
              multiline
              InputLabelProps={{ shrink: true }}
            />
          </Grid>
        </Grid>
        <Grid item xs={12} lg={12}>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <KeyboardDatePicker
              size="small"
              inputVariant="outlined"
              fullWidth
              showTodayButton={true}
              variant="dialog"
              format="MM/dd/yyyy"
              id="date-picker-inline-from"
              label="Date Ended - If Applicable"
              value={
                offensesDVSuspectFormState.values.EndedDate
                  ? offensesDVSuspectFormState.values.EndedDate
                  : null
              }
              onChange={(date) => {
                setOffensesDVSuspectFormState((state) => {
                  return {
                    ...state,
                    values: {
                      ...state.values,
                      EndedDate: date,
                    },
                  };
                });
              }}
              KeyboardButtonProps={{
                'aria-label': 'change date',
              }}
            />
          </MuiPickersUtilsProvider>
        </Grid>

        <Grid xs={12} className="mb-3 mt-2">
          <span
            style={{ color: '#fff', background: '#1976d2' }}
            className="mb-1 badge badge-primary">
            Language
          </span>
          <Divider />
        </Grid>
        <Grid item>
          <RMSRadio
            size="small"
            radioGroup={radioGroupLanguage}
            selectedValue={offensesDVSuspectFormState.values.language}
            handleFormChange={(event) =>
              handleFormChangeNew(
                event,
                event.target.value,
                'none',
                'language',
                setOffensesDVSuspectFormState
              )
            }
          />
        </Grid>

        <Grid xs={12}>
          <span
            style={{ color: '#fff', background: '#1976d2' }}
            className="mb-1 badge badge-primary">
            Prior History
          </span>
          <Divider />
        </Grid>
        <FormControl size="small" className={classes.formControl}>
          <Grid>
            {priorSelections.map((selection) => (
              <FormControlLabel
                control={
                  <Checkbox
                    checked={offensesDVSuspectFormState.values[selection.key] || false}
                    name={selection.key}
                    onChange={(event) =>
                      handleFormChangeNew(
                        event,
                        event.target.checked,
                        'none',
                        selection.key,
                        setOffensesDVSuspectFormState
                      )
                    }
                  />
                }
                label={selection.value}
              />
            ))}
          </Grid>
        </FormControl>
        <Grid item xs={12} lg={12}>
          <TextField
            autoComplete="hidden"
            size="small"
            className="mb-3"
            id="standard-textarea-number-of-incidents"
            label="# of Incidents"
            placeholder="# of Incidents"
            name="NumberOfIncidents"
            defaultValue={offensesDVSuspectFormState.values?.NumberOfIncidents || ''}
            onBlur={(event) =>
              handleFormChangeNew(
                event,
                event.target.value,
                'none',
                'NumberOfIncidents',
                setOffensesDVSuspectFormState
              )
            }
            variant="outlined"
            fullWidth
            multiline
            InputLabelProps={{ shrink: true }}
          />
        </Grid>

        <Grid xs={12}>
          <span
            style={{ color: '#fff', background: '#1976d2' }}
            className="mb-1 badge badge-primary">
            Others
          </span>
          <Divider />
        </Grid>
        <Grid item>
          <FormControlLabel
            control={
              <Checkbox
                key={offensesDVSuspectFormState.values?.IsMentallyChallenged}
                checked={offensesDVSuspectFormState.values?.IsMentallyChallenged}
                onChange={(event) =>
                  handleFormChangeNew(
                    event,
                    event.target.checked,
                    'none',
                    'IsMentallyChallenged',
                    setOffensesDVSuspectFormState
                  )
                }
                name="IsMentallyChallenged"
              />
            }
            label="Mentally/Emotionally Challenged"
          />

          <FormControlLabel
            control={
              <Checkbox
                key={offensesDVSuspectFormState.values?.IsPhysicallyChallenged}
                checked={offensesDVSuspectFormState.values?.IsPhysicallyChallenged}
                onChange={(event) =>
                  handleFormChangeNew(
                    event,
                    event.target.checked,
                    'none',
                    'IsPhysicallyChallenged',
                    setOffensesDVSuspectFormState
                  )
                }
                name="IsPhysicallyChallenged"
              />
            }
            label="Physically/Medically Challenged"
          />
        </Grid>
        <Grid item xs={12} lg={12}>
          <TextField
            autoComplete="hidden"
            size="small"
            className="mt-3"
            id="outlined-multiline-static-address-notes"
            label="Comments"
            placeholder="Comments"
            rows="4"
            fullWidth
            name="Comments"
            value={offensesDVSuspectFormState.values?.Comments || ''}
            onChange={(event) =>
              handleFormChangeNew(
                event,
                event.target.value,
                'none',
                'Comments',
                setOffensesDVSuspectFormState
              )
            }
            variant="outlined"
            multiline
            InputLabelProps={{ shrink: true }}
          />
        </Grid>
        <Grid id="image-root" item xs={12} lg={12} className="mt-4">
          <Paper className="p-5">
            <Typography variant="h4" className="mb-4">
              Suspect Conditions Memo
            </Typography>
            {victimGender === 'female' && (
              <Tooltip title="Click the image to make annotations" placement="right">
                <img
                  style={{ width: '100%' }}
                  id="body-outline"
                  className={classes.bodyLine}
                  onClick={handleClickImg}
                  src={offensesDVSuspectFormState.values?.File || femaleSuspectCondition}
                  alt="female-body-outline"
                />
              </Tooltip>
            )}
            {victimGender === 'male' && (
              <Tooltip title="Click the image to make annotations" placement="right">
                <img
                  style={{ width: '100%' }}
                  id="body-outline"
                  className={classes.bodyLine}
                  onClick={handleClickImg}
                  src={offensesDVSuspectFormState.values?.File || maleSuspectCondition}
                  alt="male-body-outline"
                />
              </Tooltip>
            )}
          </Paper>
        </Grid>
      </Grid>
    </PerfectScrollbar>
  );
};

const mapStateToProps = (state) => ({
  offensesDVSuspectForm: state.incident.offensesDVSuspectForm,
  maleSuspectCondition: state.uiImage.dvMaleSuspectConditionState,
  femaleSuspectCondition: state.uiImage.dvFemaleSuspectConditionState,
  dvSuspects: state.incident.dvSuspects,
  selectedSuspect: state.incident.selectedSuspect,
  parties: state.incident.parties,
});

export default connect(mapStateToProps, {
  updateIncOffensesDVSuspectForm,
  updateIncSuspectDetails,
})(SuspectsForm);
