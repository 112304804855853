export const GET_CODE_VICTIM_TREATMENT = 'GET_CODE_VICTIM_TREATMENT';
export const ADD_CODE_VICTIM_TREATMENT = 'ADD_CODE_VICTIM_TREATMENT';

export const addCodeVictimTreatment = (newValue) => {
  return { type: ADD_CODE_VICTIM_TREATMENT, payload: newValue };
};

export const getCodeVictimTreatment = (state) => {
  return async (dispatch) => {
    return dispatch({ type: GET_CODE_VICTIM_TREATMENT, payload: state });
  };
};

export default function reducer(state = [], action) {
  switch (action.type) {
    case GET_CODE_VICTIM_TREATMENT:
      state = action.payload;
      return state;
    case ADD_CODE_VICTIM_TREATMENT:
      return [...state, action.payload];
    default:
      return state;
  }
}
