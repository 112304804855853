import React from 'react';
import BlurOnIcon from '@material-ui/icons/BlurOn';
import RMSCollapsibleList from '../index';
import {
  MedicalForm,
  MentalForm,
  PhysicalForm,
  SpecialNeedsForm,
  TypeOffenseForm,
  TypeVictimForm,
  VictimHistoryForm,
} from '../../../RMSForms/RMSDomesticViolenceForm/VictimForm';
import GeographicForm from '../../../RMSForms/RMSDomesticViolenceForm/VictimForm/GeographicForm';

const panelItems = [
  {
    name: 'panel1',
    title: 'Mental',
    details: <MentalForm />,
    icon: <BlurOnIcon />,
  },
  {
    name: 'panel2',
    title: 'Physical',
    details: <PhysicalForm />,
    icon: <BlurOnIcon />,
  },
  {
    name: 'panel3',
    title: 'Medical',
    details: <MedicalForm />,
    icon: <BlurOnIcon />,
  },
  {
    name: 'panel4',
    title: 'Type Victim',
    details: <TypeVictimForm />,
    icon: <BlurOnIcon />,
  },
  {
    name: 'panel5',
    title: 'Type Offense(s)',
    details: <TypeOffenseForm />,
    icon: <BlurOnIcon />,
  },
  {
    name: 'panel6',
    title: 'Geographic',
    details: <GeographicForm />,
    icon: <BlurOnIcon />,
  },
  {
    name: 'panel7',
    title: 'Special Needs',
    details: <SpecialNeedsForm />,
    icon: <BlurOnIcon />,
  },
  {
    name: 'panel8',
    title: 'History - Sexual Assault(s)',
    details: <VictimHistoryForm />,
    icon: <BlurOnIcon />,
  },
];

const DvVictimsPanels = () => {
  return <RMSCollapsibleList panelItems={panelItems} />;
};

export default DvVictimsPanels;
