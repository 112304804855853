import React from 'react';
import { connect } from 'react-redux';
import {
  getCodeBloodType,
  getCodeEthnicity,
  getCodeHanded,
  getCodeEyeColor,
  getCodeCADStatuses,
  getCodeCities,
  getCodeCoordinateDatumTypes,
  getCodeDEADrugClasses,
  getCodeDNACollection,
  getCodeDrugMeasurements,
  getCodeJewelryCateogory,
  getCodeJuvenileDisposition,
} from '../reducers/dictionaries/CodeOthersReducer';
import {
  getCodeAddressBldgTypes,
  getCodeAddressUnitTypes,
  getCodeAddressFloorTypes,
} from '../reducers/dictionaries/CodeAddressReducer';
import {
  getCodeFirearmCaliber,
  getCodeFirearmCategory,
} from '../reducers/dictionaries/CodeFirearmReducer';
import {
  getCodeIncidentTypes,
  getCodeIncidentCategories,
} from '../reducers/dictionaries/CodeIncidentReducer';
import {
  getCodeHairColor,
  getCodeHairLength,
  getCodeHairStyle,
} from '../reducers/dictionaries/CodeHairReducer';
import {
  getCodeItemConditions,
  getCodeItemMakes,
  getCodeItemModels,
} from '../reducers/dictionaries/CodeItemReducer';
import {
  getCodeGarageFacilities,
  getCodeGarageTypes,
} from '../reducers/dictionaries/CodeGarageReducer';
import {
  getCodeArresteeArmed,
  getCodeArrestCategories,
} from '../reducers/dictionaries/CodeArrestReducer';
import {
  getCodeEvidenceCategory,
  getCodeEvidenceCustodyReason,
  getCodeEvidenceCustodyStatus,
  getCodeEvidenceDisposalCode,
  getCodeEvidenceFinalDisposition,
  getCodeEvidenceRequiredAction,
  getCodeEvidenceStorageContainer,
  getCodeEvidenceStorageLocation,
  getCodeEvidenceStorageSubLocation,
  getCodeEvidenceTemporaryLocation,
  getCodeEvidenceType,
} from '../reducers/dictionaries/CodeEvidenceReducer';

const PageMain = (props) => {
  const {
    getCodeBloodType,
    getCodeEthnicity,
    getCodeHanded,
    getCodeEyeColor,
    getCodeCADStatuses,
    getCodeCities,
    getCodeCoordinateDatumTypes,
    getCodeDEADrugClasses,
    getCodeDNACollection,
    getCodeDrugMeasurements,
    getCodeJewelryCateogory,
    getCodeJuvenileDisposition,
    getCodeEvidenceCategory,
    getCodeEvidenceCustodyReason,
    getCodeEvidenceCustodyStatus,
    getCodeEvidenceDisposalCode,
    getCodeEvidenceFinalDisposition,
    getCodeEvidenceRequiredAction,
    getCodeEvidenceStorageContainer,
    getCodeEvidenceStorageLocation,
    getCodeEvidenceStorageSubLocation,
    getCodeEvidenceTemporaryLocation,
    getCodeEvidenceType,
    getCodeArresteeArmed,
    getCodeArrestCategories,
    getCodeGarageFacilities,
    getCodeGarageTypes,
    getCodeItemConditions,
    getCodeItemMakes,
    getCodeItemModels,
    getCodeHairColor,
    getCodeHairLength,
    getCodeHairStyle,
    getCodeIncidentTypes,
    getCodeIncidentCategories,
    getCodeFirearmCaliber,
    getCodeFirearmCategory,
    getCodeAddressBldgTypes,
    getCodeAddressUnitTypes,
    getCodeAddressFloorTypes,
    wsClient,
  } = props;

  React.useEffect(() => {
    if (wsClient.websocket) {
      getCodeBloodType();
      getCodeEthnicity();
      getCodeHanded();
      getCodeEyeColor();
      getCodeBloodType();
      getCodeEthnicity();
      getCodeHanded();
      getCodeEyeColor();
      getCodeCADStatuses();
      getCodeCities();
      getCodeCoordinateDatumTypes();
      getCodeDEADrugClasses();
      getCodeDNACollection();
      getCodeDrugMeasurements();
      getCodeJewelryCateogory();
      getCodeJuvenileDisposition();
      getCodeEvidenceCategory();
      getCodeEvidenceCustodyReason();
      getCodeEvidenceCustodyStatus();
      getCodeEvidenceDisposalCode();
      getCodeEvidenceFinalDisposition();
      getCodeEvidenceRequiredAction();
      getCodeEvidenceStorageContainer();
      getCodeEvidenceStorageLocation();
      getCodeEvidenceStorageSubLocation();
      getCodeEvidenceTemporaryLocation();
      getCodeEvidenceType();
      getCodeArresteeArmed();
      getCodeArrestCategories();
      getCodeGarageFacilities();
      getCodeGarageTypes();
      getCodeItemConditions();
      getCodeItemMakes();
      getCodeItemModels();
      getCodeHairColor();
      getCodeHairLength();
      getCodeHairStyle();
      getCodeIncidentTypes();
      getCodeIncidentCategories();
      getCodeFirearmCaliber();
      getCodeFirearmCategory();
      getCodeAddressBldgTypes();
      getCodeAddressUnitTypes();
      getCodeAddressFloorTypes();
    }
  }, [
    getCodeBloodType,
    getCodeEthnicity,
    getCodeHanded,
    getCodeEyeColor,
    getCodeCADStatuses,
    getCodeCities,
    getCodeCoordinateDatumTypes,
    getCodeDEADrugClasses,
    getCodeDNACollection,
    getCodeDrugMeasurements,
    getCodeJewelryCateogory,
    getCodeJuvenileDisposition,
    getCodeEvidenceCategory,
    getCodeEvidenceCustodyReason,
    getCodeEvidenceCustodyStatus,
    getCodeEvidenceDisposalCode,
    getCodeEvidenceFinalDisposition,
    getCodeEvidenceRequiredAction,
    getCodeEvidenceStorageContainer,
    getCodeEvidenceStorageLocation,
    getCodeEvidenceStorageSubLocation,
    getCodeEvidenceTemporaryLocation,
    getCodeEvidenceType,
    getCodeArresteeArmed,
    getCodeArrestCategories,
    getCodeGarageFacilities,
    getCodeGarageTypes,
    getCodeItemConditions,
    getCodeItemMakes,
    getCodeItemModels,
    getCodeHairColor,
    getCodeHairLength,
    getCodeHairStyle,
    getCodeIncidentTypes,
    getCodeIncidentCategories,
    getCodeFirearmCaliber,
    getCodeFirearmCategory,
    getCodeAddressBldgTypes,
    getCodeAddressUnitTypes,
    getCodeAddressFloorTypes,
    wsClient,
  ]);

  return <h1>This is main page</h1>;
};

const mapStateToProps = (state) => ({
  wsClient: state.websocket,
});

export default connect(mapStateToProps, {
  getCodeBloodType,
  getCodeEthnicity,
  getCodeHanded,
  getCodeEyeColor,
  getCodeCADStatuses,
  getCodeCities,
  getCodeCoordinateDatumTypes,
  getCodeDEADrugClasses,
  getCodeDNACollection,
  getCodeDrugMeasurements,
  getCodeJewelryCateogory,
  getCodeJuvenileDisposition,
  getCodeEvidenceCategory,
  getCodeEvidenceCustodyReason,
  getCodeEvidenceCustodyStatus,
  getCodeEvidenceDisposalCode,
  getCodeEvidenceFinalDisposition,
  getCodeEvidenceRequiredAction,
  getCodeEvidenceStorageContainer,
  getCodeEvidenceStorageLocation,
  getCodeEvidenceStorageSubLocation,
  getCodeEvidenceTemporaryLocation,
  getCodeEvidenceType,
  getCodeArresteeArmed,
  getCodeArrestCategories,
  getCodeGarageFacilities,
  getCodeGarageTypes,
  getCodeItemConditions,
  getCodeItemMakes,
  getCodeItemModels,
  getCodeHairColor,
  getCodeHairLength,
  getCodeHairStyle,
  getCodeIncidentTypes,
  getCodeIncidentCategories,
  getCodeFirearmCaliber,
  getCodeFirearmCategory,
  getCodeAddressBldgTypes,
  getCodeAddressUnitTypes,
  getCodeAddressFloorTypes,
})(PageMain);
