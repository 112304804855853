import React from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router';

import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Divider from '@material-ui/core/Divider';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import makeStyles from '@material-ui/core/styles/makeStyles';

import { updateIncAddressIdentifiersForm } from '../../reducers/IncReducer';
import {
  getCodeAddressBldgTypes,
  getCodeAddressUnitTypes,
  getCodeAddressFloorTypes,
} from '../../reducers/dictionaries/CodeAddressReducer';

import { handleFormChangeNew } from '../../reducers/helpers/formHelpers';
import RMSAutoComplete from 'components/RMSAutoComplete/RMSAutoComplete';

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
  },
}));

const TabIdentifiers = (props) => {
  /** region Component Variables and Props */
  const classes = useStyles();
  const history = useHistory();
  const {
    addressIdForm,
    updateIncAddressIdentifiersForm,
    codeAddressBldgTypes,
    codeAddressFloorTypes,
    codeAddressUnitTypes,
    getCodeAddressBldgTypes,
    getCodeAddressUnitTypes,
    getCodeAddressFloorTypes,
    wsClient,
    recordActive,
  } = props;
  const [changeKey, setChangeKey] = React.useState(0);
  const [addressIdFormState, setAddressIdFormState] = React.useState({
    isValid: false,
    values: {
      isEventLookup: true
    },
    touched: {},
    errors: {},
  });
  /** endregion */
  /** region React Hooks */
  React.useEffect(() => {
    if (wsClient.websocket) {
      getCodeAddressBldgTypes();
      getCodeAddressUnitTypes();
      getCodeAddressFloorTypes();
    }
  }, [getCodeAddressBldgTypes, getCodeAddressUnitTypes, getCodeAddressFloorTypes, wsClient]);

  React.useEffect(() => {
    if (Object.keys(addressIdForm.values).length === 0) {
      return;
    }

    setAddressIdFormState((addressIdFormState) => ({
      ...addressIdFormState,
      ...(addressIdFormState.values.isEventLookup = addressIdForm.values.isEventLookup),
      ...(addressIdFormState.values.isSexOffender = addressIdForm.values.isSexOffender),
      ...(addressIdFormState.values.isParolee = addressIdForm.values.isParolee),
      ...(addressIdFormState.values.isProbation = addressIdForm.values.isProbation),
      ...(addressIdFormState.values.isNonValidated = addressIdForm.values.isNonValidated),
      ...(addressIdFormState.values.isCampusResidential = addressIdForm.values.isCampusResidential),
      ...(addressIdFormState.values.isCampusNonResidential =
        addressIdForm.values.isCampusNonResidential),
      ...(addressIdFormState.values.isNonCampus = addressIdForm.values.isNonCampus),
      ...(addressIdFormState.values.isPublic = addressIdForm.values.isPublic),
      ...(addressIdFormState.values.unitType = addressIdForm.values.unitType),
      ...(addressIdFormState.values.unitTypeIdentifier = addressIdForm.values.unitTypeIdentifier),
      ...(addressIdFormState.values.floorType = addressIdForm.values.floorType),
      ...(addressIdFormState.values.floorIdentifier = addressIdForm.values.floorIdentifier),
      ...(addressIdFormState.values.buildingType = addressIdForm.values.buildingType),
      ...(addressIdFormState.values.buildingIdentifier = addressIdForm.values.buildingIdentifier),
    }));
  }, [addressIdForm]);

  React.useEffect(() => {
    if (Object.keys(addressIdFormState.values).length === 0) {
      return;
    }
    updateIncAddressIdentifiersForm(addressIdFormState);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    addressIdFormState.values.isEventLookup,
    addressIdFormState.values.isSexOffender,
    addressIdFormState.values.isParolee,
    addressIdFormState.values.isProbation,
    addressIdFormState.values.isNonValidated,
    addressIdFormState.values.isCampusResidential,
    addressIdFormState.values.isCampusNonResidential,
    addressIdFormState.values.isNonCampus,
    addressIdFormState.values.isPublic,
    addressIdFormState.values.unitType,
    addressIdFormState.values.unitTypeIdentifier,
    addressIdFormState.values.floorType,
    addressIdFormState.values.floorIdentifier,
    addressIdFormState.values.buildingType,
    addressIdFormState.values.buildingIdentifier,
  ]);
  /** endregion */
  return (
    <Grid container className="p-3">
      <Grid container xs={12} lg={12}>
        <Grid item xs={12}>
          <span
            style={{ color: '#fff', background: '#1976d2' }}
            className="m-1 badge badge-primary">
            Identifiers
          </span>
          <Divider />
        </Grid>
        <Grid container spacing={2} className="mt-2">
          <Grid item xs={4}>
            <RMSAutoComplete
              size="small"
              autoComplete
              autoSelect
              autoHighlight
              id="combo-unit-type"
              options={codeAddressUnitTypes}
              label="Unit Type"
              serviceName="codeaddressunittypes"
              title="Code"
              stateHolderAttribute="unitType"
              variant="outlined"
              stateHolder={addressIdFormState}
              setStateHolder={setAddressIdFormState}
              setField={handleFormChangeNew}
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              id="standard-textarea-address-identifiers-unit"
              autoComplete="hidden"
              size="small"
              label="Unit Identifier"
              placeholder="Unit Identifier"
              variant="outlined"
              fullWidth
              name="unitTypeIdentifier"
              key={changeKey}
              defaultValue={addressIdFormState.values.unitTypeIdentifier}
              onBlur={(event) =>
                handleFormChangeNew(
                  event,
                  event.target.value,
                  'none',
                  'unitTypeIdentifier',
                  setAddressIdFormState
                )
              }
              multiline
              InputLabelProps={{ shrink: true }}
            />
          </Grid>
          <Grid item xs={4}>
            <RMSAutoComplete
              size="small"
              autoComplete
              autoSelect
              autoHighlight
              id="combo-floor-type"
              options={codeAddressFloorTypes}
              label="Floor Type"
              serviceName="codeaddressfloortypes"
              title="Code"
              stateHolderAttribute="floorType"
              variant="outlined"
              stateHolder={addressIdFormState}
              setStateHolder={setAddressIdFormState}
              setField={handleFormChangeNew}
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              id="standard-textarea-address-identifiers-unit"
              autoComplete="hidden"
              size="small"
              label="Floor Identifier"
              placeholder="Floor Identifier"
              variant="outlined"
              fullWidth
              name="floorIdentifier"
              key={changeKey}
              defaultValue={addressIdFormState.values.floorIdentifier}
              onBlur={(event) =>
                handleFormChangeNew(
                  event,
                  event.target.value,
                  'none',
                  'floorIdentifier',
                  setAddressIdFormState
                )
              }
              multiline
              InputLabelProps={{ shrink: true }}
            />
          </Grid>
          <Grid item xs={4}>
            <RMSAutoComplete
              size="small"
              autoComplete
              autoSelect
              autoHighlight
              id="combo-building-type"
              options={codeAddressBldgTypes}
              label="Building Type"
              serviceName="codeaddressbldgtypes"
              title="Description"
              stateHolderAttribute="buildingType"
              variant="outlined"
              stateHolder={addressIdFormState}
              setStateHolder={setAddressIdFormState}
              setField={handleFormChangeNew}
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              id="standard-textarea-address-identifiers-building"
              autoComplete="hidden"
              size="small"
              label="Building Identifier"
              placeholder="Building Identifier"
              variant="outlined"
              fullWidth
              name="buildingIdentifier"
              key={changeKey}
              defaultValue={addressIdFormState.values.buildingIdentifier}
              onBlur={(event) =>
                handleFormChangeNew(
                  event,
                  event.target.value,
                  'none',
                  'buildingIdentifier',
                  setAddressIdFormState
                )
              }
              multiline
              InputLabelProps={{ shrink: true }}
            />
          </Grid>
        </Grid>
        <Grid container spacing={2} className="mt-2">
          <Grid item xs={6}>
            <span
              style={{ color: '#fff', background: '#1976d2' }}
              className="m-1 badge badge-primary">
              Flags
            </span>
            <Divider />
          </Grid>
          <Grid item xs={6}>
            <span
              style={{ color: '#fff', background: '#1976d2' }}
              className="m-1 badge badge-primary">
              CLERY Location
            </span>
            <Divider />
          </Grid>
          <Grid container>
            <Grid item xs={6}>
              <FormControl size="small" component="fieldset" className={classes.formControl}>
                <FormGroup>
                  {recordActive && (
                    <FormControlLabel
                      control={
                        <Checkbox
                          key={addressIdFormState.values.isEventLookup}
                          checked={addressIdFormState.values.isEventLookup}
                          onChange={(event) =>
                            handleFormChangeNew(
                              event,
                              event.target.checked,
                              'none',
                              'isEventLookup',
                              setAddressIdFormState
                            )
                          }
                          name="isEventLookup"
                        />
                      }
                      label="Show in Event Lookup"
                    />
                  )}
                  <FormControlLabel
                    control={
                      <Checkbox
                        key={addressIdFormState.values.isSexOffender}
                        checked={addressIdFormState.values.isSexOffender}
                        onChange={(event) =>
                          handleFormChangeNew(
                            event,
                            event.target.checked,
                            'none',
                            'isSexOffender',
                            setAddressIdFormState
                          )
                        }
                        name="isSexOffender"
                      />
                    }
                    label="Sex Offender"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        key={addressIdFormState.values.isParolee}
                        checked={addressIdFormState.values.isParolee}
                        onChange={(event) =>
                          handleFormChangeNew(
                            event,
                            event.target.checked,
                            'none',
                            'isParolee',
                            setAddressIdFormState
                          )
                        }
                        name="isParolee"
                      />
                    }
                    label="Parolee"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        key={addressIdFormState.values.isProbation}
                        checked={addressIdFormState.values.isProbation}
                        onChange={(event) =>
                          handleFormChangeNew(
                            event,
                            event.target.checked,
                            'none',
                            'isProbation',
                            setAddressIdFormState
                          )
                        }
                        name="isProbation"
                      />
                    }
                    label="Probation"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        key={addressIdFormState.values.isNonValidated}
                        checked={addressIdFormState.values.isNonValidated}
                        onChange={(event) =>
                          handleFormChangeNew(
                            event,
                            event.target.checked,
                            'none',
                            'isNonValidated',
                            setAddressIdFormState
                          )
                        }
                        name="isNonValidated"
                      />
                    }
                    label="Non-Validated Address"
                  />
                </FormGroup>
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <FormControl size="small" component="fieldset" className={classes.formControl}>
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Checkbox
                        key={addressIdFormState.values.isCampusResidential}
                        checked={addressIdFormState.values.isCampusResidential}
                        onChange={(event) =>
                          handleFormChangeNew(
                            event,
                            event.target.checked,
                            'none',
                            'isCampusResidential',
                            setAddressIdFormState
                          )
                        }
                        name="isCampusResidential"
                      />
                    }
                    label="Campus Residential"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        key={addressIdFormState.values.isCampusNonResidential}
                        checked={addressIdFormState.values.isCampusNonResidential}
                        onChange={(event) =>
                          handleFormChangeNew(
                            event,
                            event.target.checked,
                            'none',
                            'isCampusNonResidential',
                            setAddressIdFormState
                          )
                        }
                        name="isCampusNonResidential"
                      />
                    }
                    label="Campus Non-Residential"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        key={addressIdFormState.values.isNonCampus}
                        checked={addressIdFormState.values.isNonCampus}
                        onChange={(event) =>
                          handleFormChangeNew(
                            event,
                            event.target.checked,
                            'none',
                            'isNonCampus',
                            setAddressIdFormState
                          )
                        }
                        name="isNonCampus"
                      />
                    }
                    label="Non-Campus"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        key={addressIdFormState.values.isPublic}
                        checked={addressIdFormState.values.isPublic}
                        onChange={(event) =>
                          handleFormChangeNew(
                            event,
                            event.target.checked,
                            'none',
                            'isPublic',
                            setAddressIdFormState
                          )
                        }
                        name="isPublic"
                      />
                    }
                    label="Public"
                  />
                </FormGroup>
              </FormControl>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

const mapStateToProps = (state) => ({
  addressIdForm: state.incident.addressIdForm,
  wsClient: state.websocket,
  recordActive: state.records.recordActive,
  codeAddressBldgTypes: state.codesAddress.codeAddressBldgTypes,
  codeAddressUnitTypes: state.codesAddress.codeAddressUnitTypes,
  codeAddressFloorTypes: state.codesAddress.codeAddressFloorTypes,
});

export default connect(mapStateToProps, {
  updateIncAddressIdentifiersForm,
  getCodeAddressBldgTypes,
  getCodeAddressUnitTypes,
  getCodeAddressFloorTypes,
})(TabIdentifiers);
