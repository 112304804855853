export const GET_CODE_VEHICLE_STYLE = 'GET_CODE_VEHICLE_STYLE';
export const ADD_CODE_VEHICLE_STYLE = 'ADD_CODE_VEHICLE_STYLE';

export const addCodeVehicleStyle = (newVal) => {
  return { type: ADD_CODE_VEHICLE_STYLE, payload: newVal };
};

export const getCodeVehicleStyle = (state) => {
  return async (dispatch) => {
    return dispatch({ type: GET_CODE_VEHICLE_STYLE, payload: state });
  };
};

export default function reducer(state = [], action) {
  switch (action.type) {
    case GET_CODE_VEHICLE_STYLE:
      state = action.payload;
      return state;
    case ADD_CODE_VEHICLE_STYLE:
      return [...state, action.payload];
    default:
      return state;
  }
}
