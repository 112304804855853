/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { connect } from 'react-redux';

import { v4 as uuidv4 } from 'uuid';
import DateFnsUtils from '@date-io/date-fns';
import Grid from '@material-ui/core/Grid';
import { KeyboardDateTimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';

import { updateIncDispositionForm } from '../../reducers/IncReducer';
import { TextField } from '@material-ui/core';
import { handleFormChangeNew } from 'reducers/helpers/formHelpers';
import { Autocomplete } from '@material-ui/lab';

const TabDispositions = (props) => {
  /** region Component Variables and Props */
  const { updateIncDispositionForm, dispositionForm, wsClient, dispositions } = props;
  const [changeKey, setChangeKey] = React.useState(0);
  const [selectedFiledDate, setSelectedFiledDate] = React.useState();
  const [dispositionFormState, setDispositionFormState] = React.useState({
    tempPropId: uuidv4(),
    isValid: false,
    changes: false,
    values: {},
    touched: {},
    errors: {},
    formType: 'edit',
  });
  const [dispositionsList, setDispositionsList] = React.useState([]);
  /** endregion */
  /** region React Hooks */
  React.useEffect(() => {
    setDispositionFormState((dispositionFormState) => ({
      ...dispositionFormState,
      ...dispositionForm,
      values: {
        ...dispositionFormState.values,
        ...dispositionForm.values,
      },
    }));
  }, [dispositionForm]);

  React.useEffect(() => {
    dispositionFormState.changes = true;
    updateIncDispositionForm(dispositionFormState);
  }, [dispositionFormState.values?.disposition, selectedFiledDate]);

  React.useEffect(async () => {
    if (!wsClient?.websocket) {
      return;
    }
    const service = wsClient?.websocket.service('codedispositions');
    service.timeout = 200000;
    const result = await service.find({
      query: {
        AgencyID: 'POLICEDEP',
        IsDeleted: 0,
        $select: ['Code', 'Description'],
      },
    });
    let filteredDispositions = [];

    result.data.forEach((f) => {
      let found = false;
      dispositions.forEach((z) => {
        if (f.Code === z.values.disposition) found = true;
      });
      if (!found) filteredDispositions.push(f);
    });

    setDispositionsList(filteredDispositions);
  }, []);
  /** endregion */
  return (
    <Grid container spacing={2} className="mb-3 mt-1">
      <Grid item xs={6} lg={6}>
        <Autocomplete
          size="small"
          autoComplete
          autoSelect
          autoHighlight
          id="combo-street-type"
          getOptionLabel={(option) => option || ''}
          options={dispositionsList.map((s) => s.Code)}
          key={dispositionFormState.values.disposition || ''}
          defaultValue={dispositionFormState.values.disposition || ''}
          onChange={(event, newValue) =>
            handleFormChangeNew(event, newValue, 'none', 'disposition', setDispositionFormState)
          }
          renderInput={(params) => (
            <TextField
              autoComplete="hidden"
              size="small"
              {...params}
              label="Type"
              variant="outlined"
              fullWidth
            />
          )}
        />
      </Grid>
      <Grid item xs={6} lg={6} style={{ marginTop: '-16px' }}>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <KeyboardDateTimePicker
            size="small"
            inputVariant="outlined"
            fullWidth
            showTodayButton={true}
            variant="dialog"
            format="MM/dd/yyyy hh:mm a"
            margin="normal"
            id="date-picker-inline-from"
            label="Filed"
            key={changeKey}
            value={selectedFiledDate ? selectedFiledDate : null}
            onChange={(date) => {
              setSelectedFiledDate(date);
              setDispositionFormState((state) => {
                return {
                  ...state,
                  values: {
                    ...state.values,
                    filed: date,
                  },
                };
              });
            }}
          />
        </MuiPickersUtilsProvider>
      </Grid>
    </Grid>
  );
};

const mapStateToProps = (state) => ({
  dispositionForm: state.incident.dispositionForm,
  dispositions: state.incident.dispositions,
  wsClient: state.websocket,
});

export default connect(mapStateToProps, {
  updateIncDispositionForm,
})(TabDispositions);
