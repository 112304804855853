import store from '../../config/configureStore';
import { incPropertiesSchema } from 'simpl-schema-validation/schema';
import { generateHandleEditOffense } from 'simpl-schema-validation/helpers/generateHandleEdit';

export const validateIncProperties = () => {
  const currentState = store.store.getState().incident;
  const {
    properties,
    offensesOffenseForm,
    offenses,
    incProperties,
    selectedProperty,
  } = currentState;

  let validationErrors = [];
  for (let incProperty of incProperties) {
    let propertyOffense = offenses.find(
      (o) => o.offenseDetails.ptsOffenseId === incProperty.incPropertyDetails.ptsOffenseId
    );

    let property = properties.find(
      (p) =>
        p.propertyDetails.incIncidentPropertyId ===
        incProperty.incPropertyDetails.incIncidentPropertyId
    );
    if (!propertyOffense || !property) {
      continue;
    }

    let foundProperty;
    if (incProperty.incPropertyDetails.incIncidentPropertyId === selectedProperty) {
      foundProperty = incProperty;
    }

    let propertyValue = parseInt(incProperty.incPropertyDetails.values.value);
    propertyValue = isNaN(propertyValue) ? null : propertyValue;

    let objToValidate = {
      status: incProperty.incPropertyDetails.values.status,
      value: propertyValue,
      propertyDescription: incProperty.incPropertyDetails.values.propertyDescription,
      property: incProperty.incPropertyDetails,
      offense: propertyOffense?.offenseDetails || offensesOffenseForm,
      incident: currentState.incident,
      originProperty: property.propertyDetails,
      incProperty: foundProperty,
    };

    let propertiesValidationContext = incPropertiesSchema.newContext();

    let result = propertiesValidationContext.validate(objToValidate, {
      keys: ['status', 'propertyItem', 'value', 'propertyDescription'],
    });

    if (!result) {
      propertiesValidationContext.validationErrors().forEach((error) => {
        validationErrors.push({
          id: incProperty.incPropertyDetails.ptsPropertyId,
          incIncidentPropertyId: incProperty.incPropertyDetails.incIncidentPropertyId,
          ptsOffenseId: incProperty.incPropertyDetails.ptsOffenseId,
          idType: 'ptsPropertyId',
          errType: error.type,
          field: error.name,
          name: `${property.propertyDetails.values.parentType}`,
          message: propertiesValidationContext.keyErrorMessage(error.name),
          ptsIncidentId: currentState.ptsIncidentId,
          handleEdit: generateHandleEditOffense(
            currentState.ptsIncidentId,
            incProperty.incPropertyDetails.ptsOffenseId,
            4
          ),
        });
      });
    }
  }

  return validationErrors;
};
