import React from 'react';
import { connect, useDispatch } from 'react-redux';

import clsx from 'clsx';
import DateFnsUtils from '@date-io/date-fns';
import Toolbar from '@material-ui/core/Toolbar';
import Grid from '@material-ui/core/Grid';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import SearchIcon from '@material-ui/icons/Search';
import TextField from '@material-ui/core/TextField';
import grey from '@material-ui/core/colors/grey';
import { lighten, makeStyles } from '@material-ui/core/styles';

import { setLayoutState } from '../../../reducers/ui/UiLayoutReducer';
import { setSearchKeyword } from '../../../reducers/IncReducer';

const useToolbarStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },
  highlight:
    theme.palette.type === 'light'
      ? {
          color: theme.palette.secondary.main,
          backgroundColor: lighten(theme.palette.secondary.light, 0.85),
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark,
        },
  title: {
    flex: '1 1 100%',
  },
  selectList: {
    color: grey[700],
    marginRight: '3px',
  },
  selectBoard: {
    color: grey[700],
    marginRight: '3px',
  },
}));

const EnhancedTableToolbar = (props) => {
  const classes = useToolbarStyles();
  const dispatch = useDispatch();
  const { searchKeyword, setSearchKeyword, setDateRange } = props;
  const [createdFromDate, setCreatedFromDate] = React.useState(null);
  const [createdToDate, setCreatedToDate] = React.useState(null);

  const handleFilterChange = (e, type) => {
    let currentSearchKeywords = searchKeyword;

    setSearchKeyword({
      ...currentSearchKeywords,
      incidentKeyword: {
        ...currentSearchKeywords.incidentKeyword,
        [type]: e.target.value,
      },
    });
  };

  React.useEffect(() => {
    setDateRange({
      createdFromDate,
      createdToDate,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createdFromDate, createdToDate]);

  return (
    <Toolbar className={clsx(classes.root)}>
      <Grid container wrap="nowrap">
        <Grid container item xs={4}>
          <Grid container item xs={12} className="mt-2">
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <Grid item xs={5}>
                <KeyboardDatePicker
                  clearable
                  size="small"
                  inputVariant="outlined"
                  showTodayButton={true}
                  variant="dialog"
                  format="MM/dd/yyyy"
                  margin="normal"
                  id="date-picker-inline-from"
                  label="Created from"
                  defaultValue={null}
                  value={createdFromDate}
                  onChange={(date) => setCreatedFromDate(date)}
                  KeyboardButtonProps={{
                    'aria-label': 'change date',
                  }}
                  maxDate={props?.dateRange?.createdToDate || new Date()}
                />
              </Grid>
              <Grid item xs={5} className="ml-3">
                <KeyboardDatePicker
                  clearable
                  size="small"
                  inputVariant="outlined"
                  showTodayButton={true}
                  variant="dialog"
                  format="MM/dd/yyyy"
                  margin="normal"
                  id="date-picker-inline-to"
                  label="Created to"
                  defaultValue={null}
                  value={createdToDate}
                  onChange={(date) => setCreatedToDate(date)}
                  KeyboardButtonProps={{
                    'aria-label': 'change date',
                  }}
                  minDate={props?.dateRange?.createdFromDate || false}
                />
              </Grid>
            </MuiPickersUtilsProvider>
          </Grid>
        </Grid>

        <Grid container item xs={8} justify="flex-end">
          <Grid container xs={12} spacing={2} className="mt-1 mr-3" justify="flex-end">
            <Grid item className="mt-4">
              <SearchIcon />
            </Grid>
            <Grid item xs={2}>
              <TextField
                autoComplete="hidden"
                size="small"
                id="input-with-icon-grid"
                fullWidth
                label="Filter by Event ID"
                value={searchKeyword.incidentKeyword?.eventId || ''}
                onChange={(e) => handleFilterChange(e, 'eventId')}
              />
            </Grid>
            <Grid item xs={2}>
              <TextField
                autoComplete="hidden"
                size="small"
                id="input-with-icon-grid"
                fullWidth
                label="Event Type"
                value={searchKeyword.incidentKeyword?.eventType || ''}
                onChange={(e) => handleFilterChange(e, 'eventType')}
              />
            </Grid>
            <Grid item xs={2}>
              <TextField
                autoComplete="hidden"
                size="small"
                id="input-with-icon-grid"
                fullWidth
                label="Address"
                value={searchKeyword.incidentKeyword?.address || ''}
                onChange={(e) => handleFilterChange(e, 'address')}
              />
            </Grid>
            <Grid item xs={2}>
              <TextField
                autoComplete="hidden"
                size="small"
                id="input-with-icon-grid"
                fullWidth
                label="Agency ID"
                value={searchKeyword.incidentKeyword?.agencyId || ''}
                onChange={(e) => handleFilterChange(e, 'agencyId')}
              />
            </Grid>
            <Grid item xs={2}>
              <TextField
                autoComplete="hidden"
                size="small"
                id="input-with-icon-grid"
                fullWidth
                label="Disposition"
                value={searchKeyword.incidentKeyword?.disposition || ''}
                onChange={(e) => handleFilterChange(e, 'disposition')}
              />
            </Grid>
            <Grid item xs={1}>
              <TextField
                autoComplete="hidden"
                size="small"
                id="input-with-icon-grid"
                fullWidth
                label="Officer"
                value={searchKeyword.incidentKeyword?.officer || ''}
                onChange={(e) => handleFilterChange(e, 'officer')}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Toolbar>
  );
};

const mapStateToProps = (state) => ({
  searchKeyword: state.incident.searchKeyword,
});

export default connect(mapStateToProps, {
  setLayoutState,
  setSearchKeyword,
})(EnhancedTableToolbar);
