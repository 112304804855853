import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import { formatDate, sortObjArr } from '../../../../utils/functions';
import IconButton from '@material-ui/core/IconButton';
// import DeleteIcon from '@material-ui/icons/Delete';
import GetAppIcon from '@material-ui/icons/GetApp';
import { Tooltip } from '@material-ui/core';
// import { AttachmentOutlined } from '@material-ui/icons';
import PerfectScrollbar from 'react-perfect-scrollbar';
import AttachmentIcon from 'components/AttachmentIcon';
import { showSpinner, hideSpinner } from 'reducers/UiReducer';

const useStyles = makeStyles((theme) => ({
  attachments: {
    textAlign: 'left',
    maxHeight: '550px',
    overflowY: 'auto',
  },
  attachment: {
    padding: '10px 15px 5px',
    borderBottom: '1px solid #D3D3D3',
    color: theme.palette.type === 'light' ? 'rgba(0, 0, 0, 0.87)' : '#d4d6d7',
    '& p': {
      fontSize: 13,
      marginBottom: 5,
    },
  },
  header: {
    '& img': {
      width: 24,
      height: 24,
      borderRadius: 3,
    },
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    '& h4': {
      fontSize: '1em',
      marginBottom: '0.25em',
    },
  },
  imgInfo: {
    display: 'flex',
    alignItems: 'center',
    '& img': {
      marginRight: 10,
    },
  },
  date: {
    minWidth: 150,
    display: 'inline-block',
    textAlign: 'right',
  },
  fileInput: {
    display: 'none',
  },
  actions: {
    padding: '5px 15px',
    textAlign: 'right',
    '& label': {
      marginBottom: 0,
    },
  },
  attachmentActions: {
    '& button': {
      marginLeft: 5,
    },
  },
  attachmentName: {},
}));

function Attachment(props) {
  const classes = useStyles();
  const { FileName, Attached, Description, ptsAttachmentID, FileType } = props.attachment;

  const downloadFileHandler = async () => {
    props.downloadAttachment(ptsAttachmentID, FileName);
  };

  return (
    <div className={classes.attachment}>
      <div className={classes.header}>
        <div className={classes.imgInfo}>
          <AttachmentIcon type={FileType} />
          <div>
            <h4>{FileName}</h4>
            {!!Description && <p>{Description}</p>}
          </div>
        </div>
        <div className={classes.attachmentActions}>
          <Tooltip title="Download attachment">
            <IconButton onClick={downloadFileHandler} size="small">
              <GetAppIcon fontSize="inherit" color="primary" />
            </IconButton>
          </Tooltip>
          <span className={classes.date}>{formatDate(Attached)}</span>
        </div>
      </div>
    </div>
  );
}

function AttachmentsTab(props) {
  const classes = useStyles();
  const { ptsEventID, sortOption, wsClient } = props;
  const [attachments, setAttachments] = useState([]);

  useEffect(() => {
    if (!props.attachments || props.attachments.length === 0) {
      setAttachments([]);
      return;
    }
    setAttachments(sortObjArr(props.attachments, 'FileName', sortOption));
    // eslint-disable-next-line
  }, [props.attachments, sortOption]);

  function bufferToBlob(buffer, mime) {
    return new Blob([buffer], { type: mime });
  }

  const downloadAttachment2 = (id, name) => {
    return new Promise((resolve, reject) => {
      const service = wsClient?.websocket.service('download-attachment');
      service.timeout = 200000;
      service
        .get(id)
        .then((res) => {
          const blob = bufferToBlob(res.buffer, res.type);
          const link = document.createElement('a');
          link.href = window.URL.createObjectURL(blob);
          link.download = name;
          link.click();
          return resolve();
        })
        .catch((err) => {
          reject(err);
        });
    });
  };

  const downloadAttachment = async (ptsAttachmentID, FileName) => {
    console.log('download');
    props.showSpinner();
    downloadAttachment2(ptsAttachmentID, FileName)
      .then(() => {
        console.log('File is downloaded');
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => props.hideSpinner());
  };

  return (
    <div className={classes.attachments}>
      <PerfectScrollbar style={{ maxHeight: window.innerHeight - 500 + 'px' }}>
        {attachments &&
          attachments.map((attachment, idx) => (
            <Attachment key={idx} attachment={attachment} downloadAttachment={downloadAttachment} />
          ))}
      </PerfectScrollbar>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    wsClient: state.websocket,
  };
};

export default connect(mapStateToProps, { showSpinner, hideSpinner })(AttachmentsTab);
