export const checkFormType = (hasPtsId, setFormState, history) => {
  const parts = history.location.pathname.split('/');
  if (parts[2] === 'add' && !hasPtsId) {
    setFormState((formState) => ({
      ...formState,
      formType: 'add',
    }));
  } else {
    setFormState((formState) => ({
      ...formState,
      formType: 'edit',
    }));
  }
};

export const returnFormType = (history) => {
  const parts = history.location.pathname.split('/');
  if (parts[2] === 'edit') {
    return 'edit';
  } else {
    return 'add';
  }
};
