import React from 'react';
import Grid from '@material-ui/core/Grid';
import { connect } from 'react-redux';
import { updateIncOffensesDVVictimSubForm } from '../../../../reducers/IncReducer';
import { handleFormChangeNew } from '../../../../reducers/helpers/formHelpers';
import FormControl from '@material-ui/core/FormControl';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import makeStyles from '@material-ui/core/styles/makeStyles';

const selections = [
  { key: 'IsRural', value: 'Rural' },
  { key: 'IsTribal', value: 'Tribal' },
  { key: 'IsUrban', value: 'Urban' },
  { key: 'IsGeographicOther', value: 'Other' },
];

const useStyles = makeStyles((theme) => ({
  formControl: (props) => ({
    margin: theme.spacing(1),
    minWidth: '100%',
    maxWidth: '100%',
  }),
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chip: {
    margin: 2,
  },
  noLabel: {
    marginTop: theme.spacing(3),
  },
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const GeographicForm = (props) => {
  const classes = useStyles(props);
  const { offensesDVVictimForm, updateIncOffensesDVVictimSubForm } = props;

  const [offensesDVVictimFormState, setOffensesDVVictimFormState] = React.useState({
    isValid: false,
    values: {},
    touched: {},
    errors: {},
  });

  React.useEffect(() => {
    setOffensesDVVictimFormState({
      ...offensesDVVictimFormState,
      values: { ...offensesDVVictimFormState.values, ...offensesDVVictimForm.values },
    });
  }, [offensesDVVictimForm]);

  React.useEffect(
    () => {
      updateIncOffensesDVVictimSubForm(offensesDVVictimFormState);
    },
    selections.map((s) => offensesDVVictimFormState.values[s.key])
  );

  return (
    <Grid container justify="center">
      <Grid item xs={10} lg-={10}>
        <FormControl size="small" className={classes.formControl}>
          <Grid>
            {selections.map((selection) => (
              <FormControlLabel
                control={
                  <Checkbox
                    checked={offensesDVVictimFormState.values[selection.key] || false}
                    name={selection.key}
                    onChange={(event) =>
                      handleFormChangeNew(
                        event,
                        event.target.checked,
                        'none',
                        selection.key,
                        setOffensesDVVictimFormState
                      )
                    }
                  />
                }
                label={selection.value}
              />
            ))}
          </Grid>
        </FormControl>
      </Grid>
    </Grid>
  );
};

const mapStateToProps = (state) => ({
  offensesDVVictimForm: state.incident.offensesDVVictimForm,
});

export default connect(mapStateToProps, { updateIncOffensesDVVictimSubForm })(GeographicForm);
