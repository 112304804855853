import React from 'react';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { connect } from 'react-redux';
import { updateIncOffensesDVAdditionalForm } from '../../../../reducers/IncReducer';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { handleFormChangeNew } from '../../../../reducers/helpers/formHelpers';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Checkbox from '@material-ui/core/Checkbox';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { v4 as uuidv4 } from 'uuid';

const options = [{ name: 'Otion One' }, { name: 'Otion Two' }];

const useStyles = makeStyles((theme) => ({
  formControl: (props) => ({
    margin: theme.spacing(1),
    minWidth: '100%',
    maxWidth: '100%',
  }),
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chip: {
    margin: 2,
  },
  noLabel: {
    marginTop: theme.spacing(3),
  },
}));

const fromSelections = [
  { key: 'IsEvidenceCollectedFromCrimeScene', value: 'Crime Scene' },
  { key: 'IsEvidenceCollectedFromHospital', value: 'Hospital' },
  { key: 'IsEvidenceCollectedFromOther', value: 'Other' },
];

const typeSelections = [
  { key: 'IsEvidenceTypePhotos', value: 'Photos' },
  { key: 'IsEvidenceTypeVideo', value: 'Video' },
  { key: 'IsEvidenceType35MM', value: '35 MM' },
  { key: 'IsEvidenceTypePolaroid', value: 'Polaroid' },
  { key: 'IsEvidenceTypeOther', value: 'Other' },
];

const describingSelections = [
  { key: 'IsDescribingVictimsInjuries', value: "Victim's Injuries" },
  { key: 'IsDescribingWeaponUsed', value: 'Weapons Used' },
  { key: 'IsDescribingSuspectInjuries', value: "Suspect's Injuries" },
  { key: 'IsDescribingOther', value: 'Other' },
];

const EvidenceCollectedForm = (props) => {
  const classes = useStyles(props);
  const {
    offensesDVAdditionalForm,
    updateIncOffensesDVAdditionalForm,
    dvAdditionals,
    selectedVictim,
  } = props;

  const [offensesDVAdditionalFormState, setOffensesDVAdditionalFormState] = React.useState({
    tempIncDVAdditionalId: uuidv4(),
    isValid: false,
    changes: true,
    values: {},
    touched: {},
    errors: {},
  });

  React.useEffect(() => {
    async function fetch() {
      let dvAdditionalDetails = dvAdditionals.find(
        (dvAdditional) => dvAdditional.ptsIncPersonId === selectedVictim
      );
      if (dvAdditionalDetails) {
        await updateIncOffensesDVAdditionalForm(dvAdditionalDetails);
        await setOffensesDVAdditionalFormState(dvAdditionalDetails);
      }
    }
    // Fetching current selected victim's dv details
    fetch();
  }, []);

  React.useEffect(() => {
    setOffensesDVAdditionalFormState({
      ...offensesDVAdditionalFormState,
      values: { ...offensesDVAdditionalFormState.values, ...offensesDVAdditionalForm.values },
    });
  }, [offensesDVAdditionalForm]);

  React.useEffect(() => {
    offensesDVAdditionalFormState.changes = true;
    updateIncOffensesDVAdditionalForm(offensesDVAdditionalFormState);
  }, [
    ...fromSelections.map((s) => offensesDVAdditionalFormState.values[s.key]),
    ...typeSelections.map((s) => offensesDVAdditionalFormState.values[s.key]),
    ...describingSelections.map((s) => offensesDVAdditionalFormState.values[s.key]),
    offensesDVAdditionalFormState.values?.TakenBy,
    offensesDVAdditionalFormState.values?.WeaponUsedType,
    offensesDVAdditionalFormState.values?.IsWeaponImpounded,
    offensesDVAdditionalFormState.values?.IsFirearmUsed,
    offensesDVAdditionalFormState.values?.EvidenceDisposition,
  ]);

  return (
    <Grid container justify="center" spacing={3}>
      <Grid item xs={10} lg-={10}>
        <InputLabel id="demo-mutiple-chip-label">Collected From</InputLabel>
        <FormControl size="small" className={classes.formControl}>
          <Grid>
            {fromSelections.map((selection) => (
              <FormControlLabel
                control={
                  <Checkbox
                    checked={offensesDVAdditionalFormState.values[selection.key] || false}
                    name={selection.key}
                    onChange={(event) =>
                      handleFormChangeNew(
                        event,
                        event.target.checked,
                        'none',
                        selection.key,
                        setOffensesDVAdditionalFormState
                      )
                    }
                  />
                }
                label={selection.value}
              />
            ))}
          </Grid>
        </FormControl>
      </Grid>
      <Grid item xs={10} lg-={10}>
        <InputLabel id="demo-mutiple-chip-label">Evidence Type</InputLabel>
        <FormControl size="small" className={classes.formControl}>
          <Grid>
            {typeSelections.map((selection) => (
              <FormControlLabel
                control={
                  <Checkbox
                    checked={offensesDVAdditionalFormState.values[selection.key] || false}
                    name={selection.key}
                    onChange={(event) =>
                      handleFormChangeNew(
                        event,
                        event.target.checked,
                        'none',
                        selection.key,
                        setOffensesDVAdditionalFormState
                      )
                    }
                  />
                }
                label={selection.value}
              />
            ))}
          </Grid>
        </FormControl>
      </Grid>
      <Grid item xs={10} lg-={10}>
        <InputLabel id="demo-mutiple-chip-label">Describing</InputLabel>
        <FormControl size="small" className={classes.formControl}>
          <Grid>
            {describingSelections.map((selection) => (
              <FormControlLabel
                control={
                  <Checkbox
                    checked={offensesDVAdditionalFormState.values[selection.key] || false}
                    name={selection.key}
                    onChange={(event) =>
                      handleFormChangeNew(
                        event,
                        event.target.checked,
                        'none',
                        selection.key,
                        setOffensesDVAdditionalFormState
                      )
                    }
                  />
                }
                label={selection.value}
              />
            ))}
          </Grid>
        </FormControl>
      </Grid>
      <Grid item xs={5} lg-={5}>
        <TextField
          autoComplete="hidden"
          size="small"
          id="standard-textarea-taken-by"
          label="Taken By"
          placeholder="Taken By"
          variant="outlined"
          name="TakenBy"
          value={offensesDVAdditionalFormState.values?.TakenBy}
          onChange={(event) =>
            handleFormChangeNew(
              event,
              event.target.value,
              'none',
              'TakenBy',
              setOffensesDVAdditionalFormState
            )
          }
          fullWidth
          multiline
        />
      </Grid>
      <Grid item xs={5} lg-={5}>
        <Autocomplete
          size="small"
          autoComplete
          autoSelect
          autoHighlight
          id="combo-weapon-used-type"
          getOptionLabel={(option) => option || ''}
          options={options.map((s) => s.name)}
          key={offensesDVAdditionalFormState.values.WeaponUsedType || ''}
          value={offensesDVAdditionalFormState.values.WeaponUsedType || ''}
          onChange={(event, newValue) =>
            handleFormChangeNew(
              event,
              newValue,
              'none',
              'WeaponUsedType',
              setOffensesDVAdditionalFormState
            )
          }
          renderInput={(params) => (
            <TextField
              autoComplete="hidden"
              size="small"
              {...params}
              label="Weapon Used type"
              variant="outlined"
              fullWidth
            />
          )}
        />
      </Grid>
      <Grid item xs={5} lg-={5}>
        <FormControlLabel
          control={
            <Checkbox
              key={offensesDVAdditionalFormState.values?.IsWeaponImpounded}
              checked={offensesDVAdditionalFormState.values?.IsWeaponImpounded || false}
              onChange={(event) =>
                handleFormChangeNew(
                  event,
                  event.target.checked,
                  'none',
                  'IsWeaponImpounded',
                  setOffensesDVAdditionalFormState
                )
              }
              name="IsWeaponImpounded"
            />
          }
          label="Weapon(s) Impounded"
        />
      </Grid>
      <Grid item xs={5} lg-={5}>
        <FormControlLabel
          control={
            <Checkbox
              key={offensesDVAdditionalFormState.values?.IsFirearmUsed}
              checked={offensesDVAdditionalFormState.values?.IsFirearmUsed || false}
              onChange={(event) =>
                handleFormChangeNew(
                  event,
                  event.target.checked,
                  'none',
                  'IsFirearmUsed',
                  setOffensesDVAdditionalFormState
                )
              }
              name="IsFirearmUsed"
            />
          }
          label="Firearm(s) Used"
        />
      </Grid>
      <Grid container justify="center">
        <Grid item xs={10} lg={10}>
          <TextField
            autoComplete="hidden"
            size="small"
            className="mt-3 mb-3"
            id="outlined-multiline-static-evidence-disposition"
            label="Evidence Disposition"
            name="EvidenceDisposition"
            value={offensesDVAdditionalFormState.values?.EvidenceDisposition || ''}
            onChange={(event) =>
              handleFormChangeNew(
                event,
                event.target.value,
                'none',
                'EvidenceDisposition',
                setOffensesDVAdditionalFormState
              )
            }
            multiline
            rows="4"
            fullWidth
            defaultValue=""
            variant="outlined"
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

const mapStateToProps = (state) => ({
  offensesDVAdditionalForm: state.incident.offensesDVAdditionalForm,
  selectedVictim: state.incident.selectedVictim,
  dvAdditionals: state.incident.dvAdditionals,
});

export default connect(mapStateToProps, {
  updateIncOffensesDVAdditionalForm,
})(EvidenceCollectedForm);
