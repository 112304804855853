import React from 'react';
import { useParams } from 'react-router';
import { connect } from 'react-redux';

import PerfectScrollbar from 'react-perfect-scrollbar';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Box from '@material-ui/core/Box';
import HomeWorkIcon from '@material-ui/icons/HomeWork';
import FingerprintIcon from '@material-ui/icons/Fingerprint';
import GpsFixedIcon from '@material-ui/icons/GpsFixed';
import { makeStyles, styled } from '@material-ui/core/styles';

import Typography from '@material-ui/core/Typography';
import TabAddress from './TabAddress';
import TabIdentifiers from './TabIdentifiers';
import TabCoordinates from './TabCoordinates';
import { setHandleClose, setMenuType } from '../../reducers/ui/UiMenuReducer';

import IncSummaryDetails from '../../components/RMSForms/RMSIncidentForm/IncSummaryDetails';
import RMSFabButtons from '../../components/RMSForms/RMSIncidentForm/RMSFabButtons';
import {
  updateIncAddressForm,
  updateIncAddressIdentifiersForm,
  updateIncCoordinatesForm,
  getEntitiesByIncidentId,
  setSelectedIncident,
  setSelectedEntity,
} from '../../reducers/IncReducer';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    height: window.innerHeight - 90 + 'px',
  },
  paper: {
    minHeight: window.innerHeight - 90 + 'px',
  },
  scrollBar: {
    width: '100%',
    overflowX: 'hidden',

    [theme.breakpoints.up('lg')]: {
      height: '70vh',
    },
    [theme.breakpoints.down('lg')]: {
      height: '55vh',
    },
    [theme.breakpoints.only('lg')]: {
      height: '70vh',
    },
  },
  icons: {
    textDecoration: 'capitalize',
    padding: '11px',
    color: 'white',
    fontSize: '13px',
  },
  indicator: {
    backgroundColor: 'rgba(0,0,254,0.4)',
  },
}));

const useColorlibStepIconStyles = makeStyles({
  root: {
    zIndex: 1,
    color: '#ccc',
    width: 30,
    height: 30,
    display: 'flex',
    borderRadius: '50%',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: '10px',
  },
  active: {
    color: 'black',
    backgroundColor: 'whiteSmoke',
  },
});

const StyledStepLabel = styled(StepLabel)({
  cursor: 'pointer',

  '& .MuiStepLabel-label': {
    color: '#ccc',
    padding: '0px',
    marginTop: '0px',
  },
  '& .MuiStepLabel-active': {
    color: 'white',
  },
});

function ColorlibStepIcon(props) {
  const classes = useColorlibStepIconStyles();
  const { active } = props;

  const icons = {
    1: <HomeWorkIcon style={{ fontSize: '17px' }} />,
    2: <FingerprintIcon icon="user" style={{ fontSize: '15px' }} />,
    3: <GpsFixedIcon icon="user-shield" style={{ fontSize: '15px' }} />,
  };

  return (
    <div
      className={clsx(classes.root, {
        [classes.active]: active,
      })}>
      {icons[String(props.icon)]}
    </div>
  );
}

ColorlibStepIcon.propTypes = {
  active: PropTypes.bool,
  completed: PropTypes.bool,
  icon: PropTypes.node,
};

const AddEditAddress = (props) => {
  /** region Component Variables and Props */
  const classes = useStyles();
  const {
    selectedEntity,
    history,
    setHandleClose,
    setMenuType,
    selectedIncident,
    addresses,
    updateIncAddressForm,
    updateIncAddressIdentifiersForm,
    updateIncCoordinatesForm,
    getEntitiesByIncidentId,
    setSelectedIncident,
    setSelectedEntity,
    wsClient,
  } = props;
  const [value, setValue] = React.useState(0);
  const [addressOptions, setAddressOptions] = React.useState([]);
  const [selectedAddress, setSelectedAddress] = React.useState(null);
  let { incId, addressId } = useParams();
  const steps = [{ label: 'Address' }, { label: 'Identifiers' }, { label: 'Coordinates' }];
  /** endregion */
  /** region React Hooks */
  React.useEffect(() => {
    setMenuType('forms');
    setHandleClose('incForms', history);

    if (!selectedIncident || !selectedEntity || addresses.length === 0) {
      return;
    }

    const { ptsAddressId } = selectedEntity;

    const selectedAddressDetail = addresses.filter(
      (a) => a.addressDetails && a.addressDetails.ptsAddressId === ptsAddressId
    );

    if (selectedAddressDetail.length === 0) {
      return;
    }

    const {
      addressDetails,
      addressIdDetails,
      addressCoordinatesDetails,
    } = selectedAddressDetail[0];

    updateIncAddressForm(addressDetails);
    updateIncAddressIdentifiersForm(addressIdDetails);
    updateIncCoordinatesForm(addressCoordinatesDetails);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedEntity]);

  React.useEffect(() => {
    if (!wsClient.websocket) return;
    getEntitiesByIncidentId('addresses', {});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedIncident !== 0, wsClient]);

  React.useEffect(() => {
    if (selectedIncident === 0 && selectedEntity === null) {
      if (addressId) setSelectedEntity({ ptsAddressId: parseInt(addressId) });
      if (incId) setSelectedIncident(parseInt(incId));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedIncident === 0, selectedEntity === null]);
  /** endregion */
  /** region Helper Functions */
  const handleChange = (newValue) => {
    setValue(newValue);
  };
  const changeOptions = (data) => setAddressOptions(data);
  const changeSelectedOption = (data) => {
    setSelectedAddress(data);
  };
  const isStepOptional = () => {
    return false;
  };
  /** endregion */
  return (
    <div className={classes.root}>
      <RMSFabButtons />
      <Grid container>
        <Grid xs={3} lg={3}>
          <IncSummaryDetails history={history} />
        </Grid>
        <Grid xs={9} lg={9}>
          <Paper className={classes.paper}>
            <Grid container justify="center">
              <Grid item xs={12} lg={12}>
                <Box className={classes.stepper}>
                  <Stepper
                    alternativeLabel
                    nonLinear
                    activeStep={value}
                    style={{
                      backgroundColor: '#1976d2',
                      borderRadius: '0.5rem',
                      padding: '15px 5px',
                    }}>
                    {steps.map((obj, index) => {
                      const stepProps = {};
                      const buttonProps = {};
                      if (isStepOptional(index)) {
                        buttonProps.optional = <Typography variant="caption">Optional</Typography>;
                      }
                      return (
                        <Step key={obj.label} {...stepProps}>
                          <StyledStepLabel
                            StepIconComponent={ColorlibStepIcon}
                            onClick={() => handleChange(index)}>
                            {obj.label}
                          </StyledStepLabel>
                        </Step>
                      );
                    })}
                  </Stepper>
                </Box>
              </Grid>
              <PerfectScrollbar className={classes.scrollBar}>
                {value === 0 && (
                  <Grid item>
                    <TabAddress
                      addressOptions={addressOptions}
                      changeOptions={changeOptions}
                      changeSelectedOption={changeSelectedOption}
                      selectedAddress={selectedAddress}
                    />
                  </Grid>
                )}
                {value === 1 && (
                  <Grid item>
                    <TabIdentifiers />
                  </Grid>
                )}
                {value === 2 && (
                  <Grid item>
                    <TabCoordinates />
                  </Grid>
                )}
              </PerfectScrollbar>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    </div>
  );
};

const mapStateToProps = (state) => ({
  wsClient: state.websocket,
  selectedEntity: state.incident.selectedEntity,
  selectedIncident: state.incident.ptsIncidentId,
  addresses: state.incident.addresses,
  recordActive: state.records.recordActive,
});

export default connect(mapStateToProps, {
  setHandleClose,
  setMenuType,
  updateIncAddressForm,
  updateIncAddressIdentifiersForm,
  updateIncCoordinatesForm,
  getEntitiesByIncidentId,
  setSelectedIncident,
  setSelectedEntity,
})(AddEditAddress);
