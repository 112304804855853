import React, { useState, useEffect } from 'react';
// import IconButton from '@material-ui/core/IconButton';
import { makeStyles } from '@material-ui/core/styles';
// import { Tooltip } from '@material-ui/core';
// import DeleteIcon from '@material-ui/icons/Delete';
// import { formatDate, sortObjArr, areObjEqual } from '../../../../utils/functions';
import { formatDate, sortObjArr } from '../../../../utils/functions';
import PerfectScrollbar from 'react-perfect-scrollbar';

const useStyles = makeStyles((theme) => ({
  dispositions: {
    textAlign: 'left',
    maxHeight: '550px',
    overflowY: 'auto',
  },
  disposition: {
    padding: '10px 15px 5px',
    borderBottom: '1px solid #D3D3D3',
    position: 'relative',
    color: theme.palette.type === 'light' ? 'rgba(0, 0, 0, 0.87)' : '#d4d6d7',
    '& p': {
      fontSize: 13,
      marginBottom: 5,
    },
    '&:hover': {
      '& $dispAction': {
        opacity: 1,
      },
    },
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    '& h4': {
      fontSize: '1em',
      marginBottom: '0.25em',
    },
  },
  actions: {
    padding: '5px 15px',
    textAlign: 'right',
    '& label': {
      marginBottom: 0,
    },
  },
  addDispositions: {
    padding: '5px 15px',
    background: '#eee',
  },
  dispAction: {
    position: 'absolute',
    right: 10,
    top: 25,
    opacity: 0,
    transition: 'opacity 0.3s',
  },
  autocomplete: {
    padding: theme.spacing(1, 2),
  },
}));

function Disposition(props) {
  const classes = useStyles();
  const { ptsEventID, disposition } = props;
  const { CreatedBy, Disposition, Description, Created, AgencyID } = disposition;

  return (
    <div className={classes.disposition}>
      <div className={classes.header}>
        <h4>{CreatedBy}</h4>
        <span>{formatDate(Created)}</span>
      </div>
      <p>
        {Disposition} - {Description} ({AgencyID})
      </p>
    </div>
  );
}

function DispositionsTab(props) {
  const classes = useStyles();
  const { dispositions, ptsEventID, sortOption } = props;
  const [sortedDispositions, setSortedDispositions] = useState([]);

  useEffect(() => {
    if (!dispositions) return;
    setSortedDispositions(sortObjArr(dispositions, 'Disposition', sortOption));
  }, [dispositions, sortOption]);

  const renderDispositions = () => {
    console.log(sortedDispositions);
    return (
      <>
        {sortedDispositions.map((disposition, idx) => (
          <Disposition key={idx} disposition={disposition} no={idx} ptsEventID={ptsEventID} />
        ))}
      </>
    );
  };

  return (
    <div className={classes.dispositions}>
      <PerfectScrollbar style={{ maxHeight: window.innerHeight - 500 + 'px' }}>
        {renderDispositions()}{' '}
      </PerfectScrollbar>
    </div>
  );
}

export default DispositionsTab;
