/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import clsx from 'clsx';
import { connect } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';

import PerfectScrollbar from 'react-perfect-scrollbar';
import Grid from '@material-ui/core/Grid';
import Tooltip from '@material-ui/core/Tooltip';
import Avatar from '@material-ui/core/Avatar';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { colors } from '../../config/colorVariables';
import {
  setListProperties,
  setSelectedProperty,
  updateIncPropertyDetails,
  removeIncPropertyDetails,
  setSelectedArrestee,
} from '../../reducers/IncReducer';
import RMSSelectButton from '../../components/RMSButtons/RMSSelectButton';
import RMSSimpleList from '../../components/RMSList/RMSSimpleList';
import DialogList from '../../components/RMSList/RMSDialogList';
import Danger from '../../components/RMSAlerts/Danger';
import PropertiesMenu from '../../components/RMSVerticalMenu/PropertiesMenu';
import {
  setHandleAddIncProperty,
  setHandleOffensesRedirectFormClose,
} from '../../reducers/ui/UiMenuReducer';
import { validateIncPropertiesForm } from 'simpl-schema-validation/ValidateForms';

const useStyles = makeStyles((theme) => ({
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: colors.cyan,
    marginRight: theme.spacing(2),
  },
  detailsPanel: {
    padding: theme.spacing(4),
  },
}));

export const PropertyAvatar = (props) => {
  const { label } = props;
  const classes = useStyles();
  return <Avatar className={clsx(classes.avatar)}>{label}</Avatar>;
};

const TabProperty = (props) => {
  /** region Component Variables and Props */
  const {
    setHandleOffensesRedirectFormClose,
    setListProperties,
    setSelectedProperty,
    incProperties,
    listProperties,
    listDialogProperties,
    properties,
    selectedProperty,
    removeIncPropertyDetails,
    offensesOffenseForm,
  } = props;
  const [errors, setErrors] = React.useState({});
  const [offensesPropertyFormState, setOffensesPropertyFormState] = React.useState({
    tempIncPropId: uuidv4(),
    isValid: false,
    values: {},
    touched: {},
    errors: {},
  });
  const [changeKey, setChangeKey] = React.useState(0);
  const [dialogBox, setDialogBox] = React.useState(false);
  /** endregion */
  /** region React Hooks */
  React.useEffect(() => {
    if (offensesOffenseForm.ptsOffenseId) {
      setOffensesPropertyFormState({
        ...offensesPropertyFormState,
        ptsOffenseId: offensesOffenseForm.ptsOffenseId,
      });
    } else {
      setOffensesPropertyFormState({
        ...offensesPropertyFormState,
        tempOffenseId: offensesOffenseForm.tempOffenseId,
      });
    }
  }, []);
  React.useEffect(() => {
    setHandleOffensesRedirectFormClose(false);
    createIncPropertyList();

    if (incProperties && incProperties.length > 0) {
      let propertyItemErrors = validateIncPropertiesForm('propertyItem');
      let propertySubstanceErrors = validateIncPropertiesForm('propertySubstance');
      let propertyErrors = [...propertyItemErrors, ...propertySubstanceErrors];

      let errStatus = [];
      if (propertyErrors.length > 0) {
        propertyErrors.map((pE) => {
          errStatus.push(pE.message);
        });
      }
      setErrors(errStatus);
    }
  }, [incProperties, properties, incProperties.length]);
  /** endregion */
  /** region Helper Functions */
  const createIncPropertyList = () => {
    if (incProperties !== undefined && incProperties.length > 0) {
      let newList = [];

      incProperties.forEach((s) => {
        let property;
        let saveProperty;

        if (!s.incPropertyDetails.incIncidentPropertyId) {
          property = properties.find(
            (x) => x.propertyDetails.tempPropId === s.incPropertyDetails.tempPropId
          );
        } else {
          property = properties.find(
            (x) =>
              x.propertyDetails.incIncidentPropertyId === s.incPropertyDetails.incIncidentPropertyId
          );
        }

        if (property) {
          saveProperty = {
            id:
              property?.propertyDetails.incIncidentPropertyId ||
              property?.propertyDetails.tempPropId,
            name: `${
              property?.propertyDetails.values.description ||
              property?.propertyDetails.values.name ||
              ''
            }`,
            icon: <PropertyAvatar />,
            description: property?.propertyDetails.values.description || '',
            propertyDescription: property?.propertyDetails.values.description || '',
            propertyLossType: property?.propertyDetails.values.propertyLossType || '',
            type: 'property',
          };
          newList.push(saveProperty);
        }
        if (saveProperty && newList.length === 1) {
          setSelectedProperty(saveProperty.id);
        }
      });
      setListProperties(newList);
      setChangeKey(newList.length);
    }
  };

  const handleIncPropertyDelete = () => {
    let updatedList = listProperties.filter((s) => s.id !== selectedProperty);
    setListProperties(updatedList);
    removeIncPropertyDetails();
    setSelectedProperty(updatedList[0] ? updatedList[0].id : '');
  };

  const handleIncPropertySelect = () => {
    if (listDialogProperties.length > 0) {
      setDialogBox(true);
    }
  };

  const closeDialogBox = () => {
    createIncPropertyList();
    setDialogBox(false);
  };
  /** endregion */
  return (
    <Grid container>
      <Grid item xs={12} lg={12}>
        {errors.length > 0 && <Danger isArray={true} list={errors} />}
      </Grid>
      <Grid container spacing={2} className="mb-3">
        <Grid item className="ml-2">
          <PropertiesMenu handleRedirect isHandlingIncProperty={true} />
        </Grid>
        <Grid item className="mr-1">
          <DialogList
            dialogBox={dialogBox}
            title="Incident Property List"
            type="properties"
            list={[]}
            addToSimpleList={createIncPropertyList}
            closeDialogBox={closeDialogBox}
          />

          <Tooltip title="Select Properties from Parties">
            <RMSSelectButton lists={listDialogProperties} onClick={handleIncPropertySelect} />
          </Tooltip>
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={12} lg={12}>
          <PerfectScrollbar style={{ height: '99%' }} className="scroll-area-lg">
            <RMSSimpleList
              key={changeKey}
              listItems={listProperties}
              entity="properties"
              deleteFromList={handleIncPropertyDelete}
            />
          </PerfectScrollbar>
        </Grid>
      </Grid>
    </Grid>
  );
};

const mapStateToProps = (state) => ({
  offensesPropertyForm: state.incident.offensesPropertyForm,
  incProperties: state.incident.incProperties,
  listProperties: state.incident.listProperties,
  listDialogProperties: state.incident.listDialogProperties,
  properties: state.incident.properties,
  selectedProperty: state.incident.selectedProperty,
  offensesOffenseForm: state.incident.offensesOffenseForm,
});

export default connect(mapStateToProps, {
  setHandleOffensesRedirectFormClose,
  setHandleAddIncProperty,
  setListProperties,
  setSelectedProperty,
  removeIncPropertyDetails,
  updateIncPropertyDetails,
  setSelectedArrestee,
})(TabProperty);
