import React from 'react';
import { connect } from 'react-redux';
import { useParams } from 'react-router';

import PropTypes from 'prop-types';
import clsx from 'clsx';
import PerfectScrollbar from 'react-perfect-scrollbar';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import PersonPinIcon from '@material-ui/icons/PersonPin';
import ContactsIcon from '@material-ui/icons/Contacts';
import WorkIcon from '@material-ui/icons/Work';
import { makeStyles, styled } from '@material-ui/core/styles';
import CommentIcon from '@material-ui/icons/Comment';
import Typography from '@material-ui/core/Typography';

import TabPerson from './TabPerson';
import TabContact from './TabContact';
import TabEmployment from './TabEmployment';
import TabPartiesOthers from './TabPartiesOthers';
import IncSummaryDetails from '../../components/RMSForms/RMSIncidentForm/IncSummaryDetails';
import RMSFabButtons from '../../components/RMSForms/RMSIncidentForm/RMSFabButtons';
import { setOffensesTab } from '../../reducers/ui/UiFormReducer';
import { setHandleClose, setMenuType } from '../../reducers/ui/UiMenuReducer';
import {
  updateIncPartiesPersonForm,
  updateIncPartiesContactForm,
  updateIncPartiesEmploymentForm,
  updateIncPartiesOthersForm,
  updateIncOffensesMPBasicForm,
  updateIncMPRelatedRecords,
  getEntitiesByIncidentId,
  setSelectedEntity,
  setSelectedIncident,
} from '../../reducers/IncReducer';

const StyledStepLabel = styled(StepLabel)({
  cursor: 'pointer',

  '& .MuiStepLabel-label': {
    color: '#ccc',
    padding: '0px',
    marginTop: '0px',
  },
  '& .MuiStepLabel-active': {
    color: 'white',
  },
});

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    height: window.innerHeight - 90 + 'px',
  },
  scrollBar: {
    width: '100%',
    overflowX: 'hidden',

    paddingRight: '5px',
    [theme.breakpoints.up('lg')]: {
      height: '70vh',
    },
    [theme.breakpoints.down('lg')]: {
      height: '55vh',
    },
    [theme.breakpoints.only('lg')]: {
      height: '65vh',
    },
  },
  indicator: {
    backgroundColor: 'rgba(0,0,254,0.3)',
  },
  paper: {
    minHeight: window.innerHeight - 95 + 'px',
  },
  dialogBox: {
    minWidth: '412px',
    minHeight: '150px',
  },
  icons: {
    textDecoration: 'capitalize',
    padding: '14px',
    fontSize: '13px',
  },
}));

const useColorlibStepIconStyles = makeStyles({
  root: {
    zIndex: 1,
    color: '#ccc',
    width: 30,
    height: 30,
    display: 'flex',
    borderRadius: '50%',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: '10px',
  },
  active: {
    color: 'black',
    backgroundColor: 'whiteSmoke',
  },
});

function ColorlibStepIcon(props) {
  const classes = useColorlibStepIconStyles();
  const { active } = props;

  const icons = {
    1: <PersonPinIcon style={{ fontSize: '17px' }} />,
    2: <ContactsIcon style={{ fontSize: '15px' }} />,
    3: <WorkIcon style={{ fontSize: '15px' }} />,
    4: <CommentIcon style={{ fontSize: '15px' }} />,
  };

  return (
    <div
      className={clsx(classes.root, {
        [classes.active]: active,
      })}>
      {icons[String(props.icon)]}
    </div>
  );
}

ColorlibStepIcon.propTypes = {
  active: PropTypes.bool,
  completed: PropTypes.bool,
  icon: PropTypes.node,
};

const AddEditParties = (props) => {
  /** region Component Variables and Props */
  const classes = useStyles();
  const {
    history,
    setHandleClose,
    setMenuType,
    selectedEntity,
    selectedIncident,
    parties,
    updateIncPartiesPersonForm,
    updateIncPartiesContactForm,
    updateIncPartiesEmploymentForm,
    updateIncPartiesOthersForm,
    updateIncOffensesMPBasicForm,
    updateIncMPRelatedRecords,
    getEntitiesByIncidentId,
    wsClient,
    setSelectedEntity,
    setSelectedIncident,
    incidentId,
  } = props;

  let { incId, incPersonId } = useParams();
  const steps = [
    { label: 'Person' },
    { label: 'Contact' },
    { label: 'Employment' },
    { label: 'Others' },
  ];
  const [value, setValue] = React.useState(0);

  const [personOptions, setPersonOptions] = React.useState([]);
  const [selectedPeople, setSelectedPeople] = React.useState(null);

  const [contactAddressOptions, setContactAddressOptions] = React.useState([]);
  const [selectedContactAddress, setSelectedContactAddress] = React.useState(null);

  const [employmentAddressOptions, setEmploymentAddressOptions] = React.useState([]);
  const [selectedEmploymentAddress, setSelectedEmploymentAddress] = React.useState(null);
  /** endregion */
  /** region React Hooks */
  React.useEffect(() => {
    setMenuType('forms');
    setHandleClose('incForms', history);
    const parts = history.location.pathname.split('/');
    let type = '';

    if (parts.length === 4) type = parts[parts.length - 2];

    if (parts.length === 5) type = parts[parts.length - 3];

    if (!selectedEntity || parties.length === 0 || type === 'add') {
      return;
    }

    const { ptsPersonId, ptsIncPersonId } = selectedEntity;

    if (selectedEntity.ptsIncPersonId) {
      const selectedPartyDetail = parties.filter(
        (a) =>
          a.personDetail.ptsPersonId === ptsPersonId ||
          a.personDetail.ptsIncPersonId === ptsIncPersonId
      );

      if (selectedPartyDetail.length === 0) {
        return;
      }

      const {
        personDetail,
        contactDetail,
        employmentDetail,
        otherDetail,
        mpBasicDetail,
        mpRelatedRecords,
      } = selectedPartyDetail[0];

      updateIncPartiesPersonForm(personDetail);
      updateIncPartiesContactForm(contactDetail);
      updateIncPartiesEmploymentForm(employmentDetail);
      updateIncPartiesOthersForm(otherDetail);
      updateIncOffensesMPBasicForm(mpBasicDetail);
      updateIncMPRelatedRecords(mpRelatedRecords);
    } else {
      return;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [parties, selectedIncident && selectedEntity !== null]);

  React.useEffect(() => {
    if (!wsClient.websocket) return;

    if (!selectedEntity) {
      getEntitiesByIncidentId('parties', {});
      setSelectedEntity({ ptsIncPersonId: parseInt(incPersonId) });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [wsClient, selectedEntity === null, incidentId !== 0]);

  React.useEffect(() => {
    if (incId) setSelectedIncident(incId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [incidentId === 0]);
  /** endregion */
  /** region Helper Functions */
  const handleChange = (newValue) => setValue(newValue);

  const isStepOptional = () => {
    return false;
  };

  const changeOptions = (data) => setPersonOptions(data);
  const changeSelectedOption = (data) => setSelectedPeople(data);

  const changeContactOptions = (data) => setContactAddressOptions(data);
  const changeContactSelectedOption = (data) => setSelectedContactAddress(data);

  const changeEmploymentOptions = (data) => setEmploymentAddressOptions(data);
  const changeEmploymentSelectedOption = (data) => setSelectedEmploymentAddress(data);
  /** endregion */
  return (
    <div className={classes.root}>
      <RMSFabButtons />
      <Grid container>
        <Grid xs={3} lg={3}>
          <IncSummaryDetails history={history} />
        </Grid>
        <Grid xs={9} lg={9}>
          <Paper className={classes.paper}>
            <Grid container justify="center">
              <Grid item xs={12} style={{ paddingTop: '0px' }}>
                <Box className={classes.stepper}>
                  <Stepper
                    alternativeLabel
                    nonLinear
                    activeStep={value}
                    style={{
                      backgroundColor: '#1976d2',
                      borderRadius: '0.5rem',
                      padding: '15px 5px',
                    }}>
                    {steps.map((obj, index) => {
                      const stepProps = {};
                      const buttonProps = {};
                      if (isStepOptional(index)) {
                        buttonProps.optional = <Typography variant="caption">Optional</Typography>;
                      }
                      return (
                        <Step key={obj.label} {...stepProps}>
                          <StyledStepLabel
                            StepIconComponent={ColorlibStepIcon}
                            onClick={() => handleChange(index)}>
                            {obj.label}
                          </StyledStepLabel>
                        </Step>
                      );
                    })}
                  </Stepper>
                </Box>
              </Grid>
              <PerfectScrollbar className={classes.scrollBar}>
                {value === 0 && (
                  <Grid item>
                    <TabPerson
                      history={history}
                      personOptions={personOptions}
                      changeOptions={changeOptions}
                      changeSelectedOption={changeSelectedOption}
                      selectedPeople={selectedPeople}
                    />
                  </Grid>
                )}

                {value === 1 && (
                  <Grid item>
                    <TabContact
                      history={history}
                      addressOptions={contactAddressOptions}
                      changeOptions={changeContactOptions}
                      changeSelectedOption={changeContactSelectedOption}
                      selectedAddress={selectedContactAddress}
                    />
                  </Grid>
                )}

                {value === 2 && (
                  <Grid item>
                    <TabEmployment
                      history={history}
                      addressOptions={employmentAddressOptions}
                      changeOptions={changeEmploymentOptions}
                      changeSelectedOption={changeEmploymentSelectedOption}
                      selectedAddress={selectedEmploymentAddress}
                    />
                  </Grid>
                )}

                {value === 3 && (
                  <Grid item>
                    <TabPartiesOthers history={history} />
                  </Grid>
                )}
              </PerfectScrollbar>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    </div>
  );
};

const mapStateToProps = (state) => ({
  incidentId: state.incident.ptsIncidentId,
  incPartyState: state.uiForm.incPartiesTabState,
  selectedEntity: state.incident.selectedEntity,
  selectedIncident: state.incident.ptsIncidentId,
  parties: state.incident.parties,
  showUCRAlertModal: state.uiModal.showUCRAlertModal,
  recordActive: state.records.recordActive,
  wsClient: state.websocket,
});

export default connect(mapStateToProps, {
  setHandleClose,
  setMenuType,
  updateIncPartiesPersonForm,
  updateIncPartiesContactForm,
  updateIncPartiesEmploymentForm,
  updateIncOffensesMPBasicForm,
  updateIncMPRelatedRecords,
  updateIncPartiesOthersForm,
  setOffensesTab,
  getEntitiesByIncidentId,
  setSelectedEntity,
  setSelectedIncident,
})(AddEditParties);
