import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { connect, useDispatch } from 'react-redux';
import { useHistory, useLocation, useParams } from 'react-router';

import { Grid, makeStyles, TextField, Paper } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';

import RMSFabButtons from 'components/RMSForms/RMSIncidentForm/RMSFabButtons';
import RMSAutoComplete from 'components/RMSAutoComplete/RMSAutoComplete';
import { handleFormChangeNew } from 'reducers/helpers/formHelpers';
import { upsertRecord } from 'reducers/RecordsReducer';
import { setCircularLoading } from 'reducers/ui/UiMenuReducer';

const useStyles = makeStyles((theme) => ({
  root: {
    height: window.innerHeight - 90 + 'px',
    borderRadius: '0.5rem',
    padding: '15px',
    background: 'white !important',
  },
  form: {
    width: '100%',
  },
  input: {
    width: '100%',
    padding: '10px',
  },
  btnStyle: {
    display: 'block',
    width: '150px',
    padding: '10px',
    marginTop: '15px',

    position: 'relative',
    right: 10,
  },
}));

/** region static code */
const codeCategory = [
  'Appearance',
  'Builds',
  'Complexions',
  'EyeDefect',
  'FacialHair',
  'Glasses',
  'MethodOfOperation',
  'SpeechQuality',
  'Talk',
  'Teet',
];
const codeAppearance = [{ Code: 'App1' }, { Code: 'App2' }];
const codeBuilds = [{ Code: 'build1' }, { Code: 'build2' }];
const codeComplexions = [{ Code: 'complex3' }, { Code: 'complex2' }];
const codeEyeDefect = [{ Code: 'eyed1' }, { Code: 'eyed2' }];
const codeFacialHair = [{ Code: 'FTH1' }, { Code: 'FTH2' }];
const codeGlasses = [{ Code: 'GLS1' }, { Code: 'GLS2' }];
const codeMethodOfOperation = [{ Code: 'MOP1' }, { Code: 'MOP2' }];
const codeSpeechQuality = [{ Code: 'SPQ1' }, { Code: 'SPQ2' }];
const codeTalk = [{ Code: 'TK1' }, { Code: 'TK2' }];
const codeTeet = [{ Code: 'TH1' }, { Code: 'Th2' }];
/** end region */

const PersonFeatureForm = (props) => {
  /** region props and variables */
  const classes = useStyles();
  const [code, setCode] = useState({});
  const location = useLocation();
  let data = useSelector((state) => state.records.formData);
  const { persondId } = useParams();
  const { upsertRecord } = props;
  const actionType = location.pathname.split('/')[5];
  const [firstLoad, setFirstLoad] = React.useState(true);
  const history = useHistory();
  const dispatch = useDispatch();
  if (actionType === 'add') {
    data = {};
  }
  const [formData, setFormData] = useState({
    values: {
      ptsFeatureID: data?.ptsFeatureID || undefined,
      ptsPersonID: persondId,
      AttributeCategory: data?.AttributeCategory || 'Appearance',
      Attribute: data?.Attribute || '',
    },
  });
  /** end region */
  /**region lifecycle hooks */
  React.useEffect(() => {
    const category = formData?.values?.AttributeCategory;
    if (!firstLoad) {
      setFormData({ ...formData, values: { ...formData.values, Attribute: '' } });
    }
    setFirstLoad(false);
    if (category === 'Appearance') {
      setCode({ id: 'Appearance', values: [...codeAppearance] });
    } else if (category === 'Builds') {
      setCode({ id: 'Builds', values: [...codeBuilds] });
    } else if (category === 'Complexions') {
      setCode({ id: 'Complexions', values: [...codeComplexions] });
    } else if (category === 'EyeDefect') {
      setCode({ id: 'EyeDefect', values: [...codeEyeDefect] });
    } else if (category === 'FacialHair') {
      setCode({ id: 'FacialHair', values: [...codeFacialHair] });
    } else if (category === 'Glasses') {
      setCode({ id: 'Glasses', values: [...codeGlasses] });
    } else if (category === 'MethodOfOperation') {
      setCode({ id: 'MethodOfOperation', values: [...codeMethodOfOperation] });
    } else if (category === 'SpeechQuality') {
      setCode({ id: 'SpeechQuality', values: [...codeSpeechQuality] });
    } else if (category === 'Talk') {
      setCode({ id: 'Talk', values: [...codeTalk] });
    } else if (category === 'Teet') {
      setCode({ id: 'Teet', values: [...codeTeet] });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formData?.values?.AttributeCategory]);
  /** end region */
  /** region helper functions*/
  const handleSave = () => {
    if (!formData.values.ptsFeatureID) {
      upsertRecord('feature', formData.values, 'add').finally(() => handleFinally());
    } else {
      upsertRecord('feature', formData.values, 'edit').finally(() => handleFinally());
    }
  };

  const handleFinally = () => {
    dispatch(setCircularLoading(false));
    history.goBack();
  };
  /** end region */

  return (
    <Paper className={classes.root}>
      <form autoComplete="off" className={classes.form}>
        <Grid container xs={12} align="center" spacing={2} justify="left" className="mt-2">
          <Grid item xs={4}>
            <Autocomplete
              size="small"
              autoComplete
              autoSelect
              autoHighlight
              id="combo-attributeCategory"
              getOptionLabel={(option) => option || ''}
              options={codeCategory}
              value={formData.values?.AttributeCategory || ''}
              onChange={(event, newValue) =>
                handleFormChangeNew(event, newValue, 'none', 'AttributeCategory', setFormData)
              }
              renderInput={(params) => (
                <Grid container spacing={1} alignItems="flex-end">
                  <Grid item xs={12} lg={12}>
                    <TextField
                      autoComplete="hidden"
                      size="small"
                      {...params}
                      label="Attribute Category"
                      variant="outlined"
                      fullWidth
                    />
                  </Grid>
                </Grid>
              )}
            />
          </Grid>
          <Grid item xs={4}>
            {code.values && (
              <RMSAutoComplete
                size="small"
                autoComplete
                autoSelect
                autoHighlight
                id="Attribute"
                getOptionLabel={(option) => option || ''}
                options={code?.values}
                label="Attribute"
                serviceName={`code ${code.id}`.toLowerCase()}
                title="Code"
                stateHolderAttribute="Attribute"
                variant="outlined"
                stateHolder={formData}
                setStateHolder={setFormData}
                setField={handleFormChangeNew}
              />
            )}
          </Grid>
        </Grid>
      </form>
      <RMSFabButtons recordForm={true} handleSaveRecord={handleSave} />
    </Paper>
  );
};
const mapStateToProps = (state) => ({
  wsClient: state.websocket,
});

export default connect(mapStateToProps, { upsertRecord })(PersonFeatureForm);
