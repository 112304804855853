import React from 'react';
import { useHistory } from 'react-router';
import { connect, useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';

import { Grid, makeStyles, TextField, Tab, Tabs, Divider, Paper } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';

import { setCircularLoading } from 'reducers/ui/UiMenuReducer';
import { upsertRecord } from 'reducers/RecordsReducer';
import RMSFabButtons from 'components/RMSForms/RMSIncidentForm/RMSFabButtons';

import { handleFormChangeNew } from 'reducers/helpers/formHelpers';
import AddressSearch from 'components/RMSForms/RMSAddressForm/AddressSearch';
import RMSAddButton from 'components/RMSButtons/RMSAddButton';
import { duplicateCheck } from 'reducers/helpers/duplicateCheckHelpers';
import {
  setDuplicatePlaceList,
  setDuplicateModal,
  setDuplicateModalMessage,
} from 'reducers/ui/UiModalReducer';
import { notify } from 'reducers/NotifierReducer';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    height: window.innerHeight - 90 + 'px',
  },
  scrollBar: {
    width: '100%',
    overflowX: 'hidden',

    [theme.breakpoints.up('lg')]: {
      height: '70vh',
    },
    [theme.breakpoints.down('lg')]: {
      height: '50vh',
    },
    [theme.breakpoints.only('lg')]: {
      height: '65vh',
    },
  },
  paper: {
    minHeight: window.innerHeight - 95 + 'px',
  },
  btnStyle: {
    display: 'block',
    textAlign: 'right',
    marginBottom: '10px',
  },
}));

const codeMethodType = ['BUS', 'EML', 'HME', 'WEB', 'WRK'];

const AddEditPlaceRecord = (props) => {
  /** region Component Variables and Props */
  const history = useHistory();
  const dispatch = useDispatch();
  const classes = useStyles();
  let { placeId } = useParams();

  const {
    upsertRecord,
    records,
    setDuplicatePlaceList,
    setDuplicateModalMessage,
    setDuplicateModal,
  } = props;
  const data = records.formData;

  const wsClient = useSelector((state) => state.websocket);
  const [changeKey, setChangeKey] = React.useState(0);
  const [pageType, setPageType] = React.useState('add');
  const [firstLoad, setFirstLoad] = React.useState(true);
  const [formData, setFormData] = React.useState({
    values: {
      ptsPlaceID: data?.ptsPlaceID || undefined,
      PlaceName: data?.PlaceName || '',
      PlaceType: data?.PlaceType || '',
    },
  });
  /** endregion */
  /** region React Hooks */
  React.useEffect(() => {
    const parts = history.location.pathname.split('/');
    setPageType(parts[2]);
    return () => {
      setFormData({
        values: {
          ptsPlaceID: '',
          PlaceName: '',
          PlaceType: '',
        },
      });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    let timeoutId;
    if (formData.values.PlaceName !== '') {
      timeoutId = setTimeout(() => {
        duplicateFinder();
      }, 2000);
    }
    return () => clearTimeout(timeoutId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formData.values]);

  React.useEffect(() => {
    if (!wsClient?.websocket) {
      return;
    }
    if (!formData.values.ptsPlaceID || !firstLoad) {
      getPlaceData();
    }
    setFirstLoad(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [wsClient, placeId]);
  /** endregion */
  /** region Helper Functions */
  const getPlaceData = async () => {
    const service = wsClient?.websocket.service('places');
    service.timeout = 200000;

    if (!placeId) return;
    dispatch(setCircularLoading(true));
    const result = await service.get(placeId);
    if (result) {
      dispatch(setCircularLoading(false));
    }

    setFormData({
      values: {
        ptsPlaceID: result.ptsPlaceID,
        PlaceName: result.PlaceName,
        PlaceType: result.PlaceType,
      },
    });
  };

  const duplicateFinder = async () => {
    let duplicateFound = await duplicateCheck(formData.values, 'place');
    if (duplicateFound) {
      setDuplicateModal(true);
      setDuplicateModalMessage('Duplciate Found');
    } else {
      setDuplicatePlaceList([]);
      setDuplicateModal(false);
    }
  };

  const handleSave = async () => {
    await duplicateCheck(formData.values, 'place').then((res) => {
      if (!res) {
        upsertRecord('place', formData.values, pageType).finally(() => {
          dispatch(setCircularLoading(false));
          history.goBack();
        });
      } else {
        dispatch(notify('Duplicate Found.', 'warning'));
        dispatch(setCircularLoading(false));
      }
    });
  };

  const onSelectAddress = (place, setFormState, setChangeKey, changeKey) => {
    setFormData({
      values: {
        ...formData.values,
        ptsAddressID: place.ptsAddressID,
      },
    });
  };

  const clearPlace = () => {
    setFormData({
      values: {
        ...formData.values,
        ptsAddressID: '',
      },
    });
  };

  const goToAddAddressPage = () => {
    history.push(`/records/add/address`);
  };

  /** endregion */
  return (
    <div className={classes.root}>
      <Grid container>
        <Grid xs={12} lg={12}>
          <Paper className={classes.paper}>
            <Grid container justify="center">
              <Grid item xs={12} lg={12}>
                <div
                  className="card-header-actions"
                  style={{
                    backgroundColor: '#1976d2',
                    color: 'white',
                    borderRadius: '0.5rem 0.5rem 0 0',
                  }}>
                  <Tabs
                    value={0}
                    indicatorColor="secondary"
                    textColor="secondary"
                    variant="fullWidth"
                    classes={{
                      indicator: classes.indicator,
                    }}>
                    <Tab className={classes.icons} label="Place" style={{ color: 'white' }} />
                  </Tabs>
                  <Divider />
                </div>
              </Grid>
              <Grid item xs={12} lg={12} style={{ padding: '15px' }}>
                <Grid container spacing={1}>
                  {pageType === 'add' && (
                    <React.Fragment>
                      <Grid item xs={12} className={classes.btnStyle}>
                        <RMSAddButton tooltipText="Add Address" onClick={goToAddAddressPage} />
                      </Grid>
                      <Grid item xs={12} spacing={2} className="mt-2 mb-3">
                        <AddressSearch
                          ptsPlaces={true}
                          ptsAddresses={true}
                          googleAddresses={false}
                          onPlaceValueSet={onSelectAddress}
                          onAddressValueSet={onSelectAddress}
                          setFormState={setFormData}
                          label={'Search Addresses'}
                          setChangeKey={setChangeKey}
                          changeKey={changeKey}
                          defaultLocation={null}
                          onReset={clearPlace}
                        />
                      </Grid>
                    </React.Fragment>
                  )}

                  <Grid item xs={4}>
                    <TextField
                      fullWidth
                      autoComplete="hidden"
                      size="small"
                      name="PlaceName"
                      variant="outlined"
                      placeholder="Place Name"
                      label="Place Name"
                      value={formData.values?.PlaceName || ''}
                      onChange={(event) =>
                        handleFormChangeNew(
                          event,
                          event.target.value,
                          'none',
                          'PlaceName',
                          setFormData
                        )
                      }
                      id="formatted-placename-input"
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <Autocomplete
                      size="small"
                      autoComplete
                      autoSelect
                      autoHighlight
                      id="combo-placetype"
                      getOptionLabel={(option) => option || ''}
                      options={codeMethodType}
                      key={formData.values?.PlaceType || ''}
                      value={formData.values?.PlaceType || ''}
                      onChange={(event, newValue) =>
                        handleFormChangeNew(event, newValue, 'none', 'PlaceType', setFormData)
                      }
                      renderInput={(params) => (
                        <Grid container spacing={1} alignItems="flex-end">
                          <Grid item xs={12} lg={12}>
                            <TextField
                              autoComplete="hidden"
                              size="small"
                              {...params}
                              label="Place Type"
                              variant="outlined"
                              fullWidth
                            />
                          </Grid>
                        </Grid>
                      )}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </Grid>

      <RMSFabButtons recordForm={true} handleSaveRecord={handleSave} />
    </div>
  );
};

const mapStateToProps = (state) => ({
  wsClient: state.websocket,
  records: state.records,
});

export default connect(mapStateToProps, {
  upsertRecord,
  setDuplicateModal,
  setDuplicateModalMessage,
  setDuplicatePlaceList,
})(AddEditPlaceRecord);
