export const GET_CODE_VEHICLE_Property = 'GET_CODE_VEHICLE_Property';
export const ADD_CODE_VEHICLE_Property = 'ADD_CODE_VEHICLE_Property';

export const addCodeVehicleProperty = (newVal) => {
  return { type: ADD_CODE_VEHICLE_Property, payload: newVal };
};

export const getCodeVehicleProperty = (state) => {
  return async (dispatch) => {
    return dispatch({ type: GET_CODE_VEHICLE_Property, payload: state });
  };
};

export default function reducer(state = [], action) {
  switch (action.type) {
    case GET_CODE_VEHICLE_Property:
      state = action.payload;
      return state;
    case ADD_CODE_VEHICLE_Property:
      return [...state, action.payload];
    default:
      return state;
  }
}
