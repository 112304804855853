export const codeCriminalActivity = [
  {
    code: 'A',
    description:
      'Simple/Gross Neglect (unintentionally, intentionally, or knowingly failing to provide food, water, shelter, veterinary care, hoarding, etc.)',
  },
  {
    code: 'B',
    description: 'Buying/Receiving',
  },
  {
    code: 'C',
    description: 'Cultivating/Manufacturing/Publishing (i.e., production of any type)',
  },
  {
    code: 'D',
    description: 'Distributing/Selling',
  },
  {
    code: 'E',
    description: 'Exploiting Children',
  },
  {
    code: 'F',
    description: 'Organized Abuse (Dog Fighting and Cock Fighting)',
  },
  {
    code: 'G',
    description: 'Other Gang',
  },
  {
    code: 'I',
    description:
      'Intentional Abuse and Torture (tormenting, mutilating, maiming, poisoning, or abandonment)',
  },
  {
    code: 'J',
    description: 'Juvenile Gang',
  },
  {
    code: 'N',
    description: 'None/Unknown',
  },
  {
    code: 'O',
    description: 'Operating/Promoting/Assisting',
  },
  {
    code: 'P',
    description: 'Possessing/Concealing',
  },
  {
    code: 'S',
    description: 'Animal Sexual Abuse (Bestiality)',
  },
  {
    code: 'T',
    description: 'Transporting/Transmitting/Importing',
  },
  {
    code: 'U',
    description: 'Using/Consuming',
  },
];
