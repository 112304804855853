export const GET_CODE_RACE = 'GET_CODE_RACE';

export const getCodeRace = (state) => {
  return async (dispatch) => {
    return dispatch({ type: GET_CODE_RACE, payload: state });
  };
};

export default function reducer(state = [], action) {
  switch (action.type) {
    case GET_CODE_RACE:
      state = action.payload;
      return state;
    default:
      return state;
  }
}
