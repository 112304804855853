import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Edit';
import Tooltip from '@material-ui/core/Tooltip';
import { sortObjArr } from '../../../../../utils/functions';
import PerfectScrollbar from 'react-perfect-scrollbar';

const useStyles = makeStyles((theme) => ({
  parties: {
    textAlign: 'left',
    overflowY: 'auto',
  },
  table: {
    width: '100%',
    '& td': {
      // borderBottom: `1px solid ${theme.table.borderColor}`,
      paddingLeft: theme.spacing(1),
      paddingTop: 4,
      paddingBottom: 4,
      '&:first-child': {
        paddingLeft: theme.spacing(2),
      },
      '&:last-child': {
        paddingRight: theme.spacing(2),
        position: 'relative',
      },
      '& i': {
        marginRight: '1em',
        fontStyle: 'normal',
      },
    },
    '& strong': {
      fontWeight: 500,
    },
    '& tr': {
      '&:hover': {
        '& $rowActions': {
          opacity: 1,
        },
      },
      borderBottom: '1px solid #D3D3D3',
      color: theme.palette.type === 'light' ? 'rgba(0, 0, 0, 0.87)' : '#d4d6d7',
    },
  },
  th: {
    // backgroundColor: theme.table.nthRowBg,
    borderBottom: '1px solid #D3D3D3',
    color: theme.palette.type === 'light' ? 'rgba(0, 0, 0, 0.87)' : '#d4d6d7',
  },
  warrant: {
    // background: theme.palette.error.main,
    // color: theme.colors.errorContrast,
    '& svg': {
      // color: theme.colors.errorContrast,
    },
  },
  actions: {
    padding: '5px 15px',
    textAlign: 'right',
    // borderBottom: `1px solid ${theme.card.hr}`,
    '& label': {
      marginBottom: 0,
    },
  },
  edit: {
    padding: theme.spacing(2),
  },
  rowActions: {
    position: 'absolute',
    right: 8,
    top: 0,
    border: 'none',
    textAlign: 'right',
    opacity: 0,
    transition: 'opacity 0.3s',
    whiteSpace: 'nowrap',
    '& label': {
      marginBottom: 0,
    },
  },
}));

function Parties(props) {
  const classes = useStyles();
  const { sortOption, data, ptsEventID, Relationships } = props;
  const [callers, setCallers] = useState([]);
  const [persons, setPersons] = useState([]);
  const [places, setPlaces] = useState([]);

  useEffect(() => {
    // Sorting data
    console.log(data);
    if (!data) return;
    setCallers(sortObjArr(data.callers, 'LastName', sortOption));
    setPersons(sortObjArr(data.persons, 'LastName', sortOption));
    setPlaces(sortObjArr(data.places, 'LastName', sortOption));
    // eslint-disable-next-line
  }, [data, sortOption]);

  const renderCallers = () => {
    return (
      <>
        <tr className={classes.th}>
          <td colSpan={3}>
            <strong>Callers</strong>
          </td>
        </tr>
        {callers.map((caller) => (
          <tr key={caller.ptsCallerID}>
            <td>
              {caller.LastName}, {caller.FirstName}
            </td>
            <td>{caller.Relationship}</td>
            <td>
              {caller.AdvisoryText ? <i>{caller.AdvisoryText}</i> : <></>}
              {caller.CallerLocation ? (
                <i>
                  <strong>Location: </strong> {caller.CallerLocation}
                </i>
              ) : (
                <></>
              )}
              {caller.Info ? (
                <i>
                  <strong>Info: </strong>
                  {caller.Info}
                </i>
              ) : (
                <></>
              )}
            </td>
          </tr>
        ))}
      </>
    );
  };

  const renderPersons = () => {
    return (
      <>
        <tr className={classes.th}>
          <td colSpan={3}>
            <strong>Persons</strong>
          </td>
        </tr>
        {persons.map((person, idx) => (
          <tr
            key={'' + person.ptsPlaceID + idx}
            className={person.ActiveWarrant ? classes.warrant : null}>
            <td>
              {person.LastName}, {person.FirstName}
            </td>
            <td>{person.Relationship}</td>
            <td>{person.AdvisoryText}</td>
          </tr>
        ))}
      </>
    );
  };

  const editPlace = (place) => {
    const data = {
      ...place,
      party: 'place',
      PlaceName: place.PlaceName ? place.PlaceName : '',
      AdvisoryText: place.AdvisoryText ? place.AdvisoryText : '',
    };
    const { Relationship } = place;
    if (Relationship) {
      data.Relationship = {
        Code: Relationship,
        Description: Relationships.find((rel) => rel.Code === Relationship).Description,
      };
    }
  };

  const renderPlaces = () => {
    return (
      <>
        <tr className={classes.th}>
          <td colSpan={3}>
            <strong>Places</strong>
          </td>
        </tr>
        {places.map((place, idx) => (
          <tr key={'' + place.ptsPlaceID + idx}>
            <td>{place.PlaceName}</td>
            <td>{place.Relationship}</td>
            <td>
              {place.AdvisoryText}
              <div className={classes.rowActions}>
                <Tooltip title="Edit party">
                  <IconButton
                    color="primary"
                    size="small"
                    component="span"
                    onClick={() => editPlace(place)}>
                    <EditIcon />
                  </IconButton>
                </Tooltip>
              </div>
            </td>
          </tr>
        ))}
      </>
    );
  };

  return (
    <div className={classes.parties}>
      <PerfectScrollbar style={{ maxHeight: window.innerHeight - 500 + 'px' }}>
        <table className={classes.table}>
          <tbody>
            {!!callers.length && renderCallers()}
            {!!persons.length && renderPersons()}
            {!!places.length && renderPlaces()}
          </tbody>
        </table>
      </PerfectScrollbar>
    </div>
  );
}

export default Parties;
