/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { useLocation } from 'react-router-dom';
import { useParams } from 'react-router';
import { connect, useSelector, useDispatch } from 'react-redux';

import 'react-tiny-fab/dist/styles.css';

import clsx from 'clsx';
import PerfectScrollbar from 'react-perfect-scrollbar';
import DateFnsUtils from '@date-io/date-fns';

import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Switch,
  Tooltip,
} from '@material-ui/core';
import { CardContent, List, ListItem } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

import { KeyboardDateTimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DescriptionOutlinedIcon from '@material-ui/icons/DescriptionOutlined';

import AddressListForSummary from 'components/RMSList/RMSIncidentList/IncAddressesList/AddressListForSummary';

import {
  setHandleIncFormSave,
  setHandleAddIncVictim,
  setHandleAddIncProperty,
  setHandleAddIncArrestee,
  setHandleAddIncDVSuspect,
} from '../../../reducers/ui/UiMenuReducer';

import { getDictionary, getSettings } from '../../../reducers/dictionaries/CadReducer';

import {
  getEntitiesByIncidentId,
  setSelectedIncident,
  updateCurrentIncident,
} from '../../../reducers/IncReducer';
import { getCodeWeather } from '../../../reducers/dictionaries/CodeWeatherReducer';
import { getCodeLightning } from '../../../reducers/dictionaries/CodeLightingReducer';
import { getCodeCADStatuses } from '../../../reducers/dictionaries/CodeOthersReducer';
import { setCadEventTimeModalState } from '../../../reducers/ui/UiModalReducer';
import { setShowIncidentStepper } from '../../../reducers/ui/UiMenuReducer';
import { setEvent } from '../../../reducers/IncReducer';
import { assignTempIncidentId } from '../../../reducers/helpers/incHelpers';

import {
  getCodeIncidentCategories,
  getCodeIncidentTypes,
} from '../../../reducers/dictionaries/CodeIncidentReducer';
import { handleFormChangeNew } from '../../../reducers/helpers/formHelpers';

import RMSAutoComplete from 'components/RMSAutoComplete/RMSAutoComplete';
import IncEventDetails from './IncEventDetails';
import RMSEditButton from '../../RMSButtons/RMSEditButton';

import { formatDate } from '../../../utils/functions';

const useStyles = makeStyles((theme) => ({
  summaryDetails: {
    height: window.innerHeight - 95 + 'px',
    position: '-webkit-sticky',
    // eslint-disable-next-line no-dupe-keys
    position: 'sticky',
    top: '100px',
    bottom: 20,
    paddingBottom: '20px',
    marginBottom: '16px',
    overflow: 'hidden',
  },
  summaryHeading: {
    color: 'white',
    display: 'flex',
    justifyContent: 'center',
  },
  summaryHeadingID: {
    color: 'white',
    justifyContent: 'center',
    textAlign: 'center',
    margin: 'auto',
    paddingTop: '3px',
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
  dialogBox: {
    maxWidth: '850px',
    minHeight: '150px',
  },
  dialogBoxForAttachment: {
    minWidth: '700px',
    maxHeight: '850px',
    minHeight: '350px',
  },
  dialogBoxTitle: {
    background: '#1976d2',
  },
  badgeStyle: {
    backgroundColor: '#B6C4D2',
    color: '#0b55a1',
    top: '10px',
    right: '14px',
  },
  switchRoot: {
    border: '1px solid #ddd',
    borderRadius: '10px',
  },
}));

const IncSummaryDetails = (props) => {
  /** region Component Variables and Props */
  const classes = useStyles();
  const location = useLocation();
  const dispatch = useDispatch();
  const {
    incident,
    event,
    updateCurrentIncident,
    codeIncidentCategories,
    codeIncidentTypes,
    codeCADStatuses,
    codeWeather,
    codeLighting,
    getCodeWeather,
    getCodeCADStatuses,
    getCodeIncidentCategories,
    getCodeIncidentTypes,
    getCodeLightning,
    wsClient,
    incidentId,
    getEntitiesByIncidentId,
    history,
    setHandleIncFormSave,
  } = props;
  const [selectedFromDate, setSelectedFromDate] = React.useState('');
  const [openCadEventForm, setOpenCadEventForm] = React.useState(false);
  const [dialog, setDialog] = React.useState('');
  const [selectedToDate, setSelectedToDate] = React.useState();
  const [received, setReceived] = React.useState(null);
  const [dispatched, setDispatched] = React.useState(null);
  const [enroute, setEnroute] = React.useState(null);
  const [changeKey, setChangeKey] = React.useState(null);
  const [arrived, setArrived] = React.useState(null);
  const [underCont, setUnderCont] = React.useState(null);
  const [completed, setCompleted] = React.useState(null);
  const [eventData, setEventData] = React.useState(null);
  const [inputState, setInputState] = React.useState(true);
  const [dataLoaded, setDataLoaded] = React.useState(false);

  const [preIncidentState, setPreIncidentState] = React.useState({
    isValid: false,
    values: {
      occurred: '',
      occurredTo: '',
      received: '',
      dispatched: '',
      enroute: '',
      arrived: '',
      underCont: '',
      completed: '',
      callType: '',
      status: '',
      incidentCategory: '',
      weather: '',
      lighting: '',
    },
    touched: {},
    errors: {},
    formType: 'edit',
    changed: false,
  });
  const [incidentState, setIncidentState] = React.useState({
    isValid: false,
    values: {
      occurred: '',
      occurredTo: '',
    },
    touched: {},
    errors: {},
    formType: 'edit',
    changed: false,
  });
  let { incId } = useParams();
  const showIncidentStepper = useSelector((state) => state.uiMenu.showIncidentStepper);

  const parts = history.location.pathname.split('/');
  let place = '';
  if (parts.length === 3) place = parts[parts.length - 1];
  else if (parts.length === 4) place = parts[parts.length - 2];
  else if (parts.length === 5) place = parts[parts.length - 3];
  /** endregion */
  /**region React hooks */
  React.useEffect(() => {
    if (!wsClient?.websocket) {
      return;
    }
    if (incident.ptsEventId) updateEventData();
  }, [wsClient, incident.ptsEventId]);

  // Event
  const updateEventData = async () => {
    const service = wsClient?.websocket.service('cad');
    service.timeout = 200000;

    const result = await service.get({
      type: 'event-form-details',
      data: { ptsEventID: incident.ptsEventId, Closed: true },
    });

    const serviceEvents = wsClient?.websocket.service('cmplx-events');
    serviceEvents.timeout = 200000;
    serviceEvents.get(incident.ptsEventId).then((event) => {
      console.log('get events', event);
      dispatch(setEvent({ ...result, ...event }));
      setEventData({ ...result, ...event });
    });
  };

  React.useEffect(() => {
    if (wsClient.websocket) {
      dispatch(getSettings());
      dispatch(getDictionary());
      getEntitiesByIncidentId('general');
    }
  }, [incidentId !== 0, wsClient]);

  React.useEffect(() => {
    if (incId) setSelectedIncident(incId);
  }, [incidentId === 0]);

  React.useEffect(() => {
    if (wsClient.websocket) {
      getCodeCADStatuses();
      getCodeIncidentCategories();
      getCodeIncidentTypes();
      getCodeWeather(wsClient.websocket);
      getCodeLightning(wsClient.websocket);
    }
  }, [
    getCodeCADStatuses,
    getCodeIncidentCategories,
    getCodeIncidentTypes,
    getCodeLightning,
    getCodeWeather,
    wsClient,
  ]);

  React.useEffect(() => {
    setIncidentState((incidentState) => ({
      ...incidentState,
      ...incident,
      changed: true,
      values: {
        ...incidentState.values,
        ...incident.values,
      },
    }));

    if (incident.values) {
      setDataLoaded(true);
    }
    if (dataLoaded === true && inputState === true && place !== 'add') {
      setPreIncidentState((incidentState) => ({
        ...incidentState,
        ...incident,
        changed: true,
        values: {
          ...incidentState.values,
          ...incident.values,
        },
      }));

      setInputState(false);
    }
    setSelectedFromDate(incident.values?.occurred);
    setSelectedToDate(incident.values?.occurredTo);
    setReceived(incident.values?.received);
    setDispatched(incident.values?.dispatched);
    setEnroute(incident.values?.enroute);
    setArrived(incident.values?.arrived);
    setUnderCont(incident.values?.underCont);
    setCompleted(incident.values?.completed);
  }, [
    incident?.values?.callType,
    incident?.values?.status,
    incident?.values?.incidentCategory,
    incident?.values?.weather,
    incident?.values?.lighting,
    incident?.values?.locationCategory,
    incident?.values?.locationName,
    incident?.values?.locationCrossStreet,
    incident?.values?.locationHighway,
    incident?.values?.locationLandmarkText,
    incident?.values?.locationSubdivision,
    incident?.ptsIncidentId,
    incident?.ptsEventId,
    incident.values?.received,
    incident.values?.dispatched,
    incident.values?.enroute,
    incident.values?.arrived,
    incident.values?.underCont,
    incident.values?.completed,
    incident.values?.attachments,
  ]);

  React.useEffect(() => {
    updateCurrentIncident(incidentState);
  }, [
    incidentState?.values?.callType,
    incidentState?.values?.status,
    incidentState?.values?.incidentCategory,
    incidentState?.values?.weather,
    incidentState?.values?.lighting,
    incidentState?.values?.locationCategory,
    incidentState?.values?.locationName,
    incidentState?.values?.locationCrossStreet,
    incidentState?.values?.locationHighway,
    incidentState?.values?.locationLandmarkText,
    incidentState?.values?.locationSubdivision,
    incidentState.values?.attachments,
    incidentState?.ptsIncidentId,
    incidentState?.ptsEventId,
    selectedFromDate,
    selectedToDate,
    received,
    dispatched,
    enroute,
    arrived,
    underCont,
    completed,
  ]);
  /** endregion */
  /** region Helper Functions */
  const handleSwitchChange = (event) => {
    dispatch(setShowIncidentStepper(event.target.checked));
  };

  const handleFromDateChange = (date) => {
    let d = new Date(date);
    if (!isNaN(d.valueOf())) {
      let value = d.toISOString();
      setSelectedFromDate(value);
      setIncidentState((incidentState) => ({
        ...incidentState,
        ...(incidentState.values.occurred = value),
      }));
    }
  };

  const handleToDateChange = (date) => {
    let d = new Date(date);
    if (!isNaN(d.valueOf())) {
      let value = d.toISOString();
      setSelectedToDate(value);
      setIncidentState((incidentState) => ({
        ...incidentState,
        ...(incidentState.values.occurredTo = value),
      }));
    }
  };

  const handleReceivedDateChange = (date) => {
    let d = new Date(date);
    if (!isNaN(d.valueOf())) {
      let value = d.toISOString();
      setReceived(value);
      setIncidentState((incidentState) => ({
        ...incidentState,
        ...(incidentState.values.received = value),
      }));
    }
  };

  const handleDispatchedDateChange = (date) => {
    let d = new Date(date);
    if (!isNaN(d.valueOf())) {
      let value = d.toISOString();
      setDispatched(value);
      setIncidentState((incidentState) => ({
        ...incidentState,
        ...(incidentState.values.dispatched = value),
      }));
    }
  };

  const handleEnrouteDateChange = (date) => {
    let d = new Date(date);
    if (!isNaN(d.valueOf())) {
      let value = d.toISOString();
      setEnroute(value);
      setIncidentState((incidentState) => ({
        ...incidentState,
        ...(incidentState.values.enroute = value),
      }));
    }
  };

  const handleArrivedDateChange = (date) => {
    let d = new Date(date);
    if (!isNaN(d.valueOf())) {
      let value = d.toISOString();
      setArrived(value);
      setIncidentState((incidentState) => ({
        ...incidentState,
        ...(incidentState.values.arrived = value),
      }));
    }
  };

  const handleUnderControlDateChange = (date) => {
    let d = new Date(date);
    if (!isNaN(d.valueOf())) {
      let value = d.toISOString();
      setUnderCont(value);
      setIncidentState((incidentState) => ({
        ...incidentState,
        ...(incidentState.values.underCont = value),
      }));
    }
  };

  const handleCompletedDateChange = (date) => {
    let d = new Date(date);
    if (!isNaN(d.valueOf())) {
      let value = d.toISOString();
      setCompleted(value);
      setIncidentState((incidentState) => ({
        ...incidentState,
        ...(incidentState.values.completed = value),
      }));
    }
  };

  const closeDialogBox = () => {
    setOpenCadEventForm(false);
  };

  const openDialogBox = (dialogValue) => {
    setDialog(dialogValue);
    setOpenCadEventForm(true);
  };

  const handleClose = () => {
    closeDialogBox();

    setIncidentState((incidentState) => ({
      ...incidentState,
      ...preIncidentState,
      changed: true,
      values: {
        ...incidentState.values,
        ...preIncidentState.values,
      },
    }));

    setSelectedFromDate(preIncidentState.values.occurred);
    setSelectedToDate(preIncidentState.values.occurredTo);
    setReceived(preIncidentState.values.received);
    setDispatched(preIncidentState.values.dispatched);
    setArrived(preIncidentState.values.arrived);
    setCompleted(preIncidentState.values.completed);
    setUnderCont(preIncidentState.values.underCont);
    setEnroute(preIncidentState.values.enroute);
  };

  const handleSave = () => {
    closeDialogBox();

    updateCurrentIncident(incident);
    if (place === 'add' && !incident.ptsIncidentId) {
      incident.tempIncidentId = assignTempIncidentId();
    }

    setHandleIncFormSave(place).then(() => {
      setPreIncidentState((preincidentState) => ({
        ...preincidentState,
        ...incidentState,
        changed: true,
        values: {
          ...preincidentState.values,
          ...incidentState.values,
        },
      }));
    });
  };
  /** endregion */
  return (
    <Paper className={clsx(classes.summaryDetails, 'mr-3')}>
      <Grid container justify="center">
        <Grid item style={{ minHeight: '70px', background: '#1976d2', width: '100%' }}>
          <Box display="flex" justifyContent="center" alignItems="center" style={{ padding: 15 }}>
            <Box order={1}>
              {incidentState.ptsEventId && (
                <Tooltip title="Event Details">
                  <IconButton
                    onClick={() => openDialogBox('attachment')}
                    color="secondary"
                    aria-label="Event Details">
                    <DescriptionOutlinedIcon
                      style={{ color: 'white', marginRight: '6px' }}
                      fontSize="medium"
                    />
                  </IconButton>
                </Tooltip>
              )}
            </Box>
            <Box order={2} flexGrow={1}>
              <Typography variant="h4" className={classes.summaryHeading}>
                Incident Details
              </Typography>
              <Typography variant="h6" className={classes.summaryHeadingID}>
                {incident.CustomId || incidentState.CustomId}
              </Typography>
            </Box>
            <Box order={3}>
              <Dialog
                classes={{
                  paperWidthSm: classes.dialogBox,
                }}
                open={openCadEventForm}
                onClose={closeDialogBox}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description">
                <DialogTitle
                  className={'d-flex justify-content-between align-items-center'}
                  id="alert-dialog-title"
                  style={{ background: '#1976d2' }}>
                  <span style={{ fontSize: '18px', color: 'white' }}>
                    {dialog === 'attachment' && <div>Event Details - {event?.EventID} </div>}
                    {dialog === 'edit' && <div>Edit Incident Details</div>}
                  </span>
                </DialogTitle>
                <DialogContent>
                  <DialogContentText id="alert-dialog-description">
                    {dialog === 'attachment' && <IncEventDetails event={event} />}
                    {dialog === 'edit' && (
                      <Grid container justify="center" style={{ padding: '4px 20px 0px 5px' }}>
                        <Grid item xs={12} lg={12}>
                          <Grid container spacing={2} style={{ margin: '0px' }}>
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                              <Grid item xs={6} lg={6}>
                                <KeyboardDateTimePicker
                                  size="small"
                                  inputVariant="outlined"
                                  fullWidth
                                  showTodayButton={true}
                                  variant="dialog"
                                  format="MM/dd/yyyy hh:mm a"
                                  margin="normal"
                                  id="date-picker-inline-from"
                                  label="Incident Occurred From"
                                  key={changeKey}
                                  minDate={'1991-01-01'}
                                  value={
                                    selectedFromDate === ''
                                      ? new Date().toISOString()
                                      : selectedFromDate
                                  }
                                  onChange={handleFromDateChange}
                                />
                              </Grid>
                              <Grid item xs={6} lg={6}>
                                <KeyboardDateTimePicker
                                  size="small"
                                  inputVariant="outlined"
                                  fullWidth
                                  showTodayButton={true}
                                  variant="dialog"
                                  format="MM/dd/yyyy hh:mm a"
                                  margin="normal"
                                  id="date-picker-inline-to"
                                  label="Incident Occurred To"
                                  key={changeKey}
                                  value={
                                    selectedToDate === ''
                                      ? new Date().toISOString()
                                      : selectedToDate
                                  }
                                  onChange={handleToDateChange}
                                  KeyboardButtonProps={{
                                    'aria-label': 'change date',
                                  }}
                                />
                              </Grid>
                            </MuiPickersUtilsProvider>
                          </Grid>
                          <Grid container spacing={2} className="m-0">
                            <Grid item xs={6} lg={6}>
                              <RMSAutoComplete
                                size="small"
                                id="combo-event-types"
                                autoComplete
                                autoSelect
                                autoHighlight
                                options={codeIncidentTypes}
                                label="Category"
                                serviceName="codeincidenttypes"
                                title="Description"
                                stateHolderAttribute="callType"
                                variant="outlined"
                                stateHolder={incidentState}
                                setStateHolder={setIncidentState}
                                setField={handleFormChangeNew}
                                // value={{ Description: incidentState?.values?.callType } || ''}
                              />
                            </Grid>
                            <Grid item xs={6} lg={6}>
                              <RMSAutoComplete
                                size="small"
                                id="combo-status"
                                autoComplete
                                autoSelect
                                autoHighlight
                                options={codeCADStatuses}
                                label="Status"
                                serviceName="codecadstatuses"
                                variant="outlined"
                                title={'Description'}
                                stateHolder={incidentState}
                                setStateHolder={setIncidentState}
                                setField={handleFormChangeNew}
                                stateHolderAttribute="status"
                              />
                            </Grid>
                            <Grid item xs={6} lg={6}>
                              <RMSAutoComplete
                                size="small"
                                id="combo-category"
                                autoComplete
                                autoSelect
                                autoHighlight
                                options={codeIncidentCategories}
                                label="Event Type"
                                // value={{ Description: incidentState?.values?.finalDisposition } || ''}
                                serviceName="codeincidentcategories"
                                variant="outlined"
                                title={'Description'}
                                stateHolder={incidentState}
                                setStateHolder={setIncidentState}
                                setField={handleFormChangeNew}
                                stateHolderAttribute="incidentCategory"
                              />
                            </Grid>
                            <Grid item xs={6} lg={6}>
                              <RMSAutoComplete
                                size="small"
                                autoComplete
                                autoSelect
                                autoHighlight
                                id="combo-weather"
                                options={codeWeather}
                                // value={{ Description: incidentState?.values?.weather } || ''}
                                label="Weather"
                                serviceName="codeweather"
                                variant="outlined"
                                title={'Description'}
                                stateHolder={incidentState}
                                setStateHolder={setIncidentState}
                                setField={handleFormChangeNew}
                                stateHolderAttribute="weather"
                              />
                            </Grid>
                          </Grid>
                          <Grid container spacing={2} className="m-0">
                            <Grid item xs={6} lg={6}>
                              <RMSAutoComplete
                                size="small"
                                autoComplete
                                autoSelect
                                autoHighlight
                                id="combo-lighting"
                                options={codeLighting}
                                label="Lighting"
                                serviceName="codelighting"
                                variant="outlined"
                                title={'Description'}
                                stateHolder={incidentState}
                                setStateHolder={setIncidentState}
                                setField={handleFormChangeNew}
                                stateHolderAttribute="lighting"
                              />
                            </Grid>

                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                              <Grid item xs={6} lg={6} style={{ marginTop: '-16px' }}>
                                <KeyboardDateTimePicker
                                  size="small"
                                  inputVariant="outlined"
                                  fullWidth
                                  showTodayButton={true}
                                  variant="dialog"
                                  format="MM/dd/yyyy hh:mm a"
                                  margin="normal"
                                  id="date-picker-inline-from"
                                  label="Received Date Time"
                                  key={changeKey}
                                  value={received === '' ? new Date().toISOString() : received}
                                  onChange={handleReceivedDateChange}
                                />
                              </Grid>
                              <Grid item xs={6} lg={6} style={{ marginTop: '-22px' }}>
                                <KeyboardDateTimePicker
                                  size="small"
                                  inputVariant="outlined"
                                  fullWidth
                                  showTodayButton={true}
                                  variant="dialog"
                                  format="MM/dd/yyyy hh:mm a"
                                  margin="normal"
                                  id="date-picker-inline-to"
                                  label="Dispatched Date Time"
                                  key={changeKey}
                                  value={dispatched === '' ? new Date().toISOString() : dispatched}
                                  onChange={handleDispatchedDateChange}
                                  KeyboardButtonProps={{
                                    'aria-label': 'change date',
                                  }}
                                />
                              </Grid>

                              <Grid item xs={6} lg={6} style={{ marginTop: '-22px' }}>
                                <KeyboardDateTimePicker
                                  size="small"
                                  inputVariant="outlined"
                                  fullWidth
                                  showTodayButton={true}
                                  variant="dialog"
                                  format="MM/dd/yyyy hh:mm a"
                                  margin="normal"
                                  id="date-picker-inline-from"
                                  label="Enroute Date Time"
                                  key={changeKey}
                                  value={enroute === '' ? new Date().toISOString() : enroute}
                                  onChange={handleEnrouteDateChange}
                                />
                              </Grid>
                              <Grid item xs={6} lg={6} style={{ marginTop: '-22px' }}>
                                <KeyboardDateTimePicker
                                  size="small"
                                  inputVariant="outlined"
                                  fullWidth
                                  showTodayButton={true}
                                  variant="dialog"
                                  format="MM/dd/yyyy hh:mm a"
                                  margin="normal"
                                  id="date-picker-inline-to"
                                  label="Arrived Date Time"
                                  key={changeKey}
                                  value={arrived === '' ? new Date().toISOString() : arrived}
                                  onChange={handleArrivedDateChange}
                                  KeyboardButtonProps={{
                                    'aria-label': 'change date',
                                  }}
                                />
                              </Grid>

                              <Grid item xs={6} lg={6} style={{ marginTop: '-22px' }}>
                                <KeyboardDateTimePicker
                                  size="small"
                                  inputVariant="outlined"
                                  fullWidth
                                  showTodayButton={true}
                                  variant="dialog"
                                  format="MM/dd/yyyy hh:mm a"
                                  margin="normal"
                                  id="date-picker-inline-from"
                                  label="Under Control Date Time"
                                  key={changeKey}
                                  value={underCont === '' ? new Date().toISOString() : underCont}
                                  onChange={handleUnderControlDateChange}
                                />
                              </Grid>
                              <Grid item xs={6} lg={6} style={{ marginTop: '-22px' }}>
                                <KeyboardDateTimePicker
                                  size="small"
                                  inputVariant="outlined"
                                  fullWidth
                                  showTodayButton={true}
                                  variant="dialog"
                                  format="MM/dd/yyyy hh:mm a"
                                  margin="normal"
                                  id="date-picker-inline-to"
                                  label="Completed Date Time"
                                  key={changeKey}
                                  value={completed === '' ? new Date().toISOString() : completed}
                                  onChange={handleCompletedDateChange}
                                  KeyboardButtonProps={{
                                    'aria-label': 'change date',
                                  }}
                                />
                              </Grid>
                            </MuiPickersUtilsProvider>
                          </Grid>
                        </Grid>
                      </Grid>
                    )}
                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <Grid
                        container
                        direction="row"
                        justify="flex-end"
                        alignItems="center"
                        style={{ padding: 5, margin: '-15px 0px 10px' }}>
                        <Button onClick={handleClose} color="primary" style={{ marginRight: 5 }}>
                          Close
                        </Button>
                        {dialog === 'edit' && (
                          <Button
                            onClick={handleSave}
                            style={{ background: '#1976d2', color: '#fff' }}
                            variant="contained">
                            Save
                          </Button>
                        )}
                      </Grid>
                    </Grid>
                  </Grid>
                </DialogActions>
              </Dialog>

              <Switch
                classes={{
                  root: classes.switchRoot,
                }}
                checked={showIncidentStepper}
                onChange={(e) => handleSwitchChange(e)}
                value="checkedA"
                inputProps={{ 'aria-label': 'secondary checkbox' }}
              />
            </Box>
          </Box>
        </Grid>
      </Grid>
      <PerfectScrollbar style={{ height: '72vh', overflowX: 'hidden', paddingBottom: '5px' }}>
        <Grid container justify="center">
          <Grid item xs={12} lg={12}>
            <CardContent className="p-0">
              <List>
                <ListItem className=" d-flex justify-content-between align-items-center border-0">
                  <div className="font-weight-bold flex-grow-1">
                    <div className="text-second font-size-md">Occured From</div>
                    <span className="opacity-8">
                      <b className="pr-1">{formatDate(incidentState.values.occurred)}</b>
                    </span>
                  </div>
                  <div className="text-right">
                    <RMSEditButton
                      tooltipText="Edit Details"
                      onClick={() => openDialogBox('edit')}
                    />
                  </div>
                </ListItem>
                <ListItem className="d-flex justify-content-between align-items-center border-0">
                  <div className="font-weight-bold flex-grow-1">
                    <div className="text-second font-size-md">Occured To</div>
                    <span className="opacity-8">
                      <b className="pr-1">{formatDate(incidentState.values.occurredTo)}</b>
                    </span>
                  </div>
                  <div className="text-right font-weight-bold">
                    <div className="text-second font-size-md">Received</div>
                    <span className="opacity-8">
                      <b className="pr-1">{formatDate(incidentState.values.received)}</b>
                    </span>
                  </div>
                </ListItem>
                <ListItem className="d-flex justify-content-between align-items-center border-0">
                  <div className="font-weight-bold flex-grow-1">
                    <div className="text-second font-size-md">Dispatched</div>
                    <span className="opacity-8">
                      <b className="pr-1">{formatDate(incidentState.values.dispatched)}</b>
                    </span>
                  </div>
                  <div className="text-right font-weight-bold">
                    <div className="text-second font-size-md">Enroute</div>
                    <span className="opacity-8">
                      <b className="pr-1">{formatDate(incidentState.values.enroute)}</b>
                    </span>
                  </div>
                </ListItem>
                <ListItem className="d-flex justify-content-between align-items-center border-0">
                  <div className="font-weight-bold flex-grow-1">
                    <div className="text-second font-size-md">Arrived</div>
                    <span className="opacity-8">
                      <b className="pr-1">{formatDate(incidentState.values.arrived)}</b>
                    </span>
                  </div>
                  <div className="text-right font-weight-bold">
                    <div className="text-second font-size-md">Under Control</div>
                    <span className="opacity-8">
                      <b className="pr-1">{formatDate(incidentState.values.underCont)}</b>
                    </span>
                  </div>
                </ListItem>
                <ListItem className="d-flex justify-content-between align-items-center border-0">
                  <div className="font-weight-bold flex-grow-1">
                    <div className="text-second font-size-md">Completed</div>
                    <span className="opacity-8">
                      <b className="pr-1">{formatDate(incidentState.values.completed)}</b>
                    </span>
                  </div>
                  <div className="text-right font-weight-bold">
                    <div className="text-second font-size-md">Category</div>
                    <span className="opacity-8">
                      <b className="pr-1">{incident?.values?.callType || '-'}</b>
                    </span>
                  </div>
                </ListItem>
                <ListItem className="d-flex justify-content-between align-items-center border-0">
                  <div className="font-weight-bold flex-grow-1">
                    <div className="text-second font-size-md">Status</div>
                    <span className="opacity-8">
                      <b className="pr-1">{incident?.values?.status || '-'}</b>
                    </span>
                  </div>
                  <div className="text-right font-weight-bold">
                    <div className="text-second font-size-md">Event Type</div>
                    <span className="opacity-8">
                      <b className="pr-1">{incident?.values?.incidentCategory || '-'}</b>
                    </span>
                  </div>
                </ListItem>
                <ListItem className="d-flex justify-content-between align-items-center border-0">
                  <div className="font-weight-bold flex-grow-1">
                    <div className="text-second font-size-md">Weather</div>
                    <span className="opacity-8">
                      <b className="pr-1">{incident?.values?.weather || '-'}</b>
                    </span>
                  </div>
                  <div className="text-right font-weight-bold">
                    <div className="text-second font-size-md">Lighting</div>
                    <span className="opacity-8">
                      <b className="pr-1">{incident?.values?.lighting || '-'}</b>
                    </span>
                  </div>
                </ListItem>
              </List>
            </CardContent>
          </Grid>
        </Grid>

        {location.pathname.split('/')[4] !== 'addresses' &&
          location.pathname.split('/')[3] !== 'addresses' && (
            <div className=" mt-2 mb-2 pb-2 w-100">
              <AddressListForSummary entityFlag="addresses" />
            </div>
          )}
      </PerfectScrollbar>
    </Paper>
  );
};

const mapStateToProps = (store) => ({
  handleClose: store.uiMenu.handleCloseState,
  incident: store.incident.incident,
  event: store.incident.event,
  state: store.uiList.incListHeaderState,
  selectedTab: store.uiForm.incGeneralTabState,
  incidentId: store.incident.ptsIncidentId,
  wsClient: store.websocket,
  codeCADStatuses: store.codesOthers.codeCADStatuses,
  codeIncidentTypes: store.codesIncident.codeIncidentTypes,
  codeIncidentCategories: store.codesIncident.codeIncidentCategories,
  codeWeather: store.codeWeather,
  codeLighting: store.codeLighting,
  addEntityForm: store.addEntityForm,
  showCadEventTimeModal: store.uiModal.showCadEventTimeModal,
});

export default connect(mapStateToProps, {
  setHandleIncFormSave,
  updateCurrentIncident,
  setSelectedIncident,
  setHandleAddIncVictim,
  setHandleAddIncProperty,
  setHandleAddIncArrestee,
  setHandleAddIncDVSuspect,
  getCodeWeather,
  getCodeCADStatuses,
  getCodeIncidentCategories,
  getCodeIncidentTypes,
  getCodeLightning,
  getEntitiesByIncidentId,
  setCadEventTimeModalState,
})(IncSummaryDetails);
