/* eslint-disable array-callback-return */
import React from 'react';
import { connect } from 'react-redux';

import clsx from 'clsx';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Grid from '@material-ui/core/Grid';
import {
  AccordionSummary,
  AccordionDetails,
  Accordion,
  Checkbox,
  Fade,
  lighten,
  Tooltip,
  Typography,
  ListItem,
  ListItemIcon,
  ListItemText,
  Avatar,
} from '@material-ui/core';
import makeStyles from '@material-ui/core/styles/makeStyles';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import EmojiTransportationIcon from '@material-ui/icons/EmojiTransportation';
import LocationCityIcon from '@material-ui/icons/LocationCity';

import { colors } from '../../config/colorVariables';
import RMSDeleteButton from 'components/RMSButtons/RMSDeleteButton';
import { updateIncMPRelatedRecords } from '../../reducers/IncReducer';
import { setListBodyCells } from '../../reducers/ui/UiListReducer';
import { removeSelectedEntity, setSelectedEntity } from '../../reducers/IncReducer';

const useStyles = makeStyles((theme) => ({
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: colors.cyan,
    marginRight: theme.spacing(2),
  },
  listItemRoot: {
    listStyle: 'none',
    '&:hover': {
      backgroundColor: lighten(theme.palette.secondary.light, 0.85),
    },
  },
  accordinTypo: {
    width: '100%',
  },
  accordinRoot: {
    backgroundColor: 'rgba(0, 0, 0, .03)',
    borderBottom: '1px solid rgba(0, 0, 0, .125)',
    marginBottom: -1,
    minHeight: 56,
    '&$expanded': {
      minHeight: 56,
    },
  },
}));

export const PropertyAvatar = (props) => {
  const { label } = props;
  const classes = useStyles();
  return <Avatar className={clsx(classes.avatar)}>{label}</Avatar>;
};

const TabRelatedRecords = (props) => {
  /** region Component Variables and Props */
  const classes = useStyles();
  const {
    properties,
    addresses,
    parties,
    updateIncMPRelatedRecords,
    partiesPersonForm,
    mpRelatedRecords,
    setSelectedEntity,
    removeSelectedEntity,
  } = props;
  const [checked, setChecked] = React.useState({
    property: [],
    party: [],
    address: [],
  });
  const [selected, setSelected] = React.useState([]);
  const [expanded, setExpanded] = React.useState('');
  const entities = [
    {
      name: 'party',
      data: parties,
      id: 'ptsIncPersonId',
      icon: (
        <FontAwesomeIcon
          icon="user"
          style={{ fontSize: '14px', marginBottom: '1px', marginRight: '7px' }}
        />
      ),
    },
    {
      name: 'property',
      data: properties,
      id: 'incIncidentPropertyId',
      icon: (
        <EmojiTransportationIcon
          style={{ fontSize: '14px', marginBottom: '1px', marginRight: '7px' }}
        />
      ),
    },
    {
      name: 'address',
      data: addresses,
      id: 'ptsAddressId',
      icon: (
        <LocationCityIcon style={{ fontSize: '14px', marginBottom: '1px', marginRight: '7px' }} />
      ),
    },
  ];
  /** endregion */
  /** region React Hooks */
  React.useEffect(() => {
    if (mpRelatedRecords && Object.keys(mpRelatedRecords).length > 0) setChecked(mpRelatedRecords);
  }, [mpRelatedRecords]);

  /** endregion */
  /** region Helper Functions */
  const handleToggle = (value, entity) => () => {
    if (!entity) return;
    const currentIndex = checked[entity].indexOf(value);
    const newChecked = {
      ...checked,
    };

    if (currentIndex === -1) {
      newChecked[entity].push(value);
    } else {
      newChecked[entity].splice(currentIndex, 1);
    }

    setChecked(newChecked);
    updateIncMPRelatedRecords(newChecked);
  };

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  const isItemSelected = (id) => {
    return selected[0] === id;
  };

  const handleDelete = async (flag) => {
    const f = flag === 'party' ? 'parties' : flag === 'property' ? 'properties' : 'addresses';
    await removeSelectedEntity(f);
    await setListBodyCells(f);
  };
  /** endregion */
  return (
    <div className="mt-4 ml-2 mr-2">
      {entities.map((en) => {
        return (
          <Accordion square expanded={expanded === en.name} onChange={handleChange(en.name)}>
            <AccordionSummary
              aria-controls={`${en.name}-content`}
              id={`${en.name}-header`}
              expandIcon={<ExpandMoreIcon />}
              className={classes.accordinRoot}>
              <Typography>
                {en.icon} {en.name.charAt(0).toUpperCase() + en.name.slice(1)}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography className={classes.accordinTypo}>
                {en.data &&
                  en.data.map((item, i) => {
                    const labelId = `checkbox-list-secondary-label-${item[en.id]}`;
                    let displayText, displayIcon;

                    if (en.name === 'party') {
                      displayText = `${item.personDetail.values.firstName}${
                        item.personDetail.values.middleName
                          ? ` ${item.personDetail.values.middleName} `
                          : ' '
                      }${item.personDetail.values.lastName}`;
                      displayIcon = item.personDetail.values.partyType;
                    } else if (en.name === 'property') {
                      displayText = item?.propertyDetails.values.description;
                      displayIcon = item?.propertyType;
                      item[en.id] = item?.propertyDetails.incIncidentPropertyId;
                    } else {
                      displayText = item.addressDetails.values.fullAddressText;
                      displayIcon = 'Address';
                    }

                    if (en.name === 'party' && item[en.id] === partiesPersonForm.ptsIncPersonId)
                      return;
                    else
                      return (
                        <ListItem key={item[en.id]} button className={classes.listItemRoot}>
                          <ListItemIcon>
                            <PropertyAvatar label={displayIcon} />
                          </ListItemIcon>
                          <div>
                            <ListItemText primary={displayText} />
                          </div>
                          <Fade in={selected.length > 0 && isItemSelected(item[en.id])}>
                            <Grid container wrap="nowrap" spacing={2} justify="flex-end">
                              <Tooltip title="Delete">
                                <Grid item className="mt-2 mr-2">
                                  <RMSDeleteButton onClick={() => handleDelete(en.name)} />
                                </Grid>
                              </Tooltip>
                            </Grid>
                          </Fade>
                          <Checkbox
                            style={{
                              fontSize: '18px',
                              marginBottom: '1px',
                              marginRight: '7px',
                            }}
                            edge="end"
                            onChange={handleToggle(item[en.id], en.name)}
                            checked={checked[en.name]?.indexOf(item[en.id]) !== -1}
                            inputProps={{ 'aria-labelledby': labelId }}
                          />
                        </ListItem>
                      );
                  })}
              </Typography>
            </AccordionDetails>
          </Accordion>
        );
      })}
    </div>
  );
};

const mapStateToProps = (state) => ({
  properties: state.incident.properties,
  addresses: state.incident.addresses,
  parties: state.incident.parties,
  mpRelatedRecords: state.incident.mpRelatedRecords,
  partiesPersonForm: state.incident.partiesPersonForm,
  selectedIncident: state.incident.ptsIncidentId,
});

export default connect(mapStateToProps, {
  removeSelectedEntity,
  updateIncMPRelatedRecords,
  setSelectedEntity,
  setListBodyCells,
})(TabRelatedRecords);
