import React from 'react';
import { connect } from 'react-redux';
import { useParams } from 'react-router';

import PropTypes from 'prop-types';
import clsx from 'clsx';
import { faCar } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { Grid, Paper, styled } from '@material-ui/core';
import FingerprintIcon from '@material-ui/icons/Fingerprint';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Step, StepLabel, Stepper, Typography } from '@material-ui/core';

import TabVehicleDetails from '../PageRMSProperties/PageRMSVehicle/TabVehicleDetails';
import RMSFabButtons from '../../components/RMSForms/RMSIncidentForm/RMSFabButtons';
import {
  updateIncPropertyVehicleDetailsForm,
  updateIncPropertyCredentialsAnalysisForm,
  initFormModel,
} from '../../reducers/IncReducer';
import { setOffensesTab } from '../../reducers/ui/UiFormReducer';
import { setHandleClose, setMenuType } from '../../reducers/ui/UiMenuReducer';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    height: window.innerHeight - 90 + 'px',
  },
  scrollBar: {
    width: '100%',
    overflowX: 'hidden',

    paddingBottom: '5px',
    [theme.breakpoints.up('lg')]: {
      height: '70vh',
    },
    [theme.breakpoints.down('lg')]: {
      height: '45vh',
    },
    [theme.breakpoints.only('lg')]: {
      height: '65vh',
    },
  },
  paper: {
    minHeight: window.innerHeight - 95 + 'px',
  },
  dialogBox: {
    minWidth: '412px',
    minHeight: '150px',
  },
  indicator: {
    backgroundColor: 'rgba(0,0,254,0.4)',
  },
  icons: {
    textDecoration: 'capitalize',
    padding: '14px',
    fontSize: '13px',
  },
}));

const StyledStepLabel = styled(StepLabel)({
  cursor: 'pointer',

  '& .MuiStepLabel-label': {
    color: '#ccc',
    padding: '0px',
    marginTop: '0px',
  },
  '& .MuiStepLabel-active': {
    color: 'white',
  },
});

const useColorlibStepIconStyles = makeStyles({
  root: {
    zIndex: 1,
    color: '#ccc',
    width: 30,
    height: 30,
    display: 'flex',
    borderRadius: '50%',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: '10px',
  },
  active: {
    color: 'black',
    backgroundColor: 'whiteSmoke',
  },
});

function ColorlibStepIcon(props) {
  const classes = useColorlibStepIconStyles();
  const { active } = props;

  const icons = {
    1: <FontAwesomeIcon icon={faCar} />,
    2: <FingerprintIcon style={{ fontSize: '17px' }} />,
  };

  return (
    <div
      className={clsx(classes.root, {
        [classes.active]: active,
      })}>
      {icons[String(props.icon)]}
    </div>
  );
}

ColorlibStepIcon.propTypes = {
  active: PropTypes.bool,
  completed: PropTypes.bool,
  icon: PropTypes.node,
};

const AddEditVehicle = (props) => {
  /** region Component Variables and Props */
  const classes = useStyles();
  const {
    history,
    setHandleClose,
    setMenuType,
    properties,
    selectedIncident,
    selectedEntity,
    updateIncPropertyVehicleDetailsForm,
    updateIncPropertyCredentialsAnalysisForm,
  } = props;
  const [value, setValue] = React.useState(0);
  let { vehicleId } = useParams();
  const [firstLoad, setFirstLoad] = React.useState(true);

  /** endregion */
  /** region Helper Functions */
  const handleChange = (newValue) => {
    setValue(newValue);
  };
  /** region React Hooks */
  React.useEffect(() => {
    if (!firstLoad) {
      setValue(0);
      updateIncPropertyVehicleDetailsForm(initFormModel);
      updateIncPropertyCredentialsAnalysisForm(initFormModel);
    }
    setFirstLoad(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [vehicleId]);

  React.useEffect(() => {
    setMenuType('forms');
    setHandleClose('incForms', history);

    if (!selectedIncident || !selectedEntity || properties.length === 0) {
      return;
    }

    const { incIncidentPropertyId } = selectedEntity;
    const property = properties.filter(
      (e) => e.propertyDetails.incIncidentPropertyId === incIncidentPropertyId
    );

    if (property.length === 0) return;
    let { propertyDetails } = property[0];
    propertyDetails = {
      ...propertyDetails,
      values: {
        ...propertyDetails.values,
        vin: propertyDetails.values.vin,
        VINAnalysisText: propertyDetails.values.vinAnalysisText,
        propertyId: propertyDetails.values.propertyID,
        commercialId: propertyDetails.values.commercialID,
        cmv: propertyDetails.values.isCMVIndicator,
        emissionInspection: propertyDetails.values.emissionInspectionQty,
        plateNumber: propertyDetails.values.PlateNumber,
        propertyCode: propertyDetails.values.PropertyCode,
        plateStatus: propertyDetails.values.PlateStatus,
        plateColor: propertyDetails.values.PlateColor,
        plateType: propertyDetails.values.PlateType,
        plateExpiration: propertyDetails.values.PlateExpiration,
        plateDetail: propertyDetails.values.PlateDetail,
        plateBackgroundText: propertyDetails.values.PlateBackgroundText,
      },
    };

    updateIncPropertyVehicleDetailsForm(propertyDetails);
    updateIncPropertyCredentialsAnalysisForm(propertyDetails);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedIncident]);
  const steps = [{ label: 'Vehicle Details' }];

  const isStepOptional = () => {
    return false;
  };
  /** endregion */
  return (
    <div className={classes.root}>
      <RMSFabButtons />
      <Grid container>
        <Grid xs={12} lg={12}>
          <Paper className={classes.paper}>
            <Grid container justify="center">
              <Grid item xs={12} lg={12}>
                <Box className={classes.stepper}>
                  <Stepper
                    alternativeLabel
                    nonLinear
                    activeStep={value}
                    style={{
                      backgroundColor: '#1976d2',
                      borderRadius: '0.5rem',
                      padding: '15px 5px',
                    }}>
                    {steps.map((obj, index) => {
                      const stepProps = {};
                      const buttonProps = {};
                      if (isStepOptional(index)) {
                        buttonProps.optional = <Typography variant="caption">Optional</Typography>;
                      }
                      return (
                        <Step key={obj.label} {...stepProps}>
                          <StyledStepLabel
                            StepIconComponent={ColorlibStepIcon}
                            onClick={() => handleChange(index)}>
                            {obj.label}
                          </StyledStepLabel>
                        </Step>
                      );
                    })}
                  </Stepper>
                </Box>
              </Grid>
              <PerfectScrollbar className={classes.scrollBar}>
                <Grid item xs={12} lg={12} style={{ padding: '15px' }}>
                  <TabVehicleDetails history={history} />
                </Grid>
              </PerfectScrollbar>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    </div>
  );
};

const mapStateToProps = (state) => ({
  state: state,
  properties: state.incident.properties,
  selectedEntity: state.incident.selectedEntity,
  selectedIncident: state.incident.ptsIncidentId,
  showUCRAlertModal: state.uiModal.showUCRAlertModal,
  recordActive: state.records.recordActive,
});

export default connect(mapStateToProps, {
  setHandleClose,
  setMenuType,
  updateIncPropertyVehicleDetailsForm,
  updateIncPropertyCredentialsAnalysisForm,
  setOffensesTab,
})(AddEditVehicle);
