import React from 'react';
import { useHistory } from 'react-router';
import { connect } from 'react-redux';

import 'date-fns';
import clsx from 'clsx';
import { Fab } from '@material-ui/core';
import Fade from '@material-ui/core/Fade';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Tooltip from '@material-ui/core/Tooltip';
import { makeStyles } from '@material-ui/core/styles';
import TableContainer from '@material-ui/core/TableContainer';
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';

import EnhancedTableToolbar from '../../EnhancedTableToolbar';
import EnhancedTableHead from '../../EnhancedTableHead';
import { setListBodyCells } from '../../../../reducers/ui/UiListReducer';
import RMSPersonCell from '../../RMSEnhancedCells/RMSPersonCell';
import RMSPropertiesCell from '../../RMSEnhancedCells/RMSPropertyCell';
import RMSAddressCell from '../../RMSEnhancedCells/RMSAddressCell';
import RMSOffenseCell from '../../RMSEnhancedCells/RMSOffenseCell';
import RMSEvidenceCell from '../../RMSEnhancedCells/RMSEvidenceCell';
import RMSNarrativeCell from '../../RMSEnhancedCells/RMSNarrativeCell';
import RMSEditButton from '../../../RMSButtons/RMSEditButton';
import RMSDeleteButton from '../../../RMSButtons/RMSDeleteButton';
import {
  setSelectedEntity,
  getEntitiesByIncidentId,
  removeSelectedEntity,
  resetOffenseLists,
  setCurrentOffense,
  resetSubForms,
  updateIncPartiesPersonForm,
  updateIncPartiesContactForm,
  updateIncPartiesEmploymentForm,
  updateIncOffensesMPBasicForm,
  updateIncMPRelatedRecords,
  updateIncPartiesOthersForm,
} from '../../../../reducers/IncReducer';

const useStyles = makeStyles((theme) => ({
  root: {
    boxSizing: 'border-box',
  },
  paper: {
    marginBottom: theme.spacing(2),
    width: '100%',
    backgroundColor: '#fff',
  },
  table: {
    padding: '0px',
    overflowX: 'hidden',
    width: '100%',
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  margin: {
    margin: theme.spacing(1),
  },
  button: {
    margin: theme.spacing(1),
  },
  incidentSelector: {
    '&:hover': {
      cursor: 'pointer',
    },
  },
  spinnerLoading: {
    textAlign: 'center',
    top: '50%',
    left: '45%',
  },
}));

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const AddressListForSummary = (props) => {
  /*Region: Props and State*/
  const {
    bodyState,
    entityFlag,
    selectedIncident,
    wsClient,
    setSelectedEntity,
    removeSelectedEntity,
    setListBodyCells,
    getEntitiesByIncidentId,
    updateIncPartiesPersonForm,
    updateIncPartiesContactForm,
    updateIncPartiesEmploymentForm,
    updateIncMPRelatedRecords,
    updateIncOffensesMPBasicForm,
    updateIncPartiesOthersForm,
    parties,
    selectedEntity,
    searchKeyword,
  } = props;

  const classes = useStyles();

  const [order, setOrder] = React.useState('asc');
  const [spinnerLoading, setSpinnerLoading] = React.useState({
    parties: true,
    addresses: true,
    properties: true,
    offenses: true,
    evidence: true,
  });
  const [orderBy, setOrderBy] = React.useState('calories');
  const [selected, setSelected] = React.useState([]);
  const [rows, setRows] = React.useState([]);
  const [rowKeyProperty, setRowKeyProperty] = React.useState();
  const [parentType, setParentType] = React.useState(null);
  const history = useHistory();
  /** endregion */
  /** region React Hooks */
  React.useEffect(() => {
    setRowKeyProperty(determineRowKey());

    const runEffect = async () => {
      if (selectedIncident && wsClient) {
        setSpinnerLoading({
          ...spinnerLoading,
          [entityFlag]: true,
        });
        await getEntitiesByIncidentId(entityFlag, searchKeyword)
          .then(() => {
            setSpinnerLoading({
              ...spinnerLoading,
              [entityFlag]: false,
            });
          })
          .catch((error) => console.log(error));
      } else {
        setSpinnerLoading({
          ...spinnerLoading,
          [entityFlag]: false,
        });
      }

      await setListBodyCells(entityFlag);
    };

    const timeoutId = setTimeout(() => {
      runEffect();
    }, 2000);

    return () => clearTimeout(timeoutId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [entityFlag, wsClient]);

  React.useEffect(() => {
    setRowKeyProperty(determineRowKey());
    const runEffect = async () => {
      if (selectedIncident && wsClient) {
        setSpinnerLoading({
          parties: false,
          addresses: true,
          properties: false,
          offenses: false,
          evidence: true,
        });
        await getEntitiesByIncidentId(entityFlag, searchKeyword)
          .then(() =>
            setSpinnerLoading({
              parties: false,
              addresses: false,
              properties: false,
              offenses: false,
              evidence: false,
            })
          )
          .catch(() => {
            spinnerLoading.parties = false;
          });
      }

      await setListBodyCells(entityFlag);
    };

    const timeoutId = setTimeout(() => {
      runEffect();
    }, 2000);

    return () => clearTimeout(timeoutId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchKeyword?.addressesKeyword]);

  React.useEffect(() => {
    if (bodyState && bodyState[entityFlag]) setRows(bodyState[entityFlag]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bodyState[entityFlag]]);
  /** endregion */
  /** region Helper Functions */
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleMissingPersonClick = () => {
    const { ptsPersonId, ptsIncPersonId } = selectedEntity;

    const selectedPartyDetail = parties.filter(
      (a) =>
        a.personDetail.ptsPersonId === ptsPersonId ||
        a.personDetail.ptsIncPersonId === ptsIncPersonId
    );

    if (selectedPartyDetail.length === 0) {
      return;
    }

    const {
      personDetail,
      contactDetail,
      employmentDetail,
      otherDetail,
      mpBasicDetail,
      mpRelatedRecords,
    } = selectedPartyDetail[0];

    updateIncPartiesPersonForm(personDetail);
    updateIncPartiesContactForm(contactDetail);
    updateIncPartiesEmploymentForm(employmentDetail);
    updateIncPartiesOthersForm(otherDetail);
    updateIncOffensesMPBasicForm(mpBasicDetail);
    updateIncMPRelatedRecords(mpRelatedRecords);

    history.push('/incidents/add/missing-person');
  };

  const determineRowKey = () => {
    const keys = {
      addresses: 'ptsAddressId',
      parties: 'ptsIncPersonId',
      offenses: 'ptsOffenseId',
      properties: 'incIncidentPropertyId',
      evidence: 'ptsEvidenceId',
      narratives: 'ptsNarrativeId',
    };
    return keys[entityFlag];
  };

  const handleClick = (event, row) => {
    event.persist();

    const isProperty = entityFlag === 'properties';
    if (isProperty) {
      setParentType(row.parentType);
    }

    let newSelected = [];
    if (!selected.length || selected[0] !== row[rowKeyProperty]) {
      newSelected.push(row[rowKeyProperty]);
    }
    setSelected(newSelected);

    setSelectedEntity({ [rowKeyProperty]: row[rowKeyProperty] });
  };

  const isSelected = (row) => {
    return selected.length && selected[0] === row[rowKeyProperty];
  };

  const handleDeleteClick = async () => {
    await removeSelectedEntity(entityFlag);
    await setListBodyCells(entityFlag);
  };

  let editUrl = `/incidents/edit/${selectedIncident}/`;
  if (parentType) {
    editUrl += parentType.toLowerCase();
  } else {
    if (entityFlag !== 'parties') {
      editUrl += entityFlag;
    }
  }

  const handleEdit = (row) => {
    resetOffenseLists('edit');
    resetSubForms('edit');

    if (row.partyType && row.partyType === 'Offender') {
      editUrl += 'offenders';
    } else if (row.partyType && row.partyType === 'Victim') {
      editUrl += 'victims';
    } else if (row.partyType && row.partyType === 'Suspect') {
      editUrl += 'suspects';
    } else if (row.partyType) {
      editUrl += 'others';
    } else {
      editUrl += `/${row.id}`;
    }

    history.push(editUrl);
  };
  /** End Region */
  return (
    <div className={clsx(classes.root)}>
      <div
        style={{
          marginBottom: entityFlag !== 'addresses' ? '30px' : '10px',
        }}>
        <EnhancedTableToolbar entityFlag={entityFlag} />
        <TableContainer
          style={{
            overflow: 'hidden',
            marginBottom: entityFlag !== 'addresses' ? '30px' : '20px',
            alignItems: 'center',
            display: 'flex',
            justifyContent: 'center',
          }}
          className="mt-4">
          <Table
            stickyHeader
            className={classes.table}
            aria-label="sticky table"
            aria-labelledby="tableTitle"
            size="small">
            <EnhancedTableHead
              classes={classes}
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              rowCount={rows.length}
              entityFlag={entityFlag}
            />

            <TableBody>
              {spinnerLoading[entityFlag] && (
                <div className={classes.spinnerLoading} style={{ margin: '20% 50%' }}>
                  <CircularProgress disableShrink /> Loading..
                </div>
              )}

              {!spinnerLoading['addresses'] &&
                stableSort(rows, getComparator(order, orderBy)).map((row, index) => {
                  const isItemSelected = isSelected(row);

                  const key = rowKeyProperty
                    ? row[rowKeyProperty]
                    : row.name ||
                      row.fullName ||
                      row.FullAddressText ||
                      row.ptsPropertyId ||
                      row.ptsOffenseId ||
                      row.ptsEvidenceId ||
                      row.ptsNarrativeId;

                  return (
                    <TableRow
                      hover
                      onMouseEnter={(event) => handleClick(event, row)}
                      onMouseLeave={() => setSelected([])}
                      role="checkbox"
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={key}
                      selected={isItemSelected}
                      className={classes.incidentSelector}>
                      {entityFlag === 'addresses' && <RMSAddressCell row={row} />}
                      {entityFlag === 'parties' && <RMSPersonCell row={row} />}
                      {entityFlag === 'properties' && <RMSPropertiesCell row={row} />}
                      {entityFlag === 'offenses' && <RMSOffenseCell row={row} />}
                      {entityFlag === 'evidence' && <RMSEvidenceCell row={row} />}
                      {entityFlag === 'narratives' && <RMSNarrativeCell row={row} />}
                      <TableCell>
                        <Fade in={selected.length > 0 && isItemSelected === true}>
                          <Grid container wrap="nowrap" spacing={2} justify="flex-end">
                            {row.isMP && (
                              <Grid item className="mt-2" justify="flex-end">
                                <Tooltip title="Missing Person">
                                  <Fab
                                    onClick={handleMissingPersonClick}
                                    size="small"
                                    color="secondary"
                                    aria-label="edit"
                                    style={{ width: '40px', height: '40px' }}>
                                    MP
                                  </Fab>
                                </Tooltip>
                              </Grid>
                            )}
                            <Grid item className="mt-2" justify="flex-end">
                              <Tooltip title="Edit">
                                <RMSEditButton onClick={(e) => handleEdit(row)} />
                              </Tooltip>
                            </Grid>
                            <Grid item className="mt-2" justify="flex-end">
                              <Tooltip title="Delete">
                                <RMSDeleteButton onClick={handleDeleteClick} />
                              </Tooltip>
                            </Grid>
                          </Grid>
                        </Fade>
                      </TableCell>
                    </TableRow>
                  );
                })}
              {!spinnerLoading['addresses'] && rows.length < 1 && (
                <h6
                  style={{
                    padding: '20px',
                  }}>
                  No Data Found
                </h6>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  bodyState: state.uiList.incListBodyState,
  selectedIncident: state.incident.ptsIncidentId,
  wsClient: state.websocket.websocket,
  selectedEntity: state.incident.selectedEntity,
  parties: state.incident.parties,
  searchKeyword: state.incident.searchKeyword,
});

export default connect(mapStateToProps, {
  setListBodyCells,
  setSelectedEntity,
  getEntitiesByIncidentId,
  setCurrentOffense,
  removeSelectedEntity,
  updateIncPartiesPersonForm,
  updateIncPartiesContactForm,
  updateIncPartiesEmploymentForm,
  updateIncOffensesMPBasicForm,
  updateIncMPRelatedRecords,
  updateIncPartiesOthersForm,
})(AddressListForSummary);
