/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { connect } from 'react-redux';

import SimpleSchema from 'simpl-schema';
import { Divider } from '@material-ui/core';
import { v4 as uuidv4 } from 'uuid';
import Grid from '@material-ui/core/Grid';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';

import RMSComboBox from 'components/RMSComboBox';
import RMSComboBoxListItem from 'components/RMSComboBoxListItem';
import RMSAutoComplete from 'components/RMSAutoComplete/RMSAutoComplete';
import {
  updateIncPropertyFirearmForm,
  updateIncEvidenceItemForm,
} from '../../../reducers/IncReducer';
import { getCodeMeasures } from '../../../reducers/dictionaries/CodeMeasuresReducer';
import {
  getCodeFirearmCaliber,
  getCodeFirearmCategory,
} from '../../../reducers/dictionaries/CodeFirearmReducer';
import {
  getCodeItemMakes,
  getCodeItemModels,
  getCodeItemConditions,
} from '../../../reducers/dictionaries/CodeItemReducer';
import { handleFormChangeNew } from '../../../reducers/helpers/formHelpers';
import { checkFormType } from '../../helpers/checkFormState';
import RMSAttachments from 'components/RMSAttachments';

// Validation Schema
const validationContext = new SimpleSchema({
  barrelLength: SimpleSchema.Integer,
}).newContext();

const TabFirearm = (props) => {
  /** region Component Variables adn Props */
  const {
    updateIncPropertyFirearmForm,
    codeFirearmCaliber,
    codeFirearmCategory,
    codeItemModels,
    codeItemConditions,
    codeItemMakes,
    getCodeFirearmCaliber,
    getCodeFirearmCategory,
    getCodeItemMakes,
    getCodeItemModels,
    getCodeItemConditions,
    codeMeasures,
    getCodeMeasures,
    propertyFirearmForm,
    updateIncEvidenceItemForm,
    wsClient,
    history,
  } = props;
  const [changeKey, setChangeKey] = React.useState(0);
  const [pageType, setPageType] = React.useState('add');
  const [entityType, setEntityType] = React.useState('');
  const [firstLoad, setFirstLoad] = React.useState(true);
  const [errors, setErrors] = React.useState({});
  const [propertyFirearmFormState, setPropertyFirearmFormState] = React.useState({
    tempPropId: uuidv4(),
    isValid: false,
    changes: false,
    values: {},
    touched: {},
    errors: {},
    formType: 'edit',
  });
  /** endregion */
  /** region React Hooks */
  React.useEffect(() => {
    checkFormType(
      propertyFirearmFormState.ptsFirearmId !== undefined &&
        propertyFirearmFormState.ptsFirearmId !== null,
      setPropertyFirearmFormState,
      history
    );

    const parts = history.location.pathname.split('/');
    let type = '';
    if (parts.length === 4) type = parts[parts.length - 2];
    if (parts.length === 5) type = parts[parts.length - 3];

    setEntityType(parts[1]);
    setPageType(type);
  }, []);

  React.useEffect(() => {
    if (wsClient.websocket) {
      getCodeItemModels();
      getCodeItemConditions();
      getCodeItemMakes();
      getCodeFirearmCategory();
      getCodeFirearmCaliber();
    }
  }, [
    getCodeItemMakes,
    getCodeItemModels,
    getCodeItemConditions,
    wsClient,
    getCodeFirearmCategory,
    getCodeFirearmCaliber,
  ]);

  React.useEffect(() => {
    const services = ['codeMeasures'];

    if (!wsClient.websocket) return;

    services.forEach(async (svc) => {
      if (props[svc].length < 1) {
        const result = await wsClient.websocket.service(svc.toLowerCase()).find({
          query: {
            $limit: 0,
            $select: ['Code', 'Description'],
          },
          IsDeleted: false,
        });

        if (result.total > 0) {
          getCodeMeasures(result.data);
        }
      }
    });
  }, [wsClient]);

  React.useEffect(() => {
    checkFormType(
      propertyFirearmFormState.ptsFirearmId !== undefined &&
        propertyFirearmFormState.ptsFirearmId !== null,
      setPropertyFirearmFormState,
      history
    );
    setPropertyFirearmFormState((propertyFirearmFormState) => ({
      ...propertyFirearmFormState,
      ...propertyFirearmForm,
      values: {
        ...propertyFirearmFormState.values,
        ...propertyFirearmForm.values,
      },
    }));
  }, [propertyFirearmForm]);

  React.useEffect(() => {
    if (
      !firstLoad &&
      propertyFirearmFormState.values?.barrelLength !== '' &&
      propertyFirearmFormState.values?.barrelLength !== undefined
    ) {
      validationContext.validate({
        barrelLength: Number(propertyFirearmFormState.values?.barrelLength),
      });

      if (validationContext.isValid() === false) {
        const errs = validationContext.validationErrors();
        console.log(errs);
        let e = {};
        errs.map((er) => {
          e[er.name] = `Value must be ${er.dataType}`;
          setErrors(e);
        });
      } else {
        setErrors({});
      }
    }
    setFirstLoad(false);

    propertyFirearmFormState.changes = true;
    updateIncPropertyFirearmForm(propertyFirearmFormState);

    if (propertyFirearmFormState.values.isEvidence) {
      updateIncEvidenceItemForm({
        values: {
          description: `${propertyFirearmFormState.values?.description || ''} ${
            propertyFirearmFormState.values?.make || ''
          } ${propertyFirearmFormState.values?.model || ''}`,
          offenses: [],
          make: propertyFirearmFormState.values?.make,
          category: 'Property',
          model: propertyFirearmFormState.values?.model,
          condition: propertyFirearmFormState.values?.condition,
          serialNumber: propertyFirearmFormState.values?.serialNumber,
        },
      });
    }
  }, [
    propertyFirearmFormState.values?.incidentId,
    propertyFirearmFormState.values?.firearmId,
    propertyFirearmFormState.values?.description,
    propertyFirearmFormState.values?.automatic,
    propertyFirearmFormState.values?.make,
    propertyFirearmFormState.values?.model,
    propertyFirearmFormState.values?.firearmCategory,
    propertyFirearmFormState.values?.condition,
    propertyFirearmFormState.values?.serialNumber,
    propertyFirearmFormState.values?.caliber,
    propertyFirearmFormState.values?.barrelLength,
    propertyFirearmFormState.values?.barrelLengthMeasure,
    propertyFirearmFormState.values?.isEvidence,
    propertyFirearmFormState.values?.attachments,
  ]);

  React.useEffect(() => {
    if (!wsClient?.websocket) {
      return;
    }

    const getAttachments = async () => {
      const service = wsClient?.websocket.service('rms-attachments');
      service.timeout = 200000;

      const result = await service.find({
        query: {
          id:
            entityType === 'records'
              ? propertyFirearmFormState.ptsFirearmId
              : propertyFirearmFormState.incIncidentPropertyId,
          type: entityType === 'records' ? 'FIREARM' : 'INCIDENTPROPERTY',
        },
      });
      let atts = [];
      result.map((r) => {
        atts.push({
          ptsAttachmentId: r.ptsAttachmentID,
          name: r.FileName,
          type: r.FileType,
          dataURL: r.FileObject,
        });
      });
      setPropertyFirearmFormState((fireArmState) => ({
        ...fireArmState,
        values: {
          ...fireArmState.values,
          attachments: atts,
        },
      }));
    };

    if (propertyFirearmFormState.ptsFirearmId) {
      getAttachments();
    }
  }, [wsClient, propertyFirearmFormState.ptsFirearmId]);

  /** endregion */
  return (
    <Grid item xs={12} className="mt-2">
      <Grid container spacing={2} justify="center">
        {pageType === 'add' && entityType !== 'records' && (
          <Grid item xs={12}>
            <FormControlLabel
              control={
                <Checkbox
                  key={propertyFirearmFormState.values?.isEvidence}
                  checked={propertyFirearmFormState.values?.isEvidence}
                  onChange={(event) =>
                    handleFormChangeNew(
                      event,
                      event.target.checked,
                      'none',
                      'isEvidence',
                      setPropertyFirearmFormState
                    )
                  }
                  name="isEvidence"
                />
              }
              label="Is Evidence?"
            />
          </Grid>
        )}
        <Grid item xs={10}>
          <TextField
            id="standard-textarea-fireArm-description"
            autoComplete="hidden"
            size="small"
            label="Description"
            placeholder="Description"
            variant="outlined"
            name="description"
            key={changeKey}
            defaultValue={propertyFirearmFormState.values?.description}
            onBlur={(event) =>
              handleFormChangeNew(
                event,
                event.target.value,
                'none',
                'description',
                setPropertyFirearmFormState
              )
            }
            fullWidth
            multiline
            InputLabelProps={{ shrink: true }}
          />
        </Grid>
        <Grid item xs={2}>
          <FormControlLabel
            control={
              <Checkbox
                key={propertyFirearmFormState.values?.automatic}
                checked={propertyFirearmFormState.values?.automatic}
                onChange={(event) =>
                  handleFormChangeNew(
                    event,
                    event.target.checked,
                    'none',
                    'automatic',
                    setPropertyFirearmFormState
                  )
                }
                name="automatic"
              />
            }
            label="Automatic"
          />
        </Grid>
      </Grid>
      <Grid xs={12} className="mb-3">
        <span className="mb-1 badge badge-primary">Firearm Details</span>
        <Divider />
      </Grid>
      <Grid container xs={12} spacing={2} className="mb-2" justify="center">
        <Grid item xs={3} lg={3}>
          <Autocomplete
            size="small"
            autoComplete
            autoSelect
            autoHighlight
            id="combo-firearm-make"
            getOptionLabel={(option) => option.Code || ''}
            options={codeItemMakes}
            key={propertyFirearmFormState.values?.make || ''}
            value={{ Code: propertyFirearmFormState.values?.make } || ''}
            inputValue={propertyFirearmFormState.values?.make || ''}
            onChange={(event, newValue) =>
              handleFormChangeNew(
                event,
                newValue?.Code,
                'none',
                'make',
                setPropertyFirearmFormState
              )
            }
            renderOption={(option) => {
              return (
                <RMSComboBoxListItem
                  option={option}
                  title="Code"
                  serviceName="codeitemmakes"
                  label="Make"
                  resetField={() =>
                    handleFormChangeNew(null, null, 'none', 'make', setPropertyFirearmFormState)
                  }
                />
              );
            }}
            renderInput={(params) => (
              <RMSComboBox
                {...params}
                setField={(newVal) => {
                  handleFormChangeNew(
                    null,
                    newVal['Code'],
                    'none',
                    'make',
                    setPropertyFirearmFormState
                  );
                }}
                serviceName="codeitemmakes"
                label="Make"
                variant="outlined"
                fullWidth
              />
            )}
          />
        </Grid>
        <Grid item xs={3} lg={3}>
          <Autocomplete
            size="small"
            autoComplete
            autoSelect
            autoHighlight
            id="combo-firearm-model"
            getOptionLabel={(option) => option.Code || ''}
            options={codeItemModels}
            key={propertyFirearmFormState.values?.model || ''}
            value={{ Code: propertyFirearmFormState.values?.model } || ''}
            onChange={(event, newValue) =>
              handleFormChangeNew(
                event,
                newValue?.Code,
                'none',
                'model',
                setPropertyFirearmFormState
              )
            }
            renderOption={(option) => {
              return (
                <RMSComboBoxListItem
                  option={option}
                  title="Code"
                  serviceName="codeitemmodels"
                  label="Model"
                  resetField={() =>
                    handleFormChangeNew(null, null, 'none', 'model', setPropertyFirearmFormState)
                  }
                />
              );
            }}
            renderInput={(params) => (
              <RMSComboBox
                {...params}
                setField={(newVal) => {
                  handleFormChangeNew(
                    null,
                    newVal['Code'],
                    'none',
                    'model',
                    setPropertyFirearmFormState
                  );
                }}
                serviceName="codeitemmodels"
                label="Model"
                variant="outlined"
                fullWidth
              />
            )}
          />
        </Grid>
        <Grid item xs={3} lg={3}>
          <RMSAutoComplete
            size="small"
            autoComplete
            autoSelect
            autoHighlight
            id="combo-firearm-category"
            options={codeFirearmCategory}
            label="Firearm Category"
            serviceName="codefirearmcategory"
            title="Description"
            stateHolderAttribute="firearmCategory"
            variant="outlined"
            stateHolder={propertyFirearmFormState}
            setStateHolder={setPropertyFirearmFormState}
            setField={handleFormChangeNew}
          />
        </Grid>
        <Grid item xs={3} lg={3}>
          <Autocomplete
            size="small"
            autoComplete
            autoSelect
            autoHighlight
            id="combo-firearm-condition"
            getOptionLabel={(option) => option.Code || ''}
            options={codeItemConditions}
            key={propertyFirearmFormState.values?.condition || ''}
            value={{ Code: propertyFirearmFormState.values?.condition } || ''}
            onChange={(event, newValue) =>
              handleFormChangeNew(
                event,
                newValue?.Code,
                'none',
                'condition',
                setPropertyFirearmFormState
              )
            }
            renderOption={(option) => {
              return (
                <RMSComboBoxListItem
                  option={option}
                  title="Code"
                  serviceName="codeitemconditions"
                  label="Condition"
                  resetField={() =>
                    handleFormChangeNew(
                      null,
                      null,
                      'none',
                      'condition',
                      setPropertyFirearmFormState
                    )
                  }
                />
              );
            }}
            renderInput={(params) => (
              <RMSComboBox
                {...params}
                setField={(newVal) => {
                  handleFormChangeNew(
                    null,
                    newVal['Code'],
                    'none',
                    'condition',
                    setPropertyFirearmFormState
                  );
                }}
                serviceName="codeitemconditions"
                label="Condition"
                variant="outlined"
                fullWidth
              />
            )}
          />
        </Grid>
      </Grid>
      <Grid container xs={12} spacing={2} className="mb-2" justify="center">
        <Grid item xs={3} lg={3}>
          <TextField
            id="standard-textarea-fireArm-serialNumber"
            autoComplete="hidden"
            size="small"
            label="Serial Number"
            placeholder="Serial Number"
            variant="outlined"
            name="serialNumber"
            key={changeKey}
            defaultValue={propertyFirearmFormState.values?.serialNumber}
            onBlur={(event) =>
              handleFormChangeNew(
                event,
                event.target.value,
                'none',
                'serialNumber',
                setPropertyFirearmFormState
              )
            }
            fullWidth
            multiline
            InputLabelProps={{ shrink: true }}
          />
        </Grid>
        <Grid item xs={3} lg={3}>
          <RMSAutoComplete
            size="small"
            autoComplete
            autoSelect
            autoHighlight
            id="combo-firearm-caliber"
            options={codeFirearmCaliber}
            label="Caliber"
            serviceName="codefirearmcaliber"
            title="Code"
            stateHolderAttribute="caliber"
            variant="outlined"
            stateHolder={propertyFirearmFormState}
            setStateHolder={setPropertyFirearmFormState}
            setField={handleFormChangeNew}
          />
        </Grid>
        <Grid item xs={3} lg={3}>
          <TextField
            id="standard-textarea-fireArm-barrelLength"
            autoComplete="hidden"
            size="small"
            error={errors['barrelLength'] ? true : false}
            helperText={errors['barrelLength']}
            label="Barrel Length"
            placeholder="Barrel Length"
            variant="outlined"
            name="barrelLength"
            key={changeKey}
            value={propertyFirearmFormState.values?.barrelLength}
            onChange={(event) =>
              handleFormChangeNew(
                event,
                event.target.value,
                'none',
                'barrelLength',
                setPropertyFirearmFormState
              )
            }
            fullWidth
            multiline
            InputLabelProps={{ shrink: true }}
          />
        </Grid>
        <Grid item xs={3} lg={3}>
          <RMSAutoComplete
            size="small"
            autoComplete
            autoSelect
            autoHighlight
            id="combo-firearm-barrel-length-measure"
            options={codeMeasures}
            label="Barrel Length Measure"
            serviceName="codemeasures"
            title="Code"
            stateHolderAttribute="barrelLengthMeasure"
            variant="outlined"
            stateHolder={propertyFirearmFormState}
            setStateHolder={setPropertyFirearmFormState}
            setField={handleFormChangeNew}
          />
        </Grid>
      </Grid>
      <RMSAttachments
        attachments={propertyFirearmFormState.values.attachments}
        stateHolder={propertyFirearmFormState}
        setStateHolder={setPropertyFirearmFormState}
        setField={handleFormChangeNew}
      />
    </Grid>
  );
};

const mapStateToProps = (state) => ({
  wsClient: state.websocket,
  propertyFirearmForm: state.incident.propertyFirearmForm,
  codeMeasures: state.codeMeasures,
  codeFirearmCaliber: state.codesFirearm.codeFirearmCaliber,
  codeFirearmCategory: state.codesFirearm.codeFirearmCategory,
  codeItemModels: state.codesItem.codeItemModels,
  codeItemConditions: state.codesItem.codeItemConditions,
  codeItemMakes: state.codesItem.codeItemMakes,
});

export default connect(mapStateToProps, {
  updateIncPropertyFirearmForm,
  getCodeMeasures,
  getCodeFirearmCaliber,
  getCodeFirearmCategory,
  getCodeItemMakes,
  getCodeItemModels,
  getCodeItemConditions,
  updateIncEvidenceItemForm,
})(TabFirearm);
