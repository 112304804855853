import React, { useContext } from 'react';
import { connect } from 'react-redux';
import { XGrid } from '@material-ui/x-grid';
import { useParams } from 'react-router';
import { setFormData } from '../../../reducers/RecordsReducer';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { RefreshContext } from './AddEditPersonRecord';

const useStyles = makeStyles(() => ({
  root: {
    height: window.innerHeight - 307 + 'px',
  },
}));

function RecordPersonEmployment(props) {
  /** region Component Variables and Props */
  const [loading, setLoading] = React.useState(false);
  const [columns, setColumns] = React.useState([]);
  const [rows, setRows] = React.useState([]);
  const [rowCount, setRowCount] = React.useState(0);
  const classes = useStyles();
  const refresh = useContext(RefreshContext);
  const { wsClient, setFormData } = props;
  const fields = [
    'ptsPersonID',
    'ptsPlaceID',
    'PlaceName',
    'PlaceType',
    'DepartmentName',
    'Position',
    'Shift',
    'Occupation',
    'Pay',
    'Rate',
    'Rank',
    'Status',
    'Hired',
    'Terminated',
    'TerminationReason',
  ];
  let { type, persondId } = useParams();
  /** endregion */
  /** region React Hooks */
  React.useEffect(() => {
    const col = fields.map((e) => {
      return {
        field: e,
        headerName: e,
        width: 200,
      };
    });
    setColumns(col);
    setLoading(true);

    const delayDebounceFn = setTimeout(() => {
      pullData();
    }, 2000);

    return () => clearTimeout(delayDebounceFn);
  }, [wsClient, refresh]);

  React.useEffect(() => {
    setFormData({});
  }, []);
  /** endregion */
  /** region Helper Functions */
  const setXGridData = (result) => {
    if (result.data.length > 0) {
      const data = result.data.map((item, i) => {
        if (item.Place) {
          item = {
            ...item,
            ...item.Place[0],
          };
        }

        const obj = {
          ...item,
          id: i,
        };
        return obj;
      });

      setRows(data);
    } else {
      setRows([]);
    }
  };

  const pullData = async (skip = 0) => {
    if (!wsClient?.websocket) {
      return;
    }

    const service = wsClient?.websocket.service('record-person-employment');
    service.timeout = 200000;

    let q = {
      $skip: skip,
      $limit: 50,
      IsDeleted: false,
      $sort: {
        Created: -1,
      },
      ptsPersonID: persondId,
    };

    setRows([]);

    const result = await service.find({
      query: q,
    });

    console.log(result);
    setXGridData(result);
    setRowCount(result.total);
    setLoading(false);
  };
  /** endregion */
  return (
    <Grid container className={classes.root}>
      <React.Fragment
        style={{ minHeight: window.innerHeight - 400 + 'px' }}
        className={classes.paper}>
        <XGrid
          onPageChange={(params) => {
            pullData(params.page * 50);
          }}
          pageSize={50}
          rowCount={rowCount}
          pagination
          paginationMode="server"
          loading={loading}
          onRowClick={(row) => {
            if (row) {
              setFormData(row.row);
            } else {
              setFormData(null);
            }
          }}
          rows={rows}
          columns={columns}
        />
      </React.Fragment>
    </Grid>
  );
}

const mapStateToProps = (state) => ({
  wsClient: state.websocket,
  records: state.records,
});

const mapDispatchToProps = {
  setFormData,
};

export default connect(mapStateToProps, mapDispatchToProps)(RecordPersonEmployment);
