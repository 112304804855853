export const codeLocationCategories = [
  {
    code: '1',
    description: 'AIR/BUS/TRAIN TERMINAL',
  },
  {
    code: '2',
    description: 'BANK/SAVINGS',
  },
  {
    code: '3',
    description: 'BAR/NIGHT CLUB',
  },
  {
    code: '4',
    description: 'CHURCH/SYNAGOGUE/TEMPLE',
  },
  {
    code: '5',
    description: 'COMMERCIAL/OFFICE BLDG.',
  },
  {
    code: '6',
    description: 'CONSTRUCTION SITE',
  },
  {
    code: '7',
    description: '  CONVENIENCE STORE ',
  },
  {
    code: '8',
    description: 'DISCOUNT/DEPARTMENT STORE',
  },
  {
    code: '9',
    description: 'DRUGSTORE/MEDICAL BLDG.',
  },
  {
    code: '10',
    description: 'FIELD/WOODS',
  },
  {
    code: '11',
    description: 'GOVERNMENT/PUBLIC BLDG.',
  },
  {
    code: '12',
    description: 'GROCERY/SUPERMARKET',
  },
  {
    code: '13',
    description: 'HIGHWAY/STREET/ROAD',
  },
  {
    code: '14',
    description: 'HOTEL/MOTEL',
  },
  {
    code: '15',
    description: 'JAIL/PRISO',
  },
  {
    code: '16',
    description: 'LAKE/WATERWAY',
  },
  {
    code: '17',
    description: 'LIQUOR STORE',
  },
  {
    code: '18',
    description: 'PARKING LOT/GARAGE',
  },
  {
    code: '19',
    description: 'RENTAL STORAGE',
  },
  {
    code: '20',
    description: 'RESIDENCE',
  },
  {
    code: '21',
    description: 'RESTAURANT',
  },
  {
    code: '22',
    description: 'SCHOOL/COLLEGE',
  },
  {
    code: '23',
    description: 'SERVICE STATION',
  },
  {
    code: '24',
    description: 'SPECIALTY STORE',
  },
  {
    code: '25',
    description: 'OTHER/UNKNOWN',
  },
];
