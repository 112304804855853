/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import Grid from '@material-ui/core/Grid';
import { connect } from 'react-redux';
import { updateIncOffensesOffenseForm, updateIncOffenses } from '../../../../reducers/IncReducer';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import { codeBiasMotivation } from '../../../../reducers/_dev/codeBiasMotivation';
import { handleFormChangeNew } from '../../../../reducers/helpers/formHelpers';

import { validateOffenseForm } from 'simpl-schema-validation/ValidateForms';

const BiasMotivationsForm = (props) => {
  /** region Component Variables and Props */
  const [errors, setErrors] = React.useState({});
  const { offensesOffenseForm, updateIncOffensesOffenseForm, updateIncOffenses, offenses } = props;

  const [offensesOffenseFormState, setOffensesOffenseFormState] = React.useState({
    isValid: false,
    changes: false,
    values: {},
    touched: {},
    errors: {},
  });
  const [biasMotivationState, setBiasMotivationState] = React.useState([]);
  /** endregion */
  /** region React Hooks */
  React.useEffect(() => {
    let arr = codeBiasMotivation.map((s) => s.description).filter((s) => s !== '');
    setBiasMotivationState(arr.sort());
  }, []);

  React.useEffect(() => {
    setOffensesOffenseFormState({
      ...offensesOffenseFormState,
      ...offensesOffenseForm,
      ptsOffenseId: offensesOffenseForm.ptsOffenseId,
      values: { ...offensesOffenseFormState.values, ...offensesOffenseForm.values },
    });
  }, [offensesOffenseForm]);

  React.useEffect(() => {
    let timeoutId;
    offensesOffenseFormState.changes = true;
    updateIncOffenses(offensesOffenseFormState);
    updateIncOffensesOffenseForm(offensesOffenseFormState);

    if (offensesOffenseFormState.values.statuteDetails) {
      timeoutId = setTimeout(() => {
        let nibrError = validateOffenseForm('biasMotivation');
        let errorStatus = errors;
        if (nibrError && nibrError.length) {
          errorStatus = {
            ...errorStatus,
            biasMotivation: `${nibrError[0].message}`,
          };
        } else {
          errorStatus = {
            ...errorStatus,
            biasMotivation: null,
          };
        }
        setErrors(errorStatus);
      }, 2000);
    }
    return () => clearTimeout(timeoutId);
  }, [
    offensesOffenseFormState.values.statuteDetails,
    offensesOffenseFormState.values.biasMotivation,
  ]);
  /** endregion */
  return (
    <Grid container justify="center">
      <Grid item xs={12} lg={12} className="mt-4">
        <Autocomplete
          size="small"
          autoComplete
          autoSelect
          autoHighlight
          id="combo-bias-motivation"
          getOptionLabel={(option) => option || ''}
          options={biasMotivationState}
          key={offensesOffenseFormState.values.biasMotivation || ''}
          value={offensesOffenseFormState.values.biasMotivation || ''}
          onChange={(event, newValue) =>
            handleFormChangeNew(
              event,
              newValue,
              'none',
              'biasMotivation',
              setOffensesOffenseFormState
            )
          }
          renderInput={(params) => (
            <TextField
              autoComplete="hidden"
              size="small"
              {...params}
              label="Bias Motivation"
              variant="outlined"
              error={errors['biasMotivation'] ? true : false}
              helperText={errors['biasMotivation']}
              fullWidth
            />
          )}
        />
      </Grid>
    </Grid>
  );
};

const mapStateToProps = (state) => ({
  offensesOffenseForm: state.incident.offensesOffenseForm,
  currentOffense: state.incident.currentOffense,
  offenses: state.incident.offenses,
  selectedEntity: state.incident.selectedEntity,
});

export default connect(mapStateToProps, { updateIncOffenses, updateIncOffensesOffenseForm })(
  BiasMotivationsForm
);
