import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import React from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import { makeStyles } from '@material-ui/core/styles';
import TableCell from '@material-ui/core/TableCell';

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.info.dark,
    color: theme.palette.common.white,
    '&:last-child': { paddingLeft: '160px' },
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const useStyles = makeStyles((theme) => ({
  root: {
    '&:hover': {
      color: '#FFEDC2 !important',
    },
  },
  active: {
    color: '#FFEDC2 !important',
  },
  icon: {
    color: '#FFEDC2 !important',
    '&:hover': {
      color: '#FFEDC2 !important',
    },
  },
}));

const headCells = [
  { id: 'name', numeric: false, disablePadding: false, label: 'Incident', sortable: true },
  { id: 'address', numeric: false, disablePadding: false, label: 'Location' },
  { id: 'status', numeric: false, disablePadding: false, label: 'Status' },
  { id: 'occurred', numeric: false, disablePadding: false, label: 'Occurred', sortable: true },
  { id: 'updated', numeric: false, disablePadding: false, label: 'Updated', sortable: true },
  { id: 'createdBy', numeric: false, disablePadding: false, label: 'Created By', sortable: true },
  { id: 'action', numeric: false, disablePadding: false, label: '' },
];

function EnhancedTableHead(props) {
  const { order, orderBy, onRequestSort } = props;
  const classes = useStyles();
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <StyledTableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'default'}
            sortDirection={orderBy === headCell.id ? order : false}>
            {headCell.sortable && (
              <TableSortLabel
                classes={{
                  root: classes.root,
                  active: classes.active,
                  icon: classes.icon,
                }}
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : 'asc'}
                onClick={createSortHandler(headCell.id)}>
                {headCell.label}
              </TableSortLabel>
            )}

            {!headCell.sortable && headCell.label}
          </StyledTableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

export default EnhancedTableHead;
