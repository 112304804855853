/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */
import React from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { v4 as uuidv4 } from 'uuid';
import PerfectScrollbar from 'react-perfect-scrollbar';
import DateFnsUtils from '@date-io/date-fns';
import Grid from '@material-ui/core/Grid';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import { Divider, makeStyles } from '@material-ui/core';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { KeyboardDateTimePicker } from '@material-ui/pickers';

import RMSMultiSelect from '../../components/RMSMultiSelect';
import RMSAutoComplete from 'components/RMSAutoComplete/RMSAutoComplete';
import RMSComboBox from 'components/RMSComboBox';
import RMSComboBoxListItem from 'components/RMSComboBoxListItem';
import {
  getCodeEvidenceCategory,
  getCodeEvidenceCustodyReason,
  getCodeEvidenceCustodyStatus,
  getCodeEvidenceDisposalCode,
  getCodeEvidenceFinalDisposition,
  getCodeEvidenceRequiredAction,
  getCodeEvidenceStorageContainer,
  getCodeEvidenceStorageLocation,
  getCodeEvidenceStorageSubLocation,
  getCodeEvidenceTemporaryLocation,
  getCodeEvidenceType,
} from '../../reducers/dictionaries/CodeEvidenceReducer';
import {
  getCodeItemModels,
  getCodeItemConditions,
  getCodeItemMakes,
} from '../../reducers/dictionaries/CodeItemReducer';
import { updateIncEvidenceItemForm } from '../../reducers/IncReducer';
import { handleFormChangeNew } from '../../reducers/helpers/formHelpers';
import { RMSPersonSearch } from 'components/RMSPersonSearch';
import RMSAttachments from 'components/RMSAttachments';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    height: window.innerHeight - 95 + 'px',
  },
  scrollBar: {
    width: '100%',
    overflowX: 'hidden',

    [theme.breakpoints.up('lg')]: {
      height: '70vh',
    },
    [theme.breakpoints.down('lg')]: {
      height: '50vh',
    },
    [theme.breakpoints.only('lg')]: {
      height: '65vh',
    },
  },
}));
const TabEvidenceItemRecord = (props) => {
  /** region Component Variables and Props */
  const classes = useStyles();
  const [collectedDate, setCollectedDate] = React.useState(null);
  const [actionDate, setActionDate] = React.useState(null);
  const {
    evidenceItemForm,
    updateIncEvidenceItemForm,
    getCodeEvidenceCategory,
    getCodeEvidenceCustodyReason,
    getCodeEvidenceCustodyStatus,
    getCodeEvidenceDisposalCode,
    getCodeEvidenceFinalDisposition,
    getCodeEvidenceRequiredAction,
    getCodeEvidenceStorageContainer,
    getCodeEvidenceStorageLocation,
    getCodeEvidenceStorageSubLocation,
    getCodeEvidenceTemporaryLocation,
    getCodeEvidenceType,
    codeEvidenceCategory,
    codeEvidenceDisposalCode,
    codeEvidenceType,
    codeEvidenceTemporaryLocation,
    codeEvidenceRequiredAction,
    wsClient,
    offenses,
    getCodeItemModels,
    getCodeItemMakes,
    getCodeItemConditions,
    codeItemConditions,
    codeItemMakes,
    codeItemModels,
  } = props;

  const history = useHistory();
  const [listOffenses, setListOffenses] = React.useState([]);
  const [pageType, setPageType] = React.useState('add');
  const [evidenceItemFormState, setEvidenceItemFormState] = React.useState({
    tempEvidenceId: uuidv4(),
    changes: false,
    isValid: false,
    values: {},
    touched: {},
    errors: {},
  });
  /** endregion */
  /** region React Hooks */
  React.useEffect(() => {
    if (wsClient.websocket) {
      getCodeEvidenceCategory();
      getCodeEvidenceCustodyReason();
      getCodeEvidenceCustodyStatus();
      getCodeEvidenceDisposalCode();
      getCodeEvidenceFinalDisposition();
      getCodeEvidenceRequiredAction();
      getCodeEvidenceStorageContainer();
      getCodeEvidenceStorageLocation();
      getCodeEvidenceStorageSubLocation();
      getCodeEvidenceTemporaryLocation();
      getCodeEvidenceType();
      getCodeItemModels();
      getCodeItemConditions();
      getCodeItemMakes();
    }
  }, [
    getCodeEvidenceCategory,
    getCodeEvidenceCustodyReason,
    getCodeEvidenceCustodyStatus,
    getCodeEvidenceDisposalCode,
    getCodeEvidenceFinalDisposition,
    getCodeEvidenceRequiredAction,
    getCodeEvidenceStorageContainer,
    getCodeEvidenceStorageLocation,
    getCodeEvidenceStorageSubLocation,
    getCodeEvidenceTemporaryLocation,
    getCodeEvidenceType,
    getCodeItemMakes,
    getCodeItemModels,
    getCodeItemConditions,
    wsClient,
  ]);

  React.useEffect(() => {
    setEvidenceItemFormState((evidenceItemFormState) => ({
      ...evidenceItemFormState,
      ...evidenceItemForm,
      ptsEvidenceId: evidenceItemForm.ptsEvidenceId,
      values: { ...evidenceItemFormState.values, ...evidenceItemForm.values },
    }));
    if (evidenceItemForm.values.collectedDate)
      setCollectedDate(evidenceItemForm.values.collectedDate);
    if (evidenceItemForm.values.actionDate) setActionDate(evidenceItemForm.values.actionDate);
  }, [evidenceItemForm]);

  React.useEffect(() => {
    let list = [];
    if (offenses)
      offenses.map((o) => {
        list.push(o.offenseDetails.values.statute);
      });
    setListOffenses(list);
  }, [offenses]);

  React.useEffect(() => {
    const parts = history.location.pathname.split('/');
    let type = '';
    if (parts.length === 4) type = parts[parts.length - 2];
    if (parts.length === 5) type = parts[parts.length - 3];

    setPageType(type);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    updateIncEvidenceItemForm(evidenceItemFormState);
    evidenceItemFormState.changes = true;
  }, [
    evidenceItemFormState.values?.incidentId,
    evidenceItemFormState.values?.evidenceId,
    evidenceItemFormState.values?.category,
    evidenceItemFormState.values?.evidenceType,
    evidenceItemFormState.values?.description,
    evidenceItemFormState.values?.locationCollected,
    evidenceItemFormState.values?.collectedBy,
    evidenceItemFormState.values?.requiredAction,
    evidenceItemFormState.values?.temporaryLocation,
    evidenceItemFormState.values?.notes,
    evidenceItemFormState.values?.submittedBy,
    evidenceItemFormState.values?.disposalCode,
    evidenceItemFormState.values?.offenses,
    evidenceItemFormState.values?.collectedDate,
    evidenceItemFormState.values?.actionDate,
    evidenceItemFormState.values?.condition,
    evidenceItemFormState.values?.make,
    evidenceItemFormState.values?.model,
    evidenceItemFormState.values?.quantity,
    evidenceItemFormState.values?.tagNumber,
    evidenceItemFormState.values?.licenseNumber,
    evidenceItemFormState.values?.vin,
    evidenceItemFormState.values?.serialNumber,
    evidenceItemFormState.values?.substanceType,
    evidenceItemFormState.values?.substanceClass,
    evidenceItemFormState.values?.attachments,
  ]);
  /** endregion */
  /** region Helper Functions */
  const handleMultiSelectValues = (event) => {
    handleFormChangeNew(event, event.target.value, 'none', 'offenses', setEvidenceItemFormState);
  };

  const changeCollectedBy = (name) => {
    setEvidenceItemFormState((evidenceItemFormState) => ({
      ...evidenceItemFormState,
      ...(evidenceItemFormState.values.collectedBy = name),
    }));
  };

  const changeSubmittedBy = (name) => {
    setEvidenceItemFormState((evidenceItemFormState) => ({
      ...evidenceItemFormState,
      ...(evidenceItemFormState.values.submittedBy = name),
    }));
  };

  React.useEffect(() => {
    if (!wsClient?.websocket) {
      return;
    }

    const getAttachments = async () => {
      const service = wsClient?.websocket.service('rms-attachments');
      service.timeout = 200000;

      const result = await service.find({
        query: {
          id: evidenceItemFormState.ptsEvidenceId,
          type: 'EVIDENCE',
        },
      });
      let atts = [];
      result.map((r) => {
        atts.push({
          ptsAttachmentId: r.ptsAttachmentID,
          name: r.FileName,
          type: r.FileType,
          dataURL: r.FileObject,
        });
      });
      setEvidenceItemFormState((stateValue) => ({
        ...stateValue,
        values: {
          ...stateValue.values,
          attachments: atts,
        },
      }));
    };

    if (evidenceItemFormState.ptsEvidenceId) {
      getAttachments();
    }
  }, [wsClient, evidenceItemFormState.ptsEvidenceId]);
  /** endregion */
  return (
    <PerfectScrollbar className={classes.scrollBar}>
      <div style={{ padding: '0px 10px' }}>
        <Grid container style={{ padding: '24px 5px' }}>
          <Grid container spacing={2} className="mb-2">
            <Grid item xs={4}>
              <RMSAutoComplete
                size="small"
                autoComplete
                autoSelect
                autoHighlight
                id="combo-evidence-category"
                options={codeEvidenceCategory}
                serviceName="codeevidencecategory"
                label="Category"
                variant="outlined"
                title="Description"
                stateHolder={evidenceItemFormState}
                setStateHolder={setEvidenceItemFormState}
                setField={handleFormChangeNew}
                stateHolderAttribute="category"
              />
            </Grid>
            <Grid item xs={4}>
              <RMSAutoComplete
                size="small"
                autoComplete
                autoSelect
                autoHighlight
                id="combo-evidence-type"
                options={codeEvidenceType}
                serviceName="codeevidencetype"
                label="Evidence Type"
                variant="outlined"
                title="Code"
                stateHolder={evidenceItemFormState}
                setStateHolder={setEvidenceItemFormState}
                setField={handleFormChangeNew}
                stateHolderAttribute="evidenceType"
              />
            </Grid>
            <Grid item xs={4}>
              <RMSAutoComplete
                size="small"
                autoComplete
                autoSelect
                autoHighlight
                id="combo-search-required-action"
                options={codeEvidenceRequiredAction}
                serviceName="codeevidencerequiredaction"
                label="Required Action"
                variant="outlined"
                title="Code"
                stateHolder={evidenceItemFormState}
                setStateHolder={setEvidenceItemFormState}
                setField={handleFormChangeNew}
                stateHolderAttribute="requiredAction"
              />
            </Grid>
          </Grid>

          <Grid container spacing={2} className="mb-2">
            <Grid item xs={3}>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDateTimePicker
                  size="small"
                  inputVariant="outlined"
                  fullWidth
                  showTodayButton={true}
                  variant="dialog"
                  format="MM/dd/yyyy hh:mm a"
                  id="date-picker-inline-from"
                  label="Action Date"
                  value={actionDate ? actionDate : null}
                  onChange={(date) => {
                    setEvidenceItemFormState((state) => {
                      return {
                        ...state,
                        values: {
                          ...state.values,
                          actionDate: date,
                        },
                      };
                    });
                  }}
                  KeyboardButtonProps={{
                    'aria-label': 'change date',
                  }}
                />
              </MuiPickersUtilsProvider>
            </Grid>

            <Grid item xs={3}>
              <RMSAutoComplete
                size="small"
                autoComplete
                autoSelect
                autoHighlight
                id="combo-evidence-disposal-code"
                options={codeEvidenceDisposalCode}
                // new ones
                serviceName="codeevidencedisposalcode"
                label="Disposal Code"
                variant="outlined"
                title="Code"
                stateHolder={evidenceItemFormState}
                setStateHolder={setEvidenceItemFormState}
                setField={handleFormChangeNew}
                stateHolderAttribute="disposalCode"
              />
            </Grid>

            <Grid item xs={6} style={{ marginTop: '-13px' }}>
              <RMSMultiSelect
                min="100%"
                name="offenses"
                selections={listOffenses}
                handleFormChange={handleMultiSelectValues}
                selectedValues={evidenceItemFormState.values.offenses}
                label="Offenses"
              />
            </Grid>
          </Grid>
          <Grid container spacing={2} className="mb-2">
            <Grid item xs={3}>
              <TextField
                autoComplete="hidden"
                size="small"
                label="Quantity"
                placeholder="Quantity"
                variant="outlined"
                name="quantity"
                defaultValue={evidenceItemFormState.values.quantity}
                onBlur={(event) =>
                  handleFormChangeNew(
                    event,
                    event.target.value,
                    'none',
                    'quantity',
                    setEvidenceItemFormState
                  )
                }
                fullWidth
                multiline
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
            <Grid item xs={3}>
              <TextField
                autoComplete="hidden"
                size="small"
                label="Plate Number"
                placeholder="Plate Number"
                variant="outlined"
                name="licenseNumber"
                defaultValue={evidenceItemFormState.values.licenseNumber}
                onBlur={(event) =>
                  handleFormChangeNew(
                    event,
                    event.target.value,
                    'none',
                    'licenseNumber',
                    setEvidenceItemFormState
                  )
                }
                fullWidth
                multiline
                InputLabelProps={{ shrink: true }}
              />
            </Grid>

            <Grid item xs={3}>
              <TextField
                autoComplete="hidden"
                size="small"
                label="Tag Number"
                placeholder="Tag Number"
                variant="outlined"
                name="tagNumber"
                defaultValue={evidenceItemFormState.values.tagNumber}
                onBlur={(event) =>
                  handleFormChangeNew(
                    event,
                    event.target.value,
                    'none',
                    'tagNumber',
                    setEvidenceItemFormState
                  )
                }
                fullWidth
                multiline
                InputLabelProps={{ shrink: true }}
              />
            </Grid>

            <Grid item xs={3}>
              <TextField
                autoComplete="hidden"
                size="small"
                label="VIN"
                placeholder="VIN"
                variant="outlined"
                name="tagNumber"
                defaultValue={evidenceItemFormState.values.vin}
                onBlur={(event) =>
                  handleFormChangeNew(
                    event,
                    event.target.value,
                    'none',
                    'vin',
                    setEvidenceItemFormState
                  )
                }
                fullWidth
                multiline
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
          </Grid>

          <Grid container spacing={2} className="mb-2" justify="center">
            <Grid item xs={3} lg={3}>
              <TextField
                autoComplete="hidden"
                size="small"
                label="Serial Number"
                placeholder="Serial Number"
                variant="outlined"
                name="serialNumber"
                defaultValue={evidenceItemFormState.values?.serialNumber || ''}
                onBlur={(event) =>
                  handleFormChangeNew(
                    event,
                    event.target.value,
                    'none',
                    'serialNumber',
                    setEvidenceItemFormState
                  )
                }
                fullWidth
                multiline
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
            <Grid item xs={3} lg={3}>
              <Autocomplete
                size="small"
                autoComplete
                autoSelect
                autoHighlight
                id="combo-item-condition"
                getOptionLabel={(option) => option.Code || ''}
                options={codeItemConditions}
                key={evidenceItemFormState.values?.condition || ''}
                value={{ Code: evidenceItemFormState.values?.condition } || ''}
                onChange={(event, newValue) =>
                  handleFormChangeNew(
                    event,
                    newValue?.Code,
                    'none',
                    'condition',
                    setEvidenceItemFormState
                  )
                }
                renderOption={(option) => {
                  return (
                    <RMSComboBoxListItem
                      option={option}
                      title="Code"
                      serviceName="codeitemconditions"
                      label="Condition"
                      resetField={() =>
                        handleFormChangeNew(
                          null,
                          null,
                          'none',
                          'condition',
                          setEvidenceItemFormState
                        )
                      }
                    />
                  );
                }}
                renderInput={(params) => (
                  <RMSComboBox
                    {...params}
                    setField={(newVal) => {
                      handleFormChangeNew(
                        null,
                        newVal['Code'],
                        'none',
                        'condition',
                        setEvidenceItemFormState
                      );
                    }}
                    serviceName="codeitemconditions"
                    label="Condition"
                    variant="outlined"
                    fullWidth
                  />
                )}
              />
            </Grid>
            <Grid item xs={3} lg={3}>
              <Autocomplete
                size="small"
                autoComplete
                autoSelect
                autoHighlight
                id="combo-item-make"
                getOptionLabel={(option) => option.Code || ''}
                options={codeItemMakes}
                key={evidenceItemFormState.values?.make || ''}
                value={{ Code: evidenceItemFormState.values?.make } || ''}
                onChange={(event, newValue) =>
                  handleFormChangeNew(
                    event,
                    newValue?.Code,
                    'none',
                    'make',
                    setEvidenceItemFormState
                  )
                }
                renderOption={(option) => {
                  return (
                    <RMSComboBoxListItem
                      option={option}
                      title="Code"
                      serviceName="codeitemmakes"
                      label="Make"
                      resetField={() =>
                        handleFormChangeNew(null, null, 'none', 'make', setEvidenceItemFormState)
                      }
                    />
                  );
                }}
                renderInput={(params) => (
                  <RMSComboBox
                    {...params}
                    setField={(newVal) => {
                      handleFormChangeNew(
                        null,
                        newVal['Code'],
                        'none',
                        'make',
                        setEvidenceItemFormState
                      );
                    }}
                    serviceName="codeitemmakes"
                    label="Make"
                    variant="outlined"
                    fullWidth
                  />
                )}
              />
            </Grid>
            <Grid item xs={3} lg={3}>
              <Autocomplete
                size="small"
                autoComplete
                autoSelect
                autoHighlight
                id="combo-item-model"
                getOptionLabel={(option) => option.Code || ''}
                options={codeItemModels}
                key={evidenceItemFormState.values?.model || ''}
                value={{ Code: evidenceItemFormState.values?.model } || ''}
                onChange={(event, newValue) =>
                  handleFormChangeNew(
                    event,
                    newValue?.Code,
                    'none',
                    'model',
                    setEvidenceItemFormState
                  )
                }
                renderOption={(option) => {
                  return (
                    <RMSComboBoxListItem
                      option={option}
                      title="Code"
                      serviceName="codeitemmodels"
                      label="Model"
                      resetField={() =>
                        handleFormChangeNew(null, null, 'none', 'model', setEvidenceItemFormState)
                      }
                    />
                  );
                }}
                renderInput={(params) => (
                  <RMSComboBox
                    {...params}
                    setField={(newVal) => {
                      handleFormChangeNew(
                        null,
                        newVal['Code'],
                        'none',
                        'model',
                        setEvidenceItemFormState
                      );
                    }}
                    serviceName="codeitemmodels"
                    label="Model"
                    variant="outlined"
                    fullWidth
                  />
                )}
              />
            </Grid>
          </Grid>

          <Grid container spacing={2} className="mb-2">
            <Grid item xs={6}>
              <TextField
                autoComplete="hidden"
                size="small"
                label="Substance Class"
                placeholder="Substance Class"
                variant="outlined"
                name="substanceClass"
                defaultValue={evidenceItemFormState.values.substanceClass}
                onBlur={(event) =>
                  handleFormChangeNew(
                    event,
                    event.target.value,
                    'none',
                    'substanceClass',
                    setEvidenceItemFormState
                  )
                }
                fullWidth
                multiline
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                autoComplete="hidden"
                size="small"
                label="Substance Type"
                variant="outlined"
                name="substanceType"
                value={evidenceItemFormState.values.substanceType}
                onChange={(event) =>
                  handleFormChangeNew(
                    event,
                    event.target.value,
                    'none',
                    'substanceType',
                    setEvidenceItemFormState
                  )
                }
                fullWidth
                multiline
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
          </Grid>

          <Grid item xs={12} className="mb-3">
            <span style={{ color: '#fff', background: '#1976d2' }} className="mb-1 badge">
              Collected Information
            </span>
            <Divider />
          </Grid>

          <Grid container spacing={2} className="mb-2">
            <Grid item xs={6} lg={6}>
              <RMSPersonSearch
                setName={changeCollectedBy}
                placeholder="Search Person for Collected By"
              />
            </Grid>

            <Grid item xs={6}>
              <TextField
                autoComplete="hidden"
                size="small"
                label="Collected By"
                placeholder="Collected By"
                variant="outlined"
                name="collectedBy"
                defaultValue={evidenceItemFormState.values.collectedBy}
                onBlur={(event) =>
                  handleFormChangeNew(
                    event,
                    event.target.value,
                    'none',
                    'collectedBy',
                    setEvidenceItemFormState
                  )
                }
                fullWidth
                multiline
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                autoComplete="hidden"
                size="small"
                name="locationCollected"
                defaultValue={evidenceItemFormState.values.locationCollected}
                onBlur={(event) =>
                  handleFormChangeNew(
                    event,
                    event.target.value,
                    'none',
                    'locationCollected',
                    setEvidenceItemFormState
                  )
                }
                label="Location Collected"
                placeholder="Location Collected"
                variant="outlined"
                fullWidth
                multiline
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
            <Grid item xs={4}>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDateTimePicker
                  size="small"
                  inputVariant="outlined"
                  fullWidth
                  showTodayButton={true}
                  variant="dialog"
                  format="MM/dd/yyyy hh:mm a"
                  id="date-picker-inline-from"
                  label="Date Collected"
                  value={collectedDate ? collectedDate : null}
                  onChange={(date) => {
                    setEvidenceItemFormState((state) => {
                      return {
                        ...state,
                        values: {
                          ...state.values,
                          collectedDate: date,
                        },
                      };
                    });
                  }}
                  KeyboardButtonProps={{
                    'aria-label': 'change date',
                  }}
                />
              </MuiPickersUtilsProvider>
            </Grid>
            <Grid item xs={4}>
              <RMSAutoComplete
                size="small"
                autoComplete
                autoSelect
                autoHighlight
                id="combo-evidence-temporary-location"
                options={codeEvidenceTemporaryLocation}
                // new ones
                serviceName="codeevidencetemporarylocation"
                label="Temporary Location"
                variant="outlined"
                title="Code"
                stateHolder={evidenceItemFormState}
                setStateHolder={setEvidenceItemFormState}
                setField={handleFormChangeNew}
                stateHolderAttribute="temporaryLocation"
              />
            </Grid>
          </Grid>

          <Grid item xs={12} className="mb-3">
            <span style={{ color: '#fff', background: '#1976d2' }} className="mb-1 badge">
              Submitted Information
            </span>
            <Divider />
          </Grid>

          <Grid container spacing={2} className="mb-2">
            <Grid item xs={6} lg={6}>
              <RMSPersonSearch
                setName={changeSubmittedBy}
                placeholder="Search Person for Submitted By"
              />
            </Grid>

            <Grid item xs={6}>
              <TextField
                autoComplete="hidden"
                size="small"
                label="Submitted By"
                placeholder="Submitted By"
                variant="outlined"
                name="submittedBy"
                defaultValue={evidenceItemFormState.values.submittedBy}
                onBlur={(event) =>
                  handleFormChangeNew(
                    event,
                    event.target.value,
                    'none',
                    'submittedBy',
                    setEvidenceItemFormState
                  )
                }
                fullWidth
                multiline
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
          </Grid>

          <Grid xs={12} className="mb-3">
            <span
              style={{ color: '#fff', background: '#1976d2' }}
              className="mb-1 badge badge-primary">
              Description
            </span>
            <Divider />
          </Grid>
          <Grid item xs={12} className="mb-3">
            <TextField
              autoComplete="hidden"
              size="small"
              name="description"
              defaultValue={evidenceItemFormState.values.description || ''}
              onBlur={(event) =>
                handleFormChangeNew(
                  event,
                  event.target.value,
                  'none',
                  'description',
                  setEvidenceItemFormState
                )
              }
              fullWidth
              id="outlined-multiline-static"
              label="Description"
              placeholder="Description"
              multiline
              rows="3"
              variant="outlined"
              InputLabelProps={{ shrink: true }}
            />
          </Grid>
          <Grid xs={12} className="mb-3">
            <span
              style={{ color: '#fff', background: '#1976d2' }}
              className="mb-1 badge badge-primary">
              Notes
            </span>
            <Divider />
          </Grid>
          <Grid item xs={12} className="mb-3">
            <TextField
              autoComplete="hidden"
              size="small"
              name="notes"
              defaultValue={evidenceItemFormState.values.notes}
              onBlur={(event) =>
                handleFormChangeNew(
                  event,
                  event.target.value,
                  'none',
                  'notes',
                  setEvidenceItemFormState
                )
              }
              fullWidth
              id="outlined-multiline-static"
              label="Notes"
              placeholder="Notes"
              multiline
              rows="3"
              variant="outlined"
              InputLabelProps={{ shrink: true }}
            />
          </Grid>

          <Grid item xs={12}>
            <RMSAttachments
              attachments={evidenceItemFormState.values.attachments}
              stateHolder={evidenceItemFormState}
              setStateHolder={setEvidenceItemFormState}
              setField={handleFormChangeNew}
            />
          </Grid>
        </Grid>
      </div>
    </PerfectScrollbar>
  );
};

const mapStateToProps = (state) => ({
  evidenceItemForm: state.incident.evidenceItemForm,
  codeEvidenceCategory: state.codesEvidence.codeEvidenceCategory,
  codeEvidenceDisposalCode: state.codesEvidence.codeEvidenceDisposalCode,
  codeEvidenceType: state.codesEvidence.codeEvidenceType,
  codeEvidenceTemporaryLocation: state.codesEvidence.codeEvidenceTemporaryLocation,
  codeEvidenceRequiredAction: state.codesEvidence.codeEvidenceRequiredAction,
  codeItemConditions: state.codesItem.codeItemConditions,
  codeItemMakes: state.codesItem.codeItemMakes,
  codeItemModels: state.codesItem.codeItemModels,
  offenses: state.incident.offenses,
  wsClient: state.websocket,
});

export default connect(mapStateToProps, {
  updateIncEvidenceItemForm,
  getCodeEvidenceCategory,
  getCodeEvidenceCustodyReason,
  getCodeEvidenceCustodyStatus,
  getCodeEvidenceDisposalCode,
  getCodeEvidenceFinalDisposition,
  getCodeEvidenceRequiredAction,
  getCodeEvidenceStorageContainer,
  getCodeEvidenceStorageLocation,
  getCodeEvidenceStorageSubLocation,
  getCodeEvidenceTemporaryLocation,
  getCodeEvidenceType,
  getCodeItemModels,
  getCodeItemMakes,
  getCodeItemConditions,
})(TabEvidenceItemRecord);
