import store from '../../config/configureStore';

export const SET_CODE_ARRESTEE_ARMED = 'CODE/SET_CODE_ARRESTEE_ARMED';
export const ADD_CODE_ARRESTEE_ARMED = 'CODE/ADD_CODE_ARRESTEE_ARMED';
export const SET_CODE_CAPABILITY_TYPE = 'CODE/SET_CODE_CAPABILITY_TYPE';
export const ADD_CODE_CAPABILITY_TYPE = 'CODE/ADD_CODE_CAPABILITY_TYPE';

export const addCodeCapabilityType = (newValue) => {
  return { type: ADD_CODE_CAPABILITY_TYPE, payload: newValue };
};

export const getCodeCapabilityType = () => {
  const client = store.store.getState().websocket.websocket;

  return async (dispatch) => {
    try {
      const service = client.service('codecapabilitytypes');
      service.timeout = 20000;
      const codes = await service.find({
        query: {
          $select: ['Code', 'Description'],
          $sort: {
            Description: 1,
          },
          IsDeleted: false,
        },
      });

      dispatch({ type: SET_CODE_CAPABILITY_TYPE, payload: codes.data });
    } catch (error) {
      if (error.code === 401) {
        console.log('Unauthenticated');
      } else {
        console.log('error', error);
      }
    }
  };
};

export default function reducer(state = [], action) {
  // eslint-disable-next-line default-case
  switch (action.type) {
    case SET_CODE_CAPABILITY_TYPE:
      state = action.payload;
      return state;
    case ADD_CODE_CAPABILITY_TYPE:
      state = [...state, action.payload];
      return state;
    default:
      return state;
  }
}
