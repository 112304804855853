/* eslint-disable no-case-declarations */
import store from '../../config/configureStore';
import {
  upsertIncident,
  upsertAddress,
  upsertParty,
  upsertProperties,
  addIncArrestees,
  addIncProperties,
  addIncSubjects,
  addIncVictims,
  addIncDVVictim,
  addOffenses,
  addVictimRelationships,
  addIncDVAdditional,
  addIncSuspects,
  updateIncPartiesPersonForm,
  getEntitiesByIncidentId,
  SET_INCIDENT,
  GET_PARTIES,
  GET_PROPERTIES,
  GET_ADDRESSESS,
} from '../IncReducer';

import {
  addNewOffense,
  updateOffense,
  addNewSubject,
  updateSubject,
  addNewVictim,
  updateVictim,
  addNewArrestee,
  updateArrestee,
  doesRelationshipExist,
  updateRelationship,
  addNewRelationship,
  updateDVVictim,
  addNewDVVictim,
  updateDVAdditional,
  addNewDVAdditional,
  updateSuspect,
  addNewSuspect,
  addNewProperty,
  updateProperty,
  addNewNarratives,
  updateEvidenceItem,
  addNewEvidenceCustody,
  addNewEvidenceItem,
  updateEvidenceCustody,
  addNewEvidenceStorage,
  updateEvidenceStorage,
  updateEvidenceDisposition,
  addNewEvidenceDisposition,
  updateNarratives,
  addZone,
  addDisposition,
  prepareOffenseErrors,
  preparePartiesErrors,
  prepareArresteesErrors,
  prepareIncPropertiesErrors,
  prepareIncRelationshipErrors,
  prepareIncVictimErrors,
  preparePropertiesErrors,
} from '../helpers/incHelpers';
import { setListBodyCells } from './UiListReducer';

import {
  validateOffenses,
  validateParties,
  validateArrestees,
  validateIncProperties,
  validateIncRelationships,
  validateIncVictims,
  validateProperties,
} from 'simpl-schema-validation/validate';

const SET_MENU_TYPE = 'SET_MENU_TYPE';
const SET_HANDLE_CLOSE = 'SET_HANDLE_CLOSE';
const SET_HANDLE_INC_FORM_SAVE = 'SET_HANDLE_INC_FORM_SAVE';
const SET_OFFENSES_REDIRECT_FORM_CLOSE = 'SET_OFFENSES_REDIRECT_FORM_CLOSE';
const SET_HANDLE_ADD_INC_SUBJECT = 'SET_HANDLE_ADD_INC_SUBJECT';
const SET_HANDLE_ADD_INC_VICTIM = 'SET_HANDLE_ADD_INC_VICTIM';
const SET_HANDLE_ADD_INC_PROPERTY = 'SET_HANDLE_ADD_INC_PROPERTY';
const SET_HANDLE_ADD_INC_RELATIONSHIP = 'SET_HANDLE_ADD_INC_RELATIONSHIP';
const SET_HANDLE_ADD_INC_ARRESTEE = 'SET_HANDLE_ADD_INC_ARRESTEE';
const SET_HANDLE_ADD_INC_DV_VICTIM = 'SET_HANDLE_ADD_INC_DV_VICTIM';
const SET_HANDLE_ADD_INC_DV_SUSPECT = 'SET_HANDLE_ADD_INC_DV_SUSPECT';
const SET_HANDLE_ADD_INC_DV_ADDITIONAL = 'SET_HANDLE_ADD_INC_DV_ADDITIONAL';
const SET_CIRCULAR_LOADING = 'SET_CIRCULAR_LOADING';
const SET_SHOW_INCIDENT_STEPPER = 'SET_SHOW_INCIDENT_STEPPER';

/*** Start: Form Save Region */
async function saveSubjects(currentState, incidentID, offenseID, dispatch) {
  for (let i = 0; i < currentState.incSubjects.length; i++) {
    if (
      currentState.incSubjects[i].incSubjectDetails.changes === true &&
      currentState.incSubjects[i].incSubjectDetails.ptsIncPersonId
    ) {
      currentState.incSubjects[i].incSubjectDetails.ptsOffenseId = offenseID;
      if (currentState.incSubjects[i].incSubjectDetails.ptsSubjectId) {
        updateSubject(currentState.incSubjects[i].incSubjectDetails, dispatch);
      } else {
        let savedParty = currentState.parties.find(
          (x) => x.ptsIncPersonId === currentState.incSubjects[i].incSubjectDetails.ptsIncPersonId
        );
        savedParty &&
          addNewSubject(
            currentState.incSubjects[i].incSubjectDetails,
            savedParty,
            incidentID,
            dispatch
          )
            .then((res) => {
              currentState.incSubjects[i].incSubjectDetails.ptsSubjectId = res.ptsSubjectID;
            })
            .catch((err) => {
              console.log(err);
            });
      }
    }
    currentState.incSubjects[i].incSubjectDetails.changes = false;
  }
}

async function saveVictims(currentState, incidentID, offenseID, dispatch) {
  for (let i = 0; i < currentState.incVictims.length; i++) {
    if (
      currentState.incVictims[i].incVictimDetails.changes === true &&
      currentState.incVictims[i].incVictimDetails.ptsIncPersonId
    ) {
      currentState.incVictims[i].incVictimDetails.ptsOffenseId = offenseID;
      if (currentState.incVictims[i].incVictimDetails.ptsVictimId) {
        updateVictim(currentState.incVictims[i].incVictimDetails, dispatch);
        console.log('Victim Updated');
      } else {
        let savedParty = currentState.parties.find(
          (x) => x.ptsIncPersonId === currentState.incVictims[i].incVictimDetails.ptsIncPersonId
        );
        savedParty &&
          addNewVictim(
            currentState.incVictims[i].incVictimDetails,
            savedParty,
            incidentID,
            dispatch
          )
            .then((res) => {
              currentState.incVictims[i].incVictimDetails.ptsVictimId = res.ptsVictimID;
            })
            .catch((err) => {
              console.log(err);
            });
      }
    }
    currentState.incVictims[i].incVictimDetails.changes = false;
  }
}

async function saveDVVictim(currentState, dispatch) {
  if (
    currentState.offensesDVVictimForm.changes === true &&
    currentState.selectedVictim !== undefined &&
    currentState.selectedVictim !== ''
  ) {
    addIncDVVictim();
    if (Object.keys(currentState.offensesDVVictimForm.values).length !== 0) {
      if (currentState.offensesDVVictimForm.ptsDVVictimId) {
        updateDVVictim(currentState.offensesDVVictimForm);
      } else {
        addNewDVVictim(currentState.offensesDVVictimForm, dispatch);
      }
    }
    currentState.offensesDVVictimForm.changes = false;
  }
}

async function saveDVSuspects(currentState, incidentID, dispatch) {
  for (let i = 0; i < currentState.dvSuspects.length; i++) {
    if (
      currentState.dvSuspects[i].incSuspectDetails.changes === true &&
      currentState.dvSuspects[i].incSuspectDetails.ptsIncPersonId
    ) {
      if (currentState.dvSuspects[i].incSuspectDetails.ptsDVSuspectId) {
        updateSuspect(currentState.dvSuspects[i].incSuspectDetails, dispatch);
      } else {
        let savedParty = currentState.parties.find(
          (x) => x.ptsIncPersonId === currentState.dvSuspects[i].incSuspectDetails.ptsIncPersonId
        );
        savedParty &&
          addNewSuspect(
            currentState.dvSuspects[i].incSuspectDetails,
            savedParty,
            incidentID,
            dispatch
          )
            .then((res) => {
              currentState.dvSuspects[i].incSuspectDetails.ptsDVSuspectId = res.ptsDVSuspectID;
            })
            .catch((err) => {
              console.log(err);
            });
      }
    }
    currentState.dvSuspects[i].incSuspectDetails.changes = false;
  }
}

async function saveDVAdditional(currentState, dispatch) {
  if (
    currentState.offensesDVAdditionalForm.changes === true &&
    currentState.selectedVictim !== undefined &&
    currentState.selectedVictim !== ''
  ) {
    addIncDVAdditional();
    if (Object.keys(currentState.offensesDVAdditionalForm.values).length !== 0) {
      if (currentState.offensesDVAdditionalForm.ptsDVAdditionalId) {
        updateDVAdditional(currentState.offensesDVAdditionalForm);
      } else {
        addNewDVAdditional(currentState.offensesDVAdditionalForm, dispatch);
      }
    }
    currentState.offensesDVAdditionalForm.changes = false;
  }
}

async function saveProperties(currentState, incidentID, offenseID, dispatch) {
  for (let i = 0; i < currentState.incProperties.length; i++) {
    if (
      currentState.incProperties[i].incPropertyDetails.changes === true &&
      currentState.incProperties[i].incPropertyDetails.incIncidentPropertyId
    ) {
      currentState.incProperties[i].incPropertyDetails.ptsOffenseId = offenseID;
      if (currentState.incProperties[i].incPropertyDetails.ptsPropertyId) {
        updateProperty(currentState.incProperties[i].incPropertyDetails, dispatch);
      } else {
        let savedProperty = currentState.properties.find(
          (x) =>
            x.propertyDetails.incIncidentPropertyId ===
            currentState.incProperties[i].incPropertyDetails.incIncidentPropertyId
        );
        savedProperty &&
          addNewProperty(currentState.incProperties[i].incPropertyDetails, savedProperty, dispatch)
            .then((res) => {
              currentState.incProperties[i].incPropertyDetails.incIncidentPropertyId =
                res.incIncidentPropertyID;
              currentState.incProperties[i].incPropertyDetails.ptsPropertyId = res.ptsPropertyID;
            })
            .catch((err) => {
              console.log(err);
            });
      }
    }
    currentState.incProperties[i].incPropertyDetails.changes = false;
  }
}

async function saveArrestees(currentState, incidentID, offenseID, dispatch) {
  for (let i = 0; i < currentState.incArrestees.length; i++) {
    if (
      currentState.incArrestees[i].incArresteeDetails.changes === true &&
      currentState.incArrestees[i].incArresteeDetails.ptsIncPersonId
    ) {
      currentState.incArrestees[i].incArresteeDetails.ptsOffenseId = offenseID;
      if (currentState.incArrestees[i].incArresteeDetails.ptsArresteeId) {
        updateArrestee(currentState.incArrestees[i].incArresteeDetails, dispatch);
      } else {
        let savedParty = currentState.parties.find(
          (x) => x.ptsIncPersonId === currentState.incArrestees[i].incArresteeDetails.ptsIncPersonId
        );
        savedParty &&
          addNewArrestee(
            currentState.incArrestees[i].incArresteeDetails,
            savedParty,
            incidentID,
            dispatch
          )
            .then((res) => {
              console.log('Arrestee Created');
              currentState.incArrestees[i].incArresteeDetails.ptsArresteeId = res.ptsArresteeID;
            })
            .catch((err) => {
              console.log(err);
            });
      }
    }
    currentState.incArrestees[i].incArresteeDetails.changes = false;
  }
}

async function saveRelationships(currentState, incidentID, offenseID, dispatch) {
  for (let i = 0; i < currentState.incRelationships.length; i++) {
    if (currentState.incRelationships[i].incRelationshipDetails.changes === true) {
      currentState.incRelationships[i].incRelationshipDetails.ptsOffenseId = offenseID;
      let savedVictim = currentState.incVictims.find(
        (x) =>
          x.incVictimDetails.ptsIncPersonId ===
          currentState.incRelationships[i].incRelationshipDetails.values.relationship.victimId
      );
      let savedSubject = currentState.incSubjects.find(
        (x) =>
          x.incSubjectDetails.ptsIncPersonId ===
          currentState.incRelationships[i].incRelationshipDetails.values.relationship.offenderId
      );
      if (!savedVictim || !savedSubject) continue;
      doesRelationshipExist(savedVictim, savedSubject, offenseID)
        .then((res) => {
          if (res.total > 0) {
            updateRelationship(
              savedVictim,
              savedSubject,
              currentState.incRelationships[i].incRelationshipDetails,
              dispatch
            );
          } else {
            addNewRelationship(
              savedVictim,
              savedSubject,
              currentState.incRelationships[i].incRelationshipDetails,
              dispatch
            );
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
    currentState.incRelationships[i].incRelationshipDetails.changes = false;
  }
}

export const setHandleIncFormSave = () => {
  return async (dispatch) => {
    const menuState = store.store.getState().uiMenu;

    try {
      dispatch({ type: SET_CIRCULAR_LOADING, payload: true });
      const currentState = store.store.getState().incident;
      const recordsState = store.store.getState().records;
      const modalState = store.store.getState().uiModal;

      // For Records
      if (recordsState.recordActive) {
        await upsertAddress(currentState, true, dispatch);
        console.log('RECORD ADDRESS DONE');

        await upsertProperties(currentState, true, dispatch);
        currentState.propertyItemForm.changes = false;
        currentState.propertyFirearmForm.changes = false;
        currentState.propertySubstanceForm.changes = false;
        currentState.propertyStructureForm.changes = false;
        currentState.propertyVehicleDetailsForm.changes = false;
        currentState.propertyJewelryForm.changes = false;

        console.log('RECORD PROPERTY DONE');
      } else {
        const incident = await upsertIncident(currentState.incident);
        if (incident) {
          currentState.ptsIncidentId = incident.ptsIncidentId;
          currentState.incident.ptsLocationId = incident.ptsLocationId;
          dispatch({ type: SET_INCIDENT, payload: incident });
        }

        console.log('INC DONE', incident);

        const address = await upsertAddress(currentState, false, dispatch);

        if (address) {
          const { addresses } = currentState;
          currentState.addressForm.ptsAddressId = address.ptsAddressId;
          currentState.coordinatesForm.ptsCoordinateId = address.ptsCoordinateId;
          addresses.push(address);
          dispatch({ type: GET_ADDRESSESS, payload: addresses });
        }

        console.log('ADDRESS DONE');

        const property = await upsertProperties(currentState, false, dispatch);
        if (property) {
          const { properties } = currentState;
          properties.push(property);
          dispatch({ type: GET_PROPERTIES, payload: properties });
          currentState.propertyItemForm.changes = false;
          currentState.propertyFirearmForm.changes = false;
          currentState.propertySubstanceForm.changes = false;
          currentState.propertyStructureForm.changes = false;
          currentState.propertyVehicleDetailsForm.changes = false;
          currentState.propertyJewelryForm.changes = false;
        }

        const propertiesErrors = validateProperties();
        preparePropertiesErrors(propertiesErrors);
        console.log('PROPERTY DONE');

        let incRelationshipErrors = validateIncRelationships();
        prepareIncRelationshipErrors(incRelationshipErrors);

        const party = await upsertParty(currentState, dispatch);
        if (party) {
          let { parties } = currentState;
          let foundParty = false;
          parties.forEach((p, i) => {
            if (p.personDetail.ptsIncPersonId === party.personDetail.ptsIncPersonId) {
              foundParty = true;
              parties[i] = party;
            }
          });

          if (foundParty === false) parties.push(party);

          dispatch({ type: GET_PARTIES, payload: parties });
        }

        const partiesErrors = validateParties();
        preparePartiesErrors(partiesErrors);
        console.log('PARTY DONE');
        const incidentID = currentState.ptsIncidentId;

        if (incidentID) {
          /**
           * Below ifs will execute when user add Offenders, Victims, etc by clicking the add button
           * Attach new added party to the inc* lists
           */
          if (menuState.handleAddIncSubject)
            addIncSubjects(currentState.parties[currentState.parties.length - 1]);
          if (menuState.handleAddIncVictim)
            addIncVictims(currentState.parties[currentState.parties.length - 1]);
          if (menuState.handleAddIncDVSuspect)
            addIncSuspects(currentState.parties[currentState.parties.length - 1]);
          if (menuState.handleAddIncRelationship) addVictimRelationships();
          if (menuState.handleAddIncProperty)
            addIncProperties(currentState.properties[currentState.properties.length - 1]);
          if (menuState.handleAddIncArrestee)
            addIncArrestees(currentState.parties[currentState.parties.length - 1]);

          /**
           * Create and Update Offense
           * -> changes is a flag which indicates that the form has some changes for create or update
           * -> do we have to create or update? We can figure out by checking the pts*id
           */
          if (currentState.offensesOffenseForm.changes) {
            addOffenses();

            if (Object.keys(currentState.offensesOffenseForm.values).length !== 0) {
              if (currentState.offensesOffenseForm.ptsOffenseId) {
                updateOffense(currentState.offensesOffenseForm).then(async (res) => {
                  let errors = await validateOffenses(currentState);
                  await prepareOffenseErrors(errors);
                });
                console.log('Offense updated');
              } else {
                addNewOffense(currentState.offensesOffenseForm, incidentID, dispatch)
                  .then((res) => {
                    console.log('Offense created');
                    currentState.currentOffense = currentState.offensesOffenseForm.ptsOffenseId =
                      res.ptsOffenseID;
                    saveSubjects(currentState, incidentID, res.ptsOffenseID, dispatch).finally(
                      () => {
                        saveVictims(currentState, incidentID, res.ptsOffenseID, dispatch).finally(
                          () => {
                            saveDVVictim(currentState, dispatch);
                            saveDVSuspects(currentState, dispatch);
                            saveDVAdditional(currentState, dispatch);
                            saveRelationships(currentState, incidentID, res.ptsOffenseID, dispatch);
                          }
                        );
                      }
                    );
                    saveProperties(currentState, incidentID, res.ptsOffenseID, dispatch);
                    saveArrestees(currentState, incidentID, res.ptsOffenseID, dispatch);

                    /* NIBRs Errors */
                    let errors = validateOffenses(currentState);
                    prepareOffenseErrors(errors);

                    let arresteeErrors = validateArrestees(currentState);
                    prepareArresteesErrors(arresteeErrors);

                    let propertyErrors = validateIncProperties(currentState);
                    prepareIncPropertiesErrors(propertyErrors);

                    let incRelationshipErrors = validateIncRelationships(currentState);
                    prepareIncRelationshipErrors(incRelationshipErrors);

                    let incVictimErrors = validateIncVictims(currentState);
                    console.log('incVictimErrors', incVictimErrors);
                    prepareIncVictimErrors(incVictimErrors);

                    /* END: NIBRs Errors */
                  })
                  .catch((err) => {
                    console.log(err);
                  });
              }
            }
            currentState.offensesOffenseForm.changes = false;
          }

          if (currentState.offensesOffenseForm.ptsOffenseId) {
            saveSubjects(
              currentState,
              incidentID,
              currentState.offensesOffenseForm.ptsOffenseId,
              dispatch
            ).finally((s) => {
              saveVictims(
                currentState,
                incidentID,
                currentState.offensesOffenseForm.ptsOffenseId,
                dispatch
              ).finally((v) => {
                saveDVVictim(currentState, dispatch);
                saveDVSuspects(currentState, dispatch);
                saveDVAdditional(currentState, dispatch);
                saveRelationships(
                  currentState,
                  incidentID,
                  currentState.offensesOffenseForm.ptsOffenseId,
                  dispatch
                );
              });
            });

            saveProperties(
              currentState,
              incidentID,
              currentState.offensesOffenseForm.ptsOffenseId,
              dispatch
            );
            saveArrestees(
              currentState,
              incidentID,
              currentState.offensesOffenseForm.ptsOffenseId,
              dispatch
            );
            let arresteeErrors = validateArrestees(currentState);
            prepareArresteesErrors(arresteeErrors);

            let propertyErrors = validateIncProperties(currentState);
            prepareIncPropertiesErrors(propertyErrors);

            let incRelationshipErrors = validateIncRelationships(currentState);
            prepareIncRelationshipErrors(incRelationshipErrors);

            let incVictimErrors = validateIncVictims(currentState);
            prepareIncVictimErrors(incVictimErrors);

            dispatch(
              updateIncPartiesPersonForm({ isValid: false, values: {}, touched: {}, errors: {} })
            );
          }

          /**region Handle evidence save/edit */
          if (currentState.evidenceItemForm.changes) {
            const incidentID = currentState.ptsIncidentId;

            var offenseIDs = [];
            if (currentState.evidenceItemForm?.values.offenses) {
              for (let i = 0; i < currentState.evidenceItemForm?.values.offenses?.length; i++) {
                for (let j = 0; j < currentState.offenses.length; j++) {
                  if (
                    currentState.offenses[j].offenseDetails.values.statute ===
                    currentState.evidenceItemForm?.values.offenses[i]
                  ) {
                    offenseIDs.push(currentState.offenses[j].offenseDetails.ptsOffenseId);
                  }
                }
              }
            }

            if (Object.keys(currentState.evidenceItemForm.values).length !== 0) {
              if (currentState.evidenceItemForm.ptsEvidenceId) {
                const res = await updateEvidenceItem(
                  currentState.evidenceItemForm,
                  incidentID,
                  offenseIDs
                );
                console.log('Evidence item updated => ', res);
              } else {
                const res = await addNewEvidenceItem(
                  currentState.evidenceItemForm,
                  incidentID,
                  offenseIDs
                );
                currentState.evidenceItemForm.ptsEvidenceId = res.ptsEvidenceID;
                console.log('Evidence item created => ', res);
              }
            }
            currentState.evidenceItemForm.changes = false;
          }

          for (let i = 0; i < currentState.incEvidenceCustody.length; i++) {
            if (currentState.incEvidenceCustody[i].incEvidenceCustodyDetails.changes === true) {
              if (
                currentState.incEvidenceCustody[i].incEvidenceCustodyDetails.ptsEvidenceCustodyId
              ) {
                const res = await updateEvidenceCustody(
                  currentState.incEvidenceCustody[i].incEvidenceCustodyDetails
                );
                console.log('Custody Updated', res);
              } else {
                if (currentState.evidenceItemForm.ptsEvidenceId) {
                  const res = await addNewEvidenceCustody(
                    currentState.incEvidenceCustody[i].incEvidenceCustodyDetails,
                    incidentID,
                    currentState.evidenceItemForm.ptsEvidenceId
                  );
                  currentState.incEvidenceCustody[
                    i
                  ].incEvidenceCustodyDetails.ptsEvidenceCustodyId = res.ptsEvidenceCustodyID;
                  console.log('Evidence custody created => ', res);
                }
              }
            }
            currentState.incEvidenceCustody[i].incEvidenceCustodyDetails.changes = false;
          }

          for (let i = 0; i < currentState.incEvidenceStorage.length; i++) {
            if (currentState.incEvidenceStorage[i].incEvidenceStorageDetails.changes === true) {
              if (
                currentState.incEvidenceStorage[i].incEvidenceStorageDetails.ptsEvidenceStorageId
              ) {
                const res = await updateEvidenceStorage(
                  currentState.incEvidenceStorage[i].incEvidenceStorageDetails
                );
                console.log('Evidence storage updated => ', res);
              } else {
                if (currentState.evidenceItemForm.ptsEvidenceId) {
                  const res = await addNewEvidenceStorage(
                    currentState.incEvidenceStorage[i].incEvidenceStorageDetails,
                    incidentID,
                    currentState.evidenceItemForm.ptsEvidenceId
                  );
                  currentState.incEvidenceStorage[
                    i
                  ].incEvidenceStorageDetails.ptsEvidenceStorageId = res.ptsEvidenceStorageID;
                  console.log('Evidence storage created => ', res);
                }
              }
            }
            currentState.incEvidenceStorage[i].incEvidenceStorageDetails.changes = false;
          }

          if (currentState.evidenceDispositionForm.changes)
            if (Object.keys(currentState.evidenceDispositionForm.values).length !== 0) {
              if (currentState.evidenceDispositionForm.ptsEvidenceDispositionId) {
                const res = await updateEvidenceDisposition(currentState.evidenceDispositionForm);
                console.log('Evidence disposition updated => ', res);
              } else {
                if (currentState.evidenceItemForm.ptsEvidenceId) {
                  const res = await addNewEvidenceDisposition(
                    currentState.evidenceDispositionForm,
                    incidentID,
                    currentState.evidenceItemForm.ptsEvidenceId
                  );
                  currentState.evidenceDispositionForm.ptsEvidenceDispositionId =
                    res.ptsEvidenceDispositionID;
                  console.log('Evidence disposition created => ', res);
                }
              }
              currentState.evidenceDispositionForm.changes = false;
            }

          if (currentState.narrativeForm.changes) {
            if (currentState.narrativeForm.values.narrativeId) {
              updateNarratives(currentState.narrativeForm);
            } else {
              addNewNarratives(currentState.narrativeForm, incidentID, dispatch)
                .then((res) => {
                  currentState.narrativeForm.ptsNarrativeID = res.ptsNarrativeID;
                })
                .catch((err) => {
                  console.log(err);
                });
            }

            currentState.narrativeForm.changes = false;
          }
        }
        /**endregion */
        if (!modalState.showDuplicateModal && !store.store.getState().incident.requiredError) {
          if (currentState.ptsIncidentId > 0) {
            dispatch({ type: SET_HANDLE_INC_FORM_SAVE, payload: 'edited' });
          } else {
            dispatch({ type: SET_HANDLE_INC_FORM_SAVE, payload: 'saved' });
          }
        }
      }
      dispatch({ type: SET_CIRCULAR_LOADING, payload: false });
    } catch (e) {
      console.log(e);
      dispatch({ type: SET_CIRCULAR_LOADING, payload: false });
    }
  };
};

export const setHandleIncCadFormSave = () => {
  return async (dispatch) => {
    dispatch({ type: SET_CIRCULAR_LOADING, payload: true });

    const currentState = store.store.getState().incident;
    const { zoneForm, dispositionForm } = currentState;

    const incident = await upsertIncident(currentState.incident);
    if (incident) {
      currentState.ptsIncidentId = incident.ptsIncidentId;
      currentState.incident.ptsLocationId = incident.ptsLocationId;
      dispatch({ type: SET_INCIDENT, payload: incident });
    }

    if (zoneForm.changes) {
      zoneForm.values.ptsLocationId = currentState.incident.ptsLocationId;
      addZone(zoneForm);
    }

    if (dispositionForm.changes) {
      dispositionForm.values.ptsIncidentId = currentState.incident.ptsIncidentId;
      addDisposition(dispositionForm);
    }

    dispatch({ type: SET_CIRCULAR_LOADING, payload: false });
    dispatch({ type: SET_HANDLE_INC_FORM_SAVE, payload: 'edited' });
  };
};

/* Only for Storing Property as Evidence */
export const setHandleIncEvidenceFormSave = () => {
  const currentState = store.store.getState().incident;
  const offenseIDs = [];
  const incidentID = currentState.ptsIncidentId;

  return async (dispatch) => {
    dispatch({ type: SET_CIRCULAR_LOADING, payload: true });
    await addNewEvidenceItem(currentState.evidenceItemForm, incidentID, offenseIDs);

    await dispatch(getEntitiesByIncidentId('evidence')).then(() =>
      dispatch(setListBodyCells('evidence'))
    );

    dispatch({ type: SET_CIRCULAR_LOADING, payload: false });
  };
};
/* End: Only for Stroring Property as Evidence */

/*** End: Form Save Region */

export const setMenuType = (type) => {
  return async (dispatch) => {
    return dispatch({ type: SET_MENU_TYPE, payload: type });
  };
};

export const setHandleAddIncSubject = (flag) => {
  return async (dispatch) => {
    return dispatch({ type: SET_HANDLE_ADD_INC_SUBJECT, payload: flag });
  };
};

export const setHandleAddIncVictim = (flag) => {
  return async (dispatch) => {
    return dispatch({ type: SET_HANDLE_ADD_INC_VICTIM, payload: flag });
  };
};

export const setHandleAddIncProperty = (flag) => {
  return async (dispatch) => {
    return dispatch({ type: SET_HANDLE_ADD_INC_PROPERTY, payload: flag });
  };
};

export const setHandleAddIncRelationship = (flag) => {
  return async (dispatch) => {
    return dispatch({ type: SET_HANDLE_ADD_INC_RELATIONSHIP, payload: flag });
  };
};

export const setHandleAddIncArrestee = (flag) => {
  return async (dispatch) => {
    return dispatch({ type: SET_HANDLE_ADD_INC_ARRESTEE, payload: flag });
  };
};

export const setHandleAddIncDVVictim = (flag) => {
  return async (dispatch) => {
    return dispatch({ type: SET_HANDLE_ADD_INC_DV_VICTIM, payload: flag });
  };
};

export const setHandleAddIncDVSuspect = (flag) => {
  return async (dispatch) => {
    return dispatch({ type: SET_HANDLE_ADD_INC_DV_SUSPECT, payload: flag });
  };
};

export const setHandleAddIncDVAdditional = (flag) => {
  return async (dispatch) => {
    return dispatch({ type: SET_HANDLE_ADD_INC_DV_ADDITIONAL, payload: flag });
  };
};

export const setHandleOffensesRedirectFormClose = (flag) => {
  return async (dispatch) => {
    return dispatch({ type: SET_OFFENSES_REDIRECT_FORM_CLOSE, payload: flag });
  };
};

export const setHandleClose = (type, history) => {
  return async (dispatch) => {
    const menuState = store.store.getState().uiMenu;
    if (!menuState.handleOffensesFormRedirectCloseState) {
      switch (type) {
        case 'incidents':
          const route = () => history.push('/incidents');
          return dispatch({ type: SET_HANDLE_CLOSE, payload: route });
        case 'incForms':
          const routeIncForm = () => history.push('/incidents/add');
          return dispatch({ type: SET_HANDLE_CLOSE, payload: routeIncForm });
        case 'incSubForms':
          const routeIncSubForm = () => history.push('/incidents/add/offenses');
          return dispatch({ type: SET_HANDLE_CLOSE, payload: routeIncSubForm });
        default:
          return;
      }
    } else {
      const routeOffenseForms = () => history.push('/incidents/add/offenses');
      return dispatch({ type: SET_HANDLE_CLOSE, payload: routeOffenseForms });
    }
  };
};

export const setCircularLoading = (flag) => {
  return async (dispatch) => {
    return dispatch({ type: SET_CIRCULAR_LOADING, payload: flag });
  };
};

export const setShowIncidentStepper = (flag) => {
  return (dispatch) => {
    return dispatch({ type: SET_SHOW_INCIDENT_STEPPER, payload: flag });
  };
};

export default function reducer(
  state = {
    menuTypeState: '',
    handleCloseState: null,
    handleIncFormSaveState: null,
    handleOffensesFormRedirectCloseState: false,
    handleAddIncSubject: false,
    handleAddIncVictim: false,
    handleAddIncProperty: false,
    handleAddIncRelationship: false,
    handleAddIncArrestee: false,
    handleAddIncDVVictim: false,
    handleAddIncDVSuspect: false,
    handleAddIncDVAdditional: false,
    handleAddIncEvidenceCustody: false,
    handleAddIncEvidenceStorage: false,
    circularLoading: false,
    showIncidentStepper: true,
  },
  action
) {
  switch (action.type) {
    case SET_MENU_TYPE:
      return { ...state, menuTypeState: action.payload };
    case SET_HANDLE_CLOSE:
      return { ...state, handleCloseState: action.payload };
    case SET_HANDLE_INC_FORM_SAVE:
      return { ...state, handleIncFormSaveState: action.payload };
    case SET_OFFENSES_REDIRECT_FORM_CLOSE:
      return { ...state, handleOffensesFormRedirectCloseState: action.payload };
    case SET_HANDLE_ADD_INC_SUBJECT:
      return { ...state, handleAddIncSubject: action.payload };
    case SET_HANDLE_ADD_INC_VICTIM:
      return { ...state, handleAddIncVictim: action.payload };
    case SET_HANDLE_ADD_INC_PROPERTY:
      return { ...state, handleAddIncProperty: action.payload };
    case SET_HANDLE_ADD_INC_RELATIONSHIP:
      return { ...state, handleAddIncRelationship: action.payload };
    case SET_HANDLE_ADD_INC_ARRESTEE:
      return { ...state, handleAddIncArrestee: action.payload };
    case SET_HANDLE_ADD_INC_DV_VICTIM:
      return { ...state, handleAddIncDVVictim: action.payload };
    case SET_HANDLE_ADD_INC_DV_SUSPECT:
      return { ...state, handleAddIncDVSuspect: action.payload };
    case SET_HANDLE_ADD_INC_DV_ADDITIONAL:
      return { ...state, handleAddIncDVAdditional: action.payload };
    case SET_CIRCULAR_LOADING:
      return { ...state, circularLoading: action.payload };
    case SET_SHOW_INCIDENT_STEPPER:
      return { ...state, showIncidentStepper: action.payload };
    default:
      return state;
  }
}
