import React from 'react';
import { connect } from 'react-redux';
import { notify } from '../../reducers/NotifierReducer';
import { showSpinner, hideSpinner } from '../../reducers/ui/UiDrawerReducer';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  pageWrap: {
    '& h3': {
      marginTop: 20,
    },
  },
  buttons: {
    '& button': {
      marginRight: 10,
    },
  },
}));

let timeout = 0;

function PageRMSReports() {
  const classes = useStyles();

  React.useEffect(() => {
    return () => {
      clearTimeout(timeout);
    };
  }, []);

  return (
    <div className={classes.pageWrap}>
      <div>
        <div></div>
      </div>
    </div>
  );
}

export default connect(null, { notify, showSpinner, hideSpinner })(PageRMSReports);
