/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { connect } from 'react-redux';
import { updateIncArresteeDetails } from '../../../../reducers/IncReducer';
import { getCodeResidence } from '../../../../reducers/dictionaries/CodeResidentReducer';
import { getCodeArrestCategories } from '../../../../reducers/dictionaries/CodeArrestReducer';
import { getCodeJuvenileDisposition } from '../../../../reducers/dictionaries/CodeOthersReducer';
import { getCodeArresteeArmed } from '../../../../reducers/dictionaries/CodeArrestReducer';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { handleFormChangeNew } from '../../../../reducers/helpers/formHelpers';
import RMSAutoComplete from 'components/RMSAutoComplete/RMSAutoComplete';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { validateArresteesForm } from 'simpl-schema-validation/ValidateForms';

const options = [{ name: 'Option One' }, { name: 'Option Two' }];

const ArresteesForm = (props) => {
  /** region Component Variables and Props */
  const {
    updateIncArresteeDetails,
    incArrestees,
    selectedArrestee,
    codeResident,
    getCodeResidence,
    getCodeJuvenileDisposition,
    getCodeArrestCategories,
    getCodeArresteeArmed,
    codeArrestCategories,
    codeArresteeArmed,
    codeJuvenileDisposition,
    wsClient,
  } = props;
  const [errors, setErrors] = React.useState({});
  const [selectedArrestDate, setSelectedArrestDate] = React.useState(Date.now());
  const [offensesArresteesFormState, setOffensesArresteesFormState] = React.useState({
    isValid: false,
    values: {},
    touched: {},
    errors: {},
  });
  /** endregion*/
  /** region React Hooks */
  React.useEffect(() => {
    if (wsClient.websocket) {
      getCodeJuvenileDisposition();
      getCodeArrestCategories();
      getCodeArresteeArmed();
    }
  }, [getCodeJuvenileDisposition, getCodeArrestCategories, getCodeArresteeArmed, wsClient]);

  React.useEffect(() => {
    let timeoutId;
    if (offensesArresteesFormState.values.arrestDate) {
      timeoutId = setTimeout(() => {
        let errorStatus = errors;
        let error = validateArresteesForm('arrestDate', offensesArresteesFormState);
        if (error && error.length > 0) {
          errorStatus = {
            ...errorStatus,
            arrestDate: `${error[0].message}`,
          };
        } else {
          errorStatus = {
            ...errorStatus,
            arrestDate: null,
          };
        }

        error = validateArresteesForm('arresteeArmedWith', offensesArresteesFormState);
        if (error && error.length > 0) {
          errorStatus = {
            ...errorStatus,
            arresteeArmedWith: `${error[0].message}`,
          };
        } else {
          errorStatus = {
            ...errorStatus,
            arresteeArmedWith: null,
          };
        }

        error = validateArresteesForm('arresteeSegmentsIndicator', offensesArresteesFormState);
        if (error && error.length > 0) {
          errorStatus = {
            ...errorStatus,
            arresteeSegmentsIndicator: `${error[0].message}`,
          };
        } else {
          errorStatus = {
            ...errorStatus,
            arresteeSegmentsIndicator: null,
          };
        }

        setErrors(errorStatus);
      }, 2000);
    }
    return () => clearTimeout(timeoutId);
  }, [
    offensesArresteesFormState.values.arrestDate,
    offensesArresteesFormState.values.arresteeArmedWith,
    offensesArresteesFormState.values.arresteeSegmentsIndicator,
  ]);

  React.useEffect(() => {
    const services = ['codeResident'];

    if (!wsClient.websocket) return;

    services.forEach(async (svc) => {
      if (props[svc].length < 1) {
        const result = await wsClient.websocket.service(svc.toLowerCase()).find({
          query: {
            $limit: 0,
            $select: ['Code', 'Description'],
            $sort: {
              Description: 1,
            },
            IsDeleted: false,
          },
        });

        if (result.total > 0) {
          getCodeResidence(result.data);
        }
      }
    });
  }, [wsClient]);

  const updateIncDetailsList = () => {
    updateIncArresteeDetails(offensesArresteesFormState);
  };

  const getIncFormData = () => {
    setOffensesArresteesFormState({ ...offensesArresteesFormState, values: {} });

    let selectedArresteeDetails = incArrestees.find(
      (x) => x.incArresteeDetails.ptsIncPersonId === selectedArrestee
    );

    if (selectedArresteeDetails && selectedArresteeDetails.incArresteeDetails) {
      setOffensesArresteesFormState(selectedArresteeDetails.incArresteeDetails);
      setSelectedArrestDate(selectedArresteeDetails.incArresteeDetails.values.arrestDate);
    }
  };

  React.useEffect(() => {
    if (props.updated !== null) updateIncDetailsList();
  }, [props.updated]);

  React.useEffect(() => {
    getIncFormData();
  }, [selectedArrestee]);
  /** endregion */
  return (
    <Grid container justify="center" className="mt-3">
      <Grid container justify="space-evenly" className="mb-1" spacing={2}>
        <Grid item xs={10} lg={10}>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <KeyboardDatePicker
              size="small"
              inputVariant="outlined"
              fullWidth
              showTodayButton={true}
              variant="dialog"
              format="MM/dd/yyyy"
              id="date-picker-inline-from"
              label="Arrest Date"
              error={errors['arrestDate'] ? true : false}
              helperText={errors['arrestDate']}
              value={
                offensesArresteesFormState.values.arrestDate
                  ? offensesArresteesFormState.values.arrestDate
                  : null
              }
              onChange={(date) => {
                setOffensesArresteesFormState((state) => {
                  return {
                    ...state,
                    values: {
                      ...state.values,
                      arrestDate: date,
                    },
                  };
                });
              }}
              KeyboardButtonProps={{
                'aria-label': 'change date',
              }}
            />
          </MuiPickersUtilsProvider>
        </Grid>
        <Grid item xs={2} lg={2}>
          <FormControlLabel
            control={
              <Checkbox
                key={offensesArresteesFormState.values.cleared}
                checked={offensesArresteesFormState.values.cleared}
                onChange={(event) =>
                  handleFormChangeNew(
                    event,
                    event.target.checked,
                    'none',
                    'cleared',
                    setOffensesArresteesFormState
                  )
                }
                name="cleared"
              />
            }
            label="Cleared"
          />
        </Grid>
      </Grid>

      <Grid container justify="center" spacing={2} className="mb-2">
        <Grid item xs={6} lg={6}>
          <RMSAutoComplete
            size="small"
            autoComplete
            autoSelect
            autoHighlight
            id="combo-arrest-type"
            options={codeArrestCategories}
            // new ones
            serviceName="codearrestcategories"
            label="Arrest Type"
            variant="outlined"
            title="Description"
            stateHolder={offensesArresteesFormState}
            setStateHolder={setOffensesArresteesFormState}
            setField={handleFormChangeNew}
            stateHolderAttribute="arrestType"
          />
        </Grid>
        <Grid item xs={6} lg={6}>
          <Autocomplete
            size="small"
            autoComplete
            autoSelect
            autoHighlight
            id="combo-arrestee-segments"
            getOptionLabel={(option) => option || ''}
            options={options.map((s) => s.name)}
            key={offensesArresteesFormState.values.arresteeSegmentsIndicator || ''}
            value={offensesArresteesFormState.values.arresteeSegmentsIndicator || ''}
            onChange={(event, newValue) =>
              handleFormChangeNew(
                event,
                newValue,
                'none',
                'arresteeSegmentsIndicator',
                setOffensesArresteesFormState
              )
            }
            renderInput={(params) => (
              <TextField
                size="small"
                {...params}
                label="Arrestee Segments Indicator"
                error={errors['arresteeSegmentsIndicator'] ? true : false}
                helperText={errors['arresteeSegmentsIndicator']}
                variant="outlined"
                fullWidth
              />
            )}
          />
        </Grid>
      </Grid>

      <Grid container justify="center" spacing={2} className="mb-2">
        <Grid item xs={6} lg={6}>
          <Autocomplete
            size="small"
            autoComplete
            autoSelect
            autoHighlight
            id="combo-arrest-offense-description"
            getOptionLabel={(option) => option || ''}
            options={options.map((s) => s.name)}
            key={offensesArresteesFormState.values.offenseDescription || ''}
            value={offensesArresteesFormState.values.offenseDescription || ''}
            onChange={(event, newValue) =>
              handleFormChangeNew(
                event,
                newValue,
                'none',
                'offenseDescription',
                setOffensesArresteesFormState
              )
            }
            renderInput={(params) => (
              <TextField
                size="small"
                {...params}
                label="Arrest Offense Description"
                variant="outlined"
                fullWidth
              />
            )}
          />
        </Grid>
        <Grid item xs={6} lg={6}>
          <RMSAutoComplete
            size="small"
            autoComplete
            autoSelect
            autoHighlight
            id="combo-juvenile-disposition"
            options={codeJuvenileDisposition}
            // new ones
            serviceName="codejuveniledisposition"
            label="Juvenile Disposition"
            variant="outlined"
            title="Description"
            stateHolder={offensesArresteesFormState}
            setStateHolder={setOffensesArresteesFormState}
            setField={handleFormChangeNew}
            stateHolderAttribute="juvenileDisposition"
          />
        </Grid>
      </Grid>

      <Grid container justify="center" spacing={2} className="mb-2">
        <Grid item xs={6} lg={6}>
          <RMSAutoComplete
            size="small"
            autoComplete
            autoSelect
            autoHighlight
            id="combo-arrestee-resident-status"
            options={codeResident}
            // new ones
            serviceName="coderesident"
            label="Arrestee Resident Status"
            variant="outlined"
            title="Description"
            stateHolder={offensesArresteesFormState}
            setStateHolder={setOffensesArresteesFormState}
            setField={handleFormChangeNew}
            stateHolderAttribute="arresteeResidentStatus"
          />
        </Grid>
        <Grid item xs={6} lg={6}>
          <RMSAutoComplete
            size="small"
            autoComplete
            autoSelect
            autoHighlight
            id="combo-arrestee-armedwith"
            options={codeArresteeArmed}
            // new ones
            serviceName="codearresteearmed"
            label="Arrestee Armed With"
            variant="outlined"
            title="Description"
            stateHolder={offensesArresteesFormState}
            setStateHolder={setOffensesArresteesFormState}
            setField={handleFormChangeNew}
            stateHolderAttribute="arresteeArmedWith"
            error={errors['arresteeArmedWith'] ? true : false}
            helperText={errors['arresteeArmedWith']}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

const mapStateToProps = (state) => ({
  wsClient: state.websocket,
  offensesArresteesForm: state.incident.offensesArresteesForm,
  codeResident: state.codeResident,
  codeArrestCategories: state.codesArrest.codeArrestCategories,
  codeArresteeArmed: state.codesArrest.codeArresteeArmed,
  codeJuvenileDisposition: state.codesOthers.codeJuvenileDisposition,
  incArrestees: state.incident.incArrestees,
  selectedArrestee: state.incident.selectedArrestee,
});

export default connect(mapStateToProps, {
  getCodeResidence,
  getCodeJuvenileDisposition,
  getCodeArrestCategories,
  getCodeArresteeArmed,
  updateIncArresteeDetails,
})(ArresteesForm);
