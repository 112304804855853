export const codeAggravatedAssault = [
  {
    code: 1,
    description: 'Argument',
  },
  {
    code: 2,
    description: 'Assault on Law Enforcement Officer(s)',
  },
  {
    code: 3,
    description: 'Drug Dealing',
  },
  {
    code: 4,
    description: 'Gangland (Organized Crime Involvement)',
  },
  {
    code: 5,
    description: 'Juvenile Gang',
  },
  {
    code: 6,
    description: 'Domestic Violence',
  },
  {
    code: 7,
    description: 'Mercy Killing (Not applicable to Aggravated Assault)',
  },
  {
    code: 8,
    description: 'Other Felony Involved',
  },
  {
    code: 9,
    description: 'Other Circumstances',
  },
  {
    code: 10,
    description: 'Unknown Circumstances',
  },
  {
    code: 20,
    description: 'Criminal Killed by Private Citizen',
  },
  {
    code: 21,
    description: 'Criminal Killed by Police Officer',
  },
  {
    code: 30,
    description: 'Child Playing With Weapon',
  },
  {
    code: 31,
    description: 'Gun-Cleaning Accident',
  },
  {
    code: 32,
    description: 'Hunting Accident',
  },
  {
    code: 33,
    description: 'Other Negligent Weapon Handling',
  },
  {
    code: 34,
    description: 'Other Negligent Killings',
  },
];
