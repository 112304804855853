import store from '../../config/configureStore';

export const SET_CODE_ARRESTEE_ARMED = 'CODE/SET_CODE_ARRESTEE_ARMED';
export const ADD_CODE_ARRESTEE_ARMED = 'CODE/ADD_CODE_ARRESTEE_ARMED';
export const SET_CODE_ARREST_CATEGORIES = 'CODE/SET_CODE_ARREST_CATEGORIES';
export const ADD_CODE_ARREST_CATEGORIES = 'CODE/ADD_CODE_ARREST_CATEGORIES';

export const addCodeArresteeArmed = (newValue) => {
  return { type: ADD_CODE_ARRESTEE_ARMED, payload: newValue };
};

export const getCodeArresteeArmed = () => {
  const client = store.store.getState().websocket.websocket;
  return async (dispatch) => {
    try {
      const service = client.service('codearresteearmed');
      service.timeout = 20000;
      const codes = await service.find({
        query: {
          $select: ['Code', 'Description'],
          $sort: {
            Description: 1,
          },
          IsDeleted: false,
        },
      });
      dispatch({ type: SET_CODE_ARRESTEE_ARMED, payload: codes.data });
    } catch (error) {
      if (error.code === 401) {
        console.log('Unauthenticated');
      } else {
        console.log(error);
      }
    }
  };
};

export const addCodeArrestCategories = (newValue) => {
  return { type: ADD_CODE_ARREST_CATEGORIES, payload: newValue };
};

export const getCodeArrestCategories = () => {
  const client = store.store.getState().websocket.websocket;
  return async (dispatch) => {
    try {
      const service = client.service('codearrestcategories');
      service.timeout = 20000;
      const codes = await service.find({
        query: {
          $select: ['Code', 'Description'],
          $sort: {
            Description: 1,
          },
          IsDeleted: false,
        },
      });
      dispatch({ type: SET_CODE_ARREST_CATEGORIES, payload: codes.data });
    } catch (error) {
      if (error.code === 401) {
        console.log('Unauthenticated');
      } else {
        console.log(error);
      }
    }
  };
};

export default function reducer(
  state = {
    codeArresteeArmed: [],
    codeArrestCategories: [],
  },
  action
) {
  // eslint-disable-next-line default-case
  switch (action.type) {
    case SET_CODE_ARRESTEE_ARMED:
      return { ...state, codeArresteeArmed: action.payload };
    case ADD_CODE_ARRESTEE_ARMED:
      return { ...state, codeArresteeArmed: [...state.codeArresteeArmed, action.payload] };
    case SET_CODE_ARREST_CATEGORIES:
      return { ...state, codeArrestCategories: action.payload };
    case ADD_CODE_ARREST_CATEGORIES:
      return { ...state, codeArrestCategories: [...state.codeArrestCategories, action.payload] };
  }
  return state;
}
