import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import clsx from 'clsx';
import {
  Hidden,
  Drawer,
  IconButton,
  Box,
  Tooltip,
  ListItem,
  List,
  ListItemIcon,
  ListItemText,
} from '@material-ui/core';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import MenuOpenRoundedIcon from '@material-ui/icons/MenuOpenRounded';

import { makeStyles } from '@material-ui/core/styles';

import { setNavigationDrawerMobileState } from '../../../reducers/ui/UiDrawerReducer';
import projectLogo from '../../../assets/images/logo.png';
import menuItems from '../../../config/configureMenu';

const drawerWidth = 240;
const drawerHeight = 80;

const useStyles = makeStyles((theme) => ({
  link: {
    color: theme.palette.colorFont + '!important',
  },
  drawer: {
    [theme.breakpoints.up('sm')]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  drawerPaper: {
    width: drawerWidth,
    backgroundColor: theme.palette.bgElements,
  },
  appSidebar: {
    width: drawerWidth,
    height: drawerHeight,
    background: 'transparent',
    borderBottom: '1px solid #fff',
  },
  toolbar: {
    height: drawerHeight,
    backgroundColor: theme.palette.bgElements,
  },
  logoText: {
    color: theme.palette.colorFont,
    fontWeight: 'bold',
  },
  closeMenuButton: {
    marginRight: 'auto',
    marginLeft: 0,
  },
}));

const DrawerNavigation = (props) => {
  const { state, setNavigationDrawerMobileState } = props;
  const classes = useStyles();
  const closeMenu = () => {
    setNavigationDrawerMobileState();
  };

  return (
    <Hidden mdUp>
      <Drawer
        variant="temporary"
        anchor="left"
        open={state}
        onClose={closeMenu}
        classes={{ paper: classes.drawerPaper }}
        ModalProps={{ keepMounted: true }}>
        <div className={clsx(classes.appSidebar, 'app-sidebar-header')}>
          <Box className="header-logo-wrapper" title="CAD Mobile">
            <Link to="/Main" className="header-logo-wrapper-link">
              <IconButton
                color="primary"
                size="medium"
                style={{ background: 'transparent' }}
                className="header-logo-wrapper-btn">
                <img className="app-sidebar-logo" alt="Logo" src={projectLogo} />
              </IconButton>
            </Link>
            <Box className={classes.logoText}>CAD Mobile</Box>
          </Box>

          <Box className="app-sidebar-header-btn">
            <Tooltip title="Toggle Navigation" placement="right">
              <IconButton color="inherit" onClick={closeMenu} size="medium">
                <MenuOpenRoundedIcon />
              </IconButton>
            </Tooltip>
          </Box>

          <Box className="app-sidebar-header-btn-mobile">
            <Tooltip title="Toggle Navigation" placement="right">
              <IconButton color="inherit" onClick={closeMenu} size="medium">
                <MenuOpenRoundedIcon />
              </IconButton>
            </Tooltip>
          </Box>
        </div>

        <div>
          <List>
            {menuItems.map((item, key) => (
              <Link className={classes.link} to={item.link} key={key} onClick={closeMenu}>
                <ListItem button>
                  <ListItemIcon>
                    <FontAwesomeIcon icon={item.icon} className="font-size-xxl" />
                  </ListItemIcon>
                  <ListItemText primary={item.label} />
                </ListItem>
              </Link>
            ))}
          </List>
        </div>
      </Drawer>
    </Hidden>
  );
};

const mapStateToProps = (state) => ({
  state: state.ui.navigationDrawerMobileState,
});

export default connect(mapStateToProps, {
  setNavigationDrawerMobileState,
})(DrawerNavigation);
