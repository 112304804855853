import React from 'react';
import { connect } from 'react-redux';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import { GrCar } from 'react-icons/gr';

const useStyles = makeStyles((theme) => ({
  icon: {
    marginRight: theme.spacing(2),
  },
}));

function VehicleSearchForRecord(props) {
  const { wsClient, onReset, setVehicleID } = props;
  const classes = useStyles();
  const [value, setValue] = React.useState(null);
  const [inputValue, setInputValue] = React.useState('');
  const [options, setOptions] = React.useState([]);

  React.useEffect(() => {
    if (inputValue === '') {
      setOptions(value ? [value] : []);
      return undefined;
    }

    (async () => {
      try {
        const service = wsClient.websocket.service('rms-property-vehicle');
        service.timeout = 20000;
        const vehicles = await service.find({ query: { searchText: inputValue, search: true } });
        if (vehicles.length > 0) {
          setOptions(vehicles);
        }
      } catch (error) {
        console.log(error);
      }
    })();
  }, [value, inputValue, wsClient.websocket]);

  const getOptionLabel = (option) => {
    let label = '';
    label += option.Brand ? ` ${option.Brand}` : '';
    label += option.Model ? ` ${option.Model}` : '';
    label += option.Make ? ` ${option.Make}` : '';
    label += option.PrimaryColor ? ` ${option.PrimaryColor}` : '';
    label += option.SecondaryColor ? ` ${option.SecondaryColor}` : '';
    label += option.Year ? ` ${option.Year}` : '';
    return label;
  };

  const onChange = (ev, newValue) => {
    setOptions(newValue ? [newValue, ...options] : options);
    setValue(newValue);
  };

  const onInputChange = (ev, newInputValue) => {
    setInputValue(newInputValue);
  };

  const renderInput = (params) => (
    <TextField
      autoComplete="hidden"
      size="small"
      {...params}
      label="Search Vehicles by Brand or Model"
      variant="outlined"
      fullWidth
    />
  );

  const renderOption = (option) => {
    return (
      <Grid container alignItems="center">
        <Grid item>
          <GrCar className={classes.icon} style={{ color: 'red', fontSize: '24px' }} />
        </Grid>
        <Grid item xs>
          <span
            style={{
              fontWeight: 400,
            }}>{`${option.Brand} ${option.Model} ${option.Make} ${option.Year}`}</span>
        </Grid>
      </Grid>
    );
  };

  const getOptionSelected = (option, value) => {
    if (option.ptsVehicleID === value.ptsVehicleID) {
      setVehicleID(option.ptsVehicleID);
      return true;
    }
    return false;
  };

  return (
    <Autocomplete
      size="small"
      autoComplete
      autoSelect
      autoHighlight
      id="vehicle-search"
      getOptionLabel={getOptionLabel}
      filterOptions={(x) => x}
      options={options}
      includeInputInList
      filterSelectedOptions
      style={{ width: '100%' }}
      className={classes.autocomplete}
      value={value || ''}
      onChange={onChange}
      onInputChange={onInputChange}
      renderInput={renderInput}
      renderOption={renderOption}
      getOptionSelected={getOptionSelected}
    />
  );
}

const mapStateToProps = (state) => ({
  wsClient: state.websocket,
});

export default connect(mapStateToProps)(VehicleSearchForRecord);
