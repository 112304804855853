import React from 'react';
import Grid from '@material-ui/core/Grid';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import { connect } from 'react-redux';
import { updateIncOffensesDVAdditionalForm } from '../../../../reducers/IncReducer';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { handleFormChangeNew } from '../../../../reducers/helpers/formHelpers';

const options = [{ name: 'Otion One' }, { name: 'Otion Two' }];

const AdditionalInfoForm = (props) => {
  const {
    offensesDVAdditionalForm,
    updateIncOffensesDVAdditionalForm,
    dvAdditionals,
    selectedVictim,
  } = props;

  const [offensesDVAdditionalFormState, setOffensesDVAdditionalFormState] = React.useState({
    isValid: false,
    changes: true,
    values: {},
    touched: {},
    errors: {},
  });

  React.useEffect(() => {
    async function fetch() {
      let dvAdditionalDetails = dvAdditionals.find(
        (dvAdditional) => dvAdditional.ptsIncPersonId === selectedVictim
      );
      if (dvAdditionalDetails) {
        await updateIncOffensesDVAdditionalForm(dvAdditionalDetails);
        await setOffensesDVAdditionalFormState(dvAdditionalDetails);
      }
    }
    // Fetching current selected victim's dv details
    fetch();
  }, []);

  React.useEffect(() => {
    setOffensesDVAdditionalFormState({
      ...offensesDVAdditionalFormState,
      values: { ...offensesDVAdditionalFormState.values, ...offensesDVAdditionalForm.values },
    });
  }, [offensesDVAdditionalForm]);

  React.useEffect(() => {
    offensesDVAdditionalFormState.changes = true;
    updateIncOffensesDVAdditionalForm(offensesDVAdditionalFormState);
  }, [
    offensesDVAdditionalFormState.values?.ActionTakenCode,
    offensesDVAdditionalFormState.values?.IsRefusedAnyTypeofService,
    offensesDVAdditionalFormState.values?.DidNotWantToReport,
    offensesDVAdditionalFormState.values?.VictimWillBeAtTempAddress,
    offensesDVAdditionalFormState.values?.TempContactPhone,
    offensesDVAdditionalFormState.values?.Age,
    offensesDVAdditionalFormState.values?.MaritialStatus,
    offensesDVAdditionalFormState.values?.Race,
    offensesDVAdditionalFormState.values?.Ethnicity,
  ]);

  return (
    <Grid container spacing={3} justify="center">
      <Grid item xs={5} lg-={5} className="mt-3">
        <Autocomplete
          size="small"
          autoComplete
          autoSelect
          autoHighlight
          id="combo-action-taken-code"
          getOptionLabel={(option) => option || ''}
          options={options.map((s) => s.name)}
          key={offensesDVAdditionalFormState.values.ActionTakenCode || ''}
          value={offensesDVAdditionalFormState.values.ActionTakenCode || ''}
          onChange={(event, newValue) =>
            handleFormChangeNew(
              event,
              newValue,
              'none',
              'ActionTakenCode',
              setOffensesDVAdditionalFormState
            )
          }
          renderInput={(params) => (
            <TextField
              autoComplete="hidden"
              size="small"
              {...params}
              label="Action Taken Code"
              variant="outlined"
              fullWidth
            />
          )}
        />
      </Grid>
      <Grid item xs={10} lg-={10}>
        <FormControlLabel
          control={
            <Checkbox
              key={offensesDVAdditionalFormState.values?.IsRefusedAnyTypeofService}
              checked={offensesDVAdditionalFormState.values?.IsRefusedAnyTypeofService}
              onChange={(event) =>
                handleFormChangeNew(
                  event,
                  event.target.checked,
                  'none',
                  'IsRefusedAnyTypeofService',
                  setOffensesDVAdditionalFormState
                )
              }
              name="IsRefusedAnyTypeofService"
            />
          }
          label="Refused Any Type of Service"
        />

        <FormControlLabel
          control={
            <Checkbox
              key={offensesDVAdditionalFormState.values?.DidNotWantToReport}
              checked={offensesDVAdditionalFormState.values?.DidNotWantToReport}
              onChange={(event) =>
                handleFormChangeNew(
                  event,
                  event.target.checked,
                  'none',
                  'DidNotWantToReport',
                  setOffensesDVAdditionalFormState
                )
              }
              name="DidNotWantToReport"
            />
          }
          label="Did Not Want To Report Incident to Law Enforcement"
        />

        <FormControlLabel
          control={
            <Checkbox
              key={offensesDVAdditionalFormState.values?.VictimWillBeAtTempAddress}
              checked={offensesDVAdditionalFormState.values?.VictimWillBeAtTempAddress}
              onChange={(event) =>
                handleFormChangeNew(
                  event,
                  event.target.checked,
                  'none',
                  'VictimWillBeAtTempAddress',
                  setOffensesDVAdditionalFormState
                )
              }
              name="VictimWillBeAtTempAddress"
            />
          }
          label="Victim Will Be At Temporary Address?"
        />
      </Grid>
      <Grid item xs={5} lg={5}>
        <TextField
          autoComplete="hidden"
          size="small"
          id="standard-temp-contact"
          label="Temp Contact Phone"
          placeholder="000-0000"
          variant="outlined"
          name="TempContactPhone"
          value={offensesDVAdditionalFormState.values?.TempContactPhone}
          onChange={(event) =>
            handleFormChangeNew(
              event,
              event.target.value,
              'none',
              'TempContactPhone',
              setOffensesDVAdditionalFormState
            )
          }
          fullWidth
          multiline
        />
      </Grid>
      <Grid item xs={5} lg={5}>
        <TextField
          autoComplete="hidden"
          size="small"
          id="standard-age"
          label="Age"
          placeholder="Age"
          variant="outlined"
          name="Age"
          value={offensesDVAdditionalFormState.values?.Age}
          onChange={(event) =>
            handleFormChangeNew(
              event,
              event.target.value,
              'none',
              'Age',
              setOffensesDVAdditionalFormState
            )
          }
          fullWidth
          multiline
        />
      </Grid>
      <Grid item xs={5} lg={5}>
        <TextField
          autoComplete="hidden"
          size="small"
          id="standard-marital-status"
          label="Marital Status"
          placeholder="Marital Status"
          variant="outlined"
          name="MaritialStatus"
          value={offensesDVAdditionalFormState.values?.MaritialStatus}
          onChange={(event) =>
            handleFormChangeNew(
              event,
              event.target.value,
              'none',
              'MaritialStatus',
              setOffensesDVAdditionalFormState
            )
          }
          fullWidth
          multiline
        />
      </Grid>
      <Grid item xs={5} lg={5}>
        <TextField
          autoComplete="hidden"
          size="small"
          id="standard-race"
          label="Race"
          placeholder="Race"
          variant="outlined"
          name="Race"
          value={offensesDVAdditionalFormState.values?.Race}
          onChange={(event) =>
            handleFormChangeNew(
              event,
              event.target.value,
              'none',
              'Race',
              setOffensesDVAdditionalFormState
            )
          }
          fullWidth
          multiline
        />
      </Grid>
      <Grid item xs={5} lg={5}>
        <TextField
          autoComplete="hidden"
          size="small"
          id="standard-ethnicity"
          label="Ethnicity"
          placeholder="Ethnicity"
          variant="outlined"
          name="Ethnicity"
          value={offensesDVAdditionalFormState.values?.Ethnicity}
          onChange={(event) =>
            handleFormChangeNew(
              event,
              event.target.value,
              'none',
              'Ethnicity',
              setOffensesDVAdditionalFormState
            )
          }
          fullWidth
          multiline
        />
      </Grid>
    </Grid>
  );
};

const mapStateToProps = (state) => ({
  offensesDVAdditionalForm: state.incident.offensesDVAdditionalForm,
  selectedVictim: state.incident.selectedVictim,
  dvAdditionals: state.incident.dvAdditionals,
});

export default connect(mapStateToProps, {
  updateIncOffensesDVAdditionalForm,
})(AdditionalInfoForm);
