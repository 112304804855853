import React, { Fragment, useState } from 'react';

import MuiAlert from '@material-ui/lab/Alert';

export default function Danger(props) {
  const [message, setMessage] = useState(props.list || []);
  const [isArr, setIsArr] = useState(true);

  return (
    <Fragment>
      <MuiAlert className="mb-4" severity="error">
        <div className="d-flex align-items-center align-content-center">
          <span>{isArr === true && message.map((msg) => <li> {msg} </li>)}</span>
        </div>
      </MuiAlert>
    </Fragment>
  );
}
