import store from '../../config/configureStore';

const checkForAddress = async (addressForm, addressType) => {
  const client = store.store.getState().websocket.websocket;
  const modalState = store.store.getState().uiModal;
  const values = addressForm.values;

  if (Object.keys(values).length === 0) return false;

  if (!client) return;
  try {
    const service = client.service('rms-address-duplicates');
    service.timeout = 20000;
    let queryData = {
      AddressNumber: values.addressNumber || '',
      AddressNumberPrefix: values.addressNumberPrefix || '',
      AddressNumberSuffix: values.addressNumberSuffix || '',
      StreetName: values.streetName || '',
      StreetType: values.streetType || '',
      PostDirection: values.postDirection || '',
      PreDirection: values.preDirection || '',
      ptsCityID: values.cityId || '',
      State: values.state || '',
      PostalCode: values.postalCode || '',
      PostalCodeExtension: values.postalCodeExtension || '',
    };

    if (
      queryData.AddressNumber === '' &&
      queryData.AddressNumberPrefix === '' &&
      queryData.AddressNumberSuffix === '' &&
      queryData.StreetName === '' &&
      queryData.StreetType === '' &&
      queryData.PostDirection === '' &&
      queryData.PreDirection === '' &&
      queryData.ptsCityID === '' &&
      queryData.State === '' &&
      queryData.PostalCode === '' &&
      queryData.PostalCodeExtension === ''
    ) {
      return false;
    }

    let result = await service.find({
      query: queryData,
    });

    console.log('Address duplicates', result);

    let duplicateFound = false;
    if (result.length > 0) {
      if (addressForm.ptsAddressID || addressForm.ptsAddressId) {
        let addressId = addressForm.ptsAddressID || addressForm.ptsAddressId;
        result = result.filter((r) => r.ptsAddressID !== addressId);

        if (result.length > 0) {
          duplicateFound = true;
        } else {
          duplicateFound = false;
        }
      } else {
        duplicateFound = true;
      }
    }

    if (addressType === 'general') {
      modalState.duplicateListOfAddress = result;
    } else if (addressType === 'contact') {
      modalState.duplicateListOfAddressForContact = result;
    } else if (addressType === 'employment') {
      modalState.duplicateListOfAddressForEmployment = result;
    }

    return duplicateFound;
  } catch (e) {
    console.log(e);
  }
};

const checkForPerson = async (personForm) => {
  const client = store.store.getState().websocket.websocket;
  const modalState = store.store.getState().uiModal;
  let values = personForm.values;

  if (Object.keys(values).length === 0) return false;

  if (!client) return;
  try {
    const service = client.service('rms-person-duplicates');
    service.timeout = 20000;
    let queryData = {
      FirstName: values.firstName || '',
      LastName: values.lastName || '',
      MiddleName: values.middleName || '',
      NickName: values.nickName || '',
      Prefix: values.prefix || '',
      Suffix: values.suffix || '',
      Born: values.birthdate || '',
      Race: values.race || '',
      Sex: values.sex || '',
      OLNNumber: values.oln || '',
      SSNNumber: values.ssn || '',
    };

    let result = await service.find({
      query: queryData,
    });

    console.log('Person duplicates', result);

    let duplicateFound = false;
    if (result.length > 0) {
      if (personForm.ptsPersonID || personForm.ptsPersonId) {
        let personId = personForm.ptsPersonID || personForm.ptsPersonId;
        result = result.filter((r) => r.ptsPersonID !== personId);
        console.log(result);

        if (result.length > 0) {
          duplicateFound = true;
        } else {
          duplicateFound = false;
        }
      } else {
        duplicateFound = true;
      }
    }
    modalState.duplicateListOfPerson = result;

    return duplicateFound;
  } catch (e) {
    console.log(e);
  }
};

const checkForPlace = async (values) => {
  const client = store.store.getState().websocket.websocket;
  const modalState = store.store.getState().uiModal;

  if (Object.keys(values).length === 0) return false;

  if (!client) return;
  try {
    const service = client.service('rms-place-duplicates');
    service.timeout = 20000;
    let queryData = {
      PlaceName: values.PlaceName || '',
    };

    let result = await service.find({
      query: queryData,
    });

    console.log('Place duplicates', result);

    let duplicateFound = false;
    if (result.length > 0) {
      if (values.ptsPlaceID || values.ptsPlaceId) {
        let placeId = values.ptsPlaceID || values.ptsPlaceId;
        result = result.filter((r) => r.ptsPlaceID !== placeId);
        console.log(result);

        if (result.length > 0) {
          duplicateFound = true;
        } else {
          duplicateFound = false;
        }
      } else {
        duplicateFound = true;
      }
    }
    modalState.duplicateListOfPlace = result;

    return duplicateFound;
  } catch (e) {
    console.log(e);
  }
};

const checkForVehicle = async (vehicleForm) => {
  const client = store.store.getState().websocket.websocket;
  const modalState = store.store.getState().uiModal;
  let values = vehicleForm.values;

  if (!client) return;
  try {
    const service = client.service('rms-vehicle-duplicates');
    service.timeout = 20000;
    let queryData = {
      PlateState: values.plateState || '',
      PlateNumber: values.plateNumber || '',
      VIN: values.vin || '',
    };

    let result = await service.find({
      query: queryData,
    });

    let duplicateFound = false;
    if (result.length > 0) {
      if (vehicleForm.ptsVehicleID || vehicleForm.ptsVehicleId) {
        let vehicleId = vehicleForm.ptsVehicleID || vehicleForm.ptsVehicleId;
        result = result.filter((r) => r.ptsVehicleID !== vehicleId);
        console.log(result);

        if (result.length > 0) {
          duplicateFound = true;
        } else {
          duplicateFound = false;
        }
      } else {
        duplicateFound = true;
      }
    }
    console.log('Vehicle duplicates', result);
    modalState.duplicateListOfVehicle = result;

    return duplicateFound;
  } catch (e) {
    console.log(e);
  }
};

export const duplicateCheck = async (form, entity, addressType = 'general') => {
  const modalState = store.store.getState().uiModal;
  let duplicateFound = false;

  if (entity === 'person') {
    duplicateFound = await checkForPerson(form);
    if (duplicateFound) {
      modalState.showDuplicateModal = duplicateFound;
      modalState.duplicateModalMessage = 'Duplicate List';
    }
  } else if (entity === 'address') {
    duplicateFound = await checkForAddress(form, addressType);

    if (duplicateFound) {
      modalState.showDuplicateModal = duplicateFound;
      modalState.duplicateModalMessage = 'Duplicate List';
    }
  } else if (entity === 'vehicle') {
    duplicateFound = await checkForVehicle(form);
    if (duplicateFound) {
      modalState.showDuplicateModal = duplicateFound;
      modalState.duplicateModalMessage = 'Duplicate List';
    }
  } else if (entity === 'place') {
    duplicateFound = await checkForPlace(form);
    if (duplicateFound) {
      modalState.showDuplicateModal = duplicateFound;
      modalState.duplicateModalMessage = 'Duplicate List';
    }
  }

  return duplicateFound;
};
