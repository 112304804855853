import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import 'date-fns';
import PrintIcon from '@material-ui/icons/Print';
import Button from '@material-ui/core/Button';
import CloseIcon from '@material-ui/icons/Close';
import DeleteIcon from '@material-ui/icons/Delete';
import { formatDate } from './functions';
import EditIcon from '@material-ui/icons/Edit';
import Dialog from './CustomDialog';
import logo from './argonne-logo.png';
import IconButton from '@material-ui/core/IconButton';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';

const useStyles = makeStyles((theme) => ({
  page: {
    '& h5': {
      marginTop: 30,
      marginBottom: 15,
    },
    '& li': {
      lineHeight: 1.75,
    },
  },
  buttons: {
    '& svg': {
      marginRight: theme.spacing(1),
    },
  },
  list: {
    width: '100%',
    height: 'calc(100vh - 120px)',
  },
  actions: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
  },
  iconButtons: {
    '& button': {
      margin: '0 8px',
    },
    '& svg': {
      marginRight: '0 !important',
    },
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    '& img': {
      width: 180,
      height: 'auto',
    },
    '& td': {
      padding: 4,
    },
    '& th': {
      padding: 4,
    },
  },
  header2: {
    display: 'flex',
    justifyContent: 'space-between',
    '& table': {
      width: '100%',
    },
    '& img': {
      width: 180,
      height: 'auto',
    },
    '& td': {
      padding: 4,
    },
    '& th': {
      padding: 4,
    },
  },
  hostReq: {
    // marginTop: 30,
  },
  answer: {
    display: 'inline-block',
    padding: '0 8px',
    borderRadius: 3,
    border: '1px solid rgba(0, 0, 0, 0.3)',
    marginLeft: 5,
    minWidth: 150,
  },
  observations: {
    '& table': {
      marginTop: 20,
    },
  },
  longText: {
    borderRadius: 3,
    padding: 10,
    border: '1px solid rgba(0, 0, 0, 0.3)',
    minHeight: 120,
  },
  footer: {
    marginTop: 20,
    '& table': {
      // width: '100%',
    },
    '& img': {
      width: 180,
      height: 'auto',
    },
    '& td': {
      padding: 4,
    },
    '& th': {
      padding: 4,
    },
  },
}));

const check = (data) => (data ? <CheckBoxIcon /> : <CheckBoxOutlineBlankIcon />);

function ViewData(props) {
  const classes = useStyles();
  const { data, close } = props;

  // const renderData = <code>
  //   <pre style={{fontFamily: '"Courier New", "Lucida Console", Monaco', fontSize: 16,}}>{JSON.stringify(data, null, 2)}</pre>
  // </code>

  const renderHeader = () => {
    const { date } = data;
    return (
      <div className={classes.header}>
        <div>
          <h4>HOSTING VALIDATION</h4>
          <table>
            <tbody>
              <tr>
                <th>REPORT TYPE:</th>
                <td>HOST VALIDATION-TEST DOC</td>
              </tr>
              <tr>
                <th>DATE:</th>
                <td>{formatDate(date)}</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div>
          <img src={logo} alt="logo" />
        </div>
      </div>
    );
  };

  const renderHeader2 = () => {
    const { host, foreignNational, citizenship, favor, location } = data;
    const contractorEmployee = check(data.contractorEmployee);
    const usCitizen = check(data.usCitizen);
    const scd = check(data.scd);
    const unannouncedVisit = check(data.unannouncedVisit);
    return (
      <div className={classes.header2}>
        <table>
          <tbody>
            <tr>
              <th>Host:</th>
              <td>{host}</td>
              <th>DOE / Contractor Employee:</th>
              <td>{contractorEmployee}</td>
              <th>U.S. Citizen:</th>
              <td>{usCitizen}</td>
              <th>Current w/ SCD 100:</th>
              <td>{scd}</td>
            </tr>
            <tr>
              <th>Foreign National:</th>
              <td>{foreignNational}</td>
              <th>Citizenship:</th>
              <td>{citizenship}</td>
              <td colSpan={2}>&nbsp;</td>
              <th>Unannounced Visit:</th>
              <td>{unannouncedVisit}</td>
            </tr>
            <tr>
              <th>FAVOR #:</th>
              <td colSpan={7}>{favor}</td>
            </tr>
            <tr>
              <th>Location:</th>
              <td colSpan={7}>{location}</td>
            </tr>
          </tbody>
        </table>
      </div>
    );
  };

  const renderHostReqAct = () => {
    const { hostReqActivities } = data;
    return (
      <div className={classes.hostReq}>
        <h5>HOST REQUIRED ACTIVITIES:</h5>
        <ol>
          <li>
            Host Identified to the Visitor:{' '}
            <span className={classes.answer}>{hostReqActivities[0]}</span>
          </li>
          <li>
            Knowledge to report suspicious activities / any suspicious activities observed:
            <span className={classes.answer}>{hostReqActivities[1]}</span>
          </li>
          <li>
            Provided the terms and conditions of access approval, including restrictions and
            requirements to notify the host of changes in name, immigrant/nonimmigrant status, and
            other information as required:
            <span className={classes.answer}>{hostReqActivities[2]}</span>
          </li>
          <li>
            Provided the requirement to notify the host of any civil or criminal problems that could
            affect their status and association with DOE:{' '}
            <span className={classes.answer}>{hostReqActivities[3]}</span>
          </li>
          <li>
            Provided that the failure to provide appropriate documentation when required or
            providing fradulent documentation will result in suspension of access approval, removal
            from the site, and possible cancellation of future access.
          </li>
        </ol>
      </div>
    );
  };

  const renderObservations = () => {
    const { observations, observationsCheck } = data;
    return (
      <div className={classes.observations}>
        <h5>OBSERVATIONS:</h5>
        <div className={classes.longText}>{observations}</div>
        <table>
          <tbody>
            <tr>
              <td>Host Knowledgeable of Foreign National's whereabouts:</td>
              <td>{check(observationsCheck[0])}</td>
            </tr>
            <tr>
              <td>Foreign National on-site / presence observed:</td>
              <td>{check(observationsCheck[1])}</td>
            </tr>
            <tr>
              <td>Host Knowledgeable of Security Plan / Security Plan adhered:</td>
              <td>{check(observationsCheck[2])}</td>
            </tr>
            <tr>
              <td>Host Conducted walk-down of Foreign National's work area:</td>
              <td>{check(observationsCheck[3])}</td>
            </tr>
          </tbody>
        </table>
      </div>
    );
  };

  const foreignNatInterview = () => {
    const { foreignNatInterview, foreignNatInterviewCheck } = data;
    return (
      <div className={classes.observations}>
        <h5>FOREIGN NATIONAL INTERVIEW:</h5>
        <p>
          (Foreign National knowledgable about requirements, host communicated the required
          information, adherence to the Security Plan, etc.)
        </p>
        <div className={classes.longText}>{foreignNatInterview}</div>
        <table>
          <tbody>
            <tr>
              <td>Remedial Host Training Required:</td>
              <td>{check(foreignNatInterviewCheck[0])}</td>
            </tr>
            <tr>
              <td>Division Follow-up Required:</td>
              <td>{check(foreignNatInterviewCheck[1])}</td>
            </tr>
            <tr>
              <td>Host Compliant with DOE O 142.3A:</td>
              <td>{check(foreignNatInterviewCheck[2])}</td>
            </tr>
          </tbody>
        </table>
      </div>
    );
  };

  const renderComments = () => {
    const { comments } = data;
    return (
      <div className={classes.observations}>
        <h5>COMMENTS:</h5>
        <div className={classes.longText}>{comments}</div>
      </div>
    );
  };

  const renderFooter = () => {
    const { evaluator, approval, approvalDate } = data;

    return (
      <div className={classes.footer}>
        <table>
          <tbody>
            <tr>
              <th>Evaluator: </th>
              <td>{evaluator}</td>
              <td colSpan={2}></td>
            </tr>
            <tr>
              <th>Approval: </th>
              <td>{approval}</td>
              <th>ApprovalDate: </th>
              <td>{formatDate(approvalDate)}</td>
            </tr>
          </tbody>
        </table>
      </div>
    );
  };
  const renderActions = () => {
    return (
      <div className={classes.actions}>
        <div className={classes.iconButtons}>
          <IconButton color="primary" onClick={() => console.log('print')} size="small">
            <PrintIcon />
          </IconButton>
          <IconButton color="primary" onClick={() => console.log('save')} size="small">
            <EditIcon />
          </IconButton>
          <IconButton color="primary" onClick={() => console.log('delete')} size="small">
            <DeleteIcon />
          </IconButton>
        </div>
        <div>
          <Button color="primary" onClick={close}>
            <CloseIcon /> Close
          </Button>
        </div>
      </div>
    );
  };

  return (
    <Dialog
      onClose={close}
      title={'Veiw Data'}
      actions={renderActions()}
      className={classes.dialog}>
      <div className={classes.page}>
        {renderHeader()}
        <hr />
        {renderHeader2()}
        {renderHostReqAct()}
        {renderObservations()}
        {foreignNatInterview()}
        {renderComments()}
        {renderFooter()}
      </div>
      {/* {renderData} */}
    </Dialog>
  );
}

export default ViewData;
