import store from '../../config/configureStore';

export const getService = (serviceName = 'rms-host-validation-demo') => {
  let service;
  try {
    const state = store.store.getState();
    const client = state.websocket.websocket;
    service = client.service(serviceName);
    service.timeout = 2000;
  } catch (err) {
    console.log(err);
    throw new Error('Error, problem with connection.');
  }
  return service;
};

export const getCountryCodes = () => {
  const service = getService('codelanguages');
  return service.find({
    query: {
      $select: ['Code', 'Description'],
      $sort: {
        Description: 1,
      },
      IsDeleted: false,
    },
  });
};

export const addHVRecord = (data) => {
  const service = getService();
  return service.create({ type: 'host-validation', data });
};

export const getHVRecords = () => {
  const service = getService();
  return service.find('');
};
