import React from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router';
import { useParams } from 'react-router-dom';

import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { XGrid } from '@material-ui/x-grid';
import InfoIcon from '@material-ui/icons/Info';
import { Fab } from '@material-ui/core';

import CheckCircleSharpIcon from '@material-ui/icons/CheckCircleSharp';
import CancelSharpIcon from '@material-ui/icons/CancelSharp';
import RMSAddButton from 'components/RMSButtons/RMSAddButton';
import RMSDeleteButton from '../../components/RMSButtons/RMSDeleteButton';
import RMSEditButton from '../../components/RMSButtons/RMSEditButton';

import RMSClearButton from '../../components/RMSButtons/RMSClearButton';
import { notify } from '../../reducers/NotifierReducer';
import { showSpinner, hideSpinner } from '../../reducers/ui/UiDrawerReducer';
import {
  setFormData,
  setSelectedRecordType,
  resetRecordAdvanceSearchKeyword,
} from '../../reducers/RecordsReducer';
import {
  resetSubForms,
  updateIncPropertyItemForm,
  updateIncPartiesPersonForm,
  updateIncPropertyFirearmForm,
  updateIncPropertyJewelryForm,
  updateIncPropertySubstanceForm,
  updateIncPropertyStructureForm,
  updateIncAddressForm,
  updateIncAddressIdentifiersForm,
  updateIncCoordinatesForm,
  updateIncPartiesContactForm,
  updateIncPartiesEmploymentForm,
  updateIncPartiesOthersForm,
  updateIncPropertyCredentialsAnalysisForm,
  updateIncPropertyVehicleDetailsForm,
} from '../../reducers/IncReducer';
import RecordAdvanceSearch from './RecordAdvanceSearch';
import { setRecordPersonDetailsStep } from 'reducers/ui/UiFormReducer';

const useStyles = makeStyles((theme) => ({
  root: {
    height: window.innerHeight - 90 + 'px',
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
    height: window.innerHeight - 253 + 'px',
  },
  generalForm: {
    marginBottom: theme.spacing(3),
    padding: theme.spacing(3),
  },
  lists: {
    padding: theme.spacing(1),
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 200,
  },
  searchIcon: {
    textAlign: 'right',
    paddingRight: '15px',
    paddingTop: '15px',
  },
  gridBg: {
    width: '100%',
    margin: '0px 0px 16px 0px',
    display: 'block',
  },
}));

function PageRMSRecords(props) {
  const {
    wsClient,
    records,
    setFormData,
    updateIncPropertyItemForm,
    updateIncPropertyFirearmForm,
    updateIncPropertyJewelryForm,
    updateIncPartiesPersonForm,
    updateIncPropertySubstanceForm,
    updateIncPropertyStructureForm,
    updateIncAddressForm,
    updateIncCoordinatesForm,
    updateIncPartiesOthersForm,
    updateIncAddressIdentifiersForm,
    updateIncPropertyCredentialsAnalysisForm,
    updateIncPropertyVehicleDetailsForm,
    setSelectedRecordType,
    resetRecordAdvanceSearchKeyword,
    setRecordPersonDetailsStep,
  } = props;
  const { formData, recordAdvanceSearchKeyword } = records;
  const classes = useStyles();
  const { type } = useParams();
  const history = useHistory();

  const [loading, setLoading] = React.useState(false);
  const [columns, setColumns] = React.useState([]);
  const [rows, setRows] = React.useState([]);
  const [rowCount, setRowCount] = React.useState(0);

  const primaryKeys = {
    person: 'ptsPersonID',
    address: 'ptsAddressID',
    item: 'ptsItemID',
    firearm: 'ptsFirearmID',
    substance: 'ptsSubstanceID',
    structure: 'ptsStructureID',
    vehicle: 'ptsVehicleID',
    place: 'ptsPlaceID',
  };

  const presets = {
    person: {
      columns: [
        'PersonID',
        'LastName',
        'FirstName',
        'MiddleName',
        'Suffix',
        'SSN',
        'OLN',
        'Ethnicity',
        'Age',
        'EyeColor',
        'HairColor',
        'Sex',
        'Race',
        'Born',
      ],
      service: 'persons',
    },
    address: {
      columns: [
        'ptsAddressID',
        'FullAddressText',
        'Country',
        'State',
        'StreetName',
        'BldgType',
        'BldgIdentifier',
        'AddressNumberPrefix',
        'AddressNumber',
        'AddressNumberSuffix',
        'PreDirection',
        'PostalCode',
        'PostalCodeExtension',
        'FloorType',
        'FloorIdentifier',
        'UnitType',
        'UnitIdentifier',
      ],
      service: 'rms-address',
    },
    item: {
      columns: ['ptsItemID', 'Name', 'Description', 'Make', 'Model', 'SerialNumber', 'Condition'],
      service: 'ptsitem',
    },
    jewelry: {
      columns: [
        'ptsJewelryID',
        'Description',
        'Sex',
        'Make',
        'Model',
        'SerialNumber',
        'Condition',
        'Shape',
        'JewelryCategory',
        'Material',
        'Carat',
      ],
      service: 'ptsjewelry',
    },
    firearm: {
      columns: [
        'ptsFirearmID',
        'Description',
        'Condition',
        'Caliber',
        'Make',
        'Model',
        'SerialNumber',
      ],
      service: 'ptsfirearms',
    },
    substance: {
      columns: [
        'ptsSubstanceID',
        'SubstanceType',
        'Designator',
        'Container',
        'Description',
        'Quantity',
        'QuantityMeasure',
      ],
      service: 'ptssubstance',
    },
    structure: {
      columns: [
        'ptsStructureID',
        'Description',
        'BedroomQty',
        'BathroomQty',
        'IsResidence',
        'GarageType',
        'PrimaryColor',
        'SecondaryColor',
      ],
      service: 'ptsstructure',
    },
    vehicle: {
      columns: [
        'ptsVehicleID',
        'VehicleID',
        'VIN',
        'PlateNumber',
        'PlateState',
        'PlateType',
        'PlateColor',
        'PlateStatus',
        'PlateExpiration',
        'Brand',
        'Brander',
        'Make',
        'Model',
      ],
      service: 'rms-property-vehicle',
    },
    place: {
      columns: [
        'ptsPlaceID',
        'PlaceID',
        'PlaceName',
        'PlaceType',
        'FullAddressText',
        'Country',
        'City',
        'State',
        'StreetName',
        'BldgType',
        'BldgIdentifier',
        'AddressNumberPrefix',
        'AddressNumber',
        'AddressNumberSuffix',
        'PreDirection',
        'PostalCode',
        'PostalCodeExtension',
        'FloorType',
        'FloorIdentifier',
        'UnitType',
        'UnitIdentifier',
      ],
      service: 'places',
    },
  };

  const lowercaseObjectKeys = (obj) => {
    var key,
      keys = Object.keys(obj);
    var n = keys.length;
    var newobj = {};
    while (n--) {
      key = keys[n];
      const key1 = key.charAt(0).toLowerCase() + key.slice(1);
      newobj[key1] = obj[key];
    }
    return newobj;
  };

  const capitalize = (text) => text.charAt(0).toUpperCase() + text.slice(1).toLowerCase();

  const setXGridData = (result) => {
    if (result.data.length > 0) {
      const data = result.data.map((item, i) => {
        if (item.PersonCredentialOLN && item.PersonCredentialOLN.length > 0) {
          item.OLN = item.PersonCredentialOLN[0].Number;
        }

        if (item.PersonCredentialSSN && item.PersonCredentialSSN.length > 0) {
          item.SSN = item.PersonCredentialSSN[0].Number;
        }

        if (item.PlaceAddress) {
          item = {
            ...item,
            ...item.PlaceAddress.Address,
            City: item.PlaceAddress.Address?.City?.CityDescription,
          };
        }

        if (item.VehicleReg) {
          item = {
            ...item,
            ...item.VehicleReg,
          };
        }

        const obj = {
          ...item,
          id: i,
        };
        return obj;
      });

      // Set table Column
      const preparedColumn = [];
      const keys = Object.keys(data[0]);
      keys.forEach((e) => {
        if (e !== 'id') {
          preparedColumn.push({
            field: e,
            headerName: e,
            width: 200,
          });
        }
      });

      setRows(data);
    } else {
      setRows([]);
    }
  };

  const pullDataByAdvanceSearch = async (skip = 0) => {
    if (!wsClient?.websocket) {
      return;
    }
    setLoading(true);

    const service = wsClient?.websocket.service(presets[type].service);
    service.timeout = 200000;

    let q;
    let filters = [];
    if (type === 'vehicle') {
      let filterNames = [
        'brand',
        'brander',
        'commercial',
        'doorQuantity',
        'make',
        'model',
        'vin',
        'plateNumber',
        'plateState',
      ];
      [0, 1, 2, 3, 4, 5, 6, 7, 8].map((n) => {
        if (
          recordAdvanceSearchKeyword[type][n].value !== '' &&
          recordAdvanceSearchKeyword[type][n].value !== null
        ) {
          filters.push({
            value: recordAdvanceSearchKeyword[type][n].value,
            name: filterNames[n],
          });
        }
      });

      q = {
        filters: filters,
      };
    } else {
      q = {
        $skip: skip,
        $limit: 50,
        IsDeleted: false,
        $sort: {
          Created: -1,
        },
      };

      if (type === 'person') {
        q.detail = true;
      }

      let newKeyAvailableForSearch = false;
      // eslint-disable-next-line array-callback-return
      recordAdvanceSearchKeyword[type].map((k) => {
        if (k.type === 'Checkbox') {
          q[k.dbField] = k.value;
          newKeyAvailableForSearch = true;
        } else if (
          (k.keyword === 'sex' || k.keyword === 'ethnicity' || k.keyword === 'race') &&
          k.value &&
          k.value.length > 0
        ) {
          newKeyAvailableForSearch = true;
          q[k.dbField] = k.value;
        } else if (k.keyword === 'ssn' || k.keyword === 'oln') {
          if (k.value.length > 0) {
            newKeyAvailableForSearch = true;
            q[k.dbField] = k.value;
          }
        } else if (k.type === 'Integer' && k.value && k.value.length > 0) {
          q[k.dbField] = k.value;
          newKeyAvailableForSearch = true;
        } else if (k.value && k.value.length > 0) {
          newKeyAvailableForSearch = true;
          q[k.dbField] = {
            $like: `%${k.value}%`,
          };
        }
      });
      if (!newKeyAvailableForSearch) {
        setRowCount(0);
        setRows([]);
        setLoading(false);
        return;
      }
    }

    console.log(q);

    setRows([]);

    const result = await service.find({
      query: q,
    });

    console.log(result);
    setRowCount(result.total);
    setXGridData(result);
    setLoading(false);
  };

  const handleDelete = async () => {
    setLoading(true);
    const service = wsClient?.websocket.service(presets[type].service);
    service.timeout = 20000;

    await service.remove(formData[primaryKeys[type]]);
    await pullDataByAdvanceSearch();

    setLoading(false);
  };

  const handleAddNew = () => {
    resetSubForms('add');

    if (type === 'address') history.push(`/records/add/address`);
    else if (type === 'person') history.push(`/records/add/persons`);
    else if (type === 'item') history.push(`/records/add/item`);
    else if (type === 'jewelry') history.push(`/records/add/jewelry`);
    else if (type === 'firearm') history.push(`/records/add/firearm`);
    else if (type === 'structure') history.push(`/records/add/structure`);
    else if (type === 'substance') history.push(`/records/add/substance`);
    else if (type === 'vehicle') history.push(`/records/add/vehicle`);
    else if (type === 'place') history.push(`/records/add/place`);
  };

  const handleEdit = () => {
    (async () => {
      if (
        type === 'item' ||
        type === 'jewelry' ||
        type === 'firearm' ||
        type === 'firearm' ||
        type === 'structure' ||
        type === 'substance' ||
        type === 'vehicle' ||
        type === 'place'
      ) {
        let newObj = lowercaseObjectKeys(formData);
        const capParentType = capitalize(type);
        let propertyDetails;
        let vehicleDetails;

        if (newObj.isResidence) {
          newObj = { ...newObj, residence: newObj.isResidence };
        }
        if (newObj.bathroomQty) {
          newObj = { ...newObj, bathroomQuantity: newObj.bathroomQty };
        }
        if (newObj.quantityMeasure) {
          newObj = { ...newObj, substanceQuantityMeasurement: newObj.quantityMeasure };
        }
        if (newObj.drugDaysSupply) {
          newObj = { ...newObj, substanceDaysSupply: newObj.drugDaysSupply };
        }
        if (newObj.drugStrength) {
          newObj = { ...newObj, substanceStrength: newObj.drugStrength };
        }
        if (newObj.dEAClass) {
          newObj = { ...newObj, deaClass: newObj.dEAClass };
        }
        if (newObj.isRented) {
          newObj = { ...newObj, rented: newObj.isRented };
        }
        if (newObj.isWanted) {
          newObj = { ...newObj, wanted: newObj.isRented };
        }
        if (newObj.isAutomatic) {
          newObj = { ...newObj, automatic: newObj.isAutomatic };
        }
        if (newObj.bedroomQty) {
          newObj = { ...newObj, bedroomQuantity: newObj.bedroomQty };
        }
        if (newObj.bathroomQty) {
          newObj = { ...newObj, bathroomQuantity: newObj.bathroomQty };
        }
        if (newObj.commercialID) {
          newObj = { ...newObj, commercialCode: newObj.commercialID };
        }
        if (newObj.propertyID) {
          newObj = { ...newObj, propertyCode: newObj.propertyID };
        }

        if (type === 'vehicle') {
          vehicleDetails = {
            ptsVehicleId: formData.ptsVehicleID,
            ptsRegistrationId: formData.ptsRegistrationID,
            values: {
              description: formData.Description,
              propertyLossType: '', // Where to get it?
              make: formData.Make,
              model: formData.Model,
              parentType: formData.ParentType,
              updated: formData.Updated,
              updatedBy: formData.UpdatedBy,
              name: formData.Name,
              ...newObj,
              passengerQuantity: formData.PassengerSafeQty,
              seatQuantity: formData.SeatQty,
              vin: formData.VIN,
              vinAnalysisTest: formData.VINAnalysisText,
              year: formData.Year,
              band: formData.Band,
              brander: formData.Brander,
              rented: formData.IsRented,
              wanted: formData.IsWanted,
              transmissionCode: formData.Transmission,
              emissionInspection: formData.EmissionInspectionQty,
              commercialId: formData.CommercialID,
              commercialCode: formData.Commercial,
              cmv: formData.IsCMVIndicator,
              garageFacility: formData.GarageFacility,
              garageIndicator: formData.GarageIndicator,
              classification: formData.Classification,
              primaryColor: formData.PrimaryColor,
              secondaryColor: formData.SecondaryColor,
              interior: formData.InteriorColor,
              style: formData.Style,
              plateType: formData.PlateType,
              plateColor: formData.PlateColor,
              plateNumber: formData.PlateNumber,
              plateState: formData.PlateState,
              plateStatus: formData.PlateStatus,
              plateDetail: formData.PlateDetail,
              plateExpiration: formData.PlateExpiration,
              plateBackgroundText: formData.PlateBackgroundText,
            },
          };
        } else {
          propertyDetails = {
            [`pts${capParentType}Id`]: formData[`pts${capParentType}ID`],
            values: {
              description: formData.Description,
              make: formData.Make,
              model: formData.Model,
              parentType: formData.ParentType,
              updated: formData.Updated,
              updatedBy: formData.UpdatedBy,
              name: formData.Name,
              ...newObj,
              passengerQuantity: formData.PassengerSafeQty,
              seatQuantity: formData.SeatQty,
            },
          };
        }

        if (type === 'item') {
          updateIncPropertyItemForm(propertyDetails);
          history.push(`/records/edit/item`);
        } else if (type === 'jewelry') {
          updateIncPropertyJewelryForm(propertyDetails);
          history.push(`/records/edit/jewelry`);
        } else if (type === 'firearm') {
          updateIncPropertyFirearmForm(propertyDetails);
          history.push(`/records/edit/firearm`);
        } else if (type === 'structure') {
          updateIncPropertyStructureForm(propertyDetails);
          history.push(`/records/edit/structure`);
        } else if (type === 'substance') {
          updateIncPropertySubstanceForm(propertyDetails);
          history.push(`/records/edit/substance`);
        } else if (type === 'vehicle') {
          updateIncPropertyVehicleDetailsForm(vehicleDetails);
          updateIncPropertyCredentialsAnalysisForm(vehicleDetails);
          history.push(`/records/edit/vehicle/${vehicleDetails.ptsVehicleId}`);
        } else if (type === 'place') {
          history.push(`/records/edit/place/${propertyDetails.ptsPlaceId}`);
        }
      } else if (type === 'person') {
        let ssnNumber = 0;
        let olnNumber = 0;
        let olnState = null;

        const Cred = formData?.PersonCredential;

        Cred.forEach((c) => {
          if (c.Type === 'SSN') ssnNumber = c.Number;
          if (c.Type === 'OLN') olnNumber = c.Number;
          olnState = c.State;
        });

        let personDetail = {
          ptsPersonId: formData.ptsPersonID,
          values: {
            firstName: formData.FirstName,
            lastName: formData.LastName,
            middleName: formData.MiddleName,
            ptsPersonId: formData.ptsPersonID,
            age: formData.Age,
            ageRangeIncrement: formData.AgeRangeIncrement,
            birthdate: formData.Born,
            bloodType: formData.BloodType,
            ethnicity: formData.Ethnicity,
            eyeColor: formData.EyeColor,
            hairColor: formData.HairColor,
            hairLength: formData.HairLength,
            hairStyle: formData.HairStyle,
            handed: formData.Handed,
            height: formData.Height,
            incidentId: formData.ptsIncidentID,
            partyType: formData.Relationship,
            personId: formData.PersonID,
            race: formData.Race,
            sex: formData.Sex,
            weight: formData.Weight,
            ssn: ssnNumber,
            oln: olnNumber,
            state: olnState,
            suffix: formData.Suffix,
          },
        };

        let otherDetail = {
          values: {
            personId: formData?.ptsPersonID,
            generalAppearance: formData?.GeneralAppearance,
            personNotes: formData?.Notes,
          },
        };

        updateIncPartiesPersonForm(personDetail);
        updateIncPartiesOthersForm(otherDetail);
        history.push(`/records/edit/persons/${formData?.ptsPersonID}`);
      } else if (type === 'address') {
        let addressDetail = {
          ptsAddressId: formData.ptsAddressID,
          values: {
            ptsLocationId: formData.ptsLocationID,
            fullAddressText: formData.FullAddressText,
            created: formData.Created,
            createdBy: formData.CreatedBy,
            updated: formData.Updated,
            updatedBy: formData.UpdatedBy,
            updatedIp: formData.UpdatedIP,
            addressNumberPrefix: formData.AddressNumberPrefix,
            addressNumberSuffix: formData.AddressNumberSuffix,
            addressNumber: formData.AddressNumber,
            streetName: formData.StreetName,
            preDirection: formData.PreDirection,
            postDirection: formData.PostDirection,
            city: formData.City?.CityDescription,
            state: formData.State,
            county: formData.County,
            country: formData.Country,
            postalCode: formData.PostalCode,
            postalCodeExtension: formData.PostalCodeExtension,
            streetType: formData.StreetType,
            notes: formData.Notes,
          },
        };

        let addressIdDetails = {
          ptsAddressId: formData.ptsAddressID,
          values: {
            isEventLookup: formData.ShowInEventLookup,
            isSexOffender: formData.SexOffender,
            isParolee: formData.Parolee,
            isProbation: formData.Probation,
            isNonValidated: formData.NonValidatedAddress,
            isCampusResidential: formData.IsCampusResidential,
            isCampusNonResidential: formData.IsCampusNonResidential,
            isNonCampus: formData.IsNonCampus,
            isPublic: formData.IsPublic,
            unitType: formData.UnitType,
            unitTypeIdentifier: formData.UnitIdentifier,
            floorType: formData.FloorType,
            floorIdentifier: formData.FloorIdentifier,
            buildingType: formData.BldgType,
            buildingIdentifier: formData.BldgIdentifier,
          },
        };

        let addressCoordinatesDetails = {
          ptsAddressId: formData.ptsAddressID,
          ptsCoordinateId: formData?.Coordinate?.ptsCoordinateID,
          values: {
            latitudeSign: formData?.Coordinate?.LatitudeSign,
            latitudeDegree: formData?.Coordinate?.LatitudeDegree,
            longitudeSign: formData?.Coordinate?.LongitudeSign,
            longitudeDegree: formData?.Coordinate?.LongitudeDegree,
            uncertaintyDistance: formData?.Coordinate?.UnCertaintyDistance,
            datum: formData?.Coordinate?.Datum,
            coordinateDatetime: formData?.Coordinate?.CoordinateDT,
            altitude: formData?.Coordinate?.Altitude,
            altitudePor: formData?.Coordinate?.AltitudeRefPoint,
            altitudeUom: formData?.Coordinate?.AltitudeUnitMeasure,
            cellTower: formData?.Coordinate?.CellIDText,
            sectorId: formData?.Coordinate?.CellSectorIDText,
            speed: formData?.Coordinate?.Speed,
            speedUom: formData?.Coordinate?.SpeedUnitMeasure,
          },
        };

        await Promise.all([
          updateIncAddressForm(addressDetail),
          updateIncAddressIdentifiersForm(addressIdDetails),
          updateIncCoordinatesForm(addressCoordinatesDetails),
        ]).then(() => {
          history.push(`/records/edit/address/${addressDetail.ptsAddressId}`);
        });
      }
    })();
  };

  const handleClearForm = () => {
    setRowCount(0);
    setXGridData([]);
    setLoading(false);
    resetRecordAdvanceSearchKeyword();
  };

  const handleOpenDetails = () => {
    setRecordPersonDetailsStep(0);
    history.push(`/records/person/${formData.ptsPersonID}/details`);
  };

  React.useEffect(() => {
    setRowCount(0);
    setRows([]);
    setSelectedRecordType(type);

    if (type) {
      const col = presets[type].columns.map((e) => {
        if (e === 'FullAddressText')
          return {
            field: e,
            headerName: e,
            width: 600,
          };
        else if (e === 'IsResidence')
          return {
            field: e,
            headerName: e,
            width: 150,
            renderCell: (params) =>
              // eslint-disable-next-line jsx-a11y/img-redundant-alt
              params.value ? (
                <CheckCircleSharpIcon fontSize="20px" color="primary" />
              ) : (
                <CancelSharpIcon fontSize="20px" color="action" />
              ),
          };

        return {
          field: e,
          headerName: e,
          width: 200,
        };
      });

      setColumns(col);
      setFormData(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [type, wsClient]);

  React.useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      pullDataByAdvanceSearch();
    }, 2000);

    return () => clearTimeout(delayDebounceFn);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [recordAdvanceSearchKeyword[type]]);

  return (
    <Grid container className={classes.root}>
      <Paper className={classes.gridBg} container spacing={1} wrap="nowrap">
        <Grid style={{ margin: '20px' }} spacing={1} item xs={12}>
          <RecordAdvanceSearch entityType={type} />
        </Grid>
        <Grid style={{ float: 'right', margin: '10px 20px' }} item xs={2}>
          <Grid container alignItems="center" justify="flex-end" wrap="nowrap">
            <Grid item>
              <RMSClearButton onClick={handleClearForm} text="Clear Form" />
            </Grid>

            <Grid item>
              {type === 'person' && (
                <RMSAddButton tooltipText="Add Person" onClick={handleAddNew} />
              )}
              {type === 'address' && (
                <RMSAddButton tooltipText="Add Address" onClick={handleAddNew} />
              )}
              {type !== 'person' && type !== 'address' && (
                <RMSAddButton
                  tooltipText={`Add ${type.charAt(0).toUpperCase() + type.slice(1)}`}
                  onClick={handleAddNew}
                />
              )}
            </Grid>

            {type !== 'person' && (
              <Grid item style={{ paddingLeft: '12px' }}>
                <RMSEditButton
                  disabled={!(formData && formData[primaryKeys[type]] !== null)}
                  onClick={handleEdit}
                />
              </Grid>
            )}

            <Grid item style={{ paddingLeft: '12px' }}>
              <RMSDeleteButton
                disabled={!(formData && formData[primaryKeys[type]] !== null)}
                onClick={handleDelete}
              />
            </Grid>

            {type === 'person' && (
              <Grid item style={{ paddingLeft: '12px' }}>
                <Fab
                  size="small"
                  disabled={!(formData && formData[primaryKeys[type]] !== null)}
                  onClick={handleOpenDetails}
                  color="secondary"
                  aria-label="edit">
                  <InfoIcon />
                </Fab>
              </Grid>
            )}
          </Grid>
        </Grid>
      </Paper>

      <Paper className={classes.paper}>
        <XGrid
          onPageChange={(params) => {
            pullDataByAdvanceSearch(params.page * 50);
          }}
          pageSize={50}
          rowCount={rowCount}
          pagination
          paginationMode="server"
          loading={loading}
          onRowClick={(row) => {
            if (row) {
              setFormData(row.row);
            } else {
              setFormData(null);
            }
          }}
          rows={rows}
          columns={columns}
        />
      </Paper>
    </Grid>
  );
}

const mapStateToProps = (state) => ({
  wsClient: state.websocket,
  records: state.records,
  codeCities: state.codesOthers.codeCities,
});

export default connect(mapStateToProps, {
  notify,
  showSpinner,
  hideSpinner,
  setFormData,
  updateIncPropertyItemForm,
  updateIncPropertyFirearmForm,
  updateIncPropertyJewelryForm,
  updateIncPartiesPersonForm,
  updateIncPropertySubstanceForm,
  updateIncPropertyStructureForm,
  updateIncCoordinatesForm,
  updateIncPartiesContactForm,
  updateIncPartiesEmploymentForm,
  updateIncPartiesOthersForm,
  updateIncAddressIdentifiersForm,
  updateIncPropertyCredentialsAnalysisForm,
  updateIncPropertyVehicleDetailsForm,
  updateIncAddressForm,
  setSelectedRecordType,
  resetRecordAdvanceSearchKeyword,
  setRecordPersonDetailsStep,
})(PageRMSRecords);
