import React from 'react';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import RMSIncidentForm from '../../components/RMSForms/RMSIncidentForm';
import { setSelectedIncident } from '../../reducers/IncReducer';

const useStyles = makeStyles(() => ({
  pageWrap: {
    '& h3': {
      marginTop: 20,
    },
  },
}));

const PageAddEditIncident = (props) => {
  const classes = useStyles();
  const { history, selectedIncident, setSelectedIncident } = props;

  const { incId } = useParams();
  if (incId && !selectedIncident) {
    setSelectedIncident(incId);
  }

  return (
    <div className={classes.pageWrap}>
      <div>
        <div>
          <RMSIncidentForm history={history} />
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  selectedIncident: state.incident.ptsIncidentId,
});

export default connect(mapStateToProps, {
  setSelectedIncident,
})(PageAddEditIncident);
