import store from '../../config/configureStore';

import { propertiesSchema } from 'simpl-schema-validation/schema';

/**
 *
 * @param {the form field to validate field
 * @param {the form} form
 * @returns
 */
export const validatePropertiesForm = (field, form) => {
  const currentState = store.store.getState().incident;
  const { incProperties, offenses } = currentState;
  let errors = [];

  if (Object.keys(form.values).length > 0) {
    let incProperty = incProperties.find(
      (incP) => incP.incPropertyDetails.incIncidentPropertyId === form.incIncidentPropertyId
    );

    let incPropertyOffense = offenses.find(
      (offense) =>
        offense.offenseDetails.ptsOffenseId ===
        incProperty?.incPropertyDetails.incIncidentPropertyId
    );

    let objToValidate = {
      incProperty: incProperty ? incProperty.incPropertyDetails : null,
      incPropertyOffense: incPropertyOffense ? incPropertyOffense.offenseDetails : null,
      property: form,
      [field]: field === 'substanceQuantity' ? parseInt(form.values[field]) : form.values[field],
    };

    let propertiesValidationContext = propertiesSchema.newContext();
    let result = propertiesValidationContext.validate(objToValidate, {
      keys: [field],
    });

    if (!result) {
      propertiesValidationContext.validationErrors().forEach((error) => {
        if (field === error.name) {
          error.message = propertiesValidationContext.keyErrorMessage(error.name);
          errors.push(error);
        }
      });
    }
  }

  return errors || [];
};
