import React from 'react';

import DateFnsUtils from '@date-io/date-fns';
import { connect } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';

// import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import { MuiPickersUtilsProvider, KeyboardDateTimePicker } from '@material-ui/pickers';
import { Button } from '@material-ui/core';
import {
  updateIncEvidenceStorageDetails,
  updateIncEvidenceStorageForm,
} from '../../../../reducers/IncReducer';
import { handleFormChangeNew } from '../../../../reducers/helpers/formHelpers';
import RMSAutoComplete from 'components/RMSAutoComplete/RMSAutoComplete';

const StorageForm = (props) => {
  const {
    selectedEvidenceStorage,
    incEvidenceStorage,
    updateIncEvidenceStorageForm,
    updateIncEvidenceStorageDetails,
    codeEvidenceStorageLocation,
    codeEvidenceStorageSubLocation,
    codeEvidenceStorageContainer,
    reloadItems,
    dialogToggle,
  } = props;

  const [evidenceStorageFormState, setEvidenceStorageFormState] = React.useState({
    tempEvidenceStorageId: uuidv4(),
    isValid: false,
    changes: false,
    values: {},
    touched: {},
    errors: {},
  });

  React.useEffect(() => {
    setEvidenceStorageFormState({
      tempEvidenceStorageId: uuidv4(),
      isValid: false,
      changes: false,
      values: {},
      touched: {},
      errors: {},
    });

    let details = {};
    let found = false;

    incEvidenceStorage.forEach((s) => {
      if (s.incEvidenceStorageDetails.tempEvidenceStorageId === selectedEvidenceStorage) {
        details = s.incEvidenceStorageDetails;
        found = true;
      }

      if (s.incEvidenceStorageDetails.ptsEvidenceStorageId === selectedEvidenceStorage) {
        details = s.incEvidenceStorageDetails;
        found = true;
      }
    });

    if (found) {
      updateIncEvidenceStorageForm(details);
      setEvidenceStorageFormState(details);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedEvidenceStorage]);

  const updateStorge = () => {
    updateIncEvidenceStorageDetails(evidenceStorageFormState);
    reloadItems();
    dialogToggle(false);
  };

  const handleClose = () => {
    dialogToggle(false);
  };

  return (
    <Grid container justify="center" className="mt-3">
      <Grid item xs={12} lg={12} className="mt-2">
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <KeyboardDateTimePicker
            size="small"
            inputVariant="outlined"
            fullWidth
            showTodayButton={true}
            variant="dialog"
            format="MM/dd/yyyy hh:mm a"
            id="date-picker-datein-from"
            label="Date In"
            value={evidenceStorageFormState.values.dateIn}
            onChange={(date) => {
              setEvidenceStorageFormState((state) => {
                return {
                  ...state,
                  values: {
                    ...state.values,
                    dateIn: date,
                  },
                };
              });
            }}
            KeyboardButtonProps={{
              'aria-label': 'change date',
            }}
          />
        </MuiPickersUtilsProvider>
      </Grid>
      <Grid item xs={12} lg={12} className="mt-3">
        <RMSAutoComplete
          size="small"
          autoComplete
          autoSelect
          autoHighlight
          id="combo-evidence-location"
          options={codeEvidenceStorageLocation}
          // new ones
          serviceName="codeevidencestoragelocation"
          label="Storage Location"
          variant="outlined"
          title="Code"
          stateHolder={evidenceStorageFormState}
          setStateHolder={setEvidenceStorageFormState}
          setField={handleFormChangeNew}
          stateHolderAttribute="storageLocation"
        />
      </Grid>
      <Grid item xs={12} lg={12} className="mt-3">
        <RMSAutoComplete
          size="small"
          autoComplete
          autoSelect
          autoHighlight
          id="combo-evidence-sub-location"
          options={codeEvidenceStorageSubLocation}
          // new ones
          serviceName="codeevidencestoragesublocation"
          label="Sub-Location"
          variant="outlined"
          title="Code"
          stateHolder={evidenceStorageFormState}
          setStateHolder={setEvidenceStorageFormState}
          setField={handleFormChangeNew}
          stateHolderAttribute="subLocation"
        />
      </Grid>
      <Grid item xs={12} lg={12} className="mt-4 mb-1">
        <RMSAutoComplete
          size="small"
          autoComplete
          autoSelect
          autoHighlight
          id="combo-evidence-container"
          options={codeEvidenceStorageContainer}
          serviceName="codeevidencestoragecontainer"
          label="Container"
          variant="outlined"
          title="Code"
          stateHolder={evidenceStorageFormState}
          setStateHolder={setEvidenceStorageFormState}
          setField={handleFormChangeNew}
          stateHolderAttribute="container"
        />
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Grid
            container
            direction="row"
            justify="flex-end"
            alignItems="center"
            style={{ marginTop: 30, marginBottom: 5 }}>
            <Button onClick={handleClose} style={{ marginRight: 5 }}>
              Close
            </Button>
            <Button
              onClick={updateStorge}
              style={{ background: '#1976d2', color: '#fff' }}
              variant="contained">
              Update
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

const mapStateToProps = (state) => ({
  evidenceStorageForm: state.incident.evidenceStorageForm,
  selectedEvidenceStorage: state.incident.selectedEvidenceStorage,
  incEvidenceStorage: state.incident.incEvidenceStorage,
  codeEvidenceStorageContainer: state.codesEvidence.codeEvidenceStorageContainer,
  codeEvidenceStorageSubLocation: state.codesEvidence.codeEvidenceStorageSubLocation,
  codeEvidenceStorageLocation: state.codesEvidence.codeEvidenceStorageLocation,
});

export default connect(mapStateToProps, {
  updateIncEvidenceStorageForm,
  updateIncEvidenceStorageDetails,
})(StorageForm);
