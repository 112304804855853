import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import TreeView from '@material-ui/lab/TreeView';
import makeStyles from '@material-ui/core/styles/makeStyles';
import React, { Fragment } from 'react';
import { OffenderAvatar } from '../../pages/PageRMSOffenses/TabOffenders';
import { VictimAvatar } from '../../pages/PageRMSOffenses/TabVictims';
import StyledTreeItem from './StyledTreeItem';

const useStyles = makeStyles({
  root: {
    // height: 264,
    flexGrow: 1,
    minWidth: '1000px',
  },
});

const RMSTreeView = (props) => {
  const classes = useStyles();
  const { parentItems, childItems, options } = props;

  return (
    <Fragment>
      {parentItems.map((parent) => (
        <TreeView
          className={classes.root}
          defaultExpanded={[parent.id]}
          defaultCollapseIcon={<ArrowDropDownIcon />}
          defaultExpandIcon={<ArrowRightIcon />}
          defaultEndIcon={<div style={{ width: 24 }} />}>
          <StyledTreeItem
            nodeId={parent.id}
            labelText={parent.label}
            labelIcon={OffenderAvatar}
            avatarLabel={parent.avatarLabel}
            subHeading1={parent.subHeading1}
            subHeading2={parent.subHeading2}
            options={options}
            flag={'parent'}>
            {childItems.map((child) => (
              <StyledTreeItem
                nodeId={child.id}
                labelText={child.label}
                labelIcon={VictimAvatar}
                avatarLabel={child.avatarLabel}
                subHeading1={child.subHeading1}
                subHeading2={child.subHeading2}
                options={options}
                flag="child"
                parent={parent}
                child={child}
              />
            ))}
          </StyledTreeItem>
        </TreeView>
      ))}
    </Fragment>
  );
};

export default RMSTreeView;
