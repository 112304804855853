import React, { Fragment } from 'react';
import TableCell from '@material-ui/core/TableCell';
import Avatar from '@material-ui/core/Avatar';
import Typography from '@material-ui/core/Typography';
import { colors } from '../../../../config/colorVariables';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { withStyles } from '@material-ui/core/styles';

import Badge from '@material-ui/core/Badge';
import { countOffenseErrors } from 'simpl-schema-validation/CountErrors';

const useStyles = makeStyles((theme) => ({
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: colors.cyan,
    marginRight: theme.spacing(2),
  },
  incidentLabel: {
    padding: '3px 0px',
  },
}));

const StyledBadge = withStyles((theme) => ({
  badge: {
    right: 15,
    top: 13,
    border: `2px solid ${theme.palette.background.paper}`,
    padding: '0 4px',
  },
}))(Badge);

const RMSOffenseCell = (props) => {
  const classes = useStyles();
  const { row } = props;

  function dragStart(event) {
    event.dataTransfer.setData('Text', event.target.id);
  }

  return (
    <Fragment>
      <TableCell
        style={{ cursor: 'grabbin' }}
        onDragStart={dragStart}
        draggable={props.dnd}
        id={`${row.statuteCode || ''} ${row.statute || ''} `}
        key={row.statute}>
        <span style={{ display: 'none' }}>{row.statute}</span>
        <td>
          <div className="d-flex align-items-center">
            <StyledBadge
              badgeContent={countOffenseErrors(row.ptsOffenseId) ? '!' : 0}
              color="error">
              <Avatar aria-label="avatar" className={classes.avatar}>
                Offense
              </Avatar>
            </StyledBadge>

            <Typography variant="h6" className="d-block">
              {row.statute} | {row.statuteCode}
            </Typography>
          </div>
        </td>
      </TableCell>
      <TableCell key={row.date}>{row.date}</TableCell>
      <TableCell key={row.updated}>{row.updated}</TableCell>
      <TableCell key={row.updatedBy}>{row.updatedBy}</TableCell>
    </Fragment>
  );
};

export default RMSOffenseCell;
