import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import IncEvidencesList from '../../RMSList/RMSIncidentList/IncEvidencesList';
import { connect } from 'react-redux';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  formFields: {
    width: '100%',
    height: '100%',
  },
  lists: {
    marginTop: '20px',
  },
}));

const IncEvidencesStep = () => {
  const classes = useStyles();

  return (
    <Grid container className={classes.root}>
      <Grid item xs={12} md={12} lg={12}>
        <IncEvidencesList />
      </Grid>
    </Grid>
  );
};

export default connect(null, {})(IncEvidencesStep);
