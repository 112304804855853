/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router';
import { useParams } from 'react-router';

import ImageUploader from 'react-images-upload';
import {
  Grid,
  TextField,
  makeStyles,
  List,
  ListItem,
  Card,
  Checkbox,
  FormControlLabel,
  Paper,
} from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';

import RMSFabButtons from 'components/RMSForms/RMSIncidentForm/RMSFabButtons';
import RMSAutoComplete from 'components/RMSAutoComplete/RMSAutoComplete';
import defaultImage from '../../../../assets/images/avatars/avatar-526x526.png';
import { setCircularLoading } from 'reducers/ui/UiMenuReducer';
import { getCodeMugshotTypes } from 'reducers/dictionaries/CodeMugshotTypesReducer';
import { handleFormChangeNew } from 'reducers/helpers/formHelpers';
import { upsertRecord } from 'reducers/RecordsReducer';

const useStyles = makeStyles(() => ({
  root: {
    height: window.innerHeight - 90 + 'px',
    borderRadius: '0.5rem',
    padding: '15px',
  },
  form: {
    width: '100%',
  },
  input: {
    width: '100%',
    padding: '10px',
  },
  btnStyle: {
    display: 'block',
    width: '150px',
    padding: '10px',
    marginTop: '15px',
    marginLeft: 'auto',
    position: 'relative',
    right: 10,
  },
  spinnerLoadingContainer: {
    position: 'fixed',
    top: '50%',
    left: '50%',
  },
  imgDiv: {
    width: '160px',
    height: '160px',
    background: '#ccc',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
}));
/** region static code */

const codeMarkLocation = [{ Code: 'ML1' }, { Code: 'ML2' }];
const codeMarks = [{ Code: 'MR1' }, { Code: 'MR2' }];
/** end region */

const PersonPhotoForm = (props) => {
  /** region props and variables */
  const classes = useStyles();
  let data = useSelector((state) => state.records.formData);
  const location = useLocation();
  const actionType = location.pathname.split('/')[5];
  const { wsClient, upsertRecord, codeMugshotTypes } = props;
  const { type, persondId, entityId } = useParams();
  const [mugshot, setMugshot] = useState([]);
  const [isPhotoUploaded, setIsPhotoUploaded] = useState(false);
  const [photo, setPhoto] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();
  if (actionType === 'add') {
    data = {};
  }
  const [formData, setFormData] = useState({
    values: {
      ptsImageID: data?.ptsImageID || undefined,
      ptsPersonID: persondId,
      Gang: data?.Gang || '',
      Description: data?.Description || '',
      ImageTypeCode: data?.ImageTypeCode || '',
      IsDefault: data?.IsDefault || '',
      IsMark: data?.IsMark || '',
      MarkType: data?.MarkType || '',
      Location: data?.Location || '',
    },
  });
  const [spinnerLoading, setSpinnerLoading] = React.useState(actionType === 'add' ? false : true);
  /** end region */
  /**region lifecycle hooks */
  React.useEffect(() => {
    async function createFile() {
      let response = await fetch(defaultImage);
      let data = await response.blob();
      let metadata = {
        type: 'image/jpeg',
      };
      let file = new File([data], 'test.jpg', metadata);
      onDrop([file]);
    }
    if (actionType === 'add') {
      createFile();
    }
  }, []);

  React.useEffect(() => {
    if (!wsClient?.websocket) {
      return;
    }
    getPersonPhotos();
  }, [wsClient]);

  React.useEffect(() => {
    if (!wsClient?.websocket) {
      return;
    }
    dispatch(getCodeMugshotTypes());
    getPersonPhotos();
  }, [wsClient]);
  /** end region */
  /** region helper functions*/
  const getPersonPhotos = async () => {
    const service = wsClient?.websocket.service('record-person-photo');
    service.timeout = 200000;

    const result = await service.get(persondId);
    let parsedLocalMugshots = result.Mugshots !== null ? JSON.parse(result.Mugshots) : null;

    if (parsedLocalMugshots) {
      let filtered = parsedLocalMugshots.filter((m) => m.ptsImageID === Number.parseInt(entityId));
      setMugshot(filtered[0]);
      setSpinnerLoading(false);
    }
  };

  function readFile(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();

      reader.onload = function (e) {
        let dataURL = e.target.result;
        dataURL = dataURL.replace(';base64', `;name=${file.name};base64`);
        resolve({ file, dataURL });
      };

      reader.readAsDataURL(file);
    });
  }

  const handleSave = () => {
    if (!formData.values.ptsImageID) {
      upsertRecord('photo', formData.values, 'add').finally(() => handleFinally());
    } else {
      upsertRecord('photo', formData.values, 'edit').finally(() => handleFinally());
    }
  };

  const handleFinally = () => {
    dispatch(setCircularLoading(false));
    history.goBack();
  };

  const onDrop = (picture) => {
    readFile(picture[0]).then((fileData) => {
      setPhoto(fileData.dataURL);
    });
    setIsPhotoUploaded(true);
    setFormData({
      values: {
        ...formData.values,
        picture: picture[0],
      },
    });
  };
  /** end region */
  return (
    <Paper className={classes.root}>
      <ImageUploader
        withIcon={true}
        onChange={onDrop}
        imgExtension={['.jpg', '.gif', '.png', '.gif', '.jpeg']}
        maxFileSize={5242880}
        singleImage={true}
        buttonText={actionType === 'edit' ? 'Change Photo' : 'Add Photo'}
      />

      <form className={classes.form}>
        <Grid container spacing={2}>
          <Grid item xs={4}>
            <RMSAutoComplete
              size="small"
              autoComplete
              autoSelect
              autoHighlight
              id="combo-search-suffix"
              options={codeMugshotTypes}
              label="Image Type Code"
              serviceName="codemugshottypes"
              variant="outlined"
              title={'Code'}
              stateHolder={formData}
              setStateHolder={setFormData}
              setField={handleFormChangeNew}
              stateHolderAttribute="ImageTypeCode"
            />
          </Grid>
          <Grid item xs={2}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={formData.values?.IsDefault}
                  name="IsDefault"
                  onChange={(event) =>
                    handleFormChangeNew(
                      event,
                      event.target.checked,
                      'none',
                      'IsDefault',
                      setFormData
                    )
                  }
                />
              }
              label="Is Default?"
            />
          </Grid>
          <Grid item xs={2}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={formData.values?.IsMark}
                  name="IsMark"
                  onChange={(event) =>
                    handleFormChangeNew(event, event.target.checked, 'none', 'IsMark', setFormData)
                  }
                />
              }
              label="Is Mark?"
            />
          </Grid>
          <Grid item xs={4}>
            <RMSAutoComplete
              size="small"
              autoComplete
              autoSelect
              autoHighlight
              id="combo-search-location"
              options={codeMarkLocation}
              label="Mark Location"
              serviceName="codelocation"
              variant="outlined"
              title={'Code'}
              stateHolder={formData}
              setStateHolder={setFormData}
              setField={handleFormChangeNew}
              stateHolderAttribute="MarkLocation"
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              id="standard-textarea-gang"
              autoComplete="hidden"
              size="small"
              placeholder="Gang"
              label="Gang"
              variant="outlined"
              name="Gang"
              defaultValue={formData.values?.Gang || ''}
              onBlur={(event) =>
                handleFormChangeNew(event, event.target.value, 'none', 'Gang', setFormData)
              }
              fullWidth
              multiline
              InputLabelProps={{ shrink: true }}
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              id="standard-textarea-description"
              autoComplete="hidden"
              size="small"
              placeholder="Description"
              label="Description"
              variant="outlined"
              name="Description"
              defaultValue={formData.values?.Description || ''}
              onBlur={(event) =>
                handleFormChangeNew(event, event.target.value, 'none', 'Description', setFormData)
              }
              fullWidth
              multiline
              InputLabelProps={{ shrink: true }}
            />
          </Grid>
          <Grid item xs={4}>
            <RMSAutoComplete
              size="small"
              autoComplete
              autoSelect
              autoHighlight
              id="combo-search-markType"
              options={codeMarks}
              label="Mark Type"
              serviceName="codemarks"
              variant="outlined"
              title={'Code'}
              stateHolder={formData}
              setStateHolder={setFormData}
              setField={handleFormChangeNew}
              stateHolderAttribute="MarkType"
            />
          </Grid>
        </Grid>
      </form>

      {spinnerLoading && (
        <div className={classes.spinnerLoadingContainer}>
          <div>
            {' '}
            <CircularProgress disableShrink />
          </div>
          <span>Loading...</span>
        </div>
      )}

      {(mugshot || formData.values.picture) && (
        <List className="pb-0">
          <ListItem className="py-4">
            <div className="d-flex flex-column flex-sm-row align-items-start">
              <div>
                <Card className="card-transparent mb-3 mb-sm-0">
                  <a
                    href="#/"
                    onClick={(e) => e.preventDefault()}
                    className="card-img-wrapper rounded">
                    {!spinnerLoading ? (
                      <img
                        alt="..."
                        className="card-img-top rounded"
                        src={
                          isPhotoUploaded ? photo : 'data:image/png;base64, ' + mugshot.FileObject
                        }
                        style={{ width: 160 }}
                      />
                    ) : (
                      <div className={classes.imgDiv}>
                        <CircularProgress size={30} thickness={5} />
                      </div>
                    )}
                  </a>
                </Card>
              </div>
              <div className="pl-0 pl-sm-4">
                <div className="mb-1">
                  <a className="font-size-lg" href="#/" onClick={(e) => e.preventDefault()}>
                    Description: {formData.values.Description}
                  </a>
                </div>
                <div>
                  <div className="text-info badge badge-neutral-info">
                    Image Type: {formData.values.ImageTypeCode}{' '}
                  </div>
                  <div className="text-danger ml-2 badge badge-neutral-danger">
                    {' '}
                    Mark Type: {formData.values.MarkType}{' '}
                  </div>
                </div>
                <p className="mb-0 mt-2 text-black-50">
                  Is Default: {formData.values.IsDefault ? 'True' : 'False'}
                </p>
                <p className="mb-0 mt-1 text-black-50">
                  Is Mark: {formData.values.IsMark ? 'True' : 'False'}
                </p>
              </div>
            </div>
          </ListItem>
        </List>
      )}

      <RMSFabButtons recordForm={true} handleSaveRecord={handleSave} />
    </Paper>
  );
};
const mapStateToProps = (state) => ({
  wsClient: state.websocket,
  codeMugshotTypes: state.codeMugshotTypes,
});

export default connect(mapStateToProps, { upsertRecord })(PersonPhotoForm);
