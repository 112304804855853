/* eslint-disable react-hooks/exhaustive-deps */
import makeStyles from '@material-ui/core/styles/makeStyles';
import React from 'react';
import { getCodeVictimSubjectRelationship } from '../../reducers/dictionaries/CodeVictimSubjectRelationshipReducer';
import TreeItem from '@material-ui/lab/TreeItem';
import Typography from '@material-ui/core/Typography';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import * as PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  addVictimRelationships,
  updateIncOffensesRelationshipForm,
} from '../../reducers/IncReducer';
import { setHandleAddIncRelationship } from '../../reducers/ui/UiMenuReducer';
import { v4 as uuidv4 } from 'uuid';
import { handleFormChangeNew } from '../../reducers/helpers/formHelpers';
import { validateIncSubjectVictimForm } from '../../simpl-schema-validation/ValidateForms/incSubjectVictimRelationship';

const useTreeItemStyles = makeStyles((theme) => ({
  root: {
    color: theme.palette.text.secondary,
    '&:focus > $content': {
      backgroundColor: '#fafafa',
      color: 'var(--tree-view-color)',
    },
    '&$selected > $content $label': {
      backgroundColor: 'transparent',
    },
  },
  content: {
    color: theme.palette.text.secondary,
    borderTopRightRadius: theme.spacing(2),
    borderBottomRightRadius: theme.spacing(2),
    paddingRight: theme.spacing(1),
    fontWeight: theme.typography.fontWeightRegular,
    '$expanded > &': {
      fontWeight: theme.typography.fontWeightMedium,
    },
  },
  group: {
    marginLeft: 0,
    '& $content': {
      paddingLeft: theme.spacing(2),
    },
  },
  expanded: {},
  label: {
    fontWeight: 'inherit',
    color: 'inherit',
  },
  labelRoot: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0.5, 0),
  },
  labelIcon: {
    marginRight: theme.spacing(2),
  },
  labelText: {
    fontWeight: 'inherit',
    flexGrow: 1,
    verticalAlign: 'middle',
    marginLeft: theme.spacing(2),
  },
  childNode: {
    minWidth: '30em',
  },
  selected: {},
}));

const StyledTreeItem = (props) => {
  const classes = useTreeItemStyles();
  const [errors, setErrors] = React.useState([]);
  const {
    subHeading1,
    subHeading2,
    labelText,
    avatarLabel,
    labelIcon: LabelIcon,
    color,
    bgColor,
    flag,
    options,
    parent,
    child,
    updateIncOffensesRelationshipForm,
    getCodeVictimSubjectRelationship,
    wsClient,
    incRelationships,
    ...other
  } = props;

  let allRelations = [];
  incRelationships.forEach((relationship) => {
    allRelations.push(relationship.incRelationshipDetails?.values);
  });

  const [offensesRelationshipFormState, setOffensesRelationshipFormState] = React.useState({
    tempRelationshipId: uuidv4(),
    isValid: false,
    changes: false,
    values: {},
    touched: {},
    errors: {},
  });
  const [codeRelationships, setCodeRelationships] = React.useState([]);

  React.useEffect(() => {
    let arr = options.map((s) => s.Description).filter((s) => s !== '');
    setCodeRelationships(arr.sort());
  }, [options]);

  React.useEffect(() => {
    const services = ['codeVictimSubjectRelationship'];

    if (!wsClient) return;

    services.forEach(async (svc) => {
      if (props[svc].length < 1) {
        const result = await wsClient.service(svc.toLowerCase()).find({
          query: {
            $limit: 0,
            $select: ['Code', 'Description'],
            IsDeleted: false,
          },
        });

        if (result.total > 0) {
          getCodeVictimSubjectRelationship(result.data);
        }
      }
    });
  }, [wsClient]);

  React.useEffect(() => {
    let relationship = incRelationships.find(
      (ir) =>
        ir.incRelationshipDetails.values.relationship.offenderId === parent?.id &&
        ir.incRelationshipDetails.values.relationship.victimId === child?.id
    )?.incRelationshipDetails; // Get data from current incRelationships array

    setOffensesRelationshipFormState((offensesRelationshipFormState) => ({
      ...offensesRelationshipFormState,
      ptsOffenseId: relationship?.ptsOffenseId,
      values: {
        relationship: {
          offenderId: parent?.id,
          victimId: child?.id,
          relationship: relationship?.values.relationship.relationship,
        },
      },
    }));
  }, [parent, child, incRelationships]);

  React.useEffect(() => {
    if (flag === 'child') {
      for (let relation of allRelations) {
        if (
          relation.relationship?.offenderId ===
            offensesRelationshipFormState.values.relationship?.offenderId &&
          relation.relationship?.victimId ===
            offensesRelationshipFormState.values.relationship?.victimId
        ) {
          relation.relationship.relationship =
            offensesRelationshipFormState.values.relationship?.relationship;
        }
      }

      let error = validateIncSubjectVictimForm(
        child,
        parent,
        offensesRelationshipFormState.values.relationship?.relationship,
        allRelations
      );
      let errorStatus = [];

      if (error && error.length > 0) {
        errorStatus = [...errors];

        errorStatus.push({
          victimId: child.id,
          subjectId: parent.id,
          message: error[0].message,
        });
      } else {
        errorStatus = [];
      }

      setErrors(errorStatus);
      offensesRelationshipFormState.changes = true;
      updateIncOffensesRelationshipForm(offensesRelationshipFormState);
      addVictimRelationships();
    }
  }, [
    offensesRelationshipFormState.values.relationship?.relationship,
    offensesRelationshipFormState.values.relationship?.offenderId,
    offensesRelationshipFormState.values.relationship?.victimId,
  ]);

  return (
    <TreeItem
      label={
        <div className={classes.labelRoot}>
          <LabelIcon label={avatarLabel} color="inherit" className={classes.labelIcon} />
          <Typography variant="body2" className={classes.labelText}>
            {labelText}
          </Typography>
          <div className="mr-5">
            <Typography variant="h6" className="text-black-50 d-block">
              {subHeading1}
            </Typography>
            <Typography variant="h6" className="text-black-50 d-block">
              {subHeading2}
            </Typography>
          </div>
          {flag === 'child' && (
            <Autocomplete
              size="small"
              autoComplete
              autoHighlight
              className={classes.childNode}
              id="combo-treeview-options"
              getOptionLabel={(option) => option || ''}
              options={codeRelationships}
              key={offensesRelationshipFormState.values.relationship?.relationship || ''}
              value={offensesRelationshipFormState.values.relationship?.relationship || ''}
              onChange={(event, newValue) =>
                handleFormChangeNew(
                  event,
                  newValue,
                  'none',
                  'relationship',
                  setOffensesRelationshipFormState
                )
              }
              renderInput={(params) => (
                <TextField
                  autoComplete="hidden"
                  size="small"
                  {...params}
                  label="Select Relationship"
                  variant="outlined"
                  error={errors[errors.length - 1] ? true : false}
                  helperText={errors[errors.length - 1]?.message}
                />
              )}
            />
          )}
        </div>
      }
      style={{
        '--tree-view-color': color,
        '--tree-view-bg-color': bgColor,
      }}
      classes={{
        root: classes.root,
        content: classes.content,
        expanded: classes.expanded,
        group: classes.group,
        label: classes.label,
        selected: classes.selected,
      }}
      {...other}
    />
  );
};

StyledTreeItem.propTypes = {
  bgColor: PropTypes.string,
  color: PropTypes.string,
  labelIcon: PropTypes.elementType.isRequired,
  labelInfo: PropTypes.string,
  labelText: PropTypes.string.isRequired,
  flag: PropTypes.string.isRequired,
};
const mapStateToProps = (state) => ({
  relationshipForm: state.incident.offensesRelationshipForm,
  incRelationships: state.incident.incRelationships,
  wsClient: state.websocket.websocket,
  codeVictimSubjectRelationship: state.codeVictimSubjectRelationship,
});

export default connect(mapStateToProps, {
  updateIncOffensesRelationshipForm,
  setHandleAddIncRelationship,
  getCodeVictimSubjectRelationship,
})(StyledTreeItem);
