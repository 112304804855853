import store from '../../config/configureStore';

export const SET_CODE_HAIR_COLOR = 'CODE/SET_CODE_HAIR_COLOR';
export const ADD_CODE_HAIR_COLOR = 'CODE/ADD_CODE_HAIR_COLOR';
export const SET_CODE_HAIR_LENGTH = 'CODE/SET_CODE_HAIR_LENGTH';
export const ADD_CODE_HAIR_LENGTH = 'CODE/ADD_CODE_HAIR_LENGTH';
export const SET_CODE_HAIR_STYLE = 'CODE/SET_CODE_HAIR_STYLE';
export const ADD_CODE_HAIR_STYLE = 'CODE/ADD_CODE_HAIR_STYLE';

export const addCodeHairColor = (newVal) => {
  return { type: ADD_CODE_HAIR_COLOR, payload: newVal };
};

export const getCodeHairColor = () => {
  const client = store.store.getState().websocket.websocket;
  return async (dispatch) => {
    try {
      const service = client.service('codehaircolor');
      service.timeout = 20000;
      const codes = await service.find({
        query: {
          $select: ['Code', 'Description'],
          IsDeleted: false,
        },
      });
      dispatch({ type: SET_CODE_HAIR_COLOR, payload: codes.data });
    } catch (error) {
      if (error.code === 401) {
        console.log('Unauthenticated');
      } else {
        console.log(error);
      }
    }
  };
};

export const addCodeHairLength = (newVal) => {
  return { type: ADD_CODE_HAIR_LENGTH, payload: newVal };
};

export const getCodeHairLength = () => {
  const client = store.store.getState().websocket.websocket;
  return async (dispatch) => {
    try {
      const service = client.service('codehairlength');
      service.timeout = 20000;
      const codes = await service.find({
        query: {
          $select: ['Code', 'Description'],
          IsDeleted: false,
        },
      });
      dispatch({ type: SET_CODE_HAIR_LENGTH, payload: codes.data });
    } catch (error) {
      if (error.code === 401) {
        console.log('Unauthenticated');
      } else {
        console.log(error);
      }
    }
  };
};

export const addCodeHairStyle = (newVal) => {
  return { type: ADD_CODE_HAIR_STYLE, payload: newVal };
};

export const getCodeHairStyle = () => {
  const client = store.store.getState().websocket.websocket;
  return async (dispatch) => {
    try {
      const service = client.service('codehairstyle');
      service.timeout = 20000;
      const codes = await service.find({
        query: {
          $select: ['Code', 'Description'],
          IsDeleted: false,
        },
      });
      dispatch({ type: SET_CODE_HAIR_STYLE, payload: codes.data });
    } catch (error) {
      if (error.code === 401) {
        console.log('Unauthenticated');
      } else {
        console.log(error);
      }
    }
  };
};

export default function reducer(
  state = {
    codeHairColor: [],
    codeHairLength: [],
    codeHairStyle: [],
  },
  action
) {
  // eslint-disable-next-line default-case
  switch (action.type) {
    case SET_CODE_HAIR_COLOR:
      return { ...state, codeHairColor: action.payload };
    case ADD_CODE_HAIR_COLOR:
      return { ...state, codeHairColor: [...state.codeHairColor, action.payload] };
    case SET_CODE_HAIR_LENGTH:
      return { ...state, codeHairLength: action.payload };
    case ADD_CODE_HAIR_LENGTH:
      return { ...state, codeHairLength: [...state.codeHairLength, action.payload] };
    case SET_CODE_HAIR_STYLE:
      return { ...state, codeHairStyle: action.payload };
    case ADD_CODE_HAIR_STYLE:
      return { ...state, codeHairStyle: [...state.codeHairStyle, action.payload] };
  }
  return state;
}
