/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { connect, useDispatch } from 'react-redux';
import { useParams } from 'react-router';

import { v4 as uuidv4 } from 'uuid';
import SimpleSchema from 'simpl-schema';
import { TextField, Grid, Divider, Checkbox, FormControlLabel } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';

import VehicleSearch from './VehicleSearch';
import RMSComboBox from 'components/RMSComboBox';
import RMSComboBoxListItem from 'components/RMSComboBoxListItem';
import RMSAutoComplete from 'components/RMSAutoComplete/RMSAutoComplete';
import {
  updateIncPropertyVehicleDetailsForm,
  updateIncPropertyCredentialsAnalysisForm,
  updateIncEvidenceItemForm,
} from '../../../reducers/IncReducer';
import { getCodeVehicleMakes } from '../../../reducers/dictionaries/CodeVehicleMakesReducer';
import { getCodeVehicleMakeModels } from '../../../reducers/dictionaries/CodeVehicleMakesModelsReducer';
import { getCodeVehicleStyle } from '../../../reducers/dictionaries/CodeVehicleStylesReducer';
import { getCodeVehicleColor } from '../../../reducers/dictionaries/CodeVehicleColorsReducer';
import { getCodeVehicleBranders } from '../../../reducers/dictionaries/CodeVehicleBrandersReducer';
import { getCodeVehicleBrands } from '../../../reducers/dictionaries/CodeVehicleBrandsReducer';
import { getCodeVehicleClassifications } from '../../../reducers/dictionaries/CodeVehicleClassificationsReducer';
import { getCodeMaterial } from '../../../reducers/dictionaries/CodeMaterialReducer';
import { handleFormChangeNew, onVehicleValueChange } from '../../../reducers/helpers/formHelpers';
import { checkFormType } from '../../helpers/checkFormState';
import { setCircularLoading } from 'reducers/ui/UiMenuReducer';
import TabCredentialsAnalysis from './TabCredentialsAnalysis';
import RMSAttachments from 'components/RMSAttachments';

// Validattion Schema
const validationContext = new SimpleSchema({
  seatQuantity: SimpleSchema.Integer,
  passengerQuantity: SimpleSchema.Integer,
  year: SimpleSchema.Integer,
}).newContext();

const TabVehicleDetails = (props) => {
  /** region Component Variables and Props */
  const {
    wsClient,
    history,
    recordForm,
    codeVehicleStyles,
    codeVehicleMakesModels,
    codeVehicleMakes,
    codeVehicleColors,
    codeVehicleClassifications,
    codeVehicleBrands,
    codeVehicleBranders,
    codeMaterial,
    getCodeVehicleStyle,
    getCodeVehicleMakeModels,
    getCodeVehicleMakes,
    getCodeVehicleColor,
    getCodeVehicleClassifications,
    getCodeVehicleBrands,
    getCodeVehicleBranders,
    getCodeMaterial,
    propertyVehicleDetailsForm,
    propertyCredentialsAnalysisForm,
    updateIncEvidenceItemForm,
    updateIncPropertyVehicleDetailsForm,
    updateIncPropertyCredentialsAnalysisForm,
  } = props;
  const [changeKey, setChangeKey] = React.useState(0);
  const [pageType, setPageType] = React.useState('add');
  const [entityType, setEntityType] = React.useState('');
  const [firstLoad, setFirstLoad] = React.useState(true);
  const [errors, setErrors] = React.useState({});
  const dispatch = useDispatch();
  let { vehicleId } = useParams();

  const [propertyVehicleDetailsFormState, setPropertyVehicleDetailsFormState] = React.useState({
    tempPropId: uuidv4(),
    isValid: false,
    changes: false,
    values: {
      isEvidence: false,
    },
    touched: {},
    errors: {},
    formType: 'edit',
  });
  /** endregion */
  /** region React Hooks */
  React.useEffect(() => {
    checkFormType(
      propertyVehicleDetailsFormState.ptsVehicleId !== undefined &&
        propertyVehicleDetailsFormState.ptsVehicleId !== null,
      setPropertyVehicleDetailsFormState,
      history
    );
  }, []);

  React.useEffect(() => {
    if (!wsClient) return;

    const services = [
      'codeVehicleStyles',
      'codeVehicleMakesModels',
      'codeVehicleMakes',
      'codeVehicleColors',
      'codeVehicleClassifications',
      'codeVehicleBrands',
      'codeVehicleBranders',
      'codeMaterial',
    ];

    const parts = history.location.pathname.split('/');
    let type = '';
    if (parts.length === 4) type = parts[parts.length - 2];
    if (parts.length === 5) type = parts[parts.length - 3];

    setEntityType(parts[1]);
    setPageType(type);

    if (
      !propertyVehicleDetailsForm.ptsVehicleID &&
      !propertyVehicleDetailsForm.ptsVehicleId &&
      parts[1] === 'records'
    ) {
      getVehicleData();
    }

    services.forEach(async (svc) => {
      let $select = ['Code', 'Description'];

      if (props[svc].length < 1) {
        if (svc === 'codeVehicleMakesModels') {
          $select = ['MakeCode', 'ModelCode'];
        }

        const result = await wsClient.service(svc.toLowerCase()).find({
          query: {
            $limit: 10,
            $select,
            IsDeleted: false,
          },
        });

        if (result.total > 0) {
          if (svc === 'codeVehicleStyles') {
            getCodeVehicleStyle(result.data);
          } else if (svc === 'codeVehicleMakesModels') {
            getCodeVehicleMakeModels(result.data);
          } else if (svc === 'codeVehicleMakes') {
            getCodeVehicleMakes(result.data);
          } else if (svc === 'codeVehicleColors') {
            getCodeVehicleColor(result.data);
          } else if (svc === 'codeVehicleClassifications') {
            getCodeVehicleClassifications(result.data);
          } else if (svc === 'codeVehicleBrands') {
            getCodeVehicleBrands(result.data);
          } else if (svc === 'codeVehicleBranders') {
            getCodeVehicleBranders(result.data);
          } else if (svc === 'codeMaterial') {
            getCodeMaterial(result.data);
          }
        }
      }
    });
  }, [wsClient]);

  React.useEffect(() => {
    checkFormType(
      propertyVehicleDetailsFormState.ptsVehicleId !== undefined &&
        propertyVehicleDetailsFormState.ptsVehicleId !== null,
      setPropertyVehicleDetailsFormState,
      history
    );
    setPropertyVehicleDetailsFormState((propertyVehicleDetailsFormState) => ({
      ...propertyVehicleDetailsFormState,
      ...propertyVehicleDetailsForm,
      values: {
        ...propertyVehicleDetailsFormState.values,
        ...propertyVehicleDetailsForm.values,
      },
    }));
  }, [propertyVehicleDetailsForm]);

  React.useEffect(() => {
    if (!wsClient) {
      return;
    }

    const getAttachments = async () => {
      const service = wsClient?.service('rms-attachments');
      service.timeout = 200000;

      const result = await service.find({
        query: {
          id:
            entityType === 'records'
              ? propertyVehicleDetailsFormState.ptsVehicleId
              : propertyVehicleDetailsFormState.incIncidentPropertyId,
          type: entityType === 'records' ? 'VEHICLE' : 'INCIDENTPROPERTY',
        },
      });
      let atts = [];
      result.map((r) => {
        atts.push({
          ptsAttachmentId: r.ptsAttachmentID,
          name: r.FileName,
          type: r.FileType,
          dataURL: r.FileObject,
        });
      });
      setPropertyVehicleDetailsFormState((stateValue) => ({
        ...stateValue,
        values: {
          ...stateValue.values,
          attachments: atts,
        },
      }));
    };

    if (propertyVehicleDetailsFormState.ptsVehicleId) {
      getAttachments();
    }
  }, [wsClient, propertyVehicleDetailsFormState.ptsVehicleId]);

  React.useEffect(() => {
    if (!firstLoad) {
      validationContext.validate({
        seatQuantity:
          propertyVehicleDetailsFormState.values?.seatQuantity?.length > 0
            ? Number(propertyVehicleDetailsFormState.values?.seatQuantity)
            : 0,
        passengerQuantity:
          propertyVehicleDetailsFormState.values?.passengerQuantity?.length > 0
            ? Number(propertyVehicleDetailsFormState.values?.passengerQuantity)
            : 0,
        year:
          propertyVehicleDetailsFormState.values?.year?.length > 0
            ? Number(propertyVehicleDetailsFormState.values?.year)
            : 0,
      });

      if (validationContext.isValid() === false) {
        const errs = validationContext.validationErrors();
        console.log(errs);
        let e = {};
        // eslint-disable-next-line array-callback-return
        errs.map((er) => {
          e[er.name] = `Value must be ${er.dataType}`;
          setErrors(e);
        });
      } else {
        setErrors({});
      }
    }
    setFirstLoad(false);

    propertyVehicleDetailsFormState.changes = true;
    updateIncPropertyVehicleDetailsForm(propertyVehicleDetailsFormState);

    if (propertyVehicleDetailsFormState.values.isEvidence) {
      updateIncEvidenceItemForm({
        values: {
          description: `${propertyVehicleDetailsFormState.values?.description || ''} ${
            propertyVehicleDetailsFormState.values?.model || ''
          } ${propertyVehicleDetailsFormState.values?.brand || ''}`,
          offenses: [],
          category: 'Property',
          vin: propertyCredentialsAnalysisForm.values.vin,
          licenseNumber: propertyCredentialsAnalysisForm.values.plateNumber,
          make: propertyVehicleDetailsFormState.values?.make,
          model: propertyVehicleDetailsFormState.values?.model,
        },
      });
    }
  }, [
    propertyVehicleDetailsFormState.values?.incidentId,
    propertyVehicleDetailsFormState.values?.vehicleId,
    propertyVehicleDetailsFormState.values?.year,
    propertyVehicleDetailsFormState.values?.make,
    propertyVehicleDetailsFormState.values?.model,
    propertyVehicleDetailsFormState.values?.style,
    propertyVehicleDetailsFormState.values?.passengerQuantity,
    propertyVehicleDetailsFormState.values?.seatQuantity,
    propertyVehicleDetailsFormState.values?.primaryColor,
    propertyVehicleDetailsFormState.values?.secondaryColor,
    propertyVehicleDetailsFormState.values?.interior,
    propertyVehicleDetailsFormState.values?.classification,
    propertyVehicleDetailsFormState.values?.brand,
    propertyVehicleDetailsFormState.values?.brander,
    propertyVehicleDetailsFormState.values?.description,
    propertyVehicleDetailsFormState.values?.isEvidence,
    propertyVehicleDetailsFormState.values?.attachments,
  ]);
  /** endregion */
  /** region Helper Functions */
  const formatVehicleResult = (result) => {
    return {
      ptsVehicleID: result.ptsVehicleID,
      ptsVehicleId: result.ptsVehicleID,
      values: {
        vin: result.VIN,
        vinAnalysisTest: result.VINAnalysisText,
        make: result.Make,
        model: result.Model,
        description: result.Description,
        year: result.Year,
        band: result.Band,
        brander: result.Brander,
        rented: result.IsRented,
        wanted: result.IsWanted,
        seatQuantity: result.SeatQty,
        passengerQuantity: result.PassengerSafeQty,
        transmissionCode: result.Transmission,
        emissionInspection: result.EmissionInspectionQty,
        commercialId: result.CommercialID,
        commercialCode: result.Commercial,
        cmv: result.IsCMVIndicator,
        garageFacility: result.GarageFacility,
        garageIndicator: result.GarageIndicator,
        classification: result.Classification,
        primaryColor: result.PrimaryColor,
        secondaryColor: result.SecondaryColor,
        interior: result.InteriorColor,
        style: result.Style,
        plateNumber: result.PlateNumber,
        plateState: result.PlateState,
        propertyId: result.PropertyId,
        propertyCode: result.PropertyCode,
        plateStatus: result.PlateStatus,
        plateColor: result.PlateColor,
        plateType: result.PlateType,
        plateExpiration: result.PlateExpiration,
        plateDetail: result.PlateDetail,
        plateBackgroundText: result.PlateBackgroundText,
      },
    };
  };

  const getVehicleData = async () => {
    const service = wsClient?.service('rms-property-vehicle-search');
    service.timeout = 200000;
    if (!vehicleId) return;
    dispatch(setCircularLoading(true));
    const result = await service.get(vehicleId);

    if (result) {
      dispatch(setCircularLoading(false));
    }

    const formattedResult = formatVehicleResult(result);

    setPropertyVehicleDetailsFormState(formattedResult);
    updateIncPropertyCredentialsAnalysisForm(formattedResult);
  };

  const onVehicleSelect = (vehicle, setFormState, setChangeKey, changeKey) => {
    onVehicleValueChange(vehicle, setFormState, setChangeKey, changeKey);

    const formattedResult = formatVehicleResult(vehicle);
    updateIncPropertyCredentialsAnalysisForm(formattedResult);
  };
  /** endregion */
  return (
    <Grid item xs={12} lg={12}>
      <Grid item xs={12} lg={12} className="mb-1">
        {pageType === 'add' && entityType !== 'records' && (
          <FormControlLabel
            control={
              <Checkbox
                key={propertyVehicleDetailsFormState.values.isEvidence}
                checked={propertyVehicleDetailsFormState.values.isEvidence}
                onChange={(event) =>
                  handleFormChangeNew(
                    event,
                    event.target.checked,
                    'none',
                    'isEvidence',
                    setPropertyVehicleDetailsFormState
                  )
                }
                name="isEvidence"
              />
            }
            label="Is Evidence?"
          />
        )}
      </Grid>
      {entityType !== 'records' && pageType === 'add' && (
        <Grid xs={12} lg={12} item className="mb-3">
          <VehicleSearch
            onVehicleValueSet={onVehicleSelect}
            setFormState={setPropertyVehicleDetailsFormState}
            setChangeKey={setChangeKey}
            changeKey={changeKey}
          />
        </Grid>
      )}
      <Grid xs={12} className="mb-3">
        <span style={{ color: '#fff', background: '#1976d2' }} className="mb-1 badge badge-primary">
          Vehicle Build Info
        </span>
        <Divider />
      </Grid>
      <Grid container spacing={2} className="mb-2">
        <Grid item xs={4} lg={4}>
          <TextField
            id="standard-textarea-vehicleDetails-year"
            autoComplete="hidden"
            size="small"
            label="Year"
            placeholder="Year"
            variant="outlined"
            name="year"
            error={errors['year'] ? true : false}
            helperText={errors['year']}
            key={changeKey}
            defaultValue={propertyVehicleDetailsFormState.values?.year || ''}
            onChange={(event) =>
              handleFormChangeNew(
                event,
                event.target.value,
                'none',
                'year',
                setPropertyVehicleDetailsFormState
              )
            }
            fullWidth
            multiline
            InputLabelProps={{ shrink: true }}
          />
        </Grid>
        <Grid item xs={4} lg={4}>
          <Autocomplete
            size="small"
            autoComplete
            autoSelect
            autoHighlight
            id="combo-vehicle-make"
            getOptionLabel={(option) => option.Code || ''}
            options={codeVehicleMakes}
            key={propertyVehicleDetailsFormState.values?.make || ''}
            value={{ Code: propertyVehicleDetailsFormState.values?.make } || ''}
            onChange={(event, newValue) =>
              handleFormChangeNew(
                event,
                newValue?.Code,
                'none',
                'make',
                setPropertyVehicleDetailsFormState
              )
            }
            renderOption={(option) => {
              return (
                <RMSComboBoxListItem
                  option={option}
                  title="Code"
                  serviceName="codevehiclemakes"
                  label="Make"
                  resetField={() =>
                    handleFormChangeNew(
                      null,
                      null,
                      'none',
                      'make',
                      setPropertyVehicleDetailsFormState
                    )
                  }
                />
              );
            }}
            renderInput={(params) => (
              <RMSComboBox
                {...params}
                setField={(newVal) => {
                  handleFormChangeNew(
                    null,
                    newVal['Code'],
                    'none',
                    'make',
                    setPropertyVehicleDetailsFormState
                  );
                }}
                serviceName="codevehiclemakes"
                label="Make"
                variant="outlined"
                fullWidth
              />
            )}
          />
        </Grid>
        <Grid item xs={4} lg={4}>
          <Autocomplete
            size="small"
            autoComplete
            autoSelect
            autoHighlight
            id="combo-vehicle-make-model"
            getOptionLabel={(option) => option.ModelCode || ''}
            options={codeVehicleMakesModels}
            key={propertyVehicleDetailsFormState.values?.model || ''}
            value={{ ModelCode: propertyVehicleDetailsFormState.values?.model } || ''}
            onChange={(event, newValue) =>
              handleFormChangeNew(
                event,
                newValue?.ModelCode,
                'none',
                'model',
                setPropertyVehicleDetailsFormState
              )
            }
            renderOption={(option) => {
              return (
                <RMSComboBoxListItem
                  option={option}
                  title="ModelCode"
                  serviceName="codevehiclemakesmodels"
                  label="Model"
                  resetField={() =>
                    handleFormChangeNew(
                      null,
                      null,
                      'none',
                      'model',
                      setPropertyVehicleDetailsFormState
                    )
                  }
                />
              );
            }}
            renderInput={(params) => (
              <RMSComboBox
                {...params}
                setField={(newVal) => {
                  handleFormChangeNew(
                    null,
                    newVal['ModelCode'],
                    'none',
                    'model',
                    setPropertyVehicleDetailsFormState
                  );
                }}
                serviceName="codevehiclemakesmodels"
                label="Model"
                variant="outlined"
                fullWidth
              />
            )}
          />
        </Grid>
      </Grid>
      <Grid container spacing={2} justify="center" className="mb-2">
        <Grid item xs={4} lg={4}>
          <RMSAutoComplete
            size="small"
            autoComplete
            autoSelect
            autoHighlight
            id="combo-vehicle-classification"
            options={codeVehicleClassifications}
            label="Classification"
            serviceName="codevehicleclassifications"
            title="Code"
            stateHolderAttribute="classification"
            variant="outlined"
            stateHolder={propertyVehicleDetailsFormState}
            setStateHolder={setPropertyVehicleDetailsFormState}
            setField={handleFormChangeNew}
          />
        </Grid>
        <Grid item xs={4} lg={4}>
          <RMSAutoComplete
            size="small"
            autoComplete
            autoSelect
            autoHighlight
            id="combo-vehicle-brand"
            options={codeVehicleBrands}
            label="Brand"
            serviceName="codevehiclebrands"
            title="Code"
            stateHolderAttribute="brand"
            variant="outlined"
            stateHolder={propertyVehicleDetailsFormState}
            setStateHolder={setPropertyVehicleDetailsFormState}
            setField={handleFormChangeNew}
          />
        </Grid>
        <Grid item xs={4} lg={4}>
          <RMSAutoComplete
            size="small"
            autoComplete
            autoSelect
            autoHighlight
            id="combo-vehicle-branders"
            options={codeVehicleBranders}
            label="Brander"
            serviceName="codevehiclebranders"
            title="Code"
            stateHolderAttribute="brander"
            variant="outlined"
            stateHolder={propertyVehicleDetailsFormState}
            setStateHolder={setPropertyVehicleDetailsFormState}
            setField={handleFormChangeNew}
          />
        </Grid>
      </Grid>
      <TabCredentialsAnalysis />
      <Grid xs={12} className="mb-3">
        <span style={{ color: '#fff', background: '#1976d2' }} className="mb-1 badge badge-primary">
          Vehicle Details
        </span>
        <Divider />
      </Grid>
      <Grid container spacing={2} justify="center" className="mb-2">
        <Grid item xs={4} lg={4}>
          <RMSAutoComplete
            size="small"
            autoComplete
            autoSelect
            autoHighlight
            id="combo-vehicle-style"
            options={codeVehicleStyles}
            label="Style"
            serviceName="codeVehicleStyles"
            title="Description"
            stateHolderAttribute="style"
            variant="outlined"
            stateHolder={propertyVehicleDetailsFormState}
            setStateHolder={setPropertyVehicleDetailsFormState}
            setField={handleFormChangeNew}
          />
        </Grid>
        <Grid item xs={4} lg={4}>
          <TextField
            id="standard-textarea-vehicleDetails-passengerQuantity"
            autoComplete="hidden"
            size="small"
            error={errors['passengerQuantity'] ? true : false}
            helperText={errors['passengerQuantity']}
            label="Passenger Quantity"
            placeholder="Passenger Quantity"
            variant="outlined"
            name="passengerQuantity"
            key={changeKey}
            value={propertyVehicleDetailsFormState.values?.passengerQuantity || ''}
            onChange={(event) =>
              handleFormChangeNew(
                event,
                event.target.value,
                'none',
                'passengerQuantity',
                setPropertyVehicleDetailsFormState
              )
            }
            fullWidth
            multiline
            InputLabelProps={{ shrink: true }}
          />
        </Grid>
        <Grid item xs={4} lg={4}>
          <TextField
            id="standard-textarea-vehicleDetails-seatQuantity"
            autoComplete="hidden"
            size="small"
            error={errors['seatQuantity'] ? true : false}
            helperText={errors['seatQuantity']}
            label="Seat Quantity"
            placeholder="Seat Quantity"
            variant="outlined"
            name="seatQuantity"
            key={changeKey}
            value={propertyVehicleDetailsFormState.values?.seatQuantity || ''}
            onChange={(event) =>
              handleFormChangeNew(
                event,
                event.target.value,
                'none',
                'seatQuantity',
                setPropertyVehicleDetailsFormState
              )
            }
            fullWidth
            multiline
            InputLabelProps={{ shrink: true }}
          />
        </Grid>
      </Grid>
      <Grid container spacing={2} justify="center" className="mb-2">
        <Grid item xs={4} lg={4}>
          <RMSAutoComplete
            size="small"
            autoComplete
            autoSelect
            autoHighlight
            id="combo-vehicle-primary-color"
            options={codeVehicleColors}
            label="Primary Color"
            serviceName="codeVehicleColors"
            title="Description"
            stateHolderAttribute="primaryColor"
            variant="outlined"
            stateHolder={propertyVehicleDetailsFormState}
            setStateHolder={setPropertyVehicleDetailsFormState}
            setField={handleFormChangeNew}
          />
        </Grid>
        <Grid item xs={4} lg={4}>
          <RMSAutoComplete
            size="small"
            autoComplete
            autoSelect
            autoHighlight
            id="combo-vehicle-secondary-color"
            options={codeVehicleColors}
            label="Secondary Color"
            serviceName="codevehiclecolors"
            title="Description"
            stateHolderAttribute="secondaryColor"
            variant="outlined"
            stateHolder={propertyVehicleDetailsFormState}
            setStateHolder={setPropertyVehicleDetailsFormState}
            setField={handleFormChangeNew}
          />
        </Grid>
        <Grid item xs={4} lg={4}>
          <RMSAutoComplete
            size="small"
            autoComplete
            autoSelect
            autoHighlight
            id="combo-vehicle-interior"
            options={codeMaterial}
            label="Interior"
            serviceName="codematerial"
            title="Code"
            stateHolderAttribute="interior"
            variant="outlined"
            stateHolder={propertyVehicleDetailsFormState}
            setStateHolder={setPropertyVehicleDetailsFormState}
            setField={handleFormChangeNew}
          />
        </Grid>
      </Grid>
      <Grid xs={12} className="mt-2 mb-3">
        <span style={{ color: '#fff', background: '#1976d2' }} className="mb-1 badge badge-primary">
          Description
        </span>
        <Divider />
      </Grid>
      <Grid item xs={12} lg={12}>
        <TextField
          id="standard-textarea-vehicleDetails-description"
          autoComplete="hidden"
          size="small"
          label="Description"
          placeholder="Description"
          variant="outlined"
          name="description"
          key={changeKey}
          defaultValue={propertyVehicleDetailsFormState.values?.description || ''}
          onBlur={(event) =>
            handleFormChangeNew(
              event,
              event.target.value,
              'none',
              'description',
              setPropertyVehicleDetailsFormState
            )
          }
          fullWidth
          multiline
          InputLabelProps={{ shrink: true }}
        />
      </Grid>

      <Grid item xs={12} lg={12} className="mt-3">
        <RMSAttachments
          attachments={propertyVehicleDetailsFormState.values.attachments}
          stateHolder={propertyVehicleDetailsFormState}
          setStateHolder={setPropertyVehicleDetailsFormState}
          setField={handleFormChangeNew}
        />
      </Grid>
    </Grid>
  );
};

const mapStateToProps = (state) => ({
  propertyVehicleDetailsForm: state.incident.propertyVehicleDetailsForm,
  propertyCredentialsAnalysisForm: state.incident.propertyCredentialsAnalysisForm,
  codeVehicleStyles: state.codeVehicleStyles,
  codeVehicleMakesModels: state.codeVehicleMakesModels,
  codeVehicleMakes: state.codeVehicleMakes,
  codeVehicleColors: state.codeVehicleColors,
  codeVehicleClassifications: state.codeVehicleClassifications,
  codeVehicleBrands: state.codeVehicleBrands,
  codeVehicleBranders: state.codeVehicleBranders,
  codeMaterial: state.codeMaterial,
  wsClient: state.websocket.websocket,
});

export default connect(mapStateToProps, {
  getCodeVehicleStyle,
  getCodeVehicleMakeModels,
  getCodeVehicleMakes,
  getCodeVehicleColor,
  getCodeVehicleBrands,
  getCodeVehicleBranders,
  getCodeMaterial,
  updateIncEvidenceItemForm,
  getCodeVehicleClassifications,
  updateIncPropertyVehicleDetailsForm,
  updateIncPropertyCredentialsAnalysisForm,
})(TabVehicleDetails);
