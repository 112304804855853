import React, { Suspense } from 'react';
import { connect } from 'react-redux';
import { Switch, Route, Redirect } from 'react-router-dom';
import feathers from '@feathersjs/feathers';
import socketio from '@feathersjs/socketio-client';
import io from 'socket.io-client';
import auth from '@feathersjs/authentication-client';

import { setWebsocketConnection } from './reducers/WebsocketReducer';
import { setUserAuthenticated, setUserData } from './reducers/UserReducer';
import { ClimbingBoxLoader } from 'react-spinners';
import { motion } from 'framer-motion';

import { makeStyles } from '@material-ui/core/styles';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';

// Layout Blueprints
import { MinimalLayout, MainLayout } from './layout-blueprints';

// Pages
import PageLogin from './pages/PageLogin';
import Page404 from './pages/Page404';

import routeDefinitions from './routeDefinitions';

const ProtectedRoute = ({ component: Component, isAuthenticated, ...rest }) => (
  <Route
    {...rest}
    render={(props) =>
      isAuthenticated === true ? (
        <Component {...props} />
      ) : (
        <Redirect to={{ pathname: '/Login', state: { from: props.location } }} />
      )
    }
  />
);

const LoginRoute = ({ component: Component, isAuthenticated, ...rest }) => (
  <Route
    {...rest}
    render={(props) =>
      isAuthenticated === true ? (
        <Redirect to={{ pathname: '/incidents', state: { from: props.location } }} />
      ) : (
        <Component {...props} />
      )
    }
  />
);

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
}));

const Routes = (props) => {
  const {
    isAuthenticated,
    setWebsocketConnection,
    setUserAuthenticated,
    setUserData,
    setLoggedInUserData,
    circularLoading,
  } = props;
  const classes = useStyles();

  React.useEffect(() => {
    async function establishConnection() {
      const client = feathers();
      client.configure(
        socketio(
          io(process.env.REACT_APP_API_URL, {
            transports: ['websocket', 'polling'],
            perMessageDeflate: false,
            timeout: 20000,
            requestTimeout: 20000,
          })
        )
      );
      client.configure(auth({ storageKey: 'auth' }));
      try {
        const user = await client.reAuthenticate();
        setWebsocketConnection(client);
        setUserData(user);
        setUserAuthenticated(true);
      } catch (error) {
        if (error.code === 401) {
          setUserAuthenticated(false);
          setUserData(null);
          setWebsocketConnection(client);
        }
        setWebsocketConnection(client);
      }
    }
    establishConnection();
    // eslint-disable-next-line
  }, [setWebsocketConnection, setUserAuthenticated, setLoggedInUserData]);

  const pageVariants = {
    initial: {
      opacity: 0,
      scale: 0.99,
      width: '100%',
      height: '100%',
    },
    in: {
      opacity: 1,
      scale: 1,
    },
    out: {
      opacity: 0,
      scale: 1.01,
    },
  };

  const pageTransition = {
    type: 'tween',
    ease: 'anticipate',
    duration: 0.4,
  };

  const SuspenseLoading = () => {
    return (
      <div className="d-flex align-items-center flex-column vh-100 justify-content-center text-center py-3">
        <div className="d-flex align-items-center flex-column px-4">
          <ClimbingBoxLoader color={'#0153a3'} loading={true} />
        </div>
        <div className="text-muted font-size-xl text-center pt-3">
          Please wait while we load the view...
        </div>
      </div>
    );
  };

  return (
    <Suspense fallback={<SuspenseLoading />}>
      <Switch>
        <Redirect exact from="/" to="/Login" />
        <Route path={['/Login']}>
          <MinimalLayout>
            <motion.div
              initial="initial"
              animate="in"
              exit="out"
              variants={pageVariants}
              transition={pageTransition}>
              <LoginRoute path="/Login" component={PageLogin} isAuthenticated={isAuthenticated} />
            </motion.div>
          </MinimalLayout>
        </Route>

        {routeDefinitions.map((route, key) => {
          const Layout = route.layout;
          return (
            <Route path={[route.url]} key={key}>
              <Backdrop className={classes.backdrop} open={circularLoading}>
                <CircularProgress color="inherit" />
              </Backdrop>
              <Layout>
                <motion.div
                  initial="initial"
                  animate="in"
                  exit="out"
                  variants={pageVariants}
                  transition={pageTransition}>
                  {/*TODO: Change this back to protected route for production*/}
                  <ProtectedRoute
                    path={route.url}
                    component={route.component}
                    isAuthenticated={isAuthenticated}
                  />
                </motion.div>
              </Layout>
            </Route>
          );
        })}

        <Route>
          <MainLayout>
            <motion.div
              initial="initial"
              animate="in"
              exit="out"
              variants={pageVariants}
              transition={pageTransition}>
              <ProtectedRoute component={Page404} isAuthenticated={isAuthenticated} />
            </motion.div>
          </MainLayout>
        </Route>
      </Switch>
    </Suspense>
  );
};

const mapStateToProps = (state) => ({
  network: state.offline,
  isAuthenticated: state.user.isAuthenticated,
  circularLoading: state.uiMenu.circularLoading,
});

export default connect(mapStateToProps, {
  setWebsocketConnection,
  setUserAuthenticated,
  setUserData,
})(Routes);
