/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { connect } from 'react-redux';

import SimpleSchema from 'simpl-schema';
import { v4 as uuidv4 } from 'uuid';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Divider from '@material-ui/core/Divider';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';

import { getCodeStructureColor } from '../../../reducers/dictionaries/CodeStructureColorReducer';
import { getCodeGarageTypes } from '../../../reducers/dictionaries/CodeGarageReducer';
import { handleFormChangeNew } from '../../../reducers/helpers/formHelpers';
import { checkFormType } from '../../helpers/checkFormState';
import RMSAutoComplete from 'components/RMSAutoComplete/RMSAutoComplete';
import RMSAttachments from 'components/RMSAttachments';
import {
  updateIncPropertyStructureForm,
  updateIncEvidenceItemForm,
} from '../../../reducers/IncReducer';

const validationContext = new SimpleSchema({
  bathroomQuantity: SimpleSchema.Integer,
  bedroomQuantity: SimpleSchema.Integer,
}).newContext();

const TabStructure = (props) => {
  /** region Component Variables and Props */
  const {
    updateIncPropertyStructureForm,
    updateIncEvidenceItemForm,
    codeStructureColors,
    getCodeStructureColor,
    codeGarageTypes,
    getCodeGarageTypes,
    propertyStructureForm,
    wsClient,
    history,
  } = props;
  const [changeKey, setChangeKey] = React.useState(0);
  const [pageType, setPageType] = React.useState('add');
  const [entityType, setEntityType] = React.useState('');
  const [firstLoad, setFirstLoad] = React.useState(true);
  const [errors, setErrors] = React.useState({});
  const [propertyStructureFormState, setPropertyStructureFormState] = React.useState({
    tempPropId: uuidv4(),
    isValid: false,
    changes: false,
    values: {},
    touched: {},
    errors: {},
    formType: 'edit',
  });
  /** endregion */
  /** region React Hooks */
  React.useEffect(() => {
    checkFormType(
      propertyStructureFormState.ptsStructureId !== undefined &&
        propertyStructureFormState.ptsStructureId !== null,
      setPropertyStructureFormState,
      history
    );

    const parts = history.location.pathname.split('/');
    let type = '';
    if (parts.length === 4) type = parts[parts.length - 2];
    if (parts.length === 5) type = parts[parts.length - 3];

    setEntityType(parts[1]);
    setPageType(type);
  }, []);

  React.useEffect(() => {
    const services = ['codeStructureColors'];

    if (!wsClient.websocket) return;

    services.forEach(async (svc) => {
      if (props[svc].length < 1) {
        const result = await wsClient.websocket.service(svc.toLowerCase()).find({
          query: {
            $limit: 0,
            $select: ['Code', 'Description'],
            $sort: {
              Description: 1,
            },
            IsDeleted: false,
          },
        });

        if (result.total > 0) {
          getCodeStructureColor(result.data);
        }
      }
    });
  }, [wsClient]);

  React.useEffect(() => {
    if (wsClient.websocket) {
      getCodeGarageTypes();
    }
  }, [wsClient, getCodeGarageTypes]);

  React.useEffect(() => {
    checkFormType(
      propertyStructureFormState.ptsStructureId !== undefined &&
        propertyStructureFormState.ptsStructureId !== null,
      setPropertyStructureFormState,
      history
    );
    setPropertyStructureFormState((propertyStructureFormState) => ({
      ...propertyStructureFormState,
      ...propertyStructureForm,
      values: {
        ...propertyStructureFormState.values,
        ...propertyStructureForm.values,
      },
    }));
  }, [propertyStructureForm]);

  React.useEffect(() => {
    if (
      !firstLoad &&
      (propertyStructureFormState.values?.bathroomQuantity !== '' ||
        propertyStructureFormState.values?.bedroomQuantity)
    ) {
      validationContext.validate({
        bathroomQuantity:
          propertyStructureFormState.values?.bathroomQuantity?.length > 0
            ? Number(propertyStructureFormState.values?.bathroomQuantity)
            : 0,
        bedroomQuantity:
          propertyStructureFormState.values?.bedroomQuantity?.length > 0
            ? Number(propertyStructureFormState.values?.bedroomQuantity)
            : 0,
      });

      if (validationContext.isValid() === false) {
        const errs = validationContext.validationErrors();
        let e = {};
        errs.map((er) => {
          // propertyStructureFormState.values[er.name] = '';
          e[er.name] = `Value must be ${er.dataType}`;
          console.log(e);
          setErrors(e);
        });
      } else {
        setErrors({});
      }
    }
    setFirstLoad(false);

    propertyStructureFormState.changes = true;
    updateIncPropertyStructureForm(propertyStructureFormState);

    if (propertyStructureFormState.values.isEvidence) {
      updateIncEvidenceItemForm({
        values: {
          description: `${propertyStructureFormState.values?.description || ''} ${
            propertyStructureFormState.values?.bedroomQuantity || ''
          } ${propertyStructureFormState.values?.primaryColor || ''}`,
          category: 'Property',
          incIncidentPropertyId: propertyStructureFormState.incIncidentPropertyId,
          offenses: [],
        },
      });
    }
  }, [
    propertyStructureFormState.values?.incidentId,
    propertyStructureFormState.values?.structureId,
    propertyStructureFormState.values?.structureAddress,
    propertyStructureFormState.values?.isResidence,
    propertyStructureFormState.values?.description,
    propertyStructureFormState.values?.bedroomQuantity,
    propertyStructureFormState.values?.bathroomQuantity,
    propertyStructureFormState.values?.primaryColor,
    propertyStructureFormState.values?.secondaryColor,
    propertyStructureFormState.values?.garageType,
    propertyStructureFormState.values?.notes,
    propertyStructureFormState.values?.isEvidence,
    propertyStructureFormState.values?.attachments,
  ]);

  React.useEffect(() => {
    if (!wsClient?.websocket) {
      return;
    }

    const getAttachments = async () => {
      const service = wsClient?.websocket.service('rms-attachments');
      service.timeout = 200000;

      const result = await service.find({
        query: {
          id:
            entityType === 'records'
              ? propertyStructureFormState.ptsStructureId
              : propertyStructureFormState.incIncidentPropertyId,
          type: entityType === 'records' ? 'STRUCTURE' : 'INCIDENTPROPERTY',
        },
      });
      let atts = [];
      result.map((r) => {
        atts.push({
          ptsAttachmentId: r.ptsAttachmentID,
          name: r.FileName,
          type: r.FileType,
          dataURL: r.FileObject,
        });
      });
      setPropertyStructureFormState((stateValue) => ({
        ...stateValue,
        values: {
          ...stateValue.values,
          attachments: atts,
        },
      }));
    };

    if (propertyStructureFormState.ptsStructureId) {
      getAttachments();
    }
  }, [wsClient, propertyStructureFormState.ptsStructureId]);
  /** endregion */
  return (
    <Grid item xs={12} lg={12} className="mt-2">
      {pageType === 'add' && entityType !== 'records' && (
        <Grid item xs={12} lg={12} className="mb-1">
          <FormControlLabel
            control={
              <Checkbox
                key={propertyStructureFormState.values?.isEvidence}
                checked={propertyStructureFormState.values?.isEvidence}
                onChange={(event) =>
                  handleFormChangeNew(
                    event,
                    event.target.checked,
                    'none',
                    'isEvidence',
                    setPropertyStructureFormState
                  )
                }
                name="isEvidence"
              />
            }
            label="Is Evidence?"
          />
        </Grid>
      )}
      <Grid container spacing={3}>
        <Grid item xs={10} lg={10}>
          <TextField
            id="standard-textarea-structure-description"
            autoComplete="hidden"
            size="small"
            label="Description"
            placeholder="Description"
            variant="outlined"
            name="description"
            key={changeKey}
            defaultValue={propertyStructureFormState.values?.description}
            onBlur={(event) =>
              handleFormChangeNew(
                event,
                event.target.value,
                'none',
                'description',
                setPropertyStructureFormState
              )
            }
            fullWidth
            multiline
            InputLabelProps={{ shrink: true }}
          />
        </Grid>
        <Grid item xs={2} lg={2}>
          <FormControlLabel
            control={
              <Checkbox
                key={propertyStructureFormState.values?.isResidence}
                checked={propertyStructureFormState.values?.isResidence}
                onChange={(event) =>
                  handleFormChangeNew(
                    event,
                    event.target.checked,
                    'none',
                    'isResidence',
                    setPropertyStructureFormState
                  )
                }
                name="isResidence"
              />
            }
            label="Is Residence"
          />
        </Grid>
      </Grid>
      <Grid xs={12} className="mb-3">
        <span style={{ color: '#fff', background: '#1976d2' }} className="mb-1 badge badge-primary">
          structure Details
        </span>
        <Divider />
      </Grid>
      <Grid container spacing={2} justify="center" className="mb-2">
        <Grid item xs={4} lg={4}>
          <TextField
            id="standard-textarea-structure-bedroomQuantity"
            hint="test"
            autoComplete="hidden"
            size="small"
            error={errors['bedroomQuantity'] ? true : false}
            helperText={errors['bedroomQuantity']}
            label="Bedroom Quantity"
            placeholder="Bedroom Quantity"
            variant="outlined"
            name="bedroomQuantity"
            key={changeKey}
            value={propertyStructureFormState.values?.bedroomQuantity || ''}
            onChange={(event) =>
              handleFormChangeNew(
                event,
                event.target.value,
                'none',
                'bedroomQuantity',
                setPropertyStructureFormState
              )
            }
            fullWidth
            multiline
            InputLabelProps={{ shrink: true }}
          />
        </Grid>
        <Grid item xs={4} lg={4}>
          <TextField
            id="standard-textarea-structure-bathroomQuantity"
            autoComplete="hidden"
            size="small"
            error={errors['bathroomQuantity'] ? true : false}
            helperText={errors['bathroomQuantity']}
            label="Bathroom Quantity"
            placeholder="Bathroom Quantity"
            variant="outlined"
            name="bathroomQuantity"
            key={changeKey}
            value={propertyStructureFormState.values?.bathroomQuantity || ''}
            onChange={(event) =>
              handleFormChangeNew(
                event,
                event.target.value,
                'none',
                'bathroomQuantity',
                setPropertyStructureFormState
              )
            }
            fullWidth
            multiline
            InputLabelProps={{ shrink: true }}
          />
        </Grid>
        <Grid item xs={4} lg={4}>
          <RMSAutoComplete
            size="small"
            autoComplete
            autoSelect
            autoHighlight
            id="combo-structure-primary-color"
            options={codeStructureColors}
            label="Primary Color"
            serviceName="codeStructureColors"
            title="Description"
            stateHolderAttribute="primaryColor"
            variant="outlined"
            stateHolder={propertyStructureFormState}
            setStateHolder={setPropertyStructureFormState}
            setField={handleFormChangeNew}
          />
        </Grid>
      </Grid>
      <Grid container spacing={2} justify="center" className="mb-1">
        <Grid item xs={6} lg={6}>
          <RMSAutoComplete
            size="small"
            autoComplete
            autoSelect
            autoHighlight
            id="combo-structure-secondary-color"
            options={codeStructureColors}
            label="Secondary Color"
            serviceName="codestructurecolors"
            title="Description"
            stateHolderAttribute="secondaryColor"
            variant="outlined"
            stateHolder={propertyStructureFormState}
            setStateHolder={setPropertyStructureFormState}
            setField={handleFormChangeNew}
          />
        </Grid>
        <Grid item xs={6} lg={6}>
          <RMSAutoComplete
            size="small"
            autoComplete
            autoSelect
            autoHighlight
            id="combo-structure-garage-type"
            options={codeGarageTypes}
            label="Garage Type"
            serviceName="codegaragetypes"
            title="Code"
            stateHolderAttribute="garageType"
            variant="outlined"
            stateHolder={propertyStructureFormState}
            setStateHolder={setPropertyStructureFormState}
            setField={handleFormChangeNew}
          />
        </Grid>
      </Grid>
      <Grid item xs={12} lg={12}>
        <span style={{ color: '#fff', background: '#1976d2' }} className="m-1 badge badge-primary">
          Notes
        </span>
        <Divider />
      </Grid>
      <Grid container justify="center" className="mb-3">
        <Grid item xs={12} lg={12}>
          <TextField
            id="standard-textarea-structure-notes"
            autoComplete="hidden"
            size="small"
            className="mt-3"
            label="Notes"
            placeholder="Notes"
            rows="4"
            name="notes"
            key={changeKey}
            defaultValue={propertyStructureFormState.values?.notes}
            onBlur={(event) =>
              handleFormChangeNew(
                event,
                event.target.value,
                'notes',
                'notes',
                setPropertyStructureFormState
              )
            }
            fullWidth
            variant="outlined"
            multiline
            InputLabelProps={{ shrink: true }}
          />
        </Grid>
      </Grid>
      <RMSAttachments
        attachments={propertyStructureFormState.values.attachments}
        stateHolder={propertyStructureFormState}
        setStateHolder={setPropertyStructureFormState}
        setField={handleFormChangeNew}
      />
    </Grid>
  );
};

const mapStateToProps = (state) => ({
  wsClient: state.websocket,
  propertyStructureForm: state.incident.propertyStructureForm,
  codeStructureColors: state.codeStructureColor,
  codeGarageTypes: state.codesGarage.codeGarageTypes,
});

export default connect(mapStateToProps, {
  updateIncPropertyStructureForm,
  updateIncEvidenceItemForm,
  getCodeStructureColor,
  getCodeGarageTypes,
})(TabStructure);
