import React from 'react';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import { connect } from 'react-redux';
import { updateIncOffensesDVAdditionalForm } from '../../../../reducers/IncReducer';
import { handleFormChangeNew } from '../../../../reducers/helpers/formHelpers';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

const RestrainingOrderForm = (props) => {
  const {
    offensesDVAdditionalForm,
    updateIncOffensesDVAdditionalForm,
    dvAdditionals,
    selectedVictim,
  } = props;

  const [offensesDVAdditionalFormState, setOffensesDVAdditionalFormState] = React.useState({
    isValid: false,
    changes: true,
    values: {},
    touched: {},
    errors: {},
  });

  React.useEffect(() => {
    async function fetch() {
      let dvAdditionalDetails = dvAdditionals.find(
        (dvAdditional) => dvAdditional.ptsIncPersonId === selectedVictim
      );
      if (dvAdditionalDetails) {
        await updateIncOffensesDVAdditionalForm(dvAdditionalDetails);
        await setOffensesDVAdditionalFormState(dvAdditionalDetails);
      }
    }
    // Fetching current selected victim's dv details
    fetch();
  }, []);

  React.useEffect(() => {
    setOffensesDVAdditionalFormState({
      ...offensesDVAdditionalFormState,
      values: { ...offensesDVAdditionalFormState.values, ...offensesDVAdditionalForm.values },
    });
  }, [offensesDVAdditionalForm]);

  React.useEffect(() => {
    offensesDVAdditionalFormState.changes = true;
    updateIncOffensesDVAdditionalForm(offensesDVAdditionalFormState);
  }, [
    offensesDVAdditionalFormState.values.IsRestrainingOrder,
    offensesDVAdditionalFormState.values.Status,
    offensesDVAdditionalFormState.values.Type,
    offensesDVAdditionalFormState.values.IssuingCourt,
    offensesDVAdditionalFormState.values.DocketNumber,
    offensesDVAdditionalFormState.values.IsIncidentViolationOfProtectiveOrder,
    offensesDVAdditionalFormState.values.IsProtectiveOrderFiledAfterIncident,
  ]);

  return (
    <Grid container spacing={3} justify="center">
      <Grid item>
        <FormControlLabel
          control={
            <Checkbox
              key={offensesDVAdditionalFormState.values?.IsRestrainingOrder || false}
              checked={offensesDVAdditionalFormState.values?.IsRestrainingOrder || false}
              onChange={(event) =>
                handleFormChangeNew(
                  event,
                  event.target.checked,
                  'none',
                  'IsRestrainingOrder',
                  setOffensesDVAdditionalFormState
                )
              }
              name="IsRestrainingOrder"
            />
          }
          label="Restraining Order"
        />
      </Grid>
      <Grid item xs={10} lg={10}>
        <TextField
          autoComplete="hidden"
          size="small"
          id="standard-textarea-status"
          label="Status"
          placeholder="Status"
          variant="outlined"
          name="Status"
          value={offensesDVAdditionalFormState.values?.Status || ''}
          onChange={(event) =>
            handleFormChangeNew(
              event,
              event.target.value,
              'none',
              'Status',
              setOffensesDVAdditionalFormState
            )
          }
          fullWidth
          multiline
        />
      </Grid>
      <Grid item xs={10} lg={10}>
        <TextField
          autoComplete="hidden"
          size="small"
          id="standard-textarea-type"
          label="Type"
          placeholder="Type"
          variant="outlined"
          name="Type"
          value={offensesDVAdditionalFormState.values?.Type || ''}
          onChange={(event) =>
            handleFormChangeNew(
              event,
              event.target.value,
              'none',
              'Type',
              setOffensesDVAdditionalFormState
            )
          }
          fullWidth
          multiline
        />
      </Grid>
      <Grid item xs={10} lg={10}>
        <TextField
          autoComplete="hidden"
          size="small"
          id="standard-textarea-issuing-court"
          label="Issuing Court"
          placeholder="Issuing Court"
          variant="outlined"
          name="IssuingCourt"
          value={offensesDVAdditionalFormState.values?.IssuingCourt || ''}
          onChange={(event) =>
            handleFormChangeNew(
              event,
              event.target.value,
              'none',
              'IssuingCourt',
              setOffensesDVAdditionalFormState
            )
          }
          fullWidth
          multiline
        />
      </Grid>
      <Grid item xs={10} lg={10}>
        <TextField
          autoComplete="hidden"
          size="small"
          id="standard-textarea-order-or-docket-number"
          label="Order or Docket Number"
          placeholder="Order or Docket Number"
          variant="outlined"
          name="DocketNumber"
          value={offensesDVAdditionalFormState.values?.DocketNumber || ''}
          onChange={(event) =>
            handleFormChangeNew(
              event,
              event.target.value,
              'none',
              'DocketNumber',
              setOffensesDVAdditionalFormState
            )
          }
          fullWidth
          multiline
        />
      </Grid>
      <FormControlLabel
        control={
          <Checkbox
            key={
              offensesDVAdditionalFormState.values?.IsIncidentViolationOfProtectiveOrder || false
            }
            checked={
              offensesDVAdditionalFormState.values?.IsIncidentViolationOfProtectiveOrder || false
            }
            onChange={(event) =>
              handleFormChangeNew(
                event,
                event.target.checked,
                'none',
                'IsIncidentViolationOfProtectiveOrder',
                setOffensesDVAdditionalFormState
              )
            }
            name="IsIncidentViolationOfProtectiveOrder"
          />
        }
        label="Is Incident Violation Of Protective Order?"
      />

      <FormControlLabel
        control={
          <Checkbox
            key={offensesDVAdditionalFormState.values?.IsProtectiveOrderFiledAfterIncident || false}
            checked={
              offensesDVAdditionalFormState.values?.IsProtectiveOrderFiledAfterIncident || false
            }
            onChange={(event) =>
              handleFormChangeNew(
                event,
                event.target.checked,
                'none',
                'IsProtectiveOrderFiledAfterIncident',
                setOffensesDVAdditionalFormState
              )
            }
            name="IsProtectiveOrderFiledAfterIncident"
          />
        }
        label="Protective Order Filed After Incident?"
      />
    </Grid>
  );
};

const mapStateToProps = (state) => ({
  offensesDVAdditionalForm: state.incident.offensesDVAdditionalForm,
  selectedVictim: state.incident.selectedVictim,
  dvAdditionals: state.incident.dvAdditionals,
});

export default connect(mapStateToProps, {
  updateIncOffensesDVAdditionalForm,
})(RestrainingOrderForm);
