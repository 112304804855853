import React from 'react';
import FolderSharedIcon from '@material-ui/icons/FolderShared';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { Fab, Tooltip, Badge } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  badgeColor: {
    background: '#ff7373',
  },
  button: {
    backgroundColor: theme.palette.info.dark,
    color: theme.palette.common.white,
    '&:hover': {
      backgroundColor: theme.palette.info.dark,
      boxShadow: 'none',
    },
    '&:active': {
      boxShadow: 'none',
      backgroundColor: theme.palette.info.dark,
    },
    cursor: 'pointer',
  },
}));

export default function RMSSelectFabButton(props) {
  const classes = useStyles();
  const { onClick, listCount } = props;

  return (
    <Badge
      classes={{
        anchorOriginTopRightRectangle: classes.badge,
        colorSecondary: classes.badgeColor,
      }}
      badgeContent={listCount}
      color="secondary">
      <Tooltip title="Add From Properties">
        <Fab style={{ marginLeft: '25px' }} size="small" color="secondary" aria-label="add">
          <FolderSharedIcon onClick={onClick} />
        </Fab>
      </Tooltip>
    </Badge>
  );
}
