import React, { useState } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation, useParams } from 'react-router';

import { Grid, makeStyles, TextField, Paper } from '@material-ui/core';

import RMSAutoComplete from 'components/RMSAutoComplete/RMSAutoComplete';
import RMSFabButtons from 'components/RMSForms/RMSIncidentForm/RMSFabButtons';
import { handleFormChangeNew } from 'reducers/helpers/formHelpers';
import { getCodeNameSuffixes } from 'reducers/dictionaries/CodeNameSuffixesReducer';
import { getCodeNamePrefixes } from 'reducers/dictionaries/CodeNamePrefixesReducer';
import { upsertRecord } from 'reducers/RecordsReducer';
import { setCircularLoading } from 'reducers/ui/UiMenuReducer';

const useStyles = makeStyles(() => ({
  root: {
    height: window.innerHeight - 90 + 'px',
    borderRadius: '0.5rem',
    padding: '15px',
  },
  form: {
    width: '100%',
  },
  input: {
    width: '100%',
    padding: '10px',
  },
  btnStyle: {
    display: 'block',
    width: '150px',
    padding: '10px',
    marginTop: '15px',
    marginLeft: 'auto',
    position: 'relative',
    right: 10,
  },
}));
const PersonNameForm = (props) => {
  /** region props and variables */
  const {
    wsClient,
    getCodeNameSuffixes,
    codeNameSuffixes,
    getCodeNamePrefixes,
    codeNamePrefixes,
    upsertRecord,
  } = props;
  const classes = useStyles();
  let data = useSelector((state) => state.records.formData);
  const location = useLocation();
  const actionType = location.pathname.split('/')[5];
  const { persondId } = useParams();
  const history = useHistory();
  const dispatch = useDispatch();
  if (actionType === 'add') {
    data = {};
  }
  const [formData, setFormData] = useState({
    values: {
      ptsPersonID: persondId,
      ptsNameID: data?.ptsNameID || undefined,
      LastName: data?.LastName || '',
      FirstName: data?.FirstName || '',
      MiddleName: data?.MiddleName || '',
      Suffix: data?.Suffix || '',
      Prefix: data?.Prefix || '',
    },
  });
  /** end region */

  /**region lifecycle hooks */
  React.useEffect(() => {
    loadCodeData();
  }, [wsClient]);
  /** end region */

  /** region helper functions*/
  const loadCodeData = async () => {
    if (!wsClient.websocket) return;

    const result = await wsClient.websocket.service('codenamesuffixes').find({
      query: {
        $limit: 0,
        $select: ['Code', 'Description'],
        $sort: {
          Description: 1,
        },
        IsDeleted: false,
      },
    });
    if (result.total > 0) {
      getCodeNameSuffixes(result.data);
    }

    const result2 = await wsClient.websocket.service('codenameprefixes').find({
      query: {
        $limit: 0,
        $select: ['Code', 'Description'],
        $sort: {
          Description: 1,
        },
        IsDeleted: false,
      },
    });
    if (result2.total > 0) {
      getCodeNamePrefixes(result2.data);
    }
  };

  const handleSave = () => {
    if (!formData.values.ptsNameID) {
      upsertRecord('name', formData.values, 'add').finally(() => handleFinally());
    } else {
      upsertRecord('name', formData.values, 'edit').finally(() => handleFinally());
    }
  };

  const handleFinally = () => {
    dispatch(setCircularLoading(false));
    history.goBack();
  };
  /** end region */

  return (
    <Paper className={classes.root}>
      <form>
        <Grid container spacing={2}>
          <Grid item xs={4}>
            <TextField
              id="standard-textarea-person-lastName"
              autoComplete="hidden"
              size="small"
              placeholder="Last Name"
              label="Last Name"
              variant="outlined"
              name="LastName"
              defaultValue={formData.values?.LastName || ''}
              onBlur={(event) =>
                handleFormChangeNew(event, event.target.value, 'none', 'LastName', setFormData)
              }
              fullWidth
              multiline
              InputLabelProps={{ shrink: true }}
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              id="standard-textarea-person-firstName"
              autoComplete="hidden"
              size="small"
              placeholder="First Name"
              label="First Name"
              variant="outlined"
              name="FirstName"
              defaultValue={formData.values?.FirstName || ''}
              onBlur={(event) =>
                handleFormChangeNew(event, event.target.value, 'none', 'FirstName', setFormData)
              }
              fullWidth
              multiline
              InputLabelProps={{ shrink: true }}
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              id="standard-textarea-person-middleName"
              autoComplete="hidden"
              size="small"
              placeholder="Middle Name"
              label="Middle Name"
              variant="outlined"
              name="MiddleName"
              defaultValue={formData.values.MiddleName || ''}
              onBlur={(event) =>
                handleFormChangeNew(event, event.target.value, 'none', 'MiddleName', setFormData)
              }
              fullWidth
              multiline
              InputLabelProps={{ shrink: true }}
            />
          </Grid>
          <Grid item xs={4}>
            <RMSAutoComplete
              size="small"
              autoComplete
              autoSelect
              autoHighlight
              id="combo-search-suffix"
              options={codeNameSuffixes}
              label="Suffix"
              serviceName="codenamesuffixes"
              variant="outlined"
              title={'Code'}
              stateHolder={formData}
              setStateHolder={setFormData}
              setField={handleFormChangeNew}
              stateHolderAttribute="Suffix"
            />
          </Grid>

          <Grid item xs={4}>
            <RMSAutoComplete
              size="small"
              autoComplete
              autoSelect
              autoHighlight
              id="combo-search-prefix"
              options={codeNamePrefixes}
              label="Prefix"
              serviceName="codenameprefixes"
              variant="outlined"
              title={'Code'}
              stateHolder={formData}
              setStateHolder={setFormData}
              setField={handleFormChangeNew}
              stateHolderAttribute="Prefix"
            />
          </Grid>
        </Grid>
      </form>
      <RMSFabButtons recordForm={true} handleSaveRecord={handleSave} />
    </Paper>
  );
};
const mapStateToProps = (state) => ({
  wsClient: state.websocket,
  codeNameSuffixes: state.codeNameSuffixes,
  codeNamePrefixes: state.codeNamePrefixes,
});

export default connect(mapStateToProps, { getCodeNameSuffixes, getCodeNamePrefixes, upsertRecord })(
  PersonNameForm
);
