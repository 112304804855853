import React from 'react';
import RMSSimpleList from '../../components/RMSList/RMSSimpleList';
import Grid from '@material-ui/core/Grid';
import RMSSelectButton from '../../components/RMSButtons/RMSSelectButton';
import Avatar from '@material-ui/core/Avatar';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { colors } from '../../config/colorVariables';
import clsx from 'clsx';
import PerfectScrollbar from 'react-perfect-scrollbar';
import RMSAddButton from '../../components/RMSButtons/RMSAddButton';
import { connect } from 'react-redux';
import {
  setHandleAddIncSubject,
  setHandleOffensesRedirectFormClose,
} from '../../reducers/ui/UiMenuReducer';
import {
  setListSubjects,
  setSelectedSubject,
  removeIncSubjectDetails,
  updateIncPartiesPersonForm,
  setIncSubjectsDetails,
} from '../../reducers/IncReducer';
import Tooltip from '@material-ui/core/Tooltip';
import DialogList from '../../components/RMSList/RMSDialogList';

const useStyles = makeStyles((theme) => ({
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: colors.cyan,
    marginRight: theme.spacing(2),
  },
  female: {
    margin: theme.spacing(1),
    backgroundColor: colors.pink,
    marginRight: theme.spacing(2),
  },
  offender: {
    margin: theme.spacing(1),
    backgroundColor: colors.red,
    marginRight: theme.spacing(2),
  },
}));

export const OffenderAvatar = (props) => {
  const { label } = props;
  const classes = useStyles();

  return (
    <Avatar
      className={clsx(
        label === 'M' && classes.avatar,
        label === 'F' && classes.female,
        label === 'O' && classes.offender
      )}>
      {label}
    </Avatar>
  );
};

const TabOffenders = (props) => {
  /** region Component Variables and Props */
  const {
    history,
    incSubjects,
    listSubjects,
    listDialogSubjects,
    parties,
    selectedSubject,
    setHandleOffensesRedirectFormClose,
    setHandleAddIncSubject,
    setListSubjects,
    setSelectedSubject,
    updateIncPartiesPersonForm,
  } = props;
  const [changeKey, setChangeKey] = React.useState(0);
  const [dialogBox, setDialogBox] = React.useState(false);
  /** endregion */
  /** region React Hooks */
  React.useEffect(() => {
    setHandleOffensesRedirectFormClose(false);
    createIncSubjectList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [incSubjects, parties, incSubjects.length]);
  /** endregion */
  /** region Helper Functions */

  const createIncSubjectList = () => {
    if (incSubjects !== undefined && incSubjects.length > 0) {
      let newList = [];
      let subject = {};

      incSubjects.forEach((s) => {
        let party;
        if (!s.incSubjectDetails.ptsIncPersonId) {
          party = parties.find((x) => x.tempPartyId === s.incSubjectDetails.tempPartyId);
        } else {
          party = parties.find((x) => x.ptsIncPersonId === s.incSubjectDetails.ptsIncPersonId);
        }

        if (party) {
          subject = {
            id: party?.ptsIncPersonId || party?.personDetail?.tempPartyId,
            name: `${party?.personDetail?.values.firstName}${
              party?.personDetail?.values.middleName
                ? ` ${party?.personDetail?.values.middleName} `
                : ' '
            }${party?.personDetail?.values.lastName}`,
            icon: (
              <OffenderAvatar
                label={
                  party?.personDetail?.values.sex === 'MALE'
                    ? 'M'
                    : party?.personDetail?.values.sex === 'FEMALE'
                    ? 'F'
                    : 'U'
                }
              />
            ),
            age: party?.personDetail?.values.age,
            ethnicity: party?.personDetail?.values.ethnicity,
            type: 'person',
          };
          newList.push(subject);
        }
      });
      setListSubjects(newList);
      setChangeKey(newList.length);
    }
  };
  const handleIncSubjectAdd = () => {
    setHandleOffensesRedirectFormClose(true);
    setHandleAddIncSubject(true);
    updateIncPartiesPersonForm({ isValid: false, values: {}, touched: {}, errors: {} });
    history.push('/incidents/add/offenders');
  };

  const handleIncSubjectSelect = () => {
    if (listDialogSubjects.length > 0) {
      setDialogBox(true);
    }
  };

  const handleIncSubjectDelete = () => {
    let updatedList = listSubjects.filter((s) => s.id !== selectedSubject);
    setListSubjects(updatedList);
    removeIncSubjectDetails();
    setSelectedSubject(updatedList[0] ? updatedList[0].id : '');
  };

  const closeDialogBox = () => {
    createIncSubjectList();
    setDialogBox(false);
  };

  return (
    <Grid container>
      <Grid container spacing={2} className="mb-3">
        <Grid item className="mr-2 ml-2">
          <Tooltip title="Add Offenders">
            <RMSAddButton onClick={handleIncSubjectAdd} />
          </Tooltip>
        </Grid>

        <Grid item className="mr-1">
          <DialogList
            dialogBox={dialogBox}
            title="Incident Offender List"
            type="offenders"
            list={[]}
            addToSimpleList={createIncSubjectList}
            closeDialogBox={closeDialogBox}></DialogList>

          <Tooltip title="Select Offenders from Parties">
            <RMSSelectButton lists={listDialogSubjects} onClick={handleIncSubjectSelect} />
          </Tooltip>
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={12} lg={12}>
          <PerfectScrollbar style={{ height: '99%' }} className="scroll-area-lg">
            <RMSSimpleList
              key={changeKey}
              listItems={listSubjects}
              entity="offenders"
              deleteFromList={handleIncSubjectDelete}
            />
          </PerfectScrollbar>
        </Grid>
      </Grid>
    </Grid>
  );
};

const mapStateToProps = (state) => ({
  incSubjects: state.incident.incSubjects,
  listSubjects: state.incident.listSubjects,
  listDialogSubjects: state.incident.listDialogSubjects,
  parties: state.incident.parties,
  selectedSubject: state.incident.selectedSubject,
  partiesPersonForm: state.incident.partiesPersonForm,
  wsClient: state.websocket.websocket,
  selectedEntity: state.incident.selectedEntity,
  selectedIncident: state.incident.ptsIncidentId,
});

export default connect(mapStateToProps, {
  setHandleOffensesRedirectFormClose,
  setHandleAddIncSubject,
  setListSubjects,
  setSelectedSubject,
  removeIncSubjectDetails,
  updateIncPartiesPersonForm,
  setIncSubjectsDetails,
})(TabOffenders);
