import React from 'react';
import {
  BiasMotivationsForm,
  CriminalActivityForm,
  OffenderSuspectedOfUsingForm,
  OffenseDetailsForm,
  TypeWeaponForm,
} from '../../../RMSForms/RMSOffensesForm';
import BlurOnIcon from '@material-ui/icons/BlurOn';
import { Divider, Grid } from '@material-ui/core';

const panelItems = [
  {
    name: 'panel1',
    title: 'Offense Details',
    details: <OffenseDetailsForm />,
    icon: <BlurOnIcon />,
  },
  {
    name: 'panel2',
    title: 'Criminal Activity / Gang Information',
    details: <CriminalActivityForm />,
    icon: <BlurOnIcon />,
  },
  {
    name: 'panel3',
    title: 'Offender Suspected of Using',
    details: <OffenderSuspectedOfUsingForm />,
    icon: <BlurOnIcon />,
  },
  {
    name: 'panel4',
    title: 'Bias Motivation',
    details: <BiasMotivationsForm />,
    icon: <BlurOnIcon />,
  },
  {
    name: 'panel5',
    title: 'Type Weapon / Force Involved',
    details: <TypeWeaponForm />,
    icon: <BlurOnIcon />,
  },
];

const IncOffensesPanels = () => {
  return (
    <div style={{ padding: '0px 15px' }}>
      <Grid xs={12} className="mb-3">
        <span style={{ color: '#fff', background: '#1976d2' }} className="mb-1 badge badge-primary">
          Offense Details
        </span>
        <Divider />
      </Grid>
      <OffenseDetailsForm />
      <Grid xs={12} className="mt-2">
        <span style={{ color: '#fff', background: '#1976d2' }} className="mb-1 badge badge-primary">
          Criminal Activity
        </span>
        <Divider />
      </Grid>
      <CriminalActivityForm />
      <BiasMotivationsForm />
      <Grid xs={12} className="mt-2">
        <span style={{ color: '#fff', background: '#1976d2' }} className="mb-1 badge badge-primary">
          Weapon Details
        </span>
        <Divider />
      </Grid>
      <TypeWeaponForm />
      <Grid xs={12} className="mt-2">
        <span style={{ color: '#fff', background: '#1976d2' }} className="mb-1 badge badge-primary">
          Others
        </span>
        <Divider />
      </Grid>
      <OffenderSuspectedOfUsingForm />
    </div>
  );
};

export default IncOffensesPanels;
