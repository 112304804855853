import React from 'react';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import { connect } from 'react-redux';
import { updateIncOffensesDVAdditionalForm } from '../../../../reducers/IncReducer';
import Checkbox from '@material-ui/core/Checkbox';
import { handleFormChangeNew } from '../../../../reducers/helpers/formHelpers';
import FormControlLabel from '@material-ui/core/FormControlLabel';

const WitnessForm = (props) => {
  const {
    offensesDVAdditionalForm,
    updateIncOffensesDVAdditionalForm,
    dvAdditionals,
    selectedVictim,
  } = props;

  const [offensesDVAdditionalFormState, setOffensesDVAdditionalFormState] = React.useState({
    isValid: false,
    changes: true,
    values: {},
    touched: {},
    errors: {},
  });

  React.useEffect(() => {
    async function fetch() {
      let dvAdditionalDetails = dvAdditionals.find(
        (dvAdditional) => dvAdditional.ptsIncPersonId === selectedVictim
      );
      if (dvAdditionalDetails) {
        await updateIncOffensesDVAdditionalForm(dvAdditionalDetails);
        await setOffensesDVAdditionalFormState(dvAdditionalDetails);
      }
    }
    // Fetching current selected victim's dv details
    fetch();
  }, []);

  React.useEffect(() => {
    setOffensesDVAdditionalFormState({
      ...offensesDVAdditionalFormState,
      values: { ...offensesDVAdditionalFormState.values, ...offensesDVAdditionalForm.values },
    });
  }, [offensesDVAdditionalForm]);

  React.useEffect(() => {
    offensesDVAdditionalFormState.changes = true;
    updateIncOffensesDVAdditionalForm(offensesDVAdditionalFormState);
  }, [
    offensesDVAdditionalFormState.values.IsWitnessesPresentDuringViolence,
    offensesDVAdditionalFormState.values.IsStatementTaken,
    offensesDVAdditionalFormState.values.IschildrenPresentDuringViolence,
    offensesDVAdditionalFormState.values.WitnessInformation,
  ]);

  return (
    <Grid container spacing={3} justify="center">
      <FormControlLabel
        control={
          <Checkbox
            key={offensesDVAdditionalFormState.values?.IsWitnessesPresentDuringViolence}
            checked={offensesDVAdditionalFormState.values?.IsWitnessesPresentDuringViolence}
            onChange={(event) =>
              handleFormChangeNew(
                event,
                event.target.checked,
                'none',
                'IsWitnessesPresentDuringViolence',
                setOffensesDVAdditionalFormState
              )
            }
            name="IsWitnessesPresentDuringViolence"
          />
        }
        label="Witnesses Present During Violence?"
      />

      <FormControlLabel
        control={
          <Checkbox
            key={offensesDVAdditionalFormState.values?.IsStatementTaken}
            checked={offensesDVAdditionalFormState.values?.IsStatementTaken}
            onChange={(event) =>
              handleFormChangeNew(
                event,
                event.target.checked,
                'none',
                'IsStatementTaken',
                setOffensesDVAdditionalFormState
              )
            }
            name="IsStatementTaken"
          />
        }
        label="Statement(s) Taken?"
      />

      <FormControlLabel
        control={
          <Checkbox
            key={offensesDVAdditionalFormState.values?.IsChildrenPresentDuringViolence}
            checked={offensesDVAdditionalFormState.values?.IsChildrenPresentDuringViolence}
            onChange={(event) =>
              handleFormChangeNew(
                event,
                event.target.checked,
                'none',
                'IsChildrenPresentDuringViolence',
                setOffensesDVAdditionalFormState
              )
            }
            name="IsChildrenPresentDuringViolence"
          />
        }
        label="Children Present During Violence?"
      />

      <Grid container justify="center" className="mb-4">
        <Grid item xs={10} lg={10}>
          <TextField
            autoComplete="hidden"
            size="small"
            className="mt-3"
            id="outlined-multiline-static-witness-information"
            label="Witness Information"
            name="WitnessInformation"
            multiline
            rows="4"
            fullWidth
            defaultValue=""
            variant="outlined"
            value={offensesDVAdditionalFormState.values?.WitnessInformation || ''}
            onChange={(event) =>
              handleFormChangeNew(
                event,
                event.target.value,
                'none',
                'WitnessInformation',
                setOffensesDVAdditionalFormState
              )
            }
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

const mapStateToProps = (state) => ({
  offensesDVAdditionalForm: state.incident.offensesDVAdditionalForm,
  selectedVictim: state.incident.selectedVictim,
  dvAdditionals: state.incident.dvAdditionals,
});

export default connect(mapStateToProps, {
  updateIncOffensesDVAdditionalForm,
})(WitnessForm);
