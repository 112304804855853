import React, { Component, Fragment } from 'react';

import clsx from 'clsx';
import { Editor } from 'react-draft-wysiwyg';
import { EditorState, convertToRaw, ContentState, convertFromHTML } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import TextField from '@material-ui/core/TextField';
import { withStyles } from '@material-ui/styles';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import PropTypes from 'prop-types';

const useStyles = (theme) => ({
  toolbarClassName:
    theme.palette.type === 'light'
      ? {
          color: theme.palette.secondary.main,
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: '#424242',
          border: '1px solid #ddd',
        },
  editorClassName: {
    border: '1px solid #ddd',
    height: '200px',
    padding: '0px 20px',
    overflow: 'hidden',
    borderRadius: '5px',
    marginTop: '15px',
  },
});

class RMSEditor extends Component {
  constructor(props) {
    super(props);
    this.props = props;
    const html = props.narrativeBody ? props.narrativeBody : '';

    const blocksFromHTML = convertFromHTML(html);
    const content = ContentState.createFromBlockArray(
      blocksFromHTML.contentBlocks,
      blocksFromHTML.entityMap
    );
    this.state = {
      editorState: EditorState.createWithContent(content),
    };

    if (props.narrativeBody) {
      const contentBlock = htmlToDraft(props.narrativeBody);
      if (contentBlock) {
        const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
        const editorState = EditorState.createWithContent(contentState);
        this.setState({ editorState: editorState });
      }
    }
  }

  onEditorStateChange: Function = (editorState) => {
    this.setState({
      editorState,
    });
    this.props.setNarrativeBodyState(draftToHtml(convertToRaw(editorState.getCurrentContent())));
  };

  render() {
    const { classes } = this.props;

    const { editorState } = this.state;
    return (
      <Fragment>
        <Editor
          editorState={editorState}
          wrapperClassName="rms-wrapper"
          onEditorStateChange={this.onEditorStateChange}
          toolbarClassName={classes.toolbarClassName}
          editorClassName={classes.editorClassName}
          placeholder="Narrative Body"
        />
        <div className={clsx('divider' && 'my-4')} />
        <TextField
          autoComplete="hidden"
          size="large"
          type="textarea"
          hidden
          value={draftToHtml(convertToRaw(editorState.getCurrentContent()))}
        />
      </Fragment>
    );
  }
}

RMSEditor.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(useStyles)(RMSEditor);
