import React, { Fragment } from 'react';
import { Droppable } from 'react-beautiful-dnd';
import Paper from '@material-ui/core/Paper';
import DraggableTasks from './DraggableTasks';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import grey from '@material-ui/core/colors/grey';

const useStyles = (props) => ({
  root: {
    height: '100vh',
    minWidth: '17vw',
    margin: '10px',
    padding: '10px',
    backgroundColor: grey[100],
  },
  columnHeading: {
    fontWeight: 'bold',
    backgroundColor: (props) => props.color,
    borderRadius: '50px',
    color: 'white',
    margin: '10px',
  },
});

class InnerList extends React.Component {
  shouldComponentUpdate(nextProps) {
    if (nextProps.tasks === this.props.tasks) {
      return false;
    }
    return true;
  }

  render() {
    const { tasks, color } = this.props;
    return tasks.map((task, index) => (
      <Grid item key={task.id}>
        <DraggableTasks color={color} key={task.id} task={task} index={index} />
      </Grid>
    ));
  }
}

class DroppableColumn extends React.Component {
  render() {
    const { column, tasks, classes, color } = this.props;
    return (
      <Droppable droppableId={column.id} type="task">
        {(provided, snapshot) => (
          <Fragment>
            <Typography className={classes.columnHeading}>{column.title}</Typography>
            <Paper
              className={classes.root}
              innerRef={provided.innerRef}
              {...provided.droppableProps}
              isDraggingOver={snapshot.isDraggingOver}>
              <InnerList tasks={tasks} color={color} />
              {provided.placeholder}
            </Paper>
          </Fragment>
        )}
      </Droppable>
    );
  }
}

export default withStyles(useStyles)(DroppableColumn);
