/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import Grid from '@material-ui/core/Grid';
import { connect } from 'react-redux';
import { updateIncOffensesOffenseForm, updateIncOffenses } from '../../../../reducers/IncReducer';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import { codeCriminalActivity } from '../../../../reducers/_dev/codeCriminalActivity';
import { handleFormChangeNew } from '../../../../reducers/helpers/formHelpers';
import { validateOffenseForm } from 'simpl-schema-validation/ValidateForms/offense';

const CriminalActivityForm = (props) => {
  /** region Component Variables and Props */
  const { offensesOffenseForm, updateIncOffensesOffenseForm, updateIncOffenses } = props;
  /** endregion */
  /** region React Hooks */
  const [errors, setErrors] = React.useState({});
  const [changeKey, setChangeKey] = React.useState(0);
  const [offensesOffenseFormState, setOffensesOffenseFormState] = React.useState({
    isValid: false,
    changes: false,
    values: {
      isGangInvolved: false,
    },
    touched: {},
    errors: {},
  });
  const [criminalActivityState, setCriminalActivityState] = React.useState([]);

  React.useEffect(() => {
    let arr = codeCriminalActivity.map((s) => s.description).filter((s) => s !== '');
    setCriminalActivityState(arr.sort());
  }, []);

  React.useEffect(() => {
    setOffensesOffenseFormState({
      ...offensesOffenseFormState,
      ...offensesOffenseForm,
      ptsOffenseId: offensesOffenseForm.ptsOffenseId,
      values: { ...offensesOffenseFormState.values, ...offensesOffenseForm.values },
    });
  }, [offensesOffenseForm]);

  React.useEffect(() => {
    let timeoutId;
    offensesOffenseFormState.changes = true;
    updateIncOffenses(offensesOffenseFormState);
    updateIncOffensesOffenseForm(offensesOffenseFormState);

    if (offensesOffenseFormState.values.statuteDetails) {
      timeoutId = setTimeout(() => {
        let errorStatus = errors;
        let nibrError = validateOffenseForm('criminalActivity');
        if (nibrError && nibrError.length) {
          errorStatus = {
            ...errorStatus,
            criminalActivity: `${nibrError[0].message}`,
          };
        } else {
          errorStatus = {
            ...errorStatus,
            criminalActivity: null,
          };
        }
        setErrors(errorStatus);
      }, 2000);
    }
    return () => clearTimeout(timeoutId);
  }, [
    offensesOffenseFormState.values.statuteDetails,
    offensesOffenseFormState.values.criminalActivity,
  ]);
  /** endregion */
  return (
    <Grid container justify="center">
      <Grid item xs={12} lg={12} className="mt-4">
        <Autocomplete
          size="small"
          autoComplete
          autoSelect
          autoHighlight
          id="combo-criminal-activity"
          getOptionLabel={(option) => option || ''}
          options={criminalActivityState}
          key={offensesOffenseFormState.values.criminalActivity || ''}
          value={offensesOffenseFormState.values.criminalActivity || ''}
          onChange={(event, newValue) =>
            handleFormChangeNew(
              event,
              newValue,
              'none',
              'criminalActivity',
              setOffensesOffenseFormState
            )
          }
          renderInput={(params) => (
            <TextField
              autoComplete="hidden"
              size="small"
              {...params}
              label="Criminal Activity / Gang Information"
              variant="outlined"
              error={errors['criminalActivity'] ? true : false}
              helperText={errors['criminalActivity']}
              fullWidth
            />
          )}
        />
      </Grid>
    </Grid>
  );
};

const mapStateToProps = (state) => ({
  offensesOffenseForm: state.incident.offensesOffenseForm,
  currentOffense: state.incident.currentOffense,
  offenses: state.incident.offenses,
  selectedEntity: state.incident.selectedEntity,
});

export default connect(mapStateToProps, { updateIncOffensesOffenseForm, updateIncOffenses })(
  CriminalActivityForm
);
