import { v4 as uuidv4 } from 'uuid';
import store from '../../config/configureStore';

export const assignTempIncidentId = () => {
  return uuidv4();
};

export const getIncident = async () => {
  const client = store.store.getState().websocket.websocket;
  const incident = store.store.getState().incident;
  if (!client) return;
  try {
    const service = client.service('rms-incident');
    service.timeout = 200000;
    let result = await service.get(incident.ptsIncidentId);
    return result;
  } catch (error) {
    console.log(error);
  }
};

export const removeIncident = async () => {
  const client = store.store.getState().websocket.websocket;
  const incident = store.store.getState().incident;
  if (!client) return;
  try {
    const service = client.service('rms-incident');
    service.timeout = 20000;
    const result = await service.remove(incident.ptsIncidentId);
    return result;
  } catch (error) {
    console.log(error);
  }
};

export const removeEntity = async (entity, data) => {
  const wsClient = store.store.getState().websocket.websocket;
  const definition = {
    incidents: {
      service: 'rms-incident',
      key: 'ptsIncidentID',
      includeIncId: false,
    },
    addresses: {
      service: 'inclocationaddress',
      key: 'ptsAddressID',
      includeIncId: false,
    },
    parties: {
      service: 'incincidentpartyperson',
      key: 'ptsIncPersonID',
    },
    properties: {
      service: 'incincidentproperty',
      key: 'incIncidentPropertyID',
    },
    offenses: {
      service: 'incoffense',
      key: 'ptsOffenseID',
    },
    narratives: {
      service: 'ptsnarrative',
      key: 'ptsNarrativeID',
      includeIncId: false,
    },
    evidence: {
      service: 'incevidence',
      key: 'ptsEvidenceID',
    },
    incsubject: {
      service: 'incsubject',
      key: 'ptsSubjectID',
    },
    incvictim: {
      service: 'incvictim',
      key: 'ptsVictimID',
    },
    incarrestee: {
      service: 'incarrestee',
      key: 'ptsArresteeID',
      includeIncId: false,
    },
    incproperty: {
      service: 'incproperty',
      key: 'ptsPropertyID',
      includeIncId: false,
    },
    incdvsuspect: {
      service: 'incdvsuspect',
      key: 'ptsDVSuspectID',
      includeIncId: false,
    },
    incevidencecustody: {
      service: 'incevidencecustody',
      key: 'ptsEvidenceCustodyID',
    },
    incevidencestorage: {
      service: 'incevidencestorage',
      key: 'ptsEvidenceStorageID',
    },
    zones: {
      service: 'inclocationzone',
      datbaseKey: 'Zone',
      formKey: 'zone',
    },
    dispositions: {
      service: 'incdispositions',
      datbaseKey: 'Disposition',
      formKey: 'disposition',
    },
    alerts: {
      service: 'incalerts',
      datbaseKey: 'ptsAlertID',
      formKey: 'ptsAlertId',
    },
    notifications: {
      service: 'incnotifications',
      datbaseKey: 'ptsNotificationID',
      formKey: 'ptsNotificationId',
    },
  };

  try {
    const serviceName = definition[entity].service;
    const incident = store.store.getState().incident;
    const service = wsClient.service(serviceName);
    service.timeout = 20000;

    let query = {};

    if (['zones', 'dispositions', 'alerts', 'notifications'].includes(entity)) {
      query = {
        [definition[entity].datbaseKey]: data[definition[entity].formKey],
        IsDeleted: false,
      };

      if (entity === 'zones') {
        query = {
          ...query,
          ptsLocationID: parseInt(incident.incident.ptsLocationId),
        };
      }
      if (entity === 'dispositions') {
        query = {
          ...query,
          ptsIncidentID: parseInt(incident.ptsIncidentId),
        };
      }

      console.log(query);
    } else {
      let dataKey = definition[entity].key.replace('ID', 'Id');
      const value = data[dataKey] ? data[dataKey] : data[definition[entity].key];

      if (!value) {
        return;
      }

      query = {
        ptsIncidentID: parseInt(data.ptsIncidentId),
        [definition[entity].key]: value,
        IsDeleted: false,
      };

      if (definition[entity].includeIncId === false) {
        delete query.ptsIncidentID;
      }
    }

    if (definition[entity].query) {
      query = {
        ...query,
        ...definition[entity].query,
      };
    }

    await service.remove(null, { query });
  } catch (error) {
    console.log(error);
  }
};

export const getEntitiesByIncId = async (incId, entity, query = {}) => {
  const wsClient = store.store.getState().websocket.websocket;
  if (!wsClient) return;
  const service = wsClient?.service('rms-incident');
  service.timeout = 2000000;

  const definition = {
    parties: {
      ptsIncidentID: incId,
      $select: ['ptsIncidentID'],
      parties: true,
      ...query,
    },
    addresses: {
      ptsIncidentID: incId,
      $select: ['ptsIncidentID'],
      address: true,
      city: true,
      coordinate: true,
      ...query,
    },
    offenses: {
      ptsIncidentID: incId,
      $select: ['ptsIncidentID'],
      offenses: true,
      offender: true,
      ...query,
    },
    properties: {
      ptsIncidentID: incId,
      $select: ['ptsIncidentID'],
      properties: true,
      item: true,
      firearm: true,
      jewelry: true,
      structure: true,
      substance: true,
      vehicle: true,
      ...query,
    },
    evidence: {
      ptsIncidentID: incId,
      $select: ['ptsIncidentID'],
      evidence: true,
      custody: true,
      storage: true,
      offense: true,
      disposition: true,
      ...query,
    },
    narratives: {
      ptsIncidentID: incId,
      $select: ['ptsIncidentID'],
      narrative: true,
      ...query,
    },
    general: {
      ptsIncidentID: incId,
      location: true,
      ...query,
    },
    dispositions: {
      ptsIncidentID: incId,
      dispositions: true,
      ...query,
    },
    zones: {
      ptsIncidentID: incId,
      location: true,
      ...query,
    },
    alerts: {
      ptsIncidentID: incId,
      $select: ['ptsIncidentID'],
      alerts: true,
      ...query,
    },
    notifications: {
      ptsIncidentID: incId,
      $select: ['ptsIncidentID'],
      notifications: true,
      ...query,
    },
  };
  let response;
  await service
    .find({
      query: definition[entity],
    })
    .then((res) => {
      response = res;
    })
    .catch((err) => console.log(err));

  // If api returns only one data then just send that data to component.
  // Why? No specific reason yet.
  if (Array.isArray(response.data) && response.data.length === 1) {
    return response.data[0];
  }
  return response.data;
};

// Region - Short cut API
export const upsertEvidenceOffense = async (evId, incidentID, offenseIDs) => {
  const client = store.store.getState().websocket.websocket;
  if (!client) return;
  try {
    const service = client.service('incevidenceoffense');
    service.timeout = 20000;

    const exist = await service.find({
      query: {
        ptsEvidenceID: evId,
        ptsIncidentID: incidentID,
      },
    });

    if (exist.total > 0) {
      for (let i = 0; i < exist.data.length; i++)
        await service.patch(exist.data[i].ptsEvidenceOffenseID, {
          IsDeleted: true,
        });
    }
    offenseIDs.forEach(async (offenseID) => {
      const exist = await service.find({
        query: {
          ptsOffenseID: offenseID,
          ptsEvidenceID: evId,
          ptsIncidentID: incidentID,
        },
      });
      if (exist.total > 0) {
        for (let i = 0; i < exist.data.length; i++)
          await service.patch(exist.data[i].ptsEvidenceOffenseID, {
            IsDeleted: false,
          });
      } else {
        await service.create({
          ptsIncidentID: incidentID,
          ptsEvidenceID: evId,
          ptsOffenseID: offenseID,
        });
      }
    });
  } catch (error) {
    console.log(error);
  }
};

export const addNewEvidenceItem = async (form, incidentID, offenseIDs) => {
  const client = store.store.getState().websocket.websocket;
  const user = store.store.getState().user.userData.user.Username;

  if (!client) return;
  try {
    const service = client.service('rms-evidence');
    service.timeout = 20000;
    const result = await service.create({
      ptsIncidentID: incidentID,
      incIncidentPropertyID: form.values.incIncidentPropertyId,
      DaysInEvidence: 0,
      Category: form.values.category,
      EvidenceType: form.values.evidenceType,
      Description: form.values.description,
      LocationCollected: form.values.locationCollected,
      CollectedBy: form.values.collectedBy,
      DateTimeCollected: form.values.collectedDate,
      RequiredAction: form.values.requiredAction,
      ActionDate: form.values.actionDate,
      TemporaryLocation: form.values.temporaryLocation,
      Notes: form.values.notes,
      SubmittedBy: form.values.submittedBy,
      IsIncludeOnReport: false,
      DisposalCode: form.values.disposalCode,
      Quantity: form.values.quantity,
      TagNumber: form.values.tagNumber,
      Make: form.values.make,
      Model: form.values.model,
      SerialNumber: form.values.serialNumber,
      VIN: form.values.vin,
      LicenseNumber: form.values.licenseNumber,
      Condition: form.values.condition,
      SubstanceType: form.values.substanceType,
      SubstanceClass: form.values.substanceClass,
      Measurement: form.values.measurement,
      attachments: form.values.attachments,
      IsLocked: false,
      IsSealed: false,
      IsDeleted: false,
      CreatedBy: user,
    });
    upsertEvidenceOffense(result.ptsEvidenceID, incidentID, offenseIDs);
    return result;
  } catch (e) {
    console.log(e);
  }
};

export const addNewEvidenceCustody = async (form, incidentID, evidenceID) => {
  const client = store.store.getState().websocket.websocket;
  const user = store.store.getState().user.userData.user.Username;

  if (!client) return;
  try {
    const service = client.service('rms-evidence-custody');
    service.timeout = 20000;
    const result = await service.create({
      ptsIncidentID: incidentID,
      ptsEvidenceID: evidenceID,
      Status: form.values.Status,
      CustodyFrom: form.values.custodyFrom,
      CustodyTo: form.values.custodyTo,
      Reason: form.values.reason,
      Notes: form.values.notes,
      RequiredAction: form.values.requiredAction,
      DisposalCode: form.values.disposalCode,
      TransactionDate: form.values.transactionDate,
      TransactionType: form.values.transactionType,
      IsLocked: false,
      IsSealed: false,
      IsDeleted: false,
      CreatedBy: user,
    });
    return result;
  } catch (e) {
    console.log(e);
  }
};

export const addNewEvidenceDisposition = async (form, incidentID, evidenceID) => {
  const client = store.store.getState().websocket.websocket;
  const user = store.store.getState().user.userData.user.Username;

  if (!client) return;
  try {
    const service = client.service('rms-evidence-disposition');
    service.timeout = 20000;
    const result = await service.create({
      ptsIncidentID: incidentID,
      ptsEvidenceID: evidenceID,
      BypassDispAssumption: form.values.bypassDisposalAssumption,
      ReadyForDisposal: form.values.readyForDisposal,
      FinalDisposition: form.values.finalDisposition,
      DispositionDate: form.values.dispositionDate,
      DispositionNotes: form.values.dispositionNotes,
      DisposedOfBy: form.values.disposedOfBy,
      UpdatedBy: form.values.enteredBy,
      IsLocked: false,
      IsSealed: false,
      IsDeleted: false,
      IsDisposed: form.values.isDisposed,
      CreatedBy: user,
    });
    return result;
  } catch (e) {
    console.log(e);
  }
};

export const addNewEvidenceStorage = async (form, incidentID, evidenceID) => {
  const client = store.store.getState().websocket.websocket;
  const user = store.store.getState().user.userData.user.Username;

  if (!client) return;
  try {
    const service = client.service('rms-evidence-storage');
    service.timeout = 20000;
    const result = await service.create({
      ptsIncidentID: incidentID,
      ptsEvidenceID: evidenceID,
      DateTimeIn: form.values.dateIn,
      StorageLocation: form.values.storageLocation,
      SubLocation: form.values.subLocation,
      Container: form.values.container,
      IsLocked: false,
      IsSealed: false,
      IsDeleted: false,
      CreatedBy: user,
    });
    return result;
  } catch (e) {
    console.log(e);
  }
};

export const addNewOffense = async (form, incidentID) => {
  const client = store.store.getState().websocket.websocket;
  const user = store.store.getState().user.userData.user.Username;

  if (!client) return;
  try {
    const service = client.service('rms-offenses');
    service.timeout = 20000;
    const result = await service.create({
      CriminalActivity: form.values.criminalActivity,
      IncidentLocationType: form.values.locationCategory,
      BiasMotivations: form.values.biasMotivation,
      OffenderSuspectedOfUsing: form.values.offenderSuspectedOfUsing,
      WeaponForceType: form.values.forceCategory,
      IsGangInvolved: form.values.isGangInvolved,
      IsHomeInvasion: false,
      IsDomesticViolence: false,
      IsIdentityTheft: false,
      IsCargoTheft: false,
      StructuresEntered: Number(form.values.structuresEntered) || 0,
      StructuresOccupied: form.values.structuresOccupied || false,
      MethodOfEntry: form.values.methodOfEntry,
      statute: form.values.statuteDetails.Description,
      ptsStatuteID: form.values.statuteDetails.ptsStatuteID,
      ptsFBICode: form.values.statuteDetails.ptsFBICodeID,
      IsAttempted: form.values.offenseAttempted || false,
      IsLocked: false,
      IsSealed: false,
      IsDeleted: false,
      ptsIncidentID: incidentID,
      CreatedBy: user,
    });

    return result;
  } catch (error) {
    console.log(error);
  }
};

export const addNewNarratives = async (form, incidentID) => {
  const client = store.store.getState().websocket.websocket;
  const user = store.store.getState().user.userData.user.Username;

  if (!client) return;
  try {
    const service = client.service('rms-narratives');
    service.timeout = 20000;
    const result = await service.create({
      ParentType: 'INCIDENT',
      ptsParentID: incidentID,
      ptsAuthorID: form.values.authorId,
      NarrativeTitle: form.values.narrativeTitle,
      NarrativeSequence: Number(form.values.sequence),
      NarrativeBody: form.values.narrativeBody,
      NarrativeType: form.values.narrativeType,
      ApprovedBy: form.values.approvedBy,
      NarrativeDT: form.values.narrativeDT,
      ApprovedDT: form.values.approvedDT,
      EntryClerk: form.values.entryClerk,
      attachments: form.values.attachments,
      CreatedBy: user,
    });
    return result;
  } catch (error) {
    console.log('error', error);
  }
};

export const updateNarratives = async (form) => {
  const client = store.store.getState().websocket.websocket;
  const user = store.store.getState().user.userData.user.Username;

  console.log(form);

  if (!client) return;
  try {
    const service = client.service('rms-narratives');
    service.timeout = 20000;
    const result = await service.patch(form.values.narrativeId, {
      NarrativeTitle: form.values.narrativeTitle,
      NarrativeSequence: Number(form.values.sequence),
      NarrativeBody: form.values.narrativeBody,
      NarrativeType: form.values.narrativeType,
      ApprovedDT: form.values.approvedDT,
      NarrativeDT: form.values.narrativeDT,
      ApprovedBy: form.values.approvedBy,
      EntryClerk: form.values.entryClerk,
      ptsAuthorID: form.values.authorId,
      attachments: form.values.attachments,
      UpdatedBy: user,
    });
    return result;
  } catch (error) {
    console.log('error', error);
  }
};

export const updateEvidenceItem = async (form, incidentID, offenseIDs) => {
  const client = store.store.getState().websocket.websocket;
  const user = store.store.getState().user.userData.user.Username;

  if (!client) return;
  try {
    const service = client.service('rms-evidence');
    service.timeout = 20000;
    const result = await service.patch(form.ptsEvidenceId, {
      DaysInEvidence: 0,
      Category: form.values.category,
      EvidenceType: form.values.evidenceType,
      Description: form.values.description,
      LocationCollected: form.values.locationCollected,
      CollectedBy: form.values.collectedBy,
      DateTimeCollected: form.values.collectedDate,
      RequiredAction: form.values.requiredAction,
      ActionDate: form.values.actionDate,
      TemporaryLocation: form.values.temporaryLocation,
      Notes: form.values.notes,
      SubmittedBy: form.values.submittedBy,
      IsIncludeOnReport: false,
      DisposalCode: form.values.disposalCode,
      Quantity: form.values.quantity,
      TagNumber: form.values.tagNumber,
      Make: form.values.make,
      Model: form.values.model,
      VIN: form.values.vin,
      LicenseNumber: form.values.licenseNumber,
      SerialNumber: form.values.serialNumber,
      Condition: form.values.condition,
      SubstanceType: form.values.substanceType,
      SubstanceClass: form.values.substanceClass,
      Measurement: form.values.measurement,
      attachments: form.values.attachments,
      UpdatedBy: user,
    });
    upsertEvidenceOffense(result.ptsEvidenceID, incidentID, offenseIDs);
    return result;
  } catch (e) {
    console.log(e);
  }
};

export const updateEvidenceCustody = async (form) => {
  const client = store.store.getState().websocket.websocket;
  const user = store.store.getState().user.userData.user.Username;

  if (!client) return;
  try {
    const service = client.service('rms-evidence-custody');
    service.timeout = 20000;
    const result = await service.patch(form.ptsEvidenceCustodyId, {
      Status: form.values.Status,
      CustodyFrom: form.values.custodyFrom,
      CustodyTo: form.values.custodyTo,
      Reason: form.values.reason,
      Notes: form.values.notes,
      RequiredAction: form.values.requiredAction,
      DisposalCode: form.values.disposalCode,
      TransactionDate: form.values.transactionDate,
      TransactionType: form.values.transactionType,
      IsLocked: false,
      IsSealed: false,
      IsDeleted: false,
      UpdatedBy: user,
    });

    console.log(result);
    return result;
  } catch (e) {
    console.log(e);
  }
};

export const updateEvidenceDisposition = async (form) => {
  const client = store.store.getState().websocket.websocket;
  const user = store.store.getState().user.userData.user.Username;

  if (!client) return;
  try {
    const service = client.service('rms-evidence-disposition');
    service.timeout = 20000;
    const result = await service.patch(form.ptsEvidenceDispositionId, {
      BypassDispAssumption: form.values.bypassDisposalAssumption,
      ReadyForDisposal: form.values.readyForDisposal,
      FinalDisposition: form.values.finalDisposition,
      DispositionDate: form.values.dispositionDate,
      DispositionNotes: form.values.dispositionNotes,
      DisposedOfBy: form.values.disposedOfBy,
      IsLocked: false,
      IsSealed: false,
      IsDeleted: false,
      IsDisposed: form.values.isDisposed,
      UpdatedBy: user,
    });
    return result;
  } catch (e) {
    console.log(e);
  }
};

export const updateEvidenceStorage = async (form) => {
  const client = store.store.getState().websocket.websocket;

  if (!client) return;
  try {
    const service = client.service('rms-evidence-storage');
    service.timeout = 20000;
    const result = await service.patch(form.ptsEvidenceStorageId, {
      // EvidenceID: '', // TODO: Agency defined custom evidence ID to be implemented
      DateTimeIn: form.values.dateIn,
      StorageLocation: form.values.storageLocation,
      SubLocation: form.values.subLocation,
      Container: form.values.container,
      UpdatedBy: form.values.enteredBy,
      IsLocked: false,
      IsSealed: false,
      IsDeleted: false,
    });
    return result;
  } catch (e) {
    console.log(e);
  }
};

export const updateOffense = async (form) => {
  const client = store.store.getState().websocket.websocket;
  const user = store.store.getState().user.userData.user.Username;

  if (!client) return;
  try {
    const service = client.service('rms-offenses');
    service.timeout = 20000;
    const result = await service.patch(form.ptsOffenseId, {
      CriminalActivity: form.values.criminalActivity,
      IncidentLocationType: form.values.locationCategory,
      BiasMotivations: form.values.biasMotivation,
      OffenderSuspectedOfUsing: form.values.offenderSuspectedOfUsing,
      WeaponForceType: form.values.forceCategory,
      IsGangInvolved: form.values.isGangInvolved,
      IsHomeInvasion: false,
      IsDomesticViolence: false,
      IsIdentityTheft: false,
      IsCargoTheft: false,
      StructuresEntered: Number(form.values.structuresEntered) || 0,
      StructuresOccupied: form.values.structuresOccupied || false,
      MethodOfEntry: form.values.methodOfEntry,
      statute: form.values.statuteDetails.Description,
      ptsStatuteID: form.values.statuteDetails.ptsStatuteID,
      ptsFBICode: form.values.statuteDetails.ptsFBICodeID,
      IsAttempted: form.values.offenseAttempted || false,
      UpdatedBy: user,
    });
    return result;
  } catch (error) {
    console.log(error);
  }
};

export const addNewSubject = async (form, person, incidentID) => {
  const savedParty = person.personDetail;
  const client = store.store.getState().websocket.websocket;
  const user = store.store.getState().user.userData.user.Username;

  if (!client) return;
  try {
    const service = client.service('rms-offense-offenders');
    service.timeout = 20000;
    const result = await service.create({
      ptsPersonID: person.ptsPersonId ? person.ptsPersonId : 0,
      ptsIncPersonID: person.ptsIncPersonId,
      DNACollection: form.values.dnaCollection,
      SubjectMO: form.values.mo,
      SubjectWeapon: form.values.weapon,
      SubjectStatus: form.values.status,
      SubjectInvolvement: form.values.involvement,
      IsArmed: form.values.armed || false,
      IsExtradition: form.values.extradition || false,
      IsUnknown: form.values.unknown || false,
      Notes: form.values.notes,
      Age: savedParty.Age,
      AgeRangeIncrement: savedParty.AgeRangeIncrement,
      Race: savedParty.Race,
      Sex: savedParty.Sex,
      Ethnicity: savedParty.Ethnicity,
      FirstName: savedParty.FirstName,
      MiddleName: savedParty.MiddleName,
      LastName: savedParty.LastName,
      Prefix: savedParty.Prefix,
      Suffix: savedParty.Suffix,
      NickName: savedParty.NickName,
      FullName: savedParty.FullName,
      EyeColor: savedParty.EyeColor,
      HairColor: savedParty.HairColor,
      HairLength: savedParty.HairLength,
      HairStyle: savedParty.HairStyle,
      Handed: savedParty.Handed,
      BloodType: savedParty.BloodType,
      Height: savedParty.Height,
      Weight: savedParty.Weight,
      IsLocked: false,
      IsSealed: false,
      IsDeleted: false,
      ptsOffenseID: form.ptsOffenseId,
      Born: savedParty.Born,
      ptsIncidentID: incidentID,
      CreatedBy: user,
    });
    console.log(form);
    return result;
  } catch (error) {
    console.log(error);
  }
};

export const updateSubject = async (form) => {
  const client = store.store.getState().websocket.websocket;
  const user = store.store.getState().user.userData.user.Username;

  if (!client) return;
  try {
    const service = client.service('rms-offense-offenders');
    service.timeout = 20000;
    const result = await service.patch(form.ptsSubjectId, {
      DNACollection: form.values.dnaCollection,
      SubjectMO: form.values.mo,
      SubjectWeapon: form.values.weapon,
      SubjectStatus: form.values.status,
      SubjectInvolvement: form.values.involvement,
      IsArmed: form.values.armed || false,
      IsExtradition: form.values.extradition || false,
      IsUnknown: form.values.unknown || false,
      Notes: form.values.notes,
      UpdatedBy: user,
    });
    return result;
  } catch (error) {
    console.log(error);
  }
};

export const addNewVictim = async (form, person, incidentID) => {
  const savedParty = person.personDetail;
  const client = store.store.getState().websocket.websocket;
  const user = store.store.getState().user.userData.user.Username;

  if (!client) return;
  try {
    const service = client.service('rms-offense-victims');
    service.timeout = 20000;
    const result = await service.create({
      ptsParentID: person.ptsPersonId ? person.ptsPersonId : 0,
      ptsIncPersonID: person.ptsIncPersonId,
      ptsOffenseID: form.ptsOffenseId,
      ParentType: 'Person',
      VictimCategory: form.values.category,
      VICAPCaseNumber: form.values.caseNumber,
      VictimDisposition: form.values.disposition,
      VictimTreatment: form.values.treatment,
      ResidentStatus: form.values.residentStatus,
      IsSeekingPersecution: form.values.seekingProsecution || false,
      IsSociety: form.values.society || false,
      AggAssaultType: form.values.aggravatedAssaultHomicide,
      InjuryType: form.values.injuryType,
      NegligentManslaughter: form.values.negligentManslaughter,
      JustifiableHomicide: form.values.justifiableHomicide,
      AddlJustifiableHomicide: form.values.additionalJustifiableHomicide,
      OfficerActivity: form.values.officerActivity,
      OfficerAssignment: form.values.officerAssignment,
      OfficerORI: form.values.officerORI,
      Age: savedParty.Age,
      AgeRangeIncrement: savedParty.AgeRangeIncrement,
      Race: savedParty.Race,
      Sex: savedParty.Sex,
      Ethnicity: savedParty.Ethnicity,
      FirstName: savedParty.FirstName,
      MiddleName: savedParty.MiddleName,
      LastName: savedParty.LastName,
      Prefix: savedParty.Prefix,
      Suffix: savedParty.Suffix,
      NickName: savedParty.NickName,
      FullName: savedParty.FullName,
      EyeColor: savedParty.EyeColor,
      HairColor: savedParty.HairColor,
      HairLength: savedParty.HairLength,
      HairStyle: savedParty.HairStyle,
      Handed: savedParty.Handed,
      BloodType: savedParty.BloodType,
      Height: savedParty.Height,
      Weight: savedParty.Weight,
      PlaceName: '',
      PlaceType: '',
      Notes: form.values.notes,
      IsLocked: false,
      IsSealed: false,
      IsDeleted: false,
      Born: savedParty.Born,
      ptsIncidentID: incidentID,
      CreatedBy: user,
    });
    return result;
  } catch (error) {
    console.log(error);
  }
};

export const updateVictim = async (form) => {
  const client = store.store.getState().websocket.websocket;
  const user = store.store.getState().user.userData.user.Username;

  if (!client) return;
  try {
    const service = client.service('rms-offense-victims');
    service.timeout = 20000;
    const result = await service.patch(form.ptsVictimId, {
      VictimCategory: form.values.category,
      VICAPCaseNumber: form.values.caseNumber,
      VictimDisposition: form.values.disposition,
      VictimTreatment: form.values.treatment,
      ResidentStatus: form.values.residentStatus,
      IsSeekingPersecution: form.values.seekingProsecution || false,
      IsSociety: form.values.society || false,
      AggAssaultType: form.values.aggravatedAssaultHomicide,
      InjuryType: form.values.injuryType,
      NegligentManslaughter: form.values.negligentManslaughter,
      JustifiableHomicide: form.values.justifiableHomicide,
      AddlJustifiableHomicide: form.values.additionalJustifiableHomicide,
      OfficerActivity: form.values.officerActivity,
      OfficerAssignment: form.values.officerAssignment,
      OfficerORI: form.values.officerORI,
      PlaceName: '',
      PlaceType: '',
      Notes: form.values.notes,
      UpdatedBy: user,
    });
    return result;
  } catch (error) {
    console.log(error);
  }
};

export const addNewArrestee = async (form, person, incidentID) => {
  const savedParty = person.personDetail;
  const client = store.store.getState().websocket.websocket;
  const user = store.store.getState().user.userData.user.Username;

  if (!client) return;
  try {
    const service = client.service('rms-arrestees');
    service.timeout = 20000;
    const result = await service.create({
      ptsPersonID: person.ptsPersonId ? person.ptsPersonId : 0,
      ptsIncPersonID: person.ptsIncPersonId,
      ArrestDate: form.values.arrestDate,
      ArrestType: form.values.arrestType,
      IsCleared: form.values.cleared || false,
      ArresteeArmed: form.values.arresteeArmedWith,
      JuvenileDisposition: form.values.juvenileDisposition,
      ArrestSegmentIndicator: form.values.arresteeSegmentsIndicator,
      ResidentStatus: form.values.arresteeResidentStatus,
      OffenseDescription: form.values.offenseDescription,
      Notes: form.values.notes,
      Age: savedParty.Age,
      AgeRangeIncrement: savedParty.AgeRangeIncrement,
      Race: savedParty.Race,
      Sex: savedParty.Sex,
      Ethnicity: savedParty.Ethnicity,
      FirstName: savedParty.FirstName,
      MiddleName: savedParty.MiddleName,
      LastName: savedParty.LastName,
      Prefix: savedParty.Prefix,
      Suffix: savedParty.Suffix,
      NickName: savedParty.NickName,
      FullName: savedParty.FullName,
      EyeColor: savedParty.EyeColor,
      HairColor: savedParty.HairColor,
      HairLength: savedParty.HairLength,
      HairStyle: savedParty.HairStyle,
      Handed: savedParty.Handed,
      BloodType: savedParty.BloodType,
      Height: savedParty.Height,
      Weight: savedParty.Weight,
      IsLocked: false,
      IsSealed: false,
      IsDeleted: false,
      ptsOffenseID: form.ptsOffenseId,
      ptsIncidentID: incidentID,
      CreatedBy: user,
    });
    return result;
  } catch (error) {
    console.log(error);
  }
};

export const updateArrestee = async (form) => {
  const client = store.store.getState().websocket.websocket;
  const user = store.store.getState().user.userData.user.Username;

  if (!client) return;
  try {
    const service = client.service('rms-arrestees');
    service.timeout = 20000;
    const result = await service.patch(form.ptsArresteeId, {
      ArrestDate: form.values.arrestDate,
      ArrestType: form.values.arrestType,
      IsCleared: form.values.cleared || false,
      ArresteeArmed: form.values.arresteeArmedWith,
      JuvenileDisposition: form.values.juvenileDisposition,
      ArrestSegmentIndicator: form.values.arresteeSegmentsIndicator,
      ResidentStatus: form.values.arresteeResidentStatus,
      Notes: form.values.notes,
      OffenseDescription: form.values.offenseDescription,
      UpdatedBy: user,
    });
    return result;
  } catch (error) {
    console.log(error);
  }
};

export const doesRelationshipExist = async (victim, subject, offenseID) => {
  const client = store.store.getState().websocket.websocket;
  if (!client) return;
  try {
    const service = client.service('rms-victim-offense-subject');
    service.timeout = 20000;
    const relationship = await service.find({
      query: {
        ptsVictimID: victim.incVictimDetails.ptsVictimId,
        ptsSubjectID: subject.incSubjectDetails.ptsSubjectId,
        ptsOffenseID: offenseID,
        $limit: 1,
      },
    });
    return relationship;
  } catch (error) {
    console.log(error);
  }
};

export const addNewRelationship = async (victim, subject, form) => {
  const client = store.store.getState().websocket.websocket;
  const user = store.store.getState().user.userData.user.Username;

  if (!client) return;
  try {
    const service = client.service('rms-victim-offense-subject');
    service.timeout = 20000;
    let relationship = await service.create({
      ptsVictimID: victim.incVictimDetails.ptsVictimId,
      ptsSubjectID: subject.incSubjectDetails.ptsSubjectId,
      ptsOffenseID: form.ptsOffenseId,
      Relationship: form.values.relationship.relationship,
      IsLocked: false,
      IsSealed: false,
      IsDeleted: false,
      CreatedBy: user,
    });
    return relationship;
  } catch (error) {
    console.log(error);
  }
};

export const updateRelationship = async (victim, subject, form) => {
  const client = store.store.getState().websocket.websocket;
  const user = store.store.getState().user.userData.user.Username;

  if (!client) return;
  try {
    const service = client.service('rms-victim-offense-subject');
    service.timeout = 20000;
    let relationship = await service.update(
      {
        ptsVictimID: victim.incVictimDetails.ptsVictimId,
        ptsSubjectID: subject.incSubjectDetails.ptsSubjectId,
        ptsOffenseID: form.ptsOffenseId,
      },
      {
        ptsVictimID: victim.incVictimDetails.ptsVictimId,
        ptsSubjectID: subject.incSubjectDetails.ptsSubjectId,
        ptsOffenseID: form.ptsOffenseId,
        Relationship: form.values.relationship.relationship,
        IsLocked: false,
        IsSealed: false,
        IsDeleted: false,
        UpdatedBy: user,
      }
    );
    return relationship;
  } catch (error) {
    console.log(error);
  }
};

export const addNewDVVictim = async (form) => {
  const client = store.store.getState().websocket.websocket;
  const user = store.store.getState().user.userData.user.Username;

  if (!client) return;
  try {
    const service = client.service('rms-offense-dvvictim');
    service.timeout = 20000;
    let dvVictim = await service.create({
      ...form.values,
      ptsVictimID: form.ptsVictimId,
      CreatedBy: user,
    });
    return dvVictim;
  } catch (error) {
    console.log(error);
  }
};

export const updateDVVictim = async (form) => {
  const client = store.store.getState().websocket.websocket;
  const user = store.store.getState().user.userData.user.Username;

  if (!client) return;
  try {
    const service = client.service('rms-offense-dvvictim');
    service.timeout = 20000;
    let dvVictim = await service.patch(form.ptsDVVictimId, {
      ...form.values,
      UpdatedBy: user,
    });
    return dvVictim;
  } catch (error) {
    console.log(error);
  }
};

export const addNewDVAdditional = async (form) => {
  const client = store.store.getState().websocket.websocket;
  const user = store.store.getState().user.userData.user.Username;

  if (!client) return;
  try {
    const service = client.service('rms-offense-dvadditional');
    service.timeout = 20000;
    let dvVictim = await service.create({
      ...form.values,
      ptsVictimID: form.ptsVictimId,
      CreatedBy: user,
    });
    return dvVictim;
  } catch (error) {
    console.log(error);
  }
};

export const updateDVAdditional = async (form) => {
  const client = store.store.getState().websocket.websocket;
  const user = store.store.getState().user.userData.user.Username;

  if (!client) return;
  try {
    const service = client.service('rms-offense-dvadditional');
    service.timeout = 20000;
    let dvAdditional = await service.patch(form.ptsDVAdditionalId, {
      ...form.values,
      UpdatedBy: user,
    });
    return dvAdditional;
  } catch (error) {
    console.log(error);
  }
};

export const addNewSuspect = async (form, person) => {
  const client = store.store.getState().websocket.websocket;
  const user = store.store.getState().user.userData.user.Username;

  if (!client) return;
  try {
    const service = client.service('rms-offense-dvsuspect');
    service.timeout = 20000;
    const result = await service.create({
      ptsPersonID: person.ptsPersonId ? person.ptsPersonId : 0,
      ptsIncPersonID: person.ptsIncPersonId,
      ...form.values,
      ptsVictimID: form.ptsVictimId,
      CreatedBy: user,
    });
    return result;
  } catch (error) {
    console.log(error);
  }
};

export const updateSuspect = async (form) => {
  const client = store.store.getState().websocket.websocket;
  const user = store.store.getState().user.userData.user.Username;

  if (!client) return;
  try {
    const service = client.service('rms-offense-dvsuspect');
    service.timeout = 20000;
    const result = await service.patch(form.ptsDVSuspectId, {
      ...form.values,
      UpdatedBy: user,
    });
    return result;
  } catch (error) {
    console.log(error);
  }
};

export const addNewProperty = async (form, property) => {
  const client = store.store.getState().websocket.websocket;
  const user = store.store.getState().user.userData.user.Username;

  if (!client) return;
  try {
    const service = client.service('incproperty');
    service.timeout = 20000;
    let prop = await service.create({
      ptsOffenseID: form.ptsOffenseId,
      PropertyDescription: property.propertyDetails.values.description || null,
      PropertyLossType: form.values.type || null,
      ParentType: property.propertyDetails.values.parentType,
      ptsParentID:
        property.propertyDetails.ptsItemId ||
        property.propertyDetails.ptsFirearmId ||
        property.propertyDetails.ptsJewelryId ||
        property.propertyDetails.ptsStructureId ||
        property.propertyDetails.ptsSubstanceId ||
        property.propertyDetails.ptsVehicleId,
      Value: form.values.value || null,
      StolenVehicles: form.values.stolenVehicles || null,
      RecoveredVehicles: form.values.recoveredVehicles || null,
      // PropertyDescription: form.values.propertyDescription || null,
      RecoveryDate: form.values.recoveredDate || null,
      IsLocked: false,
      IsSealed: false,
      IsDeleted: false,
      PropertyStatus: form.values.status,
      Notes: form.values.notes,
      IsStolenFromOtherJurisdiction: form.values.stolenFromOtherJurisdiction || false,
      IsRecoveredFromOtherJurisdiction: form.values.recoveredFromOtherJurisdiction || false,
      StatusDate: form.values.statusDate || null,
      incIncidentPropertyID: property.propertyDetails.incIncidentPropertyId,
      CreatedBy: user,
    });
    return prop;
  } catch (error) {
    console.log(error);
  }
};

export const updateProperty = async (form) => {
  const client = store.store.getState().websocket.websocket;
  const user = store.store.getState().user.userData.user.Username;

  if (!client) return;
  try {
    const service = client.service('incproperty');
    service.timeout = 20000;
    console.log(form);

    const result = await service.patch(form.ptsPropertyId, {
      PropertyLossType: form.values.type,
      Value: form.values.value,
      RecoveryDate: form.values.recoveredDate,
      PropertyStatus: form.values.status,
      Notes: form.values.notes,
      IsStolenFromOtherJurisdiction: form.values.stolenFromOtherJurisdiction || false,
      IsRecoveredFromOtherJurisdiction: form.values.recoveredFromOtherJurisdiction || false,
      StatusDate: form.values.statusDate,
      UpdatedBy: user,
      StolenVehicles: form.values.stolenVehicles ? parseInt(form.values.stolenVehicles) : 0,
      RecoveredVehicles: form.values.recoveredVehicles
        ? parseInt(form.values.recoveredVehicles)
        : 0,
      PropertyDescription: form.values.propertyDescription,
    });
    console.log(result);
    return result;
  } catch (error) {
    console.log(error);
  }
};

export const findZoneByAgent = async (form) => {
  const client = store.store.getState().websocket.websocket;

  if (!client) return;
  try {
    const service = client.service('inclocationzone');
    service.timeout = 20000;

    let res = await service.find({
      query: {
        ptsLocationID: form.values.ptsLocationId,
        Zone: form.values.zone,
        IsDeleted: true,
      },
    });
    return res;
  } catch (error) {
    console.log(error);
  }
};

export const addZone = async (form) => {
  const client = store.store.getState().websocket.websocket;
  const user = store.store.getState().user.userData.user.Username;

  if (!client) return;

  let zoneFound = await findZoneByAgent(form);

  if (zoneFound.total !== 0) {
    try {
      const service = client.service('rms-inclocationzone');
      service.timeout = 20000;

      let res = await service.update(
        {
          ptsLocationID: form.values.ptsLocationId,
          Zone: form.values.zone,
        },
        {
          IsDeleted: 0,
        }
      );
      return res;
    } catch (error) {
      console.log(error);
    }
  } else {
    try {
      const service = client.service('inclocationzone');
      service.timeout = 20000;

      let res = await service.create({
        ptsLocationID: form.values.ptsLocationId,
        Zone: form.values.zone,
        IsLocked: false,
        IsSealed: false,
        IsDeleted: false,
        UpdatedBy: user,
      });
      return res;
    } catch (error) {
      console.log(error);
    }
  }
};

export const findDispositionByAgent = async (form) => {
  const client = store.store.getState().websocket.websocket;

  if (!client) return;
  try {
    const service = client.service('incdispositions');
    service.timeout = 20000;

    let res = await service.find({
      query: {
        ptsIncidentID: form.values.ptsIncidentId,
        Disposition: form.values.disposition,
        IsDeleted: true,
      },
    });
    return res;
  } catch (error) {
    console.log(error);
  }
};

export const addDisposition = async (form) => {
  const client = store.store.getState().websocket.websocket;
  const user = store.store.getState().user.userData.user.Username;

  if (!client) return;

  let zoneFound = await findDispositionByAgent(form);

  if (zoneFound.total !== 0) {
    try {
      const service = client.service('rms-incdisposition');
      service.timeout = 20000;

      let res = await service.update(
        {
          ptsIncidentID: form.values.ptsIncidentId,
          Disposition: form.values.disposition,
        },
        {
          IsDeleted: 0,
        }
      );
      return res;
    } catch (error) {
      console.log(error);
    }
  } else {
    try {
      const service = client.service('incdispositions');
      service.timeout = 20000;

      let res = await service.create({
        ptsIncidentID: form.values.ptsIncidentId,
        Disposition: form.values.disposition,
        AgencyID: 'POLICEDEP', // TODO: Must be dynamic
        IsLocked: false,
        IsSealed: false,
        IsDeleted: false,
        UpdatedBy: user,
      });
      return res;
    } catch (error) {
      console.log(error);
    }
  }
};

// region: validation helpers
export const prepareOffenseErrors = (errors) => {
  let currentIncidentId = parseInt(store.store.getState().incident.ptsIncidentId);
  let validationErrors = store.store.getState().incident.validationErrors;

  // console.log('883 exist1', validationErrors, typeof currentIncidentId);

  let errorObj = validationErrors.find((error) => error.ptsIncidentId === currentIncidentId);

  if (errorObj) {
    // console.log('883 found1', errorObj);
    errorObj.errors['offenseErrors'] = errors;
  } else {
    errorObj = {
      ptsIncidentId: currentIncidentId,
      errors: {
        offenseErrors: errors,
      },
    };
    validationErrors.push(errorObj);
  }
  // console.log('883 done prep1', errorObj);
  return validationErrors;
};

export const preparePartiesErrors = (errors) => {
  let currentIncidentId = parseInt(store.store.getState().incident.ptsIncidentId);
  let validationErrors = store.store.getState().incident.validationErrors;

  // console.log('883 exist2', validationErrors, typeof currentIncidentId);

  let errorObj = validationErrors.find((error) => error.ptsIncidentId === currentIncidentId);

  if (errorObj) {
    // console.log('883 found2', errorObj);
    errorObj.errors['partiesErrors'] = errors;
  } else {
    errorObj = {
      ptsIncidentId: currentIncidentId,
      errors: {
        partiesErrors: errors,
      },
    };
    validationErrors.push(errorObj);
  }
  // console.log('883 done prep2', errorObj);
  // console.log('883 state2', validationErrors);
  return validationErrors;
};

export const prepareArresteesErrors = (errors) => {
  let currentIncidentId = parseInt(store.store.getState().incident.ptsIncidentId);
  let validationErrors = store.store.getState().incident.validationErrors;

  let errorObj = validationErrors.find((error) => error.ptsIncidentId === currentIncidentId);

  if (errorObj) {
    errorObj.errors['arresteesErrors'] = errors;
  } else {
    errorObj = {
      ptsIncidentId: currentIncidentId,
      errors: {
        arresteesErrors: errors,
      },
    };
    validationErrors.push(errorObj);
  }
  return validationErrors;
};

export const prepareIncPropertiesErrors = (errors) => {
  let currentIncidentId = parseInt(store.store.getState().incident.ptsIncidentId);
  let validationErrors = store.store.getState().incident.validationErrors;

  let errorObj = validationErrors.find((error) => error.ptsIncidentId === currentIncidentId);

  if (errorObj) {
    errorObj.errors['incPropertiesErrors'] = errors;
  } else {
    errorObj = {
      ptsIncidentId: currentIncidentId,
      errors: {
        incPropertiesErrors: errors,
      },
    };
    validationErrors.push(errorObj);
  }
  return validationErrors;
};

export const prepareIncRelationshipErrors = (errors) => {
  let currentIncidentId = parseInt(store.store.getState().incident.ptsIncidentId);
  let validationErrors = store.store.getState().incident.validationErrors;

  let errorObj = validationErrors.find((error) => error.ptsIncidentId === currentIncidentId);

  if (errorObj) {
    errorObj.errors['incRelationshipErrors'] = errors;
  } else {
    errorObj = {
      ptsIncidentId: currentIncidentId,
      errors: {
        incRelationshipErrors: errors,
      },
    };
    validationErrors.push(errorObj);
  }
  return validationErrors;
};

export const prepareIncVictimErrors = (errors) => {
  let currentIncidentId = parseInt(store.store.getState().incident.ptsIncidentId);
  let validationErrors = store.store.getState().incident.validationErrors;

  let errorObj = validationErrors.find((error) => error.ptsIncidentId === currentIncidentId);

  if (errorObj) {
    errorObj.errors['incVictimErrors'] = errors;
  } else {
    errorObj = {
      ptsIncidentId: currentIncidentId,
      errors: {
        incVictimErrors: errors,
      },
    };
    validationErrors.push(errorObj);
  }
  return validationErrors;
};

export const preparePropertiesErrors = (errors) => {
  let currentIncidentId = parseInt(store.store.getState().incident.ptsIncidentId);
  let validationErrors = store.store.getState().incident.validationErrors;

  let errorObj = validationErrors.find((error) => error.ptsIncidentId === currentIncidentId);

  if (errorObj) {
    // console.log('883 found1', errorObj);
    errorObj.errors['propertiesErrors'] = errors;
  } else {
    errorObj = {
      ptsIncidentId: currentIncidentId,
      errors: {
        propertiesErrors: errors,
      },
    };
    validationErrors.push(errorObj);
  }
  // console.log('883 done prep1', errorObj);
  return validationErrors;
};
