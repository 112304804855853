import React from 'react';
import { connect } from 'react-redux';

import { faCrosshairs } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { Paper, Tabs, Tab, Divider, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import RMSFabButtons from '../../components/RMSForms/RMSIncidentForm/RMSFabButtons';
import TabPanel from '../../components/RMSForms/RMSIncidentForm/TabPanel';
import { setHandleClose, setMenuType } from 'reducers/ui/UiMenuReducer';

import TabFirearm from '../PageRMSProperties/PageRMSFirearm/TabFirearm';
import { updateIncPropertyFirearmForm } from '../../reducers/IncReducer';
import { setOffensesTab } from '../../reducers/ui/UiFormReducer';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    height: window.innerHeight - 90 + 'px',
  },
  scrollBar: {
    width: '100%',
    overflowX: 'hidden',

    [theme.breakpoints.up('lg')]: {
      height: '70vh',
    },
    [theme.breakpoints.down('lg')]: {
      height: '50vh',
    },
    [theme.breakpoints.only('lg')]: {
      height: '65vh',
    },
  },
  paper: {
    minHeight: window.innerHeight - 95 + 'px',
  },
  dialogBox: {
    minWidth: '412px',
    minHeight: '150px',
  },
  icons: {
    textDecoration: 'capitalize',
    padding: '16px',
    fontSize: '13px',
  },
}));

const AddEditFirearm = (props) => {
  /** region Component Variables and Props */
  const classes = useStyles();
  const {
    history,
    setHandleClose,
    setMenuType,
    properties,
    selectedIncident,
    selectedEntity,
    updateIncPropertyFirearmForm,
  } = props;
  const [value, setValue] = React.useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  /** endregion */
  /** region React Hooks */
  React.useEffect(() => {
    setMenuType('forms');
    setHandleClose('incForms', history);

    if (!selectedIncident || !selectedEntity || properties.length === 0) {
      return;
    }

    const { incIncidentPropertyId } = selectedEntity;
    const property = properties.filter(
      (e) => e.propertyDetails.incIncidentPropertyId === incIncidentPropertyId
    );

    if (property.length === 0) return;

    const { propertyDetails } = property[0];

    updateIncPropertyFirearmForm(propertyDetails);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedIncident]);

  return (
    <div className={classes.root}>
      <RMSFabButtons />
      <Grid container>
        <Grid xs={12} lg={12}>
          <Paper className={classes.paper}>
            <Grid container justify="center">
              <Grid item xs={12} lg={12}>
                <div
                  className="card-header-actions"
                  style={{
                    backgroundColor: '#1976d2',
                    color: 'white',
                    borderRadius: '0.5rem 0.5rem 0 0',
                  }}>
                  <Tabs
                    value={value}
                    indicatorColor="secondary"
                    textColor="secondary"
                    variant="fullWidth"
                    classes={{
                      indicator: classes.indicator,
                    }}
                    onChange={handleChange}>
                    <Tab
                      className={classes.icons}
                      icon={<FontAwesomeIcon icon={faCrosshairs} />}
                      label="Firearm"
                      style={{ color: 'white' }}
                    />
                  </Tabs>
                  <Divider />
                </div>
              </Grid>
              <PerfectScrollbar className={classes.scrollBar}>
                <Grid item xs={12} lg={12} style={{ padding: '15px' }}>
                  <TabPanel value={value} index={0}>
                    <TabFirearm history={history} />
                  </TabPanel>
                </Grid>
              </PerfectScrollbar>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    </div>
  );
};

const mapStateToProps = (state) => ({
  state: state,
  properties: state.incident.properties,
  selectedEntity: state.incident.selectedEntity,
  selectedIncident: state.incident.ptsIncidentId,
  showUCRAlertModal: state.uiModal.showUCRAlertModal,
  recordActive: state.records.recordActive,
});

export default connect(mapStateToProps, {
  updateIncPropertyFirearmForm,
  setOffensesTab,
  setMenuType,
  setHandleClose,
})(AddEditFirearm);
