/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { connect } from 'react-redux';

import { v4 as uuidv4 } from 'uuid';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';

import { updateIncZoneForm } from '../../reducers/IncReducer';
import { handleFormChangeNew } from '../../reducers/helpers/formHelpers';
import { Autocomplete } from '@material-ui/lab';

const TabZones = (props) => {
  /** region Component Variables and Props */
  const { zoneForm, updateIncZoneForm, wsClient, zones } = props;
  const [zoneFormState, setZoneFormState] = React.useState({
    tempPropId: uuidv4(),
    isValid: false,
    changes: false,
    values: {},
    touched: {},
    errors: {},
    formType: 'edit',
  });
  const [zonesList, setZonesList] = React.useState([]);
  /** endregion */
  /** region React Hooks */
  React.useEffect(() => {
    setZoneFormState((zoneItemFormState) => ({
      ...zoneItemFormState,
      ...zoneForm,
      values: {
        ...zoneItemFormState.values,
        ...zoneForm.values,
      },
    }));
  }, [zoneForm]);

  React.useEffect(() => {
    zoneFormState.changes = true;
    updateIncZoneForm(zoneFormState);
  }, [zoneFormState.values?.zone]);

  React.useEffect(async () => {
    if (!wsClient?.websocket) {
      return;
    }
    const service = wsClient?.websocket.service('codezones');
    service.timeout = 200000;
    const result = await service.find({
      query: {
        AgencyID: 'POLICEDEP',
        IsDeleted: 0,
        $select: ['ZoneCode', 'Description'],
      },
    });
    let filteredZones = [];

    result.data.forEach((f) => {
      let found = false;
      zones.forEach((z) => {
        if (f.ZoneCode === z.values.zone) found = true;
      });
      if (!found) filteredZones.push(f);
    });

    console.log(filteredZones);

    setZonesList(filteredZones);
  }, []);
  /** endregion */
  return (
    <Grid item xs={12} lg={12}>
      <Grid item xs={12} lg={12} className="mb-3 mt-3">
        <Autocomplete
          size="small"
          autoComplete
          autoSelect
          autoHighlight
          id="combo-zone"
          getOptionLabel={(option) => option || ''}
          options={zonesList.map((s) => s.ZoneCode)}
          key={zoneFormState.values.Zone || ''}
          defaultValue={zoneFormState.values.Zone || ''}
          onChange={(event, newValue) =>
            handleFormChangeNew(event, newValue, 'none', 'zone', setZoneFormState)
          }
          renderInput={(params) => (
            <TextField
              autoComplete="hidden"
              size="small"
              {...params}
              label="Zone"
              variant="outlined"
              fullWidth
            />
          )}
        />
      </Grid>
    </Grid>
  );
};

const mapStateToProps = (state) => ({
  zoneForm: state.incident.zoneForm,
  zones: state.incident.zones,
  wsClient: state.websocket,
});

export default connect(mapStateToProps, {
  updateIncZoneForm,
})(TabZones);
