import React from 'react';

import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Divider from '@material-ui/core/Divider';
import { PhoneMask } from '../../components/RMSTextMask';
import { connect } from 'react-redux';
import {
  getCityIdFromCityName,
  initFormModel,
  updateIncPartiesEmploymentForm,
} from '../../reducers/IncReducer';
import { getCodeStreetDirections } from '../../reducers/dictionaries/CodeStreetDirectionsReducer';
import { getCodeStreetType } from '../../reducers/dictionaries/CodeStreetTypesReducer';
import { getCodeStates } from '../../reducers/dictionaries/CodeStatesReducer';
import { getCodeCities } from '../../reducers/dictionaries/CodeOthersReducer';
import AddressSearch from '../../components/RMSForms/RMSAddressForm/AddressSearch';
import {
  clearPlace,
  handleFormChangeNew,
  onPlaceValueChange,
  setFullAddressText,
} from '../../reducers/helpers/formHelpers';
import RMSAutoComplete from 'components/RMSAutoComplete/RMSAutoComplete';
import SimpleSchema from 'simpl-schema';
import { duplicateCheck } from 'reducers/helpers/duplicateCheckHelpers';
import {
  setDuplicateEmploymentAddressList,
  setDuplicateModal,
  setDuplicateModalMessage,
} from 'reducers/ui/UiModalReducer';

const validationContext = new SimpleSchema({
  addressNumber: SimpleSchema.Integer,
}).newContext();
const TabEmployment = (props) => {
  /** region Component Variables and Props */
  const {
    partiesEmploymentForm,
    updateIncPartiesEmploymentForm,
    codeStreetTypes,
    codeStreetDirections,
    codeStates,
    getCodeStreetType,
    getCodeStreetDirections,
    getCodeStates,
    getCodeCities,
    codeCities,
    wsClient,
    changeOptions,
    addressOptions,
    selectedAddress,
    changeSelectedOption,
    uiDuplicateModalData,
    setDuplicateModal,
    setDuplicateModalMessage,
    setDuplicateEmploymentAddressList,
  } = props;
  const { duplicateListOfAddressForContact, duplicateListOfPerson } = uiDuplicateModalData;
  const [changeKey, setChangeKey] = React.useState(0);
  const [partiesEmploymentFormState, setPartiesEmploymentFormState] = React.useState({
    isValid: false,
    values: {},
    touched: {},
    errors: {},
  });
  const [firstLoad, setFirstLoad] = React.useState(true);
  const [errors, setErrors] = React.useState({});
  /** endregion */
  /** region React Hooks */
  React.useEffect(() => {
    const services = ['codeStreetTypes', 'codeStreetDirections', 'codeStates'];

    if (!wsClient.websocket) return;

    getCodeCities();

    services.forEach(async (svc) => {
      if (props[svc].length < 1) {
        const result = await wsClient.websocket.service(svc.toLowerCase()).find({
          query: {
            $limit: 0,
            $select: ['Code', 'Description'],
            IsDeleted: false,
            $sort: {
              Description: 1,
            },
          },
        });

        if (result.total > 0) {
          if (svc === 'codeStreetTypes') {
            getCodeStreetType(result.data);
          } else if (svc === 'codestreetdirections') {
            getCodeStreetDirections(result.data);
          } else if (svc === 'codestates') {
            getCodeStates(result.data);
          }
        }
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [wsClient]);

  React.useEffect(() => {
    setPartiesEmploymentFormState((partiesEmploymentFormState) => ({
      ...partiesEmploymentFormState,
      ...partiesEmploymentForm,
      values: {
        ...partiesEmploymentFormState.values,
        ...partiesEmploymentForm.values,
      },
    }));
  }, [partiesEmploymentForm]);

  React.useEffect(() => {
    if (typeof partiesEmploymentFormState.values?.city === 'string') {
      partiesEmploymentFormState.values.cityId = getCityIdFromCityName(
        partiesEmploymentFormState.values?.city
      );
    }
    const fullAddressText = setFullAddressText(partiesEmploymentFormState);
    setPartiesEmploymentFormState((partiesContactFormState) => ({
      ...partiesContactFormState,
      values: {
        ...partiesContactFormState.values,
        fullAddressText: fullAddressText,
      },
    }));

    let timeoutId;
    if (
      !firstLoad &&
      partiesEmploymentFormState.values?.addressNumber &&
      partiesEmploymentFormState.values?.addressNumber !== ''
    ) {
      validationContext.validate({
        addressNumber: Number(partiesEmploymentFormState.values?.addressNumber),
      });

      if (validationContext.isValid() === false) {
        const errs = validationContext.validationErrors();
        let e = {};
        errs.map((er) => {
          e[er.name] = `Value must be ${er.dataType}`;
          setErrors(e);
        });
      } else {
        setErrors({});
      }

      timeoutId = setTimeout(() => {
        if (Object.keys(partiesEmploymentFormState.values).length !== 0) duplicateFinder();
      }, 2000);
    }
    setFirstLoad(false);

    updateIncPartiesEmploymentForm(partiesEmploymentFormState);
    return () => clearTimeout(timeoutId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    partiesEmploymentFormState.values?.placeName,
    partiesEmploymentFormState.values?.incidentId,
    partiesEmploymentFormState.values?.personId,
    partiesEmploymentFormState.values?.addressId,
    partiesEmploymentFormState.values?.cityId,
    partiesEmploymentFormState.values?.addressNumberPrefix,
    partiesEmploymentFormState.values?.addressNumber,
    partiesEmploymentFormState.values?.addressNumberSuffix,
    partiesEmploymentFormState.values?.preDirection,
    partiesEmploymentFormState.values?.streetName,
    partiesEmploymentFormState.values?.streetType,
    partiesEmploymentFormState.values?.postDirection,
    partiesEmploymentFormState.values?.city,
    partiesEmploymentFormState.values?.state,
    partiesEmploymentFormState.values?.postalCode,
    partiesEmploymentFormState.values?.postalCodeExtension,
    partiesEmploymentFormState.values?.county,
    partiesEmploymentFormState.values?.country,
    partiesEmploymentFormState.values?.fullAddressText,
    partiesEmploymentFormState.values?.textmask1,
    partiesEmploymentFormState.values?.textmask2,
    partiesEmploymentFormState.values?.emailmask,
    partiesEmploymentFormState.values?.notes,
  ]);
  /** endregion */
  /** region Helper Functions */
  const duplicateFinder = async () => {
    let duplicateFound = !partiesEmploymentFormState.ptsAddressId
      ? await duplicateCheck(partiesEmploymentFormState, 'address', 'employment')
      : false;
    if (duplicateFound) {
      setDuplicateModal(true);
      setDuplicateModalMessage('Duplciate Found');
    } else {
      setDuplicateEmploymentAddressList([]);
      if (duplicateListOfPerson.length > 0 || duplicateListOfAddressForContact.length > 0) {
        setDuplicateModal(true);
      } else {
        setDuplicateModal(false);
      }
    }
  };
  
  const onReset = () => {
    clearPlace(setPartiesEmploymentFormState);

    setPartiesEmploymentFormState((partiesContactFormState) => ({
      ...partiesContactFormState,
      ptsAddressId: null,
      values: {
        ...partiesContactFormState.values,
        addressNumberPrefix: '',
        addressNumber: '',
        addressNumberSuffix: '',
        streetName: '',
        preDirection: '',
        postDirection: '',
        city: '',
        state: '',
        county: '',
        country: '',
        postalCode: '',
        postalCodeExtension: '',
        fullAddressText: '',
        streetType: '',
        notes: '',
      },
    }));

    setPartiesEmploymentFormState(initFormModel);

    changeSelectedOption(null);
  };

  const onChange = (place, setFormState, setChangeKey, changeKey) => {
    onPlaceValueChange(place, setFormState, setChangeKey, changeKey, true);
  };
  /** endregion */
  return (
    <Grid container justify="center" xs={12} className="p-3">
      <Grid item xs={12}>
        <AddressSearch
          ptsPlaces={true}
          ptsAddresses={false}
          googleAddresses={false}
          onPlaceValueSet={onChange}
          onAddressValueSet={onChange}
          setFormState={setPartiesEmploymentFormState}
          label={'Search Places'}
          setChangeKey={setChangeKey}
          changeKey={changeKey}
          defaultLocation={null}
          onReset={onReset}
          addressOptions={addressOptions}
          changeOptions={changeOptions}
          changeSelectedOption={changeSelectedOption}
          selectedAddress={selectedAddress}
        />
      </Grid>
      <Grid item xs={12} className="mt-4 mb-2">
        <span style={{ color: '#fff', background: '#1976d2' }} className="mb-1 badge badge-primary">
          Address Details
        </span>
        <Divider />
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={3} lg={3}>
          <TextField
            id="standard-textarea-employment-notes"
            autoComplete="hidden"
            size="small"
            placeholder="Prefix"
            label="Prefix"
            variant="outlined"
            name="prefix"
            key={changeKey}
            value={partiesEmploymentFormState.values.addressNumberPrefix}
            onChange={(event) =>
              handleFormChangeNew(
                event,
                event.target.value,
                'none',
                'addressNumberPrefix',
                setPartiesEmploymentFormState
              )
            }
            fullWidth
            multiline
            InputLabelProps={{ shrink: true }}
          />
        </Grid>
        <Grid item xs={3} lg={3}>
          <TextField
            id="standard-textarea-employment-addressNumber"
            autoComplete="hidden"
            size="small"
            placeholder="Number"
            label="Number"
            variant="outlined"
            name="number"
            key={changeKey}
            error={errors['addressNumber'] ? true : false}
            helperText={errors['addressNumber']}
            value={partiesEmploymentFormState.values.addressNumber}
            onChange={(event) =>
              handleFormChangeNew(
                event,
                event.target.value,
                'none',
                'addressNumber',
                setPartiesEmploymentFormState
              )
            }
            fullWidth
            multiline
            InputLabelProps={{ shrink: true }}
          />
        </Grid>
        <Grid item xs={3} lg={3}>
          <TextField
            id="standard-textarea-employment-addressNumberSuffix"
            autoComplete="hidden"
            size="small"
            placeholder="Suffix"
            label="Suffix"
            variant="outlined"
            name="suffix"
            key={changeKey}
            value={partiesEmploymentFormState.values.addressNumberSuffix}
            onChange={(event) =>
              handleFormChangeNew(
                event,
                event.target.value,
                'none',
                'addressNumberSuffix',
                setPartiesEmploymentFormState
              )
            }
            fullWidth
            multiline
            InputLabelProps={{ shrink: true }}
          />
        </Grid>
        <Grid item xs={3} lg={3}>
          <RMSAutoComplete
            size="small"
            autoComplete
            autoSelect
            autoHighlight
            id="combo-address-predirectional"
            options={codeStreetDirections}
            label="Predirectional"
            serviceName="codestreetdirections"
            title="Code"
            stateHolderAttribute="preDirection"
            variant="outlined"
            stateHolder={partiesEmploymentFormState}
            setStateHolder={setPartiesEmploymentFormState}
            setField={handleFormChangeNew}
          />
        </Grid>
        <Grid item xs={3} lg={3}>
          <TextField
            id="standard-textarea-employment-streetName"
            autoComplete="hidden"
            size="small"
            placeholder="Street Name"
            label="Street Name"
            variant="outlined"
            name="streetName"
            key={changeKey}
            value={partiesEmploymentFormState.values.streetName}
            onChange={(event) =>
              handleFormChangeNew(
                event,
                event.target.value,
                'none',
                'streetName',
                setPartiesEmploymentFormState
              )
            }
            fullWidth
            multiline
            InputLabelProps={{ shrink: true }}
          />
        </Grid>
        <Grid item xs={3} lg={3}>
          <RMSAutoComplete
            size="small"
            autoComplete
            autoSelect
            autoHighlight
            id="combo-address-type"
            options={codeStreetTypes}
            label="Type"
            serviceName="codestreettypes"
            title="Code"
            stateHolderAttribute="streetType"
            variant="outlined"
            stateHolder={partiesEmploymentFormState}
            setStateHolder={setPartiesEmploymentFormState}
            setField={handleFormChangeNew}
          />
        </Grid>
        <Grid item xs={3} lg={3}>
          <RMSAutoComplete
            size="small"
            autoComplete
            autoSelect
            autoHighlight
            id="combo-address-postdirectional"
            options={codeStreetDirections}
            label="Postdirectional"
            serviceName="codestreetdirections"
            title="Code"
            stateHolderAttribute="postDirection"
            variant="outlined"
            stateHolder={partiesEmploymentFormState}
            setStateHolder={setPartiesEmploymentFormState}
            setField={handleFormChangeNew}
          />
        </Grid>

        <Grid item xs={3} lg={3}>
          <RMSAutoComplete
            size="small"
            autoComplete
            autoSelect
            autoHighlight
            id="combo-address-city"
            options={codeCities}
            label="City"
            serviceName="codecities"
            title="CityDescription"
            stateHolderAttribute="city"
            variant="outlined"
            stateHolder={partiesEmploymentFormState}
            setStateHolder={setPartiesEmploymentFormState}
            setField={handleFormChangeNew}
          />
        </Grid>
      </Grid>
      <Grid container spacing={2} className="mt-2">
        <Grid item xs={3} lg={3}>
          <RMSAutoComplete
            size="small"
            autoComplete
            autoSelect
            autoHighlight
            id="combo-address-state"
            options={codeStates}
            label="State"
            serviceName="codestates"
            title="Code"
            stateHolderAttribute="state"
            variant="outlined"
            stateHolder={partiesEmploymentFormState}
            setStateHolder={setPartiesEmploymentFormState}
            setField={handleFormChangeNew}
          />
        </Grid>
        <Grid item xs={3} lg={3}>
          <TextField
            id="standard-textarea-employment-postalCode"
            autoComplete="hidden"
            size="small"
            placeholder="Postal Code"
            label="Postal Code"
            variant="outlined"
            name="postalCode"
            key={changeKey}
            value={partiesEmploymentFormState.values.postalCode}
            onChange={(event) =>
              handleFormChangeNew(
                event,
                event.target.value,
                'none',
                'postalCode',
                setPartiesEmploymentFormState
              )
            }
            fullWidth
            multiline
            InputLabelProps={{ shrink: true }}
          />
        </Grid>
        <Grid item xs={2} lg={2}>
          <TextField
            id="standard-textarea-employment-postalCodeExtension"
            autoComplete="hidden"
            size="small"
            placeholder="Extension"
            label="Extension"
            variant="outlined"
            name="extension"
            key={changeKey}
            value={partiesEmploymentFormState?.values.postalCodeExtension}
            onChange={(event) =>
              handleFormChangeNew(
                event,
                event.target.value,
                'none',
                'postalCodeExtension',
                setPartiesEmploymentFormState
              )
            }
            fullWidth
            multiline
            InputLabelProps={{ shrink: true }}
          />
        </Grid>
        <Grid item xs={2} lg={2}>
          <TextField
            id="standard-textarea-employment-county"
            autoComplete="hidden"
            size="small"
            placeholder="County"
            label="County"
            variant="outlined"
            name="county"
            key={changeKey}
            value={partiesEmploymentFormState.values.county}
            onChange={(event) =>
              handleFormChangeNew(
                event,
                event.target.value,
                'none',
                'county',
                setPartiesEmploymentFormState
              )
            }
            fullWidth
            multiline
            InputLabelProps={{ shrink: true }}
          />
        </Grid>
        <Grid item xs={2} lg={2}>
          <TextField
            id="standard-textarea-employment-country"
            autoComplete="hidden"
            size="small"
            label="Country"
            placeholder="Country"
            variant="outlined"
            name="country"
            key={changeKey}
            value={partiesEmploymentFormState.values.country}
            onChange={(event) =>
              handleFormChangeNew(
                event,
                event.target.value,
                'none',
                'country',
                setPartiesEmploymentFormState
              )
            }
            fullWidth
            multiline
            InputLabelProps={{ shrink: true }}
          />
        </Grid>
      </Grid>
      <Grid item xs={12} className="mt-4 mb-2">
        <span style={{ color: '#fff', background: '#1976d2' }} className="mb-1 badge badge-primary">
          Contacts
        </span>
        <Divider />
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={12} lg={4}>
          <TextField
            autoComplete="hidden"
            size="small"
            fullWidth
            variant="outlined"
            placeholder="Primary Phone"
            label="Primary Phone"
            name="textmask1"
            key={changeKey}
            value={partiesEmploymentFormState.values?.textmask1 || ''}
            onChange={(event) =>
              handleFormChangeNew(
                event,
                event.target.value,
                'none',
                'textmask1',
                setPartiesEmploymentFormState
              )
            }
            id="formatted-numberformat-input"
            InputProps={{
              inputComponent: PhoneMask,
            }}
            multiline
            InputLabelProps={{ shrink: true }}
          />
        </Grid>
        <Grid item xs={12} lg={4}>
          <TextField
            autoComplete="hidden"
            size="small"
            fullWidth
            variant="outlined"
            placeholder="Secondary Phone"
            label="Secondary Phone"
            name="textmask2"
            key={changeKey}
            value={partiesEmploymentFormState.values?.textmask2 || ''}
            onChange={(event) =>
              handleFormChangeNew(
                event,
                event.target.value,
                'none',
                'textmask2',
                setPartiesEmploymentFormState
              )
            }
            id="formatted-numberformat-input-secondary"
            InputProps={{ inputComponent: PhoneMask }}
            multiline
            InputLabelProps={{ shrink: true }}
          />
        </Grid>
        <Grid item xs={12} lg={4}>
          <TextField
            id="standard-textarea-employment-emailMask"
            autoComplete="hidden"
            size="small"
            variant="outlined"
            fullWidth
            placeholder="pts@pts.com"
            label="pts@pts.com"
            {...props}
            name="emailmask"
            key={changeKey}
            value={partiesEmploymentFormState.values.emailmask || ''}
            onChange={(event) =>
              handleFormChangeNew(
                event,
                event.target.value,
                'none',
                'emailmask',
                setPartiesEmploymentFormState
              )
            }
            multiline
            InputLabelProps={{ shrink: true }}
          />
        </Grid>
      </Grid>
      <Grid item lg={1} />
      <Grid item xs={12} className="mt-4 mb-2">
        <span style={{ color: '#fff', background: '#1976d2' }} className="mb-1 badge badge-primary">
          Notes
        </span>
        <Divider />
      </Grid>
      <Grid item xs={12}>
        <TextField
          autoComplete="hidden"
          size="small"
          className="mt-3"
          id="outlined-multiline-static-address-notes"
          placeholder="Notes"
          label="Notes"
          multiline
          rows="7"
          name="notes"
          key={changeKey}
          value={partiesEmploymentFormState.values.notes}
          onChange={(event) =>
            handleFormChangeNew(
              event,
              event.target.value,
              'none',
              'notes',
              setPartiesEmploymentFormState
            )
          }
          fullWidth
          variant="outlined"
          InputLabelProps={{ shrink: true }}
        />
      </Grid>
    </Grid>
  );
};

const mapStateToProps = (state) => ({
  wsClient: state.websocket,
  partiesEmploymentForm: state.incident.partiesEmploymentForm,
  codeStreetTypes: state.codeStreetTypes,
  codeStreetDirections: state.codeStreetDirections,
  codeStates: state.codeStates,
  codeCities: state.codesOthers.codeCities,
  uiDuplicateModalData: state.uiModal,
});

export default connect(mapStateToProps, {
  updateIncPartiesEmploymentForm,
  getCodeStreetType,
  getCodeStreetDirections,
  getCodeStates,
  getCodeCities,
  setDuplicateModal,
  setDuplicateModalMessage,
  setDuplicateEmploymentAddressList,
})(TabEmployment);
