export const GET_CODE_MATERIAL = 'GET_CODE_MATERIAL';
export const ADD_CODE_MATERIAL = 'ADD_CODE_MATERIAL';

export const addCodeMaterial = (newVal) => {
  return { type: ADD_CODE_MATERIAL, payload: newVal };
};

export const getCodeMaterial = (state) => {
  return async (dispatch) => {
    return dispatch({ type: GET_CODE_MATERIAL, payload: state });
  };
};

export default function reducer(state = [], action) {
  switch (action.type) {
    case GET_CODE_MATERIAL:
      state = action.payload;
      return state;
    case ADD_CODE_MATERIAL:
      return [...state, action.payload];
    default:
      return state;
  }
}
