export const GET_CODE_LOCATION_CATEGORIES = 'GET_CODE_LOCATION_CATEGORIES';
export const ADD_CODE_LOCATION_CATEGORIES = 'ADD_CODE_LOCATION_CATEGORIES';

export const getCodeLocationCategories = (state) => {
  return async (dispatch) => {
    return dispatch({ type: GET_CODE_LOCATION_CATEGORIES, payload: state });
  };
};

export const addCodeLocationCategories = (state) => {
  return { type: ADD_CODE_LOCATION_CATEGORIES, payload: state };
};

export default function reducer(state = [], action) {
  switch (action.type) {
    case GET_CODE_LOCATION_CATEGORIES:
      state = action.payload;
      return state;
    case ADD_CODE_LOCATION_CATEGORIES:
      state = [...state, action.payload];
      return state;
    default:
      return state;
  }
}
