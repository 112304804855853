const status = {
  PENDING: 'pending',
  APPROVED: 'approved',
  DISPOSED: 'disposed',
  SUBMITTED: 'submitted',
};

const testData = {
  tasks: {
    'task-1': {
      id: 'task-1',
      name: 'SIMPLE ASSAULT',
      address: '123 Main St. Main, MN',
      incidentId: '000100',
      status: status.SUBMITTED,
      flags: ['isJuvenile'],
      updated: '02/20/2020',
      updatedBy: 'PTS',
      isCadEvent: false,
    },
    'task-2': {
      id: 'task-2',
      name: 'INTIMIDATION',
      address: '456 Side St. Downtown, MN',
      incidentId: '000101',
      status: status.PENDING,
      flags: ['isJuvenile', 'isDomesticViolence'],
      updated: '02/15/2020',
      updatedBy: 'PTS',
      isCadEvent: false,
    },
    'task-3': {
      id: 'task-3',
      name: 'ASSAULT',
      address: '865 Up Blvd. Uptown, MN',
      incidentId: '000102',
      status: status.DISPOSED,
      flags: ['isDomesticViolence'],
      updated: '02/07/2020',
      updatedBy: 'PTS',
      isCadEvent: true,
    },
    'task-4': {
      id: 'task-4',
      name: 'ARSON',
      address: '20 Down Road. Somewhere, MN',
      incidentId: '000103',
      status: status.APPROVED,
      flags: [],
      updated: '02/05/2020',
      updatedBy: 'PTS',
      isCadEvent: true,
    },
    'task-5': {
      id: 'task-5',
      name: 'BURGLARY/BREAKING AND ENTERING',
      address: '777 Jackpot St. Faraway, MN',
      incidentId: '000104',
      status: status.PENDING,
      flags: [],
      updated: '01/31/2020',
      updatedBy: 'PTS',
      isCadEvent: false,
    },
    'task-6': {
      id: 'task-6',
      name: 'COUNTERFEITING/FORGERY',
      address: '12 Back St. Boys, MN',
      incidentId: '000105',
      status: status.APPROVED,
      flags: [],
      updated: '01/07/2020',
      updatedBy: 'PTS',
      isCadEvent: false,
    },
    'task-7': {
      id: 'task-7',
      name: 'IMPERSONATION',
      address: '90 Money Blvd. Main, MN',
      incidentId: '000106',
      status: status.SUBMITTED,
      flags: [],
      updated: '01/02/2020',
      updatedBy: 'PTS',
      isCadEvent: false,
    },
    'task-8': {
      id: 'task-8',
      name: 'SIMPLE ASSAULT',
      address: '111 Lost Road Down, MN',
      incidentId: '000107',
      status: status.SUBMITTED,
      flags: [],
      updated: '01/02/2020',
      updatedBy: 'PTS',
      isCadEvent: false,
    },
    'task-9': {
      id: 'task-9',
      name: 'ASSAULT',
      address: '2390 Ouch Blvd. Back, MN',
      incidentId: '000108',
      status: status.PENDING,
      flags: [],
      updated: '01/02/2020',
      updatedBy: 'PTS',
      isCadEvent: false,
    },
    'task-10': {
      id: 'task-10',
      name: 'IMPERSONATION',
      address: '50 Lying Road Front, MN',
      incidentId: '000109',
      status: status.DISPOSED,
      flags: [],
      updated: '01/02/2020',
      updatedBy: 'PTS',
      isCadEvent: false,
    },
    'task-11': {
      id: 'task-11',
      name: 'BURGLARY/BREAKING AND ENTERING',
      address: '777 Jackpot Lane Lucky, MN',
      incidentId: '000110',
      status: status.SUBMITTED,
      flags: [],
      updated: '01/02/2020',
      updatedBy: 'PTS',
      isCadEvent: false,
    },
  },
  columns: {
    'column-1': {
      id: 'column-1',
      title: 'Pending',
      taskIds: ['task-1', 'task-2', 'task-3', 'task-4'],
    },
    'column-2': {
      id: 'column-2',
      title: 'Approved',
      taskIds: ['task-5', 'task-6'],
    },
    'column-3': {
      id: 'column-3',
      title: 'Submitted',
      taskIds: ['task-7', 'task-8', 'task-9', 'task-10'],
    },
    'column-4': {
      id: 'column-4',
      title: 'Disposed',
      taskIds: ['task-11'],
    },
  },
  // Facilitate reordering of the columns
  columnOrder: ['column-1', 'column-2', 'column-3', 'column-4'],
};

export default testData;
