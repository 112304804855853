import SimpleSchema from 'simpl-schema';
import { codeInjuryCategory } from '../../reducers/_dev/codeInjuryCategory';
import { codeVictimCategory } from '../../reducers/_dev/codeVictimCategory';
import { codeAggravatedAssault } from 'reducers/_dev/codeAggravatedAssault';

import {
  crimeAgainstPersonCodes,
  crimeAgainstPropertyCodes,
  crimeAgainstSocietyCodes,
} from '../helpers/Codes/UCR';

SimpleSchema.setDefaultMessages({
  messages: {
    en: {
      'NIBRS-2019.2-141':
        'A Person Injury can only be provided when the associated Offense code is (100,11A,11B,11C,11D,120,13A,13B,210,64A,64B).',
      'NIBRS-2019.2-143':
        'When the Type of Victim is not I or L, then Injury code must be omitted.',
      'NIBRS-2019.2-146':
        'When the associated Offense code is 13B, then the Injury types must be M or N.',
      'NIBRS-2019.2-115': 'Type of Victim is required when Victim is provided.',
      'NIBRS-2019.2-116':
        'Type of Victim must be (I, L) when the Offense Code is a Crime Against Person.',
      'NIBRS-2019.2-117':
        'Type of Victim must be "S" when the Offense Code is a Crime Against Society.',
      'NIBRS-2019.2-118':
        'Type of Victim can not be "S" when the Offense Code is a Crime Against Property.',
      'NIBRS-2019.2-119':
        'Type of Victim can not be "L" unless the Offense Code is (09A, 13A, 13B, or 13C).',
      'NIBRS-2019.2-133':
        'The Victim Aggravated Assault/Homicide Circumstances can only be provided when the associated Offense is (09A,09B,09C,13A).',
      'NIBRS-2019.2-121':
        'When the Type of Victim is L then Officer Activity, Officer Assignment, Age, Sex, and Race must be provided.',
    },
  },
});

const getVictimCategory = (codeVictimCategory, cat) =>
  codeVictimCategory.find((category) => category.description === cat);

const getOffenseCode = (codeOffenseCodes, code) =>
  codeOffenseCodes.find((codeOffenseCode) => codeOffenseCode === code);

export const incVictimSchema = new SimpleSchema({
  aggravatedAssaultHomicide: {
    type: String,
    optional: true,
    custom() {
      let aggravatedAssaultHomicideCode = codeAggravatedAssault.find(
        (assault) => assault.description === this.value
      );
      // NIBRS-2019.2-133: Aggravated Assault/Homicide Circumstances
      if (!['09A', '09B', '09C', '13A'].includes(this.obj.offense.values.statuteDetails?.FBICode)) {
        if (this.value) {
          return 'NIBRS-2019.2-133';
        }
      }
    },
  },
  injuryType: {
    type: String,
    label: 'Person Injury',
    optional: true,
    custom() {
      if (this.value) {
        // NIBRS-2019.2-143
        if (this.obj.incVictim.values.category) {
          let codeVictimCategoryType = getVictimCategory(
            codeVictimCategory,
            this.obj.incVictim.values.category
          );

          if (!['I', 'L'].includes(codeVictimCategoryType.code)) {
            return 'NIBRS-2019.2-143';
          }
        }

        let codeVictimInjury = codeInjuryCategory.find(
          (injuryType) => injuryType.description === this.value
        );

        // NIBRS-2019.2-146
        if (this.obj.offense.values.statuteDetails?.FBICode === '13B') {
          if (codeVictimInjury.code !== 'M' && codeVictimInjury.code !== 'N')
            return 'NIBRS-2019.2-146';
        }

        // NIBRS-2019.2-141
        if (
          !['100', '11A', '11B', '11C', '11D', '120', '13A', '13B', '210', '64A', '64B'].find(
            (element) => element === this.obj.offense.values.statuteDetails?.FBICode
          )
        ) {
          return 'NIBRS-2019.2-141';
        }
      }
    },
  },
  category: {
    type: String,
    label: 'Victim Category',
    optional: true,
    custom() {
      if (!this.value) {
        return 'NIBRS-2019.2-115';
      }

      // NIBRS-2019.2-116
      if (
        getOffenseCode(crimeAgainstPersonCodes, this.obj.offense.values.statuteDetails?.FBICode)
      ) {
        let codeVictimCategoryType = getVictimCategory(
          codeVictimCategory,
          this.obj.incVictim.values.category
        );

        if (!['I', 'L'].includes(codeVictimCategoryType.code)) {
          return 'NIBRS-2019.2-116';
        }
      }

      // NIBRS-2019.2-117
      if (
        getOffenseCode(crimeAgainstSocietyCodes, this.obj.offense.values.statuteDetails?.FBICode)
      ) {
        let codeVictimCategoryType = getVictimCategory(
          codeVictimCategory,
          this.obj.incVictim.values.category
        );
        if (codeVictimCategoryType.code !== 'S') {
          return 'NIBRS-2019.2-117';
        }
      }

      // NIBRS-2019.2-118
      if (
        getOffenseCode(crimeAgainstPropertyCodes, this.obj.offense.values.statuteDetails?.FBICode)
      ) {
        let codeVictimCategoryType = getVictimCategory(
          codeVictimCategory,
          this.obj.incVictim.values.category
        );
        if (codeVictimCategoryType.code === 'S') {
          return 'NIBRS-2019.2-118';
        }
      }

      // NIBRS-2019.2-121: OfficerActivity
      if (getVictimCategory(codeVictimCategory, this.value).code === 'L') {
        if (
          !this.obj.incVictim.values.officerActivity ||
          !this.obj.incVictim.values.officerAssignment
        ) {
          return 'NIBRS-2019.2-121';
        }
      }

      // NIBRS-2019.2-119: Victim Type
      if (getVictimCategory(codeVictimCategory, this.value).code === 'L') {
        if (
          !['09A', '13A', '13B', '13C'].includes(this.obj.offense.values.statuteDetails?.FBICode)
        ) {
          return 'NIBRS-2019.2-119';
        }
      }
    },
  },
});
