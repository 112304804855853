/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */
import React from 'react';
import { connect } from 'react-redux';

import { v4 as uuidv4 } from 'uuid';
import SimpleSchema from 'simpl-schema';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import { Divider } from '@material-ui/core';
import Checkbox from '@material-ui/core/Checkbox';
import Autocomplete from '@material-ui/lab/Autocomplete';
import FormControlLabel from '@material-ui/core/FormControlLabel';

import {
  updateIncPropertySubstanceForm,
  updateIncEvidenceItemForm,
} from '../../../reducers/IncReducer';
import { getCodeSubstanceCategory } from '../../../reducers/dictionaries/CodeSubstanceCategoryReducer';
import {
  getCodeDEADrugClasses,
  getCodeDrugMeasurements,
} from '../../../reducers/dictionaries/CodeOthersReducer';
import { handleFormChangeNew } from '../../../reducers/helpers/formHelpers';
import { checkFormType } from '../../helpers/checkFormState';
import RMSAutoComplete from 'components/RMSAutoComplete/RMSAutoComplete';
import RMSAttachments from 'components/RMSAttachments';

import { validatePropertiesForm } from 'simpl-schema-validation/ValidateForms';

const validationContext = new SimpleSchema({
  substanceDaysSupply: SimpleSchema.Integer,
  quantity: SimpleSchema.Integer,
}).newContext();

const prescribePlace = [{ name: 'place 1' }, { name: 'place 2' }];
const prescribePerson = [{ name: 'doctor 1' }, { name: 'doctor 2' }];

const TabSubstance = (props) => {
  /** Component Variables and Props */
  const {
    updateIncPropertySubstanceForm,
    updateIncEvidenceItemForm,
    codeSubstanceCategory,
    getCodeSubstanceCategory,
    getCodeDEADrugClasses,
    codeDrugMeasurements,
    getCodeDrugMeasurements,
    codeDEADrugClasses,
    propertySubstanceForm,
    wsClient,
    history,
  } = props;
  const [changeKey, setChangeKey] = React.useState(0);
  const [propertySubstanceFormState, setPropertySubstanceFormState] = React.useState({
    tempPropId: uuidv4(),
    isValid: false,
    changes: false,
    values: {},
    touched: {},
    errors: {},
    formType: 'edit',
  });
  const [firstLoad, setFirstLoad] = React.useState(true);
  const [pageType, setPageType] = React.useState('add');
  const [entityType, setEntityType] = React.useState('');
  const [errors, setErrors] = React.useState({});
  /** endregion */
  /** region React Hooks */
  React.useEffect(() => {
    checkFormType(
      propertySubstanceFormState.ptsSubstanceId !== undefined &&
        propertySubstanceFormState.ptsSubstanceId !== null,
      setPropertySubstanceFormState,
      history
    );
    const parts = history.location.pathname.split('/');
    let type = '';
    if (parts.length === 4) type = parts[parts.length - 2];
    if (parts.length === 5) type = parts[parts.length - 3];

    setEntityType(parts[1]);
    setPageType(type);
  }, []);

  React.useEffect(() => {
    if (wsClient.websocket) {
      getCodeDEADrugClasses();
      getCodeDrugMeasurements();
    }
  }, [getCodeDEADrugClasses, getCodeDrugMeasurements, wsClient]);

  React.useEffect(() => {
    const services = ['codeSubstanceCategory'];

    if (!wsClient.websocket) return;

    services.forEach(async (svc) => {
      if (props[svc].length < 1) {
        const result = await wsClient.websocket.service(svc.toLowerCase()).find({
          query: {
            $limit: 0,
            $select: ['Code', 'Description'],
            IsDeleted: false,
          },
        });

        if (result.total > 0) {
          getCodeSubstanceCategory(result.data);
        }
      }
    });
  }, [wsClient]);

  React.useEffect(() => {
    checkFormType(
      propertySubstanceFormState.ptsSubstanceId !== undefined &&
        propertySubstanceFormState.ptsSubstanceId !== null,
      setPropertySubstanceFormState,
      history
    );
    setPropertySubstanceFormState((propertySubstanceFormState) => ({
      ...propertySubstanceFormState,
      ...propertySubstanceForm,
      values: {
        ...propertySubstanceFormState.values,
        ...propertySubstanceForm.values,
      },
    }));
  }, [propertySubstanceForm]);

  React.useEffect(() => {
    if (!firstLoad) {
      validationContext.validate({
        substanceDaysSupply:
          propertySubstanceFormState.values?.substanceDaysSupply?.length > 0
            ? Number(propertySubstanceFormState.values?.substanceDaysSupply)
            : 0,
        quantity:
          propertySubstanceFormState.values?.quantity?.length > 0
            ? Number(propertySubstanceFormState.values?.quantity)
            : 0,
      });

      if (validationContext.isValid() === false) {
        const errs = validationContext.validationErrors();
        let e = {};
        errs.map((er) => {
          e[er.name] = `Value must be ${er.dataType}`;
          console.log(e);
          // setErrors(e);
        });
      } else {
        setErrors({});
      }
    }
    setFirstLoad(false);

    propertySubstanceFormState.changes = true;
    updateIncPropertySubstanceForm(propertySubstanceFormState);

    if (propertySubstanceFormState.values.isEvidence) {
      updateIncEvidenceItemForm({
        values: {
          description: `${propertySubstanceFormState.values?.name || ''} ${
            propertySubstanceFormState.values?.description || ''
          } ${propertySubstanceFormState.values?.make || ''}`,
          offenses: [],
          category: 'Property',
          substanceType: propertySubstanceFormState.values?.substanceType,
        },
      });
    }

    let timeoutId;
    timeoutId = setTimeout(() => {
      let errorStatus = errors;

      let substanceQuantityErrors = validatePropertiesForm(
        'substanceQuantity',
        propertySubstanceFormState
      );
      if (substanceQuantityErrors && substanceQuantityErrors.length) {
        errorStatus = {
          ...errorStatus,
          quantity: `${substanceQuantityErrors[0].message}`,
        };
      } else {
        errorStatus = {
          ...errorStatus,
          quantity: null,
        };
      }

      let substanceMeasureErrors = validatePropertiesForm(
        'quantityMeasure',
        propertySubstanceFormState
      );
      console.log(substanceMeasureErrors);
      if (substanceMeasureErrors && substanceMeasureErrors.length) {
        errorStatus = {
          ...errorStatus,
          quantity: null,
          quantityMeasure: `${substanceMeasureErrors[0].message}`,
        };
      } else {
        errorStatus = {
          ...errorStatus,
          quantity: null,
          quantityMeasure: null,
        };
      }

      let substanceTypeErrors = validatePropertiesForm('substanceType', propertySubstanceFormState);
      if (substanceTypeErrors && substanceTypeErrors.length) {
        errorStatus = {
          ...errorStatus,
          quantity: null,
          quantityMeasure: null,
          substanceType: `${substanceTypeErrors[0].message}`,
        };
      } else {
        errorStatus = {
          ...errorStatus,
          quantity: null,
          quantityMeasure: null,
          substanceType: null,
        };
      }

      console.log('Errors: ', errorStatus);

      setErrors(errorStatus);
    }, 2000);
    return () => clearTimeout(timeoutId);
  }, [
    propertySubstanceFormState.values?.incidentId,
    propertySubstanceFormState.values?.substanceId,
    propertySubstanceFormState.values?.personId,
    propertySubstanceFormState.values?.placeId,
    propertySubstanceFormState.values?.description,
    propertySubstanceFormState.values?.substanceType,
    propertySubstanceFormState.values?.quantity,
    propertySubstanceFormState.values?.quantityMeasure,
    propertySubstanceFormState.values?.designator,
    propertySubstanceFormState.values?.container,
    propertySubstanceFormState.values?.composition,
    propertySubstanceFormState.values?.form,
    propertySubstanceFormState.values?.substanceDaysSupply,
    propertySubstanceFormState.values?.substanceStrength,
    propertySubstanceFormState.values?.deaClass,
    propertySubstanceFormState.values?.prescribingPlace,
    propertySubstanceFormState.values?.prescribingPerson,
    propertySubstanceFormState.values?.isEvidence,
    propertySubstanceFormState.values?.attachments,
  ]);

  React.useEffect(() => {
    if (!wsClient?.websocket) {
      return;
    }

    const getAttachments = async () => {
      const service = wsClient?.websocket.service('rms-attachments');
      service.timeout = 200000;

      const result = await service.find({
        query: {
          id:
            entityType === 'records'
              ? propertySubstanceFormState.ptsSubstanceId
              : propertySubstanceFormState.incIncidentPropertyId,
          type: entityType === 'records' ? 'SUBSTANCE' : 'INCIDENTPROPERTY',
        },
      });
      let atts = [];
      result.map((r) => {
        atts.push({
          ptsAttachmentId: r.ptsAttachmentID,
          name: r.FileName,
          type: r.FileType,
          dataURL: r.FileObject,
        });
      });
      setPropertySubstanceFormState((stateValue) => ({
        ...stateValue,
        values: {
          ...stateValue.values,
          attachments: atts,
        },
      }));
    };

    if (propertySubstanceFormState.ptsSubstanceId) {
      getAttachments();
    }
  }, [wsClient, propertySubstanceFormState.ptsSubstanceId]);
  /** endregion */
  return (
    <Grid item xs={12} lg={12} className="mt-2">
      {pageType === 'add' && entityType !== 'records' && (
        <Grid item xs={12} className="mb-2">
          <FormControlLabel
            control={
              <Checkbox
                key={propertySubstanceFormState.values?.isEvidence}
                checked={propertySubstanceFormState.values?.isEvidence}
                onChange={(event) =>
                  handleFormChangeNew(
                    event,
                    event.target.checked,
                    'none',
                    'isEvidence',
                    setPropertySubstanceFormState
                  )
                }
                name="isEvidence"
              />
            }
            label="Is Evidence?"
          />
        </Grid>
      )}
      <Grid item xs={12} className="mb-3">
        <TextField
          id="standard-textarea-substance-description"
          autoComplete="hidden"
          size="small"
          label="Description"
          placeholder="Description"
          variant="outlined"
          name="description"
          key={changeKey}
          defaultValue={propertySubstanceFormState.values?.description || ''}
          onBlur={(event) =>
            handleFormChangeNew(
              event,
              event.target.value,
              'none',
              'description',
              setPropertySubstanceFormState
            )
          }
          fullWidth
          multiline
          InputLabelProps={{ shrink: true }}
        />
      </Grid>
      <Grid xs={12} className="mt-2 mb-3">
        <span style={{ color: '#fff', background: '#1976d2' }} className="mb-1 badge badge-primary">
          Substance Info
        </span>
        <Divider />
      </Grid>
      <Grid container xs={12} spacing={2} justify="center" className="mb-2">
        <Grid item xs={4}>
          <RMSAutoComplete
            size="small"
            autoComplete
            autoSelect
            autoHighlight
            id="combo-substance-type"
            options={codeSubstanceCategory}
            error={errors['substanceType'] ? true : false}
            helperText={errors['substanceType']}
            label="Suspected Substance Type"
            serviceName="codesubstancecategory"
            title="Code"
            stateHolderAttribute="substanceType"
            variant="outlined"
            stateHolder={propertySubstanceFormState}
            setStateHolder={setPropertySubstanceFormState}
            setField={handleFormChangeNew}
          />
        </Grid>
        <Grid item xs={4}>
          <RMSAutoComplete
            size="small"
            autoComplete
            autoSelect
            autoHighlight
            id="combo-qty-measurement"
            options={codeDrugMeasurements}
            error={errors['quantityMeasure'] ? true : false}
            helperText={errors['quantityMeasure']}
            label="Substance Quantity Measurement"
            serviceName="codedrugmeasurements"
            title="Code"
            stateHolderAttribute="quantityMeasure"
            variant="outlined"
            stateHolder={propertySubstanceFormState}
            setStateHolder={setPropertySubstanceFormState}
            setField={handleFormChangeNew}
          />
        </Grid>
        <Grid item xs={4}>
          <TextField
            id="standard-textarea-substance-designator"
            autoComplete="hidden"
            size="small"
            label="Designator"
            placeholder="Designator"
            variant="outlined"
            name="designator"
            key={changeKey}
            defaultValue={propertySubstanceFormState.values?.designator || ''}
            onBlur={(event) =>
              handleFormChangeNew(
                event,
                event.target.value,
                'none',
                'designator',
                setPropertySubstanceFormState
              )
            }
            fullWidth
            multiline
            InputLabelProps={{ shrink: true }}
          />
        </Grid>

        <Grid item xs={4}>
          <TextField
            id="standard-textarea-substance-substanceDaysSupply"
            autoComplete="hidden"
            size="small"
            error={errors['substanceDaysSupply'] ? true : false}
            helperText={errors['substanceDaysSupply']}
            label="Substance Days Supply"
            placeholder="Substance Days Supply"
            variant="outlined"
            name="substanceDaysSupply"
            key={changeKey}
            value={propertySubstanceFormState.values?.substanceDaysSupply || ''}
            onChange={(event) =>
              handleFormChangeNew(
                event,
                event.target.value,
                'none',
                'substanceDaysSupply',
                setPropertySubstanceFormState
              )
            }
            fullWidth
            multiline
            InputLabelProps={{ shrink: true }}
          />
        </Grid>
        <Grid item xs={4}>
          <TextField
            id="standard-textarea-substance-substanceStrength"
            autoComplete="hidden"
            size="small"
            label="Substance Strength"
            placeholder="Substance Strength"
            variant="outlined"
            name="substanceStrength"
            key={changeKey}
            defaultValue={propertySubstanceFormState.values?.substanceStrength || ''}
            onBlur={(event) =>
              handleFormChangeNew(
                event,
                event.target.value,
                'none',
                'substanceStrength',
                setPropertySubstanceFormState
              )
            }
            fullWidth
            multiline
            InputLabelProps={{ shrink: true }}
          />
        </Grid>
        <Grid item xs={4}>
          <TextField
            id="standard-textarea-substance-quantity"
            autoComplete="hidden"
            size="small"
            error={errors['quantity'] ? true : false}
            helperText={errors['quantity']}
            label="Estimated Substance Quantity"
            placeholder="Estimated Substance Quantity"
            variant="outlined"
            name="quantity"
            key={changeKey}
            defaultValue={propertySubstanceFormState.values?.quantity || ''}
            onChange={(event) =>
              handleFormChangeNew(
                event,
                event.target.value,
                'none',
                'quantity',
                setPropertySubstanceFormState
              )
            }
            fullWidth
            multiline
            InputLabelProps={{ shrink: true }}
          />
        </Grid>
      </Grid>
      <Grid xs={12} className="mt-2 mb-3">
        <span style={{ color: '#fff', background: '#1976d2' }} className="mb-1 badge badge-primary">
          Others
        </span>
        <Divider />
      </Grid>
      <Grid container xs={12} spacing={2} className="mb-2" justify="center">
        <Grid item xs={4}>
          <TextField
            id="standard-textarea-substance-container"
            autoComplete="hidden"
            size="small"
            placeholder="Container"
            label="Container"
            variant="outlined"
            name="container"
            key={changeKey}
            defaultValue={propertySubstanceFormState.values?.container || ''}
            onBlur={(event) =>
              handleFormChangeNew(
                event,
                event.target.value,
                'none',
                'container',
                setPropertySubstanceFormState
              )
            }
            fullWidth
            multiline
            InputLabelProps={{ shrink: true }}
          />
        </Grid>
        <Grid item xs={4}>
          <TextField
            id="standard-textarea-substance-composition"
            autoComplete="hidden"
            size="small"
            label="Composition"
            placeholder="Composition"
            variant="outlined"
            name="composition"
            key={changeKey}
            defaultValue={propertySubstanceFormState.values?.composition || ''}
            onBlur={(event) =>
              handleFormChangeNew(
                event,
                event.target.value,
                'none',
                'composition',
                setPropertySubstanceFormState
              )
            }
            fullWidth
            multiline
            InputLabelProps={{ shrink: true }}
          />
        </Grid>
        <Grid item xs={4}>
          <RMSAutoComplete
            size="small"
            autoComplete
            autoSelect
            autoHighlight
            id="combo-substance-dea-class"
            options={codeDEADrugClasses}
            label="DEA Class"
            serviceName="codedeadrugclasses"
            title="Code"
            stateHolderAttribute="deaClass"
            variant="outlined"
            stateHolder={propertySubstanceFormState}
            setStateHolder={setPropertySubstanceFormState}
            setField={handleFormChangeNew}
          />
        </Grid>
      </Grid>
      <Grid container xs={12} spacing={2} justify="center">
        <Grid item xs={4}>
          <TextField
            id="standard-textarea-substance-form"
            autoComplete="hidden"
            size="small"
            label="Form"
            placeholder="Form"
            variant="outlined"
            name="form"
            key={changeKey}
            defaultValue={propertySubstanceFormState.values?.form || ''}
            onBlur={(event) =>
              handleFormChangeNew(
                event,
                event.target.value,
                'none',
                'form',
                setPropertySubstanceFormState
              )
            }
            fullWidth
            multiline
            InputLabelProps={{ shrink: true }}
          />
        </Grid>
        <Grid item xs={4}>
          <Autocomplete
            size="small"
            autoComplete
            autoSelect
            autoHighlight
            id="combo-substance-prescribing-place"
            getOptionLabel={(option) => option || ''}
            options={prescribePlace.map((s) => s.name)}
            key={propertySubstanceFormState.values?.prescribingPlace || ''}
            value={propertySubstanceFormState.values?.prescribingPlace || ''}
            onChange={(event, newValue) =>
              handleFormChangeNew(
                event,
                newValue,
                'none',
                'prescribingPlace',
                setPropertySubstanceFormState,
                'name'
              )
            }
            renderInput={(params) => (
              <TextField
                autoComplete="hidden"
                size="small"
                {...params}
                label="Prescribing Place"
                variant="outlined"
                fullWidth
              />
            )}
          />
        </Grid>
        <Grid item xs={4} className="mb-3">
          <Autocomplete
            size="small"
            autoComplete
            autoSelect
            autoHighlight
            id="combo-substance-prescribing-person"
            getOptionLabel={(option) => option}
            options={prescribePerson.map((s) => s.name)}
            key={propertySubstanceFormState.values?.prescribingPerson || ''}
            value={propertySubstanceFormState.values?.prescribingPerson || ''}
            onChange={(event, newValue) =>
              handleFormChangeNew(
                event,
                newValue,
                'none',
                'prescribingPerson',
                setPropertySubstanceFormState
              )
            }
            renderInput={(params) => (
              <TextField
                autoComplete="hidden"
                size="small"
                {...params}
                label="Prescribing Person"
                variant="outlined"
                fullWidth
              />
            )}
          />
        </Grid>
      </Grid>
      <RMSAttachments
        attachments={propertySubstanceFormState.values.attachments}
        stateHolder={propertySubstanceFormState}
        setStateHolder={setPropertySubstanceFormState}
        setField={handleFormChangeNew}
      />
    </Grid>
  );
};

const mapStateToProps = (state) => ({
  propertySubstanceForm: state.incident.propertySubstanceForm,
  wsClient: state.websocket,
  codeSubstanceCategory: state.codeSubstanceCategory,
  codeDrugMeasurements: state.codesOthers.codeDrugMeasurements,
  codeDEADrugClasses: state.codesOthers.codeDEADrugClasses,
});

export default connect(mapStateToProps, {
  updateIncPropertySubstanceForm,
  getCodeSubstanceCategory,
  getCodeDEADrugClasses,
  getCodeDrugMeasurements,
  updateIncEvidenceItemForm,
})(TabSubstance);
