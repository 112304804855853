import React, { useState } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation, useParams } from 'react-router';

import moment from 'moment';
import InfoIcon from '@material-ui/icons/Info';
import { Checkbox, FormControlLabel, Grid, makeStyles, TextField, Paper } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';

import { handleFormChangeNew } from '../../../../reducers/helpers/formHelpers';
import RMSFabButtons from 'components/RMSForms/RMSIncidentForm/RMSFabButtons';
import { upsertRecord } from 'reducers/RecordsReducer';
import { setCircularLoading } from 'reducers/ui/UiMenuReducer';
import RMSAddButton from 'components/RMSButtons/RMSAddButton';

const useStyles = makeStyles(() => ({
  root: {
    height: window.innerHeight - 90 + 'px',
    borderRadius: '0.5rem',
    padding: '15px',
  },
  form: {
    width: '100%',
  },
  input: {
    width: '100%',
    padding: '10px',
  },
  btnStyle: {
    display: 'block',
    textAlign: 'right',
    marginBottom: '30px',
  },
}));

const codeRelationships = ['COWWORKER', 'MAINWORKER'];

const PersonAssociateForm = (props) => {
  /** region Component Variables and Props */
  const classes = useStyles();
  const location = useLocation();
  const { persondId } = useParams();
  const history = useHistory();
  const dispatch = useDispatch();
  const { wsClient, upsertRecord } = props;

  const [hideDetailsIcon, setHideDetailsIcon] = React.useState(true);
  const [value, setValue] = React.useState(null);
  const [inputValue, setInputValue] = React.useState('');
  const [options, setOptions] = React.useState([]);

  let data = useSelector((state) => state.records.formData);
  const actionType = location.pathname.split('/')[5];

  if (actionType === 'add') {
    data = {};
  }

  const [formData, setFormData] = useState({
    values: {
      ptsAssociateID: data?.ptsAssociateID || undefined,
      ptsPersonID: Number(persondId),
      Relationship: data?.Relationship || '',
      IsKeepSeparate: data?.IsKeepSeparate || false,
    },
  });

  React.useEffect(() => {
    let active = true;
    let timer;

    if (!wsClient || !wsClient.websocket) {
      return;
    }

    if (timer) clearTimeout(timer);

    timer = setTimeout(async () => {
      const service = wsClient.websocket.service('persons');
      service.timeout = 20000;

      const response = await service.find({
        query: {
          FullName: {
            $like: `%${inputValue}%`,
            $ne: null,
          },
          $select: [
            'FirstName',
            'LastName',
            'MiddleName',
            'FullName',
            'Suffix',
            'Race',
            'Sex',
            'Ethnicity',
            'Age',
            'Born',
            'AgeRangeIncrement',
            'EyeColor',
            'HairColor',
            'HairStyle',
            'HairLength',
            'BloodType',
            'Handed',
            'Height',
            'Weight',
            'ptsPersonID',
          ],
        },
      });

      const filteredData = response.data.filter(
        (x) =>
          x.PersonCredential.some(
            (y) => y.Number !== 0 && y.Number !== '0' && y.Number !== 'null'
          ) || x.Born !== null
      );

      if (filteredData.length > 0) {
        if (active) {
          let newOptions = [...filteredData];

          setOptions(newOptions);
        }
      }
    }, 500);

    return () => {
      active = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [wsClient, inputValue]);

  /** endregion */
  /** region Helper Functions */
  const handleSave = () => {
    upsertRecord('associate', formData.values, 'add').finally(() => handleFinally());
  };

  const handleFinally = () => {
    dispatch(setCircularLoading(false));
    history.goBack();
  };

  const handlePeopleSelect = (event, newValue) => {
    setValue(newValue);

    if (!newValue) {
      setFormData({
        ...formData,
        values: { ...formData.values, ptsAssociateID: '' },
      });
      setHideDetailsIcon(true);
      return;
    }
    setFormData({
      ...formData,
      values: { ...formData.values, ptsAssociateID: newValue?.ptsPersonID },
    });

    setHideDetailsIcon(false);
  };

  const goToPersonDetails = () => {
    history.push(`/records/person/${formData.values.ptsAssociateID}/details`);
  };

  const goToAddAddressPage = () => {
    history.push(`/records/add/persons`);
  };
  /** endregion */
  return (
    <Paper className={classes.root}>
      <form autoComplete="off" className={classes.form}>
        <Grid container xs={12} className={classes.btnStyle}>
          <RMSAddButton tooltipText="Add Person" onClick={goToAddAddressPage} />
        </Grid>
        {actionType === 'add' && (
          <Grid container xs={12} align="center" spacing={2} className="mb-2 mt-2 pl-2">
            <div style={{ display: 'flex', width: '100%' }}>
              <Autocomplete
                size="small"
                autoComplete
                className={classes.form}
                autoHighlight
                id="combo-search-person"
                getOptionLabel={(option) => {
                  const fullName = option.FullName;
                  let ssn = '';
                  let oln = '';
                  if (Array.isArray(option.PersonCredential)) {
                    option.PersonCredential.forEach((e) => {
                      if (e.Type === 'SSN') ssn = e.Number;
                      if (e.Type === 'OLN') oln = e.Number;
                    });
                  }
                  const race = option.Race;
                  const sex = option.Sex;
                  const born = option.Born ? moment(option.Born).format('MM/DD/YYYY') : null;
                  return `${fullName}  ${ssn ? 'SSN:' + ssn : ''}  ${oln ? 'OLN: ' + oln : ''}  ${
                    race ? 'Race:' + race : ''
                  } ${sex ? 'Sex: ' + sex : ''}  ${born ? 'DOB:' + born : ''}`;
                }}
                renderOption={(option) => {
                  const fullName = option.FullName;
                  let ssn = '';
                  let oln = '';
                  if (Array.isArray(option.PersonCredential)) {
                    option.PersonCredential.forEach((e) => {
                      if (e.Type === 'SSN') ssn = e.Number;
                      if (e.Type === 'OLN') oln = e.Number;
                    });
                  }
                  const race = option.Race;
                  const sex = option.Sex;
                  const born = option.Born ? moment(option.Born).format('MM/DD/YYYY') : null;
                  return `${fullName}  ${ssn ? 'SSN:' + ssn : ''}  ${oln ? 'OLN: ' + oln : ''}  ${
                    race ? 'Race:' + race : ''
                  } ${sex ? 'Sex: ' + sex : ''}  ${born ? 'DOB:' + born : ''}`;
                }}
                options={options}
                onChange={handlePeopleSelect}
                onInputChange={(event, newInputValue) => {
                  setInputValue(newInputValue);
                }}
                renderInput={(params) => (
                  <TextField
                    autoComplete="hidden"
                    size="small"
                    {...params}
                    label="Search Person"
                    variant="outlined"
                    fullWidth
                  />
                )}
              />
              {hideDetailsIcon === false && (
                <InfoIcon
                  onClick={goToPersonDetails}
                  style={{
                    cursor: 'pointer',
                    fontSize: '24px',
                    position: 'absolute',
                    top: '30px',
                    right: '92px',
                    color: '#1976d2',
                  }}
                />
              )}
            </div>
          </Grid>
        )}

        <Grid container xs={12} align="center" spacing={2} justify="left" className="mt-2">
          <Grid item xs={4}>
            <Autocomplete
              size="small"
              autoComplete
              autoSelect
              autoHighlight
              id="combo-relation"
              getOptionLabel={(option) => option || ''}
              options={codeRelationships}
              key={formData.values.Relationship || ''}
              value={formData.values.Relationship || ''}
              onChange={(event, newValue) =>
                handleFormChangeNew(event, newValue, 'none', 'Relationship', setFormData)
              }
              renderInput={(params) => (
                <Grid container spacing={1} alignItems="flex-end">
                  <Grid item xs={12} lg={12}>
                    <TextField
                      autoComplete="hidden"
                      size="small"
                      {...params}
                      label="Relationship"
                      variant="outlined"
                      fullWidth
                    />
                  </Grid>
                </Grid>
              )}
            />
          </Grid>

          <Grid item xs={4} justify="left" align="left" className="pl-4">
            <FormControlLabel
              control={
                <Checkbox
                  checked={formData.values?.IsKeepSeparate}
                  name="IsKeepSeparate"
                  onChange={(event) =>
                    handleFormChangeNew(
                      event,
                      event.target.checked,
                      'none',
                      'IsKeepSeparate',
                      setFormData
                    )
                  }
                />
              }
              label="Is Keep Separate"
            />
          </Grid>
        </Grid>
      </form>
      <RMSFabButtons recordForm={true} handleSaveRecord={handleSave} />
    </Paper>
  );
};

const mapStateToProps = (state) => ({
  wsClient: state.websocket,
});

export default connect(mapStateToProps, { upsertRecord })(PersonAssociateForm);
