/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { formatDate, getExcerpt } from '../../../../utils/functions';
import { XGrid } from '@material-ui/x-grid';

const panelHeight = 300;
const useStyles = makeStyles((theme) => ({
  notes: {
    textAlign: 'left',
  },
  note: {
    padding: '10px 15px 5px',
    position: 'relative',
    '& p': {
      fontSize: 13,
      marginBottom: 5,
    },
    '&:hover': {
      '& $rowActions': {
        opacity: 1,
      },
    },
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    '& h4': {
      fontSize: '1em',
      marginBottom: '0.25em',
    },
  },
  actions: {
    padding: '5px 15px',
    textAlign: 'right',
    // borderBottom: `1px solid ${theme.card.hr}`,
    '& label': {
      marginBottom: 0,
    },
  },
  textField: {
    margin: `0 0 ${theme.spacing(1)}px 0`,
  },
  rowActions: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(3) + 2,
    opacity: 0,
    transition: 'opacity 0.3s',
  },
  editNote: {
    padding: `${theme.spacing(2)}px ${theme.spacing(2)}px ${theme.spacing(0.5)}px`,
  },
  gridWrap: {
    width: '100%',
    height: panelHeight,
  },
}));

const columns = [
  { field: 'MessageTo', headerName: 'Recipient', width: 170 },
  { field: 'Subject', headerName: 'Subject', width: 200 },
  { field: 'excerpt', headerName: 'Message', width: 250 },
  { field: 'AgencyID', headerName: 'Agency ID', width: 120 },
  {
    field: 'Created',
    headerName: 'Created',
    width: 170,
    valueFormatter: (params) => formatDate(params.value),
  },
];

function NotificationsTab(props) {
  const { event, wsClient } = props;
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);

  const classes = useStyles();

  useEffect(() => {
    if (!wsClient?.websocket) {
      return;
    }

    updatePages();
  }, [event.pagingHistoryCount]);

  const updatePages = async () => {
    setLoading(true);
    const service = wsClient?.websocket.service('cad');
    service.timeout = 200000;

    const result = await service.get({
      type: 'event-pages',
      data: event.EventID, //TODO: Add real id
    });

    setData(processData(result));
    setLoading(false);
  };

  const processData = (data) => {
    return data.map((row) => {
      return {
        ...row,
        id: row.PrimaryKeyValue,
        excerpt: getExcerpt(row.Body, 150),
      };
    });
  };

  return (
    <div className={classes.notes}>
      <div className={classes.gridWrap}>
        <XGrid
          columns={columns}
          rows={data}
          loading={loading}
          disableColumnMenu
          density={'compact'}
          hideFooter
        />
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    wsClient: state.websocket,
  };
};

export default connect(mapStateToProps, {})(NotificationsTab);