import store from '../../config/configureStore';
import { arresteesSchema } from 'simpl-schema-validation/schema';

export const validateArresteesForm = (field, form) => {
  const currentState = store.store.getState().incident;
  let arrestee = form;

  let objToValidate = {
    [field]: arrestee.values[field],
    incident: currentState.incident,
  };

  let arresteesValidationContext = arresteesSchema.newContext();
  let result = arresteesValidationContext.validate(objToValidate, {
    keys: [field],
  });

  let arresteesFormErrors = [];
  if (!result) {
    arresteesValidationContext.validationErrors().forEach((error) => {
      if (field === error.name) {
        error.message = arresteesValidationContext.keyErrorMessage(error.name);
        arresteesFormErrors.push(error);
      }
    });
  }

  return arresteesFormErrors || [];
};
