// eslint-disable prettier/prettier
// Layout Blueprints
import { RmsLayout } from './layout-blueprints';

// Incident Pages
import PageMain from './pages/PageMain';
import PageDashboard from './pages/PageRMSDashboard/PageDashboard';
import PageRMSIncidents from './pages/PageRMSIncidents';
import PageRMSRecords from './pages/PageRMSRecords';
import PageRMSReports from './pages/PageRMSReports';
import PageRMSSearches from './pages/PageRMSSearches';
import PageRMSSettings from './pages/PageRMSSettings';
import AddEditIncident from './pages/PageRMSIncidents/PageAddEditIncident';
import AddEditAddress from './pages/PageRMSAddress/';
import AddEditParties from './pages/PageRMSParties';
import { AddEditItem } from './pages/PageRMSProperties';
import { AddEditFirearm } from './pages/PageRMSProperties';
import { AddEditStructure } from './pages/PageRMSProperties';
import { AddEditSubstance } from './pages/PageRMSProperties';
import { AddEditJewelry } from './pages/PageRMSProperties';
import { AddEditVehicle } from './pages/PageRMSProperties';
import AddEditPlace from './pages/PageRMSPlaces';
import AddEditOffense from './pages/PageRMSOffenses';
import PageRMSDev from './pages/PageRMSDev';
import AddEditEvidence from './pages/PageRMSEvidence';
import { AddEditNarratives } from './pages/PageRMSNarratives';
import AddEditDomesticViolence from './pages/PageRMSDomesticViolence';
import { AddEditMissingPerson } from './pages/PageRMSMissingPerson';
import AddEditDispositions from './pages/PageRMSDispositions';
import AddEditSOP from './pages/PageRMSSOP';
import AddEditZones from 'pages/PageRMSZones/AddEditZones';
import AddEditAlerts from 'pages/PageRMSAlerts/AddEditAlerts';
import AddEditNotifications from 'pages/PageRMSNotifications/AddEditNotifications';

// Records Pages
import AddEditJewelryRecord from 'pages/PageRMSRecords/AddEditJewelryRecord';
import AddEditItemRecord from 'pages/PageRMSRecords/AddEditItemRecord';
import AddEditFirearmRecord from 'pages/PageRMSRecords/AddEditFirearmRecord';
import AddEditVehicleRecord from 'pages/PageRMSRecords/AddEditVehicleRecord';
import AddEditSubstanceRecord from 'pages/PageRMSRecords/AddEditSubstanceRecord';
import AddEditStructureRecord from 'pages/PageRMSRecords/AddEditStructureRecord';
import AddEditAddressRecord from 'pages/PageRMSRecords/AddEditAddressRecord';
import AddEditPersonRecord from 'pages/PageRMSRecords/PersonsTab/AddEditPersonRecord';
import AddEditPlaceRecord from 'pages/PageRMSRecords/AddEditPlaceRecord';
import {
  PersonForm,
  PersonAddressForm,
  PersonCredentialForm,
  PersonContactMethodForm,
  PersonEmploymentForm,
  PersonAssociateForm,
  PersonDNASampleForm,
  PersonFeatureForm,
  PersonNameForm,
  PersonPhotoForm,
  PersonResourceForm,
  PersonSOPForm,
  PersonVehicleRegistrationForm,
} from 'components/RMSForms/RMSRecordsForm/RecordsPersonForm';

// Other Pages
import HostingValidation from 'pages/PageRMSHostingValidation/HostingValidation';
import PageRMSEvents from 'pages/PageRMSEvents';

// eslint-disable-next-line import/no-anonymous-default-export
export default [
  { url: '/main', component: PageMain, layout: RmsLayout },
  { url: '/dashboard/', component: PageDashboard, layout: RmsLayout },
  { url: '/incidents/add/notifications', component: AddEditNotifications, layout: RmsLayout },
  {
    url: '/incidents/edit/:incId/notifications/:propId',
    component: AddEditNotifications,
    layout: RmsLayout,
  },
  { url: '/incidents/add/alerts', component: AddEditAlerts, layout: RmsLayout },
  {
    url: '/incidents/edit/:incId/alerts/:propId',
    component: AddEditAlerts,
    layout: RmsLayout,
  },
  { url: '/incidents/add/zones', component: AddEditZones, layout: RmsLayout },
  {
    url: '/incidents/edit/:incId/zones/:propId',
    component: AddEditZones,
    layout: RmsLayout,
  },
  { url: '/incidents/add/sop', component: AddEditSOP, layout: RmsLayout },
  {
    url: '/incidents/edit/:incId/sop/:propId',
    component: AddEditSOP,
    layout: RmsLayout,
  },
  { url: '/incidents/add/dispositions', component: AddEditDispositions, layout: RmsLayout },
  {
    url: '/incidents/edit/:incId/dispositions/:propId',
    component: AddEditDispositions,
    layout: RmsLayout,
  },
  { url: '/incidents/add/addresses', component: AddEditAddress, layout: RmsLayout },
  {
    url: '/incidents/edit/:incId/addresses/:addressId',
    component: AddEditAddress,
    layout: RmsLayout,
  },
  { url: '/incidents/add/offenders', component: AddEditParties, layout: RmsLayout },
  {
    url: '/incidents/edit/:incId/offenders/:incPersonId',
    component: AddEditParties,
    layout: RmsLayout,
  },
  { url: '/incidents/add/suspects', component: AddEditParties, layout: RmsLayout },
  {
    url: '/incidents/edit/:incId/suspects/:incPersonId',
    component: AddEditParties,
    layout: RmsLayout,
  },
  { url: '/incidents/add/victims/person', component: AddEditParties, layout: RmsLayout },
  { url: '/incidents/add/victims/place', component: AddEditPlace, layout: RmsLayout },
  {
    url: '/incidents/edit/:incId/victims/:incPersonId',
    component: AddEditParties,
    layout: RmsLayout,
  },
  { url: '/incidents/add/others', component: AddEditParties, layout: RmsLayout },
  {
    url: '/incidents/edit/:incId/others/:incPersonId',
    component: AddEditParties,
    layout: RmsLayout,
  },
  { url: '/incidents/add/arrestees', component: AddEditParties, layout: RmsLayout },
  { url: '/incidents/add/item', component: AddEditItem, layout: RmsLayout },
  { url: '/incidents/edit/:incId/item/:propId', component: AddEditItem, layout: RmsLayout },
  { url: '/incidents/add/firearm', component: AddEditFirearm, layout: RmsLayout },
  { url: '/incidents/edit/:incId/firearm/:propId', component: AddEditFirearm, layout: RmsLayout },
  { url: '/incidents/add/jewelry', component: AddEditJewelry, layout: RmsLayout },
  { url: '/incidents/edit/:incId/jewelry/:propId', component: AddEditJewelry, layout: RmsLayout },
  { url: '/incidents/add/vehicle', component: AddEditVehicle, layout: RmsLayout },
  { url: '/incidents/edit/:incId/vehicle/:propId', component: AddEditVehicle, layout: RmsLayout },
  { url: '/incidents/add/structure', component: AddEditStructure, layout: RmsLayout },
  {
    url: '/incidents/edit/:incId/structure/:propId',
    component: AddEditStructure,
    layout: RmsLayout,
  },
  { url: '/incidents/add/substance', component: AddEditSubstance, layout: RmsLayout },
  {
    url: '/incidents/edit/:incId/substance/:propId',
    component: AddEditSubstance,
    layout: RmsLayout,
  },
  { url: '/incidents/add/offenses', component: AddEditOffense, layout: RmsLayout },
  {
    url: '/incidents/edit/:incId/offenses/:offenseId',
    component: AddEditOffense,
    layout: RmsLayout,
  },
  { url: '/incidents/add/evidence', component: AddEditEvidence, layout: RmsLayout },
  {
    url: '/incidents/edit/:incId/evidence/:evidenceId',
    component: AddEditEvidence,
    layout: RmsLayout,
  },
  { url: '/incidents/add/narratives', component: AddEditNarratives, layout: RmsLayout },
  {
    url: '/incidents/edit/:incId/narratives/:narrativeId',
    component: AddEditNarratives,
    layout: RmsLayout,
  },
  {
    url: '/incidents/add/domestic-violence',
    component: AddEditDomesticViolence,
    layout: RmsLayout,
  },

  { url: '/incidents/add/missing-person', component: AddEditMissingPerson, layout: RmsLayout },
  { url: '/incidents/add', component: AddEditIncident, layout: RmsLayout },
  { url: '/incidents/edit/:incId', component: AddEditIncident, layout: RmsLayout },
  { url: '/incidents', component: PageRMSIncidents, layout: RmsLayout },
  { url: '/events', component: PageRMSEvents, layout: RmsLayout },
  { url: '/records/add/persons', component: PersonForm, layout: RmsLayout },
  {
    url: '/records/edit/persons/:personId',
    component: PersonForm,
    layout: RmsLayout,
  },
  { url: '/records/edit/address/:addressId', component: AddEditAddressRecord, layout: RmsLayout },
  { url: '/records/add/address', component: AddEditAddressRecord, layout: RmsLayout },
  { url: '/records/add/item', component: AddEditItemRecord, layout: RmsLayout },
  { url: '/records/edit/item', component: AddEditItemRecord, layout: RmsLayout },
  { url: '/records/edit/firearm', component: AddEditFirearmRecord, layout: RmsLayout },
  { url: '/records/add/firearm', component: AddEditFirearmRecord, layout: RmsLayout },
  { url: '/records/add/jewelry', component: AddEditJewelryRecord, layout: RmsLayout },
  { url: '/records/edit/jewelry', component: AddEditJewelryRecord, layout: RmsLayout },
  { url: '/records/edit/vehicle/:vehicleId', component: AddEditVehicleRecord, layout: RmsLayout },
  { url: '/records/add/vehicle', component: AddEditVehicleRecord, layout: RmsLayout },
  { url: '/records/add/substance', component: AddEditSubstanceRecord, layout: RmsLayout },
  { url: '/records/edit/substance', component: AddEditSubstanceRecord, layout: RmsLayout },
  { url: '/records/edit/structure', component: AddEditStructureRecord, layout: RmsLayout },
  { url: '/records/add/structure', component: AddEditStructureRecord, layout: RmsLayout },
  { url: '/records/:type/:persondId/details', component: AddEditPersonRecord, layout: RmsLayout },
  { url: '/records/edit/place/:placeId', component: AddEditPlaceRecord, layout: RmsLayout },
  { url: '/records/add/place', component: AddEditPlaceRecord, layout: RmsLayout },
  { url: '/record/person/:persondId/address/add', component: PersonAddressForm, layout: RmsLayout },
  {
    url: '/record/person/:persondId/address/edit',
    component: PersonAddressForm,
    layout: RmsLayout,
  },
  {
    url: '/record/person/:persondId/associate/add',
    component: PersonAssociateForm,
    layout: RmsLayout,
  },
  {
    url: '/record/person/:persondId/associate/edit',
    component: PersonAssociateForm,
    layout: RmsLayout,
  },

  {
    url: '/record/person/:persondId/contact-method/add',
    component: PersonContactMethodForm,
    layout: RmsLayout,
  },
  {
    url: '/record/person/:persondId/contact-method/edit',
    component: PersonContactMethodForm,
    layout: RmsLayout,
  },

  {
    url: '/record/person/:persondId/credential/add',
    component: PersonCredentialForm,
    layout: RmsLayout,
  },
  {
    url: '/record/person/:persondId/credential/edit',
    component: PersonCredentialForm,
    layout: RmsLayout,
  },

  {
    url: '/record/person/:persondId/dna-sample/add',
    component: PersonDNASampleForm,
    layout: RmsLayout,
  },
  {
    url: '/record/person/:persondId/dna-sample/edit',
    component: PersonDNASampleForm,
    layout: RmsLayout,
  },
  {
    url: '/record/person/:persondId/employment/add',
    component: PersonEmploymentForm,
    layout: RmsLayout,
  },
  {
    url: '/record/person/:persondId/employment/edit',
    component: PersonEmploymentForm,
    layout: RmsLayout,
  },
  {
    url: '/record/person/:persondId/feature/add',
    component: PersonFeatureForm,
    layout: RmsLayout,
  },
  {
    url: '/record/person/:persondId/feature/edit',
    component: PersonFeatureForm,
    layout: RmsLayout,
  },
  {
    url: '/record/person/:persondId/name/add',
    component: PersonNameForm,
    layout: RmsLayout,
  },
  {
    url: '/record/person/:persondId/name/edit',
    component: PersonNameForm,
    layout: RmsLayout,
  },
  {
    url: '/record/person/:persondId/photo/add',
    component: PersonPhotoForm,
    layout: RmsLayout,
  },
  {
    url: '/record/person/:persondId/photo/edit/:entityId',
    component: PersonPhotoForm,
    layout: RmsLayout,
  },
  {
    url: '/record/person/:persondId/resource/add',
    component: PersonResourceForm,
    layout: RmsLayout,
  },
  {
    url: '/record/person/:persondId/resource/edit',
    component: PersonResourceForm,
    layout: RmsLayout,
  },
  {
    url: '/record/person/:persondId/sop/add',
    component: PersonSOPForm,
    layout: RmsLayout,
  },
  {
    url: '/record/person/:persondId/sop/edit',
    component: PersonSOPForm,
    layout: RmsLayout,
  },
  {
    url: '/record/person/:persondId/vehicle-registration/add',
    component: PersonVehicleRegistrationForm,
    layout: RmsLayout,
  },
  {
    url: '/record/person/:persondId/vehicle-registration/edit',
    component: PersonVehicleRegistrationForm,
    layout: RmsLayout,
  },
  { url: '/records/:type', component: PageRMSRecords, layout: RmsLayout },
  { url: '/reports', component: PageRMSReports, layout: RmsLayout },
  { url: '/search', component: PageRMSSearches, layout: RmsLayout },
  { url: '/settings', component: PageRMSSettings, layout: RmsLayout },
  { url: '/hosting-validation', component: HostingValidation, layout: RmsLayout },
  { url: '/dev', component: PageRMSDev, layout: RmsLayout },
];
