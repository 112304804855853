import store from '../../config/configureStore';

export const SET_CODE_ITEM_CONDITIONS = 'CODE/SET_CODE_ITEM_CONDITIONS';
export const ADD_CODE_ITEM_CONDITIONS = 'CODE/ADD_CODE_ITEM_CONDITIONS';
export const SET_CODE_ITEM_MAKES = 'CODE/SET_CODE_ITEM_MAKES';
export const ADD_CODE_ITEM_MAKES = 'CODE/ADD_CODE_ITEM_MAKES';
export const SET_CODE_ITEM_MODELS = 'CODE/SET_CODE_ITEM_MODELS';
export const ADD_CODE_ITEM_MODELS = 'CODE/ADD_CODE_ITEM_MODELS';

export const addCodeItemConditions = (newValue) => {
  return { type: ADD_CODE_ITEM_CONDITIONS, payload: newValue };
};

export const getCodeItemConditions = () => {
  const client = store.store.getState().websocket.websocket;
  return async (dispatch) => {
    try {
      const service = client.service('codeitemconditions');
      service.timeout = 20000;
      const codes = await service.find({
        query: {
          $select: ['Code', 'Description'],
          $sort: {
            Code: 1,
          },
          IsDeleted: false,
        },
      });
      dispatch({ type: SET_CODE_ITEM_CONDITIONS, payload: codes.data });
    } catch (error) {
      if (error.code === 401) {
        console.log('Unauthenticated');
      } else {
        console.log(error);
      }
    }
  };
};

export const addCodeItemMakes = (newValue) => {
  return { type: ADD_CODE_ITEM_MAKES, payload: newValue };
};
export const getCodeItemMakes = () => {
  const client = store.store.getState().websocket.websocket;
  return async (dispatch) => {
    try {
      const service = client.service('codeitemmakes');
      service.timeout = 20000;
      const codes = await service.find({
        query: {
          $select: ['Code', 'Description'],
          $sort: {
            Code: 1,
          },
          IsDeleted: false,
        },
      });
      dispatch({ type: SET_CODE_ITEM_MAKES, payload: codes.data });
    } catch (error) {
      if (error.code === 401) {
        console.log('Unauthenticated');
      } else {
        console.log(error);
      }
    }
  };
};

export const addCodeItemModels = (newValue) => {
  return { type: ADD_CODE_ITEM_MODELS, payload: newValue };
};

export const getCodeItemModels = () => {
  const client = store.store.getState().websocket.websocket;
  return async (dispatch) => {
    try {
      const service = client.service('codeitemmodels');
      service.timeout = 20000;
      const codes = await service.find({
        query: {
          $select: ['Code', 'Description'],
          $sort: {
            Code: 1,
          },
          IsDeleted: false,
        },
      });
      dispatch({ type: SET_CODE_ITEM_MODELS, payload: codes.data });
    } catch (error) {
      if (error.code === 401) {
        console.log('Unauthenticated');
      } else {
        console.log(error);
      }
    }
  };
};

export default function reducer(
  state = {
    codeItemConditions: [],
    codeItemMakes: [],
    codeItemModels: [],
  },
  action
) {
  // eslint-disable-next-line default-case
  switch (action.type) {
    case SET_CODE_ITEM_CONDITIONS:
      return { ...state, codeItemConditions: action.payload };
    case ADD_CODE_ITEM_CONDITIONS:
      return { ...state, codeItemConditions: [...state.codeItemConditions, action.payload] };
    case SET_CODE_ITEM_MAKES:
      return { ...state, codeItemMakes: action.payload };
    case ADD_CODE_ITEM_MAKES:
      return { ...state, codeItemMakes: [...state.codeItemMakes, action.payload] };
    case SET_CODE_ITEM_MODELS:
      return { ...state, codeItemModels: action.payload };
    case ADD_CODE_ITEM_MODELS:
      return { ...state, codeItemModels: [...state.codeItemModels, action.payload] };
  }
  return state;
}
