/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */
import React from 'react';
import { connect } from 'react-redux';
import { useLocation } from 'react-router';

import { v4 as uuidv4 } from 'uuid';
import SimpleSchema from 'simpl-schema';
import DateFnsUtils from '@date-io/date-fns';
import moment from 'moment-timezone';
import InfoIcon from '@material-ui/icons/Info';
import Grid from '@material-ui/core/Grid';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import Divider from '@material-ui/core/Divider';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import FormControl from '@material-ui/core/FormControl';
import Checkbox from '@material-ui/core/Checkbox';

import {
  initFormModel,
  updateIncPartiesContactForm,
  updateIncPartiesEmploymentForm,
  updateIncPartiesOthersForm,
  updateIncPartiesPersonForm,
} from '../../reducers/IncReducer';
import { getCodePartyInvolvementTypes } from '../../reducers/dictionaries/CodePartyInvolvementTypesReducer';
import { getCodeNameSuffixes } from '../../reducers/dictionaries/CodeNameSuffixesReducer';
import { getCodeRace } from '../../reducers/dictionaries/CodeRaceReducer';
import { getCodeSex } from '../../reducers/dictionaries/CodeSexReducer';
import {
  getCodeBloodType,
  getCodeEthnicity,
  getCodeEyeColor,
  getCodeHanded,
} from '../../reducers/dictionaries/CodeOthersReducer';
import {
  getCodeHairColor,
  getCodeHairLength,
  getCodeHairStyle,
} from '../../reducers/dictionaries/CodeHairReducer';
import { getCodeStates } from '../../reducers/dictionaries/CodeStatesReducer';

import { handleFormChangeNew } from '../../reducers/helpers/formHelpers';
import RMSComboBox from 'components/RMSComboBox';
import RMSComboBoxListItem from 'components/RMSComboBoxListItem';
import RMSAutoComplete from 'components/RMSAutoComplete/RMSAutoComplete';
import { Tooltip, Typography } from '@material-ui/core';
import { duplicateCheck } from 'reducers/helpers/duplicateCheckHelpers';
import {
  setDuplicateModal,
  setDuplicateModalMessage,
  setDuplicateFoundBtn,
} from 'reducers/ui/UiModalReducer';
import RMSAttachments from 'components/RMSAttachments';
import { validatePartiesForm } from 'simpl-schema-validation/ValidateForms';

// Validation Schema
const validationContext = new SimpleSchema({
  age: SimpleSchema.Integer,
}).newContext();

const ageIncrement = [
  { code: '1' },
  { code: '2' },
  { code: '3' },
  { code: '4' },
  { code: '5' },
  { code: '6' },
  { code: '7' },
  { code: '8' },
  { code: '9' },
  { code: '10' },
];

const TabPerson = (props) => {
  /** region Component Variables and Props */
  const {
    partiesPersonForm,
    updateIncPartiesPersonForm,
    updateIncPartiesContactForm,
    updateIncPartiesEmploymentForm,
    updateIncPartiesOthersForm,
    history,
    codeBloodType,
    codeEthnicity,
    codeHanded,
    codeEyeColor,
    codeHairColor,
    codeHairStyle,
    codeHairLength,
    codeStates,
    codeSex,
    codeRace,
    codePartyInvolvementTypes,
    codeNameSuffixes,
    getCodeStates,
    getCodeSex,
    getCodeRace,
    getCodePartyInvolvementTypes,
    getCodeNameSuffixes,
    getCodeHairColor,
    getCodeHairLength,
    getCodeHairStyle,
    getCodeBloodType,
    getCodeEthnicity,
    getCodeEyeColor,
    getCodeHanded,
    recordForm,
    changeOptions,
    personOptions,
    selectedPeople,
    changeSelectedOption,
    setDuplicateModal,
    setDuplicateModalMessage,
    wsClient,
    uiDuplicateModalData,
  } = props;
  const {
    duplicateListOfAddressForContact,
    duplicateListOfAddressForEmployment,
  } = uiDuplicateModalData;
  const location = useLocation();
  const urlParams = location.pathname.split('/');
  const rootType = urlParams[1];
  const actionType = urlParams[2];
  const [changeKey, setChangeKey] = React.useState(0);
  const [selectedBirthDate, setSelectedBirthDate] = React.useState(null);
  const [formType, setFormType] = React.useState('edit');
  const [partiesPersonFormState, setPartiesPersonFormState] = React.useState({
    tempPartyId: uuidv4(),
    formType: 'edit',
    isValid: false,
    values: {},
    touched: {},
    errors: {},
  });
  const [errors, setErrors] = React.useState({});
  const [value, setValue] = React.useState(null);
  const [urlPlace, setURLPlace] = React.useState(null);
  const [options, setOptions] = React.useState([]);
  const [mugshot, setMugshot] = React.useState(null);
  const [inputValue, setInputValue] = React.useState('');
  const [firstLoad, setFirstLoad] = React.useState(true);
  const [loadingPhotos, setLoadingPhotos] = React.useState(false);
  const [hideDetailsIcon, setHideDetailsIcon] = React.useState(true);
  /** endregion */
  /** region React Hooks */
  React.useEffect(() => {
    setOptions(personOptions);
    determinePartyType();
    setValue(selectedPeople);

    const parts = history.location.pathname.split('/');
    if (
      !(
        partiesPersonFormState.ptsIncPersonId !== undefined &&
        partiesPersonFormState.ptsIncPersonId !== null
      ) &&
      parts[2] === 'add'
    ) {
      setFormType('add');
    }
  }, []);

  React.useEffect(() => {
    const services = [
      'codeStates',
      'codeSex',
      'codePartyInvolvementTypes',
      'codeNameSuffixes',
      'codeEthnicity',
      'codeHairColor',
      'codeHairStyle',
      'codeBloodType',
      'codeEyeColor',
      'codeHanded',
    ];

    if (!wsClient.websocket) return;

    getCodeHairLength();

    const getRaces = async () => {
      const result = await wsClient.websocket.service('coderace').find({
        query: {
          $limit: 0,
          $select: ['Code', 'Description'],
          $sort: {
            Description: 1,
          },
          IsDeleted: false,
        },
      });

      getCodeRace(result.data);
    };

    getRaces();

    services.forEach(async (svc) => {
      if (props[svc].length < 1) {
        const result = await wsClient.websocket.service(svc.toLowerCase()).find({
          query: {
            $limit: 0,
            $select: ['Code', 'Description'],
            $sort: {
              Description: 1,
            },
            IsDeleted: false,
          },
        });

        if (result.total > 0) {
          if (svc === 'codeStates') {
            getCodeStates(result.data);
          } else if (svc === 'codeSex') {
            getCodeSex(result.data);
          } else if (svc === 'codeRace') {
            getCodeRace(result.data);
          } else if (svc === 'codePartyInvolvementTypes') {
            getCodePartyInvolvementTypes(result.data);
          } else if (svc === 'codeNameSuffixes') {
            getCodeNameSuffixes(result.data);
          } else if (svc === 'codeEthnicity') {
            getCodeEthnicity(result.data);
          } else if (svc === 'codeHairColor') {
            getCodeHairColor(result.data);
          } else if (svc === 'codeHairStyle') {
            getCodeHairStyle(result.data);
          } else if (svc === 'codeBloodType') {
            getCodeBloodType(result.data);
          } else if (svc === 'codeEyeColor') {
            getCodeEyeColor(result.data);
          } else if (svc === 'codeHanded') {
            getCodeHanded(result.data);
          }
        }
      }
    });
  }, [wsClient]);

  React.useEffect(() => {
    if (mugshot === null && partiesPersonForm.ptsPersonId) {
      getPersonPhotos(partiesPersonForm.ptsPersonId);
    }
    setPartiesPersonFormState((partiesPersonFormState) => ({
      ...partiesPersonFormState,
      ...partiesPersonForm,
      formType: formType,
      values: {
        ...partiesPersonFormState.values,
        ...partiesPersonForm.values,
      },
    }));
    if (partiesPersonForm.values.birthdate)
      setSelectedBirthDate(partiesPersonForm.values.birthdate);
  }, [
    partiesPersonForm.values?.incidentId,
    partiesPersonForm.values?.personId,
    partiesPersonForm.values?.credentialId,
    partiesPersonForm.values?.partyType,
    partiesPersonForm.values?.firstName,
    partiesPersonForm.values?.middleName,
    partiesPersonForm.values?.lastName,
    partiesPersonForm.values?.suffix,
    partiesPersonForm.values?.race,
    partiesPersonForm.values?.sex,
    partiesPersonForm.values?.ethnicity,
    partiesPersonForm.values?.age,
    partiesPersonForm.values?.ageRangeIncrement,
    partiesPersonForm.values?.height,
    partiesPersonForm.values?.weight,
    partiesPersonForm.values?.eyeColor,
    partiesPersonForm.values?.hairColor,
    partiesPersonForm.values?.hairStyle,
    partiesPersonForm.values?.hairLength,
    partiesPersonForm.values?.bloodType,
    partiesPersonForm.values?.handed,
    partiesPersonForm.values?.ssn,
    partiesPersonForm.values?.oln,
    partiesPersonForm.values?.state,
    partiesPersonForm.values?.ptsImageId,
    partiesPersonForm.values?.isUnknownParty,
    partiesPersonForm.values?.isArrestee,
  ]);

  React.useEffect(() => {
    if (formType === 'add') {
      if (partiesPersonFormState.values?.isUnknownParty === true) {
        setPartiesPersonFormState((partiesPersonFormState) => ({
          ...partiesPersonFormState,
          values: {
            ...partiesPersonFormState.values,
            firstName: 'Unknown',
            lastName: 'Unknown',
          },
        }));
      } else if (partiesPersonFormState.values?.isUnknownParty === false) {
        setPartiesPersonFormState((partiesPersonFormState) => ({
          ...partiesPersonFormState,
          values: {
            ...partiesPersonFormState.values,
            firstName: '',
            lastName: '',
          },
        }));
      }
    }
  }, [partiesPersonFormState.values?.isUnknownParty]);

  React.useEffect(() => {
    let timeoutId;
    if (!firstLoad && partiesPersonFormState.values?.age !== '') {
      if (validationContext.isValid() === false) {
        const errs = validationContext.validationErrors();
        let e = {};
        errs.map((er) => {
          e[er.name] = `Value must be ${er.dataType}`;
          setErrors(e);
        });
      }

      if (partiesPersonFormState.values.firstName) {
        timeoutId = setTimeout(() => {
          if (Object.keys(partiesPersonFormState.values).length !== 0) duplicateFinder();
          let errorStatus = errors;

          ['age', 'race', 'ethnicity', 'sex'].map((field) => {
            let error = validatePartiesForm(field);

            console.log(error);

            if (error && error.length > 0) {
              errorStatus = {
                ...errorStatus,
                [field]: `${error[0].message}`,
              };
            } else {
              errorStatus = {
                ...errorStatus,
                [field]: null,
              };
            }
            setErrors(errorStatus);
          });

          if (
            partiesPersonFormState.values.partyType === 'Offender' &&
            (urlPlace === 'arrestees' || partiesPersonFormState.values.isArrestee)
          ) {
            [
              { name: 'arresteeAge', field: 'age' },
              { name: 'arresteeRace', field: 'race' },
              { name: 'arresteeSex', field: 'sex' },
            ].map((item) => {
              let error = validatePartiesForm(item.name);

              if (error && error.length > 0) {
                errorStatus = {
                  ...errorStatus,
                  [item.field]: `${error[0].message}`,
                };
              } else {
                errorStatus = {
                  ...errorStatus,
                  [item.field]: null,
                };
              }
            });

            setErrors(errorStatus);
          }
        }, 2000);
      }
    }
    setFirstLoad(false);
    updateIncPartiesPersonForm(partiesPersonFormState);
    return () => clearTimeout(timeoutId);
  }, [
    partiesPersonFormState.values?.incidentId,
    partiesPersonFormState.values?.personId,
    partiesPersonFormState.values?.credentialId,
    partiesPersonFormState.values?.partyType,
    partiesPersonFormState.values?.firstName,
    partiesPersonFormState.values?.middleName,
    partiesPersonFormState.values?.lastName,
    partiesPersonFormState.values?.suffix,
    partiesPersonFormState.values?.race,
    partiesPersonFormState.values?.sex,
    partiesPersonFormState.values?.ethnicity,
    partiesPersonFormState.values?.age,
    partiesPersonFormState.values?.ageRangeIncrement,
    partiesPersonFormState.values?.height,
    partiesPersonFormState.values?.weight,
    partiesPersonFormState.values?.eyeColor,
    partiesPersonFormState.values?.hairColor,
    partiesPersonFormState.values?.hairStyle,
    partiesPersonFormState.values?.hairLength,
    partiesPersonFormState.values?.bloodType,
    partiesPersonFormState.values?.handed,
    partiesPersonFormState.values?.ssn,
    partiesPersonFormState.values?.oln,
    partiesPersonFormState.values?.state,
    partiesPersonFormState.values?.birthdate,
    partiesPersonFormState.values?.ptsImageId,
    partiesPersonFormState.values?.isUnknownParty,
    partiesPersonFormState.values.attachments,
    partiesPersonFormState.values?.isArrestee,
  ]);

  React.useEffect(() => {
    let active = true;

    if (!wsClient || !wsClient.websocket) {
      return;
    }

    if (inputValue === '') return;
    if (changeSelectedOption) changeSelectedOption(value);

    searchForPerson(active);

    return () => {
      active = false;
    };
  }, [wsClient, inputValue]);

  React.useEffect(() => {
    if (!wsClient?.websocket) {
      return;
    }

    const getAttachments = async () => {
      const service = wsClient?.websocket.service('rms-attachments');
      service.timeout = 200000;

      const result = await service.find({
        query: {
          id:
            rootType === 'records'
              ? partiesPersonFormState.ptsPersonId
              : partiesPersonFormState.ptsIncPersonId,
          type: rootType === 'records' ? 'PERSON' : 'INCIDENTPERSON',
        },
      });
      let atts = [];
      result.map((r) => {
        atts.push({
          ptsAttachmentId: r.ptsAttachmentID,
          name: r.FileName,
          type: r.FileType,
          dataURL: r.FileObject,
        });
      });
      setPartiesPersonFormState((stateValue) => ({
        ...stateValue,
        values: {
          ...stateValue.values,
          attachments: atts,
        },
      }));
    };

    if (partiesPersonFormState.ptsPersonId) {
      getAttachments();
    }
  }, [wsClient, partiesPersonFormState.ptsPersonId]);
  /** endregion */
  /** region Helper Functions */
  const calculateAge = (dob) => {
    var today = new Date();
    var birthDate = new Date(dob);
    var age_now = today.getFullYear() - birthDate.getFullYear();
    var m = today.getMonth() - birthDate.getMonth();

    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      age_now--;
    }

    setPartiesPersonFormState({
      ...partiesPersonFormState,
      values: {
        ...partiesPersonFormState.values,
        age: parseInt(age_now) > 0 ? parseInt(age_now) : 0,
      },
    });
  };

  const duplicateFinder = async () => {
    let duplicateFound = !partiesPersonFormState.ptsPersonId
      ? await duplicateCheck(partiesPersonFormState, 'person')
      : false;
    if (duplicateFound) {
      setDuplicateModal(true);
      setDuplicateModalMessage('Duplicate Found');
    } else if (
      duplicateListOfAddressForContact.length > 0 ||
      duplicateListOfAddressForEmployment.length > 0
    ) {
      setDuplicateModal(true);
    } else {
      setDuplicateModal(false);
    }
  };

  const searchForPerson = async (active) => {
    if (inputValue.length < 3) return;
    const service = wsClient.websocket.service('persons');
    service.timeout = 20000;

    const response = await service.find({
      query: {
        IsDeleted: false,
        FullName: {
          $like: `%${inputValue}%`,
          $ne: null,
        },
        $select: [
          'FirstName',
          'LastName',
          'MiddleName',
          'FullName',
          'Suffix',
          'Race',
          'Sex',
          'Ethnicity',
          'Age',
          'Born',
          'AgeRangeIncrement',
          'EyeColor',
          'HairColor',
          'HairStyle',
          'HairLength',
          'BloodType',
          'Handed',
          'Height',
          'Weight',
          'ptsPersonID',
        ],
      },
    });

    const filteredData = response.data.filter(
      (x) =>
        // eslint-disable-next-line prettier/prettier
        x.PersonCredential.some(
          (y) => y.Number !== 0 && y.Number !== '0' && y.Number !== 'null'
          // eslint-disable-next-line prettier/prettier
        ) || x.Born !== null
    );

    if (filteredData.length > 0) {
      if (active) {
        let newOptions = [...filteredData];

        setOptions(newOptions);
        if (changeOptions) changeOptions(newOptions);
      }
    }
  };

  const handlePartyImageChange = (event) => {
    setPartiesPersonFormState({
      ...partiesPersonFormState,
      ptsImageId: parseInt(event.target.value),
      values: {
        ...partiesPersonFormState.values,
        ptsImageId: parseInt(event.target.value),
      },
    });
  };

  const getPersonPhotos = async (persondId) => {
    const service = wsClient?.websocket.service('record-person-photo');
    service.timeout = 200000;
    setLoadingPhotos(true);
    try {
      const result = await service.get(persondId);
      if (result) {
        setLoadingPhotos(false);
        let parsedLocalMugshots = result.Mugshots !== null ? JSON.parse(result.Mugshots) : null;
        if (parsedLocalMugshots && parsedLocalMugshots.length > 0) {
          parsedLocalMugshots.map((mugshot) => {
            if (mugshot.IsDefault === true) {
              partiesPersonForm.ptsImageId = parseInt(mugshot.ptsImageID);
              partiesPersonForm.values.ptsImageId = parseInt(mugshot.ptsImageID);
            }
          });
          setMugshot(parsedLocalMugshots);
        } else {
          setMugshot([]);
        }
      }
      setLoadingPhotos(false);
    } catch (error) {
      setLoadingPhotos(false);
      console.log(error);
    }
  };

  const handlePeopleSelect = (event, newValue) => {
    if (newValue === null) {
      changeSelectedOption(null);
      setValue(null);
      setMugshot(null);
      updateIncPartiesContactForm({
        ...initFormModel,
        values: {
          ...initFormModel.values,
          partyType: partiesPersonFormState.values.partyType,
        },
      });
      updateIncPartiesEmploymentForm(initFormModel);
      updateIncPartiesOthersForm(initFormModel);
      setPartiesPersonFormState({
        ...initFormModel,
        values: {
          ...initFormModel.values,
          partyType: partiesPersonFormState.values.partyType,
        },
      });
    } else {
      setValue(newValue);
      getPersonPhotos(newValue.ptsPersonID);

      let ssn = '';
      let oln = '';
      let state = '';

      if (newValue) {
        if (Array.isArray(newValue.PersonCredential)) {
          newValue.PersonCredential.forEach((e) => {
            if (e.Type === 'SSN') ssn = e.Number;
            if (e.Type === 'OLN') {
              oln = e.Number;
              state = e.State;
            }
          });
        }

        const ContactInfo = newValue?.ContactInfo;

        let contactPhone1 = '';
        let contactPhone2 = '';
        let contactEmail = '';
        let contactNote = '';
        let contactId1 = null;
        let contactId2 = null;
        let contactId3 = null;

        let employeePhone1 = '';
        let employeePhone2 = '';
        let employeeEmail = '';
        let employeeNote = '';
        let employeeId1 = null;
        let employeeId2 = null;
        let employeeId3 = null;

        if (Array.isArray(ContactInfo) && ContactInfo.length > 0) {
          ContactInfo.forEach((item) => {
            if (
              item.PartyType === 'Person' &&
              item.ContactMethod === 'Phone' &&
              item.MethodType === 'HME'
            ) {
              contactPhone1 = item.Info;
              contactNote = item.Notes;
              contactId1 = item.ptsInfoID;
            }
            if (
              item.PartyType === 'Person' &&
              item.ContactMethod === 'Phone' &&
              item.MethodType === 'WRK'
            ) {
              contactPhone2 = item.Info;
              contactNote = item.Notes;
              contactId2 = item.ptsInfoID;
            }

            if (item.PartyType === 'Person' && item.ContactMethod === 'Email') {
              contactEmail = item.Info;
              contactNote = item.Notes;
              contactId3 = item.ptsInfoID;
            }

            if (
              item.PartyType === 'Place' &&
              item.ContactMethod === 'Phone' &&
              item.MethodType === 'BUS1'
            ) {
              employeePhone1 = item.Info;
              employeeNote = item.Notes;
              employeeId1 = item.ptsInfoID;
            }

            if (
              item.PartyType === 'Place' &&
              item.ContactMethod === 'Phone' &&
              item.MethodType === 'BUS2'
            ) {
              employeePhone2 = item.Info;
              employeeNote = item.Notes;
              employeeId2 = item.ptsInfoID;
            }

            if (
              item.PartyType === 'Place' &&
              item.MethodType === 'BUS' &&
              item.ContactMethod === 'Email'
            ) {
              employeeEmail = item.Info;
              employeeNote = item.Notes;
              employeeId3 = item.ptsInfoID;
            }
          });
        }

        let contactDetail = {
          ptsContactId1: contactId1,
          ptsContactId2: contactId2,
          ptsContactId3: contactId3,
          values: {
            textmask1: contactPhone1,
            textmask2: contactPhone2,
            emailmask: contactEmail,
            notes: contactNote,
          },
        };

        let employmentDetail = {
          ptsContactId1: employeeId1,
          ptsContactId2: employeeId2,
          ptsContactId3: employeeId3,
          values: {
            textmask1: employeePhone1,
            textmask2: employeePhone2,
            emailmask: employeeEmail,
            notes: employeeNote,
          },
        };

        let otherDetail = {
          values: {
            incidentId: newValue.ptsIncidentID,
            personId: newValue?.ptsPersonID,
            addressId: newValue?.ptsAddressID,
            // credentialId: newValue?,
            generalAppearance: newValue?.GeneralAppearance,
            personNotes: newValue?.Notes,
          },
        };

        updateIncPartiesContactForm(contactDetail);
        updateIncPartiesEmploymentForm(employmentDetail);
        updateIncPartiesOthersForm(otherDetail);

        setPartiesPersonFormState((previousState) => ({
          ...previousState,
          ptsPersonId: newValue.ptsPersonID,
          values: {
            ...previousState.values,
            ptsPersonId: newValue.ptsPersonID,
            firstName: newValue.FirstName,
            lastName: newValue.LastName,
            middleName: newValue.MiddleName,
            suffix: newValue.Suffix,
            race: newValue.Race,
            sex: newValue.Sex,
            ethnicity: newValue.Ethnicity,
            age: newValue.Age,
            ageRangeIncrement: newValue.AgeRangeIncrement,
            eyeColor: newValue.EyeColor,
            hairColor: newValue.HairColor,
            hairLength: newValue.HairLength,
            bloodType: newValue.BloodType,
            handed: newValue.Handed,
            height: newValue.Height,
            weight: newValue.Weight,
            ssn: ssn,
            oln: oln,
            state: state,
            birthdate: newValue.Born,
          },
        }));
        setSelectedBirthDate(newValue.Born);
        setChangeKey(changeKey + 1);
        setHideDetailsIcon(false);
      } else {
        setHideDetailsIcon(true);
      }
    }
  };

  const determinePartyType = () => {
    const parts = history.location.pathname.split('/');
    let type = '';
    let place = '';

    if (parts.length === 4) {
      place = parts[parts.length - 1];
      type = parts[parts.length - 2];
    }
    if (parts.length === 5) {
      place = parts[parts.length - 2];
      type = parts[parts.length - 3];
    }
    if (parts.length === 6) {
      place = parts[parts.length - 2];
      type = parts[parts.length - 3];
    }

    setURLPlace(place);

    if ((place === 'offenders' || place === 'arrestees') && type === 'add') {
      setPartiesPersonFormState((partiesPersonFormState) => ({
        ...partiesPersonFormState,
        values: { ...partiesPersonFormState.values, partyType: 'Offender' },
      }));
    } else if (place === 'suspects' && type === 'add') {
      setPartiesPersonFormState((partiesPersonFormState) => ({
        ...partiesPersonFormState,
        values: { ...partiesPersonFormState.values, partyType: 'Suspect' },
      }));
    } else if (place === 'victims' && type === 'add') {
      setPartiesPersonFormState((partiesPersonFormState) => ({
        ...partiesPersonFormState,
        values: { ...partiesPersonFormState.values, partyType: 'Victim' },
      }));
    } else if (place === 'others' && type === 'add') {
      setPartiesPersonFormState((partiesPersonFormState) => ({
        ...partiesPersonFormState,
        values: { ...partiesPersonFormState.values, partyType: 'Other' },
      }));
    }
  };

  const goToPersonDetails = () => {
    history.push(`/records/person/${partiesPersonFormState.ptsPersonId}/details`);
  };
  /** endregion */
  return (
    <Grid container justify="center" xs={12} className="p-3">
      <Grid container spacing={2}>
        {actionType === 'add' && rootType !== 'records' && (
          <Grid item xs={8} className="mb-2">
            <div style={{ display: 'flex' }}>
              <Autocomplete
                size="small"
                autoComplete
                autoHighlight
                value={value}
                id="combo-search-person"
                style={{ width: '100%' }}
                getOptionLabel={(option) => {
                  const fullName = option.FullName;
                  let ssn = '';
                  let oln = '';
                  if (Array.isArray(option.PersonCredential)) {
                    option.PersonCredential.forEach((e) => {
                      if (e.Type === 'SSN') ssn = e.Number;
                      if (e.Type === 'OLN') oln = e.Number;
                    });
                  }
                  const race = option.Race;
                  const sex = option.Sex;
                  const born = option.Born ? moment(option.Born).format('MM/DD/YYYY') : null;
                  return `${fullName}  ${ssn ? 'SSN:' + ssn : ''}  ${oln ? 'OLN: ' + oln : ''}  ${
                    race ? 'Race:' + race : ''
                  } ${sex ? 'Sex: ' + sex : ''}  ${born ? 'DOB:' + born : ''}`;
                }}
                renderOption={(option) => {
                  const fullName = option.FullName;
                  let ssn = '';
                  let oln = '';
                  if (Array.isArray(option.PersonCredential)) {
                    option.PersonCredential.forEach((e) => {
                      if (e.Type === 'SSN') ssn = e.Number;
                      if (e.Type === 'OLN') oln = e.Number;
                    });
                  }
                  const race = option.Race;
                  const sex = option.Sex;
                  const born = option.Born ? moment(option.Born).format('MM/DD/YYYY') : null;
                  return `${fullName}  ${ssn ? 'SSN:' + ssn : ''}  ${oln ? 'OLN: ' + oln : ''}  ${
                    race ? 'Race:' + race : ''
                  } ${sex ? 'Sex: ' + sex : ''}  ${born ? 'DOB:' + born : ''}`;
                }}
                options={options}
                onChange={handlePeopleSelect}
                onInputChange={(event, newInputValue) => {
                  setInputValue(newInputValue);
                }}
                renderInput={(params) => (
                  <TextField
                    autoComplete="hidden"
                    size="small"
                    {...params}
                    inputProps={{
                      ...params.inputProps,
                    }}
                    label="Search Person"
                    variant="outlined"
                    fullWidth
                  />
                )}
              />
              {hideDetailsIcon === false && (
                <InfoIcon
                  onClick={goToPersonDetails}
                  style={{
                    cursor: 'pointer',
                    fontSize: '24px',
                    position: 'absolute',
                    top: '16px',
                    right: '78px',
                    color: '#1976d2',
                  }}
                />
              )}
            </div>
          </Grid>
        )}
        {rootType !== 'records' && (
          <Grid item xs={actionType === 'edit' ? 6 : 2} className="mb-2">
            <Autocomplete
              size="small"
              autoComplete
              autoSelect
              autoHighlight
              disabled={
                partiesPersonFormState.values?.partyType === 'Offender' ||
                partiesPersonFormState.values?.partyType === 'Victim'
              }
              id="combo-party-type"
              getOptionLabel={(option) => option || ''}
              options={codePartyInvolvementTypes.map((s) => s.Code)}
              key={partiesPersonFormState.values?.partyType || ''}
              value={partiesPersonFormState.values?.partyType || 'Other'}
              onChange={(event, newValue) =>
                handleFormChangeNew(event, newValue, 'none', 'partyType', setPartiesPersonFormState)
              }
              renderInput={(params) => (
                <TextField
                  autoComplete="hidden"
                  size="small"
                  {...params}
                  label="Party Type"
                  name="partyType"
                  variant="outlined"
                />
              )}
            />
          </Grid>
        )}
        {rootType !== 'records' && (
          <Grid item xs={actionType === 'edit' ? 4 : 2}>
            <FormControlLabel
              control={
                <Checkbox
                  key={partiesPersonFormState.values.isUnknownParty}
                  checked={partiesPersonFormState.values.isUnknownParty}
                  disabled={actionType === 'edit'}
                  onChange={(event) =>
                    handleFormChangeNew(
                      event,
                      event.target.checked,
                      'none',
                      'isUnknownParty',
                      setPartiesPersonFormState
                    )
                  }
                  name="isUnknownParty"
                />
              }
              label="Is Unknown?"
            />
          </Grid>
        )}

        {actionType === 'edit' &&
          rootType !== 'records' &&
          !partiesPersonFormState.values?.isUnknownParty && (
            <Grid>
              <Tooltip title="Go to Person Record">
                <InfoIcon
                  onClick={goToPersonDetails}
                  style={{
                    cursor: 'pointer',
                    fontSize: '34px',
                    position: 'absolute',
                    top: '26px',
                    right: '28px',
                    color: '#1976d2',
                  }}
                />
              </Tooltip>
            </Grid>
          )}
      </Grid>

      <Grid xs={12} className="mb-2">
        <span style={{ color: '#fff', background: '#1976d2' }} className="mb-1 badge">
          Name Details
        </span>
        <Divider />
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={3} lg={3}>
          <TextField
            id="standard-textarea-person-lastName"
            autoComplete="hidden"
            size="small"
            placeholder="Last Name"
            label="Last Name"
            variant="outlined"
            name="lastName"
            key={changeKey}
            value={partiesPersonFormState.values?.lastName || ''}
            onChange={(event) =>
              handleFormChangeNew(
                event,
                event.target.value,
                'none',
                'lastName',
                setPartiesPersonFormState
              )
            }
            fullWidth
            multiline
            InputLabelProps={{ shrink: true }}
          />
        </Grid>
        <Grid item xs={3} lg={3}>
          <TextField
            id="standard-textarea-person-firstName"
            autoComplete="hidden"
            size="small"
            placeholder="First Name"
            label="First Name"
            variant="outlined"
            name="firstName"
            key={changeKey}
            value={partiesPersonFormState.values?.firstName || ''}
            onChange={(event) =>
              handleFormChangeNew(
                event,
                event.target.value,
                'none',
                'firstName',
                setPartiesPersonFormState
              )
            }
            fullWidth
            multiline
            InputLabelProps={{ shrink: true }}
          />
        </Grid>
        <Grid item xs={3} lg={3}>
          <TextField
            id="standard-textarea-person-middleName"
            autoComplete="hidden"
            size="small"
            placeholder="Middle Name"
            label="Middle Name"
            variant="outlined"
            name="middleName"
            key={changeKey}
            value={partiesPersonFormState.values?.middleName || ''}
            onChange={(event) =>
              handleFormChangeNew(
                event,
                event.target.value,
                'none',
                'middleName',
                setPartiesPersonFormState
              )
            }
            fullWidth
            multiline
            InputLabelProps={{ shrink: true }}
          />
        </Grid>
        <Grid item xs={3} lg={3}>
          <RMSAutoComplete
            size="small"
            autoComplete
            autoSelect
            autoHighlight
            id="combo-search-suffix"
            options={codeNameSuffixes}
            label="Suffix"
            serviceName="codenamesuffixes"
            variant="outlined"
            title={'Code'}
            stateHolder={partiesPersonFormState}
            setStateHolder={setPartiesPersonFormState}
            setField={handleFormChangeNew}
            stateHolderAttribute="suffix"
          />
        </Grid>
      </Grid>
      <Grid xs={12} className="mt-4 mb-2">
        <span style={{ color: '#fff', background: '#1976d2' }} className="mb-1 badge">
          Demographics
        </span>
        <Divider />
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={12} lg={3}>
          <Autocomplete
            size="small"
            autoComplete
            autoSelect
            autoHighlight
            id="combo-race"
            getOptionLabel={(option) => option.Description || ''}
            options={codeRace.map((s) => s)}
            key={partiesPersonFormState.values?.race || ''}
            value={{ Description: partiesPersonFormState.values?.race } || ''}
            onChange={(event, newValue) => {
              return handleFormChangeNew(
                event,
                newValue?.Description,
                'none',
                'race',
                setPartiesPersonFormState
              );
            }}
            renderOption={(option) => (
              <RMSComboBoxListItem option={option} title={'Description'} label={'Race'} />
            )}
            renderInput={(params) => (
              <RMSComboBox
                {...params}
                error={errors['race'] ? true : false}
                helperText={errors['race']}
                label="Race"
                variant="outlined"
                fullWidth
              />
            )}
          />
        </Grid>
        <Grid item xs={12} lg={3}>
          <Autocomplete
            size="small"
            autoComplete
            autoSelect
            autoHighlight
            id="combo-sex"
            getOptionLabel={(option) => option.Description || ''}
            options={codeSex}
            key={partiesPersonFormState.values?.sex || ''}
            value={{ Description: partiesPersonFormState.values?.sex } || ''}
            onChange={(event, newValue) =>
              handleFormChangeNew(
                event,
                newValue?.Description,
                'none',
                'sex',
                setPartiesPersonFormState
              )
            }
            renderOption={(option) => (
              <RMSComboBoxListItem option={option} label={'Sex'} title={'Description'} />
            )}
            renderInput={(params) => (
              <RMSComboBox
                {...params}
                error={errors['sex'] ? true : false}
                helperText={errors['sex']}
                label="Sex"
                variant="outlined"
                fullWidth
              />
            )}
          />
        </Grid>
        <Grid item xs={12} lg={3}>
          <Autocomplete
            size="small"
            autoComplete
            autoSelect
            autoHighlight
            id="combo-ethnicity"
            getOptionLabel={(option) => option.Description || ''}
            options={codeEthnicity}
            key={partiesPersonFormState.values?.ethnicity || ''}
            value={{ Description: partiesPersonFormState.values?.ethnicity } || ''}
            onChange={(event, newValue) =>
              handleFormChangeNew(
                event,
                newValue?.Description,
                'none',
                'ethnicity',
                setPartiesPersonFormState
              )
            }
            renderOption={(option) => {
              return (
                <RMSComboBoxListItem
                  option={option}
                  title={'Description'}
                  label={'Ethnicity'}
                  handleFormChangeNew={handleFormChangeNew}
                  resetField={() =>
                    handleFormChangeNew(null, null, 'none', 'ethnicity', setPartiesPersonFormState)
                  }
                />
              );
            }}
            renderInput={(params) => {
              return (
                <RMSComboBox
                  {...params}
                  resetField={() =>
                    handleFormChangeNew(null, null, 'none', 'ethnicity', setPartiesPersonFormState)
                  }
                  setField={(newVal) => {
                    handleFormChangeNew(
                      null,
                      newVal['Description'],
                      'none',
                      'ethnicity',
                      setPartiesPersonFormState
                    );
                  }}
                  label="Ethnicity"
                  variant="outlined"
                  fullWidth
                  error={errors['ethnicity'] ? true : false}
                  helperText={errors['ethnicity']}
                />
              );
            }}
          />
        </Grid>
        <Grid item xs={12} lg={3}>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <KeyboardDatePicker
              size="small"
              inputVariant="outlined"
              fullWidth
              showTodayButton={true}
              variant="dialog"
              format="MM/dd/yyyy"
              id="date-picker-inline-from"
              label="Birth Date"
              value={selectedBirthDate ? selectedBirthDate : null}
              onChange={(date) => {
                setSelectedBirthDate(date);
                calculateAge(date);
                setPartiesPersonFormState((state) => {
                  return {
                    ...state,
                    values: {
                      ...state.values,
                      birthdate: date,
                    },
                  };
                });
              }}
              KeyboardButtonProps={{
                'aria-label': 'change date',
              }}
            />
          </MuiPickersUtilsProvider>
        </Grid>
      </Grid>
      <Grid container spacing={2} className="mt-2">
        <Grid item xs={12} lg={3}>
          <TextField
            id="standard-textarea-person-age"
            autoComplete="hidden"
            size="small"
            label="Age"
            placeholder="Age"
            variant="outlined"
            error={errors['age'] ? true : false}
            helperText={errors['age']}
            name="age"
            key={changeKey}
            value={partiesPersonFormState.values?.age || ''}
            onChange={(event) => {
              handleFormChangeNew(
                event,
                event.target.value,
                'none',
                'age',
                setPartiesPersonFormState
              );
            }}
            fullWidth
            multiline
            InputLabelProps={{ shrink: true }}
          />
        </Grid>
        <Grid item xs={12} lg={3}>
          <Autocomplete
            size="small"
            autoComplete
            autoSelect
            autoHighlight
            id="combo-age-increment"
            getOptionLabel={(option) => option || ''}
            options={ageIncrement.map((s) => s.code)}
            key={
              partiesPersonFormState.values?.ageRangeIncrement
                ? partiesPersonFormState.values?.ageRangeIncrement.toString()
                : ''
            }
            value={
              partiesPersonFormState.values?.ageRangeIncrement
                ? partiesPersonFormState.values?.ageRangeIncrement.toString()
                : ''
            }
            onChange={(event, newValue) =>
              handleFormChangeNew(
                event,
                newValue,
                'none',
                'ageRangeIncrement',
                setPartiesPersonFormState
              )
            }
            renderInput={(params) => (
              <TextField
                autoComplete="hidden"
                size="small"
                {...params}
                label="Age Range Increment"
                variant="outlined"
                fullWidth
              />
            )}
          />
        </Grid>
        <Grid item xs={12} lg={3}>
          <TextField
            id="standard-textarea-person-height"
            autoComplete="hidden"
            size="small"
            label="Height"
            placeholder="Height"
            variant="outlined"
            name="height"
            key={changeKey}
            value={partiesPersonFormState.values?.height || ''}
            onChange={(event) =>
              handleFormChangeNew(
                event,
                event.target.value,
                'none',
                'height',
                setPartiesPersonFormState
              )
            }
            fullWidth
            multiline
            InputLabelProps={{ shrink: true }}
          />
        </Grid>
        <Grid item xs={12} lg={3}>
          <TextField
            id="standard-textarea-person-weight"
            autoComplete="hidden"
            size="small"
            placeholder="Weight"
            label="Weight"
            variant="outlined"
            name="weight"
            key={changeKey}
            value={partiesPersonFormState.values?.weight || ''}
            onChange={(event) =>
              handleFormChangeNew(
                event,
                event.target.value,
                'none',
                'weight',
                setPartiesPersonFormState
              )
            }
            fullWidth
            multiline
            InputLabelProps={{ shrink: true }}
          />
        </Grid>
      </Grid>
      <Grid xs={12} className="mt-4 mb-2">
        <span style={{ color: '#fff', background: '#1976d2' }} className="mb-1 badge">
          Features
        </span>
        <Divider />
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={12} lg={3}>
          <RMSAutoComplete
            size="small"
            autoComplete
            autoSelect
            autoHighlight
            id="combo-eye-color"
            options={codeEyeColor}
            label="Eye Color"
            serviceName="codeeyecolor"
            variant="outlined"
            title={'Description'}
            stateHolder={partiesPersonFormState}
            setStateHolder={setPartiesPersonFormState}
            setField={handleFormChangeNew}
            stateHolderAttribute="eyeColor"
          />
        </Grid>
        <Grid item xs={12} lg={3}>
          <RMSAutoComplete
            size="small"
            autoComplete
            autoSelect
            autoHighlight
            id="combo-hair-color"
            options={codeHairColor}
            label="Hair Color"
            serviceName="codehaircolor"
            title="Description"
            stateHolderAttribute="hairColor"
            variant="outlined"
            stateHolder={partiesPersonFormState}
            setStateHolder={setPartiesPersonFormState}
            setField={handleFormChangeNew}
          />
        </Grid>
        <Grid item xs={12} lg={3}>
          <RMSAutoComplete
            size="small"
            autoComplete
            autoSelect
            autoHighlight
            id="combo-hair-style"
            options={codeHairStyle}
            label="Hair Style"
            serviceName="codeHairStyle"
            title="Code"
            stateHolderAttribute="hairStyle"
            variant="outlined"
            stateHolder={partiesPersonFormState}
            setStateHolder={setPartiesPersonFormState}
            setField={handleFormChangeNew}
          />
        </Grid>
        <Grid item xs={12} lg={3}>
          <RMSAutoComplete
            size="small"
            autoComplete
            autoSelect
            autoHighlight
            id="combo-hair-length"
            options={codeHairLength}
            label="Hair Length"
            serviceName="codehairlength"
            title="Code"
            stateHolderAttribute="hairLength"
            variant="outlined"
            stateHolder={partiesPersonFormState}
            setStateHolder={setPartiesPersonFormState}
            setField={handleFormChangeNew}
          />
        </Grid>
      </Grid>
      <Grid container spacing={2} className="mt-2">
        <Grid item xs={12} lg={3}>
          <RMSAutoComplete
            size="small"
            autoComplete
            autoSelect
            autoHighlight
            id="combo-blood-type"
            options={codeBloodType}
            label="Blood Type"
            serviceName="codeBloodType"
            title="Description"
            stateHolderAttribute="bloodType"
            variant="outlined"
            stateHolder={partiesPersonFormState}
            setStateHolder={setPartiesPersonFormState}
            setField={handleFormChangeNew}
          />
        </Grid>
        <Grid item xs={12} lg={3}>
          <RMSAutoComplete
            size="small"
            autoComplete
            autoSelect
            autoHighlight
            id="combo-handed"
            options={codeHanded}
            label="Handed"
            serviceName="codeHanded"
            title="Code"
            stateHolderAttribute="handed"
            variant="outlined"
            stateHolder={partiesPersonFormState}
            setStateHolder={setPartiesPersonFormState}
            setField={handleFormChangeNew}
          />
        </Grid>
      </Grid>
      <Grid xs={12} className="mt-4 mb-2">
        <span style={{ color: '#fff', background: '#1976d2' }} className="mb-1 badge">
          Credentials
        </span>
        <Divider />
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={3}>
          <TextField
            id="standard-textarea-person-ssn"
            autoComplete="hidden"
            size="small"
            placeholder="SSN"
            label="SSN"
            variant="outlined"
            name="ssn"
            key={changeKey}
            value={partiesPersonFormState.values?.ssn || ''}
            onChange={(event) =>
              handleFormChangeNew(
                event,
                event.target.value,
                'none',
                'ssn',
                setPartiesPersonFormState
              )
            }
            fullWidth
            multiline
            InputLabelProps={{ shrink: true }}
          />
        </Grid>
        <Grid item xs={3}>
          <TextField
            id="standard-textarea-person-oln"
            autoComplete="hidden"
            size="small"
            placeholder="OLN"
            label="OLN"
            variant="outlined"
            name="oln"
            key={changeKey}
            value={partiesPersonFormState.values?.oln || ''}
            onChange={(event) =>
              handleFormChangeNew(
                event,
                event.target.value,
                'none',
                'oln',
                setPartiesPersonFormState
              )
            }
            fullWidth
            multiline
            InputLabelProps={{ shrink: true }}
          />
        </Grid>
        {!recordForm && (
          <Grid item xs={3}>
            <RMSAutoComplete
              size="small"
              autoComplete
              autoSelect
              autoHighlight
              id="combo-state"
              options={codeStates}
              label="State"
              serviceName="codestates"
              title="Code"
              stateHolderAttribute="state"
              variant="outlined"
              stateHolder={partiesPersonFormState}
              setStateHolder={setPartiesPersonFormState}
              setField={handleFormChangeNew}
            />
          </Grid>
        )}
      </Grid>

      {rootType !== 'records' && (
        <>
          <Grid xs={12} className="mt-4 mb-2">
            <span style={{ color: '#fff', background: '#1976d2' }} className="mb-1 badge">
              Select Party Image
            </span>
            <Divider />
          </Grid>

          <Grid item xs={12}>
            <FormControl component="fieldset">
              <Grid container>
                {mugshot === null && loadingPhotos && (
                  <Grid item xs={12}>
                    <Typography color="textSecondary" variant="p">
                      Loading...
                    </Typography>
                  </Grid>
                )}

                {mugshot && mugshot.length === 0 && !loadingPhotos && (
                  <Grid item xs={12}>
                    <Typography color="textSecondary" variant="p">
                      No images found
                    </Typography>
                  </Grid>
                )}

                {!mugshot && !loadingPhotos && (
                  <Grid item xs={12}>
                    <Typography color="textSecondary" variant="p">
                      No images found
                    </Typography>
                  </Grid>
                )}
              </Grid>
              <RadioGroup
                aria-label="image"
                name="gender1"
                value={partiesPersonFormState.values.ptsImageId || ''}
                onChange={handlePartyImageChange}>
                <Grid container spacing={5}>
                  {mugshot &&
                    mugshot.map((m, index) => {
                      return (
                        <Grid item xs={4} sm={4} md={4}>
                          <FormControlLabel
                            id={index}
                            value={m.ptsImageID}
                            control={<Radio />}
                            label={
                              <img
                                alt="..."
                                className="card-img-top rounded"
                                src={'data:image/png;base64, ' + m.FileObject}
                                style={{
                                  objectFit: 'contain',
                                  width: '100px',
                                  height: '100px',
                                  margin: 'auto',
                                }}
                              />
                            }
                          />
                        </Grid>
                      );
                    })}
                </Grid>
              </RadioGroup>
            </FormControl>
          </Grid>
        </>
      )}

      <Grid container spacing={2} className="mt-3">
        <Grid item xs={12}>
          <RMSAttachments
            attachments={partiesPersonFormState.values.attachments}
            stateHolder={partiesPersonFormState}
            setStateHolder={setPartiesPersonFormState}
            setField={handleFormChangeNew}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

const mapStateToProps = (state) => ({
  wsClient: state.websocket,
  partiesPersonForm: state.incident.partiesPersonForm,
  codeBloodType: state.codesOthers.codeBloodType,
  codeEthnicity: state.codesOthers.codeEthnicity,
  codeHairColor: state.codesHair.codeHairColor,
  codeHairStyle: state.codesHair.codeHairStyle,
  codeStates: state.codeStates,
  codeSex: state.codeSex,
  codeRace: state.codeRace,
  codePartyInvolvementTypes: state.codePartyInvolvementTypes,
  codeNameSuffixes: state.codeNameSuffixes,
  codeHairLength: state.codesHair.codeHairLength,
  codeHanded: state.codesOthers.codeHanded,
  codeEyeColor: state.codesOthers.codeEyeColor,
  uiDuplicateModalData: state.uiModal,
});

export default connect(mapStateToProps, {
  updateIncPartiesPersonForm,
  getCodeHairColor,
  getCodeHairLength,
  getCodeHairStyle,
  getCodeBloodType,
  getCodeEthnicity,
  getCodeEyeColor,
  getCodeHanded,
  getCodeStates,
  getCodeSex,
  getCodeRace,
  getCodePartyInvolvementTypes,
  getCodeNameSuffixes,
  updateIncPartiesContactForm,
  updateIncPartiesEmploymentForm,
  updateIncPartiesOthersForm,
  setDuplicateModal,
  setDuplicateModalMessage,
  setDuplicateFoundBtn,
})(TabPerson);
