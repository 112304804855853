import React from 'react';
import Grid from '@material-ui/core/Grid';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import RMSCheckbox from '../../components/RMSCheckbox';
import Divider from '@material-ui/core/Divider';
import { PhoneMask } from '../../components/RMSTextMask';
import MaskedInput from 'react-text-mask';
import emailMask from 'text-mask-addons/dist/emailMask';
import { connect } from 'react-redux';
import { updateIncPartiesPlaceForm } from '../../reducers/IncReducer';
import { clearPlace, onPlaceValueChange } from '../../reducers/helpers/formHelpers';
import AddressSearch from '../../components/RMSForms/RMSAddressForm/AddressSearch';

const condition = [{ name: 'good' }, { name: 'great' }];

const TabPlace = (props) => {
  const { partiesPlaceForm, updateIncPartiesPlaceForm } = props;

  const [formState, setFormState] = React.useState({
    isValid: false,
    values: {},
    touched: {},
    errors: {},
  });

  const [partiesPlaceFormState, setPartiesPlaceFormState] = React.useState({
    incidentId: '',
    placeId: '',
    addressId: '',
    placeORI: '',
    placeDepID: '',
    placeType: '',
    destination: '',
    textmask1: '(1  )    -    ',
    textmask2: '(1  )    -    ',
    emailmask: emailMask,
    notes: '',
  });

  /**
   * For edit view only. Get saved addressForm state
   */
  React.useEffect(() => {
    setFormState((formState) => ({
      // isValid: errors ? false : true,
      // errors: errors || {},
      ...formState,
      ...(formState.values.incidentId = partiesPlaceForm.incidentId),
      ...(formState.values.placeId = partiesPlaceForm.placeId),
      ...(formState.values.addressId = partiesPlaceForm.addressId),
      ...(formState.values.placeORI = partiesPlaceForm.placeORI),
      ...(formState.values.placeDepID = partiesPlaceForm.placeDepID),
      ...(formState.values.placeType = partiesPlaceForm.placeType),
      ...(formState.values.destination = partiesPlaceForm.destination),
      ...(formState.values.textmask1 = partiesPlaceForm.textmask1),
      ...(formState.values.textmask2 = partiesPlaceForm.textmask2),
      ...(formState.values.emailmask = partiesPlaceForm.emailmask),
      ...(formState.values.notes = partiesPlaceForm.notes),
    }));
  }, []);

  React.useEffect(() => {
    // const errors = validate(formState.values, schema);
    setFormState((formState) => ({
      ...formState,
      // isValid: errors ? false : true,
      // errors: errors || {},
    }));
    setPartiesPlaceFormState((partiesPlaceFormState) => ({
      ...partiesPlaceFormState,
      ...(partiesPlaceFormState.placeORI = formState.values.placeORI),
      ...(partiesPlaceFormState.placeDepID = formState.values.placeDepID),
      ...(partiesPlaceFormState.placeType = formState.values.placeType),
      ...(partiesPlaceFormState.destination = formState.values.destination || false),
      ...(partiesPlaceFormState.textmask1 = formState.values.textmask1),
      ...(partiesPlaceFormState.textmask2 = formState.values.textmask2),
      ...(partiesPlaceFormState.emailmask = formState.values.emailmask),
      ...(partiesPlaceFormState.notes = formState.values.notes),
    }));
    updateIncPartiesPlaceForm(partiesPlaceFormState);
  }, [formState.values]);

  const handleFormChange = (event) => {
    event.persist();
    setFormState((formState) => ({
      ...formState,
      values: {
        ...formState.values,
        [event.target.name]:
          event.target.type === 'checkbox' ? event.target.checked : event.target.value,
      },
      touched: {
        ...formState.touched,
        [event.target.name]: true,
      },
    }));
  };

  return (
    <Grid item xs={12} lg={12} className="mt-2">
      <Grid xs={12} lg={12} item className="mb-3">
        <AddressSearch
          ptsPlaces={true}
          ptsAddresses={false}
          googleAddresses={false}
          onPlaceValueSet={onPlaceValueChange}
          onAddressValueSet={onPlaceValueChange}
          setFormState={setPartiesPlaceFormState}
          label={'Search Places'}
          defaultLocation={null}
          onReset={clearPlace}
        />
      </Grid>

      <Grid container spacing={3} className="mb-1">
        <Grid item xs={3} lg={3}>
          <TextField
            autoComplete="hidden"
            size="small"
            label="Place ORI"
            variant="outlined"
            name="placeORI"
            value={formState.values.placeORI}
            onChange={handleFormChange}
            fullWidth
          />
        </Grid>
        <Grid item xs={3} lg={3}>
          <TextField
            autoComplete="hidden"
            size="small"
            label="Place Department ID"
            variant="outlined"
            name="placeDepID"
            value={formState.values.placeDepID}
            onChange={handleFormChange}
            fullWidth
          />
        </Grid>
        <Grid item xs={3} lg={3}>
          <Autocomplete
            size="small"
            autoComplete
            autoSelect
            autoHighlight
            id="combo-place-type"
            getOptionLabel={(option) => option.name}
            options={condition}
            renderInput={(params) => (
              <Grid container spacing={1} alignItems="flex-end">
                <Grid item xs={12} lg={12}>
                  <TextField
                    autoComplete="hidden"
                    size="small"
                    {...params}
                    label="Place type"
                    variant="outlined"
                    name="placeType"
                    value={formState.values.placeType}
                    onSelect={handleFormChange}
                    fullWidth
                  />
                </Grid>
              </Grid>
            )}
          />
        </Grid>
        <Grid item xs={3} lg={3}>
          <RMSCheckbox
            label="Destination"
            name="destination"
            selectedCheckbox={formState.values.destination}
            handleFormChange={handleFormChange}
          />
        </Grid>
      </Grid>

      <Grid container spacing={3} justify="center">
        <Grid xs={12} lg={12} item className="mb-3">
          <AddressSearch
            ptsPlaces={true}
            ptsAddresses={false}
            googleAddresses={false}
            onPlaceValueSet={onPlaceValueChange}
            onAddressValueSet={onPlaceValueChange}
            setFormState={setPartiesPlaceFormState}
            label={'Search Address'}
            defaultLocation={null}
            onReset={clearPlace}
          />
        </Grid>
      </Grid>

      <Grid container justify="center" spacing={2}>
        <Grid item xs={6} lg={6}>
          <span
            style={{ color: '#fff', background: '#1976d2' }}
            className="m-1 badge badge-primary">
            Contacts
          </span>
          <Divider />
        </Grid>
        <Grid item xs={6} lg={6}>
          <span
            style={{ color: '#fff', background: '#1976d2' }}
            className="m-1 badge badge-primary">
            Notes
          </span>
          <Divider />
        </Grid>
      </Grid>

      <Grid container spacing={3} justify="center">
        <Grid item xs={6} lg={6}>
          <Grid container justify="center" className="mt-4">
            <Grid container spacing={3} justify="center">
              <Grid item xs={6} lg={6}>
                <TextField
                  autoComplete="hidden"
                  size="small"
                  fullWidth
                  variant="outlined"
                  label="Primary Phone"
                  name="textmask1"
                  value={formState.values.textmask1}
                  onChange={handleFormChange}
                  id="formatted-numberformat-input"
                  InputProps={{
                    inputComponent: PhoneMask,
                  }}
                />
              </Grid>
              <Grid item xs={6} lg={6}>
                <TextField
                  autoComplete="hidden"
                  size="small"
                  fullWidth
                  variant="outlined"
                  label="Secondary Phone"
                  name="textmask2"
                  value={formState.values.textmask2}
                  onChange={handleFormChange}
                  id="formatted-numberformat-input-secondary"
                  InputProps={{
                    inputComponent: PhoneMask,
                  }}
                />
              </Grid>
            </Grid>
            <Grid container spacing={3} justify="center">
              <Grid item xs={12} lg={12} className="mt-4">
                <MaskedInput
                  mask={emailMask}
                  placeholder="pts@pts.com"
                  id="input-id-email"
                  render={(ref, props) => (
                    <TextField
                      autoComplete="hidden"
                      size="small"
                      variant="outlined"
                      fullWidth
                      innerRef={ref}
                      {...props}
                      name="emailmask"
                      onChange={handleFormChange}
                      value={formState.values.emailmask}
                    />
                  )}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={6} lg={6}>
          <TextField
            autoComplete="hidden"
            size="small"
            className="mt-3"
            id="outlined-multiline-static-address-notes"
            label="Notes"
            multiline
            rows="7"
            name="notes"
            onChange={handleFormChange}
            value={formState.values.notes}
            fullWidth
            defaultValue=""
            variant="outlined"
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

const mapStateToProps = (state) => ({
  partiesPlaceForm: state.incident.partiesPlaceForm,
});

export default connect(mapStateToProps, {
  updateIncPartiesPlaceForm,
})(TabPlace);
