/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';

import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Divider from '@material-ui/core/Divider';
import { makeStyles } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import PersonPinIcon from '@material-ui/icons/PersonPin';
import PerfectScrollbar from 'react-perfect-scrollbar';

import TabPerson from '../../../../pages/PageRMSParties/TabPerson';
import RMSFabButtons from '../../RMSIncidentForm/RMSFabButtons';
import TabPanel from '../../RMSIncidentForm/TabPanel';
import {
  setCircularLoading,
  setHandleClose,
  setMenuType,
} from '../../../../reducers/ui/UiMenuReducer';
import { initFormModel, updateIncPartiesPersonForm } from '../../../../reducers/IncReducer';
import { setOffensesTab } from '../../../../reducers/ui/UiFormReducer';
import { upsertRecord } from 'reducers/RecordsReducer';
import { duplicateCheck } from 'reducers/helpers/duplicateCheckHelpers';
import { setDuplicateModal, setDuplicateModalMessage } from 'reducers/ui/UiModalReducer';
import { notify } from 'reducers/NotifierReducer';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    height: window.innerHeight - 90 + 'px',
    marginBottom: '20px',
  },
  scrollBar: {
    width: '100%',
    overflowX: 'hidden',
    paddingRight: '5px',
    [theme.breakpoints.up('lg')]: {
      height: '70vh',
    },
    [theme.breakpoints.down('lg')]: {
      height: '55vh',
    },
    [theme.breakpoints.only('lg')]: {
      height: '65vh',
    },
  },
  indicator: {
    backgroundColor: 'rgba(0,0,254,0.3)',
  },
  paper: {
    minHeight: window.innerHeight - 95 + 'px',
  },
  dialogBox: {
    minWidth: '412px',
    minHeight: '150px',
  },
  icons: {
    textDecoration: 'capitalize',
    padding: '14px',
    fontSize: '13px',
  },
  btn: {
    position: 'absolute',
    bottom: '64px',
    right: '262px',
    zIndex: 999,
    maxWidth: '200px',
    textAlign: 'right',
  },
}));

const PersonFrom = (props) => {
  /** region Component Variables and Props */
  const classes = useStyles();
  let { personId } = useParams();
  const dispatch = useDispatch();
  const [value, setValue] = React.useState(0);
  const wsClient = useSelector((state) => state.websocket);
  const { history, updateIncPartiesPersonForm, upsertRecord, partiesPersonForm } = props;
  /** endregion */
  /** region React Hooks */
  React.useEffect(() => {
    if (!wsClient?.websocket) {
      return;
    }
    if (!partiesPersonForm.ptsPersonID && !partiesPersonForm.ptsPersonId) {
      getPersonData();
    }
  }, [wsClient]);
  /** endregion */
  /** region Helper Functions */
  const formatPersonFormData = (data) => {
    return {
      ptsPersonId: data.ptsPersonID,
      ptsPersonID: data.ptsPersonID,
      PersonId: data.PersonID,
      PersonID: data.PersonID,
      values: {
        firstName: data.FirstName,
        lastName: data.LastName,
        middleName: data.MiddleName,
        ptsPersonId: data.ptsPersonID,
        age: data.Age,
        ageRangeIncrement: data.AgeRangeIncrement,
        birthdate: data.Born,
        bloodType: data.BloodType,
        ethnicity: data.Ethnicity,
        eyeColor: data.EyeColor,
        hairColor: data.HairColor,
        hairLength: data.HairLength,
        hairStyle: data.HairStyle,
        handed: data.Handed,
        height: data.Height,
        incidentId: data.ptsIncidentID,
        partyType: data.Relationship,
        personId: data.PersonID,
        race: data.Race,
        sex: data.Sex,
        weight: data.Weight,
        ssn: data.PersonCredentialSSN?.length > 0 ? data.PersonCredentialSSN[0]?.Number : '',
        oln: data.PersonCredentialOLN?.length > 0 ? data.PersonCredentialOLN[0]?.Number : '',
        state: data.OLNState,
        suffix: data.Suffix,
      },
    };
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const getPersonData = async () => {
    const service = wsClient?.websocket.service('record-person-details');
    service.timeout = 200000;

    if (!personId) return;
    dispatch(setCircularLoading(true));
    const result = await service.get(personId);
    if (result) {
      dispatch(setCircularLoading(false));
    }
    updateIncPartiesPersonForm(formatPersonFormData(result));
  };

  const handleSave = async () => {
    await duplicateCheck(partiesPersonForm, 'person').then((res) => {
      if (!res) {
        upsertRecord('person', {}, 'add').finally(() => {
          dispatch(setCircularLoading(false));
          updateIncPartiesPersonForm(initFormModel);
          history.goBack();
        });
      } else {
        dispatch(notify('Duplicate Found.', 'warning'));
        dispatch(setCircularLoading(false));
      }
    });
  };
  /** endregion */
  return (
    <div className={classes.root}>
      <RMSFabButtons recordForm={true} handleSaveRecord={handleSave} />
      <Grid container>
        <Grid xs={12} lg={12}>
          <Paper className={classes.paper}>
            <Grid container justify="center">
              <Grid item xs={12} style={{ paddingTop: '0px' }}>
                <div
                  className="card-header-actions"
                  style={{
                    backgroundColor: '#1976d2',
                    color: 'white',
                    borderRadius: '0.5rem 0.5rem 0 0',
                  }}>
                  <Tabs
                    value={value}
                    indicatorColor="secondary"
                    variant="fullWidth"
                    classes={{
                      indicator: classes.indicator,
                    }}
                    onChange={handleChange}>
                    <Tab
                      className={classes.icons}
                      icon={<PersonPinIcon />}
                      label="Person"
                      style={{ color: 'white' }}
                    />
                  </Tabs>
                  <Divider />
                </div>
              </Grid>
              <PerfectScrollbar className={classes.scrollBar}>
                <Grid item>
                  <TabPanel value={value} index={0}>
                    <TabPerson history={history} recordForm={true} />
                  </TabPanel>
                </Grid>
              </PerfectScrollbar>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    </div>
  );
};

const mapStateToProps = (state) => ({
  partiesPersonForm: state.incident.partiesPersonForm,
  duplicatePersonForm: state.duplicateForm.dulicatePersonForm,
  showDuplicateModal: state.uiModal.showDuplicateModal,
});

export default connect(mapStateToProps, {
  setHandleClose,
  setMenuType,
  updateIncPartiesPersonForm,
  setOffensesTab,
  upsertRecord,
  setDuplicateModal,
  setDuplicateModalMessage,
})(PersonFrom);
