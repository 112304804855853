export const GET_CODE_SUBJECT_WEAPON = 'GET_CODE_SUBJECT_WEAPON';
export const ADD_CODE_SUBJECT_WEAPON = 'ADD_CODE_SUBJECT_WEAPON';

export const addCodeSubjectWeapon = (newVal) => {
  return { type: ADD_CODE_SUBJECT_WEAPON, payload: newVal };
};

export const getCodeSubjectWeapon = (state) => {
  return async (dispatch) => {
    return dispatch({ type: GET_CODE_SUBJECT_WEAPON, payload: state });
  };
};

export default function reducer(state = [], action) {
  switch (action.type) {
    case GET_CODE_SUBJECT_WEAPON:
      state = action.payload;
      return state;
    case ADD_CODE_SUBJECT_WEAPON:
      return [...state, action.payload];
    default:
      return state;
  }
}
