import React from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation, useParams } from 'react-router';

import DateFnsUtils from '@date-io/date-fns';
import { Grid, makeStyles, Paper, TextField } from '@material-ui/core';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';

import RMSFabButtons from 'components/RMSForms/RMSIncidentForm/RMSFabButtons';
import VehicleSearchForRecord from 'pages/PageRMSProperties/PageRMSVehicle/VehicleSearchForRecord';
import { handleFormChangeNew } from 'reducers/helpers/formHelpers';
import { upsertRecord } from 'reducers/RecordsReducer';
import { setCircularLoading } from 'reducers/ui/UiMenuReducer';
import SimpleSchema from 'simpl-schema';

/**validation schema */
const validationContext = new SimpleSchema({
  VehicleValue: SimpleSchema.Integer,
  OdometerReading: SimpleSchema.Integer,
}).newContext();
/**end */
const useStyles = makeStyles((theme) => ({
  root: {
    height: window.innerHeight - 90 + 'px',
    borderRadius: '0.5rem',
    padding: '15px',
  },
  form: {
    width: '100%',
  },
  input: {
    width: '100%',
    padding: '10px',
  },
  btnStyle: {
    display: 'block',
    width: '150px',
    padding: '10px',
    marginTop: '15px',
    marginLeft: 'auto',
    position: 'relative',
    right: 10,
  },
}));

const PersonVehicleRegistrationForm = (props) => {
  /** region props and variables */
  const classes = useStyles();
  let data = useSelector((state) => state.records.formData);
  const { persondId } = useParams();
  let { upsertRecord } = props;
  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();

  const [firstLoad, setFirstLoad] = React.useState(true);
  const [errors, setErrors] = React.useState({});
  const [vehicleID, setVehicleID] = React.useState('');
  const [formData, setFormData] = React.useState({
    values: {
      ptsVehicleID: data?.ptsVehicleID || undefined,
      ptsParentID: persondId,
      PartyType: 'Person',
      PlateType: data?.PlateType || '',
      PlateColor: data?.PlateColor || '',
      PlateNumber: data?.PlateNumber || '',
      PlateBackgroundText: data?.PlateBackgroundText || '',
      PlateDetail: data?.PlateDetail || '',
      PlateStatus: data?.PlateStatus || '',
      OdometerReading: data?.OdometerReading || '',
      InsuranceCompany: data?.InsuranceCompany || '',
      VehicleValue: data?.VehicleValue || '',
      PlateExpiration: data?.PlateExpiration || '',
    },
  });
  const actionType = location.pathname.split('/')[5];
  if (actionType === 'add') {
    data = {};
  }
  /** end region */

  /**region lifecycle hooks */
  React.useEffect(() => {
    if (!firstLoad) {
      validationContext.validate({
        VehicleValue:
          formData.values?.VehicleValue?.length > 0 ? Number(formData.values?.VehicleValue) : 0,
        OdometerReading:
          formData.values?.OdometerReading?.length > 0
            ? Number(formData.values?.OdometerReading)
            : 0,
      });

      if (validationContext.isValid() === false) {
        const errs = validationContext.validationErrors();
        let e = {};
        errs.map((er) => {
          e[er.name] = `Value must be ${er.dataType}`;
          setErrors(e);
        });
      } else {
        setErrors({});
      }
    }
    setFirstLoad(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formData?.values?.OdometerReading, formData?.values?.VehicleValue]);
  /** end region */

  /** region helper functions*/
  const handleSave = () => {
    const newForm = {
      ...formData.values,
      VehicleValue: Number.isInteger(Number(formData.values.VehicleValue))
        ? Number(formData.values.VehicleValue)
        : 0,
      OdometerReading: Number.isInteger(Number(formData.values.OdometerReading))
        ? Number(formData.values.OdometerReading)
        : 0,
      PlateExpiration: formData.values.PlateExpiration
        ? new Date(formData.values.PlateExpiration)
        : null,
    };

    if (!data.ptsRegistrationID) {
      upsertRecord('vehicle', { ...newForm, ptsVehicleID: vehicleID }, 'add').finally(() =>
        handleFinally()
      );
    } else {
      upsertRecord(
        'vehicle',
        { ...newForm, ptsRegistrationID: data.ptsRegistrationID },
        'edit'
      ).finally(() => handleFinally());
    }
  };

  const handleFinally = () => {
    dispatch(setCircularLoading(false));
    history.goBack();
  };
  /** end region */

  return (
    <Paper className={classes.root}>
      {actionType !== 'edit' && (
        <Grid container xs={12} align="center" className="mt-2 mb-2">
          <VehicleSearchForRecord setVehicleID={setVehicleID} />
        </Grid>
      )}
      <fieldset disabled={vehicleID || actionType === 'edit' ? false : true}>
        <form style={{ marginTop: '20px' }}>
          <Grid container spacing={2}>
            <Grid item xs={4}>
              <TextField
                id="standard-textarea-PlateType"
                autoComplete="hidden"
                size="small"
                placeholder="Plate Type"
                label="Plate Type"
                variant="outlined"
                name="PlateType"
                defaultValue={formData.values?.PlateType || ''}
                onBlur={(event) =>
                  handleFormChangeNew(event, event.target.value, 'none', 'PlateType', setFormData)
                }
                fullWidth
                multiline
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                id="standard-textarea-PlateColor"
                autoComplete="hidden"
                size="small"
                placeholder="Plate Color"
                label="Plate Color"
                variant="outlined"
                name="DPlateColor"
                defaultValue={formData.values?.PlateColor || ''}
                onBlur={(event) =>
                  handleFormChangeNew(event, event.target.value, 'none', 'PlateColor', setFormData)
                }
                fullWidth
                multiline
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                id="standard-textarea-PlateNumber"
                autoComplete="hidden"
                size="small"
                placeholder="Plate Number"
                label="Plate Number"
                variant="outlined"
                name="PlateNumber"
                defaultValue={formData.values?.PlateNumber || ''}
                onBlur={(event) =>
                  handleFormChangeNew(event, event.target.value, 'none', 'PlateNumber', setFormData)
                }
                fullWidth
                multiline
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                id="standard-textarea-PlateBackgroundText"
                autoComplete="hidden"
                size="small"
                placeholder="Plate Background Text"
                label="Plate Background Text"
                variant="outlined"
                name="Plate Background Text"
                defaultValue={formData.values?.PlateBackgroundText || ''}
                onBlur={(event) =>
                  handleFormChangeNew(
                    event,
                    event.target.value,
                    'none',
                    'PlateBackgroundText',
                    setFormData
                  )
                }
                fullWidth
                multiline
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                id="standard-textarea-PlateDetail"
                autoComplete="hidden"
                size="small"
                placeholder="Plate Detail"
                label="Plate Detail"
                variant="outlined"
                name="PlateDetail"
                defaultValue={formData.values?.PlateDetail || ''}
                onBlur={(event) =>
                  handleFormChangeNew(event, event.target.value, 'none', 'PlateDetail', setFormData)
                }
                fullWidth
                multiline
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                id="standard-textarea-PlateStatus"
                autoComplete="hidden"
                size="small"
                placeholder="Plate Status"
                label="Plate Status"
                variant="outlined"
                name="PlateStatus"
                defaultValue={formData.values?.PlateStatus || ''}
                onBlur={(event) =>
                  handleFormChangeNew(event, event.target.value, 'none', 'PlateStatus', setFormData)
                }
                fullWidth
                multiline
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                id="standard-textarea-OdometerReading"
                autoComplete="hidden"
                size="small"
                error={errors['OdometerReading'] ? true : false}
                helperText={errors['OdometerReading']}
                placeholder="Odometer Reading"
                label="Odometer Reading"
                variant="outlined"
                name="OdometerReading"
                defaultValue={formData.values?.OdometerReading || ''}
                onChange={(event) =>
                  handleFormChangeNew(
                    event,
                    event.target.value,
                    'none',
                    'OdometerReading',
                    setFormData
                  )
                }
                fullWidth
                multiline
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                id="standard-textarea-InsuranceCompany"
                autoComplete="hidden"
                size="small"
                placeholder="Insurance Company"
                label="Insurance Company"
                variant="outlined"
                name="InsuranceCompany"
                defaultValue={formData.values?.InsuranceCompany || ''}
                onBlur={(event) =>
                  handleFormChangeNew(
                    event,
                    event.target.value,
                    'none',
                    'InsuranceCompany',
                    setFormData
                  )
                }
                fullWidth
                multiline
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                id="standard-textarea-VehicleValue"
                autoComplete="hidden"
                size="small"
                error={errors['VehicleValue'] ? true : false}
                helperText={errors['VehicleValue']}
                placeholder="Vehicle Value"
                label="Vehicle Value"
                variant="outlined"
                name="VehicleValue"
                defaultValue={formData.values?.VehicleValue || ''}
                onChange={(event) =>
                  handleFormChangeNew(
                    event,
                    event.target.value,
                    'none',
                    'VehicleValue',
                    setFormData
                  )
                }
                fullWidth
                multiline
                InputLabelProps={{ shrink: true }}
              />
            </Grid>

            <Grid item xs={4}>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  size="small"
                  inputVariant="outlined"
                  fullWidth
                  showTodayButton={true}
                  variant="dialog"
                  format="MM/dd/yyyy"
                  id="date-picker-inline-from"
                  label="Plate Expiration"
                  value={formData.values?.PlateExpiration || null}
                  onChange={(date) => {
                    setFormData((state) => {
                      return {
                        ...state,
                        values: {
                          ...state.values,
                          PlateExpiration: date,
                        },
                      };
                    });
                  }}
                  KeyboardButtonProps={{
                    'aria-label': 'change date',
                  }}
                />
              </MuiPickersUtilsProvider>
            </Grid>
          </Grid>
        </form>
      </fieldset>
      <RMSFabButtons recordForm={true} handleSaveRecord={handleSave} />
    </Paper>
  );
};

const mapStateToProps = (state) => ({
  wsClient: state.websocket,
});

export default connect(mapStateToProps, { upsertRecord })(PersonVehicleRegistrationForm);
