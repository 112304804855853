import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { sortObjArr } from '../../../../utils/functions';
import PerfectScrollbar from 'react-perfect-scrollbar';

const useStyles = makeStyles((theme) => ({
  zones: {
    overflowY: 'auto',
  },
  case: {
    padding: '10px 15px',
    borderBottom: '1px solid #D3D3D3',
    color: theme.palette.type === 'light' ? 'rgba(0, 0, 0, 0.87)' : '#d4d6d7',
    position: 'relative',
    boxSizing: 'border-box',
    '& p': {
      fontSize: 13,
      marginBottom: 0,
    },
    '&:hover': {
      '& $zoneAction': {
        opacity: 1,
      },
    },
  },
  actions: {
    padding: '5px 15px',
    textAlign: 'right',
    '& label': {
      marginBottom: 0,
    },
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'end',
    '& h4': {
      fontSize: 16,
      minWidth: 130,
      marginBottom: '0.25em',
    },
    '& p': {
      fontSize: 14,
    },
  },
  date: {
    maxWidth: 150,
    display: 'inline-block',
  },
  status: {
    textAlign: 'center',
    '& > div': {
      fontSize: '1rem',
      fontWeight: 500,
      padding: '0 5px',
      borderRadius: 5,
    },
    '& i': {
      padding: '2px 10px 1px',
      borderRadius: '4px',
      display: 'inline-block',
      textTransform: 'uppercase',
      fontWeight: 500,
      fontSize: 12,
      fontStyle: 'normal',
    },
  },
  zoneAction: {
    position: 'absolute',
    right: 10,
    top: 5,
    opacity: 0,
    transition: 'opacity 0.3s',
  },
  form: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    flexGrow: 1,
    padding: theme.spacing(1),
  },
  autocomplete: {
    margin: '0 4px',
    maxWidth: '50%',
    flexBasis: 180,
    minWidth: 180,
    flexGrow: 1,
  },
}));

function Zone(props) {
  const classes = useStyles();
  const { ZoneCode, Description, AgencyID } = props.zone;

  return (
    <div className={classes.case}>
      <div className={classes.header}>
        <div className={classes.left}>
          <h4>{ZoneCode}</h4>
          <p>
            {Description} ({AgencyID})
          </p>
        </div>
        <div></div>
      </div>
    </div>
  );
}

function ZonesTab(props) {
  const { sortOption } = props;
  const [sortedZones, setSortedZones] = useState([]);
  const [agency, setAgency] = useState(null);
  const [zones, setZones] = useState([]);
  const [agencyZones, setAgencyZones] = useState([]);
  const classes = useStyles();

  useEffect(() => {
    if (!props.zones) return;
    setSortedZones(sortObjArr(props.zones, 'ZoneCode', sortOption));
    // eslint-disable-next-line
  }, [props.zones, sortOption]);

  useEffect(() => {
    if (zones.length === 0 || !agency) return;
    setAgencyZones(
      zones
        .filter((zone) => zone.AgencyID === agency.AgencyID)
        .filter((zone) => !sortedZones.find((z) => z.ZoneCode === zone.ZoneCode))
    );
    // eslint-disable-next-line
  }, [zones, agency]);

  const renderZones = () => {
    return (
      <>
        {sortedZones.map((zone) => (
          <Zone key={zone.ZoneCode} zone={zone} />
        ))}
      </>
    );
  };

  if (!sortedZones) return '';
  return (
    <div className={classes.zones}>
      <PerfectScrollbar style={{ maxHeight: window.innerHeight - 500 + 'px' }}>
        {renderZones()}
      </PerfectScrollbar>
    </div>
  );
}

export default ZonesTab;
