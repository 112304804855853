import React, { useState } from 'react';
import { connect, useSelector } from 'react-redux';
import { useLocation } from 'react-router';

import DateFnsUtils from '@date-io/date-fns';
import {
  FormControl,
  Grid,
  InputLabel,
  makeStyles,
  MenuItem,
  Select,
  TextField,
  Paper,
} from '@material-ui/core';
import { KeyboardDateTimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';

import { handleFormChangeNew } from 'reducers/helpers/formHelpers';

const useStyles = makeStyles((theme) => ({
  root: {
    height: window.innerHeight - 90 + 'px',
    borderRadius: '0.5rem',
    padding: '15px',
  },
  form: {
    width: '100%',
  },
  input: {
    width: '100%',
    padding: '10px',
  },
  btnStyle: {
    display: 'block',
    width: '150px',
    padding: '10px',
    marginTop: '15px',
    marginLeft: 'auto',
    position: 'relative',
    right: 10,
  },
}));

const PersonSOPForm = (props) => {
  /** region props and variables */
  const classes = useStyles();
  let data = useSelector((state) => state.records.formData);
  const location = useLocation();
  const actionType = location.pathname.split('/')[5];
  if (actionType === 'add') {
    data = {};
  }
  const [formData, setFormData] = useState({ values: {} });

  /** end region */
  return (
    <Paper className={classes.root}>
      <form>
        <Grid container spacing={2}>
          <Grid item xs={4}>
            <TextField
              id="standard-textarea-DispatcherMessage"
              autoComplete="hidden"
              size="small"
              placeholder="Dispatcher Message"
              label="Dispatcher Message"
              variant="outlined"
              name="DispatcherMessage"
              defaultValue={formData.values?.DispatcherMessage || ''}
              onBlur={(event) =>
                handleFormChangeNew(
                  event,
                  event.target.value,
                  'none',
                  'DispatcherMessage',
                  setFormData
                )
              }
              fullWidth
              multiline
              InputLabelProps={{ shrink: true }}
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              id="standard-textarea-DispatcherQuestions"
              autoComplete="hidden"
              size="small"
              placeholder="Dispatcher Questions"
              label="Dispatcher Questions"
              variant="outlined"
              name="DispatcherQuestions"
              defaultValue={formData.values?.DispatcherQuestions || ''}
              onBlur={(event) =>
                handleFormChangeNew(
                  event,
                  event.target.value,
                  'none',
                  'DispatcherQuestions',
                  setFormData
                )
              }
              fullWidth
              multiline
              InputLabelProps={{ shrink: true }}
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              id="standard-textarea-AlertMessage"
              autoComplete="hidden"
              size="small"
              placeholder="Alert Message"
              label="Alert Message"
              variant="outlined"
              name="AlertMessage"
              defaultValue={formData.values?.AlertMessage || ''}
              onBlur={(event) =>
                handleFormChangeNew(event, event.target.value, 'none', 'AlertMessage', setFormData)
              }
              fullWidth
              multiline
              InputLabelProps={{ shrink: true }}
            />
          </Grid>

          <Grid item xs={4}>
            <FormControl variant="outlined" className={classes.form} size="small">
              <InputLabel htmlFor="outlined-age-native-simple">IsAlertMessageUsed</InputLabel>
              <Select
                small
                value={formData.values?.IsAlertMessageUsed}
                onChange={(event) =>
                  handleFormChangeNew(
                    event,
                    event.target.value,
                    'none',
                    'IsAlertMessageUsed',
                    setFormData
                  )
                }
                label="IsAlertMessageUsed"
                inputProps={{
                  name: 'IsAlertMessageUsed',
                  id: 'outlined-isMark-simple',
                }}>
                <MenuItem value="1">True</MenuItem>
                <MenuItem value="0">Flase</MenuItem>
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={4}>
            <TextField
              id="standard-textarea-PagingMessage"
              autoComplete="hidden"
              size="small"
              placeholder="Paging Message"
              label="Paging Message"
              variant="outlined"
              name="PagingMessage"
              defaultValue={formData.values?.PagingMessage || ''}
              onBlur={(event) =>
                handleFormChangeNew(event, event.target.value, 'none', 'PagingMessage', setFormData)
              }
              fullWidth
              multiline
              InputLabelProps={{ shrink: true }}
            />
          </Grid>
          <Grid item xs={4}>
            <FormControl variant="outlined" className={classes.form} size="small">
              <InputLabel htmlFor="outlined-age-native-simple">IsPagingMessageUsed</InputLabel>
              <Select
                small
                value={formData.values?.IsPagingMessageUsed}
                onChange={(event) =>
                  handleFormChangeNew(
                    event,
                    event.target.value,
                    'none',
                    'IsPagingMessageUsed',
                    setFormData
                  )
                }
                label="IsPagingMessageUsed"
                inputProps={{
                  name: 'IsPagingMessageUsed',
                  id: 'outlined-isMark-simple',
                }}>
                <MenuItem value="1">True</MenuItem>
                <MenuItem value="0">Flase</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={4}>
            <FormControl variant="outlined" className={classes.form} size="small">
              <InputLabel htmlFor="outlined-age-native-simple">IsApproved</InputLabel>
              <Select
                small
                value={formData.values?.IsApproved}
                onChange={(event) =>
                  handleFormChangeNew(event, event.target.value, 'none', 'IsApproved', setFormData)
                }
                label="IsApproved"
                inputProps={{
                  name: 'IsApproved',
                  id: 'outlined-isMark-simple',
                }}>
                <MenuItem value="1">True</MenuItem>
                <MenuItem value="0">Flase</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={4}>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <KeyboardDateTimePicker
                size="small"
                inputVariant="outlined"
                fullWidth
                showTodayButton={true}
                variant="dialog"
                format="MM/dd/yyyy hh:mm a"
                id="date-picker-inline-from-Approved"
                label="Image Date time"
                value={formData.values?.Approved || null}
                onChange={(date) => {
                  setFormData((state) => {
                    return {
                      ...state,
                      values: {
                        ...state.values,
                        Approved: date,
                      },
                    };
                  });
                }}
                KeyboardButtonProps={{
                  'aria-label': 'change date',
                }}
              />
            </MuiPickersUtilsProvider>
          </Grid>
        </Grid>
      </form>
    </Paper>
  );
};

const mapStateToProps = (state) => ({
  wsClient: state.websocket,
});
export default connect(mapStateToProps, {})(PersonSOPForm);
