import React from 'react';
import { connect } from 'react-redux';

import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Checkbox from '@material-ui/core/Checkbox';

import { getCodeEthnicity, getCodeCities } from '../../reducers/dictionaries/CodeOthersReducer';
import { getCodeRace } from '../../reducers/dictionaries/CodeRaceReducer';
import { setRecordAdvanceSearchKeyword } from '../../reducers/RecordsReducer';
import {
  getCodeFirearmCaliber,
  getCodeFirearmCategory,
} from '../../reducers/dictionaries/CodeFirearmReducer';
import {
  getCodeItemMakes,
  getCodeItemModels,
  getCodeItemConditions,
} from '../../reducers/dictionaries/CodeItemReducer';
import { getCodeStates } from '../../reducers/dictionaries/CodeStatesReducer';
import { getCodeVehicleMakes } from '../../reducers/dictionaries/CodeVehicleMakesReducer';
import { getCodeVehicleMakeModels } from '../../reducers/dictionaries/CodeVehicleMakesModelsReducer';
import { getCodeVehicleBranders } from '../../reducers/dictionaries/CodeVehicleBrandersReducer';
import { getCodeVehicleBrands } from '../../reducers/dictionaries/CodeVehicleBrandsReducer';
import { getCodeSubstanceCategory } from '../../reducers/dictionaries/CodeSubstanceCategoryReducer';

function RecordAdvanceSearch(props) {
  /** region Component Variables and Props */
  const {
    codeEthnicity,
    codeStates,
    codeCities,
    codeVehicleMakes,
    codeVehicleMakesModels,
    codeVehicleBrands,
    codeVehicleBranders,
    codeSubstanceCategory,
    codeFirearmCaliber,
    codeFirearmCategory,
    codeItemModels,
    codeItemConditions,
    codeItemMakes,
    codeRace,
    records,
    entityType,
    setRecordAdvanceSearchKeyword,
    getCodeEthnicity,
    getCodeStates,
    getCodeCities,
    getCodeVehicleMakes,
    getCodeVehicleMakeModels,
    getCodeVehicleBrands,
    getCodeVehicleBranders,
    getCodeSubstanceCategory,
    getCodeItemMakes,
    getCodeItemModels,
    getCodeItemConditions,
    getCodeFirearmCaliber,
    getCodeRace,
    getCodeFirearmCategory,
    wsClient,
  } = props;
  const { recordAdvanceSearchKeyword } = records;
  const [codeData, setCodeData] = React.useState({});
  /** endregion */
  /** region React Hooks */
  React.useEffect(() => {
    console.log(recordAdvanceSearchKeyword[entityType]);
  }, [entityType, recordAdvanceSearchKeyword]);

  React.useEffect(() => {
    if (!wsClient.websocket) return;
    getCodeEthnicity();
    getCodeCities();
    getCodeItemModels();
    getCodeItemConditions();
    getCodeItemMakes();
    getCodeFirearmCategory();
    getCodeFirearmCaliber();

    const services = [
      'codeRace',
      'codeStates',
      'codeVehicleMakes',
      'codeVehicleMakesModels',
      'codeVehicleBrands',
      'codeVehicleBranders',
      'codeSubstanceCategory',
    ];

    services.forEach(async (svc) => {
      let $select = ['Code'];

      if (props[svc].length < 1) {
        if (svc === 'codeVehicleMakesModels') {
          $select = ['MakeCode', 'ModelCode'];
        } else if (svc === 'codeRace') {
          $select = ['Description'];
        }

        const result = await wsClient.websocket.service(svc.toLowerCase()).find({
          query: {
            $limit: 0,
            $select,
            $sort: {
              Description: 1,
            },
            IsDeleted: false,
          },
        });

        if (result.total > 0) {
          if (svc === 'codeStates') {
            getCodeStates(result.data);
          } else if (svc === 'codeVehicleMakes') {
            getCodeVehicleMakes(result.data);
          } else if (svc === 'codeVehicleMakesModels') {
            getCodeVehicleMakeModels(result.data);
          } else if (svc === 'codeVehicleBrands') {
            getCodeVehicleBrands(result.data);
          } else if (svc === 'codeVehicleBranders') {
            getCodeVehicleBranders(result.data);
          } else if (svc === 'codeSubstanceCategory') {
            getCodeSubstanceCategory(result.data);
          } else if (svc === 'codeRace') {
            getCodeRace(result.data);
          }
        }
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [wsClient, getCodeEthnicity]);

  React.useEffect(() => {
    setCodeData({
      ...codeData,
      codeEthnicity: codeEthnicity,
      codeStates: codeStates,
      codeCities: codeCities,
      sexData: [{ Code: 'M' }, { Code: 'F' }, { Code: 'U' }],
      codeVehicleMakes: codeVehicleMakes,
      codeVehicleMakesModels: codeVehicleMakesModels,
      codeVehicleBrands: codeVehicleBrands,
      codeVehicleBranders: codeVehicleBranders,
      codeSubstanceCategory: codeSubstanceCategory,
      codeFirearmCaliber: codeFirearmCaliber,
      codeFirearmCategory: codeFirearmCategory,
      codeItemModels: codeItemModels,
      codeItemConditions: codeItemConditions,
      codeItemMakes: codeItemMakes,
      codeRace: codeRace,
      codeMethodType: [
        { Code: 'BUS' },
        { Code: 'EML' },
        { Code: 'HME' },
        { Code: 'WEB' },
        { Code: 'WRK' },
      ],
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    codeEthnicity,
    codeCities,
    codeStates,
    codeVehicleMakes,
    codeVehicleMakesModels,
    codeVehicleBrands,
    codeVehicleBranders,
    codeSubstanceCategory,
    codeFirearmCaliber,
    codeFirearmCategory,
    codeItemModels,
    codeItemConditions,
  ]);
  /** endregion */
  /** region Helper Functions */
  const handleOnChange = (val, keyword) => {
    const allTypeKeywordFields = recordAdvanceSearchKeyword;
    const selectedTypeKeywordFields = recordAdvanceSearchKeyword[entityType].map((k) => {
      if (k.keyword === keyword) {
        k.value = val;
      }
      return k;
    });

    allTypeKeywordFields[entityType] = selectedTypeKeywordFields;
    setRecordAdvanceSearchKeyword(allTypeKeywordFields);
  };
  /** endregion */
  return (
    <Grid container spacing={2} wrap="nowrap">
      {/* eslint-disable-next-line array-callback-return */}
      {recordAdvanceSearchKeyword[entityType].map((singleField) => {
        if (singleField.type === 'Text' || singleField.type === 'Integer') {
          return (
            <Grid item xs={2}>
              <TextField
                autoComplete="hidden"
                size="small"
                id={`record-search-box-${singleField.keyword}`}
                fullWidth
                onChange={(e) => handleOnChange(e.target.value, singleField.keyword)}
                label={singleField.label}
                variant="outlined"
                value={singleField.value || ''}
                inputValue={singleField.value || ''}
              />
            </Grid>
          );
        } else if (singleField.type === 'Checkbox') {
          return (
            <Grid item xs={2}>
              <Checkbox
                id={`record-search-box-${singleField.keyword}`}
                checked={singleField.value}
                onChange={(e) => handleOnChange(e.target.checked, singleField.keyword)}
                inputProps={{ 'aria-label': 'primary checkbox' }}
                label={singleField.label}
              />{singleField.label}
            </Grid>
          );
        } else if (singleField.type === 'Autocomplete') {
          return (
            <Grid item xs={2}>
              <Autocomplete
                size="small"
                autoComplete
                autoSelect
                autoHighlight
                id="combo-ethnicity"
                getOptionLabel={(option) => option || ''}
                options={
                  codeData[singleField.codeData]?.map((o) => {
                    return o[singleField.option];
                  }) || []
                }
                key={singleField.value || ''}
                value={singleField.value || ''}
                onChange={(e, newValue) => handleOnChange(newValue, singleField.keyword)}
                renderInput={(params) => (
                  <TextField
                    autoComplete="hidden"
                    {...params}
                    label={singleField.label}
                    variant="outlined"
                    size="small"
                    fullWidth
                  />
                )}
              />
            </Grid>
          );
        }
      })}
    </Grid>
  );
}

const mapStateToProps = (state) => ({
  wsClient: state.websocket,
  records: state.records,
  codeEthnicity: state.codesOthers.codeEthnicity,
  codeStates: state.codeStates,
  codeCities: state.codesOthers.codeCities,
  codeVehicleMakes: state.codeVehicleMakes,
  codeVehicleMakesModels: state.codeVehicleMakesModels,
  codeVehicleBrands: state.codeVehicleBrands,
  codeVehicleBranders: state.codeVehicleBranders,
  codeSubstanceCategory: state.codeSubstanceCategory,
  codeFirearmCaliber: state.codesFirearm.codeFirearmCaliber,
  codeFirearmCategory: state.codesFirearm.codeFirearmCategory,
  codeItemModels: state.codesItem.codeItemModels,
  codeItemConditions: state.codesItem.codeItemConditions,
  codeItemMakes: state.codesItem.codeItemMakes,
  codeRace: state.codeRace,
});

export default connect(mapStateToProps, {
  setRecordAdvanceSearchKeyword,
  getCodeEthnicity,
  getCodeStates,
  getCodeCities,
  getCodeRace,
  getCodeVehicleMakes,
  getCodeVehicleMakeModels,
  getCodeVehicleBrands,
  getCodeVehicleBranders,
  getCodeSubstanceCategory,
  getCodeItemMakes,
  getCodeItemModels,
  getCodeItemConditions,
  getCodeFirearmCaliber,
  getCodeFirearmCategory,
})(RecordAdvanceSearch);
