/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { connect, useSelector } from 'react-redux';

import moment from 'moment-timezone';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { TextField } from '@material-ui/core';

export const RMSPersonSearch = (props) => {
  const { placeholder, setName } = props;
  const [value, setValue] = React.useState(null);
  const [options, setOptions] = React.useState([]);
  const [inputValue, setInputValue] = React.useState('');
  const wsClient = useSelector((state) => state.websocket);

  React.useEffect(() => {
    let active = true;

    if (!wsClient || !wsClient.websocket) {
      return;
    }

    if (inputValue === '') return;
    searchForPerson(active);

    return () => {
      active = false;
    };
  }, [wsClient, inputValue]);

  const searchForPerson = async (active) => {
    if (inputValue.length < 3) return;
    const service = wsClient.websocket.service('persons');
    service.timeout = 20000;

    const response = await service.find({
      query: {
        IsDeleted: false,
        FullName: {
          $like: `%${inputValue}%`,
          $ne: null,
        },
        $select: [
          'FirstName',
          'LastName',
          'MiddleName',
          'FullName',
          'Suffix',
          'Race',
          'Sex',
          'Ethnicity',
          'Age',
          'Born',
          'AgeRangeIncrement',
          'EyeColor',
          'HairColor',
          'HairStyle',
          'HairLength',
          'BloodType',
          'Handed',
          'Height',
          'Weight',
          'ptsPersonID',
        ],
      },
    });

    const filteredData = response.data.filter(
      (x) =>
        x.PersonCredential.some((y) => y.Number !== 0 && y.Number !== '0' && y.Number !== 'null') ||
        x.Born !== null
    );

    if (filteredData.length > 0) {
      if (active) {
        let newOptions = [...filteredData];

        setOptions(newOptions);
      }
    }
  };

  const handlePeopleSelect = (event, newValue) => {
    if (newValue === null) {
      setValue(null);
    } else {
      setName(newValue.ptsPersonID, newValue.FullName);
      setValue(newValue);
    }
  };

  return (
    <Autocomplete
      size="small"
      autoComplete
      autoHighlight
      value={value}
      id="combo-search-person"
      style={{ width: '100%' }}
      getOptionLabel={(option) => {
        const fullName = option.FullName;
        let ssn = '';
        let oln = '';
        if (Array.isArray(option.PersonCredential)) {
          option.PersonCredential.forEach((e) => {
            if (e.Type === 'SSN') ssn = e.Number;
            if (e.Type === 'OLN') oln = e.Number;
          });
        }
        const race = option.Race;
        const sex = option.Sex;
        const born = option.Born ? moment(option.Born).format('MM/DD/YYYY') : null;
        return `${fullName}  ${ssn ? 'SSN:' + ssn : ''}  ${oln ? 'OLN: ' + oln : ''}  ${
          race ? 'Race:' + race : ''
        } ${sex ? 'Sex: ' + sex : ''}  ${born ? 'DOB:' + born : ''}`;
      }}
      renderOption={(option) => {
        const fullName = option.FullName;
        let ssn = '';
        let oln = '';
        if (Array.isArray(option.PersonCredential)) {
          option.PersonCredential.forEach((e) => {
            if (e.Type === 'SSN') ssn = e.Number;
            if (e.Type === 'OLN') oln = e.Number;
          });
        }
        const race = option.Race;
        const sex = option.Sex;
        const born = option.Born ? moment(option.Born).format('MM/DD/YYYY') : null;
        return `${fullName}  ${ssn ? 'SSN:' + ssn : ''}  ${oln ? 'OLN: ' + oln : ''}  ${
          race ? 'Race:' + race : ''
        } ${sex ? 'Sex: ' + sex : ''}  ${born ? 'DOB:' + born : ''}`;
      }}
      options={options}
      onChange={handlePeopleSelect}
      onInputChange={(event, newInputValue) => {
        setInputValue(newInputValue);
      }}
      renderInput={(params) => (
        <TextField
          autoComplete="hidden"
          size="small"
          {...params}
          inputProps={{
            ...params.inputProps,
          }}
          label={placeholder}
          variant="outlined"
          fullWidth
        />
      )}
    />
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(RMSPersonSearch);
