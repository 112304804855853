import React, { useState } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation, useParams } from 'react-router';

import { Grid, makeStyles, TextField, Paper } from '@material-ui/core';
import DateFnsUtils from '@date-io/date-fns';
import SimpleSchema from 'simpl-schema';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';

import RMSAutoComplete from 'components/RMSAutoComplete/RMSAutoComplete';
import AddressSearch from 'components/RMSForms/RMSAddressForm/AddressSearch';
import RMSFabButtons from 'components/RMSForms/RMSIncidentForm/RMSFabButtons';
import { upsertRecord } from 'reducers/RecordsReducer';
import { getCodeCapabilityType } from 'reducers/dictionaries/CodeCapabilityTypeReducer';
import { handleFormChangeNew } from 'reducers/helpers/formHelpers';
import { setCircularLoading } from 'reducers/ui/UiMenuReducer';

/**validation schema */
const validationContext = new SimpleSchema({
  CapabilityTypeQuantity: SimpleSchema.Integer,
}).newContext();
/**end */

const useStyles = makeStyles((theme) => ({
  root: {
    height: window.innerHeight - 90 + 'px',
    borderRadius: '0.5rem',
    padding: '15px',
  },
  form: {
    width: '100%',
  },
  input: {
    width: '100%',
    padding: '10px',
  },
  btnStyle: {
    display: 'block',
    width: '150px',
    padding: '10px',
    marginTop: '15px',
    marginLeft: 'auto',
    position: 'relative',
    right: 10,
  },
}));

const codeResourceCategories = [{ Code: 'RSC1' }, { Code: 'RSC2' }];

const PersonResourceForm = (props) => {
  /** region props and variables */
  const classes = useStyles();
  const location = useLocation();
  const { persondId } = useParams();
  const [changeKey, setChangeKey] = React.useState(0);
  let data = useSelector((state) => state.records.formData);
  const actionType = location.pathname.split('/')[5];
  const [firstLoad, setFirstLoad] = useState(true);
  const [errors, setErrors] = useState({});
  const history = useHistory();
  const dispatch = useDispatch();
  const { getCodeCapabilityType, codeCapabilityType, wsClient, upsertRecord } = props;
  if (actionType === 'add') {
    data = {};
  }
  const [formData, setFormData] = useState({
    values: {
      ptsResourceID: data?.ptsResourceID || undefined,
      ptsPlaceID: data?.ptsPlaceID || undefined,
      ptsParentID: Number(persondId),
      CapabilityType: data?.CapabilityType || '',
      CapabilityTypeQuantity: data?.CapabilityTypeQuantity || null,
      CapabilityDetails: data?.CapabilityDetails || '',
      Category: data?.Category || '',
      ParentType: 'Person',
      Keyword: data?.Keyword || '',
      Kind: data?.Kind || '',
      URN: data?.URN || '',
      SpecialRequirements: data?.SpecialRequirements || '',
      Ending: data?.Ending || null,
      Beginning: data?.Beginning || null,
      ChildType: data?.ChildType || '',
    },
  });
  /** end region */

  /**region lifecycle hooks */
  React.useEffect(() => {
    if (!wsClient) {
      return;
    }
    getCodeCapabilityType();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [wsClient]);

  React.useEffect(() => {
    if (!firstLoad) {
      validationContext.validate({
        VehicleValue:
          formData.values?.VehicleValue?.length > 0 ? Number(formData.values?.VehicleValue) : 0,
        CapabilityTypeQuantity:
          formData.values?.CapabilityTypeQuantity?.length > 0
            ? Number(formData.values?.CapabilityTypeQuantity)
            : 0,
      });

      if (validationContext.isValid() === false) {
        const errs = validationContext.validationErrors();
        let e = {};
        errs.map((er) => {
          e[er.name] = `Value must be ${er.dataType}`;
          setErrors(e);
        });
      } else {
        setErrors({});
      }
    }
    setFirstLoad(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formData?.values?.CapabilityTypeQuantity]);
  /** end region */

  /** region helper functions*/
  const handleSave = () => {
    upsertRecord(
      'resource',
      {
        ...formData.values,
        CapabilityTypeQuantity: Number.isInteger(Number(formData.values.CapabilityTypeQuantity))
          ? Number(formData.values.CapabilityTypeQuantity)
          : 0,
      },
      'add'
    ).finally(() => handleFinally());
  };

  const handleFinally = () => {
    dispatch(setCircularLoading(false));
    history.goBack();
  };

  const onPlaceValueChange = (place, setFormState, setChangeKey, changeKey, contact = false) => {
    setFormData({
      ...formData,
      values: {
        ...formData.values,
        ptsPlaceID: place.ptsPlaceID,
      },
    });
  };

  const clearPlace = () => {
    setFormData({
      ...formData,
      values: {
        ...formData.values,
        ptsPlaceID: '',
      },
    });
  };
  /** end region */
  return (
    <Paper className={classes.root}>
      {actionType === 'add' && (
        <AddressSearch
          ptsPlaces={true}
          ptsAddresses={false}
          googleAddresses={false}
          onPlaceValueSet={onPlaceValueChange}
          onAddressValueSet={onPlaceValueChange}
          label={'Search Places'}
          setChangeKey={setChangeKey}
          changeKey={changeKey}
          defaultLocation={null}
          onReset={clearPlace}
        />
      )}
      <form autoComplete="off" className="mt-4">
        <Grid container spacing={2}>
          <Grid item xs={4}>
            <RMSAutoComplete
              size="small"
              autoComplete
              autoSelect
              autoHighlight
              id="combo-search-capability-type"
              options={codeCapabilityType}
              label="Capability Type"
              serviceName="codecapabilitytypes"
              variant="outlined"
              title={'Code'}
              stateHolder={formData}
              setStateHolder={setFormData}
              setField={handleFormChangeNew}
              stateHolderAttribute="CapabilityType"
            />
          </Grid>

          <Grid item xs={4}>
            <TextField
              id="standard-textarea-capabilityQuantity"
              autoComplete="hidden"
              size="small"
              error={errors['CapabilityTypeQuantity'] ? true : false}
              helperText={errors['CapabilityTypeQuantity']}
              placeholder="Capability Quantity"
              label="Capability Quantity"
              variant="outlined"
              name="CapabilityTypeQuantity"
              defaultValue={formData.values?.CapabilityTypeQuantity || ''}
              onChange={(event) =>
                handleFormChangeNew(
                  event,
                  event.target.value,
                  'none',
                  'CapabilityTypeQuantity',
                  setFormData
                )
              }
              fullWidth
              multiline
              InputLabelProps={{ shrink: true }}
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              id="standard-textarea-capability-details"
              autoComplete="hidden"
              size="small"
              placeholder="Capability Details"
              label="Capability Details"
              variant="outlined"
              name="CapabilityDetails"
              defaultValue={formData.values?.CapabilityDetails || ''}
              onBlur={(event) =>
                handleFormChangeNew(
                  event,
                  event.target.value,
                  'none',
                  'CapabilityDetails',
                  setFormData
                )
              }
              fullWidth
              multiline
              InputLabelProps={{ shrink: true }}
            />
          </Grid>
          <Grid item xs={4}>
            <RMSAutoComplete
              size="small"
              autoComplete
              autoSelect
              autoHighlight
              id="combo-search-caregories"
              options={codeResourceCategories}
              label="Category"
              serviceName="coderesourcecategories"
              variant="outlined"
              title={'Code'}
              stateHolder={formData}
              setStateHolder={setFormData}
              setField={handleFormChangeNew}
              stateHolderAttribute="Category"
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              id="standard-textarea-Keyword"
              autoComplete="hidden"
              size="small"
              placeholder="Keyword"
              label="Keyword"
              variant="outlined"
              name="Keyword"
              defaultValue={formData.values?.Keyword || ''}
              onBlur={(event) =>
                handleFormChangeNew(event, event.target.value, 'none', 'Keyword', setFormData)
              }
              fullWidth
              multiline
              InputLabelProps={{ shrink: true }}
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              id="standard-textarea-Kind"
              autoComplete="hidden"
              size="small"
              placeholder="Kind"
              label="Kind"
              variant="outlined"
              name="Kind"
              defaultValue={formData.values?.Kind || ''}
              onBlur={(event) =>
                handleFormChangeNew(event, event.target.value, 'none', 'Kind', setFormData)
              }
              fullWidth
              multiline
              InputLabelProps={{ shrink: true }}
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              id="standard-textarea-URN"
              autoComplete="hidden"
              size="small"
              placeholder="URN"
              label="URN"
              variant="outlined"
              name="URN"
              defaultValue={formData.values?.URN || ''}
              onBlur={(event) =>
                handleFormChangeNew(event, event.target.value, 'none', 'URN', setFormData)
              }
              fullWidth
              multiline
              InputLabelProps={{ shrink: true }}
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              id="standard-textarea-SpecialRequirements"
              autoComplete="hidden"
              size="small"
              placeholder="Special Requirements"
              label="Special Requirements"
              variant="outlined"
              name="SpecialRequirements"
              defaultValue={formData.values?.SpecialRequirements || ''}
              onBlur={(event) =>
                handleFormChangeNew(
                  event,
                  event.target.value,
                  'none',
                  'SpecialRequirements',
                  setFormData
                )
              }
              fullWidth
              multiline
              InputLabelProps={{ shrink: true }}
            />
          </Grid>
          <Grid item xs={4}>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <KeyboardDatePicker
                size="small"
                inputVariant="outlined"
                fullWidth
                showTodayButton={true}
                variant="dialog"
                format="MM/dd/yyyy"
                id="date-picker-inline-from"
                label="Ending"
                value={formData.values?.Ending || null}
                onChange={(date) => {
                  setFormData((state) => {
                    return {
                      ...state,
                      values: {
                        ...state.values,
                        Ending: date,
                      },
                    };
                  });
                }}
                KeyboardButtonProps={{
                  'aria-label': 'change date',
                }}
              />
            </MuiPickersUtilsProvider>
          </Grid>
          <Grid item xs={4}>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <KeyboardDatePicker
                size="small"
                inputVariant="outlined"
                fullWidth
                showTodayButton={true}
                variant="dialog"
                format="MM/dd/yyyy"
                id="date-picker-inline-from"
                label="Beginning"
                value={formData.values?.Beginning || null}
                onChange={(date) => {
                  setFormData((state) => {
                    return {
                      ...state,
                      values: {
                        ...state.values,
                        Beginning: date,
                      },
                    };
                  });
                }}
                KeyboardButtonProps={{
                  'aria-label': 'change date',
                }}
              />
            </MuiPickersUtilsProvider>
          </Grid>
          <Grid item xs={4}>
            <TextField
              id="standard-textarea-ChildType"
              autoComplete="hidden"
              size="small"
              placeholder="Child Type"
              label="Child Type"
              variant="outlined"
              name="ChildType"
              defaultValue={formData.values?.ChildType || ''}
              onBlur={(event) =>
                handleFormChangeNew(event, event.target.value, 'none', 'ChildType', setFormData)
              }
              fullWidth
              multiline
              InputLabelProps={{ shrink: true }}
            />
          </Grid>
        </Grid>
      </form>
      <RMSFabButtons recordForm={true} handleSaveRecord={handleSave} />
    </Paper>
  );
};

const mapStateToProps = (state) => ({
  wsClient: state.websocket,
  codeCapabilityType: state.codeCapabilityType,
});
export default connect(mapStateToProps, { getCodeCapabilityType, upsertRecord })(
  PersonResourceForm
);
