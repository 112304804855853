import store from '../../config/configureStore';

const GET_DICTIONARY = 'GET_DICTIONARY';
const GET_SETTINGS = 'CONFIG/GET_SETTINGS';

export const getDictionary = () => {
  const client = store.store.getState().websocket.websocket;
  return async (dispatch) => {
    try {
      const service = client.service('cad-dictionary');
      service.timeout = 200000;
      const data = await service.find({
        query: {
          options: 'cad',
        },
      });
      dispatch({ type: GET_DICTIONARY, payload: data });
    } catch (error) {
      if (error.code === 401) {
        console.log('Unauthenticated');
      } else {
        console.log(error);
      }
    }
  };
};

export const getSettings = () => {
  const client = store.store.getState().websocket.websocket;
  return async (dispatch) => {
    try {
      const service = client.service('cad');
      service.timeout = 200000;
      const data = await service.find({
        query: {
          type: 'settings',
        },
      });
      dispatch({ type: GET_SETTINGS, payload: data });
    } catch (error) {
      if (error.code === 401) {
        console.log('Unauthenticated');
      } else {
        console.log(error);
      }
    }
  };
};

export default function reducer(state = {}, action) {
  switch (action.type) {
    case GET_DICTIONARY:
      return { ...state, ...action.payload };
    case GET_SETTINGS:
      return { ...state, ...action.payload };
    default:
      return state;
  }
}
