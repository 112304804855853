/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import Grid from '@material-ui/core/Grid';
import RelationshipTreeView from '../../components/RMSTreeView/RelationshipTreeView';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Typography from '@material-ui/core/Typography';
import BlurOnIcon from '@material-ui/icons/BlurOn';
import { connect } from 'react-redux';
import { setIncRelationshipDetails } from '../../reducers/IncReducer';
import { getCodeVictimSubjectRelationship } from '../../reducers/dictionaries/CodeVictimSubjectRelationshipReducer';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3),
    marginTop: theme.spacing(3),
  },
  inner: {
    padding: theme.spacing(3),
  },
  header: {
    marginTop: theme.spacing(1),
    marginLeft: theme.spacing(1),
    marginBottom: theme.spacing(3),
  },
  title: {
    color: 'grey',
  },
  icon: {
    marginRight: theme.spacing(2),
  },
}));

const TabRelationships = (props) => {
  const {
    subjects,
    victims,
    parties,
    getCodeVictimSubjectRelationship,
    codeVictimSubjectRelationship,
    wsClient,
  } = props;

  const classes = useStyles();
  const [parentItems, setParentItems] = React.useState([]);
  const [childItems, setChildItems] = React.useState([]);

  React.useEffect(() => {
    if (!wsClient) return;

    const services = ['codeVictimSubjectRelationship'];
    let $select = ['Code', 'Description'];

    services.forEach(async (svc) => {
      if (props[svc].length < 1) {
        try {
          const result = await wsClient.service(svc.toLowerCase()).find({
            query: {
              $limit: 0,
              $select,
              IsDeleted: false,
            },
          });

          if (result.total > 0) {
            if (svc === 'codeVictimSubjectRelationship') {
              getCodeVictimSubjectRelationship(result.data);
            }
          }
        } catch (error) {
          console.log('Something went wrong. Message: ', error.message);
        }
      }
    });
  }, [wsClient]);

  React.useEffect(() => {
    let subjectList = [];
    let victimList = [];

    subjects.map((x) => {
      const party = parties?.find((p) =>
        p.ptsIncPersonId
          ? p.ptsIncPersonId === x.incSubjectDetails.ptsIncPersonId
          : p.tempPartyId === x.incSubjectDetails.tempPartyId
      );
      party &&
        subjectList.push({
          id: x.incSubjectDetails.ptsIncPersonId || x.incSubjectDetails.tempIncSubjectId,
          label: `${party.personDetail.values.firstName}${
            party.personDetail.values.middleName ? ` ${party.personDetail.values.middleName} ` : ' '
          }${party.personDetail.values.lastName}`,
          icon: 'OffenderAvatar',
          avatarLabel: 'O',
          subHeading1: `Age: ${party.personDetail.values.age}`,
          subHeading2: `Ethnicity: ${party.personDetail.values.ethnicity}`,
          age: party?.personDetail.values.age,
        });
    });
    setParentItems(subjectList);

    victims.map((x) => {
      const party = parties?.find((p) =>
        p.ptsIncPersonId
          ? p.ptsIncPersonId === x.incVictimDetails.ptsIncPersonId
          : p.tempPartyId === x.incVictimDetails.tempPartyId
      );

      party &&
        victimList.push({
          id: x.incVictimDetails.ptsIncPersonId || x.incVictimDetails.tempVictimId,
          label: `${party?.personDetail.values.firstName}${
            party?.personDetail.values.middleName
              ? ` ${party?.personDetail.values.middleName} `
              : ' '
          }${party?.personDetail.values.lastName}`,
          icon: 'VictimAvatar',
          avatarLabel: 'V',
          subHeading1: `Age: ${party?.personDetail.values.age}`,
          subHeading2: `Ethnicity: ${party?.personDetail.values.ethnicity}`,
          age: party?.personDetail.values.age,
        });
    });
    setChildItems(victimList);
  }, [subjects, victims]);

  return (
    <Grid container justify="center" className="mt-2 ml-2" style={{ width: '96%' }}>
      <Grid item xs={12} lg={12} className={classes.header}>
        <Typography variant="h5" className={classes.title}>
          <span className={classes.icon}>
            <BlurOnIcon />
          </span>
          Offender / Victim Relationship Details
        </Typography>
      </Grid>
      <Grid item xs={12} lg={12}>
        <RelationshipTreeView
          parentItems={parentItems}
          childItems={childItems}
          options={codeVictimSubjectRelationship}
        />
      </Grid>
    </Grid>
  );
};

const mapStateToProps = (state) => ({
  subjects: state.incident.incSubjects,
  victims: state.incident.incVictims,
  parties: state.incident.parties,
  wsClient: state.websocket.websocket,
  selectedEntity: state.incident.selectedEntity,
  selectedIncident: state.incident.ptsIncidentId,
  codeVictimSubjectRelationship: state.codeVictimSubjectRelationship,
});

export default connect(mapStateToProps, {
  getCodeVictimSubjectRelationship,
  setIncRelationshipDetails,
})(TabRelationships);
