export const codeForceCategory = [
  {
    code: '11',
    description: 'Firearm (type not stated)',
  },
  {
    code: '11A',
    description: 'Automatic Firearm (type not stated)',
  },
  {
    code: '12',
    description: 'Handgun',
  },
  {
    code: '12A',
    description: 'Automatic Handgun',
  },
  {
    code: '13',
    description: 'Rifle',
  },
  {
    code: '13A',
    description: 'Automatic Rifle',
  },
  {
    code: '14',
    description: 'Shotgun',
  },
  {
    code: '14A',
    description: 'Automatic Shotgun',
  },
  {
    code: '15',
    description: 'Other Firearm',
  },
  {
    code: '15A',
    description: 'Other Automatic Firearm',
  },
  {
    code: '20',
    description: 'Lethal Cutting Instument',
  },
  {
    code: '30',
    description: 'Club/ Blackjack/ Brass Knuckles',
  },
  {
    code: '35',
    description: 'Motor Vehicle/Vessel',
  },
  {
    code: '40',
    description: 'Personal Weapons',
  },
  {
    code: '50',
    description: 'Poison',
  },
  {
    code: '60',
    description: 'Explosives',
  },
  {
    code: '65',
    description: 'Fire/ Incendiary Device',
  },
  {
    code: '70',
    description: 'Drugs/ Narcotics/ Sleeping Pills',
  },
  {
    code: '85',
    description: 'Asphyxiation',
  },
  {
    code: '90',
    description: 'Other',
  },
  {
    code: '95',
    description: 'Unknown',
  },
  {
    code: '99',
    description: 'None',
  },
];
