/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, Fragment } from 'react';
import { connect } from 'react-redux';
// import { useHistory } from 'react-router';
import { NavLink } from 'react-router-dom';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  AppBar,
  Toolbar,
  IconButton,
  Tooltip,
  Button,
  Hidden,
  Avatar,
  Box,
  Badge,
  Menu,
  List,
  ListItem,
  Divider,
  Card,
} from '@material-ui/core';
import MenuBulletIcon from '@material-ui/icons/FiberManualRecord';
import grey from '@material-ui/core/colors/grey';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import MenuIcon from '@material-ui/icons/Menu';

import menuItems from '../config/configureMenu';
import AboutDialog from './AboutDialog';
import { resetRecordAdvanceSearchKeyword, setRecordActive } from 'reducers/RecordsReducer';
import {
  setNavigationDrawerMobileState,
  setEventsDrawerMobileState,
  setUnitsDrawerMobileState,
} from '../reducers/ui/UiDrawerReducer';
import { setMode } from '../reducers/ThemeReducer';
import { setUserAuthenticated } from '../reducers/UserReducer';

const drawerHeight = 50;

const useStyles = makeStyles((theme) => ({
  wrapper: {
    display: 'flex',
    flexDirection: 'row',
    alignSelf: 'center',
    justifyContent: 'space-between',
    width: '100%',
  },
  leftMenu: {
    display: 'flex',
    flex: 1,
    color: 'white',
  },
  items: {
    color: 'white',
    padding: '0px',
    fontSize: '17px',
  },
  itemsBtn: {
    color: 'white',
    fontSize: '17px',
    padding: '10px 40px',
  },
  rightMenu: {
    alignSelf: 'center',
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    backgroundColor: theme.palette.bgElements,
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },
  toolbar: {
    height: drawerHeight,
  },
  btn: {
    width: '85px',
    height: '60px',
    border: '3px solid rgba(1, 83, 163, 0.5) !important',
    color: 'white',
  },

  btnLabel: {},
  avatar: {
    position: 'relative',

    '& i': {
      position: 'absolute',
      bottom: -18,
      left: -30,
      right: -30,
      fontSize: 11,
      fontWeight: 600,
      color: theme.palette.primary.main,
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      fontStyle: 'normal',
      textAlign: 'center',
    },
  },
  activeNav: {
    color: 'red',
    '& > button': {
      fontWeight: 600,
      color: grey[200],
    },
  },
  searchMenu: {
    display: 'flex',
    '& h5': {
      paddingLeft: '30px',
    },
    '& svg': {
      marginRight: theme.spacing(1),
      fontSize: 16,
      opacity: 0.25,
    },
    '& a': {
      color: theme.palette.colorFont,
    },
  },
  fullWidthMenu: {
    zIndex: 2,
    position: 'absolute',
    top: 70,
    left: 0,
    right: 0,
    width: '100%',
    padding: `${theme.spacing(3)}px ${theme.spacing(2)}px ${theme.spacing(2)}px`,
  },
  fwMenuWrap: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    width: '100%',
    height: '100%',
    zIndex: 2,
  },
}));

const UserBadge = withStyles({
  badge: {
    boxShadow: '0 0 0 2px #fff',
    '&::after': {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      borderRadius: '50%',
      animation: '$ripple 1.2s infinite ease-in-out',
      border: '1px solid currentColor',
      content: '""',
    },
  },
  '@keyframes ripple': {
    '0%': {
      transform: 'scale(.8)',
      opacity: 1,
    },
    '100%': {
      transform: 'scale(2.4)',
      opacity: 0,
    },
  },
})(Badge);

const TopBar = (props) => {
  const classes = useStyles();
  const {
    setMode,
    network,
    user,
    themeMode,
    setUserAuthenticated,
    setNavigationDrawerMobileState,
    resetRecordAdvanceSearchKeyword,
  } = props;

  const [anchorEl, setAnchorEl] = useState(null);
  const [displayAbout, setDisplayAbout] = useState(false);
  const [userName, setUserName] = useState('');
  const [searchMenuVisible, setSearchMenuVisible] = useState(false);
  const [anchorEl1, setAnchorEl1] = React.useState(null);

  React.useEffect(() => {
    if (user.userData && user.userData.user) {
      let name = user.userData.user.Username;
      if (name !== userName) setUserName(name);
    }
  }, [user]);

  const StyledBadge = withStyles({
    badge: {
      backgroundColor: network.online === false ? 'var(--danger)' : 'var(--success)',
      color: network.online === false ? 'var(--danger)' : 'var(--success)',
    },
  })(UserBadge);

  const handleUserMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const closeAbout = () => {
    setDisplayAbout(false);
  };

  const showAbout = () => {
    setDisplayAbout(true);
    handleClose();
  };

  const toggleMode = () => {
    setMode();
    handleClose();
  };

  const open1 = Boolean(anchorEl1);

  const renderUserMenu = () => {
    return (
      <Menu
        anchorEl={anchorEl}
        keepMounted
        getContentAnchorEl={null}
        open={Boolean(anchorEl)}
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'center',
          horizontal: 'center',
        }}
        onClose={handleClose}
        className="ml-2">
        <div className="dropdown-menu-right dropdown-menu-lg overflow-hidden p-0">
          <List className="text-left bg-transparent d-flex align-items-center flex-column pt-0">
            <Box>
              <StyledBadge
                overlap="circle"
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'right',
                }}
                variant="dot">
                <Avatar sizes="44" alt={userName} />
              </StyledBadge>
            </Box>
            <div>
              <div className="font-weight-bold text-center pt-2 line-height-1">{userName}</div>
            </div>
            <Divider className="w-100 mt-2" />
            <ListItem button onClick={showAbout}>
              About
            </ListItem>
            <ListItem onClick={toggleMode} button>
              {themeMode === 'day' ? 'Night mode' : 'Day mode'}
            </ListItem>
            <Divider className="w-100" />
            <ListItem className="d-block rounded-bottom px-3 pt-3 pb-0 text-center">
              <Button
                onClick={() => setUserAuthenticated(false)}
                variant="outlined"
                size="medium"
                color="default">
                <span className="btn-wrapper--icon">
                  <FontAwesomeIcon icon={['fas', 'sign-out-alt']} />
                </span>
                <span className="btn-wrapper--label">Logout</span>
              </Button>
            </ListItem>
          </List>
        </div>
      </Menu>
    );
  };

  const renderMobileMenu = () => {
    return (
      <div
        style={{
          display: 'flex',
          alignSelf: 'center',
          justifyContent: 'space-between',
          width: '100%',
        }}>
        <div style={{ alignSelf: 'center' }}>
          <IconButton
            color="inherit"
            aria-label="Open drawer"
            edge="start"
            onClick={() => setNavigationDrawerMobileState()}>
            <MenuIcon color="primary" />
          </IconButton>
        </div>
        <div style={{ display: 'flex' }}>
          <div style={{ alignSelf: 'center' }}>
            <Button
              color="inherit"
              onClick={handleUserMenuClick}
              className="text-capitalize px-3 text-left btn-inverse d-flex align-items-center">
              <Box>
                <StyledBadge
                  overlap="circle"
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                  }}
                  variant="dot">
                  <Avatar sizes="44" alt={userName} />
                </StyledBadge>
              </Box>
              <div className="d-none d-xl-block">
                <div className="font-weight-bold pt-2 line-height-1">{userName}</div>
              </div>
              <span className="pl-1 pl-xl-3">
                <FontAwesomeIcon icon={['fas', 'angle-down']} className="opacity-5" />
              </span>
            </Button>
          </div>
        </div>
      </div>
    );
  };

  const handleMenuClose = () => {
    setSearchMenuVisible(false);
  };

  const handleSearchMenuVisibility = () => {
    searchMenuVisible ? setSearchMenuVisible(false) : setSearchMenuVisible(true);
  };

  const renderSearchMenu = () => {
    return (
      <div className={classes.searchMenu}>
        <div style={{ width: '15%' }}>
          <MenuList>
            <NavLink to="/records/item" activeClassName={classes.activeNav}>
              <MenuItem onClick={handleMenuClose} button>
                <MenuBulletIcon /> Item
              </MenuItem>
            </NavLink>
            <NavLink to="/records/jewelry" activeClassName={classes.activeNav}>
              <MenuItem onClick={handleMenuClose} button>
                <MenuBulletIcon /> Jewelry
              </MenuItem>
            </NavLink>
            <NavLink to="/records/firearm" activeClassName={classes.activeNav}>
              <MenuItem onClick={handleMenuClose} button>
                <MenuBulletIcon /> Firearm
              </MenuItem>
            </NavLink>
          </MenuList>
        </div>
        <div style={{ width: '15%' }}>
          <MenuList>
            <NavLink to="/records/structure" activeClassName={classes.activeNav}>
              <MenuItem onClick={handleMenuClose} button>
                <MenuBulletIcon /> Structure
              </MenuItem>
            </NavLink>
            <NavLink to="/records/substance" activeClassName={classes.activeNav}>
              <MenuItem onClick={handleMenuClose} button>
                <MenuBulletIcon /> Substance
              </MenuItem>
            </NavLink>
            <NavLink to="/records/vehicle" activeClassName={classes.activeNav}>
              <MenuItem onClick={handleMenuClose} button>
                <MenuBulletIcon /> Vehicle
              </MenuItem>
            </NavLink>
          </MenuList>
        </div>
        <div style={{ width: '15%' }}>
          <MenuList>
            <NavLink to="/records/place" activeClassName={classes.activeNav}>
              <MenuItem onClick={handleMenuClose} button>
                <MenuBulletIcon /> Place
              </MenuItem>
            </NavLink>
          </MenuList>
        </div>
      </div>
    );
  };

  const renderDesktopMenu = () => {
    return (
      <div className={classes.wrapper}>
        <div className={classes.leftMenu}>
          {menuItems.map((btn, idx) => {
            if (btn.label === 'Property') {
              return (
                <Tooltip
                  key={idx}
                  title={btn.tooltip}
                  // onMouseEnter={handleMouseEvent}
                  onClick={handleSearchMenuVisibility}
                  className={classes.items}
                  style={{ padding: '0px 0px' }}>
                  <NavLink to="#">
                    <Button className={classes.itemsBtn}>
                      <span style={{ paddingRight: '5px' }}>Property</span>
                      <FontAwesomeIcon icon={['fas', 'angle-down']} />
                    </Button>
                  </NavLink>
                </Tooltip>
              );
            } else {
              return (
                <Tooltip
                  title={btn.tooltip}
                  key={idx}
                  className={classes.items}
                  style={{ padding: '0px 0px' }}>
                  <NavLink to={btn.link}>
                    <Button onClick={resetRecordAdvanceSearchKeyword} className={classes.itemsBtn}>
                      {btn.label}
                    </Button>
                  </NavLink>
                </Tooltip>
              );
            }
          })}
        </div>

        <div className={classes.rightMenu}>
          <Button
            color="inherit"
            onClick={handleUserMenuClick}
            className="text-capitalize px-3 text-left btn-inverse d-flex align-items-center">
            <Box className={classes.avatar} style={{ display: 'flex' }}>
              <div>
                <StyledBadge
                  overlap="circle"
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                  }}
                  variant="dot">
                  <Avatar sizes="44" alt={userName} />
                </StyledBadge>
              </div>
            </Box>
            <span className="pl-2 pl-xl-3 d-flex">
              <span style={{ paddingRight: '8px', textDecoration: 'none' }}>{userName}</span>
              <FontAwesomeIcon icon={['fas', 'angle-down']} className="opacity-5" />
            </span>
          </Button>
        </div>
      </div>
    );
  };

  return (
    <Fragment>
      <AppBar color="primary" position="fixed" className={classes.appBar}>
        <Toolbar className={classes.toolbar}>
          <Hidden smDown>{renderDesktopMenu()}</Hidden>
          <Hidden mdUp>{renderMobileMenu()}</Hidden>
          {renderUserMenu()}
        </Toolbar>
      </AppBar>
      {searchMenuVisible && (
        <div className={classes.fwMenuWrap} onClick={() => setSearchMenuVisible(false)}>
          <Card className={classes.fullWidthMenu}>{renderSearchMenu()}</Card>
        </div>
      )}
      {displayAbout && <AboutDialog close={closeAbout} />}
    </Fragment>
  );
};

const mapStateToProps = (state) => ({
  user: state.user,
  network: state.offline,
  themeMode: state.theme.mode,
});

export default connect(mapStateToProps, {
  setMode,
  setRecordActive,
  setUserAuthenticated,
  setEventsDrawerMobileState,
  setUnitsDrawerMobileState,
  setNavigationDrawerMobileState,
  resetRecordAdvanceSearchKeyword,
})(TopBar);
