import store from '../../config/configureStore';

import { propertiesSchema } from 'simpl-schema-validation/schema';
import { generateHandleEditIncident } from 'simpl-schema-validation/helpers/generateHandleEdit';

export const validateProperties = () => {
  const currentState = store.store.getState().incident;
  const { properties, incProperties, offenses } = currentState;

  let validationErrors = [];

  let objToValidate = { properties };
  let keys = ['propertySubstance'];

  let propertiesValidationContext = propertiesSchema.newContext();
  let result = propertiesValidationContext.validate(objToValidate, {
    keys,
  });

  if (!result) {
    propertiesValidationContext.validationErrors().forEach((error) => {
      validationErrors.push({
        id: '',
        idType: '',
        errType: error.type,
        field: error.name,
        name: '',
        message: propertiesValidationContext.keyErrorMessage(error.name),
        ptsIncidentId: currentState.ptsIncidentId,
        propertyType: '',
        handleEdit: generateHandleEditIncident(currentState.ptsIncidentId, 1),
      });
    });
  }

  for (let property of properties) {
    let incProperty = incProperties.find(
      (incP) => incP.incPropertyDetails.incIncidentPropertyId === property.incIncidentPropertyId
    );

    let incPropertyOffense = offenses.find(
      (offense) =>
        offense.offenseDetails.ptsOffenseId ===
        incProperty?.incPropertyDetails.incIncidentPropertyId
    );

    let objToValidate = {
      incPropertyOffense: incPropertyOffense ? incPropertyOffense.offenseDetails : null,
      incProperty: incProperty ? incProperty.incPropertyDetails : null,
      property: property.propertyDetails,
      parentType: property.propertyDetails.values.parentType,
      substanceQuantity: property.propertyDetails.values.quantity,
      quantityMeasure: property.propertyDetails.values.quantityMeasure,
      quantity: property.propertyDetails.values.quantity,
      substanceType: property.propertyDetails.values.substanceType,
    };

    let keys = ['substanceQuantity', 'quantityMeasure', 'substanceType', 'quantity'];

    let propertiesValidationContext = propertiesSchema.newContext();
    let result = propertiesValidationContext.validate(objToValidate, {
      keys,
    });

    if (!result) {
      propertiesValidationContext.validationErrors().forEach((error) => {
        validationErrors.push({
          id: property.propertyDetails.incIncidentPropertyId,
          idType: 'incIncidentPropertyId',
          errType: error.type,
          field: error.name,
          name: property.propertyDetails.values.parentType.toUpperCase(),
          message: propertiesValidationContext.keyErrorMessage(error.name),
          ptsIncidentId: currentState.ptsIncidentId,
          propertyType: property.propertyType,
          parentType: property.propertyDetails.values.parentType,
        });
      });
    }
  }

  return validationErrors;
};
