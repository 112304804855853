import React from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router';
import { useLocation } from 'react-router';

import 'react-tiny-fab/dist/styles.css';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import Grid from '@material-ui/core/Grid';
import Fade from '@material-ui/core/Fade';

import {
  setHandleIncFormSave,
  setHandleIncCadFormSave,
  setHandleAddIncSubject,
  setHandleAddIncVictim,
  setHandleAddIncProperty,
  setHandleAddIncArrestee,
  setHandleAddIncDVVictim,
  setHandleAddIncDVSuspect,
  setCircularLoading,
} from '../../../reducers/ui/UiMenuReducer';
import { assignTempIncidentId } from '../../../reducers/helpers/incHelpers';
import {
  getEntitiesByIncidentId,
  initFormModel,
  updateCurrentIncident,
  updateIncAddressForm,
  updateIncPartiesContactForm,
  updateIncPartiesEmploymentForm,
  updateIncPartiesPersonForm,
  updateIncPropertyCredentialsAnalysisForm,
  updateIncPropertyVehicleDetailsForm,
} from '../../../reducers/IncReducer';
import { getCodeWeather } from '../../../reducers/dictionaries/CodeWeatherReducer';
import { getCodeLightning } from '../../../reducers/dictionaries/CodeLightingReducer';
import { getCodeCADStatuses } from '../../../reducers/dictionaries/CodeOthersReducer';
import {
  resetDuplicateList,
  setCadEventTimeModalState,
  setDuplicateModal,
  setDuplicateFoundBtn,
} from '../../../reducers/ui/UiModalReducer';
import {
  getCodeIncidentCategories,
  getCodeIncidentTypes,
} from '../../../reducers/dictionaries/CodeIncidentReducer';
import RMSMissingPersonButton from 'components/RMSButtons/RMSMissingPersonButton';

const options = ['Save and Close', 'Save', 'Close'];

const useStyles = makeStyles((theme) => ({
  summaryDetails: {
    position: 'absolute',
    bottom: '64px',
    right: '32px',
    zIndex: 999,
    maxWidth: '900px',
    textAlign: 'right',
  },
  duplicateButton: {
    backgroundColor: theme.palette.warning.main,
    color: theme.palette.common.white,
    '&:hover': {
      backgroundColor: theme.palette.warning.main,
      boxShadow: 'none',
    },
    '&:active': {
      boxShadow: 'none',
      backgroundColor: theme.palette.warning.main,
    },
    marginRight: '20px',
  },
  button: {
    backgroundColor: theme.palette.info.dark,
    color: theme.palette.common.white,
    '&:hover': {
      backgroundColor: theme.palette.info.dark,
      boxShadow: 'none',
    },
    '&:active': {
      boxShadow: 'none',
      backgroundColor: theme.palette.info.dark,
    },
  },
}));

const RMSFabButtons = (props) => {
  /** region Component Variables and Props */
  const classes = useStyles();
  const {
    isCadForm,
    incident,
    recordForm,
    setDuplicateModal,
    handleSaveRecord,
    showDuplicateModal,
    setCircularLoading,
    setHandleIncFormSave,
    setHandleIncCadFormSave,
    updateIncAddressForm,
    updateCurrentIncident,
    setDuplicateFoundBtn,
    setHandleAddIncSubject,
    setHandleAddIncVictim,
    setHandleAddIncProperty,
    setHandleAddIncArrestee,
    setHandleAddIncDVSuspect,
    setCadEventTimeModalState,
    updateIncPartiesPersonForm,
    updateIncPartiesContactForm,
    updateIncPartiesEmploymentForm,
    updateIncPropertyVehicleDetailsForm,
    updateIncPropertyCredentialsAnalysisForm,
  } = props;
  const history = useHistory();
  const [open, setOpen] = React.useState(false);
  const [isGoBack, setIsGoBack] = React.useState(false);
  const anchorRef = React.useRef(null);
  const [selectedIndex, setSelectedIndex] = React.useState(0);

  const location = useLocation();
  const urlParams = location.pathname.split('/');
  const pageType = urlParams[2];
  let partyType = '';

  if (pageType === 'add') {
    partyType = urlParams[3];
  } else if (pageType === 'edit') {
    partyType = urlParams[4];
  }
  /** endregion */
  /** region Event Handlers */
  const closeDialogBox = () => {
    setCadEventTimeModalState(false);
  };

  const handleSave = async (behaviour) => {
    setCircularLoading(true);
    closeDialogBox();

    const parts = history.location.pathname.split('/');
    let place = '';
    if (parts.length === 3) place = parts[parts.length - 1];
    else if (parts.length === 4) place = parts[parts.length - 2];
    else if (parts.length === 5) place = parts[parts.length - 3];

    if (place === 'add' && !incident.ptsIncidentId) {
      incident.tempIncidentId = assignTempIncidentId();
      updateCurrentIncident(incident);
    }

    if (!isCadForm) {
      setHandleIncFormSave(place).then(() => {
        if (behaviour === 0) {
          setIsGoBack(true);
        }
      });
    } else {
      setHandleIncCadFormSave(place).then(() => {
        if (behaviour === 0) {
          setIsGoBack(true);
        }
      });
    }
  };

  const goBack = () => {
    resetDuplicateList();
    setHandleAddIncProperty(false);
    setHandleAddIncSubject(false);
    setHandleAddIncVictim(false);
    setHandleAddIncArrestee(false);
    setHandleAddIncDVVictim(false);
    setHandleAddIncDVSuspect(false);
    setDuplicateModal(false);
    resetDuplicateList();
    updateIncPartiesPersonForm(initFormModel);
    updateIncPartiesContactForm(initFormModel);
    updateIncPartiesEmploymentForm(initFormModel);
    updateIncAddressForm(initFormModel);
    updateIncPropertyVehicleDetailsForm(initFormModel);
    updateIncPropertyCredentialsAnalysisForm(initFormModel);
    history.goBack();
  };

  const handleClick = () => {
    if (selectedIndex === 0 || selectedIndex === 1) {
      if (!recordForm) handleSave(selectedIndex);
      else {
        setCircularLoading(true);
        handleSaveRecord();
      }
    } else {
      goBack();
    }
  };

  const handleMenuItemClick = (event, index) => {
    setSelectedIndex(index);
    setOpen(false);
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };

  const onClickDuplicateFoundBtn = () => {
    setDuplicateFoundBtn(true);
  };
  /** endregion */
  /** region React Hooks */
  React.useEffect(() => {
    if (showDuplicateModal) {
      setIsGoBack(false);
      return;
    }
    if (isGoBack) {
      goBack();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showDuplicateModal, isGoBack]);
  /** endregion */
  return (
    <Grid className={classes.summaryDetails} container>
      <Grid item xs={12}>
        <Fade in={showDuplicateModal === true}>
          <Button
            className={classes.duplicateButton}
            size="medium"
            onClick={onClickDuplicateFoundBtn}>
            Duplicate Found! Show Duplicate List
          </Button>
        </Fade>
        {partyType === 'victims' && <RMSMissingPersonButton />}
        <ButtonGroup
          variant="contained"
          ref={anchorRef}
          aria-label="split button"
          className={classes.button}>
          <Button className={classes.button} onClick={handleClick}>
            {options[selectedIndex]}
          </Button>
          <Button
            className={classes.button}
            size="small"
            aria-controls={open ? 'split-button-menu' : undefined}
            aria-expanded={open ? 'true' : undefined}
            aria-label="select merge strategy"
            aria-haspopup="menu"
            onClick={handleToggle}>
            <ArrowDropUpIcon />
          </Button>
        </ButtonGroup>
        <Popper open={open} anchorEl={anchorRef.current} role={undefined} transition disablePortal>
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{
                transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
              }}>
              <Paper>
                <ClickAwayListener onClickAway={handleClose}>
                  <MenuList id="split-button-menu">
                    {options.map((option, index) => (
                      <MenuItem
                        key={option}
                        selected={index === selectedIndex}
                        onClick={(event) => handleMenuItemClick(event, index)}>
                        {option}
                      </MenuItem>
                    ))}
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </Grid>
    </Grid>
  );
};

const mapStateToProps = (store) => ({
  wsClient: store.websocket,
  codeWeather: store.codeWeather,
  codeLighting: store.codeLighting,
  incident: store.incident.incident,
  addEntityForm: store.addEntityForm,
  state: store.uiList.incListHeaderState,
  incidentId: store.incident.ptsIncidentId,
  handleClose: store.uiMenu.handleCloseState,
  selectedTab: store.uiForm.incGeneralTabState,
  codeCADStatuses: store.codesOthers.codeCADStatuses,
  codeIncidentTypes: store.codesIncident.codeIncidentTypes,
  showCadEventTimeModal: store.uiModal.showCadEventTimeModal,
  showDuplicateModal: store.uiModal.showDuplicateModal,
  duplicateFoundBtnClicked: store.uiModal.duplicateFoundBtnClicked,
  codeIncidentCategories: store.codesIncident.codeIncidentCategories,
});

export default connect(mapStateToProps, {
  setHandleIncFormSave,
  setHandleIncCadFormSave,
  updateCurrentIncident,
  setHandleAddIncSubject,
  setHandleAddIncVictim,
  setHandleAddIncProperty,
  setHandleAddIncArrestee,
  setHandleAddIncDVSuspect,
  getCodeWeather,
  setDuplicateModal,
  getCodeCADStatuses,
  getCodeIncidentCategories,
  getCodeIncidentTypes,
  getCodeLightning,
  setCircularLoading,
  setDuplicateFoundBtn,
  updateIncAddressForm,
  getEntitiesByIncidentId,
  setCadEventTimeModalState,
  updateIncPartiesPersonForm,
  updateIncPartiesContactForm,
  updateIncPartiesEmploymentForm,
  updateIncPropertyVehicleDetailsForm,
  updateIncPropertyCredentialsAnalysisForm,
})(RMSFabButtons);
