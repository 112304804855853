import React, { useState } from 'react';

import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import Alert from '@material-ui/lab/Alert';
import { connect } from 'react-redux';
import { Grid } from '@material-ui/core';
import DialogTitle from '@material-ui/core/DialogTitle';

import { updateFormOpen, incrementCount } from 'reducers/AddEntityFormReducer';
import {
  getCodeHairColor,
  getCodeHairLength,
  getCodeHairStyle,
} from '../../reducers/dictionaries/CodeHairReducer';
import { addCodeWeather, getCodeWeather } from '../../reducers/dictionaries/CodeWeatherReducer';
import {
  addCodeLightning,
  getCodeLightning,
} from '../../reducers/dictionaries/CodeLightingReducer';
import { addCodeNameSuffixes } from '../../reducers/dictionaries/CodeNameSuffixesReducer';
import {
  addCodeCADStatuses,
  getCodeCADStatuses,
  addCodeHanded,
  addCodeEyeColor,
  addCodeBloodType,
  addCodeEthnicity,
  getCodeEthnicity,
  addCodeCities,
  getCodeEyeColor,
  getCodeBloodType,
  getCodeHanded,
  getCodeCities,
  addCodeJewelryCateogory,
  getCodeJewelryCateogory,
  addCodeDrugMeasurements,
  addCodeDEADrugClasses,
  getCodeDrugMeasurements,
  getCodeDEADrugClasses,
  addCodeDNACollection,
  getCodeDNACollection,
  addCodeJuvenileDisposition,
  getCodeJuvenileDisposition,
  addCodeCoordinateDatumTypes,
  getCodeCoordinateDatumTypes,
} from '../../reducers/dictionaries/CodeOthersReducer';
import {
  addCodeHairColor,
  addCodeHairLength,
  addCodeHairStyle,
} from '../../reducers/dictionaries/CodeHairReducer';
import { addCodeStates, getCodeStates } from '../../reducers/dictionaries/CodeStatesReducer';
import {
  addCodeStreetType,
  getCodeStreetType,
} from '../../reducers/dictionaries/CodeStreetTypesReducer';
import {
  addCodeFirearmCaliber,
  addCodeFirearmCategory,
  getCodeFirearmCategory,
  getCodeFirearmCaliber,
} from '../../reducers/dictionaries/CodeFirearmReducer';
import { addCodeMeasures, getCodeMeasures } from '../../reducers/dictionaries/CodeMeasuresReducer';
import { form } from './formDeatils';

import {
  addCodeOffenseFactor,
  getCodeOffenseFactor,
} from '../../reducers/dictionaries/CodeOffenseFactorReducer';
import { addCodeMaterial, getCodeMaterial } from '../../reducers/dictionaries/CodeMaterialReducer';
import {
  addCodeGarageTypes,
  getCodeGarageTypes,
  addCodeGarageFacilities,
  getCodeGarageFacilities,
} from '../../reducers/dictionaries/CodeGarageReducer';
import {
  addCodeStructureColor,
  getCodeStructureColor,
} from '../../reducers/dictionaries/CodeStructureColorReducer';
import {
  addCodeVehicleStyle,
  getCodeVehicleStyle,
} from '../../reducers/dictionaries/CodeVehicleStylesReducer';

import {
  getCodeVehicleColor,
  addCodeVehicleColor,
} from '../../reducers/dictionaries/CodeVehicleColorsReducer';

import {
  getCodeIncidentCategories,
  addCodeIncidentCategories,
  getCodeIncidentTypes,
  addCodeIncidentTypes,
} from '../../reducers/dictionaries/CodeIncidentReducer';
import {
  getCodeVehicleClassifications,
  addCodeVehicleClassifications,
} from '../../reducers/dictionaries/CodeVehicleClassificationsReducer';
import {
  addCodeVehicleBrands,
  getCodeVehicleBrands,
} from '../../reducers/dictionaries/CodeVehicleBrandsReducer';
import {
  addCodeVehicleBranders,
  getCodeVehicleBranders,
} from '../../reducers/dictionaries/CodeVehicleBrandersReducer';

import {
  addCodeVehicleProperty,
  getCodeVehicleProperty,
} from '../../reducers/dictionaries/CodeVehiclePropertyReducer';
import {
  addCodeVehicleCommercial,
  getCodeVehicleCommercial,
} from '../../reducers/dictionaries/CodeVehicleCommercialReducer';
import {
  addCodeTransmission,
  getCodeTransmission,
} from '../../reducers/dictionaries/CodeTransmissionReducer';
import {
  addCodeSubjectInvolvement,
  getCodeSubjectInvolvement,
} from '../../reducers/dictionaries/CodeSubjectInvolvementReducer';
import {
  addCodeSubjectMO,
  getCodeSubjectMO,
} from '../../reducers/dictionaries/CodeSubjectMOReducer';
import {
  addCodeSubjectStatus,
  getCodeSubjectStatus,
} from '../../reducers/dictionaries/CodeSubjectStatusReducer';
import {
  addCodeSubjectWeapon,
  getCodeSubjectWeapon,
} from '../../reducers/dictionaries/CodeSubjectWeaponReducer';
import {
  addCodeItemConditions,
  getCodeItemConditions,
  addCodeItemMakes,
  getCodeItemMakes,
  addCodeItemModels,
  getCodeItemModels,
} from '../../reducers/dictionaries/CodeItemReducer';
import {
  addCodeVehicleMakes,
  getCodeVehicleMakes,
} from '../../reducers/dictionaries/CodeVehicleMakesReducer';
import {
  addCodeVehicleMakeModels,
  getCodeVehicleMakeModels,
} from '../../reducers/dictionaries/CodeVehicleMakesModelsReducer';
import {
  addCodeVictimCategory,
  getCodeVictimCategory,
} from '../../reducers/dictionaries/CodeVictimCategoryReducer';
import {
  getCodeVictimDisposition,
  addCodeVictimDisposition,
} from '../../reducers/dictionaries/CodeVictimDispositionReducer';
import {
  addCodeVictimTreatment,
  getCodeVictimTreatment,
} from '../../reducers/dictionaries/CodeVictimTreatmentReducer';
import {
  addCodeResidence,
  getCodeResidence,
} from '../../reducers/dictionaries/CodeResidentReducer';
import {
  addCodeOfficerActivity,
  getCodeOfficerActivity,
} from '../../reducers/dictionaries/CodeOfficerActivityReducer';
import {
  addCodeOfficerAssignment,
  getCodeOfficerAssignment,
} from '../../reducers/dictionaries/CodeOfficerAssignmentReducer';
import {
  addCodePropertyLossType,
  getCodePropertyLossType,
} from '../../reducers/dictionaries/CodePropertyLossTypeReducer';
import {
  addCodePropertyCategory,
  getCodePropertyCategory,
} from '../../reducers/dictionaries/CodePropertyCategoryReducer';
import {
  addCodeArrestCategories,
  getCodeArrestCategories,
  addCodeArresteeArmed,
  getCodeArresteeArmed,
} from '../../reducers/dictionaries/CodeArrestReducer';
import {
  addCodeEvidenceType,
  getCodeEvidenceType,
  addCodeEvidenceRequiredAction,
  getCodeEvidenceRequiredAction,
  addCodeEvidenceTemporaryLocation,
  getCodeEvidenceTemporaryLocation,
  addCodeEvidenceDisposalCode,
  getCodeEvidenceDisposalCode,
  addCodeEvidenceCustodyStatus,
  getCodeEvidenceCustodyStatus,
  addCodeEvidenceCustodyReason,
  getCodeEvidenceCustodyReason,
  addCodeEvidenceStorageLocation,
  getCodeEvidenceStorageLocation,
  addCodeEvidenceStorageContainer,
  getCodeEvidenceStorageContainer,
  addCodeEvidenceFinalDisposition,
  getCodeEvidenceFinalDisposition,
} from '../../reducers/dictionaries/CodeEvidenceReducer';
import { getCodeNameSuffixes } from '../../reducers/dictionaries/CodeNameSuffixesReducer';
import {
  addCodeStreetDirections,
  getCodeStreetDirections,
} from '../../reducers/dictionaries/CodeStreetDirectionsReducer';
import {
  addCodeSubstanceCategory,
  getCodeSubstanceCategory,
} from '../../reducers/dictionaries/CodeSubstanceCategoryReducer';
import {
  addCodeAddressUnitTypes,
  getCodeAddressUnitTypes,
  addCodeAddressBldgTypes,
  getCodeAddressBldgTypes,
  addCodeAddressFloorTypes,
  getCodeAddressFloorTypes,
} from '../../reducers/dictionaries/CodeAddressReducer';
import { getCodePropertyDescription } from '../../reducers/dictionaries/CodePropertyDescriptionReducer';
import { addCodePropertyDescription } from '../../reducers/dictionaries/CodePropertyDescriptionReducer';

const useStyles = makeStyles((theme) => ({
  dialogBoxTitle: {
    background: '#1976d2',
  },
}));

function RMSAddModal(props) {
  /** region Component Variables and Props */
  const classes = useStyles();
  const {
    addEntityForm,
    updateFormOpen,
    wsClient,
    // General
    getCodeIncidentTypes,
    addCodeIncidentTypes,
    addCodeCADStatuses,
    getCodeCADStatuses,
    getCodeIncidentCategories,
    addCodeIncidentCategories,
    addCodeWeather,
    addCodeLightning,
    addCodeNameSuffixes,
    addCodeHanded,
    addCodeEyeColor,
    addCodeHairColor,
    addCodeHairLength,
    addCodeBloodType,
    addCodeEthnicity,
    addCodeStates,
    addCodeCities,
    addCodeStreetType,
    addCodeHairStyle,
    addCodeFirearmCategory,
    addCodeMeasures,
    addCodeFirearmCaliber,
    addCodeMaterial,
    addCodeJewelryCateogory,
    addCodeGarageTypes,
    addCodeStructureColor,
    addCodeVehicleStyle,
    addCodeDrugMeasurements,
    addCodeDEADrugClasses,
    addCodeVehicleBrands,

    getCodeEthnicity,
    getCodeHairLength,
    getCodeHairStyle,
    getCodeHairColor,
    getCodeEyeColor,
    getCodeBloodType,
    getCodeHanded,
    getCodeStates,
    getCodeCities,
    getCodeLightning,
    getCodeWeather,
    getCodeFirearmCategory,
    getCodeFirearmCaliber,
    getCodeMeasures,
    getCodeDrugMeasurements,
    getCodeDEADrugClasses,

    getCodeJewelryCateogory,
    getCodeMaterial,
    getCodeStructureColor,
    getCodeGarageTypes,
    // Offense
    getCodeOffenseFactor,
    addCodeOffenseFactor,
    getCodeVehicleColor,
    addCodeVehicleColor,
    addCodeVehicleClassifications,
    addCodeVehicleBranders,
    getCodeVehicleStyle,
    getCodeVehicleClassifications,
    getCodeVehicleBrands,
    getCodeVehicleBranders,
    addCodeVehicleProperty,
    addCodeVehicleCommercial,
    addCodeTransmission,
    addCodeGarageFacilities,
    getCodeVehicleProperty,
    getCodeVehicleCommercial,
    getCodeTransmission,
    getCodeGarageFacilities,
    addCodeDNACollection,
    getCodeDNACollection,
    addCodeSubjectInvolvement,
    getCodeSubjectInvolvement,
    addCodeSubjectMO,
    getCodeSubjectMO,
    addCodeSubjectStatus,
    getCodeSubjectStatus,
    addCodeSubjectWeapon,
    getCodeSubjectWeapon,
    addCodeItemConditions,
    getCodeItemConditions,
    addCodeItemMakes,
    getCodeItemMakes,
    addCodeItemModels,
    getCodeItemModels,
    addCodeVehicleMakes,
    getCodeVehicleMakes,
    addCodeVehicleMakeModels,
    getCodeVehicleMakeModels,
    addCodeVictimCategory,
    getCodeVictimCategory,
    getCodeVictimDisposition,
    addCodeVictimDisposition,
    addCodeVictimTreatment,
    getCodeVictimTreatment,
    addCodeResidence,
    getCodeResidence,
    addCodeOfficerActivity,
    getCodeOfficerActivity,
    addCodeOfficerAssignment,
    getCodeOfficerAssignment,
    addCodePropertyLossType,
    getCodePropertyLossType,
    addCodePropertyCategory,
    getCodePropertyCategory,
    addCodePropertyDescription,
    getCodePropertyDescription,
    addCodeArrestCategories,
    getCodeArrestCategories,
    addCodeJuvenileDisposition,
    getCodeJuvenileDisposition,
    addCodeArresteeArmed,
    getCodeArresteeArmed,
    addCodeEvidenceType,
    getCodeEvidenceType,
    addCodeEvidenceRequiredAction,
    getCodeEvidenceRequiredAction,
    addCodeEvidenceTemporaryLocation,
    getCodeEvidenceTemporaryLocation,
    addCodeEvidenceDisposalCode,
    getCodeEvidenceDisposalCode,
    addCodeEvidenceCustodyStatus,
    getCodeEvidenceCustodyStatus,
    addCodeEvidenceCustodyReason,
    getCodeEvidenceCustodyReason,
    addCodeEvidenceStorageLocation,
    getCodeEvidenceStorageLocation,
    addCodeEvidenceStorageContainer,
    getCodeEvidenceStorageContainer,
    addCodeEvidenceFinalDisposition,
    getCodeEvidenceFinalDisposition,
    getCodeNameSuffixes,
    addCodeStreetDirections,
    getCodeStreetDirections,
    getCodeStreetType,
    addCodeSubstanceCategory,
    getCodeSubstanceCategory,
    addCodeAddressUnitTypes,
    getCodeAddressUnitTypes,
    addCodeAddressBldgTypes,
    getCodeAddressBldgTypes,
    addCodeAddressFloorTypes,
    getCodeAddressFloorTypes,
    addCodeCoordinateDatumTypes,
    getCodeCoordinateDatumTypes,
  } = props;

  const client = wsClient.websocket;
  const [serviceName, setServiceName] = useState(null);
  const [fields, setFields] = useState([]);
  const [modalForm, setModalForm] = useState([]);
  const [button, setButton] = useState(null);
  const [primaryKey, setPrimaryKey] = useState(null);

  const { setTextField } = addEntityForm;

  const getCodeFromDB = async (serviceName, func) => {
    const select = [];
    fields.forEach((row) => row.forEach((col) => select.push(col[0])));
    const result = await client.service(serviceName.toLowerCase()).find({
      query: {
        $limit: 10,
        $select: [...select],
        $sort: {
          Description: 1,
        },
        IsDeleted: false,
      },
    });

    func(result.data);

    return result;
  };

  const getCode = (newEntity) => {
    switch (serviceName.toLowerCase()) {
      case 'codeincidenttypes': {
        getCodeIncidentTypes();
        break;
      }
      case 'codecadstatuses': {
        getCodeCADStatuses();
        break;
      }
      case 'codeincidentcategories': {
        getCodeFromDB(serviceName, getCodeIncidentCategories);
        break;
      }
      case 'codeethnicity': {
        getCodeEthnicity();
        break;
      }
      case 'codehairlength': {
        getCodeHairLength();
        break;
      }
      case 'codehairstyle': {
        getCodeHairStyle();
        break;
      }
      case 'codehaircolor': {
        getCodeHairColor();
        break;
      }
      case 'codeeyecolor': {
        getCodeEyeColor();
        break;
      }
      case 'codebloodtype': {
        getCodeBloodType();
        break;
      }
      case 'codehanded': {
        getCodeHanded();
        break;
      }
      case 'codestates': {
        getCodeFromDB(serviceName, getCodeStates);
        break;
      }
      case 'codevehiclestyles': {
        getCodeFromDB(serviceName, getCodeVehicleStyle);
        break;
      }
      case 'codelighting': {
        getCodeLightning(client);
        // getCodeFromDB(serviceName, getCodeLightning);
        break;
      }
      case 'codestructurecolors': {
        getCodeFromDB(serviceName, getCodeStructureColor);
        break;
      }
      case 'codeweather': {
        getCodeWeather(client);
        // getCodeFromDB(serviceName, getCodeWeather);
        break;
      }
      case 'codemeasures': {
        getCodeFromDB(serviceName, getCodeMeasures);
        break;
      }
      case 'codematerial': {
        getCodeFromDB(serviceName, getCodeMaterial);
        break;
      }
      case 'codevehicleclassifications': {
        getCodeFromDB(serviceName, getCodeVehicleClassifications);
        break;
      }
      case 'codevehiclebrands': {
        getCodeFromDB(serviceName, getCodeVehicleBrands);
        break;
      }
      case 'codevehiclebranders': {
        getCodeFromDB(serviceName, getCodeVehicleBranders);
        break;
      }
      case 'codevehicleproperty': {
        getCodeFromDB(serviceName, getCodeVehicleProperty);
        break;
      }
      case 'codevehiclecommercial': {
        getCodeFromDB(serviceName, getCodeVehicleCommercial);
        break;
      }
      case 'codetransmissions': {
        getCodeFromDB(serviceName, getCodeTransmission);
        break;
      }
      case 'codesubjectinvolvement': {
        getCodeFromDB(serviceName, getCodeSubjectInvolvement);
        break;
      }
      case 'codesubjectstatus': {
        getCodeFromDB(serviceName, getCodeSubjectStatus);
        break;
      }
      case 'codesubjectmo': {
        getCodeFromDB(serviceName, getCodeSubjectMO);
        break;
      }
      case 'codesubjectweapon': {
        getCodeFromDB(serviceName, getCodeSubjectWeapon);
        break;
      }
      case 'codeitemconditions': {
        getCodeFromDB(serviceName, getCodeItemConditions);
        break;
      }
      case 'codevehiclemakes': {
        getCodeFromDB(serviceName, getCodeVehicleMakes);
        break;
      }
      case 'codevehiclemakesmodels': {
        getCodeFromDB(serviceName, getCodeVehicleMakeModels);
        break;
      }
      case 'codevictimcategory': {
        getCodeFromDB(serviceName, getCodeVictimCategory);
        break;
      }
      case 'codevictimdisposition': {
        getCodeFromDB(serviceName, getCodeVictimDisposition);
        break;
      }
      case 'codevictimtreatment': {
        getCodeFromDB(serviceName, getCodeVictimTreatment);
        break;
      }
      case 'coderesident': {
        getCodeFromDB(serviceName, getCodeResidence);
        break;
      }
      case 'codeofficeractivity': {
        getCodeFromDB(serviceName, getCodeOfficerActivity);
        break;
      }
      case 'codeofficerassignment': {
        getCodeFromDB(serviceName, getCodeOfficerAssignment);
        break;
      }
      case 'codepropertylosstype': {
        getCodeFromDB(serviceName, getCodePropertyLossType);
        break;
      }
      case 'codepropertycategory': {
        getCodeFromDB(serviceName, getCodePropertyCategory);
        break;
      }
      case 'codepropertydescription': {
        getCodeFromDB(serviceName, getCodePropertyDescription);
        break;
      }
      case 'codenamesuffixes': {
        getCodeFromDB(serviceName, getCodeNameSuffixes);
        break;
      }
      case 'codestreetdirections': {
        getCodeFromDB(serviceName, getCodeStreetDirections);
        break;
      }
      case 'codestreettypes': {
        getCodeFromDB(serviceName, getCodeStreetType);
        break;
      }
      case 'codesubstancecategory': {
        getCodeFromDB(serviceName, getCodeSubstanceCategory);
        break;
      }
      case 'codecities': {
        getCodeCities();
        break;
      }
      case 'codefirearmcategory': {
        getCodeFirearmCategory();
        break;
      }
      case 'codefirearmcaliber': {
        getCodeFirearmCaliber();
        break;
      }
      case 'codejewelrycategory': {
        getCodeJewelryCateogory();
        break;
      }
      case 'codegaragetypes': {
        getCodeGarageTypes();
        break;
      }
      case 'codeoffensefactor': {
        getCodeFromDB(serviceName, getCodeOffenseFactor);
        break;
      }
      case 'codevehiclecolors': {
        getCodeFromDB(serviceName, getCodeVehicleColor);
        break;
      }
      case 'codedrugmeasurements': {
        getCodeDrugMeasurements();
        break;
      }
      case 'codedeadrugclasses': {
        getCodeDEADrugClasses();
        break;
      }
      case 'codegaragefacilities': {
        getCodeGarageFacilities();
        break;
      }
      case 'codednacollection': {
        getCodeDNACollection();
        break;
      }
      case 'codeitemmakes': {
        getCodeItemMakes();
        break;
      }
      case 'codeitemmodels': {
        getCodeItemModels();
        break;
      }
      case 'codearrestcategories': {
        getCodeArrestCategories();
        break;
      }
      case 'codejuveniledisposition': {
        getCodeJuvenileDisposition();
        break;
      }
      case 'codearresteearmed': {
        getCodeArresteeArmed();
        break;
      }
      case 'codeevidencetype': {
        getCodeEvidenceType();
        break;
      }
      case 'codeevidencerequiredaction': {
        getCodeEvidenceRequiredAction();
        break;
      }
      case 'codeevidencetemporarylocation': {
        getCodeEvidenceTemporaryLocation();
        break;
      }
      case 'codeevidencedisposalcode': {
        getCodeEvidenceDisposalCode();
        break;
      }
      case 'codeevidencecustodystatus': {
        getCodeEvidenceCustodyStatus();
        break;
      }
      case 'codeevidencecustodyreason': {
        getCodeEvidenceCustodyReason();
        break;
      }
      case 'codeevidencestoragelocation': {
        getCodeEvidenceStorageLocation();
        break;
      }
      case 'codeevidencestoragecontainer': {
        getCodeEvidenceStorageContainer();
        break;
      }
      case 'codeevidencefinaldisposition': {
        getCodeEvidenceFinalDisposition();
        break;
      }
      case 'codeaddressunittypes': {
        getCodeAddressUnitTypes();
        break;
      }
      case 'codeaddressbldgtypes': {
        getCodeAddressBldgTypes();
        break;
      }
      case 'codeaddressfloortypes': {
        getCodeAddressFloorTypes();
        break;
      }
      case 'codecoordinatedatumtypes': {
        getCodeCoordinateDatumTypes();
        break;
      }
      default:
        console.log('no get code function found for service ' + serviceName);
    }
  };

  const addCode = (newEntity) => {
    switch (serviceName.toLowerCase()) {
      case 'codeincidenttypes': {
        addCodeIncidentTypes(newEntity);
        break;
      }
      case 'codecadstatuses': {
        addCodeCADStatuses(newEntity);
        break;
      }
      case 'codeincidentcategories': {
        addCodeIncidentCategories(newEntity);
        break;
      }
      case 'codeweather': {
        addCodeWeather(newEntity);
        break;
      }
      case 'codelighting': {
        addCodeLightning(newEntity);
        break;
      }
      case 'codehanded': {
        addCodeHanded(newEntity);
        break;
      }
      case 'codeeyecolor': {
        addCodeEyeColor(newEntity);
        break;
      }
      case 'codehaircolor': {
        addCodeHairColor(newEntity);
        break;
      }
      case 'codehairlength': {
        addCodeHairLength(newEntity);
        break;
      }
      case 'codebloodtype': {
        addCodeBloodType(newEntity);
        break;
      }
      case 'codeethnicity': {
        addCodeEthnicity(newEntity);
        break;
      }
      case 'codestates': {
        addCodeStates(newEntity);
        break;
      }
      case 'codecities': {
        addCodeCities(newEntity);
        break;
      }
      case 'codestreettypes': {
        addCodeStreetType(newEntity);
        break;
      }
      case 'codehairstyle': {
        addCodeHairStyle(newEntity);
        break;
      }
      case 'codefirearmcaliber': {
        addCodeFirearmCaliber(newEntity);
        break;
      }
      case 'codefirearmcategory': {
        addCodeFirearmCategory(newEntity);
        break;
      }
      case 'codemeasures': {
        addCodeMeasures(newEntity);
        break;
      }
      case 'codematerial': {
        addCodeMaterial(newEntity);
        break;
      }
      case 'codejewelrycategory': {
        addCodeJewelryCateogory(newEntity);
        break;
      }
      case 'codegaragetypes': {
        addCodeGarageTypes(newEntity);
        break;
      }
      case 'codestructurecolors': {
        addCodeStructureColor(newEntity);
        break;
      }
      case 'codedrugmeasurements': {
        addCodeDrugMeasurements(newEntity);
        break;
      }
      case 'codeoffensefactor': {
        addCodeOffenseFactor(newEntity);
        break;
      }
      case 'codedeadrugclasses': {
        addCodeDEADrugClasses(newEntity);
        break;
      }
      case 'codevehiclestyles': {
        addCodeVehicleStyle(newEntity);
        break;
      }
      case 'codevehiclecolors': {
        addCodeVehicleColor(newEntity);
        break;
      }
      case 'codevehicleclassifications': {
        addCodeVehicleClassifications(newEntity);
        break;
      }
      case 'codevehiclebrands': {
        addCodeVehicleBrands(newEntity);
        break;
      }
      case 'codevehiclebranders': {
        addCodeVehicleBranders(newEntity);
        break;
      }
      case 'codevehicleproperty': {
        addCodeVehicleProperty(newEntity);
        break;
      }
      case 'codevehiclecommercial': {
        addCodeVehicleCommercial(newEntity);
        break;
      }
      case 'codetransmissions': {
        addCodeTransmission(newEntity);
        break;
      }
      case 'codegaragefacilities': {
        addCodeGarageFacilities(newEntity);
        break;
      }
      case 'codednacollection': {
        addCodeDNACollection(newEntity);
        break;
      }
      case 'codesubjectinvolvement': {
        addCodeSubjectInvolvement(newEntity);
        break;
      }
      case 'codesubjectmo': {
        addCodeSubjectMO(newEntity);
        break;
      }
      case 'codesubjectstatus': {
        addCodeSubjectStatus(newEntity);
        break;
      }
      case 'codesubjectweapon': {
        addCodeSubjectWeapon(newEntity);
        break;
      }
      case 'codeitemconditions': {
        addCodeItemConditions(newEntity);
        break;
      }
      case 'codeitemmakes': {
        addCodeItemMakes(newEntity);
        break;
      }
      case 'codeitemmodels': {
        addCodeItemModels(newEntity);
        break;
      }
      case 'codevehiclemakes': {
        addCodeVehicleMakes(newEntity);
        break;
      }
      case 'codevehiclemakesmodels': {
        addCodeVehicleMakeModels(newEntity);
        break;
      }
      case 'codevictimcategory': {
        addCodeVictimCategory(newEntity);
        break;
      }
      case 'codevictimdisposition': {
        addCodeVictimDisposition(newEntity);
        break;
      }
      case 'codevictimtreatment': {
        addCodeVictimTreatment(newEntity);
        break;
      }
      case 'coderesident': {
        addCodeResidence(newEntity);
        break;
      }
      case 'codeofficeractivity': {
        addCodeOfficerActivity(newEntity);
        break;
      }
      case 'codeofficerassignment': {
        addCodeOfficerAssignment(newEntity);
        break;
      }
      case 'codepropertylosstype': {
        addCodePropertyLossType(newEntity);
        break;
      }
      case 'codepropertycategory': {
        addCodePropertyCategory(newEntity);
        break;
      }
      case 'codepropertydescription': {
        addCodePropertyDescription(newEntity);
        break;
      }
      case 'codearrestcategories': {
        addCodeArrestCategories(newEntity);
        break;
      }
      case 'codejuveniledisposition': {
        addCodeJuvenileDisposition(newEntity);
        break;
      }
      case 'codearresteearmed': {
        addCodeArresteeArmed(newEntity);
        break;
      }
      case 'codeevidencetype': {
        addCodeEvidenceType(newEntity);
        break;
      }
      case 'codeevidencetemporarylocation': {
        addCodeEvidenceTemporaryLocation(newEntity);
        break;
      }
      case 'codeevidencedisposalcode': {
        addCodeEvidenceDisposalCode(newEntity);
        break;
      }
      case 'codeevidencecustodystatus': {
        addCodeEvidenceCustodyStatus(newEntity);
        break;
      }
      case 'codeevidencecustodyreason': {
        addCodeEvidenceCustodyReason(newEntity);
        break;
      }
      case 'codeevidencerequiredaction': {
        addCodeEvidenceRequiredAction(newEntity);
        break;
      }
      case 'codeevidencestoragelocation': {
        addCodeEvidenceStorageLocation(newEntity);
        break;
      }
      case 'codeevidencestoragecontainer': {
        addCodeEvidenceStorageContainer(newEntity);
        break;
      }
      case 'codeevidencefinaldisposition': {
        addCodeEvidenceFinalDisposition(newEntity);
        break;
      }
      case 'codenamesuffixes': {
        addCodeNameSuffixes(newEntity);
        break;
      }
      case 'codestreetdirections': {
        addCodeStreetDirections(newEntity);
        break;
      }
      case 'codesubstancecategory': {
        addCodeSubstanceCategory(newEntity);
        break;
      }
      case 'codeaddressunittypes': {
        addCodeAddressUnitTypes(newEntity);
        break;
      }
      case 'codeaddressbldgtypes': {
        addCodeAddressBldgTypes(newEntity);
        break;
      }
      case 'codeaddressfloortypes': {
        addCodeAddressFloorTypes(newEntity);
        break;
      }
      case 'codecoordinatedatumtypes': {
        addCodeCoordinateDatumTypes(newEntity);
        break;
      }
      // case "codenamesuffixes": { addCodeNameSuffixes(obj); break; } // not null violation while creating.
      default:
        console.log('no add code function found for service ' + serviceName);
    }
  };

  const addEntity = async (newEntity) => {
    try {
      // console.log('obj is', obj);
      for (const key in newEntity) {
        if (newEntity[key].length === 0) throw Error('empty_string');
      }
      console.log(newEntity);
      let ifAlreadyExist = await client.service(serviceName.toLowerCase()).find({
        query: {
          Code: newEntity.Code,
        },
      });

      let response;
      if (ifAlreadyExist.total > 0) {
        response = await client.service(serviceName.toLowerCase()).patch(newEntity.Code, newEntity);
      } else {
        response = await client.service(serviceName.toLowerCase()).create(newEntity);
      }

      console.log('add response', response);
      addCode(newEntity);
      if (setTextField) setTextField(newEntity);
      resetModal(); // called to close the modal.
    } catch (err) {
      console.log('err', err);
      if (err.errors && err.errors[0]?.validatorKey?.toLowerCase() === 'not_unique') {
        setAlertBox({
          visibility: true,
          message: `${label} must be unique.`,
          severity: 'error',
        });
      } else if (err.message === 'empty_string') {
        setAlertBox({
          visibility: true,
          message: `You must fill all the fields.`,
          severity: 'warning',
        });
      } else {
        setAlertBox({
          visibility: true,
          message: `Something went wrong.`,
          severity: 'error',
        });
      }
    }
  };

  const editEntity = async (newForm) => {
    try {
      const oldForm = addEntityForm.form;
      for (const key in newForm) {
        if (newForm[key].length === 0) throw Error('empty_string');
      }
      // TODO: SET P_K IN THE BEGINNING.
      const response = await client
        .service(serviceName.toLowerCase())
        .patch(oldForm[primaryKey], newForm);
      console.log('response', response);
      getCode();
      resetModal();
      if (addEntityForm.resetTextField) {
        addEntityForm.resetTextField();
      }
    } catch (err) {
      console.log('err', err);
      setAlertBox({
        visibility: true,
        message: `Something went wrong.`,
        severity: 'error',
      });
    }
  };

  const deleteEntity = async (obj) => {
    // console.log('deleteEntity()');
    try {
      // const key = "00";
      // console.log('modalForm["00"]', modalForm["00"]);
      await client.service(serviceName.toLowerCase()).remove(modalForm['00']);
      // console.log('delete response', response);
      getCode();
      resetModal(); // called to close the modal.
      if (addEntityForm.resetTextField) {
        addEntityForm.resetTextField();
      }
    } catch (err) {
      console.log(err);
      setAlertBox({
        visibility: true,
        message: `Something went wrong.`,
        severity: 'error',
      });
    }
  };

  const handleSubmit = () => {
    resetAlertbox();
    if (addEntityForm.type === null || addEntityForm.type === 'add') {
      let newEntity = {};
      fields.forEach((row, i) => {
        row.forEach((col, j) => {
          const key = i + '' + j;
          newEntity[col[0]] = modalForm[key];
        });
      });
      // to fix the not null violation error.
      newEntity = {
        ...newEntity,
        IsDefault: true,
        IsActive: true,
        IsDeleted: false,
        // the following added for code unit
        isDefault: true,
        isActive: true,
        isDeleted: false,
      };
      addEntity(newEntity);
    } else if (addEntityForm.type === 'edit') {
      let newEntity = {};
      fields.forEach((row, i) => {
        row.forEach((col, j) => {
          const key = i + '' + j;
          newEntity[col[0]] = modalForm[key];
        });
      });
      editEntity(newEntity);
    } else if (addEntityForm.type === 'delete') {
      deleteEntity();
    }
  };
  const { open, label } = addEntityForm;

  // const [alertVisibility, setAlertVisibility] = useState(false);
  // console.log('modal form', modalForm);
  const [alertBox, setAlertBox] = useState({
    visibility: false,
    message: '',
    severity: '',
  });

  const resetAlertbox = () => {
    setAlertBox({
      visibility: false,
      message: '',
      severity: '',
    });
  };

  React.useEffect(() => {
    resetAlertbox();
    let found = false;
    form.forEach((entity) => {
      if (
        serviceName === null &&
        !found &&
        (addEntityForm.serviceName
          ? addEntityForm.serviceName.toLowerCase() === entity.serviceName &&
            entity?.label === label
          : entity?.label === label)
      ) {
        let tempFields = [];
        setPrimaryKey(entity.fields[0][0]);
        setServiceName(entity.serviceName.toLowerCase());
        found = true;
        for (let i = 0; i < entity.fields.length; ++i) {
          tempFields.push([...entity.fields[i]]);
        }

        const tempModalForm = [];
        for (let i = 0; i < tempFields.length; ++i) {
          for (let j = 0; j < tempFields[i].length; ++j) {
            tempFields[i][j] = [tempFields[i][j], i, j];
            let key = i + '' + j;
            tempModalForm.push({ [key]: '' });
          }
        }
        if (modalForm.length === 0) {
          tempModalForm.forEach((obj) => {
            setModalForm((p) => {
              return {
                ...p,
                ...obj,
              };
            });
          });
        }
        if (fields.length === 0) setFields(tempFields);
        if (
          (addEntityForm.type === 'edit' || addEntityForm.type === 'delete') &&
          addEntityForm.form != null
        ) {
          // console.log('TempFields', tempFields)
          // setButton("Update");
          for (let i = 0; i < tempFields.length; ++i) {
            for (let j = 0; j < tempFields[i].length; ++j) {
              handleChange(
                addEntityForm.form[tempFields[i][j][0]],
                tempFields[i][j][1],
                tempFields[i][j][2]
              );
            }
          }
        }
        switch (addEntityForm.type) {
          case 'edit':
            setButton('Update');
            break;
          case 'delete':
            setButton('Remove');
            break;
          case 'add':
            setButton('Add');
            break;
          default:
            setButton('Add');
        }
      }
    });
    console.log('service name', serviceName);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  const handleChange = (input, row, col) => {
    let key = row + '' + col;
    setModalForm((p) => {
      return {
        ...p,
        [key]: input,
      };
    });
  };

  const resetModal = () => {
    updateFormOpen();
    setFields([]);
    setModalForm([]);
    setServiceName(null);
    setButton(null);
    setPrimaryKey(null);
  };

  return (
    <div>
      {fields.length > 0 && (
        <Dialog open={open} maxWidth="lg" aria-labelledby="form-dialog-title">
          <DialogTitle
            className={clsx(
              classes.dialogBoxTitle,
              ' d-flex justify-content-between align-items-center'
            )}
            id="alert-dialog-title">
            {button === 'Add' && (
              <span style={{ fontSize: '16px', color: 'white' }}>{`Add ${label}`}</span>
            )}
            {button === 'Update' && (
              <span style={{ fontSize: '16px', color: 'white' }}>{`Update ${label}`}</span>
            )}
            {button === 'Remove' && (
              <span
                style={{
                  fontSize: '16px',
                  color: 'white',
                }}>{`Remove ${label}`}</span>
            )}
          </DialogTitle>
          <DialogContent style={{ padding: '34px 40px 60px' }}>
            <Grid container spacing={3}>
              {button === 'Remove' && `Are you sure you want to remove this ${label}?`}
              {button !== 'Remove' &&
                fields.map((attribute) => {
                  // console.log('attribute', attribute);
                  return (
                    <Grid container spacing={3}>
                      {attribute.map((item) => {
                        let key = item[1] + '' + item[2];
                        const edit = item[1] === 0 && item[2] === 0 && button === 'Update';
                        return (
                          <Grid item xs>
                            <TextField
                              autoComplete="hidden"
                              size="small"
                              disabled={edit}
                              autoFocus
                              margin="dense"
                              id="name"
                              label={item[0]}
                              type="text"
                              value={modalForm[key]}
                              fullWidth
                              onChange={(e) => handleChange(e.target.value, item[1], item[2])}
                            />
                          </Grid>
                        );
                      })}
                    </Grid>
                  );
                })}
            </Grid>
          </DialogContent>
          <Alert
            hidden={!alertBox.visibility}
            style={{ marginLeft: '20px', marginRight: '20px', marginTop: '0px' }}
            severity={alertBox.severity}>
            {alertBox.message}
          </Alert>
          <DialogActions>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Grid
                  container
                  direction="row"
                  justify="flex-end"
                  alignItems="center"
                  style={{ padding: 5, margin: '-15px 0px 10px' }}>
                  <Button onClick={resetModal} style={{ marginRight: 10 }}>
                    {button === 'Remove' ? 'No' : 'Close'}
                  </Button>
                  <Button
                    onClick={handleSubmit}
                    style={{ background: '#1976d2', color: '#fff' }}
                    variant="contained">
                    {button === 'Remove' ? 'Yes' : button}
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </DialogActions>
        </Dialog>
      )}

      {fields.length === 0 && (
        <Dialog open={open} maxWidth="lg" aria-labelledby="form-dialog-title">
          <DialogContent>
            <DialogContentText>No such label found in the dataset.</DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button color="primary" onClick={() => updateFormOpen({ label })}>
              Close
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </div>
  );
}

const mapStateToProps = (state) => ({
  wsClient: state.websocket,
  addEntityForm: state.addEntityForm,
});

export default connect(mapStateToProps, {
  updateFormOpen,
  incrementCount,
  getCodeIncidentTypes,
  addCodeIncidentTypes,
  getCodeCADStatuses,
  addCodeCADStatuses,
  getCodeIncidentCategories,
  addCodeIncidentCategories,
  addCodeWeather,
  addCodeLightning,
  addCodeNameSuffixes,
  addCodeHanded,
  addCodeEyeColor,
  addCodeHairColor,
  addCodeHairLength,
  addCodeBloodType,
  addCodeEthnicity,
  addCodeStates,
  addCodeCities,
  addCodeStreetType,
  addCodeHairStyle,
  addCodeFirearmCaliber,
  addCodeMaterial,
  addCodeJewelryCateogory,
  addCodeGarageTypes,
  addCodeStructureColor,
  addCodeVehicleStyle,

  getCodeEthnicity,
  getCodeHairLength,
  getCodeHairStyle,
  getCodeHairColor,
  getCodeEyeColor,
  getCodeBloodType,
  getCodeHanded,
  getCodeStates,
  getCodeCities,
  getCodeLightning,
  getCodeWeather,
  addCodeFirearmCategory,
  addCodeMeasures,
  getCodeFirearmCategory,
  getCodeFirearmCaliber,
  getCodeMeasures,

  getCodeOffenseFactor,
  addCodeOffenseFactor,
  getCodeJewelryCateogory,
  getCodeMaterial,
  getCodeStructureColor,
  getCodeGarageTypes,
  addCodeDrugMeasurements,
  addCodeDEADrugClasses,
  getCodeDrugMeasurements,
  getCodeDEADrugClasses,
  getCodeVehicleColor,
  addCodeVehicleColor,
  addCodeVehicleClassifications,
  addCodeVehicleBrands,
  addCodeVehicleBranders,
  getCodeVehicleStyle,
  getCodeVehicleClassifications,
  getCodeVehicleBrands,
  getCodeVehicleBranders,
  addCodeVehicleProperty,
  addCodeVehicleCommercial,
  addCodeTransmission,
  addCodeGarageFacilities,
  getCodeVehicleProperty,
  getCodeVehicleCommercial,
  getCodeTransmission,
  getCodeGarageFacilities,
  addCodeDNACollection,
  getCodeDNACollection,
  addCodeSubjectInvolvement,
  getCodeSubjectInvolvement,
  addCodeSubjectMO,
  getCodeSubjectMO,
  addCodeSubjectStatus,
  getCodeSubjectStatus,
  addCodeSubjectWeapon,
  getCodeSubjectWeapon,
  addCodeItemConditions,
  getCodeItemConditions,
  addCodeItemMakes,
  getCodeItemMakes,
  addCodeItemModels,
  getCodeItemModels,
  addCodeVehicleMakes,
  getCodeVehicleMakes,
  addCodeVehicleMakeModels,
  getCodeVehicleMakeModels,
  addCodeVictimCategory,
  getCodeVictimCategory,
  getCodeVictimDisposition,
  addCodeVictimDisposition,
  addCodeVictimTreatment,
  getCodeVictimTreatment,
  addCodeResidence,
  getCodeResidence,
  addCodeOfficerActivity,
  getCodeOfficerActivity,
  addCodeOfficerAssignment,
  getCodeOfficerAssignment,
  addCodePropertyLossType,
  getCodePropertyLossType,
  addCodePropertyCategory,
  getCodePropertyCategory,
  getCodePropertyDescription,
  addCodePropertyDescription,
  addCodeArrestCategories,
  getCodeArrestCategories,
  addCodeJuvenileDisposition,
  getCodeJuvenileDisposition,
  addCodeArresteeArmed,
  getCodeArresteeArmed,
  addCodeEvidenceType,
  getCodeEvidenceType,
  addCodeEvidenceRequiredAction,
  getCodeEvidenceRequiredAction,
  addCodeEvidenceTemporaryLocation,
  getCodeEvidenceTemporaryLocation,
  addCodeEvidenceDisposalCode,
  getCodeEvidenceDisposalCode,
  addCodeEvidenceCustodyStatus,
  getCodeEvidenceCustodyStatus,
  addCodeEvidenceCustodyReason,
  getCodeEvidenceCustodyReason,
  addCodeEvidenceStorageLocation,
  getCodeEvidenceStorageLocation,
  addCodeEvidenceStorageContainer,
  getCodeEvidenceStorageContainer,
  addCodeEvidenceFinalDisposition,
  getCodeEvidenceFinalDisposition,
  getCodeNameSuffixes,
  addCodeStreetDirections,
  getCodeStreetDirections,
  getCodeStreetType,
  addCodeSubstanceCategory,
  getCodeSubstanceCategory,
  addCodeAddressUnitTypes,
  getCodeAddressUnitTypes,
  addCodeAddressBldgTypes,
  getCodeAddressBldgTypes,
  addCodeAddressFloorTypes,
  getCodeAddressFloorTypes,
  addCodeCoordinateDatumTypes,
  getCodeCoordinateDatumTypes,
})(RMSAddModal);
