export const codeInjuryCategory = [
  {
    code: 'B',
    description: 'Apparent Broken Bones',
  },
  {
    code: 'I',
    description: 'Possible Internal Injury',
  },
  {
    code: 'L',
    description: 'Severe Laceration',
  },
  {
    code: 'M',
    description: 'Apparent Minor Injury',
  },
  {
    code: 'N',
    description: 'None',
  },
  {
    code: 'O',
    description: 'Other Major Injury',
  },
  {
    code: 'T',
    description: 'Loss of Teeth',
  },
  {
    code: 'U',
    description: 'Unconsciousness',
  },
];
