import React from 'react';
import { connect } from 'react-redux';

import { Card, CardContent, Button, FormControl, TextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { submitLoginForm } from '../reducers/UserReducer';
import { checkForUpdates } from '../reducers/AppInfoReducer';
import splashScreen from '../assets/images/login-splash.jpg';
import IconRms from '../assets/images/icon-RMS.svg';
import ptsLogo from '../assets/images/PTS-logo.png';
const useStyles = makeStyles(theme => ({
  pageWrap: {
    width: '100%',
    height: '100vh',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundImage: `url(${splashScreen})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  },
  card: {
    width: 480,
    height: 510,
    maxWidth: '95%',
    background: 'fff',
    '& img': {
      width: '100%',
    },
  },
  content: {
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
  },
  branding: {
    width: '100%',
    height: 200,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    background: '#0051a0',
    '& img': {
      width: 150,
      height: 'auto',
    },
  },
  branding2: {
    width: '100%',
    textAlign: 'center',
    padding: '25px 0 5px',
    '& img': {
      height: 50,
      width: 'auto',
    },
  },
  actions: {
    textAlign: 'center',
    marginTop: 30,
    '& button': {
      background: '#0051a0',
      color: '#fff',
      paddingLeft: '4em',
      paddingRight: '4em',
      textTransform: 'uppercase',
      '&:hover': {
        background: '#021090',
      },
    },
  },
}));

const PageLogin = props => {
  const { themeMode, checkForUpdates } = props;
  const { appVersion } = props.appInfo;
  const classes = useStyles();
  const [username, setUsername] = React.useState('');
  const [password, setPassword] = React.useState('');

  React.useEffect(() => {
    props.checkForUpdates();
  }, []);
  const handleUsernameChange = event => {
    setUsername(event.target.value);
  };

  const handlePasswordChange = event => {
    setPassword(event.target.value);
  };

  const submitForm = event => {
    event.preventDefault();
    props.submitLoginForm(username, password);
  };

  return (
    <div className={classes.pageWrap}>
      <Card className={classes.card}>
        <div className={classes.branding}>
          <img src={IconRms} alt="MOBILE icon" />
        </div>
        <div className={classes.branding2}>
          <img src={ptsLogo} alt="PTS logo" />
        </div>
        <CardContent>
          <form onSubmit={submitForm}>
            <div className="mb-3">
              <FormControl size="small" className="w-100">
                <TextField
                  autoComplete="hidden"
                  size="small"
                  required
                  variant="outlined"
                  label="Username"
                  fullWidth
                  placeholder="Enter your username"
                  type="text"
                  value={username}
                  onChange={handleUsernameChange}
                />
              </FormControl>
            </div>
            <div className="mb-3">
              <FormControl size="small" className="w-100">
                <TextField
                  autoComplete="hidden"
                  size="small"
                  required
                  variant="outlined"
                  label="Password"
                  fullWidth
                  placeholder="Enter your password"
                  type="password"
                  value={password}
                  onChange={handlePasswordChange}
                />
              </FormControl>
            </div>
            <div className={classes.actions}>
              <Button type="submit" variant="contained" size="large" className="my-2">
                Login
              </Button>
            </div>
          </form>
          <p style={{ fontWeight: 'bold' }}> v. {appVersion}</p>
        </CardContent>
      </Card>
    </div>
  );
};

const mapStateToProps = state => ({
  themeMode: state.theme.mode,
  appInfo: state.appInfo,
});

export default connect(mapStateToProps, {
  submitLoginForm,
  checkForUpdates,
})(PageLogin);
