export const codeBiasMotivation = [
  {
    code: 'ANTIAMERICAN INDIAN_ ALASKAN NATIVE',
    description: 'NIBRS CODE: 13 | Anti-American Indian or Alaskan Native',
  },
  {
    code: 'ANTIARAB',
    description: 'NIBRS CODE: 31 | Anti-Arab',
  },
  {
    code: 'ANTIASIAN',
    description: 'NIBRS CODE: 14 | Anti-Asian',
  },
  {
    code: 'ANTIATHEIST_AGNOSTIC',
    description: 'NIBRS CODE: 27 | Anti-Atheism/ Agnosticism',
  },
  {
    code: 'ANTIBISEXUAL',
    description: 'NIBRS CODE: 45 | Anti-Bisexual',
  },
  {
    code: 'ANTIBLACK_AFRICAN AMERICAN',
    description: 'NIBRS CODE: 12 | Anti-Black or African American',
  },
  {
    code: 'ANTIBUDDHIST',
    description: 'NIBRS CODE: 83 | Anti Buddhist',
  },
  {
    code: 'ANTICATHOLIC',
    description: 'NIBRS CODE: 22 | Anti-Catholic',
  },
  {
    code: 'ANTIEASTERNORTHODOX',
    description: 'NIBRS CODE: 81 | Anti-Eastern Orthodox (Russian, Greek, Other)',
  },
  {
    code: 'ANTIFEMALE',
    description: 'NIBRS CODE: 62 | Anti-Female',
  },
  {
    code: 'ANTIFEMALE HOMOSEXUAL',
    description: 'NIBRS CODE: 42 | Anti-Lesbian',
  },
  {
    code: 'ANTIGENDER_NONCONFORMING',
    description: 'NIBRS CODE: 72 | Anti-Gender Non-Conforming',
  },
  {
    code: 'ANTIHETEROSEXUAL',
    description: 'NIBRS CODE: 44 | Anti-Heterosexual',
  },
  {
    code: 'ANTIHINDU',
    description: 'NIBRS CODE: 84 | Anti-Hindu',
  },
  {
    code: 'ANTIHISPANIC_LATINO',
    description: 'NIBRS CODE: 32 | Anti-Hispanic or Latino',
  },
  {
    code: 'ANTIHOMOSEXUAL',
    description: 'NIBRS CODE: 43 | Anti-Lesbian, Gay, Bisexual, or Transgender (Mixed Group)',
  },
  {
    code: 'ANTIISLAMIC',
    description: 'NIBRS CODE: 24 | Anti-Islamic (Muslim)',
  },
  {
    code: 'ANTIJEHOVAHWITNESS',
    description: "NIBRS CODE: 29 | Anti-Jehovah's Witness",
  },
  {
    code: 'ANTIJEWISH',
    description: 'NIBRS CODE: 21 | Anti-Jewish',
  },
  {
    code: 'ANTIMALE',
    description: 'NIBRS CODE: 61 | Anti-Male',
  },
  {
    code: 'ANTIMALE HOMOSEXUAL',
    description: 'NIBRS CODE: 41 | Anti-Gay',
  },
  {
    code: 'ANTIMENTAL DISABILITY',
    description: 'NIBRS CODE: 52 | Anti-Mental Disability',
  },
  {
    code: 'ANTIMORMON',
    description: 'NIBRS CODE: 28 | Anti-Mormon',
  },
  {
    code: 'ANTIMULTIRACIAL GROUP',
    description: 'NIBRS CODE: 15 | Anti-Multiple Races, Group',
  },
  {
    code: 'ANTIMULTIRELIGIOUS GROUP',
    description: 'NIBRS CODE: 26 | Anti-Multiple Religions, Group',
  },
  {
    code: 'ANTINATIVEHAWAIIAN_OTHERPACIFICISLANDER',
    description: 'NIBRS CODE: 16 | Anti-Native Hawaiian or Other Pacific Islander',
  },
  {
    code: 'ANTIOTHER CHRISTIAN',
    description: 'NIBRS CODE: 82 | Anti-Other Christian',
  },
  {
    code: 'ANTIOTHER ETHNICITY_NATIONAL ORIGIN',
    description:
      'NIBRS CODE: 33 | Anti-Other Race, Ethnicity, Ancestry, or National Origin_race ethnicity bias',
  },
  {
    code: 'ANTIOTHER RELIGION',
    description: 'NIBRS CODE: 25 | Anti-Other Religion',
  },
  {
    code: 'ANTIPHYSICAL DISABILITY',
    description: 'NIBRS CODE: 51 | Anti-Physical Disability',
  },
  {
    code: 'ANTIPROTESTANT',
    description: 'NIBRS CODE: 23 | Anti-Protestant',
  },
  {
    code: 'ANTISIKH',
    description: 'NIBRS CODE: 85 | Anti-Sikh',
  },
  {
    code: 'ANTITRANSGENDER',
    description: 'NIBRS CODE: 71 | Anti-Transgender',
  },
  {
    code: 'ANTIWHITE',
    description: 'NIBRS CODE: 11 | Anti-White',
  },
  {
    code: 'NONE',
    description: 'NIBRS CODE: 88 | None (no bias)',
  },
  {
    code: 'UNKNOWN',
    description: "NIBRS CODE: 99 | Unknown (offender's motivation not known)",
  },
];
