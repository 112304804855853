/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import { getCodeVictimCategory } from '../../../../reducers/dictionaries/CodeVictimCategoryReducer';
import { getCodeVictimDisposition } from '../../../../reducers/dictionaries/CodeVictimDispositionReducer';
import { getCodeVictimTreatment } from '../../../../reducers/dictionaries/CodeVictimTreatmentReducer';
import { getCodeResidence } from '../../../../reducers/dictionaries/CodeResidentReducer';
import { getCodeOfficerAssignment } from '../../../../reducers/dictionaries/CodeOfficerAssignmentReducer';
import { getCodeOfficerActivity } from '../../../../reducers/dictionaries/CodeOfficerActivityReducer';
import { connect } from 'react-redux';
import { updateIncVictimsDetails } from '../../../../reducers/IncReducer';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { setHandleAddIncVictim } from '../../../../reducers/ui/UiMenuReducer';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { handleFormChangeNew } from '../../../../reducers/helpers/formHelpers';
import RMSAutoComplete from 'components/RMSAutoComplete/RMSAutoComplete';
import { codeInjuryCategory } from '../../../../reducers/_dev/codeInjuryCategory';
import { codeAggravatedAssault } from '../../../../reducers/_dev/codeAggravatedAssault';
import { validateIncVictimsForm } from 'simpl-schema-validation/ValidateForms';

const options = [{ name: 'option one' }, { name: 'option two' }];

const VictimsForm = (props) => {
  const {
    selectedVictim,
    updateIncVictimsDetails,
    incVictims,
    getCodeOfficerAssignment,
    getCodeVictimTreatment,
    codeVictimTreatment,
    codeVictimDisposition,
    codeVictimCategory,
    codeOfficerAssignment,
    codeOfficerActivity,
    codeResident,
    getCodeVictimDisposition,
    getCodeVictimCategory,
    getCodeResidence,
    getCodeOfficerActivity,
    wsClient,
  } = props;

  const [changeKey, setChangeKey] = React.useState(0);
  const [errors, setErrors] = React.useState({});
  const [offensesVictimFormState, setOffensesVictimFormState] = React.useState({
    isValid: false,
    values: {},
    touched: {},
    errors: {},
  });
  const [injuryCategoryState, setInjuryCategoryState] = React.useState([]);
  const [aggravatedAssaultState, setAggravatedAssaultState] = React.useState([]);

  React.useEffect(() => {
    let arr = codeAggravatedAssault.map((s) => s.description).filter((s) => s !== '');
    setAggravatedAssaultState(arr.sort());
  }, []);

  React.useEffect(() => {
    let arr = codeInjuryCategory.map((s) => s.description).filter((s) => s !== '');
    setInjuryCategoryState(arr.sort());
  }, []);

  React.useEffect(() => {
    if (!wsClient) return;

    const services = [
      'codeVictimDisposition',
      'codeVictimTreatment',
      'codeVictimCategory',
      'codeResident',
      'codeOfficerAssignment',
      'codeOfficerActivity',
    ];
    let $select = ['Code', 'Description'];

    services.forEach(async (svc) => {
      if (props[svc].length < 1) {
        if (svc === 'codeVictimDisposition') {
          let result = await wsClient.service(svc.toLowerCase()).find({
            query: {
              $limit: 0,
              $select,
              $sort: {
                Code: 1,
              },
              IsDeleted: false,
            },
          });
          if (result.total > 0) getCodeVictimDisposition(result.data);
        } else if (svc === 'codeVictimTreatment') {
          let result = await wsClient.service(svc.toLowerCase()).find({
            query: {
              $limit: 0,
              $select,
              $sort: {
                Code: 1,
              },
              IsDeleted: false,
            },
          });
          if (result.total > 0) getCodeVictimTreatment(result.data);
        } else if (svc === 'codeVictimCategory') {
          let result = await wsClient.service(svc.toLowerCase()).find({
            query: {
              $limit: 0,
              $select,
              $sort: {
                Description: 1,
              },
              IsDeleted: false,
            },
          });
          if (result.total > 0) getCodeVictimCategory(result.data);
        } else if (svc === 'codeResident') {
          let result = await wsClient.service(svc.toLowerCase()).find({
            query: {
              $limit: 0,
              $select,
              $sort: {
                Description: 1,
              },
              IsDeleted: false,
            },
          });
          if (result.total > 0) getCodeResidence(result.data);
        } else if (svc === 'codeOfficerAssignment') {
          let result = await wsClient.service(svc.toLowerCase()).find({
            query: {
              $limit: 0,
              $select: ['Code', 'Description'],
              $sort: {
                Description: 1,
              },
              IsDeleted: false,
            },
          });

          if (result.total > 0) {
            getCodeOfficerAssignment(result.data);
          }
        } else if (svc === 'codeOfficerActivity') {
          let result = await wsClient.service(svc.toLowerCase()).find({
            query: {
              $limit: 0,
              $select: ['Code', 'Description'],
              $sort: {
                Description: 1,
              },
              IsDeleted: false,
            },
          });

          if (result.total > 0) {
            getCodeOfficerActivity(result.data);
          }
        }
      }
    });
  }, [wsClient]);

  const updateIncDetailsList = () => {
    updateIncVictimsDetails(offensesVictimFormState);
  };

  const getIncFormData = () => {
    setOffensesVictimFormState({ ...offensesVictimFormState, values: {} });

    let selectedVictimDetails = incVictims.find(
      (x) => x.incVictimDetails.ptsIncPersonId === selectedVictim
    );

    if (selectedVictimDetails && selectedVictimDetails.incVictimDetails) {
      setOffensesVictimFormState(selectedVictimDetails.incVictimDetails);
    }
  };

  React.useEffect(() => {
    if (props.updated !== null) updateIncDetailsList();
  }, [props.updated]);

  React.useEffect(() => {
    getIncFormData();
  }, [selectedVictim, incVictims]);

  React.useEffect(() => {
    let timeoutId;
    timeoutId = setTimeout(() => {
      let errorStatus = errors;
      let error = validateIncVictimsForm('injuryType', offensesVictimFormState);

      if (error && error.length > 0) {
        errorStatus = {
          ...errorStatus,
          injuryType: `${error[0].message}`,
        };
      } else {
        errorStatus = {
          ...errorStatus,
          injuryType: null,
        };
      }

      error = validateIncVictimsForm('category', offensesVictimFormState);

      if (error && error.length > 0) {
        errorStatus = {
          ...errorStatus,
          category: `${error[0].message}`,
        };
      } else {
        errorStatus = {
          ...errorStatus,
          category: null,
        };
      }

      setErrors(errorStatus);
    }, 2000);
    return () => clearTimeout(timeoutId);
  }, [offensesVictimFormState.values.injuryType, offensesVictimFormState.values.category]);

  return (
    <Grid container justify="center" className="mt-3">
      <Grid container justify="center" className="mb-1" spacing={2}>
        <Grid item>
          <FormControlLabel
            control={
              <Checkbox
                key={offensesVictimFormState.values.seekingProsecution}
                checked={offensesVictimFormState.values.seekingProsecution}
                onChange={(event) =>
                  handleFormChangeNew(
                    event,
                    event.target.checked,
                    'none',
                    'seekingProsecution',
                    setOffensesVictimFormState
                  )
                }
                name="seekingProsecution"
              />
            }
            label="Seeking Prosecution"
          />
        </Grid>
        <Grid item>
          <FormControlLabel
            control={
              <Checkbox
                key={offensesVictimFormState.values.society}
                checked={offensesVictimFormState.values.society}
                onChange={(event) =>
                  handleFormChangeNew(
                    event,
                    event.target.checked,
                    'none',
                    'society',
                    setOffensesVictimFormState
                  )
                }
                name="society"
              />
            }
            label="Society"
          />
        </Grid>
      </Grid>

      <Grid container justify="center" className="mb-2" spacing={2}>
        <Grid item xs={12} lg={12}>
          <TextField
            id="standard-textarea-victims-caseNumber"
            size="small"
            label="VICAP Case Number"
            placeholder="VICAP Case Number"
            variant="outlined"
            fullWidth
            name="caseNumber"
            key={changeKey}
            defaultValue={offensesVictimFormState.values.caseNumber || ''}
            onBlur={(event) =>
              handleFormChangeNew(
                event,
                event.target.value,
                'none',
                'caseNumber',
                setOffensesVictimFormState
              )
            }
            multiline
            InputLabelProps={{ shrink: true }}
          />
        </Grid>
      </Grid>

      <Grid container justify="center" className="mb-2" spacing={2}>
        <Grid item xs={6} lg={6}>
          <RMSAutoComplete
            size="small"
            autoComplete
            autoSelect
            autoHighlight
            id="combo-victim-category"
            options={codeVictimCategory}
            serviceName="codevictimcategory"
            label="Victim Category"
            variant="outlined"
            title="Description"
            error={errors['category'] ? true : false}
            helperText={errors['category']}
            stateHolder={offensesVictimFormState}
            setStateHolder={setOffensesVictimFormState}
            setField={handleFormChangeNew}
            stateHolderAttribute="category"
          />
        </Grid>
        <Grid item xs={6} lg={6}>
          <RMSAutoComplete
            size="small"
            autoComplete
            autoSelect
            autoHighlight
            id="combo-victim-disposition"
            options={codeVictimDisposition}
            serviceName="codevictimdisposition"
            label="Victim Disposition"
            variant="outlined"
            title="Code"
            stateHolder={offensesVictimFormState}
            setStateHolder={setOffensesVictimFormState}
            setField={handleFormChangeNew}
            stateHolderAttribute="disposition"
          />
        </Grid>
      </Grid>

      <Grid container justify="center" className="mb-2" spacing={2}>
        <Grid item xs={6} lg={6}>
          <RMSAutoComplete
            size="small"
            autoComplete
            autoSelect
            autoHighlight
            id="combo-victim-treatment"
            options={codeVictimTreatment}
            serviceName="codeVictimTreatment"
            label="Victim Treatment"
            variant="outlined"
            title="Code"
            stateHolderAttribute="treatment"
            stateHolder={offensesVictimFormState}
            setStateHolder={setOffensesVictimFormState}
            setField={handleFormChangeNew}
          />
        </Grid>
        <Grid item xs={6} lg={6}>
          <RMSAutoComplete
            size="small"
            autoComplete
            autoSelect
            autoHighlight
            id="combo-victim-resident"
            options={codeResident}
            serviceName="coderesident"
            label="Victim Resident Status"
            variant="outlined"
            title="Description"
            stateHolder={offensesVictimFormState}
            setStateHolder={setOffensesVictimFormState}
            setField={handleFormChangeNew}
            stateHolderAttribute="residentStatus"
          />
        </Grid>
      </Grid>

      <Grid container justify="center" className="mb-2" spacing={2}>
        <Grid item xs={6} lg={6}>
          <Autocomplete
            size="small"
            autoComplete
            autoSelect
            autoHighlight
            id="combo-injury-type"
            getOptionLabel={(option) => option || ''}
            options={injuryCategoryState}
            key={offensesVictimFormState.values.injuryType || ''}
            value={offensesVictimFormState.values.injuryType || ''}
            onChange={(event, newValue) =>
              handleFormChangeNew(event, newValue, 'none', 'injuryType', setOffensesVictimFormState)
            }
            renderInput={(params) => (
              <TextField
                size="small"
                {...params}
                label="Injury Type"
                variant="outlined"
                fullWidth
                error={errors['injuryType'] ? true : false}
                helperText={errors['injuryType']}
              />
            )}
          />
        </Grid>
        <Grid item xs={6} lg={6}>
          <Autocomplete
            size="small"
            autoComplete
            autoSelect
            autoHighlight
            id="combo-aggravated-assault"
            getOptionLabel={(option) => option || ''}
            options={aggravatedAssaultState}
            key={offensesVictimFormState.values.aggravatedAssaultHomicide || ''}
            value={offensesVictimFormState.values.aggravatedAssaultHomicide || ''}
            onChange={(event, newValue) =>
              handleFormChangeNew(
                event,
                newValue,
                'none',
                'aggravatedAssaultHomicide',
                setOffensesVictimFormState
              )
            }
            renderInput={(params) => (
              <TextField
                size="small"
                {...params}
                label="Aggravated Assault / Homicide"
                variant="outlined"
                fullWidth
              />
            )}
          />
        </Grid>
      </Grid>

      <Grid container justify="center" className="mb-2" spacing={2}>
        <Grid item xs={6} lg={6}>
          <TextField
            id="standard-textarea-victims-officerORI"
            size="small"
            label="Officer ORI"
            placeholder="Officer ORI"
            variant="outlined"
            fullWidth
            name="officerORI"
            key={changeKey}
            defaultValue={offensesVictimFormState.values.officerORI || ''}
            onBlur={(event) =>
              handleFormChangeNew(
                event,
                event.target.value,
                'none',
                'officerORI',
                setOffensesVictimFormState
              )
            }
            multiline
            InputLabelProps={{ shrink: true }}
          />
        </Grid>
        <Grid item xs={6} lg={6}>
          <RMSAutoComplete
            size="small"
            autoComplete
            autoSelect
            autoHighlight
            id="combo-officer-activity"
            options={codeOfficerActivity}
            // new ones
            serviceName="codeofficeractivity"
            label="Officer Activity / Circumstance"
            variant="outlined"
            title="Description"
            stateHolder={offensesVictimFormState}
            setStateHolder={setOffensesVictimFormState}
            setField={handleFormChangeNew}
            stateHolderAttribute="officerActivity"
          />
        </Grid>
      </Grid>

      <Grid container justify="center" className="mb-2" spacing={2}>
        <Grid item xs={6} lg={6}>
          <RMSAutoComplete
            size="small"
            autoComplete
            autoSelect
            autoHighlight
            id="combo-officer-assignment"
            options={codeOfficerAssignment}
            // new ones
            serviceName="codeofficerassignment"
            label="Officer Assignment"
            variant="outlined"
            title="Description"
            stateHolder={offensesVictimFormState}
            setStateHolder={setOffensesVictimFormState}
            setField={handleFormChangeNew}
            stateHolderAttribute="officerAssignment"
          />
        </Grid>
        <Grid item xs={6} lg={6}>
          <Autocomplete
            size="small"
            autoComplete
            autoSelect
            autoHighlight
            id="combo-search-addresses"
            getOptionLabel={(option) => option || ''}
            options={options.map((s) => s.name)}
            key={offensesVictimFormState.values.negligentManslaughter || ''}
            value={offensesVictimFormState.values.negligentManslaughter || ''}
            onChange={(event, newValue) =>
              handleFormChangeNew(
                event,
                newValue,
                'none',
                'negligentManslaughter',
                setOffensesVictimFormState
              )
            }
            renderInput={(params) => (
              <TextField
                size="small"
                {...params}
                label="Negligent Manslaughter"
                variant="outlined"
                fullWidth
              />
            )}
          />
        </Grid>
      </Grid>

      <Grid container justify="center" className="mb-2" spacing={2}>
        <Grid item xs={6} lg={6}>
          <Autocomplete
            size="small"
            autoComplete
            autoSelect
            autoHighlight
            id="combo-search-addresses"
            getOptionLabel={(option) => option || ''}
            options={options.map((s) => s.name)}
            key={offensesVictimFormState.values.justifiableHomicide || ''}
            value={offensesVictimFormState.values.justifiableHomicide || ''}
            onChange={(event, newValue) =>
              handleFormChangeNew(
                event,
                newValue,
                'none',
                'justifiableHomicide',
                setOffensesVictimFormState
              )
            }
            renderInput={(params) => (
              <TextField
                size="small"
                {...params}
                label="Justifiable Homicide"
                variant="outlined"
                fullWidth
              />
            )}
          />
        </Grid>
        <Grid item xs={6} lg={6}>
          <Autocomplete
            size="small"
            autoComplete
            autoSelect
            autoHighlight
            id="combo-search-addresses"
            getOptionLabel={(option) => option || ''}
            options={options.map((s) => s.name)}
            key={offensesVictimFormState.values.additionalJustifiableHomicide || ''}
            value={offensesVictimFormState.values.additionalJustifiableHomicide || ''}
            onChange={(event, newValue) =>
              handleFormChangeNew(
                event,
                newValue,
                'none',
                'additionalJustifiableHomicide',
                setOffensesVictimFormState
              )
            }
            renderInput={(params) => (
              <TextField
                size="small"
                {...params}
                label="Additional Justifiable Homicide"
                variant="outlined"
                fullWidth
              />
            )}
          />
        </Grid>
      </Grid>

      <Grid container justify="center" className="mb-2">
        <Grid item xs={12} lg={12}>
          <TextField
            size="small"
            className="mt-1"
            id="outlined-multiline-static-address-notes"
            label="Notes"
            placeholder="Notes"
            rows="4"
            fullWidth
            variant="outlined"
            key={changeKey}
            defaultValue={offensesVictimFormState.values?.notes || ''}
            onBlur={(event) =>
              handleFormChangeNew(
                event,
                event.target.value,
                'none',
                'notes',
                setOffensesVictimFormState
              )
            }
            multiline
            InputLabelProps={{ shrink: true }}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

const mapStateToProps = (state) => ({
  offensesVictimForm: state.incident.offensesVictimForm,
  wsClient: state.websocket.websocket,
  codeVictimTreatment: state.codeVictimTreatment,
  codeVictimDisposition: state.codeVictimDisposition,
  codeVictimCategory: state.codeVictimCategory,
  codeOfficerAssignment: state.codeOfficerAssignment,
  codeOfficerActivity: state.codeOfficerActivity,
  codeResident: state.codeResident,
  selectedVictim: state.incident.selectedVictim,
  incVictims: state.incident.incVictims,
});

export default connect(mapStateToProps, {
  getCodeVictimTreatment,
  getCodeVictimDisposition,
  getCodeVictimCategory,
  getCodeOfficerAssignment,
  getCodeOfficerActivity,
  getCodeResidence,
  setHandleAddIncVictim,
  updateIncVictimsDetails,
})(VictimsForm);
