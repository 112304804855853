export const GET_CODE_WEATHER = 'GET_CODE_WEATHER';
export const ADD_CODE_WEATHER = 'ADD_CODE_WEATHER';

export const addCodeWeather = (newVal) => {
  return { type: ADD_CODE_WEATHER, payload: newVal };
};

export const getCodeWeather = (wsClient) => {
  return async (dispatch) => {
    const service = wsClient.service('codeweather');
    service.timeout = 20000;

    const result = await service.find({
      query: {
        $limit: 0,
        $select: ['Code', 'Description'],
        $sort: {
          Description: 1,
        },
        IsDeleted: false,
      },
    });

    if (result.total > 0) {
      return dispatch({ type: GET_CODE_WEATHER, payload: result.data });
    }
  };
};

export default function reducer(state = [], action) {
  switch (action.type) {
    case GET_CODE_WEATHER:
      state = action.payload;
      return state;
    case ADD_CODE_WEATHER:
      return [...state, action.payload];
    default:
      return state;
  }
}
