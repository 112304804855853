import React from 'react';
import SimpleSchema from 'simpl-schema';
import { connect } from 'react-redux';

import Grid from '@material-ui/core/Grid';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import Divider from '@material-ui/core/Divider';

import { updateIncCoordinatesForm } from '../../reducers/IncReducer';
import { getCodeMeasures } from '../../reducers/dictionaries/CodeMeasuresReducer';
import { getCodeCoordinateDatumTypes } from '../../reducers/dictionaries/CodeOthersReducer';
import { handleFormChangeNew } from '../../reducers/helpers/formHelpers';
import RMSAutoComplete from 'components/RMSAutoComplete/RMSAutoComplete';

const validationContext = new SimpleSchema({
  altitude: SimpleSchema.Integer,
  altitudePor: SimpleSchema.Integer,
  speed: SimpleSchema.Integer,
  longitudeDegree: SimpleSchema.Integer,
  latitudeDegree: SimpleSchema.Integer,
}).newContext();

const signs = [{ name: '+' }, { name: '-' }];

const TabCoordinates = (props) => {
  /** region Component Variables and Props */
  const {
    coordinatesForm,
    updateIncCoordinatesForm,
    codeMeasures,
    getCodeMeasures,
    getCodeCoordinateDatumTypes,
    codeCoordinateDatumTypes,
    wsClient,
  } = props;
  /** endregion */
  /** region React Hooks */
  const [changeKey, setChangeKey] = React.useState(0);
  const [coordinatesFormState, setCoordinatesFormState] = React.useState({
    isValid: false,
    values: {},
    touched: {},
    errors: {},
  });
  const [firstLoad, setFirstLoad] = React.useState(true);
  const [errors, setErrors] = React.useState({});

  React.useEffect(() => {
    if (wsClient.websocket) {
      getCodeCoordinateDatumTypes();
    }
  }, [getCodeCoordinateDatumTypes, wsClient]);
  React.useEffect(() => {
    const services = ['codeMeasures'];

    if (!wsClient.websocket) return;

    services.forEach(async (svc) => {
      if (props[svc].length < 1) {
        const result = await wsClient.websocket.service(svc.toLowerCase()).find({
          query: {
            $limit: 0,
            $select: ['Code', 'Description'],
            IsDeleted: false,
          },
        });

        if (result.total > 0) {
          getCodeMeasures(result.data);
        }
      }
    });
  }, [wsClient]);

  React.useEffect(() => {
    setCoordinatesFormState({
      ...coordinatesFormState,
      ...coordinatesForm,
      changed: true,
      values: {
        ...coordinatesFormState.values,
        ...coordinatesForm.values,
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [coordinatesForm]);

  React.useEffect(() => {
    if (Object.keys(coordinatesFormState.values).length === 0) {
      return;
    }

    if (
      !firstLoad &&
      (coordinatesFormState.values?.altitude !== '' ||
        coordinatesFormState.values?.altitudePor !== '' ||
        coordinatesFormState.values?.speed !== '')
    ) {
      validationContext.validate({
        altitude:
          coordinatesFormState.values?.altitude?.length > 0
            ? Number(coordinatesFormState.values?.altitude)
            : 0,
        altitudePor:
          coordinatesFormState.values?.altitudePor?.length > 0
            ? Number(coordinatesFormState.values?.altitudePor)
            : 0,
        speed:
          coordinatesFormState.values?.speed?.length > 0
            ? Number(coordinatesFormState.values?.speed)
            : 0,
        longitudeDegree:
          coordinatesFormState.values?.longitudeDegree?.length > 0
            ? Number(coordinatesFormState.values?.longitudeDegree)
            : 0,
        latitudeDegree:
          coordinatesFormState.values?.latitudeDegree?.length > 0
            ? Number(coordinatesFormState.values?.latitudeDegree)
            : 0,
      });

      if (validationContext.isValid() === false) {
        const errs = validationContext.validationErrors();
        let e = {};
        errs.map((er) => {
          e[er.name] = `Value must be ${er.dataType}`;
          console.log(e);
          setErrors(e);
        });
      } else {
        setErrors({});
      }
    }
    setFirstLoad(false);

    updateIncCoordinatesForm(coordinatesFormState);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    coordinatesFormState.values.latitudeSign,
    coordinatesFormState.values.latitudeDegree,
    coordinatesFormState.values.longitudeSign,
    coordinatesFormState.values.longitudeDegree,
    coordinatesFormState.values.uncertaintyDistance,
    coordinatesFormState.values.datum,
    coordinatesFormState.values.coordinateDatetime,
    coordinatesFormState.values.altitude,
    coordinatesFormState.values.altitudePor,
    coordinatesFormState.values.altitudeUom,
    coordinatesFormState.values.cellTower,
    coordinatesFormState.values.sectorId,
    coordinatesFormState.values.speed,
    coordinatesFormState.values.speedUom,
  ]);
  /** endregion */
  return (
    <Grid container className="p-3">
      <Grid item xs={12}>
        <span style={{ color: '#fff', background: '#1976d2' }} className="m-1 badge badge-primary">
          Coordinates
        </span>
        <Divider />
      </Grid>
      <Grid container spacing={2} className="mt-2 mb-2">
        <Grid item xs={2}>
          <Autocomplete
            size="small"
            autoComplete
            autoSelect
            autoHighlight
            id="combo-latitude-sign"
            getOptionLabel={(option) => option || ''}
            options={signs.map((s) => s.name)}
            key={coordinatesFormState.values.latitudeSign || ''}
            value={coordinatesFormState.values.latitudeSign || ''}
            onChange={(event, newValue) =>
              handleFormChangeNew(event, newValue, 'none', 'latitudeSign', setCoordinatesFormState)
            }
            renderInput={(params) => (
              <TextField
                autoComplete="hidden"
                size="small"
                {...params}
                label="+/-"
                variant="outlined"
                fullWidth
              />
            )}
          />
        </Grid>
        <Grid item xs={4}>
          <TextField
            autoComplete="hidden"
            size="small"
            label="Degrees Latitude"
            placeholder="Degrees Latitude"
            variant="outlined"
            fullWidth
            name="latitudeDegree"
            error={errors['latitudeDegree'] ? true : false}
            helperText={errors['latitudeDegree']}
            key={changeKey}
            defaultValue={coordinatesFormState.values.latitudeDegree}
            onBlur={(event) =>
              handleFormChangeNew(
                event,
                event.target.value,
                'none',
                'latitudeDegree',
                setCoordinatesFormState
              )
            }
            multiline
            InputLabelProps={{ shrink: true }}
          />
        </Grid>
        <Grid item xs={2}>
          <Autocomplete
            size="small"
            autoComplete
            autoSelect
            autoHighlight
            id="combo-longitude-sign"
            getOptionLabel={(option) => option || ''}
            options={signs.map((s) => s.name)}
            key={coordinatesFormState.values.longitudeSign || ''}
            value={coordinatesFormState.values.longitudeSign || ''}
            onChange={(event, newValue) =>
              handleFormChangeNew(event, newValue, 'none', 'longitudeSign', setCoordinatesFormState)
            }
            renderInput={(params) => (
              <TextField
                autoComplete="hidden"
                size="small"
                {...params}
                label="+/-"
                variant="outlined"
                fullWidth
              />
            )}
          />
        </Grid>
        <Grid item xs={4}>
          <TextField
            autoComplete="hidden"
            size="small"
            label="Degrees Longitude"
            placeholder="Degrees Longitude"
            variant="outlined"
            error={errors['longitudeDegree'] ? true : false}
            helperText={errors['longitudeDegree']}
            fullWidth
            name="longitudeDegree"
            key={changeKey}
            defaultValue={coordinatesFormState.values.longitudeDegree}
            onBlur={(event) =>
              handleFormChangeNew(
                event,
                event.target.value,
                'none',
                'longitudeDegree',
                setCoordinatesFormState
              )
            }
            multiline
            InputLabelProps={{ shrink: true }}
          />
        </Grid>
      </Grid>
      <Grid container spacing={2} justify="center">
        <Grid item xs={4}>
          <TextField
            autoComplete="hidden"
            size="small"
            label="Uncertainty Distance"
            placeholder="Uncertainty Distance"
            variant="outlined"
            fullWidth
            name="uncertaintyDistance"
            key={changeKey}
            defaultValue={coordinatesFormState.values.uncertaintyDistance}
            onBlur={(event) =>
              handleFormChangeNew(
                event,
                event.target.value,
                'none',
                'uncertaintyDistance',
                setCoordinatesFormState
              )
            }
            multiline
            InputLabelProps={{ shrink: true }}
          />
        </Grid>
        <Grid item xs={4}>
          <RMSAutoComplete
            size="small"
            autoComplete
            autoSelect
            autoHighlight
            id="combo-datum"
            options={codeCoordinateDatumTypes}
            label="Datum"
            serviceName="codecoordinatedatumtypes"
            title="Code"
            stateHolderAttribute="datum"
            variant="outlined"
            stateHolder={coordinatesFormState}
            setStateHolder={setCoordinatesFormState}
            setField={handleFormChangeNew}
          />
        </Grid>
        <Grid item xs={4}>
          <Autocomplete
            size="small"
            autoComplete
            autoSelect
            autoHighlight
            id="combo-coordinate-datetime"
            getOptionLabel={(option) => option || ''}
            options={signs.map((s) => s.name)}
            key={coordinatesFormState.values.coordinateDatetime || ''}
            value={coordinatesFormState.values.coordinateDatetime || ''}
            onBlur={(event, newValue) =>
              handleFormChangeNew(
                event,
                newValue,
                'none',
                'coordinateDatetime',
                setCoordinatesFormState
              )
            }
            renderInput={(params) => (
              <TextField
                autoComplete="hidden"
                size="small"
                {...params}
                label="Coordinate Date Time"
                variant="outlined"
                fullWidth
              />
            )}
          />
        </Grid>
      </Grid>
      <Grid item xs={12} className="mt-2">
        <span style={{ color: '#fff', background: '#1976d2' }} className="m-1 badge badge-primary">
          Geographical Details
        </span>
        <Divider />
      </Grid>
      <Grid container justify="center" spacing={2} className="mt-2">
        <Grid item xs={4}>
          <TextField
            autoComplete="hidden"
            size="small"
            label="Altitude"
            placeholder="Altitude"
            error={errors['altitude'] ? true : false}
            helperText={errors['altitude']}
            variant="outlined"
            fullWidth
            name="altitude"
            key={changeKey}
            defaultValue={coordinatesFormState.values.altitude}
            onBlur={(event) =>
              handleFormChangeNew(
                event,
                event.target.value,
                'none',
                'altitude',
                setCoordinatesFormState
              )
            }
            multiline
            InputLabelProps={{ shrink: true }}
          />
        </Grid>
        <Grid item xs={4}>
          <TextField
            autoComplete="hidden"
            size="small"
            label="Altitude Point of Reference"
            placeholder="Altitude Point of Reference"
            error={errors['altitudePor'] ? true : false}
            helperText={errors['altitudePor']}
            variant="outlined"
            fullWidth
            name="altitudePor"
            key={changeKey}
            defaultValue={coordinatesFormState.values.altitudePor}
            onBlur={(event) =>
              handleFormChangeNew(
                event,
                event.target.value,
                'none',
                'altitudePor',
                setCoordinatesFormState
              )
            }
            multiline
            InputLabelProps={{ shrink: true }}
          />
        </Grid>
        <Grid item xs={4}>
          <RMSAutoComplete
            size="small"
            autoComplete
            autoSelect
            autoHighlight
            id="combo-altitude-uom"
            options={codeMeasures}
            label="Altitude Unit of Measure"
            serviceName="codemeasures"
            title="Code"
            stateHolderAttribute="altitudeUom"
            variant="outlined"
            stateHolder={coordinatesFormState}
            setStateHolder={setCoordinatesFormState}
            setField={handleFormChangeNew}
          />
        </Grid>
      </Grid>
      <Grid container justify="center" spacing={2} className="mt-2">
        <Grid item xs={3}>
          <TextField
            autoComplete="hidden"
            size="small"
            label="Cell Tower"
            placeholder="Cell Tower"
            variant="outlined"
            fullWidth
            name="cellTower"
            key={changeKey}
            defaultValue={coordinatesFormState.values.cellTower}
            onBlur={(event) =>
              handleFormChangeNew(
                event,
                event.target.value,
                'none',
                'cellTower',
                setCoordinatesFormState
              )
            }
            multiline
            InputLabelProps={{ shrink: true }}
          />
        </Grid>
        <Grid item xs={3}>
          <TextField
            autoComplete="hidden"
            size="small"
            label="Sector ID"
            placeholder="Sector ID"
            variant="outlined"
            fullWidth
            name="sectorId"
            key={changeKey}
            defaultValue={coordinatesFormState.values.sectorId}
            onBlur={(event) =>
              handleFormChangeNew(
                event,
                event.target.value,
                'none',
                'sectorId',
                setCoordinatesFormState
              )
            }
            multiline
            InputLabelProps={{ shrink: true }}
          />
        </Grid>
        <Grid item xs={3}>
          <TextField
            autoComplete="hidden"
            size="small"
            label="Speed"
            placeholder="Speed"
            variant="outlined"
            fullWidth
            name="speed"
            error={errors['speed'] ? true : false}
            helperText={errors['speed']}
            key={changeKey}
            defaultValue={coordinatesFormState.values.speed}
            onBlur={(event) =>
              handleFormChangeNew(
                event,
                event.target.value,
                'none',
                'speed',
                setCoordinatesFormState
              )
            }
            multiline
            InputLabelProps={{ shrink: true }}
          />
        </Grid>
        <Grid item xs={3}>
          <RMSAutoComplete
            size="small"
            autoComplete
            autoSelect
            autoHighlight
            id="combo-speed-uom"
            options={codeMeasures}
            label="Speed Unit of Measure"
            serviceName="codemeasures"
            title="Code"
            stateHolderAttribute="speedUom"
            variant="outlined"
            stateHolder={coordinatesFormState}
            setStateHolder={setCoordinatesFormState}
            setField={handleFormChangeNew}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

const mapStateToProps = (state) => ({
  wsClient: state.websocket,
  coordinatesForm: state.incident.coordinatesForm,
  codeMeasures: state.codeMeasures,
  codeCoordinateDatumTypes: state.codesOthers.codeCoordinateDatumTypes,
});

export default connect(mapStateToProps, {
  updateIncCoordinatesForm,
  getCodeMeasures,
  getCodeCoordinateDatumTypes,
})(TabCoordinates);
