import React from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTools, faCrosshairs, faFlask, faCar } from '@fortawesome/free-solid-svg-icons';
import { faGem, faBuilding } from '@fortawesome/free-regular-svg-icons';

import ListItem from '@material-ui/core/ListItem';
import List from '@material-ui/core/List';
import Menu from '@material-ui/core/Menu';
import Button from '@material-ui/core/Button';
import RMSAddButton from '../RMSButtons/RMSAddButton';
import makeStyles from '@material-ui/core/styles/makeStyles';

import {
  setHandleOffensesRedirectFormClose,
  setHandleAddIncProperty,
} from '../../reducers/ui/UiMenuReducer';
import {
  updateIncPropertyItemForm,
  updateIncPropertyFirearmForm,
  updateIncPropertyCredentialsAnalysisForm,
  updateIncPropertyVehicleDetailsForm,
  updateIncPropertySubstanceForm,
  updateIncPropertyStructureForm,
  updateIncPropertyJewelryForm,
  resetSubForms,
} from '../../reducers/IncReducer';

const useStyles = makeStyles(() => ({
  button: {
    padding: '0px !important',
    '&:hover': {
      background: 'transparent',
    },
    border: 'none',
    background: 'transparent',
  },
  menu: {
    width: '180px',
    padding: '0px !important',
    margin: '0px !important',
  },
}));

const PropertiesMenu = (props) => {
  const classes = useStyles();
  const history = useHistory();
  const [anchorElMenu, setAnchorElMenu] = React.useState(null);
  const {
    setHandleAddIncProperty,
    setHandleOffensesRedirectFormClose,
    isHandlingIncProperty,
    updateIncPropertyItemForm,
    updateIncPropertyFirearmForm,
    updateIncPropertyCredentialsAnalysisForm,
    updateIncPropertyVehicleDetailsForm,
    updateIncPropertySubstanceForm,
    updateIncPropertyStructureForm,
    updateIncPropertyJewelryForm,
  } = props;

  const handleClickMenu = (event) => {
    setAnchorElMenu(event.currentTarget);
    setHandleOffensesRedirectFormClose(true);
  };

  const handleCloseMenu = () => {
    setAnchorElMenu(null);
  };

  const onItemClicked = () => {
    if (isHandlingIncProperty) {
      setHandleAddIncProperty(true);
    }
    resetSubForms('add');
    updateIncPropertyItemForm({ isValid: false, values: {}, touched: {}, errors: {} });
    history.push('/incidents/add/item');
  };

  const onFirearmClicked = () => {
    if (isHandlingIncProperty) {
      setHandleAddIncProperty(true);
    }
    resetSubForms('add');
    updateIncPropertyFirearmForm({ isValid: false, values: {}, touched: {}, errors: {} });
    history.push('/incidents/add/firearm');
  };

  const onJewelryClicked = () => {
    if (isHandlingIncProperty) {
      setHandleAddIncProperty(true);
    }
    resetSubForms('add');
    updateIncPropertyJewelryForm({ isValid: false, values: {}, touched: {}, errors: {} });
    history.push('/incidents/add/jewelry');
  };

  const onSubstanceClicked = () => {
    if (isHandlingIncProperty) {
      setHandleAddIncProperty(true);
    }
    resetSubForms('add');
    updateIncPropertySubstanceForm({ isValid: false, values: {}, touched: {}, errors: {} });
    history.push('/incidents/add/substance');
  };

  const onStructureClicked = () => {
    if (isHandlingIncProperty) {
      setHandleAddIncProperty(true);
    }
    resetSubForms('add');
    updateIncPropertyStructureForm({ isValid: false, values: {}, touched: {}, errors: {} });
    history.push('/incidents/add/structure');
  };

  const onVehicleClicked = () => {
    if (isHandlingIncProperty) {
      setHandleAddIncProperty(true);
    }
    resetSubForms('add');
    updateIncPropertyVehicleDetailsForm({ isValid: false, values: {}, touched: {}, errors: {} });
    updateIncPropertyCredentialsAnalysisForm({
      isValid: false,
      values: {},
      touched: {},
      errors: {},
    });
    history.push('/incidents/add/vehicle');
  };

  return (
    <>
      <Button
        style={{ marginLeft: '-7px' }}
        onClick={handleClickMenu}
        variant="text"
        disableFocusRipple="true"
        disableRipple="true"
        className={classes.button}>
        <RMSAddButton tooltipText="Add Property" />
      </Button>
      <Menu
        className={classes.menu}
        anchorEl={anchorElMenu}
        keepMounted
        open={Boolean(anchorElMenu)}
        onClose={handleCloseMenu}
        classes={{ list: 'p-0' }}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}>
        <div className="overflow-hidden dropdown-menu-xl">
          <List className="flex-column">
            <ListItem button onClick={onItemClicked}>
              <div className="nav-link-icon opacity-6">
                <FontAwesomeIcon icon={faTools} />
              </div>
              <span>Item</span>
            </ListItem>

            <ListItem button onClick={onFirearmClicked}>
              <div className="nav-link-icon opacity-6">
                <FontAwesomeIcon icon={faCrosshairs} />
              </div>
              <span>Firearm</span>
            </ListItem>

            <ListItem button onClick={onJewelryClicked}>
              <div className="nav-link-icon opacity-6">
                <FontAwesomeIcon icon={faGem} />
              </div>
              <span>Jewelry</span>
            </ListItem>

            <ListItem button onClick={onStructureClicked}>
              <div className="nav-link-icon opacity-6">
                <FontAwesomeIcon icon={faBuilding} />
              </div>
              <span>Structure</span>
            </ListItem>

            <ListItem button onClick={onSubstanceClicked}>
              <div className="nav-link-icon opacity-6">
                <FontAwesomeIcon icon={faFlask} />
              </div>
              <span>Substance</span>
            </ListItem>

            <ListItem button onClick={onVehicleClicked}>
              <div className="nav-link-icon opacity-6">
                <FontAwesomeIcon icon={faCar} />
              </div>
              <span>Vehicle</span>
            </ListItem>
          </List>
        </div>
      </Menu>
    </>
  );
};

const mapStateToProps = (state) => ({
  offensesPropertyForm: state.incident.offensesPropertyForm,
});

export default connect(mapStateToProps, {
  setHandleOffensesRedirectFormClose,
  setHandleAddIncProperty,
  updateIncPropertyItemForm,
  updateIncPropertyFirearmForm,
  updateIncPropertyCredentialsAnalysisForm,
  updateIncPropertyVehicleDetailsForm,
  updateIncPropertySubstanceForm,
  updateIncPropertyStructureForm,
  updateIncPropertyJewelryForm,
})(PropertiesMenu);
