import React from 'react';
import EnhancedTableToolbar from '../RMSList/RMSIncidentList/EnhancedTableToolbar';
import clsx from 'clsx';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import DragAndDropContext from '../RMSDragAndDropCards/DragAndDropContext';
import Grid from '@material-ui/core/Grid';

const useToolbarStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },
  paper: {
    width: '100%',
  },
  board: {
    marginTop: theme.spacing(3),
  },
}));

const RMSIncidentBoard = (props) => {
  const classes = useToolbarStyles();
  return (
    <Grid container className={clsx(classes.root)} justify="center">
      <Paper className={classes.paper}>
        <Grid item xs={12} lg={12}>
          <EnhancedTableToolbar />
        </Grid>
        <Grid item xs={12} lg={12} className={classes.board}>
          <DragAndDropContext />
        </Grid>
      </Paper>
    </Grid>
  );
};

export default RMSIncidentBoard;
