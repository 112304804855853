import React from 'react';
import { useDispatch } from 'react-redux';

import { IconButton, TextField } from '@material-ui/core';
import { AddIcon } from 'evergreen-ui';

import {
  updateFormOpen,
  setModalServiceName,
  setModalSetTextFieldFunction,
} from 'reducers/AddEntityFormReducer';

export default function RMSComboBox(props) {
  const { label, serviceName, setField } = props;
  const dispatch = useDispatch();
  return (
    <div ref={props.InputProps.ref}>
      <TextField
        autoComplete="hidden"
        size="small"
        {...props}
        InputProps={{
          ...props.InputProps,
          startAdornment: (
            <IconButton style={{ margin: 0, padding: 0 }} aria-label="delete">
              <AddIcon
                fontSize="small"
                onClick={() => {
                  if (serviceName) dispatch(setModalServiceName(serviceName));
                  if (setField) dispatch(setModalSetTextFieldFunction(setField));
                  dispatch(updateFormOpen(label));
                }}
              />
            </IconButton>
          ),
        }}
      />
    </div>
  );
}
