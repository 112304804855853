import { Autocomplete } from '@material-ui/lab';
import RMSComboBox from 'components/RMSComboBox';
import RMSComboBoxListItem from 'components/RMSComboBoxListItem';
import React from 'react';
import { handleFormChangeNew } from 'reducers/helpers/formHelpers';

function RMSAutoComplete(props) {
  let {
    label,
    variant,
    serviceName,
    setField,
    title,
    stateHolder,
    stateHolderAttribute,
    setStateHolder,
    error,
    helperText,
  } = props;
  serviceName = serviceName.toLowerCase();
  if (!setStateHolder) {
    setStateHolder = handleFormChangeNew;
  }

  return (
    <Autocomplete
      size="small"
      {...props}
      key={stateHolder.values.category || ''}
      value={{ [title]: stateHolder.values[stateHolderAttribute] } || ''}
      getOptionLabel={(option) => option[title] || ''}
      onChange={(event, newValue) =>
        setField(
          event,
          newValue ? newValue[title] : '',
          'none',
          stateHolderAttribute,
          setStateHolder
        )
      }
      renderOption={(option) => {
        return (
          <RMSComboBoxListItem
            option={option}
            title={title}
            serviceName={serviceName}
            label={label}
            resetField={() => setField(null, null, 'none', stateHolderAttribute, setStateHolder)}
          />
        );
      }}
      renderInput={(params) => (
        <RMSComboBox
          error={error}
          helperText={helperText}
          {...params}
          serviceName={serviceName}
          label={label}
          FormHelperTextProps={{
            style: { color: 'red' },
          }}
          setField={(newVal) => {
            setField(null, newVal[title], 'none', stateHolderAttribute, setStateHolder);
          }}
          variant={variant || 'outlined'}
          fullWidth
        />
      )}
    />
  );
}

export default RMSAutoComplete;
