import React from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';


import ListItem from '@material-ui/core/ListItem';
import List from '@material-ui/core/List';
import Menu from '@material-ui/core/Menu';
import Button from '@material-ui/core/Button';
import makeStyles from '@material-ui/core/styles/makeStyles';

import { VictimsMenu } from '../RMSVerticalMenu';
import RMSAddButton from '../RMSButtons/RMSAddButton';
import {
  setHandleOffensesRedirectFormClose,
  setHandleAddIncProperty,
} from '../../reducers/ui/UiMenuReducer';
import { resetSubForms } from '../../reducers/IncReducer';

const useStyles = makeStyles(() => ({
  button: {
    padding: '0px !important',
    '&:hover': {
      background: 'transparent',
    },
    marginLeft: '-7px !important',
    border: 'none',
    background: 'transparent',
  },
  menu: {
    padding: '0px !important',
    margin: '0px !important',
    width: '180px',
  },
}));

const PartiesMenu = (props) => {
  const classes = useStyles();
  const history = useHistory();
  const [anchorElMenu, setAnchorElMenu] = React.useState(null);
  const { setHandleOffensesRedirectFormClose } = props;

  const handleClickMenu = (event) => {
    setAnchorElMenu(event.currentTarget);
    setHandleOffensesRedirectFormClose(true);
  };

  const handleCloseMenu = () => {
    setAnchorElMenu(null);
  };

  const onOffenderClicked = () => {
    resetSubForms('add');
    history.push('/incidents/add/offenders');
  };

  const onOtherClicked = () => {
    resetSubForms('add');
    history.push('/incidents/add/others');
  };

  return (
    <>
      <Button
        style={{ marginLeft: '-7px' }}
        onClick={handleClickMenu}
        variant="text"
        disableFocusRipple="true"
        disableRipple="true"
        className={classes.button}>
        <RMSAddButton tooltipText="Add Party" />
      </Button>
      <Menu
        className={classes.menu}
        anchorEl={anchorElMenu}
        keepMounted
        open={Boolean(anchorElMenu)}
        onClose={handleCloseMenu}
        classes={{ list: 'p-0' }}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}>
        <div className="overflow-hidden dropdown-menu-xl">
          <List className="flex-column">
            <ListItem button onClick={onOffenderClicked}>
              <div className="nav-link-icon opacity-6">
                <FontAwesomeIcon icon="user" />
              </div>
              <span>Offender</span>
            </ListItem>

            <ListItem button>
              <VictimsMenu />
            </ListItem>

            <ListItem button onClick={onOtherClicked}>
              <div className="nav-link-icon opacity-6">
                <FontAwesomeIcon icon="users" />
              </div>
              <span>Other</span>
            </ListItem>
          </List>
        </div>
      </Menu>
    </>
  );
};

export default connect(null, {
  setHandleOffensesRedirectFormClose,
  setHandleAddIncProperty,
})(PartiesMenu);
