import { Fab, Tooltip } from '@material-ui/core';
import React from 'react';
import DescriptionOutlinedIcon from '@material-ui/icons/DescriptionOutlined';

export default function RMSEventsButton(props) {
  return (
    <Tooltip title={props.tooltipText}>
      <Fab style={{ marginLeft: '10px' }} size="small" color="secondary" aria-label="events">
        <DescriptionOutlinedIcon />
      </Fab>
    </Tooltip>
  );
}
