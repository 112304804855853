import React from 'react';
import Grid from '@material-ui/core/Grid';
import { connect } from 'react-redux';
import { updateIncOffensesDVAdditionalForm } from '../../../../reducers/IncReducer';
import Checkbox from '@material-ui/core/Checkbox';
import { handleFormChangeNew } from '../../../../reducers/helpers/formHelpers';
import FormControlLabel from '@material-ui/core/FormControlLabel';

const VictimGivenForm = (props) => {
  const {
    offensesDVAdditionalForm,
    updateIncOffensesDVAdditionalForm,
    dvAdditionals,
    selectedVictim,
  } = props;

  const [offensesDVAdditionalFormState, setOffensesDVAdditionalFormState] = React.useState({
    isValid: false,
    changes: true,
    values: {},
    touched: {},
    errors: {},
  });

  React.useEffect(() => {
    async function fetch() {
      let dvAdditionalDetails = dvAdditionals.find(
        (dvAdditional) => dvAdditional.ptsIncPersonId === selectedVictim
      );
      if (dvAdditionalDetails) {
        await updateIncOffensesDVAdditionalForm(dvAdditionalDetails);
        await setOffensesDVAdditionalFormState(dvAdditionalDetails);
      }
    }
    // Fetching current selected victim's dv details
    fetch();
  }, []);

  React.useEffect(() => {
    setOffensesDVAdditionalFormState({
      ...offensesDVAdditionalFormState,
      values: { ...offensesDVAdditionalFormState.values, ...offensesDVAdditionalForm.values },
    });
  }, [offensesDVAdditionalForm]);

  React.useEffect(() => {
    offensesDVAdditionalFormState.changes = true;
    updateIncOffensesDVAdditionalForm(offensesDVAdditionalFormState);
  }, [
    offensesDVAdditionalFormState.values.IsReferralToDVAssistance,
    offensesDVAdditionalFormState.values.IsDVInformation,
    offensesDVAdditionalFormState.values.IsCaseNumber,
  ]);

  return (
    <Grid container spacing={3} justify="center">
      <FormControlLabel
        control={
          <Checkbox
            key={offensesDVAdditionalFormState.values?.IsCaseNumber}
            checked={offensesDVAdditionalFormState.values?.IsCaseNumber}
            onChange={(event) =>
              handleFormChangeNew(
                event,
                event.target.checked,
                'none',
                'IsCaseNumber',
                setOffensesDVAdditionalFormState
              )
            }
            name="IsCaseNumber"
          />
        }
        label="Case Number"
      />

      <FormControlLabel
        control={
          <Checkbox
            key={offensesDVAdditionalFormState.values?.IsDVInformation}
            checked={offensesDVAdditionalFormState.values?.IsDVInformation}
            onChange={(event) =>
              handleFormChangeNew(
                event,
                event.target.checked,
                'none',
                'IsDVInformation',
                setOffensesDVAdditionalFormState
              )
            }
            name="IsDVInformation"
          />
        }
        label="Domestic Violence Information"
      />

      <FormControlLabel
        control={
          <Checkbox
            key={offensesDVAdditionalFormState.values?.IsReferralToDVAssistance}
            checked={offensesDVAdditionalFormState.values?.IsReferralToDVAssistance}
            onChange={(event) =>
              handleFormChangeNew(
                event,
                event.target.checked,
                'none',
                'IsReferralToDVAssistance',
                setOffensesDVAdditionalFormState
              )
            }
            name="IsReferralToDVAssistance"
          />
        }
        label="Referral to Domestic Violence Assistance"
      />
    </Grid>
  );
};

const mapStateToProps = (state) => ({
  offensesDVAdditionalForm: state.incident.offensesDVAdditionalForm,
  selectedVictim: state.incident.selectedVictim,
  dvAdditionals: state.incident.dvAdditionals,
});

export default connect(mapStateToProps, {
  updateIncOffensesDVAdditionalForm,
})(VictimGivenForm);
