import store from '../../config/configureStore';
import { incVictimSchema } from 'simpl-schema-validation/schema';

export const validateIncVictimsForm = (field, form) => {
  const currentState = store.store.getState().incident;
  const { offenses, offensesOffenseForm, currentOffense } = currentState;

  let victim = form;

  let victimOffense = offenses.find((o) =>
    o.offenseDetails.ptsOffenseId === victim.ptsOffenseId ? victim.ptsOffenseId : currentOffense
  );

  let objToValidate = {
    [field]: victim.values[field],
    incVictim: form,
    offense: victimOffense?.offenseDetails || offensesOffenseForm,
    incident: currentState.incident,
  };

  let incVictimsValidationContext = incVictimSchema.newContext();
  let result = incVictimsValidationContext.validate(objToValidate, {
    keys: [field],
  });

  let incVictimsErrors = [];
  if (!result) {
    incVictimsValidationContext.validationErrors().forEach((error) => {
      if (field === error.name) {
        error.message = incVictimsValidationContext.keyErrorMessage(error.name);
        incVictimsErrors.push(error);
      }
    });
  }

  return incVictimsErrors || [];
};
