import store from '../config/configureStore';
import { enqueueSnackbar } from './NotifierReducer';
import { updateIfNewVersionAvail } from './AppInfoReducer';

// User
export const USER_SET_AUTHENTICATED = 'USER/SET_AUTHENTICATED';
export const USER_SET_DATA = 'USER/SET_DATA';

export const submitLoginForm = (username, password) => {
  const client = store.store.getState().websocket.websocket;
  return async (dispatch) => {
    try {
      const user = await client.authenticate({
        strategy: 'local',
        username: username,
        password: password,
      });
      dispatch({ type: USER_SET_DATA, payload: user });
      dispatch({ type: USER_SET_AUTHENTICATED, payload: true });
      dispatch(updateIfNewVersionAvail());
    } catch (error) {
      dispatch(
        enqueueSnackbar({
          message: 'Login failed. Please try again.',
          options: {
            key: new Date().getTime() + Math.random(),
            variant: 'warning',
            anchorOrigin: {
              vertical: 'top',
              horizontal: 'center',
            },
          },
        })
      );
      dispatch({ type: USER_SET_AUTHENTICATED, payload: false });
    }
  };
};

export const setUserAuthenticated = (state) => {
  const client = store.store.getState().websocket.websocket;
  return (dispatch) => {
    if (client) {
      if (state === false) {
        client.logout();
      }
    }
    dispatch({ type: USER_SET_AUTHENTICATED, payload: state });
  };
};

export const setUserData = (data) => {
  return (dispatch) => {
    dispatch({ type: USER_SET_DATA, payload: data });
  };
};

export default function reducer(
  state = {
    // User
    isAuthenticated: false,
    userData: null,
  },
  action
) {
  switch (action.type) {
    // User
    case USER_SET_AUTHENTICATED:
      return {
        ...state,
        isAuthenticated: action.payload,
      };
    case USER_SET_DATA:
      return {
        ...state,
        userData: action.payload,
      };
    default:
      break;
  }
  return state;
}
