import store from '../../config/configureStore';

import { partiesSchema } from 'simpl-schema-validation/schema';

export const validateParties = () => {
  const currentState = store.store.getState().incident;
  const { parties, incVictims, offenses, incSubjects } = currentState;

  let validationErrors = [];

  for (let party of parties) {
    // TODO: will refactor this
    let name = '';
    if (party.PersonId) {
      name += `${party.PersonId}` + ' | ';
    }
    if (party.personDetail.values.lastName) {
      name += `${party.personDetail.values.lastName}`;
    }
    name += ', ';
    if (party.personDetail.values.firstName) {
      name += party.personDetail.values.firstName;
    }
    name += ' ';
    if (party.personDetail.values.middleName) {
      name += party.personDetail.values.middleName;
    }

    let victim = incVictims.find(
      (incVictim) => incVictim.incVictimDetails.ptsIncPersonId === party.ptsIncPersonId
    );

    let victimOffense = offenses.find(
      (offense) => offense.offenseDetails.ptsOffenseId === victim?.incVictimDetails.ptsOffenseId
    );

    let offender = incSubjects.find(
      (incSubject) => incSubject.incSubjectDetails.ptsIncPersonId === party.ptsIncPersonId
    );

    let subjectOffense = offenses.find(
      (offense) => offense.offenseDetails.ptsOffenseId === offender?.incSubjectDetails.ptsOffenseId
    );

    let objToValidate = {
      age: party.personDetail.values.age,
      sex: party.personDetail.values.sex,
      race: party.personDetail.values.race,
      ethnicity: party.personDetail.values.ethnicity,
      victim: victim ? victim.incVictimDetails : null,
      victimOffense: victimOffense ? victimOffense.offenseDetails : null,
      party: party.personDetail,
      offender: offender ? offender.incSubjectDetails : null,
      subjectOffense: subjectOffense ? subjectOffense.offenseDetails : null,
    };

    let keys = ['age', 'party', 'sex', 'race', 'ethnicity'];

    let isArrestee = party.personDetail.values.isArrestee || false;

    if (isArrestee) {
      objToValidate = {
        ...objToValidate,
        arresteeAge: party.personDetail.values.age,
        arresteeRace: party.personDetail.values.race,
        arresteeSex: party.personDetail.values.sex,
      };
      keys = ['age', 'arresteeAge', 'arresteeRace', 'arresteeSex', 'sex'];
    }

    let partiesValidationContext = partiesSchema.newContext();
    let result = partiesValidationContext.validate(objToValidate, {
      keys: keys,
    });

    if (!result) {
      partiesValidationContext.validationErrors().forEach((error) => {
        validationErrors.push({
          id: party.ptsIncPersonId,
          idType: 'ptsIncPersonId',
          errType: error.type,
          field: error.name,
          name,
          message: partiesValidationContext.keyErrorMessage(error.name),
          ptsIncidentId: currentState.ptsIncidentId,
          partyType: party.personDetail.values.partyType,
        });
      });
    }
  }

  return validationErrors;
};
