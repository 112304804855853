import store from '../../config/configureStore';

import { incSubjectVictimRelationshipSchema } from 'simpl-schema-validation/schema';
import { generateHandleEditOffense } from 'simpl-schema-validation/helpers/generateHandleEdit';

export const validateIncRelationships = () => {
  const currentState = store.store.getState().incident;
  const { incRelationships, parties, incVictims, offenses } = currentState;
  const relations = [];
  const validatedVictims = [];

  incRelationships.forEach((relationship) => {
    relations.push(relationship.incRelationshipDetails?.values);
  });

  let validationErrors = [];

  for (let incRelationship of incRelationships) {
    let victimPerson = parties.find(
      (p) =>
        p.ptsIncPersonId === incRelationship.incRelationshipDetails?.values?.relationship?.victimId
    );

    let offense = offenses.find(
      (offense) =>
        offense.offenseDetails.ptsOffenseId === incRelationship.incRelationshipDetails.ptsOffenseId
    );

    let incVictimPerson = incVictims.find(
      (victim) =>
        victim?.incVictimDetails?.ptsIncPersonId ===
        incRelationship.incRelationshipDetails?.values?.relationship?.victimId
    );

    let victimFullName = `${victimPerson.PersonId} | ${
      victimPerson.personDetail.values.lastName || ''
    }, ${victimPerson.personDetail.values.firstName || ''} ${
      victimPerson.personDetail.values.middleName || ''
    }`;

    let victim = {
      victimId: incRelationship.incRelationshipDetails?.relationship?.victimId,
      age: victimPerson.personDetail.values.age,
      category: incVictimPerson.incVictimDetails.values.category,
    };

    let subjectPerson = parties.find(
      (p) =>
        p.ptsIncPersonId ===
        incRelationship.incRelationshipDetails?.values?.relationship?.offenderId
    );

    let subject = {
      offenderId: incRelationship.incRelationshipDetails?.relationship?.offenderId,
      age: subjectPerson.personDetail.values.age,
    };

    let subjectFullName = `${subjectPerson.PersonId} | ${
      subjectPerson.personDetail.values.lastName || ''
    }, ${subjectPerson.personDetail.values.firstName || ''} ${
      subjectPerson.personDetail.values.middleName || ''
    }`;

    let objToValidate = {
      victim,
      subject,
      offense,
      offenderDetails: subjectPerson.personDetail.values,
      relationship: incRelationship.incRelationshipDetails?.values?.relationship?.relationship,
      incident: currentState.incident,
    };
    let keys = ['relationship'];

    if (
      !validatedVictims.includes(
        incRelationship.incRelationshipDetails?.values?.relationship?.victimId
      )
    ) {
      let victimOffenders = [];
      let victimSpouses = [];

      for (let relation of relations) {
        if (
          relation.relationship?.victimId ===
          incRelationship.incRelationshipDetails?.values?.relationship?.victimId
        ) {
          victimOffenders.push(relation.relationship?.offenderId);
          if (relation.relationship?.relationship === 'NIBRS CODE: SE | Victim Was Spouse') {
            victimSpouses.push(relation.relationship?.offenderId);
          }
        }
      }

      objToValidate = {
        ...objToValidate,
        victimOffenders,
        victimSpouses,
      };

      keys = [...keys, 'victimOffenders', 'victimSpouses'];

      validatedVictims.push(incRelationship.incRelationshipDetails?.values?.relationship?.victimId);
    }

    let incSubjectVictimRelationshipValidationContext = incSubjectVictimRelationshipSchema.newContext();
    let result = incSubjectVictimRelationshipValidationContext.validate(objToValidate, {
      keys,
    });

    if (!result) {
      incSubjectVictimRelationshipValidationContext.validationErrors().forEach((error) => {
        validationErrors.push({
          victimId: incRelationship.incRelationshipDetails?.relationship?.victimId,
          offenderId: incRelationship.incRelationshipDetails?.relationship?.offenderId,
          idType: 'incRelationship',
          errType: error.type,
          field: error.name,
          name: `Victim ${victimFullName} - Offender ${subjectFullName}`,
          message: incSubjectVictimRelationshipValidationContext.keyErrorMessage(error.name),
          ptsIncidentId: currentState.ptsIncidentId,
          ptsOffenseId: incRelationship.incRelationshipDetails.ptsOffenseId,
          handleEdit: generateHandleEditOffense(
            currentState.ptsIncidentId,
            incRelationship.incRelationshipDetails.ptsOffenseId,
            3
          ),
        });
      });
    }
  }

  return validationErrors;
};
