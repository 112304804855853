import React, { Fragment, useEffect, useState, useRef } from 'react';
import clsx from 'clsx';
// import AgencyIcon from '../../../../RMSIcons/AgencyIcon';
import DirectionsCarIcon from '@material-ui/icons/DirectionsCar';
import { makeStyles } from '@material-ui/core/styles';
import {
  getTime,
  formatDate,
  sortObjArr,
  // getPassedTime,
  // minsToHours,
} from '../../../../../utils/functions';
import { Tooltip } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import { AiFillPushpin } from 'react-icons/ai';
import { AiOutlinePushpin } from 'react-icons/ai';
import AgencyIcon from 'components/RMSIcons/AgencyIcon';
import HtmlTooltip from 'components/HtmlTooltip';

const iconWidth = 50;
const headerWidth = 185;
const statusWidth = 90;
const zoneWidth = 65;
const locationWidth = 150;
const timeWidth = 75;
const rightWidth = zoneWidth * 2;
const leftWidth = locationWidth + timeWidth + statusWidth + iconWidth + headerWidth - 10;

const useStyles = makeStyles((theme) => ({
  unit: {
    cursor: 'grab',
    display: 'flex',
    overflow: 'hidden',
    alignItems: 'center',
    justifyContent: 'space-between',
    position: 'relative',
    // backgroundColor: theme.card.bg,
    '& > div': {
      display: 'flex',
      alignItems: 'center',
      padding: '5px 0',
    },
    '&:hover': {
      // background: theme.card.bgHover,
      overflowX: 'auto',
      '& $menuBtn > div': {
        // background: theme.card.bgHover,
      },
    },
    '&.unit-selected': {
      // background: theme.colors.active,
      '& $menuBtn > div': {
        // background: theme.colors.grey2,
      },
    },
    '&.unit-selected:hover': {
      '& $menuBtn > div': {
        // background: theme.colors.grey2,
      },
    },
    borderBottom: '1px solid #D3D3D3',
    color: theme.palette.type === 'light' ? 'rgba(0, 0, 0, 0.87)' : '#d4d6d7',
  },
  left: {
    width: `calc(100% - ${rightWidth}px)`,
    minWidth: leftWidth,
  },
  right: {
    width: `${rightWidth}px`,
    flexDirection: 'row-reverse',
  },
  icon: {
    flex: `0 0 ${iconWidth}px`,
    textAlign: 'center',
    '& > div': {
      height: 20,
      width: 20,
      display: 'inline-block',
    },
  },
  headerTd: {
    flex: `0 0 ${headerWidth}px`,
    width: headerWidth,
  },
  header: {
    '& h5': {
      fontSize: '0.85rem',
      marginBottom: 0,
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      '& svg': {
        fontSize: 20,
        marginTop: -4,
      },
    },
    '& p': {
      fontWeight: 500,
      opacity: 0.7,
      marginBottom: 0,
      fontSize: '0.8rem',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
  },
  statusTd: {
    flex: `0 0 ${statusWidth + 5}px`,
    width: statusWidth + 5,
    boxSizing: 'border-box',
  },
  menuBtn: {
    position: 'absolute',
    right: 0,
    top: 0,
    '& > div': {
      // background: theme.card.bg,
      borderTopLeftRadius: '50%',
      borderBottomLeftRadius: '50%',
    },
  },
  status: {
    textAlign: 'center',
    cursor: 'pointer',
    '& > div': {
      fontSize: '1rem',
      fontWeight: 500,
      padding: '0 5px',
      // background: theme.card.bg1,
      borderRadius: 5,
    },
    '& i': {
      padding: '2px 10px 1px',
      borderRadius: '4px',
      display: 'inline-block',
      textTransform: 'uppercase',
      fontWeight: 500,
      fontSize: 12,
      fontStyle: 'normal',
      width: statusWidth,
    },
  },
  eventTimes: {
    display: 'flex',
    justifyContent: 'space-between',
    fontWeight: 500,
    fontSize: '0.8rem',
    opacity: 0.75,
  },
  timeTd: {
    flex: `0 0 ${timeWidth}px`,
    width: timeWidth,
    textAlign: 'center',
  },
  locationTd: {
    fontSize: 12,
    textAlign: 'center',
    flex: `1 0 ${locationWidth}px`,
    width: locationWidth,
    boxSizing: 'border-box',
    whiteSpace: 'initial',
    display: '-webkit-box',
    '-webkit-line-clamp': 2,
    '-webkit-box-orient': 'vertical',
    overflow: 'hidden',
    maxHeight: '3em',
  },
  stat: {
    display: 'inline-block',
    border: '1px solid #333',
    borderRadius: 4,
    padding: '1px 5px 0',
  },
  updateTime: {
    cursor: 'pointer',
  },
  timeIsUp: {
    color: theme.palette.error.main,
    fontWeight: 500,
  },
  zone: {
    textAlign: 'center',
    width: zoneWidth,
  },
  OutOfZone: {
    color: theme.palette.error.main,
  },
  noOfCalls: {
    minWidth: 20,
    '& > span': {
      // background: theme.colors.grey2,
      textAlign: 'center',
      borderRadius: '50%',
      minWidth: 20,
      display: 'inline-block',
    },
    marginLeft: 8,
  },
}));

function Unit(props) {
  const classes = useStyles();
  const [statusHiostory, setStatusHistory] = useState([]);
  const [lastActionTime, setLastActionTime] = useState(null);
  const [passedTime, setPassedTime] = useState('');
  const [Resources, setResources] = useState([]);
  const {
    unit,
    selected,
    colorPalette,
    selection,
    ptsEventID,
    togglePin,
    pinned,
    parent,
    /* showMenu, */
  } = props;
  const {
    AgencyID,
    Unit,
    UnitStatus,
    UnitStatuses,
    LastActionOccurred,
    Flags,
    Events,
    OutOfZone,
    Division,
    UnitResources,
    Zone,
    Location,
    ptsUnitID,
    LastActionPlate,
    Plate,
    NoOfCalls,
  } = unit;

  console.log(colorPalette);
  // const timeIsUp = unitsNeedAttention.indexOf(ptsUnitID) !== -1;
  const plate = Plate ? Plate : LastActionPlate;
  const mobile = Flags && Flags.trim() === 'M' ? true : false;
  const [statusMenuOpen, setStatusMenuOpen] = useState(false);
  const [timer, setTimer] = useState(0);
  const UnitColors = colorPalette.Units;
  const statusColor = UnitColors[UnitStatus];
  const statusMenuRef = useRef();

  useEffect(() => {
    let timer = 0;
    const interval = setInterval(() => {
      setTimer(timer++);
    }, 30000);

    return () => {
      clearInterval(interval);
    };
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (!props.resources) return;
    const Resources = props.resources[ptsUnitID];
    if (Resources) setResources(Resources);
    // eslint-disable-next-line
  }, [props.resources]);

  useEffect(() => {
    let history = [];
    if (UnitStatuses) {
      UnitStatuses.forEach((status) => {
        if (status.Unit === Unit) {
          history.push(status);
        }
      });
      history = sortObjArr(history, 'Occurred', 'ASC');
    }
    let lastActionTime;
    if (LastActionOccurred) {
      lastActionTime = LastActionOccurred;
    } else {
      if (history.length > 0) {
        lastActionTime = history[0].Occurred;
      } else {
        lastActionTime = null;
      }
    }
    setLastActionTime(lastActionTime);
    setStatusHistory(history);
    // eslint-disable-next-line
  }, [UnitStatuses]);

  // useEffect(() => {
  //   const passedTime = getPassedTime(lastActionTime);
  //   setPassedTime(minsToHours(passedTime));
  //   // eslint-disable-next-line
  // }, [timer, lastActionTime, UnitStatus]);

  const renderTime = () => {
    const getStatuses = () => {
      return (
        <Fragment>
          <h4>Units History</h4>
          <table>
            <tbody>
              {statusHiostory.map((status, idx) => {
                return (
                  <tr key={idx}>
                    <td>{status.UnitStatus}</td>
                    <td>{formatDate(status.Occurred)}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </Fragment>
      );
    };

    const Html = (
      <div className={classes.historyTooltip}>
        <h4>Unit</h4>
        <table>
          <tbody>
            <tr>
              <th>Last update</th>
              <td>{formatDate(LastActionOccurred)}</td>
            </tr>
          </tbody>
        </table>
        {statusHiostory.length > 0 && getStatuses()}
      </div>
    );

    return (
      <div className={classes.updateTime}>
        <HtmlTooltip title={Html}>
          <span>{getTime(lastActionTime)}</span>
        </HtmlTooltip>
        {/* <Tooltip title="Last action time [hrs:mins]">
          <div className={timeIsUp ? classes.timeIsUp : classes.passedTime}>{passedTime}</div>
        </Tooltip> */}
      </div>
    );
  };

  const renderHeader = () => {
    return (
      <Tooltip unit={unit} Resources={Resources}>
        <div>
          <div className={classes.headerTd}>
            <div className={classes.header}>
              <div className={classes.unitId}>
                <h5>
                  {mobile && <DirectionsCarIcon />} {Unit} {Division && <span> | {Division}</span>}
                </h5>
                <p>{UnitResources}</p>
              </div>
            </div>
          </div>
        </div>
      </Tooltip>
    );
  };

  const renderStatus = () => (
    <div className={classes.statusTd} onClick={showUnitStatusMenu} ref={statusMenuRef}>
      <div className={classes.status}>
        <i style={{ background: statusColor, color: '#000' }}>{UnitStatus}</i>
      </div>
    </div>
  );

  const renderNoOfCalls = () => {
    const CallsNo = (
      <div className={classes.noOfCalls}>{!!NoOfCalls && <span>{NoOfCalls}</span>}</div>
    );
    if (Events && Events.length) {
      const tooltipHtml = (
        <div className={classes.historyTooltip}>
          <h4>Attached Events</h4>
          <table>
            <tbody>
              {Events.map((event) => (
                <tr key={event.ptsEventID}>
                  <td>{event.EventID}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      );
      return <HtmlTooltip title={tooltipHtml}>{CallsNo}</HtmlTooltip>;
    } else {
      return CallsNo;
    }
  };

  const dragStart = () => {
    const currentSelection = selection ? [...selection] : [];
    if (currentSelection.indexOf(ptsUnitID) === -1) {
      currentSelection.push(ptsUnitID);
    }
    const dragObj = {
      units: currentSelection,
      ptsEventID: ptsEventID !== undefined ? ptsEventID : null,
    };
    localStorage.setItem('dragNdrop', JSON.stringify(dragObj));
  };

  const dragEnd = () => {
    localStorage.removeItem('dragNdrop');
  };

  const showUnitStatusMenu = (ev) => {
    ev.stopPropagation();
    setStatusMenuOpen(true);
  };

  const showUnitProps = (ev) => {
    ev.stopPropagation();
    props.showUnitProps(unit.ptsUnitID);
  };

  return (
    <>
      <div
        className={clsx(
          classes.unit,
          'thin-horizontal-scrollbar',
          selected ? 'unit-selected' : null
        )}
        draggable="true"
        onDragStart={dragStart}
        onDragEnd={dragEnd}
        onClick={props.onClick}>
        <div className={classes.left}>
          <div className={classes.icon}>
            <div>
              <AgencyIcon color={statusColor} AgencyID={AgencyID} />
            </div>
          </div>
          {renderHeader()}
          {renderStatus()}
          {renderNoOfCalls()}
          <div className={classes.timeTd}>{renderTime()}</div>
          <div className={classes.locationTd}>{Location}</div>
        </div>
        <div className={classes.right}>
          <div className={clsx(classes.zone, OutOfZone ? classes.OutOfZone : null)}>
            <small onClick={showUnitProps}>Zone</small>
            <br /> {Zone !== null ? <span>{Zone}</span> : <span>-</span>}
          </div>
          <div className={classes.zone}>
            <small>Plate</small>
            <br />
            {plate === null || plate === undefined ? (
              <span>-</span>
            ) : (
              <span style={{ fontSize: '0.7rem' }}>{plate}</span>
            )}
          </div>
        </div>
        {parent === 'unit-list' && (
          <Tooltip title={pinned ? 'Unpin' : 'Pin'}>
            <IconButton onClick={togglePin}>
              {pinned && <AiFillPushpin />}
              {!pinned && <AiOutlinePushpin />}
            </IconButton>
          </Tooltip>
        )}
      </div>
    </>
  );
}

export default Unit;
