import SimpleSchema from 'simpl-schema';
import { codeVictimCategory } from 'reducers/_dev/codeVictimCategory';

SimpleSchema.setDefaultMessages({
  messages: {
    en: {
      'NIBRS-2019.2-127':
        'When a Person is a victim and the Relationship to the Offender indicates Spouse then the Victim Age must be 13 or greater.',
      'NIBRS-2019.2-149':
        'A Victim cannot be associated to a Offender when the Victim Type is not (I,L).',
      'NIBRS-2019.2-154': `When the Age of Offender is 00, Sex of Offender is 'U', and Race of Offender is 'U', then Victim Subject Relationship Code must be 'Relationship Unknown.`,
      'NIBRS-2019.2-150':
        'When the Offense Victim Association contains Offense UCR Code of (Crime Against Person or Crime Against Property), then the same Victim must have a Subject Victim Association.',
      'NIBRS-2019.2-148': 'A Victim can be associated with up to ten (10) different Offenders.',
      'NIBRS-2019.2-152': 'A Victim can only have one spousal relationship with an Offender.',
    },
  },
});

const getVictimCategory = (codeVictimCategory, victimCategory) =>
  codeVictimCategory.find((category) => category.description === victimCategory);

export const incSubjectVictimRelationshipSchema = new SimpleSchema({
  relations: {
    type: Array,
    optional: true,
    custom() {
      const incVictims = this.value.map((relation) => relation.relationship.victimId);
      const incOffenders = this.value.map((relation) => relation.relationship.offenderId);
      let incVictimRelations = {};

      // NIBRS-2019.2-148: Offender Number
      // NIBRS-2019.2-152: Offender Number
      this.value.forEach((relation) => {
        let victimId = relation.relationship.victimId;
        if (!incVictimRelations[victimId]) {
          incVictimRelations = {
            ...incVictimRelations,
            [victimId]: {
              offenders: [],
              spouses: [],
            },
          };
        }
        incVictimRelations[victimId].offenders.push(relation.relationship.offenderId);
        if (relation.relationship.relationship === 'NIBRS CODE: SE | Victim Was Spouse') {
          incVictimRelations[victimId].spouses.push(relation.relationship.offenderId);
        }
      });
      let offendersCountErrorFlag = 0;
      let spousesCountErrorFlag = 0;
      for (let victimIdKey of Object.keys(incVictimRelations)) {
        if (incVictimRelations[victimIdKey].offenders.length > 10) {
          offendersCountErrorFlag = victimIdKey;
        }
        if (incVictimRelations[victimIdKey].spouses.length > 1) {
          spousesCountErrorFlag = victimIdKey;
        }
      }
      if (offendersCountErrorFlag) {
        return 'NIBRS-2019.2-148';
      }
      if (spousesCountErrorFlag) {
        return 'NIBRS-2019.2-152';
      }
    },
  },
  'relations.$': {
    type: Object,
    optional: true,
    blackbox: true,
    custom() {},
  },
  victimOffenders: {
    type: Array,
    optional: true,
    custom() {
      if (this.value.length > 10) {
        return 'NIBRS-2019.2-148';
      }
    },
  },
  'victimOffenders.$': {
    type: SimpleSchema.Integer,
    optional: true,
    custom() {},
  },
  victimSpouses: {
    type: Array,
    optional: true,
    custom() {
      if (this.value.length > 1) {
        return 'NIBRS-2019.2-152';
      }
    },
  },
  'victimSpouses.$': {
    type: SimpleSchema.Integer,
    optional: true,
    custom() {},
  },
  relationship: {
    type: String,
    label: 'NIBRS-2019.2-127: Person Age',
    optional: true,
    custom() {
      if (this.value === 'NIBRS CODE: SE | Victim Was Spouse') {
        if (this.obj.victim.age < 13) return 'NIBRS-2019.2-127';
      }

      // NIBRS-2019.2-149: Offender Number
      if (
        !['I', 'L'].includes(getVictimCategory(codeVictimCategory, this.obj.victim.category)?.code)
      ) {
        if (this.value) {
          return 'NIBRS-2019.2-149';
        }
      }
      //NIBRS-2019.2-150: Offender Number
      if (
        this.obj.offense.values?.statuteDetails?.FBICode === '09A' ||
        this.obj.offense.values?.statuteDetails?.FBICode === '09B' ||
        this.obj.offense.values?.statuteDetails?.FBICode === '09C' ||
        this.obj.offense.values?.statuteDetails?.FBICode === '100' ||
        this.obj.offense.values?.statuteDetails?.FBICode === '11A' ||
        this.obj.offense.values?.statuteDetails?.FBICode === '11B' ||
        this.obj.offense.values?.statuteDetails?.FBICode === '11C' ||
        this.obj.offense.values?.statuteDetails?.FBICode === '11D' ||
        this.obj.offense.values?.statuteDetails?.FBICode === '13A' ||
        this.obj.offense.values?.statuteDetails?.FBICode === '13B' ||
        this.obj.offense.values?.statuteDetails?.FBICode === '13C' ||
        this.obj.offense.values?.statuteDetails?.FBICode === '36A' ||
        this.obj.offense.values?.statuteDetails?.FBICode === '36B' ||
        this.obj.offense.values?.statuteDetails?.FBICode === '64A' ||
        this.obj.offense.values?.statuteDetails?.FBICode === '64B' ||
        this.obj.offense.values?.statuteDetails?.FBICode === '90Z' ||
        this.obj.offense.values?.statuteDetails?.FBICode === '200' ||
        this.obj.offense.values?.statuteDetails?.FBICode === '510' ||
        this.obj.offense.values?.statuteDetails?.FBICode === '220' ||
        this.obj.offense.values?.statuteDetails?.FBICode === '250' ||
        this.obj.offense.values?.statuteDetails?.FBICode === '290' ||
        this.obj.offense.values?.statuteDetails?.FBICode === '270' ||
        this.obj.offense.values?.statuteDetails?.FBICode === '210' ||
        this.obj.offense.values?.statuteDetails?.FBICode === '26A' ||
        this.obj.offense.values?.statuteDetails?.FBICode === '26B' ||
        this.obj.offense.values?.statuteDetails?.FBICode === '26C' ||
        this.obj.offense.values?.statuteDetails?.FBICode === '26D' ||
        this.obj.offense.values?.statuteDetails?.FBICode === '26E' ||
        this.obj.offense.values?.statuteDetails?.FBICode === '26F' ||
        this.obj.offense.values?.statuteDetails?.FBICode === '26G' ||
        this.obj.offense.values?.statuteDetails?.FBICode === '23A' ||
        this.obj.offense.values?.statuteDetails?.FBICode === '23B' ||
        this.obj.offense.values?.statuteDetails?.FBICode === '23C' ||
        this.obj.offense.values?.statuteDetails?.FBICode === '23D' ||
        this.obj.offense.values?.statuteDetails?.FBICode === '23E' ||
        this.obj.offense.values?.statuteDetails?.FBICode === '23F' ||
        this.obj.offense.values?.statuteDetails?.FBICode === '23G' ||
        this.obj.offense.values?.statuteDetails?.FBICode === '23H' ||
        this.obj.offense.values?.statuteDetails?.FBICode === '240' ||
        this.obj.offense.values?.statuteDetails?.FBICode === '120' ||
        this.obj.offense.values?.statuteDetails?.FBICode === '280' ||
        this.obj.offense.values?.statuteDetails?.FBICode === '90A' ||
        this.obj.offense.values?.statuteDetails?.FBICode === '90Z'
      ) {
        if (this.value === undefined || this.value === null) {
          return 'NIBRS-2019.2-150';
        }
      }

      //NIBRS-2019.2-154: Relationship to Victim
      if (
        this.obj.offenderDetails.age === 0 &&
        this.obj.offenderDetails.race === 'UNKNOWN' &&
        this.obj.offenderDetails.sex === 'UNKNOWN'
      ) {
        if (this.value !== 'NIBRS CODE: RU | Relationship Unknown') {
          return 'NIBRS-2019.2-154';
        }
      }
    },
  },
});
