export const GET_CODE_SUBJECT_INVOLVEMENT = 'GET_CODE_SUBJECT_INVOLVEMENT';
export const ADD_CODE_SUBJECT_INVOLVEMENT = 'ADD_CODE_SUBJECT_INVOLVEMENT___';

export const addCodeSubjectInvolvement = (newValue) => {
  return { type: ADD_CODE_SUBJECT_INVOLVEMENT, payload: newValue };
};

export const getCodeSubjectInvolvement = (state) => {
  return async (dispatch) => {
    return dispatch({ type: GET_CODE_SUBJECT_INVOLVEMENT, payload: state });
  };
};

export default function reducer(state = [], action) {
  switch (action.type) {
    case GET_CODE_SUBJECT_INVOLVEMENT:
      state = action.payload;
      return state;
    case ADD_CODE_SUBJECT_INVOLVEMENT:
      return [...state, action.payload];
    default:
      return state;
  }
}
