import React from 'react';
import { connect } from 'react-redux';

import { v4 as uuidv4 } from 'uuid';
import Grid from '@material-ui/core/Grid';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Checkbox from '@material-ui/core/Checkbox';
import TextField from '@material-ui/core/TextField';
import makeStyles from '@material-ui/core/styles/makeStyles';
import FormControlLabel from '@material-ui/core/FormControlLabel';

import { setSelectedSubject, updateIncSubjectsDetails } from '../../../../reducers/IncReducer';
import { getCodeSubjectInvolvement } from '../../../../reducers/dictionaries/CodeSubjectInvolvementReducer';
import { getCodeSubjectMO } from '../../../../reducers/dictionaries/CodeSubjectMOReducer';
import { getCodeSubjectStatus } from '../../../../reducers/dictionaries/CodeSubjectStatusReducer';
import { getCodeSubjectWeapon } from '../../../../reducers/dictionaries/CodeSubjectWeaponReducer';
import { getCodeDNACollection } from '../../../../reducers/dictionaries/CodeOthersReducer';

import { handleFormChangeNew } from '../../../../reducers/helpers/formHelpers';
import RMSComboBox from 'components/RMSComboBox';
import RMSComboBoxListItem from 'components/RMSComboBoxListItem';

const useStyles = makeStyles((theme) => ({
  header: {
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(3),
  },
  title: {
    color: 'grey',
  },
  icon: {
    marginRight: theme.spacing(2),
  },
}));

const OffendersForm = (props) => {
  /** region Component Variables and Props */
  const {
    codeSubjectWeapon,
    codeSubjectStatus,
    codeSubjectMO,
    codeSubjectInvolvement,
    getCodeSubjectWeapon,
    getCodeSubjectStatus,
    getCodeSubjectMO,
    getCodeSubjectInvolvement,
    getCodeDNACollection,
    codeDNACollection,
    offensesOffenseForm,
    selectedSubject,
    wsClient,
    incSubjects,
    updateIncSubjectsDetails,
    setSelectedSubject,
  } = props;
  /** endregion */
  /** region React Hooks */
  const [changeKey, setChangeKey] = React.useState(0);
  const [offensesOffendersFormState, setOffensesOffendersFormState] = React.useState({
    tempIncSubjectId: uuidv4(),
    isValid: false,
    values: {},
    touched: {},
    errors: {},
  });

  React.useEffect(() => {
    if (offensesOffenseForm.ptsOffenseId) {
      setOffensesOffendersFormState({
        ...offensesOffendersFormState,
        ptsOffenseId: offensesOffenseForm.ptsOffenseId,
      });
    } else {
      setOffensesOffendersFormState({
        ...offensesOffendersFormState,
        tempOffenseId: offensesOffenseForm.tempOffenseId,
      });
    }
    return () => setSelectedSubject('');
  }, []);

  React.useEffect(() => {
    if (wsClient.websocket) {
      getCodeDNACollection();
    }
  }, [getCodeDNACollection, wsClient]);

  React.useEffect(() => {
    const services = [
      'codeSubjectWeapon',
      'codeSubjectStatus',
      'codeSubjectMO',
      'codeSubjectInvolvement',
    ];

    if (!wsClient.websocket) return;

    services.forEach(async (svc) => {
      if (props[svc].length < 1) {
        const result = await wsClient.websocket.service(svc.toLowerCase()).find({
          query: {
            $limit: 0,
            $select: ['Code', 'Description'],
            $sort: {
              Code: 1,
            },
            IsDeleted: false,
          },
        });

        if (result.total > 0) {
          if (svc === 'codeSubjectWeapon') {
            getCodeSubjectWeapon(result.data);
          } else if (svc === 'codeSubjectStatus') {
            getCodeSubjectStatus(result.data);
          } else if (svc === 'codeSubjectMO') {
            getCodeSubjectMO(result.data);
          } else if (svc === 'codeSubjectInvolvement') {
            getCodeSubjectInvolvement(result.data);
          }
        }
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [wsClient]);

  const updateIncDetailsList = () => {
    updateIncSubjectsDetails(offensesOffendersFormState);
  };

  const getIncFormData = () => {
    setOffensesOffendersFormState({ ...offensesOffendersFormState, values: {} });

    let selectedSubjectDetails = incSubjects.find(
      (x) => x.incSubjectDetails.ptsIncPersonId === selectedSubject
    );

    if (selectedSubjectDetails && selectedSubjectDetails.incSubjectDetails) {
      setOffensesOffendersFormState(selectedSubjectDetails.incSubjectDetails);
    }
  };

  React.useEffect(() => {
    if (props.updated !== null) updateIncDetailsList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.updated]);

  React.useEffect(() => {
    getIncFormData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedSubject]);

  /** endregion */
  return (
    <Grid container justify="center" className="mt-3">
      <Grid container justify="center" className="mb-2" spacing={2}>
        <Grid item>
          <FormControlLabel
            control={
              <Checkbox
                key={offensesOffendersFormState.values?.armed}
                checked={offensesOffendersFormState.values?.armed}
                onChange={(event) =>
                  handleFormChangeNew(
                    event,
                    event.target.checked,
                    'none',
                    'armed',
                    setOffensesOffendersFormState
                  )
                }
                name="armed"
              />
            }
            label="Armed"
          />
        </Grid>
        <Grid item>
          <FormControlLabel
            control={
              <Checkbox
                key={offensesOffendersFormState.values?.extradition}
                checked={offensesOffendersFormState.values?.extradition}
                onChange={(event) =>
                  handleFormChangeNew(
                    event,
                    event.target.checked,
                    'none',
                    'extradition',
                    setOffensesOffendersFormState
                  )
                }
                name="extradition"
              />
            }
            label="Extradition"
          />
        </Grid>
        <Grid item>
          <FormControlLabel
            control={
              <Checkbox
                key={offensesOffendersFormState.values?.unknown}
                checked={offensesOffendersFormState.values?.unknown}
                onChange={(event) =>
                  handleFormChangeNew(
                    event,
                    event.target.checked,
                    'none',
                    'unknown',
                    setOffensesOffendersFormState
                  )
                }
                name="unknown"
              />
            }
            label="Unknown"
          />
        </Grid>
      </Grid>
      <Grid container justify="center" className="mb-2" spacing={2}>
        <Grid item xs={6} lg={6}>
          <Autocomplete
            size="small"
            autoComplete
            autoSelect
            autoHighlight
            id="combo-dna-collection"
            getOptionLabel={(option) => option.Code || ''}
            options={codeDNACollection}
            key={offensesOffendersFormState.values?.dnaCollection || ''}
            value={{ Code: offensesOffendersFormState.values?.dnaCollection } || ''}
            onChange={(event, newValue) =>
              handleFormChangeNew(
                event,
                newValue?.Code,
                'none',
                'dnaCollection',
                setOffensesOffendersFormState
              )
            }
            renderOption={(option) => {
              return (
                <RMSComboBoxListItem
                  option={option}
                  title={'Code'}
                  label="DNA Collection"
                  serviceName="codednacollection"
                  resetField={() =>
                    handleFormChangeNew(
                      null,
                      null,
                      'none',
                      'dnaCollection',
                      setOffensesOffendersFormState
                    )
                  }
                />
              );
            }}
            renderInput={(params) => (
              <RMSComboBox
                {...params}
                setField={(newVal) => {
                  handleFormChangeNew(
                    null,
                    newVal['Code'],
                    'none',
                    'dnaCollection',
                    setOffensesOffendersFormState
                  );
                }}
                label="DNA Collection"
                serviceName="codednacollection"
                variant="outlined"
                fullWidth
              />
            )}
          />
        </Grid>
        <Grid item xs={6} lg={6}>
          <Autocomplete
            size="small"
            autoComplete
            autoSelect
            autoHighlight
            id="combo-subject-involvement"
            getOptionLabel={(option) => option.Code || ''}
            options={codeSubjectInvolvement}
            key={offensesOffendersFormState.values?.involvement || ''}
            value={{ Code: offensesOffendersFormState.values?.involvement } || ''}
            onChange={(event, newValue) =>
              handleFormChangeNew(
                event,
                newValue?.Code,
                'none',
                'involvement',
                setOffensesOffendersFormState
              )
            }
            renderOption={(option) => {
              return (
                <RMSComboBoxListItem
                  option={option}
                  title="Code"
                  label="Offender Involvement"
                  serviceName="codesubjectinvolvement"
                  resetField={() =>
                    handleFormChangeNew(
                      null,
                      null,
                      'none',
                      'involvement',
                      setOffensesOffendersFormState
                    )
                  }
                />
              );
            }}
            renderInput={(params) => (
              <RMSComboBox
                {...params}
                setField={(newVal) => {
                  handleFormChangeNew(
                    null,
                    newVal['Code'],
                    'none',
                    'involvement',
                    setOffensesOffendersFormState
                  );
                }}
                serviceName="codesubjectinvolvement"
                label="Offender Involvement"
                variant="outlined"
                fullWidth
              />
            )}
          />
        </Grid>
      </Grid>
      <Grid container justify="center" className="mb-2" spacing={2}>
        <Grid item xs={6} lg={6}>
          <Autocomplete
            size="small"
            autoComplete
            autoSelect
            autoHighlight
            id="combo-subject-mo"
            getOptionLabel={(option) => option.Code || ''}
            options={codeSubjectMO}
            key={offensesOffendersFormState.values?.mo || ''}
            value={{ Code: offensesOffendersFormState.values?.mo } || ''}
            onChange={(event, newValue) =>
              handleFormChangeNew(
                event,
                newValue?.Code,
                'none',
                'mo',
                setOffensesOffendersFormState
              )
            }
            renderOption={(option) => {
              return (
                <RMSComboBoxListItem
                  option={option}
                  title="Code"
                  serviceName="codesubjectmo"
                  label="Offender MO"
                  resetField={() =>
                    handleFormChangeNew(null, null, 'none', 'mo', setOffensesOffendersFormState)
                  }
                />
              );
            }}
            renderInput={(params) => (
              <RMSComboBox
                {...params}
                setField={(newVal) => {
                  handleFormChangeNew(
                    null,
                    newVal['Code'],
                    'none',
                    'mo',
                    setOffensesOffendersFormState
                  );
                }}
                serviceName="codesubjectmo"
                label="Offender MO"
                variant="outlined"
                fullWidth
              />
            )}
          />
        </Grid>
        <Grid item xs={6} lg={6}>
          <Autocomplete
            size="small"
            autoComplete
            autoSelect
            autoHighlight
            id="combo-subject-status"
            getOptionLabel={(option) => option.Code || ''}
            options={codeSubjectStatus}
            key={offensesOffendersFormState.values?.status || ''}
            value={{ Code: offensesOffendersFormState.values?.status } || ''}
            onChange={(event, newValue) =>
              handleFormChangeNew(
                event,
                newValue?.Code,
                'none',
                'status',
                setOffensesOffendersFormState
              )
            }
            renderOption={(option) => {
              return (
                <RMSComboBoxListItem
                  option={option}
                  title="Code"
                  serviceName="codesubjectstatus"
                  label="Offender Status"
                  resetField={() =>
                    handleFormChangeNew(null, null, 'none', 'status', setOffensesOffendersFormState)
                  }
                />
              );
            }}
            renderInput={(params) => (
              <RMSComboBox
                {...params}
                setField={(newVal) => {
                  handleFormChangeNew(
                    null,
                    newVal['Code'],
                    'none',
                    'status',
                    setOffensesOffendersFormState
                  );
                }}
                serviceName="codesubjectstatus"
                label="Offender Status"
                variant="outlined"
                fullWidth
              />
            )}
          />
        </Grid>
      </Grid>
      <Grid container justify="center" className="mb-2">
        <Grid item xs={12} lg={12}>
          <Autocomplete
            size="small"
            autoComplete
            autoSelect
            autoHighlight
            id="combo-subject-weapon"
            getOptionLabel={(option) => option.Code || ''}
            options={codeSubjectWeapon}
            key={offensesOffendersFormState.values?.weapon || ''}
            value={{ Code: offensesOffendersFormState.values?.weapon } || ''}
            onChange={(event, newValue) =>
              handleFormChangeNew(
                event,
                newValue?.Code,
                'none',
                'weapon',
                setOffensesOffendersFormState
              )
            }
            renderOption={(option) => {
              return (
                <RMSComboBoxListItem
                  option={option}
                  title="Code"
                  serviceName="codesubjectweapon"
                  label="Offender Weapon"
                  resetField={() =>
                    handleFormChangeNew(null, null, 'none', 'weapon', setOffensesOffendersFormState)
                  }
                />
              );
            }}
            renderInput={(params) => (
              <RMSComboBox
                {...params}
                serviceName="codesubjectweapon"
                label="Offender Weapon"
                variant="outlined"
                fullWidth
              />
            )}
          />
        </Grid>
      </Grid>
      <Grid container justify="center" className="mb-2">
        <Grid item xs={12} lg={12}>
          <TextField
            size="small"
            className="mt-3"
            id="outlined-multiline-static-address-notes"
            label="Notes"
            placeholder="Notes"
            multiline
            rows="4"
            fullWidth
            name="notes"
            key={changeKey}
            defaultValue={offensesOffendersFormState.values?.notes || ''}
            onBlur={(event) =>
              handleFormChangeNew(
                event,
                event.target.value,
                'none',
                'notes',
                setOffensesOffendersFormState
              )
            }
            variant="outlined"
            InputLabelProps={{ shrink: true }}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

const mapStateToProps = (state) => ({
  offensesOffenseForm: state.incident.offensesOffenseForm,
  offensesOffendersForm: state.incident.offensesOffendersForm,
  codeDNACollection: state.codesOthers.codeDNACollection,
  wsClient: state.websocket,
  codeSubjectWeapon: state.codeSubjectWeapon,
  codeSubjectStatus: state.codeSubjectStatus,
  codeSubjectMO: state.codeSubjectMO,
  codeSubjectInvolvement: state.codeSubjectInvolvement,
  selectedSubject: state.incident.selectedSubject,
  incSubjects: state.incident.incSubjects,
});

export default connect(mapStateToProps, {
  getCodeSubjectWeapon,
  getCodeSubjectStatus,
  getCodeSubjectMO,
  getCodeSubjectInvolvement,
  getCodeDNACollection,
  updateIncSubjectsDetails,
  setSelectedSubject,
})(OffendersForm);
