import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import RMSIncidentList from '../../components/RMSList/RMSIncidentList';
import { setMenuType } from '../../reducers/ui/UiMenuReducer';
import RMSIncidentBoard from '../../components/RMSIncidentBoard';
import { setActiveIncidents } from '../../reducers/IncReducer';
import { resetTncFormActiveStepAndTab } from '../../reducers/ui/UiFormReducer';

const useStyles = makeStyles((theme) => ({
  pageWrap: {
    height: '1000px',
    '& h3': {
      marginTop: 20,
    },
  },
}));

const PageRMSIncidents = (props) => {
  const classes = useStyles();
  const {
    setMenuType,
    layoutState,
    setActiveIncidents,
    activeIncidents,
    resetTncFormActiveStepAndTab,
  } = props;

  useEffect(() => {
    setMenuType('');
    setActiveIncidents();

    resetTncFormActiveStepAndTab();
  }, []);

  return (
    <div className={classes.pageWrap}>
      <div>
        <div>
          {layoutState === 'list' && <RMSIncidentList />}
          {layoutState === 'board' && <RMSIncidentBoard />}
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  layoutState: state.uiLayout.layoutState,
  activeIncidents: state.incident.activeIncidents,
});

export default connect(mapStateToProps, {
  setMenuType,
  setActiveIncidents,
  resetTncFormActiveStepAndTab,
})(PageRMSIncidents);
