import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import RMSEventList from '../../components/RMSList/RMSEventList';
import { setMenuType } from '../../reducers/ui/UiMenuReducer';
import { getEvents } from '../../reducers/IncReducer';
import { resetTncFormActiveStepAndTab } from '../../reducers/ui/UiFormReducer';

const useStyles = makeStyles((theme) => ({
  pageWrap: {
    height: '1000px',
    '& h3': {
      marginTop: 20,
    },
  },
}));

const PageRMSIncidents = (props) => {
  const classes = useStyles();
  const { setMenuType, layoutState, getEvents, events, resetTncFormActiveStepAndTab } = props;

  useEffect(() => {
    setMenuType('');
    getEvents();

    resetTncFormActiveStepAndTab();
  }, []);

  return (
    <div className={classes.pageWrap}>
      <div>
        <div>
          <RMSEventList />
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  layoutState: state.uiLayout.layoutState,
  getEvents: state.incident.events,
});

export default connect(mapStateToProps, {
  setMenuType,
  getEvents,
  resetTncFormActiveStepAndTab,
})(PageRMSIncidents);
