import React, { Fragment } from 'react';
import TableCell from '@material-ui/core/TableCell';
import Avatar from '@material-ui/core/Avatar';
import Typography from '@material-ui/core/Typography';
import { colors } from '../../../../config/colorVariables';
import makeStyles from '@material-ui/core/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: colors.cyan,
    marginRight: theme.spacing(2),
  },
  incidentLabel: {
    padding: '3px 0px',
  },
}));

const RMSNarrativeCell = (props) => {
  const classes = useStyles();
  const { row } = props;

  return (
    <Fragment>
      <TableCell key={row.disposition}>
        <td>
          <div className="d-flex align-items-center">
            <Avatar aria-label="avatar" className={classes.avatar}>
              {row.disposition}
            </Avatar>
            <div>
              <Typography variant="h6" className="d-block">
                {row.disposition}
              </Typography>
            </div>
          </div>
        </td>
      </TableCell>
      <TableCell key={row.updated}>{row.updated}</TableCell>
      <TableCell key={row.updatedBy}>{row.updatedBy}</TableCell>
    </Fragment>
  );
};

export default RMSNarrativeCell;
