import React, { useEffect, useState } from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

import TabScrollButton from '@material-ui/core/TabScrollButton';
import { sortObjArr } from '../../../../utils/functions';

const useStyles = makeStyles((theme) => ({
  label: {
    '& span': {
      display: 'inline-block',
      textAlign: 'center',
      width: '1.5em',
      minHeight: '1.5em',
      borderRadius: '1.5em',
      color: theme.palette.secondary.main,
      lineHeight: 1.5,
      marginLeft: 5,
    },
  },
  sort: {
    fontSize: 18,
    marginRight: 5,
    opacity: 0.5,
    transition: 'opacity 0.3s',
    marginLeft: -5,
    '&:hover': {
      opacity: 1,
    },
  },
}));

const MyTabScrollButton = withStyles((theme) => ({
  root: {
    width: 28,
    overflow: 'hidden',
    transition: 'width 0.5s',
    '&.Mui-disabled': {
      width: 0,
    },
  },
}))(TabScrollButton);

const MenuTabs = withStyles((theme) => ({
  root: {
    minHeight: 30,
  },
  indicator: {
    backgroundColor: '#1890ff',
  },
}))(Tabs);

const MenuTab = withStyles((theme) => ({
  root: {
    minWidth: 72,
    fontWeight: theme.typography.fontWeightRegular,
    marginRight: theme.spacing(1),
    padding: '0 12px',
    fontSize: 12,
    minHeight: 30,
    '&:hover': {
      opacity: 1,
    },
    '&$selected': {
      fontWeight: theme.typography.fontWeightMedium,
    },
    '&:focus': {},
  },
  selected: {},
}))((props) => <Tab disableRipple {...props} />);

export default function EventTabMenu(props) {
  const classes = useStyles();
  const { menuOptions, toggleMenuOptions, value, setValue, event } = props;
  const [menu, setMenu] = useState([]);

  useEffect(() => {
    const menu = [];
    Object.keys(menuOptions).forEach((key) => {
      menu.push({ name: key, ...menuOptions[key] });
    });
    setMenu(sortObjArr(menu, 'seq', 'ASC'));
    // eslint-disable-next-line
  }, []);

  const handleChange = (ev, newValue) => {
    console.log(newValue);
    setValue(newValue);
  };

  const getOptionLength = (label) => {
    let length = 0;
    switch (label) {
      case 'Units':
        length = event.assignedUnits?.length;
        break;
      case 'Dispositions':
        length = event.dispositions?.length;
        break;
      case 'Notes':
        length = event.notes?.length;
        break;
      case 'Attachments':
        if (event.attachments) {
          const attachments = JSON.parse(event.attachments);
          length = attachments?.length;
        }
        break;
      case 'History':
        if (event.UnitStatuses) {
          const UnitStatuses = JSON.parse(event.UnitStatuses);
          length = UnitStatuses?.length;
        }
        break;
      case 'Case IDs':
        if (event.CaseIds) {
          const CaseIds = JSON.parse(event.CaseIds);
          length = CaseIds?.length;
        }
        break;
      case 'Routing':
        if (event.EventRouting) {
          const EventRouting = JSON.parse(event.EventRouting);
          length = EventRouting?.length;
        }
        break;
      case 'Parties':
        length =
          event.parties?.callers?.length +
          event.parties?.persons?.length +
          event.parties?.places?.length;
        break;
      case 'Zones':
        length = event.zones?.length;
        break;
      case 'Notifications':
        length = event.pagingHistoryCount;
        break;
      case 'Locations':
        length = event.locationCount;
        break;
      case 'Vehicles':
        length = event.VehicleCount;
        break;
      default:
    }
    return length;
  };

  const getLabel = (label) => {
    const length = getOptionLength(label);
    if (length > 0) {
      const { sort } = menuOptions[label];
      return (
        <div className={classes.label}>
          {/* {sort === 'ASC' && (
            <ArrowDropDownIcon className={classes.sort} onClick={() => toggleMenuOptions(label)} />
          )}
          {sort === 'DESC' && (
            <ArrowDropUpIcon className={classes.sort} onClick={() => toggleMenuOptions(label)} />
          )} */}
          {label}
          <span>{length}</span>
        </div>
      );
    } else {
      return label;
    }
  };

  console.log(value);

  return (
    <MenuTabs
      value={value}
      onChange={handleChange}
      indicatorColor="primary"
      textColor="primary"
      variant="scrollable"
      scrollButtons="auto"
      ScrollButtonComponent={MyTabScrollButton}>
      {menu.map((tab, i) => (
        <MenuTab key={tab.name} label={getLabel(tab.name)} value={i} />
      ))}
    </MenuTabs>
  );
}
