import React from 'react';
import { Link } from 'react-router-dom';
import { connect, useDispatch } from 'react-redux';

import clsx from 'clsx';
import DateFnsUtils from '@date-io/date-fns';
import Toolbar from '@material-ui/core/Toolbar';
import Grid from '@material-ui/core/Grid';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import SearchIcon from '@material-ui/icons/Search';
import TextField from '@material-ui/core/TextField';
import grey from '@material-ui/core/colors/grey';
import { lighten, makeStyles } from '@material-ui/core/styles';

import RMSAddButton from '../../RMSButtons/RMSAddButton';
import RMSEventsButton from '../../RMSButtons/RMSEventsButton';
import { setIncFormActiveStep } from '../../../reducers/ui/UiFormReducer';
import { setLayoutState } from '../../../reducers/ui/UiLayoutReducer';
import { resetAllEntitiesList } from '../../../reducers/ui/UiListReducer';
import { setSearchKeyword, resetAllForms } from '../../../reducers/IncReducer';
import { setShowIncidentStepper } from '../../../reducers/ui/UiMenuReducer';

const useToolbarStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },
  highlight:
    theme.palette.type === 'light'
      ? {
          color: theme.palette.secondary.main,
          backgroundColor: lighten(theme.palette.secondary.light, 0.85),
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark,
        },
  title: {
    flex: '1 1 100%',
  },
  selectList: {
    color: grey[700],
    marginRight: '3px',
  },
  selectBoard: {
    color: grey[700],
    marginRight: '3px',
  },
}));

const EnhancedTableToolbar = (props) => {
  const classes = useToolbarStyles();
  const dispatch = useDispatch();
  const {
    searchKeyword,
    setIncFormActiveStep,
    setSearchKeyword,
    setDateRange,
    resetAllEntitiesList,
  } = props;
  const [selectedFromDate, setSelectedFromDate] = React.useState(null);
  const [selectedToDate, setSelectedToDate] = React.useState(null);

  const handleOnClick = () => {
    dispatch(setShowIncidentStepper(true));
    setIncFormActiveStep(0);
    resetAllForms();
    resetAllEntitiesList();
  };

  const handleFilterChange = (e, type) => {
    let currentSearchKeywords = searchKeyword;

    setSearchKeyword({
      ...currentSearchKeywords,
      incidentKeyword: {
        ...currentSearchKeywords.incidentKeyword,
        [type]: e.target.value,
      },
    });
  };

  React.useEffect(() => {
    setDateRange({
      selectedFromDate,
      selectedToDate,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedFromDate, selectedToDate]);

  return (
    <Toolbar className={clsx(classes.root)}>
      <Grid container wrap="nowrap">
        <Grid container item xs={6}>
          <Grid item className="mt-4">
            <Link to={'incidents/add'} onClick={handleOnClick}>
              <RMSAddButton tooltipText="Add Incident" />
            </Link>
          </Grid>

          <Grid item className="mt-4 ml-2">
            <Link to={'/events'}>
              <RMSEventsButton tooltipText="Pending Events" />
            </Link>
          </Grid>

          <Grid container item xs={10} className="mt-2 ml-4">
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <Grid item xs={4}>
                <KeyboardDatePicker
                  clearable
                  size="small"
                  inputVariant="outlined"
                  showTodayButton={true}
                  variant="dialog"
                  format="MM/dd/yyyy"
                  margin="normal"
                  id="date-picker-inline-from"
                  label="Occurred from"
                  defaultValue={null}
                  value={selectedFromDate}
                  onChange={(date) => setSelectedFromDate(date)}
                  KeyboardButtonProps={{
                    'aria-label': 'change date',
                  }}
                  maxDate={props?.dateRange?.selectedToDate || new Date()}
                />
              </Grid>
              <Grid item xs={4} className="ml-3">
                <KeyboardDatePicker
                  clearable
                  size="small"
                  inputVariant="outlined"
                  showTodayButton={true}
                  variant="dialog"
                  format="MM/dd/yyyy"
                  margin="normal"
                  id="date-picker-inline-to"
                  label="Occurred to"
                  defaultValue={null}
                  value={selectedToDate}
                  onChange={(date) => setSelectedToDate(date)}
                  KeyboardButtonProps={{
                    'aria-label': 'change date',
                  }}
                  minDate={props?.dateRange?.selectedFromDate || false}
                />
              </Grid>
            </MuiPickersUtilsProvider>
          </Grid>
        </Grid>

        <Grid container item xs={6} justify="flex-end">
          <Grid container xs={12} spacing={2} className="mt-1 mr-3" justify="flex-end">
            <Grid item className="mt-4">
              <SearchIcon />
            </Grid>
            <Grid item xs={3}>
              <TextField
                autoComplete="hidden"
                size="small"
                id="input-with-icon-grid"
                fullWidth
                label="Filter by Event Type"
                value={searchKeyword.incidentKeyword?.eventType || ''}
                onChange={(e) => handleFilterChange(e, 'eventType')}
              />
            </Grid>

            <Grid item xs={3}>
              <TextField
                autoComplete="hidden"
                size="small"
                id="input-with-icon-grid"
                fullWidth
                label="Primary Address"
                value={searchKeyword.incidentKeyword?.address || ''}
                onChange={(e) => handleFilterChange(e, 'address')}
              />
            </Grid>

            <Grid item xs={3}>
              <TextField
                autoComplete="hidden"
                size="small"
                id="input-with-icon-grid"
                fullWidth
                label="Offense"
                value={searchKeyword.incidentKeyword?.offense || ''}
                onChange={(e) => handleFilterChange(e, 'offense')}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Toolbar>
  );
};

const mapStateToProps = (state) => ({
  selectedIncident: state.incident.ptsIncidentId,
  searchKeyword: state.incident.searchKeyword,
});

export default connect(mapStateToProps, {
  setLayoutState,
  setIncFormActiveStep,
  setSearchKeyword,
  resetAllEntitiesList,
})(EnhancedTableToolbar);
