export const forceCategoryCodes = {
  11: 'Firearm (type not stated)',
  12: 'Handgun',
  13: 'Rifle',
  14: 'Shotgun',
  15: 'Other Firearm',
  40: 'Personal Weapons',
  90: 'Other',
  95: 'Unknown',
  99: 'None',
};
