import React from 'react';
import Grid from '@material-ui/core/Grid';
import { connect } from 'react-redux';
import { updateIncOffensesOffenseForm, updateIncOffenses } from '../../../../reducers/IncReducer';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { getCodeOffenseFactor } from '../../../../reducers/dictionaries/CodeOffenseFactorReducer';
import { handleFormChangeNew } from '../../../../reducers/helpers/formHelpers';
import RMSComboBox from 'components/RMSComboBox';
import RMSComboBoxListItem from 'components/RMSComboBoxListItem';

import { validateOffenseForm } from 'simpl-schema-validation/ValidateForms';

const OffenderSuspectedOfUsingForm = (props) => {
  /** region Component Variables and Props */
  const [errors, setErrors] = React.useState({});
  const {
    offensesOffenseForm,
    updateIncOffensesOffenseForm,
    codeOffenseFactor,
    getCodeOffenseFactor,
    updateIncOffenses,
    offenses,
    wsClient,
  } = props;
  /** endregion */
  /** region React Hooks */
  const [offensesOffenseFormState, setOffensesOffenseFormState] = React.useState({
    isValid: false,
    changes: false,
    values: {},
    touched: {},
    errors: {},
  });
  React.useEffect(() => {
    const services = ['codeOffenseFactor'];

    if (!wsClient) return;

    services.forEach(async (svc) => {
      if (props[svc].length < 1) {
        try {
          const service = wsClient.service(svc.toLowerCase());
          service.timeout = 20000;

          const result = await service.find({
            query: {
              $limit: 0,
              $select: ['Code', 'Description'],
              $sort: {
                Description: 1,
              },
              IsDeleted: false,
            },
          });

          if (result.total > 0) {
            getCodeOffenseFactor(result.data);
          }
        } catch (error) {
          console.log(error);
        }
      }
    });
  }, [wsClient]);

  React.useEffect(() => {
    setOffensesOffenseFormState({
      ...offensesOffenseFormState,
      ...offensesOffenseForm,
      ptsOffenseId: offensesOffenseForm.ptsOffenseId,
      values: { ...offensesOffenseFormState.values, ...offensesOffenseForm.values },
    });
  }, [offensesOffenseForm]);

  React.useEffect(() => {
    let timeoutId;
    offensesOffenseForm.changes = true;
    updateIncOffenses(offensesOffenseFormState);
    updateIncOffensesOffenseForm(offensesOffenseFormState);

    if (offensesOffenseFormState.values.statuteDetails) {
      timeoutId = setTimeout(() => {
        let nibrError = validateOffenseForm('offenderSuspectedOfUsing');
        let errorStatus = errors;
        if (nibrError && nibrError.length) {
          errorStatus = {
            ...errorStatus,
            offenderSuspectedOfUsing: `${nibrError[0].message}`,
          };
        } else {
          errorStatus = {
            ...errorStatus,
            offenderSuspectedOfUsing: null,
          };
        }
        setErrors(errorStatus);
      }, 2000);
    }
    return () => clearTimeout(timeoutId);
  }, [
    offensesOffenseFormState.values.statuteDetails,
    offensesOffenseFormState.values.offenderSuspectedOfUsing,
  ]);
  /** endregion */
  return (
    <Grid container justify="center">
      <Grid item xs={12} lg={12} className="mt-4">
        <Autocomplete
          size="small"
          autoComplete
          autoSelect
          autoHighlight
          id="combo-offense-factor"
          getOptionLabel={(option) => option.Description || ''}
          options={codeOffenseFactor}
          key={offensesOffenseFormState.values.offenderSuspectedOfUsing || ''}
          value={{ Description: offensesOffenseFormState.values.offenderSuspectedOfUsing } || ''}
          onChange={(event, newValue) =>
            handleFormChangeNew(
              event,
              newValue?.Description,
              'none',
              'offenderSuspectedOfUsing',
              setOffensesOffenseFormState
            )
          }
          renderInput={(params) => (
            <RMSComboBox
              {...params}
              label="Offender Suspected of Using"
              serviceName="codeoffensefactor"
              variant="outlined"
              error={errors['offenderSuspectedOfUsing'] ? true : false}
              helperText={errors['offenderSuspectedOfUsing']}
              fullWidth
            />
          )}
          renderOption={(option) => {
            return (
              <RMSComboBoxListItem
                option={option}
                title={'Description'}
                label={'Offender Suspected of Using'}
                serviceName="codeoffensefactor"
                handleFormChangeNew={handleFormChangeNew}
                resetField={() =>
                  handleFormChangeNew(
                    null,
                    null,
                    'none',
                    'offenderSuspectedOfUsing',
                    setOffensesOffenseFormState
                  )
                }
              />
            );
          }}
        />
      </Grid>
    </Grid>
  );
};

const mapStateToProps = (state) => ({
  wsClient: state.websocket.websocket,
  offensesOffenseForm: state.incident.offensesOffenseForm,
  codeOffenseFactor: state.codeOffenseFactor,
  currentOffense: state.incident.currentOffense,
  offenses: state.incident.offenses,
  selectedEntity: state.incident.selectedEntity,
});

export default connect(mapStateToProps, {
  updateIncOffenses,
  updateIncOffensesOffenseForm,
  getCodeOffenseFactor,
})(OffenderSuspectedOfUsingForm);
