import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation, useParams } from 'react-router';
import { connect, useSelector } from 'react-redux';

import SimpleSchema from 'simpl-schema';
import DateFnsUtils from '@date-io/date-fns';
import { Grid, makeStyles, TextField, Paper } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';

import RMSAddButton from '../../../RMSButtons/RMSAddButton';
import RMSFabButtons from 'components/RMSForms/RMSIncidentForm/RMSFabButtons';
import AddressSearch from 'components/RMSForms/RMSAddressForm/AddressSearch';
import { handleFormChangeNew } from '../../../../reducers/helpers/formHelpers';
import { setFormData as setRecordFormData, upsertRecord } from 'reducers/RecordsReducer';
import { setCircularLoading } from 'reducers/ui/UiMenuReducer';
import RMSEditButton from 'components/RMSButtons/RMSEditButton';

const validationContext = new SimpleSchema({
  Pay: SimpleSchema.Integer,
}).newContext();

const useStyles = makeStyles(() => ({
  root: {
    height: window.innerHeight - 90 + 'px',
    borderRadius: '0.5rem',
    padding: '15px',
  },
  form: {
    width: '100%',
  },
  input: {
    width: '100%',
    padding: '10px',
  },
  btnStyle: {
    display: 'block',
    textAlign: 'right',
    marginBottom: '10px',
  },
}));

/** region static code */
const CodePositions = [{ Code: 'test1' }, { Code: 'test2' }];
const CodeOccupations = [{ Code: 'test3' }, { Code: 'test1' }, { Code: 'test2' }];
const CodeRates = [{ Code: '10' }, { Code: '20' }];
const CodeRanks = [{ Code: 'rank1' }, { Code: 'rank2' }];
const CodeEmploymentStatus = [
  { Code: 'FTH' },
  { Code: 'FTS' },
  { Code: 'OTH' },
  { Code: 'PTH' },
  { Code: 'PTS' },
  { Code: 'RES' },
  { Code: 'RET' },
];
const CodeTerminationReasoans = [
  { Code: 'F' },
  { Code: 'LISS' },
  { Code: 'LNON' },
  { Code: 'NONE' },
  { Code: 'Q' },
  { Code: 'RES' },
  { Code: 'RET' },
];
/** end region */

const PersonEmploymentForm = (props) => {
  /** region props and variables */
  const classes = useStyles();
  const location = useLocation();
  let data = useSelector((state) => state.records.formData);
  const [changeKey, setChangeKey] = React.useState(0);
  const { persondId } = useParams();
  const { upsertRecord, setRecordFormData } = props;
  const [firstLoad, setFirstLoad] = useState(true);
  const [errors, setErrors] = useState({});
  const history = useHistory();
  const dispatch = useDispatch();
  const pageType = location.pathname.split('/')[5];
  if (pageType === 'add') {
    data = {};
  }
  const [formData, setFormData] = useState({
    values: {
      ptsPlaceID: data?.ptsPlaceID || undefined,
      ptsPersonID: persondId,
      DepartmentName: data?.DepartmentName || '',
      MethodPositionType: data?.Position || '',
      Shift: data?.Shift || '',
      Occupation: data?.Occupation || '',
      Rank: data?.Rank || '',
      Position: data?.Position || '',
      Pay: data?.Pay || null,
      Rate: data?.Rate || null,
      Status: data?.Status || '',
      TerminationReason: data?.TerminationReason || '',
      Hired: data?.Hired || new Date(),
      Terminated: data?.Terminated || new Date(),
    },
  });
  /** end region */
  /**region lifecycle hooks */
  React.useEffect(() => {
    if (!firstLoad) {
      validationContext.validate({
        VehicleValue:
          formData.values?.VehicleValue?.length > 0 ? Number(formData.values?.VehicleValue) : 0,
        Pay: formData.values?.Pay?.length > 0 ? Number(formData.values?.Pay) : 0,
      });

      if (validationContext.isValid() === false) {
        const errs = validationContext.validationErrors();
        let e = {};
        errs.map((er) => {
          e[er.name] = `Value must be ${er.dataType}`;
          setErrors(e);
        });
      } else {
        setErrors({});
      }
    }
    setFirstLoad(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formData?.values?.Pay]);
  /** endregion */
  /** region Helper Functions */
  const handleSave = () => {
    upsertRecord(
      'employment',
      {
        ...formData.values,
        Pay: Number.isInteger(Number(formData.values.Pay)) ? Number(formData.values.Pay) : 0,
      },
      'add'
    ).finally(() => handleFinally());
  };

  const handleFinally = () => {
    dispatch(setCircularLoading(false));
    history.goBack();
  };

  const onPlaceValueChange = (place, setFormState, setChangeKey, changeKey, contact) => {
    setFormData({
      ...formData,
      values: {
        ...formData.values,
        ptsPlaceID: place.ptsPlaceID,
      },
    });
  };

  const clearPlace = () => {
    setFormData({
      ...formData,
      values: {
        ...formData.values,
        ptsPlaceID: '',
      },
    });
  };

  const goToAddPlacePage = () => {
    history.push(`/records/add/place`);
  };

  const goToEditPlacePage = () => {
    setRecordFormData({
      ptsPlaceID: data?.ptsPlaceID,
      PlaceName: data?.PlaceName,
      PlaceType: data?.PlaceType,
    });
    history.push(`/records/edit/place/${data.ptsPlaceID}`);
  };
  /** end region */
  return (
    <Paper className={classes.root}>
      <Grid container xs={12} spacing={2} className="mt-2 mb-2">
        {pageType === 'add' && (
          <Grid item xs={12} className={classes.btnStyle}>
            <RMSAddButton tooltipText="Add Place" onClick={goToAddPlacePage} />
          </Grid>
        )}

        {pageType === 'edit' && (
          <Grid item xs={12} className={classes.btnStyle}>
            <RMSEditButton tooltipText="Edit Place" onClick={goToEditPlacePage} />
          </Grid>
        )}

        <Grid item xs={12}>
          {pageType === 'add' && (
            <AddressSearch
              ptsPlaces={true}
              ptsAddresses={false}
              googleAddresses={false}
              onPlaceValueSet={onPlaceValueChange}
              onAddressValueSet={onPlaceValueChange}
              label={'Search Places'}
              setChangeKey={setChangeKey}
              changeKey={changeKey}
              defaultLocation={null}
              onReset={clearPlace}
            />
          )}
        </Grid>
      </Grid>

      <form autoComplete="off" className={classes.form}>
        <Grid container xs={12} align="center" spacing={2} justify="left">
          <Grid item xs={4}>
            <TextField
              id="standard-textarea-dept-name"
              autoComplete="hidden"
              size="small"
              label="Department Name"
              placeholder="Department Name"
              variant="outlined"
              name="DepartmentName"
              value={formData?.values?.DepartmentName || ''}
              onChange={(event) =>
                handleFormChangeNew(
                  event,
                  event.target.value,
                  'none',
                  'DepartmentName',
                  setFormData
                )
              }
              fullWidth
              multiline
              InputLabelProps={{ shrink: true }}
            />
          </Grid>
          <Grid item xs={4}>
            <Autocomplete
              size="small"
              autoComplete
              autoSelect
              autoHighlight
              id="combo-position"
              getOptionLabel={(option) => option || ''}
              options={CodePositions.map((s) => s.Code)}
              key={formData.values.Position || ''}
              value={formData.values.Position || ''}
              onChange={(event, newValue) =>
                handleFormChangeNew(event, newValue, 'none', 'Position', setFormData)
              }
              renderInput={(params) => (
                <Grid container spacing={1} alignItems="flex-end">
                  <Grid item xs={12} lg={12}>
                    <TextField
                      autoComplete="hidden"
                      size="small"
                      {...params}
                      label="Position"
                      variant="outlined"
                      fullWidth
                    />
                  </Grid>
                </Grid>
              )}
            />
          </Grid>

          <Grid item xs={4}>
            <TextField
              id="standard-textarea-shift"
              autoComplete="hidden"
              size="small"
              label="Shift"
              placeholder="Shift"
              variant="outlined"
              name="Shift"
              value={formData?.values?.Shift || ''}
              onChange={(event) =>
                handleFormChangeNew(event, event.target.value, 'none', 'Shift', setFormData)
              }
              fullWidth
              multiline
              InputLabelProps={{ shrink: true }}
            />
          </Grid>
        </Grid>

        <Grid container xs={12} align="center" spacing={2} justify="left" className="mt-2">
          <Grid item xs={4}>
            <Autocomplete
              size="small"
              autoComplete
              autoSelect
              autoHighlight
              id="combo-occupation"
              getOptionLabel={(option) => option || ''}
              options={CodeOccupations.map((s) => s.Code)}
              key={formData.values.Occupation || ''}
              value={formData.values.Occupation || ''}
              onChange={(event, newValue) =>
                handleFormChangeNew(event, newValue, 'none', 'Occupation', setFormData)
              }
              renderInput={(params) => (
                <Grid container spacing={1} alignItems="flex-end">
                  <Grid item xs={12} lg={12}>
                    <TextField
                      autoComplete="hidden"
                      size="small"
                      {...params}
                      label="Occupation"
                      variant="outlined"
                      fullWidth
                    />
                  </Grid>
                </Grid>
              )}
            />
          </Grid>

          <Grid item xs={4}>
            <TextField
              id="standard-textarea-pay"
              autoComplete="hidden"
              size="small"
              label="Pay"
              placeholder="Pay"
              variant="outlined"
              name="Pay"
              error={errors['Pay'] ? true : false}
              helperText={errors['Pay']}
              value={formData?.values?.Pay || ''}
              onChange={(event) =>
                handleFormChangeNew(event, event.target.value, 'none', 'Pay', setFormData)
              }
              fullWidth
              multiline
              InputLabelProps={{ shrink: true }}
            />
          </Grid>
          <Grid item xs={4}>
            <Autocomplete
              size="small"
              autoComplete
              autoSelect
              autoHighlight
              id="combo-rate"
              getOptionLabel={(option) => option || ''}
              options={CodeRates.map((s) => s.Code)}
              key={formData.values.Rate || ''}
              value={formData.values.Rate || ''}
              onChange={(event, newValue) =>
                handleFormChangeNew(event, newValue, 'none', 'Rate', setFormData)
              }
              renderInput={(params) => (
                <Grid container spacing={1} alignItems="flex-end">
                  <Grid item xs={12} lg={12}>
                    <TextField
                      autoComplete="hidden"
                      size="small"
                      {...params}
                      label="Rate"
                      variant="outlined"
                      fullWidth
                    />
                  </Grid>
                </Grid>
              )}
            />
          </Grid>
        </Grid>
        <Grid container xs={12} align="center" spacing={2} justify="left" className="mt-2">
          <Grid item xs={4}>
            <Autocomplete
              size="small"
              autoComplete
              autoSelect
              autoHighlight
              id="combo-rank"
              getOptionLabel={(option) => option || ''}
              options={CodeRanks.map((s) => s.Code)}
              key={formData.values.Rank || ''}
              value={formData.values.Rank || ''}
              onChange={(event, newValue) =>
                handleFormChangeNew(event, newValue, 'none', 'Rank', setFormData)
              }
              renderInput={(params) => (
                <Grid container spacing={1} alignItems="flex-end">
                  <Grid item xs={12} lg={12}>
                    <TextField
                      autoComplete="hidden"
                      size="small"
                      {...params}
                      label="Rank"
                      variant="outlined"
                      fullWidth
                    />
                  </Grid>
                </Grid>
              )}
            />
          </Grid>
          <Grid item xs={4}>
            <Autocomplete
              size="small"
              autoComplete
              autoSelect
              autoHighlight
              id="combo-status"
              getOptionLabel={(option) => option || ''}
              options={CodeEmploymentStatus.map((s) => s.Code)}
              key={formData.values.Status || ''}
              value={formData.values.Status || ''}
              onChange={(event, newValue) =>
                handleFormChangeNew(event, newValue, 'none', 'Status', setFormData)
              }
              renderInput={(params) => (
                <Grid container spacing={1} alignItems="flex-end">
                  <Grid item xs={12} lg={12}>
                    <TextField
                      autoComplete="hidden"
                      size="small"
                      {...params}
                      label="Status"
                      variant="outlined"
                      fullWidth
                    />
                  </Grid>
                </Grid>
              )}
            />
          </Grid>
          <Grid item xs={4}>
            <Autocomplete
              size="small"
              autoComplete
              autoSelect
              autoHighlight
              id="combo-Termination-Reasoan"
              getOptionLabel={(option) => option || ''}
              options={CodeTerminationReasoans.map((s) => s.Code)}
              key={formData.values.TerminationReason || ''}
              value={formData.values.TerminationReason || ''}
              onChange={(event, newValue) =>
                handleFormChangeNew(event, newValue, 'none', 'TerminationReason', setFormData)
              }
              renderInput={(params) => (
                <Grid container spacing={1} alignItems="flex-end">
                  <Grid item xs={12} lg={12}>
                    <TextField
                      autoComplete="hidden"
                      size="small"
                      {...params}
                      label="Termination Reason"
                      variant="outlined"
                      fullWidth
                    />
                  </Grid>
                </Grid>
              )}
            />
          </Grid>
        </Grid>
        <Grid container xs={12} align="center" spacing={2} justify="left" className="mt-2">
          <Grid item xs={4}>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <KeyboardDatePicker
                size="small"
                inputVariant="outlined"
                fullWidth
                showTodayButton={true}
                variant="dialog"
                format="MM/dd/yyyy"
                id="date-picker-inline-from"
                label="Hired"
                value={formData.values?.Hired ? formData.values.Hired : null}
                onChange={(date) => {
                  setFormData((state) => {
                    return {
                      ...state,
                      values: {
                        ...state.values,
                        Hired: date,
                      },
                    };
                  });
                }}
                KeyboardButtonProps={{
                  'aria-label': 'change date',
                }}
              />
            </MuiPickersUtilsProvider>
          </Grid>
          <Grid item xs={4}>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <KeyboardDatePicker
                size="small"
                inputVariant="outlined"
                fullWidth
                showTodayButton={true}
                variant="dialog"
                format="MM/dd/yyyy"
                id="date-picker-inline-from"
                label="Terminated"
                value={formData.values?.Terminated ? formData.values.Terminated : null}
                onChange={(date) => {
                  setFormData((state) => {
                    return {
                      ...state,
                      values: {
                        ...state.values,
                        Terminated: date,
                      },
                    };
                  });
                }}
                KeyboardButtonProps={{
                  'aria-label': 'change date',
                }}
              />
            </MuiPickersUtilsProvider>
          </Grid>
        </Grid>
      </form>
      <RMSFabButtons recordForm={true} handleSaveRecord={handleSave} />
    </Paper>
  );
};

const mapStateToProps = (state) => ({
  wsClient: state.websocket,
});

export default connect(mapStateToProps, { setRecordFormData, upsertRecord })(PersonEmploymentForm);
