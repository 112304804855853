import store from '../config/configureStore';

// const GET_LABEL = 'GET_LABEL';
export const SET_OPEN = 'SET_OPEN';
export const INCREMENT_COUNT = 'INCREMENT_COUNT';
export const SET_MODAL_FORM = 'SET_MODAL_FORM';
export const SET_MODAL_SERVICE_NAME = 'MODAL/SET_SERVICE_NAME';
export const SET_MODAL_FORM_TYPE = 'SET_MODAL_FORM_TYPE';
export const SET_MODAL_RESET_TEXT_FIELD_FUNCTION = 'SET_MODAL_RESET_TEXT_FIELD_FUNCTION';
export const SET_MODAL_SET_TEXT_FIELD_FUNCTION = 'SET_MODAL_SET_TEXT_FIELD_FUNCTION';

const defaultState = {
  label: null,
  open: false,
  count: 0,
  type: null,
  form: null,
  resetTextField: null,
  serviceName: null,
  setTextField: null,
};

export const setModalSetTextFieldFunction = (func) => {
  return { type: SET_MODAL_SET_TEXT_FIELD_FUNCTION, payload: func };
};

export const setModalResetTextFieldFunction = (func) => {
  return { type: SET_MODAL_RESET_TEXT_FIELD_FUNCTION, payload: func };
};

export const setModalServiceName = (name) => {
  return { type: SET_MODAL_SERVICE_NAME, payload: name };
};

export const setModalFormType = (type) => {
  return { type: SET_MODAL_FORM_TYPE, payload: type };
};

export const setModalForm = (form) => {
  return { type: SET_MODAL_FORM, payload: form };
};

export const incrementCount = () => {
  return { type: INCREMENT_COUNT };
};

export const updateFormOpen = (label) => {
  return { type: SET_OPEN, payload: label };
};
export const getLabel = () => {
  const state = store.store.getState();
};

export default function reducer(state = defaultState, action) {
  switch (action.type) {
    case SET_OPEN:
      if (!state.open == false)
        return {
          ...state,
          open: !state.open,
          label: null,
          form: null,
          type: null,
          resetTextField: null,
          serviceName: null,
          setTextField: null,
        };
      else
        return {
          ...state,
          open: !state.open,
          label: action.payload,
        };
    case SET_MODAL_FORM:
      return {
        ...state,
        form: action.payload,
      };
    case SET_MODAL_SERVICE_NAME:
      return {
        ...state,
        serviceName: action.payload,
      };
    case SET_MODAL_FORM_TYPE:
      return {
        ...state,
        type: action.payload,
      };
    case SET_MODAL_RESET_TEXT_FIELD_FUNCTION:
      return {
        ...state,
        resetTextField: action.payload,
      };
    case SET_MODAL_SET_TEXT_FIELD_FUNCTION:
      return {
        ...state,
        setTextField: action.payload,
      };
    case INCREMENT_COUNT:
      return {
        ...state,
        count: state.count + 1,
      };
    default:
      return state;
  }
}
