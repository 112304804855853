/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';

import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import withStyles from '@material-ui/core/styles/withStyles';
import TableCell from '@material-ui/core/TableCell';
import { makeStyles } from '@material-ui/core/styles';

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.info.dark,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const useStyles = makeStyles((theme) => ({
  root: {
    '&:hover': {
      color: '#FFEDC2 !important',
    },
  },
  active: {
    color: '#FFEDC2 !important',
  },
  icon: {
    color: '#FFEDC2 !important',
    '&:hover': {
      color: '#FFEDC2 !important',
    },
  },
}));

function EnhancedTableHead(props) {
  /*Region: Props and State*/
  const classes = useStyles();
  const { order, orderBy, onRequestSort, entityFlag, headCells } = props;
  const [cells, setCells] = useState(headCells[entityFlag]);
  /** End Region */
  /*Region: useEffect*/
  useEffect(() => {
    if (headCells && headCells[entityFlag]) setCells(headCells[entityFlag]);
  }, [entityFlag]);
  /** End Region */
  /*Region: Helper Functions*/
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {cells.map((headCell, i) => {
          if (headCell.id !== 'action')
            return (
              <StyledTableCell
                key={headCell.id}
                align={headCell.numeric ? 'right' : 'left'}
                padding={headCell.disablePadding ? 'none' : 'default'}
                sortDirection={orderBy === headCell.id ? order : false}>
                {headCell.sortable && (
                  <TableSortLabel
                    classes={{
                      root: classes.root,
                      active: classes.active,
                      icon: classes.icon,
                    }}
                    active={orderBy === headCell.id}
                    direction={orderBy === headCell.id ? order : 'asc'}
                    onClick={createSortHandler(headCell.id)}>
                    {headCell.label}
                  </TableSortLabel>
                )}
                {!headCell.sortable && headCell.label}
              </StyledTableCell>
            );
          else if (headCell.id === 'action' && !props.dnd)
            return <StyledTableCell key="action" align="left" padding="default"></StyledTableCell>;
        })}
      </TableRow>
    </TableHead>
  );
}

const mapStateToProps = (state) => ({
  headCells: state.uiList.incListHeaderState,
});

export default connect(mapStateToProps, {})(EnhancedTableHead);
