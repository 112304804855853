import { Fab } from '@material-ui/core';
import React from 'react';
import FolderSharedIcon from '@material-ui/icons/FolderShared';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Badge from '@material-ui/core/Badge';

const useStyles = makeStyles((theme) => ({
  badge: {
    top: '2px',
    right: '3px',
  },
  badgeColor: {
    background: '#ff7373',
  },
}));

export default function RMSSelectButton(props) {
  const classes = useStyles();
  const { onClick, lists } = props;
  return (
    <Badge
      classes={{
        anchorOriginTopRightRectangle: classes.badge,
        colorSecondary: classes.badgeColor,
      }}
      badgeContent={lists?.length || 0}
      color="secondary">
      <Fab size="small" color="secondary" aria-label="add">
        <FolderSharedIcon onClick={onClick} />
      </Fab>
    </Badge>
  );
}
