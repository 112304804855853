import React, { useEffect } from 'react';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBuilding } from '@fortawesome/free-regular-svg-icons';
import Divider from '@material-ui/core/Divider';
import TabPanel from '../../components/RMSForms/RMSIncidentForm/TabPanel';
import { makeStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import { setHandleClose, setMenuType } from '../../reducers/ui/UiMenuReducer';
import TabPlace from './TabPlace';
import IncSummaryDetails from '../../components/RMSForms/RMSIncidentForm/IncSummaryDetails';
import RMSFabButtons from 'components/RMSForms/RMSIncidentForm/RMSFabButtons';
import PerfectScrollbar from 'react-perfect-scrollbar';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    height: window.innerHeight - 90 + 'px',
  },
  indicator: {
    backgroundColor: 'rgba(0,0,254,0.4)',
  },
  paper: {
    minHeight: window.innerHeight - 95 + 'px',
  },
  scrollBar: {
    width: '100%',
    overflowX: 'hidden',
    paddingTop: '5px',
    paddingLeft: '15px',
    paddingRight: '5px',
    [theme.breakpoints.up('lg')]: {
      height: '70vh',
    },
    [theme.breakpoints.down('lg')]: {
      height: '55vh',
    },
    [theme.breakpoints.only('lg')]: {
      height: '60vh',
    },
  },
}));

const AddEditPlace = (props) => {
  const classes = useStyles();
  const { history, setHandleClose, setMenuType } = props;
  const [value, setValue] = React.useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    setMenuType('forms');
    setHandleClose('incForms', history);
  }, []);

  return (
    <div className={classes.root}>
      <RMSFabButtons />
      <Grid container>
        <Grid xs={3} lg={3}>
          <IncSummaryDetails history={history} />
        </Grid>
        <Grid xs={9} lg={9}>
          <Paper className={classes.paper}>
            <Grid container justify="center">
              <Grid item xs={12} lg={12}>
                <div
                  className="card-header--actions"
                  style={{
                    backgroundColor: '#1976d2',
                    color: 'white',
                    borderRadius: '0.5rem 0.5rem 0 0',
                  }}>
                  <Tabs
                    value={value}
                    indicatorColor="secondary"
                    textColor="secondary"
                    variant="fullWidth"
                    onChange={handleChange}>
                    <Tab
                      className="text-capitalize p-3"
                      style={{ color: 'white' }}
                      icon={<FontAwesomeIcon icon={faBuilding} />}
                      label="Place"
                    />
                  </Tabs>
                  <Divider />
                </div>
              </Grid>
              <PerfectScrollbar className={classes.scrollBar}>
                <Grid item xs={12} lg={12} style={{ paddingRight: '10px' }}>
                  <TabPanel value={value} index={0}>
                    <TabPlace />
                  </TabPanel>
                </Grid>
              </PerfectScrollbar>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    </div>
  );
};

const mapStateToProps = (state) => ({});

export default connect(null, { setMenuType, setHandleClose })(AddEditPlace);
