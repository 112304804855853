export const codeVictimCategory = [
  {
    code: 'B',
    description: 'Business',
  },
  {
    code: 'F',
    description: 'Financial Institution',
  },
  {
    code: 'G',
    description: 'Government',
  },
  {
    code: 'I',
    description: 'Individual',
  },
  {
    code: 'L',
    description: 'Law Enforcement Officer',
  },
  {
    code: 'O',
    description: 'Other',
  },
  {
    code: 'R',
    description: 'Religious Organization',
  },
  {
    code: 'S',
    description: 'Society/ Public',
  },
  {
    code: 'U',
    description: 'Unknown',
  },
];
