import React from 'react';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import Input from '@material-ui/core/Input';
import MenuItem from '@material-ui/core/MenuItem';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Chip from '@material-ui/core/Chip';

const useStyles = makeStyles((theme) => ({
  formControl: (props) => ({
    margin: theme.spacing(1),
    minWidth: props.min,
    maxWidth: props.max,
  }),
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chip: {
    margin: 2,
  },
  noLabel: {
    marginTop: theme.spacing(3),
  },
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const RMSMultiSelect = (props) => {
  const classes = useStyles(props);
  const { selections, label, selectedValues, handleFormChange, name } = props;
  const [selection, setSelection] = React.useState([]);

  React.useEffect(() => {
    setSelection(selectedValues || []);
  }, [selectedValues]);

  const handleChange = (event) => {
    setSelection(event.target.value);
    handleFormChange(event);
  };

  return (
    <FormControl size="small" className={classes.formControl}>
      <InputLabel id="demo-mutiple-chip-label">{label}</InputLabel>
      <Select
        labelId="demo-mutiple-chip-label"
        id="demo-mutiple-chip"
        multiple
        name={name}
        value={selection}
        onChange={handleChange}
        input={<Input id="select-multiple-chip" />}
        renderValue={(selected) => (
          <div className={classes.chips}>
            {selection.map((value) => (
              <Chip key={value} label={value} className={classes.chip} />
            ))}
          </div>
        )}
        MenuProps={MenuProps}>
        {selections.map((selection) => (
          <MenuItem key={selection} value={selection}>
            {selection}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default RMSMultiSelect;
