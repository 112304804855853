/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { connect } from 'react-redux';

import DateFnsUtils from '@date-io/date-fns';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Checkbox from '@material-ui/core/Checkbox';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import Input from '@material-ui/core/Input';
import MenuItem from '@material-ui/core/MenuItem';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Chip from '@material-ui/core/Chip';

import RMSAutoComplete from 'components/RMSAutoComplete/RMSAutoComplete';
import { updateIncPropertyDetails } from '../../../../reducers/IncReducer';
import { getCodePropertyLossType } from '../../../../reducers/dictionaries/CodePropertyLossTypeReducer';
import { getCodePropertyCategory } from '../../../../reducers/dictionaries/CodePropertyCategoryReducer';
import { getCodePropertyDescription } from '../../../../reducers/dictionaries/CodePropertyDescriptionReducer';
import { handleFormChangeNew } from '../../../../reducers/helpers/formHelpers';
import RMSAddButton from '../../../RMSButtons/RMSAddButton';
import { validateIncPropertiesForm } from 'simpl-schema-validation/ValidateForms';

const useStyles = makeStyles((theme) => ({
  formControl: (props) => ({
    margin: theme.spacing(1),
    minWidth: '100%',
    maxWidth: '100%',
  }),
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chip: {
    margin: 2,
  },
  noLabel: {
    marginTop: theme.spacing(3),
  },
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const selections = ['John Smith', 'Jane London'];

const PropertyForm = (props) => {
  const [errors, setErrors] = React.useState({});
  const classes = useStyles(props);
  const {
    codePropertyLossType,
    codePropertyCategory,
    codePropertyDescription,
    getCodePropertyLossType,
    getCodePropertyCategory,
    getCodePropertyDescription,
    wsClient,
    incProperties,
    selectedProperty,
    updateIncPropertyDetails,
  } = props;

  const [offensesPropertyFormState, setOffensesPropertyFormState] = React.useState({
    isValid: false,
    values: {},
    touched: {},
    errors: {},
  });

  React.useEffect(() => {
    const services = ['codePropertyLossType', 'codePropertyCategory', 'codePropertyDescription'];

    if (!wsClient) return;

    services.forEach(async (svc) => {
      if (props[svc].length < 1) {
        let result = await wsClient.service(svc.toLowerCase()).find({
          query: {
            $limit: 0,
            $select: ['Code', 'Description'],
            $sort: {
              Code: 1,
            },
            IsDeleted: false,
          },
        });

        if (result.total > 0) {
          if (svc === 'codePropertyLossType') {
            getCodePropertyLossType(result.data);
          } else if (svc === 'codePropertyCategory') {
            getCodePropertyCategory(result.data);
          } else if (svc === 'codePropertyDescription') {
            getCodePropertyDescription(result.data);
          }
        }
      }
    });
  }, [wsClient]);

  React.useEffect(() => {
    if (offensesPropertyFormState.values.status !== 'RECOVERED') {
      offensesPropertyFormState.values.recoveredDate = null;
    }

    let timeoutId;
    timeoutId = setTimeout(() => {
      let errorStatus = errors;
      let error = validateIncPropertiesForm('status', offensesPropertyFormState);

      if (error && error.length > 0) {
        errorStatus = {
          ...errorStatus,
        };

        if (
          error[0].type === 'NIBRS-2019.2-63' ||
          error[0].type === 'NIBRS-2019.2-64' ||
          error[0].type === 'NIBRS-2019.2-65' ||
          error[0].type === 'NIBRS-2019.2-62' ||
          error[0].type === 'NIBRS-2019.2-67' ||
          error[0].type === 'NIBRS-2019.2-61' ||
          error[0].type === 'NIBRS-2019.2-58' ||
          error[0].type === 'NIBRS-2019.2-59' ||
          error[0].type === 'NIBRS-2019.2-60' ||
          error[0].type === 'NIBRS-2019.2-66' ||
          error[0].type === 'NIBRS-2019.2-182'
        ) {
          errorStatus = {
            ...errorStatus,
            recoveredDate: null,
            value: null,
            stolenVehicles: null,
            recoveredVehicles: null,
            propertyDescription: null,
          };
          errorStatus.status = `${error[0].message}`;
        } else if (error[0].type === 'NIBRS-2019.2-68') {
          errorStatus.status = null;
          if (offensesPropertyFormState.values.value) errorStatus.value = `${error[0].message}`;
          if (offensesPropertyFormState.values.stolenVehicles)
            errorStatus.stolenVehicles = `${error[0].message}`;
          else errorStatus.stolenVehicles = null;
          if (offensesPropertyFormState.values.recoveredVehicles)
            errorStatus.recoveredVehicles = `${error[0].message}`;
          else errorStatus.recoveredVehicles = null;
          if (offensesPropertyFormState.values.propertyDescription)
            errorStatus.propertyDescription = `${error[0].message}`;
          else errorStatus.propertyDescription = null;
          if (offensesPropertyFormState.values.recoveredDate)
            errorStatus.recoveredDate = `${error[0].message}`;
          else errorStatus.recoveredDate = null;
        } else {
          errorStatus.status = null;
          if (
            !offensesPropertyFormState.values.value ||
            offensesPropertyFormState.values.value.length === 0
          )
            errorStatus.value = `${error[0].message}`;
          if (
            !offensesPropertyFormState.values.stolenVehicles ||
            offensesPropertyFormState.values.stolenVehicles.length === 0
          )
            errorStatus.stolenVehicles = `${error[0].message}`;
          else errorStatus.stolenVehicles = null;
          if (
            !offensesPropertyFormState.values.recoveredVehicles ||
            offensesPropertyFormState.values.recoveredVehicles.length === 0
          )
            errorStatus.recoveredVehicles = `${error[0].message}`;
          else errorStatus.recoveredVehicles = null;
          if (
            !offensesPropertyFormState.values.propertyDescription ||
            offensesPropertyFormState.values.propertyDescription.length === 0
          )
            errorStatus.propertyDescription = `${error[0].message}`;
          else errorStatus.propertyDescription = null;
          if (!offensesPropertyFormState.values.recoveredDate)
            errorStatus.recoveredDate = `${error[0].message}`;
          else errorStatus.recoveredDate = null;
        }
      } else {
        errorStatus = {
          ...errorStatus,
          recoveredDate: null,
          value: null,
          stolenVehicles: null,
          recoveredVehicles: null,
          propertyDescription: null,
          status: null,
        };
      }

      error = validateIncPropertiesForm('value', offensesPropertyFormState);
      if (error.length > 0) errorStatus.value = `${error[0].message}`;
      else errorStatus.value = '';

      error = validateIncPropertiesForm('propertyDescription', offensesPropertyFormState);
      if (error.length > 0) errorStatus.propertyDescription = `${error[0].message}`;
      else errorStatus.propertyDescription = '';

      setErrors(errorStatus);
    }, 2000);
    return () => clearTimeout(timeoutId);
  }, [
    offensesPropertyFormState.values.status,
    offensesPropertyFormState.values.recoveredDate,
    offensesPropertyFormState.values.value,
    offensesPropertyFormState.values.propertyDescription,
    offensesPropertyFormState.values.stolenVehicles,
    offensesPropertyFormState.values.recoveredVehicles,
  ]);

  const handleMultiSelectValues = (event) => {
    handleFormChangeNew(
      event,
      event.target.value,
      'none',
      'ownerDetails',
      setOffensesPropertyFormState
    );
  };

  const updateIncDetailsList = () => {
    updateIncPropertyDetails(offensesPropertyFormState);
  };

  const getIncFormData = () => {
    setOffensesPropertyFormState({ ...offensesPropertyFormState, values: {} });

    let selectedPropertyDetails = incProperties.find(
      (x) => x.incPropertyDetails.incIncidentPropertyId === selectedProperty
    );

    if (selectedPropertyDetails && selectedPropertyDetails.incPropertyDetails) {
      setOffensesPropertyFormState(selectedPropertyDetails.incPropertyDetails);
    }
  };

  React.useEffect(() => {
    if (props.updated !== null) {
      updateIncDetailsList();
    }
  }, [props.updated]);

  React.useEffect(() => {
    getIncFormData();
  }, [selectedProperty]);

  return (
    <Grid container justify="center" className="mt-3">
      <Grid container justify="space-evenly" className="mb-1" spacing={1}>
        <Grid item>
          <FormControlLabel
            control={
              <Checkbox
                key={offensesPropertyFormState.values.stolenFromOtherJurisdiction}
                checked={offensesPropertyFormState.values.stolenFromOtherJurisdiction}
                onChange={(event) =>
                  handleFormChangeNew(
                    event,
                    event.target.checked,
                    'none',
                    'stolenFromOtherJurisdiction',
                    setOffensesPropertyFormState
                  )
                }
              />
            }
            label="Stolen From Other Jurisdiction"
          />
        </Grid>
        <Grid item>
          <FormControlLabel
            control={
              <Checkbox
                key={offensesPropertyFormState.values.recoveredFromOtherJurisdiction}
                checked={offensesPropertyFormState.values.recoveredFromOtherJurisdiction}
                onChange={(event) =>
                  handleFormChangeNew(
                    event,
                    event.target.checked,
                    'none',
                    'recoveredFromOtherJurisdiction',
                    setOffensesPropertyFormState
                  )
                }
              />
            }
            label="Recovered From Other Jurisdiction"
          />
        </Grid>
      </Grid>

      <Grid container justify="center" spacing={2} className="mb-2">
        <Grid item xs={12} lg={12}>
          <TextField
            id="standard-textarea-property-value"
            size="small"
            label="Property Value"
            placeholder="Property Value"
            variant="outlined"
            name="value"
            defaultValue={offensesPropertyFormState.values.value || ''}
            error={errors['value'] ? true : false}
            helperText={errors['value']}
            onBlur={(event) =>
              handleFormChangeNew(
                event,
                event.target.value,
                'none',
                'value',
                setOffensesPropertyFormState
              )
            }
            fullWidth
            multiline
            InputLabelProps={{ shrink: true }}
          />
        </Grid>
      </Grid>

      <Grid container justify="center" spacing={2} className="mb-2">
        <Grid item xs={12} lg={12}>
          <RMSAutoComplete
            size="small"
            autoComplete
            autoSelect
            autoHighlight
            id="combo-property-desc"
            error={errors['propertyDescription'] ? true : false}
            helperText={errors['propertyDescription']}
            options={codePropertyDescription}
            serviceName="codepropertydescription"
            label="Property Description"
            variant="outlined"
            title="Description"
            stateHolder={offensesPropertyFormState}
            setStateHolder={setOffensesPropertyFormState}
            setField={handleFormChangeNew}
            stateHolderAttribute="propertyDescription"
          />
        </Grid>
      </Grid>

      <Grid container justify="center" spacing={2} className="mb-2">
        <Grid item xs={6} lg={6}>
          <RMSAutoComplete
            size="small"
            autoComplete
            autoSelect
            autoHighlight
            id="combo-property-status"
            options={codePropertyLossType}
            error={errors['status'] ? true : false}
            helperText={errors['status']}
            serviceName="codepropertylosstype"
            label="Property Status"
            variant="outlined"
            title="Code"
            stateHolder={offensesPropertyFormState}
            setStateHolder={setOffensesPropertyFormState}
            setField={handleFormChangeNew}
            stateHolderAttribute="status"
          />
        </Grid>
        <Grid item xs={6} lg={6}>
          <RMSAutoComplete
            size="small"
            autoComplete
            autoSelect
            autoHighlight
            id="combo-property-type"
            options={codePropertyCategory}
            // new ones
            serviceName="codepropertycategory"
            label="Property Type"
            variant="outlined"
            title="Description"
            stateHolder={offensesPropertyFormState}
            setStateHolder={setOffensesPropertyFormState}
            setField={handleFormChangeNew}
            stateHolderAttribute="type"
          />
        </Grid>
      </Grid>

      <Grid container justify="center" spacing={2} className="mb-2">
        <Grid item xs={12} lg={12}>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <KeyboardDatePicker
              size="small"
              fullWidth
              inputVariant="outlined"
              showTodayButton={true}
              variant="dialog"
              format="MM/dd/yyyy"
              id="date-picker-inline-from"
              label="Status Date"
              value={
                offensesPropertyFormState.values.statusDate
                  ? offensesPropertyFormState.values.statusDate
                  : null
              }
              onChange={(date) => {
                setOffensesPropertyFormState((state) => {
                  return {
                    ...state,
                    values: {
                      ...state.values,
                      statusDate: date,
                    },
                  };
                });
              }}
              KeyboardButtonProps={{
                'aria-label': 'change date',
              }}
            />
          </MuiPickersUtilsProvider>
        </Grid>
      </Grid>

      <Grid container justify="center" spacing={2} className="mb-2">
        <Grid item xs={12} lg={12}>
          <TextField
            id="standard-textarea-stolen-vehicles"
            size="small"
            label="Stolen Vehicles"
            placeholder="Stolen Vehicles"
            variant="outlined"
            name="stolenVehicles"
            defaultValue={offensesPropertyFormState.values.stolenVehicles || ''}
            error={errors['stolenVehicles'] ? true : false}
            helperText={errors['stolenVehicles']}
            onBlur={(event) =>
              handleFormChangeNew(
                event,
                event.target.value,
                'none',
                'stolenVehicles',
                setOffensesPropertyFormState
              )
            }
            fullWidth
            multiline
            InputLabelProps={{ shrink: true }}
          />
        </Grid>
      </Grid>

      <Grid container justify="center" spacing={2} className="mb-2">
        <Grid
          item
          xs={offensesPropertyFormState.values.status === 'RECOVERED' ? 6 : 12}
          lg={offensesPropertyFormState.values.status === 'RECOVERED' ? 6 : 12}>
          <TextField
            id="standard-textarea-recovered-vehicles"
            size="small"
            label="Recovered Vehicles"
            placeholder="Recovered Vehicles"
            variant="outlined"
            name="recoveredVehicles"
            defaultValue={offensesPropertyFormState.values.recoveredVehicles || ''}
            error={errors['recoveredVehicles'] ? true : false}
            helperText={errors['recoveredVehicles']}
            onBlur={(event) =>
              handleFormChangeNew(
                event,
                event.target.value,
                'none',
                'recoveredVehicles',
                setOffensesPropertyFormState
              )
            }
            fullWidth
            multiline
            InputLabelProps={{ shrink: true }}
          />
        </Grid>

        {offensesPropertyFormState.values.status === 'RECOVERED' && (
          <Grid item xs={6} lg={6}>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <KeyboardDatePicker
                size="small"
                inputVariant="outlined"
                showTodayButton={true}
                variant="dialog"
                format="MM/dd/yyyy"
                id="date-picker-inline-from"
                label="Recovered Date"
                disabled={offensesPropertyFormState.values.status !== 'RECOVERED'}
                error={errors['recoveredDate'] ? true : false}
                helperText={errors['recoveredDate']}
                value={
                  offensesPropertyFormState.values.recoveredDate
                    ? offensesPropertyFormState.values.recoveredDate
                    : null
                }
                onChange={(date) => {
                  setOffensesPropertyFormState((state) => {
                    return {
                      ...state,
                      values: {
                        ...state.values,
                        recoveredDate: date,
                      },
                    };
                  });
                }}
                KeyboardButtonProps={{
                  'aria-label': 'change date',
                }}
              />
            </MuiPickersUtilsProvider>
          </Grid>
        )}
      </Grid>

      <Grid container justify="center" spacing={3} className="mb-2">
        <Grid item xs={10} lg={10}>
          <FormControl size="small" className={classes.formControl}>
            <InputLabel id="demo-mutiple-chip-label">Property Owners</InputLabel>
            <Select
              labelId="demo-mutiple-chip-label"
              id="demo-mutiple-chip"
              multiple
              name="ownerDetails"
              value={offensesPropertyFormState.values.ownerDetails || []}
              onChange={handleMultiSelectValues}
              input={<Input id="select-multiple-chip" />}
              renderValue={(selected) => (
                <div className={classes.chips}>
                  {selected.map((value) => (
                    <Chip key={value} label={value} className={classes.chip} />
                  ))}
                </div>
              )}
              MenuProps={MenuProps}>
              {selections.map((selection) => (
                <MenuItem key={selection} value={selection}>
                  {selection}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={2} lg={2} className="mt-2">
          <RMSAddButton />
        </Grid>
      </Grid>

      <Grid container justify="center" spacing={2} className="mb-2">
        <Grid item xs={12} lg={12}>
          <TextField
            size="small"
            id="outlined-multiline-static-address-notes"
            label="Notes"
            placeholder="Notes"
            rows="4"
            name="notes"
            defaultValue={offensesPropertyFormState.values.notes || ''}
            onBlur={(event) =>
              handleFormChangeNew(
                event,
                event.target.value,
                'none',
                'notes',
                setOffensesPropertyFormState
              )
            }
            fullWidth
            variant="outlined"
            multiline
            InputLabelProps={{ shrink: true }}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

const mapStateToProps = (state) => ({
  wsClient: state.websocket.websocket,
  offensesPropertyForm: state.incident.offensesPropertyForm,
  codePropertyLossType: state.codePropertyLossType,
  codePropertyCategory: state.codePropertyCategory,
  codePropertyDescription: state.codePropertyDescription,
  incProperties: state.incident.incProperties,
  selectedProperty: state.incident.selectedProperty,
});

export default connect(mapStateToProps, {
  getCodePropertyLossType,
  getCodePropertyCategory,
  getCodePropertyDescription,
  updateIncPropertyDetails,
})(PropertyForm);
