/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */
import React from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router';

import Files from 'react-files';
import { saveAs } from 'file-saver';

import { Divider, Grid, Tooltip } from '@material-ui/core';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import { Typography } from '@material-ui/core';
import Avatar from '@material-ui/core/Avatar';
import { makeStyles } from '@material-ui/core/styles';
import DeleteIcon from '@material-ui/icons/Delete';
import IconButton from '@material-ui/core/IconButton';
import GetAppIcon from '@material-ui/icons/GetApp';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import RMSAddButton from 'components/RMSButtons/RMSAddButton';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    maxWidth: 752,
  },
  demo: {
    backgroundColor: theme.palette.background.paper,
  },
  title: {
    margin: theme.spacing(4, 0, 2),
  },
  attachmentsButton: {
    display: 'inline-block',
  },
  attachmentsAddButton: {
    textAlign: 'right',
    margin: '12px',
    paddingRight: '17px',
  },
}));

const RMSAttachments = (props) => {
  const classes = useStyles();
  const history = useHistory();

  let { setField, setStateHolder, wsClient, isModal } = props;
  const [pageType, setPageType] = React.useState('add');
  const [attachments, setAttachments] = React.useState([]);

  React.useEffect(() => {
    const parts = history.location.pathname.split('/');
    setPageType(parts[2]);
  }, []);

  React.useEffect(() => {
    if (props.attachments) {
      setAttachments(props.attachments);
    }
  }, [props.attachments]);

  function readFile(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();

      reader.onload = function (e) {
        let dataURL = e.target.result;
        dataURL = dataURL.replace(';base64', `;name=${file.name};base64`);
        resolve({ file, dataURL, name: file.name, type: file.type });
      };

      reader.readAsDataURL(file);
    });
  }

  const onFilesChange = (files) => {
    readFile(files[0]).then((fileData) => {
      fileData = {
        ...fileData,
        rawFile: files[0],
      };
      let ats = attachments;
      ats.push(fileData);

      setField(null, ats, 'none', 'attachments', setStateHolder);
    });
  };

  const onFilesError = (error, file) => {
    console.log('error code ' + error.code + ': ' + error.message);
  };

  const handleDelete = async (id, index) => {
    let ats = attachments.filter((a, i) => i !== index);
    setField(null, ats, 'none', 'attachments', setStateHolder);
    if (id) {
      const service = wsClient?.websocket.service('rms-attachments');
      service.timeout = 200000;
      await service.remove(id);
    }
  };

  return (
    <div>
      <Grid xs={12} className={isModal ? '' : 'mb-3'}>
        {isModal ? (
          <Files
            className={classes.attachmentsAddButton}
            onChange={onFilesChange}
            onError={onFilesError}
            accepts={['image/*', 'video/*', 'audio/*', 'application/*', 'text/*']}
            multiple={false}
            maxFileSize={10000000}
            minFileSize={0}
            clickable>
            <Tooltip title="Add Attachment">
              <RMSAddButton tooltipText="Add Attachment" />
            </Tooltip>
          </Files>
        ) : (
          <>
            <Files
              className={classes.attachmentsButton}
              onChange={onFilesChange}
              onError={onFilesError}
              accepts={['image/*', 'video/*', 'audio/*', 'application/*', 'text/*']}
              multiple={false}
              maxFileSize={10000000}
              minFileSize={0}
              clickable>
              <span
                style={{ color: '#fff', background: '#1976d2' }}
                className="mb-1 badge badge-primary mr-2">
                Attachments
              </span>
              <Tooltip title="Add Attachment">
                <IconButton edge="end" aria-label="delete">
                  <AttachFileIcon style={{ fontSize: '17px' }} />
                </IconButton>
              </Tooltip>
            </Files>
            <Divider />
          </>
        )}
      </Grid>
      <Grid container xs={12} spacing={2} className="mt-2 mb-2">
        <Grid item xs={12} md={isModal ? 12 : 6}>
          <Typography color="textSecondary" variant="p">
            {!props.attachments && pageType !== 'add' && 'Loading...'}
            {props.attachments && props.attachments.length === 0 && 'No Attachments Found'}
          </Typography>

          <div className={classes.demo}>
            <List dense={false}>
              {attachments.map((a, index) => (
                <ListItem>
                  <ListItemAvatar>
                    <Avatar>{index + 1}</Avatar>
                  </ListItemAvatar>
                  <ListItemText primary={a.name} />
                  <ListItemSecondaryAction>
                    <Tooltip title="Download">
                      <IconButton edge="end" aria-label="delete" className="mr-1">
                        <GetAppIcon
                          onClick={() =>
                            saveAs(
                              a.rawFile ? a.rawFile : new Blob([a.dataURL], { type: a.type }),
                              a.name
                            )
                          }
                        />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title="Delete">
                      <IconButton edge="end" aria-label="delete" className="ml-2">
                        <DeleteIcon onClick={() => handleDelete(a.ptsAttachmentId, index)} />
                      </IconButton>
                    </Tooltip>
                  </ListItemSecondaryAction>
                </ListItem>
              ))}
            </List>
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

const mapStateToProps = (state) => ({
  wsClient: state.websocket,
});

export default connect(mapStateToProps, {})(RMSAttachments);
