import React, { useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router';
import { connect, useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';

import DateFnsUtils from '@date-io/date-fns';
import { Grid, makeStyles, Paper } from '@material-ui/core';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';

import RMSAutoComplete from 'components/RMSAutoComplete/RMSAutoComplete';
import { handleFormChangeNew } from '../../../../reducers/helpers/formHelpers';
import { getCodeDNACollection } from '../../../../reducers/dictionaries/CodeOthersReducer';
import { upsertRecord } from 'reducers/RecordsReducer';
import RMSFabButtons from 'components/RMSForms/RMSIncidentForm/RMSFabButtons';
import { setCircularLoading } from 'reducers/ui/UiMenuReducer';

const useStyles = makeStyles(() => ({
  root: {
    height: window.innerHeight - 90 + 'px',
    borderRadius: '0.5rem',
    padding: '15px',
  },
  form: {
    width: '100%',
  },
  input: {
    width: '100%',
    padding: '10px',
  },
  btnStyle: {
    display: 'block',
    width: '150px',
    padding: '10px',
    marginTop: '15px',
    marginLeft: 'auto',
    position: 'relative',
    right: 10,
  },
}));
/** region static code */
const codeDNSSampleMethods = [{ Code: 'method1' }, { Code: 'method2' }, { Code: 'method3' }];
const codeDNSSampleTypes = [{ Code: 'Type1' }, { Code: 'Type2' }];
const codeDNSSampleReasons = [{ Code: 'reason1' }, { Code: 'reason2' }];
/** end region */

const PersonDNASampleForm = (props) => {
  /** region props and variables */
  const classes = useStyles();
  const { persondId } = useParams();
  let data = useSelector((state) => state.records.formData);
  const { upsertRecord, wsClient, getCodeDNACollection, codeDNACollection } = props;
  const location = useLocation();
  const actionType = location.pathname.split('/')[5];
  const history = useHistory();
  const dispatch = useDispatch();
  if (actionType === 'add') {
    data = {};
  }
  const [formData, setFormData] = useState({
    values: {
      ptsDNASampleID: data?.ptsDNASampleID || undefined,
      ptsPersonID: persondId,
      Type: data?.Type || '',
      Method: data?.Method || '',
      Reason: data?.Reason || '',
      Status: data?.Status || '',
      Taken: data?.Taken || new Date(),
    },
  });
  /** end region */
  /**region React hooks */
  React.useEffect(() => {
    if (wsClient.websocket) {
      getCodeDNACollection();
    }
  }, [getCodeDNACollection, wsClient]);
  /** end region */
  /** region Helper Functions */
  const handleSave = () => {
    if (!formData.values.ptsDNASampleID) {
      upsertRecord('dnasample', formData.values, 'add').finally(() => handleFinally());
    } else {
      upsertRecord('dnasample', formData.values, 'edit').finally(() => handleFinally());
    }
  };

  const handleFinally = () => {
    dispatch(setCircularLoading(false));
    history.goBack();
  };
  /** end region */
  return (
    <Paper className={classes.root}>
      <form autoComplete="off" className={classes.form}>
        <Grid container xs={12} align="center" spacing={2} justify="left" className="mt-2">
          <Grid item xs={4}>
            <RMSAutoComplete
              size="small"
              autoComplete
              autoSelect
              autoHighlight
              id="combo-dna-Type"
              getOptionLabel={(option) => option || ''}
              options={codeDNSSampleTypes}
              label="Type"
              serviceName="codednatypes"
              title="Code"
              stateHolderAttribute="Type"
              variant="outlined"
              stateHolder={formData}
              setStateHolder={setFormData}
              setField={handleFormChangeNew}
            />
          </Grid>

          <Grid item xs={4}>
            <RMSAutoComplete
              size="small"
              autoComplete
              autoSelect
              autoHighlight
              id="combo-dna-Methos"
              getOptionLabel={(option) => option || ''}
              options={codeDNSSampleMethods}
              label="Method"
              serviceName="codednamethods"
              title="Code"
              stateHolderAttribute="Method"
              variant="outlined"
              stateHolder={formData}
              setStateHolder={setFormData}
              setField={handleFormChangeNew}
            />
          </Grid>
          <Grid item xs={4}>
            <RMSAutoComplete
              size="small"
              autoComplete
              autoSelect
              autoHighlight
              id="combo-dna-Reasons"
              getOptionLabel={(option) => option || ''}
              options={codeDNSSampleReasons}
              label="Reason"
              serviceName="codednareasons"
              title="Code"
              stateHolderAttribute="Reason"
              variant="outlined"
              stateHolder={formData}
              setStateHolder={setFormData}
              setField={handleFormChangeNew}
            />
          </Grid>
        </Grid>
        <Grid container xs={12} align="center" spacing={2} justify="left" className="mt-2">
          <Grid item xs={4}>
            <RMSAutoComplete
              size="small"
              autoComplete
              autoSelect
              autoHighlight
              id="combo-dna-collection"
              getOptionLabel={(option) => option || ''}
              options={codeDNACollection}
              label="Status"
              serviceName="codednacollection"
              title="Code"
              stateHolderAttribute="Status"
              variant="outlined"
              stateHolder={formData}
              setStateHolder={setFormData}
              setField={handleFormChangeNew}
            />
          </Grid>
          <Grid item xs={4}>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <KeyboardDatePicker
                size="small"
                inputVariant="outlined"
                fullWidth
                showTodayButton={true}
                variant="dialog"
                format="MM/dd/yyyy"
                id="date-picker-inline-from"
                label="Taken"
                value={formData.values?.Taken || null}
                onChange={(date) => {
                  setFormData((state) => {
                    return {
                      ...state,
                      values: {
                        ...state.values,
                        Taken: date,
                      },
                    };
                  });
                }}
                KeyboardButtonProps={{
                  'aria-label': 'change date',
                }}
              />
            </MuiPickersUtilsProvider>
          </Grid>
        </Grid>
      </form>
      <RMSFabButtons recordForm={true} handleSaveRecord={handleSave} />
    </Paper>
  );
};
const mapStateToProps = (state) => ({
  wsClient: state.websocket,
  codeDNACollection: state.codesOthers.codeDNACollection,
});

export default connect(mapStateToProps, {
  getCodeDNACollection,
  upsertRecord,
})(PersonDNASampleForm);
