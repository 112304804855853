import store from '../../config/configureStore';

export const SET_CODE_EVIDENCE_CATEGORY = 'CODE/SET_CODE_EVIDENCE_CATEGORY';
export const SET_CODE_EVIDENCE_CUSTODY_REASON = 'CODE/SET_CODE_EVIDENCE_CUSTODY_REASON';
export const ADD_CODE_EVIDENCE_CUSTODY_REASON = 'CODE/ADD_CODE_EVIDENCE_CUSTODY_REASON';
export const SET_CODE_EVIDENCE_CUSTODY_STATUS = 'CODE/SET_CODE_EVIDENCE_CUSTODY_STATUS';
export const ADD_CODE_EVIDENCE_CUSTODY_STATUS = 'CODE/ADD_CODE_EVIDENCE_CUSTODY_STATUS';
export const SET_CODE_EVIDENCE_DISPOSAL_CODE = 'CODE/SET_CODE_EVIDENCE_DISPOSAL_CODE';
export const ADD_CODE_EVIDENCE_DISPOSAL_CODE = 'CODE/ADD_CODE_EVIDENCE_DISPOSAL_CODE';
export const SET_CODE_EVIDENCE_FINAL_DISPOSITION = 'CODE/SET_CODE_EVIDENCE_FINAL_DISPOSITION';
export const ADD_CODE_EVIDENCE_FINAL_DISPOSITION = 'CODE/ADD_CODE_EVIDENCE_FINAL_DISPOSITION';
export const SET_CODE_EVIDENCE_REQUIRED_ACTION = 'CODE/SET_CODE_EVIDENCE_REQUIRED_ACTION';
export const ADD_CODE_EVIDENCE_REQUIRED_ACTION = 'CODE/ADD_CODE_EVIDENCE_REQUIRED_ACTION';
export const SET_CODE_EVIDENCE_STORAGE_CONTAINER = 'CODE/SET_CODE_EVIDENCE_STORAGE_CONTAINER';
export const ADD_CODE_EVIDENCE_STORAGE_CONTAINER = 'CODE/ADD_CODE_EVIDENCE_STORAGE_CONTAINER';
export const SET_CODE_EVIDENCE_STORAGE_LOCATION = 'CODE/SET_CODE_EVIDENCE_STORAGE_LOCATION';
export const ADD_CODE_EVIDENCE_STORAGE_LOCATION = 'CODE/ADD_CODE_EVIDENCE_STORAGE_LOCATION';
export const SET_CODE_EVIDENCE_STORAGE_SUB_LOCATION = 'CODE/SET_CODE_EVIDENCE_STORAGE_SUB_LOCATION';
export const SET_CODE_EVIDENCE_STORAGE_TEMP_LOCATION =
  'CODE/SET_CODE_EVIDENCE_STORAGE_TEMP_LOCATION';
export const ADD_CODE_EVIDENCE_STORAGE_TEMP_LOCATION =
  'CODE/ADD_CODE_EVIDENCE_STORAGE_TEMP_LOCATION';
export const SET_CODE_EVIDENCE_TYPE = 'CODE/SET_CODE_EVIDENCE_TYPE';
export const ADD_CODE_EVIDENCE_TYPE = 'CODE/ADD_CODE_EVIDENCE_TYPE';

export const getCodeEvidenceCategory = () => {
  const client = store.store.getState().websocket.websocket;
  return async (dispatch) => {
    try {
      const service = client.service('codeevidencecategory');
      service.timeout = 20000;
      const codes = await service.find({
        query: {
          $select: ['Code', 'Description'],
          $sort: {
            Code: 1,
          },
          IsDeleted: false,
        },
      });
      dispatch({ type: SET_CODE_EVIDENCE_CATEGORY, payload: codes.data });
    } catch (error) {
      if (error.code === 401) {
        console.log('Unauthenticated');
      } else {
        console.log(error);
      }
    }
  };
};

export const addCodeEvidenceCustodyReason = (newVal) => {
  return { type: ADD_CODE_EVIDENCE_CUSTODY_REASON, payload: newVal };
};

export const getCodeEvidenceCustodyReason = () => {
  const client = store.store.getState().websocket.websocket;
  return async (dispatch) => {
    try {
      const service = client.service('codeevidencecustodyreason');
      service.timeout = 20000;
      const codes = await service.find({
        query: {
          $select: ['Code', 'Description'],
          $sort: {
            Code: 1,
          },
          IsDeleted: false,
        },
      });
      dispatch({ type: SET_CODE_EVIDENCE_CUSTODY_REASON, payload: codes.data });
    } catch (error) {
      if (error.code === 401) {
        console.log('Unauthenticated');
      } else {
        console.log(error);
      }
    }
  };
};

export const addCodeEvidenceCustodyStatus = (newValue) => {
  return { type: ADD_CODE_EVIDENCE_CUSTODY_STATUS, payload: newValue };
};

export const getCodeEvidenceCustodyStatus = () => {
  const client = store.store.getState().websocket.websocket;
  return async (dispatch) => {
    try {
      const service = client.service('codeevidencecustodystatus');
      service.timeout = 20000;
      const codes = await service.find({
        query: {
          $select: ['Code', 'Description'],
          $sort: {
            Code: 1,
          },
          IsDeleted: false,
        },
      });
      dispatch({ type: SET_CODE_EVIDENCE_CUSTODY_STATUS, payload: codes.data });
    } catch (error) {
      if (error.code === 401) {
        console.log('Unauthenticated');
      } else {
        console.log(error);
      }
    }
  };
};

export const addCodeEvidenceDisposalCode = (newValue) => {
  return { type: ADD_CODE_EVIDENCE_DISPOSAL_CODE, payload: newValue };
};

export const getCodeEvidenceDisposalCode = () => {
  const client = store.store.getState().websocket.websocket;
  return async (dispatch) => {
    try {
      const service = client.service('codeevidencedisposalcode');
      service.timeout = 20000;
      const codes = await service.find({
        query: {
          $select: ['Code', 'Description'],
          $sort: {
            Code: 1,
          },
          IsDeleted: false,
        },
      });
      dispatch({ type: SET_CODE_EVIDENCE_DISPOSAL_CODE, payload: codes.data });
    } catch (error) {
      if (error.code === 401) {
        console.log('Unauthenticated');
      } else {
        console.log(error);
      }
    }
  };
};

export const addCodeEvidenceFinalDisposition = (newValue) => {
  return { type: ADD_CODE_EVIDENCE_FINAL_DISPOSITION, payload: newValue };
};

export const getCodeEvidenceFinalDisposition = () => {
  const client = store.store.getState().websocket.websocket;
  return async (dispatch) => {
    try {
      const service = client.service('codeevidencefinaldisposition');
      service.timeout = 20000;
      const codes = await service.find({
        query: {
          $select: ['Code', 'Description'],
          $sort: {
            Code: 1,
          },
          IsDeleted: false,
        },
      });
      dispatch({ type: SET_CODE_EVIDENCE_FINAL_DISPOSITION, payload: codes.data });
    } catch (error) {
      if (error.code === 401) {
        console.log('Unauthenticated');
      } else {
        console.log(error);
      }
    }
  };
};

export const addCodeEvidenceRequiredAction = (newValue) => {
  return { type: ADD_CODE_EVIDENCE_REQUIRED_ACTION, payload: newValue };
};

export const getCodeEvidenceRequiredAction = () => {
  const client = store.store.getState().websocket.websocket;
  return async (dispatch) => {
    try {
      const service = client.service('codeevidencerequiredaction');
      service.timeout = 20000;
      const codes = await service.find({
        query: {
          $select: ['Code', 'Description'],
          $sort: {
            Code: 1,
          },
          IsDeleted: false,
        },
      });
      dispatch({ type: SET_CODE_EVIDENCE_REQUIRED_ACTION, payload: codes.data });
    } catch (error) {
      if (error.code === 401) {
        console.log('Unauthenticated');
      } else {
        console.log(error);
      }
    }
  };
};

export const addCodeEvidenceStorageContainer = (newValue) => {
  return { type: ADD_CODE_EVIDENCE_STORAGE_CONTAINER, payload: newValue };
};

export const getCodeEvidenceStorageContainer = () => {
  const client = store.store.getState().websocket.websocket;
  return async (dispatch) => {
    try {
      const service = client.service('codeevidencestoragecontainer');
      service.timeout = 20000;
      const codes = await service.find({
        query: {
          $select: ['Code', 'Description'],
          $sort: {
            Code: 1,
          },
          IsDeleted: false,
        },
      });
      dispatch({ type: SET_CODE_EVIDENCE_STORAGE_CONTAINER, payload: codes.data });
    } catch (error) {
      if (error.code === 401) {
        console.log('Unauthenticated');
      } else {
        console.log(error);
      }
    }
  };
};

export const addCodeEvidenceStorageLocation = (newValue) => {
  return { type: ADD_CODE_EVIDENCE_STORAGE_LOCATION, payload: newValue };
};

export const getCodeEvidenceStorageLocation = () => {
  const client = store.store.getState().websocket.websocket;
  return async (dispatch) => {
    try {
      const service = client.service('codeevidencestoragelocation');
      service.timeout = 20000;
      const codes = await service.find({
        query: {
          $select: ['Code', 'Description'],
          $sort: {
            Code: 1,
          },
          IsDeleted: false,
        },
      });
      dispatch({ type: SET_CODE_EVIDENCE_STORAGE_LOCATION, payload: codes.data });
    } catch (error) {
      if (error.code === 401) {
        console.log('Unauthenticated');
      } else {
        console.log(error);
      }
    }
  };
};

export const getCodeEvidenceStorageSubLocation = () => {
  const client = store.store.getState().websocket.websocket;
  return async (dispatch) => {
    try {
      const service = client.service('codeevidencestoragesublocation');
      service.timeout = 20000;
      const codes = await service.find({
        query: {
          $select: ['Code', 'Description'],
          $sort: {
            Code: 1,
          },
          IsDeleted: false,
        },
      });
      dispatch({ type: SET_CODE_EVIDENCE_STORAGE_SUB_LOCATION, payload: codes.data });
    } catch (error) {
      if (error.code === 401) {
        console.log('Unauthenticated');
      } else {
        console.log(error);
      }
    }
  };
};

export const addCodeEvidenceTemporaryLocation = (newValue) => {
  return { type: ADD_CODE_EVIDENCE_STORAGE_TEMP_LOCATION, payload: newValue };
};

export const getCodeEvidenceTemporaryLocation = () => {
  const client = store.store.getState().websocket.websocket;
  return async (dispatch) => {
    try {
      const service = client.service('codeevidencetemporarylocation');
      service.timeout = 20000;
      const codes = await service.find({
        query: {
          $select: ['Code', 'Description'],
          $sort: {
            Code: 1,
          },
          IsDeleted: false,
        },
      });
      dispatch({ type: SET_CODE_EVIDENCE_STORAGE_TEMP_LOCATION, payload: codes.data });
    } catch (error) {
      if (error.code === 401) {
        console.log('Unauthenticated');
      } else {
        console.log(error);
      }
    }
  };
};

export const addCodeEvidenceType = (newValue) => {
  return { type: ADD_CODE_EVIDENCE_TYPE, payload: newValue };
};

export const getCodeEvidenceType = () => {
  const client = store.store.getState().websocket.websocket;
  return async (dispatch) => {
    try {
      const service = client.service('codeevidencetype');
      service.timeout = 20000;
      const codes = await service.find({
        query: {
          $select: ['Code', 'Description'],
          $sort: {
            Code: 1,
          },
          IsDeleted: false,
        },
      });
      dispatch({ type: SET_CODE_EVIDENCE_TYPE, payload: codes.data });
    } catch (error) {
      if (error.code === 401) {
        console.log('Unauthenticated');
      } else {
        console.log(error);
      }
    }
  };
};

export default function reducer(
  state = {
    codeEvidenceCategory: [],
    codeEvidenceCustodyReason: [],
    codeEvidenceCustodyStatus: [],
    codeEvidenceDisposalCode: [],
    codeEvidenceFinalDisposition: [],
    codeEvidenceRequiredAction: [],
    codeEvidenceStorageContainer: [],
    codeEvidenceStorageLocation: [],
    codeEvidenceStorageSubLocation: [],
    codeEvidenceTemporaryLocation: [],
    codeEvidenceType: [],
  },
  action
) {
  // eslint-disable-next-line default-case
  switch (action.type) {
    case SET_CODE_EVIDENCE_CATEGORY:
      return { ...state, codeEvidenceCategory: action.payload };
    case SET_CODE_EVIDENCE_CUSTODY_REASON:
      return { ...state, codeEvidenceCustodyReason: action.payload };
    case ADD_CODE_EVIDENCE_CUSTODY_REASON:
      return {
        ...state,
        codeEvidenceCustodyReason: [...state.codeEvidenceCustodyReason, action.payload],
      };
    case SET_CODE_EVIDENCE_CUSTODY_STATUS:
      return { ...state, codeEvidenceCustodyStatus: action.payload };
    case ADD_CODE_EVIDENCE_CUSTODY_STATUS:
      return {
        ...state,
        codeEvidenceCustodyStatus: [...state.codeEvidenceCustodyStatus, action.payload],
      };
    case SET_CODE_EVIDENCE_DISPOSAL_CODE:
      return { ...state, codeEvidenceDisposalCode: action.payload };
    case ADD_CODE_EVIDENCE_DISPOSAL_CODE:
      return {
        ...state,
        codeEvidenceDisposalCode: [...state.codeEvidenceDisposalCode, action.payload],
      };
    case SET_CODE_EVIDENCE_FINAL_DISPOSITION:
      return { ...state, codeEvidenceFinalDisposition: action.payload };
    case ADD_CODE_EVIDENCE_FINAL_DISPOSITION:
      return {
        ...state,
        codeEvidenceFinalDisposition: [...state.codeEvidenceFinalDisposition, action.payload],
      };
    case SET_CODE_EVIDENCE_REQUIRED_ACTION:
      return { ...state, codeEvidenceRequiredAction: action.payload };
    case ADD_CODE_EVIDENCE_REQUIRED_ACTION:
      return {
        ...state,
        codeEvidenceRequiredAction: [...state.codeEvidenceRequiredAction, action.payload],
      };
    case SET_CODE_EVIDENCE_STORAGE_CONTAINER:
      return { ...state, codeEvidenceStorageContainer: action.payload };
    case ADD_CODE_EVIDENCE_STORAGE_CONTAINER:
      return {
        ...state,
        codeEvidenceStorageContainer: [...state.codeEvidenceStorageContainer, action.payload],
      };
    case SET_CODE_EVIDENCE_STORAGE_LOCATION:
      return { ...state, codeEvidenceStorageLocation: action.payload };
    case ADD_CODE_EVIDENCE_STORAGE_LOCATION:
      return {
        ...state,
        codeEvidenceStorageLocation: [...state.codeEvidenceStorageLocation, action.payload],
      };
    case SET_CODE_EVIDENCE_STORAGE_SUB_LOCATION:
      return { ...state, codeEvidenceStorageSubLocation: action.payload };
    case SET_CODE_EVIDENCE_STORAGE_TEMP_LOCATION:
      return { ...state, codeEvidenceTemporaryLocation: action.payload };
    case ADD_CODE_EVIDENCE_STORAGE_TEMP_LOCATION:
      return {
        ...state,
        codeEvidenceTemporaryLocation: [...state.codeEvidenceTemporaryLocation, action.payload],
      };
    case SET_CODE_EVIDENCE_TYPE:
      return { ...state, codeEvidenceType: action.payload };
    case ADD_CODE_EVIDENCE_TYPE:
      return { ...state, codeEvidenceType: [...state.codeEvidenceType, action.payload] };
  }
  return state;
}
