import React from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserAlt, faBuilding } from '@fortawesome/free-solid-svg-icons';
import { ListItem, List, Menu, Button } from '@material-ui/core';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { resetSubForms } from '../../reducers/IncReducer';

const useStyles = makeStyles(() => ({
  button: {
    '&:hover': {
      background: 'transparent',
    },
    border: 'none',
    background: 'transparent',
  },
  menu: {
    width: '180px',
  },
}));

const VictimsMenu = (props) => {
  const classes = useStyles();
  const history = useHistory();
  const [anchorElMenu, setAnchorElMenu] = React.useState(null);

  const handleClickMenu = (event) => {
    setAnchorElMenu(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorElMenu(null);
  };

  const onPersonClicked = () => {
    resetSubForms('add');
    history.push('/incidents/add/victims/person');
  };

  const onPlacesClicked = () => {
    resetSubForms('add');
    history.push('/incidents/add/victims/place');
  };

  return (
    <>
      <span>
        <Button
          style={{ margin: '0px', padding: '0px' }}
          onClick={handleClickMenu}
          variant="text"
          disableFocusRipple="true"
          disableRipple="true"
          className={classes.button}>
          <div className="nav-link-icon opacity-6">
            <FontAwesomeIcon icon="user-shield" />
          </div>
          Victim
        </Button>

        <Menu
          className={classes.menu}
          anchorEl={anchorElMenu}
          keepMounted
          open={Boolean(anchorElMenu)}
          onClose={handleCloseMenu}
          classes={{ list: 'p-0' }}
          getContentAnchorEl={null}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}>
          <div className="overflow-hidden dropdown-menu-xl">
            <List className="flex-column">
              <ListItem button onClick={onPersonClicked}>
                <div className="nav-link-icon opacity-6">
                  <FontAwesomeIcon icon={faUserAlt} />
                </div>
                <span>Person</span>
              </ListItem>
              <ListItem button onClick={onPlacesClicked}>
                <div className="nav-link-icon opacity-6">
                  <FontAwesomeIcon icon={faBuilding} />
                </div>
                <span>Place</span>
              </ListItem>
            </List>
          </div>
        </Menu>
      </span>
    </>
  );
};

export default connect(null, {})(VictimsMenu);
